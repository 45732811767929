const { RATING_PREFIX } = require('./carbonPrefix')

const RATING_KEYS = Object.freeze({
  SYLVERA: 'SYLVERA',
  BEZERO: 'BEZERO',
  Other: 'Other',
})

const RATING_VALUES = Object.freeze({
  [RATING_KEYS.SYLVERA]: 'Sylvera',
  [RATING_KEYS.BEZERO]: 'BeZero',
  [RATING_KEYS.Other]: 'Other',
})

const RATING_FULL_KEYS = {}
Object.keys(RATING_KEYS).forEach((key) => {
  RATING_FULL_KEYS[key] = `${RATING_PREFIX}.${key}`
})

const RATING_PATHS = Object.values(RATING_FULL_KEYS)

const RATING_OPTIONS = Object.values(RATING_KEYS).map((rating) => {
  const option = {
    label: RATING_VALUES[rating],
    value: RATING_FULL_KEYS[rating],
  }
  if (rating === RATING_KEYS.Other) {
    option.extra = {
      field: `${RATING_PREFIX}.otherContent`,
      placeholder: 'Rating',
    }
  }
  return option
})

module.exports = {
  RATING_KEYS,
  RATING_PATHS,
  RATING_OPTIONS,
  RATING_FULL_KEYS,
  RATING_VALUES,
}
