import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Text from '@material-ui/core/Typography'

import { PERSON_CLIENT_TYPE, APP_CONTEXT_TYPE } from 'emsurge-selectors'
import OrderCard from 'screens/orderIndex/components/OrderCard'
import OrderCardCarbon from 'screens/orderIndex/components/OrderCardCarbon'
/**
 * Get the user orders with the criteria based on his client type.
 * As a Trader, "My Orders" are the ones:
 * - I created on behalf of my company (no 3rd party orders)
 * - Were created by a broker on my behalf
 * As a Broker, "My Orders" are the ones:
 * - I created on behalf of a third party
 * - I created on behalf of a trader
 * - Were shared with me
 * @param {Array[object]} orders all orders the user has access
 * @param {object} user logged user
 */
export const filterMyOrders = (orders, user) => {
  switch (user.clientType) {
    case PERSON_CLIENT_TYPE.TRADER:
      return orders.filter(
        (order) => order.person.id === user.id && !order.thirdParty
      )
    case PERSON_CLIENT_TYPE.BROKER:
      return orders.filter(
        (order) =>
          order.brokerPersonId === user.id ||
          (!order.brokerPersonId && order.brokerAccess.length > 0)
      )
    default:
      console.error({ err: 'Unknown client type' })
      return orders
  }
}

/**
 * Get all the user's orders with the criteria based on his client type.
 * As a Trader, "All Orders" are the ones that:
 * - Belong to my company
 * - Are not 3rd party
 * As a Broker, "All Orders" are the ones that:
 * - I created on behalf of a third party
 * - I created on behalf of a trader
 * - Were shared with me
 * - Were created by another broker from my company
 * @param {Array[object]} orders all orders the user has access
 * @param {object} user logged user
 */
export const filterAllOrders = (orders, user) => {
  switch (user.clientType) {
    case PERSON_CLIENT_TYPE.TRADER:
      return orders.filter((order) => !order.thirdParty && order.company.id === user.companyId)
    case PERSON_CLIENT_TYPE.BROKER:
      return orders
    default:
      console.error({ err: 'Unknown client type' })
      return orders
  }
}

export const sortByUpdatedAt = (orderA, orderB) => {
  return moment(orderB.updatedAt).diff(orderA.updatedAt)
}

const GUTTER = 4
const MARGIN_TOP = 15
const STYLE_PROPS = {
  style: PropTypes.object,
}

export const infoLabelFactory = (label) => {
  const LabelWithStyle = ({ style }) => (
    <Text style={{ ...style, fontWeight: 700 }}>{label}</Text>
  )

  LabelWithStyle.propTypes = STYLE_PROPS
  return LabelWithStyle
}

export const headingLabelFactory = (label) => {
  const HeadingLabelWithStyle = ({ style }) => (
    <Text
      style={{
        ...style,
        fontWeight: 700,
        top: style.top + MARGIN_TOP,
        height: style.height - MARGIN_TOP,
      }}
    >
      {label}
    </Text>
  )

  HeadingLabelWithStyle.propTypes = STYLE_PROPS
  return HeadingLabelWithStyle
}

export const orderCardFactory = (order) => {
  const OrderCardComponent =
    order.context === APP_CONTEXT_TYPE.CARBON ? OrderCardCarbon : OrderCard
  const OrderCardWithStyle = ({ style }) => (
    <OrderCardComponent
      order={order}
      style={{
        ...style,
        overflow: 'hidden',
        top: style.top + GUTTER,
        height: style.height - GUTTER,
      }}
    />
  )

  OrderCardWithStyle.propTypes = STYLE_PROPS

  return OrderCardWithStyle
}

export const NoMarketOrdersLabel = ({ style }) => (
  <Text style={{ ...style, fontWeight: 700 }}>There are no active orders</Text>
)

NoMarketOrdersLabel.propTypes = STYLE_PROPS

export const NO_ORDERS_LABEL_HEIGHT = 30
export const guessNoOrdersLabelHeight = () => NO_ORDERS_LABEL_HEIGHT

export const PENDING_ORDER_CARD_HEIGHT = 85
export const guessPendingOrderCardHeight = () => PENDING_ORDER_CARD_HEIGHT

export const OUT_TO_MARKET_ORDER_CARD_HEIGHT_CARBON = 95
export const OUT_TO_MARKET_ORDER_CARD_HEIGHT_LNG = 85
export const guessOutToMarketOrderCardHeight = (
  appContext = APP_CONTEXT_TYPE.LNG
) =>
  appContext === APP_CONTEXT_TYPE.CARBON
    ? OUT_TO_MARKET_ORDER_CARD_HEIGHT_CARBON
    : OUT_TO_MARKET_ORDER_CARD_HEIGHT_LNG

export const PENDING_LABEL_HEIGHT = 30
export const guessPendingLabelHeight = () => PENDING_LABEL_HEIGHT

export const OUT_TO_MARKET_LABEL_HEIGHT = 45
export const guessOutToMarketLabelHeight = () => OUT_TO_MARKET_LABEL_HEIGHT
