import { every, isEmpty } from 'lodash'

export const getStringOfValuesDeep = (object) => {
  // this function combines all the values of the object in one line, so we can use it to search
  let objectString = ''

  for (const key in object) {
    // eslint-disable-line
    const value = object[key]

    if (typeof value === 'object') {
      objectString += getStringOfValuesDeep(value)
    } else {
      objectString += value.toString()
    }
  }

  return objectString.toLowerCase()
}

export const hasValues = (obj) => !every(obj, isEmpty)
