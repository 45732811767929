import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'

import { DischargePortModel } from 'screens/orderCreate/components/DischargePortModel'
import TextField from 'containers/formikTextField/FormikTextField'

export const _DischargePort = ({ formik: { values } }) => (
  <DischargePortModel>
    {({ isNA, shouldDisableField }) => (
      <TextField
        fullWidth
        margin="normal"
        multiline
        InputLabelProps={{
          error: false,
        }}
        type="text"
        name={'nominationRules.dischargePort.base.name'}
        label="Base Port"
        disabled={shouldDisableField({
          ...values.nominationRules.dischargePort.base,
          isNA,
        })('port')}
      />
    )}
  </DischargePortModel>
)

_DischargePort.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const DischargePort = connect(_DischargePort)
