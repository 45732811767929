import { map, flatMap, pluck } from 'lodash/fp'
import { groupBy, isEmpty, get, clamp, chain, filter, each } from 'lodash'
import moment from 'moment'
import { $O, $T } from 'emsurge-selectors'
import { typeMap } from '../containers/periodRow/PeriodRow.constants'
import { PERIOD } from '../MarketVisualisation.constants'
import { REVERSE_PERIOD_LOOKUP } from 'components/DeliveryContract'
import { dark as theme } from 'theme'

const isToday = (mDate) => mDate.isSame(moment(), 'day')
const isYesterday = (mDate) =>
  mDate.isSameOrAfter(moment().subtract(1, 'day'), 'day') && !isToday(mDate)
const isPrevious = (mDate) => mDate.isBefore(moment().subtract(1, 'day'))

const TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  PREVIOUSLY = 'Previously'

const groupByDateField = (orders, field) =>
  groupBy(orders, (o) => {
    const date = moment(o[field])
    return (
      (isToday(date) && TODAY) ||
      (isYesterday(date) && YESTERDAY) ||
      (isPrevious(date) && PREVIOUSLY)
    )
  })

export const getFormattedGroups = (orders) => {
  const groups = groupByDateField(orders, 'updatedAt')

  return Object.keys(groups)
    .map((key) => ({
      id: key.length,
      content: `Updated ${key}`,
      treeLevel: 1,
      showNested: true,
      nestedGroups: pluck('id')(groups[key]),
    }))
    .sort((a, b) => a.id - b.id)
}

export const getFormattedOrders = map((order) => {
  const summary = $O.summary(order)
  return {
    id: order.id,
    treeLevel: 2,
    content: `${summary.tradingType} ${summary.deliveryLocation} ${summary.deliveryPeriod} ${summary.deliveryWindowShortLabel}`,
  }
})

const toFormattedCargosFromOrder = (order) => {
  const cargos = $T.deliveryWindow.cargos.get(order.nominationRules)
  return cargos.map((cargo, i) => ({
    id: `${order.id}-cargo-${i}`,
    orderId: order.id,
    group: order.id,
    start: cargo.from,
    end: cargo.to,
  }))
}

export const getFormattedCargos = flatMap(toFormattedCargosFromOrder)

export const findContiguousKeys = (type, name) => {
  const previous = typeMap[type]?.getPrevious(name)
  const next = typeMap[type]?.getNext(name)

  return {
    previous,
    next,
  }
}

export const findCustomContiguousKeys = (keys, currentPeriod) => {
  if (isEmpty(keys)) {
    return {}
  }
  const currentPeriodIndex = keys.findIndex((p) => p === currentPeriod)
  const lastIndex = keys.length - 1
  const previous = get(keys, clamp(currentPeriodIndex - 1, 0, lastIndex))
  const next = get(keys, clamp(currentPeriodIndex + 1, 0, lastIndex))

  return {
    previous: previous !== currentPeriod ? previous : null,
    next: next !== currentPeriod ? next : null,
  }
}

export const getKeys = (type, orders) => {
  if (type !== PERIOD.CUSTOM) {
    return []
  }

  return chain(orders)
    .sortBy((orderA) => $O.delivery.period.get(orderA).from)
    .map((order) => $O.delivery.period.text(order))
    .uniq()
    .value()
}

export const getDeliveryProps = (type, period, periodStartDate) => {
  if (type === PERIOD.CUSTOM) {
    return {
      'delivery.period': PERIOD.CUSTOM,
    }
  }
  return {
    'delivery.period': REVERSE_PERIOD_LOOKUP[period.split('-')?.[0]],
    'delivery.year': periodStartDate?.format('YYYY'),
  }
}

export const getOrders = (type, period, _orders) => {
  if (type === PERIOD.CUSTOM) {
    return filter(_orders, (order) => $O.delivery.period.text(order) === period)
  }
  return _orders
}

export const withHighlightStyle = (orders, highlightId) =>
  orders.map(({ id, ...props }) => ({
    id,
    ...props,
    style:
      id === highlightId ? `color: ${theme.palette.primary.main};` : undefined,
  }))

export const guessPeriodType = (period) => {
  let match
  each(typeMap, function (methods, key) {
    if (methods.isMatch(period)) {
      match = key
    }
  })
  return typeMap[match] || {}
}

export const getTimelinePropsFromOrder = (order) => {
  const period = $O.delivery.period.text(order)
  const type = guessPeriodType(period)

  return {
    typeName: type.name.toLowerCase(),
    period: period.toUpperCase(),
  }
}
