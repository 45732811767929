import { object, string } from 'yup'

const REQUIRED_TEXT = 'Required'
const INVALID_EMAIL_TEXT = 'Invalid email'

export const getCompanyAdminFormSchema = ({ email }) =>
  object().shape({
    name: string().required(REQUIRED_TEXT),
    surname: string().required(REQUIRED_TEXT),
    email: string()
      .email(INVALID_EMAIL_TEXT)
      .emailIsUnique(email)
      .required(REQUIRED_TEXT),
    phone: string().required(REQUIRED_TEXT),
    jobTitle: string().required(REQUIRED_TEXT),
  })
