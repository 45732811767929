import { get, keysIn, uniq } from 'lodash'
import moment from 'moment'

export const getPropLabels = (order) => ({
  broker: 'Broker',
  brokerAccess: order.brokerAccess?.map(
    (_, index) => `Broker Access ${index}`
  ) || ['Broker Access'],
  brokerId: 'Broker Id',
  cargoSize: {
    party: 'Cargo Party',
    type: 'Cargo Type',
    unit: 'Cargo Unit',
    varianceMinus: 'Cargo Variance -',
    variancePlus: 'Cargo Variance +',
    sizeMin: 'Cargo Size Min',
    sizeMax: 'Cargo Size Max',
  },
  checklist: order.checklist?.map((_, index) => `Checklist ${index}`) || [
    'Checklist',
  ],
  comments: order.comments?.map((_, index) => `Comment ${index}`) || [
    'Comment',
  ],
  company: {
    code: 'Company Code',
    id: 'Company Id',
    name: 'Company Name',
  },
  companyId: 'Company Id',
  counterPartyName: 'Counter Party Name',
  createdAt: 'Created At',
  deletedAt: 'Deleted At',
  delivery: {
    frequency: 'Delivery Frequency',
    frequencyDistribution: 'Delivery Frequency Distribution',
    location: 'Delivery Location',
    period: 'Delivery Period',
    type: 'Delivery Type',
    year: 'Delivery Year',
  },
  details: 'Note',
  entity: {
    code: 'Entity Code',
    id: 'Entity Id',
    name: 'Entity Name',
  },
  entityId: 'Entity Id',
  id: 'Id',
  nominationRules: {
    boilOff: 'Boil Off',
    demurage: 'Demurage',
    demurageNote: 'Demurage Note',
    laytime: 'Laytime',
    fsruFsu: 'FSRU/FSU',
    other: {
      alternatives: order?.nominationRules?.other?.alternatives?.map(
        (_, index) => ({
          name: `Other Terms #${index} (Name)`,
          description: `Other Terms #${index} (Description)`,
          party: `Other Terms #${index} (Party)`,
          timeframe: `Other Terms #${index} (Timeframe)`,
          timeframeValue: `Other Terms #${index} (Timeframe Value)`,
          timeframeValueDeliveryWindow: `Other Terms #${index} (Timeframe Value DW)`,
          toBeNominated: `Other Terms #${index} (To Be Nominated)`,
        })
      ),
    },
    ship: {
      alternatives: order?.nominationRules?.ship?.alternatives?.map(
        (_, index) => ({
          name: `LNG Ship Alternative #${index} (Name)`,
          party: `LNG Ship Alternative #${index} (Party)`,
          vesselType: `LNG Ship Alternative #${index} (Vessel Type)`,
          size: {
            min: `LNG Ship Alternative #${index} (Size Min)`,
            max: `LNG Ship Alternative #${index} (Size Max)`,
            value: `LNG Ship Alternative #${index} (Size)`,
          },
          timeframe: `LNG Ship Alternative #${index} (Timeframe)`,
          timeframeValue: `LNG Ship Alternative #${index} (Timeframe Value)`,
          timeframeValueDeliveryWindow: `LNG Ship Alternative #${index} (Timeframe Value DW)`,
          toBeNominated: `LNG Ship Alternative #${index} (To Be Nominated)`,
        })
      ),
      base: {
        name: 'LNG Ship Name',
        party: 'LNG Ship Party',
        vesselType: 'Vessel Type',
        size: {
          min: 'LNG Ship Min',
          max: 'LNG Ship Max',
          value: 'LNG Ship Value',
        },
        timeframe: 'LNG Ship Timeframe',
        timeframeValue: 'LNG Ship Timeframe Value',
        timeframeValueDeliveryWindow: 'LNG Ship Timeframe Value DW',
        toBeNominated: 'LNG Ship To Be Nominated',
      },
    },
    dischargePort: {
      alternatives: order?.nominationRules?.dischargePort?.alternatives?.map(
        (_, index) => ({
          name: `Discharge Port Alternative #${index} (Name)`,
          party: `Discharge Port Alternative #${index} (Party)`,
          timeframe: `Discharge Port Alternative #${index} (Timeframe)`,
          timeframeValue: `Discharge Port Alternative #${index} (Timeframe Value)`,
          timeframeValueDeliveryWindow: `Discharge Port Alternative #${index} (Timeframe Value DW)`,
          toBeNominated: `Discharge Port Alternative #${index} (To Be Nominated)`,
        })
      ),
      adgas: 'Discharge Port ADGAS',
      base: {
        party: 'Discharge Port Party',
        name: 'Discharge Port Base Name',
        toBeNominated: 'Discharge Port - To Be Nominated',
        timeframe: 'Discharge Port Timeframe',
        timeframeValue: 'Discharge Port Timeframe Value',
        timeframeValueDeliveryWindow: 'Discharge Port Timeframe DW',
      },
      timezone: 'Discharge Port Timezone',
    },
    loadPort: {
      alternatives: order?.nominationRules?.loadPort?.alternatives?.map(
        (_, index) => ({
          name: `Load Port Alternative #${index} (Name)`,
          party: `Load Port Alternative #${index} (Party)`,
          timeframe: `Load Port Alternative #${index} (Timeframe)`,
          timeframeValue: `Load Port Alternative #${index} (Timeframe Value)`,
          timeframeValueDeliveryWindow: `Load Port Alternative #${index} (Timeframe Value DW)`,
          toBeNominated: `Load Port Alternative #${index} (To Be Nominated)`,
        })
      ),
      adgas: 'Load Port ADGAS',
      base: {
        party: 'Load Port Party',
        name: 'Load Port Base Name',
        toBeNominated: 'Load Port - To Be Nominated',
        timeframe: 'Load Port Timeframe',
        timeframeValue: 'Load Port Timeframe Value',
        timeframeValueDeliveryWindow: 'Load Port Timeframe DW',
      },
      timezone: 'Load Port Timezone',
    },
    deliveryWindow: {
      alternatives: order?.nominationRules?.deliveryWindow?.alternatives?.map(
        (_, index) => ({
          name: `DW Alternative #${index} (Name)`,
          party: `DW Alternative #${index} (Party)`,
          timeframe: `DW Alternative #${index} (Timeframe)`,
          timeframeValue: `DW Alternative #${index} (Timeframe Value)`,
          timeframeValueDeliveryWindow: `DW Alternative #${index} (Timeframe Value DW)`,
          toBeNominated: `DW Alternative #${index} (To Be Nominated)`,
        })
      ),
      arrival: {
        period: 'DW Arrival Period',
        window: 'DW Arrival Window',
      },
      isProRata: 'Pro Rata',
      cargos: order?.nominationRules?.deliveryWindow?.cargos?.map(
        (_, index) => ({
          from: `Cargo #${index + 1} (From)`,
          to: `Cargo #${index + 1} (To)`,
          cargoWindow: `Cargo #${index + 1} (Window)`,
        })
      ),
    },
  },
  nominations: ['Nominations'],
  permission: 'Permission',
  person: {
    companyId: 'Person Company Id',
    email: 'Person Email',
    id: 'Person Id',
    name: 'Person Name',
    phone: 'Person Phone',
    surname: 'Person surname',
  },
  personId: 'Person Id',
  price: {
    basic: {
      type: 'Price Type',
      info: {
        notes: 'Price Request For Quotes',
        unit: 'Price Unit',
        value: 'Price Value',
        index: 'Price Index',
        amount: 'Price Amount',
        percentage: 'Price Percentage',
        plusOrMinus: 'Price Plus Or Minus',
        delivery: {
          period: 'Price Delivery Period',
          year: 'Price Delivery Year',
        },
        award: {
          date: 'Price Award Date',
          time: 'Price Award Time',
        },
        deadline: {
          date: 'Price Deadline Date',
          time: 'Price Deadline Time',
        },
      },
    },
    contract: {
      type: 'Contract Price Type',
      info: {
        notes: 'Contract Price Request For Quotes',
        unit: 'Contract Price Unit',
        value: 'Contract Price Value',
        index: 'Contract Price Index',
        amount: 'Contract Price Amount',
        percentage: 'Contract Price Percentage',
        plusOrMinus: 'Contract Price Plus Or Minus',
        delivery: {
          period: 'Contract Price Delivery Period',
          year: 'Contract Price Delivery Year',
        },
        award: {
          date: 'Contract Price Award Date',
          time: 'Contract Price Award Time',
        },
        deadline: {
          date: 'Contract Price Deadline Date',
          time: 'Contract Price Deadline Time',
        },
      },
    },
    reserve: {
      type: 'Reserve Price Type',
      info: {
        notes: 'Reserve Price Request For Quotes',
        unit: 'Reserve Price Unit',
        value: 'Reserve Price Value',
        index: 'Reserve Price Index',
        amount: 'Reserve Price Amount',
        percentage: 'Reserve Price Percentage',
        plusOrMinus: 'Reserve Price Plus Or Minus',
        delivery: {
          period: 'Reserve Price Delivery Period',
          year: 'Reserve Price Delivery Year',
        },
        award: {
          date: 'Reserve Price Award Date',
          time: 'Reserve Price Award Time',
        },
        deadline: {
          date: 'Reserve Price Deadline Date',
          time: 'Reserve Price Deadline Time',
        },
      },
    },
  },
  quality: {
    cbmPermitted: 'Quality CBM Permitted',
    type: 'Quality Type',
    usPermitted: 'Quality US Permitted',
    customMin: 'Quality Range Min',
    customMax: 'Quality Range Max',
  },
  shareToken: 'Share Token',
  shortId: 'Order Short Id',
  status: 'Order Status',
  submittedAt: 'Order Submitted At',
  swapId: 'Order Swap Id',
  template: 'Is Template',
  termsheets: order.termsheets?.map((_, index) => ({
    id: `Termsheet Id ${index}`,
  })),
  thirdParty: {
    companyName: 'Company Name',
    contact: 'Contact',
    entityName: 'Entity Name',
  },
  tradingType: 'Trading Type',
  updatedAt: 'Updated At',
  validUntil: 'Valid Until',
  validityType: 'Validity Type',
  volume: {
    min: 'Volume Min',
    max: 'Volume Max',
    metric: 'Volume Metric',
  },
})

export const defaultLabels = (indices) => ({
  price: {
    basic: {
      info: [
        {
          notes: `Price #${indices[0]} Request For Quotes`,
          unit: `Price #${indices[0]} (Unit)`,
          value: `Price #${indices[0]} (Value)`,
          index: `Price #${indices[0]} (Index)`,
          amount: `Price #${indices[0]} (Amount)`,
          weight: `Price #${indices[0]} (Weight)`,
          percentage: `Price #${indices[0]} (Percentage)`,
          plusOrMinus: `Price #${indices[0]} (Plus Or Minus)`,
          delivery: {
            period: `Price #${indices[0]} (Delivery Period)`,
            year: `Price #${indices[0]} (Delivery Year)`,
          },
        },
      ],
    },
    reserve: {
      info: [
        {
          notes: `Reserve Price #${indices[0]} (Request For Quotes)`,
          unit: `Reserve Price #${indices[0]} (Unit)`,
          value: `Reserve Price #${indices[0]} (Value)`,
          index: `Reserve Price #${indices[0]} (Index)`,
          amount: `Reserve Price #${indices[0]} (Amount)`,
          weight: `Price #${indices[0]} (Weight)`,
          percentage: `Reserve Price #${indices[0]} (Percentage)`,
          plusOrMinus: `Reserve Price #${indices[0]} (Plus Or Minus)`,
          delivery: {
            period: `Reserve Price #${indices[0]} (Delivery Period)`,
            year: `Reserve Price #${indices[0]} (Delivery Year)`,
          },
        },
      ],
    },
    contract: {
      info: [
        {
          notes: `Contract Price #${indices[0]} (Request For Quotes)`,
          unit: `Contract Price #${indices[0]} (Unit)`,
          value: `Contract Price #${indices[0]} (Value)`,
          index: `Contract Price #${indices[0]} (Index)`,
          amount: `Contract Price #${indices[0]} (Amount)`,
          weight: `Price #${indices[0]} (Weight)`,
          percentage: `Contract Price #${indices[0]} (Percentage)`,
          plusOrMinus: `Contract Price #${indices[0]} (Plus Or Minus)`,
          delivery: {
            period: `Contract Price #${indices[0]} (Delivery Period)`,
            year: `Contract Price #${indices[0]} (Delivery Year)`,
          },
        },
      ],
    },
  },
  nominationRules: {
    loadPort: {
      alternatives: [
        {
          name: `Load Port Alternative #${indices[0]} (Name)`,
          party: `Load Port Alternative #${indices[0]} (Party)`,
          timeframe: `Load Port Alternative #${indices[0]} (Timeframe)`,
          timeframeValue: `Load Port Alternative #${indices[0]} (Timeframe Value)`,
          timeframeValueDeliveryWindow: `Load Port Alternative #${indices[0]} (Timeframe Value DW)`,
          toBeNominated: `Load Port Alternative #${indices[0]} (To Be Nominated)`,
        },
      ],
    },
    dischargePort: {
      alternatives: [
        {
          name: `Discharge Port Alternative #${indices[0]} (Name)`,
          party: `Discharge Port Alternative #${indices[0]} (Party)`,
          timeframe: `Discharge Port Alternative #${indices[0]} (Timeframe)`,
          timeframeValue: `Discharge Port Alternative #${indices[0]} (Timeframe Value)`,
          timeframeValueDeliveryWindow: `Discharge Port Alternative #${indices[0]} (Timeframe Value DW)`,
          toBeNominated: `Discharge Port Alternative #${indices[0]} (To Be Nominated)`,
        },
      ],
    },
    ship: {
      alternatives: [
        {
          name: `LNG Ship Alternative #${indices[0]} (Name)`,
          party: `LNG Ship Alternative #${indices[0]} (Party)`,
          timeframe: `LNG Ship Alternative #${indices[0]} (Timeframe)`,
          timeframeValue: `LNG Ship Alternative #${indices[0]} (Timeframe Value)`,
          timeframeValueDeliveryWindow: `LNG Ship Alternative #${indices[0]} (Timeframe Value DW)`,
          toBeNominated: `LNG Ship Alternative #${indices[0]} (To Be Nominated)`,
        },
      ],
    },
    deliveryWindow: {
      alternatives: [
        {
          name: `DW Alternative #${indices[0]} (Name)`,
          party: `DW Alternative #${indices[0]} (Party)`,
          timeframe: `DW Alternative #${indices[0]} (Timeframe)`,
          timeframeValue: `DW Alternative #${indices[0]} (Timeframe Value)`,
          timeframeValueDeliveryWindow: `DW Alternative #${indices[0]} (Timeframe Value DW)`,
          toBeNominated: `DW Alternative #${indices[0]} (To Be Nominated)`,
        },
      ],
      cargos: [
        {
          from: `Cargo #${indices[0]} (From)`,
          to: `Cargo #${indices[0]} (To)`,
          cargoWindow: `Cargo #${indices[0]} (Window)`,
        },
      ],
    },
  },
})

/**
 * Sometimes the prop path isn't accessible by calling getPropLabels()
 * because the item could no longer exist in the current order.
 * The path is replaced with 0 and the indices are passed to defaultLabels()
 * so it can output the correct index of the array.
 * @param {String} propPath path of the prop whose label should be returned
 */
const getDefaultLabel = (propPath) => {
  const regex = /(\d+)/g
  const indices = propPath.match(regex) || []
  return get(defaultLabels(indices), propPath.replace(regex, '0'))
}

const toDate = (date) =>
  moment(date).isValid() ? moment(date).format('DD/MM/YY, HH:mm') : ''
const toPercentage = (value) => (value ? `${value}%` : '')
const toString = (value) => value?.toString()

const valuesMapper = {
  submittedAt: toDate,
  nominationRules: {
    deliveryWindow: {
      cargos: [
        {
          from: toDate,
          to: toDate,
        },
      ],
    },
  },
  price: {
    basic: {
      info: {
        percentage: toPercentage,
      },
    },
    contract: {
      info: {
        percentage: toPercentage,
      },
    },
    reserve: {
      info: {
        percentage: toPercentage,
      },
    },
  },
}

/**
 * Maps from/to values to date or other formats, defaulting to string conversion.
 * All paths are replaced with index 0.
 * @param {String} propPath path of the prop whose value transformer should be returned
 */
const getValueMapper = (propPath) => {
  const regex = /(\d+)/g
  return get(valuesMapper, propPath.replace(regex, '0'), toString)
}

export const toLogLines = (mappedProps, log) => {
  const allPropsPaths = uniq([...keysIn(log.from), ...keysIn(log.to)])

  return allPropsPaths.map((propPath) => {
    const label = get(mappedProps, propPath) || getDefaultLabel(propPath)
    const from = get(log.from, propPath, '')
    const to = get(log.to, propPath, '')
    const transformer = getValueMapper(propPath)

    return {
      label,
      from: transformer(from),
      to: transformer(to),
    }
  })
}
