import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import MuiTooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(
  (theme) => ({
    tooltip: {
      backgroundColor: '#101621',
      boxShadow: theme.shadows[1],
      color: '#FFF',
      fontWeight: 500,
    },
  }),
  { withTheme: true }
)

const Tooltip = ({ classes, ...props }) => (
  <MuiTooltip
    classes={{ tooltip: useStyles().tooltip, ...classes }}
    {...props}
  />
)

Tooltip.propTypes = {
  classes: PropTypes.object,
}

export default Tooltip
