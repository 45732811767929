import { useState, useEffect } from 'react'

export const useDebouncedState = (initialState, stateUpdater, duration) => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    const timeout = setTimeout(() => setState(stateUpdater), duration)
    return () => clearTimeout(timeout)
  }, [setState, stateUpdater, duration])

  return [state, setState]
}
