import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Hidden, Typography as Text } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { StyledGrid } from './StyledGrid'
import {
  getNameInfo,
  getNominationSentence,
  getVesselTypeData,
} from './ShipPort.helpers'

const ShipPort = ({
  title,
  name,
  size,
  toBeNominated,
  party,
  vesselType,
  nominationType,
  style,
  onClick,
  timeframe,
  timeframeValue,
  timeframeValueDeliveryWindow,
  additionalInfo,
  ...textProps
}) => {
  const { spacing } = useTheme()
  const vesselValues = [
    ...getNameInfo(name),
    ...getVesselTypeData(vesselType, size, toBeNominated),
    ...getNominationSentence(
      timeframe,
      timeframeValue,
      party,
      nominationType,
      toBeNominated,
      timeframeValueDeliveryWindow
    ),
  ]
  return (
    <StyledGrid
      key="order-terms-ship-port-container"
      onClick={onClick}
      container
    >
      <Grid item xs={12} sm={3}>
        <Text
          variant="body2"
          key={'order-terms-ship-port-title'}
          style={{ ...style, fontWeight: 700 }}
        >
          {title}
        </Text>
      </Grid>

      <Grid item xs={12} sm={7}>
        {vesselValues.map((text, idx) => (
          <Text
            key={`order-terms-ship-port-${idx}`}
            style={style}
            variant="body1"
            data-testid={
              textProps?.['data-testid'] && `${textProps['data-testid']}-${idx}`
            }
          >
            {text}
          </Text>
        ))}
      </Grid>

      <Grid item xs={12} sm={2}>
        <Hidden xsDown>
          {additionalInfo &&
            additionalInfo.map((text, idx) => (
              <Text
                key={`order-terms-ship-port-date-${idx}`}
                style={{ ...style, paddingLeft: spacing(2) }}
                variant="body1"
                {...textProps}
                data-testid={`order-terms-ship-port-date-${idx}`}
              >
                {text}
              </Text>
            ))}
        </Hidden>
      </Grid>
    </StyledGrid>
  )
}

ShipPort.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  size: PropTypes.object,
  toBeNominated: PropTypes.bool,
  nominationType: PropTypes.string,
  party: PropTypes.string,
  vesselType: PropTypes.string,
  timeframe: PropTypes.string,
  timeframeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timeframeValueTime: PropTypes.string,
  timeframeValueDeliveryWindow: PropTypes.string,
  onClick: PropTypes.func,
  additionalInfo: PropTypes.array,
}

export const AlternativeShipPorts = ({
  alternatives,
  nominationType,
  onClick,
  additionalInfo,
  ...textProps
}) => {
  if (!alternatives) {
    return null
  }

  return alternatives.map((alternative, index) => (
    <ShipPort
      {...alternative}
      onClick={onClick}
      style={{
        fontSize: 14,
        fontWeight: 500,
      }}
      key={index}
      nominationType={nominationType}
      title={`Alternative Vessel ${index + 1}`}
      additionalInfo={[additionalInfo?.[index]]}
      {...textProps}
    />
  ))
}

export default ShipPort
