import React from 'react'
import { PropTypes } from 'prop-types'
import { AddButton } from 'screens/orderCreateCommon/components/Buttons'

const AddAlternativeSourceButton = ({ onClick, label, ...props }) => (
  <AddButton {...props} onClick={onClick}>
    {label}
  </AddButton>
)

AddAlternativeSourceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
}

AddAlternativeSourceButton.defaultProps = {
  label: 'Add Alternative Sources',
}

export default AddAlternativeSourceButton
