import React from 'react'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { Redirect } from 'react-router-dom'
import { Form } from 'formik'
import { withTheme } from '@material-ui/styles'
import Add from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import NewEntityFormProvider from '../containers/NewEntityFormProvider'
import Fetch from 'containers/fetch/Fetch'
import TextField from 'containers/formikTextField/FormikTextField'
import FormikConsumer from 'containers/formikFormConsumer/FormikFormConsumer'
import SubmitButton from 'containers/formikSubmitButton/FormikSubmitButton'
import { ERROR } from 'routes'

const Select = withProps({
  select: true,
  fullWidth: true,
  margin: 'normal',
})(TextField)

const SelectCompany = ({ companies, onChange }) => (
  <Select
    data-testid="broker-access-company-dropdown"
    name="company"
    label="Select Company"
    onChange={(evt) => onChange(evt.target.value)}
  >
    {companies.map((company) => (
      <MenuItem key={company.id} value={company.id}>
        {company.name}
      </MenuItem>
    ))}
  </Select>
)

SelectCompany.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
}

const SelectEntity = ({ entities }) => (
  <Select
    data-testid="broker-access-entity-dropdown"
    name="entity"
    label={entities.length ? 'Select Entity' : 'All entities already selected'}
    disabled={!entities.length}
  >
    {entities.map((entity) => (
      <MenuItem key={entity.id} value={entity.id}>
        {entity.name}
      </MenuItem>
    ))}
  </Select>
)

SelectEntity.propTypes = {
  entities: PropTypes.array,
}

const NewEntityForm = ({ permissionedEntities, refetch, theme }) => {
  return (
    <Fetch url="/companies?type=trader">
      {({ loading, error, data: companies }) => {
        if (loading) {
          return null
        }

        if (error) {
          return <Redirect to={ERROR} />
        }

        return (
          <NewEntityFormProvider
            refetch={refetch}
            companies={companies}
            permissionedEntities={permissionedEntities}
          >
            <Form style={{ marginBottom: theme.spacing(2) }}>
              <FormikConsumer>
                {({ values, setFieldValue }) => {
                  const selectableEntities = companies
                    .find((company) => values.company === company.id)
                    .entities.filter(
                      (entity) =>
                        permissionedEntities.findIndex(
                          ({ id }) => id === entity.id
                        ) === -1
                    )

                  return (
                    <Grid container spacing={2} alignItems="flex-end">
                      <Grid item xs>
                        <SelectCompany
                          companies={companies}
                          onChange={() => setFieldValue('entity', '')}
                        />
                      </Grid>
                      <Grid item xs>
                        <SelectEntity entities={selectableEntities} />
                      </Grid>
                      <Grid item>
                        <SubmitButton
                          data-testid="broker-access-add-entity-button"
                          disabled={!values.entity}
                          icon={Add}
                        >
                          Add
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  )
                }}
              </FormikConsumer>
            </Form>
          </NewEntityFormProvider>
        )
      }}
    </Fetch>
  )
}

NewEntityForm.propTypes = {
  permissionedEntities: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
}

export default withTheme(NewEntityForm)
