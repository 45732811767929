import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from 'screens/orderCreate/components/Card'

const OffsetQuarterCard = ({ children }) => (
  <Grid container justifyContent="flex-end">
    <Grid item xs={9}>
      <Card>{children}</Card>
    </Grid>
  </Grid>
)

OffsetQuarterCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OffsetQuarterCard
