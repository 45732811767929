import React, { useEffect, useState } from 'react'
import {
  ADDITIONAL_ATTRIBUTES_SDG_PREFIX,
  SDGS_OPTIONS,
  SDGS_KEYS,
  ADDITIONAL_ATTRIBUTES_SDGS_PREFIX,
  ADDITIONAL_ATTRIBUTES_TYPE_KEYS,
  ADDITIONAL_ATTRIBUTES_TYPE_OPTIONS,
  ADDITIONAL_ATTRIBUTES_TYPE_PREFIX,
} from 'emsurge-selectors'
import { get } from 'lodash'
import { connect } from 'formik'
import styled from 'styled-components'
import { Switch } from '@material-ui/core'
import { DropdownSelectOptions } from './DropdownSelectOptions'

const MAPPED_ADDITIONAL_ATTRIBUTES = {
  false: ADDITIONAL_ATTRIBUTES_TYPE_KEYS.NONCERTIFIED,
  true: ADDITIONAL_ATTRIBUTES_TYPE_KEYS.CERTIFIED,
}

const SwitchWrapper = styled.div`
  margin-top: 16px;
`

export const _AdditionalAttributesSDGs = ({
  formik: { values, setFieldValue },
}) => {
  const [checked, setChecked] = useState(
    get(values, ADDITIONAL_ATTRIBUTES_TYPE_PREFIX) ===
      ADDITIONAL_ATTRIBUTES_TYPE_KEYS.CERTIFIED
  )

  const onSwitchChange = async (evt) => {
    const newChecked = evt?.target?.checked
    const checkedValue = MAPPED_ADDITIONAL_ATTRIBUTES[newChecked]
    setChecked(newChecked)
    setFieldValue(ADDITIONAL_ATTRIBUTES_TYPE_PREFIX, checkedValue)
  }

  const sortFn = (sdgA, sdgB) => sdgA.index > sdgB.index

  useEffect(() => {
    const selectedAdditionalAttributes =
      get(values, ADDITIONAL_ATTRIBUTES_SDGS_PREFIX) || []
    selectedAdditionalAttributes.forEach((cert) => {
      cert.focus = checked
    })
    setFieldValue(
      ADDITIONAL_ATTRIBUTES_SDGS_PREFIX,
      selectedAdditionalAttributes
    )
  }, [values.additionalAttributes.sdgs, checked])

  return (
    <>
      <DropdownSelectOptions
        optionsKeys={SDGS_KEYS}
        fullOptions={SDGS_OPTIONS}
        inputPath={ADDITIONAL_ATTRIBUTES_SDG_PREFIX}
        optionsPath={ADDITIONAL_ATTRIBUTES_SDGS_PREFIX}
        inputTitle="Select all relevant SDGs"
        sortFn={sortFn}
      >
        <SwitchWrapper>
          <label>
            {
              ADDITIONAL_ATTRIBUTES_TYPE_OPTIONS[
                ADDITIONAL_ATTRIBUTES_TYPE_KEYS.NONCERTIFIED
              ]
            }
          </label>
          <Switch
            inputProps={{ 'data-testid': 'sdgs-certified-switch' }}
            checked={checked}
            onChange={onSwitchChange}
            color="primary"
            name={ADDITIONAL_ATTRIBUTES_TYPE_PREFIX}
          />
          <label>
            {
              ADDITIONAL_ATTRIBUTES_TYPE_OPTIONS[
                ADDITIONAL_ATTRIBUTES_TYPE_KEYS.CERTIFIED
              ]
            }
          </label>
        </SwitchWrapper>
      </DropdownSelectOptions>
    </>
  )
}

export const AdditionalAttributesSDGs = connect(_AdditionalAttributesSDGs)
