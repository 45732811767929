import React from 'react'
import { connect } from 'formik'
const {
  VENUE_OPTIONS_PREFIX,
  VENUE_OPTIONS,
} = require('emsurge-selectors/constants')
const { CheckboxGroup } = require('./CheckboxGroup')

const _Venue = () => {
  return (
    <CheckboxGroup
      key="checkbox-group-venue"
      label="Venue"
      fieldPrefix={VENUE_OPTIONS_PREFIX}
      options={VENUE_OPTIONS}
    />
  )
}

export const VenueComponent = connect(_Venue)
