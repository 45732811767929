const nomination = require('../nomination')

const getName = (alternative) => {
  const { name } = alternative
  return !isNaN(name) ? parseInt(name) : undefined
}

const getNameSummary = (alternative) => {
  const name = getName(alternative)

  return (name || '').toString()
}

const getNameText = (alternative) => {
  const name = getName(alternative)

  return name ? `${name} Day DW` : ''
}

const getAlternatives = (terms) => {
  const deliveryWindow = terms.deliveryWindow || {}
  const alternatives = deliveryWindow.alternatives || []

  return alternatives
    .map((alternative) => {
      return {
        name: getName(alternative),
        nomination: nomination.get(alternative),
      }
    })
    .sort((a1, a2) => a2.name - a1.name)
}

const getAlternativesText = (terms) => {
  const deliveryWindow = terms.deliveryWindow || {}
  const alternatives = deliveryWindow.alternatives || []

  return alternatives.map((alternative) => {
    const nameText = getNameText(alternative)
    const nominationSummary = nomination.summary(alternative)

    return {
      name: nameText,
      nomination: nomination.text(alternative, {
        name: `${nominationSummary.timeframe.deliveryWindow} to an ${nameText}`,
        nominate: 'narrow',
      }),
    }
  })
}

const getAlternativesSummary = (terms) => {
  const deliveryWindow = terms.deliveryWindow || {}
  const alternatives = deliveryWindow.alternatives || []

  return alternatives.map((alternative) => ({
    name: getNameSummary(alternative),
    nomination: nomination.summary(alternative),
  }))
}

module.exports = {
  get: getAlternatives,
  summary: getAlternativesSummary,
  text: getAlternativesText,
}
