import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { truncate } from 'lodash'
import { Text, ClampedText } from './Text'
import { XsmallText, SmallText, capitalize } from 'components/Text'
import { TimeAgo } from 'components/TimeAgo'
import { Card, Container } from 'components/Card'
import { getFormattedDateWithOffset } from 'utils/dates'

const ContainerWithMargin = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`

export const ActivityBulletinCard = ({ activity, style, onClick }) => {
  const { ...bulletin } = activity
  const timeAgoDate = bulletin.occurredAt || bulletin.updatedAt

  // This control is reused by the bulletins page wish uses a different query from Activities. This query doesn't have an explanation.
  const explanationText = bulletin.explanation
    ? `You can see this bulletin because of Rule #1: You are in an entity that has access to the broker. Your entities with access to the broker are ${bulletin.explanation.rule1.person_entities_with_broker_access}.`
    : ''

  return (
    <Card
      style={style}
      onClick={onClick}
      id={`activity-${bulletin.id}`}
      data-testid={`activity-bulletin-${bulletin.id}`}
    >
      <Container justifyContent="space-between">
        <XsmallText
          title={explanationText}
          data-testid="activity-bulletin-type"
        >
          Message: {capitalize(bulletin.messageType)}
        </XsmallText>
        <XsmallText data-testid="activity-bulletin-author">
          {truncate(bulletin.company.name, { length: 22 })}
        </XsmallText>
      </Container>

      <Container style={{ width: 'auto' }}>
        <Text
          data-testid="activity-bulletin-title"
          variant="subtitle2"
          color="primary"
        >
          {bulletin.title}
        </Text>
      </Container>

      <Container justifyContent="space-between">
        <SmallText noWrap>
          {getFormattedDateWithOffset(bulletin.createdAt)}
        </SmallText>
        <TimeAgo date={timeAgoDate} />
      </Container>

      <ContainerWithMargin>
        <ClampedText
          data-testid="activity-bulletin-message"
          component={SmallText}
          style={{ whiteSpace: 'pre' }}
        >
          {bulletin.message}
        </ClampedText>
      </ContainerWithMargin>
    </Card>
  )
}

ActivityBulletinCard.defaultProps = {
  onClick: () => {},
}

ActivityBulletinCard.propTypes = {
  activity: PropTypes.object.isRequired,
  style: PropTypes.object,
  onClick: PropTypes.func,
}
