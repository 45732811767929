import { useQuery } from 'react-query'
import { useApi } from 'containers/api/useApi'
import { ORDER_KEY } from 'api/constants'

export const useOrderLog = (id) => {
  const api = useApi()

  const fetchOrderLog = async (id) => {
    const logs = await api.getOrderLog(id, {
      params: {
        actions: 'update',
      },
    })
    return logs.filter(({ tableName }) => tableName === 'order')
  }

  return useQuery(id && [ORDER_KEY, id, { log: true }], () => fetchOrderLog(id), {
    staleTime: 0, 
  })
}
