import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TableRow, TableCell } from '@material-ui/core'
import { PERSON_PERMISSION_TEXT } from 'emsurge-selectors/constants'
import { connect } from 'formik'
import { $U } from 'emsurge-selectors/models'
import RadioInput from 'containers/FormikRadioInput/FormikRadioInput'

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '0.8125rem',
    fontWeight: 400,
  },
}))

const _PersonPermission = ({ formik: { values: entity }, index, onChange }) => {
  const classes = useStyles()
  const options = Object.keys(PERSON_PERMISSION_TEXT).map(
    (PERMISSION_VALUE) => {
      return {
        value: PERMISSION_VALUE,
        label: PERSON_PERMISSION_TEXT[PERMISSION_VALUE],
        labelClassName: classes.label,
      }
    }
  )

  const fullName = $U.fullName.get(entity.persons[index])

  return (
    <TableRow>
      <TableCell data-testid={`persons[${index}].name`}>{fullName}</TableCell>
      <TableCell>
        <RadioInput
          name={`persons[${index}].permission`}
          options={options}
          onChange={onChange}
        />
      </TableCell>
    </TableRow>
  )
}

_PersonPermission.propTypes = {
  formik: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func,
}

_PersonPermission.defaultProps = {
  onChange: () => {},
}

export const PersonPermission = connect(_PersonPermission)
