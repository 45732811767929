import { get, isEmpty } from 'lodash'
import moment from 'moment'

const hasChecklist = (order) => {
  const checklistItems = get(order, 'checklist.items')

  return Array.isArray(checklistItems)
}

const getChecklist = (order) => {
  if (!hasChecklist(order)) {
    throw new Error('Order has no checklist. Use order.checklist.exists()')
  }

  const checklist = order.checklist.items.map((item) => {
    return {
      ...item,
      deadline: moment(item.deadline),
      dateChecked: isEmpty(item.dateChecked)
        ? undefined
        : moment(item.dateChecked),
    }
  })

  return checklist
}

export const checklist = {
  exists: hasChecklist,
  get: getChecklist,
}
