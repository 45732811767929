const getRate = (terms) => {
  return terms.demurage
}

const getRateSummary = (terms) => {
  const rate = getRate(terms)

  if (rate) {
    return `${rate}`
  }

  return ''
}

const getRateText = (terms) => {
  const rate = getRateSummary(terms)

  if (rate) {
    return `The rate shall be ${rate} USD per day and pro rata for every hour provided that each begun hour shall be fully paid`
  }

  return ''
}

const getBoilOff = (terms) => {
  return terms.boilOff
}

const getBoilOffSummary = (terms) => {
  const boilOff = getBoilOff(terms)

  if (boilOff) {
    return `${boilOff}%`
  }

  return ''
}
const getBoilOffText = (terms) => {
  const boilOffSummary = getBoilOffSummary(terms)

  if (boilOffSummary) {
    return `The rate shall be ${boilOffSummary} per day or pro rata for part of a day`
  }

  return ''
}

const getNotes = (terms) => {
  return terms.demurageNote
}

const getNotesText = (terms) => {
  const notes = getNotes(terms)

  return notes || ''
}

const getDemurrage = (terms) => {
  const { boilOff, demurage, demurageNote } = terms

  return {
    rate: demurage,
    boilOff,
    notes: demurageNote,
  }
}

const getDemurrageSummary = (terms) => {
  const rate = getRateSummary(terms)
  const boilOff = getBoilOffSummary(terms)
  const notes = getNotesText(terms)

  return {
    rate,
    boilOff,
    notes,
  }
}

module.exports = {
  get: getDemurrage,
  rate: {
    get: getRate,
    text: getRateText,
  },
  boilOff: {
    get: getBoilOff,
    text: getBoilOffText,
  },
  notes: {
    get: getNotes,
    text: getNotesText,
  },
  summary: getDemurrageSummary,
}
