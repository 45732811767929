import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ORDER_STATUS,
  PERSON_CLIENT_TYPE,
  TRADING_TYPE,
} from 'emsurge-selectors/constants'
import { matchPath, useHistory, useLocation } from 'react-router'

import { Grid as _Grid, Typography as Text } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import MarkAsTradedCheckbox from '../containers/MarkAsTradedCheckbox'
import TermsheetControl from '../containers/TermsheetControl'
import { ORDER_VIEW } from 'routes'
import { useRouteBaseSlug, getBaseSlug } from 'utils/useRouteBaseSlug'
import MarketInterestOrderCard from 'screens/orderIndex/components/MarketInterest/MarketInterestOrderCard'
import { useUser } from 'containers/user/useUser'
import { StyledLink as Link } from 'components/StyledLink'

const ContainerGrid = styled(_Grid)`
  ${spacing};
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-radius: 5px;
`

const Grid = styled(_Grid)`
  ${spacing}
`

export const TermsheetCard = ({ termsheet }) => {
  const { user } = useUser()
  const history = useHistory()
  const location = useLocation()
  const BASE_SLUG = useRouteBaseSlug()
  const bidOrder = termsheet.orders.find(
    (order) => order.tradingType === TRADING_TYPE.BID
  )
  const askOrder = termsheet.orders.find(
    (order) => order.tradingType === TRADING_TYPE.ASK
  )
  const isBuyerOrderWithheldFromOtherCompany =
    bidOrder.person.companyId !== user.companyId &&
    bidOrder.status === ORDER_STATUS.WITHHELD
  const isSellerOrderWithheldFromOtherCompany =
    askOrder.person.companyId !== user.companyId &&
    askOrder.status === ORDER_STATUS.WITHHELD
  const termsheetPath = `/${BASE_SLUG}/termsheets/${termsheet.id}`
  const {
    params: { id: orderId },
  } = matchPath(location.pathname, { path: ORDER_VIEW })

  const viewTermsheet = (location) => ({
    pathname: `/${getBaseSlug(location)}/termsheets/${termsheet.id}`,
    state: {
      referrer: `/${getBaseSlug(location)}/orders/${orderId}`,
    },
  })

  const editTermsheet = () => {
    history.push(`${termsheetPath}/edit`)
  }

  const checkboxProps = {
    showLabel: false,
    size: 'small',
    style: {
      padding: 0,
    },
  }

  return (
    <ContainerGrid container p={2} justifyContent="space-between">
      <Grid mb={1} item xs={6}>
        <Link data-testid="termsheet-card" to={viewTermsheet}>
          <Text variant="subtitle2" style={{ height: '100%' }}>
            Term sheet #{termsheet.shortId.toUpperCase()}
          </Text>
        </Link>
      </Grid>

      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            {
              <MarketInterestOrderCard
                order={{
                  ...bidOrder,
                  views: {
                    hasViewed: true,
                  },
                }}
                renderTopRight={() => (
                  <Grid item style={{ fontSize: 0 }}>
                    <MarkAsTradedCheckbox
                      order={bidOrder}
                      termsheet={termsheet}
                      checkboxProps={checkboxProps}
                    />
                  </Grid>
                )}
                disabled={
                  user.clientType === PERSON_CLIENT_TYPE.TRADER &&
                  isBuyerOrderWithheldFromOtherCompany
                }
              />
            }
          </Grid>
          <Grid item xs>
            {
              <MarketInterestOrderCard
                order={{
                  ...askOrder,
                  views: {
                    hasViewed: true,
                  },
                }}
                renderTopRight={() => (
                  <Grid item style={{ fontSize: 0 }}>
                    <MarkAsTradedCheckbox
                      order={askOrder}
                      termsheet={termsheet}
                      checkboxProps={checkboxProps}
                    />
                  </Grid>
                )}
                disabled={
                  user.clientType === PERSON_CLIENT_TYPE.TRADER &&
                  isSellerOrderWithheldFromOtherCompany
                }
              />
            }
          </Grid>
          <Grid item>
            <TermsheetControl termsheet={termsheet} onEdit={editTermsheet} />
          </Grid>
        </Grid>
      </Grid>
    </ContainerGrid>
  )
}

TermsheetCard.propTypes = {
  termsheet: PropTypes.object.isRequired,
}
