import React from 'react'

import { SelectNoOfCargoesType } from 'screens/orderCreate/components/SelectNoOfCargoesType'
import {
  PaddedContainer,
  Button,
  StyledContainer,
  StyledConnectedSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { SelectableGridOption as Option } from 'components/SelectableGrid'
import { SelectCargosQuantity } from 'screens/orderCreate/components/SelectCargosQuantity'
import { SelectCargosDeliveryFrequency } from 'screens/orderCreate/components/SelectCargosDeliveryFrequency'

export const NoOfCargoes = () => {
  return (
    <PaddedContainer>
      <SelectNoOfCargoesType>
        {({ options, onChange }) => (
          <StyledConnectedSelectableGrid name="volume.type" onChange={onChange}>
            {options.map(({ value, label }) => (
              <Option
                data-testid={`volume.type-${value}`}
                key={`order-cargo-volume-type-${value}`}
                xs={6}
                value={value}
              >
                <Button>{label}</Button>
              </Option>
            ))}
          </StyledConnectedSelectableGrid>
        )}
      </SelectNoOfCargoesType>

      <StyledContainer>
        <SelectCargosQuantity />
      </StyledContainer>

      <StyledContainer>
        <SelectCargosDeliveryFrequency>
          {({ options }) => (
            <StyledConnectedSelectableGrid name="delivery.frequency">
              {options.map(({ value, label, disabled }) => (
                <Option
                  data-testid={`delivery.frequency-${value}`}
                  key={`order-cargo-delivery-frequency-${value}`}
                  disabled={disabled}
                  value={value}
                >
                  <Button>{label}</Button>
                </Option>
              ))}
            </StyledConnectedSelectableGrid>
          )}
        </SelectCargosDeliveryFrequency>
      </StyledContainer>
    </PaddedContainer>
  )
}
