import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const useIsMobile = () => {
  const { breakpoints } = useTheme()
  return useMediaQuery(breakpoints.down('xs'))
}

export const Mobile = ({ children }) => {
  const isMobile = useIsMobile()
  return isMobile ? children : null
}

Mobile.defaultProps = {
  children: [],
}

Mobile.propTypes = {
  children: PropTypes.node,
}
