import React from 'react'
import styled from 'styled-components'
import Text from '@material-ui/core/Typography'
import { OrderedList, List, ListItem } from './List'

export const Header = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

const PrivacyPolicy = () => (
  <>
    <Header>
      <Text align="center" variant="h1" gutterBottom>
        EMSURGE PRIVACY POLICY
      </Text>
      <Text>
        Emsurge Limited (“Emsurge”) respects your privacy and is committed to
        protecting your personal data. This privacy policy tells you how we look
        after your personal data when you visit our website (regardless of where
        you visit it from) and about your privacy rights and how the law
        protects you.
      </Text>
    </Header>

    <OrderedList style={{ paddingLeft: 0, marginBottom: 240 }}>
      {/* 1 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          IMPORTANT INFORMATION AND WHO WE ARE
        </Text>

        <OrderedList>
          {/* 1.1 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Purpose of this privacy policy
            </Text>
            <Text gutterBottom>
              This privacy policy aims to give you information on how Emsurge
              collects and processes your personal data through your use of this
              website, including any data you may provide through this website
              when you sign up to our Trader Network.
            </Text>
            <Text gutterBottom>
              This website is not intended for children and we do not knowingly
              collect data relating to children.
            </Text>
            <Text>
              It is important that you read this privacy policy together with
              any other fair processing notice or privacy policy we may provide
              on specific occasions when we collect or process personal data
              about you, so you are fully aware how and why we are using your
              data. This privacy policy supplements such other notices and does
              not replace them.
            </Text>
          </ListItem>

          {/* 1.2 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Controllers
            </Text>
            <Text>
              Emsurge (“we”, “us” and “our” in this policy) is the controller
              and responsible for this website and your personal data.
            </Text>
          </ListItem>

          {/* 1.3 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Changes to this privacy policy and your duty to tell us of changes
            </Text>
            <Text gutterBottom>
              This version was last updated on 14 February, 2019
            </Text>
            <Text gutterBottom>
              We will tell you about any change the first time you visit our
              website after that change is made; you will need to accept the
              updated policy before using the Trader Network.
            </Text>
            <Text>
              It is important that the personal data we hold about you is
              accurate and up-to-date. Please let us know if any of your
              personal data changes during your relationship with us.
            </Text>
          </ListItem>

          {/* 1.4 */}
          <ListItem>
            <Text variant="h6" gutterBottom>
              Third party links
            </Text>
            <Text>
              This website may include links to third party websites, plug-ins
              and applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third parties and are not responsible
              for their privacy policies. When you leave our website, we
              recommend that you read the privacy policy of every website you
              visit and every plug-in or application you use.
            </Text>
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 2 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          THE DATA WE COLLECT ABOUT YOU
        </Text>

        <OrderedList>
          {/* 2.1 */}
          <ListItem gutterBottom={3}>
            <Text>
              Personal data means any information about a living individual from
              which that person can be identified. It does not include data
              where the identity has been removed (anonymous data).
            </Text>
          </ListItem>

          {/* 2.2 */}
          <ListItem gutterBottom={3}>
            <Text gutterBottom>
              We may collect, use, store and transfer different kinds of
              personal data about you which we have grouped together as follows:
            </Text>

            <List>
              <ListItem ordered={false} gutterBottom>
                <Text>
                  Identity data including your first name, last name, maiden
                  name, title and company of employment;
                </Text>
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                <Text>
                  Crypto-experience data including your trading experience;
                </Text>
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                <Text>
                  Address data including your work email address and work
                  telephone numbers;
                </Text>
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                <Text>
                  Technical data including your internet protocol (IP) address,
                  your login data, browser type and version, time zone setting
                  and location, browser plug-in types and versions, operating
                  system, system crash reports, and platform and other details
                  about the devices you use to access this website; and
                </Text>
              </ListItem>
              <ListItem ordered={false}>
                <Text>
                  Marketing and communications data including your preferences
                  in receiving marketing material from us and our third parties
                  and your communication preferences.
                </Text>
              </ListItem>
            </List>
          </ListItem>

          {/* 2.3 */}
          <ListItem gutterBottom={3}>
            <Text>
              We also collect, use and share aggregated data such as statistical
              or demographic data. Aggregated data may be derived from your
              personal data but is not considered to be personal data in law as
              it does not directly or indirectly reveal your identity. For
              example, we may aggregate your usage data to calculate the
              percentage of users accessing a specific website feature. However,
              if we combine or connect aggregated data with your personal data
              so that it can directly or indirectly identify you, the combined
              data is personal data and we deal with it in accordance with this
              privacy policy.
            </Text>
          </ListItem>

          {/* 2.4 */}
          <ListItem gutterBottom={3}>
            <Text>
              We do not collect details about your racial or ethnic origin,
              political opinions, religious or philosophical beliefs, trade
              union membership, health, sex life, sexual orientation, or genetic
              or biometric data (known as “special categories of data”), or any
              information about criminal convictions or offences.
            </Text>
          </ListItem>

          {/* 2.5 */}
          <ListItem>
            <Text>
              If we need to collect personal data in order to comply with law or
              under the terms of any contract we have with you, and you fail to
              provide this data when requested, we may not be able to perform a
              contract we have or are trying to make with you (e.g. to provide
              you with goods or services). In this situation, we may need to
              cancel an order or service you have with us, but we will tell you
              if this is the case.
            </Text>
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 3 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          HOW YOUR PERSONAL DATA IS COLLECTED
        </Text>

        <OrderedList>
          {/* 3.1 */}
          <ListItem>
            <Text gutterBottom>
              We collect data from and about you in in different ways, including
              by:
            </Text>

            <OrderedList>
              {/* 3.1.1 */}
              <ListItem gutterBottom={3}>
                <Text gutterBottom>
                  Direct interaction with you. You may give us identity, contact
                  and/or financial data by filling in forms or by communicating
                  with us by post, telephone, email or otherwise. This includes
                  data you provide when you:
                </Text>

                <List>
                  <ListItem ordered={false} gutterBottom>
                    create an account on our website;
                  </ListItem>
                  <ListItem ordered={false} gutterBottom>
                    share your business card with us;
                  </ListItem>
                  <ListItem ordered={false} gutterBottom>
                    share your personal data with others using the Trader
                    Network (for example, if you tell someone your date of birth
                    on the Trader Network);
                  </ListItem>
                  <ListItem ordered={false} gutterBottom>
                    request marketing information; or
                  </ListItem>
                  <ListItem ordered={false}>give us feedback.</ListItem>
                </List>
              </ListItem>

              {/* 3.1.2 */}
              <ListItem gutterBottom={3}>
                Automated technologies. When you interact with our website, we
                may automatically collect technical data about your equipment,
                browsing actions and patterns. We collect this personal data
                using cookies, server logs and other similar technologies. We
                may also receive technical data about you if you visit other
                websites using our cookies.
              </ListItem>

              {/* 3.1.3 */}
              <ListItem gutterBottom={3}>
                <Text gutterBottom>
                  Third parties and publicly available sources. We may receive
                  personal data about you from other people and/or from public
                  sources, as set out below:
                </Text>

                <List>
                  <ListItem ordered={false} gutterBottom>
                    other users of the Trader Network in the course of their use
                    of the Trader Network;
                  </ListItem>
                  <ListItem ordered={false} gutterBottom>
                    personal data that you share on publicly accessible social
                    media accounts or the internet more generally;
                  </ListItem>
                  <ListItem ordered={false} gutterBottom>
                    identity, financial and transaction data from credit
                    reference and fraud prevention agencies; and
                  </ListItem>
                  <ListItem ordered={false}>
                    identity and contact data from publicly available sources
                    based within the EU such as Companies House, the Electoral
                    Register and government sanction lists.
                  </ListItem>
                </List>
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 4 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          HOW WE USE YOUR PERSONAL DATA
        </Text>

        <OrderedList>
          {/* 4.1 */}
          <ListItem gutterBottom={3}>
            <Text gutterBottom>
              We will only use your personal data when the law allows us to do
              so. Our most common uses of your personal data will be:
            </Text>

            <List gutterBottom>
              <ListItem ordered={false} gutterBottom>
                in order for us to perform a contract we are about to make or
                have made with you (or to take steps at your request before
                entering such a contract);
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                in order for us to perform a service to you, such as the
                provision of our Emsurge network, which will include personal
                data (including information and contact details) being disclosed
                and made available to other trader users on such network;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                if it is necessary to do so for our legitimate interests (or
                those of a third party) and your interests and fundamental
                rights do not override those interests; and/or
              </ListItem>
              <ListItem ordered={false}>
                in order for us to comply with a legal or regulatory obligation.
              </ListItem>
            </List>

            <Text>
              We do not generally rely on your consent as a legal basis for
              processing your personal data except in relation to sending third
              party direct marketing communications to you via email or text.
              You have the right to withdraw your consent to marketing at any
              time by emailing us at support@emsurge.com.
            </Text>
          </ListItem>

          {/* 4.2 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Marketing
            </Text>

            <OrderedList>
              {/* 4.2.1 */}
              <ListItem gutterBottom={3}>
                We try to provide you with choices about the use of your
                personal data, particularly in relation to marketing and
                advertising. You have the right to withdraw your consent to
                marketing at any time by emailing us at support@emsurge.com.
              </ListItem>

              {/* 4.2.2 */}
              <ListItem gutterBottom={3}>
                We may use your identity, contact, technical, usage and/or
                profile data to form a view on what we think you may need or
                want or what may be of interest to you, in order to decide what
                products, services or offers may be of interest to you.
              </ListItem>

              {/* 4.2.3 */}
              <ListItem>
                We will also get your express opt-in consent before we share
                your personal data with anyone outside Emsurge for marketing
                purposes.
              </ListItem>
            </OrderedList>
          </ListItem>

          {/* 4.3 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Opting out
            </Text>
            <Text gutterBottom>
              You can ask us, and/or any third party, to stop sending you
              marketing message at any time by contacting us.
            </Text>
            <Text>
              If you opt out of receiving any marketing messages, this will not
              apply to personal data provided to us as a result of your use of
              the Trader Network.
            </Text>
          </ListItem>

          {/* 4.4 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Cookies
            </Text>
            <Text>
              You can set your browser to refuse some or all browser cookies, or
              to alert you when websites set or access cookies. If you disable
              or refuse cookies, some parts of our website may become
              inaccessible or may not function properly.
            </Text>
          </ListItem>

          {/* 4.5 */}
          <ListItem>
            <Text variant="h6" gutterBottom>
              Change of purpose
            </Text>
            <Text gutterBottom>
              We will only use your personal data for the purposes for which we
              collected it, unless we reasonably consider that we need to use it
              for another reason which is compatible with the original purpose.
              If you would like an explanation as to how processing for the new
              processing is compatible with the original purpose, please contact
              us.
            </Text>
            <Text gutterBottom>
              If we need to use your personal data for an unrelated purpose, we
              will notify you and explain the legal basis which allows us to do
              so.
            </Text>
            <Text>
              Please be aware that we may process your personal data without
              your knowledge or consent, if this is required or permitted by
              law.
            </Text>
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 5 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          DISCLOSURES OF YOUR PERSONAL DATA
        </Text>

        <OrderedList>
          {/* 5.1 */}
          <ListItem gutterBottom={3}>
            <Text gutterBottom>
              We may share your personal data with the parties set out:
            </Text>

            <OrderedList>
              {/* 5.1.1 */}
              <ListItem gutterBottom={3}>
                Internal third parties: other companies associated with Emsurge,
                including Emstream (whether acting as joint controllers and/or
                processors), who are based in the UK, Dubai or Singapore and
                provide consultancy or brokering services; and
              </ListItem>

              {/* 5.1.2 */}
              <ListItem gutterBottom={3}>
                <Text gutterBottom>External third parties:</Text>

                <List>
                  <ListItem ordered={false} gutterBottom>
                    service providers (acting as processors) based in UK,
                    Portugal and other locations from time to time who provide
                    IT and system administration services, delivery services,
                    sales services, complaints and support handling services;
                    the following companies process your personal data under
                    contracts with us for these purposes: AppliedBlockchain in
                    the UK, Emstream Limited in the UK;
                  </ListItem>
                  <ListItem ordered={false} gutterBottom>
                    professional advisers (acting as processors or joint
                    controllers), including lawyers, accountants, auditors,
                    bankers, insurers, in each case based in UK who provide
                    legal, accountancy, audit, banking, insurance or
                    consultancy, or other services to us; the following process
                    your personal data under contracts with us for these
                    purposes: Alvis Company in the UK, Kemp Little in the UK.
                  </ListItem>
                  <ListItem ordered={false}>
                    HM Revenue & Customers, regulators and other authorities
                    (acting as processors or joint controllers) based in UK or
                    Singapore who require us to report on processing activities
                    in certain circumstances;
                  </ListItem>
                </List>
              </ListItem>

              {/* 5.1.3 */}
              <ListItem>
                Other third parties, if we transfer any part of our business or
                assets to them or acquire any part of their business or assets,
                or otherwise merge any part of our and their businesses; in any
                of these cases, the new owners of our business may use your
                personal data in the same ways and for the same purposes as set
                out in this privacy policy.
              </ListItem>
            </OrderedList>
          </ListItem>

          {/* 5.2 */}
          <ListItem gutterBottom={3}>
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the law. We only
            disclose the personal data each third party needs in order to
            provide the specified purposes, and except as set out in clause 5.3
            we do not allow any of our third party service providers to use your
            personal data for their own purposes and only permit them to process
            your personal data for specified purposes and in accordance with our
            instructions.
          </ListItem>

          {/* 5.3 */}
          <ListItem>
            We only share your personal data with a third party for it to use
            for its own purposes if you have given your express opt-in consent
            to this.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 6 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          INTERNATIONAL TRANSFERS
        </Text>

        <OrderedList>
          {/* 6.1 */}
          <ListItem>
            We will not transfer your personal data to any country (other than
            the United Kingdom) which is outside the European Union except as
            permitted by applicable law.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 7 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          DATA SECURITY
        </Text>

        <OrderedList>
          {/* 7.1 */}
          <ListItem gutterBottom={3}>
            <Text gutterBottom>
              We have put in place appropriate security measures to protect your
              personal data from being:
            </Text>

            <List>
              <ListItem ordered={false} gutterBottom>
                accidentally or unlawfully destroyed, lost or altered; or
              </ListItem>
              <ListItem ordered={false}>
                disclosed or accessed in an unauthorised way.
              </ListItem>
            </List>
          </ListItem>

          {/* 7.2 */}
          <ListItem gutterBottom={3}>
            <Text gutterBottom>
              Any paper files are stored in secure areas. In relation to
              information that is held on our computer database, we apply the
              following guidelines:
            </Text>

            <List>
              <ListItem ordered={false} gutterBottom>
                passwords will be required to access the system;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                data ownership is clearly defined;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                employees have restricted access to certain sections of the
                system;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                unauthorised employees are barred from updating and editing
                personal information;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                all computers which contain personal information are secured
                both physically and electronically;
              </ListItem>
              <ListItem ordered={false}>
                print reporting of data containing personal information is
                limited.
              </ListItem>
            </List>
          </ListItem>

          {/* 7.3 */}
          <ListItem gutterBottom={3}>
            We limit access to your personal data to those of our employees,
            agents, contractors and other third parties who have a business need
            to know this data. They will only process your personal data on our
            instructions, and they are subject to a duty of confidentiality.
          </ListItem>

          {/* 7.4 */}
          <ListItem>
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any relevant regulator of a
            breach if we are legally required to do so.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 8 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          HOW LONG WILL YOU USE MY PERSONAL DATA FOR
        </Text>

        <OrderedList>
          {/* 8.1 */}
          <ListItem gutterBottom={3}>
            We will only retain your personal data for as long as necessary to
            fulfil the purposes we collected it for, including for the purposes
            of complying with any legal, accounting and/or reporting
            obligations.
          </ListItem>

          {/* 8.2 */}
          <ListItem gutterBottom={3}>
            By law we have to keep some basic information about our customers
            (including contact, identity, financial and transaction data) for
            six years after they cease to be customers, for tax purposes. To
            determine the appropriate retention period for other personal data,
            we consider the amount, nature and sensitivity of the personal data,
            the potential risk of harm from its unauthorised use or disclosure,
            the purposes for which we process it, and whether we can achieve
            those purposes through other means, and the applicable legal
            requirements.
          </ListItem>

          {/* 8.3 */}
          <ListItem gutterBottom={3}>
            Your personal data will only be used and held for the duration of
            your membership and for up to a year after to the extent that it is
            necessary to enable and manage your membership of the Emsurge
            Network.
          </ListItem>

          {/* 8.4 */}
          <ListItem>
            <Text gutterBottom>
              In some circumstances you can ask us to delete your personal data:
              see clause 9.4.
            </Text>
            <Text>
              In some circumstances we may anonymise your personal data (so that
              it can no longer be associated with you) for research or
              statistical purposes, in which case we may use this information
              indefinitely without further notice to you.
            </Text>
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 9 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          YOUR LEGAL RIGHTS
        </Text>

        <OrderedList>
          {/* 9.1 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Your rights - practicalities
            </Text>
            <Text gutterBottom>
              In certain circumstances, you have rights under data protection
              laws in relation to your personal data, as set out below. If you
              wish to exercise any of these rights, please contact us.
            </Text>
            <Text gutterBottom>
              You will not usually have to pay a fee to access your personal
              data or to exercise any of the other rights in clauses 9.2 to 9.9
              inclusive However, if your request is clearly unfounded,
              repetitive or excessive, we may refuse to comply with your request
              or charge a reasonable fee for the administrative costs of
              complying with your request.
            </Text>
            <Text gutterBottom>
              We may need to ask you for specific information to help us confirm
              your identity and to ensure your right to access your personal
              data or exercise any of your other rights. This is a security
              measure to ensure your personal data is not disclosed to anyone
              who is not entitled to receive it. We may also ask you for further
              information in relation to your request, in order to speed up our
              response.
            </Text>
            <Text>
              We try to respond to all requests within one month. Occasionally
              it may take us longer than a month to respond, if your request is
              particularly complex or you have made a number of requests; we
              will let you know within a month if this is the case and will keep
              you updated.
            </Text>
          </ListItem>

          {/* 9.2 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Right to access your personal data (a “subject access request”)
            </Text>
            <Text>
              You have the right to be told whether we are processing personal
              data about you and, if we are doing so, to receive a copy of the
              personal data we hold about you and to check that we are
              processing it lawfully.
            </Text>
          </ListItem>

          {/* 9.3 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Right to correction
            </Text>
            <Text>
              You have the right to require us to correct any inaccurate
              personal data we hold about you, and to complete any incomplete
              personal data. We may need to verify the accuracy of the new data
              you provide to us.
            </Text>
          </ListItem>

          {/* 9.4 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Right to erasure (the “right to be forgotten”)
            </Text>
            <Text gutterBottom>
              You have the right to ask us to delete any personal data about you
              if:
            </Text>

            <List gutterBottom>
              <ListItem ordered={false} gutterBottom>
                there is no good reason for us continuing to process it;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                you have withdrawn your consent to us processing it and there is
                no other legal ground for us to continue to do so;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                you have successfully exercised your right to object to
                processing (see clause 9.6);
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                we have processed that personal data unlawfully; or
              </ListItem>
              <ListItem ordered={false}>
                we are required to erase that personal data to comply with a
                legal obligation under local law.
              </ListItem>
            </List>

            <Text>
              There may be legal reasons why we cannot comply with your request
              for erasure. If this is the case, we will notify you of this at
              the time of your request.
            </Text>
          </ListItem>

          {/* 9.5 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Right to restrict processing
            </Text>
            <Text gutterBottom>
              You have the right to ask us to suspend the processing of your
              personal data if:
            </Text>

            <List>
              <ListItem ordered={false} gutterBottom>
                you want us to verify the accuracy of that data;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                our processing of that data is unlawful but you do not want us
                to erase it;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                we no longer need to process the personal data but you want us
                to retain it because you need it to establish, exercise or
                defend a legal claim; or
              </ListItem>
              <ListItem ordered={false}>
                you have objected to our processing but we need to verify
                whether we have any overriding legitimate grounds to process it
                (see clause 9.6).
              </ListItem>
            </List>
          </ListItem>

          {/* 9.6 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Right to object to processing
            </Text>
            <Text gutterBottom>
              You have the right to object to processing of your personal data
              for direct marketing purposes.
            </Text>
            <Text>
              You also have the right to object to processing of your personal
              data where we are relying on our own or a third party’s legitimate
              interest to justify our processing, if there is something in your
              particular situation which makes you feel our processing impacts
              your interests or fundamental rights and freedoms. In some
              situations we may demonstrate that we have compelling legitimate
              grounds for our processing which override your interests, rights
              and freedoms, or that our processing is necessary to establish,
              exercise or defend a legal claim, and in these cases we can
              continue to process the data for these purposes.
            </Text>
          </ListItem>

          {/* 9.7 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Right to object to automated decision making
            </Text>
            <Text gutterBottom>
              You have the right not to have any decision which has a
              significant legal impact on you made solely on the basis of
              automated processing, including profiling, unless that decision:
            </Text>

            <List>
              <ListItem ordered={false} gutterBottom>
                is permitted by local law which safeguards your rights, freedoms
                and legitimate interests; or
              </ListItem>
              <ListItem ordered={false}>
                is necessary to enter or perform a contract between you and us
                or based on your explicit consent, and we take measure to
                safeguard your rights, freedoms and legitimate interests
                including at least the right for you to require human
                intervention in the decision, to put your viewpoint and to
                contest the decision.
              </ListItem>
            </List>
          </ListItem>

          {/* 9.8 */}
          <ListItem gutterBottom={3}>
            <Text variant="h6" gutterBottom>
              Right to request transfer of data (“data portability”)
            </Text>
            <Text gutterBottom>
              You have the right to ask us to transfer, to you or anyone-else,
              any of your personal data which you have provided, if:
            </Text>

            <List gutterBottom>
              <ListItem ordered={false} gutterBottom>
                our processing is carried out by automated means; and
              </ListItem>
              <ListItem ordered={false}>
                our processing is based on your consent or is necessary to
                perform a contract with you.
              </ListItem>
            </List>

            <Text>
              We will provide this personal data in a structured, commonly-used
              and machine-readable format.
            </Text>
          </ListItem>

          {/* 9.9 */}
          <ListItem>
            <Text variant="h6" gutterBottom>
              Right to withdraw consent
            </Text>
            <Text gutterBottom>
              Where we are relying on your consent to process your personal
              data, you have the right to withdraw that consent at any time.
              This will not affect the lawfulness of any processing based on
              consent which was carried out before you withdrew your consent.
            </Text>
            <Text>
              If you withdraw your consent, we may not be able to provide
              certain products or services to you. We will let you know if this
              is the case when you withdraw your consent.
            </Text>
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 10 */}
      <ListItem>
        <Text variant="h6" gutterBottom>
          Contact details
        </Text>
        <Text gutterBottom>Emsurge Limited</Text>
        <Text gutterBottom>Email address: support@emsurge.com</Text>
        <Text gutterBottom>
          Postal address: 1 Garrick Close, Hersham, Walton-On-Thames, United
          Kingdom, KT12 5NY
        </Text>
        <Text>
          You have the right to complain at any time to the Information
          Commissioner’s Office (ICO), which is the UK’s supervisory authority
          for data protection issues (www.ico.org.uk). We would however
          appreciate the chance to deal with your concerns before you approach
          the ICO, do please do contact us in the first instance.
        </Text>
      </ListItem>
    </OrderedList>
  </>
)

export default PrivacyPolicy
