import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray, connect } from 'formik'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Card from 'screens/orderCreate/components/Card'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'
import AddAlternativeSourceButton from 'screens/orderCreate/components/AddAlternativeSourceButton'

import Checkbox from 'containers/formikCheckbox/FormikCheckbox'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'

import NominationPort from 'screens/orderCreate/components/NominationPort'
import SelectTimeZone from 'screens/orderCreate/components/SelectTimeZone'
import { NOMINATION_PORT_DEFAULT_VALUES } from 'screens/orderCreate/containers/NominationsInitialValues'
import { LoadPortModel } from 'screens/orderCreate/components/LoadPortModel'

const LoadPortForm = ({ formik: { values, setFieldValue } }) => (
  <LoadPortModel>
    {({ isFOB, shouldDisableField, setPartyDefaultValue }) => (
      <>
        {isFOB && (
          <SelectTimeZone
            name="nominationRules.deliveryWindow.timezone"
            label="Timezone (for nominations)"
          />
        )}
        <NominationPort
          mainName="nominationRules.loadPort.base"
          portNameLabel="Base Port (any safe port in)"
          setPartyDefaultValue={setPartyDefaultValue}
          shouldBeDisabled={shouldDisableField(
            values.nominationRules.loadPort.base
          )}
        />
        <FieldArray
          name="nominationRules.loadPort.alternatives"
          render={(arrayHelpers) => (
            <>
              {values.nominationRules.loadPort.alternatives.map((_, index) => (
                <Card key={`alternative_${index}`}>
                  <CloseButton onClick={() => arrayHelpers.remove(index)} />
                  <NominationPort
                    mainName={`nominationRules.loadPort.alternatives[${index}]`}
                    portNameLabel="Permitted Alternative Port"
                    setPartyDefaultValue={setPartyDefaultValue}
                    shouldBeDisabled={shouldDisableField(
                      values.nominationRules.loadPort.alternatives[index]
                    )}
                  />
                </Card>
              ))}
              <AddAlternativeSourceButton
                label="Add Alternative Port"
                onClick={() =>
                  arrayHelpers.push({
                    ...NOMINATION_PORT_DEFAULT_VALUES,
                    name: isFOB ? values.delivery.location : '',
                  })
                }
              />
            </>
          )}
        />
        <FormControl fullWidth>
          <FormControlLabel
            label="ADGAS subject to Buyer's consent"
            control={
              <Checkbox
                name="nominationRules.loadPort.adgas"
                color="primary"
                value="nominationRules.loadPort.adgas"
                onChange={() => {
                  setFieldValue(
                    'nominationRules.loadPort.adgas',
                    !values.nominationRules.loadPort.adgas
                  )
                }}
              />
            }
          />
        </FormControl>
      </>
    )}
  </LoadPortModel>
)

LoadPortForm.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const LoadPort = connect(LoadPortForm)
