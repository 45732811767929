import { lazy, object, string, bool } from 'yup'

import {
  PERSON_CLIENT_TYPE,
  CARBON_STANDARD_KEYS,
  STAKEHOLDER_TYPE,
  PROGRAM_ELIGIBILITY_KEYS,
  RATING_KEYS,
} from 'emsurge-selectors'

const companyShapeByClientType = {
  [PERSON_CLIENT_TYPE.TRADER]: object().when('behalfOf', (behalfOf, schema) => {
    if ([STAKEHOLDER_TYPE.ON_SYSTEM].includes(behalfOf)) {
      return schema.shape({
        entityId: string().label('Entity').required(),
      })
    }
  }),
  [PERSON_CLIENT_TYPE.BROKER]: object().when('behalfOf', (behalfOf, schema) => {
    if ([STAKEHOLDER_TYPE.ON_SYSTEM].includes(behalfOf)) {
      return schema.shape({
        companyId: string().required(),
        entityId: string().required(),
        traderId: string().required(),
      })
    }
  }),
}

const PROGRAM_ELIGIBILITY_SCHEMA = {}
Object.values(PROGRAM_ELIGIBILITY_KEYS).forEach((programEligibility) => {
  PROGRAM_ELIGIBILITY_SCHEMA[programEligibility] = bool()
})

const CARBON_STANDARD_SCHEMA = {}
Object.values(CARBON_STANDARD_KEYS).forEach((cs) => {
  CARBON_STANDARD_SCHEMA[cs] = bool()
})
CARBON_STANDARD_SCHEMA['otherContent'] = string()

const RATING_SCHEMA = {}
Object.values(RATING_KEYS).forEach((rat) => {
  RATING_SCHEMA[rat] = bool()
})
RATING_SCHEMA['otherContent'] = string()

const validationCheckboxOptions = (schema, key, label) => {
  return object()
    .shape({
      ...schema,
    })
    .test(`at-least-one-${key}`, `Select at least one ${label}`, (option) => {
      return Object.values(option).some((opt) =>
        [true, undefined].includes(opt)
      )
    })
}

export const typeSchema = object({
  programEligibility: validationCheckboxOptions(
    PROGRAM_ELIGIBILITY_SCHEMA,
    'program-eligibility',
    'program eligibility'
  ),
  carbonStandard: validationCheckboxOptions(
    CARBON_STANDARD_SCHEMA,
    'carbon-standard',
    'carbon standard'
  ),
  rating: validationCheckboxOptions(RATING_SCHEMA, 'rating', 'rating'),
  titleTransfers: string(),
  classifications: object().shape({
    first: string(),
    second: string(),
  }),
  otherCarbonStandard: string(),
  otherProgramEligibility: string(),
  otherRating: string(),
})

export const termVintageSchema = object({
  termSpot: string(),
  fixedRolling: string(),
  period: object(),
  offset: object(),
  vintageFixed: object(),
  vintageRolling: object(),
  projectInformation: object(),
})

export const getSchema = (user, isCreatingTrade) =>
  lazy(() =>
    object({
      behalfOf: string().oneOf([
        STAKEHOLDER_TYPE.OFF_SYSTEM,
        STAKEHOLDER_TYPE.ON_SYSTEM,
      ]),
      thirdParty: object().when('behalfOf', (behalfOf, schema) => {
        if ([STAKEHOLDER_TYPE.OFF_SYSTEM].includes(behalfOf)) {
          return schema.shape({
            entityName: string().label('Entity name').required().min(1),
            companyName: string().label('Company name').required().min(2),
          })
        }
        return schema
      }),
      myCompany: isCreatingTrade ? object().notRequired() : companyShapeByClientType[user.clientType],
      type: typeSchema,
      termVintage: termVintageSchema,
      seller: isCreatingTrade ? partySchema.required() : object().notRequired(),
      buyer: isCreatingTrade ? partySchema.required() : object().notRequired()
    })
  )

  const partySchema = object({
    company: object({
      name : string().required(),
      id: string().notRequired()
    }),
    entity: object({
      name : string().required(),
      id: string().notRequired()
    }),
    person: object({
      name : string().required(),
      id: string().notRequired()
    }),
    executionTrader: object({
      email: string().email('Invalid email')
    })
  })