import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import Typography from '@material-ui/core/Typography'
import StepLabel from '@material-ui/core/StepLabel'

import { steps as essentialSteps, stepsNewTrade as essentialStepsNewTrade } from './EssentialFormStepper'
import { steps as optionalSteps } from './OptionalFormStepper'
import { steps as recapSteps } from './RecapFormStepper'
import {
  TAB_CARBON,
  TAB_CARBON_TEXT,
  useActiveStep,
} from 'containers/OrderProvider'

const DRAWER_WIDTH = 260

const useStyles = makeStyles(({ spacing }) => ({
  drawer: {
    height: '100%',
  },
  paper: {
    width: DRAWER_WIDTH,
    position: 'inherit',
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: spacing(1),
  },
  stepButton: {
    display: 'inline',
    textAlign: 'left',
    marginTop: spacing(1),
    paddingTop: 0,
  },
  mainTabTitle: {
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  stepTitle: {
    fontSize: 12,
  },
  icon: {
    height: 18,
    fontSize: 16,
    fontWeight: 900,
  },
}))

const SideBar = ({isCreatingTrade}) => {
  const classes = useStyles()
  const {
    activeStep,
    navigate: { goTo },
  } = useActiveStep()

  const drawerProps = {
    variant: 'permanent',
    anchor: 'right',
    PaperProps: {
      classes: {
        root: classes.paper,
      },
    },
  }

  const mainTabs = [
    {
      header: TAB_CARBON_TEXT.ESSENTIAL,
      stepsList: isCreatingTrade ? essentialStepsNewTrade : essentialSteps,
      index: TAB_CARBON.ESSENTIAL,
    },
    {
      header: TAB_CARBON_TEXT.OPTIONAL,
      stepsList: optionalSteps,
      index: TAB_CARBON.OPTIONAL,
    },
    {
      header: TAB_CARBON_TEXT.RECAP,
      stepsList: recapSteps,
      index: TAB_CARBON.RECAP,
    },
  ]

  return (
    <Drawer {...drawerProps} className={classes.drawer}>
      <List disablePadding>
        {mainTabs.map((tab) => (
          <ListItem className={classes.listItem} key={tab.header} dense>
            <Typography
              variant="subtitle2"
              className={classes.mainTabTitle}
              onClick={() => goTo({ tab: tab.index, step: 0 })}
              gutterBottom
            >
              {tab.header}
            </Typography>
            <Step>
              {tab.stepsList.map((tabStep, idx) => (
                <StepButton
                  key={idx}
                  active={
                    activeStep.tab === tab.index && activeStep.step === idx
                  }
                  onClick={() => goTo({ tab: tab.index, step: idx })}
                  className={classes.stepButton}
                  icon={tabStep.number}
                >
                  <StepLabel
                    StepIconProps={{ classes: { root: classes.icon } }}
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.stepTitle}
                    >
                      {tabStep.title}
                    </Typography>
                  </StepLabel>
                </StepButton>
              ))}
            </Step>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

SideBar.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const SideMenu = connect(SideBar)
