/* :: () -> string */
export const createId = (() => {
  let id = 0

  const createId = () => {
    id += 1
    return String(id)
  }

  return createId
})()
