import React from 'react'
import PropTypes from 'prop-types'
import { Grid, IconButton } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { FieldArray } from 'formik'
import { DELIVERY_PERIOD_TYPE_TEXT } from 'emsurge-selectors'
import { noop } from 'lodash'
import { Option } from './Option'
import { GridInline } from './GridInline'
import TextField from 'containers/formikTextField/FormikTextField'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'

const Actions = ({ fieldName = '', onMoveDown = noop, onMoveUp = noop }) => {
  return (
    <Grid container alignItems="center" style={{ height: 32 }}>
      <GridInline item mx={1}>
        <TextField
          name={`${fieldName}.value`}
          inputProps={{
            type: 'number',
            min: 1,
            max: 32,
            style: {
              textAlign: 'center',
            },
          }}
          InputProps={{
            style: {
              width: '3rem',
              textAlign: 'center',
            },
          }}
        />
      </GridInline>
      <GridInline item>
        <IconButton size="small" onClick={onMoveUp}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </GridInline>
      <GridInline item>
        <IconButton size="small" onClick={onMoveDown}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </GridInline>
    </Grid>
  )
}

Actions.propTypes = {
  fieldName: PropTypes.string,
  onMoveDown: PropTypes.func,
  onMoveUp: PropTypes.func,
}

export const PeriodOptions = () => (
  <FormConsumer>
    {({ values }) => (
      <FieldArray
        name="periods"
        render={({ swap }) => {
          return values.periods.map((period, index) => (
            <Option
              key={`period-${period.type}`}
              label={DELIVERY_PERIOD_TYPE_TEXT[period.type]}
              actions={
                <Actions
                  fieldName={`periods.${index}`}
                  onMoveDown={() =>
                    swap(index, Math.min(index + 1, values.periods.length - 1))
                  }
                  onMoveUp={() => swap(index, Math.max(index - 1, 0))}
                />
              }
            />
          ))
        }}
      />
    )}
  </FormConsumer>
)
