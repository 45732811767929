import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'

import { QualityType as QualityTypeModel } from 'screens/orderCreate/components/QualityType'
import { QualityGHVFields } from 'screens/orderCreate/components/QualityGHVFields'

const QualityType = () => (
  <FormControl>
    <FormLabel>Quality</FormLabel>
    <QualityTypeModel>
      {({ options, onChange }) => (
        <RadioInputs
          name="quality.type"
          options={options}
          onChange={(evt) => onChange(evt.target.value)}
        />
      )}
    </QualityTypeModel>
  </FormControl>
)

export const QualityGHV = () => (
  <>
    <QualityType />
    <QualityGHVFields />
  </>
)
