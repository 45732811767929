import {
  ADDITIONAL_ATTRIBUTES_OPTIONS_PATHS,
  ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX,
  ADDITIONAL_ATTRIBUTES_SDG_PREFIX,
  ADDITIONAL_ATTRIBUTES_SDGS_PREFIX,
  ADDITIONAL_ATTRIBUTES_TYPE_PREFIX,
  ADDITIONAL_ATTRIBUTES_TYPE_KEYS,
  PROJECT_PATH,
  VENUE_FULL_KEYS,
  VENUES,
  VENUE_OPTIONS_PATHS,
  ALTERNATIVES_PREFIX,
  PROJECT_SECTORAL_SCOPE_CHECKBOX,
  REGION_COUNTRIES_PREFIX,
  REGION_OPTIONS_PREFIX,
  REGION_TYPE_PREFIX,
  REGION_TYPE_KEYS,
  REGIONS_FULL_KEYS,
} from 'emsurge-selectors'
import { set } from 'lodash'

export const getStep6 = () => {
  const initialValues = {}
  set(initialValues, REGION_OPTIONS_PREFIX, {})
  set(initialValues, REGION_TYPE_PREFIX, REGION_TYPE_KEYS.ALL)
  set(initialValues, REGION_COUNTRIES_PREFIX, [])
  Object.values(REGIONS_FULL_KEYS).forEach((path) =>
    set(initialValues, path, true)
  )
  return initialValues
}

export const getStep7 = () => {
  const initialValues = {}
  set(initialValues, `${PROJECT_SECTORAL_SCOPE_CHECKBOX}.ALL`, true)
  set(initialValues, PROJECT_PATH, [{}])
  return initialValues
}

export const getStep8 = () => {
  const initialValues = {}
  set(initialValues, ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX, {})
  set(initialValues, ADDITIONAL_ATTRIBUTES_SDGS_PREFIX, [])
  set(
    initialValues,
    ADDITIONAL_ATTRIBUTES_TYPE_PREFIX,
    ADDITIONAL_ATTRIBUTES_TYPE_KEYS.NONCERTIFIED
  )
  return initialValues
}

export const getStep9 = () => {
  const initialValues = {}
  set(initialValues, VENUE_FULL_KEYS[VENUES.OTC], true)
  return initialValues
}

export const getStep10 = () => {
  const initialValues = {}
  set(initialValues, ALTERNATIVES_PREFIX, [])
  return initialValues
}

export const getStep6Paths = () => []
export const getStep7Paths = () => [
  PROJECT_PATH,
  PROJECT_SECTORAL_SCOPE_CHECKBOX,
]

export const getStep8Paths = () => [
  ...Object.values(ADDITIONAL_ATTRIBUTES_OPTIONS_PATHS),
  ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX,
  ADDITIONAL_ATTRIBUTES_SDG_PREFIX,
  ADDITIONAL_ATTRIBUTES_SDGS_PREFIX,
]

export const getStep9Paths = () => [...Object.values(VENUE_OPTIONS_PATHS)]
export const getStep10Paths = () => []

export const getOptionalInitialValues = () => {
  return {
    ...getStep6(),
    ...getStep7(),
    ...getStep8(),
    ...getStep9(),
    ...getStep10(),
  }
}

export const getOptionalPaths = () => [
  ...getStep6Paths(),
  ...getStep7Paths(),
  ...getStep8Paths(),
  ...getStep9Paths(),
  ...getStep10Paths(),
]
