import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'

export const ErrorMessage = ({ children, message, title }) => {
  return (
    <div style={{
      backgroundColor: '#353F50',
      color: 'whitesmoke',
      padding: '40px',
      height: '100%',
      position: 'absolute',
      left: '0',
      width: '100%',
    }}>
      <Text variant="h4" gutterBottom align='center' marginTop='20px'>
        {title}
      </Text>
      <Text variant="h6" gutterBottom align='center'style={{
          margin : '40px',
        }}>
        {message}
      </Text>
      {children}
    </div>
  )
}

ErrorMessage.defaultProps = {
  title: 'An error has occurred',
  message:   `Please, try again in a few minutes and contact us if this persists`
}

ErrorMessage.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}
