import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import {
  infoLabelFactory,
  headingLabelFactory,
  orderCardFactory,
  NoMarketOrdersLabel,
  guessNoOrdersLabelHeight,
  guessPendingOrderCardHeight,
  guessOutToMarketOrderCardHeight,
  guessPendingLabelHeight,
  guessOutToMarketLabelHeight,
} from './helpers'
import { withHeight } from 'screens/orderIndex/components/helpers'
import { $O } from 'model/order/selectors/selector'
import { VirtualList } from 'components/VirtualList'
import useAppContext from 'containers/appContext/useAppContext'

const getPendingOrderNodes = (pendingOrders) => {
  if (isEmpty(pendingOrders)) {
    return [
      withHeight(
        guessNoOrdersLabelHeight(),
        infoLabelFactory('There are no pending orders')
      ),
    ]
  }
  return pendingOrders.map((order) =>
    withHeight(guessPendingOrderCardHeight(), orderCardFactory(order))
  )
}

const getOutToMarketNodes = (
  outToMarketOrders,
  appContext = APP_CONTEXT_TYPE.LNG
) => {
  if (isEmpty(outToMarketOrders)) {
    return [withHeight(guessNoOrdersLabelHeight(), NoMarketOrdersLabel)]
  }
  return outToMarketOrders.map((order) =>
    withHeight(
      guessOutToMarketOrderCardHeight(appContext),
      orderCardFactory(order)
    )
  )
}

const RowRenderer = ({ index, style, data }) => {
  const { Component } = data[index]
  return <Component style={style} />
}

RowRenderer.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.array,
}

const getItemSize = (data, index) => {
  const { height } = data[index]
  return height
}

const MyOrders = ({ orders }) => {
  const { appContext } = useAppContext()
  const pendingOrders = orders.filter((order) => $O.status.isPending(order))
  const outToMarketOrders = orders.filter((order) =>
    $O.status.isOutToMarket(order)
  )

  const nodeTree = [
    withHeight(guessPendingLabelHeight(), infoLabelFactory('Pending')),
    ...getPendingOrderNodes(pendingOrders),
    withHeight(guessOutToMarketLabelHeight(), headingLabelFactory('Active')),
    ...getOutToMarketNodes(outToMarketOrders, appContext),
  ]

  return (
    <VirtualList
      rowRenderer={RowRenderer}
      getItemSize={getItemSize}
      items={nodeTree}
    />
  )
}

MyOrders.propTypes = {
  orders: PropTypes.array.isRequired,
}
MyOrders.defaultProps = {
  orders: [],
}

export default memo(MyOrders)
