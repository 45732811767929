const FIRST = '1'
const SECOND = '2'
const THIRD = '3'

/* :: (number || string) -> string */
export const getNumberWithOrdinalSuffix = (number) => {
  if (!number || isNaN(number)) {
    throw Error('The number is not a valid number')
  }

  if (
    parseInt(number) === 11 ||
    parseInt(number) === 12 ||
    parseInt(number) === 13
  ) {
    return `${number}th`
  }

  const numberString = number.toString()
  const lastNumber = numberString.substr(numberString.length - 1)

  switch (lastNumber) {
    case FIRST:
      return `${number}st`
    case SECOND:
      return `${number}nd`
    case THIRD:
      return `${number}rd`
    default:
      return `${number}th`
  }
}
