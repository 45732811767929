import { useContext } from 'react'
import { partial } from 'lodash'
import { FeatureFlagsContext } from './FeatureFlagsProvider'

// Gets and sets a single feature flag
// E.g., const [value, setFlag] = useFeatureFlag(FEATURE_FLAGS.MOBILE_CREATE_ORDER_MODAL)
export const useFeatureFlag = (flag) => {
  const context = useContext(FeatureFlagsContext)
  return [context.get(flag), partial(context.set, flag)]
}
