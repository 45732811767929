import { withProps, defaultProps, compose } from 'recompose'
import TextField from 'containers/formikTextField/FormikTextField'

/**
 [1]. On error, the label is colored red as well the input + input helper text.
 This forces the default color. Opinionated style
 */
const EmailField = compose(
  withProps({ type: 'email' }),
  defaultProps({
    label: 'Email address',
    autoComplete: 'email',
    fullWidth: true,
    margin: 'normal',
    InputLabelProps: {
      error: false /* [1] */,
    },
  })
)(TextField)

/**
 [2]. @HACK: Chrome autofills previously used email and password. We don't want
 this for development as we use localhost for various projects.
 */
const PasswordField = compose(
  withProps({ type: 'password' }),
  defaultProps({
    label: 'Password',
    autoComplete:
      process.env.NODE_ENV === 'production' /* [2] */
        ? 'current-password'
        : 'new-password',
    fullWidth: true,
    margin: 'normal',
    InputLabelProps: {
      error: false /* [1] */,
    },
  })
)(TextField)

export { EmailField, PasswordField }
