import React from 'react'
import PropTypes from 'prop-types'
import Add from '@material-ui/icons/Add'
import { useTheme } from '@material-ui/styles'
import { $U, PERSON_CLIENT_TYPE, PERSON_PERMISSION } from 'emsurge-selectors'
import IconButton from 'components/iconButton/IconButton'
import { useUser } from 'containers/user/useUser'
import Fetch from 'containers/fetch/Fetch'
import Tooltip from 'components/tooltip/Tooltip'

const CreateButton = (props) => (
  <IconButton
    icon={Add}
    color="primary"
    variant="contained"
    size="small"
    style={{ margin: useTheme().spacing(0, 1) }}
    id="createOrderButton"
    {...props}
  >
    Order
  </IconButton>
)

const CreateOrderButton = ({ render: Button, ...props }) => {
  const { user } = useUser()
  const clientType = $U.clientType.get(user)

  if (clientType === PERSON_CLIENT_TYPE.TRADER) {
    return (
      <Fetch url={`/companies/${user.companyId}`}>
        {({ loading, data }) => {
          const hasWritePermission =
            data &&
            data.entities.some(({ permission }) =>
              [PERSON_PERMISSION.TRADE, PERSON_PERMISSION.WRITE].includes(
                permission
              )
            )

          return (
            <Tooltip
              title={
                hasWritePermission
                  ? ''
                  : 'Not enough permissions to create orders'
              }
            >
              <span>
                <Button disabled={loading || !hasWritePermission} {...props} />
              </span>
            </Tooltip>
          )
        }}
      </Fetch>
    )
  }

  return <Button {...props} />
}

CreateOrderButton.defaultProps = {
  render: CreateButton,
}

CreateOrderButton.propTypes = {
  render: PropTypes.func,
}

export default CreateOrderButton
