const getArrival = (terms) => {
  const deliveryWindow = terms.deliveryWindow || {}

  return deliveryWindow.arrival || {}
}

const getArrivalSummary = (terms) => {
  const arrival = getArrival(terms)
  const arrivalSummary = {}

  if (arrival.period) {
    arrivalSummary.period = arrival.period
  }

  if (arrival.window) {
    arrivalSummary.window = `${arrival.window} hour`
  }

  return arrivalSummary
}

const getArrivalText = (terms) => {
  const arrivalSummary = getArrivalSummary(terms)

  if (arrivalSummary.period && arrivalSummary.window) {
    return `Arrival Period shall be a ${arrivalSummary.window} period commencing ${arrivalSummary.period} (local time at Discharge Port using 24hr clock)`
  }

  return ''
}

module.exports = {
  get: getArrival,
  summary: getArrivalSummary,
  text: getArrivalText,
}
