import { connect } from 'formik'
import { isUndefined } from 'lodash'

const FormikFormConsumer = ({ children, formik }) => {
  const elem = children(formik)

  return isUndefined(elem) ? null : elem
}

export default connect(FormikFormConsumer)
