import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { withProps } from 'recompose'

const StyledMainTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`
const MainTitle = withProps({ variant: 'h1' })(StyledMainTitle)

const StyledFormTitle = styled(Typography)`
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`
const FormTitle = withProps({ variant: 'h3' })(StyledFormTitle)

const StyledStepTitle = styled(Typography)`
  text-align: left;
  margin: ${({ theme }) => theme.spacing(1)}px;
`
const StepTitle = withProps({ variant: 'h6' })(StyledStepTitle)

const StyledPrimaryEntityTitle = styled(Typography)`
  text-align: left;
  display: inline-block;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  padding-right: ${({ theme }) => theme.spacing(3)}px;
`
const PrimaryEntityTitle = withProps({ variant: 'h2' })(
  StyledPrimaryEntityTitle
)

const StyledFormInstructionsTitle = styled(Typography)`
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(0, 1)};
`
const FormInstructionsTitle = withProps({ variant: 'h2' })(
  StyledFormInstructionsTitle
)

export {
  MainTitle,
  FormTitle,
  StepTitle,
  FormInstructionsTitle,
  PrimaryEntityTitle,
}
