import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import Grid from '@material-ui/core/Grid'
import {
  DateRange,
  Name,
  ShipFields,
  SaveButton,
} from '../components/NominationFormFields'
import Form from 'containers/formikForm/FormikForm'
import { useApi } from 'containers/api/useApi'
import { formatDate } from 'screens/orderCreate/components/DeliveryWindow.helpers'
import { getApiErrorMessage } from 'containers/api/helpers'

const renderFormField = ({ nominationName, nominationId, timezone }) => {
  if (nominationName === 'deliveryWindow') {
    return <DateRange nominationId={nominationId} />
  }
  if (nominationName === 'loadPort') {
    return (
      <Name label="Load port" nominationId={nominationId} timezone={timezone} />
    )
  }
  if (nominationName === 'dischargePort') {
    return (
      <Name
        label="Discharge port"
        nominationId={nominationId}
        timezone={timezone}
      />
    )
  }
  if (nominationName === 'other') {
    return <Name label="Description" nominationId={nominationId} other />
  }
  if (nominationName === 'ship') {
    return <ShipFields nominationId={nominationId} />
  }
}

const getInitialValues = ({
  nominationName,
  nominationId,
  timezone,
  otherName,
}) => {
  if (nominationName === 'deliveryWindow') {
    return {
      [nominationId]: { from: formatDate(moment()), to: formatDate(moment()) },
    }
  }

  if (nominationName === 'ship') {
    return { [nominationId]: { name: '', size: '' } }
  }

  if (nominationName === 'other') {
    return { [nominationId]: { name: otherName, description: '' } }
  }

  const value = {
    [nominationId]: { name: '' },
  }

  if (!isEmpty(timezone)) {
    value[nominationId].timezone = timezone
  }

  return value
}

const getValues = (row, values) => {
  if (row.nominationName === 'ship') {
    return {
      name: values.name,
      size: {
        value: values.size,
      },
    }
  }

  return values[row.nominationId]
}

const NominationsForm = ({ rowData }) => {
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()

  /* :: object -> Promise<void> */
  const handleSubmit = async (values, actions) => {
    const data = {
      toBeNominated: false,
      ...getValues(rowData, values),
    }

    try {
      await api.patch(`/nominations/${rowData.nominationId}`, data)
      enqueueSnackbar('Nomination update successful', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Nomination update not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Form initialValues={getInitialValues(rowData)} onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ flexWrap: 'nowrap' }}>
        <Grid item xs={10}>
          {renderFormField(rowData)}
        </Grid>
        <Grid item xs style={{ alignSelf: 'center' }}>
          <SaveButton />
        </Grid>
      </Grid>
    </Form>
  )
}

NominationsForm.propTypes = {
  rowData: PropTypes.object.isRequired,
}

export default NominationsForm
