import { $U, REGISTRATION_STATUS } from 'emsurge-selectors'

export const filterByStatusNotPendingApproval = ({ status }) =>
  status !== REGISTRATION_STATUS.PENDING_APPROVAL

export const mapToUserView = (user) => {
  return {
    id: user.id,
    clientType: $U.clientType.text(user),
    name: $U.fullName.get(user),
    email: user.email,
    phone: user.phone,
    jobTitle: user.jobTitle,
    code: user.entities
      .map((entity) => `${user.company.code}${entity.code}`)
      .join(', '),
    companyName: user.company.name,
  }
}

export const sortAlphabetically = [
  (user) => user.companyName.toLowerCase(),
  (user) => user.name.toLowerCase(),
]
