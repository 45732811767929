import moment from 'moment'
import { every, isInteger } from 'lodash'
import { getStringOfValuesDeep } from 'utils/objects'

export const calculateCargoDeliverWindow = (cargo) => {
  const cargoFrom = moment(cargo.from)
  const cargoTo = moment(cargo.to)
  const cargoDaysInterval = cargoTo.diff(cargoFrom, 'days') + 1
  const cargoMonthsInterval = cargoTo
    .add(1, 'days')
    .diff(cargoFrom, 'months', true)
  const interval = isInteger(cargoMonthsInterval)
    ? cargoMonthsInterval
    : cargoDaysInterval
  const periodName = isInteger(cargoMonthsInterval) ? 'Month' : 'Day'

  return { interval, periodName }
}

export const filterArrayOfObjects = (array, filter) => {
  const searchTerms = filter.toLowerCase().split(' ')

  return array.filter((object) => {
    const searchField = getStringOfValuesDeep(object)
    return every(searchTerms, (term) => searchField.includes(term))
  })
}
