import React from 'react'
import List from '@material-ui/core/List'
import { withTheme } from '@material-ui/styles'

export default withTheme(({ theme, children, ...props }) => (
  <List
    component="nav"
    style={{ marginTop: `${theme.custom.desktop.navbar.height}px` }}
    {...props}
  >
    {children}
  </List>
))
