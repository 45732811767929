import styled from 'styled-components'

const BlockHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export default BlockHeader
