import { get, isEmpty } from 'lodash'
import {
  PROGRAM_ELIGIBILITY_PREFIX,
  CARBON_STANDARD_PREFIX,
} from 'emsurge-selectors'
import {
  getStep1Paths,
  getStep2Paths,
  getEssentialPaths,
  getStep3Paths,
  getStep4Paths,
  getStep5Paths,
} from './essentialInitialValues'
import {
  getOptionalPaths,
  getStep7Paths,
  getStep8Paths,
} from './optionalInitialValues'
import { typeSchema } from './FormSchema'
import {
  validateStep3,
  validateStep4,
  validateStep5,
  validateStep7,
  validateStep8,
} from './containers/validation'
import { getErroneousPaths } from 'screens/orderCreateCommon/helpers'
import { TAB_CARBON } from 'containers/OrderProvider'
import { useIsReactInDevelomentMode } from 'screens/orderCreateCarbon/helpers'

export const getEssentialErrors = async (formik) => {
  const essentialSteps = [
    { paths: getStep1Paths() },
    { paths: getStep2Paths(), schema: typeSchema },
    { paths: getStep3Paths(), extraValidation: validateStep3 },
    { paths: getStep4Paths(), extraValidation: validateStep4 },
    { paths: getStep5Paths(), extraValidation: validateStep5 },
  ]

  for (let step = 0; step < essentialSteps.length; step += 1) {
    const essentialStep = essentialSteps[step]
    const errorPaths = getErroneousPaths(formik.errors, essentialStep.paths)
    const schema = essentialStep.schema
    const extraValidation = essentialStep.extraValidation
    const validSchema = !schema
      ? true
      : await schema.isValid(formik.values.carbonType)
    const validExtraValidation = !extraValidation
      ? true
      : extraValidation(formik.values)
    if (!isEmpty(errorPaths) || !validSchema || !validExtraValidation) {
      return { tab: TAB_CARBON.ESSENTIAL, step }
    }
  }

  return {}
}

export const getOptionalErrors = async (formik) => {
  const optionalSteps = [
    { paths: [] },
    { paths: getStep7Paths(), extraValidation: validateStep7 },
    { paths: getStep8Paths(), extraValidation: validateStep8 },
  ]

  for (let step = 0; step < optionalSteps.length; step += 1) {
    const optionalStep = optionalSteps[step]
    const errorPaths = getErroneousPaths(formik.errors, optionalStep.paths)
    const extraValidation = optionalStep.extraValidation
    const validExtraValidation = !extraValidation
      ? true
      : extraValidation(formik.values)
    if (!isEmpty(errorPaths) || !validExtraValidation) {
      return { tab: TAB_CARBON.OPTIONAL, step }
    }
  }

  return {}
}

export const getEarliestErrorStep = async (formik) => {
  const essentialErrors = await getEssentialErrors(formik)
  const optionalErrors = await getOptionalErrors(formik)
  return {
    ...essentialErrors,
    ...optionalErrors,
  }
}

export const essentialHasErrors = ({ errors }) => {
  const PATHS = [
    ...getEssentialPaths(),
    PROGRAM_ELIGIBILITY_PREFIX,
    CARBON_STANDARD_PREFIX,
  ]
  const result = PATHS.filter((path) => !isEmpty(get(errors, path))).length > 0
  if( result && useIsReactInDevelomentMode ) console.debug(`There are essential validation errors:`, errors)
  return result
}

export const optionalHasErrors = ({ errors }) => {
  const PATHS = [...getOptionalPaths()]
  const result = PATHS.filter((path) => !isEmpty(get(errors, path))).length > 0
  if( result && useIsReactInDevelomentMode  ) console.debug(`There are optional validation errors:`, errors)
  return result
}
