import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import { ADMIN_VIEW_COMPANY } from 'routes'
import { getFormSchema } from 'screens/entityCreate/containers/FormProvider'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const FormProvider = ({
  history,
  companyId,
  enqueueSnackbar,
  initialValues,
  children,
}) => {
  const api = useApi()

  /* :: (object, object) -> Promise<void> */
  const handleSubmit = async (values, form) => {
    const data = {
      companyId,
      ...values.info,
      pointOfContact: values.primaryContact,
      authorisedSignatory: values.signatory,
      persons: initialValues.persons.map(({ id, role, isAdmin }) => ({
        personId: id,
        role,
        isAdmin,
      })),
    }

    const { id: entityId } = initialValues.info

    try {
      await api.put(`/entities/${entityId}`, data)
      enqueueSnackbar('Entity updated successful', { variant: 'success' })
      history.push(`${ADMIN_VIEW_COMPANY.replace(':id', companyId)}`)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Entity update was not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      form.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getFormSchema(initialValues)}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}

FormProvider.propTypes = {
  companyId: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}

export default compose(withRouter, withSnackbar)(FormProvider)
