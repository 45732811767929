import { withProps } from 'recompose'
import countryList from 'country-list'
import TextField from 'containers/formikTextField/FormikTextField'
import AutoComplete from 'containers/formikAutocomplete/AutoComplete'

const defaultProps = {
  required: true,
  type: 'text',
  fullWidth: true,
  margin: 'normal',
  InputLabelProps: {
    error: false,
  },
}

const Name = withProps({
  ...defaultProps,
  name: 'name',
  id: 'name',
  label: 'Company Brand Name',
  autoComplete: 'organization',
})(TextField)

const ParentCompany = withProps({
  ...defaultProps,
  name: 'parentCompany',
  id: 'parentCompany',
  label: 'Parent Company Entity',
  autoComplete: 'organization',
})(TextField)

const RegistrationNumber = withProps({
  ...defaultProps,
  name: 'registrationNumber',
  id: 'registrationNumber',
  label: 'Registration Number',
  autoComplete: 'off',
})(TextField)

const Code = withProps({
  ...defaultProps,
  name: 'code',
  id: 'code',
  label: 'Company Code (Pick a unique 4 letter company identifier)',
  autoComplete: 'off',
  inputProps: {
    maxLength: 4,
    style: { textTransform: 'uppercase' },
  },
})(TextField)

const AddressFirstLine = withProps({
  ...defaultProps,
  name: 'addressFirstLine',
  id: 'addressFirstLine',
  label: 'Address First Line',
  autoComplete: 'address-line1',
})(TextField)

const AddressSecondLine = withProps({
  ...defaultProps,
  required: false,
  name: 'addressSecondLine',
  id: 'addressSecondLine',
  label: 'Address Second Line',
  autoComplete: 'address-line2',
})(TextField)

const Postcode = withProps({
  ...defaultProps,
  name: 'postcode',
  id: 'postcode',
  label: 'Postcode',
  autoComplete: 'postal-code',
})(TextField)

const City = withProps({
  ...defaultProps,
  name: 'city',
  id: 'city',
  label: 'City',
  autoComplete: 'address-level2',
})(TextField)

const Country = withProps({
  ...defaultProps,
  name: 'country',
  id: 'country',
  label: 'Country',
  suggestions: countryList.getNames(),
  inputProps: {
    autoComplete: 'disabled',
  },
})(AutoComplete)

const CompanyType = withProps({
  ...defaultProps,
  select: false,
  name: 'type',
  label: 'Company Type',
  value: 'trader',
  inputProps: {
    readOnly: true,
    style: { textTransform: 'capitalize' },
  },
})(TextField)

export {
  Name,
  ParentCompany,
  RegistrationNumber,
  AddressFirstLine,
  AddressSecondLine,
  Postcode,
  Code,
  City,
  Country,
  CompanyType,
}
