import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { $O } from 'emsurge-selectors/models/order'
import { SmallText } from 'components/Text'

const useStyles = makeStyles(() => ({
  withTextOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export const MainPrice = ({ order, fixed = false, ...props }) => {
  const classes = useStyles()
  const summary = $O.price.summary(order)

  if (!$O.price.basic.exists(order)) {
    return null
  }

  return (
    <SmallText {...props} className={classes.withTextOverflow}>
      {fixed ? summary.fixed : summary.short}
    </SmallText>
  )
}

MainPrice.propTypes = {
  order: PropTypes.object.isRequired,
  fixed: PropTypes.bool,
}
