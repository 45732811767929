const {
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS,
  PROJECT_SPECIFICATION_STORAGE_TYPE_OPTIONS,
} = require('../../constants')

const getProjectSpecification = (order) => order.projectSpecification || {}

const getProjectSpecificationText = (order) => {
  const { projects, keywords, sectoralScopes, selectedScopes } =
    getProjectSpecification(order)
  return {
    projects: projects.map(({ sectoralScope, storageType, ...props }) => {
      const scope = PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS[sectoralScope]
      const type = PROJECT_SPECIFICATION_STORAGE_TYPE_OPTIONS[storageType]
      return {
        ...props,
        ...(scope ? { sectoralScope: scope } : {}),
        ...(type ? { storageType: type } : {}),
      }
    }),
    keywords,
    sectoralScopes,
    selectedScopes,
  }
}

module.exports = {
  get: getProjectSpecification,
  text: getProjectSpecificationText,
}
