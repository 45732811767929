import { pick, partialRight, mapValues, isNil } from 'lodash'

/* :: object -> object */
const mapNullVals = partialRight(mapValues, (v) => (isNil(v) ? '' : v))

/* :: object -> object */
export const getInitialFormValues = (entity) => {
  const signatory = mapNullVals(entity.authorisedSignatory)
  const primaryContact = mapNullVals(entity.pointOfContact)
  const info = pick(mapNullVals(entity), [
    'id',
    'name',
    'registrationName',
    'registrationNumber',
    'registrationNumber',
    'code',
    'addressFirstLine',
    'addressSecondLine',
    'postcode',
    'city',
    'country',
    'type',
  ])

  const { persons } = entity

  return { info, primaryContact, signatory, persons }
}
