import { uniq, values, flatten, get } from 'lodash'
import { DELIVERY_REGION } from 'emsurge-selectors'
import { type } from './type'
import { DELIVERY_TYPES } from 'model/order/constants/cargo'

export const PORT_LIST = uniq(flatten(values(DELIVERY_REGION)))

/* :: object -> boolean */
const hasDeliveryPort = (order) =>
  PORT_LIST.includes(get(order, 'delivery.location'))

/* :: object -> string */
const getDeliveryPort = (order) => {
  if (!hasDeliveryPort(order)) {
    throw new Error('Order has no delivery port')
  }

  return order.delivery.location
}

export const getPortBaseName = (order) => {
  if (type.get(order) === DELIVERY_TYPES.FOB) {
    return get(order, 'nominationRules.loadPort.base.name')
  }
  return get(order, 'nominationRules.dischargePort.base.name')
}

export const port = {
  exists: hasDeliveryPort,
  get: getDeliveryPort,
  getPortBaseName,
}
