import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { SummaryBlock } from './SummaryBlock'
import { writeToClipboard } from 'utils/clipboard'
import { useIsMobile } from 'components/Viewport/Mobile'

const Superscript = styled.sup`
  ${({ small }) => `
    font-size: ${small ? 10 : 11}px;
  `}
`

const FormattedPriceContainer = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
`

const FormattedPriceFragment = styled.span`
  white-space: pre-wrap;
`

const useStyles = makeStyles((theme) => ({
  text1: {
    fontWeight: 700,
    fontSize: (isMobile) => (isMobile ? 19 : 21),
    lineHeight: 25 / 21,
    textTransform: 'uppercase',
  },
  text2: {
    fontSize: (isMobile) => (isMobile ? 14 : 16),
    fontWeight: 700,
    lineHeight: 19 / 16,
  },
  text3: {
    color: theme.palette.primary.main,
    fontSize: (isMobile) => (isMobile ? 12 : 14),
    fontWeight: 700,
    lineHeight: 18 / 14,
  },
  text4: {
    color: theme.palette.secondary.main,
    fontSize: (isMobile) => (isMobile ? 15 : 17),
    fontWeight: 700,
    lineHeight: 18 / 14,
  },
  hoverable: {
    cursor: 'pointer',
  },
  copyOrderIcon: {
    color: theme.palette.primary.main,
    fontSize: (isMobile) => (isMobile ? 14 : 12),
    marginLeft: theme.spacing(1),
  },
}))

const useClasses = () => {
  const isMobile = useIsMobile()
  const classes = useStyles(isMobile)

  return classes
}

export const TradingType = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Trading Type',
        placement: 'top',
      }}
      textProps={{
        'data-testid': 'order-summary-trading-type',
        classes: {
          root: classes.text1,
        },
      }}
      value={value}
    />
  )
}

TradingType.defaultProps = {
  value: '',
}

TradingType.propTypes = {
  value: PropTypes.string,
}

export const DeliveryType = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Delivery Type',
        placement: 'top',
      }}
      textProps={{
        'data-testid': 'order-summary-delivery-type',
        classes: {
          root: classes.text1,
        },
      }}
      value={value}
    />
  )
}

DeliveryType.defaultProps = {
  value: '',
}

DeliveryType.propTypes = {
  value: PropTypes.string,
}

export const DeliveryPort = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Delivery Port',
        placement: 'top',
      }}
      textProps={{
        'data-testid': 'order-summary-delivery-port',
        classes: {
          root: classes.text1,
        },
      }}
      value={value}
    />
  )
}

DeliveryPort.defaultProps = {
  value: '',
}

DeliveryPort.propTypes = {
  value: PropTypes.string,
}

export const DeliveryPeriod = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Delivery Period',
        placement: 'top',
      }}
      textProps={{
        'data-testid': 'order-summary-delivery-period',
        noWrap: true,
        classes: {
          root: classes.text1,
        },
      }}
      value={value}
    />
  )
}

DeliveryPeriod.defaultProps = {
  value: '',
}

DeliveryPeriod.propTypes = {
  value: PropTypes.string,
}

export const DeliveryCargoes = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Delivery Cargoes',
        placement: 'top',
      }}
      textProps={{
        'data-testid': 'order-summary-delivery-window',
        noWrap: true,
        classes: {
          root: classes.text1,
        },
      }}
      value={value}
    />
  )
}

DeliveryCargoes.defaultProps = {
  value: '',
}

DeliveryCargoes.propTypes = {
  value: PropTypes.string,
}

export const Price = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Basic Price',
        placement: 'left',
      }}
      textProps={{
        'data-testid': 'order-summary-basic-price',
        classes: {
          root: classes.text2,
        },
      }}
      value={value}
    />
  )
}

Price.defaultProps = {
  value: '',
}

Price.propTypes = {
  value: PropTypes.string,
}

export const FormattedPrice = ({ price }) => (
  <FormattedPriceContainer>
    {price.split(', ').map((fragment, index, { length }) => (
      <FormattedPriceFragment key={index}>
        {fragment}
        {index + 1 !== length ? ', ' : ''}
      </FormattedPriceFragment>
    ))}
  </FormattedPriceContainer>
)

FormattedPrice.propTypes = {
  price: PropTypes.string,
}

export const ImpliedPrice = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Implied Price',
        placement: 'left',
      }}
      textProps={{
        'data-testid': 'order-summary-implied-price',
        display: 'block',
        classes: {
          root: classes.text4,
        },
      }}
      value={<FormattedPrice price={value} />}
    />
  )
}

ImpliedPrice.defaultProps = {
  value: '',
}

ImpliedPrice.propTypes = {
  value: PropTypes.string,
}

export const NumberOfCargoes = ({ value, proRataLabel }) => {
  const isMobile = useIsMobile()
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Number of cargoes',
        placement: 'left',
      }}
      textProps={{
        'data-testid': 'order-summary-number-of-cargoes',
        noWrap: true,
        classes: {
          root: classes.text2,
        },
      }}
      value={
        <>
          {value}
          <Superscript small={isMobile} noWrap>
            {proRataLabel}
          </Superscript>
        </>
      }
    />
  )
}

NumberOfCargoes.defaultProps = {
  value: '',
  proRataLabel: '',
}

NumberOfCargoes.propTypes = {
  value: PropTypes.string,
  proRataLabel: PropTypes.string,
}

export const CargoSize = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Cargo size',
        placement: 'bottom',
      }}
      textProps={{
        'data-testid': 'order-summary-cargo-size',
        classes: {
          root: classes.text2,
        },
      }}
      value={value}
    />
  )
}

CargoSize.defaultProps = {
  value: '',
}

CargoSize.propTypes = {
  value: PropTypes.string,
}

export const CargoSizeVariance = ({ value, cargoSizeVarianceParty }) => {
  const isMobile = useIsMobile()
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: `${cargoSizeVarianceParty}'s Operational Tolerance`,
        placement: 'bottom',
      }}
      textProps={{
        'data-testid': 'order-summary-operational-tolerance',
        classes: {
          root: classes.text2,
        },
      }}
      value={<Superscript small={isMobile}>{value}</Superscript>}
    />
  )
}

CargoSizeVariance.defaultProps = {
  value: '',
  cargoSizeVarianceParty: '',
}

CargoSizeVariance.propTypes = {
  value: PropTypes.string,
  cargoSizeVarianceParty: PropTypes.string,
}

export const QualityGHV = ({ value, qualityPermittedLabel }) => {
  const isMobile = useIsMobile()
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        'data-testid': 'order-summary-quality-ghv',
        title: 'Quality GHV',
        placement: 'bottom',
      }}
      textProps={{
        classes: {
          root: classes.text2,
        },
      }}
      value={
        <>
          {value}
          {qualityPermittedLabel && (
            <Superscript
              small={isMobile}
            >{` ${qualityPermittedLabel}`}</Superscript>
          )}
        </>
      }
    />
  )
}

QualityGHV.defaultProps = {
  value: '',
  qualityPermittedLabel: '',
}

QualityGHV.propTypes = {
  value: PropTypes.string,
  qualityPermittedLabel: PropTypes.string,
}

export const OrderId = ({ value, shortId, label }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useClasses()

  const copyToClipboard = async (text, successMessage) => {
    const response = await writeToClipboard(text, successMessage)
    enqueueSnackbar(response.message, {
      variant: response.error ? 'error' : 'success',
    })
  }

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'ID',
        placement: 'bottom',
      }}
      textProps={{
        'data-testid': 'order-short-id',
        className: classes.hoverable,
        classes: {
          root: classes.text3,
        },
        onClick: () =>
          copyToClipboard(shortId, `${label} ID copied to clipboard.`),
      }}
      value={
        <>
          {`${label} ${value.toUpperCase()}`}
          <FileCopyIcon classes={{ root: classes.copyOrderIcon }} />
        </>
      }
    />
  )
}

OrderId.defaultProps = {
  value: '',
  shortId: '',
  label: '',
}

OrderId.propTypes = {
  value: PropTypes.string,
  shortId: PropTypes.string,
  label: PropTypes.string,
}

const Exports = {
  TradingType,
  DeliveryType,
  DeliveryPort,
  DeliveryPeriod,
  DeliveryCargoes,
  Price,
  ImpliedPrice,
  NumberOfCargoes,
  CargoSize,
  CargoSizeVariance,
  QualityGHV,
  OrderId,
}

export default Exports
