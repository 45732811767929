import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { omit } from 'lodash'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

export const useEditOrder = () => {
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()

  const putOrder = async (data) =>
    api.editOrder(data.id, omit(data, ['id', 'comments']))

  const onSuccess = () =>
    enqueueSnackbar('Order updated successfully', { variant: 'success' })

  const onError = (error) => {
    const message = getApiErrorMessage({
      error,
      defaultMessage: 'Order update failed',
    })
    enqueueSnackbar(message, { variant: 'error' })
  }

  return useMutation(putOrder, {
    onSuccess,
    onError,
    throwOnError: true,
  })
}
