const { PROGRAM_ELIGIBILITY_PREFIX } = require('./carbonPrefix')

const PROGRAM_ELIGIBILITY_KEYS = Object.freeze({
  CHINAETS: 'CHINAETS',
  CORSIA: 'CORSIA',
  CCP: 'CCP',
  Other: 'Other',
})

const PROGRAM_ELIGIBILITY_VALUES = Object.freeze({
  [PROGRAM_ELIGIBILITY_KEYS.CHINAETS]: 'China ETS',
  [PROGRAM_ELIGIBILITY_KEYS.CORSIA]: 'CORSIA',
  [PROGRAM_ELIGIBILITY_KEYS.CCP]: 'CCP',
  [PROGRAM_ELIGIBILITY_KEYS.Other]: 'Other',
})

const PROGRAM_ELIGIBILITY_FULL_KEYS = {}
Object.keys(PROGRAM_ELIGIBILITY_KEYS).forEach((key) => {
  PROGRAM_ELIGIBILITY_FULL_KEYS[key] = `${PROGRAM_ELIGIBILITY_PREFIX}.${key}`
})

const PROGRAM_ELIGIBILITY_PATHS = Object.values(PROGRAM_ELIGIBILITY_FULL_KEYS)

const PROGRAM_ELIGIBILITY_OPTIONS = Object.values(PROGRAM_ELIGIBILITY_KEYS).map(
  (programEligibility) => {
    const option = {
      label: PROGRAM_ELIGIBILITY_VALUES[programEligibility],
      value: PROGRAM_ELIGIBILITY_FULL_KEYS[programEligibility],
    }
    if (programEligibility === PROGRAM_ELIGIBILITY_KEYS.Other) {
      option.extra = {
        field: `${PROGRAM_ELIGIBILITY_PREFIX}.otherContent`,
        placeholder: 'Programme Eligibility',
      }
    }
    return option
  }
)

module.exports = {
  PROGRAM_ELIGIBILITY_KEYS,
  PROGRAM_ELIGIBILITY_PATHS,
  PROGRAM_ELIGIBILITY_OPTIONS,
  PROGRAM_ELIGIBILITY_FULL_KEYS,
  PROGRAM_ELIGIBILITY_VALUES,
}
