import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { pick, defaults, isNull, omitBy } from 'lodash'
import { HOME } from 'routes'
import CompanyForm from 'screens/companyCreate/companyForm/CompanyForm'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { withApi, ApiType } from 'containers/api/withApi'
import { getApiErrorMessage } from 'containers/api/helpers'

class EditCompany extends Component {
  static propTypes = {
    api: ApiType,
    history: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  state = {
    loading: false,
    error: null,
    companyAdmin: undefined,
    company: undefined,
    companyPrimaryContact: undefined,
    entity: undefined,
  }

  componentDidMount() {
    this.fetchCompany()
  }

  getCompanyId() {
    return this.props.match.params.id
  }

  async fetchCompany() {
    const { api } = this.props
    this.setState({ loading: true, error: null })

    const companyId = this.getCompanyId()
    try {
      const { data: company } = await api.get(`/companies/${companyId}`)
      const initialValues = this.getFormDataFromCompany(company)
      this.setState({
        loading: false,
        ...initialValues,
      })
    } catch (e) {
      this.setState({ loading: false, error: e })
    }
  }

  getFormDataFromCompany(companyObj) {
    const { persons, pointOfContact, entities } = companyObj

    const company = pick(companyObj, [
      'name',
      'parentCompany',
      'registrationNumber',
      'addressFirstLine',
      'addressSecondLine',
      'postcode',
      'city',
      'country',
      'code',
      'type',
    ])

    const admin = persons.find(
      (person) =>
        person.adminRole === 'company' && person.companyId === companyObj.id
    )
    const companyAdmin = pick(admin, [
      'name',
      'surname',
      'email',
      'phone',
      'jobTitle',
    ])

    const entity = pick(entities[0], [
      'name',
      'registrationName',
      'registrationNumber',
      'addressFirstLine',
      'addressSecondLine',
      'postcode',
      'city',
      'country',
      'authorisedSignatory',
      'pointOfContact',
      'code',
      'type',
    ])

    const addDefaultAddressSecondLine = (obj) =>
      defaults(omitBy(obj, isNull), { addressSecondLine: '' })

    return {
      company: addDefaultAddressSecondLine(company),
      companyAdmin,
      companyPrimaryContact: addDefaultAddressSecondLine(pointOfContact),
      entity: {
        ...addDefaultAddressSecondLine(entity),
        pointOfContact: addDefaultAddressSecondLine(entity.pointOfContact),
      },
    }
  }

  handleFinish = async ({ company, companyAdmin, entity }) => {
    const { history, enqueueSnackbar, api } = this.props
    const companyId = this.getCompanyId()

    const data = {
      companyAdmin,
      company,
      entity,
    }

    try {
      await api.put(`/registration/companies/${companyId}`, data)
      enqueueSnackbar('Company update successful', { variant: 'success' })
      history.push(HOME)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Company update not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  render() {
    const { companyAdmin, company, companyPrimaryContact, entity, loading } =
      this.state
    const companyLoaded = !loading && company !== undefined
    return companyLoaded ? (
      <CompanyForm
        title="Edit Company"
        subTitle="This information can be changed at later date"
        companyAdmin={companyAdmin}
        company={company}
        companyPrimaryContact={companyPrimaryContact}
        entity={entity}
        onSubmit={this.handleFinish}
      />
    ) : (
      <LoadingSpinner />
    )
  }
}

export default compose(withSnackbar, withApi)(EditCompany)
