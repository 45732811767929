import React, { useCallback, useEffect } from 'react'
import { isEmpty, first } from 'lodash'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { TextField } from './TextField'
import { isMyCompanyOrder } from 'screens/orderCreateCommon/helpers'
import { useUser } from 'containers/user/useUser'
import {
  useOrderFlow,
  ORDER_FLOW,
  useOrderState,
} from 'containers/OrderProvider'

export const _MyCompanyEntitiesSelect = ({ formik, company }) => {
  const { flow } = useOrderFlow()
  const { user } = useUser()
  const setFieldValue = useCallback(formik.setFieldValue, [])
  const {
    orderState: { hasTermsheets, brokerEditsSharedOrder },
  } = useOrderState()
  const disabled =
    (hasTermsheets && flow === ORDER_FLOW.EDIT) || brokerEditsSharedOrder

  useEffect(() => {
    const entityId = formik.values.myCompany.entityId
    if (entityId) {
      setFieldValue(
        'myCompany.entity',
        company.entities.find(({ id }) => id === entityId)
      )
    }
  }, [
    company.entities,
    formik.values.myCompany.entity,
    formik.values.myCompany.entityId,
    setFieldValue,
  ])

  useEffect(() => {
    const shouldSetEntity =
      !formik.values.myCompany || isEmpty(formik.values.myCompany.entityId)

    if (shouldSetEntity) {
      const entitiesPermissioned = company.entities.filter(
        ({ permission }) => permission === 'write' || permission === 'trade'
      )
      const initialSelectedEntity = first(entitiesPermissioned).id

      setFieldValue('myCompany.entityId', initialSelectedEntity)
      setFieldValue('myCompany.company', user.company)
      setFieldValue('myCompany.trader', user)
    }
  }, [company, formik.values.myCompany, setFieldValue, user])

  if (!isMyCompanyOrder(formik.values)) {
    return null
  }

  const onEntityChanged = (evt) => {
    const entityId = evt.target.value
    formik.setFieldValue('myCompany.entityId', entityId)
  }

  return (
    <TextField
      InputProps={{ 'data-testid': 'my-company-entity-dropdown' }}
      disabled={disabled}
      name="myCompany.entityId"
      onChange={onEntityChanged}
      label="Entity"
      select
      required
    >
      {company.entities
        .filter(
          ({ permission }) => permission === 'write' || permission === 'trade'
        )
        .map(({ id, name }) => (
          <MenuItem
            data-testid="my-company-entity-dropdown-values"
            key={id}
            value={id}
          >
            {name}
          </MenuItem>
        ))}
    </TextField>
  )
}

_MyCompanyEntitiesSelect.defaultProps = {
  formik: {},
}

_MyCompanyEntitiesSelect.propTypes = {
  formik: PropTypes.object,
  company: PropTypes.object.isRequired,
}

export const MyCompanyEntitiesSelect = connect(_MyCompanyEntitiesSelect)
