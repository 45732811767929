import React from 'react'
import Header from './components/Header'
import Block from './components/Block'
import PendingApprovalTable from './components/PendingApprovalTable'
import OnboardedTable from './components/OnboardedTable'
import ApplicationStartedTable from './components/ApplicationStartedTable'
import Fetch from 'containers/fetchPlus/FetchPlus'

/* :: object[] -> object[] */
const getByStatus = (statusToFind, companies) =>
  companies.filter(({ status }) => status === statusToFind)

const ViewCompanies = () => (
  <>
    <Fetch url="/companies">
      {(companies) => (
        <>
          <Header />
          <Block title="Application Started">
            <ApplicationStartedTable
              companies={getByStatus('application_started', companies)}
            />
          </Block>
          <Block title="Pending Approval">
            <PendingApprovalTable
              companies={getByStatus('pending_approval', companies)}
            />
          </Block>
          <Block title="Onboarded">
            <OnboardedTable companies={getByStatus('active', companies)} />
          </Block>
        </>
      )}
    </Fetch>
  </>
)

export default ViewCompanies
