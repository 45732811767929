import { get, values } from 'lodash'
import { CARGO_SIZE_UNIT } from 'emsurge-selectors/constants'

/* :: object -> boolean */
const hasCargoSize = (order) => {
  const type = get(order, 'cargoSize.type')
  const unit = get(order, 'cargoSize.unit')

  if (!values(CARGO_SIZE_UNIT).includes(unit)) {
    return false
  }

  if (type === 'standard') {
    return true
  }

  const min = parseFloat(get(order, 'cargoSize.sizeMin'))
  return Number.isFinite(min)
}

/* :: object -> object */
const getCargoSize = (order) => {
  if (!hasCargoSize(order)) {
    throw new Error('Order has no cargo size. Use order.cargo.size.exists()')
  }

  const { unit, sizeMin, sizeMax, type } = order.cargoSize

  if (type === 'standard') {
    return { value: 'standard', unit }
  }

  const min = parseFloat(sizeMin)
  const max = parseFloat(sizeMax)
  if (max > min) {
    return {
      unit,
      value: [min, max],
    }
  }

  return { value: min, unit }
}

/* :: object -> boolean */
const isCargoSizeRange = (order) => Array.isArray(getCargoSize(order).value)

export const size = {
  exists: hasCargoSize,
  get: getCargoSize,
  isRange: isCargoSizeRange,
}
