/* eslint-disable */
import React, {Fragment , useState, useEffect } from 'react';
import {AppInsightsErrorBoundary, AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import { createTelemetryService } from './TelemetryService'
import { ErrorView } from 'screens/errorView'

const ReloadButton = () => (
  <Button
    onClick={() => window.location.reload()}
    variant="contained"
    color="primary"
  >
    Reload now
  </Button>
)

const TelemetryProvider = ({ children, appInsightsConnectionString}) => {
    const [reactPlugin, setReactPlugin] = useState()

    const history = useHistory()
    if (!history) {
        throw new Error('History is null')
    }
    if (!appInsightsConnectionString) {
        throw new Error('appInsightsConnectionString is null')
    }

    if (!reactPlugin) {
        const reactPluginInstance = createTelemetryService(appInsightsConnectionString, history);
        setReactPlugin(reactPluginInstance);
    }

    const errorHandler = (e) => {
        console.error(e)
        return <ErrorView>
            <ReloadButton />
          </ErrorView>
    }

    return <AppInsightsErrorBoundary onError={errorHandler} appInsights={reactPlugin}>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <Fragment>
                        {children}
                    </Fragment>  
                </AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>
}

TelemetryProvider.propTypes = {
    children: PropTypes.node,
    appInsightsConnectionString: PropTypes.string
}

export default TelemetryProvider
