import React from 'react'
import { connect } from 'formik'
import { Grid } from '@material-ui/core'
import { VOLUME_RANGE_PREFIX } from 'emsurge-selectors'
import { VolumeInput } from '../components/VolumeInput'
import { VolumeOffset } from '../components/VolumeOffset'

const _VolumePerAnnum = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <VolumeInput
          name={`${VOLUME_RANGE_PREFIX}.first`}
          label="First Year"
          unit="unit"
        />
      </Grid>
      <Grid item xs={6}>
        <VolumeInput
          name={`${VOLUME_RANGE_PREFIX}.last`}
          label="Last Year"
          unit="unit"
        />
      </Grid>
      <Grid item xs={12}>
        <VolumeOffset />
      </Grid>
    </Grid>
  )
}

export const VolumePerAnnum = connect(_VolumePerAnnum)
