export const COLUMNS = [
  {
    title: 'Company',
    field: 'companyName',
  },
  {
    title: 'Entity Code',
    field: 'code',
  },
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Phone',
    field: 'phone',
  },
  {
    title: 'Job Title',
    field: 'jobTitle',
  },
]

export const NO_TRADERS_TEXT = 'There are no traders in the system'
