import { withProps } from 'recompose'
import TextField from 'containers/formikTextField/FormikTextField'

const defaultProps = {
  required: true,
  type: 'text',
  fullWidth: true,
  margin: 'normal',
  InputLabelProps: {
    error: false,
  },
}

const Name = withProps({
  ...defaultProps,
  name: 'name',
  id: 'name',
  label: 'Name',
  autoComplete: 'given-name',
})(TextField)

const Surname = withProps({
  ...defaultProps,
  name: 'surname',
  id: 'surname',
  label: 'Surname',
  autoComplete: 'family-name',
})(TextField)

const JobTitle = withProps({
  ...defaultProps,
  name: 'jobTitle',
  id: 'jobTitle',
  label: 'Job Title',
  autoComplete: 'organization-title',
})(TextField)

const Email = withProps({
  ...defaultProps,
  name: 'email',
  type: 'email',
  id: 'email',
  label: 'Email',
  autoComplete: 'email',
})(TextField)

const Phone = withProps({
  ...defaultProps,
  name: 'phone',
  id: 'phone',
  label: 'Telephone Number',
  autoComplete: 'tel',
})(TextField)

export { Name, Surname, Email, JobTitle, Phone }
