const TITLE_TRANSFER_KEYS = {
  sellerRetires: 'sellerRetires',
  sellerTransfers: 'sellerTransfers',
  sellerRetireTransfer: 'sellerRetireTransfer',
}

const TITLE_TRANSFER_ATTRIBUTES = {
  [TITLE_TRANSFER_KEYS.sellerRetires]: {
    key: TITLE_TRANSFER_KEYS.sellerRetires,
    value: 'Seller Retires',
  },
  [TITLE_TRANSFER_KEYS.sellerTransfers]: {
    key: TITLE_TRANSFER_KEYS.sellerTransfers,
    value: 'Seller Transfers',
  },
  [TITLE_TRANSFER_KEYS.sellerRetireTransfer]: {
    key: TITLE_TRANSFER_KEYS.sellerRetireTransfer,
    value: 'Seller can Retire or Transfer',
  },
}

const TITLE_TRANSFER = [
  {
    value: TITLE_TRANSFER_KEYS.sellerRetires,
    label: TITLE_TRANSFER_ATTRIBUTES.sellerRetires.value,
  },
  {
    value: TITLE_TRANSFER_KEYS.sellerTransfers,
    label: TITLE_TRANSFER_ATTRIBUTES.sellerTransfers.value,
  },
  {
    value: TITLE_TRANSFER_KEYS.sellerRetireTransfer,
    label: TITLE_TRANSFER_ATTRIBUTES.sellerRetireTransfer.value,
  },
]

module.exports = {
  TITLE_TRANSFER_ATTRIBUTES,
  TITLE_TRANSFER_KEYS,
  TITLE_TRANSFER,
}
