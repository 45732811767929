const { PRICE_VOLUME_DEPENDENT_PREFIX } = require('./carbonPrefix')
const CURRENCIES = require('./currencies.json')

const PRICE_VOLUME_DEPENDENT_TYPE_KEYS = Object.freeze({
  UP_TO: { key: 'UP_TO', value: 'Up to' },
  FROM: { key: 'FROM', value: 'From' },
  ABOVE: { key: 'ABOVE', value: 'Above' },
})

const PRICE_VOLUME_DEPENDENT_NO_RANGE = [
  PRICE_VOLUME_DEPENDENT_TYPE_KEYS.UP_TO.key,
  PRICE_VOLUME_DEPENDENT_TYPE_KEYS.ABOVE.key,
]

const PRICE_VOLUME_DEPENDENT_TYPE = [
  {
    key: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.UP_TO.key,
    value: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.UP_TO.key,
    label: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.UP_TO.value,
  },
  {
    key: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.FROM.key,
    value: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.FROM.key,
    label: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.FROM.value,
  },
  {
    key: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.ABOVE.key,
    value: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.ABOVE.key,
    label: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.ABOVE.value,
  },
]

const PRICE_CURRENCY_OPTIONS = Object.values(CURRENCIES).map(
  ({ symbol, code }) => {
    return {
      key: code,
      value: code,
      label: symbol,
    }
  }
)

const PRICE_CURRENCY_KEYS = Object.keys(CURRENCIES)

const getPriceVolumeDependentCommonPath = (itemNr, type) =>
  `${PRICE_VOLUME_DEPENDENT_PREFIX}.${itemNr}.${type}`
const getPriceVolumeDependentTypePath = (itemNr) =>
  getPriceVolumeDependentCommonPath(itemNr, 'type')
const getPriceVolumeDependentQuantityPath = (itemNr) =>
  getPriceVolumeDependentCommonPath(itemNr, 'quantity')
const getPriceVolumeDependentQuantityFromPath = (itemNr) =>
  getPriceVolumeDependentCommonPath(itemNr, 'quantityFrom')
const getPriceVolumeDependentQuantityToPath = (itemNr) =>
  getPriceVolumeDependentCommonPath(itemNr, 'quantityTo')
const getPriceVolumeDependentPricePath = (itemNr) =>
  getPriceVolumeDependentCommonPath(itemNr, 'price')
const getPriceVolumeDependentCurrencyPath = (itemNr) =>
  getPriceVolumeDependentCommonPath(itemNr, 'currency')
const getCurrencyByKey = (key) =>
  Object.values(CURRENCIES).find(({ code }) => code === key)

module.exports = {
  PRICE_VOLUME_DEPENDENT_TYPE,
  PRICE_VOLUME_DEPENDENT_TYPE_KEYS,
  PRICE_VOLUME_DEPENDENT_NO_RANGE,
  PRICE_CURRENCY_OPTIONS,
  PRICE_CURRENCY_KEYS,
  getPriceVolumeDependentTypePath,
  getPriceVolumeDependentQuantityPath,
  getPriceVolumeDependentQuantityFromPath,
  getPriceVolumeDependentQuantityToPath,
  getPriceVolumeDependentPricePath,
  getPriceVolumeDependentCurrencyPath,
  getCurrencyByKey,
}
