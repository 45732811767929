const bulletin = require('./bulletin')
const author = require('./author')
const company = require('./company')

module.exports = {
  $B: {
    ...bulletin,
    author,
    company,
  },
}
