import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { Collapse, IconButton } from '@material-ui/core'
import Equalizer from '@material-ui/icons/Equalizer'

import {
  ORDER_STATUS,
  DELIVERY_WINDOW_TYPE_TEXT,
  PRICE_INDEX,
} from 'emsurge-selectors/constants'
import { $O, $T } from 'emsurge-selectors'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import SummaryRow from './SummaryRow'
import CompanyCode from 'components/CompanyCode/CompanyCode'
import CompanyCodeTermsheet from 'components/CompanyCode/CompanyCodeTermsheet'
import { isTermsheet } from 'model/termsheet/isTermsheet'
import { MARKET_VISUALISATION_TIMELINE } from 'routes'
import { getTimelinePropsFromOrder } from 'screens/marketVisualisation/MarketVisualisationTimeline/MarketVisualisationTimeline.utils'
import { STATUS } from 'components/StatusFilters'
import { useIsMobile } from 'components/Viewport/Mobile'
import { GridArea, HEADER_AREAS } from 'components/ModalHeader/layout'
import { useImpliedPrice } from 'hooks'

export const OrderSummary = ({ order, expanded, isCreatingTrade, isTermSheetSummary }) => {
  const { spacing, palette } = useTheme()
  const { typeName, period } = getTimelinePropsFromOrder(order)
  const isMobile = useIsMobile()
  const impliedPrices = useImpliedPrice(order, [
    PRICE_INDEX.NBP,
    PRICE_INDEX.TTF,
    PRICE_INDEX.JKM,
    PRICE_INDEX.HH,
  ])

  const gutterX = spacing(1)
  const styles = {
    text1: {
      fontWeight: 700,
      fontSize: isMobile ? 19 : 21,
      lineHeight: 25 / 21,
      textTransform: 'uppercase',
    },
  }
  const {
    tradingType,
    deliveryType,
    deliveryLocation,
    deliveryPeriod,
    deliveryWindowShortLabel,
    price,
    cargoSize,
    cargoSizeVariance,
    id,
  } = $O.summary(order)
  const quality = $O.quality.value.text(order)
  const proRataLabel = $T.deliveryWindow.isProRata.get(order.nominationRules)
    ? DELIVERY_WINDOW_TYPE_TEXT.PRO_RATA
    : ''
  const { min: deliveryCargoMin, max: deliveryCargoMax } =
    $O.delivery.cargo.get(order)
  const cargoSizeVarianceParty = $O.cargoSize.party.text(order)
  const qualityPermittedLabel = $O.quality.permitted.text(order)
  const nCargoes = `${
    deliveryCargoMin !== deliveryCargoMax
      ? `${deliveryCargoMin} - ${deliveryCargoMax}`
      : deliveryCargoMax
  } Cargoes`
  const tradingTypeInCurrentContext = isCreatingTrade ? order.orderBeenTradedTradingType : tradingType
  return (
    <>
      <GridArea name={HEADER_AREAS.SUMMARY}>
        <Collapse in={expanded}>
          <Grid container>
          {!isTermSheetSummary && (
              <>
            <CompanyCode
              data-testid="order-summary-company-code"
              order={order}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...styles.text1,
                marginRight: gutterX,
                fontWeight: 900,
              }}
            />
            {!isCreatingTrade && (<SummaryRow.TradingType value={tradingTypeInCurrentContext} />)}
            {isCreatingTrade && (<SummaryRow.TradingType value={'Trade'} />)}
            <SummaryRow.DeliveryType value={deliveryType} />
            <SummaryRow.DeliveryPort value={deliveryLocation} />
            <SummaryRow.DeliveryPeriod value={deliveryPeriod} />
            <SummaryRow.DeliveryCargoes value={deliveryWindowShortLabel} />
            {order.id &&
              !isTermsheet(order) &&
              order.status !== ORDER_STATUS.PENDING && (
                <IconButton
                  size="small"
                  style={{ marginLeft: gutterX, marginTop: '-2px' }}
                  data-testid="timeline-link"
                >
                  <Link
                    to={`${MARKET_VISUALISATION_TIMELINE}?type=${typeName}&period=${period}&highlightId=${order.id}&status=${STATUS.ALL}`}
                    style={{ fontSize: 0 }}
                  >
                    <Equalizer style={{ color: palette.primary.main }} />
                  </Link>
                </IconButton>
              )}
               </>
            )}
            {isTermSheetSummary && (
              <CompanyCodeTermsheet
              data-testid="order-summary-company-code"
              order={order}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...styles.text1,
                marginRight: gutterX,
                fontWeight: 900,
              }}
            />
            )}
          </Grid>
        </Collapse>
      </GridArea>

      {!isTermSheetSummary && (
        <GridArea name={HEADER_AREAS.DETAILS}>
          <Collapse in={expanded}>
            <SummaryRow.Price value={price} />
            {!isEmpty(impliedPrices) && (
              <SummaryRow.ImpliedPrice
                value={`Evo Implied: ${impliedPrices.join(', ')}`}
              />
            )}
            <Grid item>
              {deliveryCargoMax > 1 && (
                <SummaryRow.NumberOfCargoes
                  value={nCargoes}
                  proRataLabel={proRataLabel}
                />
              )}
              <SummaryRow.CargoSize value={cargoSize} />
              <SummaryRow.CargoSizeVariance
                value={cargoSizeVariance}
                cargoSizeVarianceParty={cargoSizeVarianceParty}
              />
              <SummaryRow.QualityGHV
                value={quality}
                qualityPermittedLabel={qualityPermittedLabel}
              />
            </Grid>

            {id && (
              <SummaryRow.OrderId
                shortId={order.shortId}
                value={id}
                label={isTermsheet(order) ? 'Term sheet' : 'Order'}
              />
            )}
          </Collapse>
        </GridArea>
      )}
    </>
  )
}

OrderSummary.defaultProps = {
  order: {},
  expanded: true,
}

OrderSummary.propTypes = {
  order: PropTypes.object,
  expanded: PropTypes.bool,
}
