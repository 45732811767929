import React from 'react'
import { QualityType as QualityTypeModel } from 'screens/orderCreate/components/QualityType'
import {
  PaddedContainer,
  Button,
  StyledContainer,
  StyledConnectedSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { SelectableGridOption as Option } from 'components/SelectableGrid'
import { QualityGHVFields } from 'screens/orderCreate/components/QualityGHVFields'

export const QualityGHV = () => (
  <PaddedContainer>
    <QualityTypeModel>
      {({ options, onChange }) => (
        <StyledConnectedSelectableGrid
          name="quality.type"
          onChange={(value) => onChange(value)}
        >
          {options.map(({ value, label }, index) => {
            const first = 0
            const last = options.length - 1
            const xs = index === first || index === last ? 12 : 6
            return (
              <Option
                data-testid={`quality.type-${value}`}
                key={`order-quality-type-${value}`}
                xs={xs}
                value={value}
              >
                <Button>{label}</Button>
              </Option>
            )
          })}
        </StyledConnectedSelectableGrid>
      )}
    </QualityTypeModel>

    <StyledContainer>
      <QualityGHVFields />
    </StyledContainer>
  </PaddedContainer>
)
