import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { mapProps, compose } from 'recompose'

const withRouteSearch = compose(
  withRouter,
  mapProps((props) => ({
    ...props,
    ...queryString.parse(props.location.search),
  }))
)

export default withRouteSearch
