import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Grid from '@material-ui/core/Grid'
import { NextButton, BackButton } from '../components/Buttons'
import { ButtonsWrapper } from '../components/ButtonsWrapper'
import {
  Name,
  Surname,
  Email,
  Phone,
  JobTitle,
  Entity,
  AddressFirstLine,
  AddressSecondLine,
  Postcode,
  City,
  Country,
} from '../components/CompanyPrimaryContact/FormFields'
import { getCompanyPrimaryContactFormSchema } from '../components/CompanyPrimaryContact/FormSchema'
import FormRow from 'components/formRow/FormRow'

const CompanyPrimaryContactForm = ({
  initialValues,
  handleNext,
  handleBack,
}) => {
  const handleSubmit = (values, form) => {
    form.setSubmitting(false)
    form.resetForm()
    handleNext({
      form: 'companyPrimaryContact',
      values,
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getCompanyPrimaryContactFormSchema(initialValues)}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Name data-testid="contact-name" />
            </Grid>
            <Grid item xs={5}>
              <Surname />
            </Grid>
            <Grid item xs>
              <JobTitle />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Entity />
            </Grid>
            <Grid item xs={4}>
              <Email />
            </Grid>
            <Grid item xs>
              <Phone />
            </Grid>
          </Grid>
          <FormRow>
            <AddressFirstLine />
            <AddressSecondLine />
          </FormRow>
          <FormRow>
            <Postcode />
            <City />
            <Country />
          </FormRow>
          <ButtonsWrapper>
            <BackButton
              onClick={() => {
                handleBack({ form: 'companyPrimaryContact', values })
              }}
            />
            <NextButton />
          </ButtonsWrapper>
        </Form>
      )}
    </Formik>
  )
}

CompanyPrimaryContactForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
}

export default withRouter(CompanyPrimaryContactForm)
