import React from 'react'
import { ChevronLeft } from '@material-ui/icons'
import { useTheme } from '@material-ui/core'
import IconButton from 'components/iconButton/IconButton'

export const GoBackButton = (props) => {
  const { spacing } = useTheme()
  return (
    <IconButton
      icon={ChevronLeft}
      color="secondary"
      variant="contained"
      size="small"
      id="resetVisualisationFiltersButton"
      style={{ marginLeft: spacing(2) }}
      {...props}
    >
      Back
    </IconButton>
  )
}
