import React from 'react'
import PropTypes from 'prop-types'
import MuiCheckbox from '@material-ui/core/Checkbox'
import { Field } from 'formik'
import { useIsReactInDevelomentMode } from 'screens/orderCreateCarbon/helpers'

/* :: (object, object, object) -> object */
export const getCheckboxProps = (props, formikProps, formikForm) => {
  const checkboxProps = {
    ...props,
    id: props.id || props.value,
    onChange: (...args) => {
      formikProps.onChange(...args)
      props.onChange(...args)
    },
    checked: formikProps.value || props.checked || false,
    disabled: formikForm.isSubmitting || props.disabled,
  }

  return checkboxProps
}

const FormikCheckbox = (props) => (
  <Field name={props.value}>
    {({ field, form }) => (
      <MuiCheckbox {...getCheckboxProps(props, field, form)}
      {...(useIsReactInDevelomentMode ? {title: `Value is: ${props.value}`} : {})} />
    )}
  </Field>
)

FormikCheckbox.defaultProps = {
  onChange: () => {},
}

FormikCheckbox.propTypes = {
  ...MuiCheckbox.propTypes,
  value: PropTypes.string.isRequired,
}

FormikCheckbox.defaultProps = {
  onChange: () => {},
}

export default FormikCheckbox
