import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { connect } from 'formik'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { compose } from 'recompose'
import { $U, PERSON_CLIENT_TYPE, ORDER_STATUS } from 'emsurge-selectors'
import { getEarliestErrorStep } from '../getErrors'
import { ConfirmShareButton } from 'screens/orderCreateCommon/components/ConfirmShareButton'
import { getStatusLabel } from 'screens/orderCreate/helpers'
import { mapFormikValuesToOrder } from 'screens/orderCreateCommon/helpers'
import {
  TAB,
  ORDER_CONFIRMATION,
  useActiveStep,
  useOrderConfirmation,
  useOrderFlow,
  ORDER_FLOW,
} from 'containers/OrderProvider'
import { useUser } from 'containers/user/useUser'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'
import Checkbox from 'containers/formikCheckbox/FormikCheckbox'
import withRouteParams from 'utils/withRouteParams'
import {
  ConfirmButton,
  SaveButton,
} from 'screens/orderCreateCommon/components/Buttons'
import {
  Wrapper,
  GridContainer,
  GridArea,
  HEADER_AREAS,
} from 'components/ModalHeader/layout'
import { useCanShareOrderWithBroker } from 'hooks'
import { BrokerOrderSummary } from 'screens/orderCreateCommon/components/BrokerOrderSummary'
import { TraderOrderSummary } from 'screens/orderCreateCommon/components/TraderOrderSummary'

const Header = ({
  onCancel,
  formik,
  onSave,
  showSaveAsTemplateOption,
  isEdit,
  isCreatingTrade
}) => {
  const { user } = useUser()
  const { enqueueSnackbar } = useSnackbar()
  const {
    activeStep,
    navigate: { goTo: _goTo },
  } = useActiveStep()
  const goTo = useCallback(_goTo, [])
  const { confirmation, setConfirmation } = useOrderConfirmation()
  const hasSharePermission = useCanShareOrderWithBroker(
    mapFormikValuesToOrder(formik.values)
  )
  const { flow } = useOrderFlow()
  const isCreateTemplateFromOrderFlow = flow === ORDER_FLOW.CREATE_TEMPLATE

  const onSaveClick = useCallback(async () => {
    const errors = await formik.validateForm()

    if (!isEmpty(errors)) {
      enqueueSnackbar('Please correct all the errors', { variant: 'error' })
      const { tab, step } = getEarliestErrorStep(formik)
      formik.submitForm() // force touching all form fields to make errors visible
      goTo({ tab, step })
    } else {
      setConfirmation(ORDER_CONFIRMATION.CONFIRMED)
      goTo({ tab: TAB.RECAP })
    }
  }, [enqueueSnackbar, formik, goTo, setConfirmation])

  useEffect(() => {
    setConfirmation(ORDER_CONFIRMATION.UNCONFIRMED)
  }, [formik.values, setConfirmation])

  return (
    <Wrapper>
      <GridContainer data-testid="order-modal-header">
        {$U.clientType.get(user) === PERSON_CLIENT_TYPE.BROKER ? (
          <BrokerOrderSummary isEdit={isEdit} isCreatingTrade={isCreatingTrade}/>
        ) : (
          <TraderOrderSummary isCreatingTrade={isCreatingTrade}/>
        )}

        <GridArea name={HEADER_AREAS.OTHER_ACTIONS}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid data-testid="order-creation-status-label" item>
              {getStatusLabel(formik.values, isCreatingTrade)}
            </Grid>
            <Grid item>
              <CloseButton onClick={onCancel} />
            </Grid>
          </Grid>
        </GridArea>

        <GridArea name={HEADER_AREAS.MAIN_ACTIONS} style={{ marginTop: '8px' }}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {showSaveAsTemplateOption && (
              <Grid item>
                <FormControl>
                  <FormControlLabel
                    label="Save as template"
                    style={{ marginRight: 0 }}
                    control={
                      <Checkbox
                        disabled={isCreateTemplateFromOrderFlow}
                        data-testid="save-as-template-checkbox"
                        color="primary"
                        value="template"
                        onChange={() => {
                          formik.setFieldValue('status', ORDER_STATUS.PENDING)
                          if (activeStep.tab === TAB.COMMENTS) {
                            goTo({ tab: TAB.OVERVIEW })
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {confirmation === ORDER_CONFIRMATION.CONFIRMED && (
              <>
                {hasSharePermission && (
                  <Grid item>
                    <ConfirmShareButton
                      disabled={formik.isSubmitting}
                      onCancel={onCancel}
                    />
                  </Grid>
                )}
                <Grid item>
                  <ConfirmButton
                    disabled={formik.isSubmitting}
                    onClick={onSave || formik.submitForm}
                  />
                </Grid>
              </>
            )}
            {confirmation === ORDER_CONFIRMATION.UNCONFIRMED && (
              <Grid item>
                <SaveButton onClick={onSaveClick} />
              </Grid>
            )}
          </Grid>
        </GridArea>
      </GridContainer>
    </Wrapper>
  )
}

Header.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  formik: PropTypes.object.isRequired,
  showSaveAsTemplateOption: PropTypes.bool,
  isEdit: PropTypes.bool,
}

Header.defaultProps = {
  showSaveAsTemplateOption: false,
  isEdit: false,
}

const enhance = compose(connect, withRouteParams)

export default enhance(Header)
