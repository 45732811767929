import { isEmpty, isString, round } from 'lodash'

import {
  PRICE_TYPE,
  PRICE_TYPE_TEXT,
  PRICE_UNIT_TEXT,
} from 'emsurge-selectors/constants'

const hasPrice = (info) => {
  const amount = parseFloat(info.value)
  const unit = info.unit

  return Number.isFinite(amount) && isString(unit) && !isEmpty(unit)
}

const getPrice = (info) => {
  return { ...info }
}

const getSummary = (info) => {
  let summary
  const priceUnitText = PRICE_UNIT_TEXT[info.unit]

  if (info.value && priceUnitText) {
    const value = round(info.value, 3)
    const unit = priceUnitText(value)

    summary = unit
  }

  return {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.FIXED],
    short: summary,
    long: summary,
  }
}

export const fixed = {
  exists: hasPrice,
  get: getPrice,
  summary: getSummary,
}
