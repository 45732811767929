import { useQuery } from 'react-query'

const formatNumber = (n) => n.toLocaleString([], { minimumIntegerDigits: 2})

const formatDateAsVersionNumber = (date) => {
    const dateStr = `${date.getFullYear()}.${date.getMonth() + 1}${date.getDate()}`
    const timeStr = `${formatNumber(date.getHours())}${formatNumber(date.getMinutes())}`
    const result = `${dateStr}.${timeStr}`
    return result
}

export const useVersion = () => {
    return useQuery('version', async () => {
        const response = await(await fetch('/version.txt'))
        let result;
        try {
            result = await response.json()
        } catch (e) {
            console.warn('Failed to retrieve version information from version.txt', e)
            console.debug("version.txt response was", response)
            result = {
                branch: '-',
                latestCommitId: '-',
                latestCommitDate: '-',
                latestCommits: [],
                versionNumber: '-',
                uncommittedLocalChanges: []
            }
        }
        const commitStr = result.latestCommitId.substring(0, 10)
        result.latestCommitDate = new Date(result.latestCommitDate)
        result.versionNumber = `${formatDateAsVersionNumber(result.latestCommitDate)}-${commitStr}`
        return result
    }, {
        staleTime: Infinity
    })
}
