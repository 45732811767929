import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import FormProvider from './containers/FormProvider'
import { getInitialFormValues } from './getInitialFormValues'
import Fetch from 'containers/fetchPlus/FetchPlus'
import EntityForm from 'screens/entityCreate/components/EntityForm'
import withParams from 'utils/withRouteParams'

const EditEntity = ({ id }) => (
  <>
    <Text variant="h1">Edit Entity</Text>
    <Fetch url={`/entities/${id}`}>
      {(entity) => {
        const { companyId } = entity
        const initialValues = getInitialFormValues(entity)

        return (
          <FormProvider companyId={companyId} initialValues={initialValues}>
            <EntityForm />
          </FormProvider>
        )
      }}
    </Fetch>
  </>
)

EditEntity.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withParams(EditEntity)
