import PropTypes from 'prop-types'
import {
  $U,
  STAKEHOLDER_TYPE,
  STAKEHOLDER_TYPE_TEXT_BY_CLIENT_TYPE,
} from 'emsurge-selectors'
import {
  useOrderFlow,
  ORDER_FLOW,
  useOrderState,
} from 'containers/OrderProvider'
import { useUser } from 'containers/user/useUser'

export const OnBehalfOf = ({ children }) => {
  const { user } = useUser()
  const { flow } = useOrderFlow()
  const {
    orderState: {
      hasTermsheets,
      brokerEditsSharedOrder,
      isOtherTraderCompanyOrder,
      stakeholderType,
    },
  } = useOrderState()
  const clientType = $U.clientType.get(user)
  const isCountering = flow === ORDER_FLOW.COUNTER
  const isEditing = flow === ORDER_FLOW.EDIT
  const disabled = (hasTermsheets && isEditing) || brokerEditsSharedOrder
  const options = Object.entries(
    STAKEHOLDER_TYPE_TEXT_BY_CLIENT_TYPE[clientType]
  ).map(([value, label]) => ({ value, label, disabled }))

  const offSystemOption = options.find(
    (option) => option.value === STAKEHOLDER_TYPE.OFF_SYSTEM
  )
  offSystemOption.disabled =
    (isEditing && stakeholderType === STAKEHOLDER_TYPE.ON_SYSTEM) ||
    (isCountering && isOtherTraderCompanyOrder)

  return children(options)
}

OnBehalfOf.defaultProps = {
  children: () => {},
}

OnBehalfOf.propTypes = {
  children: PropTypes.func,
}
