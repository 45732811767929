import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'

import { Formik } from 'formik'
import { $O, TRADING_TYPE } from 'emsurge-selectors'
import PublicNote from './PublicNote'
import { EssentialPanel } from 'screens/orderCreateCarbon/components/EssentialPanel'
import { OptionalPanel } from 'screens/orderCreateCarbon/components/OptionalPanel'

export const Information = ({ order, clickable }) => {
  const { spacing } = useTheme()
  const panelProps = { order }
  const entityCode = $O.stakeholder.code.text(order)

  if (order.tradingType === TRADING_TYPE.BID) {
    panelProps.buyer = entityCode
  } else {
    panelProps.seller = entityCode
  }

  return (
    <Formik>
    <section>
        <PublicNote order={order} style={{ marginBottom: spacing(1) }} />
        <EssentialPanel clickable={clickable} order={order} />
        <OptionalPanel clickable={clickable} {...panelProps} />
    </section>
    </Formik>
  )
}

Information.defaultProps = {
  clickable: false,
}

Information.propTypes = {
  order: PropTypes.object.isRequired,
  clickable: PropTypes.bool,
}
