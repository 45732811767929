import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/styles'

const Card = ({ id, children, theme, style }) => (
  <section
    id={id}
    style={{
      backgroundColor: theme.palette.background.secondary,
      borderRadius: '5px',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      position: 'relative',
      ...style,
    }}
  >
    {children}
  </section>
)

Card.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export default withTheme(Card)
