import React from 'react'
import { merge } from 'lodash'
import { Grid } from '@material-ui/core'
import {
  $O,
  $OC,
  CLASSIFICATIONS_SECOND,
  TRADING_TYPE,
} from 'emsurge-selectors'
import Person from '../Person'
import { buildStyles } from './utils'
import { CardIcons } from './CardIcons'
import { getOrderCardOpacity } from 'screens/orderIndex/helpers'
import { useTruncatedTextObject } from 'hooks'
import CompanyCode from 'components/CompanyCode/CompanyCode'
import { SmallText } from 'components/Text'
import { Row } from 'components/Row'
import { Card } from 'components/Card'
import { CarbonMainPrice } from 'components/MainPrice'

const ORDER_CARD_MIN_HEIGHT = 60

const getClassificationVolumeOrder = (tradingType, classificationVolume) => {
  if (tradingType === TRADING_TYPE.ASK) {
    return classificationVolume.reverse()
  }
  return classificationVolume
}

export const OrderCardCarbon = ({ order, style }) => {
  const opacity = getOrderCardOpacity(order)
  const tradingType = $OC.tradingType.get(order)
  const rowDirection = tradingType === TRADING_TYPE.BID ? 'row' : 'row-reverse'
  const { hasViewed } = $O.views.get(order)
  const styles = merge(buildStyles(), {
    root: {
      opacity,
      minHeight: ORDER_CARD_MIN_HEIGHT,
      overflow: 'hidden',
    },
  })
  const { classifications = {} } = $OC.carbonType.get(order)
  const { text: volumeText } = $OC.volume.text(order)
  const classificationsDetail =
    CLASSIFICATIONS_SECOND.find(
      (attr) => attr.value === classifications.second
    ) || {}
  const classificationVolume = getClassificationVolumeOrder(tradingType, [
    classificationsDetail.label,
    volumeText.join('-'),
  ])
  const { keywords, projects } = $OC.projectSpecification.get(order)

  const keywordsText = useTruncatedTextObject(keywords, x => x, 40)
  const projectNamesText = useTruncatedTextObject(projects, x => `${x.referenceNr}-${x.name}`, 40)

  return (
    <Card
      style={{ ...styles.root, ...style }}
      id={`market-${order.id}`}
      border={!hasViewed}
      data-seen={!hasViewed}
      data-cy="market-interest-carbon-order-cards"
      data-testid={`market-${order.id}`}
    >
      <Grid container>
        <Row direction={rowDirection}>
          <Person data-testid="order-card-author" person={order.person} />
          { tradingType === TRADING_TYPE.BID
          ? (<SmallText title={keywordsText.completeText}>{keywordsText.truncatedText}</SmallText>)
          : (<SmallText title={projectNamesText.completeText}>{projectNamesText.truncatedText}</SmallText>)
          }
        </Row>

        <Row direction={rowDirection} style={{ marginTop: '5px' }}>
          <CardIcons order={order} tradingType={$O.tradingType.get(order)} />
          <SmallText>{classificationVolume.join(' ')}</SmallText>
        </Row>

        <Row direction={rowDirection} style={{ marginTop: '2px' }}>
          <CompanyCode data-testid="order-card-company-code" order={order} />
          <CarbonMainPrice data-testid="order-card-main-price" order={order} />
        </Row>
      </Grid>
    </Card>
  )
}
