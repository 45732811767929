const getCompany = (bulletin) => bulletin.company || {}

const getCompanyText = (bulletin) => {
  const company = getCompany(bulletin)

  return {
    id: company.id || '',
    name: company.name || '',
  }
}

module.exports = {
  get: getCompany,
  text: getCompanyText,
}
