import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import Text from '@material-ui/core/Typography'
import { withTheme } from '@material-ui/styles'
import { compose } from 'recompose'
import EntityTable from './components/EntityTable'
import BlockHeader from './components/BlockHeader'
import AddButton from './components/AddButton'
import withParams from 'utils/withRouteParams'
import Fetch from 'containers/fetchPlus/FetchPlus'
import Header from 'screens/companyViewRequest/components/CompanyInformation'
import UsersTable from 'screens/userIndex/components/UsersTable'

const ViewCompany = ({ id, theme }) => (
  <Fetch url={`/companies/${id}`}>
    {(company) => {
      const marginBottom = { marginBottom: theme.spacing(5) }
      const addEntityLink = `/admin/companies/${company.id}/entities/new`
      const addUserLink = `/admin/companies/${company.id}/users/new`

      return (
        <>
          <Header company={company} />
          <Divider style={marginBottom} />

          {/** ENTITIES */}
          <section style={marginBottom}>
            <BlockHeader>
              <Text variant="h2">Entities</Text>
              <AddButton to={addEntityLink} />
            </BlockHeader>
            <EntityTable entities={company.entities} />
          </section>

          {/** USERS */}
          <section>
            <BlockHeader>
              <Text variant="h2">Users</Text>
              <AddButton to={addUserLink} />
            </BlockHeader>
            <UsersTable users={company.persons} />
          </section>
        </>
      )
    }}
  </Fetch>
)

ViewCompany.propTypes = {
  theme: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
}

export default compose(withTheme, withParams)(ViewCompany)
