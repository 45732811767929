import React from 'react'
import PropTypes from 'prop-types'
import HandleCollapse from './HandleCollapse'
import CollapseComponent from './CollapseComponent'
import { getSubMenuPaths, getSubNavs } from './helpers'
import NavLinkMenu from './NavLinkMenu'

const SubNavLink = ({ to, children, Icon, subMenu, ...textProps }) => {
  const matchPath = getSubMenuPaths(subMenu)
  return (
    <>
      <HandleCollapse to={to}>
        <NavLinkMenu
          {...{ to, matchPath, children, Icon, subMenu, ...textProps }}
        />
      </HandleCollapse>
      <CollapseComponent to={to} subMenu={subMenu}>
        {getSubNavs(subMenu).map((element, idx) => (
          <NavLinkMenu
            {...{ ...element.props, isSubMenu: true }}
            key={`submenu-${idx}`}
          />
        ))}
      </CollapseComponent>
    </>
  )
}

SubNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  subMenu: PropTypes.node,
}

export default SubNavLink
