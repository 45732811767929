import React from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

const HamburgerMenuButton = (props) => (
  <IconButton
    data-testid="open-drawer-button"
    color="inherit"
    aria-label="Open drawer"
    {...props}
  >
    <MenuIcon />
  </IconButton>
)

export default HamburgerMenuButton
