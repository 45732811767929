import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { PRICE_VARIANT } from 'emsurge-selectors/constants'
import { Indexes } from './Indexes'
import { PlusOrMinus } from './PlusOrMinus'
import { PriceDeliveryContractFields } from './PriceDeliveryContractFields'
import {
  UppercaseTextField,
  TextField,
} from 'screens/orderCreateCommon/components/TextField'
import { Unit } from 'screens/orderCreate/components/Unit'

export const Floating = ({ variant, sizes }) => {
  const floatingPercentage = `price.${variant}.floatingInfo.percentage`
  const floatingIndex = `price.${variant}.floatingInfo.index`
  const floatingPlusOrMinus = `price.${variant}.floatingInfo.plusOrMinus`
  const floatingAmount = `price.${variant}.floatingInfo.amount`
  const floatingUnit = `price.${variant}.floatingInfo.unit`
  return (
    <Grid container spacing={2}>
      <Grid item xs={sizes.percentage}>
        <TextField
          type="number"
          name={floatingPercentage}
          label="Percentage"
          inputProps={{ min: 0, max: 100, 'data-testid': floatingPercentage }}
        />
      </Grid>

      <Grid data-testid={floatingIndex} item xs={sizes.indexes}>
        <Indexes name={floatingIndex} />
      </Grid>

      <PriceDeliveryContractFields rootPath={`price.${variant}.floatingInfo`} />

      <Grid data-testid={floatingPlusOrMinus} item xs={sizes.plusOrMinus}>
        <PlusOrMinus name={floatingPlusOrMinus} />
      </Grid>

      <Grid item xs={sizes.amount}>
        <UppercaseTextField
          inputProps={{ 'data-testid': floatingAmount }}
          name={floatingAmount}
          label="Amount"
        />
      </Grid>

      <Grid data-testid={floatingUnit} item xs={sizes.unit}>
        <Unit name={floatingUnit} />
      </Grid>
    </Grid>
  )
}

Floating.defaultProps = {
  sizes: {
    percentage: 2,
    indexes: true,
    plusOrMinus: 1,
    amount: true,
    unit: true,
  },
}

Floating.propTypes = {
  sizes: PropTypes.shape({
    percentage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    indexes: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    plusOrMinus: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    amount: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    unit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }),
  variant: PropTypes.oneOf(Object.values(PRICE_VARIANT)).isRequired,
}
