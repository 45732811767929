const {
  TERM_SPOT_VALUES,
  PROJECT_INFORMATION,
  TERM_SPOT_KEYS,
  TERM_FIXED_ROLLING_KEYS,
  TERM_FIXED_ROLLING_VALUES,
  DELIVERED_BY_KEY,
  getRollingRangeMessage,
} = require('../../constants')
const moment = require('moment')
const { computeRenderedDate, getDatePeriods } = require('../../helpers')

const getTermVintage = (order) => order.termVintage || {}

const getDateAsFormattedString = (dateObject = {}) => {
  const { period, year, customFrom, customTo } = dateObject || {}
  return String(computeRenderedDate(period, customFrom, customTo, year))
}

const getFixedRollingData = (term, fixedRolling, shortRollingRange) => {
  if (fixedRolling === TERM_FIXED_ROLLING_KEYS.FIXED) {
    return shortRollingRange
    ? TERM_SPOT_VALUES.TERM 
    : getDateAsFormattedString(term.vintage)
  }
  if (fixedRolling === TERM_FIXED_ROLLING_KEYS.ROLLING) {
    const { within, year } = term.vintage
    return shortRollingRange
      ? TERM_SPOT_VALUES.TERM
      : getRollingRangeMessage(within, year)
  }
  return ''
}

const getSpotTermTextVintage = (order, shortRollingRange) => {
  const { termSpot, spot, term, investment, fixedRolling } =
    getTermVintage(order)
  if (termSpot === TERM_SPOT_KEYS.TERM) {
    return {
      vintage: getFixedRollingData(term, fixedRolling, shortRollingRange),
      offset: getDateAsFormattedString(term.offset),
      term: TERM_FIXED_ROLLING_VALUES[fixedRolling],
    }
  }
  if (termSpot === TERM_SPOT_KEYS.SPOT) {
    return {
      vintage: getDateAsFormattedString(spot),
    }
  }
  if (termSpot === TERM_SPOT_KEYS.INVESTMENT) {
    return {
      vintage: TERM_SPOT_VALUES.INVESTMENT,
      investmentDescription: investment.investmentDescription,
    }
  }
  return {}
}

const getTermVintageText = (order, shortRollingRange = false) => {
  const { termSpot, projectInformation } = getTermVintage(order)
  return {
    termSpot: TERM_SPOT_VALUES[termSpot],
    projectStatus: projectInformation
      .map((proj) => PROJECT_INFORMATION[proj])
      .join(', '),
    ...getSpotTermTextVintage(order, shortRollingRange),
  }
}

const getVintagePeriods = (order) => {
  const { termSpot, spot, fixedRolling, term } = getTermVintage(order)
  if (termSpot === TERM_SPOT_KEYS.SPOT) {
    return { ...getDatePeriods(spot), sortOrder: 1 }
  }
  if (termSpot === TERM_SPOT_KEYS.TERM) {
    if (fixedRolling === TERM_FIXED_ROLLING_KEYS.FIXED) {
      return { ...getDatePeriods(term.vintage), sortOrder: 2 }
    }
    if (fixedRolling === TERM_FIXED_ROLLING_KEYS.ROLLING) {
      return { sortOrder: 3 }
    }
  }
  if (termSpot === TERM_SPOT_KEYS.INVESTMENT) {
    return { sortOrder: 4 }
  }
  return { sortOrder: 0 }
}

const getDeliveredByText = (order) => {
  const deliveredBy = order.termVintage.deliveredBy || {}
  if(deliveredBy.type === DELIVERED_BY_KEY.SPOT)
  {
    return 'Spot'
  }
  if (deliveredBy.fixedDate)
  {
    const formatedDate = moment(deliveredBy.fixedDate).format('DD MM YY');
    return `On or before ${formatedDate}`
  }
  if(deliveredBy.daysAfter){
    return `${deliveredBy.daysAfter} Days after the Point of Trade`
  }
  return ''
}

module.exports = {
  get: getTermVintage,
  text: getTermVintageText,
  spotTerm: {
    text: getSpotTermTextVintage,
    vintage: getVintagePeriods,
  },
  deliveredBy: {
    text: getDeliveredByText
  },
}
