import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import { RecapScreen } from './screens/Recap'
import { useActiveStep } from 'containers/OrderProvider'
import VerticalStepper from 'screens/orderCreateCommon/components/VerticalStepper'
import StepLabel from 'screens/orderCreateCommon/components/StepLabel'

export const steps = [
  {
    title: 'Recap',
    component: RecapScreen,
    number: 11,
  },
]

const RecapFormStepper = ({ company, isCreatingTrade }) => {
  const {
    activeStep,
    navigate: { goTo },
  } = useActiveStep()

  return (
    <VerticalStepper activeStep={activeStep.step} nonLinear>
      {steps.map(({ title, number, component: Content }, idx) => {
        return (
          <Step key={title}>
            <StepLabel icon={number} onClick={() => goTo({ step: idx })}>
              {title}
            </StepLabel>

            <StepContent>
              <Content company={company} isCreatingTrade={isCreatingTrade}/>
            </StepContent>
          </Step>
        )
      })}
    </VerticalStepper>
  )
}

RecapFormStepper.propTypes = {
  company: PropTypes.object.isRequired,
}

export default connect(RecapFormStepper)
