import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { SelectableGrid } from './SelectableGrid'

export const ConnectedSelectableGrid = ({
  name,
  classes,
  onChange,
  children,
}) => (
  <Field name={name}>
    {({ field, form }) => (
      <SelectableGrid
        classes={classes}
        value={field.value}
        onChange={(value) => {
          if (field.value === value) {
            return
          }
          form.setFieldValue(name, value)
          onChange(value)
        }}
      >
        {children}
      </SelectableGrid>
    )}
  </Field>
)

ConnectedSelectableGrid.defaultProps = {
  onChange: () => {},
  classes: {},
  children: [],
}

ConnectedSelectableGrid.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.node,
}
