const getAuthor = (bulletin) => bulletin.author || {}

const getAuthorText = (bulletin) => {
  const author = getAuthor(bulletin)

  return {
    id: author.id || '',
    companyId: author.companyId,
    name: author.name,
    surname: author.surname,
    fullName: `${author.name || ''} ${author.surname || ''}`.trim(),
  }
}

module.exports = {
  get: getAuthor,
  text: getAuthorText,
}
