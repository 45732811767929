import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, MenuItem, TextField } from '@material-ui/core'
import { find, first, isEmpty, values } from 'lodash'
import {
  DELIVERY_REGION,
  DELIVERY_TYPE,
  DELIVERY_TYPE_TEXT,
} from 'emsurge-selectors'
import { FieldArray } from 'formik'
import { Add } from '@material-ui/icons'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'

export const EmsurgeFields = ({ fields, onChange }) => {
  const getLocation = (selectedType, currentPort) => {
    if (DELIVERY_REGION[selectedType]?.includes(currentPort)) {
      return currentPort
    }
    return first(DELIVERY_REGION[selectedType])
  }

  return (
    <>
      <Grid item>
        <TextField
          select
          label="Type"
          InputLabelProps={{ shrink: true }}
          value={fields?.value?.type || ''}
          onChange={(evt) =>
            onChange({
              ...fields,
              name: `${evt.target?.value} ${getLocation(
                evt.target?.value,
                first(fields.value?.location)
              )}`,
              value: {
                type: evt.target?.value,
                location: [
                  getLocation(evt.target?.value, first(fields.value?.location)),
                ],
              },
            })
          }
        >
          {values(DELIVERY_TYPE).map((deliveryType) => (
            <MenuItem
              key={`delivery-type-${deliveryType}`}
              value={deliveryType}
            >
              {DELIVERY_TYPE_TEXT[deliveryType]}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          select
          label="Port"
          InputLabelProps={{ shrink: true }}
          value={first(fields?.value?.location) || ''}
          onChange={(evt) =>
            onChange({
              ...fields,
              name: `${fields.value?.type} ${evt.target.value}`,
              value: {
                ...fields?.value,
                location: [evt.target?.value],
              },
            })
          }
        >
          {values(DELIVERY_REGION[fields.value?.type]).map((port) => (
            <MenuItem key={`delivery-port-${port}`} value={port}>
              {port}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <FormConsumer>
          {({ values }) => (
            <FieldArray
              name="markets"
              render={({ push }) => (
                <Button
                  disabled={
                    Boolean(
                      find(values?.markets, ({ name }) => name === fields.name)
                    ) ||
                    !fields.value?.type ||
                    isEmpty(fields.value?.location)
                  }
                  onClick={() => push(fields)}
                  startIcon={<Add />}
                  color="secondary"
                  variant="contained"
                  size="small"
                >
                  Add
                </Button>
              )}
            ></FieldArray>
          )}
        </FormConsumer>
      </Grid>
    </>
  )
}

EmsurgeFields.propTypes = {
  fields: PropTypes.object,
  onChange: PropTypes.func,
}
