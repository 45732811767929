import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { curryRight } from 'lodash/fp'
import { isEmpty, debounce } from 'lodash'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { ActivityCard } from '../components/ActivityCard'
import { ActivitySkeleton } from '../components/ActivitySkeleton'
import SearchForm from '../components/SearchForm'
import {
  BlockContent,
  IconTitle,
  BlockRow,
  ActionBlock,
} from '../components/Block'
import {
  guessOrderActivityCardHeight,
  guessTermsheetActivityCardHeight,
  guessCommentActivityCardHeight,
  guessBulletinActivityCardHeight,
  DEFAULT_CARD_HEIGHT,
  GUTTER,
} from 'screens/orderIndex/helpers.js'
import { guessNoteActivityCardHeight } from 'screens/noteIndex/utils'
import { VirtualList } from 'components/VirtualList'
import { filterArrayOfObjects as _filterArrayOfObjects } from 'utils/dataHandlers'
import {
  isOrderType,
  isNoteType,
  isCommentType,
  isTermsheetType,
  isBulletinType,
} from 'constants/activityType'
import { Tablet } from 'components/Viewport'
import { useActivities } from 'api'

const filterArrayOfObjects = curryRight(_filterArrayOfObjects)
const DEBOUNCE_RATE = 500

export const isUpdateActivity = (type) => (type || '').indexOf('updated') > -1

export const isCreateActivity = (type) => (type || '').indexOf('created') > -1

export const isDeleteActivity = ({ type }) =>
  (type || '').indexOf('deleted') > -1

export const isViewedActivity = ({ type }) =>
  (type || '').indexOf('viewed') > -1

export const isClosedActivity = ({ type }) =>
  (type || '').indexOf('closed') > -1

const getItemSize = (data, index) => {
  const card = data[index]
  if (isNoteType(card.type)) {
    return guessNoteActivityCardHeight(card.note, !isEmpty(card.tags))
  }
  if (isOrderType(card.type)) {
    return guessOrderActivityCardHeight(card.context)
  }
  if (isCommentType(card.type)) {
    return guessCommentActivityCardHeight()
  }
  if (isTermsheetType(card.type)) {
    return guessTermsheetActivityCardHeight()
  }
  if (isBulletinType(card.type)) {
    return guessBulletinActivityCardHeight(card.message, !!card.title)
  }

  return DEFAULT_CARD_HEIGHT
}

const RowRenderer = ({ index, style, data }) => {
  const activity = data[index]
  return (
    <ActivityCard
      key={`${activity.id}_${activity.type}_${activity.history_id}`}
      activity={activity}
      style={{
        ...style,
        overflow: 'hidden',
        top: style.top + GUTTER,
        height: style.height - GUTTER,
      }}
    />
  )
}

RowRenderer.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.array,
}

const Activity = () => {
  const { isLoading, isError, data } = useActivities()
  const activities = data?.all || []
  const [filter, setFilter] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const filteredActivities = useMemo(
    () => filterArrayOfObjects(activities, filter),
    [activities, filter]
  )

  const onFilterChange = debounce((value) => setFilter(value), DEBOUNCE_RATE)

  if (isError) {
    enqueueSnackbar('Connection failed checking for new activities', {
      variant: 'warning',
    })
  }

  return (
    <>
      <Tablet matchLarger>
        <BlockRow>
          <Grid item>
            <IconTitle icon={NotificationsIcon}>Activity</IconTitle>
          </Grid>
        </BlockRow>
      </Tablet>

      <ActionBlock />

      <Grid container>
        <Grid item xs>
          <SearchForm
            data-testid="activity-search"
            onChange={(evt) => onFilterChange(evt.target.value)}
          />
        </Grid>
      </Grid>

      <BlockContent>
        {isLoading ? (
          <ActivitySkeleton count={10} />
        ) : (
          <VirtualList
            rowRenderer={RowRenderer}
            getItemSize={getItemSize}
            items={filteredActivities}
          />
        )}
      </BlockContent>
    </>
  )
}

export default Activity
