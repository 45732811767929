import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { withSnackbar } from 'notistack'
import { PERSON_STATUS } from 'emsurge-selectors/constants'
import { ADMIN_VIEW_USERS } from 'routes'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

export const getInitialFormValues = (company) => {
  const baseValues = {
    name: '',
    surname: '',
    jobTitle: '',
    email: '',
    phone: '',
    clientType: company.type,
  }

  const initialValues = company.entities.reduce(
    (values, entity) => ({
      ...values,
      [entity.id]: {
        isAdmin: false,
        role: 'none',
      },
    }),
    baseValues
  )

  return initialValues
}

export const getFormSchema = (initialValues) =>
  object().shape({
    name: string().required('Required'),
    surname: string().required('Required'),
    jobTitle: string().required('Required'),
    email: string()
      .email('Invalid email')
      .emailIsUnique(initialValues.email)
      .required('Required'),
    phone: string().required('Required'),
    clientType: string().required('Required'),
  })

export const getApiData = (
  companyId,
  entities,
  formValues,
  status = PERSON_STATUS.PENDING_PASSWORD_CREATION
) => {
  const { name, surname, email, phone, jobTitle, clientType, adminRole } =
    formValues
  return {
    person: {
      name,
      surname,
      email,
      phone,
      jobTitle,
      companyId,
      clientType,
      adminRole: adminRole || 'none',
      status,
    },
    personEntities: entities.map((entity) => ({
      ...formValues[entity.id],
      entityId: entity.id,
    })),
  }
}

const FormProvider = ({
  company,
  entities,
  history,
  enqueueSnackbar,
  children,
  initialValues = getInitialFormValues(company),
}) => {
  const api = useApi()

  /* :: (object, object) -> Promise<void> */
  const handleSubmit = async (values, form) => {
    const data = getApiData(company.id, entities, values)

    try {
      await api.post('/persons', data)
      enqueueSnackbar('User creation successful', { variant: 'success' })
      history.push(ADMIN_VIEW_USERS)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'User creation not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }

    form.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getFormSchema(initialValues)}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  entities: PropTypes.array,
  company: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
}

export default withSnackbar(FormProvider)
