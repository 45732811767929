import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import FormProvider from './containers/FormProvider'
import EntityForm from './components/EntityForm'
import Fetch from 'containers/fetchPlus/FetchPlus'
import withParams from 'utils/withRouteParams'

const CreateEntity = ({ companyId }) => (
  <>
    <Text component="h1" variant="h1">
      Create Entity
    </Text>
    <Fetch url={`/companies/${companyId}`}>
      {(company) => (
        <FormProvider company={company} companyId={companyId}>
          <EntityForm />
        </FormProvider>
      )}
    </Fetch>
  </>
)

CreateEntity.propTypes = {
  companyId: PropTypes.string.isRequired,
}

export default withParams(CreateEntity)
