export const getTimeFrameOptions = ({ disabled }) => {
  return [
    {
      disabled,
      value: 'point_trade',
      label: 'Point of trade',
    },
    {
      disabled,
      value: 'days_ahead',
      label: 'XX days ahead of relevant DW',
    },
    {
      disabled,
      value: 'fixed_date',
      label: 'Fixed date',
    },
    {
      disabled,
      value: 'month_ahead',
      label: 'XX of M-1',
    },
    {
      disabled,
      value: 'days_after',
      label: 'XX days after point of trade',
    },
  ]
}
