import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { withTheme } from '@material-ui/styles'
import Clear from '@material-ui/icons/Clear'
import IconButton from 'components/iconButton/IconButton'
import withRouteParams from 'utils/withRouteParams'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

/* :: (object[], object) -> object[] */
const getApiData = (permissionedEntities, entityToRemove) =>
  permissionedEntities
    .filter((entity) => entity.id !== entityToRemove.id)
    .map(({ id: entityId }) => ({ entityId }))

const RemoveEntityPermission = ({
  id,
  entityToRemove,
  entities,
  refetchPermissions,
  enqueueSnackbar,
  theme,
}) => {
  const api = useApi()

  /* :: object -> Promise<void> */
  const removeEntity = async (event) => {
    event.stopPropagation()
    const data = getApiData(entities, entityToRemove)

    try {
      await api.put(`/permissions/broker/${id}`, data)
      refetchPermissions()
      enqueueSnackbar('Entity permission removed', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Error removing entity permission',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <IconButton
      icon={Clear}
      style={{ color: theme.palette.error.main }}
      onClick={removeEntity}
    />
  )
}

RemoveEntityPermission.propTypes = {
  id: PropTypes.string.isRequired,
  entityToRemove: PropTypes.object.isRequired,
  entities: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  refetchPermissions: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default compose(
  withTheme,
  withSnackbar,
  withRouteParams
)(RemoveEntityPermission)
