import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withProps } from 'recompose'
import { Form, FieldArray } from 'formik'
import { useTheme, makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import countryList from 'country-list'
import { getDefaultActionItemValues } from '../containers/FormProvider'
import FormHeader from './FormHeader'
import PriorityLabel from './PriorityLabel'
import FormikTextField from 'containers/formikTextField/FormikTextField'
import FormikRadioInput from 'containers/FormikRadioInput/FormikRadioInput'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'
import { AddButton } from 'screens/orderCreateCommon/components/AddButton'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'
import Checkbox from 'containers/formikCheckbox/FormikCheckbox'
import { useUser } from 'containers/user/useUser'
import { DateTime } from 'components/DateTime'
import AutoComplete from 'containers/formikAutocomplete/AutoComplete'

const useStyles = makeStyles(() => ({
  root: {
    alignSelf: 'center',
    width: '100%',
    height: '100%',
  },
}))

const Subtitle = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

const Card = styled.section`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  position: relative;
`

const TextField = withProps({
  fullWidth: true,
  margin: 'normal',
})(FormikTextField)

const Country = withProps({
  type: 'text',
  fullWidth: true,
  margin: 'normal',
  name: 'country',
  id: 'country',
  label: 'Location',
  suggestions: countryList.getNames(),
  inputProps: {
    error: false,
  },
})(AutoComplete)

const ActionItem = ({ index, onRemove }) => (
  <Card>
    <CloseButton onClick={onRemove} />

    <Grid container spacing={2}>
      <Grid item xs={1}>
        <Grid container alignItems="center" style={{ height: '100%' }}>
          <Checkbox
            inputProps={{ 'data-testid': 'action-item-checkbox' }}
            color="primary"
            value={`actionItems[${index}].isChecked`}
          />
        </Grid>
      </Grid>

      <Grid item xs>
        <TextField
          inputProps={{ 'data-testid': 'action-item-title' }}
          name={`actionItems[${index}].title`}
          label="Title*"
        />
      </Grid>

      <Grid item xs>
        <TextField
          inputProps={{ 'data-testid': 'action-item-assignee' }}
          name={`actionItems[${index}].assignee`}
          label="Assignee*"
        />
      </Grid>

      <Grid item xs>
        <TextField
          inputProps={{ 'data-testid': 'action-item-date' }}
          name={`actionItems[${index}].dueDate`}
          label="Date*"
          type="datetime-local"
        />
      </Grid>
    </Grid>
  </Card>
)

ActionItem.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
}

const NewNoteForm = ({ onCancel }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { user } = useUser()
  const DEFAULT_ACTION_ITEM_VALUES = getDefaultActionItemValues(user)

  return (
    <Form>
      <FormHeader onCancel={onCancel} />
      <Subtitle variant="h3" gutterBottom>
        Information
      </Subtitle>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <DateTime name="eventDate" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            inputProps={{ 'data-testid': 'note-company-name' }}
            name="companyName"
            label="Company*"
          />
        </Grid>
      </Grid>

      <Country data-testid="note-location" />

      <TextField
        inputProps={{ 'data-testid': 'note-attendees' }}
        name="attendees"
        label="Attendees"
        helperText="Separate with commas"
      />

      <TextField
        inputProps={{ 'data-testid': 'note-tags' }}
        name="tags"
        label="Tags"
        helperText="Separate with commas"
      />

      <FormikRadioInput
        name="priority"
        options={[
          { value: '1', label: <PriorityLabel label="Memo" /> },
          { value: '2', label: <PriorityLabel label="Low" /> },
          { value: '3', label: <PriorityLabel label="Medium" /> },
          { value: '4', label: <PriorityLabel label="High" /> },
        ]}
      />

      <TextField
        inputProps={{ 'data-testid': 'note-text' }}
        name="note"
        label="Note"
        multiline
        rows={5}
      />

      <Subtitle variant="h3" gutterBottom className={classes.subtitle}>
        Action Items
      </Subtitle>

      <FormConsumer>
        {({ values }) => (
          <FieldArray
            name="actionItems"
            render={(arrayHelpers) => (
              <>
                {values.actionItems.map((_, index) => (
                  <ActionItem
                    key={index}
                    index={index}
                    onRemove={() => arrayHelpers.remove(index)}
                  />
                ))}

                <AddButton
                  data-testid="add-action-item-button"
                  style={{ margin: theme.spacing(1, 0) }}
                  onClick={() => arrayHelpers.push(DEFAULT_ACTION_ITEM_VALUES)}
                >
                  Add Action Item
                </AddButton>
              </>
            )}
          />
        )}
      </FormConsumer>
    </Form>
  )
}

NewNoteForm.propTypes = {
  onCancel: PropTypes.func,
}

export default NewNoteForm
