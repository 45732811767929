const { PARTY_TEXT } = require('../../../constants')

const getCargoSizeParty = (order) => {
  const cargoSize = order.cargoSize || {}

  return cargoSize.party
}

const getCargoSizePartyText = (order) => {
  const party = getCargoSizeParty(order)

  return PARTY_TEXT[party] || ''
}

module.exports = {
  get: getCargoSizeParty,
  text: getCargoSizePartyText,
}
