import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { getInitialUserState, LOCAL_STORAGE_KEY, INITIAL_STATE } from './helpers'
import { configureAuthenticatedUserInAppInsights } from 'containers/telemetry/TelemetryService'

export const UserContext = createContext()

const UserProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [user, setState] = useState(getInitialUserState)

  const setUser = (newUserState) => {
    setState(newUserState)
    updateStoredUser(newUserState)
  }

  const updateStoredUser = (newUserState) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newUserState))
    configureAuthenticatedUserInAppInsights(newUserState.email)
  }

  const login = ({ token, user }) => setUser({ ...user, token })

  const logout = (forceReload = false) => {
    if (forceReload) {
      updateStoredUser(INITIAL_STATE)
      window.location.href = "/"
      return
    }
    else {
      setUser(INITIAL_STATE)
      enqueueSnackbar('Session expired. Please sign in again', { variant: 'warning' })
    }
  }

  const value = { user, login, logout, setUser }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

UserProvider.propTypes = {
  children: PropTypes.node,
}

export default UserProvider
