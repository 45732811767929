import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import { compose } from 'recompose'
import { object, string } from 'yup'
import { useApi } from 'containers/api/useApi'
import { useUser } from 'containers/user/useUser'
import { getApiErrorMessage } from 'containers/api/helpers'

const SignInSchema = object().shape({
  email: string().email('Invalid email').required('Required'),
  password: string().required('Required'),
})

const INITIAL_SIGNIN_VALUES = {
  email: '',
  password: '',
  remember: false,
}

const FormProvider = ({ location, children, enqueueSnackbar }) => {
  const api = useApi()
  const { login } = useUser()
  const { email = '' } = queryString.parse(location.search)

  /* :: (object, object) -> Promise<void> */
  const handleSignIn = async ({ email, password }, actions) => {
    const data = { email, password }

    try {
      const response = await api.post('/login', data, { auth: false })
      const { token, user } = response.data

      login({ token, user })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'There was an internal error, please contact us if this persists',
      })

      enqueueSnackbar(message, { variant: 'error' })
      actions.resetForm()
      actions.setFieldValue('email', email)
    }
  }

  return (
    <Formik
      initialValues={{ ...INITIAL_SIGNIN_VALUES, email }}
      validationSchema={SignInSchema}
      onSubmit={handleSignIn}
    >
      {children}
    </Formik>
  )
}

FormProvider.propTypes = {
  location: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}

export default compose(withRouter, withSnackbar)(FormProvider)
