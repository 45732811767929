const VALIDITY_TYPE = {
  INDICATIVE: 'indicative',
  GOOD_TILL_CANCELLED: 'good_till_cancelled',
  GOOD_TILL_DATE: 'good_till_date',
}

const VALIDITY_TYPE_TEXT = {
  [VALIDITY_TYPE.INDICATIVE]: 'Withheld',
  [VALIDITY_TYPE.GOOD_TILL_CANCELLED]: 'Live till Cancelled',
  [VALIDITY_TYPE.GOOD_TILL_DATE]: 'Live till Date',
  INDICATIVE: 'Withheld',
  GOOD_TILL_CANCELLED: 'Live till Cancelled',
  GOOD_TILL_DATE: 'Live till Date',
}

module.exports = {
  VALIDITY_TYPE,
  VALIDITY_TYPE_TEXT,
}
