import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { partial } from 'lodash'
import { withSnackbar } from 'notistack'
import { compose } from 'recompose'

import { TRADING_TYPE } from 'emsurge-selectors/constants'
import { $U, PERSON_CLIENT_TYPE } from 'emsurge-selectors'
import { useQueryClient } from 'react-query'
import { getData } from 'screens/orderEdit/OrderEdit'
import FormProvider from 'screens/orderEdit/containers/FormProvider'
import ModalContent from 'screens/orderCreate/desktop/ModalContent'
import ModalHeader from 'screens/orderCreate/desktop/ModalHeader'
import { useOrderFlow, ORDER_FLOW } from 'containers/OrderProvider'
import withParams from 'utils/withRouteParams'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { useUser } from 'containers/user/useUser'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { useOrder } from 'api'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { ORDER_KEY } from 'api/constants'

const invertTradingType = (type) =>
  type === TRADING_TYPE.BID ? TRADING_TYPE.ASK : TRADING_TYPE.BID

const formatToSwap = (order) => ({
  ...order,
  id: null,
  shortId: null,
  createdAt: null,
  updatedAt: null,
  permission: null,
  termsheets: [],
  comments: [],
  details: undefined,
  tradingType: invertTradingType(order.tradingType),
})

export const OrderSwapCreate = ({ id, history, enqueueSnackbar }) => {
  const api = useApi()
  const { user } = useUser()
  const BASE_SLUG = useRouteBaseSlug()
  const { setFlow } = useOrderFlow()
  const { isLoading, data: order } = useOrder(id)
  const queryClient = useQueryClient()
  const onCancel = () => history.push(`/${BASE_SLUG}`)

  useEffect(() => setFlow(ORDER_FLOW.SWAP), [setFlow])

  const handleSubmit = async (order, values, actions) => {
    const data = {
      ...getData(values, user, order),
      swapId: order.id,
    }

    if (
      !values.brokerId &&
      $U.clientType.get(user) === PERSON_CLIENT_TYPE.BROKER
    ) {
      data.brokerId = null
    }

    try {
      const { data: swappedOrder } = await api.post(`/orders/${id}/swap`, {
        swapOrder: data,
      })
      enqueueSnackbar('Swap Leg order created successfully', {
        variant: 'success',
      })
      queryClient.invalidateQueries([ORDER_KEY, id]) // TODO: replace with mutation
      history.push(`/${BASE_SLUG}/orders/${swappedOrder.id}`)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Swap Leg order creation failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
      actions.setSubmitting(false)
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <FormProvider
      order={formatToSwap(order)}
      onSubmit={partial(handleSubmit, order)}
    >
      <>
        <ModalHeader
          showSaveAsTemplateOption={order.template}
          onCancel={onCancel}
        />
        <ModalContent />
      </>
    </FormProvider>
  )
}

OrderSwapCreate.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default compose(withParams, withSnackbar)(OrderSwapCreate)
