import React from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import CompanyForm from 'screens/companyCreate/companyForm/CompanyForm'
import { HOME } from 'routes'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const AddCompany = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()

  /* :: object -> Promise<void> */
  const handleFinish = async ({ companyAdmin, company, entity }) => {
    try {
      await api.post('/registration', { companyAdmin, company, entity })
      enqueueSnackbar('Registration successful', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Registration not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      history.push(HOME)
    }
  }

  return (
    <CompanyForm
      title="Add New Company"
      subTitle="This information can be changed at later date"
      onSubmit={handleFinish}
    />
  )
}

AddCompany.propTypes = {
  history: PropTypes.object.isRequired,
}

export default AddCompany
