import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  font-family: Montserrat, sans-serif;
`

const User = styled.div`
  font-weight: bold;
  font-size: 14px;
`

const Company = styled.div`
  font-size: 12px;
`

export const UserName = ({ classes, userName, companyName }) => (
  <Container className={classes.root}>
    <User data-testid="header-user-name">{userName}</User>
    <Company data-testid="header-company-name">{companyName}</Company>
  </Container>
)

UserName.propTypes = {
  userName: PropTypes.string,
  companyName: PropTypes.string,
  classes: PropTypes.object,
}

UserName.defaultProps = {
  classes: {},
}
