import deburr from 'lodash/deburr'

const getSuggestions = (suggestions, value) => {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length
  let count = 0

  const isEmpty = inputLength === 0

  if (isEmpty) {
    return []
  } else {
    return suggestions.filter((suggestion) => {
      const keep =
        count < 5 &&
        suggestion.slice(0, inputLength).toLowerCase() === inputValue

      if (keep) {
        count += 1
      }

      return keep
    })
  }
}

export { getSuggestions }
