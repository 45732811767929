import { string } from 'yup'
import { ENTITY_TYPE } from 'emsurge-selectors/constants'

const GeneralInformationFormSchema = {
  name: string().required('Required'),
  registrationName: string().required('Required'),
  registrationNumber: string().required('Required'),
  addressFirstLine: string().required('Required'),
  addressSecondLine: string(),
  postcode: string().required('Required'),
  city: string().required('Required'),
  country: string().required('Required'),
  code: string()
    .required('Required')
    .max(1, 'The entity code should have 1 character')
    .min(1, 'The entity code should have 1 character'),
  type: string().required('Required').oneOf(Object.values(ENTITY_TYPE)),
}

export { GeneralInformationFormSchema }
