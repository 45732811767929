import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { FormTitle, PrimaryEntityTitle } from '../components/Titles'
import * as AuthorisedSignatory from '../components/TradingEntities/AuthorisedSignatory/FormFields'
import {
  Name,
  RegistrationName,
  RegistrationNumber,
  AddressFirstLine,
  AddressSecondLine,
  Postcode,
  City,
  Country,
  Code,
  Type,
} from '../components/TradingEntities/GeneralInformation/FormFields'
import * as PrimaryPointContact from '../components/TradingEntities/PrimaryPointContact/FormFields'
import { BackButton, FinishButton } from '../components/Buttons'
import { ButtonsWrapper } from '../components/ButtonsWrapper'
import { getTradingEntityFormSchema } from '../components/TradingEntities/FormSchema'
import FormRow from 'components/formRow/FormRow'

const TradingEntityForm = ({
  initialValues,
  copyPrimaryEntity,
  handleBack,
  handleFinish,
  handleCopyPrimaryEntity,
}) => {
  const handleSubmit = (values, form) => {
    form.setSubmitting(false)
    handleFinish({
      form: 'entity',
      values: {
        ...values,
        pointOfContact: {
          ...values.pointOfContact,
        },
      },
    })
    form.resetForm()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getTradingEntityFormSchema(initialValues)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <PrimaryEntityTitle>Primary Entity</PrimaryEntityTitle>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="copy-information-checkbox"
                checked={copyPrimaryEntity}
                onChange={() => handleCopyPrimaryEntity(!copyPrimaryEntity)}
                color="primary"
              />
            }
            label="Copy information from above if same as parent company"
          />
          <FormTitle>General Information</FormTitle>
          <FormRow>
            <Name />
            <RegistrationName />
          </FormRow>
          <FormRow>
            <RegistrationNumber />
            <Type />
            <Code />
          </FormRow>
          <FormRow>
            <AddressFirstLine />
            <AddressSecondLine />
          </FormRow>
          <FormRow>
            <Postcode />
            <City />
            <Country />
          </FormRow>
          <FormTitle>Primary Point of Contact</FormTitle>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <PrimaryPointContact.Name />
            </Grid>
            <Grid item xs={5}>
              <PrimaryPointContact.Surname />
            </Grid>
            <Grid item xs>
              <PrimaryPointContact.JobTitle />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <PrimaryPointContact.Entity />
            </Grid>
            <Grid item xs={4}>
              <PrimaryPointContact.Email />
            </Grid>
            <Grid item xs>
              <PrimaryPointContact.Phone />
            </Grid>
          </Grid>
          <FormRow>
            <PrimaryPointContact.AddressFirstLine />
            <PrimaryPointContact.AddressSecondLine />
          </FormRow>
          <FormRow>
            <PrimaryPointContact.Postcode />
            <PrimaryPointContact.City />
            <PrimaryPointContact.Country />
          </FormRow>
          <FormTitle>Authorised Signatory</FormTitle>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <AuthorisedSignatory.Name />
            </Grid>
            <Grid item xs={5}>
              <AuthorisedSignatory.Surname />
            </Grid>
            <Grid item xs>
              <AuthorisedSignatory.JobTitle />
            </Grid>
          </Grid>
          <FormRow>
            <AuthorisedSignatory.Email />
            <AuthorisedSignatory.Phone />
          </FormRow>
          <ButtonsWrapper>
            <BackButton
              onClick={() => {
                handleBack({ form: 'tradingEntity', values })
              }}
            />
            <FinishButton />
          </ButtonsWrapper>
        </Form>
      )}
    </Formik>
  )
}

TradingEntityForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  copyPrimaryEntity: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleCopyPrimaryEntity: PropTypes.func.isRequired,
}

export default TradingEntityForm
