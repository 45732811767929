const { VENUE_OPTIONS_PREFIX } = require('./carbonPrefix')

const VENUES = Object.freeze({
  OTC: 'OTC',
  CBL: 'CBL',
  CIX: 'CIX',
  ACX: 'ACX',
  CTX: 'CTX',
})

const VENUE_OPTIONS = Object.keys(VENUES).map((cert) => ({
  label: VENUES[cert],
  value: `${VENUE_OPTIONS_PREFIX}.${cert}`,
}))

const VENUE_FULL_KEYS = {}
Object.keys(VENUES).forEach((key) => {
  VENUE_FULL_KEYS[key] = `${VENUE_OPTIONS_PREFIX}.${key}`
})

const VENUE_OPTIONS_PATHS = Object.keys(VENUES).map(
  (cert) => `${VENUE_OPTIONS_PREFIX}.${cert}`
)

module.exports = {
  VENUE_OPTIONS,
  VENUE_FULL_KEYS,
  VENUES,
  VENUE_OPTIONS_PATHS,
}
