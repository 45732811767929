import moment from 'moment'
import { isInteger } from 'lodash'
import { PARTY_TEXT, PERSON_PERMISSION, TRADING_TYPE } from 'emsurge-selectors/constants'
import { getNumberWithOrdinalSuffix } from 'utils/numbers'
import { convertDateToCurrentTimestamp } from 'utils/dates'
import { calculateCargoDeliverWindow } from 'utils/dataHandlers'

import { $O } from 'model/order/selectors/selector'
import { useUserHasPermission } from 'permissions'

export const getOrderCounterParty = ({ orders }, order) =>
  orders.find((counterPartyOrder) => counterPartyOrder.id === order.id)

const getRelevantDw = (dw = '') => {
  const tokens = dw.match(/\w+/g)
  return `${tokens?.[2]} ${tokens?.[3]}`
}

export const getTimeFrameLabel = (
  timeframe,
  timeframeValue,
  timeframeValueDeliveryWindow
) => {
  switch (timeframe) {
    case 'fixed_date': {
      const formatedTimeFrame = moment(timeframeValue).format('DD MMM YYYY')
      return `no later than ${formatedTimeFrame}`
    }
    case 'point_trade':
      return 'at the point of trade'
    case 'days_ahead':
      return timeframeValue
        ? `no later than ${timeframeValue} days prior to the start of the first day of the ${getRelevantDw(
            timeframeValueDeliveryWindow
          )} Delivery Window`
        : ''
    case 'month_ahead':
      return timeframeValue
        ? `no later than the ${getNumberWithOrdinalSuffix(
            timeframeValue
          )} day of m-1, where m is the scheduled delivery month`
        : ''
    case 'days_after':
      return timeframeValue
        ? `${timeframeValue} days after the point of trade`
        : ''
    default:
      return ''
  }
}

export const getDeliveryWindowTimeFrameLabel = (
  {
    name: newSize = '',
    timeframe,
    timeframeValue,
    timeframeValueDeliveryWindow,
    party,
  },
  mainCargo,
  previousNarrowing = null
) => {
  const partyLabel = PARTY_TEXT[party] || PARTY_TEXT.TBA
  const { interval, periodName } = calculateCargoDeliverWindow(mainCargo)
  let deliveryWindow = `${interval} ${periodName}`

  if (previousNarrowing) {
    deliveryWindow = `${previousNarrowing} Day`
  }

  const narrowPrefix = `The ${partyLabel} shall narrow the ${deliveryWindow} DW to a ${newSize} Day DW`

  switch (timeframe) {
    case 'fixed_date': {
      /** Change UTC time to user local timezone time */
      const dateInCurrentTimezone =
        convertDateToCurrentTimestamp(timeframeValue)
      const formatedTimeFrame = moment(dateInCurrentTimezone).format(
        'YYYY-MM-DD HH:mm'
      )
      return `${narrowPrefix} no later than ${formatedTimeFrame}`
    }
    case 'point_trade':
      return `${narrowPrefix} at the point of trade`
    case 'days_ahead':
      return `${narrowPrefix} at ${timeframeValue} Days prior to the start of the ${getRelevantDw(
        timeframeValueDeliveryWindow
      )} Delivery Window`
    case 'days_after':
      return `${narrowPrefix} at ${timeframeValue} Days after the point of trade`
    case 'month_ahead':
      return `${narrowPrefix} no later than ${getNumberWithOrdinalSuffix(
        timeframeValue
      )} day of m-1, where m is the scheduled delivery month`
    default:
      return ''
  }
}

export const getPartyLabel = (party) => {
  return `${party ? PARTY_TEXT[party] : 'TBA'} shall nominate the`
}

export const getSizeLabel = (size = {}, type = 'single') => {
  if (isInteger(size.value) && type === 'single') {
    return `${size.value}m³`
  }

  if (isInteger(size.min) && isInteger(size.max) && type === 'range') {
    return `Min: ${size.min}m³ Max: ${size.max}m³`
  }

  return ''
}

export const getVesselType = (type) =>
  type === 'vessel_size' ? 'Vessel Size' : 'Vessel Capacity'

export const useUserCanCounterOrder = ({ order }) => {
  const hasPermission = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.WRITE,
  })

  return $O.status.isOutToMarket(order) && hasPermission && !order.template
}

export const getChecklistProgress = (order) => {
  if (!$O.checklist.exists(order)) {
    return ''
  }

  const checklistItems = $O.checklist.get(order)
  const completedChecklistItems = checklistItems.filter(
    (checklistItem) => !!checklistItem.dateChecked
  )

  return `${completedChecklistItems.length}/${checklistItems}`
}

export const getOrderOrTermsheetTitle = (order) => {
  let text
  if (order.template) {
    text = 'template'
  } else {
    text = !order.orders ? 'order' : 'term sheet'
  }

  return text
}

export const getPartyFromOrderInTermsheet = (order, termsheet) => {
  let result
  if(order.tradingType === TRADING_TYPE.BID && termsheet.bid && termsheet.bid.companyName)
  {
    result = getPartyFromTermsheet(termsheet.bid)
  }
  else if(order.tradingType === TRADING_TYPE.ASK && termsheet.ask && termsheet.ask.companyName)
  {
    result = getPartyFromTermsheet(termsheet.ask)
  }
  else if(order.thirdParty) {
    result = {
      company : { name: order.thirdParty.companyName },
      entity : { name: order.thirdParty.entityName },
      person: { name: order.thirdParty.contact },
    }
  }
  else {
    const {company, entity, person } = order
    result = {
      company: {id: company.id, name: company.name, code: company.code },
      entity: {id: entity.id, name: entity.name, code: entity.code },
      person: {id: person.id, name: person.name },
    }
  }
  return result
}

const getPartyFromTermsheet = (termsheetPartyInformation) =>
{
  const result = {
    company : { name: termsheetPartyInformation.companyName, id: termsheetPartyInformation.companyId, code: termsheetPartyInformation.companyCode },
    entity : { name: termsheetPartyInformation.entityName, id: termsheetPartyInformation.entityId, code: termsheetPartyInformation.entityCode },
    person: { name: termsheetPartyInformation.personName, id: termsheetPartyInformation.personId },
  }
  return result
}
