import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const getBreakpoint = ({ matchSmaller, matchLarger, breakpoints }) => {
  if (matchSmaller) {
    return breakpoints.down('sm')
  }
  if (matchLarger) {
    return breakpoints.up('sm')
  }
  return breakpoints.only('sm')
}

export const useIsTablet = ({ matchSmaller, matchLarger }) => {
  const { breakpoints } = useTheme()
  return useMediaQuery(
    getBreakpoint({ matchSmaller, matchLarger, breakpoints })
  )
}

export const Tablet = ({ children, matchSmaller, matchLarger }) => {
  const isTablet = useIsTablet({ matchSmaller, matchLarger })
  return isTablet ? children : null
}

Tablet.defaultProps = {
  children: [],
  matchSmaller: false,
  matchLarger: false,
}

Tablet.propTypes = {
  children: PropTypes.node,
  matchSmaller: PropTypes.bool,
  matchLarger: PropTypes.bool,
}
