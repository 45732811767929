const {
  CARGO_SIZE_TOLERANCE_FACTOR,
  CARGO_SIZE_UNIT_MMBTU,
  CARGO_SIZE_UNIT,
} = require('../../../constants')

const unit = require('./unit')
const size = require('./size')
const variance = require('./variance')
const party = require('./party')

const getToleranceValue = (size = 0, variance = 0) => {
  const percent = (100 + variance) / 100

  return size * percent
}

const getTolerance = (order) => {
  const { min = 0, max = 0 } = size.get(order)
  const { minus = 0, plus = 0 } = variance.get(order)

  const tolerance = {
    min: getToleranceValue(min, minus * -1),
    max: getToleranceValue(max || min, plus),
  }

  return tolerance
}

const getToleranceText = (order) => {
  const tolerance = getTolerance(order)
  const unitText = unit.text(order)
  let toleranceText = []

  if (tolerance.min === 0 && tolerance.max === 0) {
    return ''
  }

  if (tolerance.min !== undefined) {
    toleranceText.push(tolerance.min.toFixed(2))
  }

  if (tolerance.max !== undefined) {
    toleranceText.push(tolerance.max.toFixed(2))
  }

  toleranceText = toleranceText.join(' - ')

  if (toleranceText) {
    toleranceText += ` ${unitText}`
  }

  return toleranceText.trim()
}

const getToleranceSummary = (order) => {
  const tolerance = getTolerance(order)
  const unitText = unit.text(order)
  const toleranceSummary = {}

  if (tolerance.min === 0 && tolerance.max === 0) {
    return toleranceSummary
  }

  if (tolerance.min !== undefined) {
    toleranceSummary.min = `${tolerance.min.toFixed(2)} ${unitText}`
  }

  if (tolerance.max !== undefined) {
    toleranceSummary.max = `${tolerance.max.toFixed(2)} ${unitText}`
  }

  return toleranceSummary
}

const getToleranceContractSummary = (order) => {
  const partyText = party.text(order)
  const varianceText = variance.text(order)
  const cargoSizeUnit = unit.get(order)
  let toleranceText = ''

  if (cargoSizeUnit === CARGO_SIZE_UNIT.TBTU) {
    const tolerance = getTolerance(order)

    if (tolerance.min !== 0 || tolerance.max !== 0) {
      const min = (tolerance.min * CARGO_SIZE_TOLERANCE_FACTOR).toLocaleString()
      const max = (tolerance.max * CARGO_SIZE_TOLERANCE_FACTOR).toLocaleString()

      toleranceText = `${min} - ${max} ${CARGO_SIZE_UNIT_MMBTU}`
    }
  } else {
    toleranceText = getToleranceText(order)
  }

  return {
    variance:
      partyText && varianceText
        ? `${partyText}'s Operational Tolerance to be ${varianceText} of the nominated Contract Quantity`
        : '',
    tolerance: toleranceText
      ? `(${toleranceText} including Operational Tolerance)`
      : '',
  }
}

module.exports = {
  get: getTolerance,
  text: getToleranceText,
  summary: getToleranceSummary,
  contract: {
    summary: getToleranceContractSummary,
  },
}
