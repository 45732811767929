import { range, get, times } from 'lodash'
import moment from 'moment'
import {
  formatDate,
  formatDateRanges,
  getTotalCargos,
  isDeliveryWindowPerCargo,
  getDeliveryWindowRanges,
  getDeliveryPeriodRange,
  SPECIFIC_DATES_DELIVERY_OPTION,
  MONTHS,
  SEASONS,
  QUARTERS,
  isWeekStr,
  HALF_OPTIONS,
  GENERAL_OPTIONS,
  getWinterMonths,
  getSummerMonths,
  isCompleteMonthsRange,
  isMonthPeriod,
  isCustomDeliveryPeriod,
} from './DeliveryWindow.helpers'

/*
 * Derives the default date ranges for cargos based on the default delivery window period.
 *
 * @param values: create order form values
 *
 * @returns cargoes date ranges
 */
export const getCargoesDateRanges = (values) => {
  const periodRange = getDeliveryPeriodRange(values.delivery)
  const totalCargos = getTotalCargos(values)
  const { frequencyDistribution } = values.delivery
  const spreadRange = frequencyDistribution === 'x_days_or_months'
  const evenMonthsOnly = frequencyDistribution === 'even_months'
  const oddMonthsOnly = frequencyDistribution === 'odd_months'

  return getDeliveryWindowRanges(
    periodRange,
    SPECIFIC_DATES_DELIVERY_OPTION,
    totalCargos,
    { evenMonthsOnly, oddMonthsOnly, spreadRange }
  )
}

/*
 * Derive the select options for delivery window cargos.
 */
export const getCargoDeliveryWindowOptions = ({
  cargoRange,
  periodRange,
  exclusions = [],
  spreadWeeks = true,
  delivery,
}) => {
  let options = [SPECIFIC_DATES_DELIVERY_OPTION, ...HALF_OPTIONS]

  if (
    isMonthPeriod(delivery.period) ||
    isCustomDeliveryPeriod(delivery.period)
  ) {
    options = [...options, ...GENERAL_OPTIONS]
  }
  // weeks
  if (!exclusions.includes('weeks')) {
    const allPeriodWeeks = times(
      periodRange.to.diff(periodRange.from, 'days') / 7
    ).map((weekIndex) => ({
      label: `W${weekIndex + 1}`,
      from: moment(periodRange.from).add(7 * weekIndex, 'days'),
      to: moment(periodRange.from)
        .add(7 * (weekIndex + 1), 'days')
        .subtract(1, 'day'),
    }))
    const cargoWeeks = spreadWeeks
      ? allPeriodWeeks.filter(
          (periodWeek) =>
            periodWeek.from.isBetween(
              cargoRange.from,
              cargoRange.to,
              'day',
              '[]'
            ) &&
            periodWeek.to.isBetween(cargoRange.from, cargoRange.to, 'day', '[]')
        )
      : allPeriodWeeks
    options = [...options, ...cargoWeeks.map((cargoWeek) => cargoWeek.label)]
  }

  // Months options
  const startMonth = moment(cargoRange.from).month()
  const startMonthYear = moment(cargoRange.from).year()
  const endMonth = moment(cargoRange.to).month()
  const endMonthYear = moment(cargoRange.to).year()
  const numberOfYears = endMonthYear - startMonthYear

  const monthsToAdd = []

  if (numberOfYears > 0) {
    monthsToAdd.push(...range(startMonth, 12).map((i) => MONTHS[i]))
    monthsToAdd.push(...range(0, endMonth + 1).map((i) => MONTHS[i]))
  } else {
    monthsToAdd.push(...range(startMonth, endMonth + 1).map((i) => MONTHS[i]))
  }

  if (
    monthsToAdd.length === 0 &&
    isCompleteMonthsRange(cargoRange.from, cargoRange.to)
  ) {
    monthsToAdd.push(MONTHS[startMonth])
  }

  options = [...options, ...monthsToAdd]

  // Quarter options
  QUARTERS.forEach((quarter, quarterIndex) => {
    const quarterMonths = MONTHS.filter((_, monthIndex) => {
      const index = quarterIndex * 3
      return [index, index + 1, index + 2].includes(monthIndex)
    })

    const periodHaveAllQuarterMonths = quarterMonths.every((month) =>
      monthsToAdd.includes(month)
    )

    if (periodHaveAllQuarterMonths) {
      options.push(quarter)
    }
  })

  // Seasons options
  const summerMonths = getSummerMonths()
  if (summerMonths.every((month) => monthsToAdd.includes(month))) {
    options.push(SEASONS[0])
  }

  const winterMonths = getWinterMonths()
  if (winterMonths.every((month) => monthsToAdd.includes(month))) {
    options.push(SEASONS[1])
  }

  return options
}

/*
 * Derives the equivalent delivery window option for one of the other cargoes based on the delivery window selection in the
 * main cargo.
 *
 * @param newMainCargoDeliveryWindowOption: new delivery window option selected in the main cargo
 * @param otherCargoDeliveryWindowOptions: current delivery window options available for the other cargo
 * @param values: create order form values
 *
 * @returns the equivalent option for the other cargo
 */
export const getEquivalentDeliveryWindowOption = ({
  newMainCargoDeliveryWindowOption,
  mainCargoDeliveryWindowOptions,
  otherCargoDeliveryWindowOptions,
}) => {
  const getClosest = (otherOptions, index) => {
    if (index === -1) {
      return undefined
    }

    if (otherOptions[index]) {
      return otherOptions[index]
    }

    return getClosest(otherOptions, index - 1)
  }

  if (MONTHS.includes(newMainCargoDeliveryWindowOption)) {
    const isMonth = (option) => MONTHS.includes(option)
    const mainCargoMonthsIndex = mainCargoDeliveryWindowOptions
      .filter(isMonth)
      .findIndex(
        (monthOption) => monthOption === newMainCargoDeliveryWindowOption
      )
    const otherCargoMonths = otherCargoDeliveryWindowOptions.filter(isMonth)
    const otherCargoMonth =
      otherCargoMonths[mainCargoMonthsIndex] !== undefined
        ? otherCargoMonths[mainCargoMonthsIndex]
        : getClosest(otherCargoMonths, mainCargoMonthsIndex)
    return otherCargoMonth
  }

  if (QUARTERS.includes(newMainCargoDeliveryWindowOption)) {
    const isQuarter = (option) => QUARTERS.includes(option)
    const mainCargoQuartersIndex = mainCargoDeliveryWindowOptions
      .filter(isQuarter)
      .findIndex(
        (quarterOption) => quarterOption === newMainCargoDeliveryWindowOption
      )
    const otherCargoQuarters = otherCargoDeliveryWindowOptions.filter(isQuarter)
    const otherCargoQuarter =
      otherCargoQuarters[mainCargoQuartersIndex] !== undefined
        ? otherCargoQuarters[mainCargoQuartersIndex]
        : getClosest(otherCargoQuarters, mainCargoQuartersIndex)
    return otherCargoQuarter
  }

  if (SEASONS.includes(newMainCargoDeliveryWindowOption)) {
    const otherCargoSeason = otherCargoDeliveryWindowOptions.find((option) =>
      SEASONS.includes(option)
    )

    if (otherCargoSeason) {
      return otherCargoSeason
    }
    return otherCargoDeliveryWindowOptions.find((option) =>
      MONTHS.includes(option)
    )
  }

  if (isWeekStr(newMainCargoDeliveryWindowOption)) {
    const mainCargoWeekIndex = mainCargoDeliveryWindowOptions
      .filter(isWeekStr)
      .findIndex(
        (weekOption) => weekOption === newMainCargoDeliveryWindowOption
      )
    const otherCargoWeeks = otherCargoDeliveryWindowOptions.filter(isWeekStr)
    const otherCargoWeek =
      otherCargoWeeks[mainCargoWeekIndex] !== undefined
        ? otherCargoWeeks[mainCargoWeekIndex]
        : getClosest(otherCargoWeeks, mainCargoWeekIndex)
    return otherCargoWeek
  }
}

export const isValidDate = (ev) => {
  const date = get(ev[0], 'target.value')
  const dayMonthYearLength = 10
  return date && date.length === dayMonthYearLength
}

/*
 * Gets a new cargo date range based in the new delivery option selected.
 */
const getNewCargo = (
  cargo,
  newDeliveryOption,
  frequencyDistribution,
  periodRange
) => {
  const numberOfRanges = 1
  const spreadRange = frequencyDistribution === 'x_days_or_months'

  // If the delivery window option is a week number we need to use all period range
  // because the week are counter from the start of the period until the end and not
  // reset every month
  const baseRange = isWeekStr(newDeliveryOption) ? periodRange : cargo

  const [newRange] = getDeliveryWindowRanges(
    { from: moment(baseRange.from), to: moment(baseRange.to) },
    newDeliveryOption,
    numberOfRanges,
    { spreadRange }
  )

  return {
    ...newRange,
    cargoWindow: newDeliveryOption,
  }
}

/*
 * Derive the initial cargoes date ranges.
 */
const getInitialCargoesDateRanges = ({ values }) => {
  const periodRange = getDeliveryPeriodRange(values.delivery)
  const totalCargoes = getTotalCargos(values)
  const { frequencyDistribution } = values.delivery
  const evenMonthsOnly = frequencyDistribution === 'even_months'
  const oddMonthsOnly = frequencyDistribution === 'odd_months'
  const spreadRange = frequencyDistribution === 'x_days_or_months'
  return getDeliveryWindowRanges(
    periodRange,
    SPECIFIC_DATES_DELIVERY_OPTION,
    totalCargoes,
    { evenMonthsOnly, spreadRange, oddMonthsOnly }
  )
}

/*
 * Set the initial cargoes cargoes date ranges.
 */
export const setCargoesDateRangeFields = ({ values, setFieldValue }) => {
  const cargoesRanges = formatDateRanges(
    getInitialCargoesDateRanges({ values })
  ).map((cargo) => ({ ...cargo, cargoWindow: SPECIFIC_DATES_DELIVERY_OPTION }))
  setFieldValue('nominationRules.deliveryWindow.cargos', cargoesRanges)
}

/*
 * Updates the other cargoes (all cargoes except the first one) to the same delivery window
 * selected in the main cargo (the first cargo of the list).
 *
 * @param cargoIndex: index of the cargo updated
 * @param newDeliveryWindowOption: new delivery window option selected
 * @param values: create order form values
 * @param setFieldValue: formik helper method
 *
 */
export const setAllCargosDW = ({
  cargoIndex,
  newDeliveryWindowOption,
  mainCargoDeliveryWindowOptions,
  values,
  setFieldValue,
}) => {
  const isMainCargo = cargoIndex === 0
  const originalCargoes = getInitialCargoesDateRanges({ values })
  let newCargos = []
  const { frequencyDistribution } = values.delivery
  const periodRange = getDeliveryPeriodRange(values.delivery)

  if (
    isMainCargo &&
    !isDeliveryWindowPerCargo(values.nominationRules.deliveryWindow)
  ) {
    const newMainCargo = {
      cargoWindow: newDeliveryWindowOption,
      ...getNewCargo(
        originalCargoes[0],
        newDeliveryWindowOption,
        frequencyDistribution,
        periodRange
      ),
    }
    const otherCargoesAvailable = originalCargoes.length > 1
    newCargos.push(newMainCargo)

    if (otherCargoesAvailable) {
      const otherOriginalCargoes = originalCargoes.slice(1)
      const newOtherCargoes = otherOriginalCargoes.map((cargoRange) => {
        const periodRange = getDeliveryPeriodRange(values.delivery)
        const otherCargoDeliveryWindowOptions = getCargoDeliveryWindowOptions({
          cargoRange,
          periodRange,
          delivery: values.delivery,
        })
        if (otherCargoDeliveryWindowOptions.includes(newDeliveryWindowOption)) {
          return getNewCargo(
            cargoRange,
            newDeliveryWindowOption,
            frequencyDistribution,
            periodRange
          )
        }
        const closestDeliveryWindow =
          getEquivalentDeliveryWindowOption({
            newMainCargoDeliveryWindowOption: newDeliveryWindowOption,
            mainCargoDeliveryWindowOptions,
            otherCargoDeliveryWindowOptions,
          }) || SPECIFIC_DATES_DELIVERY_OPTION
        return getNewCargo(
          cargoRange,
          closestDeliveryWindow,
          frequencyDistribution,
          periodRange
        )
      })
      newCargos = newCargos.concat(newOtherCargoes)
    }
  } else {
    newCargos = values.nominationRules.deliveryWindow.cargos.map(
      (cargo, index) => {
        if (index !== cargoIndex) {
          return cargo
        }
        return getNewCargo(
          originalCargoes[index],
          newDeliveryWindowOption,
          frequencyDistribution,
          periodRange
        )
      }
    )
  }
  setFieldValue(
    'nominationRules.deliveryWindow.cargos',
    formatDateRanges(newCargos)
  )
}

const getDays = (dateStr) =>
  parseInt(dateStr.substring(dateStr.lastIndexOf('-') + 1), 10)
const getMonth = (dateStr) =>
  parseInt(
    dateStr.substring(dateStr.lastIndexOf('-') - 2, dateStr.lastIndexOf('-')),
    10
  )
const getYear = (dateStr) =>
  parseInt(dateStr.substring(0, dateStr.indexOf('-')), 10)

const isValidNewDate = (dateStr) => {
  const dateIsAfter1900 = moment(dateStr, 'YYYY-MM-DD') > moment('1900', 'YYYY')
  const newYearIsValid = `${getYear(dateStr)}`.length === 4
  const newMonthIsValid =
    `${getMonth(dateStr)}`.length === 2 || `${getMonth(dateStr)}`.length === 1
  const newDayIsValid =
    `${getDays(dateStr)}`.length === 2 || `${getDays(dateStr)}`.length === 1

  if (
    !newYearIsValid ||
    !newMonthIsValid ||
    !newDayIsValid ||
    !dateIsAfter1900
  ) {
    return false
  }

  return true
}

/*
 * Updates the other cargoes (all cargoes except the first one) date ranges according
 * the changes in the main cargo (the first one of the list).
 *
 * @param dateFieldName: can be 'from' or 'to'
 * @param cargoIndex: index of the cargo updated
 * @param newValue: new date (in From or To field) typed on the main cargo
 * @param values: create order form values
 * @param setFieldValue: formik helper method
 *
 */
export const setAllCargosDates = ({
  dateFieldName,
  cargoIndex,
  newValue,
  values,
  setFieldValue,
}) => {
  const isMainCargo = cargoIndex === 0
  const otherCargoesAvailable =
    values.nominationRules.deliveryWindow.cargos.length > 1

  if (
    !isMainCargo ||
    !otherCargoesAvailable ||
    isDeliveryWindowPerCargo(values.nominationRules.deliveryWindow)
  ) {
    return
  }

  const newMainCargo = {
    ...values.nominationRules.deliveryWindow.cargos[0],
    [dateFieldName]: formatDate(moment(newValue, 'YYYY-MM-DD')),
  }

  const allCargoesRangesAreValid = [
    newMainCargo,
    ...values.nominationRules.deliveryWindow.cargos.slice(1),
  ].every(
    (cargo) =>
      moment(cargo.from, 'YYYY-MM-DD').isValid() &&
      moment(cargo.to, 'YYYY-MM-DD').isValid()
  )

  if (!isValidNewDate(newValue) || !allCargoesRangesAreValid) {
    return
  }

  const originalCargoes = getInitialCargoesDateRanges({ values })
  const currentOtherCargoes =
    values.nominationRules.deliveryWindow.cargos.slice(1)

  // Gets the order cargoes date ranges with the datefield being edit to
  // the original value to be able to be changed in next lines and maintains
  // the state values of the fields not being edited
  let otherCargoes = originalCargoes.slice(1).map((original, index) => ({
    ...currentOtherCargoes[index],
    [dateFieldName]: original[dateFieldName],
  }))

  const yearChanged =
    getYear(formatDate(originalCargoes[0][dateFieldName])) !== getYear(newValue)
  const monthChanged =
    getMonth(formatDate(originalCargoes[0][dateFieldName])) !==
    getMonth(newValue)

  const changeDatesAccordingMainCargo = (cargoes, amount, units) =>
    cargoes.map((cargo) => ({
      ...cargo,
      [dateFieldName]: moment(get(cargo, dateFieldName))[
        amount > 0 ? 'add' : 'subtract'
      ](Math.abs(amount), units),
    }))

  const changeDaysAccordingMainCargo = (cargoes, newDate) =>
    cargoes.map((cargo) => {
      const currentDate = get(cargo, dateFieldName)
      const newDateDay = moment(newDate).date()
      const endOfCurrentMonth = get(cargo, dateFieldName).endOf('month')
      const lastDayOfCurrentMonth = endOfCurrentMonth.date()
      const shouldChangeCurrentDay =
        currentDate.isSame(endOfCurrentMonth) &&
        newDateDay >= lastDayOfCurrentMonth

      return {
        ...cargo,
        [dateFieldName]: shouldChangeCurrentDay
          ? currentDate
          : moment(currentDate).date(newDateDay),
      }
    })

  if (yearChanged) {
    const yearsToAdd =
      getYear(newValue) - getYear(formatDate(originalCargoes[0][dateFieldName]))
    otherCargoes = changeDatesAccordingMainCargo(
      otherCargoes,
      yearsToAdd,
      'years'
    )
  }

  if (monthChanged) {
    const monthsToAdd =
      getMonth(newValue) -
      getMonth(formatDate(originalCargoes[0][dateFieldName]))
    otherCargoes = changeDatesAccordingMainCargo(
      otherCargoes,
      monthsToAdd,
      'months'
    )
  }

  otherCargoes = changeDaysAccordingMainCargo(otherCargoes, newValue)

  const newCargoes = [
    newMainCargo,
    ...otherCargoes.map((cargo) => ({
      ...cargo,
      [dateFieldName]: formatDate(cargo[dateFieldName]),
    })),
  ]

  setFieldValue('nominationRules.deliveryWindow.cargos', newCargoes)
}

export const getCargoOptions = ({ volume, delivery, nominationRules }) => {
  const { deliveryWindow } = nominationRules
  const resetCargoes = getCargoesDateRanges({ volume, delivery })
  const periodRange = getDeliveryPeriodRange(delivery)
  const options = resetCargoes.map((cargoRange) =>
    getCargoDeliveryWindowOptions({
      cargoRange,
      periodRange,
      spreadWeeks: !isDeliveryWindowPerCargo(deliveryWindow),
      delivery,
      exclusions:
        !isMonthPeriod(delivery.period) &&
        !isCustomDeliveryPeriod(delivery.period)
          ? ['weeks']
          : [],
    })
  )

  return options
}

const MAIN_CARGO = 1

export const isDisabled = (index, deliveryWindow) =>
  index > MAIN_CARGO && !isDeliveryWindowPerCargo(deliveryWindow)
