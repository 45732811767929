import React, { memo } from 'react'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import { BlockContent } from '../Block'
import SearchForm from '../SearchForm'
import MyOrders from './MyOrders'
import { filterAllOrders, filterMyOrders } from './helpers'
import { OrdersSkeleton } from './OrdersSkeleton'
import { TabsWrapper } from 'screens/orderView/components/ModalHeader'
import {
  ALL_ORDERS_TAB,
  MY_ORDERS_TAB,
} from 'screens/orderIndex/containers/OrdersAndTemplates.helper'
import { useUser } from 'containers/user/useUser'

const TAB_LABELS = {
  [ALL_ORDERS_TAB]: 'All',
  [MY_ORDERS_TAB]: 'My Orders',
}

// TODO: (refactor) remove prop drilling from the parent all the way down to the children of this component
const Orders = ({
  tab,
  setTab,
  isLoading,
  orders,
  loadNextPage,
  hasNextPage,
  onFilterChange,
}) => {
  const { user } = useUser()
  const handleTabChange = (_, newTab) => {
    if (newTab !== tab) {
      // TODO: (EM-919) this shouldn't be necessary once the prop drilling is sorted
      setTab(newTab)
    }
  }

  let filteredOrders

  switch (tab) {
    case ALL_ORDERS_TAB:
      filteredOrders = filterAllOrders(orders, user)
      break
    case MY_ORDERS_TAB:
      filteredOrders = filterMyOrders(orders, user)
      break
    default:
      filteredOrders = []
      break
  }

  return (
    <>
      <TabsWrapper>
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          value={tab}
          onChange={handleTabChange}
        >
          <Tab
            data-testid="orders-panel-all-orders"
            label={TAB_LABELS[ALL_ORDERS_TAB]}
          />
          <Tab
            data-testid="orders-panel-my-orders"
            label={TAB_LABELS[MY_ORDERS_TAB]}
          />
        </Tabs>
      </TabsWrapper>

      <SearchForm
        inputProps={{ 'data-testid': 'orders-search' }}
        onChange={(evt) => onFilterChange(evt.target.value)}
      />

      <BlockContent>
        {isLoading ? (
          <OrdersSkeleton count={2} />
        ) : (
          <MyOrders
            orders={filteredOrders}
            loadNextPage={loadNextPage}
            hasNextPage={hasNextPage}
          />
        )}
      </BlockContent>
    </>
  )
}

Orders.defaultProps = {
  tab: ALL_ORDERS_TAB,
  setTab: () => {},
  isLoading: false,
  orders: [],
  onFilterChange: () => {},
}

Orders.propTypes = {
  tab: PropTypes.number,
  setTab: PropTypes.func,
  isLoading: PropTypes.bool,
  orders: PropTypes.array,
  loadNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  onFilterChange: PropTypes.func,
}

export default memo(Orders)
