import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import {
  TERM_SPOT_VINTAGE_PROJECT_INFORMATION,
  PROJECT_INFORMATION_OPTIONS,
  PROJECT_INFORMATION_KEYS,
} from 'emsurge-selectors'
import { CheckboxGroup } from './CheckboxGroup'

const _ProjectInformation = ({ onlyActive }) => {
  const options = !onlyActive
    ? PROJECT_INFORMATION_OPTIONS
    : PROJECT_INFORMATION_OPTIONS.filter(
        (project) => project.key === PROJECT_INFORMATION_KEYS.ACTIVE
      )
  options.map((opt) => {
    opt.disabled = onlyActive
    return opt
  })

  return (
    <CheckboxGroup
      key="radio-button-group-project-status"
      label="Project status"
      fieldPrefix={TERM_SPOT_VINTAGE_PROJECT_INFORMATION}
      options={options}
    />
  )
}

_ProjectInformation.defaultProps = {
  onlyActive: false,
}

_ProjectInformation.propTypes = {
  onlyActive: PropTypes.bool,
}

export const ProjectInformation = connect(_ProjectInformation)
