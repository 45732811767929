import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { chain, get, isEmpty } from 'lodash'
import { connect } from 'formik'

import TextField from '@material-ui/core/TextField'
import { PERSON_CLIENT_TYPE } from 'emsurge-selectors'
import { useUpdatedValues } from './useUpdatedValues'
import { useFetch } from 'containers/fetch/useFetch'
import { useUser } from 'containers/user/useUser'
import { userHasTradePermission } from 'permissions'

const Select = connect(
  ({ disabled, label, name, onChange, options, formik, ...textProps }) => {
    const setValueWhenNotEmpty = (options, key) => {
      return isEmpty(options) ? '' : get(formik.values, key, '')
    }

    const setError = (key) => {
      return formik.submitCount > 0 && !get(formik.values, key)
    }

    const isDisabled = (options) => {
      return disabled || isEmpty(options)
    }

    return (
      <TextField
        data-testid={textProps?.['data-testid'] && textProps['data-testid']}
        select
        required
        label={label}
        value={setValueWhenNotEmpty(options, name)}
        onChange={onChange}
        disabled={isDisabled(options)}
        error={setError(name)}
        fullWidth
      >
        {chain(options || [])
          .sortBy('name')
          .value()
          .map(({ id, name }) => (
            <MenuItem
              data-testid={
                textProps?.['data-testid'] &&
                `${textProps['data-testid']}-values`
              }
              key={id}
              value={id}
            >
              {name}
            </MenuItem>
          ))}
      </TextField>
    )
  }
)

Select.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
}

Select.defaultProps = {
  disabled: false,
  options: [],
  onChange: () => {},
}

const OnSystemByBroker = connect(({ userId, formik }) => {
  const [companyId, setCompanyId] = useState(undefined)
  const [entityId, setEntityId] = useState(undefined)
  const entities = useUpdatedValues(
    companyId,
    `/brokers/${userId}/entities?companyId=${companyId}`
  )
  const traders = useUpdatedValues(
    entityId,
    `/brokers/${userId}/persons?entityId=${entityId}`
  )

  const { data: companies = [] } = useFetch(`/brokers/${userId}/companies`)

  const updateCompanyId = (newCompanyId) => {
    setCompanyId(newCompanyId)
    formik.setFieldValue('companyId', newCompanyId)
  }

  const updateEntityId = (newEntityId) => {
    setEntityId(newEntityId)
    formik.setFieldValue('entityId', newEntityId)
  }

  const updateTraderId = (newTraderId) => {
    formik.setFieldValue('personId', newTraderId)
  }

  const handleCompanyChange = (event) => {
    const newCompanyId = event.target.value

    updateCompanyId(newCompanyId)
    updateEntityId(undefined)
    updateTraderId(undefined)
  }

  const handleEntityChange = (event) => {
    const newEntityId = event.target.value

    updateEntityId(newEntityId)
    updateTraderId(undefined)
  }

  const handleTraderChange = (event) => {
    const newTraderId = event.target.value

    updateTraderId(newTraderId)
  }

  return (
    <>
      <Select
        data-testid="company-selection-dropdown"
        label="Company"
        name="companyId"
        options={companies}
        onChange={handleCompanyChange}
      />
      <Select
        data-testid="entity-selection-dropdown"
        label="Entity"
        name="entityId"
        options={entities}
        onChange={handleEntityChange}
      />
      <Select
        data-testid="trader-selection-dropdown"
        label="Trader"
        name="personId"
        options={traders}
        onChange={handleTraderChange}
      />
    </>
  )
})

OnSystemByBroker.propTypes = {
  userId: PropTypes.string.isRequired,
}

const OnSystemByTrader = connect(({ formik }) => {
  const {
    user: { entities = [] },
  } = useUser()
  const entitiesPermission = entities.filter((entity) =>
    userHasTradePermission(entity.permission)
  )

  const handleEntityChange = (event) => {
    const newEntityId = event.target.value

    formik.setFieldValue('entityId', newEntityId)
  }

  return (
    <Select
      data-testid="entity-selection-dropdown"
      label="Entity"
      name="entityId"
      options={entitiesPermission}
      onChange={handleEntityChange}
    />
  )
})

export const OnSystem = () => {
  const { user } = useUser()
  const clientType = user.clientType

  return (
    <>
      {clientType === PERSON_CLIENT_TYPE.TRADER && <OnSystemByTrader />}
      {clientType === PERSON_CLIENT_TYPE.BROKER && (
        <OnSystemByBroker userId={user.id} />
      )}
    </>
  )
}
