import React from 'react'
import { merge } from 'lodash'
import PropTypes from 'prop-types'
import { $O, TRADING_TYPE } from 'emsurge-selectors'
import { Grid } from '@material-ui/core'
import Person from '../Person'
import { SwapLegContainer } from '../SwapLegContainer'
import { buildStyles } from './utils'
import { CardIcons } from './CardIcons'
import { getOrderCardOpacity } from 'screens/orderIndex/helpers'
import { Row } from 'components/Row'
import { SmallText } from 'components/Text'
import CompanyCode from 'components/CompanyCode/CompanyCode'
import { MainPrice } from 'components/MainPrice'
import { Card } from 'components/Card'

const ORDER_CARD_MIN_HEIGHT = 60

const _OrderCardLNG = ({ order, style, renderTopRight }) => {
  const orderSummary = $O.summary(order)
  const opacity = getOrderCardOpacity(order)
  const rowDirection =
    $O.tradingType.get(order) === TRADING_TYPE.BID ? 'row' : 'row-reverse'
  const { hasViewed } = $O.views.get(order)
  const styles = merge(buildStyles(), {
    root: {
      opacity,
      minHeight: ORDER_CARD_MIN_HEIGHT,
      overflow: 'hidden',
    },
  })

  return (
    <Card
      style={{ ...styles.root, ...style }}
      id={`market-${order.id}`}
      border={!hasViewed}
      data-seen={!hasViewed}
      data-cy="market-interest-order-cards"
      data-testid={`market-${order.id}`}
    >
      <Grid container>
        <Row direction={rowDirection}>
          <Grid item>
            <Person data-testid="order-card-author" person={order.person} />
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              spacing={1}
              direction={rowDirection}
              wrap="nowrap"
            >
              <Grid item>
                {order.swapId && <SwapLegContainer size={14} order={order} />}
              </Grid>
              {renderTopRight && renderTopRight()}
            </Grid>
          </Grid>
        </Row>

        <Row direction={rowDirection} style={{ marginTop: '5px' }}>
          <CardIcons order={order} tradingType={$O.tradingType.get(order)} />
          <SmallText>{orderSummary.deliveryWindowShortLabel}</SmallText>
        </Row>

        <Row direction={rowDirection} style={{ marginTop: '2px' }}>
          <CompanyCode data-testid="order-card-company-code" order={order} />
          <MainPrice data-testid="order-card-main-price" order={order} />
        </Row>
      </Grid>
    </Card>
  )
}

_OrderCardLNG.propTypes = {
  order: PropTypes.object.isRequired,
  style: PropTypes.object,
  renderTopRight: PropTypes.func,
}

export const OrderCardLNG = _OrderCardLNG
