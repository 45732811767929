import { get, partition, keys } from 'lodash'
import { WINDOW, WINDOW_LABELS } from 'model/order/constants/delivery'

export const MULTIPLE_CARGO_DISCLAIMER =
  'If you have chosen multiple cargoes, the delivery window will be set pro-rata. Edit the order on your desktop to input a specific delivery window per cargo.'

const WINDOW_OPTIONS = {
  [WINDOW_LABELS[WINDOW.FIRST_HALF]]: {
    value: WINDOW_LABELS[WINDOW.FIRST_HALF],
    xs: 6,
  },
  [WINDOW_LABELS[WINDOW.SECOND_HALF]]: {
    value: WINDOW_LABELS[WINDOW.SECOND_HALF],
    xs: 6,
  },
  [WINDOW_LABELS[WINDOW.EARLY]]: {
    value: WINDOW_LABELS[WINDOW.EARLY],
    xs: 4,
  },
  [WINDOW_LABELS[WINDOW.MID]]: {
    value: WINDOW_LABELS[WINDOW.MID],
    xs: 4,
  },
  [WINDOW_LABELS[WINDOW.LATE]]: {
    value: WINDOW_LABELS[WINDOW.LATE],
    xs: 4,
  },
  [WINDOW_LABELS[WINDOW.CUSTOM]]: {
    value: WINDOW_LABELS[WINDOW.CUSTOM],
    xs: 12,
  },
}

export const parseOptions = (options) => {
  const sortedOptions = options.sort((a, b) => {
    if (
      a === WINDOW_LABELS[WINDOW.CUSTOM] ||
      b === WINDOW_LABELS[WINDOW.CUSTOM]
    ) {
      return -1
    }
    return 0
  })

  const [mainOptions, otherOptions] = partition(sortedOptions, (value) =>
    keys(WINDOW_OPTIONS).includes(value)
  )

  return {
    mainOptions: mainOptions.map((opt) => get(WINDOW_OPTIONS, opt)),
    otherOptions,
  }
}
