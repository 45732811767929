import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

export const Row = ({ children, direction, style, classes }) => (
  <Grid
    classes={classes}
    container
    style={style}
    direction={direction}
    wrap="nowrap"
    justifyContent="space-between"
    alignItems="center"
  >
    {children}
  </Grid>
)

Row.defaultProps = {
  classes: {},
  children: [],
  direction: 'row',
  style: {},
}

Row.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  direction: PropTypes.string,
  style: PropTypes.object,
}
