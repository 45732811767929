const { isEmpty } = require('lodash')
const {
  VOLUME_OFFSET_LABELS,
  VOLUME_TYPE_KEYS,
  VOLUME_TYPE_VALUES,
} = require('../../constants')

const formatVolume = (amount, suffix = 'tonnes') => {
  const formattedSuffix = suffix.length ? ` ${suffix}` : ''
  const result = `${Number(amount || 0).toLocaleString('en')}${formattedSuffix}`
  return result
}
const getVolume = (order) => order.volume || {}

const getOffset = (offset, minParcelSize = 0) => {
  if (isEmpty(offset)) {
    return formatVolume(minParcelSize)
  }
  return offset.map((opt) => VOLUME_OFFSET_LABELS[opt] || '').join(', ')
}

const getRange = (type, range) => {
  const { first, last } = range || {}
  if (type === VOLUME_TYPE_KEYS.PER_ANNUM) {
    return {
      firstYear: formatVolume(first),
      lastYear: formatVolume(last),
    }
  }
  return {}
}

const getText = (type, amount, range) => {
  if (type === VOLUME_TYPE_KEYS.TOTAL) {
    const result = [formatVolume(amount || 0, 'tCO2e')]
    return result
  }
  if (type === VOLUME_TYPE_KEYS.PER_ANNUM) {
    const { first, last } = range || {}
    const result = [formatVolume(first, ''), formatVolume(last, 'tCO2e')]
    return result
  }
  return []
}

const getVolumeText = (order) => {
  const { amount, offset, minParcelSize, range, type } = getVolume(order)
  const amountFormatted = Number(amount || 0).toLocaleString('en')
  const result = {
    volume: `${amountFormatted} tCO2e`,
    offset: getOffset(offset, minParcelSize),
    type,
    textType: VOLUME_TYPE_VALUES[type],
    text: getText(type, amount, range),
    ...getRange(type, range),
  }
  return result
}

module.exports = {
  get: getVolume,
  text: getVolumeText,
}
