import { isEmpty } from 'lodash'
import {
  $U,
  PERSON_CLIENT_TYPE,
  PERSON_PERMISSION,
  ORDER_STATUS,
  $O,
} from 'emsurge-selectors'
import { useUserHasPermission } from 'permissions'
import { isThirdPartyOrder } from 'screens/transactionIndex/helpers'
import { isBrokerOrder } from 'screens/orderCreate/helpers'
import { useUser } from 'containers/user/useUser'
import { $O as $o } from 'model/order/selectors/selector'
import { useEntityBrokers } from 'api'

export const useCanShareOrderWithBroker = (order) => {
  const { user } = useUser()
  const { data: brokerEntityPermissions } = useEntityBrokers(order.entityId)
  const clientType = $U.clientType.get(user)
  const userCanSendToBroker = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.TRADE,
  })
  const otherTraderCompanyOrder =
    clientType === PERSON_CLIENT_TYPE.TRADER &&
    !$o.author.isFromMyCompany(order, user)
  const isBroker = clientType === PERSON_CLIENT_TYPE.BROKER

  if (
    isBroker ||
    !userCanSendToBroker ||
    order.template ||
    isThirdPartyOrder(order) ||
    isBrokerOrder(order) ||
    otherTraderCompanyOrder ||
    [ORDER_STATUS.PENDING, ORDER_STATUS.CLOSED].includes(
      $O.status.get(order)
    ) ||
    !isEmpty($O.brokerAccess.get(order)) ||
    isEmpty(brokerEntityPermissions) // no broker has access to this entity
  ) {
    return false
  }

  return true
}
