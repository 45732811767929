import { useState, useEffect } from 'react'
import { useApi } from 'containers/api/useApi'

export const useUpdatedValues = (id, url) => {
  const api = useApi()
  const [values, setValues] = useState([])

  useEffect(() => {
    let didCancel = false

    const getValues = async () => {
      let newValues

      if (id) {
        const { data = [] } = await api.get(url)
        newValues = data
      } else {
        newValues = []
      }

      if (!didCancel) {
        setValues(newValues)
      }
    }

    getValues()

    return () => {
      didCancel = true
    }
  }, [api, id, url])

  return values
}
