import { Grid } from '@material-ui/core'
import {
  CLASSIFICATIONS_FIRST,
  CLASSIFICATIONS_FIRST_PREFIX,
  CLASSIFICATIONS_SECOND,
  CLASSIFICATIONS_SECOND_PREFIX,
} from 'emsurge-selectors'
import React from 'react'
const { RadioButtonGroup } = require('./RadioButtonGroup')

export const Classifications = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <RadioButtonGroup
          key="classifications-first-row"
          title="Classifications (select one option in each row)"
          options={CLASSIFICATIONS_FIRST}
          fieldName={CLASSIFICATIONS_FIRST_PREFIX}
        />
      </Grid>
      <Grid item xs={12}>
        <RadioButtonGroup
          key="classifications-second-row"
          options={CLASSIFICATIONS_SECOND}
          fieldName={CLASSIFICATIONS_SECOND_PREFIX}
          style={{ marginTop: 0 }}
        />
      </Grid>
    </Grid>
  )
}
