import React from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'

import { PRICE_VARIANT } from 'emsurge-selectors/constants'
import { PRICE_TYPE_COMPONENTS, PRICE_TYPE_OPTIONS } from './Type.helpers'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'
import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'
export const Type = ({ variant }) => {
  return (
    <>
      <FormControl>
        <FormLabel>Type</FormLabel>
        <RadioInputs
          name={`price.${variant}.type`}
          options={PRICE_TYPE_OPTIONS}
        />
      </FormControl>

      <FormConsumer>
        {({ values }) => {
          const price = values.price[variant] || {}
          const PriceComponent = PRICE_TYPE_COMPONENTS[price.type]
          return PriceComponent ? <PriceComponent variant={variant} /> : null
        }}
      </FormConsumer>
    </>
  )
}

Type.propTypes = {
  variant: PropTypes.oneOf(Object.values(PRICE_VARIANT)).isRequired,
}
