import React from 'react'
import Text from '@material-ui/core/Typography'
import { sortBy } from 'lodash'

import UsersTable from './components/UsersTable'
import {
  filterByStatusNotPendingApproval,
  mapToUserView,
  sortAlphabetically,
} from './helpers'
import Fetch from 'containers/fetchPlus/FetchPlus'

const ViewUsers = () => (
  <>
    <Fetch url="/persons">
      {(users) => (
        <section>
          <Text component="h2" variant="h1" gutterBottom>
            Users
          </Text>
          <UsersTable
            users={sortBy(
              users.filter(filterByStatusNotPendingApproval).map(mapToUserView),
              sortAlphabetically
            )}
          />
        </section>
      )}
    </Fetch>
  </>
)

export default ViewUsers
