import React from 'react'
import Text from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import FieldCard from 'screens/orderCreateCommon/components/FieldCard'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'

const DeliveryLocationCard = ({ title }) => (
  <FieldCard title={title}>
    <FormConsumer>
      {({ values }) => (
        <Text variant="caption">{values.delivery.location}</Text>
      )}
    </FormConsumer>
  </FieldCard>
)

DeliveryLocationCard.propTypes = {
  title: PropTypes.string.isRequired,
}

export default DeliveryLocationCard
