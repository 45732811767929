const { COUNTRIES_AFRICA, COUNTRIES_AFRICA_KEYS, COUNTRIES_AFRICA_VALUES } = require('./africa')
const { COUNTRIES_ASIA, COUNTRIES_ASIA_KEYS, COUNTRIES_ASIA_VALUES } = require('./asia')
const { COUNTRIES_EUROPE, COUNTRIES_EUROPE_KEYS, COUNTRIES_EUROPE_VALUES } = require('./europe')
const {
  COUNTRIES_LATIN_AMERICA,
  COUNTRIES_LATIN_AMERICA_KEYS,
  COUNTRIES_LATIN_AMERICA_VALUES
} = require('./latinAmerica')
const {
  COUNTRIES_NORTHERN_AMERICA,
  COUNTRIES_NORTHERN_AMERICA_KEYS,
  COUNTRIES_NORTHERN_AMERICA_VALUES
} = require('./northernAmerica')
const { COUNTRIES_OCEANIA, COUNTRIES_OCEANIA_KEYS, COUNTRIES_OCEANIA_VALUES } = require('./oceania')
const { REGIONS_KEYS, REGIONS } = require('./regions')

const getValues = (countries) => {
  const countriesValues = {}
  Object.values(countries).forEach(
    ({ key, label }) => (countriesValues[key] = label)
  )
  return countriesValues
}

const COUNTRIES_KEYS = {
  ...COUNTRIES_AFRICA_KEYS,
  ...COUNTRIES_ASIA_KEYS,
  ...COUNTRIES_EUROPE_KEYS,
  ...COUNTRIES_LATIN_AMERICA_KEYS,
  ...COUNTRIES_NORTHERN_AMERICA_KEYS,
  ...COUNTRIES_OCEANIA_KEYS,
}

const COUNTRIES_VALUES = {
  ...COUNTRIES_AFRICA_VALUES,
  ...COUNTRIES_ASIA_VALUES,
  ...COUNTRIES_EUROPE_VALUES,
  ...COUNTRIES_LATIN_AMERICA_VALUES,
  ...COUNTRIES_NORTHERN_AMERICA_VALUES,
  ...COUNTRIES_OCEANIA_VALUES,
}

const getCountriesByRegion = (countries, region) => {
  const countriesRecords = {}
  Object.values(countries).forEach(
    (country) =>
      (countriesRecords[country.key] = {
        ...country,
        groupBy: region,
      })
  )
  return countriesRecords
}

const getCountryRegionFromKey = (countryKey) => {
  if(Object.keys(COUNTRIES_AFRICA_KEYS).includes(countryKey)) return REGIONS[REGIONS_KEYS.AFRICA]
  if(Object.keys(COUNTRIES_ASIA_KEYS).includes(countryKey)) return REGIONS[REGIONS_KEYS.ASIA]
  if(Object.keys(COUNTRIES_EUROPE_KEYS).includes(countryKey)) return REGIONS[REGIONS_KEYS.EUROPE]
  if(Object.keys(COUNTRIES_LATIN_AMERICA_KEYS).includes(countryKey)) return REGIONS[REGIONS_KEYS.LATIN_AMERICA]
  if(Object.keys(COUNTRIES_NORTHERN_AMERICA_KEYS).includes(countryKey)) return REGIONS[REGIONS_KEYS.NORTHERN_AMERICA]
  if(Object.keys(COUNTRIES_OCEANIA_KEYS).includes(countryKey)) return REGIONS[REGIONS_KEYS.OCEANIA]
}

const REGION_COUNTRIES = {
  [REGIONS_KEYS.AFRICA]: getCountriesByRegion(
    COUNTRIES_AFRICA,
    REGIONS_KEYS.AFRICA
  ),
  [REGIONS_KEYS.ASIA]: getCountriesByRegion(COUNTRIES_ASIA, REGIONS_KEYS.ASIA),
  [REGIONS_KEYS.EUROPE]: getCountriesByRegion(
    COUNTRIES_EUROPE,
    REGIONS_KEYS.EUROPE
  ),
  [REGIONS_KEYS.LATIN_AMERICA]: getCountriesByRegion(
    COUNTRIES_LATIN_AMERICA,
    REGIONS_KEYS.LATIN_AMERICA
  ),
  [REGIONS_KEYS.NORTHERN_AMERICA]: getCountriesByRegion(
    COUNTRIES_NORTHERN_AMERICA,
    REGIONS_KEYS.NORTHERN_AMERICA
  ),
  [REGIONS_KEYS.OCEANIA]: getCountriesByRegion(
    COUNTRIES_OCEANIA,
    REGIONS_KEYS.OCEANIA
  ),
}

const REGION_COUNTRY_KEYS = {
  ...REGION_COUNTRIES[REGIONS_KEYS.AFRICA],
  ...REGION_COUNTRIES[REGIONS_KEYS.ASIA],
  ...REGION_COUNTRIES[REGIONS_KEYS.EUROPE],
  ...REGION_COUNTRIES[REGIONS_KEYS.LATIN_AMERICA],
  ...REGION_COUNTRIES[REGIONS_KEYS.NORTHERN_AMERICA],
  ...REGION_COUNTRIES[REGIONS_KEYS.OCEANIA],
}

const REGION_COUNTRY_OPTIONS = {
  [REGIONS_KEYS.AFRICA]: REGIONS[REGIONS_KEYS.AFRICA],
  ...getValues(COUNTRIES_AFRICA),
  [REGIONS_KEYS.ASIA]: REGIONS[REGIONS_KEYS.ASIA],
  ...getValues(COUNTRIES_ASIA),
  [REGIONS_KEYS.EUROPE]: REGIONS[REGIONS_KEYS.EUROPE],
  ...getValues(COUNTRIES_EUROPE),
  [REGIONS_KEYS.LATIN_AMERICA]: REGIONS[REGIONS_KEYS.LATIN_AMERICA],
  ...getValues(COUNTRIES_LATIN_AMERICA),
  [REGIONS_KEYS.NORTHERN_AMERICA]: REGIONS[REGIONS_KEYS.NORTHERN_AMERICA],
  ...getValues(COUNTRIES_NORTHERN_AMERICA),
  [REGIONS_KEYS.OCEANIA]: REGIONS[REGIONS_KEYS.OCEANIA],
  ...getValues(COUNTRIES_OCEANIA),
}

module.exports = {
  COUNTRIES_KEYS,
  COUNTRIES_VALUES,
  REGION_COUNTRY_KEYS,
  REGION_COUNTRY_OPTIONS,
  REGIONS,
  REGIONS_KEYS,
  REGION_COUNTRIES,
  getCountryRegionFromKey,
}
