import React from 'react'
import { object, string } from 'yup'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import Text from '@material-ui/core/Typography'
import Form from 'containers/formikForm/FormikForm'
import { BrandLogoWrapper } from 'components/logo'
import { LayoutWrapper } from 'components/LayoutWrapper'
import { SubmitButton } from 'screens/signIn/components/Buttons'
import { EmailField } from 'screens/signIn/components/SignInInputs'
import { NEW_PASSWORD, HOME } from 'routes'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const Schema = object().shape({
  email: string().email('Invalid email').required('Required'),
})

const INITIAL_VALUES = {
  email: '',
}

const UserRequestResetPassword = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()

  const handleRequest = async (values, form) => {
    const { email } = values
    const data = {
      email,
      resetPasswordUrl: window.location.origin + NEW_PASSWORD,
    }

    try {
      await api.post('/password/reset', data, { auth: false })
      form.resetForm()
      enqueueSnackbar('New password requested with success', {
        variant: 'success',
      })
      history.push(HOME)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'New password cannot be requested',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      form.setSubmitting(false)
    }
  }

  return (
    <LayoutWrapper>
      <BrandLogoWrapper />
      <Text
        component="h1"
        variant="h5"
        gutterBottom
        style={{ textAlign: 'center' }}
      >
        Enter your email to request a new password
      </Text>

      <Form
        initialValues={INITIAL_VALUES}
        validationSchema={Schema}
        onSubmit={handleRequest}
      >
        <EmailField name="email" label="Email" autoFocus />
        <SubmitButton>Submit</SubmitButton>
      </Form>
    </LayoutWrapper>
  )
}

UserRequestResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
}

export default UserRequestResetPassword
