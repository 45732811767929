import React from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Activity from './containers/Activity'
import { MarketInterest } from './containers/MarketInterest'
import { OrdersAndTemplates } from './containers/OrdersAndTemplates'
import { DashboardSwiper } from './DashboardSwiper'
import { Block as DesktopBlock } from 'screens/orderIndex/components/Block'
import { DASHBOARD } from 'routes'
import { Tablet, Mobile } from 'components/Viewport'

import ViewOrder from 'screens/orderView/ViewOrder'
import ViewTermsheet from 'screens/termsheetView/ViewTermsheet'
import BulletinView from 'screens/bulletinView'

export const Dashboard = () => {
  const history = useHistory()
  const match = useRouteMatch()

  return (
    <>
      <Tablet matchLarger>
        <Grid container spacing={2}>
          <Grid item xs>
            <DesktopBlock>
              <MarketInterest />
            </DesktopBlock>
          </Grid>

          <Grid item style={{ width: 350 }}>
            <DesktopBlock>
              <OrdersAndTemplates />
            </DesktopBlock>
          </Grid>

          <Grid item style={{ width: 350 }}>
            <DesktopBlock>
              <Activity />
            </DesktopBlock>
          </Grid>
        </Grid>
      </Tablet>

      <Mobile>
        <DashboardSwiper />
      </Mobile>
      <Route
        path={`${match.path}/orders/:id`}
        component={() => <ViewOrder onClose={() => history.push(DASHBOARD)} />}
      />
      <Route
        path={`${match.path}/termsheets/:id`}
        component={() => <ViewTermsheet />}
      />
      <Route path={`${match.path}/bulletins/:id`} component={BulletinView} />
    </>
  )
}
