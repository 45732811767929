import React from 'react'
import PropTypes from 'prop-types'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import Text from '@material-ui/core/Typography'

const NoteActions = ({ resource, style, ...props }) => {
  const numOfActions = resource.actionItems.length
  const numOfCheckedActions = resource.actionItems.filter(
    ({ isChecked }) => isChecked
  ).length

  const textStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    ...style,
  }

  const iconStyles = {
    width: 10,
    height: 10,
    marginRight: 3,
  }

  return (
    <Text style={textStyle} {...props}>
      <PriorityHigh style={iconStyles} />
      <span>
        {numOfCheckedActions}/{numOfActions}
      </span>
    </Text>
  )
}

NoteActions.propTypes = {
  resource: PropTypes.shape({
    actionItems: PropTypes.array,
  }).isRequired,
  style: PropTypes.object,
}

export default NoteActions
