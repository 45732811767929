import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { $U, ORDER_STATUS, PERSON_CLIENT_TYPE } from 'emsurge-selectors'
import OrderSwap from 'screens/orderSwap/OrderSwap'
import Modal from 'components/modal/Modal'
import OrderCreateCounter from 'screens/orderCreateCounter/OrderCreateCounter'
import OrderEdit from 'screens/orderEdit/OrderEdit'
import OrderClone from 'screens/orderClone/OrderClone'
import {
  ORDER_COUNTER_CREATE,
  ORDER_MARK_AS_TRADED,
  ORDER_CLONE,
  ORDER_EDIT,
  ORDER_SWAP,
  ORDER_CREATE_TEMPLATE,
} from 'routes'
import { OrderMarkAsTraded } from 'screens/orderMarkAsTraded'
import { useOrder } from 'api'
import { OrderProvider, useOrderState } from 'containers/OrderProvider'
import { useUser } from 'containers/user/useUser'
import { $O } from 'model/order/selectors/selector'
import OrderCreateTemplate from 'screens/orderCreateTemplate/OrderCreateTemplate'

const useModalStyles = makeStyles({
  root: {
    overflow: 'hidden',
  },
})

const ViewOrderModal = ({ onClose, children }) => {
  const classes = useModalStyles()
  const { id } = useParams()
  const { isLoading, data: order } = useOrder(id)
  const { user } = useUser()
  const { setOrderState } = useOrderState()

  useEffect(() => {
    if (!isLoading && order) {
      const clientType = $U.clientType.get(user)
      setOrderState((state) => ({
        isPending: order.status === ORDER_STATUS.PENDING,
        hasBrokerId: !!order.brokerId || false,
        hasTermsheets: order.termsheets?.length > 0,
        isTermsheet: false,
        isOtherTraderCompanyOrder:
          clientType === PERSON_CLIENT_TYPE.TRADER &&
          !$O.author.isFromMyCompany(order, user),
        ...state,
      }))
    }
  }, [isLoading, order, setOrderState, user])

  return (
    <Modal
      open={true}
      paperProps={{ classes }}
      onClose={onClose}
    >
      {!isLoading && (
        <Switch>
          <Route path={ORDER_COUNTER_CREATE} component={OrderCreateCounter} />
          <Route path={ORDER_MARK_AS_TRADED} component={OrderMarkAsTraded} />
          <Route path={ORDER_CLONE} component={OrderClone} />
          <Route path={ORDER_CREATE_TEMPLATE} component={OrderCreateTemplate} />
          <Route path={ORDER_EDIT} component={OrderEdit} />
          <Route path={ORDER_SWAP} component={OrderSwap} />
          <Route>{children}</Route>
        </Switch>
      )}
    </Modal>
  )
}

ViewOrderModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
}

ViewOrderModal.defaultProps = {
  onClose: () => {},
}

const ViewOrderModalWithProvider = (props) => (
  <OrderProvider>
    <ViewOrderModal {...props} />
  </OrderProvider>
)

export default ViewOrderModalWithProvider
