const {
  QUALITY_TYPE,
  QUALITY_TYPE_TEXT,
  QUALITY_TYPE_UNIT,
} = require('../../constants')

const getQualityType = (order) => {
  return order.quality.type
}

const getQualityTypeText = (order) => {
  return QUALITY_TYPE_TEXT[order.quality.type]
}

const getQualityValue = (order) => {
  const qualityType = getQualityType(order)

  switch (qualityType) {
    case QUALITY_TYPE.CUSTOM:
      return {
        min: order.quality.customMin,
        max: order.quality.customMax,
      }
    case QUALITY_TYPE.OVER_1040:
      return { amount: 1040 }
    case QUALITY_TYPE.OVER_1050:
      return { amount: 1050 }
    case QUALITY_TYPE.RICH:
    case QUALITY_TYPE.LEAN:
    case QUALITY_TYPE.FLEXIBLE:
    default:
      return {}
  }
}

const getQualityValueText = (order) => {
  const qualityType = getQualityType(order)
  const qualityValue = getQualityValue(order)

  switch (qualityType) {
    case QUALITY_TYPE.CUSTOM:
      return `${Math.round(qualityValue.min, 3)}–${Math.round(
        qualityValue.max,
        3
      )} ${QUALITY_TYPE_UNIT.BTU_SCF}`
    case QUALITY_TYPE.OVER_1040:
    case QUALITY_TYPE.OVER_1050:
      return `${qualityValue.amount}+ ${QUALITY_TYPE_UNIT.BTU_SCF}`
    case QUALITY_TYPE.FLEXIBLE:
    case QUALITY_TYPE.LEAN:
    case QUALITY_TYPE.RICH:
      return `GHV ${QUALITY_TYPE_TEXT[qualityType]}`
    default:
      return ''
  }
}

const getQualityPermitted = (order) => {
  return {
    cbm: order.quality.cbmPermitted,
    us: order.quality.usPermitted,
  }
}

const getQualityPermittedText = (order) => {
  const qualityPermitted = getQualityPermitted(order)

  const permittedLabels = []

  if (!qualityPermitted.cbm) {
    permittedLabels.push('no CBM')
  }

  if (!qualityPermitted.us) {
    permittedLabels.push('no US')
  }

  return permittedLabels.join(' & ')
}

const getQuality = (order) => {
  const type = getQualityType(order)
  const value = getQualityValue(order)
  const permitted = getQualityPermitted(order)

  return {
    type,
    value,
    permitted,
  }
}

const getQualityText = (order) => {
  return `${getQualityValueText(order)} ${getQualityPermittedText(
    order
  )}`.trim()
}

module.exports = {
  type: {
    get: getQualityType,
    text: getQualityTypeText,
  },
  value: {
    get: getQualityValue,
    text: getQualityValueText,
  },
  permitted: {
    get: getQualityPermitted,
    text: getQualityPermittedText,
  },
  get: getQuality,
  text: getQualityText,
}
