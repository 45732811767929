import React from 'react'
import PropTypes from 'prop-types'
import { XsmallText } from 'components/Text'
import { Container } from 'components/Card'

export const Header = ({ label, author, explanation }) => (
  <Container data-testid="termsheet-card-header" justifyContent="space-between">
    <XsmallText title={explanation}>{label}</XsmallText>
    {author}
  </Container>
)

Header.defaultProps = {
  label: '',
  author: null,
}

Header.propTypes = {
  label: PropTypes.string.isRequired,
  author: PropTypes.node,
}
