import PropTypes from 'prop-types'
import { connect } from 'formik'

import {
  DELIVERY_VOLUME_TYPE,
  DELIVERY_VOLUME_TYPE_OPTIONS,
} from 'emsurge-selectors/constants'

const _SelectNoOfCargoesType = ({ children, formik }) => {
  const onChange = (value) => {
    if (value === DELIVERY_VOLUME_TYPE.FIXED) {
      formik.setFieldValue('volume.max', undefined)
    }
  }

  return children({ options: DELIVERY_VOLUME_TYPE_OPTIONS, onChange })
}

_SelectNoOfCargoesType.defaultProps = {
  children: () => {},
}

_SelectNoOfCargoesType.propTypes = {
  children: PropTypes.func,
  formik: PropTypes.object.isRequired,
}

export const SelectNoOfCargoesType = connect(_SelectNoOfCargoesType)
