const { TERM_SPOT_VINTAGE_PROJECT_INFORMATION } = require('./carbonPrefix')

const PROJECT_INFORMATION_KEYS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  SEEKING_INVESTMENT: 'SEEKING_INVESTMENT',
}

const PROJECT_INFORMATION = Object.freeze({
  [PROJECT_INFORMATION_KEYS.ACTIVE]: 'Active',
  [PROJECT_INFORMATION_KEYS.PENDING]: 'Pending',
  [PROJECT_INFORMATION_KEYS.SEEKING_INVESTMENT]: 'Seeking Investment',
})

const PROJECT_INFORMATION_PATHS = Object.keys(PROJECT_INFORMATION).map(
  (projectInfo) => `${TERM_SPOT_VINTAGE_PROJECT_INFORMATION}.${projectInfo}`
)

const PROJECT_INFORMATION_OPTIONS = Object.keys(PROJECT_INFORMATION).map(
  (projectInfo) => ({
    label: PROJECT_INFORMATION[projectInfo],
    value: `${TERM_SPOT_VINTAGE_PROJECT_INFORMATION}.${projectInfo}`,
    key: projectInfo,
  })
)

module.exports = {
  PROJECT_INFORMATION_KEYS,
  PROJECT_INFORMATION,
  PROJECT_INFORMATION_PATHS,
  PROJECT_INFORMATION_OPTIONS,
}
