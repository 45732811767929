import { isString, isEmpty } from 'lodash'
import { ORDER_STATUS } from 'emsurge-selectors/constants'

const hasStatus = (order) => isString(order.status) && !isEmpty(order.status)

const getStatus = (order) => {
  if (!hasStatus(order)) {
    throw new Error('Order does not have a status')
  }

  return order.status
}

const isClosed = (order) => getStatus(order) === ORDER_STATUS.CLOSED

const isLive = (order) => getStatus(order) === ORDER_STATUS.LIVE

const isPending = (order) => getStatus(order) === ORDER_STATUS.PENDING

const isTraded = (order) => getStatus(order) === ORDER_STATUS.TRADED

const isWitheld = (order) => getStatus(order) === ORDER_STATUS.WITHHELD

const isOutToMarket = (order) => isWitheld(order) || isLive(order)

export const status = {
  exists: hasStatus,
  get: getStatus,
  isClosed,
  isLive,
  isOutToMarket,
  isPending,
  isTraded,
  isWitheld,
}
