import React from 'react'
import CompanyCode from 'components/CompanyCode/CompanyCode'

const style = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
}

export const SellerCompanyCode = (row) => (
  <CompanyCode style={style} order={row.sellerOrder} />
)

export const BuyerCompanyCode = (row) => (
  <CompanyCode style={style} order={row.buyerOrder} />
)
