import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import { ProjectSpecification } from './screens/ProjectSpecification'
import { AdditionalAttributes } from './screens/AdditionalAttributes'
import { Venue } from './screens/Venue'
import { Region } from './screens/Region'
import { OtherTerms } from './screens/OtherTerms'
import { useActiveStep } from 'containers/OrderProvider'
import VerticalStepper from 'screens/orderCreateCommon/components/VerticalStepper'
import StepLabel from 'screens/orderCreateCommon/components/StepLabel'
import StepActions, {
  validNextStep,
} from 'screens/orderCreateCommon/components/StepActions'

export const steps = [
  {
    title: 'Region',
    component: Region,
    number: 6,
  },
  {
    title: 'Project Specification',
    component: ProjectSpecification,
    number: 7,
  },
  {
    title: 'Additional Attributes',
    component: AdditionalAttributes,
    number: 8,
  },
  {
    title: 'Venue',
    component: Venue,
    number: 9,
  },
  {
    title: 'Other Terms',
    component: OtherTerms,
    number: 10,
  },
]

const OptionalFormStepper = ({ company, formik }) => {
  const {
    activeStep,
    navigate: { goTo, goBack, goForward },
  } = useActiveStep()

  return (
    <VerticalStepper activeStep={activeStep.step} nonLinear>
      {steps.map(({ title, number, component: Content }, idx) => {
        return (
          <Step key={title}>
            <StepLabel icon={number} onClick={() => goTo({ step: idx })}>
              {title}
            </StepLabel>

            <StepContent>
              <Content company={company} />
              <StepActions
                totalSteps={steps.length}
                activeStep={activeStep.step}
                onBack={goBack}
                onNext={() => validNextStep(formik, goForward)}
              />
            </StepContent>
          </Step>
        )
      })}
    </VerticalStepper>
  )
}

OptionalFormStepper.propTypes = {
  formik: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
}

export default connect(OptionalFormStepper)
