const { STAKEHOLDER_TYPE, STAKEHOLDER_TYPE_TEXT } = require('../../constants')

const getType = (order) => {
  if (order.companyId) {
    return STAKEHOLDER_TYPE.ON_SYSTEM
  }

  if (order.thirdParty) {
    return STAKEHOLDER_TYPE.OFF_SYSTEM
  }
}

const getTypeText = (order) => {
  const type = getType(order)

  return STAKEHOLDER_TYPE_TEXT[type] || ''
}

const getCompany = (order) => {
  const type = getType(order)

  switch (type) {
    case STAKEHOLDER_TYPE.ON_SYSTEM:
      return order.company || {}
    case STAKEHOLDER_TYPE.OFF_SYSTEM:
      return { name: order.thirdParty.companyName }
    default:
      return {}
  }
}

const getCompanyText = (order) => {
  const company = getCompany(order)

  return company.name || ''
}

const getEntity = (order) => {
  const type = getType(order)

  switch (type) {
    case STAKEHOLDER_TYPE.ON_SYSTEM:
      return order.entity || {}
    case STAKEHOLDER_TYPE.OFF_SYSTEM:
      return { name: order.thirdParty.entityName }
    default:
      return {}
  }
}

const getEntityText = (order) => {
  const entity = getEntity(order)

  return entity.name || ''
}

const getPerson = (order) => {
  const type = getType(order)

  switch (type) {
    case STAKEHOLDER_TYPE.ON_SYSTEM:
      return order.person || {}
    case STAKEHOLDER_TYPE.OFF_SYSTEM:
      return order.thirdParty.contact ? { name: order.thirdParty.contact } : {}
    default:
      return {}
  }
}

const getPersonText = (order) => {
  const person = getPerson(order)

  let fullName = ''

  if (person.name) {
    fullName += `${person.name} `
  }

  if (person.surname) {
    fullName += `${person.surname}`
  }

  return fullName.trim()
}

const getCode = (order) => {
  const company = getCompany(order)
  const entity = getEntity(order)
  const type = getType(order)

  let code

  switch (type) {
    case STAKEHOLDER_TYPE.ON_SYSTEM:
      code = `${company.code || ''}${entity.code || ''}`
      break
    case STAKEHOLDER_TYPE.OFF_SYSTEM:
      code = `${company.name || ''}${entity.name || ''}`
        .replace(' ', '')
        .slice(0, 5)
      break
    default:
      code = ''
  }

  return code.toUpperCase()
}

const getCodeText = (order) => {
  const code = getCode(order)

  return code
}

const getStakeholder = (order) => {
  return {
    company: getCompany(order),
    entity: getEntity(order),
    person: getPerson(order),
    code: getCode(order),
    type: getType(order),
  }
}

const getStakeholderSummary = (order) => {
  return {
    company: getCompanyText(order),
    entity: getEntityText(order),
    person: getPersonText(order),
    code: getCodeText(order),
    type: getTypeText(order),
  }
}

module.exports = {
  get: getStakeholder,
  type: {
    get: getType,
    text: getTypeText,
  },
  company: {
    get: getCompany,
    text: getCompanyText,
  },
  entity: {
    get: getEntity,
    text: getEntityText,
  },
  person: {
    get: getPerson,
    text: getPersonText,
  },
  code: {
    get: getCode,
    text: getCodeText,
  },
  summary: getStakeholderSummary,
}
