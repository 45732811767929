import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { Typography as Text } from '@material-ui/core'
import {
  findContiguousKeys,
  findCustomContiguousKeys,
} from './MarketVisualisationTimeline.utils'
import { Grid } from './MarketVisualisationTimeline.components'
import { PERIOD } from 'screens/marketVisualisation/MarketVisualisation.constants'
import { NavButton } from 'screens/marketVisualisation/components/NavButton'
import { StatusFilters } from 'components/StatusFilters'
import { GoBackButton } from 'screens/marketVisualisation/components/GoBackButton'
import { MARKET_VISUALISATION } from 'routes'

export const Header = ({ status, onStatusChange, type, keys, period }) => {
  const history = useHistory()
  const { previous, next } =
    type === PERIOD.CUSTOM
      ? findCustomContiguousKeys(keys, period)
      : findContiguousKeys(type, period)
  const goBack = () => history.push(`?type=${type}&period=${previous}`)
  const goForward = () => history.push(`?type=${type}&period=${next}`)

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Text variant="h1">Market Timeline</Text>
          </Grid>
          <Grid item>
            <GoBackButton
              onClick={() =>
                history.push(`${MARKET_VISUALISATION}?status=${status}`)
              }
            />
          </Grid>
        </Grid>
        <StatusFilters value={status} onChange={onStatusChange} />
      </Grid>
      <Grid item>
        <NavButton
          data-testid="market-timeline-header-prev"
          onClick={goBack}
          disabled={!previous}
          title="Previous period"
        >
          <ChevronLeft />
        </NavButton>
        <NavButton
          data-testid="market-timeline-header-next"
          onClick={goForward}
          disabled={!next}
          title="Next period"
          style={{ marginLeft: '10px' }}
        >
          <ChevronRight />
        </NavButton>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  status: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  keys: PropTypes.array.isRequired,
  period: PropTypes.string.isRequired,
}
