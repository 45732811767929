const { CARGO_SIZE_TYPE_TEXT } = require('../../../constants')

const getCargoSizeType = (order) => {
  const cargoSize = order.cargoSize || {}

  return cargoSize.type
}

const getCargoSizeTypeText = (order) => {
  const type = getCargoSizeType(order)

  return CARGO_SIZE_TYPE_TEXT[type] || ''
}

module.exports = {
  get: getCargoSizeType,
  text: getCargoSizeTypeText,
}
