import React from 'react'
import PropTypes from 'prop-types'
import MuiCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { ORDER_STATUS, PERSON_PERMISSION } from 'emsurge-selectors'
import { getOrderCounterParty } from '../helpers'
import MarkAsTradedConfirmDialog from '../components/MarkAsTradedConfirmDialog'
import { useUserHasPermission } from 'permissions'
import { useMarkAsTraded } from 'api'

const Checkbox = ({ disabled, onClick, checked, size, style, showLabel }) => {
  const onClickHandler = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    onClick()
  }

  if (showLabel) {
    return (
      <FormControlLabel
        disabled={disabled}
        onClick={onClickHandler}
        control={
          <MuiCheckbox
            inputProps={{ 'data-testid': 'mark-as-traded-checkbox' }}
            disabled={disabled}
            checked={checked}
            color="primary"
          />
        }
        label="Mark as traded"
      />
    )
  }
  return (
    <MuiCheckbox
      inputProps={{ 'data-testid': 'mark-as-traded-checkbox' }}
      onClick={onClickHandler}
      disabled={disabled}
      checked={checked}
      style={style}
      size={size}
      color="primary"
    />
  )
}

Checkbox.defaultProps = {
  showLabel: true,
  size: 'medium',
  style: {},
}

Checkbox.propTypes = {
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object,
}

const MarkAsTradedCheckbox = ({ order, termsheet, checkboxProps }) => {
  const counterParty = getOrderCounterParty(termsheet, order)
  const userCanMarkAsTraded = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.TRADE,
    forceSameCompany: true,
  })
  const disabled =
    termsheet.status === ORDER_STATUS.TRADED ||
    termsheet.status === ORDER_STATUS.CLOSED ||
    order.status === ORDER_STATUS.CLOSED ||
    !userCanMarkAsTraded
  const { mutateAsync: markAsTraded } = useMarkAsTraded()

  const setTraded = ({ closeOnTraded } = { closeOnTraded: false }) =>
    markAsTraded({
      termsheetId: termsheet.id,
      closeOnTraded,
      counterParty: {
        ...counterParty,
        isTraded: !counterParty.isTraded,
      },
    })

  return (
    <MarkAsTradedConfirmDialog setTraded={setTraded}>
      {({ open }) => (
        <Checkbox
          checked={counterParty.isTraded}
          disabled={disabled}
          onClick={() => {
            if (disabled) {
              return
            }

            if (counterParty.isTraded) {
              setTraded()
              return
            }

            open()
          }}
          {...checkboxProps}
        />
      )}
    </MarkAsTradedConfirmDialog>
  )
}

MarkAsTradedCheckbox.defaultProps = {
  checkboxProps: {},
}

MarkAsTradedCheckbox.propTypes = {
  order: PropTypes.object.isRequired,
  termsheet: PropTypes.object.isRequired,
  checkboxProps: PropTypes.object,
}

export default MarkAsTradedCheckbox
