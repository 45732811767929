import React from 'react'
import { connect } from 'formik'
import { VenueComponent } from '../components/Venue'
import { TitleTransfer } from '../components/TitleTransfer'

const _Venue = () => {
  return (
    <>
      <VenueComponent key="venue-component"/>
      <TitleTransfer key="title-transfer" />
    </>
  )
}

export const Venue = connect(_Venue)
