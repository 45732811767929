import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  ACTIVITY_TYPE,
  ACTIVITY_ACTION_TEXT,
} from 'emsurge-selectors/constants'
import { $O } from 'model/order/selectors/selector'
import Person from 'screens/orderIndex/components/Person'
import { TermsheetCard } from 'components/TermsheetCard'

const _ActivityTermsheetCard = ({ activity, style }) => {
  const { type, ...termsheet } = activity
  let actionText
  let masrkAsTradedActionText

  switch (type) {
    case ACTIVITY_TYPE.TERMSHEET_CLOSED:
      actionText = ACTIVITY_ACTION_TEXT.CLOSED
      break
    case ACTIVITY_TYPE.TERMSHEET_CREATED:
      actionText = ACTIVITY_ACTION_TEXT.CREATED
      break
    case ACTIVITY_TYPE.TERMSHEET_DELETED:
      actionText = ACTIVITY_ACTION_TEXT.DELETED
      break
    case ACTIVITY_TYPE.TERMSHEET_UPDATED:
      actionText = ACTIVITY_ACTION_TEXT.UPDATED
      break
    case ACTIVITY_TYPE.TERMSHEET_TRADED ||
      ACTIVITY_TYPE.TERMSHEET_COUNTERPARTY_TRADED:
      masrkAsTradedActionText = ACTIVITY_ACTION_TEXT.MARK_AS_TRADED
      break
    default:
      actionText = ''
  }

  const typeText = masrkAsTradedActionText || `${actionText} Term Sheet`.trim()
  const date = termsheet.occurredAt
  let explanationText = `You can see this termsheet because of Rule #`
  switch (activity.visibility_rule_number) {
    case 1: {
      explanationText +=
        '1: You have at least read permissions on the entity of the'
      if (
        termsheet.explanation.rule1
          .person_has_at_least_read_permissions_on_bidOrder
      ) {
        explanationText += ` BID order for the termsheet, which has the ID ${termsheet.bidOrder.entity.id}`
        if (
          termsheet.explanation.rule1
            .person_has_at_least_read_permissions_on_askOrder
        ) {
          explanationText += ' and the'
        }
      }
      if (
        termsheet.explanation.rule1
          .person_has_at_least_read_permissions_on_askOrder
      ) {
        explanationText += ` ASK order for the termsheet, which has the ID ${termsheet.askOrder.entity.id}`
      }
      explanationText += `. The entities in which you have at least read permissions are ${termsheet.explanation.person_read_entity_ids}.`
      break
    }
    case 2: {
      let bidAsk = ''
      if (
        termsheet.explanation.rule2.person_company_id ===
        termsheet.explanation.rule2.bid_order_person_company_id
      ) {
        bidAsk = 'bid'
      } else if (
        termsheet.explanation.rule2.person_company_id ===
        termsheet.explanation.rule2.ask_order_person_company_id
      ) {
        bidAsk = 'ask'
      }
      explanationText += `2: The ${bidAsk} order is a 3rd party order created by someone in your company, and you have at least read permission on at least one entity. The author's company ID is ${
        termsheet.explanation.rule2[`${bidAsk}_order_person_company_id`]
      }. Your company's ID is ${
        termsheet.explanation.person_company_id
      }. The entities of this company in which you have at least read permissions are ${
        termsheet.explanation.person_read_entity_ids
      }.`
      break
    }
    case 3: {
      explanationText +=
        '3: You have at least read permissions on the broker entity of the'
        if (
          termsheet.explanation.rule3
            .person_has_at_least_read_permissions_on_bidOrder_broker
        ) {
          explanationText += ` BID order for the termsheet, which has the ID ${termsheet.explanation.rule3.bid_order_broker_entity_id}`
          if (
            termsheet.explanation.rule3
              .person_has_at_least_read_permissions_on_askOrder_broker
          ) {
            explanationText += ' and the'
          }
        }
        if (
          termsheet.explanation.rule3
            .person_has_at_least_read_permissions_on_askOrder_broker
        ) {
          explanationText += ` ASK order for the termsheet, which has the ID ${termsheet.explanation.rule3.bid_order_broker_entity_id}`
        }
        explanationText += `. The entities in which you have at least read permissions are ${termsheet.explanation.person_read_entity_ids}.`
      break
    }
    default:
      explanationText += '?'
  }
  explanationText += ` Activity ID=${activity.activity_id}.`

  const author = $O.author.exists(termsheet) ? (
    <Person person={termsheet.person} />
  ) : null

  return (
    <TermsheetCard
      data-cy="activity-termsheet-cards"
      data-testid={`termsheet-${termsheet.id}`}
      id="activity"
      termsheet={termsheet}
      explanation={explanationText}
      label={typeText}
      date={date}
      author={author}
      style={style}
    />
  )
}

_ActivityTermsheetCard.propTypes = {
  activity: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export const ActivityTermsheetCard = memo(_ActivityTermsheetCard)
