import React, { useEffect } from 'react'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import { InputAdornment } from '@material-ui/core'
import moment from 'moment'
import {
  ORDER_STATUS,
  VALIDITY_TYPE,
  VALIDITY_TYPE_TEXT,
  STAKEHOLDER_TYPE,
} from 'emsurge-selectors/constants'
import { TextField } from '../components/TextField'
import FormRow from 'components/formRow/FormRow'
import { useOrderFlow, ORDER_FLOW } from 'containers/OrderProvider'

const OPTIONS = [
  {
    value: VALIDITY_TYPE.INDICATIVE,
    label: VALIDITY_TYPE_TEXT.INDICATIVE,
  },
  {
    value: VALIDITY_TYPE.GOOD_TILL_CANCELLED,
    label: VALIDITY_TYPE_TEXT.GOOD_TILL_CANCELLED,
  },
  {
    value: VALIDITY_TYPE.GOOD_TILL_DATE,
    label: VALIDITY_TYPE_TEXT.GOOD_TILL_DATE,
  },
]

export const _OrderValidity = ({
  formik: { values, setFieldValue },
  children,
}) => {
  const { flow } = useOrderFlow()

  useEffect(() => {
    if (
      values.validity.until === VALIDITY_TYPE.GOOD_TILL_DATE &&
      (!values.validity.time || !values.validity.date)
    ) {
      setFieldValue(
        'validity.date',
        moment().add(1, 'month').format('YYYY-MM-DD')
      )
      setFieldValue('validity.time', '17:00')
    }

    if ([ORDER_STATUS.CANCELLED, ORDER_STATUS.CLOSED].includes(values.status)) {
      return
    }

    if (values.validity.until === VALIDITY_TYPE.INDICATIVE) {
      setFieldValue('status', ORDER_STATUS.WITHHELD)
    } else if (
      [
        VALIDITY_TYPE.GOOD_TILL_CANCELLED,
        VALIDITY_TYPE.GOOD_TILL_DATE,
      ].includes(values.validity.until)
    ) {
      setFieldValue('status', ORDER_STATUS.LIVE)
    }
  }, [
    values.validity.until,
    setFieldValue,
    values.validity.date,
    values.validity.time,
    values.status,
  ])

  useEffect(() => {
    if (
      flow === ORDER_FLOW.CREATE &&
      !values.template &&
      values.behalfOf === STAKEHOLDER_TYPE.OFF_SYSTEM
    ) {
      setFieldValue('status', ORDER_STATUS.LIVE)
      setFieldValue('validity.until', VALIDITY_TYPE.GOOD_TILL_CANCELLED)
    }
  }, [setFieldValue, values.behalfOf, values.template, flow])

  if (values.status === ORDER_STATUS.PENDING) {
    return null
  }

  return (
    <>
      {children(OPTIONS)}
      {values.validity.until === VALIDITY_TYPE.GOOD_TILL_DATE && (
        <FormRow>
          <TextField
            inputProps={{ 'data-testid': 'validity-date-field' }}
            type="date"
            name="validity.date"
            label="Validity date"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            inputProps={{ 'data-testid': 'validity-time-field' }}
            type="time"
            name="validity.time"
            label="Validity time"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">UTC</InputAdornment>
              ),
            }}
          />
        </FormRow>
      )}
    </>
  )
}

_OrderValidity.defaultProps = {
  children: () => null,
}

_OrderValidity.propTypes = {
  formik: PropTypes.object.isRequired,
  children: PropTypes.func,
}

export const OrderValidity = connect(_OrderValidity)
