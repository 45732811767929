import React, { createContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import { DASHBOARD } from 'routes'
import withRouteSearch from 'utils/withRouteSearch'

export const AppContext = createContext()

export const APP_CONTEXT_LS_KEY = 'app-context'

export const setAppContextStorageValue = (value) =>
  sessionStorage.setItem(APP_CONTEXT_LS_KEY, value)
export const getAppContextStorageValue = () =>
  sessionStorage.getItem(APP_CONTEXT_LS_KEY)

const AppContextProvider = ({ children, appContext }) => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const APP_CONTEXT_KEY = 'appContext'

    if (queryParams.has(APP_CONTEXT_KEY)) {
      queryParams.delete(APP_CONTEXT_KEY)
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, [location])

  const [context, setter] = useState(() => {
    const previousAppContext = getAppContextStorageValue()
    const value = appContext || previousAppContext || APP_CONTEXT_TYPE.CARBON

    if (previousAppContext !== value) {
      setAppContextStorageValue(value)
    }
    return value
  })

  const setAppContext = (value) => {
    if (history.location.pathname !== DASHBOARD) {
      history.push(DASHBOARD)
    }

    setAppContextStorageValue(value)
    return setter(value)
  }

  return (
    <AppContext.Provider value={{ appContext: context, setAppContext }}>
      {children}
    </AppContext.Provider>
  )
}

AppContextProvider.propTypes = {
  children: PropTypes.node,
  appContext: PropTypes.string,
}

export default withRouteSearch(AppContextProvider)
