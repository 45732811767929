import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { toUpper } from 'lodash'
import { useTheme } from '@material-ui/styles'
// import { withSnackbar } from 'notistack'
// import Copy from '@material-ui/icons/FileCopy'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import useWindowSize from 'react-use/lib/useWindowSize'
import { EssentialPanel } from 'screens/orderCreateCarbon/components/EssentialPanel'
import { OptionalPanel } from 'screens/orderCreateCarbon/components/OptionalPanel'
import { OrderSummary } from 'components/OrderSummaryCarbon'
// import { writeToClipboard, getOrderText } from 'utils/clipboard'
// import Button from 'components/iconButton/IconButton'
import PublicNote from 'screens/orderView/components/PublicNote'
import { GridArea, HEADER_AREAS } from 'components/ModalHeader/layout'

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 1px 1px;
  grid-template-areas:
    'summary'
    'details';
`

// const CopyOrderButton = withSnackbar(({ order, enqueueSnackbar }) => {
//   const onClick = async () => {
//     const textToCopy = getOrderText(order)
//     const response = await writeToClipboard(
//       textToCopy,
//       'Order copied to clipboard'
//     )

//     enqueueSnackbar(response.message, {
//       variant: response.error ? 'error' : 'success',
//     })
//   }

//   return (
//     <Button
//       icon={Copy}
//       variant="contained"
//       size="small"
//       color="secondary"
//       onClick={onClick}
//     >
//       Copy order
//     </Button>
//   )
// })

const EmsurgeDisclaimer = () => (
  <footer>
    <Text gutterBottom>
      This link and the contents of it are confidential and may be privileged.
      The information within this link may be read, copied and used only by the
      intended recipients, and must not be re-transmitted in any form without
      the prior consent of the sender. If you think you have received this link
      in error, please notify the sender immediately; you should also not retain
      or disclose the information within it to any other person. Emsurge Limited
      is not the sender nor owner of any information transmitted via the Emsurge
      system. Emsurge Limited does not verify the information in this link nor
      take any liability for the use of it.
    </Text>
    <Text gutterBottom>
      Emsurge is a network of tech enabled energy traders who agree to the
      Emsurge Terms of Use. For a copy of the Terms of Use please email{' '}
      <Text display="inline" component="a" href="mailto:support@emsurge.com">
        support@emsurge.com
      </Text>
      .
    </Text>
    <Text>
      Emsurge is the technology partner of LNG brokers, Emstream. For more
      information on both companies, please visit us at{' '}
      <Text display="inline" component="a" href="www.emstream.co.uk">
        www.emstream.co.uk
      </Text>
      .
    </Text>
  </footer>
)

const OrderView = ({ order }) => {
  const { spacing, breakpoints } = useTheme()
  const { width } = useWindowSize()

  return (
    <section>
      <Grid container justifyContent="space-between">
        <GridContainer>
          <GridArea name={HEADER_AREAS.DETAILS}>
            <OrderSummary order={order} />
          </GridArea>
        </GridContainer>
        <Grid
          container
          alignItems="flex-start"
          style={{ width: width < breakpoints.width('sm') ? '100%' : 'auto' }}
        >
          <Text variant="h6" style={{ marginRight: spacing(2) }}>
            {toUpper(order.status)}
          </Text>
          {/* <CopyOrderButton order={order} /> */}
        </Grid>
      </Grid>

      <PublicNote
        order={order}
        style={{ marginBottom: spacing(1), marginTop: spacing(2) }}
      />
      <EssentialPanel order={order} />
      <OptionalPanel order={order} />
      <EmsurgeDisclaimer />
    </section>
  )
}

OrderView.propTypes = {
  order: PropTypes.object.isRequired,
}

export default OrderView
