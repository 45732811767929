import React from 'react'
import Text from '@material-ui/core/Typography'
import { BulletinsTable } from './components/BulletinsTable'
import { useFetch } from 'containers/fetch/useFetch'

const sortByCreateDate = (bulletins) =>
  bulletins.sort(
    (bulletins1, bulletins2) =>
      new Date(bulletins2.createdAt) - new Date(bulletins1.createdAt)
  )

const ViewBulletins = () => {
  const { loading, error, data: bulletins } = useFetch('/bulletins')

  if (loading || error) {
    return null
  }

  return (
    <>
      <Text variant="h1" gutterBottom>
        BULLETINS
      </Text>
      <BulletinsTable bulletins={sortByCreateDate(bulletins)} />
    </>
  )
}

export default ViewBulletins
