import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { PERSON_PERMISSION, ORDER_STATUS } from 'emsurge-selectors/constants'
import { IconButton, useTheme } from '@material-ui/core'
import MoreMenu from '../containers/HeaderMoreMenu'
import OrderStatusSwitch from '../containers/OrderStatusSwitch'
import { getChecklistProgress } from '../helpers'
// import { CounterAndTalkButton } from './CounterAndTalkButton'
import OrderShareButton from './OrderShareButton/OrderShareButton'
import SwapSymbol from './SwapSymbol'
import { CopyOrderButton } from './CopyOrderButton'
import { EditOrderButton } from './EditOrderButton'
import { OrderSummary } from 'components/OrderSummary'
import { ShareWithBrokerMenuButton } from 'screens/orderView/components/OrderShareButton/ShareWithBrokerMenuButton'
import { $O as $o } from 'model/order/selectors/selector'
import If from 'containers/controlFlow/If'
import Tooltip from 'components/tooltip/Tooltip'
import { useUserHasPermission } from 'permissions'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'
import {
  Wrapper,
  GridContainer,
  GridArea,
  HEADER_AREAS,
} from 'components/ModalHeader/layout'
import { getStatusLabel } from 'screens/orderCreate/helpers'
import { Mobile, useIsMobile } from 'components/Viewport/Mobile'

export const TabsWrapper = styled.div`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.secondary.dark};
`

const SwapLegButton = withRouter(({ order, history, match, style, size }) => {
  const goToSwapLeg = () =>
    history.push(match.path.replace(':id', order.swapId))

  const rootStyle = {
    cursor: 'pointer',
    ...style,
  }

  return (
    <Tooltip title="View swap leg">
      <span>
        <SwapSymbol
          data-testid="view-swap-leg-button"
          size={size}
          style={rootStyle}
          onClick={goToSwapLeg}
        />
      </span>
    </Tooltip>
  )
})

const Header = ({ activeTab, setTab, order, onClose, enqueueSnackbar }) => {
  const { spacing } = useTheme()
  const isMobile = useIsMobile()
  const [expanded, setExpanded] = useState(true)

  const canEditOrder = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.WRITE,
    forceSameCompany: true,
  })

  const canCopyToClipboard = order.permission === PERSON_PERMISSION.TRADE

  const buttonGridProps = {
    justifyContent: `flex-${isMobile ? 'start' : 'end'}`,
  }

  const gridAreaProps = {
    style: {
      margin: spacing(isMobile ? 1 : 2, 0),
    },
  }

  return (
    <>
      {/* UPPER SECTION */}
      <Wrapper>
        <GridContainer data-testid="order-modal-header">
          <OrderSummary order={order} expanded={expanded} />

          <GridArea name={HEADER_AREAS.OTHER_ACTIONS}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid data-testid="view-order-status-label" item>
                {!(
                  [ORDER_STATUS.LIVE, ORDER_STATUS.WITHHELD].includes(
                    order.status
                  ) && !order.template
                ) && getStatusLabel(order)}
                <OrderStatusSwitch
                  data-testid="order-status-switch"
                  order={order}
                  label="Live"
                  id="modalOrderLiveStatus"
                />
              </Grid>
              <Grid item>
                <CloseButton onClick={onClose} />
              </Grid>
            </Grid>
          </GridArea>

          <GridArea name={HEADER_AREAS.MAIN_ACTIONS} {...gridAreaProps}>
            <Grid
              container
              alignItems="center"
              spacing={1}
              {...buttonGridProps}
            >
              {/* <CounterAndTalkButton order={order} /> */}
              <ShareWithBrokerMenuButton closeMenu={onClose} order={order} />
              <Grid item>
                <Grid container spacing={1}>
                  <If condition={$o.swapLeg.exists(order)}>
                    <Grid item>
                      <SwapLegButton order={order} size={40} />
                    </Grid>
                  </If>
                  {!order.template ? (
                    <Grid item>
                      <OrderShareButton order={order} />
                    </Grid>
                  ) : null}
                  <If condition={canEditOrder}>
                    <Grid item>
                      <EditOrderButton
                        data-testid="edit-order-button"
                        order={order}
                      />
                    </Grid>
                  </If>
                  <If condition={canCopyToClipboard}>
                    <Grid item>
                      <CopyOrderButton
                        order={order}
                        enqueueSnackbar={enqueueSnackbar}
                      />
                    </Grid>
                  </If>
                  <Grid item>
                    <MoreMenu order={order} setTab={setTab} onClose={onClose} />
                  </Grid>
                </Grid>
              </Grid>

              <Mobile>
                <Grid item xs>
                  <IconButton
                    style={{ float: 'right' }}
                    onClick={() => setExpanded((expanded) => !expanded)}
                    aria-expanded={expanded}
                    aria-label="show summary"
                  >
                    <ExpandMoreIcon
                      style={{ transform: `rotate(${expanded ? 180 : 0}deg)` }}
                    />
                  </IconButton>
                </Grid>
              </Mobile>
            </Grid>
          </GridArea>
        </GridContainer>
      </Wrapper>

      {/* TABS */}
      <TabsWrapper>
        <If
          condition={order.template}
          then={
            <Tabs indicatorColor="primary" value={0}>
              <Tab
                label="Information"
                id="orderInformationTab"
                data-testid="order-information-tab"
              />
            </Tabs>
          }
          else={
            <Tabs
              scrollButtons="auto"
              variant="scrollable"
              indicatorColor="primary"
              value={activeTab}
              onChange={(_, tabIndex) => setTab(tabIndex)}
            >
              <Tab
                label="Information"
                id="orderInformationTab"
                data-testid="order-information-tab"
              />
              {$o.status.isTraded(order) ? (
                <Tab label="Nominations" id="orderNominationsTab" />
              ) : (
                <Tab
                  label={`Term Sheets (${
                    order.termsheets?.filter((ts) => !$o.status.isClosed(ts))
                      .length || 0
                  })`}
                  disabled={!$o.status.isOutToMarket(order)}
                  id="orderTermsheetsTab"
                  data-testid="order-termsheets-tab"
                />
              )}
              <Tab
                label={`Checklist${getChecklistProgress(order)}`}
                id="orderChecklistTab"
                data-testid="order-checklist-tab"
              />
              <Tab
                label={`Comments (${order.comments?.length || 0})`}
                id="orderCommentsTab"
                data-testid="order-comments-tab"
              />
              <Tab
                label={'Edit Log'}
                data-testid="order-edit-log-tab"
              />
            </Tabs>
          }
        />
      </TabsWrapper>
    </>
  )
}

Header.propTypes = {
  activeTab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default withSnackbar(Header)
