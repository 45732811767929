import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { withSnackbar } from 'notistack'
import { BULLETIN_MESSAGE_TYPE } from 'emsurge-selectors/constants'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const initialValues = {
  messageType: BULLETIN_MESSAGE_TYPE.NEWS,
  title: '',
  message: '',
}

const schema = object().shape({
  messageType: string()
    .oneOf(Object.values(BULLETIN_MESSAGE_TYPE))
    .required('Required'),
  title: string().required('Required'),
  message: string().required('Required'),
})

const _FormProvider = ({ children, postSubmit, enqueueSnackbar }) => {
  const api = useApi()

  const onSubmit = async (data, actions) => {
    try {
      await api.post('/bulletins', data)
      postSubmit()
      enqueueSnackbar('Broadcast message sent', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Broadcast message failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {children}
    </Formik>
  )
}

_FormProvider.propTypes = {
  children: PropTypes.node,
  postSubmit: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
}

export const FormProvider = withSnackbar(_FormProvider)
