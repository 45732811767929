import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { get } from 'lodash'
import FieldCard from 'screens/orderCreateCommon/components/FieldCard'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'
import { getNumberWithOrdinalSuffix } from 'utils/numbers'
import { calculateCargoDeliverWindow } from 'utils/dataHandlers'

const getDeliveryWindow = ({
  mainDeliveryWindow,
  alternativeDeliveryWindow,
  index,
  values,
}) => {
  if (mainDeliveryWindow) {
    const firstCargo = get(
      values,
      'nominationRules.deliveryWindow.cargos[0]',
      null
    )

    if (!firstCargo) {
      return ''
    }

    const { interval, periodName } = calculateCargoDeliverWindow(firstCargo)

    return `${interval} ${periodName}`
  }

  if (alternativeDeliveryWindow) {
    const narrowMechanisms = get(
      values,
      'nominationRules.deliveryWindow.alternatives',
      []
    )
    return narrowMechanisms[index].name
  }

  throw new Error('Undefined delivery window type')
}

const CargoDeliveryWindowCard = ({
  mainDeliveryWindow,
  alternativeDeliveryWindow,
  index,
}) => {
  // The mainDeliveryWindow is the Delivery Window number 1 and created from cargo 1
  // The narrowing mechanisms array starts at 0 (!!!), but the first narrowing mechanism is the second DW
  // so we need to add 2 to the index
  const offset = mainDeliveryWindow ? 1 : 2
  const numberLabel = getNumberWithOrdinalSuffix(index + offset)
  return (
    <FieldCard title={`${numberLabel} DW`} style={{ alignSelf: 'center' }}>
      <FormConsumer>
        {({ values }) => {
          const dw = getDeliveryWindow({
            mainDeliveryWindow,
            alternativeDeliveryWindow,
            index,
            values,
          })
          return <Text variant="caption">{dw || 'N/A'} DW</Text>
        }}
      </FormConsumer>
    </FieldCard>
  )
}

CargoDeliveryWindowCard.propTypes = {
  mainDeliveryWindow: PropTypes.bool,
  alternativeDeliveryWindow: PropTypes.bool,
  index: PropTypes.number,
}

CargoDeliveryWindowCard.defaultProps = {
  index: 0,
}

export default CargoDeliveryWindowCard
