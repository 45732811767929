import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'
import queryString from 'query-string'
import withParams from 'utils/withRouteParams'
import { DeferredTextField } from 'containers/formikTextField/DeferredTextField'

const PublicNotes = ({ location }) => {
  const { palette, spacing } = useTheme()
  const { focus } = queryString.parse(location.search)
  const focusOnNotes = focus === 'details'
  return (
    <Accordion
      defaultExpanded={focusOnNotes}
      style={{
        zIndex: 10,
        width: '100%',
        backgroundColor: palette.background.secondary,
        paddingRight: spacing(4),
        paddingLeft: spacing(4),
        position: 'absolute',
        bottom: 0,
      }}
    >
      <AccordionSummary
        data-testid="public-note-expansive-panel"
        expandIcon={<ExpandLessIcon />}
      >
        <Text variant="subtitle2">Public note</Text>
      </AccordionSummary>
      <AccordionDetails>
        <DeferredTextField
          data-testid="public-note-field"
          fullWidth
          autoFocus={focusOnNotes}
          multiline
          rows={5}
          name="details"
          id="details"
        />
      </AccordionDetails>
    </Accordion>
  )
}

PublicNotes.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withParams(PublicNotes)
