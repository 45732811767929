import { get, values, isString, isEmpty, omitBy } from 'lodash'
import moment from 'moment'
import { DELIVERY_PERIOD_MONTH } from 'emsurge-selectors/constants'
import { PERIOD } from 'model/order/constants/delivery'

const NON_CUSTOM_PERIODS = omitBy(PERIOD, (value) => value === PERIOD.CUSTOM)

/* :: string -> object */
const parseCustomPeriod = (period) => {
  const [yearStr, monthStr] = period.trim().split('-')
  const year = parseInt(yearStr, 10)
  const month = parseInt(monthStr, 10)

  return { month, year }
}

/* :: any -> boolean */
const isValidCustomDate = (val) => {
  if (!isString(val) || isEmpty(val)) {
    return false
  }

  const { month, year } = parseCustomPeriod(val)

  return (
    Number.isFinite(year) &&
    Number.isFinite(month) &&
    month >= 1 &&
    month <= 12 &&
    year > 1999
  )
}

/* :: object -> boolean */
const hasDeliveryContract = (order) => {
  const orderPeriod = get(order, 'delivery.period')
  const hasPeriod = values(NON_CUSTOM_PERIODS).includes(orderPeriod)

  if (hasPeriod) {
    const year = parseInt(get(order, 'delivery.year'), 10)
    return Number.isFinite(year)
  }

  const fromVal = get(order, 'delivery.customFrom')
  const toVal = get(order, 'delivery.customTo')

  const hasValidValues = [fromVal, toVal].every(isValidCustomDate)
  if (!hasValidValues) {
    return false
  }

  return moment(toVal, 'YYYY-MM').isSameOrAfter(fromVal, 'YYYY-MM')
}

/* :: object ->  */
const getDeliveryContract = (order) => {
  if (!hasDeliveryContract(order)) {
    throw new Error('Order has no delivery contract')
  }
  const orderPeriod = get(order, 'delivery.period')
  const hasPeriod = values(NON_CUSTOM_PERIODS).includes(orderPeriod)
  if (hasPeriod) {
    const orderPeriod = order.delivery.period
    const year = parseInt(order.delivery.year, 10)

    return { period: orderPeriod, year }
  }

  const contract = [order.delivery.customFrom, order.delivery.customTo].map(
    parseCustomPeriod
  )

  return contract
}

/* :: order -> booelan */
const isCustomDeliveryContract = (order) =>
  Array.isArray(getDeliveryContract(order))

const isSingleMonthContract = (order) =>
  Object.values(DELIVERY_PERIOD_MONTH).includes(order?.delivery?.period)

export const contract = {
  exists: hasDeliveryContract,
  get: getDeliveryContract,
  isCustom: isCustomDeliveryContract,
  isSingleMonth: isSingleMonthContract,
}
