import { toLower, isNil, get } from 'lodash'
import {
  $O,
  CARGO_SIZE_TYPE,
  CARGO_SIZE_UNIT,
  CARGO_SIZE_VARIANCE_DEFAULT_VALUE,
  DELIVERY_TYPE,
  PARTY,
  ORDER_STATUS,
  ORDER_STATUS_TEXT,
  VALIDITY_TYPE,
  $T,
  NEW_TRADE
} from 'emsurge-selectors'
import moment from 'moment'
import { mapFormikValuesToOrder } from 'screens/orderCreateCommon/helpers'

export const isFOBType = (type) => toLower(type) === 'fob'

export const isBrokerOrder = ({ brokerId }) => !isNil(brokerId)

export const getCargoSizeType = (cargoSizeUnit) => {
  return cargoSizeUnit === CARGO_SIZE_UNIT.TBTU
    ? CARGO_SIZE_TYPE.STANDARD
    : CARGO_SIZE_TYPE.SPECIFIC
}

export const getCargoSizeParty = (deliveryType) => {
  switch (deliveryType) {
    case DELIVERY_TYPE.FOB:
      return PARTY.BUYER
    default:
      return PARTY.SELLER
  }
}

export const getCargoSizeVariance = (cargoSizeParty = PARTY.TBA) => {
  const VARIANCE = CARGO_SIZE_VARIANCE_DEFAULT_VALUE[cargoSizeParty]

  return {
    varianceMinus: VARIANCE.MINUS,
    variancePlus: VARIANCE.PLUS,
  }
}

const getValidityLabel = (values) =>
  values.validity?.until === VALIDITY_TYPE.INDICATIVE
    ? ORDER_STATUS_TEXT[ORDER_STATUS.WITHHELD]
    : ORDER_STATUS_TEXT[ORDER_STATUS.LIVE]

export const getStatusLabel = (values, isCreatingTrade) => {
  if(isCreatingTrade) {
    return NEW_TRADE
  }
  if ($O.template.get(values)) {
    return 'Template'
  }
  if (
    [ORDER_STATUS.PENDING, ORDER_STATUS.CLOSED].includes($O.status.get(values))
  ) {
    return ORDER_STATUS_TEXT[$O.status.get(values)]
  }
  return getValidityLabel(values)
}

const monthsAhead = (fromDate, timeframeValue) => {
  const diff = timeframeValue || 1
  return moment(fromDate)
    .subtract(1, 'month')
    .startOf('month')
    .add(diff - 1, 'days')
    .toDate()
}

const daysAhead = (fromDate, timeframeValue = 0) => {
  return moment(fromDate).subtract(timeframeValue, 'days').toDate()
}

export const getNominationUntil = (timeframe, timeframeValue, from) => {
  const fromDate = new Date(from)

  switch (timeframe) {
    case 'month_ahead':
      return monthsAhead(fromDate, timeframeValue)
    case 'days_ahead':
      return daysAhead(fromDate, timeframeValue)
    default:
      return ''
  }
}

const getCargoData = (values) => {
  const { nominationRules } = mapFormikValuesToOrder(values)
  const allCargos = $T.deliveryWindow.cargos.summary(nominationRules)
  const { from } = allCargos.shift()
  const {
    dischargePort: { timezone = '0' },
  } = nominationRules
  return {
    timezone,
    from,
  }
}

const getFromDateAndTimezone = (cargo, alternative) => {
  const { timeframe, timeframeValue } = alternative
  const { timezone, from } = cargo
  const nominateUntil = getNominationUntil(timeframe, timeframeValue, from)
  return {
    nominateUntil: timeframeValue ? nominateUntil : '',
    timezone,
  }
}

export const calculateNominationDate = ({
  values,
  mainName,
  cargo,
  alternative,
}) => {
  const alternativeToUse = alternative || get(values, mainName)
  const cargoToUse = cargo || getCargoData(values)
  const { nominateUntil, timezone } = getFromDateAndTimezone(
    cargoToUse,
    alternativeToUse
  )
  return !nominateUntil
    ? ''
    : moment(nominateUntil).subtract(timezone, 'hours').format('ddd D MMM YYYY')
}
