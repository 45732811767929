import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, negate, isNil } from 'lodash'
import styled from 'styled-components'
import Text from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import EditButton from './EditButton'
import InfoText from 'screens/companyViewRequest/components/InfoText'
import { ADMIN_EDIT_ENTITY } from 'routes'
import Wrapper from 'screens/companyViewRequest/components/Wrapper'

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

/* :: object -> string[] */
const exists = negate(isEmpty)
const getInfoAddress = (address) =>
  [
    address.line1,
    address.line2,
    `${address.city}, ${address.postCode}`,
    address.country,
  ].filter(exists)

const EntityInformation = ({ id, info, primaryContact, admin }) => {
  const editLink = ADMIN_EDIT_ENTITY.replace(':id', id)

  return (
    <Wrapper>
      <HeaderWrapper>
        <Text component="h1" variant="h1" gutterBottom>
          {info.brandName}
        </Text>

        <EditButton to={editLink} />
      </HeaderWrapper>

      <Grid container spacing={2}>
        {/** GENERAL INFORMATION */}
        <Grid item xs={4}>
          <Text variant="h2" gutterBottom>
            General Information
          </Text>
          <InfoText header="Company Brand name" info={info.brandName} />
          <InfoText header="Parent company Entity" info={info.parentCompany} />
          <InfoText
            header="Registration Number"
            info={info.registrationNumber}
          />
          <InfoText header="Address" info={getInfoAddress(info.address)} />
        </Grid>

        {/** Primary Point of Contact */}
        <Grid item xs={4}>
          <Text variant="h2" gutterBottom>
            Primary point of contact
          </Text>
          <InfoText
            header="Name"
            info={`${primaryContact.name} ${primaryContact.surname}`}
          />
          <InfoText header="Job Title" info={primaryContact.jobTitle} />
          <InfoText header="Email" info={primaryContact.email} />
          <InfoText
            header="Telephone Number"
            info={primaryContact.phoneNumber}
          />
          <InfoText
            header="Address"
            info={getInfoAddress(primaryContact.address)}
          />
        </Grid>

        {/** Entity Admin */}
        <Grid item xs={4}>
          <Text variant="h2" gutterBottom>
            Authorised Signatory
          </Text>
          {isNil(admin) ? (
            <>
              <InfoText header="Name" info="--" />
              <InfoText header="Job Title" info="--" />
              <InfoText header="Email" info="--" />
              <InfoText header="Telephone number" info="--" />
            </>
          ) : (
            <>
              <InfoText header="Name" info={`${admin.name} ${admin.surname}`} />
              <InfoText header="Job Title" info={admin.jobTitle} />
              <InfoText header="Email" info={admin.email} />
              <InfoText header="Telephone number" info={admin.phoneNumber} />
            </>
          )}
        </Grid>
      </Grid>
    </Wrapper>
  )
}

EntityInformation.propTypes = {
  id: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  primaryContact: PropTypes.object.isRequired,
  admin: PropTypes.object,
}

export default EntityInformation
