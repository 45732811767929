import moment from 'moment'
import {
  STAKEHOLDER_TYPE,
  TERM_SPOT_PERIOD_PREFIX,
  TERM_SPOT_VINTAGE_PROJECT_INFORMATION,
  PROJECT_INFORMATION_KEYS,
  PRICE_FIXED_PREFIX,
  VALIDITY_TYPE,
  TERM_SPOT_KEYS,
  TERM_SPOT_OFFSET_PREFIX,
  TERM_SPOT_VINTAGE_FIXED_PREFIX,
  PRICE_TYPE_KEYS,
  PRICE_RANGE_PREFIX,
  PRICE_RFQ_PREFIX,
  PRICE_VOLUME_DEPENDENT_PREFIX,
  PRICE_VOLUME_DEPENDENT_TYPE_KEYS,
  PROGRAM_ELIGIBILITY_PREFIX,
  VOLUME_OFFSET_PREFIX,
  VOLUME_OFFSET,
  PROGRAM_ELIGIBILITY_KEYS,
  CARBON_STANDARD_KEYS,
  CARBON_STANDARD_PREFIX,
  RATING_PREFIX,
  RATING_KEYS,
  TERM_SPOT_INVESTMENT_DESCRIPTION,
  TERM_SPOT_VINTAGE_ROLLING_PREFIX,
  PRICE_FIXED_CURRENCY_PREFIX
} from 'emsurge-selectors'
import { set } from 'lodash'
import { populateCheckboxGroupInitialValues } from '../orderCreateCarbon/components/CheckboxGroup'
import { isThirdPartyOrder } from 'screens/transactionIndex/helpers'

const validateBehalfOf = (thirdParty) => {
  return thirdParty ? STAKEHOLDER_TYPE.OFF_SYSTEM : STAKEHOLDER_TYPE.ON_SYSTEM
}

export const getValidity = ({ validUntil, validityType }) => {
  if (validityType === VALIDITY_TYPE.GOOD_TILL_DATE) {
    return {
      until: VALIDITY_TYPE.GOOD_TILL_DATE,
      date: moment.utc(validUntil).format('YYYY-MM-DD'),
      time: moment.utc(validUntil).format('HH:mm'),
    }
  }

  return {
    until: validityType,
  }
}

export const getStep1 = (order) => {
  const orderInformation = {
    behalfOf: validateBehalfOf(order.thirdParty),
    tradingType: order.tradingType,
    details: order.details,
    validity: getValidity(order)
  }

  if (!isThirdPartyOrder(order)) {
    orderInformation.myCompany = {
      brokerEntityId: order.brokerId,
      // a trader's information
      companyId: order.companyId,
      entityId: order.entityId,
      traderId: order.personId,
    }
    orderInformation.thirdParty = { // This is required to avoid validations related to Formik.
      companyName: '',
      entityName: '',
      contact: '',
    }
  } else {
    orderInformation.myCompany = { brokerEntityId: order.brokerId }
    orderInformation.thirdParty = order.thirdParty
  }

  return orderInformation
}

export const getStep2 = (order) => {
  const initialValues = {}
  populateCheckboxGroupInitialValues(initialValues, PROGRAM_ELIGIBILITY_KEYS, order.carbonType.programEligibility, PROGRAM_ELIGIBILITY_PREFIX, order.carbonType.otherProgramEligibility)
  populateCheckboxGroupInitialValues(initialValues, CARBON_STANDARD_KEYS, order.carbonType.carbonStandard, CARBON_STANDARD_PREFIX, order.carbonType.otherCarbonStandard)
  populateCheckboxGroupInitialValues(initialValues, RATING_KEYS, order.carbonType.rating, RATING_PREFIX)

  return initialValues;
}

export const getStep3 = (order) => {
  const initialValues = {}
  if(order.termVintage.termSpot === TERM_SPOT_KEYS.SPOT) {
    if(order.termVintage.spot.period == 'custom') {
      set(
        initialValues,
        TERM_SPOT_PERIOD_PREFIX,
        {
          period: order.termVintage.spot.period,
          customFrom: order.termVintage.spot.customFrom,
          customTo: order.termVintage.spot.customTo
        }
      )
    }
    else {
      set(
        initialValues,
        TERM_SPOT_PERIOD_PREFIX,
        {
          period: order.termVintage.spot.period,
          year: order.termVintage.spot.year
        }
      )
    }
  }
  if(order.termVintage.termSpot === TERM_SPOT_KEYS.TERM) {
    set(
      initialValues,
      TERM_SPOT_OFFSET_PREFIX,
      {
        period: order.termVintage.term.offset.period,
        customTo: order.termVintage.term.offset.customTo,
        customFrom: order.termVintage.term.offset.customFrom
      }
    )
    set(
        initialValues,
        TERM_SPOT_VINTAGE_FIXED_PREFIX,
        {
          period: order.termVintage.term.vintage.period,
          year: order.termVintage.term.vintage.year,
        }
    )
    set(
      initialValues,
      TERM_SPOT_VINTAGE_ROLLING_PREFIX,
      {
        within: order.termVintage.term.vintage.within,
        year: order.termVintage.term.vintage.year,
      }
    )
    
  }
  if(order.termVintage.investment.investmentDescription) {
    set(
      initialValues,
      TERM_SPOT_INVESTMENT_DESCRIPTION,
      order.termVintage.investment.investmentDescription
    )
  }
  populateCheckboxGroupInitialValues(initialValues, PROJECT_INFORMATION_KEYS, order.termVintage.projectInformation, TERM_SPOT_VINTAGE_PROJECT_INFORMATION)
  return initialValues
}

export const getStep4 = (order) => {
  const initialValues = {}
  if(order.volume.offset.includes(VOLUME_OFFSET.ALL_OR_NOTHING)) {
    set(
      initialValues,
      `${VOLUME_OFFSET_PREFIX}.${VOLUME_OFFSET.ALL_OR_NOTHING}`,
      true
    )
  }
  return initialValues;
}

export const getStep5 = (order) => {
  const initialValues = {}
  if( order.price.type ===  PRICE_TYPE_KEYS.FIXED){
    set(initialValues, PRICE_FIXED_PREFIX, order.price.basic.amount)
    set(initialValues, PRICE_FIXED_CURRENCY_PREFIX, order.price.basic.fixedCurrency)
  }  
  else if( order.price.type ===  PRICE_TYPE_KEYS.RANGE){
    set(initialValues, PRICE_RANGE_PREFIX.currency, order.price.basic.currency)
    set(initialValues, PRICE_RANGE_PREFIX.min, order.price.basic.min)
    set(initialValues, PRICE_RANGE_PREFIX.max, order.price.basic.max)
  }  
  else if( order.price.type ===  PRICE_TYPE_KEYS.VOLUME_DEPENDENT){
    const volumeDependent = order.price.basic.map((x) => {
      let result = {
        type: x.type,
        price: x.price,
        currency: x.currency,
      }
      if(x.type === PRICE_VOLUME_DEPENDENT_TYPE_KEYS.UP_TO.key || x.type === PRICE_VOLUME_DEPENDENT_TYPE_KEYS.ABOVE.key){
        result = { 
          ...result, 
          quantity: x.quantity
        }
      }
      else if(x.type === PRICE_VOLUME_DEPENDENT_TYPE_KEYS.FROM.key){
        result = { 
          ...result, 
          quantityFrom: x.quantityFrom,
          quantityTo: x.quantityTo
        }
      }
      return result
    })
    set(initialValues, PRICE_VOLUME_DEPENDENT_PREFIX, volumeDependent)   
  }  
  else if( order.price.type ===  PRICE_TYPE_KEYS.RFQ){
    set(initialValues, PRICE_RFQ_PREFIX.deadline.date, order.price.basic.deadline.date)
    set(initialValues, PRICE_RFQ_PREFIX.deadline.time, order.price.basic.deadline.time)
    set(initialValues, PRICE_RFQ_PREFIX.award.date, order.price.basic.award.date)
    set(initialValues, PRICE_RFQ_PREFIX.award.time, order.price.basic.award.time)
    set(initialValues, PRICE_RFQ_PREFIX.notes, order.price.basic.notes)
  }  
  return initialValues
}

export const getEssentialEditValues = (order) => {
  const result = {
    ...getStep1(order),
    ...getStep2(order),
    ...getStep3(order),
    ...getStep4(order),
    ...getStep5(order)
  }
  return result
}
