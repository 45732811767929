import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/styles'

/**
 [1]. Adding a prop of small by default does not change the size of icon which
 has a default appearance of large
 */
const IconButton = ({ icon: Icon, children, ...props }) => {
  const { spacing } = useTheme()
  const iconStyles = {
    marginRight: spacing(1),
  }

  if (props.size === 'small') {
    iconStyles.fontSize = 16 /* [1] */
  }

  return (
    <Button {...props}>
      <Icon style={iconStyles} />
      {children}
    </Button>
  )
}

IconButton.propTypes = {
  icon: PropTypes.object,
  children: PropTypes.node,
  size: PropTypes.string,
}

export default IconButton
