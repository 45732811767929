import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { findIndex } from 'lodash'
import Autosizer from 'react-virtualized-auto-sizer'
import ActivityCard from './ActivityCard'
import { guessBulletinActivityCardHeight } from 'screens/orderIndex/helpers'
import { VirtualList } from 'components/VirtualList'

const _BulletinsActivity = ({ active, onActiveChange, bulletins }) => {
  const memoizedBulletins = useMemo(() => bulletins, [bulletins])

  const selectedIndex = findIndex(
    memoizedBulletins,
    ({ id }) => id === active.id
  )

  const getItemSize = (data, index) => {
    const bulletin = data[index]
    return guessBulletinActivityCardHeight(bulletin.message, !!bulletin.title)
  }

  const RowRenderer = ({ index, style, data }) => {
    const GUTTER = 4
    const bulletin = data[index]
    return (
      <ActivityCard
        key={bulletin.id}
        bulletin={bulletin}
        selected={bulletin.id === active.id}
        onClick={() => onActiveChange(bulletin)}
        style={{
          ...style,
          overflow: 'hidden',
          top: style.top + GUTTER,
          height: style.height - GUTTER,
        }}
      />
    )
  }

  RowRenderer.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
    data: PropTypes.array,
  }

  return (
    <Autosizer>
      {({ width, height }) => (
        <VirtualList
          width={width}
          height={height - 55 /* provide enough space for search bar (55px) */}
          fillParent={false}
          getItemSize={getItemSize}
          items={memoizedBulletins}
          scrollTo={selectedIndex}
          rowRenderer={RowRenderer}
        />
      )}
    </Autosizer>
  )
}

_BulletinsActivity.defaultProps = {
  active: {},
  onActiveChange: () => {},
}

_BulletinsActivity.propTypes = {
  bulletins: PropTypes.array.isRequired,
  active: PropTypes.object,
  onActiveChange: PropTypes.func,
}

export const BulletinsActivity = withRouter(_BulletinsActivity)
