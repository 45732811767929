const getLaytime = (terms) => {
  return terms.laytime
}

const getLaytimeSummary = (terms) => {
  const laytime = getLaytime(terms)
  return laytime ? `${laytime}` : ''
}

const getLaytimeText = (terms) => {
  const laytime = getLaytimeSummary(terms)
  return laytime
    ? `The Allowed Laytime at the Discharge Port shall be ${laytime} consecutive hours`
    : ''
}

module.exports = {
  get: getLaytime,
  summary: getLaytimeSummary,
  text: getLaytimeText,
}
