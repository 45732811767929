import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { LayoutContext } from 'containers/layout/LayoutProvider'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
}))

const Wrapper = styled.main`
  position: relative;
  display: block;
  margin-top: ${({ theme }) => theme.custom.mobile.navbar.height}px;
  height: calc(100vh - ${({ theme }) => theme.custom.mobile.navbar.height}px);
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`

export const Main = ({ children, ...props }) => {
  const layout = useContext(LayoutContext)
  const classes = useStyles()

  return (
    <Wrapper {...props}>
      {children}
      <Backdrop
        className={classes.backdrop}
        open={layout.hasSideMenu}
        onClick={() => layout.setSideMenu(false)}
      />
    </Wrapper>
  )
}

Main.defaultProps = {
  style: {},
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}
