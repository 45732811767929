import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableRowLink from 'components/tableRowLink/TableRowLink'
import { ADMIN_VIEW_ENTITY } from 'routes'

const getEntityAdminName = (persons) => {
  const adminPersonNames = persons
    .filter((person) => person.isAdmin)
    .map((adminPerson) => `${adminPerson.name} ${adminPerson.surname}`)
    .join(', ')

  return adminPersonNames
}

const EntityTable = ({ entities }) => (
  <Paper>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Entity</TableCell>
          <TableCell>Admin</TableCell>
          <TableCell>Users</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {entities.map(({ id, name, persons = [] }) => (
          <TableRowLink key={id} to={ADMIN_VIEW_ENTITY.replace(':id', id)}>
            <TableCell style={{ fontWeight: 'bold' }}>{name}</TableCell>
            <TableCell>{getEntityAdminName(persons) || '--'}</TableCell>
            <TableCell>{persons.length || '--'}</TableCell>
          </TableRowLink>
        ))}
      </TableBody>
    </Table>
  </Paper>
)

EntityTable.propTypes = {
  entities: PropTypes.array.isRequired,
}

export default EntityTable
