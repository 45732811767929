import React, { memo } from 'react'
import PropTypes from 'prop-types'
import InfiniteLoader from 'react-window-infinite-loader'
import { VirtualList } from './VirtualList'

const _VirtualListInfinite = ({
  items,
  loadNextPage,
  hasNextPage,
  ...props
}) => {
  const itemCount = hasNextPage ? items.length + 1 : items.length
  const isItemLoaded = (index) => !hasNextPage || index < items.length

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadNextPage}
    >
      {({ onItemsRendered, ref }) => (
        <VirtualList
          items={items}
          onItemsRendered={onItemsRendered}
          setRef={ref}
          {...props}
        />
      )}
    </InfiniteLoader>
  )
}

_VirtualListInfinite.defaultProps = {
  items: [],
  loadNextPage: () => {},
  hasNextPage: false,
}

_VirtualListInfinite.propTypes = {
  items: PropTypes.array,
  loadNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
}

export const VirtualListInfinite = memo(_VirtualListInfinite)
