import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MuiIconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'

const FlexWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing(1)}px;
  top: ${({ theme }) => theme.spacing(1)}px;
  cursor: pointer;
  z-index: 10;
`

export const CloseButton = ({ onClick }) => (
  <FlexWrapper>
    <MuiIconButton aria-label="Close" onClick={onClick} style={{ padding: 0 }}>
      <Close />
    </MuiIconButton>
  </FlexWrapper>
)

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
