import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { $O } from 'model/order/selectors/selector'
import OrderStatusSwitch from 'screens/orderView/containers/OrderStatusSwitch'

/** [1]. This container is used to reduce the size of the switch for the cards */
const SwitchContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 26px;
  overflow: hidden;
`

const SmallOrderStatusSwitch = ({ order, ...props }) => {
  if (!$O.status.isOutToMarket(order) || order.template) {
    return null
  }

  return (
    <SwitchContainer onClick={(e) => void e.stopPropagation()}>
      <OrderStatusSwitch order={order} {...props} />
    </SwitchContainer>
  )
}

SmallOrderStatusSwitch.propTypes = {
  order: PropTypes.object.isRequired,
}

export default SmallOrderStatusSwitch
