const {
  DELIVERY_WINDOW_TYPE,
  DELIVERY_WINDOW_TYPE_TEXT,
} = require('../../../constants')

// TODO: When creating/updating an order isProRata is being sent instead of the delivery type
const getType = (terms) => {
  const deliveryWindow = terms.deliveryWindow || {}
  const isProRata = deliveryWindow.isProRata

  switch (isProRata) {
    case true:
      return DELIVERY_WINDOW_TYPE.PRO_RATA
    case false:
      return DELIVERY_WINDOW_TYPE.PER_CARGO
    default:
      return undefined
  }
}

const getTypeText = (terms) => {
  const type = getType(terms)

  return DELIVERY_WINDOW_TYPE_TEXT[type] || ''
}

module.exports = {
  get: getType,
  text: getTypeText,
}
