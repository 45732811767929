import { useState } from 'react'
import { useQuery } from 'react-query'
import { ACTIVITIES_KEY } from 'api/constants'
import { useApi } from 'containers/api/useApi'

export const useActivities = () => {
  const [ lastRequestTime, setLastRequestTime ] = useState(0)
  const api = useApi()

  const onSuccess = () => {
    setLastRequestTime(Date.now())
  }

  const fetchActivities = async (time) =>
    await api.getActivities(time)

  const getAll = async () => {
    const latest = await fetchActivities(lastRequestTime)
    setLastRequestTime(Date.now())

    return {
      all: latest,
    }
  }

  return useQuery(ACTIVITIES_KEY, () => getAll(), {
    onSuccess,
    refetchInterval: 3000,
    refetchIntervalInBackground: false,
    staleTime: Infinity
  })
}
