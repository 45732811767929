const { REGISTTRATION_STATUS } = require('./registration')

const ENTITY_STATUS = Object.freeze({
  ...REGISTTRATION_STATUS,
})

const ENTITY_TYPE = Object.freeze({
  SYSTEM: 'system',
  TRADER: 'trader',
  BROKER: 'broker',
})

module.exports = {
  ENTITY_STATUS,
  ENTITY_TYPE,
}
