import React from 'react'
import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton'

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const CargoCardSkeleton = () => (
  <Container>
    <Skeleton variant="rect" width={750} height={192} />
  </Container>
)
