import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Box, Grid, Typography as Text, IconButton } from '@material-ui/core'
import { Route, useHistory, useRouteMatch } from 'react-router'
import CreateIcon from '@material-ui/icons/Create'
import { GridProvider } from './GridProvider'
import { GridFilters } from './GridFilters'
import { MARKET_GRID, MARKET_GRID_VIEW } from 'routes'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { useGrids } from 'api'
import { StyledLink } from 'components/StyledLink'
import { MarketGridView } from 'screens/marketGridView/MarketGridView'
import ViewOrder from 'screens/orderView/ViewOrder'

const TabButton = styled.div`
  background: ${({ selected, theme: { palette } }) =>
    selected ? palette.background.secondary : palette.background.paper};
  width: ${({ theme: { spacing } }) => spacing(20)}px;
  border-radius: 5px 5px 0px 0px;
  display: inline-flex;
  justify-content: space-between;
  margin-right: ${({ theme: { spacing } }) => spacing(0.5)}px;
  margin-bottom: ${({ theme: { spacing } }) => spacing(0.25)}px;
  padding: ${({ theme: { spacing } }) => spacing(0.75, 1.25)};
`
const byIndex = (a, b) => (a.index < b.index ? -1 : 1)

const Header = () => (
  <Grid container alignItems="center" justifyContent="space-between">
    <Grid item>
      <Text variant="h1">Market Grid</Text>
    </Grid>
    <Grid item>
      <GridFilters />
    </Grid>
  </Grid>
)

const MarketGridIndex = () => {
  const history = useHistory()
  const indexMatch = useRouteMatch({
    path: MARKET_GRID,
    strict: true,
  })
  const viewMatch = useRouteMatch({
    path: MARKET_GRID_VIEW,
    strict: true,
  })
  const { data: grids, isLoading } = useGrids()

  useEffect(() => {
    if (indexMatch.isExact && !isLoading) {
      const [defaultGrid] = grids
      history.push(`${indexMatch.url}/${defaultGrid.id}`)
    }
  }, [grids, history, indexMatch.isExact, indexMatch.url, isLoading])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <GridProvider>
      <Box pb={5}>
        <Header />
      </Box>
      <Box>
        {grids.sort(byIndex).map((grid) => (
          <StyledLink to={`${indexMatch.url}/${grid.id}`} key={grid.id}>
            <TabButton
              size="small"
              selected={grid.id === viewMatch?.params?.id}
            >
              <Text variant="subtitle1">{grid.name}</Text>
              <IconButton
                aria-label="edit"
                size="small"
                onClick={(evt) => {
                  evt.preventDefault()
                  history.push(`${MARKET_GRID}/${grid.id}/edit`)
                }}
              >
                <CreateIcon style={{ fontSize: 14 }} />
              </IconButton>
            </TabButton>
          </StyledLink>
        ))}
      </Box>
      <Switch>
        <Route
          path={`${MARKET_GRID}/orders/:id`}
          component={() => <ViewOrder onClose={() => history.goBack()} />}
        />
        <Route path={MARKET_GRID_VIEW} component={MarketGridView} />
      </Switch>
    </GridProvider>
  )
}

export default MarketGridIndex
