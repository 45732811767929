import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core'
import { getCompanyCodeStyle } from './CompanyCodeHelpers'
import { $O } from 'model/order/selectors/selector'
import { useUser } from 'containers/user/useUser'

export const Square = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
  margin-bottom: 1px;
  display: inline-block;
`

Square.propTypes = {
  color: PropTypes.string.isRequired,
}

const CompanyCode = ({ order, style, ...props }) => {
  const { user } = useUser()
  const { palette } = useTheme()
  const { textColor, symbolColor } = getCompanyCodeStyle({
    user,
    order,
    palette,
  })

  if (!$O.stakeholder.code.exists(order)) {
    return null
  }

  const textStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: textColor,
  }

  return (
    <Text variant="button" style={{ ...textStyle, ...style }} {...props}>
      {$O.stakeholder.code.get(order)}
      {symbolColor && (
        <Square
          data-testid="shared-order-indicator"
          color={symbolColor}
          className="company-code-symbol"
        />
      )}
    </Text>
  )
}

CompanyCode.propTypes = {
  order: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export default CompanyCode
