const hasPrice = () => false
const getPrice = () => undefined
const getSummary = () => ({
  long: '',
  short: '',
})

export const unknown = {
  exists: hasPrice,
  get: getPrice,
  summary: getSummary,
}
