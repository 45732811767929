import React from 'react'

import {
  Button,
  StyledConnectedSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { SelectableGridOption as Option } from 'components/SelectableGrid'
import { SelectCargoSizeUnit } from 'screens/orderCreate/components/SelectCargoSizeUnit'

export const Unit = () => (
  <SelectCargoSizeUnit>
    {({ options, onChange }) => (
      <StyledConnectedSelectableGrid
        name="cargoSize.unit"
        onChange={(value) => onChange(value)}
      >
        {options.map(({ value, label }) => (
          <Option
            data-testid={`cargoSize.unit-${value}`}
            key={`order-cargo-size-unit-${value}`}
            xs={4}
            value={value}
          >
            <Button>{label}</Button>
          </Option>
        ))}
      </StyledConnectedSelectableGrid>
    )}
  </SelectCargoSizeUnit>
)
