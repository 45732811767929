import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import backgroundImg from 'screens/background.png'

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    width: '100vw',
    minHeight: '95vh',
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: 'bottom right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  contentContainer: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
  },
}))

const LayoutWrapper = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.rootContainer}>
      <div className={classes.contentContainer}>{children}</div>
    </div>
  )
}

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutWrapper
