import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import { $U, CHECKLIST_TYPE_TEXT, PERSON_PERMISSION } from 'emsurge-selectors'
import { flatMap, isEmpty, sortBy, throttle } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import ChecklistFormProvider from '../containers/ChecklistFormProvider'
import { ItemCheckbox } from '../containers/ItemCheckbox'
import ChecklistEditForm from './ChecklistEditForm'
import EditButton from './EditButton'
import { useUserHasPermission } from 'permissions'
import { isTermsheet } from 'model/termsheet/isTermsheet'
import { Mobile, Tablet } from 'components/Viewport'
import { useEditChecklistItem } from 'api'

const ChecklistItem = ({ onChange, checklistItem, disabled }) => {
  const { palette, spacing } = useTheme()

  return (
    <>
      <article className="checklist-item">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1}>
            <ItemCheckbox
              inputProps={{ 'data-testid': 'checklist-tab-item-checkbox' }}
              name="item-checkbox"
              checked={checklistItem.isChecked}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>

          <Tablet matchLarger>
            <Grid item sm={4} md={3}>
              <Text
                data-testid="checklist-tab-item-name"
                name="item-name"
                style={{ fontWeight: 700 }}
                variant="subtitle1"
              >
                {checklistItem.name}
              </Text>
            </Grid>

            <Grid item sm>
              <Text
                data-testid="checklist-tab-item-deadline"
                name="item-deadline"
                variant="subtitle1"
              >
                {moment(checklistItem.deadlineDate).format('Do MMM YYYY ddd')}
              </Text>
            </Grid>

            {checklistItem.isChecked && (
              <>
                <Grid item xs={3}>
                  <Text
                    name="item-checkedDate"
                    variant="subtitle1"
                    style={{ color: palette.text.disabled }}
                  >
                    {moment(checklistItem.checkedDate).format(
                      'Do MMM YYYY ddd HH:mm'
                    )}
                  </Text>
                </Grid>
                <Grid item xs>
                  <Text
                    name="item-checkedDate"
                    variant="subtitle1"
                    style={{ color: palette.text.disabled }}
                  >
                    {`${$U.fullName.get(checklistItem.checkedPerson)}`}
                  </Text>
                </Grid>
              </>
            )}
          </Tablet>

          <Mobile>
            <Grid item xs style={{ padding: spacing(3) }}>
              <Text
                name="item-name"
                style={{ fontWeight: 700, lineHeight: 1 }}
                variant="subtitle1"
              >
                {checklistItem.name}
              </Text>
              <Text
                name="item-deadline"
                variant="overline"
                style={{ color: '#A7B8C2', lineHeight: 1.5 }}
              >
                {moment(checklistItem.deadlineDate).format('Do MMM YYYY ddd')}
              </Text>
              {checklistItem.isChecked && (
                <Text
                  name="item-checkedDate"
                  variant="overline"
                  style={{ color: '#A7B8C2', lineHeight: 1 }}
                  component="p"
                >
                  {moment(checklistItem.checkedDate).format('YY/MM/DD HH:mm')} -{' '}
                  {`${$U.fullName.get(checklistItem.checkedPerson)}`}
                </Text>
              )}
            </Grid>
          </Mobile>
        </Grid>
      </article>
      <Divider />
    </>
  )
}

ChecklistItem.propTypes = {
  onChange: PropTypes.func,
  checklistItem: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

const EditChecklistButton = ({ canEditChecklists, onClick }) => (
  <Grid container justifyContent="flex-end">
    <EditButton
      data-testid="checklist-tab-edit-button"
      onClick={onClick}
      disabled={!canEditChecklists}
    />
  </Grid>
)

EditChecklistButton.propTypes = {
  canEditChecklists: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

const EmptyCheckList = ({ canEditChecklists, onEdit }) => (
  <>
    <EditChecklistButton
      canEditChecklists={canEditChecklists}
      onClick={onEdit}
    />
    <Text data-testid="checklists-tab-no-checklists" variant="body1">
      {' '}
      There are no checklists
    </Text>
  </>
)

EmptyCheckList.propTypes = {
  canEditChecklists: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
}

const EditCheckListForm = ({ checklists, setView }) => (
  <ChecklistFormProvider checklists={checklists} postSubmit={setView}>
    <ChecklistEditForm />
  </ChecklistFormProvider>
)

EditCheckListForm.propTypes = {
  setView: PropTypes.func.isRequired,
  checklists: PropTypes.array.isRequired,
}

const Checklist = ({ order }) => {
  const { mutateAsync: editChecklistItem } = useEditChecklistItem()
  const [mode, setMode] = useState('VIEW')
  const setEdit = () => setMode('EDIT')
  const setView = () => setMode('VIEW')
  const { spacing } = useTheme()

  const mutateItem = (checklist, id, isChecked) =>
    editChecklistItem({
      id,
      checklistId: checklist.id,
      orderId: checklist.orderId,
      isChecked,
    })

  const onItemCheck = useCallback(throttle(mutateItem, 1000), [])

  const canEditChecklists = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.WRITE,
    forceSameCompany: !isTermsheet(order),
  })

  const checklists = order.checklist || []
  const allCheckListItems = flatMap(checklists, (checklist) => checklist.items)

  if (mode === 'EDIT') {
    return <EditCheckListForm setView={setView} checklists={checklists} />
  }

  if (isEmpty(allCheckListItems)) {
    return (
      <EmptyCheckList canEditChecklists={canEditChecklists} onEdit={setEdit} />
    )
  }

  const orderedChecklists = checklists.sort((cA, cB) => cA.type < cB.type)

  return (
    <section id="orderCheckListSection">
      <EditChecklistButton
        canEditChecklists={canEditChecklists}
        onClick={setEdit}
      />
      {orderedChecklists.map((checklist, index) => {
        const items = checklist.items || []
        const checklistLabel = CHECKLIST_TYPE_TEXT[checklist.type]
        const orderedItems = sortBy(items, ['deadlineDate', 'id'])

        return (
          <Fragment key={checklist.id}>
            <Text
              style={{
                padding: index > 0 ? spacing(4, 0, 2, 0) : spacing(0, 0, 2, 0),
              }}
              variant="h6"
            >
              {checklistLabel}
            </Text>
            {orderedItems.map((checkItem) => (
              <ChecklistItem
                key={checkItem.id || uuidv4()}
                checklistItem={checkItem}
                onChange={(isChecked) =>
                  onItemCheck(checklist, checkItem.id, isChecked)
                }
                disabled={!canEditChecklists}
              />
            ))}
          </Fragment>
        )
      })}
    </section>
  )
}

Checklist.propTypes = {
  order: PropTypes.object.isRequired,
}

export default Checklist
