import { times, isEmpty, get } from 'lodash'
import moment from 'moment'
import { DELIVERY_VOLUME_MAX_VALUE } from 'emsurge-selectors/constants'
import { DEFAULT_DATE_FORMAT } from '../components/DeliveryWindow.helpers'
import { $O } from 'model/order/selectors/selector'

/* :: object[] -> object */
const validateHybridPricesWeight = (hybridPrices) => {
  const totalWeight = hybridPrices
    .map(({ weight }) => parseInt(weight, 10) || 0)
    .reduce((acc, x) => acc + x, 0)

  if (totalWeight !== 100) {
    const msg = 'Total sum weight of hybrid prices must equal 100'
    const errors = times(hybridPrices.length).map(() => ({ weight: msg }))

    return errors
  }
}

/* :: object -> object */
const validatePrices = (prices) => {
  const errors = {}
  const priceTypes = ['basic', 'reserve', 'contract']

  priceTypes.forEach((type) => {
    const price = prices[type]

    if (price.enable && price.type === 'hybrid') {
      const errorMessage = validateHybridPricesWeight(price.hybridInfo)
      if (!isEmpty(errorMessage)) {
        errors[type] = { hybridInfo: errorMessage }
      }
    }
  })

  return errors
}

const validateContract = (order) => {
  if (!$O.delivery.contract.exists(order)) {
    return 'Invalid period'
  }

  const isCustomPeriod = order.delivery && order.delivery.period === 'custom'
  if (!isCustomPeriod) {
    const { year } = $O.delivery.contract.get(order)
    const yearIsValid = year && year / 1000 > 1

    if (!yearIsValid) {
      return 'Invalid period year'
    }
  }

  return undefined
}

const validateVolume = (order) => {
  const quantity = get(order, 'volume.min')
  if (quantity > DELIVERY_VOLUME_MAX_VALUE) {
    return `Number of cargoes should not exceed ${DELIVERY_VOLUME_MAX_VALUE}`
  }
  return undefined
}

const validateCargo = (cargo) => {
  if (
    moment(cargo.from, DEFAULT_DATE_FORMAT) >
    moment(cargo.to, DEFAULT_DATE_FORMAT)
  ) {
    return { from: 'Invalid range', to: 'Invalid range' }
  }
  return undefined
}

const validateCargoes = (order) => {
  const cargoes = get(order, 'nominationRules.deliveryWindow.cargos', [])
  const cargoesErrors = cargoes.map(validateCargo)
  return cargoesErrors.every(isEmpty) ? undefined : cargoesErrors
}

const validateSize = (size) =>
  size <= 0 ? 'Size must be greater than zero' : null

const validateMaxSize = (sizes) =>
  sizes.max <= sizes.min
    ? 'Max size must be greater than min size'
    : validateSize(sizes.max)

const validateCustomSize = (values) =>
  values.customMax <= values.customMin
    ? 'Custom max must be greater than custom min'
    : null

/* :: object -> object */
export const validateOrder = (values) => {
  const errors = {
    price: validatePrices(values.price),
    delivery: {
      period: validateContract(values),
    },
    cargoSize: {
      varianceMinus: validateSize(get(values, 'cargoSize.varianceMinus')),
      variancePlus: validateSize(get(values, 'cargoSize.variancePlus')),
    },
    volume: {
      min: validateVolume(values),
    },
    quality: {
      customMax: validateCustomSize(get(values, 'quality')),
      customMin: validateCustomSize(get(values, 'quality')),
    },
    nominationRules: {
      deliveryWindow: {
        cargos: validateCargoes(values),
      },
      ship: {
        base: {
          size: validateSize(get(values, 'nominationRules.ship.base.size')),
          min: validateSize(get(values, 'nominationRules.ship.base.min')),
          max: validateMaxSize(get(values, 'nominationRules.ship.base', {})),
        },
      },
    },
  }

  const noErrors =
    isEmpty(errors.price) &&
    isEmpty(errors.delivery.period) &&
    isEmpty(errors.volume.min) &&
    isEmpty(errors.nominationRules.deliveryWindow.cargos) &&
    isEmpty(errors.nominationRules.ship.base.size) &&
    isEmpty(errors.nominationRules.ship.base.min) &&
    isEmpty(errors.nominationRules.ship.base.max)

  return noErrors ? undefined : errors
}
