const { ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX } = require('./carbonPrefix')

const ADDITIONAL_ATTRIBUTES = Object.freeze({
  CCB: 'CCB',
  JNR: 'JNR',
  REDD: 'REDD+',
  SDGS: '6+SDGs (SD VISta/GS)',
  CDP: 'CDP',
  Other: 'Other',
})

const ADDITIONAL_ATTRIBUTES_FULL_KEYS = {}
Object.keys(ADDITIONAL_ATTRIBUTES).forEach((key) => {
  ADDITIONAL_ATTRIBUTES_FULL_KEYS[
    key
  ] = `${ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX}.${key}`
})

const ADDITIONAL_ATTRIBUTES_OPTIONS_PATHS = Object.keys(
  ADDITIONAL_ATTRIBUTES
).map((cert) => `${ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX}.${cert}`)

const ADDITIONAL_ATTRIBUTES_OPTIONS = Object.keys(ADDITIONAL_ATTRIBUTES).map(
  (cert) => {
    const option = {
      label: ADDITIONAL_ATTRIBUTES[cert],
      value: `${ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX}.${cert}`,
    }
    if (cert === ADDITIONAL_ATTRIBUTES.Other) {
      option.extra = {
        field: `${ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX}.otherContent`,
        placeholder: 'Certification',
      }
    }
    return option
  }
)

const SDGS_KEYS = {
  NOPO: 'NOPO',
  ZEHU: 'ZEHU',
  GHWB: 'GHWB',
  QUED: 'QUED',
  GEEQ: 'GEEQ',
  CWAS: 'CWAS',
  AACE: 'AACE',
  DWEG: 'DWEG',
  IIAI: 'IIAI',
  REIN: 'REIN',
  SCAC: 'SCAC',
  RCAP: 'RCAP',
  CLAC: 'CLAC',
  LBWA: 'LBWA',
  LOLA: 'LOLA',
  PJSI: 'PJSI',
  PAGO: 'PAGO',
}

const SDGS_OPTIONS = {
  [SDGS_KEYS.NOPO]: '1 - No Poverty',
  [SDGS_KEYS.ZEHU]: '2 - Zero Hunger',
  [SDGS_KEYS.GHWB]: '3 - Good Health and Well-being',
  [SDGS_KEYS.QUED]: '4 - Quality Education',
  [SDGS_KEYS.GEEQ]: '5 - Gender Equality',
  [SDGS_KEYS.CWAS]: '6 - Clean Water and Sanitation',
  [SDGS_KEYS.AACE]: '7 - Affordable and Clean Energy',
  [SDGS_KEYS.DWEG]: '8 - Decent Work and Economic Growth',
  [SDGS_KEYS.IIAI]: '9 - Industry, Innovation and Infrastructure',
  [SDGS_KEYS.REIN]: '10 - Reducing Inequality',
  [SDGS_KEYS.SCAC]: '11 - Sustainable Cities and Communities',
  [SDGS_KEYS.RCAP]: '12 - Responsible Consumption and Production',
  [SDGS_KEYS.CLAC]: '13 - Climate Action',
  [SDGS_KEYS.LBWA]: '14 - Life Below Water',
  [SDGS_KEYS.LOLA]: '15 - Life On Land',
  [SDGS_KEYS.PJSI]: '16 - Peace, Justice, and Strong Institutions',
  [SDGS_KEYS.PAGO]: '17 - Partnerships for the Goals',
}

const ADDITIONAL_ATTRIBUTES_TYPE_KEYS = {
  CERTIFIED: 'CERTIFIED',
  NONCERTIFIED: 'NONCERTIFIED',
}

const ADDITIONAL_ATTRIBUTES_TYPE_OPTIONS = {
  [ADDITIONAL_ATTRIBUTES_TYPE_KEYS.CERTIFIED]: 'Certified',
  [ADDITIONAL_ATTRIBUTES_TYPE_KEYS.NONCERTIFIED]: 'Non-Certified',
}

module.exports = {
  ADDITIONAL_ATTRIBUTES,
  ADDITIONAL_ATTRIBUTES_OPTIONS_PATHS,
  ADDITIONAL_ATTRIBUTES_OPTIONS,
  SDGS_KEYS,
  SDGS_OPTIONS,
  ADDITIONAL_ATTRIBUTES_FULL_KEYS,
  ADDITIONAL_ATTRIBUTES_TYPE_KEYS,
  ADDITIONAL_ATTRIBUTES_TYPE_OPTIONS,
}
