import React from 'react'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { useTheme } from '@material-ui/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Text from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Form } from 'formik'
import { withSnackbar } from 'notistack'
import { PERSON_CLIENT_TYPE } from 'emsurge-selectors/constants'
import FormikTextField from 'containers/formikTextField/FormikTextField'
import FormikSwitch from 'containers/formikSwitch/FormikSwitch'
import SubmitButton from 'containers/formikSubmitButton/FormikSubmitButton'
import FormRow from 'components/formRow/FormRow'
import FormikRadioInput from 'containers/FormikRadioInput/FormikRadioInput'

const TextField = withProps({
  fullWidth: true,
  margin: 'normal',
})(FormikTextField)

const UserType = ({ company }) => {
  const props = { select: true, label: 'User Type', name: 'clientType' }

  if (company.type === PERSON_CLIENT_TYPE.TRADER) {
    return (
      <TextField {...props}>
        <MenuItem value="trader">Trader</MenuItem>
      </TextField>
    )
  }

  return (
    <TextField {...props}>
      <MenuItem value="broker">Broker</MenuItem>
    </TextField>
  )
}

UserType.propTypes = {
  company: PropTypes.object.isRequired,
}

const UserForm = ({ company, entities }) => {
  const { spacing } = useTheme()
  const mtSm = { marginTop: spacing(2) }
  const mtLg = { marginTop: spacing(4) }

  return (
    <Form>
      <section style={mtLg}>
        <Text variant="h2">General information</Text>
        <FormRow>
          <TextField name="name" label="First name" />
          <TextField name="surname" label="Surname" />
          <UserType company={company} />
        </FormRow>
        <FormRow>
          <TextField name="jobTitle" label="Job title" />
          <TextField name="email" label="Email address" />
          <TextField name="phone" label="Phone number" />
        </FormRow>
      </section>

      <Grid container spacing={2}>
        {entities.map(({ id, name }) => (
          <Grid key={id} item xs={6}>
            <section style={mtLg}>
              <Text variant="h2">{name}</Text>

              <FormControl fullWidth style={mtSm}>
                <FormLabel>Entity permission</FormLabel>
                <FormControlLabel
                  label="Admin"
                  control={
                    <FormikSwitch
                      data-testid="admin-toggle"
                      value={`${id}.isAdmin`}
                    />
                  }
                />
              </FormControl>

              <FormControl fullWidth style={mtSm}>
                <FormLabel>Order permissions</FormLabel>
                <FormikRadioInput
                  name={`${id}.role`}
                  options={[
                    { value: 'none', label: 'None' },
                    { value: 'read', label: 'Read' },
                    { value: 'write', label: 'Write' },
                    { value: 'trade', label: 'Trade' },
                  ]}
                />
              </FormControl>
            </section>
          </Grid>
        ))}
      </Grid>

      <Grid container justifyContent="flex-end">
        <SubmitButton data-testid="user-form-submit-button" style={mtSm}>
          Submit
        </SubmitButton>
      </Grid>
    </Form>
  )
}

UserForm.propTypes = {
  company: PropTypes.object.isRequired,
  entities: PropTypes.array.isRequired,
}

export default withSnackbar(UserForm)
