import React, { memo } from 'react'
import PropTypes from 'prop-types'
import UpdateIcon from '@material-ui/icons/Update'
import Text from '@material-ui/core/Typography'

import { getTimeAgo, getTimeAgoAbbreviated } from './TimeAgo.helpers'

const iconStyles = {
  width: 10,
  height: 10,
  marginRight: 3,
}

const _TimeAgo = ({ date, style }) => {
  const timeAgo = date ? getTimeAgo(date) : 'Missing date!'
  const timeAgoAbbreviated = date ? getTimeAgoAbbreviated(date) : '?'

  const textStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    ...style,
  }

  if (!date) {
    textStyle.color = 'red' // Highlight that there was an error
  }

  return (
    <Text title={timeAgo} style={textStyle}>
      <UpdateIcon data-testid="last-updated-icon" style={iconStyles} />
      <span data-testid="last-updated" className="time-ago-abbr">
        {timeAgoAbbreviated}
      </span>
    </Text>
  )
}

_TimeAgo.propTypes = {
  date: PropTypes.string,
  style: PropTypes.object,
}

export const TimeAgo = memo(_TimeAgo)
