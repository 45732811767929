const moment = require('moment')
const { BULLETIN_MESSAGE_TYPE_TEXT } = require('../../constants')
const author = require('./author')
const company = require('./company')

const formatDateText = (date) => moment(date).format('Do MMM YYYY ddd HH:mm')

const getBulletin = (bulletin) => ({
  ...bulletin,
  author: bulletin.author || {},
  company: bulletin.company || {},
})

const getBulletinSummary = (bulletin) => {
  const _bulletin = getBulletin(bulletin)

  return {
    id: _bulletin.id || '',
    messageType: _bulletin.messageType || '',
    title: _bulletin.title || '',
    message: _bulletin.message || '',
    authorId: _bulletin.authorId || '',
    createdAt: formatDateText(_bulletin.createdAt || ''),
    updatedAt: formatDateText(_bulletin.updatedAt || ''),
    author: author.text(_bulletin),
    company: company.text(_bulletin),
  }
}

const getBulletinText = (bulletin) => {
  const bulletinSummary = getBulletinSummary(bulletin)

  return {
    ...bulletinSummary,
    messageType: BULLETIN_MESSAGE_TYPE_TEXT[bulletinSummary.messageType],
  }
}

module.exports = {
  get: getBulletin,
  summary: getBulletinSummary,
  text: getBulletinText,
}
