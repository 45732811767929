const getNote = (order) => {
  return order.details
}

const getNoteText = (order) => {
  const note = getNote(order)

  return note || ''
}

module.exports = {
  get: getNote,
  text: getNoteText,
}
