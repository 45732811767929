import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import { AbstractNavbar } from './AbstractNavbar'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'

const AutoFit = styled.div`
  font-size: 0;
`

export const Navbar = ({
  title,
  shouldRenderBackButton,
  disabled,
  onBackButtonClicked,
  onCancel,
}) => (
  <AbstractNavbar
    title={title}
    leftChild={
      shouldRenderBackButton && (
        <AutoFit>
          <ArrowLeft
            color={disabled ? 'disabled' : 'inherit'}
            fontSize="large"
            onClick={() => !disabled && onBackButtonClicked()}
          />
        </AutoFit>
      )
    }
    rightChild={
      <CloseButton onClick={onCancel} style={{ fontSize: 0 }} size="large" />
    }
  />
)

Navbar.defaultProps = {
  title: '',
  shouldRenderBackButton: false,
  onBackButtonClicked: () => {},
  disabled: false,
  onCancel: () => {},
}

Navbar.propTypes = {
  title: PropTypes.string,
  shouldRenderBackButton: PropTypes.bool,
  onBackButtonClicked: PropTypes.func,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
}
