const HISTORY_ACTIONS = Object.freeze({
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  ORDER_VIEW: 'order_view',
  ORDER_SHARE: 'order_share',
  ORDER_TRADE: 'order_trade',
  TERMSHEET_TRADE: 'termsheet_trade',
})

const HISTORY_TABLES = Object.freeze({
  ORDER: 'order',
  TERMSHEET: 'termsheet',
  COMMENT: 'comment',
  NOMINATION: 'nomination',
  CHECKLIST_ITEM: 'checklist_item',
  NOTE: 'note',
  BULLETIN: 'bulletin',
  BROKER_ORDER_ACCESS: 'broker_order_access',
})

module.exports = {
  HISTORY_ACTIONS,
  HISTORY_TABLES,
}
