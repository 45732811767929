import React, { useContext, useEffect, useState } from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import {
  MARKET_GRID_COLUMNS,
  MARKET_GRID_ACTUAL_IMPLIED,
} from 'emsurge-selectors'
import styled from 'styled-components'
import { GridContext } from './GridProvider'
import { usePersonSettings } from 'hooks/usePersonSettings'
import { useEditPersonSettings } from 'api'

const SwitchWrapper = styled.span`
  margin-right: 16px;
`

export const GridFilters = () => {
  const { filters, setFilters } = useContext(GridContext)
  const [checked, setChecked] = useState(false)
  const { data: personSettings, refetch } = usePersonSettings()
  const { mutateAsync: updatePersonSettings } = useEditPersonSettings()

  const onMidCheck = (evt) =>
    setFilters((filters) =>
      evt?.target?.checked
        ? [...filters, MARKET_GRID_COLUMNS.MID]
        : filters.filter((value) => value !== MARKET_GRID_COLUMNS.MID)
    )

  const onSwitchChange = async (evt) => {
    const checked = evt?.target?.checked
    const marketGridPriceType = checked
      ? MARKET_GRID_ACTUAL_IMPLIED.IMPLIED
      : MARKET_GRID_ACTUAL_IMPLIED.ACTUAL
    await updatePersonSettings({ marketGridPriceType })
    await refetch()
    return setFilters((filters) =>
      checked
        ? [...filters, MARKET_GRID_ACTUAL_IMPLIED.IMPLIED]
        : filters.filter(
            (value) => value !== MARKET_GRID_ACTUAL_IMPLIED.IMPLIED
          )
    )
  }

  useEffect(() => {
    const checked =
      personSettings?.marketGridPriceType === MARKET_GRID_ACTUAL_IMPLIED.IMPLIED
    setFilters((filters) =>
      checked
        ? [...filters, MARKET_GRID_ACTUAL_IMPLIED.IMPLIED]
        : filters.filter(
            (value) => value !== MARKET_GRID_ACTUAL_IMPLIED.IMPLIED
          )
    )
  }, [personSettings, setFilters])

  useEffect(() => {
    setChecked(filters.includes(MARKET_GRID_ACTUAL_IMPLIED.IMPLIED))
  }, [filters, setChecked])

  return (
    <>
      <SwitchWrapper>
        <label>Actual</label>
        <Switch
          checked={checked}
          onChange={onSwitchChange}
          color="primary"
          name="checked"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <label>Implied</label>
      </SwitchWrapper>
      <FormControlLabel
        control={
          <Checkbox
            data-testid="grid-filter-show-mid"
            onChange={onMidCheck}
            checked={filters.includes[MARKET_GRID_COLUMNS.MID]}
          />
        }
        label="Show Mid"
      />
    </>
  )
}
