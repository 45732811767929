const TRADING_TYPE = Object.freeze({
  BID: 'bid',
  ASK: 'ask',
})

const TRADING_TYPE_TEXT = Object.freeze({
  [TRADING_TYPE.BID]: 'Bid',
  [TRADING_TYPE.ASK]: 'Ask',
  BID: 'Bid',
  ASK: 'Ask',
})

module.exports = {
  TRADING_TYPE,
  TRADING_TYPE_TEXT,
}
