import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import { ButtonsWrapper } from '../components/ButtonsWrapper'
import { NextButton, BackButton } from '../components/Buttons'
import {
  Name,
  Surname,
  JobTitle,
  Email,
  Phone,
} from '../components/CompanyAdmin/FormFields'
import { getCompanyAdminFormSchema } from '../components/CompanyAdmin/FormSchema'
import FormRow from 'components/formRow/FormRow'

const CompanyAdminForm = ({
  initialValues,
  handleNext,
  handleBack,
  copyCompanyAdmin,
  handleCopyCompanyAdmin,
}) => {
  const handleSubmit = (values, form) => {
    form.setSubmitting(false)
    handleNext({ form: 'companyAdmin', values })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getCompanyAdminFormSchema(initialValues)}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="copy-point-of-contact-checkbox"
                  checked={copyCompanyAdmin}
                  onChange={() => handleCopyCompanyAdmin(!copyCompanyAdmin)}
                  color="primary"
                />
              }
              label="Copy information from above if same as primary point of contact"
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Name />
            </Grid>
            <Grid item xs={5}>
              <Surname />
            </Grid>
            <Grid item xs>
              <JobTitle />
            </Grid>
          </Grid>
          <FormRow>
            <Email />
            <Phone />
          </FormRow>
          <ButtonsWrapper>
            <BackButton
              onClick={() => {
                handleBack({ form: 'companyAdmin', values })
              }}
            />
            <NextButton />
          </ButtonsWrapper>
        </Form>
      )}
    </Formik>
  )
}

CompanyAdminForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  copyCompanyAdmin: PropTypes.bool.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleCopyCompanyAdmin: PropTypes.func.isRequired,
}

export default withRouter(CompanyAdminForm)
