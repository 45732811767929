import { PRICE_TYPE, PRICE_TYPE_TEXT } from 'emsurge-selectors/constants'
import { Hybrid } from './Hybrid'
import { Preference } from 'screens/orderCreate/components/Preference'
import { Floating } from 'screens/orderCreate/components/Floating'
import { Fixed } from 'screens/orderCreate/components/Fixed'
import { Rfq } from 'screens/orderCreateCommon/components/Rfq'
import { Tbd } from 'screens/orderCreateCommon/components/Tbd'

const PRICE_TYPE_INFO = {
  [PRICE_TYPE.FLOATING]: {
    value: PRICE_TYPE.FLOATING,
    label: PRICE_TYPE_TEXT[PRICE_TYPE.FLOATING],
    component: Floating,
  },
  [PRICE_TYPE.FIXED]: {
    value: PRICE_TYPE.FIXED,
    label: PRICE_TYPE_TEXT[PRICE_TYPE.FIXED],
    component: Fixed,
  },
  [PRICE_TYPE.HYBRID]: {
    value: PRICE_TYPE.HYBRID,
    label: PRICE_TYPE_TEXT[PRICE_TYPE.HYBRID],
    component: Hybrid,
  },
  [PRICE_TYPE.RFQ]: {
    value: PRICE_TYPE.RFQ,
    label: PRICE_TYPE_TEXT[PRICE_TYPE.RFQ],
    component: Rfq,
  },
  [PRICE_TYPE.PREFERENCE]: {
    value: PRICE_TYPE.PREFERENCE,
    label: PRICE_TYPE_TEXT[PRICE_TYPE.PREFERENCE],
    component: Preference,
  },
  [PRICE_TYPE.TBD]: {
    value: PRICE_TYPE.TBD,
    label: PRICE_TYPE_TEXT[PRICE_TYPE.TBD],
    component: Tbd,
  },
}

export const PRICE_TYPE_OPTIONS = Object.values(PRICE_TYPE_INFO)
export const PRICE_TYPE_COMPONENTS = Object.values(PRICE_TYPE_INFO).reduce(
  (PRICE_TYPE_COMPONENTS, { value, component }) => {
    PRICE_TYPE_COMPONENTS[value] = component
    return PRICE_TYPE_COMPONENTS
  },
  {}
)
