import React from 'react'
import { get } from 'lodash'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  PRICE_VOLUME_DEPENDENT_PREFIX,
  PRICE_VOLUME_DEPENDENT_TYPE,
  PRICE_VOLUME_DEPENDENT_TYPE_KEYS,
  getPriceVolumeDependentTypePath,
  getPriceVolumeDependentQuantityFromPath,
  getPriceVolumeDependentQuantityToPath,
  getPriceVolumeDependentQuantityPath,
  getPriceVolumeDependentPricePath,
  getPriceVolumeDependentCurrencyPath,
} from 'emsurge-selectors'
import { Dropdown } from './Dropdown'
import { VolumeInput } from './VolumeInput'
import Card from 'screens/orderCreate/components/Card'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'

const useStyles = makeStyles((theme) => ({
  startPlaceholder: {
    color: '#ffffff54',
    paddingRight: '8px',
  },
  endPlaceholder: {
    color: '#ffffff54',
  },
  priceTypeStyle: {
    marginTop: theme.spacing(4),
  },
}))

const QuantityComponent = ({ componentName, label, className }) => {
  return (
    <VolumeInput
      name={componentName}
      label={label}
      classNameAdornment={className}
      unit={'unit'}
    />
  )
}

const _VolumeDependentItem = ({ formik: { values }, itemNr, onClose }) => {
  const classes = useStyles()

  const priceTypeIsFrom = () =>
    get(values, getPriceVolumeDependentTypePath(itemNr)) ===
    PRICE_VOLUME_DEPENDENT_TYPE_KEYS.FROM.key

  return (
    <Card key={`${PRICE_VOLUME_DEPENDENT_PREFIX}${itemNr}`}>
      <CloseButton onClick={() => onClose()} />
      <Grid container spacing={3} direction="row" alignItems="flex-start">
        <Grid
          data-testid="volume-dependent-parameter-type-dropdown"
          item
          xs={3}
        >
          <Dropdown
            path={getPriceVolumeDependentTypePath(itemNr)}
            listOptions={PRICE_VOLUME_DEPENDENT_TYPE}
            className={classes.priceTypeStyle}
          />
        </Grid>
        <Grid item xs={5}>
          {priceTypeIsFrom() ? (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <QuantityComponent
                  componentName={getPriceVolumeDependentQuantityFromPath(
                    itemNr
                  )}
                  label={'Quantity From'}
                  className={classes.endPlaceholder}
                />
              </Grid>
              <Grid item xs={6}>
                <QuantityComponent
                  componentName={getPriceVolumeDependentQuantityToPath(itemNr)}
                  label={'Quantity To'}
                  className={classes.endPlaceholder}
                />
              </Grid>
            </Grid>
          ) : (
            <QuantityComponent
              componentName={getPriceVolumeDependentQuantityPath(itemNr)}
              label={'Quantity'}
              className={classes.endPlaceholder}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <VolumeInput
            name={getPriceVolumeDependentPricePath(itemNr)}
            label={
              !get(values, getPriceVolumeDependentPricePath(itemNr))
                ? ' '
                : 'Price'
            }
            showInputProps={true}
            currencyFieldPath={getPriceVolumeDependentCurrencyPath(itemNr)}
            classNameAdornment={classes.endPlaceholder}
            unit={'currency'}
            dropdownNrCells={3}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

_VolumeDependentItem.propTypes = {
  itemNr: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
}

export const VolumeDependentItem = connect(_VolumeDependentItem)
