import { object, string } from 'yup'

const REQUIRED_TEXT = 'Required'
const INVALID_EMAIL_TEXT = 'Invalid email'

export const getCompanyPrimaryContactFormSchema = ({ email }) =>
  object().shape({
    name: string().required(REQUIRED_TEXT),
    surname: string().required(REQUIRED_TEXT),
    phone: string().required(REQUIRED_TEXT),
    jobTitle: string().required(REQUIRED_TEXT),
    entity: string().required(REQUIRED_TEXT),
    addressFirstLine: string().required(REQUIRED_TEXT),
    addressSecondLine: string(),
    postcode: string().required(REQUIRED_TEXT),
    city: string().required(REQUIRED_TEXT),
    country: string().required(REQUIRED_TEXT),
    email: string()
      .email(INVALID_EMAIL_TEXT)
      .required(REQUIRED_TEXT)
      .emailIsUnique(email),
  })
