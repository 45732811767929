const NOTE_SIMPLE_HEIGHT = 87
const NOTE_TAGS_HEIGHT = 15
const NOTE_COMMENTS_HEIGHT = 15
const NOTE_COMMENTS_LINE_MAXIMUM_CHARS = 58

export const guessNoteActivityCardHeight = (comments, hasTags) => {
  let height = NOTE_SIMPLE_HEIGHT
  if (comments) {
    height +=
      NOTE_COMMENTS_HEIGHT *
      (comments.length < NOTE_COMMENTS_LINE_MAXIMUM_CHARS ? 1 : 2)
  }
  if (hasTags) {
    height += NOTE_TAGS_HEIGHT
  }
  return height
}
