import PropTypes from 'prop-types'
import { connect } from 'formik'
import { isMyCompanyOrder } from 'screens/orderCreateCommon/helpers'
import {
  useOrderFlow,
  ORDER_FLOW,
  useOrderState,
} from 'containers/OrderProvider'

export const ThirdPartyInfo = connect(({ formik, children }) => {
  const { flow } = useOrderFlow()
  const {
    orderState: { hasTermsheets, brokerEditsSharedOrder },
  } = useOrderState()
  const disabled =
    (hasTermsheets && flow === ORDER_FLOW.EDIT) || brokerEditsSharedOrder

  if (isMyCompanyOrder(formik.values)) {
    return null
  }

  const options = [
    {
      required: true,
      disabled,
      name: 'thirdParty.companyName',
      label: 'Company',
    },
    {
      required: true,
      disabled,
      name: 'thirdParty.entityName',
      label: 'Entity',
    },
    {
      required: false,
      disabled,
      name: 'thirdParty.contact',
      label: 'Contact',
    },
  ]

  return children(options)
})

ThirdPartyInfo.defaultProps = {
  children: () => {},
  formik: {},
}

ThirdPartyInfo.propTypes = {
  formik: PropTypes.object,
  children: PropTypes.func,
}
