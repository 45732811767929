import React from 'react'
import { useQueryClient } from 'react-query'
import { Formik, Form } from 'formik'
import { makeStyles } from '@material-ui/styles'
import Text from '@material-ui/core/Typography'
import { useSnackbar } from 'notistack'
import { cloneDeep, sortBy } from 'lodash'
import { Redirect } from 'react-router-dom'
import { EntityPermission } from './EntityPermission'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { DASHBOARD } from 'routes'
import { useTraderPermissions } from 'api'
import { useUser } from 'containers/user/useUser'
import { ACTIVITIES_KEY, ORDER_KEY } from 'api/constants'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
}))

export const TraderPermissions = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()
  const { setUser, user } = useUser()
  const { isLoading, data = [], error } = useTraderPermissions()
  const queryClient = useQueryClient()

  const getEntitiesPermissions = (entities, entityId, permission) => {
    return (entities || []).map((entity) => {
      const entityPermission = cloneDeep(entity)
      if (entityPermission.id === entityId && permission) {
        entityPermission.permission = permission
      }
      return entityPermission
    })
  }

  const invalidateCache = () => {
    queryClient.removeQueries([ACTIVITIES_KEY])
    queryClient.removeQueries([ORDER_KEY])
  }

  const updateUserCache = (newUserData) => setUser(newUserData)

  const updateUserProvider = (data) => {
    const { id, entities = [] } = user
    const { entityId, permission } = data.find(
      ({ personId }) => id === personId
    )
    const entitiesPermissions = getEntitiesPermissions(
      entities,
      entityId,
      permission
    )
    updateUserCache({ ...user, entities: entitiesPermissions })
    invalidateCache()
  }

  const handleSubmit = async (entity, form) => {
    const { persons } = entity
    const data = persons.map((person) => ({
      personId: person.id,
      entityId: entity.id,
      permission: person.permission,
    }))

    try {
      form.setSubmitting(true)
      await api.put('/permissions/trader', data)
      updateUserProvider(data)
      enqueueSnackbar('Permissions updated', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({ error })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      form.setSubmitting(false)
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    const message = getApiErrorMessage({ error })
    enqueueSnackbar(message, { variant: 'error' })
    return <Redirect to={DASHBOARD} />
  }

  const entities = sortBy(
    data.map((entity) => {
      return {
        ...entity,
        persons: sortBy(entity.persons, ['name', 'surname']),
      }
    }),
    'name'
  )

  return (
    <>
      <Text className={classes.title} variant="h2">
        Permissions
      </Text>
      <Text className={classes.subtitle} variant="body1">
        Manage trader permissions for each entity
      </Text>
      {entities.map((entity) => (
        <Formik
          key={entity.id}
          initialValues={entity}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          <Form>
            <EntityPermission entityName={entity.name} />
          </Form>
        </Formik>
      ))}
    </>
  )
}
