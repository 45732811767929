import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray, connect } from 'formik'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from 'containers/formikCheckbox/FormikCheckbox'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'
import AddAlternativeSourceButton from 'screens/orderCreate/components/AddAlternativeSourceButton'
import Card from 'screens/orderCreate/components/Card'
import NominationPort from 'screens/orderCreate/components/NominationPort'
import SelectTimeZone from 'screens/orderCreate/components/SelectTimeZone'
import { NOMINATION_PORT_DEFAULT_VALUES } from 'screens/orderCreate/containers/NominationsInitialValues'
import { DischargePortModel } from 'screens/orderCreate/components/DischargePortModel'

const DischargePortForm = ({ formik: { values, setFieldValue } }) => (
  <DischargePortModel>
    {({ isFOB, isNA, shouldDisableField, setPartyDefaultValue }) => (
      <>
        {isFOB && (
          <FormControl fullWidth>
            <FormControlLabel
              label="N/A"
              control={
                <Checkbox
                  name="nominationRules.dischargePort.na"
                  color="primary"
                  value="nominationRules.dischargePort.na"
                  onChange={() => {
                    setFieldValue(
                      'nominationRules.dischargePort.base.toBeNominated',
                      false
                    )
                  }}
                />
              }
            />
          </FormControl>
        )}
        {!isFOB && (
          <SelectTimeZone
            name="nominationRules.deliveryWindow.timezone"
            label="Timezone (for nominations)"
          />
        )}
        <NominationPort
          mainName="nominationRules.dischargePort.base"
          portNameLabel="Base Port (any safe port in)"
          setPartyDefaultValue={setPartyDefaultValue}
          shouldBeDisabled={shouldDisableField({
            ...values.nominationRules.dischargePort.base,
            isNA,
          })}
        />
        <FieldArray
          name="nominationRules.dischargePort.alternatives"
          render={(arrayHelpers) => (
            <>
              {values.nominationRules.dischargePort.alternatives.map(
                (_, index) => (
                  <Card key={`alternative_${index}`}>
                    <CloseButton onClick={() => arrayHelpers.remove(index)} />
                    <NominationPort
                      mainName={`nominationRules.dischargePort.alternatives[${index}]`}
                      portNameLabel="Permitted Alternative Port"
                      setPartyDefaultValue={setPartyDefaultValue}
                      shouldBeDisabled={shouldDisableField({
                        ...values.nominationRules.dischargePort.alternatives[
                          index
                        ],
                        isNA,
                      })}
                    />
                  </Card>
                )
              )}
              <AddAlternativeSourceButton
                disabled={isNA}
                label="Add Alternative Port"
                onClick={() => {
                  arrayHelpers.push({
                    ...NOMINATION_PORT_DEFAULT_VALUES,
                    name: !isFOB ? values.delivery.location : '',
                  })
                }}
              />
            </>
          )}
        />
      </>
    )}
  </DischargePortModel>
)

DischargePortForm.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const DischargePort = connect(DischargePortForm)
