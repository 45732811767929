import React from 'react'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { calculateNominationDate } from '../helpers'
import FieldCard from 'screens/orderCreateCommon/components/FieldCard'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'

const getNominationDate = (values, mainName) => {
  return calculateNominationDate({
    values,
    mainName,
  })
}

const styles = () => ({
  fontColor: {
    fontSize: '0.75rem',
    fontWeight: 700,
  },
})

const NominationDate = ({ title, mainName, classes }) => (
  <FieldCard title={title}>
    <FormConsumer>
      {({ values }) => (
        <TextField
          aria-readonly={true}
          value={getNominationDate(values, mainName)}
          InputProps={{
            disableUnderline: true,
            className: classes.fontColor,
          }}
        ></TextField>
      )}
    </FormConsumer>
  </FieldCard>
)

NominationDate.propTypes = {
  title: PropTypes.string.isRequired,
  mainName: PropTypes.string.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(NominationDate)
