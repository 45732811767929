import React from 'react'
import PropTypes from 'prop-types'
import { escape } from 'lodash'
import { URL, replaceURL } from './expressions'

export const Interpolate = ({ exp, replacer, children, ...props }) => (
  <span
    {...props}
    dangerouslySetInnerHTML={{
      __html: escape(children).replace(exp, replacer),
    }}
  />
)

Interpolate.defaultProps = {
  exp: URL,
  replacer: replaceURL,
  children: '',
}

Interpolate.propTypes = {
  exp: PropTypes.object,
  replacer: PropTypes.func,
  children: PropTypes.string,
}
