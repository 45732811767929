import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import {
  ACTIVITY_TYPE,
  ACTIVITY_ACTION_TEXT,
  $OC,
  CLASSIFICATIONS_SECOND,
  TRADING_TYPE
} from 'emsurge-selectors'
import OrderType from './OrderType'
import Person from './Person'
import { CardIcons } from './MarketInterest/CardIcons'
import { SmallText, SuperSmallText } from 'components/Text'
import { CarbonMainPrice } from 'components/MainPrice'
import { Row } from 'components/Row'
import { Card, Container } from 'components/Card'
import { getOrderCardOpacity } from 'screens/orderIndex/helpers'
import CompanyCode from 'components/CompanyCode/CompanyCode'
import { StyledLink as Link } from 'components/StyledLink'
import { $O } from 'model/order/selectors/selector'
import { useTruncatedTextObject } from 'hooks'

const _ActivityOrderCardCarbon = ({ activity, style }) => {
  const { type, ...order } = activity

  const opacity = getOrderCardOpacity(order)
  const { spacing } = useTheme()
  const gutterR = { marginRight: spacing(1.5) }

  let actionText

  switch (type) {
    case ACTIVITY_TYPE.ORDER_CLOSED:
      actionText = ACTIVITY_ACTION_TEXT.CLOSED
      break
    case ACTIVITY_TYPE.ORDER_CREATED:
      actionText = ACTIVITY_ACTION_TEXT.CREATED
      break
    case ACTIVITY_TYPE.ORDER_DELETED:
      actionText = ACTIVITY_ACTION_TEXT.DELETED
      break
    case ACTIVITY_TYPE.ORDER_UPDATED:
      actionText = ACTIVITY_ACTION_TEXT.UPDATED
      break
    case ACTIVITY_TYPE.ORDER_SHARED:
      actionText = ACTIVITY_ACTION_TEXT.SHARED
      break
    default:
      actionText = '?'
  }

  const typeText = `${actionText} ${order.template ? 'Template' : 'Order'}`
  const timeAgoDate = activity.timestamp

  let explanationText = `You can see this order because of Rule #`
  switch (activity.visibility_rule_number) {
    case 1:
      explanationText += `1: You are a trader and you have at least read permissions on the entity in which this order was created. The order was created in the entity with ID ${order.entity.id}. The entities in which you have at least read permissions are ${activity.explanation.person_read_entity_ids}.`
      break
    case 2:
      explanationText += `2: This is a 3rd party order created by someone in your company, and you have at least read permission on at least one entity. The author's company ID is ${activity.explanation.rule2.order_person_company_id}. Your company's ID is ${activity.explanation.person_company_id}. The entities of this company in which you have at least read permissions are ${activity.explanation.person_read_entity_ids}.`
      break
    case 3:
      explanationText += `3: This order has been shared with a broker, and you have at least read permission on at least one entity with access to the same broker. The entities with access to the broker in which you have at least read permissions are ${activity.explanation.rule3.person_read_entities_with_broker_access}.`
      break
    case 4:
      explanationText += `4: This order was created and published by a broker, and you have at least read permission on at least one entity with access to the same broker. The broker entity ID is ${
        activity.explanation.rule4.order_broker_entity_id
      }. The entities with access to this broker in which you have at least read permissions are ${JSON.parse(
        activity.explanation.rule4.person_permissions_with_broker_access
      )
        .filter(
          (x) =>
            x.broker_entity_id ===
            activity.explanation.rule4.order_broker_entity_id
        )
        .map(
          (x) =>
            `${x.person_entity_entity_id}: ${x.broker_entity_access_permission}`
        )
        .join(', ')}.`
      break
    case 5:
      explanationText += `5: This order has been shared with a broker entity, and you are a broker with at least read permission on this entity. The order has been shared with broker ${activity.explanation.rule5.order_is_shared_with_broker_entity}. You are a broker with at least read permission on entities ${activity.explanation.person_read_entity_ids}.`
      break
    default:
      explanationText += '?'
  }
  explanationText += ` Activity ID=${activity.activity_id}.`

  const { classifications = {} } = $OC.carbonType.get(order)
  const { text: volumeText } = $OC.volume.text(order)
  const classificationsDetail = CLASSIFICATIONS_SECOND.find(
    (attr) => attr.value === classifications.second
  ) || { label: '' }
  const classificationVolume = [
    volumeText.join('-'),
    classificationsDetail.label,
  ]

  const tradingType = $OC.tradingType.get(order)
  const { keywords, projects } = $OC.projectSpecification.get(order)
  const keywordsText = useTruncatedTextObject(keywords, x => x, 40)
  const projectNamesText = useTruncatedTextObject(projects, x =>  `${x.referenceNr}-${x.name}`, 30)

  return (
    <Link
      to={(location) => `${location.pathname}/orders/${order.id}`}
      style={{ float: 'left', width: '100%' }}
    >
      <Card
        style={{ opacity, ...style }}
        id={`activity-${order.id}`}
        data-cy="activity-order-cards"
        data-testid={`activity-${order.id}`}
      >
        <Grid container>
          <Row direction={'row'} style={{ marginTop: '-5px' }}>
            {$O.author.exists(order) && (
              <Person data-testid="order-card-author" person={order.person} />
            )}
            <SmallText title={explanationText}>{typeText}</SmallText>
          </Row>
          <Row direction={'row-reverse'} style={{ marginTop: '2px' }}>
            { tradingType === TRADING_TYPE.BID
              ? (<SuperSmallText title={keywordsText.completeText}>{keywordsText.truncatedText}</SuperSmallText>)
              : (<SuperSmallText title={projectNamesText.completeText}>{projectNamesText.truncatedText}</SuperSmallText>)
            }
          </Row>
          <Row direction={'row'} style={{ marginTop: '-2px' }}>
            <Container style={{ width: 'auto' }}>
              <CompanyCode
                data-testid="order-card-company-code"
                order={order}
                style={gutterR}
              />
              <OrderType data-testid="order-card-order-type" order={order} />
            </Container>

            <CardIcons
              order={order}
              tradingType={$OC.tradingType.get(order)}
              timestamp={timeAgoDate}
            />
          </Row>

          <Row direction={'row'} style={{ marginTop: '-5px' }}>
            <SmallText>{classificationVolume.join(' ')}</SmallText>
            <CarbonMainPrice
              data-testid="order-card-main-price"
              order={order}
            />
          </Row>
        </Grid>
      </Card>
    </Link>
  )
}

_ActivityOrderCardCarbon.defaultProps = {
  style: {},
}

_ActivityOrderCardCarbon.propTypes = {
  activity: PropTypes.object.isRequired,
  style: PropTypes.object,
}

const ActivityOrderCardCarbon = memo(_ActivityOrderCardCarbon)

export default ActivityOrderCardCarbon
