import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { isEqual } from 'lodash'
import { makeStyles } from '@material-ui/styles'

import { TRADING_TYPE } from 'emsurge-selectors/constants'
import { useTheme } from '@material-ui/core'
import { useUser } from 'containers/user/useUser'
import { getCompanyCodeStyle } from 'components/CompanyCode/CompanyCodeHelpers'
import { MainPrice } from 'components/MainPrice'
import { Square } from 'components/CompanyCode/CompanyCode'
import { useRouter } from 'utils/useRouter'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginTop: spacing(0.25),
    padding: spacing(0.375, 1),
    backgroundColor: palette.background.paper,
    cursor: 'pointer',
    borderRadius: 5,
    borderStyle: 'solid',
    borderColor: (props) => props.textColor,
    borderWidth: (props) =>
      props.type === TRADING_TYPE.BID ? '0 0 0 5px' : '0 5px 0 0',
  },
  price: {
    fontSize: 10,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))

export const OrderChip = React.memo(
  ({ order, type }) => {
    const { user } = useUser()
    const { history, match } = useRouter()
    const { palette } = useTheme()

    const { textColor, symbolColor } = getCompanyCodeStyle({
      order,
      user,
      palette,
    })
    const classes = useStyles({ textColor, type })

    const viewOrder = () => history.push(`${match.url}/orders/${order.id}`)

    return (
      <Grid
        container
        item
        direction={type === TRADING_TYPE.BID ? 'row-reverse' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        className={classes.root}
        onClick={viewOrder}
      >
        <MainPrice order={order} className={classes.price} />
        {symbolColor && (
          <Square color={symbolColor} className="company-code-symbol" />
        )}
      </Grid>
    )
  },
  (prev, next) => prev.type === next.type && isEqual(prev.order, next.order)
)

OrderChip.propTypes = {
  order: PropTypes.object.isRequired,
  type: PropTypes.oneOf(Object.values(TRADING_TYPE)).isRequired,
}
