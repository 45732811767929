import PropTypes from 'prop-types'
import { useFetch } from './useFetch'

const Fetch = ({ url, auth, children }) => children(useFetch(url, { auth }))

Fetch.defaultProps = { auth: true }

Fetch.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  auth: PropTypes.bool,
}

export default Fetch
