import React from 'react'
import MaterialTable from 'material-table'
import PropTypes from 'prop-types'
import { icons } from './Icons'
import TableBody from './TableBody'
import TableFilter from './TableFilter'
import TableRow from './TableRow'

const Table = ({
  columns,
  data,
  groupFunction,
  filtering,
  getLink,
  noDataMessage,
  sorting,
  ...props
}) => (
  <MaterialTable
    columns={columns}
    data={data}
    icons={icons}
    options={{
      groupFunction,
      header: true,
      toolbar: false,
      search: false,
      filtering,
      getLink,
      noDataMessage,
      sorting,
      paging: false
    }}
    components={{
      Body: TableBody,
      FilterRow: TableFilter,
      Row: TableRow,
    }}
    {...props}
  />
)

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  groupFunction: PropTypes.func,
  filtering: PropTypes.bool.isRequired,
  getLink: PropTypes.func,
  noDataMessage: PropTypes.string,
  sorting: PropTypes.bool,
}

Table.defaultProps = {
  data: [],
  filtering: true,
  noDataMessage: 'No data to show',
  sorting: true,
}

export default Table
