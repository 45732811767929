import React from 'react'
import PropTypes from 'prop-types'
import DetailRow from '../../orderView/components/DetailRow'

const ExtraInfo = ({ nomination: { demurage, boilOff, laytime, fsruFsu } }) => (
  <>
    <DetailRow
      title="Demurrage"
      info={`${demurage ? `${demurage} USD` : 'Not Defined'}`}
    />
    <DetailRow
      title="Boil-Off"
      info={`${boilOff ? `${boilOff}%` : 'Not Defined'}`}
    />
    <DetailRow
      title="Allowed Laytime"
      info={`${laytime ? `${laytime} consecutive hours` : 'Not Defined'}`}
    />
    <DetailRow
      title="FSRU/FSU"
      info={`${fsruFsu ? `${fsruFsu} consecutive hours` : 'Not Defined'}`}
    />
  </>
)

ExtraInfo.propTypes = {
  nomination: PropTypes.object.isRequired,
}

export default ExtraInfo
