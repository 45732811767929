import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import OrderViewCarbon from '../orderShareCarbon/OrderView'
import Main from './components/Main'
import ModalHeader from './components/ModalHeader'
import OrderView from './components/OrderView'
import Fetch from 'containers/fetch/Fetch'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import withParams from 'utils/withRouteParams'
import { Panel } from 'screens/orderView/components/Panel'
import { ERROR } from 'routes'

const OrderShare = ({ token }) => {
  const [tabIndex, setTab] = useState(0)

  return (
    <Main>
      <Fetch auth={false} url={`/orders/share/${token}`}>
        {({ loading, error, data: order }) => {
          if (loading) {
            return <Panel component={LoadingSpinner} />
          }

          if (error) {
            return (
              <Redirect
                to={{ pathname: ERROR, state: { error: error.data } }}
              />
            )
          }

          const orderToShow = tabIndex === 0 ? order : order.swapOrder

          return (
            <>
              {order.context === APP_CONTEXT_TYPE.LNG && (
                <>
                  <ModalHeader order={order} activeTab={tabIndex} setTab={setTab} />
                  <Panel order={orderToShow} component={OrderView} />
                </>
              )}
              {order.context === APP_CONTEXT_TYPE.CARBON && (
                <>
                  <ModalHeader order={order} activeTab={tabIndex} setTab={setTab} />
                  <Panel order={orderToShow} component={OrderViewCarbon} />
                </>
              )}
            </>
          )
        }}
      </Fetch>
    </Main>
  )
}

OrderShare.propTypes = {
  token: PropTypes.string.isRequired,
}

export default withParams(OrderShare)
