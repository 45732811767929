import { isString, isEmpty, negate, isObject, get } from 'lodash'

/* :: object -> boolean */
const isOnSystemOrder = (order) => {
  const entityId = get(order, 'entity.id', order.entityId)

  return isString(entityId) && !isEmpty(entityId)
}

/* :: object -> boolean */
const isOffSystemOrder = negate(isOnSystemOrder)

/* :: object -> boolean */
const hasCompany = (order) => {
  if (isOnSystemOrder(order)) {
    return isObject(order.company) && !isEmpty(order.company)
  }

  const company = get(order, 'thirdParty.companyName')
  return isString(company) && !isEmpty(company)
}

/* :: object -> object */
const getCompany = (order) => {
  if (!hasCompany(order)) {
    throw new Error('Order has no company')
  }

  if (isOnSystemOrder(order)) {
    return order.company
  }

  const company = { name: order.thirdParty.companyName }
  return company
}

/* :: object -> boolean */
const hasEntity = (order) => {
  if (isOnSystemOrder(order)) {
    return isObject(order.entity) && !isEmpty(order.entity)
  }

  const entity = get(order, 'thirdParty.entityName')
  return isString(entity) && !isEmpty(entity)
}

/* :: object -> object */
const getEntity = (order) => {
  if (!hasEntity(order)) {
    throw new Error('Order has no entity')
  }

  if (isOnSystemOrder(order)) {
    return order.entity
  }

  const entity = { name: order.thirdParty.entityName }
  return entity
}

/* :: object -> boolean */
const hasCode = (order) => {
  if (isOnSystemOrder(order)) {
    const companyCode = get(order, 'company.code') || ''
    const entityCode = get(order, 'entity.code') || ''
    return (
      hasCompany(order) &&
      companyCode.length === 4 &&
      hasEntity(order) &&
      entityCode.length === 1
    )
  }

  return hasCompany(order) && hasEntity(order)
}

/* :: object -> string */
const getCode = (order) => {
  if (!hasCode(order)) {
    throw new Error('Order has no code')
  }

  if (isOnSystemOrder(order)) {
    const companyCode = order.company.code.toUpperCase()
    const entityCode = order.entity.code.toUpperCase()

    return `${companyCode}${entityCode}`
  }

  const company = order.thirdParty.companyName.toUpperCase()
  const entity = order.thirdParty.entityName.toUpperCase()
  const code = `${company}${entity}`.replace(' ', '').slice(0, 5)

  return code
}

const getLongFormatCode = (order) => {
  if (isOnSystemOrder(order)) {
    return getCode(order)
  }

  const company = order.thirdParty.companyName.toUpperCase()
  const entity = order.thirdParty.entityName.toUpperCase()
  return `${company} ${entity}`
}

export const stakeholder = {
  isOnSystem: isOnSystemOrder,
  isOffSystem: isOffSystemOrder,
  company: {
    exists: hasCompany,
    get: getCompany,
  },
  entity: {
    exists: hasEntity,
    get: getEntity,
  },
  code: {
    exists: hasCode,
    get: getCode,
    getLongFormat: getLongFormatCode,
  },
}
