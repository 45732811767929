const DELIVERY_TYPE = Object.freeze({
  DES: 'DES',
  FOB: 'FOB',
  CIF: 'CIF',
  DAT: 'DAT',
  DAP: 'DAP',
  STS: 'STS',
  INT: 'INT',
})

const DELIVERY_TYPE_TEXT = Object.freeze({
  [DELIVERY_TYPE.DES]: 'DES',
  [DELIVERY_TYPE.FOB]: 'FOB',
  [DELIVERY_TYPE.CIF]: 'CIF',
  [DELIVERY_TYPE.DAT]: 'DAT',
  [DELIVERY_TYPE.DAP]: 'DAP',
  [DELIVERY_TYPE.STS]: 'STS',
  [DELIVERY_TYPE.INT]: 'INT',
})

module.exports = {
  DELIVERY_TYPE,
  DELIVERY_TYPE_TEXT,
}
