const nomination = require('./nomination')

const getOther = (terms) => {
  const { other = {} } = terms
  const otherTerms = other.alternatives || []

  return otherTerms.map((otherTerm) => {
    const { name, description, toBeNominated } = otherTerm
    const otherNomination = nomination.get(otherTerm)

    return {
      name,
      description,
      toBeNominated,
      nomination: otherNomination,
    }
  })
}

const getOtherSummary = (terms) => {
  const { other = {} } = terms
  const otherTerms = other.alternatives || []

  return otherTerms.map((otherTerm) => {
    const { name, description, toBeNominated } = otherTerm
    const otherNominationSummary = nomination.summary(otherTerm)

    return {
      name: name || '',
      description: description || '',
      toBeNominated: toBeNominated.toString(),
      nomination: otherNominationSummary,
    }
  })
}

const getOtherText = (terms) => {
  const { other = {} } = terms
  const otherTerms = other.alternatives || []

  return otherTerms.map((otherTerm) => {
    const { name, description, toBeNominated, timeframe, timeframeValue } =
      otherTerm
    const otherNominationText = nomination.text(otherTerm, { name })

    return {
      name: name || '',
      description: description || '',
      toBeNominated: toBeNominated.toString(),
      nomination: otherNominationText,
      timeframe,
      timeframeValue,
    }
  })
}

module.exports = {
  get: getOther,
  summary: getOtherSummary,
  text: getOtherText,
}
