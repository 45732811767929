import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from '@material-ui/core'
import { getSubMenuPaths } from './helpers'
import { LayoutContext } from 'containers/layout/LayoutProvider'

const isSubMenu = (subMenu, openedSubMenu) =>
  getSubMenuPaths(subMenu).findIndex((item) => item === openedSubMenu) !== -1

const CollapseComponent = ({ children, to, subMenu }) => {
  const { openedSubMenu } = useContext(LayoutContext)
  return (
    <Collapse in={openedSubMenu === to || isSubMenu(subMenu, openedSubMenu)}>
      {children}
    </Collapse>
  )
}

CollapseComponent.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  subMenu: PropTypes.node,
}

export default CollapseComponent
