import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { get } from 'lodash'
import { DELIVERY_VOLUME_MAX_VALUE } from 'emsurge-selectors/constants'
import DeliveryWindowType from './DeliveryWindowType'
import { CargoCard } from './CargoCard'
import { $O } from 'model/order/selectors/selector'
import { getCargoOptions } from 'screens/orderCreate/components/DeliveryWindowCargoes.helpers'

const _MultipleDateRangeField = ({ formik: { values } }) => {
  const { volume, delivery, nominationRules } = values
  const totalCargoes = $O.cargo.total.exists(values)
    ? $O.cargo.total.get(values)
    : 1
  const options = useMemo(
    () => getCargoOptions({ volume, delivery, nominationRules }),
    [delivery, nominationRules, volume]
  )

  if (parseInt(totalCargoes, 10) > DELIVERY_VOLUME_MAX_VALUE) {
    return null
  }

  const otherCargoes = get(nominationRules, 'deliveryWindow.cargos', []).slice(
    1
  )

  return (
    <>
      <CargoCard index={0} options={options} />
      {Boolean(otherCargoes.length) && (
        <>
          <DeliveryWindowType />
          {otherCargoes.map((_, index) => (
            <CargoCard
              index={index + 1}
              key={`delivery-window-cargo-${index + 1}`}
              options={options}
            />
          ))}
        </>
      )}
    </>
  )
}

_MultipleDateRangeField.propTypes = {
  formik: PropTypes.object.isRequired,
}

export default connect(_MultipleDateRangeField)
