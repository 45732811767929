import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { groupBy, merge } from 'lodash'
import { Route, withRouter, Switch } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import Text from '@material-ui/core/Typography'

import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import { SellerCompanyCode, BuyerCompanyCode } from './components/CompanyCode'
import { getTableDataFromOrders as getTableDataFromOrdersCarbon } from './helpersCarbon'
import { getTableDataFromOrders as getTableDataFromOrdersLNG } from './helpers'
import Fetch from 'containers/fetchPlus/FetchPlus'
import { useUser } from 'containers/user/useUser'
import Table from 'components/Table/Table'
import ViewTermsheet from 'screens/termsheetView/ViewTermsheet'
import { TRANSACTION_BOOK } from 'routes'
import ViewOrder from 'screens/orderView/ViewOrder'
import useAppContext from 'containers/appContext/useAppContext'

const isToday = (date) => moment(date).isSame(new Date(), 'day')

const groupRowsByDate = (data) =>
  groupBy(data, ({ createdAt }) =>
    isToday(createdAt) ? 'Today' : moment(createdAt).format('Do MMM YYYY')
  )

const TransactionBook = ({ match, history }) => {
  const { user } = useUser()
  const { background } = useTheme().palette
  const { isLNGContext } = useAppContext()

  const COLUMNS = [
    {
      title: 'Id',
      field: 'shortId',
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Delivery Port',
      field: 'location',
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Period',
      field: 'period',
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      title: 'Price',
      field: 'price',
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Buyer',
      field: 'buyer',
      render: BuyerCompanyCode,
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Seller',
      field: 'seller',
      render: SellerCompanyCode,
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Cargoes',
      field: 'cargoes',
      type: 'numeric',
    },
  ]

  const CABORN_COLUMNS = [
    {
      title: 'Id',
      field: 'shortId',
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Term',
      field: 'termSpot',
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Vintage',
      field: 'vintage',
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Volume',
      field: 'volume',
    },
    {
      title: 'Project ID',
      field: 'projectIds',
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Project',
      field: 'projectNames',
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Price',
      field: 'price',
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Buyer',
      field: 'buyer',
      render: BuyerCompanyCode,
      cellStyle: { textTransform: 'uppercase' },
    },
    {
      title: 'Seller',
      field: 'seller',
      render: SellerCompanyCode,
      cellStyle: { textTransform: 'uppercase' },
    },
  ]

  const contextColumns = isLNGContext ? COLUMNS : CABORN_COLUMNS
  const columns = contextColumns.map((column) =>
    merge(column, {
      defaultFilter: '',
      cellStyle: { backgroundColor: background.paper },
    })
  )
  const getTableDataFromOrders = isLNGContext ? getTableDataFromOrdersLNG : getTableDataFromOrdersCarbon
  const fetchTermsheetUrl = isLNGContext ? '/termsheets?status=traded' : '/termsheets/transactions/carbon'
  return (
    <>
      <Text data-testid="transaction-book-title" variant="h1" gutterBottom>
        Transaction book
      </Text>

      <Fetch url={fetchTermsheetUrl}>
        {(termsheets) => (
          <Table
            columns={columns}
            data={getTableDataFromOrders(termsheets, user)}
            noDataMessage="There are no transactions"
            getLink={(rowData) =>
              `${match.path}/termsheets/${rowData.termsheetId}`
            }
            groupFunction={groupRowsByDate}
          />
        )}
      </Fetch>
      <Switch>
        <Route
          path={`${match.path}/termsheets/:id`}
          component={() => (
            <ViewTermsheet context={isLNGContext ? APP_CONTEXT_TYPE.LNG : APP_CONTEXT_TYPE.CARBON } />
          )}
        />
        <Route
          path={`${match.path}/orders/:id`}
          component={() => (
            <ViewOrder onClose={() => history.push(TRANSACTION_BOOK)} />
          )}
        />
      </Switch>
    </>
  )
}

TransactionBook.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withRouter(TransactionBook)
