import { useQuery } from 'react-query'
import { useApi } from 'containers/api/useApi'
import { ORDER_KEY } from 'api/constants'

export const useOrder = (id) => {
  const api = useApi()

  return useQuery(id && [ORDER_KEY, id], () => api.getOrder(id), {
    enabled: Boolean(id),
    staleTime: 0,
  })
}
