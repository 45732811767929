import moment from 'moment'
import { map, get } from 'lodash'
import { connect } from 'formik'
import { CARBON_PERIOD, CARBON_PERIOD_OPTIONS, CARBON_PERIOD_TERM_OPTIONS } from 'emsurge-selectors'
import { computeVintageYear } from '../helpers'

const _SelectVintagePeriod = ({
  formik: { setFieldValue, values, initialValues },
  children,
  fieldPrefix,
  hasYMinus1
}) => {
  const options = hasYMinus1 ? CARBON_PERIOD_TERM_OPTIONS : CARBON_PERIOD_OPTIONS
  const periodOptions = map(options, (label, value) => ({
    value,
    label,
  }))

  const getValue = (path) =>
    get(values, path) || get(initialValues, path) || moment().format('YYYY-MM')

  const onChange = (value) => {
    const newPeriod = value

    if (newPeriod === CARBON_PERIOD.CUSTOM) {
      setFieldValue(
        `${fieldPrefix}.customFrom`,
        getValue(`${fieldPrefix}.customFrom`)
      )
      setFieldValue(
        `${fieldPrefix}.customTo`,
        getValue(`${fieldPrefix}.customTo`)
      )
    } else {
      setFieldValue(`${fieldPrefix}.year`, computeVintageYear())
    }
  }

  return children({ options: periodOptions, onChange })
}

export const SelectVintagePeriod = connect(_SelectVintagePeriod)
