import React from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import Fab from '@material-ui/core/Fab'
import FileCopy from '@material-ui/icons/FileCopy'
import Tooltip from 'components/tooltip/Tooltip'
import { writeToClipboard, getOrderText } from 'utils/clipboard'
import { getOrderOrTermsheetTitle } from 'screens/orderView/helpers'

export const CopyOrderButton = ({ order, style }) => {
  const { enqueueSnackbar } = useSnackbar()

  const valueText = getOrderOrTermsheetTitle(order)

  const goToCopyOrder = async () => {
    const textToCopy = getOrderText(order)
    const response = await writeToClipboard(
      textToCopy,
      `Copied ${valueText} to clipboard`
    )

    enqueueSnackbar(response.message, {
      variant: response.error ? 'error' : 'success',
    })
  }

  const title = `Copy ${valueText} to clipboard`

  return (
    <Tooltip title={title}>
      <Fab
        data-testid="copy-to-clipboard-button"
        size="small"
        onClick={goToCopyOrder}
        style={style}
      >
        <FileCopy />
      </Fab>
    </Tooltip>
  )
}

CopyOrderButton.propTypes = {
  order: PropTypes.object.isRequired,
  style: PropTypes.object,
}
