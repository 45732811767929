import { $O, $T } from 'emsurge-selectors/models'
import { DELIVERY_TYPE } from 'emsurge-selectors/constants'
import { NEW_LINE, NOT_AVAILABLE, TAB } from './constants'

const getDeliveryWindowCargos = (order) => {
  const cargosText = $T.deliveryWindow.cargos.text(order.nominationRules)
  const deliveryWindowCargos = cargosText.map(
    (cargo, index) => `Cargo ${index + 1}: ${cargo}`
  )
  const deliveryWindowCargosText = `${
    NEW_LINE + TAB
  }${deliveryWindowCargos.join(NEW_LINE + TAB)}`

  return `Delivery Windows:${deliveryWindowCargosText || NOT_AVAILABLE}`
}

const getDeliverySchedule = (order) => {
  const arrivalText = $T.deliveryWindow.arrival.text(order.nominationRules)
  const alternatives = $T.deliveryWindow.alternatives.text(
    order.nominationRules
  )
  const narrowingMechanisms = alternatives.map(
    (alternative, index) =>
      `${TAB}Narrowing Mechanism ${index + 1}: ${alternative.nomination}`
  )

  return [
    `Delivery Schedule: ${arrivalText || NOT_AVAILABLE}`,
    ...narrowingMechanisms,
  ]
    .join(NEW_LINE)
    .trim()
}

const getPortHeaders = (order) => {
  const deliveryType = $O.delivery.type.get(order)

  if (deliveryType === DELIVERY_TYPE.FOB) {
    return {
      loadPort: 'Delivery Port (Load Port)',
      deliveryPort: 'Discharge Port',
    }
  }

  return {
    loadPort: 'Load Port',
    deliveryPort: 'Delivery Port (Discharge Port)',
  }
}

const getPort = (portText, { portTitle }) => {
  const { alternatives, ...port } = portText
  const allPorts = [port, ...alternatives]

  const text = allPorts.map((port, index) => {
    const title = index ? `Alternative Port ${index + 1}` : `${portTitle}`
    const text = [`${title}: ${port.name || NOT_AVAILABLE}`]

    if (port.nomination) {
      text.push(`${TAB}${port.nomination}`)
    }

    if (port.adgas) {
      text.push(`${TAB}${portText.adgas || ''}`)
    }

    return text.join(NEW_LINE).trim()
  })

  return text.join(NEW_LINE).trim()
}

const getLoadPort = (order) => {
  const portText = $T.loadPort.text(order.nominationRules)
  const { loadPort } = getPortHeaders(order)

  return getPort(portText, { portTitle: loadPort })
}

const getDeliveryPort = (order) => {
  const portText = $T.deliveryPort.text(order.nominationRules)
  const { deliveryPort } = getPortHeaders(order)

  return getPort(portText, { portTitle: deliveryPort })
}

const getVessel = (order) => {
  const vesselText = $T.vessel.text(order.nominationRules)
  const vesselAlternativesText = $T.vessel.alternatives.text(
    order.nominationRules
  )
  const allVessels = [vesselText, ...vesselAlternativesText]
  const text = allVessels.map((vessel, index) => {
    const title = index ? `Alternative Vessel ${index + 1}` : 'LNG Ship'
    const text = [`${title}: ${vessel.name || NOT_AVAILABLE}`]

    if (vessel.size) {
      text.push(`${TAB}${vessel.size}`)
    }

    if (vessel.nomination) {
      text.push(`${TAB}${vessel.nomination}`)
    }

    return text.join(NEW_LINE).trim()
  })

  return text.join(NEW_LINE).trim()
}

const getDemurrageRate = (order) => {
  const rateText = $T.demurrage.rate.text(order.nominationRules)

  return `Demurrage Rate: ${rateText || NOT_AVAILABLE}`
}

const getDemurrageNotes = (order) => {
  const notesText = $T.demurrage.notes.text(order.nominationRules)

  return `Demurrage Notes: ${notesText || NOT_AVAILABLE}`
}

const getBoilOff = (order) => {
  const boilOffRateText = $T.demurrage.boilOff.text(order.nominationRules)

  return `Deemed Boil-Off Rate: ${boilOffRateText || NOT_AVAILABLE}`
}

const getLaytime = (order) => {
  const laytime = $T.laytime.text(order.nominationRules)

  return `Allowed Laytime: ${laytime || NOT_AVAILABLE}`
}

const getFsruFsu = (order) => {
  const fsru_fsu = $T.fsru_fsu.text(order.nominationRules)

  return `${fsru_fsu || `FSRU/FSU: ${NOT_AVAILABLE}`}`
}

const getOtherTerms = (order) => {
  const otherTermsText = $T.other.text(order.nominationRules)
  const text = otherTermsText.map((otherTerm) => {
    const text = [
      `${otherTerm.name}: ${otherTerm.description || NOT_AVAILABLE}`,
    ]

    if (otherTerm.nomination) {
      text.push(`${TAB}${otherTerm.nomination}`)
    }

    return text.join(NEW_LINE).trim()
  }, [])

  return text.join(NEW_LINE).trim()
}

export const getOrderTermsText = (order) => {
  const text = [
    getDeliveryWindowCargos(order),
    getDeliverySchedule(order),
    getLoadPort(order),
    getDeliveryPort(order),
    getVessel(order),
    getDemurrageRate(order),
    getDemurrageNotes(order),
    getBoilOff(order),
    getLaytime(order),
    getFsruFsu(order),
    getOtherTerms(order),
  ].join(NEW_LINE)

  return text
}
