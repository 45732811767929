const PRIMARY_POINT_CONTACT = {
  name: '',
  surname: '',
  jobTitle: '',
  entity: '',
  email: '',
  phone: '',
  addressFirstLine: '',
  addressSecondLine: '',
  postcode: '',
  city: '',
  country: '',
}

export { PRIMARY_POINT_CONTACT }
