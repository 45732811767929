import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { StylesProvider } from '@material-ui/core/styles'
import MuiThemeProvider from '@material-ui/styles/ThemeProvider'
import { ThemeProvider } from 'styled-components'
import { SnackbarProvider } from 'notistack'
import * as theme from 'theme'
import FetchProvider from 'containers/fetch/FetchProvider'
import LayoutProvider from 'containers/layout/LayoutProvider'
import ApiProvider from 'containers/api/ApiProvider'
import UserProvider from 'containers/user/UserProvider'
import { FeatureFlagsProvider } from 'containers/featureFlags/'
import AppContextProvider from 'containers/appContext/AppContextProvider'
import { QueryClientInspector } from 'components/QueryClientInspector/QueryClientInspector'
import TelemetryProvider from 'containers/telemetry/TelemetryProvider'

const queryClient = new QueryClient()

const AppProvider = ({ children, theme, appInsightsConnectionString }) => {
  const snackbarAnchor = { vertical: 'bottom', horizontal: 'left' }
  return (
    <BrowserRouter>
     <TelemetryProvider appInsightsConnectionString={appInsightsConnectionString}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              <SnackbarProvider anchorOrigin={snackbarAnchor}>
                <QueryClientProvider client={queryClient}>
                  <AppContextProvider>
                    <UserProvider>
                      <FeatureFlagsProvider>
                        <ApiProvider>
                          <FetchProvider>
                            <LayoutProvider>{children}</LayoutProvider>
                            <QueryClientInspector />
                          </FetchProvider>
                        </ApiProvider>
                      </FeatureFlagsProvider>
                    </UserProvider>
                  </AppContextProvider>
                </QueryClientProvider>
              </SnackbarProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </StylesProvider>
      </TelemetryProvider>
    </BrowserRouter>
  )
}

AppProvider.defaultProps = {
  theme: theme.dark,
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
  appInsightsConnectionString: PropTypes.string
}

export default AppProvider
