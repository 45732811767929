import React from 'react'
import PropTypes from 'prop-types'
import { useRouteMatch, useHistory } from 'react-router'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import ActivityNoteCard from './ActivityNoteCard'
import ActivityOrderCard from './ActivityOrderCard'
import ActivityOrderCardCarbon from './ActivityOrderCardCarbon'
import ActivityCommentCard from './ActivityCommentCard'
import { ActivityTermsheetCard } from './ActivityTermsheetCard'
import { ActivityBulletinCard } from './ActivityBulletinCard'
import {
  isOrderType,
  isCommentType,
  isNoteType,
  isTermsheetType,
  isBulletinType,
} from 'constants/activityType'
import useAppContext from 'containers/appContext/useAppContext'

const getContext = (activity, defaultContext) => {
  return activity.context || activity.order?.context || defaultContext
}

export const ActivityCard = (props) => {
  const history = useHistory()
  const match = useRouteMatch()
  const { appContext } = useAppContext()
  const { activity } = props
  const activityType = activity.type
  const isCarbonContext =
    getContext(activity, appContext) === APP_CONTEXT_TYPE.CARBON

  if (isCommentType(activityType)) {
    return <ActivityCommentCard {...props} />
  }

  if (isOrderType(activityType)) {
    const ActivityOrderCardComponent = isCarbonContext
      ? ActivityOrderCardCarbon
      : ActivityOrderCard
    return <ActivityOrderCardComponent {...props} />
  }

  if (isNoteType(activityType)) {
    return <ActivityNoteCard {...props} />
  }

  if (isTermsheetType(activityType)) {
    return <ActivityTermsheetCard {...props} />
  }

  if (isBulletinType(activityType)) {
    return (
      <ActivityBulletinCard
        onClick={() =>
          history.push(`${match.path}/bulletins/${props.activity.id}`)
        }
        {...props}
      />
    )
  }

  return null
}

ActivityCard.defaultProps = {
  style: {},
}

ActivityCard.propTypes = {
  activity: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
}
