import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { getViewCompanyLink } from '../helpers'
import TableRowLink from 'components/tableRowLink/TableRowLink'

const Onboarded = ({ companies, theme }) => (
  <section
    style={{
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    }}
  >
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ textAlign: 'left', width: '50%' }}>
              Company
            </TableCell>
            <TableCell
              style={{ textAlign: 'left', width: '25%' }}
              align="right"
            >
              Entities
            </TableCell>
            <TableCell
              style={{ textAlign: 'left', width: '25%' }}
              align="right"
            >
              Users
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map((company) => (
            <TableRowLink key={company.id} to={getViewCompanyLink(company)}>
              <TableCell
                style={{ fontWeight: 'bold', textAlign: 'left', width: '50%' }}
              >
                {company.name}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left',
                  width: '25%',
                }}
                align="right"
              >
                {company.entities.length}
              </TableCell>
              <TableCell
                style={{ textAlign: 'left', width: '25%' }}
                align="right"
              >
                {company.persons.length}
              </TableCell>
            </TableRowLink>
          ))}
        </TableBody>
      </Table>
    </Paper>
  </section>
)
Onboarded.propTypes = {
  companies: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
}
export default withTheme(Onboarded)
