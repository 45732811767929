import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MuiText from '@material-ui/core/Typography'

import { Row } from 'components/Row'

const TextGrey = styled(MuiText)`
  color: #9097a1;
  white-space: nowrap;
  font-size: 15px;
`

const PaddedSpan = styled.span`
  padding-left: ${({ theme }) => theme.spacing(1)}px;
  text-align: right;
`

export const RowWithValues = ({ classes, label, value, ...textProps }) => (
  <Row classes={classes}>
    <TextGrey>{label}</TextGrey>
    <PaddedSpan
      {...textProps}
      data-testid={textProps?.['data-testid'] && textProps['data-testid']}
    >
      {value}
    </PaddedSpan>
  </Row>
)

RowWithValues.defaultProps = {
  classes: {},
  label: '',
  value: <span />,
}

RowWithValues.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.node,
}
