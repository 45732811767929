import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { constant } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import ModalHeader from './ModalHeader'
import ModalContent from './ModalContent'
import FormProvider from './FormProvider'
import Modal from 'components/modal/Modal'
import { useModal } from 'utils/useModal'
import {
  OrderProvider,
  ORDER_FLOW,
  STEP_CARBON,
  TAB_CARBON,
  useActiveStep,
  useOrderFlow,
} from 'containers/OrderProvider'

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
  },
})

const CreateOrder = ({ children }) => {
  const { isOpen, open, close } = useModal()
  const classes = useStyles()
  const {
    navigate: { goTo },
  } = useActiveStep()
  const { setFlow } = useOrderFlow()

  useEffect(() => setFlow(ORDER_FLOW.CREATE), [setFlow])

  const onClose = () => {
    goTo({
      tab: TAB_CARBON.ESSENTIAL,
      step: STEP_CARBON[TAB_CARBON.ESSENTIAL].GENERAL,
    })
    close()
  }

  return (
    <>
      <Modal open={isOpen} paperProps={{ classes }}>
        <FormProvider postSubmit={onClose}>
          <>
            <ModalHeader onCancel={onClose} />
            <ModalContent />
          </>
        </FormProvider>
      </Modal>

      {children({ open })}
    </>
  )
}

CreateOrder.defaultProps = {
  children: constant(null),
}

CreateOrder.propTypes = {
  children: PropTypes.func,
}

const _CreateOrder = (props) => (
  <OrderProvider>
    <CreateOrder {...props} />
  </OrderProvider>
)

export default _CreateOrder
