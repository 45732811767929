import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography as Text } from '@material-ui/core'
import {
  $O,
  PRICE_TYPE,
  PRICE_UNIT,
  DELIVERY_PERIOD,
  DELIVERY_PERIOD_TEXT,
} from 'emsurge-selectors'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { isEmpty, isNaN } from 'lodash'
import { CargoChart } from './components/CargoChart'
import { useFetchOrders, useCalculateFixedPrice } from 'hooks'
import { STATUS, STATUS_VALUES } from 'components/StatusFilters'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import ViewTermsheet from 'screens/termsheetView/ViewTermsheet'
import ViewOrder from 'screens/orderView/ViewOrder'
import { PRICE_CHARTS_FIXED } from 'routes'
import { PERIOD } from 'model/order/constants/delivery'

const FILTERS = {
  status: STATUS_VALUES[STATUS.ACTIVE],
  'delivery.period': Object.values(PERIOD),
  template: false,
}

const getPeriodsToDiscard = () => {
  const PERIODS = [
    DELIVERY_PERIOD.CUSTOM,
    DELIVERY_PERIOD.GAS_YEAR,
    DELIVERY_PERIOD.M,
    DELIVERY_PERIOD.M1,
  ]
  return PERIODS.map((period) => DELIVERY_PERIOD_TEXT[period])
}

const PriceChartsFixed = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const [filteredOrders, setFilteredOrders] = useState([])
  const [ordersToPlot, setOrdersToPlot] = useState([])
  const { loading, orders } = useFetchOrders(FILTERS)
  const [calculatedOrders, loadingCalculate] =
    useCalculateFixedPrice(filteredOrders)

  useEffect(() => {
    const filtered =
      orders?.filter((order) => {
        const price = $O.price.get(order)
        const delivery = $O.delivery.text(order)
        const priceType = $O.price.type(order)
        const periodsToDiscard = getPeriodsToDiscard()
        const period = price?.delivery || delivery?.period
        return (
          (PRICE_TYPE.FIXED === priceType ||
            (PRICE_TYPE.FLOATING === priceType &&
              period &&
              !periodsToDiscard.includes(period))) &&
          price?.unit === PRICE_UNIT.MMBTU
        )
      }) || []
    setFilteredOrders(filtered)
  }, [orders])

  useEffect(() => {
    const handledOrders = []
    calculatedOrders.forEach((order) => {
      const { indexAmount, value } = $O.price.get(order)
      const priceType = $O.price.type(order)
      if (priceType === PRICE_TYPE.FIXED && !isNaN(parseFloat(value))) {
        handledOrders.push(order)
      }

      if (priceType === PRICE_TYPE.FLOATING) {
        // If indexAmount is 0, it means the index wasn't calculated
        if (indexAmount !== 0) {
          handledOrders.push(order)
        }
      }
    })
    setOrdersToPlot(handledOrders)
  }, [calculatedOrders])

  if (loading || loadingCalculate) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing={2}
        style={{ flexGrow: 1 }}
      >
        <Grid item>
          <Text variant="h1">Fixed Visualisation</Text>
        </Grid>
        {isEmpty(ordersToPlot) ? (
          <Box display="flex" justifyContent="center" py={8}>
            <Text variant="h6">No orders</Text>
          </Box>
        ) : (
          <CargoChart orders={ordersToPlot} chartType={'PRICE'} />
        )}
      </Grid>
      <Route
        path={`${match.path}/orders/:id`}
        component={() => (
          <ViewOrder onClose={() => history.push(PRICE_CHARTS_FIXED)} />
        )}
      />
      <Route path={`${match.path}/termsheets/:id`} component={ViewTermsheet} />
    </>
  )
}

export default PriceChartsFixed
