import {
  periodIsCustom,
  PROJECT_INFORMATION_KEYS,
  TERM_SPOT_KEYS,
  TERM_FIXED_ROLLING_KEYS,
  VOLUME_TYPE_KEYS,
  priceTypeIsFixed,
  priceTypeIsRange,
  priceTypeIsVolumeDependent,
  priceTypeIsRFQ,
  priceTypeIsTBD,
  PROJECT_PATH,
  PROJECT_KEYWORD_OPTIONS,
  PROJECT_SECTORAL_SCOPE_OPTIONS,
  TRADING_TYPE,
  PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE_PATHS,
  VOLUME_OFFSET,
  VENUES,
  REGIONS_KEYS,
  periodIsYMinus1,
  DELIVERED_BY_KEY,
} from 'emsurge-selectors'
import { get, set } from 'lodash'
import { computeVolumeInputNumber } from './helpers'

export const getPeriodData = ({ period, year, customFrom, customTo }) => {
  if (periodIsCustom(period)) {
    return { period, customFrom, customTo }
  }
  if (periodIsYMinus1(period)){
    return { period }
  }
  return { period, year }
}

const getTypeCheckboxOptions = (selectedOptions) => {
  return !selectedOptions
    ? {}
    : Object.keys(selectedOptions).filter((opt) => selectedOptions[opt])
}

const getOtherContent = (option) => (option && option.otherContent) || ''

export const getTypeData = (values, orderInfo) => {
  if (values.carbonType) {
    const {
      programEligibility,
      carbonStandard,
      titleTransfers,
      classifications,
      rating,
    } = values.carbonType
    orderInfo.carbonType = {
      programEligibility: getTypeCheckboxOptions(programEligibility),
      carbonStandard: getTypeCheckboxOptions(carbonStandard),
      rating: getTypeCheckboxOptions(rating),
      titleTransfers,
      classifications,
      otherCarbonStandard: getOtherContent(carbonStandard),
      otherProgramEligibility: getOtherContent(programEligibility),
      otherRating: getOtherContent(rating),
    }
  }
}

const getDeliveredByData = (deliveredBy) => {
  const {type = null, fixedDate = null, daysAfter = null} = deliveredBy
  if (type === DELIVERED_BY_KEY.SPOT) {
    return { type }
  } 
  if (type === DELIVERED_BY_KEY.FIXED) {
    return { type, fixedDate}
  } 
  if (type === DELIVERED_BY_KEY.DAYS_AFTER){
    return { type, daysAfter }
  }
  return null
}

export const getTermVintageData = (values, orderInfo) => {
  const getProjectInformationKeys = (
    projectInformation,
    onlyActive = false
  ) => {
    const keys = onlyActive
      ? [PROJECT_INFORMATION_KEYS.ACTIVE]
      : Object.values(PROJECT_INFORMATION_KEYS)
    return Object.keys(projectInformation).filter(
      (key) => keys.includes(key) && projectInformation[key]
    )
  }

  if (values.termVintage) {
    const {
      termSpot,
      fixedRolling,
      period,
      offset,
      vintageFixed,
      vintageRolling,
      projectInformation,
      investmentDescription,
      deliveredBy
    } = values.termVintage
    orderInfo.termVintage = {
      termSpot,
      spot: {},
      term: {},
      investment: {},
      projectInformation: getProjectInformationKeys(projectInformation),
    }
    if (termSpot === TERM_SPOT_KEYS.SPOT) {
      orderInfo.termVintage = {
        ...orderInfo.termVintage,
        spot: getPeriodData(period),
        projectInformation: getProjectInformationKeys(projectInformation, true),
        deliveredBy: deliveredBy && getDeliveredByData(deliveredBy)
      }
    } else if (termSpot === TERM_SPOT_KEYS.TERM) {
      orderInfo.termVintage = {
        ...orderInfo.termVintage,
        fixedRolling,
        term: {
          offset: getPeriodData(offset),
          vintage:
            fixedRolling === TERM_FIXED_ROLLING_KEYS.FIXED
              ? getPeriodData(vintageFixed)
              : vintageRolling,
        },
      }
    } else {
      orderInfo.termVintage = {
        ...orderInfo.termVintage,
        investment: {
          investmentDescription,
        },
      }
    }
  }
}

export const getVolumeData = (values, orderInfo) => {
  if (values.volume) {
    const { amount, offset = {}, range, type, minParcelSize } = values.volume
    const offsetOptions = offset[VOLUME_OFFSET.ALL_OR_NOTHING] ? [ VOLUME_OFFSET.ALL_OR_NOTHING ] : []
    orderInfo.volume = {
      amount: computeVolumeInputNumber(amount),
      type,
      offset: offsetOptions,
    }
    if (!offsetOptions.length) {
      orderInfo.volume.minParcelSize = computeVolumeInputNumber(minParcelSize)
    }
    if (type === VOLUME_TYPE_KEYS.PER_ANNUM) {
      const { first, last } = range || {}
      orderInfo.volume.range = {
        first: computeVolumeInputNumber(first),
        last: computeVolumeInputNumber(last),
      }
    }
  }
}

export const getPriceData = (values, orderInfo) => {
  if (values.price) {
    const { type, basic } = values.price || {}
    let priceData = {}
    if (priceTypeIsFixed(type)) {
      const amount = computeVolumeInputNumber(basic.fixed)
      priceData = {
        amount,
        fixedCurrency: basic.fixedCurrency,
      }
    }
    if (priceTypeIsRange(type)) {
      const { min, max, currency } = basic.range || {}
      priceData = {
        min: computeVolumeInputNumber(min),
        max: computeVolumeInputNumber(max),
        currency,
      }
    }
    if (priceTypeIsVolumeDependent(type)) {
      priceData = basic.volumeDependent
    }
    if (priceTypeIsRFQ(type)) {
      priceData = basic.rfq
    }
    if (priceTypeIsTBD(type)) {
      priceData = { amount: 'TBD' }
    }
    orderInfo.price = {
      basic: priceData,
      type,
    }
  }
}

const getProjectSpecificationCommonData = ({
  keywords = [],
  sectoralScopes = [],
  projects = [],
  selectedScopes = {},
}) => {
  return { keywords, sectoralScopes, projects, selectedScopes }
}

const getProjectSpecificationBuyerData = (values) => {
  const keywords = get(values, PROJECT_KEYWORD_OPTIONS) || []
  const sectoralScopes = get(values, PROJECT_SECTORAL_SCOPE_OPTIONS) || []
  const selectedScopes = {}
  Object.keys(PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE_PATHS).forEach(
    (key) => {
      const path = PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE_PATHS[key]
      set(selectedScopes, key, get(values, path))
    }
  )
  return getProjectSpecificationCommonData({
    keywords: keywords.map(({ key }) => key),
    sectoralScopes: sectoralScopes.map(({ key }) => key),
    selectedScopes,
  })
}

const getProjectSpecificationSellerData = (values) => {
  return getProjectSpecificationCommonData({
    projects: get(values, PROJECT_PATH),
  })
}

export const getProjectSpecificationData = (values, orderInfo) => {
  const tradingType = get(values, 'tradingType')
  const fn =
    tradingType === TRADING_TYPE.ASK
      ? getProjectSpecificationSellerData
      : getProjectSpecificationBuyerData
  if (values.projectSpecification) {
    orderInfo.projectSpecification = fn(values)
  }
}

const getOptionsKeys = (options) =>
  options ? Object.keys(options).filter((opt) => options[opt]) : []

export const getAdditionalAttributesData = (values, orderInfo) => {
  if (values.additionalAttributes) {
    const { options, sdgs = [], type } = values.additionalAttributes
    const optionsKeys = getOptionsKeys(options)
    orderInfo.additionalAttributes = {
      options: optionsKeys.filter((key) => key !== 'otherContent'),
      sdgs: sdgs.map(({ key }) => key),
      otherAdditionalAttributes: (options && options.otherContent) || '',
      type,
    }
  }
}
const venues = Object.keys(VENUES)

export const getVenueData = (values, orderInfo) => {
  if (values.venue) {
    const { options } = values.venue
    const optionsKeys = getOptionsKeys(options).filter(x => venues.includes(x))
    orderInfo.venue = {
      options: optionsKeys,
    }
  }
}

export const getRegionData = (values, orderInfo) => {
  if (values.regions) {
    const { countries, ldcsOnly, options, type } = values.regions
    const optionsKeys = !options ? [] : getOptionsKeys(options).filter(x => Object.keys(REGIONS_KEYS).includes(x))
    orderInfo.regions = {
      countries: Object.values(countries || []).map(({ key }) => key),
      ldcsOnly: ldcsOnly || false,
      options: optionsKeys,
      type,
    }
  }
}

export const getAlternativesData = (values, orderInfo) => {
  if (values.alternatives) {
    orderInfo.alternatives = {
      otherTerms: values.alternatives,
    }
  }
}
