import { useQuery } from 'react-query'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import { useApi } from 'containers/api/useApi'
import { ORDER_KEY, TERMSHEET_EDIT } from 'api/constants'

export const useTermsheet = (id, context) => {
  const api = useApi()

  const apiMethod = (context == APP_CONTEXT_TYPE.LNG || !context) ? api.getTermsheet : api.getTermsheetCarbon
  return useQuery(id && [ORDER_KEY, id], () => apiMethod(id), {
    staleTime: 0,
  })
}

export const useTermsheetEdit = (id) => {
  const api = useApi()

  const apiMethod = api.getTermsheet
  return useQuery(id && [TERMSHEET_EDIT, id], () => apiMethod(id), {
    staleTime: 0,
  })
}

