const { ADDITIONAL_ATTRIBUTES, SDGS_OPTIONS } = require('../../constants')

const getAdditionalAttributes = (order) => order.additionalAttributes || {}

const getAdditionalAttributesText = (order) => {
  const {
    options = [],
    sdgs = [],
    otherAdditionalAttributes,
    type,
  } = getAdditionalAttributes(order)
  return {
    additionalAttributes: options
      .map((opt) => {
        if (opt === 'Other') {
          return otherAdditionalAttributes
        }
        if (opt === ADDITIONAL_ATTRIBUTES.Other) {
          return ''
        }
        return ADDITIONAL_ATTRIBUTES[opt]
      })
      .filter((val) => val)
      .join(', '),
    sdgs: sdgs.map((sdg) => SDGS_OPTIONS[sdg]).join(', '),
    type,
  }
}

module.exports = {
  get: getAdditionalAttributes,
  text: getAdditionalAttributesText,
}
