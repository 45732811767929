import PropTypes from 'prop-types'
import { connect } from 'formik'

import { CARGO_SIZE_UNIT_OPTIONS } from 'emsurge-selectors/constants'
import { getCargoSizeType } from 'screens/orderCreate/helpers'

const _SelectCargoSizeUnit = ({ formik, children }) => {
  const onChange = (value) => {
    formik.setFieldValue('cargoSize.type', getCargoSizeType(value))
    formik.setFieldValue('cargoSize.sizeMin', null)
    formik.setFieldValue('cargoSize.sizeMax', null)
  }

  return children({ options: CARGO_SIZE_UNIT_OPTIONS, onChange })
}

_SelectCargoSizeUnit.propTypes = {
  formik: PropTypes.object.isRequired,
  children: PropTypes.func,
}

export const SelectCargoSizeUnit = connect(_SelectCargoSizeUnit)
