import React from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { omit, merge} from 'lodash'
import { getSchema } from 'screens/orderCreateCarbon/FormSchema'
import { getInitialValues as getInitialValuesCreateTrade } from 'screens/orderCreateCounterCarbon/FormProvider'
import { useApi } from 'containers/api/useApi'
import { useUser } from 'containers/user/useUser'
import { validateOrder } from 'screens/orderCreateCarbon/containers/validation'
import { getApiErrorMessage } from 'containers/api/helpers'
import { useRouter } from 'utils/useRouter'
import { ORDER_KEY, TERMSHEET_EDIT} from 'api/constants'
import { getOrderInfo } from 'screens/orderCreateCarbon/FormProvider'
import { getStep1, getApiDataUpdateOnlyTermsheet } from 'screens/termsheetEdit/containers/FormProvider'

const getEditTradeInitialValues = (termsheet, user) => {
  const initialValues = merge(
    {},
    getInitialValuesCreateTrade(termsheet, user),
    getStep1(termsheet)
  )

  return initialValues
}

const getApiData = (termsheet, values, user) => {
  const result = {
    id: termsheet.id,
    ...getData(values, user),
  }

  result.brokerId = termsheet.brokerId
  result.companyId = termsheet.companyId
  result.entityId = termsheet.entityId
  result.personId = termsheet.personId
  result.tradingType =  termsheet.tradingType

  return result
}

const getData = (values, user) =>
  omit(getOrderInfo(values, user), ['comments', 'broker', 'brokerAccess'])

const FormProvider = ({ termsheet, children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { history } = useRouter()
  const api = useApi()
  const { user } = useUser()
  const queryClient = useQueryClient()

  const handleSubmit = async (values, actions) => {
    const data = getApiData(termsheet, values, user)
    const dataUpdateOnlyTermSheet = getApiDataUpdateOnlyTermsheet(termsheet, values)
    const isTermsheetTableUpdateRequired = !!(dataUpdateOnlyTermSheet.bid || dataUpdateOnlyTermSheet.ask)
    delete data.orders

    try {
      await api.put(`/orders/${termsheet.id}`, data)
      if(isTermsheetTableUpdateRequired)
      {
        await api.patch(`/termsheets/${termsheet.id}/tradedetails`, dataUpdateOnlyTermSheet)
      }
      actions.resetForm()
      enqueueSnackbar('Term sheet updated successfully', {
        variant: 'success',
      })
      queryClient.invalidateQueries([ORDER_KEY, termsheet.id]) // TODO: replace with mutation
      queryClient.invalidateQueries([TERMSHEET_EDIT, termsheet.id]) // TODO: replace with mutation
      history.goBack()
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Term sheet update failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik 
      onSubmit={handleSubmit} 
      initialValues={getEditTradeInitialValues(termsheet, user)} 
      validationSchema={getSchema(user, true)} 
      validate={validateOrder}
      validateOnMount={false}>
      {children}
    </Formik>
  )
}

FormProvider.defaultProps = {
  postSubmit: () => {},
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  termsheet: PropTypes.object.isRequired,
}

export default FormProvider
