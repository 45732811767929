import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import { getInformationInitialValues } from './getInformationInitialValues'
import { validateOrder } from 'screens/orderCreate/containers/validateOrder'
import { getSchema } from 'screens/orderCreate/containers/FormSchema'
import { getOrderInfo } from 'screens/orderCreate/containers/FormProvider'
import { getNominationInitialValues } from 'screens/orderEdit/containers/getNominationInitialValues'
import { useUser } from 'containers/user/useUser'
import { useCreateOrder } from 'api'

const getInitialValues = (order, user) => {
  const initialValues = {
    ...getInformationInitialValues(order, user),
    ...getNominationInitialValues(order),
    template: true,
    comments: [],
    brokerAccess: [],
    broker: undefined,
    details: order.details,
  }

  return initialValues
}

const FormProvider = ({ order, postSubmit, children }) => {
  const { mutateAsync: createOrder } = useCreateOrder()
  const { user } = useUser()

  const handleSubmit = async (values) => {
    const data = getOrderInfo(values, user)
    const order = await createOrder(data)
    postSubmit(order)
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getInitialValues(order, user)}
      validationSchema={getSchema(user)}
      validate={validateOrder}
      validateOnMount={false}
    >
      {children}
    </Formik>
  )
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  order: PropTypes.object.isRequired,
  postSubmit: PropTypes.func,
}

export default compose(withSnackbar, withRouter)(FormProvider)
