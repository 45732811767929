import React from 'react'
import PropTyes from 'prop-types'
import { compose } from 'recompose'
import { withTheme } from '@material-ui/styles'
import { isNil } from 'lodash'
import Divider from '@material-ui/core/Divider'
import Text from '@material-ui/core/Typography'
import BreadCrumb from './components/BreadCrumb'
import EntityInformation from './components/EntityInformation'
import Fetch from 'containers/fetchPlus/FetchPlus'
import UsersTable from 'screens/userIndex/components/UsersTable'
import AddButton from 'screens/companyView/components/AddButton'
import BlockHeader from 'screens/companyView/components/BlockHeader'
import { ADMIN_CREATE_USER } from 'routes'
import withParams from 'utils/withRouteParams'

/* :: object -> object */
const getEntityInfo = (data) => {
  const brandName = data.name
  const parentCompany = data.company.name
  const { registrationNumber } = data
  const address = {
    line1: data.addressFirstLine,
    line2: data.addressSecondLine,
    city: data.city,
    postCode: data.postcode,
    country: data.country,
  }

  return { brandName, parentCompany, registrationNumber, address }
}

/* :: object -> object */
const getPrimaryContact = ({ pointOfContact }) => {
  const { name, surname, jobTitle, email, phone: phoneNumber } = pointOfContact
  const address = {
    line1: pointOfContact.addressFirstLine,
    line2: pointOfContact.addressSecondLine,
    city: pointOfContact.city,
    postCode: pointOfContact.postcode,
  }

  return { name, surname, jobTitle, email, phoneNumber, address }
}

/* :: object -> ?object */
const getEntityAdmin = (data) => {
  if (isNil(data.authorisedSignatory)) {
    return null
  }
  const {
    name,
    jobTitle,
    email,
    phone: phoneNumber,
    surname,
  } = data.authorisedSignatory

  return { name, jobTitle, email, phoneNumber, surname }
}

const ViewEntity = ({ id, theme }) => (
  <Fetch url={`/entities/${id}`}>
    {(entity) => {
      const { companyId } = entity
      const addUserLink = ADMIN_CREATE_USER.replace(':companyId', companyId)

      return (
        <>
          <BreadCrumb company={entity.company.name} companyId={companyId} />

          <EntityInformation
            id={id}
            info={getEntityInfo(entity)}
            primaryContact={getPrimaryContact(entity)}
            admin={getEntityAdmin(entity)}
          />

          <Divider />

          {/** USERS */}
          <section style={{ marginTop: theme.spacing(5) }}>
            <BlockHeader>
              <Text variant="h2">Users</Text>
              <AddButton to={addUserLink} />
            </BlockHeader>
            <UsersTable users={entity.persons} />
          </section>
        </>
      )
    }}
  </Fetch>
)

ViewEntity.propTypes = {
  theme: PropTyes.object.isRequired,
  id: PropTyes.string.isRequired,
}

export default compose(withTheme, withParams)(ViewEntity)
