const { PARTY } = require('../party')

const CARGO_SIZE_VARIANCE_DEFAULT_VALUE = {
  [PARTY.BUYER]: {
    MINUS: 2,
    PLUS: 2,
  },
  [PARTY.SELLER]: {
    MINUS: 5,
    PLUS: 5,
  },
  [PARTY.TBA]: {
    MINUS: 0,
    PLUS: 0,
  },
}

module.exports = {
  CARGO_SIZE_VARIANCE_DEFAULT_VALUE,
}
