import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import FormProvider from './containers/FormProvider'
import ModalContent from 'screens/orderCreate/desktop/ModalContent'
import ModalHeader from 'screens/orderCreate/desktop/ModalHeader'
import { useOrderFlow, ORDER_FLOW } from 'containers/OrderProvider'
import { useRouter } from 'utils/useRouter'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { useOrder } from 'api'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { DASHBOARD, ORDER_BASE_ROUTES } from 'routes'
import { Mobile, Tablet } from 'components/Viewport'
import { CreateOrderModal } from 'screens/orderCreate/mobile/CreateOrderModal'

const OrderCreateTemplate = () => {
  const { history } = useRouter()
  const { id } = useParams()
  const BASE_SLUG = useRouteBaseSlug()
  const { setFlow } = useOrderFlow()
  const { isLoading, data: order } = useOrder(id)
  const rootSlug = ORDER_BASE_ROUTES.includes(`/${BASE_SLUG}`)
    ? `/${BASE_SLUG}`
    : DASHBOARD

  const goToOriginalOrder = () => history.push(`${rootSlug}/orders/${id}`)

  const goToNewOrder = (order) => {
    history.push(`/${BASE_SLUG}/orders/${order.id}`)
  }

  useEffect(() => setFlow(ORDER_FLOW.CREATE_TEMPLATE), [setFlow])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <FormProvider order={order} postSubmit={goToNewOrder}>
      <>
        <Tablet matchLarger>
          <ModalHeader
            onCancel={goToOriginalOrder}
            showSaveAsTemplateOption={true}
          />
          <ModalContent />
        </Tablet>
        <Mobile>
          <CreateOrderModal onCancel={goToOriginalOrder} />
        </Mobile>
      </>
    </FormProvider>
  )
}

export default OrderCreateTemplate
