import { useMutation } from 'react-query'
import { useUser } from 'containers/user/useUser'
import { useApi } from 'containers/api/useApi'

export const useEditPersonSettings = () => {
  const { user } = useUser()
  const api = useApi()
  const putSettings = async (data) =>
    api.editPersonSettings(user.id, data)
  return useMutation(putSettings)
}
