import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { TermsheetCard } from '../desktop/TermsheetCard'
import { useTermsheet } from 'api'
import { Mobile, Tablet } from 'components/Viewport'
import { TermsheetCard as MobileTermsheetCard } from 'components/TermsheetCard'
import Person from 'screens/orderIndex/components/Person'

export const TermsheetCardContainer = ({ termsheetId }) => {
  const { isLoading, data: termsheet } = useTermsheet(termsheetId)
  const { palette } = useTheme()

  if (isLoading) {
    return null
  }

  return (
    <>
      <Mobile>
        <MobileTermsheetCard
          data-testid="termsheet-card"
          termsheet={termsheet}
          author={<Person person={termsheet.person} />}
          label={`Termsheet #${termsheet.shortId?.toUpperCase()}`}
          date={termsheet.updatedAt}
          style={{
            background: palette.background.default,
          }}
        />
      </Mobile>
      <Tablet matchLarger>
        <TermsheetCard termsheet={termsheet} />
      </Tablet>
    </>
  )
}

TermsheetCardContainer.propTypes = {
  termsheetId: PropTypes.string.isRequired,
}
