import { useQueryClient } from 'react-query'
import { some, isEqual, flatten, partial } from 'lodash'

export const remove = (useQueryClient, key, params) => {
  const paramsWithKey = flatten([params]).reduce(
    (acc, curr) => [[key, curr], ...acc],
    []
  )

  useQueryClient.removeQueries(({ queryKey }) =>
    some(paramsWithKey, (key) => isEqual(key, queryKey))
  )
}

export const useCacheHelpers = () => {
  const queryClient = useQueryClient()

  return {
    removeFromCache: partial(remove, queryClient),
  }
}
