import React from 'react'
import PropTypes from 'prop-types'

export const BrandName = ({ color, ...props }) => (
  <svg viewBox="0 0 153 37" fill={color} {...props}>
    <path d="M4.4302,10.076 C5.2802,6.926 8.3332,4.521 11.7332,4.521 C15.4182,4.521 17.9572,7.207 18.8052,10.076 L4.4302,10.076 Z M11.6852,0.435 C5.0242,0.435 0.0002,5.785 0.0002,12.879 C0.0002,20.144 4.9142,25.417 11.6852,25.417 C16.3532,25.417 20.2752,23.051 22.4422,18.922 C22.7012,18.426 22.5262,17.812 22.0392,17.531 L20.2342,16.486 C19.9902,16.344 19.6962,16.305 19.4242,16.386 C19.1502,16.463 18.9212,16.648 18.7872,16.9 C17.2882,19.716 14.8042,21.332 11.9712,21.332 C9.7602,21.332 7.7712,20.54 6.3682,19.102 C5.1192,17.82 4.3972,16.127 4.2532,14.162 L22.2292,14.162 C22.8032,14.162 23.2692,13.699 23.2752,13.124 C23.3042,9.359 22.0362,5.993 19.7042,3.645 C17.6202,1.545 14.8472,0.435 11.6852,0.435 L11.6852,0.435 Z" />
    <path d="M46.3027,0.4349 C43.6707,0.4349 41.3897,1.5279 39.9097,3.4299 C38.4727,1.5069 36.2967,0.4349 33.7187,0.4349 C31.9917,0.4349 30.4007,0.9789 29.1577,1.9379 C29.1497,1.3689 28.6857,0.9099 28.1137,0.9099 L25.9757,0.9099 C25.3987,0.9099 24.9297,1.3779 24.9297,1.9559 L24.9297,23.8969 C24.9297,24.4749 25.3987,24.9429 25.9757,24.9429 L28.1137,24.9429 C28.6917,24.9429 29.1577,24.4749 29.1577,23.8969 L29.1577,11.8339 C29.1577,6.9819 30.6457,4.5209 33.5757,4.5209 C36.3427,4.5209 37.4217,6.1589 37.4217,10.3619 L37.4217,23.8969 C37.4217,24.4749 37.8887,24.9429 38.4667,24.9429 L40.6037,24.9429 C41.1817,24.9429 41.6507,24.4749 41.6507,23.8969 L41.6507,11.2639 C41.6507,4.5209 45.1157,4.5209 46.2557,4.5209 C49.0237,4.5209 49.9117,5.8139 49.9117,9.8399 L49.9117,23.8969 C49.9117,24.4749 50.3797,24.9429 50.9577,24.9429 L53.0947,24.9429 C53.6737,24.9429 54.1397,24.4749 54.1397,23.8969 L54.1397,10.1239 C54.1397,5.9649 53.3287,0.4349 46.3027,0.4349" />
    <path d="M60.5923,6.8953 C60.5923,4.9953 62.4913,3.4753 64.3433,3.4753 C66.0533,3.4753 67.4783,4.4253 68.1903,5.9443 L70.0423,4.9953 C69.0923,2.9533 66.6713,1.4803 64.4393,1.4803 C61.2573,1.4803 58.4553,3.7133 58.4553,7.0373 C58.4553,10.7423 61.8743,12.0233 64.8663,13.1163 C67.0503,13.8763 69.1393,14.7313 69.1393,17.4383 C69.1393,20.1923 66.9563,22.3773 64.2003,22.3773 C61.3043,22.3773 59.5473,20.5253 58.7403,17.9133 L56.6513,18.5783 C57.5523,22.1393 60.5453,24.3723 64.2003,24.3723 C68.1423,24.3723 71.2773,21.5703 71.2773,17.5333 C71.2773,9.6023 60.5923,12.1663 60.5923,6.8953" />
    <path d="M88.6567,14.3514 C88.6567,18.5784 88.1827,22.3774 82.9107,22.3774 C77.6387,22.3774 77.1647,18.5784 77.1647,14.3514 L77.1647,1.9554 L75.0277,1.9554 L75.0277,15.1114 C75.0277,17.3434 74.9797,19.5754 76.3557,21.4274 C77.8767,23.4224 80.4407,24.3724 82.9107,24.3724 C85.3797,24.3724 87.9447,23.4224 89.4637,21.4274 C90.8417,19.5754 90.7937,17.3434 90.7937,15.1114 L90.7937,1.9554 L88.6567,1.9554 L88.6567,14.3514 Z" />
    <path d="M98.5776,4.8055 L98.4826,4.8055 L98.4826,1.9555 L96.3456,1.9555 L96.3456,23.8975 L98.4826,23.8975 L98.4826,12.6885 C98.4826,9.4595 98.1986,3.4755 102.9946,3.4755 C103.5646,3.4755 103.9926,3.6185 104.5146,3.9025 L105.6536,2.0035 C104.8946,1.6705 104.1826,1.4805 103.3276,1.4805 C101.0006,1.4805 99.4326,2.8115 98.5776,4.8055" />
    <path d="M117.0986,22.3773 C111.7796,22.3773 108.4536,17.8183 108.4536,12.7843 C108.4536,8.0353 111.9216,3.4753 116.9556,3.4753 C122.2736,3.4753 125.7416,7.4643 125.7416,12.5463 C125.7416,17.8183 122.7026,22.3773 117.0986,22.3773 L117.0986,22.3773 Z M125.5986,5.7553 L125.5036,5.7553 C123.4616,3.0003 120.8026,1.4813 117.2396,1.4813 C110.8296,1.4813 106.3166,6.5623 106.3166,12.8793 C106.3166,19.0053 110.5916,24.3723 116.9556,24.3723 C120.2796,24.3723 123.5566,22.7103 125.5036,20.0983 L125.5986,20.0983 L125.5986,22.8053 C125.5986,25.7493 125.3606,28.5993 123.6516,31.1163 C122.0376,33.4433 119.3776,34.3933 116.6226,34.3933 C112.2056,34.3933 108.3596,31.5433 108.1216,26.9843 L105.9856,26.9843 C106.3166,33.0153 111.0656,36.3873 116.8126,36.3873 C120.2796,36.3873 123.5096,34.9623 125.5516,32.1613 C127.6406,29.2633 127.7356,26.2243 127.7356,22.8523 L127.7356,1.9553 L125.5986,1.9553 L125.5986,5.7553 Z" />
    <path d="M142.1684,3.4754 C146.6824,3.4754 150.0054,7.0844 150.5264,11.1214 L133.6204,11.1214 C134.0014,6.9904 137.7054,3.4754 142.1684,3.4754 M142.4074,22.3774 C137.3254,22.3774 133.4794,18.6734 133.6204,13.1164 L152.6664,13.1164 C152.7124,6.8004 148.7694,1.4804 142.1224,1.4804 C135.7104,1.4804 131.4824,6.7524 131.4824,12.8794 C131.4824,19.1004 135.6154,24.3724 142.1224,24.3724 C146.4424,24.3724 149.9564,22.2344 151.9514,18.4354 L150.1474,17.3904 C148.5814,20.3354 145.8264,22.3774 142.4074,22.3774" />
  </svg>
)

BrandName.defaultProps = {
  color: '#FFF',
}

BrandName.propTypes = {
  color: PropTypes.string,
}
