import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { isValidDate } from './DeliveryWindowCargoes.helpers'
import TextField from 'containers/formikTextField/FormikTextField'

export const CargoCardDateFields = ({
  spacing,
  xs,
  index,
  onChangeDate,
  disabled,
}) => (
  <Grid container spacing={spacing}>
    <Grid item xs={xs}>
      <TextField
        fullWidth
        type="date"
        margin="normal"
        InputLabelProps={{
          error: false,
          max: '3000-12-31',
          min: '1900-12-31',
        }}
        disabled={disabled}
        shouldSave={isValidDate}
        name={`nominationRules.deliveryWindow.cargos[${index}].from`}
        label="From"
        onChange={({ target: { value } }) => onChangeDate('from', value)}
      />
    </Grid>
    <Grid item xs={xs}>
      <TextField
        fullWidth
        type="date"
        margin="normal"
        InputLabelProps={{
          error: false,
          max: '3000-12-31',
          min: '1900-12-31',
        }}
        disabled={disabled}
        shouldSave={isValidDate}
        name={`nominationRules.deliveryWindow.cargos[${index}].to`}
        label="To"
        onChange={({ target: { value } }) => onChangeDate('to', value)}
      />
    </Grid>
  </Grid>
)

CargoCardDateFields.propTypes = {
  spacing: PropTypes.number,
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  index: PropTypes.number.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

CargoCardDateFields.defaultProps = {
  spacing: 2,
  xs: 6,
  disabled: false,
}
