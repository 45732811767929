import React from 'react'
import { connect } from 'formik'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiCheckbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import { TextField } from 'screens/orderCreateCommon/components/TextField'
import FormRow from 'components/formRow/FormRow'

import { $O } from 'model/order/selectors/selector'

const _QualityGHVFields = ({ formik: { values, setFieldValue } }) => (
  <>
    {$O.quality.isCustom(values) && (
      <FormRow>
        <TextField
          fullWidth={false}
          onChange={(evt) =>
            setFieldValue('quality.customMin', evt.target.value)
          }
          shouldSave={() => false}
          name="quality.customMin"
          type="number"
          label="Min"
          inputProps={{ 'data-testid': 'quality-custom-min' }}
        />
        <TextField
          fullWidth={false}
          onChange={(evt) =>
            setFieldValue('quality.customMax', evt.target.value)
          }
          shouldSave={() => false}
          name="quality.customMax"
          type="number"
          label="Max"
          inputProps={{ 'data-testid': 'quality-custom-max' }}
        />
      </FormRow>
    )}
    <FormControl fullWidth>
      <FormControlLabel
        label="CBM not permitted"
        control={
          <MuiCheckbox
            color="primary"
            checked={!values.quality.cbmPermitted}
            onChange={() =>
              setFieldValue(
                'quality.cbmPermitted',
                !values.quality.cbmPermitted
              )
            }
            inputProps={{ 'data-testid': 'cbm-not-permitted-checkbox' }}
          />
        }
      />
      <FormControlLabel
        label="US not permitted"
        control={
          <MuiCheckbox
            color="primary"
            checked={!values.quality.usPermitted}
            onChange={() =>
              setFieldValue('quality.usPermitted', !values.quality.usPermitted)
            }
            inputProps={{ 'data-testid': 'us-not-permitted-checkbox' }}
          />
        }
      />
    </FormControl>
  </>
)

_QualityGHVFields.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const QualityGHVFields = connect(_QualityGHVFields)
