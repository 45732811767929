import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withSnackbar } from 'notistack'
import { PERSON_PERMISSION, ORDER_STATUS } from 'emsurge-selectors'
import { useTheme, Tabs, Tab, Grid } from '@material-ui/core'
import MoreMenu from '../containers/HeaderMoreMenuCarbon'
import OrderStatusSwitch from '../containers/OrderStatusSwitch'
import { EditOrderButton } from './EditOrderButton'
import OrderShareButton from './OrderShareButton/OrderShareButton'
import { OrderSummary } from 'components/OrderSummaryCarbon'
import { useUserHasPermission } from 'permissions'
import If from 'containers/controlFlow/If'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'
import { useOrderLog } from 'api/queries/order/useOrderLog'
import {
  Wrapper,
  GridContainer,
  GridArea,
  HEADER_AREAS,
} from 'components/ModalHeader/layout'
import { getStatusLabel } from 'screens/orderCreate/helpers'
import { useIsMobile } from 'components/Viewport/Mobile'
import { ShareWithBrokerMenuButton } from 'screens/orderView/components/OrderShareButton/ShareWithBrokerMenuButton'

export const TabsWrapper = styled.div`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.secondary.dark};
`

const Header = ({ activeTab, setTab, order, onClose }) => {
  const { spacing } = useTheme()
  const { data: logs = [] } = useOrderLog(order.id)
  const isMobile = useIsMobile()

  const canEditOrder = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.WRITE,
    forceSameCompany: true,
  })

  const buttonGridProps = {
    justifyContent: `flex-${isMobile ? 'start' : 'end'}`,
  }

  const gridAreaProps = {
    style: {
      margin: spacing(isMobile ? 1 : 2, 0),
    },
  }

  return (
    <>
      <Wrapper>
        <GridContainer data-testid="order-modal-header">
          <OrderSummary order={order} expanded={true} />

          <GridArea name={HEADER_AREAS.OTHER_ACTIONS}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid data-testid="view-order-status-label" item>
                {!(
                  [ORDER_STATUS.LIVE, ORDER_STATUS.WITHHELD].includes(
                    order.status
                  ) && !order.template
                ) && getStatusLabel(order)}
                <OrderStatusSwitch
                  data-testid="order-status-switch"
                  order={order}
                  label="Live"
                  id="modalOrderLiveStatus"
                />
              </Grid>
              <Grid item>
                <CloseButton onClick={onClose} />
              </Grid>
            </Grid>
          </GridArea>

          <GridArea name={HEADER_AREAS.MAIN_ACTIONS} {...gridAreaProps}>
            <Grid
              container
              alignItems="center"
              spacing={1}
              {...buttonGridProps}
            >
              <ShareWithBrokerMenuButton closeMenu={onClose} order={order} />
              <Grid item>
                <Grid container spacing={1}>
                {!order.template ? (
                  <Grid item>
                    <OrderShareButton order={order} />
                  </Grid>
                ) : null}
                <If condition={canEditOrder}>
                    <Grid item>
                      <EditOrderButton
                        data-testid="edit-order-button"
                        order={order}
                      />
                    </Grid>
                  </If>
                  <Grid item>
                    <MoreMenu order={order} setTab={setTab} onClose={onClose} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </GridArea>
        </GridContainer>
      </Wrapper>

      <TabsWrapper>
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          indicatorColor="primary"
          value={activeTab}
          onChange={(_, tabIndex) => setTab(tabIndex)}
        >
          <Tab
            label="Information"
            id="orderInformationTab"
            data-testid="order-information-tab"
          />
          <Tab
            label={`Comments (${order.comments?.length || 0})`}
            id="orderCommentsTab"
            data-testid="order-comments-tab"
          />
          <Tab
            label={'Edit Log'}
            disabled={!logs.length}
            data-testid="order-edit-log-tab"
          />
        </Tabs>
      </TabsWrapper>
    </>
  )
}

Header.propTypes = {
  activeTab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withSnackbar(Header)
