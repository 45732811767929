import React, { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import { get as getProp } from 'lodash'
import { FEATURE_FLAGS_KEY, FEATURE_FLAGS } from './constants'

export const FeatureFlagsContext = createContext()

export const FeatureFlagsProvider = ({ children, featureFlags = {} }) => {
  const [flags, setFlags] = useState(featureFlags)

  const get = (key) => getProp(flags, key)

  const set = (key, value) => {
    if (get(key) === value || !getProp(FEATURE_FLAGS, key)) {
      return
    }
    const updatedFlags = {
      ...flags,
      [key]: value,
    }
    setFlags(updatedFlags)
  }

  useEffect(() => {
    try {
      const parsed =
        JSON.parse(window.localStorage?.getItem(FEATURE_FLAGS_KEY) || '{}') ||
        {}

      setFlags({ ...flags, ...parsed })
    } catch (err) {
      console.error({ err })
      setFlags({})
    }
  }, [])

  useEffect(() => {
    const stringified = JSON.stringify(flags)
    if (window.localStorage) {
      window.localStorage.setItem(FEATURE_FLAGS_KEY, stringified)
    }
  }, [flags])

  const value = {
    values: flags,
    get,
    set,
  }

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node,
  featureFlags: PropTypes.object,
}
