import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/styles'
import { useLocation, withRouter } from 'react-router'
import { compose } from 'recompose'
import { useUser } from 'containers/user/useUser'
import { isLoggedIn } from 'containers/user/helpers'

export const LayoutContext = React.createContext({
  hasSideMenu: false,
  toggleSideMenu: () => {},
  toggleSubMenus: () => {},
  setSideMenu: () => {},
  isDesktopViewportMode: false,
  setDesktopViewportMode: false,
})

/*
  clears meta viewport content when on desktop override mode
  sets meta viewport original content attribute when untoggled
*/
const useChangeViewportMetaContent = (isDesktopViewportMode) => {
  const viewportMetaElement = useRef(
    Array.from(document.getElementsByTagName('meta')).find(
      ({ name }) => name === 'viewport'
    ) || {}
  )
  const viewportMetaContent = useRef(viewportMetaElement.current.content)

  useEffect(() => {
    viewportMetaElement.current.content = isDesktopViewportMode
      ? ''
      : viewportMetaContent.current
  }, [isDesktopViewportMode])
}

const LayoutProvider = ({ children }) => {
  const { breakpoints } = useTheme()
  const { user } = useUser()
  const location = useLocation()
  const isMobile = useMediaQuery(breakpoints.down('xs'))
  const [hasSideMenu, setSideMenu] = useState(isLoggedIn(user))
  const [openedSubMenu, setSubMenu] = useState(location?.pathname || false)
  const [isDesktopViewportMode, setDesktopViewportMode] = useState(false)
  const toggleSideMenu = useCallback(
    () => setSideMenu(!hasSideMenu),
    [hasSideMenu]
  )
  const toggleSubMenus = (clickedMenu) => setSubMenu(clickedMenu)

  useChangeViewportMetaContent(isDesktopViewportMode)
  useEffect(() => setSideMenu(!isMobile), [isMobile]) // when viewport is mobile, close sidemenu

  const value = useMemo(
    () => ({
      hasSideMenu,
      toggleSideMenu,
      setSideMenu,
      isDesktopViewportMode,
      setDesktopViewportMode,
      openedSubMenu,
      toggleSubMenus,
    }),
    [hasSideMenu, isDesktopViewportMode, toggleSideMenu, openedSubMenu]
  )

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  )
}

LayoutProvider.propTypes = {
  children: PropTypes.node,
}

export default compose(withRouter)(LayoutProvider)
