import { withProps } from 'recompose'
import Add from '@material-ui/icons/Add'
import IconButton from 'components/iconButton/IconButton'

const CreateNoteButton = withProps({
  icon: Add,
  color: 'secondary',
  variant: 'contained',
  size: 'small',
  children: 'Note',
})(IconButton)

export default CreateNoteButton
