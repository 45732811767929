import React from 'react'
import styled from 'styled-components'

const Chevron = styled.div`
  width: 38px;
  border-width: 0;
  border-style: solid;
  height: 38px;
  display: inline-block;
  transform: rotate(-45deg);
  border-color: ${({ theme }) => theme.palette.secondary.main};
  border-right-width: 2px;
  border-bottom-width: 2px;
  position: absolute;
  top: 5px;
  left: -20px;
`

const ChevronWrapper = styled.div`
  position: relative;
`

const TabChevron = () => (
  <ChevronWrapper>
    <Chevron />
  </ChevronWrapper>
)

export default TabChevron
