import React from 'react'
import PropTypes from 'prop-types'
import { Grid, IconButton } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { FieldArray } from 'formik'
import { noop } from 'lodash'
import ClearIcon from '@material-ui/icons/Clear'
import { Option } from './Option'
import { GridInline } from './GridInline'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'

const Actions = ({
  onMoveDown = noop,
  onMoveUp = noop,
  onRemove = noop,
  removable,
  sortable,
}) => {
  return (
    <Grid container alignItems="center" style={{ height: 32 }}>
      {sortable && (
        <GridInline item>
          <IconButton size="small" onClick={onMoveUp}>
            <KeyboardArrowUpIcon />
          </IconButton>
        </GridInline>
      )}
      {sortable && (
        <GridInline item>
          <IconButton size="small" onClick={onMoveDown}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </GridInline>
      )}
      {removable && (
        <GridInline item>
          <IconButton size="small" onClick={onRemove}>
            <ClearIcon />
          </IconButton>
        </GridInline>
      )}
    </Grid>
  )
}

Actions.propTypes = {
  fieldName: PropTypes.string,
  onMoveDown: PropTypes.func,
  onMoveUp: PropTypes.func,
  onRemove: PropTypes.func,
  removable: PropTypes.bool,
  sortable: PropTypes.bool,
}

export const MarketOptions = () => (
  <FormConsumer>
    {({ values }) => (
      <FieldArray
        name="markets"
        render={({ swap, remove }) => {
          return values.markets.map((market, index) => (
            <Option
              key={`market-${market.name}`}
              label={market.name}
              actions={
                <Actions
                  removable={values.markets.length > 1}
                  sortable={values.markets.length > 1}
                  fieldName={`markets.${index}`}
                  onMoveDown={() =>
                    swap(index, Math.min(index + 1, values.markets.length - 1))
                  }
                  onMoveUp={() => swap(index, Math.max(index - 1, 0))}
                  onRemove={() => remove(index)}
                />
              }
            />
          ))
        }}
      />
    )}
  </FormConsumer>
)
