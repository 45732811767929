import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'

import { DELIVERY_TYPE } from 'emsurge-selectors'
import { PaddedContainer } from 'screens/orderCreate/mobile/components'
import { LoadPort } from 'screens/orderCreate/mobile/LoadPort'
import { DischargePort } from 'screens/orderCreate/mobile/DischargePort'

export const _BasePort = ({ formik: { values } }) => {
  const type = values?.delivery?.type || ''
  return (
    <PaddedContainer>
      {type.toUpperCase() === DELIVERY_TYPE.FOB ? (
        <LoadPort />
      ) : (
        <DischargePort />
      )}
    </PaddedContainer>
  )
}

_BasePort.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const BasePort = connect(_BasePort)
