import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import { AppContext } from './AppContextProvider'
import useFetchConsumer from 'containers/fetch/useFetchConsumer'

const useAppContext = () => {
  const { appContext, setAppContext } = useContext(AppContext)
  const queryClient = useQueryClient()
  const { clearCache } = useFetchConsumer()

  const setLNGContext = () => {
    setAppContext(APP_CONTEXT_TYPE.LNG)

    queryClient.removeQueries()
    clearCache()
  }

  const setCarbonContext = () => {
    setAppContext(APP_CONTEXT_TYPE.CARBON)

    queryClient.removeQueries()
    clearCache()
  }

  const setContext = (context) => {
    if (context === APP_CONTEXT_TYPE.CARBON) {
      return setCarbonContext()
    }
    return setLNGContext()
  }

  const isLNGContext = appContext === APP_CONTEXT_TYPE.LNG
  const isCarbonContext = appContext === APP_CONTEXT_TYPE.CARBON

  return {
    appContext,
    setLNGContext,
    setCarbonContext,
    setContext,
    isLNGContext,
    isCarbonContext,
  }
}

export default useAppContext
