import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import OrderType from './OrderType'
import Person from './Person'
import { XsmallText } from 'components/Text'
import { DeliveryInfo } from 'components/DeliveryInfo'
import { DeliveryContract } from 'components/DeliveryContract'
import { MainPrice } from 'components/MainPrice'
import { TimeAgo } from 'components/TimeAgo'
import { Card, Container } from 'components/Card'
import CompanyCode from 'components/CompanyCode/CompanyCode'
import { StyledLink as Link } from 'components/StyledLink'

const ActivityCommentCard = ({ style, activity }) => {
  const { spacing } = useTheme()
  const { order, author } = activity
  const gutter = { marginRight: spacing(2) }
  const timeAgoDate = activity.occurredAt

  const explanationText = `You can see this comment because of Rule #1: You have at least read permission on at least one entity shared with the author of the comment. The author's entities are ${activity.explanation.rule1.comment_person_entities}. The entities in which you have at least read permissions are ${activity.explanation.person_read_entity_ids}. Activity ID=${activity.activity_id}.`

  const isTermsheetClarification = !!activity.termsheetId // We want a boolean, so we negate the termsheetId property and then negate that again.

  const linkLocation = isTermsheetClarification
    ? `termsheets/${activity.termsheetId}`
    : `orders/${order.id}`

  return (
    <Link to={(location) => `${location.pathname}/${linkLocation}`}>
      <Card data-testid={`activity-comment-card-${order.id}`} style={style}>
        {/* ROW 1 */}
        <Container justifyContent="space-between">
          <XsmallText title={explanationText}>
            {isTermsheetClarification ? 'Clarification' : 'Comment'}
          </XsmallText>
          <Person data-testid="order-card-author" person={author} />
        </Container>

        {/* ROW 2 */}
        <Container justifyContent="space-between">
          <Container style={{ width: 'auto' }}>
            <CompanyCode order={order} style={gutter} />
            <OrderType order={order} />
          </Container>

          <Container style={{ width: 'auto' }}>
            <TimeAgo date={timeAgoDate} />
          </Container>
        </Container>

        {/* ROW 3 */}
        <Container wrap="nowrap">
          <DeliveryInfo order={order} style={gutter} />
          <DeliveryContract order={order} style={gutter} />
          <MainPrice order={order} />
        </Container>

        <Divider style={{ margin: spacing(1, 0) }} />

        {/* ROW 4 */}
        <Container>
          <XsmallText data-testid="activity-comment-text" noWrap>
            {activity.text}
          </XsmallText>
        </Container>
      </Card>
    </Link>
  )
}

ActivityCommentCard.propTypes = {
  activity: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export default memo(ActivityCommentCard)
