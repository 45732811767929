import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { isNull } from 'lodash'
import Fetch from 'containers/fetch/Fetch'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { ERROR } from 'routes'

/**
 @NOTE: Use this when you want the default loading, error handling while fetching
 data.
 */
const FetchPlus = ({ children, ...props }) => (
  <Fetch {...props}>
    {({ loading, error, data, refetch }) => {
      if (loading && isNull(data)) {
        return <LoadingSpinner />
      }

      if (error) {
        const state = { error }
        return <Redirect to={{ pathname: ERROR, state }} />
      }

      return children(data, refetch)
    }}
  </Fetch>
)

FetchPlus.propTypes = {
  children: PropTypes.func,
}

export default FetchPlus
