const { PERSON_CLIENT_TYPE } = require('./person')

const STAKEHOLDER_TYPE = Object.freeze({
  ON_SYSTEM: 'company',
  OFF_SYSTEM: 'third_party',
})

const STAKEHOLDER_TYPE_TEXT = Object.freeze({
  [STAKEHOLDER_TYPE.ON_SYSTEM]: 'My Company',
  [STAKEHOLDER_TYPE.OFF_SYSTEM]: 'Third Party',
})

const STAKEHOLDER_TYPE_TEXT_BY_CLIENT_TYPE = Object.freeze({
  [PERSON_CLIENT_TYPE.TRADER]: {
    [STAKEHOLDER_TYPE.ON_SYSTEM]:
      STAKEHOLDER_TYPE_TEXT[STAKEHOLDER_TYPE.ON_SYSTEM],
    [STAKEHOLDER_TYPE.OFF_SYSTEM]:
      STAKEHOLDER_TYPE_TEXT[STAKEHOLDER_TYPE.OFF_SYSTEM],
  },
  [PERSON_CLIENT_TYPE.BROKER]: {
    [STAKEHOLDER_TYPE.ON_SYSTEM]: 'On System',
    [STAKEHOLDER_TYPE.OFF_SYSTEM]: 'Off System',
  },
})

module.exports = {
  STAKEHOLDER_TYPE,
  STAKEHOLDER_TYPE_TEXT,
  STAKEHOLDER_TYPE_TEXT_BY_CLIENT_TYPE,
}
