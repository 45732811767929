import React from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@material-ui/core/TextField'
import { Field } from 'formik'
import MuiRadio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withProps } from 'recompose'

/* :: (object, object) -> object */
export const getRadioInputProps = (name, props, formikFieldProps) => {
  const radioProps = {
    ...props,
    ...formikFieldProps,
    id: props.id || name,
    onChange(...args) {
      formikFieldProps.onChange(...args)
      props.onChange(...args)
    },
    onBlur(...args) {
      formikFieldProps.onBlur(...args)
      props.onBlur(...args)
    },
  }

  return radioProps
}

const Radio = withProps({ color: 'primary' })(MuiRadio)

const FormikRadioInput = ({ name, options, radiosWrapperStyle, ...props }) => (
  <Field name={name}>
    {({ field, form }) => (
      <RadioGroup
        name={name}
        row
        value={field.value}
        style={radiosWrapperStyle}
      >
        {options.map(({ value, label, disabled, labelClassName }) => (
          <FormControlLabel
            {...getRadioInputProps(name, props, field)}
            classes={{
              label: labelClassName,
            }}
            key={value}
            checked={value === field.value}
            label={label}
            value={value}
            control={
              <Radio inputProps={{ 'data-testid': `${name}-${value}` }} />
            }
            disabled={disabled || form.isSubmitting}
          />
        ))}
      </RadioGroup>
    )}
  </Field>
)

FormikRadioInput.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  radiosWrapperStyle: {},
}

FormikRadioInput.propTypes = {
  ...MuiTextField.propTypes,
  radiosWrapperStyle: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      disabled: PropTypes.bool,
      labelClassName: PropTypes.string,
    })
  ).isRequired,
}

export default FormikRadioInput
