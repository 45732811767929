import { useEffect, useState } from 'react'
import { computeFixedPrices } from './useCalculateFixedPrice.helpers'
import { usePriceIndexForOrders } from 'api'

export const useCalculateFixedPrice = (orders) => {
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(true)
  const { data: priceIndexes, isLoading } = usePriceIndexForOrders(orders)

  useEffect(() => {
    (() => {
      try {
        setLoading(true)
        setResult(computeFixedPrices(orders, priceIndexes))
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    })()
  }, [orders, priceIndexes, isLoading])

  return [result, loading]
}
