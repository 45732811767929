import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import { withSnackbar } from 'notistack'
import { ADMIN_VIEW_USERS } from 'routes'
import {
  getFormSchema,
  getApiData,
} from 'screens/userCreate/containers/FormProvider'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

export const getInitialFormValues = (user, entities) => {
  const { name, surname, jobTitle, email, phone, clientType, adminRole } = user
  const initialValues = entities.reduce(
    (values, entity) => {
      const person = entity.persons.find(({ id }) => id === user.id)
      return {
        ...values,
        [entity.id]: {
          isAdmin: person.isAdmin,
          role: person.role,
        },
      }
    },
    { name, surname, jobTitle, email, phone, clientType, adminRole }
  )

  return initialValues
}

const FormProvider = ({
  user,
  entities,
  history,
  enqueueSnackbar,
  children,
}) => {
  const api = useApi()

  const handleSubmit = async (values, form) => {
    const data = getApiData(user.companyId, entities, values, user.status)

    try {
      await api.patch(`/persons/${user.id}`, data)
      enqueueSnackbar('User edited successfully', { variant: 'success' })
      history.push(ADMIN_VIEW_USERS)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'User edit not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }

    form.setSubmitting(false)
  }

  const initialValues = getInitialFormValues(user, entities)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getFormSchema(initialValues)}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  user: PropTypes.object.isRequired,
  entities: PropTypes.array.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default compose(withSnackbar, withRouter)(FormProvider)
