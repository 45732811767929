import React from 'react'

import {
  Button,
  StyledContainer,
  StyledConnectedSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { SelectableGridOption as Option } from 'components/SelectableGrid'
import { SelectCargoSizeParty } from 'screens/orderCreate/components/SelectCargoSizeParty'

export const Party = () => (
  <StyledContainer>
    <SelectCargoSizeParty>
      {({ options, onChange }) => (
        <StyledConnectedSelectableGrid
          name="cargoSize.party"
          onChange={(value) => onChange(value)}
        >
          {options.map(({ value, label }) => (
            <Option
              data-testid={`cargoSize.party-${value}`}
              key={`order-cargo-size-party-${value}`}
              xs={4}
              value={value}
            >
              <Button>{label}</Button>
            </Option>
          ))}
        </StyledConnectedSelectableGrid>
      )}
    </SelectCargoSizeParty>
  </StyledContainer>
)
