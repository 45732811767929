const { PRICE_TYPE, PRICE_TYPE_TEXT } = require('../../../constants')

const hasPrice = (info) => {
  const priceType = Object.values(PRICE_TYPE).includes(info.value)

  return priceType
}

const getPrice = (info) => {
  return { ...info }
}

const getText = (info) => {
  const priceType = info.value
  let longSummary
  let shortSummary

  if (priceType) {
    longSummary = PRICE_TYPE_TEXT[priceType]
    shortSummary = `Preference: ${PRICE_TYPE_TEXT[priceType]}`
  }

  return {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.PREFERENCE],
    short: shortSummary,
    shorter: shortSummary,
    long: longSummary,
  }
}

module.exports = {
  exists: hasPrice,
  get: getPrice,
  summary: getText,
}
