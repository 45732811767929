const { VOLUME_OFFSET_PREFIX } = require('./carbonPrefix')

const VOLUME_OFFSET = Object.freeze({
  ALL_OR_NOTHING: 'ALL_OR_NOTHING',
})

const VOLUME_OFFSET_LABELS = Object.freeze({
  [VOLUME_OFFSET.ALL_OR_NOTHING]: 'All-or-nothing',
})

const VOLUME_OFFSET_PATHS = Object.values(VOLUME_OFFSET).map(
  (volume) => `${VOLUME_OFFSET_PREFIX}.${volume}`
)

const VOLUME_OFFSET_OPTIONS = Object.values(VOLUME_OFFSET).map((volume) => ({
  label: VOLUME_OFFSET_LABELS[volume],
  value: `${VOLUME_OFFSET_PREFIX}.${volume}`,
}))

module.exports = {
  VOLUME_OFFSET,
  VOLUME_OFFSET_PATHS,
  VOLUME_OFFSET_OPTIONS,
  VOLUME_OFFSET_LABELS,
}
