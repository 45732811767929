import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { groupBy, sortBy } from 'lodash'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Text from '@material-ui/core/Typography'
import { Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'
import DetailDivider from '../../orderView/components/DetailDivider'
import { getOrderCargoTimezoneMap, getPartyEntityCode } from '../helpers'
import DeliveryWindowNominations from '../components/DeliveryWindowNominations'
import LoadPortNominations from '../components/LoadPortNominations'
import DischargePortNominations from '../components/DischargePortNominations'
import ShipNominations from '../components/ShipNominations'
import OtherNominations from '../components/OtherNominations'
import ExtraInfo from '../components/ExtraInfo'
import { getApiErrorMessage } from 'containers/api/helpers'

export const handleSave = async (
  api,
  nominationId,
  values,
  actions,
  enqueueSnackbar
) => {
  try {
    await api.patch(`/nominations/${nominationId}`, {
      toBeNominated: false,
      ...values,
    })
    enqueueSnackbar('Nomination update successful', { variant: 'success' })
    actions.setSubmitting(false)
  } catch (error) {
    actions.setSubmitting(false)
    const message = getApiErrorMessage({
      error,
      defaultMessage: 'Nomination update not successful',
    })
    enqueueSnackbar(message, { variant: 'error' })
  }
}

const getNominationComponent = ({ type }) => {
  switch (type) {
    case 'deliveryWindow':
      return DeliveryWindowNominations
    case 'loadPort':
      return LoadPortNominations
    case 'dischargePort':
      return DischargePortNominations
    case 'ship':
      return ShipNominations
    case 'other':
      return OtherNominations
    case 'extra':
      return ExtraInfo
    default:
      throw new Error(`Unknown nomination name "${type}"`)
  }
}

const getNominationPosition = ({ type }) => {
  switch (type) {
    case 'deliveryWindow':
      return 1
    case 'loadPort':
      return 2
    case 'dischargePort':
      return 3
    case 'ship':
      return 4
    case 'other':
      return 5
    case 'extra':
      return 6
    default:
      return 7
  }
}

const CargoPanel = ({
  cargoNumber,
  cargoNominations,
  orders,
  nominationRules,
  orderCargoTimeZoneMap,
  ...props
}) => {
  const indexMap = cargoNominations.reduce(
    (mem, item, idx) => {
      const isOther = item.type === 'other'
      return { ...mem, i: mem.i + isOther, [idx]: isOther ? mem.i : undefined }
    },
    { i: 0 }
  )

  return (
    <Accordion {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Text variant="h6">Cargo {cargoNumber}</Text>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {cargoNominations.map((nomination, index) => {
          const NominationComponent = getNominationComponent(nomination)
          return (
            <Fragment key={index}>
              <NominationComponent
                nomination={nomination}
                entityCode={getPartyEntityCode(nomination, orders)}
                nominationRules={nominationRules}
                orderCargoTimeZoneMap={orderCargoTimeZoneMap}
                orders={orders}
                index={indexMap[index]}
              />
              <DetailDivider />
            </Fragment>
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}

CargoPanel.propTypes = {
  cargoNumber: PropTypes.number.isRequired,
  cargoNominations: PropTypes.array.isRequired,
  orders: PropTypes.array.isRequired,
  nominationRules: PropTypes.object.isRequired,
  orderCargoTimeZoneMap: PropTypes.object.isRequired,
}

const TradedNominations = ({ order }) => {
  const { nominations, nominationRules, orders } = order
  const { boilOff, demurage, laytime, fsruFsu } = nominationRules
  const nominationsByCargoes = groupBy(nominations, (n) => n.cargo)

  // add boilOff, demurage, laytime and fsruFsu as a nomination
  for (const cargo in nominationsByCargoes) {
    // eslint-disable-line
    nominationsByCargoes[cargo].push({
      type: 'extra',
      party: 'bid', // this value doesn't matter as it's not editable
      laytime,
      demurage,
      boilOff,
      fsruFsu,
      cargo,
    })
  }

  const orderCargoTimeZoneMap = getOrderCargoTimezoneMap(nominations)

  return (
    <section>
      {Object.keys(nominationsByCargoes).map((cargoNumber, index) => {
        const isFirstCargo = index === 0
        const orderedNominations = sortBy(
          nominationsByCargoes[cargoNumber].map((nomination) => ({
            position: getNominationPosition(nomination),
            ...nomination,
          })),
          ['position', ({ alternative }) => (alternative ? 2 : 1)]
        )

        return (
          <CargoPanel
            key={index}
            orders={orders}
            cargoNumber={parseInt(cargoNumber) + 1}
            defaultExpanded={isFirstCargo}
            cargoNominations={orderedNominations}
            nominationRules={nominationRules}
            orderCargoTimeZoneMap={orderCargoTimeZoneMap}
          />
        )
      })}
    </section>
  )
}

export default TradedNominations

TradedNominations.propTypes = {
  order: PropTypes.object.isRequired,
}
