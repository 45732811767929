import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

export const Loading = () => (
  <>
    <Skeleton animation="wave" variant="text" height={60} />
    <Skeleton animation="wave" variant="text" height={60} />
    <Skeleton animation="wave" variant="rect" height={180} />
    <Skeleton animation="wave" variant="text" height={40} />
  </>
)
