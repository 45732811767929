import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { TimeAgo } from 'components/TimeAgo'
import { DeliveryInfo } from 'components/DeliveryInfo'
import { DeliveryContract } from 'components/DeliveryContract'
import { MainPrice } from 'components/MainPrice'

const useStyles = makeStyles((theme) => ({
  gutter: {
    marginRight: theme.spacing(1.5),
  },
  childInfoLeft: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    flexWrap: 'wrap',
  },
  childInfoRight: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  childInfoRow: {
    display: 'flex',
  },
}))

export const TermsheetInfo = ({ termsheet, date }) => {
  const classes = useStyles()

  return (
    <>
      <Grid xs={6} item className={classes.childInfoLeft}>
        <DeliveryInfo
          data-testid="termsheet-card-delivery-info"
          order={termsheet}
          className={classes.gutter}
        />
      </Grid>

      <Grid xs={3} item className={classes.childInfoRight}>
        <TimeAgo data-testid="termsheet-card-last-updated" date={date} />
      </Grid>

      <Grid xs={12} item className={classes.childInfoRow}>
        <DeliveryContract
          data-testid="termsheet-card-delivery-contract"
          order={termsheet}
          className={classes.gutter}
        />
        <MainPrice data-testid="termsheet-card-main-price" order={termsheet} />
      </Grid>
    </>
  )
}

TermsheetInfo.defaultProps = {
  termsheet: {},
}

TermsheetInfo.propTypes = {
  termsheet: PropTypes.object.isRequired,
  date: PropTypes.string,
}
