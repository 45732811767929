import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import IconButton from '@material-ui/core/IconButton'
import Edit from '@material-ui/icons/Edit'
import Clear from '@material-ui/icons/Clear'

import { withSnackbar } from 'notistack'
import { Grid } from '@material-ui/core'
import { getApiErrorMessage } from 'containers/api/helpers'
import { $O } from 'model/order/selectors/selector'
import { useApi } from 'containers/api/useApi'
import { useUser } from 'containers/user/useUser'
import { useUserHasPermission } from 'permissions'

const TermsheetControl = ({ termsheet, enqueueSnackbar, onEdit }) => {
  const theme = useTheme()
  const api = useApi()
  const { user } = useUser()
  const userHasPermission = useUserHasPermission({
    order: termsheet,
    minimumPermission: 'write',
  })

  const onDelete = async () => {
    try {
      await api.delete(`/termsheets/${termsheet.id}`)
      enqueueSnackbar('Term sheet closed successfully', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Error closing term sheet',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const disableTermsheetControls =
    $O.status.isClosed(termsheet) ||
    $O.status.isTraded(termsheet) ||
    !userHasPermission
  const disableArchiveTermsheet =
    disableTermsheetControls || user.id !== termsheet.personId

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      style={{ height: '100%' }}
    >
      <Grid item>
        <IconButton
          disabled={disableTermsheetControls}
          style={{ padding: theme.spacing(1) }}
          onClick={(ev) => {
            ev.stopPropagation()
            onEdit()
          }}
        >
          <Edit />
        </IconButton>
      </Grid>

      <Grid item>
        <IconButton
          disabled={disableArchiveTermsheet}
          style={{
            color: disableArchiveTermsheet ? 'gray' : 'red',
            padding: theme.spacing(1),
          }}
          onClick={(ev) => {
            ev.stopPropagation()
            onDelete()
          }}
        >
          <Clear />
        </IconButton>
      </Grid>
    </Grid>
  )
}

TermsheetControl.propTypes = {
  termsheet: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default withSnackbar(TermsheetControl)
