import { ORDER_STATUS, PERSON_PERMISSION } from 'emsurge-selectors/constants'
import { useUserHasPermission } from 'permissions'

export const useOrderCanBeTraded = (order) => {
  const userCanMarkAsTraded = useUserHasPermission({
    order: order || {},
    minimumPermission: PERSON_PERMISSION.TRADE,
    forceSameCompany: true,
  })
  return (
    userCanMarkAsTraded && !order.template && order.status !== ORDER_STATUS.TRADED && order.status !== ORDER_STATUS.CANCELLED
  )
}
