import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { cloneDeep, noop, omit } from 'lodash'
import { useUser } from 'containers/user/useUser'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { ORDER_KEY } from 'api/constants'

const mapProps = (checklistItem) =>
  omit(checklistItem, ['id', 'orderId', 'checklistId'])

export const useEditChecklistItem = () => {
  const { user } = useUser()
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const putChecklistItem = async (checklistItem) =>
    api.editChecklistItem(
      checklistItem.checklistId,
      checklistItem.id,
      mapProps(checklistItem)
    )

  const updateCachedOrder = (order) => {
    const queryKey = [ORDER_KEY, order.id]
    queryClient.setQueryData(queryKey, order)
  }

  const withCheckProps = (item) => ({
    checkedDate: item.checkedDate || Date.now(),
    checkedPersonId: item.checkedPersonId || user.id,
    checkedPerson: {
      id: item.checkedPerson?.id || user.id,
      name: item.checkedPerson?.name || user.name,
      surname: item.checkedPerson?.surname || user.surname,
    },
  })

  const itemMapper = (checklistItem) => (item) => {
    if (item.id === checklistItem.id) {
      return {
        ...item,
        ...checklistItem,
        ...(checklistItem.isChecked ? withCheckProps(item) : {}),
      }
    }

    return item
  }

  const onMutate = ({ orderId, ...checklistItem }) => {
    const query = [ORDER_KEY, orderId]
    queryClient.cancelQueries(query)
    const previousOrder = cloneDeep(queryClient.getQueryData(query))
    const rollback = () => queryClient.setQueryData(query, previousOrder)
    updateCachedOrder({
      id: orderId,
      ...previousOrder,
      checklist: previousOrder.checklist.map((checklist) => {
        if (checklist.id === checklistItem.checklistId) {
          return {
            ...checklist,
            items: checklist?.items?.map(itemMapper(checklistItem)) || [],
          }
        }
        return checklist
      }),
    })
    return rollback
  }

  const onSuccess = (checklistItem, variables) => {
    enqueueSnackbar(
      checklistItem.isChecked
        ? 'Checklist item checked'
        : 'Checklist item unchecked',
      { variant: 'success' }
    )
    queryClient.invalidateQueries([ORDER_KEY, variables.orderId])
  }

  const onError = (error, _, rollback = noop) => {
    const message = getApiErrorMessage({
      error,
      defaultMessage: 'Checklist update failed',
    })
    enqueueSnackbar(message, { variant: 'error' })
    rollback()
  }

  return useMutation(putChecklistItem, {
    onMutate,
    onSuccess,
    onError,
    throwOnError: true,
  })
}
