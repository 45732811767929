import { merge } from 'lodash'
import {
  $O,
  $U,
  PERSON_CLIENT_TYPE,
  STAKEHOLDER_TYPE,
  ORDER_STATUS,
} from 'emsurge-selectors'
import {
  getValidity,
} from 'screens/orderEditCarbon/essentialEditValues'

const getStep1 = (order, user) => {
  const stepValues = {
    behalfOf: $O.stakeholder.type.get(order),
    details: '',
  }

  if ($O.status.get(order) === ORDER_STATUS.CLOSED && !order.thirdParty) {
    stepValues.validity = {}
    stepValues.status = ORDER_STATUS.PENDING
  } else {
    stepValues.validity = getValidity(order)
    stepValues.status = order.thirdParty ? ORDER_STATUS.LIVE : order.status
  }

  if (
    $O.stakeholder.type.get(order) === STAKEHOLDER_TYPE.ON_SYSTEM &&
    $U.clientType.get(user) === PERSON_CLIENT_TYPE.BROKER
  ) {
    stepValues.myCompany = {
      brokerEntityId: order.brokerId,
      // a trader's information
      companyId: order.companyId,
      entityId: order.entityId,
      traderId: order.personId,
    }

    stepValues.thirdParty = {
      companyName: '',
      entityName: '',
      contact: '',
    }
  } else {
    stepValues.myCompany = {}
    stepValues.thirdParty = order.thirdParty || {}
  }

  return stepValues
}

export const getInformationInitialValues = (order, user) => {
  
  const initialValues = merge(
    {},
    getStep1(order, user),
  )

  return initialValues
}
