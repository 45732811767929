import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, MenuItem, TextField } from '@material-ui/core'
import { find, values } from 'lodash'
import { PRODUCT_TO_PRICE_INDEX } from 'emsurge-selectors'
import { FieldArray } from 'formik'
import { Add } from '@material-ui/icons'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'

export const EvoFields = ({ fields, onChange }) => (
  <>
    <Grid item>
      <TextField
        select
        label="Type"
        InputLabelProps={{ shrink: true }}
        value={fields?.value?.type || ''}
        onChange={(evt) =>
          onChange({
            ...fields,
            name: `${evt.target?.value?.toUpperCase()} [Evo]`,
            value: {
              type: evt.target?.value,
            },
          })
        }
      >
        {values(PRODUCT_TO_PRICE_INDEX).map((index) => (
          <MenuItem key={`price-index-${index}`} value={index}>
            {index.toUpperCase()}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
    <Grid item>
      <FormConsumer>
        {({ values }) => (
          <FieldArray
            name="markets"
            render={({ push }) => (
              <Button
                disabled={
                  Boolean(
                    find(values?.markets, ({ name }) => name === fields.name)
                  ) || !fields.value?.type
                }
                onClick={() => push(fields)}
                startIcon={<Add />}
                color="secondary"
                variant="contained"
                size="small"
              >
                Add
              </Button>
            )}
          ></FieldArray>
        )}
      </FormConsumer>
    </Grid>
  </>
)

EvoFields.propTypes = {
  fields: PropTypes.object,
  onChange: PropTypes.func,
}
