import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { mapValues, isObject, omit, isNil } from 'lodash'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import {
  ORDER_STATUS,
  STAKEHOLDER_TYPE,
  VALIDITY_TYPE,
  APP_CONTEXT_TYPE,
} from 'emsurge-selectors'
import {
  NOMINATIONS_INITIAL_VALUES,
  getNominationsRules,
} from './NominationsInitialValues'
import { getInformationInitialValues } from './informationInitialValues'
import { validateOrder } from './validateOrder'
import { getSchema } from './FormSchema'
import { useUser } from 'containers/user/useUser'
import { DASHBOARD, ORDER_BASE_ROUTES } from 'routes'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { useCreateOrder } from 'api'

const omitEmpty = (obj, exceptions = []) =>
  mapValues(obj, (value, identity) => {
    if (Array.isArray(value)) {
      return value.length === 0 ? undefined : value
    }

    if (isObject(value)) {
      return omitEmpty(value)
    }

    const isInExceptions = exceptions.indexOf(identity) > -1
    const isNullOrEmpty = value === undefined || value === null || value === ''

    return !isInExceptions && isNullOrEmpty ? undefined : value
  })

const getPrice = (price) => {
  if (!price.enable) {
    return
  }

  const info = {
    type: price.type,
    info: price[`${price.type}Info`],
  }

  return info
}

const getCargoSize = (values) => {
  const { type, unit, sizeMin, sizeMax, varianceMinus, variancePlus, party } =
    values.cargoSize

  if (isNil(unit)) {
    return null
  }

  const cargoSize = {
    unit,
    type,
    varianceMinus,
    variancePlus,
    party,
  }

  switch (type) {
    case 'standard':
      return cargoSize
    case 'specific':
      return {
        ...cargoSize,
        sizeMin: parseFloat(sizeMin),
      }
    case '3.2':
    case '3.3':
    case '3.5':
      return {
        ...cargoSize,
        sizeMin: parseFloat(type),
      }
    case 'custom':
      return {
        ...cargoSize,
        sizeMin: parseFloat(sizeMin),
        sizeMax: parseFloat(sizeMax),
      }
    default:
      throw new Error(`Unknown cargo type ${type}`)
  }
}

export const getOrderInfo = (values, user) => {
  const orderInfo = {
    submittedAt: values.submittedAt || values.submissionDate,
    comments: values.template
      ? null
      : values.comments.map(({ personId, text }) => ({ personId, text })),
    status: values.status,
    tradingType: values.tradingType && values.tradingType.toLowerCase(),
    details: values.details,
    price: {
      basic: getPrice(values.price.basic),
      reserve: getPrice(values.price.reserve),
      contract: getPrice(values.price.contract),
    },
    volume: {
      metric: 'no_of_cargoes',
      min: values.volume.min,
      max: values.volume.type === 'range' ? values.volume.max : null,
    },
    cargoSize: getCargoSize(values),
    quality: {
      ...omit(values.quality, 'customMin', 'customMax'),
    },
    validityType: values.validity.until,
    nominationRules: getNominationsRules(values),
    template: values.template,
    orders: values.orders,
    context: APP_CONTEXT_TYPE.LNG,
  }

  if (values.quality.type === 'custom') {
    orderInfo.quality = {
      ...orderInfo.quality,
      customMin: values.quality.customMin,
      customMax: values.quality.customMax,
    }
  }

  /** DELIVERY */
  if (values.delivery.period === 'custom') {
    orderInfo.delivery = {
      ...omit(values.delivery, 'year'),
      type: values.delivery.type.toUpperCase(),
    }
  } else {
    orderInfo.delivery = {
      ...omit(values.delivery, 'customTo', 'customFrom'),
      type: values.delivery.type.toUpperCase(),
    }
  }

  /** VALIDITY */
  if (
    values.validity.until === VALIDITY_TYPE.INDICATIVE &&
    values.status !== ORDER_STATUS.PENDING
  ) {
    orderInfo.status = ORDER_STATUS.WITHHELD
  }
  if (values.validity.until === VALIDITY_TYPE.GOOD_TILL_DATE) {
    const { date, time } = values.validity
    orderInfo.validUntil = moment
      .utc(`${date}:${time}`, 'YYYY-MM-DD:HH:mm')
      .format()
  }

  if (values.behalfOf === STAKEHOLDER_TYPE.ON_SYSTEM) {
    orderInfo.personId = values.myCompany.traderId || user.id
    orderInfo.entityId = values.myCompany.entityId
  }

  if (values.behalfOf === STAKEHOLDER_TYPE.OFF_SYSTEM) {
    orderInfo.brokerId = values.myCompany.brokerEntityId
    orderInfo.thirdParty = values.thirdParty
  }

  orderInfo.brokerId = values.myCompany.brokerEntityId

  return omitEmpty(orderInfo, [
    'thirdParty',
    'companyId',
    'entityId',
    'details',
  ])
}

const getInitialValues = () => {
  return {
    ...getInformationInitialValues(),
    nominationRules: NOMINATIONS_INITIAL_VALUES,
  }
}

const FormProvider = ({ children, postSubmit, history }) => {
  const { user } = useUser()
  const BASE_SLUG = useRouteBaseSlug()
  const { mutateAsync: createOrder } = useCreateOrder()

  const handleSubmit = async (values, actions) => {
    const data = getOrderInfo(values, user)

    try {
      const order = await createOrder(data)
      postSubmit()
      const slug = ORDER_BASE_ROUTES.includes(`/${BASE_SLUG}`)
        ? `/${BASE_SLUG}`
        : DASHBOARD
      history.push(`${slug}/orders/${order.id}`)
    } catch {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getInitialValues(user)}
      validate={validateOrder}
      validationSchema={getSchema(user)}
    >
      {children}
    </Formik>
  )
}

FormProvider.defaultProps = {
  postSubmit: () => {},
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  postSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default compose(withSnackbar, withRouter)(FormProvider)
