import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import { Type } from './screens/Type'
import { TermVintage } from './screens/TermVintage'
import { Volume } from './screens/Volume'
import { Price } from './screens/Price'
import { AddButton } from 'screens/orderCreateCommon/components/AddButton'
import { General } from 'screens/orderCreateCommon/desktop/General'
import {
  STEP_CARBON,
  TAB_CARBON,
  useActiveStep,
} from 'containers/OrderProvider'
import VerticalStepper from 'screens/orderCreateCommon/components/VerticalStepper'
import StepLabel from 'screens/orderCreateCommon/components/StepLabel'
import StepActions, {
  validNextStep,
} from 'screens/orderCreateCommon/components/StepActions'

export const steps = [
  { title: 'General Information', component: General, number: 1 },
  { title: 'Type', component: Type, number: 2 },
  { title: 'Term & Vintage', component: TermVintage, number: 3 },
  { title: 'Volume', component: Volume, number: 4 },
  { title: 'Price', component: Price, number: 5 },
]

export const stepsNewTrade = [
  { title: 'Trade Information', component: General, number: 1 },
  { title: 'Type', component: Type, number: 2 },
  { title: 'Term & Vintage', component: TermVintage, number: 3 },
  { title: 'Volume', component: Volume, number: 4 },
  { title: 'Price', component: Price, number: 5 },
]

const getStepsForContext = (isCreatingTrade) => {
  if(isCreatingTrade){
    return stepsNewTrade
  }
  return steps
}

const EssentialFormStepper = ({ company, formik, isCreatingTrade, isEditTrade }) => {
  const {
    activeStep,
    navigate: { goTo, goBack, goForward },
  } = useActiveStep()

  const stepsWithContext = getStepsForContext(isCreatingTrade)
  return (
    <VerticalStepper activeStep={activeStep.step} nonLinear>
      {stepsWithContext.map(({ title, component: Content }, idx) => {
        return (
          <Step key={title}>
            <StepLabel onClick={() => goTo({ step: idx })}>{title}</StepLabel>

            <StepContent>
              <Content company={company} isCreatingTrade={isCreatingTrade} isEditTrade={isEditTrade}/>
              <StepActions
                totalSteps={stepsWithContext.length}
                activeStep={activeStep.step}
                onBack={goBack}
                onNext={() => validNextStep(formik, goForward)}
                completeButton={
                  <AddButton
                    data-testid="add-optional-button"
                    onClick={() =>
                      goTo({
                        tab: TAB_CARBON.OPTIONAL,
                        step: STEP_CARBON[TAB_CARBON.OPTIONAL].REGION,
                      })
                    }
                  >
                    Add Optional
                  </AddButton>
                }
              />
            </StepContent>
          </Step>
        )
      })}
    </VerticalStepper>
  )
}

EssentialFormStepper.propTypes = {
  formik: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
}

export default connect(EssentialFormStepper)
