import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { XsmallText, SmallText } from 'components/Text'
import { PERIOD } from 'model/order/constants/delivery'
import { $O } from 'model/order/selectors/selector'

export const PERIOD_LOOKUP = {
  [PERIOD.CAL_YEAR]: 'CAL-',
  [PERIOD.GAS_YEAR]: 'GY-',
  [PERIOD.SUMMER]: 'SUM-',
  [PERIOD.WINTER]: 'WIN-',
  [PERIOD.Q1]: 'Q1-',
  [PERIOD.Q2]: 'Q2-',
  [PERIOD.Q3]: 'Q3-',
  [PERIOD.Q4]: 'Q4-',
  [PERIOD.JAN]: 'JAN-',
  [PERIOD.FEB]: 'FEB-',
  [PERIOD.MAR]: 'MAR-',
  [PERIOD.APR]: 'APR-',
  [PERIOD.MAY]: 'MAY-',
  [PERIOD.JUN]: 'JUN-',
  [PERIOD.JUL]: 'JUL-',
  [PERIOD.AUG]: 'AUG-',
  [PERIOD.SEP]: 'SEP-',
  [PERIOD.OCT]: 'OCT-',
  [PERIOD.NOV]: 'NOV-',
  [PERIOD.DEC]: 'DEC-',
}

export const REVERSE_PERIOD_LOOKUP = {
  CAL: PERIOD.CAL_YEAR,
  GY: PERIOD.GAS_YEAR,
  SUM: PERIOD.SUMMER,
  WIN: PERIOD.WINTER,
  Q1: PERIOD.Q1,
  Q2: PERIOD.Q2,
  Q3: PERIOD.Q3,
  Q4: PERIOD.Q4,
  JAN: PERIOD.JAN,
  FEB: PERIOD.FEB,
  MAR: PERIOD.MAR,
  APR: PERIOD.APR,
  MAY: PERIOD.MAY,
  JUN: PERIOD.JUN,
  JUL: PERIOD.JUL,
  AUG: PERIOD.AUG,
  SEP: PERIOD.SEP,
  OCT: PERIOD.OCT,
  NOV: PERIOD.NOV,
  DEC: PERIOD.DEC,
}

/* :: object -> string */
export const getContractLabel = (order) => {
  const contract = $O.delivery.contract.get(order)
  if ($O.delivery.contract.isCustom(order)) {
    const [fromPeriod, toPeriod] = contract.map(({ month, year }) =>
      month < 10
        ? moment(`${year}-0${month}`, 'YYYY-MM').format('MMM-YY').toUpperCase()
        : moment(`${year}-${month}`, 'YYYY-MM').format('MMM-YY').toUpperCase()
    )

    return `${fromPeriod} - ${toPeriod}`
  }

  const period = PERIOD_LOOKUP[contract.period]
  const year = String(contract.year).slice(2)

  return `${period}${year}`
}

export const DeliveryContractSmall = ({ order, ...props }) => {
  if (!$O.delivery.contract.exists(order)) {
    return null
  }

  return <XsmallText {...props}>{getContractLabel(order)}</XsmallText>
}

DeliveryContractSmall.propTypes = {
  order: PropTypes.object.isRequired,
}

export const DeliveryContract = ({ order, ...props }) => {
  if (!$O.delivery.contract.exists(order)) {
    return null
  }

  return <SmallText {...props}>{getContractLabel(order)}</SmallText>
}

DeliveryContract.propTypes = {
  order: PropTypes.object.isRequired,
}
