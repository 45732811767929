import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const getBreakpoint = ({ matchSmaller, matchLarger, breakpoints }) => {
  if (matchSmaller) {
    return breakpoints.down('md')
  }
  if (matchLarger) {
    return breakpoints.up('md')
  }
  return breakpoints.allow('md')
}

export const Laptop = ({ children, matchSmaller, matchLarger }) => {
  const { breakpoints } = useTheme()
  const isLaptop = useMediaQuery(
    getBreakpoint({ matchSmaller, matchLarger, breakpoints })
  )
  return isLaptop ? children : null
}

Laptop.defaultProps = {
  children: [],
  matchSmaller: false,
  matchLarger: false,
}

Laptop.propTypes = {
  children: PropTypes.node,
}
