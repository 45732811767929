import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { capitalize, cloneDeep, isEmpty, isNumber } from 'lodash'
import { Grid, Popper, Typography } from '@material-ui/core'
import { typography, spacing } from '@material-ui/system'
import { MoreVert } from '@material-ui/icons'
import { $O } from 'emsurge-selectors'
import {
  MARKET_GRID_COLUMNS,
  MARKET_GRID_ACTUAL_IMPLIED,
  MARKET_GRID_MARKET_PROVIDER,
  TRADING_TYPE,
  PRICE_TYPE,
  PRICE_PLUS_MINUS,
} from 'emsurge-selectors/constants'
import { EvoCard } from './EvoCard'
import { GridContext } from 'screens/marketGridIndex/GridProvider'
import { useOrder } from 'api'
import OrderCard from 'screens/orderIndex/components/OrderCard'
import { useImpliedPrice, useCalculateFixedPrice } from 'hooks'
import { buildOptions, getMainIndexSlug } from 'hooks/useImpliedPrice.helpers'
import { FORMAT_PRICE, OTHERS_INDEXES } from 'hooks/useImpliedPrice.constants'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'

const COLUMN_WIDTH = 300

const Text = styled(Typography)`
  ${spacing}
  ${typography}
`

export const MatrixGrid = styled.div`
  overflow-x: scroll;
  display: grid;
  gap: 2px;
  grid-template-columns: ${({ columns }) =>
    `${COLUMN_WIDTH / 2}px repeat(${columns}, ${COLUMN_WIDTH}px)`};
`

const DataColumn = styled.div`
  display: inline-grid;
  gap: 2px;
`

const MainCell = styled.div`
  ${spacing};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`

const SecondaryCell = styled.div`
  ${spacing};
  background-color: ${({ theme }) => theme.palette.background.secondary};
  height: 25px;
`

const NoContentCell = styled.div`
  ${spacing};
  background-color: ${({ theme }) => theme.palette.background.secondary};
  height: 25px;
  opacity: 0.2;
`

const PaperCellDepth = styled.div`
  ${spacing};
  background-color: #101823;
  height: 25px;
  border-bottom: 1px solid #ffffff10;
`

const PaperCell = styled.div`
  ${spacing};
  background-color: ${({ theme }) => theme.palette.background.paper};
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
`

const PeriodColumn = styled.div`
  display: inline-grid;
  gap: 2px;
  grid-column-start: 1;
`

const MarketContainer = styled.div`
  display: grid;
  gap: 2px;
  grid-auto-flow: column;
  grid-auto-columns: ${COLUMN_WIDTH}px;
`

const MarketPeriodContainer = styled.div`
  display: inline-grid;
  gap: 2px;
  grid-auto-flow: column;
  text-align: center;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));

  ${DataColumn}:first-child {
    text-align: right;
  }

  ${DataColumn}:last-child {
    text-align: left;
  }
}
`

const EmptyChar = () => <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />

const EmptyCell = () => (
  <PaperCell py={0.25} px={1}>
    <Text variant="subtitle1">
      <EmptyChar />
    </Text>
  </PaperCell>
)

const getOrderIndex = (order) => {
  const price = $O.price.get(order)
  return getMainIndexSlug(price?.index || '')
}

const ExpandWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Expand = (props) => (
  <ExpandWrapper>
    <MoreVert fontSize={'small'} onClick={props.onClick} />
  </ExpandWrapper>
)

const GridExpand = ({ expand, renderByType, order }) => {
  const { depth, setDepth } = useContext(GridContext)

  const onClick = (order) => {
    const period = $O.summary(order).deliveryPeriod
    const newDepth = { [period]: !depth[period] }
    setDepth({ ...depth, ...newDepth })
  }

  if (renderByType) {
    if (expand) {
      return (
        <Grid item xs={1}>
          <Expand onClick={() => onClick(order)} />
        </Grid>
      )
    }
    return <Grid item xs={1} />
  }
  return <></>
}

GridExpand.propTypes = {
  expand: PropTypes.PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  renderByType: PropTypes.bool,
  order: PropTypes.object,
}

export const CellEmsurge = ({ id, showImpliedPrice, expand, depthCell }) => {
  const { data: order, isLoading } = useOrder(id)
  const orderIndex = getOrderIndex(order)
  const impliedPrices = useImpliedPrice(
    order,
    showImpliedPrice && orderIndex ? [orderIndex] : [],
    buildOptions({
      [FORMAT_PRICE]: false,
      [OTHERS_INDEXES]: false,
    })
  )
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const isUnavailable = isLoading || !order

  const Wrapper = depthCell ? PaperCellDepth : PaperCell
  const orderIsBid = order.tradingType === TRADING_TYPE.BID
  const orderIsAsk = order.tradingType === TRADING_TYPE.ASK

  return isUnavailable ? (
    <EmptyCell />
  ) : (
    <>
      <Wrapper
        ref={ref}
        py={0.25}
        px={1}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <Grid container justifyContent="space-between">
          <GridExpand expand={expand} renderByType={orderIsBid} order={order} />
          <Grid item xs={11}>
            <Text variant="subtitle1" noWrap style={{ paddingLeft: '5px' }}>
              {isEmpty(impliedPrices)
                ? $O.price.basic.summary(order).shorter
                : impliedPrices[0]}
            </Text>
          </Grid>
          <GridExpand expand={expand} renderByType={orderIsAsk} order={order} />
        </Grid>
      </Wrapper>
      <Popper open={open} anchorEl={ref.current} style={{ marginTop: '20px' }}>
        <OrderCard
          order={order}
          style={{ background: '#161d29' }}
          toggle={false}
        />
      </Popper>
    </>
  )
}

CellEmsurge.propTypes = {
  id: PropTypes.string,
  showImpliedPrice: PropTypes.bool,
  expand: PropTypes.PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  depthCell: PropTypes.bool,
}

const CellEvolution = (value) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const Wrapper = value.depthCell ? PaperCellDepth : PaperCell

  return (
    <>
      <Wrapper
        ref={ref}
        py={0.25}
        px={1}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <Text variant="subtitle1">{value.price ?? <EmptyChar />}</Text>
      </Wrapper>
      {isNumber(value.price) && value.label !== MARKET_GRID_COLUMNS.MID && (
        <Popper
          open={open}
          anchorEl={ref.current}
          style={{ marginTop: '20px' }}
        >
          <EvoCard {...value} />
        </Popper>
      )}
    </>
  )
}

CellEvolution.propTypes = {
  price: PropTypes.number,
  depthCell: PropTypes.bool,
}

export const Cell = (props) => {
  if (props.type === MARKET_GRID_MARKET_PROVIDER.EMSURGE && props.id) {
    return <CellEmsurge {...props} />
  }
  if (props.type === MARKET_GRID_MARKET_PROVIDER.CT_GRID) {
    return <CellEvolution {...props} />
  }
  return props.depthCell ? <PaperCellDepth /> : <EmptyCell />
}

Cell.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  showImpliedPrice: PropTypes.bool,
  expand: PropTypes.PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  depthCell: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.node,
}

const getSortPrice = (order) => {
  const price = $O.price.get(order) || {}
  const priceType = $O.price.type(order)
  const priceTypeSort = {
    [PRICE_TYPE.FIXED]: price.value >= 0 ? price.value : 0,
    [PRICE_TYPE.FLOATING]: price.fixedPrice || price.amount || -1,
    [PRICE_TYPE.HYBRID]: -2,
    [PRICE_TYPE.RFQ]: -3,
    [PRICE_TYPE.PREFERENCE]: -4,
    [PRICE_TYPE.TBD]: -5,
  }
  const amount = priceTypeSort[priceType] || 0
  if (priceType === PRICE_TYPE.FLOATING) {
    return Math.max(convertPriceToImplied(price), 0)
  }
  return amount
}

const sum = (a, b) => a + b
const sub = (a, b) => a - b

const convertPriceToImplied = ({
  percentage = 100,
  indexAmount = 0,
  plusOrMinus = PRICE_PLUS_MINUS.PLUS,
  amount = 0,
}) => {
  const operation = plusOrMinus === PRICE_PLUS_MINUS.PLUS ? sum : sub
  return operation((percentage / 100) * indexAmount, amount)
}

const byImpliedPrice = (firstOrder, secondOrder) => {
  const { tradingType: firstTradingType } = firstOrder
  const { tradingType: secondTradingType } = secondOrder
  const firstPrice = getSortPrice(firstOrder)
  const secondPrice = getSortPrice(secondOrder)

  const sortResult =
    firstTradingType === secondTradingType &&
    (firstTradingType === TRADING_TYPE.BID ||
      (firstTradingType === TRADING_TYPE.ASK &&
        (firstPrice < 0 || secondPrice < 0)))
      ? firstPrice <= secondPrice
      : firstPrice >= secondPrice
  return sortResult ? 1 : -1
}

const sortOrders = (orders) => {
  const fixedOrders = []
  const otherOrders = []
  orders.forEach((order) => {
    const priceType = $O.price.type(order)
    if (priceType === PRICE_TYPE.FIXED) {
      const price = $O.price.get(order) || {}
      const matchOrders = price.value >= 0 ? fixedOrders : otherOrders
      return matchOrders.push(order)
    }
    if (priceType === PRICE_TYPE.FLOATING) {
      const price = $O.price.get(order) || {}
      const matchOrders = price.indexAmount ? fixedOrders : otherOrders
      return matchOrders.push(order)
    }
    otherOrders.push(order)
  })
  return [
    ...fixedOrders.sort(byImpliedPrice),
    ...otherOrders.sort(byImpliedPrice),
  ]
}

export const MarketColumnPeriod = ({
  label,
  periodMarketOrders,
  index,
  type,
  showImpliedPrice,
  marketDepth,
  period,
}) => {
  const { depth } = useContext(GridContext)
  const [calculatedOrders, loadingCalculate] =
    useCalculateFixedPrice(periodMarketOrders)
  const [orders, setOrders] = useState([])
  const [expand, setExpand] = useState(false)

  useEffect(() => {
    const sortedOrders = sortOrders(calculatedOrders)
    const [firstOrder, ...remainingOrders] = sortedOrders
    const expand = remainingOrders && remainingOrders.length
    let orders = [firstOrder]
    if (period && depth[period]) {
      const length = Math.max(0, marketDepth[period] - sortedOrders.length)
      const emptyCells = new Array(length).fill({})
      orders = sortedOrders.concat(emptyCells).slice(0, marketDepth[period])
    }
    setExpand(expand)
    setOrders(orders)
  }, [calculatedOrders, periodMarketOrders, depth])

  if (loadingCalculate) {
    return <></>
  }

  return orders.map((order, idx) => (
    <Cell
      key={`cell-${index}-${idx}`}
      label={label}
      showImpliedPrice={showImpliedPrice}
      expand={expand && idx === 0}
      depthCell={idx > 0}
      {...order}
      type={type}
    />
  ))
}

MarketColumnPeriod.propTypes = {
  label: PropTypes.string,
  periodMarketOrders: PropTypes.array,
  index: PropTypes.number,
  type: PropTypes.string,
  showImpliedPrice: PropTypes.bool,
  contracts: PropTypes.array,
  marketDepth: PropTypes.object,
  period: PropTypes.string,
}

// Single column for a market in a period (e.g, "bid" for "Months")
export const MarketColumn = ({ label, values, type, ...props }) => {
  return (
    <DataColumn>
      <SecondaryCell px={1}>
        <Text variant="caption" fontWeight={500}>
          {capitalize(label)}
        </Text>
      </SecondaryCell>
      {values.map((periodMarketOrders, index) => (
        <MarketColumnPeriod
          key={`mkt-column-period-${type}-${index}`}
          periodMarketOrders={periodMarketOrders}
          index={index}
          label={label}
          type={type}
          period={props.contracts[index]}
          {...props}
        />
      ))}
    </DataColumn>
  )
}

MarketColumn.propTypes = {
  label: PropTypes.string,
  values: PropTypes.array,
  type: PropTypes.string,
  contracts: PropTypes.array,
  marketDepth: PropTypes.object,
  showImpliedPrice: PropTypes.bool,
}

// All columns for a market in a period (e.g, "bid", "ask" for "Months")
export const MarketColumns = ({ type, columns, contracts, marketDepth }) => {
  const { filters } = useContext(GridContext)
  const filtered = columns.filter((col) => filters.includes(col.label))
  const showImpliedPrice = filters.includes(MARKET_GRID_ACTUAL_IMPLIED.IMPLIED)
  return (
    <MarketPeriodContainer>
      {filtered.map((props, i) => (
        <MarketColumn
          key={`mkt-column-${type}-${i}`}
          type={type}
          showImpliedPrice={showImpliedPrice}
          contracts={contracts}
          marketDepth={marketDepth}
          {...props}
        />
      ))}
    </MarketPeriodContainer>
  )
}

MarketColumns.propTypes = {
  columns: PropTypes.array,
  type: PropTypes.string,
  contracts: PropTypes.array,
  marketDepth: PropTypes.object,
}

const insert = (a1, a2, i) => {
  return [...a1.slice(0, i), ...a2, ...a1.slice(i)]
}

export const PeriodBlock = ({ period, contracts, markets, marketDepth }) => {
  const { depth } = useContext(GridContext)
  const [composedContracts, setComposedContracts] = useState(contracts)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let fullContracts = cloneDeep(contracts)
    Object.keys(depth).forEach((depthIndex) => {
      if (depth[depthIndex]) {
        const indexPosition = fullContracts.findIndex(
          (contractIndex) => contractIndex === depthIndex
        )
        if (indexPosition >= 0) {
          const rowsByPeriod = marketDepth[depthIndex] || 1
          const length = Math.max(0, rowsByPeriod - 1)
          const emptyCells = new Array(length).fill('')
          fullContracts = insert(fullContracts, emptyCells, indexPosition + 1)
        }
      }
    })
    setComposedContracts(fullContracts)
    setLoading(false)
  }, [contracts, depth, marketDepth])

  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <>
      <PeriodColumn>
        <MainCell px={1}>
          <Text variant="caption">{period}</Text>
        </MainCell>
        {composedContracts &&
          composedContracts.map((value, i) => {
            const key = `${period}-${value || i}`
            return value ? (
              <SecondaryCell px={1} py={0.25} key={key}>
                <Text variant="subtitle1">{value}</Text>
              </SecondaryCell>
            ) : (
              <NoContentCell key={key}>
                <Text variant="subtitle1">{value}</Text>
              </NoContentCell>
            )
          })}
      </PeriodColumn>
      <MarketContainer>
        {markets.map((marketProps, i) => (
          <MarketColumns
            key={`mkt-columns-${period}-${marketProps.label}-${i}`}
            contracts={contracts}
            marketDepth={marketDepth}
            {...marketProps}
          />
        ))}
      </MarketContainer>
    </>
  )
}

PeriodBlock.propTypes = {
  period: PropTypes.string,
  contracts: PropTypes.array,
  markets: PropTypes.array,
  marketDepth: PropTypes.object,
}

// Headline row with market names
export const MarketLabelRow = ({ grid }) => (
  <>
    <MainCell />
    {grid?.markets?.map((market, i) => (
      <SecondaryCell py={0.5} px={1} key={`market-${i}`}>
        <Text align="center" component="p" variant="subtitle1">
          {market}
        </Text>
      </SecondaryCell>
    ))}
  </>
)

MarketLabelRow.propTypes = {
  grid: PropTypes.shape({
    markets: PropTypes.array,
  }),
}
