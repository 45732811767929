import { useQuery } from 'react-query'
import { useApi } from 'containers/api/useApi'
import { ENTITY_KEY } from 'api/constants'

export const useEntityBrokers = (entityId) => {
  const api = useApi()

  return useQuery([ENTITY_KEY, entityId], () => api.getEntityBrokers(entityId), {
    enabled: Boolean(entityId),
    staleTime: Infinity
  })
}
