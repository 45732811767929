import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import { get } from 'lodash'
import {
  VOLUME_OFFSET_PREFIX,
  VOLUME_OFFSET_OPTIONS,
  VOLUME_OFFSET,
  VOLUME_AMOUNT_PREFIX,
  VOLUME_MIN_PARCEL_SIZE_PREFIX,
} from 'emsurge-selectors'
import { getDefaultVolumeInputNumber } from '../helpers'
import { CheckboxGroup } from './CheckboxGroup'
import { VolumeInput } from './VolumeInput'

const _VolumeOffset = ({ formik: { values, setFieldValue } }) => {
  const [showMinParcelSize, setShowMinParcelSize] = useState(false)

  const computeMinParcelSize = () => {
    const volume = get(values, VOLUME_AMOUNT_PREFIX) || 0
    const minParcelSize = getDefaultVolumeInputNumber(volume)
    setFieldValue(VOLUME_MIN_PARCEL_SIZE_PREFIX, minParcelSize)
  }

  useEffect(() => {
    const value = get(
      values,
      `${VOLUME_OFFSET_PREFIX}.${VOLUME_OFFSET.ALL_OR_NOTHING}`
    )
    setShowMinParcelSize(!value)
    const isEdit = get(
      values,
      'isEdit'
    )
    if( !isEdit ) {
      computeMinParcelSize()
    }
  }, [values.volume.offset])

  return (
    <>
      <CheckboxGroup
        fieldPrefix={VOLUME_OFFSET_PREFIX}
        label="Minimum Quantity"
        options={VOLUME_OFFSET_OPTIONS}
      />
      {showMinParcelSize && (
        <VolumeInput
          name={VOLUME_MIN_PARCEL_SIZE_PREFIX}
          label="Min Parcel Size"
          unit="unit"
        />
      )}
    </>
  )
}

export const VolumeOffset = connect(_VolumeOffset)
