import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import * as d3 from 'd3'
import {
  $T,
  $O,
  PRICE_TYPE,
  PRICE_UNIT_TEXT,
  PRICE_UNIT,
} from 'emsurge-selectors'
import { find, isNumber } from 'lodash'
import { compose, map, flatten } from 'lodash/fp'
import { Box, useTheme, Typography as Text } from '@material-ui/core'
import { useHistory } from 'react-router'
import useResizeObserver from 'use-resize-observer'
import { isNaN } from 'formik'
import { useUser } from 'containers/user/useUser'
import { getItemStyleProps } from 'utils/charts'
import OrderCard from 'screens/orderIndex/components/OrderCard'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'

const Container = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

const Tooltip = styled.div`
  position: absolute;
`

const startOfRange = moment().startOf('month')
const endOfRange = moment().startOf('month').add(1, 'year')

const CHART_TYPE = {
  AMOUNT: 'AMOUNT',
  PRICE: 'PRICE',
}

export const CargoChart = ({ orders, chartType = CHART_TYPE.AMOUNT }) => {
  const theme = useTheme()
  const history = useHistory()
  const { user } = useUser()
  const containerRef = useRef()
  const tooltipRef = useRef()
  const [tooltipOrder, setTooltipOrder] = useState(null)
  const BASE_SLUG = useRouteBaseSlug()
  const { width, height } = useResizeObserver({ ref: containerRef })
  const labelSize = theme.typography.subtitle1.fontSize
  const gridColor = theme.palette.background.secondary

  const getTooltip = () => d3.select(tooltipRef.current)
  const getContainer = () => d3.select(containerRef.current)

  const formatYMargin = useCallback(
    () => (chartType === CHART_TYPE.PRICE ? 100 : 40),
    [chartType]
  )
  const formatYAxis = useCallback(
    (price) => {
      const amount = (Math.round(price * 100) / 100).toFixed(2)
      if (chartType === CHART_TYPE.PRICE) {
        return PRICE_UNIT_TEXT[PRICE_UNIT.MMBTU](amount)
      }
      return amount
    },
    [chartType]
  )

  const data = useMemo(
    () =>
      compose([
        flatten,
        map((order) => {
          const cargoes = $T.deliveryWindow.cargos.get(order.nominationRules)
          const price = $O.price.get(order)
          const style = getItemStyleProps(order, user, theme.palette)

          return cargoes
            .map(({ from, to }) => ({
              id: order.id,
              price,
              from,
              to: moment(to).add(to.getTimezoneOffset(), 'minutes').toDate(),
              style,
            }))
            .filter(({ price, from, to }) => {
              return (
                price.amount !== 'X' && from <= endOfRange && to >= startOfRange
              )
            })
            .map(({ price, ...rest }) => {
              const calculatedPrice =
                $O.price.type(order) === PRICE_TYPE.FLOATING
                  ? isNumber(price.fixedPrice)
                    ? Math.max(0, price.fixedPrice)
                    : parseFloat(`${price.plusOrMinus}${price.amount || 0}`)
                  : price.value
              return {
                ...rest,
                price: parseFloat(calculatedPrice),
              }
            })
            .filter(({ price }) => !isNaN(price))
        }),
      ])(orders),
    [orders, theme.palette, user]
  )

  useEffect(() => {
    if (!data.length) {
      return
    }

    const chart = getContainer()
    const svg = chart.append('svg').style('flex', 1)
    const CARGO_HEIGHT = 5
    const width = parseInt(chart.style('width'), 10)
    const height = parseInt(chart.style('height'), 10)
    const margin = { top: 25, right: 20, bottom: 35, left: formatYMargin() }

    // Define and draw time axis (XX)
    const x = d3
      .scaleTime()
      .domain([startOfRange, endOfRange])
      .nice()
      .range([margin.left + 30, width - margin.right])
      .clamp(true)

    const xAxis = (g) =>
      g
        .attr('transform', `translate(0,${height - margin.bottom + 10})`)
        .style('font-size', labelSize)
        .style('font-weight', 900)
        .call(
          d3
            .axisBottom(x)
            .tickSize(0)
            .tickFormat((d) => moment(d).format('MMM-YY').toUpperCase())
        )
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g.selectAll('text').style('transform', 'translate(calc(100%/24),0)')
        )

    // Define and draw price axis (YY)
    const y = d3
      .scaleLinear()
      .domain(d3.extent(data, (d) => d.price))
      .nice()
      .range([height - margin.bottom - 20, margin.top + 20])

    const yAxis = (g) =>
      g
        .attr('transform', `translate(${margin.left + 20},-12)`)
        .style('font-size', labelSize)
        .call(d3.axisLeft(y).tickSize(0).tickFormat(formatYAxis))
        .call((g) => g.select('.domain').remove())

    // Draw grid
    const grid = (g) =>
      g
        .call((g) =>
          g
            .append('g')
            .selectAll('line')
            .data(x.ticks())
            .join('line')
            .attr('stroke', gridColor)
            .attr('stroke-width', 0.7)
            .attr('x1', (d) => 0.5 + x(d))
            .attr('x2', (d) => 0.5 + x(d))
            .attr('y1', margin.top)
            .attr('y2', height - margin.bottom)
        )
        .call((g) =>
          g
            .append('g')
            .selectAll('line')
            .data(y.ticks())
            .join('line')
            .attr('stroke', (d) => (d === 0 ? '#FFFFFF' : gridColor))
            .attr('stroke-width', (d) => (d === 0 ? 1 : 0.7))
            .attr('y1', (d) => 0.5 + y(d))
            .attr('y2', (d) => 0.5 + y(d))
            .attr('x1', margin.left)
            .attr('x2', width - margin.right)
        )

    svg.append('g').call(xAxis)

    svg.append('g').call(yAxis)

    svg.append('g').call(grid)

    // Draw cargoes
    svg
      .append('g')
      .selectAll('rect')
      .data(data)
      .join('rect')
      .attr('data-testid', (d, i) => `cargo-${i}-${d.id}`)
      .attr('stroke', (d) => d.style.color)
      .attr('stroke-width', 1)
      .attr('fill', (d) => d.style.backgroundColor)
      .attr('x', (d) => x(d.from))
      .attr('y', (d) => y(d.price) - CARGO_HEIGHT / 3)
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('price', (d) => d.price)
      .attr('width', (d) => x(d.to) - x(d.from) + 1)
      .attr('height', CARGO_HEIGHT)
      .style('cursor', 'pointer')
      .on('click', (event, d) => {
        const order = find(orders, ({ id }) => id === d.id)
        history.push(`/${BASE_SLUG}/orders/${order.id}`)
      })
      .on('mousemove', (event, d) => {
        const order = find(orders, ({ id }) => id === d.id)
        setTooltipOrder(order)
        const tooltip = getTooltip()
        const tooltipWidth = tooltipRef?.current?.clientWidth
        const offsetLeft = containerRef.current.getBoundingClientRect().left
        const left = Math.min(event.pageX - offsetLeft, width - tooltipWidth)
        tooltip
          .style('opacity', 1)
          .style('left', left + 'px')
          .style('top', event.pageY - 60 + 'px')
      })
      .on('mouseout', () => {
        const tooltip = getTooltip()
        tooltip.style('opacity', 0)
        setTooltipOrder(null)
      })

    return () => svg.remove()
  }, [
    data,
    gridColor,
    history,
    labelSize,
    orders,
    width,
    height,
    BASE_SLUG,
    formatYAxis,
    formatYMargin,
  ])

  return (
    <>
      {data.length > 0 ? (
        <Container ref={containerRef}>
          <Tooltip ref={tooltipRef}>
            {tooltipOrder && <OrderCard order={tooltipOrder} toggle={false} />}
          </Tooltip>
        </Container>
      ) : (
        <Box display="flex" justifyContent="center" py={8}>
          <Text variant="h6">No orders</Text>
        </Box>
      )}
    </>
  )
}

CargoChart.defaultProps = {
  orders: [],
}

CargoChart.propTypes = {
  orders: PropTypes.array,
  chartType: PropTypes.oneOf(Object.values(CHART_TYPE)),
}
