import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { string, object, array, number } from 'yup'
import { useEditGrid } from 'api'

const format = (values) => ({
  ...values,
  markets: values?.markets.map((market, index) => ({ ...market, index })),
  periods: values?.periods.map((period, index) => ({ ...period, index })),
})

const getInitialValues = ({ ...data }) => {
  return {
    ...data,
    markets: data?.markets?.map(
      ({ ...props }) => props
    ),
    periods: data?.periods?.map(
      ({ ...props }) => props
    ),
  }
}

export const FormSchema = object().shape({
  name: string().required('Required'),
  periods: array().of(
    object({
      value: number().min(1, ' ').max(32, ' ').typeError(' '),
    })
  ),
  markets: array().min(1),
})

export const FormProvider = ({ onSuccess, children, data }) => {
  const { mutateAsync: editGrid } = useEditGrid()

  const handleSubmit = (values, actions) => {
    const data = format(values)
    editGrid(data)
    actions.setSubmitting(false)
    onSuccess()
  }

  return (
    <Formik
      initialValues={getInitialValues(data)}
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
    >
      {children}
    </Formik>
  )
}

FormProvider.propTypes = {
  onSuccess: PropTypes.func,
  children: PropTypes.node,
  data: PropTypes.object,
}
