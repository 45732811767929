import React from 'react'
import { connect } from 'formik'
import { Grid } from '@material-ui/core'
import { AdditionalAttributesOptions } from '../components/AdditionalAttributesOptions'
import { AdditionalAttributesSDGs } from '../components/AdditionalAttributesSDGs'
import { Rating } from '../components'

const _AdditionalAttributes = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AdditionalAttributesOptions />
      </Grid>
      <Grid item xs={12}>
        <AdditionalAttributesSDGs />
      </Grid>
      <Grid item xs={12}>
        <Rating key="rating" />
      </Grid>
    </Grid>
  )
}

export const AdditionalAttributes = connect(_AdditionalAttributes)
