import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'

import { $U } from 'emsurge-selectors/models'
import { Comments } from 'components/Comments'
import { useUser } from 'containers/user/useUser'

const CommentsPanel = ({ formik: { values, setFieldValue }, label }) => {
  const { user } = useUser()

  const addComment = (newComment) => {
    const { comments } = values
    const comment = {
      text: newComment,
      author: [$U.fullName.get(user)],
      createdAt: new Date(),
    }
    setFieldValue('comments', [...comments, comment])
  }

  return (
    <Comments
      comments={values.comments}
      label={label}
      addComment={addComment}
    />
  )
}

CommentsPanel.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
}

export default connect(CommentsPanel)
