import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Form, Formik } from 'formik'
import { withTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import { compose } from 'recompose'
import InputAdornment from '@material-ui/core/InputAdornment'
import DetailRow from '../../orderView/components/DetailRow'
import { handleSave } from '../containers/TradedNominations'
import { getNominationTimezone, getVesselType, getPartyLabel } from '../helpers'
import OffsetQuarterCard from './OffsetQuarterCard'
import { useApi } from 'containers/api/useApi'
import SumbitButton from 'containers/formikSubmitButton/FormikSubmitButton'
import Textfield from 'containers/formikTextField/FormikTextField'

const enhance = compose(withSnackbar, withTheme)

const M3 = () => (
  <InputAdornment position="start">
    <span>m&sup3;</span>
  </InputAdornment>
)

const VesselForm = enhance(({ theme, nomination, enqueueSnackbar }) => {
  const api = useApi()
  const INITIAL_VALUES = {
    size: {
      min: get(nomination, 'size.min', ''),
      max: get(nomination, 'size.max', ''),
    },
  }

  const vesselType = getVesselType(nomination.vesselType)

  /* :: (object, object) -> Promise<void> */
  const handleSubmit = (values, actions) =>
    handleSave(api, nomination.id, values, actions, enqueueSnackbar)

  const fieldProps = {
    fullWidth: true,
    margin: 'normal',
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={INITIAL_VALUES}>
      <Form>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={5}>
            <Textfield
              name="size.min"
              label={`Min ${vesselType}`}
              {...fieldProps}
              InputProps={{
                endAdornment: <M3 />,
              }}
            />
          </Grid>

          <Grid item xs={5}>
            <Textfield
              name="size.max"
              label={`Max ${vesselType}`}
              {...fieldProps}
              InputProps={{
                endAdornment: <M3 />,
              }}
            />
          </Grid>

          <Grid item xs>
            <SumbitButton
              fullWidth
              size="small"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Save
            </SumbitButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
})

VesselForm.propTypes = {
  nomination: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func,
}

const ShipNominations = ({ nomination, orderCargoTimeZoneMap, entityCode }) => {
  const nominateUntil = getNominationTimezone(nomination, orderCargoTimeZoneMap)
  return (
    <>
      <DetailRow
        gutterBottom
        title={`${nomination.alternative ? 'Alternative Vessel' : 'Vessel'}`}
        info={[
          `${getPartyLabel(nomination.party)}`,
          moment(nominateUntil).format('DD/MM/YYYY HH:mm'),
          entityCode,
        ]}
      />

      <OffsetQuarterCard>
        <VesselForm nomination={nomination} />
      </OffsetQuarterCard>
    </>
  )
}

ShipNominations.propTypes = {
  nomination: PropTypes.object.isRequired,
  orderCargoTimeZoneMap: PropTypes.object.isRequired,
  entityCode: PropTypes.string.isRequired,
}

export default ShipNominations
