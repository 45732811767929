import { isEmpty, get } from 'lodash'
import { stakeholder } from './stakeholder'

const isBrokerOrder = (order) => !!order.brokerId

const isAuthorFromMyCompany = (order, user) => {
  /**
   @NOTE: Off system orders can only be viewed within company so it must be created
   by user from same company
   */
  if (stakeholder.isOffSystem(order)) {
    return true
  }

  const orderCompanyId = get(order, 'company.id', order.companyId)

  return user.companyId === orderCompanyId
}

const hasOrderAuthor = (order) => {
  const author = get(order, 'person')

  return !isEmpty(author)
}

const getOrderAuthor = (order) => {
  if (!hasOrderAuthor(order)) {
    throw new Error('Order has no author. Use order.author.exists()')
  }

  return order.person
}

export const author = {
  exists: hasOrderAuthor,
  get: getOrderAuthor,
  isBroker: isBrokerOrder,
  isFromMyCompany: isAuthorFromMyCompany,
}
