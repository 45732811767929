import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import Grid from '@material-ui/core/Grid'
import { DeferredTextField } from 'screens/orderCreateCommon/components/TextField'

const _SelectCustomVintageDate = ({
  formik: { setFieldValue },
  xs,
  fieldName,
}) => {
  const CUSTOM_FROM_PATH = `${fieldName}.customFrom`
  const CUSTOM_TO_PATH = `${fieldName}.customTo`

  const onChange = (value, path) => {
    setFieldValue(path, value)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={xs}>
          <DeferredTextField
            type="month"
            label="From"
            name={CUSTOM_FROM_PATH}
            onChange={(evt) => onChange(evt.target.value, CUSTOM_FROM_PATH)}
            data-testid="select-custom-vintage-date-from"
          />
        </Grid>
        <Grid item xs={xs}>
          <DeferredTextField
            type="month"
            label="To"
            name={CUSTOM_TO_PATH}
            onChange={(evt) => onChange(evt.target.value, CUSTOM_TO_PATH)}
            data-testid="select-custom-vintage-date-to"
          />
        </Grid>
      </Grid>
    </>
  )
}

_SelectCustomVintageDate.defaultProps = {
  xs: true,
}

_SelectCustomVintageDate.propTypes = {
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  fieldName: PropTypes.string.isRequired,
}

export const SelectCustomVintageDate = connect(_SelectCustomVintageDate)
