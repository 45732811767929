import moment from 'moment'

import { noop, startsWith, some } from 'lodash'
import { getWinterMonths } from 'screens/orderCreate/components/DeliveryWindow.helpers'
import { PERIOD } from 'model/order/constants/delivery'
import { PERIOD as VISUALISATION_PERIOD } from 'screens/marketVisualisation/MarketVisualisation.constants'

export const typeMap = {
  [VISUALISATION_PERIOD.MONTHS]: {
    name: 'Months',
    getPrevious: (period) =>
      moment(period, 'MMM-YY')
        .subtract(1, 'months')
        .format('MMM-YY')
        .toUpperCase(),
    getNext: (period) =>
      moment(period, 'MMM-YY').add(1, 'months').format('MMM-YY').toUpperCase(),
    keyGenerator: (date) => moment(date).format('MMM-YY').toUpperCase(),
    getStartDate: (period) => moment(period, 'MMM-YY'),
    getWeightOfMarkOfPeriod: (n) => [n, 'M'],
    periodValues: [
      PERIOD.JAN,
      PERIOD.FEB,
      PERIOD.MAR,
      PERIOD.APR,
      PERIOD.MAY,
      PERIOD.JUN,
      PERIOD.JUL,
      PERIOD.AUG,
      PERIOD.SEP,
      PERIOD.OCT,
      PERIOD.NOV,
      PERIOD.DEC,
    ],
    isMatch: function (value = '') {
      return some(this.periodValues, (period) =>
        startsWith(value.toUpperCase(), period.substr(0, 3).toUpperCase())
      )
    },
  },
  [VISUALISATION_PERIOD.QUARTERS]: {
    name: 'Quarters',
    getPrevious: (period) => {
      const quarter = moment(period, '?Q-YY')
        .subtract(1, 'quarters')
        .format('Q-YY')

      return `Q${quarter}`
    },
    getNext: (period) => {
      const quarter = moment(period, '?Q-YY').add(1, 'quarters').format('Q-YY')

      return `Q${quarter}`
    },
    keyGenerator: (date) => `Q${moment(date).format('Q-YY')}`,
    getStartDate: (period) => moment(period, '?Q-YY'),
    getWeightOfMarkOfPeriod: (n) => [n, 'Q'],
    periodValues: [PERIOD.Q1, PERIOD.Q2, PERIOD.Q3, PERIOD.Q4],
    isMatch: function (value = '') {
      return some(this.periodValues, (period) =>
        startsWith(value.toUpperCase(), period.toUpperCase())
      )
    },
  },
  [VISUALISATION_PERIOD.BIANNUAL]: {
    name: 'Biannual',
    getPrevious: (period) => {
      const [SEASON, YEAR] = period.split('-')
      if (SEASON === 'SUM') {
        const prevYear = moment(`01-01-${YEAR}`, 'MM-DD-YY')
          .subtract(1, 'years')
          .format('YY')
        return `WIN-${prevYear}`
      }
      return `SUM-${YEAR}`
    },
    getNext: (period) => {
      const [SEASON, YEAR] = period.split('-')
      if (SEASON === 'SUM') {
        return `WIN-${YEAR}`
      }
      const nextYear = moment(`01-01-${YEAR}`, 'MM-DD-YY')
        .add(1, 'years')
        .format('YY')
      return `SUM-${nextYear}`
    },
    keyGenerator: (date) => {
      const time = moment(date)
      const isWinter = getWinterMonths().includes(time.format('MMM'))
      // Check if order belongs to the past-year winter
      if ([0, 1, 2].includes(time.month())) {
        time.subtract(1, 'year')
      }
      return `${isWinter ? 'WIN' : 'SUM'}-${time.format('YY')}`
    },
    getStartDate: (period) => {
      const [SEASON, YEAR] = period.split('-')
      if (SEASON === 'SUM') {
        return moment(`JUN-${YEAR}`, 'MMM-YY')
      }
      return moment(period, 'JAN-YY')
    },
    getWeightOfMarkOfPeriod: (n) => [n * 6, 'M'],
    periodValues: [PERIOD.SUMMER, PERIOD.WINTER],
    isMatch: function (value = '') {
      return some(this.periodValues, (period) =>
        startsWith(value.toUpperCase(), period.substr(0, 3).toUpperCase())
      )
    },
  },
  [VISUALISATION_PERIOD.ANNUAL]: {
    name: 'Annual',
    getPrevious: (period) => {
      const [YEAR_TYPE, YEAR] = period.split('-')
      if (YEAR_TYPE === 'CAL') {
        const prevYear = moment(`01-01-${YEAR}`, 'MM-DD-YY')
          .subtract(1, 'years')
          .format('YY')
        return `GY-${prevYear}`
      }
      return `CAL-${YEAR}`
    },
    getNext: (period) => {
      const [SEASON, YEAR] = period.split('-')
      if (SEASON === 'CAL') {
        return `GY-${YEAR}`
      }
      const nextYear = moment(`01-01-${YEAR}`, 'MM-DD-YY')
        .add(1, 'years')
        .format('YY')
      return `CAL-${nextYear}`
    },
    keyGenerator: (date) => {
      const dateMoment = moment(date)
      const month = dateMoment.format('MM')
      if (month > 6) {
        return `CAL-${dateMoment.format('YY')}`
      }
      return `GY-${dateMoment.subtract(9, 'M').format('YY')}`
    },
    getStartDate: (period) => {
      const [YEAR_TYPE] = period.split('-')
      if (YEAR_TYPE === 'CAL') {
        return moment(period, '???-YY')
      }
      return moment(period, '??-YY')
    },
    getWeightOfMarkOfPeriod: (n) => [n * 6, 'M'],
    periodValues: [PERIOD.CAL_YEAR, PERIOD.GAS_YEAR],
    isMatch: function (value = '') {
      return some(['CAL', 'GY'], (period) =>
        startsWith(value.toUpperCase(), period)
      )
    },
  },
  [VISUALISATION_PERIOD.CUSTOM]: {
    name: 'Custom',
    getPrevious: noop,
    getNext: noop,
    getStartDate: (period) => {
      const [start] = period.split(' – ')
      return moment(start, 'MMM-YY')
    },
    periodValues: [VISUALISATION_PERIOD.CUSTOM],
    isMatch: function (value = '') {
      const [start, end] = value.split(' - ')
      return (
        typeMap[VISUALISATION_PERIOD.MONTHS].isMatch(start) &&
        typeMap[VISUALISATION_PERIOD.MONTHS].isMatch(end)
      )
    },
  },
}
