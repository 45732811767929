import React from 'react'

export const BrandLogoHex = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="76px"
    height="75px"
  >
    <g stroke="none" strokeWidth="1" fill="#F8FBFB" fillRule="evenodd">
      <path d="M38.47145,0.5834 C40.13895,1.5439 41.81845,2.4839 43.47545,3.4614 L48.45595,6.3759 C51.78095,8.3119 55.09545,10.2644 58.39445,12.2424 C58.58645,12.3579 58.69545,12.5589 58.70195,12.7679 L58.70245,12.7989 C58.78845,16.5169 58.83695,20.2344 58.89145,23.9524 C58.95495,27.6704 58.98795,31.3879 59.01945,35.1059 C59.02245,35.4584 58.83395,35.7684 58.55095,35.9374 L58.53545,35.9464 C55.28945,37.8714 52.04795,39.8054 48.78645,41.7034 L39.01545,47.4229 C38.67245,47.6239 38.26245,47.6124 37.93945,47.4299 L27.86445,41.7114 C24.50195,39.8129 21.15695,37.8824 17.81045,35.9549 L17.79495,35.9464 C17.48445,35.7674 17.31245,35.4399 17.31495,35.1059 C17.34645,31.3629 17.37595,27.6199 17.43995,23.8769 C17.49595,20.1339 17.54095,16.3909 17.62745,12.6474 L17.62745,12.6189 C17.63295,12.3874 17.76245,12.1874 17.94945,12.0799 L23.05645,9.1664 L28.18695,6.2929 L33.31845,3.4199 C35.02395,2.4549 36.75445,1.5304 38.47145,0.5834 M38.47145,0.5834 C36.82395,1.6474 35.19095,2.7334 33.53295,3.7794 L28.57345,6.9379 L23.61245,10.0969 L18.62795,13.2159 L18.95045,12.6474 C19.03695,16.3909 19.08145,20.1339 19.13795,23.8769 C19.20195,27.6199 19.23145,31.3629 19.26295,35.1059 L18.76745,34.2569 C22.14845,36.1229 25.53145,37.9864 28.89595,39.8814 L39.00295,45.5439 L37.92745,45.5509 L47.73195,39.8894 C50.99545,37.9939 54.27995,36.1344 57.55845,34.2654 L57.07495,35.1059 C57.10595,31.3879 57.13895,27.6704 57.20245,23.9524 C57.25745,20.2344 57.30545,16.5169 57.39095,12.7989 L57.69995,13.3554 C54.47295,11.2614 51.26295,9.1429 48.06245,7.0064 L43.25545,3.8129 C41.64895,2.7549 40.06645,1.6589 38.47145,0.5834" />
      <path d="M38.47145,46.48695 L30.74895,35.67895 L23.06345,24.84445 L23.02445,24.78995 L23.06045,24.73345 L38.34545,0.50345 L38.46245,0.31695 L38.59245,0.49595 L51.30595,18.04545 L51.34345,18.09745 L51.31745,18.15445 L44.91695,32.33095 L38.47145,46.48695 Z M38.47145,46.48695 L44.77295,32.26595 L51.11945,18.06545 L51.12995,18.17395 L38.35095,0.67145 L38.59745,0.66345 L23.22995,24.84045 L23.22695,24.72945 L30.86745,35.59495 L38.47145,46.48695 Z" />
      <path d="M18.2888,35.10585 C19.0533,33.36485 19.8238,31.62685 20.6173,29.89935 C21.3973,28.16585 22.2148,26.44985 23.0108,24.72435 L23.0073,24.84185 L20.6033,18.76235 C19.8158,16.73085 19.0408,14.69335 18.2888,12.64735 C19.1548,14.64785 19.9993,16.65735 20.8303,18.67185 L23.2823,24.73185 L23.3063,24.79235 L23.2783,24.84985 C22.4568,26.56385 21.6553,28.28735 20.8168,29.99335 C19.9908,31.70535 19.1433,33.40735 18.2888,35.10585" />
      <path d="M51.21775,18.10975 L37.20025,21.52725 L23.17075,24.89575 L23.19875,24.68825 C29.90825,28.36025 36.63975,31.99275 43.32725,35.70275 L43.25525,35.68575 L58.04175,34.99725 L57.94625,35.14625 L51.16725,18.13025 L51.15175,18.09125 L51.18475,18.06675 L58.04725,12.79875 L51.25175,18.15325 L51.26925,18.08925 L58.14775,35.06525 L58.20475,35.20625 L58.05175,35.21425 L43.26825,35.95975 L43.23025,35.96225 L43.19575,35.94325 C36.47325,32.29625 29.79325,28.57075 23.09125,24.88525 L22.83475,24.74475 L23.11875,24.67775 L37.16275,21.36975 L51.21775,18.10975 Z" />
      <path d="M52.688,17.76115 C52.5015,16.95615 51.697,16.45565 50.8925,16.64265 L50.87,16.64715 L43.8435,18.27915 C41.502,18.82865 39.155,19.35265 36.8215,19.93315 L29.812,21.64015 C28.61,21.92765 27.4095,22.22365 26.2105,22.52465 L28.092,19.44215 L31.7615,13.28915 C32.995,11.24465 34.181,9.16915 35.388,7.10765 C36.458,5.28815 37.501,3.45015 38.5455,1.61315 L42.965,7.29965 L47.97,13.64465 L43.477,6.92815 L38.936,0.24565 C38.8985,0.19165 38.851,0.14165 38.794,0.10165 C38.5405,-0.07635 38.19,-0.01485 38.012,0.23915 L37.9865,0.27665 C36.6135,2.23265 35.2355,4.18615 33.892,6.16115 C32.5445,8.13315 31.176,10.09215 29.8545,12.08115 L25.8615,18.03015 L21.9305,24.01815 C21.7295,24.32715 21.6445,24.71765 21.7295,25.10615 C21.898,25.87615 22.659,26.36415 23.429,26.19565 L23.478,26.18515 C25.827,25.67165 28.1745,25.14815 30.5145,24.59515 L37.542,22.96365 C39.887,22.43115 42.2195,21.84265 44.557,21.27915 L48.6245,20.28865 L43.6145,31.74515 L40.531,38.88665 L38.1975,44.32465 C37.686,43.66965 37.173,43.01665 36.664,42.36065 C35.76,41.19815 34.851,40.03915 33.923,38.89415 C32.0845,36.59015 30.2125,34.31015 28.288,32.06815 C29.757,34.63215 31.28,37.15865 32.836,39.66115 C33.605,40.91915 34.3935,42.16315 35.187,43.40415 C35.982,44.64315 36.7575,45.89665 37.569,47.12465 C37.675,47.28415 37.827,47.42115 38.013,47.50865 C38.5555,47.76315 39.202,47.53015 39.457,46.98765 L39.4795,46.93965 L42.7855,39.89915 L46.076,32.85165 L52.5895,18.72615 C52.724,18.43465 52.7665,18.09765 52.688,17.76115" />
      <path d="M2.2151,61.038 C2.6401,59.463 4.1666,58.2605 5.8666,58.2605 C7.7091,58.2605 8.9786,59.6035 9.4026,61.038 L2.2151,61.038 Z M5.8426,56.2175 C2.5121,56.2175 0.0001,58.8925 0.0001,62.4395 C0.0001,66.072 2.4571,68.7085 5.8426,68.7085 C8.1766,68.7085 10.1376,67.5255 11.2211,65.461 C11.3506,65.213 11.2631,64.906 11.0196,64.7655 L10.1171,64.243 C9.9951,64.172 9.8481,64.1525 9.7121,64.193 C9.5751,64.2315 9.4606,64.324 9.3936,64.45 C8.6441,65.858 7.4021,66.666 5.9856,66.666 C4.8801,66.666 3.8856,66.27 3.1841,65.551 C2.5596,64.91 2.1986,64.0635 2.1266,63.081 L11.1146,63.081 C11.4016,63.081 11.6346,62.8495 11.6376,62.562 C11.6521,60.6795 11.0181,58.9965 9.8521,57.8225 C8.8101,56.7725 7.4236,56.2175 5.8426,56.2175 Z" />
      <path d="M23.15135,56.21745 C21.83535,56.21745 20.69485,56.76395 19.95485,57.71495 C19.23635,56.75345 18.14835,56.21745 16.85935,56.21745 C15.99585,56.21745 15.20035,56.48945 14.57885,56.96895 C14.57485,56.68445 14.34285,56.45495 14.05685,56.45495 L12.98785,56.45495 C12.69935,56.45495 12.46485,56.68895 12.46485,56.97795 L12.46485,67.94845 C12.46485,68.23745 12.69935,68.47145 12.98785,68.47145 L14.05685,68.47145 C14.34585,68.47145 14.57885,68.23745 14.57885,67.94845 L14.57885,61.91695 C14.57885,59.49095 15.32285,58.26045 16.78785,58.26045 C18.17135,58.26045 18.71085,59.07945 18.71085,61.18095 L18.71085,67.94845 C18.71085,68.23745 18.94435,68.47145 19.23335,68.47145 L20.30185,68.47145 C20.59085,68.47145 20.82535,68.23745 20.82535,67.94845 L20.82535,61.63195 C20.82535,58.26045 22.55785,58.26045 23.12785,58.26045 C24.51185,58.26045 24.95585,58.90695 24.95585,60.91995 L24.95585,67.94845 C24.95585,68.23745 25.18985,68.47145 25.47885,68.47145 L26.54735,68.47145 C26.83685,68.47145 27.06985,68.23745 27.06985,67.94845 L27.06985,61.06195 C27.06985,58.98245 26.66435,56.21745 23.15135,56.21745" />
      <path d="M30.29615,59.44765 C30.29615,58.49765 31.24565,57.73765 32.17165,57.73765 C33.02665,57.73765 33.73915,58.21265 34.09515,58.97215 L35.02115,58.49765 C34.54615,57.47665 33.33565,56.74015 32.21965,56.74015 C30.62865,56.74015 29.22765,57.85665 29.22765,59.51865 C29.22765,61.37115 30.93715,62.01165 32.43315,62.55815 C33.52515,62.93815 34.56965,63.36565 34.56965,64.71915 C34.56965,66.09615 33.47815,67.18865 32.10015,67.18865 C30.65215,67.18865 29.77365,66.26265 29.37015,64.95665 L28.32565,65.28915 C28.77615,67.06965 30.27265,68.18615 32.10015,68.18615 C34.07115,68.18615 35.63865,66.78515 35.63865,64.76665 C35.63865,60.80115 30.29615,62.08315 30.29615,59.44765" />
      <path d="M44.32835,63.1757 C44.32835,65.2892 44.09135,67.1887 41.45535,67.1887 C38.81935,67.1887 38.58235,65.2892 38.58235,63.1757 L38.58235,56.9777 L37.51385,56.9777 L37.51385,63.5557 C37.51385,64.6717 37.48985,65.7877 38.17785,66.7137 C38.93835,67.7112 40.22035,68.1862 41.45535,68.1862 C42.68985,68.1862 43.97235,67.7112 44.73185,66.7137 C45.42085,65.7877 45.39685,64.6717 45.39685,63.5557 L45.39685,56.9777 L44.32835,56.9777 L44.32835,63.1757 Z" />
      <path d="M49.2888,58.40275 L49.2413,58.40275 L49.2413,56.97775 L48.1728,56.97775 L48.1728,67.94875 L49.2413,67.94875 L49.2413,62.34425 C49.2413,60.72975 49.0993,57.73775 51.4973,57.73775 C51.7823,57.73775 51.9963,57.80925 52.2573,57.95125 L52.8268,57.00175 C52.4473,56.83525 52.0913,56.74025 51.6638,56.74025 C50.5003,56.74025 49.7163,57.40575 49.2888,58.40275" />
      <path d="M58.5493,67.18865 C55.8898,67.18865 54.2268,64.90915 54.2268,62.39215 C54.2268,60.01765 55.9608,57.73765 58.4778,57.73765 C61.1368,57.73765 62.8708,59.73215 62.8708,62.27315 C62.8708,64.90915 61.3513,67.18865 58.5493,67.18865 Z M62.7993,58.87765 L62.7518,58.87765 C61.7308,57.50015 60.4013,56.74065 58.6198,56.74065 C55.4148,56.74065 53.1583,59.28115 53.1583,62.43965 C53.1583,65.50265 55.2958,68.18615 58.4778,68.18615 C60.1398,68.18615 61.7783,67.35515 62.7518,66.04915 L62.7993,66.04915 L62.7993,67.40265 C62.7993,68.87465 62.6803,70.29965 61.8258,71.55815 C61.0188,72.72165 59.6888,73.19665 58.3113,73.19665 C56.1028,73.19665 54.1798,71.77165 54.0608,69.49215 L52.9928,69.49215 C53.1583,72.50765 55.5328,74.19365 58.4063,74.19365 C60.1398,74.19365 61.7548,73.48115 62.7758,72.08065 C63.8203,70.63165 63.8678,69.11215 63.8678,67.42615 L63.8678,56.97765 L62.7993,56.97765 L62.7993,58.87765 Z" />
      <path d="M71.0842,57.7377 C73.3412,57.7377 75.0027,59.5422 75.2632,61.5607 L66.8102,61.5607 C67.0007,59.4952 68.8527,57.7377 71.0842,57.7377 M71.2037,67.1887 C68.6627,67.1887 66.7397,65.3367 66.8102,62.5582 L76.3332,62.5582 C76.3562,59.4002 74.3847,56.7402 71.0612,56.7402 C67.8552,56.7402 65.7412,59.3762 65.7412,62.4397 C65.7412,65.5502 67.8077,68.1862 71.0612,68.1862 C73.2212,68.1862 74.9782,67.1172 75.9757,65.2177 L75.0737,64.6952 C74.2907,66.1677 72.9132,67.1887 71.2037,67.1887" />
    </g>
  </svg>
)
