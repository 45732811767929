import React from 'react'
import TextField from 'containers/formikTextField/FormikTextField'

export const OffSystem = () => (
  <>
    <TextField
      inputProps={{ 'data-testid': 'company-name-field' }}
      name="companyName"
      label="Company Name"
      fullWidth
      required
    />
    <TextField
      inputProps={{ 'data-testid': 'entity-name-field' }}
      name="entityName"
      label="Entity Name"
      fullWidth
    />
    <TextField
      inputProps={{ 'data-testid': 'contact-field' }}
      name="contact"
      label="Contact"
      fullWidth
    />
  </>
)
