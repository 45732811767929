import React from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'
import { MarketInterestOrderCardSkeleton } from './MarketInterestOrderCardSkeleton'

export const MarketInterestOrderSkeleton = ({ count }) => (
  <>
    {times(count, (i) => (
      <MarketInterestOrderCardSkeleton
        key={`market-interest-order-card-skeleton-${i}`}
        style={{ marginTop: '25px' }}
      />
    ))}
  </>
)

MarketInterestOrderSkeleton.defaultProps = {
  count: 1,
}

MarketInterestOrderSkeleton.propTypes = {
  count: PropTypes.number,
}
