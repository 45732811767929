import React from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import { connect } from 'formik'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import { setCargoesDateRangeFields } from 'screens/orderCreate/components/DeliveryWindowCargoes.helpers'
import FormikRadioInput from 'containers/FormikRadioInput/FormikRadioInput'

const DeliveryWindowType = ({ formik: { values, setFieldValue } }) => (
  <FormControl>
    <FormLabel>Other Cargoes</FormLabel>
    <FormikRadioInput
      name="nominationRules.deliveryWindow.deliveryType"
      onChange={(ev) => {
        const { value: newDeliveryType } = ev.target
        if (newDeliveryType === 'pro_rata') {
          setCargoesDateRangeFields({ values, setFieldValue })
        }
      }}
      options={[
        { value: 'pro_rata', label: 'Pro Rata' },
        { value: 'per_cargo', label: 'Set Delivery Window per cargo' },
      ]}
    />
  </FormControl>
)

DeliveryWindowType.propTypes = {
  formik: PropTypes.object.isRequired,
}

export default connect(DeliveryWindowType)
