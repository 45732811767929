import { ORDER_STATUS } from 'emsurge-selectors/constants/order-status'

export const STATUS = Object.freeze({
  ALL: 'all',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
})

export const STATUS_LABELS = Object.freeze({
  [STATUS.ALL]: 'All',
  [STATUS.ACTIVE]: 'Active',
  [STATUS.ARCHIVED]: 'Archived',
})

export const STATUS_VALUES = Object.freeze({
  [STATUS.ALL]: [ORDER_STATUS.LIVE, ORDER_STATUS.WITHHELD, ORDER_STATUS.CLOSED],
  [STATUS.ACTIVE]: [ORDER_STATUS.LIVE, ORDER_STATUS.WITHHELD],
  [STATUS.ARCHIVED]: [ORDER_STATUS.CLOSED],
})
