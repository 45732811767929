import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import { APP_CONTEXT_TYPE, STAKEHOLDER_TYPE, VALIDITY_TYPE } from 'emsurge-selectors'
import { getEssentialInitialValues } from './essentialInitialValues'
import { getOptionalInitialValues } from './optionalInitialValues'
import { getSchema } from './FormSchema'
import { validateOrder } from './containers/validation'
import {
  getPriceData,
  getProjectSpecificationData,
  getTermVintageData,
  getTypeData,
  getVolumeData,
  getAdditionalAttributesData,
  getVenueData,
  getRegionData,
  getAlternativesData,
} from './FormProvider.helpers'
import { useUser } from 'containers/user/useUser'
import { DASHBOARD, ORDER_BASE_ROUTES } from 'routes'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { useCreateOrder } from 'api'

export const getOrderInfo = (values) => {
  const orderInfo = {
    submittedAt: values.submittedAt || values.submissionDate,
    status: values.status,
    tradingType: values.tradingType && values.tradingType.toLowerCase(),
    validityType: values.validity?.until,
    orders: values.orders,
    context: APP_CONTEXT_TYPE.CARBON,
  }

  if (values.validity?.until === VALIDITY_TYPE.GOOD_TILL_DATE) {
    const { date, time } = values.validity
    orderInfo.validUntil = moment
      .utc(`${date}:${time}`, 'YYYY-MM-DD:HH:mm')
      .format()
  }

  if (values.behalfOf === STAKEHOLDER_TYPE.ON_SYSTEM) {
    orderInfo.personId = values.myCompany?.traderId
    orderInfo.entityId = values.myCompany?.entityId
  }

  if (values.behalfOf === STAKEHOLDER_TYPE.OFF_SYSTEM) {
    orderInfo.brokerId = values.myCompany?.brokerEntityId
    orderInfo.thirdParty = values.thirdParty
  }

  getTypeData(values, orderInfo)
  getTermVintageData(values, orderInfo)
  getVolumeData(values, orderInfo)
  getPriceData(values, orderInfo)
  getRegionData(values, orderInfo)
  getProjectSpecificationData(values, orderInfo)
  getAdditionalAttributesData(values, orderInfo)
  getVenueData(values, orderInfo)
  getAlternativesData(values, orderInfo)

  orderInfo.details = values.details

  return orderInfo
}

const getInitialValues = () => {
  return {
    ...getEssentialInitialValues(),
    ...getOptionalInitialValues(),
  }
}

const FormProvider = ({ children, postSubmit, history }) => {
  const { user } = useUser()
  const BASE_SLUG = useRouteBaseSlug()
  const { mutateAsync: createOrder } = useCreateOrder()

  const handleSubmit = async (values, actions) => {
    const data = getOrderInfo(values, user)

    try {
      const order = await createOrder(data)
      postSubmit()
      const slug = ORDER_BASE_ROUTES.includes(`/${BASE_SLUG}`)
        ? `/${BASE_SLUG}`
        : DASHBOARD
      history.push(`${slug}/orders/${order.id}`)
    } catch {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getInitialValues()}
      validate={validateOrder}
      validationSchema={getSchema(user)}
    >
      {children}
    </Formik>
  )
}

FormProvider.defaultProps = {
  postSubmit: () => {},
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  postSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default compose(withSnackbar, withRouter)(FormProvider)
