import { get, find } from 'lodash'
import {
  $U,
  PERSON_CLIENT_TYPE,
  PERSON_PERMISSION,
  $O,
} from 'emsurge-selectors'
import { useUser } from 'containers/user/useUser'
import { isThirdPartyOrder } from 'screens/orderIndex/helpers'

// This is a workaround for current permissions. The and statement should be migrated to backend
const useIsSameCompany = (order) => {
  const { user } = useUser()
  const clientType = $U.clientType.get(user)

  return (
    get(order, 'company.id', '') === user.companyId ||
    order.companyId === user.companyId ||
    clientType === PERSON_CLIENT_TYPE.BROKER ||
    isThirdPartyOrder(order)
  )
}

export const userHasReadPermission = (permission) =>
  permission &&
  [
    PERSON_PERMISSION.READ,
    PERSON_PERMISSION.WRITE,
    PERSON_PERMISSION.TRADE,
  ].includes(permission.toLowerCase())
export const userHasWritePermission = (permission) =>
  permission &&
  [PERSON_PERMISSION.WRITE, PERSON_PERMISSION.TRADE].includes(
    permission.toLowerCase()
  )
export const userHasTradePermission = (permission) =>
  permission && PERSON_PERMISSION.TRADE === permission.toLowerCase()

export const userHasMinimumPermission = (
  permission,
  minimumPermission,
  forceSameCompany = false,
  isSameCompany = false
) => {
  switch (minimumPermission) {
    case PERSON_PERMISSION.NONE:
      return true
    case PERSON_PERMISSION.READ:
      return userHasReadPermission(permission)
    case PERSON_PERMISSION.WRITE:
      return (
        userHasWritePermission(permission) &&
        (!forceSameCompany || isSameCompany)
      )
    case PERSON_PERMISSION.TRADE:
      return (
        userHasTradePermission(permission) &&
        (!forceSameCompany || isSameCompany)
      )
    default:
      throw new Error('Unknown permission')
  }
}

export const useUserHasPermission = ({
  order = {},
  minimumPermission,
  forceSameCompany = false,
}) => {
  const isSameCompany = useIsSameCompany(order)
  const {
    user: { entities },
  } = useUser()

  const orderEntity = find(
    entities,
    ({ id }) => id === $O.stakeholder.entity.get(order).id
  )
  const permission = order.permission || orderEntity?.permission

  return userHasMinimumPermission(
    permission,
    minimumPermission,
    forceSameCompany,
    isSameCompany
  )
}
