import React from 'react'
import { withHandlers } from 'recompose'
import { General } from './General'
import { DeliveryAndLocation } from './DeliveryAndLocation'
import { DeliveryPeriod } from './DeliveryPeriod'
import { Price } from './Price'
import { NoOfCargoes } from './NoOfCargoes'
import { QualityGHV } from './QualityGHV'
import { DeliveryWindow } from './DeliveryWindow'
import { BasePort } from './BasePort'
import { CargoSize } from './CargoSize'
import { Notes } from './Notes'
import { Recap } from './Recap'
import {
  ShareButton,
  SaveButton,
  NextButton as NextBtn,
  ButtonPlaceholder,
  SkipToEndButton as SkipToEndBtn,
} from './CreateOrderModal.components'

export const EmptyComponent = () => <div />

const withGoToLastAction = withHandlers({
  onClick: (props) => () => props.actions.goToLast(),
})

const withGoForwardAction = withHandlers({
  onClick: (props) => () => props.actions.goForward(),
})

const SkipButton = withGoToLastAction(SkipToEndBtn)
const NextButton = withGoForwardAction(NextBtn)

export const UNKNOWN_STEP = {
  title: 'Unknown step',
  Component: EmptyComponent,
  LeftButton: ButtonPlaceholder,
  RightButton: ButtonPlaceholder,
}

export const STEP_TITLES = {
  GENERAL_INFORMATION: 'General Information',
  DELIVERY_AND_LOCATION: 'Delivery & Location',
  PERIOD: 'Period',
  PRICE: 'Price',
  NR_OF_CARGOES: 'Number of Cargoes',
  DELIVERY_WINDOW: 'Delivery Window',
  QUALITY_GHV: 'Quality GHV (in Btu/scf)',
  CARGO_SIZE: 'Cargo Size',
  BASE_PORT: 'Base Port',
  NOTES: 'Public Note',
  RECAP: 'Recap',
}

export const STEPS = [
  {
    title: STEP_TITLES.GENERAL_INFORMATION,
    Component: General,
    LeftButton: ButtonPlaceholder,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.DELIVERY_AND_LOCATION,
    Component: DeliveryAndLocation,
    LeftButton: SkipButton,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.PERIOD,
    Component: DeliveryPeriod,
    LeftButton: SkipButton,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.NR_OF_CARGOES,
    Component: NoOfCargoes,
    LeftButton: SkipButton,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.DELIVERY_WINDOW,
    Component: DeliveryWindow,
    LeftButton: SkipButton,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.PRICE,
    Component: Price,
    LeftButton: SkipButton,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.QUALITY_GHV,
    Component: QualityGHV,
    LeftButton: SkipButton,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.CARGO_SIZE,
    Component: CargoSize,
    LeftButton: SkipButton,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.BASE_PORT,
    Component: BasePort,
    LeftButton: SkipButton,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.NOTES,
    Component: Notes,
    LeftButton: SkipButton,
    RightButton: NextButton,
  },
  {
    title: STEP_TITLES.RECAP,
    Component: Recap,
    LeftButton: ShareButton,
    RightButton: SaveButton,
  },
]
