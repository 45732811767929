import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Formik, Form } from 'formik'
import {
  Name,
  ParentCompany,
  RegistrationNumber,
  AddressFirstLine,
  AddressSecondLine,
  Postcode,
  City,
  Country,
  Code,
  CompanyType,
} from 'screens/companyCreate/companyForm/components/CompanyInformation/FormFields'
import CompanyInformationFormSchema from 'screens/companyCreate/companyForm/components/CompanyInformation/FormSchema'
import { NextButton } from 'screens/companyCreate/companyForm/components/Buttons'
import { ButtonsWrapper } from 'screens/companyCreate/companyForm/components/ButtonsWrapper'
import FormRow from 'components/formRow/FormRow'

const CompanyInformationForm = ({ handleNext, initialValues }) => {
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(false)
    handleNext({ form: 'company', values })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CompanyInformationFormSchema.getFormSchema(
        initialValues
      )}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormRow>
          <Name />
          <ParentCompany />
          <CompanyType />
        </FormRow>
        <FormRow>
          <RegistrationNumber />
          <Code />
        </FormRow>
        <FormRow>
          <AddressFirstLine />
          <AddressSecondLine />
        </FormRow>
        <FormRow>
          <Postcode />
          <City />
          <Country />
        </FormRow>
        <ButtonsWrapper>
          <NextButton />
        </ButtonsWrapper>
      </Form>
    </Formik>
  )
}

CompanyInformationForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
}

export default withRouter(CompanyInformationForm)
