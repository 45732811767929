import React from 'react'
import PropTypes from 'prop-types'
import { withTheme, makeStyles } from '@material-ui/core/styles'
import Text from '@material-ui/core/Typography'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { PADDING_Y_UNIT } from 'components/main/Main.desktop'
import { FOOTER_HEIGHT } from 'components/main/Footer'

const useStyles = makeStyles((theme) => ({
  actionBlock: {
    backgroundColor: theme.palette.background.secondary,
    padding: theme.spacing(0.5, 2),
    height: 48,
  },
}))

export const Block = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ theme }) => {
    const verticalPadding = theme.spacing(PADDING_Y_UNIT)
    return `calc(100vh - ${theme.custom.desktop.navbar.height}px - ${verticalPadding}px - ${FOOTER_HEIGHT}px)`
  }};
  overflow: hidden;
  border-radius: 5px;
  border: ${({ theme }) => `2px solid ${theme.palette.background.secondary}`};
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const IconTitle = withTheme(
  ({ icon: Icon, children, theme, style, textStyle }) => {
    const rootStyle = {
      display: 'flex',
      margin: 0,
      marginRight: theme.spacing(2),
    }

    const iconStyle = {
      marginRight: theme.spacing(1),
      fill: theme.palette.text.primary,
      fontSize: theme.typography.h6.fontSize,
    }

    return (
      <div style={Object.assign(rootStyle, style)}>
        <Icon style={iconStyle} />
        <Text style={textStyle} variant="h6">
          {children}
        </Text>
      </div>
    )
  }
)

export const BlockHeader = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
`

export const BlockContent = styled.div`
  position: relative;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(0, 2, 0, 2)};
`

const PaddedGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(1.55, 2)};
`

export const BlockRow = ({ children, ...props }) => (
  <PaddedGrid
    container
    justifyContent="space-between"
    alignItems="center"
    wrap="nowrap"
    {...props}
  >
    {children}
  </PaddedGrid>
)

BlockRow.propTypes = {
  children: PropTypes.node,
}

export const ActionBlock = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <BlockRow className={classes.actionBlock} {...props}>
      {children}
    </BlockRow>
  )
}

ActionBlock.defaultProps = {
  children: [
    <div key="action-block-child" style={{ height: '100%', width: '100%' }} />,
  ],
}

ActionBlock.propTypes = {
  children: PropTypes.node,
}
