import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'

import { PRICE_PLUS_MINUS } from 'emsurge-selectors/constants'
import { TextField } from 'screens/orderCreateCommon/components/TextField'

export const PlusOrMinus = ({ name }) => (
  <TextField select name={name} label=" ">
    {Object.values(PRICE_PLUS_MINUS).map((PRICE_PLUS_MINUS_VALUE) => (
      <MenuItem
        data-testid="price-plus-or-minus-values"
        key={PRICE_PLUS_MINUS_VALUE}
        value={PRICE_PLUS_MINUS_VALUE}
      >
        {PRICE_PLUS_MINUS_VALUE}
      </MenuItem>
    ))}
  </TextField>
)

PlusOrMinus.propTypes = {
  name: PropTypes.string.isRequired,
}
