const { PROJECT_SECTORAL_SCOPE_CHECKBOX } = require('./carbonPrefix')

const PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS = {
  AFFO: 'AFFO',
  AGRI: 'AGRI',
  CHIN: 'CHIN',
  CONS: 'CONS',
  ENDE: 'ENDE',
  ENDI: 'ENDI',
  ENIN: 'ENIN',
  FUEF: 'FUEF',
  FUEP: 'FUEP',
  MAIN: 'MAIN',
  MEPR: 'MEPR',
  MIMI: 'MIMI',
  SOUS: 'SOUS',
  TRAN: 'TRAN',
  WAHA: 'WAHA',
}

const PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS = Object.freeze({
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.AFFO]:
    'Afforestation and Reforestation',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.AGRI]: 'Agriculture Forestry and Other Land Use',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.CHIN]: 'Chemical Industry',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.CONS]: 'Construction',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.ENDE]: 'Energy Demand',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.ENDI]:
    'Energy Distribution',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.ENIN]:
    'Energy Industries (Renewable / Non-Renewable Sources)',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.FUEF]:
    'Fugitive Emissions from Fuels (Solid, Oil and Gas)',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.FUEP]:
    'Fugitive Emissions from Production and Consumption of Halocarbons and Sulphur Hexafluoride',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.MAIN]:
    'Manufacturing Industries',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.MEPR]: 'Metal Production',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.MIMI]:
    'Mining/Mineral Production',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.SOUS]: 'Solvents Use',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.TRAN]: 'Transport',
  [PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS.WAHA]:
    'Waste Handling and Disposal',
})

const PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS = {
  NOAP: 'NOAP',
  GEOL: 'GEOL',
  BIOL: 'BIOL',
  WIPR: 'WIPR',
  ANY: 'ANY',
  NONE: 'NONE',
}

const PROJECT_SPECIFICATION_STORAGE_TYPE_OPTIONS = {
  [PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS.NOAP]: '-',
  [PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS.GEOL]: 'Geological',
  [PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS.BIOL]: 'Biological',
  [PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS.WIPR]: 'Within Products',
  [PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS.ANY]: 'Any',
  [PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS.NONE]: 'None',
}

const PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE_PATHS = {
  ALL: `${PROJECT_SECTORAL_SCOPE_CHECKBOX}.ALL`,
}

const PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE = [
  {
    key: 'ALL',
    label: 'All',
    value: PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE_PATHS.ALL,
  },
]

module.exports = {
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS,
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS,
  PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS,
  PROJECT_SPECIFICATION_STORAGE_TYPE_OPTIONS,
  PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE,
  PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE_PATHS,
}
