import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Route, withRouter, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import MuiTextField from '@material-ui/core/TextField'
import Text from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Search from '@material-ui/icons/Search'
import { NOTE_PRIORITY_COLOR } from 'emsurge-selectors/constants'
import { Block } from './Block'
import NotesActivity from './NotesActivity'
import NoteView from 'screens/noteView/NoteView'

const useStyles = makeStyles({
  searchField: {
    width: '100%',
    padding: '9px 2px 6px 2px',
  },
})

export const PriorityIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: ${({ note }) => NOTE_PRIORITY_COLOR[note.priority]};
`

PriorityIcon.propTypes = {
  note: PropTypes.object.isRequired,
}

const NotesTable = ({ notes, match, refetch }) => {
  const [searchNotes, setSearchNotes] = useState(notes)
  const [filter, setFilter] = useState('')
  const classes = useStyles()

  const handleChange = (e) => {
    const value = e.target.value.trim().toLowerCase()

    setFilter(value)
  }

  useEffect(() => {
    const filteredNotes = !filter
      ? notes
      : notes.filter((note) => {
          const filterRegExp = new RegExp(filter, 'i')
          const {
            country,
            companyName,
            person,
            note: noteContent,
            attendees,
            eventDate,
            tags,
          } = note
          const authorName = `${person.name} ${person.surname}`
          const filteredAttendees = attendees.filter((attendee) =>
            attendee.match(filterRegExp)
          )
          const filteredTags = tags.filter((tag) => tag.match(filterRegExp))
          const formattedDate = moment(eventDate).format('ddd HH:mm DD/MM/YY')

          if (companyName.match(filterRegExp)) {
            return true
          }
          if (authorName.match(filterRegExp)) {
            return true
          }
          if ((noteContent || '').match(filterRegExp)) {
            return true
          }
          if (formattedDate.match(filterRegExp)) {
            return true
          }
          if ((country || '').match(filterRegExp)) {
            return true
          }
          if (!isEmpty(filteredAttendees)) {
            return true
          }
          if (!isEmpty(filteredTags)) {
            return true
          }

          return false
        })

    setSearchNotes(filteredNotes)
  }, [filter, notes])

  if (!notes.length) {
    return (
      <Paper>
        <Text style={{ padding: 20 }}>There are no notes</Text>
      </Paper>
    )
  }

  if (match.isExact) {
    return <Redirect to={`${match.path}/${notes[0].id}`} />
  }

  return (
    <Grid container spacing={2}>
      <Grid item style={{ width: 350 }}>
        <Block>
          <MuiTextField
            className={classes.searchField}
            onChange={handleChange}
            InputProps={{
              disableUnderline: true,
              placeholder: 'Search Notes',
              endAdornment: <Search />,
            }}
          />
          <NotesActivity notes={searchNotes} />
        </Block>
      </Grid>

      <Grid item xs>
        <Route
          exact
          path={`${match.path}/:id`}
          render={(props) => <NoteView {...props} refetch={refetch} />}
        />
      </Grid>
    </Grid>
  )
}

NotesTable.propTypes = {
  notes: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
}

export default withRouter(NotesTable)
