import React from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'
import { ActivityCardSkeleton } from './ActivityCardSkeleton'
import {
  ORDER_ACTIVITY_CARD_HEIGHT_LNG,
  GUTTER,
  guessCommentActivityCardHeight,
} from 'screens/orderIndex/helpers'

const getCardHeight = (index) =>
  index % 4 ? ORDER_ACTIVITY_CARD_HEIGHT_LNG : guessCommentActivityCardHeight()

export const ActivitySkeleton = ({ count }) => (
  <>
    {times(count, (i) => (
      <ActivityCardSkeleton
        key={`activity-card-skeleton-${i}`}
        style={{
          marginTop: `${GUTTER}px`,
          height: `${getCardHeight(i)}px`,
        }}
      />
    ))}
  </>
)

ActivitySkeleton.defaultProps = {
  count: 1,
}

ActivitySkeleton.propTypes = {
  count: PropTypes.number,
}
