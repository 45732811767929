import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Grid as _Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { STATUS, STATUS_LABELS } from './constants'

const Grid = styled(_Grid)`
  ${spacing}
`

export const StatusFilters = ({ value, onChange, gridProps }) => (
  <Grid {...gridProps}>
    <RadioGroup
      row
      name="status-filters"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <FormControlLabel
        value={STATUS.ALL}
        label={STATUS_LABELS[STATUS.ALL]}
        control={
          <Radio
            inputProps={{ 'data-testid': 'status-filter-all' }}
            color="primary"
            size="small"
          />
        }
      />
      <FormControlLabel
        value={STATUS.ACTIVE}
        label={STATUS_LABELS[STATUS.ACTIVE]}
        control={
          <Radio
            inputProps={{ 'data-testid': 'status-filter-active' }}
            color="primary"
            size="small"
          />
        }
      />
      <FormControlLabel
        value={STATUS.ARCHIVED}
        label={STATUS_LABELS[STATUS.ARCHIVED]}
        control={
          <Radio
            inputProps={{ 'data-testid': 'status-filter-archived' }}
            color="primary"
            size="small"
          />
        }
        style={{ marginRight: 0 }}
      />
    </RadioGroup>
  </Grid>
)

StatusFilters.defaultProps = {
  value: '',
  onChange: () => {},
  gridProps: {
    p: 1,
    pb: 0.5,
  },
}

StatusFilters.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  gridProps: PropTypes.object,
}
