import React from 'react'
import PropTypes from 'prop-types'
import { find } from 'lodash'
import { ModalContent } from './components/ModalContent'
import { EditMarketGrid } from './components/EditMarketGrid'
import { FormProvider } from './containers/FormProvider'
import { ModalHeader } from './components/ModalHeader'
import Modal from 'components/modal/Modal'
import { useGrids } from 'api'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'

export const MarketGridEdit = ({ id, onClose }) => {
  const { data: grids, isLoading } = useGrids()

  if (isLoading) {
    return <LoadingSpinner />
  }

  const grid = find(grids, ({ id: gridId }) => gridId === id)

  return (
    <Modal
      open={true}
      paperProps={{
        style: {
          height: '80vh',
          maxWidth: '60vw',
        },
      }}
    >
      <FormProvider data={grid} onSuccess={onClose}>
        <>
          <ModalHeader onClose={onClose} />
          <ModalContent>
            <EditMarketGrid />
          </ModalContent>
        </>
      </FormProvider>
    </Modal>
  )
}

MarketGridEdit.defaultProps = {
  id: '',
  onClose: () => {},
}

MarketGridEdit.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
}
