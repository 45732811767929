import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const TableLink = ({ to, style, ...props }) => (
  <Route>
    {({ history }) => (
      <TableRow
        {...props}
        onClick={() => history.push(to)}
        hover
        style={{ cursor: 'pointer', ...style }}
      />
    )}
  </Route>
)

TableLink.propTypes = {
  to: PropTypes.string.isRequired,
  style: PropTypes.object,
}

export default TableLink
