const { isEmpty } = require('lodash')
const moment = require('moment')

const { PRICE_TYPE, PRICE_TYPE_TEXT } = require('../../../constants')

const DATE_FORMAT = 'Do MMM YYYY ddd HH:mm'

const hasPrice = () => true

const getPrice = (info) => {
  const deadline = info.deadline
  const award = info.award

  return {
    award: !isEmpty(award)
      ? moment(`${award.date}T${award.time}:00`)
      : undefined,
    deadline: !isEmpty(deadline)
      ? moment(`${deadline.date}T${deadline.time}:00`)
      : undefined,
  }
}

const getText = (info) => {
  const longSummary = []

  const price = getPrice(info)

  if (price.deadline) {
    longSummary.push(`Deadline Date: ${price.deadline.format(DATE_FORMAT)}`)
  }

  if (price.award) {
    longSummary.push(`Award Date: ${price.award.format(DATE_FORMAT)}`)
  }

  const shortSummary = 'RFQ'
  const summary = {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.RFQ],
    short: shortSummary,
    shorter: shortSummary,
    long: longSummary,
  }

  return summary
}

module.exports = {
  exists: hasPrice,
  get: getPrice,
  summary: getText,
}
