const map = require('lodash/map')

const DELIVERY_VOLUME_METRIC = Object.freeze({
  NO_OF_CARGOES: 'no_of_cargoes',
  MT: 'MT',
})

const DELIVERY_VOLUME_METRIC_TEXT = Object.freeze({
  [DELIVERY_VOLUME_METRIC.NO_OF_CARGOES]: 'No. of Cargoes',
  [DELIVERY_VOLUME_METRIC.MT]: 'MT',
})

const DELIVERY_VOLUME_TYPE = Object.freeze({
  FIXED: 'fixed',
  RANGE: 'range',
})

const DELIVERY_VOLUME_TYPE_TEXT = Object.freeze({
  [DELIVERY_VOLUME_TYPE.FIXED]: 'Fixed number',
  [DELIVERY_VOLUME_TYPE.RANGE]: 'Range',
})

const DELIVERY_VOLUME_TYPE_OPTIONS = map(
  DELIVERY_VOLUME_TYPE_TEXT,
  (label, value) => ({ value, label })
)

const DELIVERY_VOLUME_MAX_VALUE = 5000

module.exports = {
  DELIVERY_VOLUME_METRIC,
  DELIVERY_VOLUME_METRIC_TEXT,
  DELIVERY_VOLUME_TYPE,
  DELIVERY_VOLUME_TYPE_TEXT,
  DELIVERY_VOLUME_TYPE_OPTIONS,
  DELIVERY_VOLUME_MAX_VALUE,
}
