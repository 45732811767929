import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'

import { ORDER_STATUS, PERSON_PERMISSION } from 'emsurge-selectors/constants'
import { MatchOrder } from './MatchOrder'
import { useUser } from 'containers/user/useUser'

export const MatchOrderMenuItem = ({ order, closeMenu }) => {
  const { user } = useUser()
  const dialogProps = { onExited: closeMenu }
  const hasTradePermission =
    user.entities.some(
      (entity) => entity.permission === PERSON_PERMISSION.TRADE
    ) &&
    (order.status === ORDER_STATUS.LIVE ||
      order.status === ORDER_STATUS.WITHHELD)

  if (!hasTradePermission || order.template) {
    return null
  }

  return (
    <MatchOrder order={order} dialogProps={dialogProps}>
      {({ open }) => (
        <MenuItem
          data-testid="menu-match-order-button"
          onClick={(ev) => {
            ev.stopPropagation()
            open()
          }}
        >
          MATCH
        </MenuItem>
      )}
    </MatchOrder>
  )
}

MatchOrderMenuItem.propTypes = {
  order: PropTypes.object.isRequired,
  closeMenu: PropTypes.func.isRequired,
}
