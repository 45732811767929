import React, { useState, useMemo } from 'react'
import { Route } from 'react-router-dom'
import { Grid, Typography as Text } from '@material-ui/core'

import { PERIOD_TYPES } from './MarketVisualisation.constants'
import { typeMap } from './containers/periodRow/PeriodRow.constants'
import { useFilters } from './utils/useFilters'

import { PeriodRow } from './containers/periodRow/PeriodRow'
import { Filters } from './components/Filters'
import { GoBackButton } from 'screens/marketVisualisation/components/GoBackButton'
import { MARKET_VISUALISATION } from 'routes'
import { useRouter } from 'utils/useRouter'
import { useRouteParamsUpdater } from 'utils/useRouteParamsUpdater'

import ViewOrder from 'screens/orderView/ViewOrder'
import ViewTermsheet from 'screens/termsheetView/ViewTermsheet'

const MarketVisualisation = () => {
  const { match, history } = useRouter()
  const { filters, onFilterChange, onFiltersReset, filtersTouched, options } =
    useFilters()

  const [selectedPeriod, setSelectedPeriod] = useState(null)
  const params = useMemo(() => ({ status: filters.status }), [filters.status])
  useRouteParamsUpdater(params)

  return (
    <>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid
          item
          container
          style={{ flexShrink: 0 }}
          direction="row"
          alignItems="center"
        >
          <Text variant="h1">
            Market Visualisation{' '}
            {selectedPeriod && `- ${typeMap[selectedPeriod].name}`}
          </Text>
          {selectedPeriod && (
            <GoBackButton onClick={() => setSelectedPeriod(null)} />
          )}
        </Grid>
        <Grid item container alignItems="center">
          <Filters
            filters={filters}
            onChange={onFilterChange}
            onReset={onFiltersReset}
            options={options}
            touched={filtersTouched}
          />
        </Grid>
      </Grid>

      {selectedPeriod ? (
        <PeriodRow type={selectedPeriod} filters={filters} expanded />
      ) : (
        PERIOD_TYPES.map((type) => (
          <PeriodRow
            type={type}
            filters={filters}
            onExpand={setSelectedPeriod}
            key={`period-row-${type}`}
          />
        ))
      )}
      <Route
        path={`${match.path}/orders/:id`}
        component={() => (
          <ViewOrder onClose={() => history.push(MARKET_VISUALISATION)} />
        )}
      />
      <Route path={`${match.path}/termsheets/:id`} component={ViewTermsheet} />
    </>
  )
}

export default MarketVisualisation
