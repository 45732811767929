import React from 'react'
import {
  $OC,
  PRICE_TYPE_KEYS,
  TERM_SPOT_VALUES,
  VOLUME_TYPE_KEYS,
  $O,
  ORDER_STATUS,
  PRICE_TYPE,
  TRADING_TYPE
} from 'emsurge-selectors'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import {
  AccordionDetails,
} from '@material-ui/core'
import Accordion from '../../orderView/components/Accordion'
import DetailRow from '../../orderView/components/DetailRow'
import DetailDivider from '../../orderView/components/DetailDivider'
import { getPartyFromOrderInTermsheet } from 'screens/orderView/helpers'
import {
  STEP_CARBON_ESSENTIAL,
  TAB_CARBON,
  useActiveStep,
} from 'containers/OrderProvider'

const _EssentialPanel = ({ order, isCreatingTrade, isTermSheetSummary, isEdit }) => {
  const { navigate } = useActiveStep()
  const stakeholder = $OC.stakeholder.summary(order)
  const tradingType = $OC.tradingType.text(order).toUpperCase()
  const orderStatus = $OC.status.text(order)
  const carbonType = $OC.carbonType.text(order)
  const termVintage = $OC.termVintage.text(order)
  const termVintageDeliveredBy = $OC.termVintage.deliveredBy.text(order)
  const volume = $OC.volume.text(order)
  const price = $OC.carbonPrice.text(order)
  const showCurrency = ![PRICE_TYPE_KEYS.RFQ, PRICE_TYPE_KEYS.TBD].includes(
    price.value
  )

  const navigateTo = (tab, step) => navigate.goTo({ tab, step })
  const navigateToGeneral = () =>
    navigateTo(TAB_CARBON.ESSENTIAL, STEP_CARBON_ESSENTIAL.GENERAL)
  const navigateToType = () =>
    navigateTo(TAB_CARBON.ESSENTIAL, STEP_CARBON_ESSENTIAL.TYPE)
  const navigateToTerm = () =>
    navigateTo(TAB_CARBON.ESSENTIAL, STEP_CARBON_ESSENTIAL.TERM)
  const navigateToVolume = () =>
    navigateTo(TAB_CARBON.ESSENTIAL, STEP_CARBON_ESSENTIAL.VOLUME)
  const navigateToPrice = () =>
    navigateTo(TAB_CARBON.ESSENTIAL, STEP_CARBON_ESSENTIAL.PRICE)

  const priceType = price.typeText?.toLowerCase()
  let priceRFQNotes
  let priceRFQDeadLineDate
  let priceRFQAwardDate
  let seller
  let buyer
  if(isCreatingTrade) {
    buyer = order.buyer
    seller = order.seller
  }
  else if(isTermSheetSummary || isEdit) {
    const buyerOrder = order.orders.find(x => x.tradingType === TRADING_TYPE.BID)
    const sellerOrder = order.orders.find(x => x.tradingType === TRADING_TYPE.ASK)
    buyer = {
      ...getPartyFromOrderInTermsheet(buyerOrder, order),
      ...order.bid
    }
    seller = { 
      ...getPartyFromOrderInTermsheet(sellerOrder, order),
      ...order.ask
    }
  }
  if(priceType === PRICE_TYPE.RFQ ) {
    priceRFQNotes = price.detail?.find(x => x.title === 'Notes')
    priceRFQDeadLineDate =  price.detail?.find(x => x[0].title === 'Deadline Date')[0]
    priceRFQAwardDate = price.detail?.find(x => x[0].title === 'Award Date')[0]
  }

  const getAdditionalPartyDescription = (additionalParty) => {
    let result = ''
    if(additionalParty?.name || additionalParty?.email || additionalParty?.phone)
    {
      result = `Name: ${additionalParty.name ?? ''}, Email: ${additionalParty.email ?? ''}, Phone: ${additionalParty.phone ?? ''}`
    }
    return result
  }

  return (
    <Accordion defaultExpanded>
      <AccordionDetails
        style={{ flexDirection: 'column' }}
        id="orderTerms"
      >
        {(isCreatingTrade || isTermSheetSummary) && (
          <>
            <DetailRow
              onClick={() => navigateToGeneral()}
              title="Buyer"
              info={`Company: ${buyer?.company?.name}, Entity: ${buyer?.entity?.name}, Trader: ${buyer?.person?.name}`}
              gutterBottom
            /> 

            <DetailRow
              onClick={() => navigateToGeneral()}
              title="Buyer Execution Trader"
              info={getAdditionalPartyDescription(buyer?.executionTrader)}
              gutterBottom
            />

            <DetailRow
              onClick={() => navigateToGeneral()}
              title="Seller"
              info={`Company: ${seller?.company?.name}, Entity: ${seller?.entity?.name}, Trader: ${seller?.person?.name}`}
              gutterBottom
            />

            <DetailRow
              onClick={() => navigateToGeneral()}
              title="Seller Execution Trader"
              info={getAdditionalPartyDescription(seller?.executionTrader)}
              gutterBottom
            />
          </>
        )}

        {!(isCreatingTrade || isTermSheetSummary) && (
          <>
            <DetailRow
              title="Company"
              data-testid="order-overview-essential-company-name"
              info={stakeholder.company}
              onClick={() => navigateToGeneral()}
            />
            <DetailRow
              title="Entity"
              data-testid="order-overview-essential-entity-name"
              info={stakeholder.entity}
              onClick={() => navigateToGeneral()}
            />
            <DetailRow
              title="Trader"
              data-testid="order-overview-essential-trader-name"
              info={stakeholder.person}
              onClick={() => navigateToGeneral()}
            />
          </>
        )}
        {!(isCreatingTrade || isTermSheetSummary) && (
          <>
            <DetailRow
              title="Type"
              data-testid="order-overview-essential-type"
              info={tradingType}
              onClick={() => navigateToGeneral()}
            />
            <DetailRow
            title="Status"
            data-testid="order-overview-essential-status"
            info={orderStatus}
            onClick={() => navigateToGeneral()}
            />
          </>
        )}
        <DetailRow
          title = {(isCreatingTrade || isTermSheetSummary) ? "Trade Date" : "Submission Date"}
          data-testid="order-overview-essential-submission-date"
          info={$O.submittedAt.text(order)}
          onClick={() => navigateToGeneral()}
        />
        {$O.status.get(order) !== ORDER_STATUS.PENDING && !(isCreatingTrade || isTermSheetSummary) && (
          <DetailRow
            title="Visibility"
            data-testid="order-overview-visibility"
            info={$O.validity.text(order)}
            onClick={() => navigateToGeneral()}
          />
        )}

        <DetailDivider />

        <DetailRow
          title="Programme Eligibility"
          data-testid="order-overview-essential-program-eligibility"
          info={carbonType.programEligibility}
          onClick={() => navigateToType()}
        />
        <DetailRow
          title="Carbon Standard"
          data-testid="order-overview-essential-carbon-standard"
          info={carbonType.carbonStandard}
          onClick={() => navigateToType()}
        />
        <DetailRow
          title="Title Transfer"
          data-testid="order-overview-essential-title-transfer"
          info={carbonType.titleTransfer}
          onClick={() => navigateToType()}
        />
        <DetailRow
          title="Classifications"
          data-testid="order-overview-essential-classifications"
          info={carbonType.classifications}
          onClick={() => navigateToType()}
        />

        <DetailDivider />

        <DetailRow
          title="Term"
          data-testid="order-overview-essential-term"
          info={termVintage.termSpot}
          onClick={() => navigateToTerm()}
        />
        {termVintage.termSpot === TERM_SPOT_VALUES.SPOT && (
          <DetailRow
            title="Vintage"
            data-testid="order-overview-essential-vintage"
            info={termVintage.vintage}
            onClick={() => navigateToTerm()}
          />
        )}
        {termVintage.termSpot === TERM_SPOT_VALUES.TERM && (
          <>
            <DetailRow
              title="Range"
              data-testid="order-overview-essential-term-range"
              info={termVintage.offset}
              onClick={() => navigateToTerm()}
            />
            <DetailRow
              title="Term"
              data-testid="order-overview-essential-term-period"
              info={termVintage.term}
              onClick={() => navigateToTerm()}
            />
            <DetailRow
              title="Vintage"
              data-testid="order-overview-essential-term-vintage"
              info={termVintage.vintage}
              onClick={() => navigateToTerm()}
            />
          </>
        )}
        {termVintageDeliveredBy && (<DetailRow
          title="Delivery"
          info={termVintageDeliveredBy}
          onClick={() => navigateToTerm()}
        />)}
        
        <DetailRow
          title="Project Status"
          data-testid="order-overview-essential-project-status"
          info={termVintage.projectStatus}
          onClick={() => navigateToTerm()}
        />
        {termVintage.termSpot === TERM_SPOT_VALUES.INVESTMENT && (
          <DetailRow
            data-testid="investment-description-field"
            title="Description"
            info={termVintage.investmentDescription}
            onClick={() => navigateToTerm()}
          />
        )}

        <DetailDivider />

        <DetailRow
          title="Volume Type"
          data-testid="order-overview-essential-volume-type"
          info={volume.textType}
          onClick={() => navigateToVolume()}
        />
        <DetailRow
          title="Volume"
          data-testid="order-overview-essential-volume"
          info={volume.volume}
          onClick={() => navigateToVolume()}
        />
        {volume.type === VOLUME_TYPE_KEYS.PER_ANNUM && (
          <>
            <DetailRow
              title="First Year"
              data-testid="order-overview-essential-volume-first-year"
              info={volume.firstYear}
              onClick={() => navigateToVolume()}
            />
            <DetailRow
              title="Last Year"
              data-testid="order-overview-essential-volume-last-year"
              info={volume.lastYear}
              onClick={() => navigateToVolume()}
            />
          </>
        )}
        <DetailRow
          title="Minimum Quantity"
          data-testid="order-overview-essential-volume-offset"
          info={volume.offset}
          onClick={() => navigateToVolume()}
        />

        <DetailDivider />

        {priceType === PRICE_TYPE.RFQ && (
           <>
            <DetailRow
              title='Price'
              info={[price.typeText, `${priceRFQDeadLineDate.title}: ${priceRFQDeadLineDate.text}`]}
            />
            <DetailRow
                info={['', `${priceRFQAwardDate.title}: ${priceRFQAwardDate.text}`]}
            />
            <DetailRow
                title={priceRFQNotes?.title}
                info={priceRFQNotes?.text}
                onClick={() => navigateToPrice()}
            />
         </>
        )}

        {(() => { //Because we have problems debugging the conventional syntax '{ conditoin && '
          if (priceType !== PRICE_TYPE.RFQ && price.typeText) {
            return (
              <DetailRow
              title="Price"
              data-testid="order-overview-essential-price-type"
              info={price.typeText}
              onClick={() => navigateToPrice()}
            />
            )
          }
        })()}

        {(() => { //Because we have problems debugging the conventional syntax '{ conditoin && '         
          if (priceType !== PRICE_TYPE.RFQ && !price.detail && price.value !== '') {
            return (
              <DetailRow
                title="Price"
                data-testid="order-overview-essential-price"
                info={price.value}
                onClick={() => navigateToPrice()}
                showCurrency={price.hasUnit && showCurrency}
              />
            )
          }
        })()}
        {priceType !== PRICE_TYPE.RFQ && price.detail &&
          price.detail.map((detail, index) => (
            <div key={`detail-${index}`}>
              <DetailRow
                data-testid={`order-overview-essential-price-detail-${index}`}
                key={`price-detail-${index}`}
                title={detail.title}
                info={detail.text}
                onClick={() => navigateToPrice()}
                showCurrency={showCurrency}
                showVolume={true}
              />
            </div>
          ))}
      </AccordionDetails>
    </Accordion>
  )
}

_EssentialPanel.propTypes = {
  order: PropTypes.object.isRequired,
}

export const EssentialPanel = connect(_EssentialPanel)
