import moment from 'moment-timezone'

export const mapToDateTime = ({ date, time, timezone }) => {
  const currentDateTimeWithTimezone = `${date}T${time}${timezone}`
  const currentDate = moment(currentDateTimeWithTimezone).parseZone()

  return currentDate.isValid() ? currentDate.format('YYYY-MM-DDTHH:mmZ') : null
}

export const parseMomentDate = (dateTime = new Date()) => {
  const mDateTime = moment(dateTime).parseZone()
  const date = mDateTime.format('YYYY-MM-DD')
  const time = mDateTime.format('HH:mm')

  // return the uiDateTime
  return {
    date,
    time,
    timezone: mDateTime.format('Z'),
  }
}
