import React from 'react'
import {
  ADDITIONAL_ATTRIBUTES_OPTIONS,
  ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX,
} from 'emsurge-selectors'
import { connect } from 'formik'
import { CheckboxGroup } from './CheckboxGroup'

export const _AdditionalAttributesOptions = () => {
  return (
    <CheckboxGroup
      key="radio-button-group-additional-attributes"
      label="Certifications"
      fieldPrefix={ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX}
      options={ADDITIONAL_ATTRIBUTES_OPTIONS}
    />
  )
}

export const AdditionalAttributesOptions = connect(_AdditionalAttributesOptions)
