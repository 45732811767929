import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import {
  TERM_SPOT_PREFIX,
  TERM_SPOT_KEYS,
  PROJECT_INFORMATION_OPTIONS,
  PROJECT_INFORMATION_KEYS,
} from 'emsurge-selectors'
import { get } from 'lodash'
import { TermVintageRadio } from '../components/TermVintageRadio'
import { TermVintageSpot } from './TermVintageSpot'
import { TermVintageTerm } from './TermVintageTerm'
import { TermVintageInvestment } from './TermVintageInvestment'

const _TermVintage = ({ formik }) => {
  const { values, setFieldValue } = formik
  const selectedTerm = get(values, TERM_SPOT_PREFIX) || TERM_SPOT_KEYS.SPOT
  const [term, setTerm] = useState(selectedTerm)

  const setDefaultSelectionForInvestment = (selectedTerm) => {
    if (selectedTerm === TERM_SPOT_KEYS.INVESTMENT) {
      PROJECT_INFORMATION_OPTIONS.forEach(({ key, value: path }) => {
        const isSelected = key === PROJECT_INFORMATION_KEYS.SEEKING_INVESTMENT
        setFieldValue(path, isSelected)
      })
    }
  }

  const setDefaultSelectionForSpot = (selectedTerm) => {
    if (selectedTerm === TERM_SPOT_KEYS.SPOT) {
      PROJECT_INFORMATION_OPTIONS.forEach(({ key, value: path }) => {
        const isSelected = key === PROJECT_INFORMATION_KEYS.ACTIVE
        setFieldValue(path, isSelected)
      })
    }
  }

  useEffect(() => {
    setTerm(get(values, TERM_SPOT_PREFIX))
  }, [values.termVintage.termSpot])

  const onChange = (term) => {
    setDefaultSelectionForInvestment(term)
    setDefaultSelectionForSpot(term)
  }

  return (
    <>
      <TermVintageRadio onChange={onChange} />
      {term === TERM_SPOT_KEYS.SPOT && <TermVintageSpot />}
      {term === TERM_SPOT_KEYS.TERM && <TermVintageTerm />}
      {term === TERM_SPOT_KEYS.INVESTMENT && <TermVintageInvestment />}
    </>
  )
}

export const TermVintage = connect(_TermVintage)
