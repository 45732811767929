import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray, connect } from 'formik'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'

import { PRICE_VARIANT } from 'emsurge-selectors/constants'
import { Indexes } from 'screens/orderCreate/components/Indexes'
import { PlusOrMinus } from 'screens/orderCreate/components/PlusOrMinus'
import Card from 'screens/orderCreate/components/Card'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'
import { AddButton } from 'screens/orderCreateCommon/components/AddButton'
import {
  TextField,
  UppercaseTextField,
} from 'screens/orderCreateCommon/components/TextField'
import { DEFAULT_HYBRID_PRICE } from 'screens/orderCreate/containers/informationInitialValues'
import { Unit } from 'screens/orderCreate/components/Unit'
import { PriceDeliveryContractFields } from 'screens/orderCreate/components/PriceDeliveryContractFields'

const _Hybrid = ({ variant, formik }) => {
  const { values } = formik

  return (
    <FieldArray
      name={`price.${variant}.hybridInfo`}
      render={(hybridInfoArray) => (
        <section>
          {values.price[variant].hybridInfo.map((_, idx) => (
            <Card key={idx}>
              <CloseButton onClick={() => hybridInfoArray.remove(idx)} />

              <Text variant="h6">Part {idx + 1}</Text>

              <Grid container spacing={2}>
                <Grid item xs>
                  <TextField
                    name={`price.${variant}.hybridInfo[${idx}].weight`}
                    label="Weight"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 100,
                      'data-testid': 'hybrid-price-weight',
                    }}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    type="number"
                    name={`price.${variant}.hybridInfo[${idx}].percentage`}
                    label="Percentage"
                    inputProps={{
                      min: 0,
                      max: 100,
                      'data-testid': 'hybrid-price-percentage',
                    }}
                  />
                </Grid>

                <Grid data-testid="hybrid-price-index-dropdown" item xs>
                  <Indexes name={`price.${variant}.hybridInfo[${idx}].index`} />
                </Grid>

                <PriceDeliveryContractFields
                  rootPath={`price.${variant}.hybridInfo[${idx}]`}
                />

                <Grid
                  data-testid="hybrid-price-plus-or-minus-dropdown"
                  item
                  xs={1}
                >
                  <PlusOrMinus
                    name={`price.${variant}.hybridInfo[${idx}].plusOrMinus`}
                  />
                </Grid>

                <Grid item xs>
                  <UppercaseTextField
                    name={`price.${variant}.hybridInfo[${idx}].amount`}
                    label="Amount"
                    data-testid="hybrid-price-amount"
                  />
                </Grid>

                <Grid data-testid="hybrid-price-unit-dropdown" item xs>
                  <Unit name={`price.${variant}.hybridInfo[${idx}].unit`} />
                </Grid>
              </Grid>
            </Card>
          ))}

          <footer>
            <AddButton
              data-testid="hybrid-price-add-additional-price"
              onClick={() => hybridInfoArray.push(DEFAULT_HYBRID_PRICE)}
            >
              Add additional price
            </AddButton>
          </footer>
        </section>
      )}
    />
  )
}

_Hybrid.propTypes = {
  variant: PropTypes.oneOf(Object.values(PRICE_VARIANT)).isRequired,
  formik: PropTypes.object.isRequired,
}

export const Hybrid = connect(_Hybrid)
