import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { pick } from 'lodash'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import { withSnackbar } from 'notistack'
import { getAPIData } from 'screens/noteCreate/containers/FormProvider'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const getInitialValues = (note) => {
  return {
    ...pick(note, ['companyName', 'note', 'priority', 'country']),
    attendees: note.attendees.join(', '),
    tags: note.tags.join(', '),
    eventDate: note.eventDate,
    actionItems: note.actionItems.map((item) => ({
      ...item,
      dueDate: moment(item.dueDate).format('YYYY-MM-DDTHH:mm'),
    })),
  }
}

const FormProvider = ({ children, enqueueSnackbar, note }) => {
  const api = useApi()

  const handleSubmit = async (values, actions) => {
    const data = getAPIData(values)

    try {
      await api.patch(`/notes/${note.id}`, data)
      enqueueSnackbar('Note updated successfully', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Note update failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      validateOnMount={true}
      initialValues={getInitialValues(note)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {children}
    </Formik>
  )
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  note: PropTypes.object.isRequired,
}

export default compose(withSnackbar, withRouter)(FormProvider)
