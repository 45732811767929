import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import { Grid, InputAdornment, Typography } from '@material-ui/core'
import {
  TERM_SPOT_VINTAGE_ROLLING_PREFIX,
  getRollingRangeMessage,
} from 'emsurge-selectors'
import { get } from 'lodash'
import FieldCard from 'screens/orderCreateCommon/components/FieldCard'
import { TextField } from 'screens/orderCreateCommon/components/TextField'

const WITHIN_PATH = `${TERM_SPOT_VINTAGE_ROLLING_PREFIX}.within`
const YEAR_PATH = `${TERM_SPOT_VINTAGE_ROLLING_PREFIX}.year`

const _RollingRange = ({
  formik: { values, initialValues, setFieldValue },
}) => {
  const getValue = (path) => get(values, path) || get(initialValues, path)

  const [within, setWithin] = useState(getValue(WITHIN_PATH))
  const [year, setYear] = useState(getValue(YEAR_PATH))
  const [text, setText] = useState('')

  useEffect(() => {
    setFieldValue(WITHIN_PATH, within)
    setFieldValue(YEAR_PATH, year)
    setText(getRollingRangeMessage(within, year))
  }, [within, year])

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <TextField
          data-testid="rolling-range-within-field"
          fullWidth
          type="number"
          name={WITHIN_PATH}
          label="Within"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">Month</InputAdornment>
            ),
          }}
          value={within}
          onChange={(evt) => setWithin(evt.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          data-testid="rolling-range-no-earlier-than-field"
          fullWidth
          type="number"
          name={YEAR_PATH}
          label="No Earlier than"
          value={year}
          onChange={(evt) => setYear(evt.target.value)}
        />
      </Grid>
      <Grid item xs={7}>
        <FieldCard title="Range">
          <Typography data-testid="rolling-range-text" noWrap>
            {text}
          </Typography>
        </FieldCard>
      </Grid>
    </Grid>
  )
}

export const RollingRange = connect(_RollingRange)
