import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Div = styled.div`
  --simple-modal__content-padding: ${({ theme }) => theme.spacing(3)}px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
`

export const Content = ({ children }) => {
  return <Div>{children}</Div>
}

Content.propTypes = {
  children: PropTypes.node,
}
