import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import {
  STAKEHOLDER_TYPE,
  STAKEHOLDER_TYPE_TEXT,
} from 'emsurge-selectors/constants'

export const StakeholderType = ({ type, handleChange, options }) => {
  return (
    <FormControl>
      <Grid container justifyContent="center">
        {Object.values(STAKEHOLDER_TYPE).map((STAKEHOLDER_TYPE_VALUE) => {
          return (
            <Grid item sm={6} key={STAKEHOLDER_TYPE_VALUE}>
              <Typography component="div" align="center">
                <FormControlLabel
                  label={STAKEHOLDER_TYPE_TEXT[STAKEHOLDER_TYPE_VALUE]}
                  value={STAKEHOLDER_TYPE_VALUE}
                  disabled={!!(options[STAKEHOLDER_TYPE_VALUE] || {}).disabled}
                  control={
                    <Radio
                      inputProps={{ 'data-testid': STAKEHOLDER_TYPE_VALUE }}
                      onChange={(ev) => handleChange(ev.target.value)}
                      checked={type === STAKEHOLDER_TYPE_VALUE}
                    />
                  }
                />
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    </FormControl>
  )
}

StakeholderType.propTypes = {
  type: PropTypes.oneOf(Object.values(STAKEHOLDER_TYPE)).isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.object,
}

StakeholderType.defaultProps = {
  options: Object.values(STAKEHOLDER_TYPE).reduce(
    (options, STAKEHOLDER_TYPE_VALUE) => {
      options[STAKEHOLDER_TYPE_VALUE] = { disabled: false }

      return options
    },
    {}
  ),
}
