import React from 'react'
import { __RouterContext } from 'react-router'

/* :: () -> object */
export const useRouter = () => React.useContext(__RouterContext)

/* :: () -> object */
export const useUrlParams = () => useRouter().match.params

export default this
