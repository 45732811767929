const TERM_FIXED_ROLLING_KEYS = {
  FIXED: 'FIXED',
  ROLLING: 'ROLLING',
}

const TERM_FIXED_ROLLING_VALUES = {
  [TERM_FIXED_ROLLING_KEYS.FIXED]: 'Fixed Range',
  [TERM_FIXED_ROLLING_KEYS.ROLLING]: 'Rolling Range',
}

const TERM_FIXED_ROLLING = [
  {
    value: TERM_FIXED_ROLLING_KEYS.FIXED,
    label: TERM_FIXED_ROLLING_VALUES[TERM_FIXED_ROLLING_KEYS.FIXED],
  },
  {
    value: TERM_FIXED_ROLLING_KEYS.ROLLING,
    label: TERM_FIXED_ROLLING_VALUES[TERM_FIXED_ROLLING_KEYS.ROLLING],
  },
]

const getRollingRangeMessage = (within, year) =>
  `Within ${within} months of Offset Title Transfer/Retirement Period, and no earlier than ${year}`

module.exports = {
  TERM_FIXED_ROLLING_KEYS,
  TERM_FIXED_ROLLING_VALUES,
  TERM_FIXED_ROLLING,
  getRollingRangeMessage,
}
