const { mapCountriesToData, OPTION_TYPE_IS_LDC } = require('./helpers')

const COUNTRIES_OCEANIA_KEYS = {
  AUSTRALIA: 'AUSTRALIA',
  NEW_ZEALAND: 'NEW_ZEALAND',
  FIJI: 'FIJI',
  NEW_CALEDONIA: 'NEW_CALEDONIA',
  PAPUA_NEW_GUINEA: 'PAPUA_NEW_GUINEA',
  SOLOMON_ISLANDS: 'SOLOMON_ISLANDS',
  VANUATU: 'VANUATU',
  GUAM: 'GUAM',
  KIRIBATI: 'KIRIBATI',
  MARSHALL_ISLANDS: 'MARSHALL_ISLANDS',
  MICRONESIA_FED_STATES_OF: 'MICRONESIA_FED_STATES_OF',
  NAURU: 'NAURU',
  NORTHERN_MARIANA_ISLANDS: 'NORTHERN_MARIANA_ISLANDS',
  PALAU: 'PALAU',
  AMERICAN_SAMOA: 'AMERICAN_SAMOA',
  COOK_ISLANDS: 'COOK_ISLANDS',
  FRENCH_POLYNESIA: 'FRENCH_POLYNESIA',
  NIUE: 'NIUE',
  SAMOA: 'SAMOA',
  TOKELAU: 'TOKELAU',
  TONGA: 'TONGA',
  TUVALU: 'TUVALU',
  WALLIS_AND_FUTUNA_ISLANDS: 'WALLIS_AND_FUTUNA_ISLANDS',
}

const COUNTRIES_OCEANIA_VALUES = {
  AUSTRALIA: 'Australia',
  NEW_ZEALAND: 'New Zealand',
  FIJI: 'Fiji',
  NEW_CALEDONIA: 'New Caledonia',
  PAPUA_NEW_GUINEA: 'Papua New Guinea',
  SOLOMON_ISLANDS: 'Solomon Islands',
  VANUATU: 'Vanuatu',
  GUAM: 'Guam',
  KIRIBATI: 'Kiribati',
  MARSHALL_ISLANDS: 'Marshall Islands',
  MICRONESIA_FED_STATES_OF: 'Micronesia (Fed. States of)',
  NAURU: 'Nauru',
  NORTHERN_MARIANA_ISLANDS: 'Northern Mariana Islands',
  PALAU: 'Palau',
  AMERICAN_SAMOA: 'American Samoa',
  COOK_ISLANDS: 'Cook Islands',
  FRENCH_POLYNESIA: 'French Polynesia',
  NIUE: 'Niue',
  SAMOA: 'Samoa',
  TOKELAU: 'Tokelau',
  TONGA: 'Tonga',
  TUVALU: 'Tuvalu',
  WALLIS_AND_FUTUNA_ISLANDS: 'Wallis and Futuna Islands',
}

const COUNTRIES_OCEANIA_INCLUDED_LDCS = [
  COUNTRIES_OCEANIA_KEYS.SOLOMON_ISLANDS,
  COUNTRIES_OCEANIA_KEYS.VANUATU,
  COUNTRIES_OCEANIA_KEYS.KIRIBATI,
  COUNTRIES_OCEANIA_KEYS.TUVALU,
]

const COUNTRIES_OCEANIA = mapCountriesToData(
  COUNTRIES_OCEANIA_KEYS,
  COUNTRIES_OCEANIA_VALUES,
  {
    type: OPTION_TYPE_IS_LDC,
    elements: COUNTRIES_OCEANIA_INCLUDED_LDCS,
  }
)

module.exports = {
  COUNTRIES_OCEANIA_KEYS,
  COUNTRIES_OCEANIA_VALUES,
  COUNTRIES_OCEANIA,
}
