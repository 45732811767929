import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import MuiText from '@material-ui/core/Typography'

export const SmallText = styled(MuiText)`
  white-space: nowrap;
  font-size: 12px;
  line-height: 15px;
`

export const XsmallText = styled(MuiText)`
  white-space: nowrap;
  font-size: 10px;
  height: 13px;
  line-height: 13px;
  color: #9097a1;
`

export const SuperSmallText = styled(MuiText)`
  white-space: nowrap;
  font-size: 10px;
  height: 10px;
  line-height: 10px;
  color: #9097a1;
`

export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

const useTextStyles = makeStyles(({ spacing }) => ({
  root: {
    fontWeight: 400,
    fontSize: '0.725rem',
    lineHeight: 1.85,
    color: ({ to }) => (to ? '#fff' : 'inherit'),
    marginRight: ({ last }) => (last ? 0 : spacing(3)),
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:hover': { textDecoration: ({ to }) => (to ? 'underline' : 'none') },
  },
}))

const Text = ({ children, to, last }) => {
  const classes = useTextStyles({ to, last })

  return (
    <MuiText
      variant="subtitle1"
      className={classes.root}
      component={to ? Link : undefined}
      to={to}
    >
      {children}
    </MuiText>
  )
}

Text.defaultProps = {
  last: false,
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  last: PropTypes.bool,
}

export default Text
