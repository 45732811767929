import moment from 'moment'
import { get } from 'lodash'
import { getNumberWithOrdinalSuffix } from 'utils/numbers'
import { calculateCargoDeliverWindow } from 'utils/dataHandlers'

export const getDefaultTimeFrameValue = (timeframe) => {
  switch (timeframe) {
    case 'fixed_date':
      return moment().format('YYYY-MM-DD')
    case 'point_trade':
      return undefined
    default:
      return ''
  }
}

export const getTimeFrameValueProps = (timeframe) => {
  const props = {
    label: 'Number of days',
    type: 'number',
  }

  switch (timeframe) {
    case 'month_ahead':
      return {
        ...props,
        label: 'Day of the month',
        inputProps: {
          min: 1,
          max: 31,
        },
      }
    case 'fixed_date':
      return {
        label: 'Date',
        type: 'date',
      }
    case 'days_after':
      return {
        ...props,
        label: 'Number of days',
        inputProps: {
          min: 1,
          max: 31,
        },
      }
    default:
      return props
  }
}

export const getTimeFrameValueDeliveryWindowOptions = ({ values }) => {
  const firstCargo = get(values, 'nominationRules.deliveryWindow.cargos[0]', {})
  const { interval, periodName } = calculateCargoDeliverWindow(firstCargo)
  const firstDeliveryWindowOption = `1st DW (${interval} ${periodName} DW)`
  const narrowingMechanisms = get(
    values,
    'nominationRules.deliveryWindow.alternatives',
    []
  )

  const narrowMechanismsOptions = narrowingMechanisms.map(
    (narrowingMechanism, index) => {
      const numberLabel = getNumberWithOrdinalSuffix(index + 2)

      return `${numberLabel} DW (${narrowingMechanism.name} Day DW)`
    }
  )

  return [firstDeliveryWindowOption, ...narrowMechanismsOptions]
}
