import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { useHistory } from 'react-router'
import { noop } from 'lodash'
import { mapFormikValuesToOrder } from '../helpers'
import { ConfirmAndShareButton } from '../components/Buttons'
import { getOrderInfo as getOrderInfoLNG } from 'screens/orderCreate/containers/FormProvider'
import { getOrderInfo as getOrderInfoCarbon } from 'screens/orderCreateCarbon/FormProvider'
import { useUser } from 'containers/user/useUser'
import { useGetBrokerForEntityAndShareOrder } from 'hooks'
import { useCreateOrder, useEditOrder } from 'api'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { ORDER_BASE_ROUTES, DASHBOARD } from 'routes'
import { ORDER_FLOW, useOrderFlow } from 'containers/OrderProvider'
import useAppContext from 'containers/appContext/useAppContext'

const _ConfirmShareButton = ({ formik, onCancel, ...props }) => {
  const { user } = useUser()
  const { mutateAsync: createOrder } = useCreateOrder()
  const { mutateAsync: editOrder } = useEditOrder()
  const { flow } = useOrderFlow()
  const BASE_SLUG = useRouteBaseSlug()
  const history = useHistory()
  const { isLNGContext } = useAppContext()
  const { broker, mutateAsync: shareWithBroker } = useGetBrokerForEntityAndShareOrder(
    mapFormikValuesToOrder(formik.values)?.entityId
  )
  const orderCreateOrUpdate = useCallback(
    async (data) => {
      if (flow === ORDER_FLOW.EDIT) {
        const newData = await editOrder({ id: formik.values.id, ...data })
        return {
          id: formik.values.id,
          ...newData,
        }
      }
      if (flow === ORDER_FLOW.CREATE) {
        return createOrder(data)
      }
    },
    [createOrder, editOrder, flow, formik.values]
  )

  const onShare = async () => {
    const getOrderInfo = isLNGContext ? getOrderInfoLNG : getOrderInfoCarbon
    const data = getOrderInfo(formik.values, user)
    formik.setSubmitting(true)
    const order = await orderCreateOrUpdate(data)
    await shareWithBroker({ orderId: order.id, broker })
    formik.setSubmitting(false)
    onCancel()
    const slug = ORDER_BASE_ROUTES.includes(`/${BASE_SLUG}`)
      ? `/${BASE_SLUG}`
      : DASHBOARD
    history.push(`${slug}/orders/${order.id}`)
  }

  if (![ORDER_FLOW.EDIT, ORDER_FLOW.CREATE].includes(flow)) {
    return null
  }

  return <ConfirmAndShareButton onClick={onShare} {...props} />
}

_ConfirmShareButton.defaultProps = {
  onCancel: noop,
}

_ConfirmShareButton.propTypes = {
  formik: PropTypes.object,
  onCancel: PropTypes.func,
}

export const ConfirmShareButton = connect(_ConfirmShareButton)
