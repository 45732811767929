import React, { memo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { noop, partial } from 'lodash'
import { VariableSizeList, FixedSizeList, areEqual } from 'react-window'
import Autosizer from 'react-virtualized-auto-sizer'

// react-window caches indices dimensions, if the list is dynamic we need to reset the list
const useResetCache = (items, isVariableSizedList, setRef) => {
  const ref = useRef()
  setRef(ref)

  useEffect(() => {
    if (!ref.current || !items.length || !isVariableSizedList) {
      return
    }
    ref.current.resetAfterIndex(0)
  }, [isVariableSizedList, items])
  return ref
}

/**
 * Virtualises a list
 * @param {Boolean} fillParent - should the list fill the parent?
 * @param {Function} rowRenderer - receives index and returns item to render at that index
 * @param {Number} width - width of the container (when fillParent is false)
 * @param {Number} height - height of the container (when fillParent is false)
 * @param {Function} getItemSize - receives index and returns item size, where size is height for vertical lists or width for horizontal lists. Use it when item size is not known beforehand.
 * @param {Number} itemSize - like getItemSize() but to be used when item size is constant and known beforehand.
 * @param {Number} scrollTo - current index to display in the viewport
 * @param {Function} onItemsRendered - callback sent by VirtualListInfinite that triggers on item rendering
 * @param {Function} setRef - callback ref sent by VirtualListInfinite to be called with VirtualList ref
 */
export const _VirtualList = ({
  fillParent,
  rowRenderer,
  width,
  height,
  items,
  getItemSize,
  itemSize,
  scrollTo,
  onItemsRendered,
  setRef,
}) => {
  const IS_VARIABLE_SIZED_LIST = Boolean(getItemSize)
  const listRef = useResetCache(items, IS_VARIABLE_SIZED_LIST, setRef)
  const MemoizedRowRenderer = memo(rowRenderer, areEqual)

  useEffect(() => {
    if (!listRef.current) {
      return
    }
    listRef.current.scrollToItem(scrollTo, 'smart')
  }, [listRef, scrollTo])

  const renderList = ({ width, height }) => {
    const props = { itemCount: items.length, width, height }

    if (IS_VARIABLE_SIZED_LIST) {
      return (
        <VariableSizeList
          itemSize={partial(getItemSize, items)}
          ref={listRef}
          onItemsRendered={onItemsRendered}
          itemData={items}
          {...props}
        >
          {MemoizedRowRenderer}
        </VariableSizeList>
      )
    }
    return (
      <FixedSizeList
        itemSize={itemSize}
        ref={listRef}
        onItemsRendered={onItemsRendered}
        itemData={items}
        {...props}
      >
        {MemoizedRowRenderer}
      </FixedSizeList>
    )
  }

  renderList.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
  }

  const renderContainer = (renderList) => {
    if (fillParent) {
      return (
        <Autosizer>
          {({ width, height }) => renderList({ width, height })}
        </Autosizer>
      )
    }

    return renderList({ width, height })
  }

  return renderContainer(renderList)
}

_VirtualList.defaultProps = {
  fillParent: true,
  rowRenderer: noop,
  width: 0,
  height: 0,
  items: [],
  scrollTo: 0,
  itemSize: 0,
  setRef: () => {},
}

_VirtualList.propTypes = {
  fillParent: PropTypes.bool,
  rowRenderer: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  items: PropTypes.array,
  getItemSize: PropTypes.func,
  itemSize: PropTypes.number,
  scrollTo: PropTypes.number,
  onItemsRendered: PropTypes.func,
  setRef: PropTypes.func,
}

export const VirtualList = memo(_VirtualList)
