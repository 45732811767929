import queryString from 'query-string'
import { useFetch } from 'containers/fetch/useFetch'

const queryFromOptions = (options) =>
  `/orders?${queryString.stringify(options)}`

export const useFetchOrders = (options) => {
  const { loading, data: orders } = useFetch(queryFromOptions(options))
  return {
    orders,
    loading,
  }
}
