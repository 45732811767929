import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ArrowRight from '@material-ui/icons/KeyboardArrowRight'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import Add from '@material-ui/icons/Add'
import { withProps } from 'recompose'
import Done from '@material-ui/icons/Done'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'

export const BackButton = withProps({
  startIcon: <ArrowLeft />,
  variant: 'contained',
  color: 'default',
})(Button)

export const NextButton = withProps({
  startIcon: <ArrowRight />,
  variant: 'contained',
  color: 'primary',
})(Button)

export const AddButton = withProps({
  startIcon: <Add />,
  variant: 'contained',
  color: 'primary',
  size: 'small',
})(Button)

const _ConfirmButton = (props) => (
  <Button
    data-testid="order-creation-confirm-button"
    id="confirmOrderButton"
    autoFocus
    startIcon={<Done />}
    color="primary"
    variant="contained"
    {...props}
  >
    Confirm
  </Button>
)

const _SaveButton = (props) => (
  <Button
    data-testid="order-creation-save-button"
    id="saveOrderButton"
    startIcon={<Done />}
    variant="contained"
    color="primary"
    {...props}
  >
    Save
  </Button>
)

const usePinkButtonStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.other.pink,
    color: 'white',
  },
}))

const _ConfirmAndShareButton = ({ children, ...props }) => {
  const classes = usePinkButtonStyles()

  return (
    <Button
      data-testid="confirm-and-share-button"
      id="confirmAndShareOrderButton"
      variant="contained"
      startIcon={<Done />}
      classes={classes}
      {...props}
    >
      {children}
    </Button>
  )
}

_ConfirmAndShareButton.defaultProps = {
  children: 'Share with Emstream',
}
_ConfirmAndShareButton.propTypes = {
  children: PropTypes.string,
}

export const ConfirmButton = memo(_ConfirmButton)
export const SaveButton = memo(_SaveButton)
export const ConfirmAndShareButton = memo(_ConfirmAndShareButton)
