import { isEmpty } from 'lodash/fp'
import { makeStyles } from '@material-ui/styles'
import { $O, APP_CONTEXT_TYPE, ORDER_STATUS } from 'emsurge-selectors'

export const useSkeletonStyles = makeStyles((theme) => ({
  '@keyframes skeleton-progress': {
    '0%': {
      transform: 'translate3d(-100%, 0, 0)',
    },
    '100%': {
      transform: 'translate3d(100%, 0, 0)',
    },
  },
  root: {
    position: 'relative',
    overflow: 'hidden',
    background: theme.palette.background.paper,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      background: `linear-gradient(90deg, ${theme.palette.background.paper}, ${theme.palette.background.secondary}, ${theme.palette.background.paper})`,
      animation: '$skeleton-progress 2s ease-in-out infinite',
    },
  },
}))

export const isThirdPartyOrder = ({ thirdParty }) => !isEmpty(thirdParty)

export const GUTTER = 4
export const ORDER_ACTIVITY_CARD_HEIGHT_LNG = 66
export const ORDER_ACTIVITY_CARD_HEIGHT_CARBON = 100
export const guessOrderActivityCardHeight = (
  appContext = APP_CONTEXT_TYPE.LNG
) =>
  (appContext === APP_CONTEXT_TYPE.LNG
    ? ORDER_ACTIVITY_CARD_HEIGHT_LNG
    : ORDER_ACTIVITY_CARD_HEIGHT_CARBON) + GUTTER

export const guessCommentActivityCardHeight = () => 100

export const TERMSHEET_CARD_HEIGHT = 103
export const guessTermsheetActivityCardHeight = () =>
  TERMSHEET_CARD_HEIGHT + GUTTER
export const DEFAULT_CARD_HEIGHT = 70

const BULLETIN_SIMPLE_HEIGHT = 80
const BULLETIN_TITLE_HEIGHT = 15
const BULLETIN_MESSAGE_HEIGHT = 15
const BULLETIN_MESSAGE_LINE_MAXIMUM_CHARS = 58

export const guessBulletinActivityCardHeight = (message, hasTitle) => {
  let height = BULLETIN_SIMPLE_HEIGHT
  if (message) {
    height +=
      BULLETIN_MESSAGE_HEIGHT *
      (message.length < BULLETIN_MESSAGE_LINE_MAXIMUM_CHARS ? 1 : 2)
  }
  if (hasTitle) {
    height += BULLETIN_TITLE_HEIGHT
  }
  return height
}

export const getOrderCardOpacity = (order) => {
  const orderStatus = $O.status.get(order)

  return orderStatus === ORDER_STATUS.LIVE ? 1 : 0.5
}
