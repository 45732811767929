import { PRICE_TYPE, STAKEHOLDER_TYPE } from 'emsurge-selectors'
import { fromPairs, get, isEmpty, negate } from 'lodash'
import moment from 'moment'

const getPriceInfo = (priceValues = {}, type) => {
  switch (type) {
    case PRICE_TYPE.HYBRID:
      return priceValues[`${PRICE_TYPE.HYBRID}Info`]
    default:
      return { ...priceValues[`${type}Info`] }
  }
}

const getValidityValue = (date, time) =>
  date && time ? moment.utc(`${date}T${time}`).format() : null

export const mapFormikValuesToOrder = (values) => {
  const stakeholder = {}
  if (values.behalfOf === STAKEHOLDER_TYPE.ON_SYSTEM) {
    stakeholder.company = values.myCompany?.company || values.company
    stakeholder.entity = values.myCompany?.entity || values.entity
    stakeholder.person = values.myCompany?.trader || values.person

    stakeholder.companyId = values.company?.id || values.myCompany?.company?.id
    stakeholder.entityId = values.entity?.id || values.myCompany?.entity?.id
    stakeholder.thirdParty = undefined
  }

  if (values.behalfOf === STAKEHOLDER_TYPE.OFF_SYSTEM) {
    stakeholder.company = undefined
    stakeholder.entity = undefined
    stakeholder.companyId = undefined
    stakeholder.entityId = undefined
  }

  const prices = {
    reserve: {
      info: getPriceInfo(values.price?.reserve, values.price?.reserve.type),
      type: get(values, 'price.reserve.type'),
    },
    contract: {
      info: getPriceInfo(values.price?.contract, values.price?.contract.type),
      type: get(values, 'price.contract.type'),
    },
  }

  const nominationRules = {
    ...values.nominationRules,
    dischargePort: {
      ...values.nominationRules?.dischargePort,
      timezone: values.nominationRules?.deliveryWindow?.timezone,
    },
    ship: {
      ...values.nominationRules?.ship,
      base: {
        ...values.nominationRules?.ship?.base,
        min: undefined,
        max: undefined,
        size: {
          value: values.nominationRules?.ship?.base?.size,
          min: values.nominationRules?.ship?.base?.min,
          max: values.nominationRules?.ship?.base?.max,
        },
      },
      alternatives: values.nominationRules?.ship?.alternatives?.map(
        (alternative) => ({
          ...alternative,
          min: undefined,
          max: undefined,
          size: {
            min: alternative.min,
            max: alternative.max,
          },
        })
      ),
    },
  }

  return {
    ...values,
    ...stakeholder,
    ...{ nominationRules },
    validityType: values.validity?.until,
    ...(values.validity
      ? {
          validUntil: getValidityValue(
            values.validity.date,
            values.validity.time
          ),
        }
      : {}),
    price: {
      basic: {
        info: getPriceInfo(values.price?.basic, values.price?.basic.type),
        type: get(values, 'price.basic.type'),
      },
      ...(values.price?.reserve?.enable ? { reserve: prices.reserve } : {}),
      ...(values.price?.contract?.enable ? { contract: prices.contract } : {}),
    },
    behalfOf: undefined,
    myCompany: undefined,
  }
}

export const isMyCompanyOrder = (form) =>
  form.behalfOf === STAKEHOLDER_TYPE.ON_SYSTEM

export const isThirdPartyOrder = negate(isMyCompanyOrder)

export const getTouchedErrorsForFields = (formik, paths) => {
  const pathErrorPairs = paths
    .map((fieldPath) => {
      const error = get(formik.errors, fieldPath)
      const touched = get(formik.touched, fieldPath)
      return [fieldPath, { error, touched }]
    })
    .filter(([, { touched, error }]) => touched && !isEmpty(error))
    .map(([fieldPath, { error }]) => [fieldPath, error])

  return fromPairs(pathErrorPairs)
}

export const getErroneousPaths = (errors, paths) =>
  paths
    .map((fieldPath) => {
      const error = get(errors, fieldPath)
      return !isEmpty(error) ? fieldPath : null
    })
    .filter((value) => !!value)
