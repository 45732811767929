import { values, get } from 'lodash'

export const deliveryTypes = Object.freeze({
  DES: 'des',
  FOB: 'fob',
  CIF: 'cif',
  DAT: 'dat',
  DAP: 'dap',
  STS: 'sts',
  INT: 'int',
})

/* :: object -> boolean */
const hasDeliveryType = (order) => {
  const type = get(order, 'delivery.type') || ''

  return values(deliveryTypes).includes(type.toLowerCase())
}

/* :: object -> string */
const getDeliveryType = (order) => {
  if (!hasDeliveryType(order)) {
    throw new Error('Order has no Delivery type')
  }

  return order.delivery.type
}

export const type = {
  exists: hasDeliveryType,
  get: getDeliveryType,
}
