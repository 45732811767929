import { useState, useCallback, useMemo } from 'react'
import { isEqual, isEmpty, values } from 'lodash'

import { useLocation } from 'react-router'
import queryString from 'query-string'
import {
  DELIVERY_REGION,
  DELIVERY_REGION_TYPE,
  DELIVERY_TYPE_TEXT,
} from 'emsurge-selectors'
import { STATUS } from 'components/StatusFilters'

const getPortsByType = (type) =>
  type ? DELIVERY_REGION[type] : values(DELIVERY_REGION_TYPE)

const initialFilters = { type: '', ports: [], status: STATUS.ACTIVE }

export const useFilters = () => {
  const location = useLocation()
  const { status = STATUS.ACTIVE } = queryString.parse(location.search)
  const [filters, setFilters] = useState({ ...initialFilters, status })

  const onFilterChange = useCallback(
    (name, value) => {
      if (name === 'type') {
        if (!isEmpty(filters.ports)) {
          return setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
            ports: [],
          }))
        }
      }

      setFilters((prevFilters) => ({ ...prevFilters, [name]: value }))
    },
    [filters.ports]
  )

  const onFiltersReset = useCallback(() => setFilters(initialFilters), [])

  const options = useMemo(
    () => ({
      deliveryTypes: values(DELIVERY_TYPE_TEXT),
      ports: getPortsByType(filters.type),
    }),
    [filters.type]
  )

  const filtersTouched = useMemo(
    () => !isEqual(initialFilters, filters),
    [filters]
  )

  return {
    filters,
    onFilterChange,
    onFiltersReset,
    filtersTouched,
    options,
  }
}
