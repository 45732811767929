const getIsProRata = (terms) => {
  const deliveryWindow = terms.deliveryWindow || {}

  return deliveryWindow.isProRata
}

const getIsProRataText = (terms) => {
  const isProRata = getIsProRata(terms)

  switch (isProRata) {
    case true:
      return 'Yes'
    case false:
      return 'No'
    default:
      return ''
  }
}

const getIsProRataSummary = (terms) => {
  const isProRata = getIsProRataText(terms)

  return isProRata
}

module.exports = {
  get: getIsProRata,
  summary: getIsProRataSummary,
  text: getIsProRataText,
}
