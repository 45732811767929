import moment from 'moment'
import { DELIVERY_FREQUENCY_TYPE } from 'emsurge-selectors/constants'
import { contract } from '../delivery/contract'
import { quota } from './quota'
import { PERIOD } from 'model/order/constants/delivery'

/* :: object -> boolean */
const canCalculateTotalCargoes = (order) => {
  if (!quota.exists(order)) {
    return false
  }

  const { frequency } = quota.get(order)
  if (frequency === DELIVERY_FREQUENCY_TYPE.PER_PERIOD) {
    return true
  }

  return contract.exists(order)
}

/* :: object -> Moment */
const customPeriodToMoment = (period) =>
  moment(`${period.year}-${period.month}`, 'YYYY-MM')

/* :: object -> number */
const calculateTotalCargoes = (order) => {
  if (!canCalculateTotalCargoes(order)) {
    throw new Error(
      'Order does not have the sufficient information to calculate total cargos. Please use order.cargo.total.exists()'
    )
  }

  const { frequency, quantity } = quota.get(order)
  const numberOfCargoes = quota.isRange(order) ? quantity[1] : quantity

  if (frequency === DELIVERY_FREQUENCY_TYPE.PER_PERIOD) {
    return numberOfCargoes
  }

  /** @NOTE: PER_MONTH + PER_ANNUM contracts */
  if (contract.isCustom(order)) {
    const [fromPeriod, toPeriod] = contract.get(order).map(customPeriodToMoment)

    const diffUnit =
      frequency === DELIVERY_FREQUENCY_TYPE.PER_MONTH ? 'months' : 'years'
    const diff = toPeriod.diff(fromPeriod, diffUnit) + 1

    return numberOfCargoes * diff
  }

  /** NOTE: Non custom periods */
  const { period: orderPeriod } = contract.get(order)

  const months = [
    PERIOD.JAN,
    PERIOD.FEB,
    PERIOD.MAR,
    PERIOD.APR,
    PERIOD.MAY,
    PERIOD.JUN,
    PERIOD.JUL,
    PERIOD.AUG,
    PERIOD.SEP,
    PERIOD.OCT,
    PERIOD.NOV,
    PERIOD.DEC,
  ]
  if (months.includes(orderPeriod)) {
    return numberOfCargoes
  }

  const quarters = [PERIOD.Q1, PERIOD.Q2, PERIOD.Q3, PERIOD.Q4]
  if (quarters.includes(orderPeriod)) {
    return numberOfCargoes * 3
  }

  if ([PERIOD.SUMMER, PERIOD.WINTER].includes(orderPeriod)) {
    return numberOfCargoes * 6
  }

  /** @NOTE: CAL/GAS year period */
  if (frequency === DELIVERY_FREQUENCY_TYPE.PER_MONTH) {
    return numberOfCargoes * 12
  }

  return numberOfCargoes
}

export const total = {
  exists: canCalculateTotalCargoes,
  get: calculateTotalCargoes,
}
