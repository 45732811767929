import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import useAppContext from 'containers/appContext/useAppContext'
import { DASHBOARD } from 'routes'
import { useUser } from 'containers/user/useUser'

const TabsWrapper = styled.div`
  display: block;
  width: 224px;
  margin-left: 40px;

  align-self: stretch;
`

const useStyles = (appContext) => makeStyles((theme) => ({
  tabs: { height: '100%' },
  tabsFlexContainer: { height: '100%' },
  tabsIndicator: {
    height: '4px',
  },
  tab: {
    minWidth: '112px',
    backgroundColor: appContext == APP_CONTEXT_TYPE.LNG ? theme.palette.secondary.dark : theme.palette.carbon.navbar.background,
    color: appContext == APP_CONTEXT_TYPE.LNG ? theme.palette.text.primary : theme.palette.carbon.navbar.text,
    opacity: 1,
    ...theme.typography.subtitle2,
  },
}))

const CONTEXT_TABS = [APP_CONTEXT_TYPE.CARBON, APP_CONTEXT_TYPE.LNG ]
const CONTEXT_TABS_LNG = [APP_CONTEXT_TYPE.LNG ]

const ContextToggle = () => {
  const { user } = useUser()
  const { appContext, setLNGContext, setCarbonContext } = useAppContext()
  const history = useHistory()
  const classes = useStyles(appContext)()
  const isEmsurgeOrEmstream = user.email.includes('@emsurge.com') || user.email.includes('@emstream.co.uk')

  const handleChange = (_, value) => {
    if (CONTEXT_TABS[value] === appContext) {
      if (history.location.pathname !== DASHBOARD) {
        history.push(DASHBOARD)
      }
    }
    else if (CONTEXT_TABS[value] === APP_CONTEXT_TYPE.LNG) {
      setLNGContext()
    }
    else {
      setCarbonContext()
    }
  }

  /**
   * Fix for internal issue on Material UI where sometimes the
   * indicator will not show on page load
   *
   * @see https://stackoverflow.com/questions/61184102/react-material-tabs-indicator-not-showing-on-first-load
   */
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'))
    if(isEmsurgeOrEmstream)
    {
      setCarbonContext()
    }
    else  
    {
      setLNGContext()
    }
  }, [])

  return (
    <TabsWrapper>
      <Tabs
        indicatorColor="primary"
        value={CONTEXT_TABS.indexOf(appContext)}
        onChange={handleChange}
        classes={{
          root: classes.tabs,
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.tabsIndicator,
        }}
      >
        {isEmsurgeOrEmstream && (
          CONTEXT_TABS.map((tab) => (
            <Tab
              key={tab}
              data-testid={`app-context-${tab}`}
              label={tab}
              classes={{ root: classes.tab }}
            />
          ))
        )}
        {!isEmsurgeOrEmstream && (
          CONTEXT_TABS_LNG.map((tab) => (
            <Tab
              key={tab}
              data-testid={`app-context-${tab}`}
              label={tab}
              classes={{ root: classes.tab }}
            />
          ))
        )}
      </Tabs>
    </TabsWrapper>
  )
}

export default ContextToggle
