import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { DELIVERY_TYPES } from 'model/order/constants/cargo'

const _LoadPortModel = ({ formik: { values, setFieldValue }, children }) => {
  const isFOB = values.delivery.type === DELIVERY_TYPES.FOB
  const disableToBeNominationFields = (toBeNominated) => (fieldName) =>
    ['party', 'timeframe', 'timeframeValue'].includes(fieldName) &&
    !toBeNominated
  const shouldDisableField =
    ({ toBeNominated }) =>
    (fieldName) =>
      disableToBeNominationFields(toBeNominated)(fieldName)
  const setPartyDefaultValue = useCallback(
    (name) => setFieldValue(name, 'ask'),
    [setFieldValue]
  )

  return children({
    isFOB,
    shouldDisableField,
    setPartyDefaultValue,
  })
}

_LoadPortModel.propTypes = {
  formik: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
}

export const LoadPortModel = connect(_LoadPortModel)
