import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import FormProvider from './containers/FormProvider'
import FormProviderCarbon from 'screens/orderCreateCounterCarbon/FormProvider'
import ModalContent from 'screens/orderCreate/desktop/ModalContent'
import ModalHeader from 'screens/orderCreate/desktop/ModalHeader'
import ModalContentCarbon from 'screens/orderCreateCarbon/ModalContent'
import ModalHeaderCarbon from 'screens/orderCreateCarbon/ModalHeader'
import {
  useOrderFlow,
  ORDER_FLOW,
  useOrderState
} from 'containers/OrderProvider'
import withParams from 'utils/withRouteParams'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'
import { useOrder } from 'api'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { Mobile, Tablet } from 'components/Viewport'
import { CreateOrderModal } from 'screens/orderCreate/mobile/CreateOrderModal'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { DASHBOARD, ORDER_BASE_ROUTES } from 'routes'

const parseCounterOrder = (order) => ({
  ...order,
  comments: [],
  termsheets: [],
  submittedAt: undefined,
})

const OrderCounterCreate = ({ id, postSubmit, isCreatingTrade }) => {
  const history = useHistory()
  const { setFlow } = useOrderFlow()
  const { setOrderState } = useOrderState()
  const { data: order, isLoading } = useOrder(id)
  const BASE_SLUG = useRouteBaseSlug()
  const rootSlug = ORDER_BASE_ROUTES.includes(`/${BASE_SLUG}`)
    ? `/${BASE_SLUG}`
    : DASHBOARD
  const parsedCounterOrder = useMemo(
    () => (isLoading ? {} : parseCounterOrder(order)),
    [isLoading, order]
  )

  useEffect(() => {
    setFlow(ORDER_FLOW.COUNTER)
    setOrderState((state) => ({
      ...state,
      hasTermsheets: false,
    }))
  }, [setFlow, setOrderState])

  if (isLoading) {
    return <LoadingSpinner />
  }

  const goToOrder = () => history.push(`${rootSlug}/orders/${id}`)

  return (
    <>
      {order.context === APP_CONTEXT_TYPE.LNG && (
        <FormProvider
          order={parsedCounterOrder}
          postSubmit={postSubmit}
          isCreatingTrade={isCreatingTrade}
        >
          <>
            <FormConsumer>
              {({ submitForm }) => {
                return (
                  <>
                    <Tablet matchLarger>
                      <ModalHeader onCancel={goToOrder} onSave={submitForm} isCreatingTrade={isCreatingTrade}/>
                      <ModalContent isCreatingTrade={isCreatingTrade}/>
                    </Tablet>
                    <Mobile>
                      <CreateOrderModal onCancel={goToOrder} onSave={submitForm} />
                    </Mobile>
                  </>
                )
              }}
            </FormConsumer>
          </>
        </FormProvider>
      )}
      {order.context === APP_CONTEXT_TYPE.CARBON && (
         <FormProviderCarbon
          order={parsedCounterOrder}
          postSubmit={postSubmit}
          isCreatingTrade={isCreatingTrade}
         >
          <>
              <FormConsumer>
                {({ submitForm }) => {
                  return (
                    <>
                      <Tablet matchLarger>
                        <ModalHeaderCarbon onCancel={goToOrder} onSave={submitForm} isCreatingTrade={isCreatingTrade}/>
                        <ModalContentCarbon isCreatingTrade={isCreatingTrade}/>
                      </Tablet>
                    </>
                  )
                }}
              </FormConsumer>
            </>
      </FormProviderCarbon>
      )}
    </>
  )
}

OrderCounterCreate.propTypes = {
  id: PropTypes.string.isRequired,
  postSubmit: PropTypes.func,
  initialValues: PropTypes.object,
}

export default withParams(OrderCounterCreate)
