import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'
import { mod } from 'react-swipeable-views-core'
import Activity from './containers/Activity'
import { MarketInterest } from './containers/MarketInterest'
import { OrdersAndTemplates } from './containers/OrdersAndTemplates'
import { Block as MobileBlock } from 'screens/orderIndex/components/Block.mobile'

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

const styles = {
  swipeableContainer: {
    padding: '0 5px 0 0',
  },
  swipeableSlide: {
    padding: '0 5px 0 0',
  },
}

const slideRenderer = (params) => {
  const { index, key } = params

  switch (mod(index, 3)) {
    case 0:
      return (
        <MobileBlock key={key}>
          <Activity />
        </MobileBlock>
      )
    case 1:
      return (
        <MobileBlock key={key}>
          <MarketInterest />
        </MobileBlock>
      )
    case 2:
      return (
        <MobileBlock key={key}>
          <OrdersAndTemplates />
        </MobileBlock>
      )
    default:
      return null
  }
}

export const DashboardSwiper = () => (
  <VirtualizeSwipeableViews
    slideRenderer={slideRenderer}
    style={styles.swipeableContainer}
    slideStyle={styles.swipeableSlide}
    enableMouseEvents
  />
)
