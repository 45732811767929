import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { PERSON_PERMISSION } from 'emsurge-selectors/constants'
import { identity, upperCase } from 'lodash'
import { useUserHasPermission } from 'permissions'
import { StyledLink } from 'components/StyledLink'
import { useIsMobile } from 'components/Viewport/Mobile'

const CloneOrderMenuItem = ({ order }) => {
  const userCanClone = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.WRITE,
  })
  const isMobile = useIsMobile()
  const transformer = isMobile ? identity : upperCase

  if (!userCanClone) {
    return null
  }

  const label = order.template
    ? transformer('Create Order')
    : transformer('Clone Order')

  return (
    <StyledLink to={(location) => `${location.pathname}/clone`}>
      <MenuItem data-testid="menu-clone-order-button">{label}</MenuItem>
    </StyledLink>
  )
}

CloneOrderMenuItem.propTypes = {
  order: PropTypes.object.isRequired,
}

export default CloneOrderMenuItem
