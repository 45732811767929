import React from 'react'
import { get } from 'lodash'
import { Grid } from '@material-ui/core'
import { connect, FieldArray } from 'formik'
import { useTheme } from '@material-ui/styles'
import { ALTERNATIVES_PREFIX } from 'emsurge-selectors'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'
import { AddButton } from 'screens/orderCreateCommon/components/Buttons'
import TextField from 'containers/formikTextField/FormikTextField'
import Card from 'screens/orderCreate/components/Card'

const _OtherTerms = ({ formik: { values } }) => {
  const { spacing } = useTheme()
  return (
    <h1>
      <FieldArray
        name={ALTERNATIVES_PREFIX}
        render={(arrayHelpers) => (
          <>
            <Grid>
              {(get(values, ALTERNATIVES_PREFIX) || []).map((_, index) => (
                <Card key={`${ALTERNATIVES_PREFIX}${index}`}>
                  <CloseButton onClick={() => arrayHelpers.remove(index)} />
                  <TextField
                    fullWidth
                    margin="normal"
                    multiline
                    InputLabelProps={{
                      error: false,
                    }}
                    type="text"
                    name={`${ALTERNATIVES_PREFIX}[${index}].item`}
                    label="Item"
                    data-testid={`other-terms-item-${index}`}
                  />

                  <TextField
                    fullWidth
                    multiline
                    margin="normal"
                    InputLabelProps={{
                      error: false,
                    }}
                    type="text"
                    name={`${ALTERNATIVES_PREFIX}[${index}].description`}
                    label="Description"
                    data-testid={`other-terms-description-${index}`}
                  />
                </Card>
              ))}
            </Grid>
            <AddButton
              data-testid="other-terms-add-term-button"
              style={{ margin: spacing(1, 0) }}
              onClick={() => arrayHelpers.push({})}
            >
              Add Term
            </AddButton>
          </>
        )}
      />
    </h1>
  )
}

export const OtherTerms = connect(_OtherTerms)
