import React from 'react'
import { TITLE_TRANSFER, TITLE_TRANSFER_PREFIX } from 'emsurge-selectors'
import { connect } from 'formik'
const { RadioButtonGroup } = require('./RadioButtonGroup')

export const _TitleTransfer = () => {
  return (
    <RadioButtonGroup
      title="Title Transfer"
      options={TITLE_TRANSFER}
      fieldName={TITLE_TRANSFER_PREFIX}
    />
  )
}

export const TitleTransfer = connect(_TitleTransfer)
