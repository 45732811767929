import { $O } from 'emsurge-selectors/models'
import { NEW_LINE, NOT_AVAILABLE, TAB } from './constants'

const getPublicNote = (order) => {
  const noteTest = $O.note.text(order)

  return noteTest
}

const getTradingType = (order) => {
  const tradingTypeText = $O.tradingType.text(order)

  return tradingTypeText ? `Trading type: ${tradingTypeText}` : ''
}

const getStakeholder = (order) => {
  const party = $O.party.text(order)
  const company = $O.stakeholder.company.text(order)
  const entity = $O.stakeholder.entity.text(order)
  const trader = $O.stakeholder.person.text(order)

  return [
    company ? `${party}: ${company}` : '',
    entity ? `Entity: ${entity}` : '',
    trader ? `Trader: ${trader}` : '',
  ]
    .join(NEW_LINE)
    .trim()
}

const getDelivery = (order) => {
  const deliveryText = $O.delivery.text(order)

  return [
    `Delivery Term: ${deliveryText.type}`,
    `Delivery Port: ${deliveryText.location}`,
    `Delivery Period: ${deliveryText.period}`,
    `No. of Cargoes: ${deliveryText.cargo.max}`,
  ].join(NEW_LINE)
}

const getSubmissionDate = (order) => {
  return `Submission Date: ${$O.submittedAt.text(order)}`
}

const getValidity = (order) => {
  const visibilityText = $O.validity.text(order)

  return visibilityText ? `Visibility: ${visibilityText}` : ''
}

const getContractQuantity = (order) => {
  const cargoSizeText = $O.cargoSize.text(order)
  const contractText = $O.cargoSize.tolerance.contract.summary(order)

  return [
    `Contract Quantity: ${cargoSizeText.size || NOT_AVAILABLE}`,
    `${TAB}${contractText.variance}`,
    `${TAB}${contractText.tolerance}`,
  ]
    .join(NEW_LINE)
    .trim()
}

const getQuality = (order) => {
  return `Quality GHV: ${$O.quality.text(order) || NOT_AVAILABLE}`
}

const getPrice = (order) => {
  const priceBasic = $O.price.basic.summary(order)
  const priceReserve = $O.price.reserve.summary(order)
  const priceContract = $O.price.contract.summary(order)

  return [
    `Price: ${priceBasic.long || NOT_AVAILABLE}`,
    `Contract Price: ${priceContract.long || NOT_AVAILABLE}`,
    `Reserve Price: ${priceReserve.long || NOT_AVAILABLE}`,
  ]
    .join(NEW_LINE)
    .trim()
}

export const getOrderOverviewText = (order) => {
  const text = [
    getPublicNote(order),
    '',
    getTradingType(order),
    getStakeholder(order),
    getDelivery(order),
    getSubmissionDate(order),
    getValidity(order),
    getContractQuantity(order),
    getQuality(order),
    getPrice(order),
  ]
    .join(NEW_LINE)
    .trim()

  return text
}
