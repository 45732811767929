import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Form, Formik } from 'formik'
import { withTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import { compose } from 'recompose'
import { PARTY_TEXT } from 'emsurge-selectors/constants'
import DetailRow from '../../orderView/components/DetailRow'
import { handleSave } from '../containers/TradedNominations'
import { getNominationTimezone } from '../helpers'
import OffsetQuarterCard from './OffsetQuarterCard'
import Textfield from 'containers/formikTextField/FormikTextField'
import SumbitButton from 'containers/formikSubmitButton/FormikSubmitButton'
import { formatDate } from 'screens/orderCreate/components/DeliveryWindow.helpers'
import { useApi } from 'containers/api/useApi'

const enhance = compose(withSnackbar, withTheme)

const NarrowingMechanismForm = enhance(
  ({ theme, nomination, enqueueSnackbar }) => {
    const api = useApi()

    const INITIAL_VALUES = {
      from: formatDate(moment(nomination.from)),
      to: formatDate(moment(nomination.to)),
    }

    /* :: (object, object) -> Promise<void> */
    const handleSubmit = (values, actions) =>
      handleSave(api, nomination.id, values, actions, enqueueSnackbar)

    const fieldProps = {
      type: 'date',
      fullWidth: true,
      margin: 'normal',
      InputLabelProps: { shrink: true },
    }

    return (
      <Formik onSubmit={handleSubmit} initialValues={INITIAL_VALUES}>
        <Form>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={5}>
              <Textfield name="from" label="From" {...fieldProps} />
            </Grid>

            <Grid item xs={5}>
              <Textfield name="to" label="To" {...fieldProps} />
            </Grid>

            <Grid item xs>
              <SumbitButton
                fullWidth
                size="small"
                style={{ marginBottom: theme.spacing(1) }}
              >
                Save
              </SumbitButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    )
  }
)

NarrowingMechanismForm.propTypes = {
  nomination: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func,
}

const DeliveryWindowNarrowingMechanism = ({
  nomination,
  entityCode,
  orderCargoTimeZoneMap,
}) => {
  const nominateUntil = getNominationTimezone(nomination, orderCargoTimeZoneMap)
  return (
    <>
      <DetailRow
        gutterBottom
        title="Narrowing Mechanism"
        info={[
          `${PARTY_TEXT[nomination.party]} narrows DW to ${
            nomination.name
          } days`,
          moment(nominateUntil).format('DD/MM/YYYY HH:mm'),
          entityCode,
        ]}
      />
      <OffsetQuarterCard>
        <NarrowingMechanismForm nomination={nomination} />
      </OffsetQuarterCard>
    </>
  )
}

DeliveryWindowNarrowingMechanism.propTypes = {
  nomination: PropTypes.object.isRequired,
  entityCode: PropTypes.string.isRequired,
  orderCargoTimeZoneMap: PropTypes.object.isRequired,
}

const DeliveryWindowNominations = ({
  nomination,
  entityCode,
  nominationRules,
  orderCargoTimeZoneMap,
}) => {
  const {
    arrival: { window, period },
    cargos,
  } = get(nominationRules, 'deliveryWindow', {})
  const cargo = cargos[nomination.cargo]
  const from = moment(cargo.from).format('MMM-YY')
  const to = moment(cargo.to).format('MMM-YY')
  return (
    <>
      <DetailRow
        gutterBottom
        title="Delivery Window"
        info={`From ${from} to ${to}`}
        itemXs={9}
      />
      <DetailRow
        gutterBottom
        title="Arrival Window"
        info={`${window}h period commencing ${period} on the first day of the DW`}
      />
      <DeliveryWindowNarrowingMechanism
        nomination={nomination}
        entityCode={entityCode}
        orderCargoTimeZoneMap={orderCargoTimeZoneMap}
      />
    </>
  )
}

DeliveryWindowNominations.propTypes = {
  nomination: PropTypes.object.isRequired,
  entityCode: PropTypes.string.isRequired,
  nominationRules: PropTypes.object.isRequired,
  orderCargoTimeZoneMap: PropTypes.object.isRequired,
}

export default DeliveryWindowNominations
