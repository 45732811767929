const getCompany = (user) => user.company || {}

const getCompanyText = (user) => {
  const company = getCompany(user)

  return {
    id: company.id || '',
    code: company.code || '',
    name: company.name || '',
  }
}

module.exports = {
  get: getCompany,
  text: getCompanyText,
}
