const CLASSIFICATIONS_FIRST_PREFIX = 'carbonType.classifications.first'
const CLASSIFICATIONS_SECOND_PREFIX = 'carbonType.classifications.second'
const CARBON_STANDARD_PREFIX = 'carbonType.carbonStandard'
const PROGRAM_ELIGIBILITY_PREFIX = 'carbonType.programEligibility'
const RATING_PREFIX = 'carbonType.rating'
const TITLE_TRANSFER_PREFIX = 'carbonType.titleTransfers'

const TERM_SPOT_PREFIX = 'termVintage.termSpot'
const DELIVERED_BY_PREFIX = 'termVintage.deliveredBy.type'
const DELIVERED_BY_FIX_PREFIX = 'termVintage.deliveredBy.fixedDate'
const DELIVERED_BY_AFTER_DAYS_PREFIX = 'termVintage.deliveredBy.daysAfter'
const TERM_SPOT_PERIOD_PREFIX = 'termVintage.period'
const TERM_SPOT_OFFSET_PREFIX = 'termVintage.offset'
const TERM_FIXED_ROLLING_PREFIX = 'termVintage.fixedRolling'
const TERM_SPOT_VINTAGE_FIXED_PREFIX = 'termVintage.vintageFixed'
const TERM_SPOT_VINTAGE_ROLLING_PREFIX = 'termVintage.vintageRolling'
const TERM_SPOT_VINTAGE_PROJECT_INFORMATION = 'termVintage.projectInformation'
const TERM_SPOT_INVESTMENT_DESCRIPTION = 'termVintage.investmentDescription'

const VOLUME_AMOUNT_PREFIX = 'volume.amount'
const VOLUME_MIN_PARCEL_SIZE_PREFIX = 'volume.minParcelSize'
const VOLUME_TYPE_PREFIX = 'volume.type'
const VOLUME_OFFSET_PREFIX = 'volume.offset'
const VOLUME_RANGE_PREFIX = 'volume.range'

const PRICE_TYPE_PREFIX = 'price.type'
const PRICE_FIXED_PREFIX = 'price.basic.fixed'
const PRICE_FIXED_CURRENCY_PREFIX = 'price.basic.fixedCurrency'
const PRICE_RANGE_PREFIX = {
  min: 'price.basic.range.min',
  max: 'price.basic.range.max',
  currency: 'price.basic.range.currency',
}
const PRICE_VOLUME_DEPENDENT_PREFIX = 'price.basic.volumeDependent'
const PRICE_FLOATING_PREFIX = 'price.floating'
const PRICE_RFQ_PREFIX = {
  deadline: {
    date: 'price.basic.rfq.deadline.date',
    time: 'price.basic.rfq.deadline.time',
  },
  award: {
    date: 'price.basic.rfq.award.date',
    time: 'price.basic.rfq.award.time',
  },
  notes: 'price.basic.rfq.notes',
}
const PRICE_TBD_PREFIX = 'price.basic.tbd'

const PROJECT_KEYWORD_INPUT = 'projectSpecification.buyer.keyword.input'
const PROJECT_KEYWORD_OPTIONS = 'projectSpecification.buyer.keyword.options'
const PROJECT_SECTORAL_SCOPE_CHECKBOX =
  'projectSpecification.buyer.sectoralScope'
const PROJECT_SECTORAL_SCOPE_OPTIONS =
  'projectSpecification.buyer.sectoralScope.options'
const PROJECT_SECTORAL_SCOPE_INPUT =
  'projectSpecification.buyer.sectoralScope.input'
const PROJECT_PATH = 'projectSpecification.seller.projects'
const getProjectNr = (projectNr) => `${PROJECT_PATH}.${projectNr}`
const getProjectSpecificationPrefix = (projectNr, fieldName) =>
  `${getProjectNr(projectNr)}.${fieldName}`
const getProjectSpecificationSectoralScopePrefix = (projectNr) =>
  getProjectSpecificationPrefix(projectNr, 'sectoralScope')
const getProjectSpecificationStorageTypePrefix = (projectNr) =>
  getProjectSpecificationPrefix(projectNr, 'storageType')
const getProjectSpecificationReferenceNumberPrefix = (projectNr) =>
  getProjectSpecificationPrefix(projectNr, 'referenceNr')
const getProjectSpecificationProjectNamePrefix = (projectNr) =>
  getProjectSpecificationPrefix(projectNr, 'name')
const getProjectSpecificationProjectURLPrefix = (projectNr) =>
  getProjectSpecificationPrefix(projectNr, 'url')
const getProjectSpecificationProjectStartDatePrefix = (projectNr) =>
  getProjectSpecificationPrefix(projectNr, 'startDate')

const ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX = 'additionalAttributes.options'
const ADDITIONAL_ATTRIBUTES_SDG_PREFIX = 'additionalAttributes.sdg'
const ADDITIONAL_ATTRIBUTES_SDGS_PREFIX = 'additionalAttributes.sdgs'
const ADDITIONAL_ATTRIBUTES_TYPE_PREFIX = 'additionalAttributes.type'

const VENUE_OPTIONS_PREFIX = 'venue.options'

const REGION_COUNTRY_PREFIX = 'regions.country'
const REGION_COUNTRIES_PREFIX = 'regions.countries'
const REGION_LDCS_ONLY_PREFIX = 'regions.ldcsOnly'
const REGION_OPTIONS_PREFIX = 'regions.options'
const REGION_TYPE_PREFIX = 'regions.type'

const ALTERNATIVES_PREFIX = 'alternatives'

module.exports = {
  CLASSIFICATIONS_FIRST_PREFIX,
  CLASSIFICATIONS_SECOND_PREFIX,
  CARBON_STANDARD_PREFIX,
  PROGRAM_ELIGIBILITY_PREFIX,
  RATING_PREFIX,
  TITLE_TRANSFER_PREFIX,
  TERM_SPOT_PREFIX,
  TERM_SPOT_PERIOD_PREFIX,
  TERM_SPOT_OFFSET_PREFIX,
  TERM_FIXED_ROLLING_PREFIX,
  TERM_SPOT_VINTAGE_FIXED_PREFIX,
  TERM_SPOT_VINTAGE_ROLLING_PREFIX,
  TERM_SPOT_VINTAGE_PROJECT_INFORMATION,
  VOLUME_AMOUNT_PREFIX,
  VOLUME_TYPE_PREFIX,
  VOLUME_OFFSET_PREFIX,
  VOLUME_RANGE_PREFIX,
  VOLUME_MIN_PARCEL_SIZE_PREFIX,
  PRICE_TYPE_PREFIX,
  PRICE_RANGE_PREFIX,
  PRICE_FIXED_PREFIX,
  PRICE_FIXED_CURRENCY_PREFIX,
  PRICE_FLOATING_PREFIX,
  PRICE_RFQ_PREFIX,
  PRICE_TBD_PREFIX,
  PROJECT_PATH,
  ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX,
  ADDITIONAL_ATTRIBUTES_SDG_PREFIX,
  ADDITIONAL_ATTRIBUTES_SDGS_PREFIX,
  ADDITIONAL_ATTRIBUTES_TYPE_PREFIX,
  getProjectSpecificationSectoralScopePrefix,
  getProjectSpecificationStorageTypePrefix,
  getProjectSpecificationReferenceNumberPrefix,
  getProjectSpecificationProjectNamePrefix,
  getProjectSpecificationProjectURLPrefix,
  getProjectSpecificationProjectStartDatePrefix,
  getProjectNr,
  VENUE_OPTIONS_PREFIX,
  REGION_COUNTRY_PREFIX,
  REGION_COUNTRIES_PREFIX,
  REGION_LDCS_ONLY_PREFIX,
  REGION_OPTIONS_PREFIX,
  REGION_TYPE_PREFIX,
  ALTERNATIVES_PREFIX,
  PROJECT_KEYWORD_INPUT,
  PROJECT_KEYWORD_OPTIONS,
  PROJECT_SECTORAL_SCOPE_CHECKBOX,
  PROJECT_SECTORAL_SCOPE_OPTIONS,
  PROJECT_SECTORAL_SCOPE_INPUT,
  PRICE_VOLUME_DEPENDENT_PREFIX,
  TERM_SPOT_INVESTMENT_DESCRIPTION,
  DELIVERED_BY_PREFIX,
  DELIVERED_BY_FIX_PREFIX,
  DELIVERED_BY_AFTER_DAYS_PREFIX
}
