import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import { getInformationInitialValues } from './getInformationInitialValues'
import { getNominationInitialValues } from './getNominationInitialValues'
import { validateOrder } from 'screens/orderCreate/containers/validateOrder'
import { getSchema } from 'screens/orderCreate/containers/FormSchema'
import { useUser } from 'containers/user/useUser'

const getInitialValues = (order) => {
  const initialValues = {
    ...getInformationInitialValues(order),
    ...getNominationInitialValues(order),
  }
  return initialValues
}

const FormProvider = ({ order, children, onSubmit }) => {
  const { user } = useUser()

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={getInitialValues(order)}
      validationSchema={getSchema(user)}
      validate={validateOrder}
      validateOnMount={false}
    >
      {children}
    </Formik>
  )
}

FormProvider.defaultProps = {
  postSubmit: () => {},
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  order: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default FormProvider
