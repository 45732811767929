import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SelectedOptions } from './SelectedOptions'
import { Dropdown } from './Dropdown'

const MAXIMUM = 999999999
const DEFAULT_MAX_ITEMS = 50

const buildOption = (key, fullOptions, optionsKeys) => ({
  key,
  value: fullOptions[key],
  focus: get(optionsKeys, `${key}.focus`) || false,
})

const buildOptionLabel = (
  option,
  fullOptions,
  index,
  disabled = false,
  focus = false
) => ({
  key: option,
  value: option,
  label: fullOptions[option],
  disabled,
  focus,
  index,
})

const sortOptions = (a, b) =>
  a.value.localeCompare(b.value, 'en', { sensitivity: 'base' })

const getMappedKeys = (mappedKey) => {
  const { key, disabled, groupBy, focus } = mappedKey
  return {
    key,
    disabled: disabled || false,
    groupBy: groupBy || 'default',
    focus: focus || false,
  }
}

const addToGroupedList = (groupedList, groupBy, option) => {
  groupedList[groupBy] = groupedList[groupBy] || []
  groupedList[groupBy].push(option)
}

const groupOptions = (optionsKeys, fullOptions, sortFn, keysToDiscard = []) => {
  const groupedList = {}
  const listOptions = []
  Object.keys(optionsKeys).forEach((option, index) => {
    const mappedKey = optionsKeys[option]
    let dropdownOption = {}
    let dropdownGroupBy = 'default'
    if (typeof mappedKey === 'object') {
      const { key, disabled, groupBy, focus } = getMappedKeys(mappedKey)
      dropdownOption = buildOptionLabel(
        key,
        fullOptions,
        index + 1,
        disabled,
        focus
      )
      dropdownGroupBy = groupBy
    } else {
      dropdownOption = buildOptionLabel(mappedKey, fullOptions, index + 1)
    }
    if (!keysToDiscard.map((opt) => opt.key).includes(dropdownOption.value)) {
      addToGroupedList(groupedList, dropdownGroupBy, dropdownOption)
    }
  })
  const groupedListKeys = Object.keys(groupedList)
  groupedListKeys.forEach((key, idx) => {
    if (groupedListKeys[idx] !== 'default') {
      listOptions.push(buildOptionLabel(key, fullOptions, idx + 1, true))
    }
    listOptions.push(...groupedList[key].sort(sortFn))
  })
  return listOptions
}

const useStyles = makeStyles(() => ({
  selectedOptionsContainer: {
    paddingTop: '16px',
    overflow: 'hidden',
  },
  showMoreShadow: {
    boxShadow: 'rgb(255 255 255 / 45%) 0px 25px 20px -20px',
  },
  btnShowMore: {
    paddingBottom: '16px',
  },
}))

const _DropdownSelectOptions = ({
  fullOptions,
  inputPath,
  optionsPath,
  inputTitle,
  formik: { setFieldValue, values },
  dropdownGridSize,
  selectedOptionsGridSize,
  optionsKeys,
  sortFn,
  filterSelelectedFn,
  children,
  forceRefresh,
  onSelectOption,
  onDeleteOption,
  onChangeListOptions,
}) => {
  const classes = useStyles()
  const [showMore, setShowMore] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(
    get(values, optionsPath) || []
  )
  const [filteredSelectedOptions, setFilteredSelectedOptions] = useState([])
  const [currentOption, setCurrentOption] = useState('')
  const [listOptions, setListOptions] = useState([])
  const [maxItems, setMaxItems] = useState(DEFAULT_MAX_ITEMS)
  const [displayShowMore, setDisplayShowMore] = useState(false)

  useEffect(() => {
    setFieldValue(optionsPath, selectedOptions)
    refreshListOptions()
    setFilteredSelectedOptions(selectedOptions.filter(filterSelelectedFn))
    handleDisplayShowMore()
  }, [selectedOptions])

  useEffect(() => {
    const previousSelectedOptions = get(values, optionsPath) || []
    setSelectedOptions(previousSelectedOptions)
    refreshListOptions()
    setFilteredSelectedOptions(
      previousSelectedOptions.filter(filterSelelectedFn)
    )
    handleDisplayShowMore()
  }, [forceRefresh])

  useEffect(() => {
    rebuildListOptions(
      groupOptions(optionsKeys, fullOptions, sortFn, selectedOptions)
    )
  }, [optionsKeys, fullOptions])

  const rebuildListOptions = (newListOptions) => {
    setListOptions(newListOptions)
    onChangeListOptions(newListOptions)
  }

  const handleDisplayShowMore = () => {
    setDisplayShowMore(selectedOptions.length > DEFAULT_MAX_ITEMS)
  }

  const addOptionToSelectedOptions = (option) =>
    setSelectedOptions([
      ...selectedOptions,
      buildOption(option, fullOptions, optionsKeys),
    ])
  const removeOptionFromSelectedOptions = (option) =>
    setSelectedOptions(selectedOptions.filter(({ key }) => key !== option))
  const refreshListOptions = () => {
    rebuildListOptions(
      groupOptions(optionsKeys, fullOptions, sortFn, selectedOptions)
    )
  }

  const onDeleteSelectedOption = (option) => {
    removeOptionFromSelectedOptions(option)
    onDeleteOption(option)
  }

  const onChange = (target) => {
    const { value: option } = target
    addOptionToSelectedOptions(option)
    setFieldValue(inputPath, '')
    setCurrentOption('')
    onSelectOption(option)
  }

  const onClickShowMore = () => {
    setShowMore(!showMore)
    setMaxItems(maxItems === DEFAULT_MAX_ITEMS ? MAXIMUM : DEFAULT_MAX_ITEMS)
  }

  return (
    <>
      <Grid
        data-testid={inputPath.replace(/\./g, '-')}
        item
        xs={dropdownGridSize}
      >
        {!!listOptions.length && (
          <Dropdown
            path={inputPath}
            title={inputTitle}
            listOptions={listOptions}
            onChange={onChange}
            currentSelected={currentOption}
            clearAfterSelection={true}
          />
        )}
      </Grid>
      {children}
      {filteredSelectedOptions.length > 0 && (
        <Grid
          item
          xs={selectedOptionsGridSize}
          className={`
            ${classes.selectedOptionsContainer}
            ${displayShowMore ? classes.showMoreShadow : ''}
            ${showMore ? classes.showAll : classes.showLess}
            ${classes.btnShowMore}
          `}
        >
          <SelectedOptions
            options={filteredSelectedOptions}
            onDelete={onDeleteSelectedOption}
            maxItems={maxItems}
          />
        </Grid>
      )}
      {displayShowMore && (
        <Grid container justifyContent="center">
          <Button onClick={onClickShowMore}>
            {showMore ? 'Show Less' : 'Show More'}
          </Button>
        </Grid>
      )}
    </>
  )
}

_DropdownSelectOptions.defaultProps = {
  dropdownGridSize: 12,
  selectedOptionsGridSize: 12,
  sortFn: sortOptions,
  filterSelelectedFn: () => true,
  onSelectOption: () => {},
  onDeleteOption: () => {},
  onChangeListOptions: () => {},
}

_DropdownSelectOptions.propTypes = {
  optionsKeys: PropTypes.object.isRequired,
  fullOptions: PropTypes.object.isRequired,
  inputPath: PropTypes.string.isRequired,
  optionsPath: PropTypes.string.isRequired,
  inputTitle: PropTypes.string.isRequired,
  dropdownGridSize: PropTypes.number,
  selectedOptionsGridSize: PropTypes.number,
  sortFn: PropTypes.func,
  filterSelelectedFn: PropTypes.func,
  onSelectOption: PropTypes.func,
  onDeleteOption: PropTypes.func,
  onChangeListOptions: PropTypes.func,
  forceRefresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
}

export const DropdownSelectOptions = connect(_DropdownSelectOptions)
