import { get } from 'lodash'
import { QUALITY_TYPE } from 'emsurge-selectors/constants'

const getQualityType = (quality = {}) => quality.type

const isQualityCustom = (order) =>
  getQualityType(order.quality) === QUALITY_TYPE.CUSTOM

const isOrderCBMPermitted = (order) => !!get(order, 'quality.cbmPermitted')

const isOrderUSPermitted = (order) => !!get(order, 'quality.usPermitted')

export const quality = {
  isCustom: isQualityCustom,
  isCBMPermitted: isOrderCBMPermitted,
  isUSPermitted: isOrderUSPermitted,
}
