const { mapCountriesToData, OPTION_TYPE_IS_LDC } = require('./helpers')

const COUNTRIES_EUROPE_KEYS = {
  BELARUS: 'BELARUS',
  BULGARIA: 'BULGARIA',
  CZECHIA: 'CZECHIA',
  HUNGARY: 'HUNGARY',
  POLAND: 'POLAND',
  REPUBLIC_OF_MOLDOVA: 'REPUBLIC_OF_MOLDOVA',
  ROMANIA: 'ROMANIA',
  RUSSIAN_FEDERATION: 'RUSSIAN_FEDERATION',
  SLOVAKIA: 'SLOVAKIA',
  UKRAINE: 'UKRAINE',
  CHANNEL_ISLANDS: 'CHANNEL_ISLANDS',
  DENMARK: 'DENMARK',
  ESTONIA: 'ESTONIA',
  FAROE_ISLANDS: 'FAROE_ISLANDS',
  FINLAND: 'FINLAND',
  ICELAND: 'ICELAND',
  IRELAND: 'IRELAND',
  ISLE_OF_MAN: 'ISLE_OF_MAN',
  LATVIA: 'LATVIA',
  LITHUANIA: 'LITHUANIA',
  NORWAY: 'NORWAY',
  SWEDEN: 'SWEDEN',
  UNITED_KINGDOM: 'UNITED_KINGDOM',
  ALBANIA: 'ALBANIA',
  ANDORRA: 'ANDORRA',
  BOSNIA_AND_HERZEGOVINA: 'BOSNIA_AND_HERZEGOVINA',
  CROATIA: 'CROATIA',
  GIBRALTAR: 'GIBRALTAR',
  GREECE: 'GREECE',
  HOLY_SEE: 'HOLY_SEE',
  ITALY: 'ITALY',
  MALTA: 'MALTA',
  MONTENEGRO: 'MONTENEGRO',
  NORTH_MACEDONIA: 'NORTH_MACEDONIA',
  PORTUGAL: 'PORTUGAL',
  SAN_MARINO: 'SAN_MARINO',
  SERBIA: 'SERBIA',
  SLOVENIA: 'SLOVENIA',
  SPAIN: 'SPAIN',
  AUSTRIA: 'AUSTRIA',
  BELGIUM: 'BELGIUM',
  FRANCE: 'FRANCE',
  GERMANY: 'GERMANY',
  LIECHTENSTEIN: 'LIECHTENSTEIN',
  LUXEMBOURG: 'LUXEMBOURG',
  MONACO: 'MONACO',
  NETHERLANDS: 'NETHERLANDS',
  SWITZERLAND: 'SWITZERLAND',
}

const COUNTRIES_EUROPE_VALUES = {
  BELARUS: 'Belarus',
  BULGARIA: 'Bulgaria',
  CZECHIA: 'Czechia',
  HUNGARY: 'Hungary',
  POLAND: 'Poland',
  REPUBLIC_OF_MOLDOVA: 'Republic of Moldova',
  ROMANIA: 'Romania',
  RUSSIAN_FEDERATION: 'Russian Federation',
  SLOVAKIA: 'Slovakia',
  UKRAINE: 'Ukraine',
  CHANNEL_ISLANDS: 'Channel Islands',
  DENMARK: 'Denmark',
  ESTONIA: 'Estonia',
  FAROE_ISLANDS: 'Faroe Islands',
  FINLAND: 'Finland',
  ICELAND: 'Iceland',
  IRELAND: 'Ireland',
  ISLE_OF_MAN: 'Isle of Man',
  LATVIA: 'Latvia',
  LITHUANIA: 'Lithuania',
  NORWAY: 'Norway',
  SWEDEN: 'Sweden',
  UNITED_KINGDOM: 'United Kingdom',
  ALBANIA: 'Albania',
  ANDORRA: 'Andorra',
  BOSNIA_AND_HERZEGOVINA: 'Bosnia and Herzegovina',
  CROATIA: 'Croatia',
  GIBRALTAR: 'Gibraltar',
  GREECE: 'Greece',
  HOLY_SEE: 'Holy See',
  ITALY: 'Italy',
  MALTA: 'Malta',
  MONTENEGRO: 'Montenegro',
  NORTH_MACEDONIA: 'North Macedonia',
  PORTUGAL: 'Portugal',
  SAN_MARINO: 'San Marino',
  SERBIA: 'Serbia',
  SLOVENIA: 'Slovenia',
  SPAIN: 'Spain',
  AUSTRIA: 'Austria',
  BELGIUM: 'Belgium',
  FRANCE: 'France',
  GERMANY: 'Germany',
  LIECHTENSTEIN: 'Liechtenstein',
  LUXEMBOURG: 'Luxembourg',
  MONACO: 'Monaco',
  NETHERLANDS: 'Netherlands',
  SWITZERLAND: 'Switzerland',
}

const COUNTRIES_EUROPE = mapCountriesToData(
  COUNTRIES_EUROPE_KEYS,
  COUNTRIES_EUROPE_VALUES,
  {
    type: OPTION_TYPE_IS_LDC,
    elements: [],
  }
)

module.exports = {
  COUNTRIES_EUROPE_KEYS,
  COUNTRIES_EUROPE_VALUES,
  COUNTRIES_EUROPE,
}
