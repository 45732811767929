import {
  $O,
  DELIVERY_PERIOD,
  DELIVERY_PERIOD_TEXT,
  PRICE_TYPE,
  PRICE_UNIT,
} from 'emsurge-selectors'
import { difference, isEmpty, isNil, isNumber } from 'lodash'
import {
  formatLeadPrice,
  getFloatingPrice,
  getImpliedForIndex,
  getMainIndexSlug,
  getMidPointFromPriceList,
} from './useImpliedPrice.helpers'
import { DEFAULT_OPTIONS, OTHERS_INDEXES } from './useImpliedPrice.constants'
import { usePriceIndex } from 'api'

export const useImpliedPrice = (
  order,
  indexes = [],
  options = DEFAULT_OPTIONS
) => {
  const { deliveryPeriod } = $O.summary(order)
  const priceType = $O.price.type(order)
  const price = $O.price.get(order)
  const priceIndex = getMainIndexSlug(price?.index || '')
  const { data: lists, isLoading: isLoadingIndexes } = usePriceIndex(
    indexes,
    deliveryPeriod
  )
  const { data: orderPriceList, isLoading: isLoadingOrderPrice } =
    usePriceIndex([priceIndex], price?.delivery)

  if (
    isLoadingIndexes ||
    isLoadingOrderPrice ||
    (priceType === PRICE_TYPE.FLOATING && !indexes.includes(priceIndex)) ||
    ![PRICE_TYPE.FLOATING, PRICE_TYPE.FIXED].includes(priceType) ||
    price.unit !== PRICE_UNIT.MMBTU
  ) {
    return null
  }

  if (priceType === PRICE_TYPE.FLOATING && isEmpty(orderPriceList)) {
    return []
  }

  const midpoints = indexes.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: getMidPointFromPriceList(lists?.[curr]),
    }),
    {
      orderPrice: getMidPointFromPriceList(
        orderPriceList?.[priceIndex],
        priceIndex
      ),
    }
  )

  if (priceType === PRICE_TYPE.FIXED && parseFloat(price.value)) {
    return indexes
      .map((index) =>
        midpoints?.[index]
          ? getImpliedForIndex(
              index,
              deliveryPeriod,
              price.value - midpoints?.[index],
              lists
            )
          : null
      )
      .filter((value) => !isNil(value))
  }

  if (
    priceType === PRICE_TYPE.FLOATING &&
    (isNumber(price.amount) || isNil(price.amount)) &&
    ![
      DELIVERY_PERIOD_TEXT[DELIVERY_PERIOD.M],
      DELIVERY_PERIOD_TEXT[DELIVERY_PERIOD.M1],
    ].includes(price.delivery)
  ) {
    const leadIndexPrice = midpoints.orderPrice
    const otherIndexes = [
      ...(price.delivery === deliveryPeriod ? [] : [priceIndex]),
      ...difference(indexes, [priceIndex]),
    ]

    const { floating, fixed, full } = getFloatingPrice(price, leadIndexPrice)
    const leadPrice = formatLeadPrice(full, options)

    const otherIndexesPrices = otherIndexes
      .map((otherIndex) => {
        const variance = floating - midpoints[otherIndex] + fixed
        return midpoints[otherIndex]
          ? getImpliedForIndex(otherIndex, deliveryPeriod, variance, lists)
          : null
      })
      .filter((value) => !isNil(value))

    return [
      ...(leadIndexPrice ? [leadPrice] : []),
      ...(options[OTHERS_INDEXES] ? otherIndexesPrices : []),
    ]
  }

  return null
}
