const getFsruFsu = (terms = {}) => terms.fsruFsu || ''

const getFsruFsuText = (terms) => {
  const fsru_fsu = getFsruFsu(terms)
  return fsru_fsu ? `FSRU/FSU: ${fsru_fsu} consecutive hours` : ''
}

module.exports = {
  get: getFsruFsu,
  summary: getFsruFsu,
  text: getFsruFsuText,
}
