import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { get } from 'lodash'
import { mapToDateTime, parseMomentDate } from './DateTime.utils'
import { DateField, TimeField, TimezoneField } from './DateTime.fields'
import { ACTIONS } from './DateTime.actions'
import { reducer, computeState } from './DateTime.reducer'
import { hasValues } from 'utils/objects'
import FormRow from 'components/formRow/FormRow'

const { SET_FULL_DATE, UPDATE_DATE, UPDATE_TIME, UPDATE_TIMEZONE } = ACTIONS

export const PureDateTime = ({ formik, name, labels, disabled }) => {
  const { setFieldValue, values } = formik
  const currentValue = get(values, name)
  const initialState = computeState(parseMomentDate(currentValue))
  const [{ date, time, timezone }, dispatch] = useReducer(reducer, initialState)

  // when a new value prop is received from the form, parse it and set the state with the result
  useEffect(() => {
    dispatch({ type: SET_FULL_DATE, ...parseMomentDate(currentValue) })
  }, [currentValue])

  // whenever the state changes, update the form
  useEffect(() => {
    if (!hasValues({ date, time, timezone })) {
      return
    }
    setFieldValue(name, mapToDateTime({ date, time, timezone }))
  }, [date, name, setFieldValue, time, timezone])

  return (
    <FormRow>
      <TimezoneField
        label={labels.timezone}
        value={timezone}
        onChange={(value) => dispatch({ type: UPDATE_TIMEZONE, value })}
        disabled={disabled}
      />
      <DateField
        label={labels.date}
        value={date}
        onChange={(value) => value && dispatch({ type: UPDATE_DATE, value })}
        disabled={disabled}
      />
      <TimeField
        label={labels.time}
        value={time}
        onChange={(value) => dispatch({ type: UPDATE_TIME, value })}
        disabled={disabled}
      />
    </FormRow>
  )
}

PureDateTime.defaultProps = {
  disabled: false,
}

PureDateTime.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  labels: PropTypes.object,
  disabled: PropTypes.bool,
}

PureDateTime.defaultProps = {
  labels: {
    date: 'Date',
    time: 'Time',
    timezone: 'Timezone',
  },
  disabled: false,
}

export const DateTime = connect(PureDateTime)
