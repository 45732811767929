const { DELIVERY_TYPE } = require('./type')

const { DES, FOB, CIF, DAT, DAP, STS, INT } = DELIVERY_TYPE

const DELIVERY_REGION_TYPE = Object.freeze({
  ANY: 'ANY',
  ATL: 'ATL',
  AUS: 'AUS',
  AUSNE: 'AUSNE',
  AUSW: 'AUSW',
  CA_ATL: 'CA Atl',
  CA_PAC: 'CA Pac',
  CANE: 'CANE',
  DKI: 'DKI',
  EAF: 'EAF',
  EOS: 'EOS',
  EUR_R: 'EURr',
  EUR: 'EUR',
  FE_R: 'FEr',
  FE_SEA: 'FE+SEA',
  FE: 'FE',
  FE1: 'FE1',
  IB: 'IB',
  JK: 'JK',
  JKC: 'JKC',
  JKTC: 'JKTC',
  JKTCI: 'JKTCI',
  mCJK: 'mCJK',
  ME: 'ME',
  MED: 'MED',
  MEI_R: 'MEIr',
  MEI: 'MEI',
  MEI1: 'MEI1',
  MEIP: 'MEIP',
  MEIPB: 'MEIPB',
  MEIX: 'MEIX',
  NA: 'NA',
  NAF: 'NAF',
  NWE: 'NWE',
  NWE_IBERIA: 'NWE + IBERIA',
  NWE_SPAIN: 'NWE + N. SPAIN',
  PAC: 'PAC',
  SA_ATL: 'SA Atl',
  SA_PAC: 'SA Pac',
  SA: 'SA',
  SAK: 'SAK',
  SEA_R: 'SEAr',
  SEA: 'SEA',
  TT: 'TT',
  USGC: 'USGC',
  USNE: 'USNE',
  WAF: 'WAF',
  WME: 'WME',
})

const DELIVERY_REGION_DES = Object.freeze([
  DELIVERY_REGION_TYPE.ATL,
  DELIVERY_REGION_TYPE.EOS,
  DELIVERY_REGION_TYPE.EUR,
  DELIVERY_REGION_TYPE.IB,
  DELIVERY_REGION_TYPE.JK,
  DELIVERY_REGION_TYPE.JKTC,
  DELIVERY_REGION_TYPE.MED,
  DELIVERY_REGION_TYPE.MEI,
  DELIVERY_REGION_TYPE.MEIP,
  DELIVERY_REGION_TYPE.NWE,
  DELIVERY_REGION_TYPE.NWE_IBERIA,
  DELIVERY_REGION_TYPE.NWE_SPAIN,
  DELIVERY_REGION_TYPE.ANY,
  DELIVERY_REGION_TYPE.CA_ATL,
  DELIVERY_REGION_TYPE.CA_PAC,
  DELIVERY_REGION_TYPE.CANE,
  DELIVERY_REGION_TYPE.DKI,
  DELIVERY_REGION_TYPE.FE,
  DELIVERY_REGION_TYPE.FE_SEA,
  DELIVERY_REGION_TYPE.FE1,
  DELIVERY_REGION_TYPE.JKC,
  DELIVERY_REGION_TYPE.JKTCI,
  DELIVERY_REGION_TYPE.mCJK,
  DELIVERY_REGION_TYPE.MEI1,
  DELIVERY_REGION_TYPE.MEIPB,
  DELIVERY_REGION_TYPE.MEIX,
  DELIVERY_REGION_TYPE.NA,
  DELIVERY_REGION_TYPE.SA_ATL,
  DELIVERY_REGION_TYPE.SA_PAC,
  DELIVERY_REGION_TYPE.SA,
  DELIVERY_REGION_TYPE.SEA,
  DELIVERY_REGION_TYPE.USGC,
  DELIVERY_REGION_TYPE.USNE,
  DELIVERY_REGION_TYPE.WAF,
  DELIVERY_REGION_TYPE.WME,
])

const DELIVERY_REGION_FOB = Object.freeze([
  DELIVERY_REGION_TYPE.ATL,
  DELIVERY_REGION_TYPE.USGC,
  DELIVERY_REGION_TYPE.EUR,
  DELIVERY_REGION_TYPE.WAF,
  DELIVERY_REGION_TYPE.NAF,
  DELIVERY_REGION_TYPE.ME,
  DELIVERY_REGION_TYPE.PAC,
  DELIVERY_REGION_TYPE.AUS,
  DELIVERY_REGION_TYPE.SEA,
  DELIVERY_REGION_TYPE.TT,
  DELIVERY_REGION_TYPE.ANY,
  DELIVERY_REGION_TYPE.AUSNE,
  DELIVERY_REGION_TYPE.AUSW,
  DELIVERY_REGION_TYPE.EAF,
  DELIVERY_REGION_TYPE.EUR_R,
  DELIVERY_REGION_TYPE.FE_R,
  DELIVERY_REGION_TYPE.MEI,
  DELIVERY_REGION_TYPE.MEI_R,
  DELIVERY_REGION_TYPE.NA,
  DELIVERY_REGION_TYPE.SA,
  DELIVERY_REGION_TYPE.SAK,
  DELIVERY_REGION_TYPE.SEA_R,
])

const DELIVERY_REGION = Object.freeze({
  [DES]: DELIVERY_REGION_DES,
  [FOB]: DELIVERY_REGION_FOB,
  [CIF]: DELIVERY_REGION_DES,
  [DAT]: DELIVERY_REGION_DES,
  [DAP]: DELIVERY_REGION_DES,
  [STS]: DELIVERY_REGION_DES,
  [INT]: DELIVERY_REGION_DES,
})

module.exports = {
  DELIVERY_REGION,
  DELIVERY_REGION_TYPE,
}
