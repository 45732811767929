import React from 'react'
import PropTypes from 'prop-types'
import { head, tail } from 'lodash'
import { $O } from 'emsurge-selectors/models/order'
import { PRICE_TYPE, PRICE_VARIANT } from 'emsurge-selectors/constants'
import DetailRow from './DetailRow'

const PriceSummary = ({ title, variant, order, gutterBottom, ...props }) => {
  const price = $O.price[variant]

  if (!price.exists(order)) {
    return null
  }

  const summary = price.summary(order)
  const priceType = price.type(order)

  if (priceType === PRICE_TYPE.HYBRID || priceType === PRICE_TYPE.RFQ) {
    return (
      <>
        <DetailRow
          title={title}
          info={[summary.type, head(summary.long)]}
          gutterBottom={gutterBottom}
          {...props}
        />
        {tail(summary.long).map((info, idx) => (
          <DetailRow
            key={idx}
            info={['', info]}
            gutterBottom={gutterBottom}
            {...props}
          />
        ))}
      </>
    )
  }

  return (
    <DetailRow
      title={title}
      info={[summary.type, summary.long]}
      gutterBottom
      {...props}
    />
  )
}

PriceSummary.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(PRICE_VARIANT)).isRequired,
  order: PropTypes.object.isRequired,
  gutterBottom: PropTypes.bool,
}

export default PriceSummary
