const moment = require('moment')

const {
  DELIVERY_FREQUENCY_TYPE,
  DELIVERY_PERIOD_CUSTOM,
  DELIVERY_PERIOD_MONTH,
  DELIVERY_PERIOD_QUARTER,
  DELIVERY_PERIOD_SEASON,
  DELIVERY_PERIOD_YEAR,
} = require('../../../constants')

const period = require('./period')
const volume = require('./volume')
const frequency = require('./frequency')

const getDeliveryPeriodDifference = (deliveryPeriod, timeUnitMoment) => {
  const fromDateMoment = moment(deliveryPeriod.from)
  const toDateMoment = moment(deliveryPeriod.to)

  return toDateMoment.diff(fromDateMoment, timeUnitMoment) + 1
}

const getDeliveryPeriodFactor = (deliveryPeriod) => {
  if (Object.values(DELIVERY_PERIOD_CUSTOM).includes(deliveryPeriod.type)) {
    return getDeliveryPeriodDifference(deliveryPeriod, 'months')
  }

  if (Object.values(DELIVERY_PERIOD_MONTH).includes(deliveryPeriod.type)) {
    return 1
  }

  if (Object.values(DELIVERY_PERIOD_QUARTER).includes(deliveryPeriod.type)) {
    return 3
  }

  if (Object.values(DELIVERY_PERIOD_SEASON).includes(deliveryPeriod.type)) {
    return 6
  }

  if (Object.values(DELIVERY_PERIOD_YEAR).includes(deliveryPeriod.type)) {
    return 12
  }
}

const getCargoCount = (order) => {
  const deliveryVolume = volume.get(order)
  const { type: frequencyType } = frequency.get(order)
  const deliveryPeriod = period.get(order)

  let factor = 1

  if (frequencyType === DELIVERY_FREQUENCY_TYPE.PER_PERIOD) {
    factor = 1
  }

  if (frequencyType === DELIVERY_FREQUENCY_TYPE.PER_ANNUM) {
    if (Object.values(DELIVERY_PERIOD_CUSTOM).includes(deliveryPeriod.type)) {
      factor = getDeliveryPeriodDifference(deliveryPeriod, 'years')
    } else {
      factor = 1
    }
  }

  if (frequencyType === DELIVERY_FREQUENCY_TYPE.PER_MONTH) {
    factor = getDeliveryPeriodFactor(deliveryPeriod)
  }

  const min = (deliveryVolume.min || 0) * factor
  const max = (deliveryVolume.max || 0) * factor

  return {
    min,
    max: min > max ? min : max,
  }
}

module.exports = {
  get: getCargoCount,
}
