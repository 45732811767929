import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import backgroundImg from 'screens/background.png'

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    width: '100vw',
    minHeight: '95vh',
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: 'bottom right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  contentContainer: {
    maxWidth: 482 + theme.spacing(6),
    padding: theme.spacing(8, 3, 0, 3),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

export const LayoutWrapper = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.rootContainer}>
      <div className={classes.contentContainer}>{children}</div>
    </div>
  )
}

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
