import { PRIMARY_POINT_CONTACT } from './PrimaryPointContact/FormInitialValues'
import { GENERAL_INFORMATION_INITIAL_VALUES } from './GeneralInformation/FormInitialValues'
import { AUTHORISED_SIGNATORY_INITIAL_VALUES } from './AuthorisedSignatory/FormInitialValues'

const TRADING_ENTITY_INITIAL_VALUES = {
  ...GENERAL_INFORMATION_INITIAL_VALUES,
  pointOfContact: {
    ...PRIMARY_POINT_CONTACT,
  },
  authorisedSignatory: {
    ...AUTHORISED_SIGNATORY_INITIAL_VALUES,
  },
}

export { TRADING_ENTITY_INITIAL_VALUES }
