import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { noop, omit } from 'lodash'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { MARKET_GRID } from 'api/constants'

export const useEditGrid = () => {
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const putGrid = async (data) =>
    api.editGrid(data.id, omit(data, ['id']))

  const onSuccess = () => {
    enqueueSnackbar('Market Grid updated successfully', { variant: 'success' })
    queryClient.refetchQueries([`${MARKET_GRID}s`])
  }

  const onError = (error, _, rollback = noop) => {
    const message = getApiErrorMessage({
      error,
      defaultMessage: 'Market Grid update failed',
    })
    enqueueSnackbar(message, { variant: 'error' })
    rollback()
  }

  return useMutation(putGrid, {
    onSuccess,
    onError,
    throwOnError: true,
  })
}
