import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import FormProvider from './containers/FormProvider'
import ModalContent from 'screens/orderCreate/desktop/ModalContent'
import ModalHeader from 'screens/orderCreate/desktop/ModalHeader'
import ModalContentCarbon from 'screens/orderCreateCarbon/ModalContent'
import ModalHeaderCarbon from 'screens/orderCreateCarbon/ModalHeader'
import FormProviderCarbon from 'screens/orderCloneCarbon/containers/FormProvider'
import { useOrderFlow, ORDER_FLOW } from 'containers/OrderProvider'
import { useRouter } from 'utils/useRouter'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { useOrder } from 'api'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { DASHBOARD, ORDER_BASE_ROUTES } from 'routes'
import { Mobile, Tablet } from 'components/Viewport'
import { CreateOrderModal } from 'screens/orderCreate/mobile/CreateOrderModal'

const OrderClone = () => {
  const { history } = useRouter()
  const { id } = useParams()
  const BASE_SLUG = useRouteBaseSlug()
  const { setFlow } = useOrderFlow()
  const { isLoading, data: order } = useOrder(id)
  const rootSlug = ORDER_BASE_ROUTES.includes(`/${BASE_SLUG}`)
    ? `/${BASE_SLUG}`
    : DASHBOARD

  const goToOriginalOrder = () => history.push(`${rootSlug}/orders/${id}`)

  const goToOriginalOrderCarbon = () => history.push(`${rootSlug}/orders/${id}`)

  const goToNewOrder = (order) => {
    history.push(`/${BASE_SLUG}/orders/${order.id}`)
  }

  const goToNewOrderCarbon = (order) => {
    history.push(`/${BASE_SLUG}/orders/${order.id}`)
  }

  useEffect(() => setFlow(ORDER_FLOW.CLONE), [setFlow])
  useEffect(() => order.submissionDate = new Date().toISOString(), [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      {order.context === APP_CONTEXT_TYPE.LNG && (
        <FormProvider order={order} postSubmit={goToNewOrder}>
          <>
            <Tablet matchLarger>
              <ModalHeader onCancel={goToOriginalOrder} />
              <ModalContent />
            </Tablet>
            <Mobile>
              <CreateOrderModal onCancel={goToOriginalOrder} />
            </Mobile>
          </>
        </FormProvider>
      )}
      {order.context === APP_CONTEXT_TYPE.CARBON && (
        <FormProviderCarbon order={order} postSubmit={goToNewOrderCarbon}>
        <>
          <Tablet matchLarger>
            <ModalHeaderCarbon onCancel={goToOriginalOrderCarbon} isEdit />
            <ModalContentCarbon isEdit/>
          </Tablet>
        </>
      </FormProviderCarbon>
      )}
    </>
  )
}

export default OrderClone
