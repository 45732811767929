import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'

import { $O, TRADING_TYPE } from 'emsurge-selectors'
import NominationsPanel from './NominationsPanel'
import InformationPanel from './InformationPanel'
import PublicNote from './PublicNote'

export const Information = ({ order, clickable, isCreatingTrade }) => {
  const { spacing } = useTheme()
  const panelProps = { order }
  const entityCode = $O.stakeholder.code.text(order)

  if (order.tradingType === TRADING_TYPE.BID) {
    panelProps.buyer = entityCode
  } else {
    panelProps.seller = entityCode
  }

  return (
    <section>
      <PublicNote order={order} style={{ marginBottom: spacing(1) }} />
      <InformationPanel clickable={clickable} order={order} isCreatingTrade={isCreatingTrade} />
      <NominationsPanel clickable={clickable} {...panelProps} isCreatingTrade={isCreatingTrade}/>
    </section>
  )
}

Information.defaultProps = {
  clickable: false,
}

Information.propTypes = {
  order: PropTypes.object.isRequired,
  clickable: PropTypes.bool,
}
