import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'

import { PRICE_VARIANT } from 'emsurge-selectors/constants'
import { TextField } from 'screens/orderCreateCommon/components/TextField'

const PATH = 'price.$variant$.$rfq$.$context$.$field$'

export const Rfq = ({ variant, rfqPath = 'rfqInfo' }) => {
  const computeDeadlinePath = (context, field) => {
    return PATH.replace('$variant$', variant)
      .replace('$rfq$', rfqPath)
      .replace('$context$', context)
      .replace('$field$', field)
  }

  const computeDeadlinePathNoContext = (field) => {
    return PATH.replace('$variant$', variant)
      .replace('$rfq$', rfqPath)
      .replace('.$context$', '')
      .replace('$field$', field)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            type="date"
            name={computeDeadlinePath('deadline', 'date')}
            label="Deadline"
            inputProps={{ 'data-testid': 'rfq-price-deadline-date' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="time"
            name={computeDeadlinePath('deadline', 'time')}
            label=" "
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">UTC</InputAdornment>
              ),
            }}
            inputProps={{ 'data-testid': 'rfq-price-deadline-time' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            type="date"
            name={computeDeadlinePath('award', 'date')}
            label="Award date"
            inputProps={{ 'data-testid': 'rfq-price-award-date' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="time"
            name={computeDeadlinePath('award', 'time')}
            label=" "
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">UTC</InputAdornment>
              ),
            }}
            inputProps={{ 'data-testid': 'rfq-price-award-time' }}
          />
        </Grid>
      </Grid>

      <TextField
        multiline
        rows={3}
        name={computeDeadlinePathNoContext('notes')}
        label="Request for quotes"
        helperText="Use this field to provide any additional information"
        inputProps={{ 'data-testid': 'rfq-price-request-for-quotes' }}
      />
    </>
  )
}

Rfq.propTypes = {
  variant: PropTypes.oneOf(Object.values(PRICE_VARIANT)).isRequired,
  rfqPath: PropTypes.string,
}
