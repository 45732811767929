import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import InviteFormProvider from '../containers/InviteFormProvider'
import SendInvitationButton from './InviteCompanySubmitButton'
import { EmailField, NameField } from './InviteCompanyFields'

const InviteCompanyDialog = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  const open = () => setOpen(true)
  const close = () => setOpen(false)

  return (
    <>
      <Dialog open={isOpen} onClose={close} maxWidth="lg">
        <InviteFormProvider onPostSubmit={close}>
          <Form>
            <DialogContent>
              <DialogContentText variant="h2">
                Fill the basic information and send the invite
              </DialogContentText>

              <EmailField />
              <NameField />
            </DialogContent>

            <DialogActions>
              <SendInvitationButton />
            </DialogActions>
          </Form>
        </InviteFormProvider>
      </Dialog>

      {children({ open })}
    </>
  )
}

InviteCompanyDialog.propTypes = {
  children: PropTypes.func.isRequired,
}

export default InviteCompanyDialog
