const unknown = require('./unknown')
const fixed = require('./fixed')
const floating = require('./floating')
const hybrid = require('./hybrid')
const preference = require('./preference')
const rfq = require('./rfq')
const tbd = require('./tbd')
const { PRICE_TYPE, PRICE_VARIANT } = require('../../../constants')

const getVariant = (variantName, price = {}) => {
  const variant = price[variantName]

  return variant || {}
}

const getPriceApi = (type) => {
  switch (type) {
    case PRICE_TYPE.FIXED:
      return fixed
    case PRICE_TYPE.FLOATING:
      return floating
    case PRICE_TYPE.HYBRID:
      return hybrid
    case PRICE_TYPE.PREFERENCE:
      return preference
    case PRICE_TYPE.RFQ:
      return rfq
    case PRICE_TYPE.TBD:
      return tbd
    default:
      return undefined
  }
}

const delegateFn = (
  methodName,
  variantName = PRICE_VARIANT.BASIC,
  order = {}
) => {
  const variant = getVariant(variantName, order.price)
  const api = getPriceApi(variant.type)

  let methodFn

  if (!api) {
    methodFn = unknown[methodName]
  } else {
    methodFn = api[methodName]
  }

  return methodFn(variant.info)
}

const getPriceType = (variantName, order) => {
  const variant = getVariant(variantName, order.price)
  return variant.type
}

/**
 *  Since each price variant has the same price types, this sets existing price type methods for each of those variants.
 *  Any newly added variants will automatically have these methods available. In case the variant is not specified, it
 *  defaults to basic.
 *  e.g.
 *  - $o.price.basic.summary(order) // gets the order's price summary for the basic variant
 *  - $o.price.contract.summary(order) // gets the order's price summary for the contract variant
 *  - $o.price.summary(order) // gets the order's price summary for the basic variant (default)
 * */
module.exports = Object.values(PRICE_VARIANT).reduce(
  (price, variantName) => {
    price[variantName] = {
      exists: delegateFn.bind(undefined, 'exists', variantName),
      get: delegateFn.bind(undefined, 'get', variantName),
      summary: delegateFn.bind(undefined, 'summary', variantName),
      type: getPriceType.bind(undefined, variantName),
    }

    return price
  },
  {
    exists: delegateFn.bind(undefined, 'exists', PRICE_VARIANT.BASIC),
    get: delegateFn.bind(undefined, 'get', PRICE_VARIANT.BASIC),
    summary: delegateFn.bind(undefined, 'summary', PRICE_VARIANT.BASIC),
    type: getPriceType.bind(undefined, PRICE_VARIANT.BASIC),
  }
)
