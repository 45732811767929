const hasPrice = () => false
const getPrice = () => undefined
const getText = () => ({
  long: '',
  short: '',
})

module.exports = {
  exists: hasPrice,
  get: getPrice,
  summary: getText,
}
