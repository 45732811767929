import React from 'react'
import Grid from '@material-ui/core/Grid'

import { Unit } from './Unit'
import { Type } from './Type'
import { Party } from './Party'
import { PaddedContainer } from 'screens/orderCreate/mobile/components'
import { VarianceFields } from 'screens/orderCreate/components/VarianceFields'
import { CargoSizeFields } from 'screens/orderCreate/components/CargoSizeFields'

export const CargoSize = () => (
  <PaddedContainer>
    <Unit />
    <Type />

    <CargoSizeFields />
    <Grid container spacing={1}>
      <VarianceFields labels={{ minus: '-', plus: '+' }} />
    </Grid>

    <Party />
  </PaddedContainer>
)
