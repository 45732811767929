import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Formik } from 'formik'
import { withSnackbar } from 'notistack'
import withRouteParams from 'utils/withRouteParams'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

/* :: object[] -> object */
const getInitialValues = (companies) => ({
  company: companies[0].id,
})

/* :: (object[], object) -> object[] */
const getApiData = (permissionedEntities, values) => {
  const entityIdToAdd = values.entity

  return permissionedEntities
    .map(({ id: entityId }) => ({ entityId }))
    .concat({ entityId: entityIdToAdd })
}

const NewEntityFormProvider = ({
  id,
  refetch,
  companies,
  permissionedEntities,
  enqueueSnackbar,
  children,
}) => {
  const api = useApi()

  /* :: (object, object) -> Promise<void> */
  const handleSubmit = async (values, actions) => {
    const data = getApiData(permissionedEntities, values)

    try {
      await api.put(`/permissions/broker/${id}`, data)
      enqueueSnackbar('Entity has been permissioned successfully', {
        variant: 'success',
      })
      refetch()
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Entity permission has failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik initialValues={getInitialValues(companies)} onSubmit={handleSubmit}>
      {children}
    </Formik>
  )
}

NewEntityFormProvider.propTypes = {
  id: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  permissionedEntities: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default compose(withRouteParams, withSnackbar)(NewEntityFormProvider)
