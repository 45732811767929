import { get, values } from 'lodash'
import { DELIVERY_FREQUENCY_TYPE } from 'emsurge-selectors/constants'

/* :: object -> boolean */
const hasQuota = (order) => {
  const deliveryFrequency = get(order, 'delivery.frequency')
  const cargoQuantity = parseInt(get(order, 'volume.min'), 10)

  return (
    values(DELIVERY_FREQUENCY_TYPE).includes(deliveryFrequency) &&
    Number.isFinite(cargoQuantity) &&
    cargoQuantity > 0
  )
}

/* :: object -> boolean */
const getQuota = (order) => {
  if (!hasQuota(order)) {
    throw new Error('Order has no quota (frequency + cargo quantity)')
  }

  const { frequency } = order.delivery
  const min = parseInt(order.volume.min, 10)
  const max = parseInt(order.volume.max, 10)

  if (Number.isFinite(max) && max > min) {
    return { frequency, quantity: [min, max] }
  }

  return { frequency, quantity: min }
}

/* :: object -> boolean */
const isQuotaRange = (order) => Array.isArray(getQuota(order).quantity)

export const quota = {
  exists: hasQuota,
  get: getQuota,
  isRange: isQuotaRange,
}
