import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { XsmallText } from 'components/Text'

const Person = ({ person, ...props }) => {
  const firstName = get(person, 'name', '')
  const surname = get(person, 'surname', '')
  const fullname =
    !isEmpty(firstName) || !isEmpty(surname) ? `${firstName} ${surname}` : ''

  return <XsmallText {...props}>{fullname}</XsmallText>
}

Person.propTypes = {
  person: PropTypes.object.isRequired,
}

export default Person
