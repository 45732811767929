export const initializeClientConfig = async (clientConfig) => {
  console.debug('Loading config.json from server...')
  const fetchResult = await fetch('/config.json')
  const configJson = await fetchResult.json()
  console.debug('Config.json loaded', configJson)

  Object.assign(clientConfig, configJson)
  Object.freeze(clientConfig)
  console.debug('clientConfig has been initialized', clientConfig)
  return clientConfig
}
