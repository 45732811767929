import React from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'
import { ActivityCardSkeleton } from '../ActivityCardSkeleton'
import {
  useSkeletonStyles,
  ORDER_ACTIVITY_CARD_HEIGHT_LNG,
} from 'screens/orderIndex/helpers'
import { Container } from 'components/Card'

export const MarketInterestOrderCardSkeleton = ({ style }) => {
  const { root } = useSkeletonStyles()
  const cardStyles = {
    marginTop: '4px',
    width: '49%',
    height: `${ORDER_ACTIVITY_CARD_HEIGHT_LNG}px`,
  }
  return (
    <Container style={{ flexDirection: 'column', ...style }}>
      <div className={root} style={{ width: '140px', height: '22px' }} />
      <Container style={{ marginTop: '4px' }} justifyContent="space-around">
        {times(8, (i) => (
          <ActivityCardSkeleton
            key={`market-interest-card-skeleton-${i}`}
            style={cardStyles}
          />
        ))}
      </Container>
    </Container>
  )
}

MarketInterestOrderCardSkeleton.propTypes = {
  style: PropTypes.object,
}
