import React from 'react'
import ReactDOM from 'react-dom'
import App from 'containers/app/App'
import AppProvider from 'containers/appProvider/AppProvider'
import clientConfig from 'clientConfig'
import { initializeClientConfig } from 'initializeClientConfig'
import {
  getApiBaseUrl,
  getAppInsightsConnectionString,
  // setVersion,
} from 'utils/getApiConfig'

initializeClientConfig(clientConfig)
  .then((result) => {
    console.debug('clientConfig is', result)

    const appInsightsConnectionString = getAppInsightsConnectionString()
    const apiBaseUrl = getApiBaseUrl()
    console.debug(
      "Making a call to the API base URL to make sure it's available",
      apiBaseUrl
    )
    fetch(apiBaseUrl)
      .then(async (x) => {
        const result = await x.json()
        // setVersion(result.version)
        console.log('API seems to be available. Response: ', result)
      })
      .catch((x) => console.error('API returned an error', x))

    ReactDOM.render(
      <AppProvider appInsightsConnectionString={appInsightsConnectionString}>
        <App clientConfig={result} />
      </AppProvider>,
      document.getElementById('root')
    )
  })
  .catch((err) => {
    console.error(err)
    alert(
      'Failed to load the configuration. Cannot proceed. Please, get in touch with us at support@emsurge.com'
    )
  })
