import PropTypes from 'prop-types'
import { connect } from 'formik'

import { CARGO_SIZE_TYPE } from 'emsurge-selectors/constants'

const _SelectCargoSizeType = ({ formik, children }) => {
  const onChange = (value) => {
    const sizeMin = Object.values(CARGO_SIZE_TYPE).includes(value)
      ? null
      : Number(value)
    formik.setFieldValue('cargoSize.sizeMin', sizeMin)
  }

  const { unit } = formik.values.cargoSize

  return children({ unit, onChange })
}

_SelectCargoSizeType.propTypes = {
  formik: PropTypes.object.isRequired,
  children: PropTypes.func,
}

export const SelectCargoSizeType = connect(_SelectCargoSizeType)
