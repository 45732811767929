import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/styles'
import ActivityNoteCard from 'screens/orderIndex/components/ActivityNoteCard'

const ActivityCard = ({ note, style, onClick, selected, theme }) => {
  if (selected) {
    style.backgroundColor = theme.palette.background.secondary
  }

  return (
    <div onClick={onClick}>
      <ActivityNoteCard style={style} activity={note} />
    </div>
  )
}

ActivityCard.defaultProps = {
  onClick: () => {},
  style: {},
}

ActivityCard.propTypes = {
  style: PropTypes.object,
  note: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  theme: PropTypes.object.isRequired,
}

export default withTheme(ActivityCard)
