import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { withTheme } from '@material-ui/styles'

const Block = ({ title, children, theme }) => (
  <section style={{ marginBottom: theme.spacing(5) }}>
    <Text component="h2" variant="h2" gutterBottom>
      {title}
    </Text>
    {children}
  </section>
)

Block.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default withTheme(Block)
