const { ORDER_STATUS_TEXT } = require('../../constants')

const getStatus = (order) => {
  return order.status
}

const getStatusText = (order) => {
  return ORDER_STATUS_TEXT[order.status] || ''
}

module.exports = {
  get: getStatus,
  text: getStatusText,
}
