import { isEmpty } from 'lodash'
import {
  getPartyLabel,
  getSizeLabel,
  getTimeFrameLabel,
  getVesselType,
} from '../helpers'

export const returnCommonData = (data) => {
  return data ? [data] : []
}

export const getVesselTypeData = (vesselType, size, toBeNominated) => {
  const rangeSize = getSizeLabel(size, 'range')
  const singleSize = getSizeLabel(size, 'single')
  const sizeLabel = toBeNominated ? rangeSize : singleSize
  const sizeLabelValues = isEmpty(sizeLabel) ? [] : [sizeLabel]
  const data = `${getVesselType(vesselType)}: ${sizeLabelValues}`
  return returnCommonData(data)
}

export const getNominationSentence = (
  timeframe,
  timeframeValue,
  party,
  nominationType,
  toBeNominated,
  timeframeValueDeliveryWindow
) => {
  const timeframeValueLabel = getTimeFrameLabel(
    timeframe,
    timeframeValue,
    timeframeValueDeliveryWindow
  )
  const nominationSentence = `For each cargo, the ${getPartyLabel(
    party
  )} ${nominationType} ${timeframeValueLabel}`
  const data = toBeNominated ? nominationSentence : ''
  return returnCommonData(data)
}

export const getNameInfo = (name) => {
  const data = `${name || ''}`
  return returnCommonData(data)
}
