import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import { BrandLogo } from 'components/logo'
import { HOME } from 'routes'

const _BrandLogoGold = ({ history, styles }) => (
  <BrandLogo
    data-testid="app-logo"
    backgroundColor={useTheme().palette.primary.main}
    width={40}
    style={{ marginRight: 'auto', cursor: 'pointer', ...styles }}
    onClick={() => history.push(HOME)}
  />
)

_BrandLogoGold.propTypes = {
  history: PropTypes.object.isRequired,
  styles: PropTypes.object,
}

export const BrandLogoGold = withRouter(_BrandLogoGold)
