import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useTheme } from '@material-ui/styles'
import { Hidden, Grid, Typography as Text } from '@material-ui/core'
import { StyledGrid } from './StyledGrid'

const PortNominations = ({
  title,
  style,
  name,
  nominationSentence,
  gutterBottom,
  onClick,
  additionalInfo,
  ...textProps
}) => {
  const hasContent = !!name.trim() || !!nominationSentence.trim()
  const { spacing } = useTheme()

  return (
    <StyledGrid onClick={onClick} container>
      <Grid item xs={12} sm={3}>
        <Text
          variant="body2"
          gutterBottom={gutterBottom}
          style={{ fontWeight: 700, ...style }}
        >
          {title}
        </Text>
      </Grid>

      <Grid item xs sm={7} style={{ style, marginBottom: '5px' }}>
        <Text
          style={{ style, marginBottom: '2px' }}
          variant="body1"
          gutterBottom={gutterBottom}
          {...textProps}
        >
          {name}
        </Text>
        <Text
          style={style}
          variant="body1"
          gutterBottom={gutterBottom}
          {...textProps}
          data-testid={
            textProps?.['data-testid'] &&
            `${textProps['data-testid']}-nomination`
          }
        >
          {nominationSentence}
        </Text>
      </Grid>

      {hasContent && !isEmpty(additionalInfo) && (
        <Hidden xsDown>
          <Grid item>
            <Text
              style={{ ...style, paddingLeft: spacing(2) }}
              variant="body1"
              gutterBottom={gutterBottom}
              {...textProps}
              data-testid={
                textProps?.['data-testid'] &&
                `${textProps['data-testid']}-nomination-date`
              }
            >
              {additionalInfo}
            </Text>
          </Grid>
        </Hidden>
      )}
    </StyledGrid>
  )
}

PortNominations.defaultProps = {
  gutterBottom: false,
  title: '',
  additionalInfo: [],
}

PortNominations.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  nominationSentence: PropTypes.string,
  gutterBottom: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  timeframeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timeframeValueTime: PropTypes.string,
  timeframeValueDeliveryWindow: PropTypes.string,
  additionalInfo: PropTypes.array,
}

export default PortNominations
