import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DetailRow from '../../orderView/components/DetailRow'
import { getNominationTimezone, getPartyLabel } from '../helpers.js'
import OffsetQuarterCard from './OffsetQuarterCard'
import NameForm from './NameForm'

const OtherNominations = ({
  nomination,
  entityCode,
  orderCargoTimeZoneMap,
  nominationRules,
  index,
}) => {
  const nominateUntil = getNominationTimezone(nomination, orderCargoTimeZoneMap)
  return (
    <>
      <DetailRow
        gutterBottom
        title={nomination.name}
        info={[
          `${getPartyLabel(nomination.party)}`,
          moment(nominateUntil).format('DD/MM/YYYY HH:mm'),
          entityCode,
        ]}
      />

      <OffsetQuarterCard>
        <NameForm
          index={index}
          nominationRules={nominationRules}
          nomination={nomination}
          nameLabel="Description"
        />
      </OffsetQuarterCard>
    </>
  )
}

OtherNominations.propTypes = {
  nomination: PropTypes.object.isRequired,
  orderCargoTimeZoneMap: PropTypes.object.isRequired,
  entityCode: PropTypes.string.isRequired,
  nominationRules: PropTypes.object,
  index: PropTypes.number,
}

export default OtherNominations
