const delivery = require('./delivery')
const stakeholder = require('./stakeholder')
const tradingType = require('./trading-type')
const quality = require('./quality')
const cargoSize = require('./cargo-size')
const price = require('./price')
const views = require('./views')
const { $T } = require('../terms')

const getOrderSummary = (order) => {
  const cargoSizeText = cargoSize.size.text(order)
  const cargoSizeVarianceText = cargoSize.variance.text(order)
  const deliveryText = delivery.text(order)
  const qualityText = quality.text(order)
  const priceSummary = price.basic.summary(order)
  const deliveryWindowCargosSummary = $T.deliveryWindow.cargos.summary(
    order.nominationRules
  )
  const {
    cargo: { max: deliveryCargoMax, min: deliveryCargoMin },
  } = deliveryText

  const [firstCargoSummary] = deliveryWindowCargosSummary
  const nCargoes =
    deliveryCargoMin !== deliveryCargoMax
      ? `${deliveryCargoMin} - ${deliveryCargoMax}`
      : deliveryCargoMax
  const deliveryCargo = `${nCargoes} Cargo${deliveryCargoMax > 1 ? 'es' : ''}`

  let deliveryWindowShortLabel = ''
  if (deliveryCargoMax > 1) {
    deliveryWindowShortLabel = deliveryCargo
  } else if (deliveryCargoMax === 1) {
    deliveryWindowShortLabel = firstCargoSummary
      ? firstCargoSummary.deliveryWindow
      : ''
  }

  return {
    id: order.shortId ? `#${order.shortId}` : '',
    code: stakeholder.code.text(order),
    tradingType: tradingType.text(order).toUpperCase(),
    deliveryType: deliveryText.type,
    deliveryLocation: deliveryText.location,
    deliveryPeriod: deliveryText.period,
    deliveryCargo,
    deliveryWindowShortLabel,
    quality: qualityText,
    cargoSize: cargoSizeText,
    cargoSizeVariance: cargoSizeVarianceText,
    price: priceSummary.short,
    views: views.text(order),
  }
}

module.exports = {
  summary: getOrderSummary,
}
