import { string, object } from 'yup'

const getFormSchema = ({ code }) =>
  object().shape({
    addressFirstLine: string().required('Required'),
    addressSecondLine: string(),
    city: string().required('Required'),
    code: string()
      .companyCodeIsUnique(code)
      .max(4, 'The company code should have 4 characters')
      .min(4, 'The company code should have 4 characters')
      .required('Required'),
    country: string().required('Required'),
    name: string().required('Required'),
    parentCompany: string().required('Required'),
    postcode: string().required('Required'),
    registrationNumber: string().required('Required'),
  })

const Exports = { getFormSchema }

export default Exports
