const DELIVERED_BY_KEY = {
  SPOT: 'SPOT',
  FIXED: 'FIXED',
  DAYS_AFTER: 'DAYS_AFTER',
}

const DELIVERED_BY_VALUES = {
  SPOT: 'Spot',
  FIXED: 'Fixed Date',
  DAYS_AFTER: 'X Days after the Point of Trade',
}

const DELIVERED_BY = [
  {
    value: DELIVERED_BY_KEY.SPOT,
    label: DELIVERED_BY_VALUES.SPOT,
  },
  {
    value: DELIVERED_BY_KEY.FIXED,
    label: DELIVERED_BY_VALUES.FIXED,
  },
  {
    value: DELIVERED_BY_KEY.DAYS_AFTER,
    label: DELIVERED_BY_VALUES.DAYS_AFTER,
  },
]

module.exports = {
  DELIVERED_BY,
  DELIVERED_BY_KEY,
  DELIVERED_BY_VALUES,
}
