const PRICE_TYPE = Object.freeze({
  FIXED: 'fixed',
  FLOATING: 'floating',
  HYBRID: 'hybrid',
  PREFERENCE: 'preference',
  RFQ: 'rfq',
  TBD: 'tbd',
})

const PRICE_TYPE_TEXT = Object.freeze({
  [PRICE_TYPE.FIXED]: 'Fixed',
  [PRICE_TYPE.FLOATING]: 'Floating',
  [PRICE_TYPE.HYBRID]: 'Hybrid',
  [PRICE_TYPE.PREFERENCE]: 'Preference',
  [PRICE_TYPE.RFQ]: 'RFQ',
  [PRICE_TYPE.TBD]: 'TBD',
  FIXED: 'Fixed',
  FLOATING: 'Floating',
  HYBRID: 'Hybrid',
  PREFERENCE: 'Preference',
  RFQ: 'RFQ',
  TBD: 'TBD',
})

const PRICE_DEVIATION = Object.freeze({
  PLUS: '+',
  MINUS: '-',
})

const PRICE_VARIANT = Object.freeze({
  BASIC: 'basic',
  CONTRACT: 'contract',
  RESERVE: 'reserve',
})

const PRICE_UNIT = Object.freeze({
  MMBTU: 'mmbtu',
  MMBTU_EUR: '€mmbtu',
  MWH: 'mwh',
  P_THERM: 'p/therm',
})

const PRICE_UNIT_LABEL = Object.freeze({
  [PRICE_UNIT.MMBTU]: '$/MMBtu',
  [PRICE_UNIT.MMBTU_EUR]: '€/MMBtu',
  [PRICE_UNIT.MWH]: '€/MWh',
  [PRICE_UNIT.P_THERM]: 'p/Therm',
})

const PRICE_UNIT_TEXT = Object.freeze({
  [PRICE_UNIT.MMBTU]: (x) => `$${x}/MMBtu`,
  [PRICE_UNIT.MMBTU_EUR]: (x) => `€ ${x}/MMBtu`,
  [PRICE_UNIT.MWH]: (x) => `€ ${x}/MWh`,
  [PRICE_UNIT.P_THERM]: (x) => `${x} p/Therm`,
})

const PRICE_PLUS_MINUS = Object.freeze({
  PLUS: '+',
  MINUS: '-',
})

const PRICE_INDEX_TEXT = Object.freeze({
  // group 1
  NBP: 'NBP ICE',
  TTF: 'TTF Heren',
  JKM: 'JKM',
  HH: 'HH',
  DKI: 'DKI',
  BRIPE_ONE_ONE: 'BRIPE(1, 0, 1)',
  JCC_TBA: 'JCC(TBA)',
  MCJK: 'mCJK',
  PEG_TBA: 'PEG(TBA)',
  // group 2
  JKM_TBA: 'JKM(TBA)',
  NBP_TBA: 'NBP(TBA)',
  TTF_TBA: 'TTF(TBA)',
  PVB_TBA: 'PVB(TBA)',
  PSV_TBA: 'PSV(TBA)',
  HH_TBA: 'HH(TBA)',
  HIGHER_ICE: 'Higher of ICE NBP/TTF',
  LOWER_ICE: 'Lower of ICE NBP/TTF',
  BRENT_TBA: 'Brent(TBA)',
  BRENT_DATED_ONE_ONE: 'Dated Brent(1, 0, 1)',
  BRENT_DATED_TBA: 'Dated Brent(TBA)',
  BRIPE_TBA: 'BRIPE(TBA)',
  // group 3
  BRENT_DATED_THREE_ONE: 'Dated Brent(3, 0, 1)',
  BRENT_DATED_SIX_ONE: 'Dated Brent(6, 0, 1)',
  BRENT_DATED_SIX_THREE: 'Dated Brent(6, 0, 3)',
  BRENT_DATED_THREE_THREE: 'Dated Brent(3, 0, 3)',
  BRIPE_THREE_ONE: 'BRIPE(3, 0, 1)',
  BRIPE_SIX_ONE: 'BRIPE(6, 0, 1)',
  BRIPE_SIX_THREE: 'BRIPE(6, 0, 3)',
  BRIPE_THREE_THREE: 'BRIPE(3, 0, 3)',
  NBP_HEREN: 'NBP Heren',
  TTF_ICE: 'TTF ICE',
  OTHER: 'Other',
  TFU: 'TTF USD',
  TFU_ICE: 'TFU ICE',
  // evo
  JKM_TTF: 'JKM/TTF',
  JKM_NBP: 'JKM/NBP',
  NBP_TTF: 'NBP/TTF',
})

const PRICE_INDEX = Object.freeze({
  // group 1
  NBP: 'nbp',
  TTF: 'ttf',
  JKM: 'jkm',
  HH: 'hh',
  DKI: 'dki',
  BRIPE_ONE_ONE: 'bripe(1, 0, 1)',
  JCC_TBA: 'jcc(tba)',
  MCJK: 'mcjk',
  PEG_TBA: 'peg(tba)',
  // group 2
  JKM_TBA: 'jkm(tba)',
  NBP_TBA: 'nbp(tba)',
  TTF_TBA: 'ttf(tba)',
  PVB_TBA: 'pvb(tba)',
  PSV_TBA: 'psv(tba)',
  HH_TBA: 'hh(tba)',
  HIGHER_ICE: 'higher of ice nbp/ttf',
  LOWER_ICE: 'lower of ice nbp/ttf',
  BRENT_TBA: 'brent(tba)',
  BRENT_DATED_ONE_ONE: 'dated brent(1, 0, 1)',
  BRENT_DATED_TBA: 'dated brent(tba)',
  BRIPE_TBA: 'bripe(tba)',
  // group 3
  BRENT_DATED_THREE_ONE: 'dated brent(3, 0, 1)',
  BRENT_DATED_SIX_ONE: 'dated brent(6, 0, 1)',
  BRENT_DATED_SIX_THREE: 'dated brent(6, 0, 3)',
  BRENT_DATED_THREE_THREE: 'dated brent(3, 0, 3)',
  BRIPE_THREE_ONE: 'bripe(3, 0, 1)',
  BRIPE_SIX_ONE: 'bripe(6, 0, 1)',
  BRIPE_SIX_THREE: 'bripe(6, 0, 3)',
  BRIPE_THREE_THREE: 'bripe(3, 0, 3)',
  NBP_HEREN: 'nbp heren',
  TTF_ICE: 'ttf ice',
  OTHER: 'other',
  // evo
  JKM_TTF: 'jkm/ttf',
  JKM_NBP: 'jkm/nbp',
  NBP_TTF: 'nbp/ttf',
})

const PRICE_INDEX_OPTIONS_ONE = [
  {
    name: PRICE_INDEX_TEXT.JKM,
    value: PRICE_INDEX.JKM,
    description: "Average Headline Platt's JKM from 16th m-2 to 15th m-1",
  },
  {
    name: PRICE_INDEX_TEXT.NBP,
    value: PRICE_INDEX.NBP,
    description:
      'Average settlement price of the NBP as a front month contract on ICE',
  },
  {
    name: PRICE_INDEX_TEXT.TTF_ICE,
    value: PRICE_INDEX.TTF_ICE,
    description:
      'Average settlement price of the TTF in EUR as a front month contract on ICE',
  },
  {
    name: PRICE_INDEX_TEXT.HH,
    value: PRICE_INDEX.HH,
    description:
      'Nymex Henry Hub - final settlement of futures contract as a front month',
  },
  {
    name: PRICE_INDEX_TEXT.BRIPE_ONE_ONE,
    value: PRICE_INDEX.BRIPE_ONE_ONE,
  },
  {
    name: PRICE_INDEX_TEXT.TTF,
    value: PRICE_INDEX.TTF,
    description:
      'Average settlement price of the TTF as a front month contract in ICIS HEREN',
  },
]

const PRICE_INDEX_OPTIONS_TWO = [
  {
    name: PRICE_INDEX_TEXT.JKM_TBA,
    value: PRICE_INDEX.JKM_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.NBP_TBA,
    value: PRICE_INDEX.NBP_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.TTF_TBA,
    value: PRICE_INDEX.TTF_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.PVB_TBA,
    value: PRICE_INDEX.PVB_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.PSV_TBA,
    value: PRICE_INDEX.PSV_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.HH_TBA,
    value: PRICE_INDEX.HH_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.BRENT_TBA,
    value: PRICE_INDEX.BRENT_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.JCC_TBA,
    value: PRICE_INDEX.JCC_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.PEG_TBA,
    value: PRICE_INDEX.PEG_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.HIGHER_ICE,
    value: PRICE_INDEX.HIGHER_ICE,
  },
  {
    name: PRICE_INDEX_TEXT.LOWER_ICE,
    value: PRICE_INDEX.LOWER_ICE,
  },
]

const PRICE_INDEX_OPTIONS_THREE = [
  {
    name: PRICE_INDEX_TEXT.BRENT_DATED_ONE_ONE,
    value: PRICE_INDEX.BRENT_DATED_ONE_ONE,
  },
  {
    name: PRICE_INDEX_TEXT.BRENT_DATED_TBA,
    value: PRICE_INDEX.BRENT_DATED_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.BRIPE_TBA,
    value: PRICE_INDEX.BRIPE_TBA,
  },
  {
    name: PRICE_INDEX_TEXT.BRENT_DATED_THREE_ONE,
    value: PRICE_INDEX.BRENT_DATED_THREE_ONE,
  },
  {
    name: PRICE_INDEX_TEXT.BRENT_DATED_SIX_ONE,
    value: PRICE_INDEX.BRENT_DATED_SIX_ONE,
  },
  {
    name: PRICE_INDEX_TEXT.BRENT_DATED_SIX_THREE,
    value: PRICE_INDEX.BRENT_DATED_SIX_THREE,
  },
  {
    name: PRICE_INDEX_TEXT.BRENT_DATED_THREE_THREE,
    value: PRICE_INDEX.BRENT_DATED_THREE_THREE,
  },
  {
    name: PRICE_INDEX_TEXT.BRIPE_THREE_ONE,
    value: PRICE_INDEX.BRIPE_THREE_ONE,
  },
  {
    name: PRICE_INDEX_TEXT.BRIPE_SIX_ONE,
    value: PRICE_INDEX.BRIPE_SIX_ONE,
  },
  {
    name: PRICE_INDEX_TEXT.BRIPE_SIX_THREE,
    value: PRICE_INDEX.BRIPE_SIX_THREE,
  },
  {
    name: PRICE_INDEX_TEXT.BRIPE_THREE_THREE,
    value: PRICE_INDEX.BRIPE_THREE_THREE,
  },
  {
    name: PRICE_INDEX_TEXT.NBP_HEREN,
    value: PRICE_INDEX.NBP_HEREN,
    description:
      'Average settlement price of the NBP as a front month contract in ICIS HEREN',
  },
  {
    name: PRICE_INDEX_TEXT.OTHER,
    value: PRICE_INDEX.OTHER,
  },
]

const PRICE_INDEX_OPTIONS_BY_GROUP = [
  PRICE_INDEX_OPTIONS_ONE,
  PRICE_INDEX_OPTIONS_TWO,
  PRICE_INDEX_OPTIONS_THREE,
]

module.exports = {
  PRICE_TYPE,
  PRICE_TYPE_TEXT,
  PRICE_DEVIATION,
  PRICE_VARIANT,
  PRICE_UNIT,
  PRICE_UNIT_LABEL,
  PRICE_UNIT_TEXT,
  PRICE_PLUS_MINUS,
  PRICE_INDEX_TEXT,
  PRICE_INDEX,
  PRICE_INDEX_OPTIONS_BY_GROUP,
}
