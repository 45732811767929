import { getOrderOverviewText } from './overview'
import { getOrderSummaryText } from './summary'
import { getOrderTermsText } from './terms'
import { NEW_LINE } from './constants'

export const getOrderText = (order) => {
  const text = [
    getOrderSummaryText(order),
    '',
    getOrderOverviewText(order),
    '',
    getOrderTermsText(order),
  ].join(NEW_LINE)

  return text
}
