const { REGION_OPTIONS_PREFIX } = require('./carbonPrefix')
const {
  REGIONS,
  REGIONS_KEYS,
  REGION_COUNTRY_KEYS,
  REGION_COUNTRY_OPTIONS,
  COUNTRIES_KEYS,
  COUNTRIES_VALUES,
  COUNTRIES,
  REGION_COUNTRIES,
  getCountryRegionFromKey
} = require('./countries/index')

const REGIONS_FULL_KEYS = {}
Object.keys(REGIONS_KEYS).forEach((key) => {
  REGIONS_FULL_KEYS[key] = `${REGION_OPTIONS_PREFIX}.${key}`
})

const REGIONS_OPTIONS = Object.keys(REGIONS_KEYS).map((region) => ({
  key: REGIONS_KEYS[region],
  label: REGIONS[region],
  value: REGIONS_FULL_KEYS[region],
}))

const REGION_TYPE_KEYS = {
  ALL: 'ALL',
  SPECIFIC: 'SPECIFIC',
}

const REGION_TYPE_OPTIONS = {
  [REGION_TYPE_KEYS.ALL]: 'All',
  [REGION_TYPE_KEYS.SPECIFIC]: 'Specific',
}

module.exports = {
  REGIONS_FULL_KEYS,
  REGIONS_OPTIONS,
  REGIONS_KEYS,
  COUNTRIES,
  REGION_COUNTRY_KEYS,
  REGION_COUNTRY_OPTIONS,
  COUNTRIES_KEYS,
  COUNTRIES_VALUES,
  REGION_TYPE_KEYS,
  REGION_TYPE_OPTIONS,
  REGION_COUNTRIES,
  getCountryRegionFromKey
}
