const activity = require('./activity')
const bulletinMessageType = require('./bulletin-message-type')
const cargoSize = require('./cargo-size')
const checklist = require('./checklist')
const comment = require('./comment')
const company = require('./company')
const delivery = require('./delivery')
const deliveryWindow = require('./delivery-window')
const entity = require('./entity')
const invitation = require('./invitation')
const nomination = require('./nomination')
const note = require('./note')
const orderStatus = require('./order-status')
const party = require('./party')
const person = require('./person')
const price = require('./price')
const quality = require('./quality')
const registration = require('./registration')
const stakeholder = require('./stakeholder')
const tradingType = require('./trading-type')
const validity = require('./validity')
const vessel = require('./vessel')
const history = require('./history')
const marketGrid = require('./market-grid')
const ctGrid = require('./ct-grid')
const appContext = require('./app-context')
const programEligibility = require('./carbon/programEligibility')
const carbonStandard = require('./carbon/carbonStandard')
const rating = require('./carbon/rating')
const classifications = require('./carbon/classifications')
const titleTransfer = require('./carbon/titleTransfer')
const termSpot = require('./carbon/termSpot')
const deliveredBy = require('./carbon/deliveredBy')
const period = require('./carbon/period')
const termFixedRolling = require('./carbon/termFixedRolling')
const projectInformation = require('./carbon/projectInformation')
const volumeType = require('./carbon/volumeType')
const volumeOffset = require('./carbon/volumeOffset')
const priceType = require('./carbon/priceType')
const projectSpecification = require('./carbon/projectSpecification')
const additionalAttributes = require('./carbon/additionalAttributes')
const venues = require('./carbon/venue')
const regions = require('./carbon/regions')
const carbonPrice = require('./carbon/price')
const carbonPrefix = require('./carbon/carbonPrefix')
const sessionExpired = require('./session-expired')

module.exports = {
  ...activity,
  ...bulletinMessageType,
  ...cargoSize,
  ...checklist,
  ...comment,
  ...company,
  ...ctGrid,
  ...delivery,
  ...deliveryWindow,
  ...entity,
  ...history,
  ...invitation,
  ...nomination,
  ...note,
  ...orderStatus,
  ...party,
  ...person,
  ...price,
  ...marketGrid,
  ...quality,
  ...registration,
  ...stakeholder,
  ...tradingType,
  ...validity,
  ...vessel,
  ...appContext,
  ...programEligibility,
  ...carbonStandard,
  ...rating,
  ...classifications,
  ...titleTransfer,
  ...carbonPrefix,
  ...termSpot,
  ...period,
  ...termFixedRolling,
  ...projectInformation,
  ...volumeType,
  ...volumeOffset,
  ...priceType,
  ...projectSpecification,
  ...additionalAttributes,
  ...venues,
  ...regions,
  ...carbonPrice,
  ...sessionExpired,
  ...deliveredBy,
}
