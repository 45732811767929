import { useMutation, useQueryClient } from 'react-query'
import { cloneDeep, uniq } from 'lodash'
import { useSnackbar } from 'notistack'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { ORDER_KEY } from 'api/constants'

export const useShareOrder = () => {
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const shareOrder = async ({ orderId, broker }) =>
    await api.shareOrderWithBroker(
      { brokerId: broker.brokerEntityId, orderId }
    )

  const addBrokerAccess = ({ orderId, broker }) => {
    const queryKey = [ORDER_KEY, orderId]
    queryClient.setQueryData(queryKey, (previous) => ({
      ...previous,
      brokerAccess: uniq([
        ...(previous?.brokerAccess || []),
        broker.brokerEntityId,
      ]),
    }))
  }

  const onMutate = ({ orderId, broker }) => {
    const query = [ORDER_KEY, orderId]
    queryClient.cancelQueries(query)
    const previousOrder = cloneDeep(queryClient.getQueryData(query))
    const rollback = () => queryClient.setQueryData(query, previousOrder)
    addBrokerAccess({ orderId, broker })
    return rollback
  }

  const onSuccess = () =>
    enqueueSnackbar('Order shared successfully', { variant: 'success' })

  const onError = (error, _, rollback) => {
    const message = getApiErrorMessage({
      error,
      defaultMessage: 'Error sharing order with broker',
    })
    enqueueSnackbar(message, { variant: 'error' })
    rollback()
  }

  return useMutation(shareOrder, {
    onMutate,
    onSuccess,
    onError,
    throwOnError: true,
  })
}
