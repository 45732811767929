import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
}))

export const StyledLink = ({ disabled, ...props }) => {
  const classes = useStyles()

  if (!disabled) {
    return <RouterLink className={classes.root} {...props} />
  }

  return props.children
}

StyledLink.defaultProps = {
  disabled: false,
}

StyledLink.propTypes = {
  disabled: PropTypes.bool,
}
