import React from 'react'
import PropTypes from 'prop-types'
import { SmallText, XsmallText } from 'components/Text'
import { $O } from 'model/order/selectors/selector'

export const DeliveryInfo = ({ order, ...props }) => {
  if (!$O.delivery.type.exists(order) || !$O.delivery.port.exists(order)) {
    return null
  }

  const type = $O.delivery.type.get(order)
  const port = $O.delivery.port.get(order)

  return (
    <SmallText {...props}>
      {type} {port}
    </SmallText>
  )
}

DeliveryInfo.propTypes = {
  order: PropTypes.object.isRequired,
}

DeliveryInfo.propTypes = {
  order: PropTypes.object.isRequired,
}

export const DeliveryInfoSmall = ({ order, ...props }) => {
  if (!$O.delivery.type.exists(order) || !$O.delivery.port.exists(order)) {
    return null
  }

  const type = $O.delivery.type.get(order)
  const port = $O.delivery.port.get(order)

  return (
    <XsmallText {...props}>
      {type} {port}
    </XsmallText>
  )
}

DeliveryInfoSmall.propTypes = {
  order: PropTypes.object.isRequired,
}
