const PRICE_TYPE_KEYS = {
  FIXED: 'FIXED',
  RANGE: 'RANGE',
  VOLUME_DEPENDENT: 'VOLUME_DEPENDENT',
  RFQ: 'RFQ',
  TBD: 'TBD',
}

const PRICE_TYPE_VALUES = {
  [PRICE_TYPE_KEYS.FIXED]: 'Fixed',
  [PRICE_TYPE_KEYS.RANGE]: 'Range',
  [PRICE_TYPE_KEYS.VOLUME_DEPENDENT]: 'Volume Dependant',
  [PRICE_TYPE_KEYS.RFQ]: 'RFQ',
  [PRICE_TYPE_KEYS.TBD]: 'TBD',
}

const PRICE_TYPE_OPTIONS = [
  {
    value: PRICE_TYPE_KEYS.FIXED,
    label: PRICE_TYPE_VALUES[PRICE_TYPE_KEYS.FIXED],
  },
  {
    value: PRICE_TYPE_KEYS.RANGE,
    label: PRICE_TYPE_VALUES[PRICE_TYPE_KEYS.RANGE],
  },
  {
    value: PRICE_TYPE_KEYS.VOLUME_DEPENDENT,
    label: PRICE_TYPE_VALUES[PRICE_TYPE_KEYS.VOLUME_DEPENDENT],
  },
  {
    value: PRICE_TYPE_KEYS.RFQ,
    label: PRICE_TYPE_VALUES[PRICE_TYPE_KEYS.RFQ],
  },
  {
    value: PRICE_TYPE_KEYS.TBD,
    label: PRICE_TYPE_VALUES[PRICE_TYPE_KEYS.TBD],
  },
]

const priceTypeIsFixed = (priceType) => priceType === PRICE_TYPE_KEYS.FIXED
const priceTypeIsRange = (priceType) => priceType === PRICE_TYPE_KEYS.RANGE
const priceTypeIsVolumeDependent = (priceType) =>
  priceType === PRICE_TYPE_KEYS.VOLUME_DEPENDENT
const priceTypeIsRFQ = (priceType) => priceType === PRICE_TYPE_KEYS.RFQ
const priceTypeIsTBD = (priceType) => priceType === PRICE_TYPE_KEYS.TBD

module.exports = {
  PRICE_TYPE_KEYS,
  PRICE_TYPE_VALUES,
  PRICE_TYPE_OPTIONS,
  priceTypeIsFixed,
  priceTypeIsRange,
  priceTypeIsVolumeDependent,
  priceTypeIsTBD,
  priceTypeIsRFQ,
}
