const {
  DELIVERY_FREQUENCY_DISTRIBUTION_TEXT,
  DELIVERY_FREQUENCY_TYPE_TEXT,
} = require('../../../constants')

const getFrequency = (order) => {
  const frequency = order.delivery.frequency
  const distribution = order.delivery.frequencyDistribution

  return {
    type: frequency,
    distribution,
  }
}

const getFrequencySummary = (order) => {
  const frequency = getFrequency(order)

  return {
    type: DELIVERY_FREQUENCY_TYPE_TEXT[frequency.type] || '',
    distribution:
      DELIVERY_FREQUENCY_DISTRIBUTION_TEXT[frequency.distribution] || '',
  }
}

// There's no text definition for the frequency. Feel free to change this if needed. Created for consistency sake.
const getFrequencyText = () => {
  return 'Method not implemented'
}

module.exports = {
  get: getFrequency,
  summary: getFrequencySummary,
  text: getFrequencyText,
}
