import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import {
  useOrderConfirmation,
  ORDER_CONFIRMATION,
} from 'containers/OrderProvider'
import { Information } from 'screens/orderView/components/Information'

export const steps = [{ title: 'Recap', component: () => null, number: 12 }]

const _Recap = ({ ...props }) => {
  const { setConfirmation } = useOrderConfirmation()

  useEffect(() => {
    setConfirmation(ORDER_CONFIRMATION.CONFIRMED)
  }, [setConfirmation])

  return <Information {...props} clickable />
}

_Recap.propTypes = {
  formik: PropTypes.object,
}

export const Recap = connect(_Recap)
