import React from 'react'
import PropTypes from 'prop-types'
import { $O } from 'emsurge-selectors'
import { Text } from './Text'

const OrderType = ({ order, ...props }) => (
  <Text variant="subtitle2" {...props}>
    {$O.tradingType.text(order).toUpperCase()}
  </Text>
)

OrderType.propTypes = {
  order: PropTypes.object.isRequired,
}

export default OrderType
