import React, { memo } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { useVersion } from '../../api/queries/version/useVersion'
import { PRIVACY_POLICY, TERMS_CONDITIONS, VERSION } from 'routes'
import Text from 'components/Text/Text'

export const FOOTER_HEIGHT = 32

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  overflow: auto;
`
const Footer = () => {
  const { isLoading, data: version } = useVersion()

  return (
    <Wrapper>
      <Grid container style={{ flexWrap: 'nowrap' }} justifyContent="center">
        <Text to={VERSION}>{isLoading ? 'version ...' : `v${version.versionNumber}`}</Text>
        <Text>EMSURGE LIMITED</Text>
        <Text> REGISTERED IN ENGLAND & WALES</Text>
        <Text>COMPANY NUMBER: 11236705</Text>
        <Text to={PRIVACY_POLICY}>PRIVACY POLICY</Text>
        <Text to={TERMS_CONDITIONS} last>
          TERMS & CONDITIONS
        </Text>
      </Grid>
    </Wrapper>
  )
}

export default memo(Footer)
