import {
  PRICE_DEVIATION,
  PRICE_UNIT_TEXT,
  PRICE_UNIT,
  TRADING_TYPE,
} from 'emsurge-selectors'
import { withDecimals } from 'emsurge-selectors/helpers'
import { find, isEmpty, isNumber } from 'lodash'
import { compose, split, head } from 'lodash/fp'
import {
  DEFAULT_OPTIONS,
  FORMAT_PRICE,
  OTHERS_INDEXES,
} from './useImpliedPrice.constants'

export const getMainIndexSlug = compose([head, split(/\(|\s+/g)])

export const normalize = (value) => withDecimals(value)?.substr(0, 5)

export const formatter = PRICE_UNIT_TEXT[PRICE_UNIT.MMBTU]
export const getPriceLabel = (value) => {
  if (value === 0) {
    return value
  }
  return `${value > 0 ? '+' : '-'} ${normalize(Math.abs(value))}`
}

export const getMidPoint = (
  { price: bidPrice, pricePercentage: bidPricePercentage, market: bidMarket },
  { price: askPrice, pricePercentage: askPricePercentage, market: askMarket },
  orderIndex = ''
) => {
  const bidTotal =
    !bidMarket || bidMarket === orderIndex ? bidPrice : bidPricePercentage
  const askTotal =
    !askMarket || askMarket === orderIndex ? askPrice : askPricePercentage

  return (bidTotal + askTotal) / 2
}

export const getMidPointFromPriceList = (list = [], orderIndex = '') => {
  const ask = find(list, (price) => price.tradingType === TRADING_TYPE.ASK)
  const bid = find(list, (price) => price.tradingType === TRADING_TYPE.BID)

  if (!bid || !ask || !isNumber(ask.price) || !isNumber(bid.price)) {
    return null
  }

  return getMidPoint(bid, ask, orderIndex)
}

export const getFloatingPrice = (price, referenceIndex) => {
  const floatingPart = (price.percentage / 100) * referenceIndex || 0
  const fixedPart =
    price.plusOrMinus === PRICE_DEVIATION.MINUS
      ? -parseFloat(price.amount) || 0
      : parseFloat(price.amount) || 0
  return {
    floating: floatingPart,
    fixed: fixedPart,
    full: Math.max(0, floatingPart + fixedPart),
  }
}

export const buildOptions = (options = {}) => ({
  ...DEFAULT_OPTIONS,
  [FORMAT_PRICE]:
    typeof options[FORMAT_PRICE] === 'boolean'
      ? options[FORMAT_PRICE]
      : DEFAULT_OPTIONS[FORMAT_PRICE],
  [OTHERS_INDEXES]:
    typeof options[OTHERS_INDEXES] === 'boolean'
      ? options[OTHERS_INDEXES]
      : DEFAULT_OPTIONS[FORMAT_PRICE],
})

export const formatLeadPrice = (price, options) => {
  if (!options[FORMAT_PRICE]) {
    return `${price ? normalize(price) : price}`
  }
  const leadPrice = `${
    price ? formatter(normalize(price)) : getPriceLabel(price)
  }`
  return leadPrice === '0'
    ? formatter(parseFloat(leadPrice).toFixed(2))
    : leadPrice
}

export const getIndexPercentage = (indexes = [], index = '') => {
  const indexPrice = indexes[index] || []
  const indexRecord =
    !isEmpty(indexPrice) && indexPrice.length > 0 ? indexPrice[0] : {}
  return indexRecord.percentage || 100
}

export const getImpliedForIndex = (
  index,
  deliveryPeriod,
  variance,
  indexes
) => {
  const indexPercentage = getIndexPercentage(indexes, index)
  const impliedData = indexPercentage > 100 ? [`${indexPercentage}%`] : []

  return impliedData
    .concat(index.toUpperCase(), `(${deliveryPeriod})`, getPriceLabel(variance))
    .join(' ')
}
