import React from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { Loading } from './components/Loading'
import { Bulletin } from './components/Bulletin'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'
import { ERROR } from 'routes'
import withParams from 'utils/withRouteParams'
import Fetch from 'containers/fetch/Fetch'
import Modal from 'components/modal/Modal'

const usePaperPropsStyles = (theme) => ({
  width: 'calc(60vw - 160px)',
  height: 'auto',
  padding: theme.spacing(3, 4),
})

const PositionedContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing(1)}px;
`

export const _BulletinView = ({ id, history }) => {
  const theme = useTheme()
  const styles = usePaperPropsStyles(theme)

  return (
    <Modal paperProps={{ styles }} open={true}>
      <Fetch url={`/bulletins/${id}`}>
        {({ loading, error, data: bulletin }) => {
          if (error) {
            return <Redirect to={ERROR} />
          }

          return loading ? (
            <Loading />
          ) : (
            <>
              <PositionedContainer>
                <CloseButton onClick={history.goBack} />
              </PositionedContainer>
              <Bulletin bulletin={bulletin} />
            </>
          )
        }}
      </Fetch>
    </Modal>
  )
}

_BulletinView.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object,
}

export const BulletinView = withParams(_BulletinView)
