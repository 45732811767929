const { REGISTRATION_STATUS } = require('./registration')

const PERSON_CLIENT_TYPE = Object.freeze({
  BROKER: 'broker',
  BROKER_CLIENT: 'broker_client',
  TRADER: 'trader',
  SYSTEM: 'system',
})

const PERSON_CLIENT_TYPE_TEXT = Object.freeze({
  [PERSON_CLIENT_TYPE.BROKER]: 'Broker',
  [PERSON_CLIENT_TYPE.TRADER]: 'Trader',
  [PERSON_CLIENT_TYPE.SYSTEM]: 'System',
  BROKER: 'Broker',
  TRADER: 'Trader',
  SYSTEM: 'System',
})

const PERSON_PERMISSION = Object.freeze({
  NONE: 'none',
  READ: 'read',
  WRITE: 'write',
  TRADE: 'trade',
})

const PERSON_PERMISSION_WEIGHT = Object.freeze({
  NONE: 1,
  READ: 10,
  WRITE: 20,
  TRADE: 30,
  [PERSON_PERMISSION.NONE]: 1,
  [PERSON_PERMISSION.READ]: 10,
  [PERSON_PERMISSION.WRITE]: 20,
  [PERSON_PERMISSION.TRADE]: 30,
})

const PERSON_PERMISSION_TEXT = Object.freeze({
  [PERSON_PERMISSION.NONE]: 'None',
  [PERSON_PERMISSION.READ]: 'Read',
  [PERSON_PERMISSION.WRITE]: 'Write',
  [PERSON_PERMISSION.TRADE]: 'Trade',
})

const PERSON_ROLE = Object.freeze({
  COMPANY: 'company',
  NONE: 'none',
  SYSTEM: 'system',
})
const PERSON_STATUS = Object.freeze({
  ...REGISTRATION_STATUS,
  PENDING_PASSWORD_CREATION: 'pending_password_creation',
})

module.exports = {
  PERSON_CLIENT_TYPE,
  PERSON_CLIENT_TYPE_TEXT,
  PERSON_PERMISSION,
  PERSON_PERMISSION_TEXT,
  PERSON_PERMISSION_WEIGHT,
  PERSON_ROLE,
  PERSON_STATUS,
}
