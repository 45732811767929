import { slice } from 'lodash'
import { PERIOD, PERIOD_LABELS } from 'model/order/constants/delivery'

export const PERIOD_OPTIONS = [
  {
    value: PERIOD.CAL_YEAR,
    label: PERIOD_LABELS[PERIOD.CAL_YEAR],
    xs: 6,
  },
  {
    value: PERIOD.GAS_YEAR,
    label: PERIOD_LABELS[PERIOD.GAS_YEAR],
    xs: 6,
  },
  {
    value: PERIOD.SUMMER,
    label: 'Summer',
    xs: 6,
  },
  {
    value: PERIOD.WINTER,
    label: 'Winter',
    xs: 6,
  },
  ...[
    PERIOD.Q1,
    PERIOD.Q2,
    PERIOD.Q3,
    PERIOD.Q4,
    PERIOD.JAN,
    PERIOD.FEB,
    PERIOD.MAR,
    PERIOD.APR,
    PERIOD.MAY,
    PERIOD.JUN,
    PERIOD.JUL,
    PERIOD.AUG,
    PERIOD.SEP,
    PERIOD.OCT,
    PERIOD.NOV,
    PERIOD.DEC,
  ].map((value) => ({
    value,
    label: slice(PERIOD_LABELS[value], 0, 3),
    xs: 3,
  })),
  {
    value: PERIOD.CUSTOM,
    label: PERIOD_LABELS[PERIOD.CUSTOM],
    xs: 12,
  },
]
