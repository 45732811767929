import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PanelWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  flex: 1;
  overflow: auto;
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(5)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  @media (min-width: 600px) {
    padding-left: ${({ theme }) => theme.spacing(5)}px;
    padding-right: ${({ theme }) => theme.spacing(5)}px;
  }
`

export const Panel = ({ component: Component, ...props }) => (
  <PanelWrapper>
    <Component {...props} />
  </PanelWrapper>
)

Panel.propTypes = {
  component: PropTypes.func.isRequired,
}
