import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import ModalHeader from './components/ModalHeader'
import InformationTab from './components/InformationTab'
import InformationTabCarbon from './components/InformationTabCarbon'
import TradedNominations from './containers/TradedNominations'
import TradeDetail from './components/TradeDetail'
import ModalHeaderCarbon from './components/ModalHeaderCarbon'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import withParams from 'utils/withRouteParams'
import { Panel } from 'screens/orderView/components/Panel'
import Modal from 'screens/termsheetView/containers//Modal'
import { useTermsheet } from 'api'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'

export const TERMSHEET_TAB = {
  INFORMATION: 0,
  TRADED_NOMINATIONS: 1,
  TRADE_DETAILS: 2,
}

export const TERMSHEET_TAB_CARBON = {
  INFORMATION: 0,
  TRADE_DETAILS: 1,
}

const ViewTermsheet = ({ id, history, context }) => {
  const location = useLocation()
  const { tab } = queryString.parse(location.search)
  const [tabIndex, setTab] = useState(
    tab ? parseInt(tab) : TERMSHEET_TAB.INFORMATION
  )
  const { isLoading, data: termsheet } = useTermsheet(id, context)
  const BASE_SLUG = useRouteBaseSlug()

  const onClose = () => {
    if (location?.state?.referrer) {
      history.push(location.state.referrer)
    } else {
      history.push(`/${BASE_SLUG}`)
    }
  }

  if (!isLoading && !termsheet) {
    return <Redirect to={`/${BASE_SLUG}`} />
  }

  let panel
  if(termsheet?.context === APP_CONTEXT_TYPE.LNG) {
    switch (tabIndex) {
      case TERMSHEET_TAB.INFORMATION:
        panel = <Panel component={InformationTab} termsheet={termsheet} isTermSheetSummary={true}/>
        break
      case TERMSHEET_TAB.TRADED_NOMINATIONS:
        panel = <Panel component={TradedNominations} order={termsheet} />
        break
      case TERMSHEET_TAB.TRADE_DETAILS:
        panel = <Panel component={TradeDetail} termsheet={termsheet} />
        break
      default:
        throw new Error(`Invalid tab index state of ${tabIndex}`)
    }
  }
  else if (termsheet?.context === APP_CONTEXT_TYPE.CARBON) {
    switch (tabIndex) {
      case TERMSHEET_TAB_CARBON.INFORMATION:
        panel = <Panel component={InformationTabCarbon} termsheet={termsheet} isTermSheetSummary={true}/>
        break
      case TERMSHEET_TAB_CARBON.TRADE_DETAILS:
        panel = <Panel component={TradeDetail} termsheet={termsheet} />
        break
      default:
        throw new Error(`Invalid tab index state of ${tabIndex}`)
    }
  }

  if (isLoading) {
    return (
      <Modal onClose={onClose}>
        <LoadingSpinner />
      </Modal>
    )
  }

  return (
    <>
      {termsheet.context === APP_CONTEXT_TYPE.LNG && (    
        <Modal onClose={onClose}>
          <ModalHeader
            termsheet={termsheet}
            activeTab={tabIndex}
            onTabsChange={(_, tabIndex) => setTab(tabIndex)}
            onClose={onClose}
          />
          {panel}
        </Modal>
      )}
      {termsheet.context === APP_CONTEXT_TYPE.CARBON && (    
        <Modal onClose={onClose}>
          <ModalHeaderCarbon
            termsheet={termsheet}
            activeTab={tabIndex}
            onTabsChange={(_, tabIndex) => setTab(tabIndex)}
            onClose={onClose}
          />
          {panel}
        </Modal>
      )}
    </>
  )
}

ViewTermsheet.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
}

export default withParams(ViewTermsheet)
