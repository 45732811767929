import React from 'react'
import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { PeriodOptions } from './PeriodOptions'
import { MarketOptions } from './MarketOptions'
import { MarketSelector } from './MarketSelector'
import TextField from 'containers/formikTextField/FormikTextField'

const Text = styled(Typography)`
  ${spacing}
`

export const EditMarketGrid = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Text my={3} variant="h6">
            Information
          </Text>
          <TextField name="name" label="Name" fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Text my={3} variant="h6">
            Periods
          </Text>
          <PeriodOptions />
        </Grid>

        <Grid item xs={12} md>
          <Text my={3} variant="h6">
            Markets
          </Text>
          <MarketOptions />
          <MarketSelector />
        </Grid>
      </Grid>
    </>
  )
}
