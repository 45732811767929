import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import Text from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { Comment } from './Comment'
import { CommentCreate } from './CommentCreate'
import { mapToCommentView } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(4),
  },
  commentsList: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.secondary,
    padding: '16px',
    borderRadius: '5px',
  },
}))

export const Comments = ({ comments, label, headerColor, addComment }) => {
  const classes = useStyles()

  return (
    <section className={classes.container}>
      <Text variant="h1" style={{ color: headerColor }}>
        {label} ({comments.length})
      </Text>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CommentCreate addComment={addComment} />
        </Grid>
        <Grid item xs={6}>
          <section className={classes.commentsList}>
            {isEmpty(comments) ? (
              <Text data-testid="no-comments-text" variant="body2">
                There are no {label.toLowerCase()}
              </Text>
            ) : (
              comments
                .sort(
                  (c1, c2) => new Date(c2.createdAt) - new Date(c1.createdAt)
                )
                .map(mapToCommentView)
                .map(({ author, text, createdAt }, idx) => (
                  <Comment
                    key={idx}
                    author={author}
                    message={text}
                    createdAt={createdAt}
                  />
                ))
            )}
          </section>
        </Grid>
      </Grid>
    </section>
  )
}

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  addComment: PropTypes.func.isRequired,
  headerColor: PropTypes.string,
}

Comments.defaultProps = {
  comments: [],
  headerColor: '#fff',
}
