import { $O } from 'emsurge-selectors/models'
import { NEW_LINE } from './constants'

export const getOrderSummaryText = (order) => {
  const orderSummary = $O.summary(order)
  const firstLine = `${orderSummary.code} ${orderSummary.tradingType} ${orderSummary.deliveryType} ${orderSummary.deliveryLocation} ${orderSummary.deliveryPeriod} ${orderSummary.deliveryWindowShortLabel}`
  const secondLine = `${orderSummary.price}`
  const thirdLine = `${orderSummary.deliveryCargo} ${orderSummary.cargoSize} ${orderSummary.quality}`

  return [
    firstLine.trim(),
    secondLine.trim(),
    thirdLine.trim(),
    `${order.orders ? 'Term sheet' : 'Order'}: ${orderSummary.id}`,
  ].join(NEW_LINE)
}
