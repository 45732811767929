import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { withTheme } from '@material-ui/styles'

const BreadCrumb = ({ theme, company }) => (
  <section style={{ marginBottom: theme.spacing(4) }}>
    <Text variant="h4">Companies • {company.toUpperCase()}</Text>
  </section>
)

BreadCrumb.propTypes = {
  theme: PropTypes.object.isRequired,
  company: PropTypes.string.isRequired,
}

export default withTheme(BreadCrumb)
