import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { groupBy } from 'lodash'
import moment from 'moment'
import Text from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import NominationsForm from './containers/NominationsForm'
import SortMenu from './components/SortMenu'
import { getTableData } from './helpers'
import Fetch from 'containers/fetchPlus/FetchPlus'
import Table from 'components/Table/Table'
import ViewTermsheet from 'screens/termsheetView/ViewTermsheet'
import { useRouter } from 'utils/useRouter'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { NOMINATIONS } from 'routes'
import ViewOrder from 'screens/orderView/ViewOrder'

const SORT_CRITERIA = {
  ASC: 0,
  DESC: 1,
}

const META_DATA_COLUMNS = [
  {
    title: 'Term Sheet Id',
    field: 'termsheetShortId',
    cellStyle: { textTransform: 'uppercase' },
  },
  {
    title: 'Trade Date',
    field: 'tradeDate',
  },
  {
    title: 'Overview',
    field: 'overview',
  },
  {
    title: 'Buyer',
    field: 'buyer',
  },
  {
    title: 'Seller',
    field: 'seller',
  },
  {
    title: 'Cargo',
    field: 'cargo',
  },
  {
    title: 'Party',
    field: 'party',
  },
  { title: '', field: 'nominationType' },
  { title: '', field: 'name' },
  { title: '', field: 'vesselType' },
  { title: '', field: 'form' },
]

const isToday = (date) => moment(date).isSame(new Date(), 'day')

const groupRowsByDate = (data) =>
  groupBy(data, ({ nominateBy }) =>
    isToday(nominateBy)
      ? 'Today'
      : moment(nominateBy).format('Do MMM YYYY HH:mm')
  )

const ViewNominations = () => {
  const { match, history } = useRouter()
  const { palette } = useTheme()
  const BASE_SLUG = useRouteBaseSlug()
  const columns = META_DATA_COLUMNS.map((column) => {
    const cellStyle = {
      backgroundColor: palette.background.paper,
      ...column.cellStyle,
    }
    const defaultFilter = ''
    const isFormColumn = column.field === 'form'
    if (isFormColumn) {
      return {
        defaultFilter,
        ...column,
        render: (rowData) => (
          <NominationsForm rowData={rowData} />
        ) /* eslint-disable-line react/display-name */,
        cellStyle,
      }
    }

    return { defaultFilter, ...column, cellStyle }
  })

  const [sortCriteria, setSortCriteria] = useState(SORT_CRITERIA.ASC)
  const sortNominations = (a, b) => {
    if (sortCriteria === SORT_CRITERIA.ASC) {
      return new Date(a.nominateBy).getTime() - new Date(b.nominateBy).getTime()
    }
    return new Date(b.nominateBy).getTime() - new Date(a.nominateBy).getTime()
  }

  const getViewTermsheetUrl = (rowData) =>
    `/${BASE_SLUG}/termsheets/${rowData.termsheetId}`

  return (
    <>
      <Grid container justifyContent="space-between">
        <Text variant="h1" gutterBottom>
          Outstanding Nominations
        </Text>
        <SortMenu
          setAscOrder={() => setSortCriteria(SORT_CRITERIA.ASC)}
          setDesOrder={() => setSortCriteria(SORT_CRITERIA.DES)}
        />
      </Grid>

      <Fetch url="/nominations?toBeNominated=true">
        {(nominations) => {
          const tableData = getTableData(nominations)
          const sortedData = tableData.sort(sortNominations)
          return (
            <Table
              sorting={false}
              filtering={false}
              columns={columns}
              data={sortedData}
              noDataMessage="There are no outstanding nominations"
              groupFunction={groupRowsByDate}
              getLink={getViewTermsheetUrl}
            />
          )
        }}
      </Fetch>
      <Switch>
        <Route
          path={`${match.path}/termsheets/:id`}
          component={ViewTermsheet}
        />
        <Route
          path={`${match.path}/orders/:id`}
          component={() => (
            <ViewOrder onClose={() => history.push(NOMINATIONS)} />
          )}
        />
      </Switch>
    </>
  )
}

export default ViewNominations
