import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'formik'
import { isNil, isEmpty } from 'lodash'
import { Redirect } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import InformationForm from './InformationFormStepper'
import NominationsForm from './NominationsFormStepper'
import { Recap } from './Recap'
import {
  useActiveStep,
  TAB,
  useOrderFlow,
  ORDER_FLOW,
} from 'containers/OrderProvider'
import { mapFormikValuesToOrder } from 'screens/orderCreateCommon/helpers'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import Tooltip from 'components/tooltip/Tooltip'
import Fetch from 'containers/fetch/Fetch'
import { ERROR } from 'routes'
import { useUser } from 'containers/user/useUser'
import TabChevron from 'components/TabChevron/TabChevron'
import { Laptop } from 'components/Viewport'
import PublicNotes from 'screens/orderCreate/components/PublicNotes'
import NewOrderCommentsPanel from 'screens/orderCreate/components/CommentsPanel'
import EditOrderCommentsPanel from 'screens/orderView/components/CommentsPanel'
import { VerticalDividerWrapper } from 'screens/orderCreateCommon/components/VerticalDivider'
import {
  getInformationErrors,
  getNominationErrors,
} from 'screens/orderCreate/getErrors'
import { SideMenu } from 'screens/orderCreate/components/SideMenu'
import { DeliveryCargosUpdater } from 'screens/orderCreate/components/DeliveryCargosUpdater'

const TabsWrapper = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.background.secondary};
`

const PanelWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: calc(100% - 206px);
`

const useTermsTabStyles = makeStyles({
  root: {
    minWidth: '186px',
  },
})

const usePanelStyles = makeStyles(({ spacing }) => ({
  component: {
    overflow: 'auto',
    flex: 'auto',
    padding: spacing(4, 4, 15, 0),
  },
  sideMenu: {
    paddingTop: spacing(4),
  },
}))

const Panel = ({ component: Component, hasSideMenu, ...props }) => {
  const classes = usePanelStyles()
  return (
    <PanelWrapper>
      {hasSideMenu && (
        <Laptop matchLarger>
          <div className={classes.sideMenu}>
            <SideMenu {...props} />
          </div>
        </Laptop>
      )}
      <div className={classes.component}>
        <Component {...props} />
      </div>
    </PanelWrapper>
  )
}

Panel.propTypes = {
  component: PropTypes.func.isRequired,
  hasSideMenu: PropTypes.bool,
}

const TabLabel = ({ error, children }) => {
  const { palette } = useTheme()

  if (!error) {
    return children
  }

  return (
    <Tooltip title="Please correct all the errors">
      <span style={{ color: palette.error.main }}>{children}</span>
    </Tooltip>
  )
}

TabLabel.defaultProps = {
  error: false,
}

TabLabel.propTypes = {
  children: PropTypes.string.isRequired,
  error: PropTypes.bool,
}

const Content = ({ formik, isCreatingTrade }) => {
  const { user } = useUser()
  const termsClasses = useTermsTabStyles()
  const infoHasErrors = !isEmpty(getInformationErrors(formik))
  const nominationHasErrors = !isEmpty(getNominationErrors(formik))
  const {
    activeStep,
    navigate: { goTo },
  } = useActiveStep()
  const { flow } = useOrderFlow()
  const isCreating = flow === ORDER_FLOW.CREATE

  return (
    <>
      <TabsWrapper>
        <Tabs
          indicatorColor="primary"
          value={activeStep.tab}
          onChange={(_, tab) => goTo({ tab, step: 0 })}
        >
          <Tab
            data-testid="order-creation-overview-tab"
            label={<TabLabel error={infoHasErrors}>Overview</TabLabel>}
            value={TAB.OVERVIEW}
          />
          <TabChevron />
          <Tab
            data-testid="order-creation-terms-tab"
            classes={termsClasses}
            label={<TabLabel error={nominationHasErrors}>Terms</TabLabel>}
            value={TAB.TERMS}
          />
          <TabChevron />
          <Tab
            data-testid="order-creation-recap-tab"
            classes={termsClasses}
            label={<TabLabel>Recap</TabLabel>}
            value={TAB.RECAP}
          />
          {!formik.values.template && <VerticalDividerWrapper />}
          {!formik.values.template && (
            <Tab
              data-testid="order-creation-comments-tab"
              label={<TabLabel>Comments</TabLabel>}
              value={TAB.COMMENTS}
            />
          )}
        </Tabs>
      </TabsWrapper>

      <DeliveryCargosUpdater>
        <Fetch url={`/companies/${user.companyId}`}>
          {({ loading, error, data: company }) => {
            if (loading && isNil(company)) {
              return <Panel component={LoadingSpinner} />
            }

            if (error) {
              return <Redirect to={ERROR} />
            }

            const informationProps = {
              company,
              component: InformationForm,
              isCreatingTrade
            }
            const nominationProps = {
              component: NominationsForm,
            }
            const recapProps = {
              component: Recap,
              order: mapFormikValuesToOrder(formik.values),
              isCreatingTrade
            }
            const commentProps = {
              component: isCreating
                ? NewOrderCommentsPanel
                : EditOrderCommentsPanel,
              order: informationProps,
            }

            switch (activeStep.tab) {
              case TAB.OVERVIEW:
                return <Panel {...informationProps} hasSideMenu />
              case TAB.TERMS:
                return <Panel {...nominationProps} hasSideMenu />
              case TAB.RECAP:
                return <Panel {...recapProps} hasSideMenu />
              case TAB.COMMENTS:
                return <Panel {...commentProps} label="Comments" />
              default:
                throw new Error(
                  `Unexpected tab index value of ${activeStep.tab}`
                )
            }
          }}
        </Fetch>
      </DeliveryCargosUpdater>
      <PublicNotes />
    </>
  )
}

Content.propTypes = {
  formik: PropTypes.object.isRequired,
}

export default connect(Content)
