import React from 'react'

import { CARGO_SIZE_TYPE_OPTIONS } from 'emsurge-selectors/constants'
import {
  Button,
  StyledContainer,
  StyledConnectedSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { SelectableGridOption as Option } from 'components/SelectableGrid'
import { SelectCargoSizeType } from 'screens/orderCreate/components/SelectCargoSizeType'

export const Type = () => (
  <StyledContainer>
    <SelectCargoSizeType>
      {({ unit, onChange }) => (
        <StyledConnectedSelectableGrid
          name="cargoSize.type"
          onChange={(value) => onChange(value)}
        >
          {CARGO_SIZE_TYPE_OPTIONS[unit].map(({ value, label }) => (
            <Option
              data-testid={`cargoSize.type-${value}`}
              key={`order-cargo-size-type-${value}`}
              xs={6}
              value={value}
            >
              <Button>{label}</Button>
            </Option>
          ))}
        </StyledConnectedSelectableGrid>
      )}
    </SelectCargoSizeType>
  </StyledContainer>
)
