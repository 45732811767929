import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { useTheme } from '@material-ui/styles'
import { Grid, Tab, Tabs } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { PERSON_PERMISSION, ORDER_STATUS } from 'emsurge-selectors'
import Text from '@material-ui/core/Typography'

import MoreMenu from '../containers/HeaderMoreMenu'
import { BackToOrderButton } from './BackToOrderButton'
import { CopyOrderButton } from 'screens/orderView/components/CopyOrderButton'
import { EditOrderButton } from 'screens/orderView/components/EditOrderButton'
import { TabsWrapper } from 'screens/orderView/components/ModalHeader'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'
import { useUserHasPermission } from 'permissions'
import { OrderSummary } from 'components/OrderSummary'
import If from 'containers/controlFlow/If'
import { useUser } from 'containers/user/useUser'
import {
  Wrapper,
  GridContainer,
  GridArea,
  HEADER_AREAS,
} from 'components/ModalHeader/layout'

const Header = ({
  activeTab,
  onTabsChange,
  termsheet,
  onClose,
  enqueueSnackbar,
  location,
}) => {
  const { user } = useUser()
  const { spacing } = useTheme()
  const hasComeFromOrder =
    !!location.state &&
    location.state.referrer &&
    location.state.referrer.includes('orders')
  const userCanEditTermsheet = useUserHasPermission({
    order: termsheet,
    minimumPermission: PERSON_PERMISSION.WRITE,
  })
  const isTraded = termsheet.status === ORDER_STATUS.TRADED
  const canCopyToClipboard = user.entities.some(
    (entity) => entity.permission === PERSON_PERMISSION.TRADE
  )
  const canEditTermsheet = userCanEditTermsheet
  const termsheetWithoutTradingType = {
    ...termsheet,
    tradingType: undefined,
  }

  const textStyle = {
    whiteSpace: 'nowrap',
    color: '#d8cfb3',
    fontWeight: 700,
    fontSize: 21,
    lineHeight: 25 / 21,
    textTransform: 'uppercase',
    marginTop: '-100px'
  }
  return (
    <>
      {/* UPPER SECTION */}
      <Wrapper>
        <Text variant="button" style={{ ...textStyle }}>
            Trade
        </Text>
        <GridContainer>
          <OrderSummary order={termsheetWithoutTradingType} isTermSheetHeader={true}/>
          <GridArea name={HEADER_AREAS.OTHER_ACTIONS}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <CloseButton onClick={onClose} />
            </Grid>
          </GridArea>

          <GridArea
            name={HEADER_AREAS.MAIN_ACTIONS}
            style={{ marginTop: '8px' }}
          >
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <If condition={hasComeFromOrder}>
                  <BackToOrderButton />
                </If>
              </Grid>
              <Grid item>
                <If condition={canEditTermsheet}>
                  <EditOrderButton
                    data-testid="edit-termsheet-button"
                    order={termsheetWithoutTradingType}
                    style={{ marginLeft: spacing(1) }}
                  />
                </If>
                <If condition={canCopyToClipboard}>
                  <CopyOrderButton
                    order={termsheetWithoutTradingType}
                    style={{ marginLeft: spacing(1) }}
                    enqueueSnackbar={enqueueSnackbar}
                  />
                </If>
                <MoreMenu
                  termsheet={termsheetWithoutTradingType}
                  style={{ marginLeft: spacing(1) }}
                  onClose={onClose}
                  id="termsheet-view"
                />
              </Grid>
            </Grid>
          </GridArea>
        </GridContainer>
      </Wrapper>

      {/* TABS */}
      <TabsWrapper>
        <Tabs
          indicatorColor="primary"
          value={activeTab}
          onChange={onTabsChange}
        >
          <Tab data-testid="information-tab" label="Information" />
          {isTraded && (
            <Tab data-testid="nominations-tab" label="Nominations" />
          )}
          {isTraded && (
            <Tab data-testid="trade-details-tab" label="Trade Details" />
          )}
        </Tabs>
      </TabsWrapper>
    </>
  )
}

Header.propTypes = {
  activeTab: PropTypes.number.isRequired,
  onTabsChange: PropTypes.func.isRequired,
  termsheet: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

const enhance = compose(withSnackbar, withRouter)
export default enhance(Header)
