import { useQuery } from 'react-query'
import { useApi } from 'containers/api/useApi'

export const useTraderPermissions = () => {
  const api = useApi()

  return useQuery('trader-permissions', () => api.getTraderPermissions(), {
    cacheTime: 0,
  })
}
