import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'formik'
import queryString from 'query-string'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'

import { PARTY_TEXT } from 'emsurge-selectors'
import { TextField } from 'screens/orderCreateCommon/components/TextField'
import withParams from 'utils/withRouteParams'

export const getCargoSizeVarianceLabel = (cargoSizeParty, plusMinus = '') => {
  const cargoSizeVarianceLabel = `${PARTY_TEXT[cargoSizeParty]}'s Operational Tolerance ${plusMinus}`

  return cargoSizeVarianceLabel.trim()
}

const _VarianceFields = ({ location, formik, labels: { minus, plus } }) => {
  const { focus } = queryString.parse(location.search)
  const { party } = formik.values.cargoSize
  return (
    <>
      <Grid item xs>
        <TextField
          fullWidth
          type="number"
          name="cargoSize.varianceMinus"
          label={minus || getCargoSizeVarianceLabel(party, '-')}
          inputProps={{ 'data-testid': 'cargo-size-variance-minus' }}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          fullWidth
          autoFocus={focus === 'variance'}
          type="number"
          name="cargoSize.variancePlus"
          label={plus || getCargoSizeVarianceLabel(party, '+')}
          inputProps={{ 'data-testid': 'cargo-size-variance-plus' }}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Grid>
    </>
  )
}

_VarianceFields.defaultProps = {
  labels: {},
}

_VarianceFields.propTypes = {
  formik: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  labels: PropTypes.shape({
    minus: PropTypes.string,
    plus: PropTypes.string,
  }),
}

const enhance = compose(withParams, connect)

export const VarianceFields = enhance(_VarianceFields)
