import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Collapse, useTheme } from '@material-ui/core'
import {
  $OC,
  CLASSIFICATIONS_FIRST,
  CLASSIFICATIONS_SECOND,
} from 'emsurge-selectors'
import SummaryRow from './SummaryRow'
import { isTermsheet } from 'model/termsheet/isTermsheet'
import { GridArea, HEADER_AREAS } from 'components/ModalHeader/layout'
import CompanyCode from 'components/CompanyCode/CompanyCode'
import { useIsMobile } from 'components/Viewport/Mobile'
import CompanyCodeTermsheet from 'components/CompanyCode/CompanyCodeTermsheet'

const truncatedTextObject = (array, mapFunc, maxLength, separator = ', ', truncatedSuffix = '...') => {
  const text = (array || []).map(mapFunc).join(separator)
  const truncatedText =
      text.length > maxLength
          ? (text.substring(0, maxLength) + truncatedSuffix)
          : text.substring(0, maxLength)
  const result = {
      truncatedText: truncatedText
  }
  if (text.length > maxLength) {
      result['completeText'] = text
  }

  return result
}

export const OrderSummary = ({ order, expanded, isCreatingTrade, isTermSheetSummary }) => {
  const [data, setData] = useState({})
  const { spacing } = useTheme()
  const gutterX = spacing(1)
  const isMobile = useIsMobile()
  const styles = {
    text1: {
      fontWeight: 700,
      fontSize: isMobile ? 19 : 21,
      lineHeight: 25 / 21,
      textTransform: 'uppercase',
    },
  }

  useEffect(() => {
    const price = $OC.carbonPrice.text(order)
    const { tradingType, id } = $OC.summary(order)
    let { carbonStandard = [], classifications = [] } =
      $OC.carbonType.get(order)
    if (Object.keys(carbonStandard).length === 0) carbonStandard = []
    const spotTerm = $OC.termVintage.spotTerm.text(order, true)
    const date = spotTerm.vintage || ''
    const { text: volumeText } = $OC.volume.text(order)
    const classificationsFirstRow =
      CLASSIFICATIONS_FIRST.find(
        (attr) => attr.value === classifications?.first
      ) || {}
    const classificationsSecondRow =
      CLASSIFICATIONS_SECOND.find(
        (attr) => attr.value === classifications?.second
      ) || {}
    const { projects } = $OC.projectSpecification.get(order)
    const projectIds = truncatedTextObject(projects, x => `${x.referenceNr}`, 30)?.truncatedText
    const additionalAttributes = $OC.additionalAttributes.text(order)?.additionalAttributes
    const carbonStandardFiltered = carbonStandard?.filter((cs) => cs !== 'otherContent')
    const classificationsFirst = classificationsFirstRow.short
    const classificationsSecond = classificationsSecondRow.label
    const orderSummaryPrice = price.value ? price.value+'/tCO2e' : ''
    const orderSummaryFirstLine = [tradingType, 
      projectIds, 
      additionalAttributes, 
      carbonStandardFiltered.join(', '), 
      date, 
      classificationsFirst, 
      orderSummaryPrice, 
      volumeText, 
      classificationsSecond].filter(x => !!x ).join(' ')
    const orderSummaryToCopy = `${orderSummaryFirstLine}
${id.toUpperCase()}`
    setData({
      id,
      tradingType,
      carbonStandard: carbonStandardFiltered,
      classificationsFirst: classificationsFirst,
      volumeText,
      classificationsSecond: classificationsSecond,
      date,
      price,
      projectIds,
      additionalAttributes,
      orderSummaryToCopy
    })
  }, [order])
  const tradingTypeInCurrentContext = isCreatingTrade ? order.orderBeenTradedTradingType : data.tradingType
  return (
    <>
      <GridArea name={HEADER_AREAS.SUMMARY}>
        <Collapse in={expanded}>
          <Grid container>
            {!isTermSheetSummary && (
              <>
                <CompanyCode
                  data-testid="order-summary-company-code"
                  order={order}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ...styles.text1,
                    marginRight: gutterX,
                    fontWeight: 900,
                  }}
                />
                {!isCreatingTrade && (
                  <>
                    <SummaryRow.TradingType value={tradingTypeInCurrentContext} />
                    <SummaryRow.ProjectIds value={data.projectIds} />
                    <SummaryRow.AdditionalAttributes value={data.additionalAttributes} />
                  </>
                )}
                {isCreatingTrade && (<SummaryRow.TradingType value={'Trade'} />)}
                <SummaryRow.CarbonStandard value={data.carbonStandard} />
                <SummaryRow.VintageDate value={data.date} />
                <SummaryRow.Classifications value={data.classificationsFirst} />
                {!isCreatingTrade && (<SummaryRow.CopyOrderSummary orderSummary={data.orderSummaryToCopy} />)}
              </>
            )}
            {isTermSheetSummary && (
              <CompanyCodeTermsheet
              data-testid="order-summary-company-code"
              order={order}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...styles.text1,
                marginRight: gutterX,
                fontWeight: 900,
              }}
            />
            )}
          </Grid>
        </Collapse>
      </GridArea>

      {!isTermSheetSummary && (
        <GridArea name={HEADER_AREAS.DETAILS}>
          <Collapse in={expanded}>
            <SummaryRow.Price {...data.price} id={data.id} />
            <Grid item>
              <SummaryRow.ClassificationsInfo
                value={data.classificationsSecond}
                volumeText={data.volumeText}
              />
            </Grid>

            {data.id && (
              <SummaryRow.OrderId
                shortId={data.id}
                value={data.id}
                label={isTermsheet(order) ? 'Term sheet' : 'Order'}
              />
            )}
          </Collapse>
        </GridArea>
      )}
    </>
  )
}

OrderSummary.defaultProps = {
  order: {},
  expanded: true,
}

OrderSummary.propTypes = {
  expanded: PropTypes.bool,
}
