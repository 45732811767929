import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import HandleCollapse from './HandleCollapse'
import SubNavLink from './SubNavLink'
import NavLinkMenu from './NavLinkMenu'

const Icon = () => <span />

const NavLink = (props) => {
  if (props.subMenu) {
    return <SubNavLink {...props} />
  }
  return (
    <HandleCollapse {...{ to: props.to }}>
      <NavLinkMenu {...props} />
    </HandleCollapse>
  )
}

NavLink.defaultProps = {
  Icon,
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  subMenu: PropTypes.node,
}

export default withRouter(NavLink)
