import { withProps } from 'recompose'
import Edit from '@material-ui/icons/Edit'
import IconButton from 'components/iconButton/IconButton'

const EditButton = withProps({
  variant: 'contained',
  color: 'primary',
  icon: Edit,
  children: 'Edit',
})(IconButton)

export default EditButton
