import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import {
  ConnectedSelectableGrid,
  SelectableGrid,
} from 'components/SelectableGrid'

export const Button = styled.div`
  padding: ${({ theme }) => theme.spacing(0.5)}px;
`

export const StyledContainer = styled.div`
  margin-top: ${({ theme, spacing }) => theme.spacing(spacing || 3)}px;
`

export const PaddedContainer = styled.div`
  padding: var(--simple-modal__content-padding);
`

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
}))

export const StyledConnectedSelectableGrid = (props) => {
  const classes = useStyles()
  return <ConnectedSelectableGrid classes={classes} {...props} />
}

export const StyledSelectableGrid = (props) => {
  const classes = useStyles()
  return <SelectableGrid classes={classes} {...props} />
}
