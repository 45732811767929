import { isEmpty, isString, round } from 'lodash'

import {
  PRICE_TYPE,
  PRICE_TYPE_TEXT,
  PRICE_UNIT_TEXT,
  PRICE_INDEX,
  PRICE_INDEX_TEXT,
} from 'emsurge-selectors/constants'

const hasPrice = (info) => {
  if (!Array.isArray(info) || isEmpty(info)) {
    return false
  }

  const validPrices = info.filter((price) => {
    const index = price.index
    const percentage = parseFloat(price.percentage)
    const weight = parseFloat(price.weight)

    return (
      Number.isFinite(weight) &&
      Number.isFinite(percentage) &&
      isString(index) &&
      !isEmpty(index)
    )
  })

  return !isEmpty(validPrices)
}

const getPrice = (info = []) => {
  return info.map((price) => {
    return {
      amount: parseFloat(price.amount) || undefined,
      index: price.index,
      percentage: parseFloat(price.percentage) || undefined,
      plusOrMinus: price.plusOrMinus,
      unit: price.unit,
      weight: parseFloat(price.weight) || undefined,
    }
  })
}

const mapPriceToLongSummary = (price) => {
  const percentage = round(price.percentage, 3)

  const indexEntry = Object.entries(PRICE_INDEX).find((a) =>
    a.includes(price.index)
  )
  const indexValueKey = indexEntry && indexEntry[0]
  const indexName = PRICE_INDEX_TEXT[indexValueKey] || ''

  let longSummary = `${price.weight}% of ${percentage}% ${indexName}`

  if (price.amount) {
    const amount = round(price.amount, 3)
    const unit = PRICE_UNIT_TEXT[price.unit](amount)
    const plusOrMinus = price.plusOrMinus || ''

    longSummary += ` ${plusOrMinus} ${unit}`
  }

  return longSummary
}

const getSummary = (info) => {
  const summary = {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.HYBRID],
    short: 'Hybrid Price',
    long: Array.isArray(info) ? info.map(mapPriceToLongSummary) : [],
  }

  return summary
}

export const hybrid = {
  exists: hasPrice,
  get: getPrice,
  summary: getSummary,
}
