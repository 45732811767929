import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { Laptop } from 'components/Viewport'
import Footer from 'components/main/Footer'
import { LayoutWrapper } from 'components/LayoutWrapper'
import { BrandLogoHex, BrandLogoWrapper } from 'components/logo'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    flexWrap: 'nowrap',
  },
}))

export const LoggedOutView = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <LayoutWrapper>
        <BrandLogoWrapper />
        {children}
        <Grid container justifyContent="center" className={classes.container}>
          <BrandLogoHex />
        </Grid>
      </LayoutWrapper>
      <Laptop matchLarger>
        <Footer />
      </Laptop>
    </>
  )
}

LoggedOutView.propTypes = {
  children: PropTypes.node.isRequired,
}
