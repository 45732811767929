import React, { forwardRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import MuiModal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'

const useWrapperStyles = makeStyles((theme) => ({
  root: ({ styles }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100vw - 160px)',
    maxWidth: 1600,
    height: 'calc(100vh - 25px)',
    maxHeight: 900,
    backgroundColor: theme.palette.background.paper,
    outline: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '100vh',
      maxHeight: '100%',
    },
    ...styles,
  }),
}))

const Wrapper = forwardRef(({ styles, ...props }, ref) => {
  const classes = useWrapperStyles({ styles })
  return <Paper {...props} ref={ref} className={classes.root} />
})

Wrapper.propTypes = {
  styles: PropTypes.object,
}

const Modal = ({ children, paperProps, ...props }) => (
  <MuiModal {...props}>
    <Wrapper {...paperProps}>{children}</Wrapper>
  </MuiModal>
)

Modal.propTypes = {
  paperProps: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Modal
