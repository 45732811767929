import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { InputAdornment } from '@material-ui/core'

const UNIT = 'unit'
const PER_YEAR = 'per_year'
const CURRENCY = 'currency'

const LABEL_UNIT = {
  [UNIT]: 'tCO',
  [PER_YEAR]: 'tCO',
  [CURRENCY]: '/tCO',
}

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
  },
  marginTop: {
    marginTop: '12px',
  },
}))

const _Currency = ({ unit, bold, style, className }) => {
  const classes = useStyles()
  const classText = bold ? classes.bold : ''

  const getSuffix = () => {
    return unit === PER_YEAR ? 'e/yr' : 'e'
  }

  return (
    <InputAdornment position="start" className={className} style={style}>
      <span className={classText}>{LABEL_UNIT[unit] || ''}</span>
      <sub className={`${classText} ${classes.marginTop}`}>2</sub>
      <span className={classText}>{getSuffix()}</span>
    </InputAdornment>
  )
}

_Currency.defaultProps = {
  unit: UNIT,
  bold: false,
  className: '',
}

_Currency.propTypes = {
  unit: PropTypes.oneOf([CURRENCY, UNIT, PER_YEAR]),
  style: PropTypes.object,
  bold: PropTypes.bool,
  className: PropTypes.string,
}

export const Currency = _Currency
