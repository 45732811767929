const CHECKLIST_TYPE = Object.freeze({
  ORDER: 1,
  PRE_TRADE: 2,
  POST_TRADE: 3,
})

const CHECKLIST_TYPE_TEXT = {
  [CHECKLIST_TYPE.ORDER]: 'Order Checklist',
  [CHECKLIST_TYPE.PRE_TRADE]: 'Open Term Sheet Checklist',
  [CHECKLIST_TYPE.POST_TRADE]: 'Traded Term Sheet Checklist',
}

const CHECKLIST_ITEM_DEFAULTS = {
  [CHECKLIST_TYPE.ORDER]: [
    'Tax',
    'Legal',
    'Ship Ops',
    'Commercial Ops',
    'Finance',
    'Risk',
    'Management Approval',
    'Management 2 Approval',
    'Board Approval',
    'Commercial Approval',
    'Ship-Shore Compatibility',
  ],
  [CHECKLIST_TYPE.PRE_TRADE]: [
    'Tax',
    'Legal',
    'Ship Ops',
    'Commercial Ops',
    'Finance',
    'Risk',
    'Management Approval',
    'Management 2 Approval',
    'Board Approval',
    'Commercial Approval',
    'Ship-Shore Compatibility',
    'NDA',
    'KYC',
    'MSA',
  ],
  [CHECKLIST_TYPE.POST_TRADE]: [
    'KYC',
    'MSA',
    'CN - Tax',
    'CN - Legal',
    'CN - Credit',
    'CN - Ship Ops',
    'CN - Marine Insurance',
    'CN - Commercial Ops',
    'CN - Finance',
    'CN - Trade Finance',
    'CN - Management',
    'CN - Management 2',
    'CN - Board Approval',
    'CN - Commercial Approval',
    'Ship-Shore Compatibility',
    'SBLC Issued',
  ],
}

const CHECKLIST_ITEM_DEFAULTS_CARBON = {
  [CHECKLIST_TYPE.ORDER]: [
    'Tax',
    'Legal',
    'Commercial Ops',
    'Finance',
    'Risk',
    'Management Approval',
    'Management 2 Approval',
    'Board Approval',
    'Commercial Approval',
  ],
  [CHECKLIST_TYPE.PRE_TRADE]: [
    'Tax',
    'Legal',
    'Commercial Ops',
    'Finance',
    'Risk',
    'Management Approval',
    'Management 2 Approval',
    'Board Approval',
    'Commercial Approval',
    'NDA',
    'KYC',
    'MSA',
  ],
  [CHECKLIST_TYPE.POST_TRADE]: [
    'KYC',
    'MSA',
    'CN - Tax',
    'CN - Legal',
    'CN - Credit',
    'CN - Marine Insurance',
    'CN - Commercial Ops',
    'CN - Finance',
    'CN - Trade Finance',
    'CN - Management',
    'CN - Management 2',
    'CN - Board Approval',
    'CN - Commercial Approval',
    'SBLC Issued',
  ],
}

module.exports = {
  CHECKLIST_TYPE,
  CHECKLIST_TYPE_TEXT,
  CHECKLIST_ITEM_DEFAULTS,
  CHECKLIST_ITEM_DEFAULTS_CARBON
}
