import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'
import FormProvider from './containers/FormProvider'
import FormProviderCarbon from 'screens/termsheetEditCarbon/containers/FormProvider'
import ModalContent from 'screens/orderCreate/desktop/ModalContent'
import ModalHeader from 'screens/orderCreate/desktop/ModalHeader'
import ModalHeaderCarbon from 'screens/orderCreateCarbon/ModalHeader'
import ModalContentCarbon from 'screens/orderCreateCarbon/ModalContent'
import { useTermsheetEdit } from 'api'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
const {
  APP_CONTEXT_TYPE,
} = require('emsurge-selectors/constants')

export const TermsheetEdit = () => {
  const history = useHistory()
  const { id } = useParams()
  const { isLoading, data: termsheet } = useTermsheetEdit(id)
  const BASE_SLUG = useRouteBaseSlug()

  if (isLoading) {
    return <CircularProgress />
  }

  const onCancel = () => history.push(`/${BASE_SLUG}`)
  const context = termsheet.context

  return (
    <>
      { context === APP_CONTEXT_TYPE.LNG && (
        <FormProvider termsheet={termsheet}>
          <>
            <ModalHeader onCancel={onCancel} isCreatingTrade={true} isEdit={true}/>
            <ModalContent isCreatingTrade={true} isEditTrade={true}/>
          </>
        </FormProvider>
      )}
      { context === APP_CONTEXT_TYPE.CARBON && (
        <FormProviderCarbon termsheet={termsheet}>
          <>
            <ModalHeaderCarbon onCancel={onCancel} isCreatingTrade={true} isEdit={true}/>
            <ModalContentCarbon isCreatingTrade={true} isEditTrade={true}/>
          </>
        </FormProviderCarbon>
      )}
    </>
  )
}
