import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid as _Grid, MenuItem, TextField } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { first, values } from 'lodash'
import {
  MARKET_GRID_MARKET_PROVIDER,
  MARKET_GRID_MARKET_PROVIDER_TEXT,
} from 'emsurge-selectors'
import { EmsurgeFields } from './EmsurgeFields'
import { EvoFields } from './EvoFields'

const Grid = styled(_Grid)`
  ${spacing}
`

const components = {
  [MARKET_GRID_MARKET_PROVIDER.EMSURGE]: EmsurgeFields,
  [MARKET_GRID_MARKET_PROVIDER.CT_GRID]: EvoFields,
}

const ProviderFields = (props) => {
  const Provider =
    components[props.fields?.provider || MARKET_GRID_MARKET_PROVIDER.EMSURGE]
  return <Provider {...props} />
}

ProviderFields.propTypes = {
  fields: PropTypes.object,
}

export const MarketSelector = () => {
  const [marketProps, setMarketProps] = useState({
    provider: first(values(MARKET_GRID_MARKET_PROVIDER)),
  })

  return (
    <Grid container my={2} spacing={2} alignItems="flex-end">
      <Grid item>
        <TextField
          select
          label="Provider"
          value={marketProps.provider}
          onChange={(evt) =>
            setMarketProps({
              provider: evt.target?.value,
            })
          }
        >
          {values(MARKET_GRID_MARKET_PROVIDER).map((providerId) => (
            <MenuItem key={`provider-${providerId}`} value={providerId}>
              {MARKET_GRID_MARKET_PROVIDER_TEXT[providerId]}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <ProviderFields
        fields={marketProps}
        onChange={(newProps) => setMarketProps(newProps)}
      />
    </Grid>
  )
}
