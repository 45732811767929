import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'formik'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'

import {
  $O,
  $T,
  DELIVERY_TYPE,
  DELIVERY_WINDOW_TYPE_TEXT,
  ORDER_STATUS,
} from 'emsurge-selectors'
import { getStepByTitle } from './helpers'
import { RowWithValues } from './RowWithValues'
import { OrderStatusSwitch } from './OrderStatusSwitch'
import { mapFormikValuesToOrder } from 'screens/orderCreateCommon/helpers'
import { STEP_TITLES } from 'screens/orderCreate/mobile/constants'
import { PERIOD, PERIOD_LABELS } from 'model/order/constants/delivery'

const PaddedContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
`

const useRowStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(0.75),
  },
}))

const getBasePort = (order) =>
  $O.delivery.type.get(order).toUpperCase() === DELIVERY_TYPE.FOB
    ? 'loadPort'
    : 'deliveryPort'

export const UnconnectedRecap = ({ order, actions }) => {
  const classes = useRowStyles()
  const goTo = useCallback(
    (stepTitle) => actions.goTo(getStepByTitle(stepTitle)),
    [actions]
  )
  const deliveryText = $O.delivery.text(order)
  const { min: deliveryCargoMin, max: deliveryCargoMax } =
    $O.delivery.cargo.get(order)
  const nCargoes = `${
    deliveryCargoMin !== deliveryCargoMax
      ? `${deliveryCargoMin} - ${deliveryCargoMax}`
      : deliveryCargoMax
  }`

  const hasMultipleCargoes = () => deliveryText.cargo.max > 1

  return (
    <>
      <PaddedContainer onClick={() => goTo(STEP_TITLES.GENERAL_INFORMATION)}>
        <RowWithValues
          classes={classes}
          label="Company"
          value={$O.stakeholder.company.text(order)}
        />
        <RowWithValues
          label="Entity"
          value={$O.stakeholder.entity.text(order)}
        />
        <RowWithValues
          label="Trader"
          value={$O.stakeholder.person.text(order)}
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.DELIVERY_AND_LOCATION)}>
        <RowWithValues
          data-testid="recap-order-type"
          classes={classes}
          label="Order type"
          value={$O.tradingType.text(order)}
        />
        <RowWithValues
          data-testid="recap-delivery-and-location"
          label="Delivery & Location"
          value={`${deliveryText.type} ${deliveryText.location}`}
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.PERIOD)}>
        <RowWithValues
          data-testid="recap-period"
          label="Period"
          value={deliveryText.period}
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.NR_OF_CARGOES)}>
        <RowWithValues
          data-testid="recap-number-of-cargoes"
          label="No. of Cargoes"
          value={`${nCargoes}`}
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.DELIVERY_WINDOW)}>
        <RowWithValues
          classes={classes}
          data-testid="recap-delivery-window"
          label="Delivery Window"
          value={`
            ${get(
              order,
              'nominationRules.deliveryWindow.cargos[0].cargoWindow',
              PERIOD_LABELS[PERIOD.CUSTOM]
            )} 
            ${
              hasMultipleCargoes(order)
                ? `(${
                    DELIVERY_WINDOW_TYPE_TEXT[
                      order?.nominationRules?.deliveryWindow?.deliveryType
                    ]
                  })`
                : ''
            }
          `}
        />
        <RowWithValues
          data-testid="recap-frequency"
          label="Frequency"
          value={$O.delivery.frequency.summary(order).type}
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.PRICE)}>
        <RowWithValues
          data-testid="recap-price"
          label="Price"
          value={$O.price.summary(order).short}
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.QUALITY_GHV)}>
        <RowWithValues
          classes={classes}
          data-testid="recap-quality-ghv"
          label="Quality GHV"
          value={$O.quality.text(order)}
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.CARGO_SIZE)}>
        <RowWithValues
          classes={classes}
          data-testid="recap-cargo-size"
          label="Cargo Size"
          value={$O.cargoSize.size.text(order)}
        />
        <RowWithValues
          data-testid="recap-op-tolerance"
          label="Op. Tolerance"
          value={$O.cargoSize.variance.text(order)}
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.BASE_PORT)}>
        <RowWithValues
          data-testid="recap-base-port"
          label="Base Port"
          value={$T[getBasePort(order)]?.base.text(order.nominationRules).name}
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.GENERAL_INFORMATION)}>
        <RowWithValues
          data-testid="recap-visibility"
          label="Visibility"
          value={
            $O.status.get(order) !== ORDER_STATUS.PENDING
              ? $O.validity.text(order)
              : 'N/A'
          }
        />
      </PaddedContainer>

      <Divider />

      <PaddedContainer onClick={() => goTo(STEP_TITLES.NOTES)}>
        <RowWithValues
          data-testid="recap-notes"
          label="Public Note"
          value={order.details}
        />
      </PaddedContainer>
      <Divider />

      <PaddedContainer>
        <RowWithValues
          data-testid="recap-order-status"
          label="Status"
          value={<OrderStatusSwitch order={order} />}
        />
      </PaddedContainer>
    </>
  )
}

UnconnectedRecap.defaultProps = {
  actions: {
    goTo: () => {},
  },
}

UnconnectedRecap.propTypes = {
  order: PropTypes.object.isRequired,
  actions: PropTypes.object,
}

export const Recap = connect(({ formik: { values }, ...props }) => (
  <UnconnectedRecap order={mapFormikValuesToOrder(values)} {...props} />
))
