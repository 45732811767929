import React from 'react'
import PropTypes from 'prop-types'
import { find, get } from 'lodash'
import { connect } from 'formik'
import Grid from '@material-ui/core/Grid'

import { DELIVERY_FREQUENCY_TYPE } from 'emsurge-selectors/constants'
import { DeferredTextField } from 'screens/orderCreateCommon/components/TextField'
import { getFrequencyOptions } from 'screens/orderCreate/components/DeliveryWindow.helpers'

const isValidCustomDate = (ev) => {
  const date = get(ev[0], 'target.value')
  const monthYearLength = 7
  return date && date.length === monthYearLength
}

const _SelectCustomDeliveryDate = ({ formik, xs }) => {
  // When custom date changes, we need to evaluate whether "per annum" frequency is still valid, and change it otherwise
  const onCustomDateChange = (field, value) => {
    const {
      setFieldValue,
      values: {
        delivery: { frequency, period, customFrom, customTo },
      },
    } = formik

    if (frequency === DELIVERY_FREQUENCY_TYPE.PER_ANNUM) {
      const frequencyOptions = getFrequencyOptions(period, {
        customFrom,
        customTo,
        [field]: value,
      })
      const perAnnumOption = find(
        frequencyOptions,
        ({ value }) => value === DELIVERY_FREQUENCY_TYPE.PER_ANNUM
      )

      if (perAnnumOption.disabled) {
        const firstAvailableOption = find(
          frequencyOptions,
          ({ disabled }) => !disabled
        )
        setFieldValue('delivery.frequency', firstAvailableOption.value)
      }
    }
  }

  return (
    <>
      <Grid item xs={xs}>
        <DeferredTextField
          type="month"
          label="From"
          name="delivery.customFrom"
          shouldSave={isValidCustomDate}
          onBlur={(evt) => onCustomDateChange('customFrom', evt.target.value)}
          inputProps={{ 'data-testid': 'custom-period-from-date' }}
        />
      </Grid>
      <Grid item xs={xs}>
        <DeferredTextField
          type="month"
          label="To"
          name="delivery.customTo"
          shouldSave={isValidCustomDate}
          onBlur={(evt) => onCustomDateChange('customTo', evt.target.value)}
          inputProps={{ 'data-testid': 'custom-period-to-date' }}
        />
      </Grid>
    </>
  )
}

_SelectCustomDeliveryDate.defaultProps = {
  xs: true,
}

_SelectCustomDeliveryDate.propTypes = {
  formik: PropTypes.object.isRequired,
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
}

export const SelectCustomDeliveryDate = connect(_SelectCustomDeliveryDate)
