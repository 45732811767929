import React from 'react'
import PropTypes from 'prop-types'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { wrapDisplayName } from 'recompose'
import { ApiContext } from './ApiProvider'

/* :: Function -> Function */
export const withApi = (Component) => {
  const WithApi = (props) => (
    <ApiContext.Consumer>
      {(api) => <Component {...props} api={api} />}
    </ApiContext.Consumer>
  )

  hoistNonReactStatics(WithApi, Component)

  if (process.env.NODE_ENV !== 'production') {
    WithApi.displayName = wrapDisplayName(Component, 'withApi')
  }

  return WithApi
}

export const ApiType = PropTypes.shape({
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  put: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
})
