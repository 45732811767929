import React from 'react'
import PropTypes from 'prop-types'
import StepButton from '@material-ui/core/StepButton'
import Text from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import Tooltip from 'components/tooltip/Tooltip'

const StepLabel = ({ children, error, ...props }) => {
  const { palette } = useTheme()

  if (error) {
    return (
      <StepButton {...props}>
        <Tooltip title="Please correct all the errors">
          <Text variant="h6" style={{ color: palette.error.main }}>
            {children}
          </Text>
        </Tooltip>
      </StepButton>
    )
  }

  return (
    <StepButton {...props}>
      <Text variant="h6">{children}</Text>
    </StepButton>
  )
}

StepLabel.defaultProps = {
  error: false,
}

StepLabel.propTypes = {
  children: PropTypes.string.isRequired,
  error: PropTypes.bool,
}

export default StepLabel
