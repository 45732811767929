import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import UserForm from './components/UserForm'
import FormProvider from './containers/FormProvider'
import Fetch from 'containers/fetchPlus/FetchPlus'
import withParams from 'utils/withRouteParams'

const CreateUser = ({ companyId, history }) => (
  <Fetch url={`/companies/${companyId}`}>
    {(company) => (
      <>
        <Text component="h1" variant="h1">
          Add user
        </Text>

        <FormProvider
          company={company}
          entities={company.entities}
          history={history}
        >
          <UserForm company={company} entities={company.entities} />
        </FormProvider>
      </>
    )}
  </Fetch>
)

CreateUser.propTypes = {
  companyId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
}

export default withParams(CreateUser)
