import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { $U, PERSON_PERMISSION } from 'emsurge-selectors'
import { userHasMinimumPermission } from 'permissions'
import { useUser } from 'containers/user/useUser'
import { hasValidSession } from 'containers/user/helpers'
import { HOME } from 'routes'

const AuthRoute = ({ permissions, minimumPermission, ...props }) => {
  const { user, logout } = useUser()
  const location = useLocation()
  const type = $U.clientType.get(user)

  if (hasValidSession(user)) {
    const hasMinimumPermission =
      minimumPermission === PERSON_PERMISSION.NONE ||
      (user.entities &&
        user.entities.some(({ permission }) =>
          userHasMinimumPermission(permission, minimumPermission)
        ))

    if (!permissions.includes(type) || !hasMinimumPermission) {
      return <Redirect to={{ path: HOME }} />
    }

    return <Route {...props} />
  } else {
    console.warn("The user session is not valid. Forcing a logout...")
    logout()
  }

  return (
    <Redirect
      to={{
        path: HOME,
        state: {
          referrer: `${location.pathname}${location.search}`,
        },
      }}
    />
  )
}

AuthRoute.defaultProps = {
  permissions: [],
  minimumPermission: PERSON_PERMISSION.NONE,
}

AuthRoute.propTypes = {
  permissions: PropTypes.array,
  minimumPermission: PropTypes.string,
}

export default AuthRoute
