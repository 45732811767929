import React, { useState, useMemo } from 'react'
import { debounce } from 'lodash'
import { compose } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import {
  groupMarketInterestOrders,
  filterMarketInterestOrdersByStatus,
  filterMarketInterestOrdersByClassifications,
} from './helpers'
import { BlockContent } from 'screens/orderIndex/components/Block'
import MarketInterestHeader from 'screens/orderIndex/components/MarketInterest/MarketInterestHeader'
import { MarketInterestOrderSkeleton } from 'screens/orderIndex/components/MarketInterest/MarketInterestOrderSkeleton'
import { VirtualList } from 'components/VirtualList'
import { filterArrayOfObjects } from 'utils/dataHandlers'
import { STATUS } from 'components/StatusFilters'
import useAppContext from 'containers/appContext/useAppContext'
import { useOrders } from 'api'

const DEBOUNCE_RATE = 500

const RowRenderer = ({ index, style, data }) => {
  const { Component } = data[index]
  return <Component style={style} />
}

RowRenderer.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.array,
}

const getItemSize = (data, index) => {
  const { height } = data?.[index] || {}
  return height
}

export const MarketInterest = () => {
  const { appContext, isLNGContext } = useAppContext()
  const [filter, setFilter] = useState('')
  const [status, setStatus] = useState(STATUS.ACTIVE)
  const [sortBy, setSortBy] = useState(isLNGContext ? 'date' : 'vintage')
  const [classifications, setClassifications] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const onFilterChange = debounce((value) => setFilter(value), DEBOUNCE_RATE)
  const onStatusChange = (status) => setStatus(status)

  const { isLoading, isError, data } = useOrders()
  const orders = data || []

  if (isError) {
    enqueueSnackbar('Connection failed updating orders', {
      variant: 'warning',
    })
  }

  const filteredOrders = useMemo(
    () => filterArrayOfObjects(orders, filter),
    [orders, filter]
  )

  const onClassificationsChange = (classifications) => {
    const filteredClassifications = Object.keys(classifications).filter(
      (key) => classifications[key]
    )
    if (filteredClassifications.length === 2) {
      filteredClassifications.push('RA')
    }
    setClassifications(filteredClassifications)
  }

  const handleSortBy = ({ target: { value: sortBy } }) => setSortBy(sortBy)

  const nodeTree = useMemo(
    () =>
      compose(
        groupMarketInterestOrders(sortBy, appContext),
        filterMarketInterestOrdersByStatus(status),
        isLNGContext
          ? () => filteredOrders
          : filterMarketInterestOrdersByClassifications(classifications)
      )(filteredOrders),
    [filteredOrders, sortBy, status, classifications]
  )

  return (
    <>
      <MarketInterestHeader
        sortByValue={sortBy}
        onSortByChange={handleSortBy}
        status={status}
        setStatus={onStatusChange}
        setClassifications={onClassificationsChange}
        filter={filter}
        setFilter={onFilterChange}
      />
      <BlockContent>
        {isLoading ? (
          <MarketInterestOrderSkeleton count={3} />
        ) : (
          <VirtualList
            rowRenderer={RowRenderer}
            getItemSize={getItemSize}
            items={nodeTree}
          />
        )}
      </BlockContent>
    </>
  )
}
