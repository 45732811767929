import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import MoreVertical from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import Fab from '@material-ui/core/Fab'
import { Dialog, IconButton, List, ListItem } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { PERSON_PERMISSION } from 'emsurge-selectors/constants'
import ArchiveOrderMenuItem from '../components/MenuItems/ArchiveOrderMenuItem'
import DeleteOrderMenuItem from '../components/MenuItems/DeleteOrderMenuItem'
import CloneOrderMenuItem from '../components/MenuItems/CloneOrderMenuItem'
import { MarkAsTradedMenuItem } from '../components/MenuItems/MarkAsTradedMenuItem'
import Tooltip from 'components/tooltip/Tooltip'
import { useUserHasPermission } from 'permissions'
import { Mobile, Tablet } from 'components/Viewport'

const HeaderMoreMenu = ({ order, style, onClose }) => {
  const userHasWritePermission = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.WRITE,
  })
  const ref = useRef()
  const [open, setOpen] = useState(false)

  if (!userHasWritePermission) {
    return null
  }

  const close = () => {
    setOpen(false)
    return onClose
  }

  return (
    <>
      <Tooltip title="Menu">
        <Fab
          data-testid="more-menu-button"
          size="small"
          style={style}
          ref={ref}
          onClick={() => setOpen(true)}
        >
          <MoreVertical />
        </Fab>
      </Tooltip>

      <Mobile>
        <Dialog
          onClose={close}
          open={open}
          aria-labelledby="more-menu-options"
          fullWidth
        >
          <List>
            <ListItem style={{ justifyContent: 'flex-end' }}>
              <IconButton onClick={close} style={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </ListItem>
            <ArchiveOrderMenuItem order={order} closeMenu={close} />
            <DeleteOrderMenuItem order={order} closeMenu={close} />
            <CloneOrderMenuItem order={order} />
            <MarkAsTradedMenuItem order={order} />
          </List>
        </Dialog>
      </Mobile>

      <Tablet matchLarger>
        <Menu keepMounted anchorEl={ref.current} open={open} onClose={close}>
          <span>
            <ArchiveOrderMenuItem order={order} closeMenu={close} />
            <DeleteOrderMenuItem order={order} closeMenu={close} />
            <CloneOrderMenuItem order={order} />
            <MarkAsTradedMenuItem order={order} />
          </span>
        </Menu>
      </Tablet>
    </>
  )
}

HeaderMoreMenu.propTypes = {
  order: PropTypes.object.isRequired,
  style: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default HeaderMoreMenu
