const VESSEL_TYPE = Object.freeze({
  VESSEL_SIZE: 'vessel_size',
  VESSEL_CAPACITY: 'vessel_capacity',
})

const VESSEL_TYPE_LABEL = Object.freeze({
  [VESSEL_TYPE.VESSEL_SIZE]: 'Size',
  [VESSEL_TYPE.VESSEL_CAPACITY]: 'Capacity',
})

const VESSEL_SIZE_UNIT = Object.freeze({
  M_THREE: 'm³',
})

module.exports = {
  VESSEL_TYPE,
  VESSEL_TYPE_LABEL,
  VESSEL_SIZE_UNIT,
}
