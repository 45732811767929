import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography as Text } from '@material-ui/core'
import { CancelButton, ConfirmButton } from './Buttons'

export const ConfirmActionInline = ({ onConfirm, onCancel, text }) => (
  <Grid container direction="column" spacing={3}>
    <Grid item>
      <Text
        variant="overline"
        component="p"
        style={{
          color: '#A7B8C2',
          lineHeight: 1.66,
          textTransform: 'none',
        }}
      >
        {text}
      </Text>
    </Grid>
    <Grid item container spacing={1} justifyContent="flex-end">
      <Grid item>
        <CancelButton onClick={onCancel} size="small">
          Cancel
        </CancelButton>
      </Grid>
      <Grid item>
        <ConfirmButton onClick={onConfirm} size="small">
          Confirm
        </ConfirmButton>
      </Grid>
    </Grid>
  </Grid>
)

ConfirmActionInline.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}
