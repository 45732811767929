import React from 'react'
import Text from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { getFormattedDate } from 'utils/dates'

const Row = ({ title, value, formatter }) => (
  <>
    <Text variant="caption">{title}</Text>
    <Text variant="subtitle1" color="textSecondary" gutterBottom>
      {value ? formatter(value) : '--'}
    </Text>
  </>
)

Row.defaultProps = {
  formatter: (v) => v,
}

Row.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  formatter: PropTypes.func,
}

const ViewTradeTime = ({ termsheet }) => {
  const { tradeDate, cnDate, tradeDetails } = termsheet
  const localTime = moment.tz.guess()

  return (
    <>
      <Text variant="h6" gutterBottom paragraph>
        Trade
      </Text>
      <Row
        title="Trade Date"
        value={tradeDate}
        formatter={(v) => getFormattedDate(v, localTime, 'DD/MM/YY')}
      />
      <Row
        title="Trade Time"
        value={tradeDate}
        formatter={(v) => getFormattedDate(v, localTime, 'HH:mm')}
      />
      <Row
        title="Timezone"
        value={tradeDate}
        formatter={(v) => getFormattedDate(v, localTime, 'UTC Z')}
      />
      <Row title="Details" value={tradeDetails} />
      <Row
        title="CN Signed Date"
        value={cnDate}
        formatter={(v) => getFormattedDate(v, localTime, 'DD/MM/YY')}
      />
      <Row
        title="CN Time"
        value={cnDate}
        formatter={(v) => getFormattedDate(v, localTime, 'HH:mm')}
      />
      <Row
        title="CN Timezone"
        value={cnDate}
        formatter={(v) => getFormattedDate(v, localTime, 'UTC Z')}
      />
    </>
  )
}

ViewTradeTime.propTypes = {
  termsheet: PropTypes.object,
}

export default ViewTradeTime
