import React from 'react'
import PropTypes from 'prop-types'
import { $T } from 'emsurge-selectors/models'
import DetailRow from './DetailRow'
import DetailDivider from './DetailDivider'

const NominationRulesOtherFields = ({ nominationRules, onClick }) => {
  return (
    <>
      {nominationRules.demurage && (
        <>
          <DetailDivider />
          <DetailRow
            onClick={onClick}
            title="Demurrage Rate"
            data-testid="order-terms-demurrage-rate"
            info={$T.demurrage.rate.text(nominationRules)}
          />
        </>
      )}
      {nominationRules.demurageNote && (
        <>
          <DetailDivider />
          <DetailRow
            onClick={onClick}
            title="Demurrage Notes"
            data-testid="order-terms-demurrage-notes"
            info={$T.demurrage.notes.text(nominationRules)}
          />
        </>
      )}
      {nominationRules.boilOff && (
        <>
          <DetailDivider />
          <DetailRow
            onClick={onClick}
            title="Deemed Boil-Off Rate"
            data-testid="order-terms-boil-off-rate"
            info={$T.demurrage.boilOff.text(nominationRules)}
          />
        </>
      )}
      {nominationRules.laytime && (
        <>
          <DetailDivider />
          <DetailRow
            onClick={onClick}
            title="Allowed Laytime"
            data-testid="order-terms-allowed-laytime"
            info={$T.laytime.text(nominationRules)}
          />
          {nominationRules.fsruFsu && (
            <DetailRow
              onClick={onClick}
              info={$T.fsru_fsu.text(nominationRules)}
            />
          )}
        </>
      )}
    </>
  )
}

NominationRulesOtherFields.propTypes = {
  nominationRules: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

export default NominationRulesOtherFields
