import React from 'react'
import PropTypes from 'prop-types'
import { NOTES_PRIORITIES_COLORS } from '../constants'

const PriorityLabel = ({ label, containerStyle, beforeText, ...props }) => {
  const backgroundColor = NOTES_PRIORITIES_COLORS[label.toLowerCase()]

  return (
    <span style={{ display: 'flex', ...containerStyle }} {...props}>
      <div
        style={{
          borderRadius: 4,
          alignSelf: 'center',
          height: 16,
          width: 16,
          backgroundColor,
          display: 'inline-flex',
          marginRight: 8,
        }}
      />
      {beforeText ? `${beforeText} ${label}` : label}
    </span>
  )
}

PriorityLabel.propTypes = {
  label: PropTypes.string.isRequired,
  containerStyle: PropTypes.object,
  beforeText: PropTypes.string,
}

PriorityLabel.defaultProps = {
  containerStyle: {},
  beforeText: '',
}

export default PriorityLabel
