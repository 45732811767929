import React, { useCallback } from 'react'
import { get } from 'lodash'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FieldArray, connect } from 'formik'
import TextField from 'containers/formikTextField/FormikTextField'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'
import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'
import FormRow from 'components/formRow/FormRow'
import Card from 'screens/orderCreate/components/Card'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'
import TimeFrame from 'screens/orderCreate/components/TimeFrame'
import AddAlternativeSourceButton from 'screens/orderCreate/components/AddAlternativeSourceButton'
import {
  ToBeNominated,
  Party,
} from 'screens/orderCreate/components/NominationPort'
import { LNG_SHIP_NOMINATION_DEFAULT_VALUES } from 'screens/orderCreate/containers/NominationsInitialValues'
import { isFOBType } from 'screens/orderCreate/helpers'
import DeliveryLocationCard from 'screens/orderCreate/components/DeliveryLocationCard'

const defaultProps = {
  type: 'number',
  fullWidth: true,
  margin: 'normal',
  InputLabelProps: {
    error: false,
  },
}

const M3 = () => (
  <InputAdornment position="start">
    <span>m&sup3;</span>
  </InputAdornment>
)

const VESSEL_TYPE = [
  { value: 'vessel_size', label: 'Vessel Size' },
  { value: 'vessel_capacity', label: 'Vessel Capacity' },
]

const ShipNominationPort = connect(
  ({ formik: { values, setFieldValue }, mainName }) => {
    const getFieldName = useCallback(
      (relativeName) => `${mainName}.${relativeName}`,
      [mainName]
    )
    const getFieldValue = (relativeName) =>
      get(values, `${mainName}.${relativeName}`)
    const setPartyDefaultValue = useCallback(
      () =>
        setFieldValue(
          getFieldName('party'),
          isFOBType(values.delivery.type) ? 'bid' : 'ask'
        ),
      [getFieldName, setFieldValue, values.delivery.type]
    )
    const getTimeframeLabel = () => {
      const party = get(values, getFieldName('party'))

      switch (party) {
        case 'ask':
          return 'Seller will nominate the ship'
        case 'bid':
          return 'Buyer will nominate the ship'
        default:
          return 'Nomination of the ship to be announced'
      }
    }

    return (
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              {...defaultProps}
              type="text"
              multiline
              name={getFieldName('name')}
              label="Vessel name"
            />
          </Grid>
          <Grid item xs>
            <DeliveryLocationCard title="Delivery Port" />
          </Grid>
        </Grid>

        <TextField
          {...defaultProps}
          name={getFieldName('size')}
          label="Vessel size"
          InputProps={{
            endAdornment: <M3 />,
          }}
        />

        <ToBeNominated
          name={getFieldName('toBeNominated')}
          value={getFieldName('toBeNominated')}
        />

        <Party
          toBeNominated={getFieldValue('toBeNominated')}
          values={values}
          disabled={!getFieldValue('toBeNominated')}
          name={getFieldName('party')}
          setPartyDefaultValue={setPartyDefaultValue}
        />

        <RadioInputs
          name={getFieldName('vesselType')}
          options={VESSEL_TYPE.map((vt) => ({
            ...vt,
            disabled: !getFieldValue('toBeNominated'),
          }))}
        />

        <FormRow>
          <TextField
            {...defaultProps}
            disabled={!getFieldValue('toBeNominated')}
            name={getFieldName('min')}
            label="Min"
            InputProps={{
              endAdornment: <M3 />,
            }}
          />
          <TextField
            {...defaultProps}
            disabled={!getFieldValue('toBeNominated')}
            name={getFieldName('max')}
            label="Max"
            InputProps={{
              endAdornment: <M3 />,
            }}
          />
        </FormRow>
        <TimeFrame
          disabled={!getFieldValue('toBeNominated')}
          mainName={mainName}
          timeframe={getFieldValue('timeframe')}
          label={getTimeframeLabel()}
        />
      </>
    )
  }
)

export const ShipNominations = () => (
  <>
    <FormConsumer>
      {({ values }) => {
        const getFieldName = (relativePath) =>
          `nominationRules.ship.${relativePath}`
        const getFieldValue = (relativePath) =>
          get(values, `nominationRules.ship.${relativePath}`)

        return (
          <>
            <ShipNominationPort
              mainName="nominationRules.ship.base"
              values={values}
            />
            <FieldArray
              name={getFieldName('alternatives')}
              render={(arrayHelpers) => (
                <>
                  {getFieldValue('alternatives').map((_, index) => (
                    <Card key={`alternative_${index}`}>
                      <CloseButton onClick={() => arrayHelpers.remove(index)} />
                      <ShipNominationPort
                        mainName={`nominationRules.ship.alternatives[${index}]`}
                        values={values}
                      />
                    </Card>
                  ))}
                  <AddAlternativeSourceButton
                    label="Add Alternative Vessel"
                    onClick={() =>
                      arrayHelpers.push(LNG_SHIP_NOMINATION_DEFAULT_VALUES)
                    }
                  />
                </>
              )}
            />
          </>
        )
      }}
    </FormConsumer>
  </>
)
