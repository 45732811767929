import React, { useEffect, useState } from 'react'
import { get, isNumber } from 'lodash'
import { connect } from 'formik'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { PRICE_CURRENCY_OPTIONS } from 'emsurge-selectors'
import { computeVolumeInputNumber } from '../helpers'
import { Currency } from './Currency'
import { Dropdown } from './Dropdown'
import { TextField } from 'screens/orderCreateCommon/components/TextField'

const useStyles = makeStyles((theme) => ({
  grid: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    bottom: 0,
    marginBottom: theme.spacing(3),
  },
  input: {
    marginLeft: '3%',
    width: '97%',
  },
}))

const _VolumeInput = ({
  formik: { values, initialValues, setFieldValue },
  name,
  label,
  showInputProps = true,
  currencyFieldPath = '',
  classNameAdornment,
  dropdownNrCells = 1,
  unit = 'currency',
}) => {
  const classes = useStyles()
  const getValue = (path) => get(values, path) || get(initialValues, path)
  const [volume, setVolume] = useState(getValue(name))
  const [formattedVolume, setFormattedVolume] = useState()

  useEffect(() => {
    const volumeAmount = computeVolumeInputNumber(volume)
    if (volumeAmount) {
      const formatted = Number(volumeAmount).toLocaleString('en')
      setFormattedVolume(formatted)
    }
  }, [volume])

  useEffect(() => {
    const amount = computeVolumeInputNumber(formattedVolume)
    if (!isNumber(amount)) {
      return setVolume(0)
    }
    setFieldValue(name, formattedVolume)
  }, [formattedVolume])

  return (
    <Grid container spacing={4}>
      {currencyFieldPath ? (
        <Grid item xs={dropdownNrCells} className={classes.grid}>
          <Dropdown
            path={currencyFieldPath}
            title={''}
            listOptions={PRICE_CURRENCY_OPTIONS}
            margin="normal"
            className={classes.dropdown}
          />
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs>
        <TextField
          data-testid={name.replace(/\./g, '-')}
          fullWidth
          name={name}
          label={label}
          InputProps={
            showInputProps
              ? {
                  endAdornment: (
                    <Currency className={classNameAdornment} unit={unit} />
                  ),
                }
              : {}
          }
          value={formattedVolume}
          onChange={(evt) => setVolume(evt.target.value)}
          className={currencyFieldPath ? classes.input : ''}
        />
      </Grid>
    </Grid>
  )
}

export const VolumeInput = connect(_VolumeInput)
