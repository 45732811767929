import { isEmpty } from 'lodash'
import moment from 'moment'

import { PRICE_TYPE, PRICE_TYPE_TEXT } from 'emsurge-selectors/constants'

const DATE_FORMAT = 'MMMM Do YYYY, h:mm a'

const hasPrice = () => true

const getPrice = (info) => {
  const deadline = info.deadline
  const award = info.award

  return {
    award: !isEmpty(award)
      ? moment(`${award.date}T${award.time}:00`)
      : undefined,
    deadline: !isEmpty(deadline)
      ? moment(`${deadline.date}T${deadline.time}:00`)
      : undefined,
  }
}

const getSummary = (info) => {
  const longSummary = []

  const price = getPrice(info)

  if (price.deadline) {
    longSummary.push(`Deadline Date: ${price.deadline.format(DATE_FORMAT)}`)
  }

  if (price.award) {
    longSummary.push(`Award Date: ${price.award.format(DATE_FORMAT)}`)
  }

  const summary = {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.RFQ],
    short: 'RFQ',
    long: longSummary,
  }

  return summary
}

export const rfq = {
  exists: hasPrice,
  get: getPrice,
  summary: getSummary,
}
