import React from 'react'
import PropTypes from 'prop-types'
import { withTheme, useTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import ArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import {
  // $O,
  ORDER_STATUS,
  PERSON_CLIENT_TYPE,
  PERSON_PERMISSION,
  TRADING_TYPE,
} from 'emsurge-selectors'
import { OrderBlock } from './OrderBlock'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { useUser } from 'containers/user/useUser'
import { EssentialPanel } from 'screens/orderCreateCarbon/components/EssentialPanel'
import { OptionalPanel } from 'screens/orderCreateCarbon/components/OptionalPanel'
import LinkButton from 'components/linkButton/LinkButton'
import { OrderSummary } from 'components/OrderSummaryCarbon'

const GoToOrderButton = withTheme(({ theme, text, ...props }) => (
  <LinkButton
    {...props}
    href="#"
    size="small"
    variant="contained"
    color="secondary"
    style={{ margin: theme.spacing(1, 0) }}
  >
    <ArrowIcon />
    {text}
  </LinkButton>
))

GoToOrderButton.defaultProps = {
  text: 'Go To Order',
}

const Information = ({ termsheet, isTermSheetSummary}) => {
  const { user } = useUser()
  const buyerOrder = termsheet.orders.find(
    (order) => order.tradingType === TRADING_TYPE.BID
  )
  const sellerOrder = termsheet.orders.find(
    (order) => order.tradingType === TRADING_TYPE.ASK
  )

  const { spacing } = useTheme()
  const BASE_SLUG = useRouteBaseSlug()

  const isBuyerOrderWithheldFromOtherCompany =
    buyerOrder.person.companyId !== user.companyId &&
    buyerOrder.status === ORDER_STATUS.WITHHELD
  const isSellerOrderWithheldFromOtherCompany =
    sellerOrder.person.companyId !== user.companyId &&
    sellerOrder.status === ORDER_STATUS.WITHHELD
  const userCanSeeBuyerOrder =
    !(
      user.clientType === PERSON_CLIENT_TYPE.TRADER &&
      isBuyerOrderWithheldFromOtherCompany
    ) &&
    (buyerOrder.permission !== PERSON_PERMISSION.NONE ||
      !!buyerOrder.deletedAt ||
      buyerOrder.status === ORDER_STATUS.CLOSED)
  const userCanSeeSellerOrder =
    !(
      user.clientType === PERSON_CLIENT_TYPE.TRADER &&
      isSellerOrderWithheldFromOtherCompany
    ) &&
    (sellerOrder.permission !== PERSON_PERMISSION.NONE ||
      !!sellerOrder.deletedAt ||
      sellerOrder.status === ORDER_STATUS.CLOSED)

  const isGoToOrderButtonSellerVisible = !!sellerOrder.lastLiveAt 
  const isGoToOrderButtonBuyerVisible = !!buyerOrder.lastLiveAt 
    
  return (
    <>
      <Grid container>
        <OrderBlock loading={false}>
          <Grid item>
            <OrderSummary order={buyerOrder} isTermSheetSummary={isTermSheetSummary}/>
          </Grid>

          <Grid
            container
            alignItems="center"
            spacing={4}
            style={{ padding: spacing(1) }}
          >
            {isGoToOrderButtonBuyerVisible && (
              <Grid item>
                <GoToOrderButton
                  data-testid="go-to-order-buyer-button"
                  disabled={!userCanSeeBuyerOrder}
                  to={{
                    pathname: `/${BASE_SLUG}/orders/${buyerOrder.id}`,
                    state: {
                      referrer: `/${BASE_SLUG}/termsheets/${termsheet.id}`,
                    },
                  }}
                  text={buyerOrder.deletedAt ? 'deleted' : undefined}
                />
              </Grid>
            )}
          </Grid>
        </OrderBlock>

        <OrderBlock loading={false}>
          <Grid item>
            <OrderSummary order={sellerOrder} isTermSheetSummary={isTermSheetSummary}/>
          </Grid>

          <Grid
            container
            alignItems="center"
            spacing={4}
            style={{ padding: spacing(1) }}
          >
            {isGoToOrderButtonSellerVisible && (
              <Grid item>
                <GoToOrderButton
                  data-testid="go-to-order-seller-button"
                  disabled={!userCanSeeSellerOrder}
                  to={{
                    pathname: `/${BASE_SLUG}/orders/${sellerOrder.id}`,
                    state: {
                      referrer: `/${BASE_SLUG}/termsheets/${termsheet.id}`,
                    },
                  }}
                  text={sellerOrder.deletedAt ? 'deleted' : undefined}
                />
              </Grid>
            )}
          </Grid>
        </OrderBlock>
      </Grid>

      <section style={{ marginTop: spacing(5) }}>
        <EssentialPanel order={termsheet} isTermSheetSummary={isTermSheetSummary}/>
      </section>
      <section style={{ marginTop: spacing(2) }}>
        <OptionalPanel
          order={termsheet}
          isTermSheetSummary={isTermSheetSummary}
          // buyer={$O.stakeholder.code.text(buyerOrder || {})}
          // seller={$O.stakeholder.code.text(sellerOrder || {})}
        />
      </section>
    </>
  )
}

Information.propTypes = {
  termsheet: PropTypes.object.isRequired,
}

export default Information
