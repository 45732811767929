import React from 'react'
import { map, get } from 'lodash'

import { PRICE_VARIANT, PRICE_TYPE } from 'emsurge-selectors/constants'
import { PRICE_OPTIONS } from './helpers'
import {
  PaddedContainer,
  Button,
  StyledContainer,
  StyledConnectedSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { SelectableGridOption as Option } from 'components/SelectableGrid'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'

export const Price = () => {
  const floatingProps = {
    sizes: {
      percentage: 6,
      indexes: 6,
      plusOrMinus: 12,
      amount: 6,
      unit: 6,
    },
  }

  return (
    <PaddedContainer>
      <StyledConnectedSelectableGrid name={`price.${PRICE_VARIANT.BASIC}.type`}>
        {map(PRICE_OPTIONS, ({ label }, value) => (
          <Option
            data-testid={`price.basic.type-${value}`}
            key={`order-price-basic-${value}`}
            value={value}
          >
            <Button>{label}</Button>
          </Option>
        ))}
      </StyledConnectedSelectableGrid>

      <StyledContainer>
        <FormConsumer>
          {({ values }) => {
            const priceType = get(
              values,
              ['price', PRICE_VARIANT.BASIC, 'type'],
              null
            )
            const extraProps =
              priceType === PRICE_TYPE.FLOATING ? floatingProps : {}
            const { component: Component } = get(PRICE_OPTIONS, priceType, {
              component: null,
            })
            return <Component variant={PRICE_VARIANT.BASIC} {...extraProps} />
          }}
        </FormConsumer>
      </StyledContainer>
    </PaddedContainer>
  )
}
