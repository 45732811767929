import {
  ACTIVITY_TYPE_BULLETIN,
  ACTIVITY_TYPE_COMMENT,
  ACTIVITY_TYPE_NOTE,
  ACTIVITY_TYPE_ORDER,
  ACTIVITY_TYPE_TERMSHEET,
} from 'emsurge-selectors/constants'

export const isOrderType = (type) => {
  return Object.values(ACTIVITY_TYPE_ORDER).includes(type)
}

export const isCommentType = (type) => {
  return Object.values(ACTIVITY_TYPE_COMMENT).includes(type)
}

export const isNoteType = (type) => {
  return Object.values(ACTIVITY_TYPE_NOTE).includes(type)
}

export const isTermsheetType = (type) => {
  return Object.values(ACTIVITY_TYPE_TERMSHEET).includes(type)
}

export const isBulletinType = (type) => {
  return Object.values(ACTIVITY_TYPE_BULLETIN).includes(type)
}

export const isViewType = (type) => {
  return type.includes('_viewed')
}
