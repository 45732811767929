import moment from 'moment'

export const formatDate = (strDate) =>
  new Date(Date.parse(strDate)).toDateString()

export const convertDateToCurrentTimestamp = (date = new Date()) => {
  const offsetInMins = new Date().getTimezoneOffset()
  return moment(date).add(offsetInMins, 'minutes').toDate()
}

export const getUTCDate = ({ date, time, timezone }) =>
  moment(`${date}T${time}${timezone}`).utc()

export const getDateWithOffset = ({ date, time, timezone }) =>
  moment(`${date}T${time}Z`).utcOffset(timezone)

export const getFormattedDateWithOffset = (date) => {
  const momentDateWithTz = moment(date).parseZone()
  return momentDateWithTz.format('HH:mm (UTC Z) ddd DD/MM/YY')
}

export const getFormattedDate = (
  dateString,
  timezone = moment.tz.guess(),
  strFormat = 'HH:mm z (UTC Z) ddd DD/MM/YY'
) => {
  const formattedDate = moment(dateString)
  const formattedDateWithTz = formattedDate.tz(timezone)
  return formattedDateWithTz.format(strFormat)
}

export const getDateTime = () => moment().format('YYYY-MM-DDTHH:mmZ')

export const getDateTimeLocal = () => moment().format('YYYY-MM-DDTHH:mm')
