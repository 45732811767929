import { isEmpty } from 'lodash'
import {
  getInformationPaths,
  getStep1Paths,
  getStep2Paths,
  getStep3Paths,
  getStep4Paths,
  getStep5Paths,
} from './containers/informationInitialValues'
import {
  getNominationPaths,
  getNominationsStep1Paths,
  getNominationsStep2Paths,
  getNominationsStep3Paths,
  getNominationsStep4Paths,
  getNominationsStep5Paths,
} from './containers/NominationsInitialValues'
import { TAB } from 'containers/OrderProvider'
import {
  getErroneousPaths,
  getTouchedErrorsForFields,
} from 'screens/orderCreateCommon/helpers'

export const getInfoStep1Errors = (formik) =>
  getTouchedErrorsForFields(formik, getStep1Paths())
export const getInfoStep2Errors = (formik) =>
  getTouchedErrorsForFields(formik, getStep2Paths())
export const getInfoStep3Errors = (formik) =>
  getTouchedErrorsForFields(formik, getStep3Paths())
export const getInfoStep4Errors = (formik) =>
  getTouchedErrorsForFields(formik, getStep4Paths())
export const getInfoStep5Errors = (formik) =>
  getTouchedErrorsForFields(formik, getStep5Paths())
export const getInformationErrors = (formik) =>
  getTouchedErrorsForFields(formik, getInformationPaths())
export const getNominationsStep1Errors = (formik) =>
  getTouchedErrorsForFields(formik, getNominationsStep1Paths(formik))
export const getNominationsStep2Errors = (formik) =>
  getTouchedErrorsForFields(formik, getNominationsStep2Paths(formik))
export const getNominationsStep3Errors = (formik) =>
  getTouchedErrorsForFields(formik, getNominationsStep3Paths(formik))
export const getNominationsStep4Errors = (formik) =>
  getTouchedErrorsForFields(formik, getNominationsStep4Paths(formik))
export const getNominationsStep5Errors = (formik) =>
  getTouchedErrorsForFields(formik, getNominationsStep5Paths(formik))
export const getNominationErrors = (formik) =>
  getTouchedErrorsForFields(formik, getNominationPaths(formik))

export const getEarliestErrorStep = (formik) => {
  const informationSteps = [
    getStep1Paths(),
    getStep2Paths(),
    getStep3Paths(),
    getStep4Paths(),
    getStep5Paths(),
  ]

  for (let step = 0; step < informationSteps.length; step += 1) {
    const errorPaths = getErroneousPaths(formik.errors, informationSteps[step])

    if (!isEmpty(errorPaths)) {
      return { tab: TAB.OVERVIEW, step }
    }
  }

  const nominationsSteps = [
    getNominationsStep1Paths(formik),
    getNominationsStep2Paths(formik),
    getNominationsStep3Paths(formik),
    getNominationsStep4Paths(formik),
    getNominationsStep5Paths(formik),
  ]

  for (let step = 0; step < nominationsSteps.length; step++) {
    const errorPaths = getErroneousPaths(formik.errors, nominationsSteps[step])

    if (!isEmpty(errorPaths)) {
      return { tab: TAB.TERMS, step }
    }
  }

  return {}
}
