import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import {
  DELIVERY_PERIOD_VARIABLE_YEAR,
  DELIVERY_PERIOD_VARIABLE_YEAR_TEXT,
} from 'emsurge-selectors/constants'
import { Grid, MenuItem } from '@material-ui/core'
import { some, get } from 'lodash'
import { SelectDeliveryPeriod } from './SelectDeliveryPeriod'
import { SelectDeliveryYear } from 'screens/orderCreateCommon/components/SelectDeliveryYear'
import { TextField } from 'screens/orderCreateCommon/components/TextField'
import { PERIOD } from 'model/order/constants/delivery'

const isDisabledType = (period) =>
  Object.values(DELIVERY_PERIOD_VARIABLE_YEAR).includes(period)

const getPriceDeliveryOptions = (options) => [
  ...options.filter(({ value }) => value !== PERIOD.CUSTOM),
  {
    value: DELIVERY_PERIOD_VARIABLE_YEAR.M1,
    label: DELIVERY_PERIOD_VARIABLE_YEAR_TEXT[DELIVERY_PERIOD_VARIABLE_YEAR.M1],
  },
  {
    value: DELIVERY_PERIOD_VARIABLE_YEAR.M,
    label: DELIVERY_PERIOD_VARIABLE_YEAR_TEXT[DELIVERY_PERIOD_VARIABLE_YEAR.M],
  },
]

const _PriceDeliveryContractFields = ({
  formik: { values, setFieldValue },
  rootPath,
}) => {
  const deliveryPeriodPath = `${rootPath}.delivery.period`
  const deliveryYearPath = `${rootPath}.delivery.year`
  const deliveryPeriod = get(values, deliveryPeriodPath, '')

  const onPeriodChange = (evt) => {
    const period = evt.target.value

    if (isDisabledType(period)) {
      setFieldValue(`${rootPath}.delivery.year`, undefined)
    }
  }

  return (
    <>
      <SelectDeliveryPeriod>
        {({ options }) => {
          const priceDeliveryPeriods = getPriceDeliveryOptions(options)
          const isValidPricePeriod = (period) =>
            some(priceDeliveryPeriods, ({ value }) => value === period)

          return (
            <Grid item xs>
              <TextField
                select
                name={deliveryPeriodPath}
                defaultValue={
                  isValidPricePeriod(values.delivery?.period)
                    ? values.delivery.period
                    : priceDeliveryPeriods[0].value
                }
                onChange={onPeriodChange}
                label="Period"
              >
                {priceDeliveryPeriods.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )
        }}
      </SelectDeliveryPeriod>
      <SelectDeliveryYear
        name={deliveryYearPath}
        defaultValue={
          isDisabledType(deliveryPeriod) ? undefined : values.delivery?.year
        }
        disabled={isDisabledType(deliveryPeriod)}
      />
    </>
  )
}

_PriceDeliveryContractFields.propTypes = {
  formik: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
}

export const PriceDeliveryContractFields = connect(_PriceDeliveryContractFields)
