const getCargoSizeVariance = (order) => {
  const cargoSize = order.cargoSize || {}
  const variance = {}

  if (cargoSize.varianceMinus !== undefined) {
    variance.minus = parseFloat(cargoSize.varianceMinus)
  }

  if (cargoSize.variancePlus !== undefined) {
    variance.plus = parseFloat(cargoSize.variancePlus)
  }

  return variance
}

const getCargoSizeVarianceSummary = (order) => {
  const variance = getCargoSizeVariance(order)
  const varianceSummary = {}

  if (variance.minus) {
    varianceSummary.minus = `-${variance.minus}%`
  }

  if (variance.plus) {
    varianceSummary.plus = `+${variance.plus}%`
  }

  return varianceSummary
}

const getCargoVarianceText = (order) => {
  const variance = getCargoSizeVariance(order)
  const varianceSummary = getCargoSizeVarianceSummary(order)

  if (!Object.values(variance).length) {
    return ''
  }

  if (variance.plus === variance.minus) {
    return `-/+${variance.plus}%`
  }

  let varianceText = ''

  if (variance.minus) {
    varianceText += `${varianceSummary.minus}`
  }

  if (variance.plus) {
    varianceText += `${variance.minus ? '/' : ''}${varianceSummary.plus}`
  }

  return varianceText
}

module.exports = {
  get: getCargoSizeVariance,
  summary: getCargoSizeVarianceSummary,
  text: getCargoVarianceText,
}
