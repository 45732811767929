import React, { memo } from 'react'
import PropTypes from 'prop-types'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import useAppContext from 'containers/appContext/useAppContext'

const AppBar = styled(MuiAppBar)`
background-color: ${({ theme, $appContext }) => $appContext === APP_CONTEXT_TYPE.LNG ? theme.palette.background.secondary : theme.palette.carbon.navbar.background};
z-index: ${({ theme }) => theme.zIndex.drawer + 100};
`

const useToolbarStyles = (appContext) => makeStyles((theme) => ({
  gutters: {
    paddingLeft: theme.spacing(0.5),
    color: appContext === APP_CONTEXT_TYPE.LNG ? 'white' : theme.palette.carbon.navbar.text
  },
}))

const Navbar = ({ children }) => {
  const { appContext } = useAppContext()
  const toolbarClasses = useToolbarStyles(appContext)()

  // The custom prop appContext needs to be prefixed with $, otherwise we get a warning from React saying it's not a
  // valid prop of AppBar.
  // See https://gist.github.com/meddokss/009cdb5192e487654c0d90cd8f692214
  return (
    <AppBar position="fixed" color="default" $appContext={appContext}>
      <Toolbar classes={{ gutters: toolbarClasses.gutters }}>{children}</Toolbar>
    </AppBar>
  )
}

Navbar.propTypes = {
  children: PropTypes.node,
}

export default memo(Navbar)
