import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isObject } from 'lodash'
import { withSnackbar } from 'notistack'
import SaveIcon from '@material-ui/icons/SaveAlt'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from 'components/iconButton/IconButton'
import { useApi } from 'containers/api/useApi'

/* :: (object, string, string) -> Promise<void> */
export const downloadFile = async (api, path, filename) => {
  try {
    const options = { responseType: 'arraybuffer' }
    const { data } = await api.get(path, options)
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename

    document.body.appendChild(link)

    link.click()
    link.remove()
  } catch (e) {
    console.error(e)
    throw e
  }
}

const ACCOUNT_MENU_ID = 'appbar-export-menu'

const ExportMenu = ({ enqueueSnackbar, style }) => {
  const api = useApi()
  const [buttonElem, setElement] = useState()
  const isOpen = isObject(buttonElem)

  const closeMenu = () => setElement(null)

  const getExportHandler = (resource) => async () => {
    const currentDate = new Date()
    const filename = `Emsurge_${resource}_${currentDate.toISOString()}.xlsx`

    try {
      await downloadFile(api, `/${resource}/export`, filename)
      closeMenu()
    } catch (e) {
      enqueueSnackbar(`Error downloading ${resource} excel file`, {
        variant: 'error',
      })
    }
  }

  const exportOrders = getExportHandler('orders')
  const exportNotes = getExportHandler('notes')
  const exportNominations = getExportHandler('nominations')

  return (
    <>
      <IconButton
        aria-owns={isOpen ? ACCOUNT_MENU_ID : undefined}
        aria-haspopup="true"
        icon={SaveIcon}
        size="small"
        onClick={(e) => setElement(e.currentTarget)}
        style={style}
        data-testid="export-button"
      >
        Export
      </IconButton>

      <Menu
        id={ACCOUNT_MENU_ID}
        anchorEl={buttonElem}
        open={isOpen}
        onClose={closeMenu}
      >
        <MenuItem data-testid="export-orders" onClick={exportOrders}>
          Orders
        </MenuItem>
        <MenuItem data-testid="export-notes" onClick={exportNotes}>
          Notes
        </MenuItem>
        <MenuItem data-testid="export-nominations" onClick={exportNominations}>
          Nominations
        </MenuItem>
      </Menu>
    </>
  )
}

ExportMenu.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  style: PropTypes.object,
}

export default withSnackbar(ExportMenu)
