import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Form } from 'formik'
import { SubmitButton, LinkButton } from './components/Buttons'
import { EmailField, PasswordField } from './components/SignInInputs'
import FormProvider from './containers/FormProvider'
import { getRedirectLink } from './getRedirectLink'
import { NEW_PASSWORD_REQUEST } from 'routes'
import { hasValidSession, isLoggedIn } from 'containers/user/helpers'
import { useUser } from 'containers/user/useUser'
import { LoggedOutView } from 'components/LoggedOutView'

export const SignIn = () => {
  const { user } = useUser()
  const location = useLocation()

  if (isLoggedIn(user) && hasValidSession(user)) {
    return <Redirect to={location.state?.referrer || getRedirectLink(user)} />
  }

  return (
    <LoggedOutView>
      <FormProvider>
        <Form>
          <EmailField data-testid="email-field" name="email" label="Email" />
          <PasswordField data-testid="password-field" name="password" />
          <SubmitButton data-testid="submit-button">Enter</SubmitButton>
        </Form>
      </FormProvider>

      <LinkButton
        data-testid="forgot-password-button"
        to={NEW_PASSWORD_REQUEST}
        variant="contained"
        color="primary"
        style={{ backgroundColor: '#19222f' }}
      >
        Forgot Password?
      </LinkButton>
    </LoggedOutView>
  )
}
