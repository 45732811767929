import { ACTIONS } from './DateTime.actions'
import { getUTCDate, getDateWithOffset } from 'utils/dates'

const { SET_FULL_DATE, UPDATE_DATE, UPDATE_TIME, UPDATE_TIMEZONE } = ACTIONS

export const computeState = ({ date, time, timezone }) => {
  const momentUTC = getUTCDate({ date, time, timezone })

  return {
    datePlaceholder: momentUTC.format('YYYY-MM-DD'),
    timePlaceholder: momentUTC.format('HH:mm'),
    date,
    time,
    timezone,
  }
}

export const reducer = (state, action) => {
  let momentUTC
  let momentWithOffset

  switch (action.type) {
    case SET_FULL_DATE:
      return computeState({
        date: action.date,
        time: action.time,
        timezone: action.timezone,
      })
    case UPDATE_DATE:
      momentUTC = getUTCDate({
        date: action.value,
        time: state.time,
        timezone: state.timezone,
      })
      return {
        ...state,
        datePlaceholder: momentUTC.format('YYYY-MM-DD'),
        date: action.value,
      }
    case UPDATE_TIME:
      momentUTC = getUTCDate({
        date: state.date,
        time: action.value,
        timezone: state.timezone,
      })
      return {
        ...state,
        timePlaceholder: momentUTC.format('HH:mm'),
        time: action.value,
      }
    case UPDATE_TIMEZONE:
      momentWithOffset = getDateWithOffset({
        date: state.datePlaceholder,
        time: state.timePlaceholder,
        timezone: action.value,
      })
      return {
        ...state,
        timezone: action.value,
        time: momentWithOffset.format('HH:mm'),
        date: momentWithOffset.format('YYYY-MM-DD'),
      }
    default:
      return state
  }
}
