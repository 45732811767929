import moment from 'moment'
import { PARTY_TEXT } from 'emsurge-selectors/constants'

export const mapToCommentView = (comment) => {
  const { author, createdAt, text } = comment

  const parsedAuthor = author.map((a) => PARTY_TEXT[a] || a).join('/')

  return {
    author: parsedAuthor,
    createdAt: moment(createdAt).format('Do MMM YYYY ddd HH:mm'),
    text,
  }
}
