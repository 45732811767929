import React from 'react'
import PropTypes from 'prop-types'
import { merge } from 'lodash'
import { useTheme } from '@material-ui/styles'
import { buildStyles, buildTradingTypeStyles } from './MarketInterest/utils'
import { DeliveryInfoSmall } from 'components/DeliveryInfo'
import { DeliveryContractSmall } from 'components/DeliveryContract'
import { Container } from 'components/Card'
import SwapSymbol from 'screens/orderView/components/SwapSymbol'

export const SwapLegContainer = ({ order, size, style }) => {
  const { spacing } = useTheme()
  const styles = merge(
    buildStyles(spacing(1)),
    buildTradingTypeStyles(spacing(1))[order.tradingType]
  )

  return order.swapOrder ? (
    <div style={{ ...styles.swapLegContainer, ...style }}>
      <Container
        style={{ ...styles.deliveryInformationContainer, flexWrap: 'nowrap' }}
      >
        <DeliveryInfoSmall
          data-testid="swap-leg-container-delivery-info"
          order={order.swapOrder}
          style={styles.deliveryInformationItem}
        />
        <DeliveryContractSmall
          data-testid="swap-leg-container-contract"
          order={order.swapOrder}
          style={styles.deliveryInformationItem}
        />
      </Container>
      <SwapSymbol data-testid="swap-leg-container-symbol" size={size} />
    </div>
  ) : (
    <></>
  )
}

SwapLegContainer.propTypes = {
  style: PropTypes.object,
  size: PropTypes.number,
  order: PropTypes.object.isRequired,
}
