import { number, date, object, string } from 'yup'
import {
  isUndefinedTimeFrameValue,
  isFixedDateTimeFrameValue,
  isMonthAheadTimeFrameValue,
} from '../containers/NominationsInitialValues'

const getTimeFrameValueSchema = (timeframe) => {
  if (isUndefinedTimeFrameValue({ timeframe })) {
    return number()
  }

  if (isFixedDateTimeFrameValue({ timeframe })) {
    return date().typeError('Invalid date').required('Date is required')
  }

  if (isMonthAheadTimeFrameValue({ timeframe })) {
    return number()
      .typeError('Invalid day number')
      .min(1, 'Invalid day number')
      .max(31, 'Invalid month day')
      .required('Required')
  }

  return number().typeError('Invalid number').required('Required')
}

const TIME_FORMAT = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/

const getTimeFrameValueTimeSchema = (timeframe) => {
  if (isFixedDateTimeFrameValue({ timeframe })) {
    return string()
      .matches(TIME_FORMAT, 'Invalid time')
      .required('Time is required')
  }

  return undefined
}

const getTimeFrameSchema = ({ skipToBeNominated } = {}) =>
  object().shape({
    timeframeValue: number().when(
      ['timeframe', 'toBeNominated'],
      (timeframe, toBeNominated) => {
        if (!toBeNominated && !skipToBeNominated) {
          return number()
        }

        return getTimeFrameValueSchema(timeframe)
      }
    ),
    timeframeValueTime: date().when(
      ['timeframe', 'toBeNominated'],
      (timeframe, toBeNominated) => {
        if (
          (!toBeNominated && !skipToBeNominated) ||
          !isFixedDateTimeFrameValue({ timeframe })
        ) {
          return string()
        }

        return getTimeFrameValueTimeSchema(timeframe)
      }
    ),
  })

export { getTimeFrameSchema }
