import React from 'react'
import PropTypes from 'prop-types'

import { parseOptions } from './helpers'
import { ConnectedSelect } from 'components/Select'
import {
  Button,
  StyledContainer,
  StyledConnectedSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { SelectableGridOption as Option } from 'components/SelectableGrid'
import { CargoCardDateFields } from 'screens/orderCreate/components/CargoCardDateFields'

export const CargoCard = ({
  options,
  disabled,
  onChangeDeliveryWindow,
  onChangeDate,
}) => {
  const { mainOptions, otherOptions } = parseOptions(options)
  return (
    <>
      <StyledConnectedSelectableGrid
        name="nominationRules.deliveryWindow.cargos[0].cargoWindow"
        onChange={(value) => onChangeDeliveryWindow(value)}
      >
        {mainOptions.map(({ value, xs }) => (
          <Option key={`order-delivery-window-${value}`} xs={xs} value={value}>
            <Button>{value}</Button>
          </Option>
        ))}
      </StyledConnectedSelectableGrid>

      <StyledContainer spacing={2}>
        <ConnectedSelect
          onChange={(value) => onChangeDeliveryWindow(value)}
          name="nominationRules.deliveryWindow.cargos[0].cargoWindow"
          options={otherOptions}
          placeholder={<option value={mainOptions[0].value}>OTHER</option>}
        />
      </StyledContainer>

      <StyledContainer>
        <CargoCardDateFields
          spacing={1}
          xs={6}
          index={0}
          onChangeDate={onChangeDate}
          disabled={disabled}
        />
      </StyledContainer>
    </>
  )
}

CargoCard.defaultProps = {
  options: [],
  disabled: false,
  onChangeDeliveryWindow: () => {},
  onChangeDate: () => {},
}

CargoCard.propTypes = {
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChangeDeliveryWindow: PropTypes.func,
  onChangeDate: PropTypes.func,
}
