import {
  groupBy,
  isNil,
  mapValues,
  sortBy,
  get,
  isEmpty,
  capitalize,
} from 'lodash'
import moment from 'moment'
import { PARTY_TEXT, TRADING_TYPE } from 'emsurge-selectors'
import { getEntityCode } from 'screens/transactionIndex/helpers'
import { getVesselType } from 'screens/orderView/helpers'
import { formatDate } from 'screens/orderCreate/components/DeliveryWindow.helpers'
import { isFOBType } from 'screens/orderCreate/helpers'

const getOverviewLabel = ({ type, location }) => {
  return `${type} ${location}`
}

const getNominationTypeLabel = ({ type, name, delivery }) => {
  if (type === 'deliveryWindow') {
    const days = parseInt(name, 10)
    return `Narrow DW to ${days} day${days > 1 ? 's' : ''}`
  }

  if (type === 'other') {
    return isEmpty(name) ? 'Other nomination' : capitalize(name)
  }

  if (type === 'ship') {
    return 'LNG Ship'
  }

  if (type === 'dischargePort') {
    return !isFOBType(delivery.type)
      ? 'Delivery Port (Discharge Port)'
      : 'Discharge Port'
  }

  if (type === 'loadPort') {
    return isFOBType(delivery.type) ? 'Delivery Port (Load Port)' : 'Load Port'
  }
}

export const getTableData = (nominations) => {
  const orderCargoMap = groupBy(nominations, (n) => [n.orderId, n.cargo])
  const orderCargoTimezoneNominationMap = mapValues(
    orderCargoMap,
    (cargoNominations) => cargoNominations.find((n) => !isNil(n.timezone))
  )
  const orderCargoTimezoneMap = mapValues(
    orderCargoTimezoneNominationMap,
    (nomination) => get(nomination, 'timezone', null)
  )

  const tableData = nominations.map(
    ({
      shortId,
      delivery,
      type,
      party,
      nominateUntil,
      id,
      orderId,
      orders,
      cargo,
      updatedAt,
      timezone,
      termsheetTimezone,
      name,
      vesselType,
    }) => {
      const buyer = orders.find(
        (order) => order.tradingType === TRADING_TYPE.BID
      )
      const seller = orders.find(
        (order) => order.tradingType === TRADING_TYPE.BID
      )
      const sizeType = type === 'ship' ? getVesselType(vesselType) : ''

      const nominationTimezone =
        timezone ||
        orderCargoTimezoneMap[`${orderId},${cargo}`] ||
        termsheetTimezone

      const userOffsetInMins = new Date().getTimezoneOffset()
      const nominateBy = moment(nominateUntil)
        .subtract(nominationTimezone, 'hours')
        .add(userOffsetInMins, 'minutes')
        .format()

      return {
        nominationId: id,
        termsheetId: orderId,
        termsheetShortId: shortId,
        tradeDate: formatDate(moment(updatedAt)),
        overview: getOverviewLabel(delivery),
        buyer: buyer && getEntityCode(buyer),
        seller: seller && getEntityCode(seller),
        cargo: cargo + 1,
        orderId: shortId,
        party: PARTY_TEXT[party],
        vesselType: sizeType,
        nominationName: type,
        nominationType: getNominationTypeLabel({ type, name, delivery }),
        nominateBy,
        timezone,
        otherName: type === 'other' ? name : null,
      }
    }
  )

  return sortBy(tableData, (nomination) =>
    moment(nomination.nominateBy)
  ).reverse()
}
