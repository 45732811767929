import React from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@material-ui/core/TextField'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import { connect } from 'formik'
import TimeFrameValue from './TimeFrameValue'
import { getTimeFrameOptions } from './TimeFrameHelpers'
import { getDefaultTimeFrameValue } from './TimeFrameValueHelpers'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import FormikRadioInput from 'containers/FormikRadioInput/FormikRadioInput'

const TimeFrame = ({
  label,
  mainName,
  timeframe,
  formik,
  disabled,
  alternativeDeliveryWindow,
  alternativeDeliveryWindowIndex,
  ...props
}) => (
  <>
    <Grid container spacing={0} alignItems="center">
      <Grid item xs={10}>
        <FormControl fullWidth>
          <FormLabel>{label}</FormLabel>
          <FormikRadioInput
            onChange={(ev) => {
              const defaultValue = getDefaultTimeFrameValue(ev.target.value)
              formik.setFieldValue(`${mainName}.timeframeValue`, defaultValue)
            }}
            radiosWrapperStyle={{ flexDirection: 'column' }}
            {...props}
            name={`${mainName}.timeframe`}
            options={getTimeFrameOptions({ disabled })}
          />
        </FormControl>
      </Grid>
    </Grid>
    <TimeFrameValue
      alternativeDeliveryWindow={alternativeDeliveryWindow}
      alternativeDeliveryWindowIndex={alternativeDeliveryWindowIndex}
      mainName={mainName}
      timeframe={timeframe}
      disabled={disabled}
    />
  </>
)

TimeFrame.propTypes = {
  ...MuiTextField.propTypes,
  label: PropTypes.string,
  mainName: PropTypes.string.isRequired,
  timeframe: PropTypes.string,
  formik: PropTypes.object.isRequired,
  alternativeDeliveryWindow: PropTypes.bool,
  alternativeDeliveryWindowIndex: PropTypes.number,
}

TimeFrame.defaultProps = {
  label: 'Time Frame',
}

export default connect(TimeFrame)
