import React from 'react'
import { connect } from 'formik'
import { STAKEHOLDER_TYPE } from 'emsurge-selectors'
import { OrderSummary as OrderSummaryLNG } from 'components/OrderSummary'
import { OrderSummary as OrderSummaryCarbon } from 'components/OrderSummaryCarbon'
import { useUser } from 'containers/user/useUser'
import useAppContext from 'containers/appContext/useAppContext'
import { getOrderInfo as getOrderInfoLNG } from 'screens/orderCreate/containers/FormProvider'
import { getOrderInfo as getOrderInfoCarbon } from 'screens/orderCreateCarbon/FormProvider'

const getOrderUsingFormForTrader = (formValues, user, isLNGContext) => {
  const getOrderInfo = isLNGContext ? getOrderInfoLNG : getOrderInfoCarbon
  const parameters = [formValues, user, ...(isLNGContext ? [] : [true])]
  const order = {
    ...getOrderInfo(...parameters),
    orderBeenTradedTradingType: formValues.orderBeenTradedTradingType && formValues.orderBeenTradedTradingType.toLowerCase()
  }

  order.person = formValues.person

  if (formValues.behalfOf === STAKEHOLDER_TYPE.ON_SYSTEM) {
    order.company = user.company
    order.entity = user.entities.find(
      (e) => e.id === formValues.myCompany.entityId
    )
  }

  return order
}

export const TraderOrderSummary = connect(({ formik, isCreatingTrade }) => {
  const { user } = useUser()
  const { isLNGContext } = useAppContext()

  const order = getOrderUsingFormForTrader(formik.values, user, isLNGContext)

  const OrderSummary = isLNGContext ? OrderSummaryLNG : OrderSummaryCarbon
  return <OrderSummary order={order} isCreatingTrade={isCreatingTrade}/>
})
