const map = require('lodash/map')

const QUALITY_TYPE = {
  CUSTOM: 'custom',
  OVER_1040: '1040+',
  OVER_1050: '1050+',
  RICH: 'rich',
  LEAN: 'lean',
  FLEXIBLE: 'flexible',
}

const QUALITY_TYPE_TEXT = {
  [QUALITY_TYPE.CUSTOM]: 'Custom',
  [QUALITY_TYPE.OVER_1040]: '1040+',
  [QUALITY_TYPE.OVER_1050]: '1050+',
  [QUALITY_TYPE.RICH]: 'Rich',
  [QUALITY_TYPE.LEAN]: 'Lean',
  [QUALITY_TYPE.FLEXIBLE]: 'Flexible',
}

const QUALITY_TYPE_OPTIONS = map(QUALITY_TYPE_TEXT, (label, value) => ({
  value,
  label,
}))

const QUALITY_TYPE_UNIT = {
  BTU_SCF: 'Btu/scf',
}

module.exports = {
  QUALITY_TYPE,
  QUALITY_TYPE_TEXT,
  QUALITY_TYPE_OPTIONS,
  QUALITY_TYPE_UNIT,
}
