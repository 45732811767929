const COMPANY_INFORMATION_INITIAL_VALUES = {
  name: '',
  parentCompany: '',
  registrationNumber: '',
  addressFirstLine: '',
  addressSecondLine: '',
  postcode: '',
  city: '',
  country: '',
  type: 'trader',
}

export { COMPANY_INFORMATION_INITIAL_VALUES }
