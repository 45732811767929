import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { capitalize, flatten, noop, values } from 'lodash'
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import {
  PRICE_INDEX_OPTIONS_BY_GROUP,
  PRICE_UNIT,
  PRICE_UNIT_LABEL,
} from 'emsurge-selectors'

const MenuItemOpaque = styled(MenuItem)`
  opacity: 0.5;
`

const Filter = styled(Select)`
  min-width: ${({ theme }) => theme.spacing(15)}px;
  max-width: 200px;
  ${(props) => !props.selected ?? 'opacity: 0.5;'}
`

const OPTIONS = flatten(PRICE_INDEX_OPTIONS_BY_GROUP)

const ALL = 'all'
const NONE = 'none'

export const Filters = ({ values: filters, onChange }) => {
  const [toggle, setToggle] = useState(ALL)
  const ref = useRef()

  const updateIndexes = (evt) => {
    const indexes = evt?.target?.value || []

    if (indexes.includes(ALL)) {
      onChange({
        ...filters,
        indexes: OPTIONS.map(({ value }) => value),
      })
      setToggle(NONE)
      setTimeout(() => {
        ref.current.scrollIntoView() // forces first option to scroll into view
      }, 0)
      return
    }

    if (indexes.includes(NONE)) {
      onChange({
        ...filters,
        indexes: [],
      })
      setToggle(ALL)
      return
    }

    onChange({
      ...filters,
      indexes,
    })
  }

  const updateUnit = (evt) =>
    onChange({
      ...filters,
      unit: evt?.target?.value,
    })

  return (
    <Grid item container alignItems="center" spacing={2}>
      <Grid item>
        <FormControl>
          <InputLabel id="price-index-input">Index</InputLabel>
          <Filter
            multiple
            labelId="price-index-input"
            name="price-index"
            placeholder="Index"
            value={filters?.indexes}
            renderValue={(selected) =>
              OPTIONS.filter((option) => selected?.includes(option.value))
                .map(({ name }) => name)
                .join(', ')
            }
            onChange={updateIndexes}
          >
            <MenuItemOpaque ref={ref}>Index</MenuItemOpaque>
            <MenuItem value={toggle}>{`Select ${capitalize(toggle)}`}</MenuItem>
            {OPTIONS.map(({ name, value }) => (
              <MenuItem key={`price-index-${value}`} value={value}>
                <Checkbox checked={filters?.indexes?.includes(value)} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Filter>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl>
          <InputLabel id="price-unit-input">Unit</InputLabel>
          <Filter
            labelId="price-unit-input"
            name="price-unit"
            placeholder="Unit"
            value={filters.unit}
            onChange={updateUnit}
          >
            <MenuItem style={{ opacity: 0.5 }}>Unit</MenuItem>
            {values(PRICE_UNIT).map((value) => (
              <MenuItem key={`price-unit-${value}`} value={value}>
                {PRICE_UNIT_LABEL[value]}
              </MenuItem>
            ))}
          </Filter>
        </FormControl>
      </Grid>
    </Grid>
  )
}

Filters.defaultProps = {
  values: [
    {
      indexes: [],
    },
  ],
  onChange: noop,
}

Filters.propTypes = {
  values: PropTypes.shape({
    indexes: PropTypes.array,
    unit: PropTypes.string,
  }),
  onChange: PropTypes.func,
}
