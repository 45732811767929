import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'

import {
  CARGO_SIZE_TYPE,
  CARGO_SIZE_UNIT,
  CARGO_SIZE_UNIT_TEXT,
} from 'emsurge-selectors/constants'
import FormRow from 'components/formRow/FormRow'
import { TextField } from 'screens/orderCreateCommon/components/TextField'

export const getCargoSizeDescription = (cargoSizeUnit) => {
  const sizeUnitLabel = `(${CARGO_SIZE_UNIT_TEXT[cargoSizeUnit]})`

  return cargoSizeUnit === CARGO_SIZE_UNIT.TBTU
    ? `Size ${sizeUnitLabel}`
    : `Quantity ${sizeUnitLabel}`
}

const _CargoSizeFields = ({ formik }) => {
  const { unit, type } = formik.values.cargoSize

  return (
    <>
      {type === CARGO_SIZE_TYPE.SPECIFIC && (
        <TextField
          inputProps={{ 'data-testid': 'cargo-size-min' }}
          type="number"
          name="cargoSize.sizeMin"
          label={getCargoSizeDescription(unit)}
        />
      )}
      {type === CARGO_SIZE_TYPE.CUSTOM && (
        <FormRow>
          <TextField
            inputProps={{ 'data-testid': 'cargo-size-min' }}
            type="number"
            name="cargoSize.sizeMin"
            label="Min"
          />
          <TextField
            inputProps={{ 'data-testid': 'cargo-size-max' }}
            type="number"
            name="cargoSize.sizeMax"
            label="Max"
          />
        </FormRow>
      )}
    </>
  )
}

_CargoSizeFields.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const CargoSizeFields = connect(_CargoSizeFields)
