import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { makeStyles } from '@material-ui/styles'
import Done from '@material-ui/icons/Done'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import StepIcon from '@material-ui/core/StepIcon'
import { PRIORITIES } from '../constants'
import PriorityLabel from './PriorityLabel'
import SubmitButton from 'containers/formikSubmitButton/FormikSubmitButton'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    margin: '0 10px -5px 20px',
  },
})

const FormHeader = ({
  formik: {
    values: { priority, actionItems = [] },
  },
  onCancel,
}) => {
  const classes = useStyles()
  const checkedActionItemsLength = actionItems.filter(
    ({ isChecked }) => isChecked
  ).length

  return (
    <Grid container justifyContent="space-between">
      <Text variant="h2" gutterBottom>
        NOTE
        <PriorityLabel
          data-testid="note-priority-label"
          label={PRIORITIES[priority]}
          beforeText="Priority"
          containerStyle={{
            display: 'inline-flex',
            marginLeft: '20px',
            fontSize: '1rem',
            fontWeight: 400,
          }}
        />
        {Boolean(actionItems.length) && (
          <>
            <StepIcon icon="!" classes={classes} active />
            <span
              style={{
                fontSize: '1rem',
                fontWeight: 400,
              }}
            >
              {`${checkedActionItemsLength}/${actionItems.length}`}
            </span>
          </>
        )}
      </Text>

      <Grid item>
        <Grid container alignItems="center">
          <SubmitButton icon={Done}>Save</SubmitButton>
          {onCancel ? <CloseButton onClick={onCancel} /> : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

FormHeader.propTypes = {
  formik: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
}

export default connect(FormHeader)
