import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { DELIVERY_TYPES } from 'model/order/constants/cargo'

const _DischargePortModel = ({
  formik: { values, setFieldValue },
  children,
}) => {
  const isFOB = values.delivery.type === DELIVERY_TYPES.FOB

  const isNA = isFOB && values.nominationRules.dischargePort.na

  const disableToBeNominationFields = (toBeNominated) => (fieldName) =>
    (['party', 'timeframe', 'timeframeValue'].includes(fieldName) &&
      !toBeNominated) ||
    (isFOB && fieldName === 'toBeNominated' && isNA)

  const shouldDisableField =
    ({ toBeNominated, isNA }) =>
    (fieldName) =>
      disableToBeNominationFields(toBeNominated)(fieldName) || isNA

  const setPartyDefaultValue = useCallback(
    (name) => setFieldValue(name, 'bid'),
    [setFieldValue]
  )

  return children({
    isFOB,
    isNA,
    shouldDisableField,
    setPartyDefaultValue,
  })
}

_DischargePortModel.propTypes = {
  formik: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
}

export const DischargePortModel = connect(_DischargePortModel)
