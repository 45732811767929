import { merge } from 'lodash'
import { STAKEHOLDER_TYPE, TRADING_TYPE } from 'emsurge-selectors'
import {
  getStep2,
  getStep3,
  getStep4,
  getStep5,
  getValidity,
} from 'screens/orderEdit/containers/getInformationInitialValues'
import { $O } from 'model/order/selectors/selector'

const getBehalfOfInitialValue = (order, user) => {
  if (
    $O.author.isFromMyCompany(order, user) ||
    $O.stakeholder.isOnSystem(order)
  ) {
    return STAKEHOLDER_TYPE.ON_SYSTEM
  }

  return STAKEHOLDER_TYPE.OFF_SYSTEM
}

const getPartyDefaultInformation = (order) =>
{
  let result

  if(order.thirdParty?.companyName)
  {
    result = {
      company : {
        name: order.thirdParty.companyName, 
      },
      entity : {
        name: order.thirdParty.entityName,
      },
      person : {
        name: order.thirdParty.contact,
      },
      isCompanyDisabled : true
    }
  }
  else{
    result = {
      company : {
        id: order.company.id, 
        name: order.company.name
      },
      entity : {
        id: order.entity.id,
        name: order.entity.name,
      },
      person : {
        id: order.person?.id,
        name: order.person?.name
      },
      isCompanyDisabled : true
    }
  }
  return result
}

const getStep1 = (order, user) => {

  const partyDefaultInformation = getPartyDefaultInformation(order)
  const buyerInformationDefaultValues = order.tradingType == TRADING_TYPE.BID ? partyDefaultInformation : {}
  const sellerInformationDefaultValues = order.tradingType == TRADING_TYPE.ASK ? partyDefaultInformation : {}

  const behalfOf = getBehalfOfInitialValue(order, user)

  const thirdParty = behalfOf === STAKEHOLDER_TYPE.OFF_SYSTEM ? {
    companyName: '',
    entityName: '',
    contact: '',
  } : {}

  const stepValues = {
    behalfOf: behalfOf,
    myCompany: {
      entityId: '',
    },
    thirdParty,
    tradingType: TRADING_TYPE.ASK, //To force all the control to have the visual behavior of an ASK flow when creating a Trade
    orderBeenTradedTradingType: order.tradingType, //This information is required to autocomplete de Seller or Buyer information in the Create New Order flow
    details: '',
    validity: getValidity(order),
    buyer: buyerInformationDefaultValues,
    seller: sellerInformationDefaultValues
  }

  return stepValues
}

export const getInformationInitialValues = (order, user) => {
  const initialValues = merge(
    {},
    order,
    getStep1(order, user),
    getStep2(order),
    getStep3(order),
    getStep4(order),
    getStep5(order)
  )

  return initialValues
}
