import PropTypes from 'prop-types'
import { TRADING_TYPE, TRADING_TYPE_TEXT } from 'emsurge-selectors/constants'

import {
  useOrderFlow,
  ORDER_FLOW,
  useOrderState,
} from 'containers/OrderProvider'

export const OrderType = ({ children }) => {
  const { flow } = useOrderFlow()
  const {
    orderState: { hasTermsheets },
  } = useOrderState()
  const isCreatingSwapOrder = flow === ORDER_FLOW.SWAP
  const isCreatingCounterOrder = flow === ORDER_FLOW.COUNTER
  const disabled =
    (hasTermsheets && flow === ORDER_FLOW.EDIT) ||
    isCreatingSwapOrder ||
    isCreatingCounterOrder

  const options = [
    { value: TRADING_TYPE.BID, label: TRADING_TYPE_TEXT.BID, disabled },
    { value: TRADING_TYPE.ASK, label: TRADING_TYPE_TEXT.ASK, disabled },
  ]

  return children(options)
}

OrderType.propTypes = {
  children: PropTypes.func,
}
