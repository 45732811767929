import { ENTITY_TYPE } from 'emsurge-selectors/constants'

const GENERAL_INFORMATION_INITIAL_VALUES = {
  name: '',
  registrationName: '',
  registrationNumber: '',
  addressFirstLine: '',
  addressSecondLine: '',
  postcode: '',
  city: '',
  country: '',
  type: ENTITY_TYPE.TRADER,
}

export { GENERAL_INFORMATION_INITIAL_VALUES }
