import { swapLeg } from './swapLeg'
import { author } from './author'
import { status } from './status'
import { stakeholder } from './stakeholder'
import { price } from './price'
import { delivery } from './delivery/delivery'
import { cargo } from './cargo/cargo'
import { quality } from './quality'
import { checklist } from './checklist'
import { broker } from './broker'

export const $O = {
  swapLeg,
  author,
  status,
  stakeholder,
  price,
  delivery,
  cargo,
  quality,
  checklist,
  broker,
}
