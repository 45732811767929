import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import NotesTable from './components/NotesTable'
import Fetch from 'containers/fetchPlus/FetchPlus'

const sortByEventDate = (notes) =>
  notes.sort(
    (note1, note2) => new Date(note2.eventDate) - new Date(note1.eventDate)
  )

const ViewNotes = ({ match }) => (
  <Fetch url="/notes">
    {(notes, refetch) => (
      <>
        <Text variant="h1" gutterBottom>
          Notes
        </Text>
        <NotesTable
          notes={sortByEventDate(notes)}
          match={match}
          refetch={refetch}
        />
      </>
    )}
  </Fetch>
)

ViewNotes.propTypes = {
  match: PropTypes.object.isRequired,
}

export default ViewNotes
