import React, { useContext, memo } from 'react'
import styled from 'styled-components'
import { get, isEqual } from 'lodash'
import { IconButton, Fab, Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import {
  PowerSettingsNew as PowerSettingsIcon,
  Add as AddIcon,
} from '@material-ui/icons'
import { PERSON_CLIENT_TYPE } from 'emsurge-selectors/constants'
import ExportMenu from './ExportMenu'
import ContextToggle from './ContextToggle'
import HamburgerMenuButton from 'components/navbar/HamburgerMenuButton'
import CreateOrderButton from 'components/navbar/CreateOrderButton'
import CreateNoteButton from 'components/navbar/CreateNoteButton'
import { CreateBulletinButton } from 'components/navbar/CreateBulletinButton'
import { BrandLogoGold } from 'components/navbar/BrandLogoGold'
import { UserName } from 'components/navbar/UserName'
import { LayoutContext } from 'containers/layout/LayoutProvider'
import { useUser } from 'containers/user/useUser'
import CreateOrderModalLNG from 'screens/orderCreate/CreateOrder'
import CreateOrderModalCarbon from 'screens/orderCreateCarbon/CreateOrder'
import NoteModal from 'screens/noteCreate/NoteCreate'
import { BulletinCreate } from 'screens/bulletinCreate'
import { isLoggedIn } from 'containers/user/helpers'
import { isSystemAdmin } from 'roles'
import { Tablet, Mobile } from 'components/Viewport'
import Navbar from 'components/navbar/Navbar'
import useAppContext from 'containers/appContext/useAppContext'

const LogoutButton = () => {
  const { palette } = useTheme()
  const { logout } = useUser()

  return (
    <IconButton
      data-testid="logout-button"
      name="logout"
      onClick={() => logout(true)}
      style={{ color: palette.error.main }}
    >
      <PowerSettingsIcon />
    </IconButton>
  )
}

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(0.5),
    minWidth: 40,
  },
  username: {
    maxHeight: theme.spacing(5),
    overflow: 'hidden',
  },
  leftMargin: {
    marginLeft: theme.spacing(2),
  },
}))

const Spacer = styled.span`
  margin-left: auto;
`

const NavbarContainer = () => {
  const { spacing } = useTheme()
  const layout = useContext(LayoutContext)
  const { user } = useUser()
  const { isLNGContext, isCarbonContext } = useAppContext()
  const classes = useStyles()

  const gutter = { marginRight: spacing(1) }

  if (!isLoggedIn(user)) {
    return (
      <Navbar>
        <BrandLogoGold />
      </Navbar>
    )
  }

  if (isSystemAdmin(user)) {
    return (
      <Navbar>
        <HamburgerMenuButton style={gutter} onClick={layout.toggleSideMenu} />
        <BrandLogoGold />
        <LogoutButton />
      </Navbar>
    )
  }

  return (
    <Navbar>
      <HamburgerMenuButton style={gutter} onClick={layout.toggleSideMenu} />

      <Tablet matchLarger>
        <BrandLogoGold styles={{ marginRight: 'initial' }} />
        <UserName
          userName={get(user, 'name')}
          companyName={get(user, 'company.name')}
          classes={{ root: classes.leftMargin }}
        />

        {<ContextToggle />}

        <Spacer />

        {isLNGContext && <ExportMenu style={{ ...gutter }} />}

        {isEqual(user.clientType, PERSON_CLIENT_TYPE.BROKER) && (
          <BulletinCreate>
            {({ open }) => (
              <CreateBulletinButton
                data-testid="create-bulletin-button"
                onClick={open}
              />
            )}
          </BulletinCreate>
        )}
        <NoteModal>
          {({ open }) => (
            <CreateNoteButton data-testid="create-note-button" onClick={open} />
          )}
        </NoteModal>
        {isLNGContext && (
          <CreateOrderModalLNG>
            {({ open }) => (
              <CreateOrderButton
                data-testid="create-order-button"
                onClick={open}
              />
            )}
          </CreateOrderModalLNG>
        )}
        {isCarbonContext && (
          <CreateOrderModalCarbon>
            {({ open }) => (
              <CreateOrderButton
                data-testid="create-order-button"
                onClick={open}
              />
            )}
          </CreateOrderModalCarbon>
        )}
      </Tablet>

      <Mobile>
        <Box flexGrow="1" />
        <Fab
          className={classes.fab}
          aria-label="Add"
          size="small"
          color="secondary"
        >
          <NoteModal>{({ open }) => <AddIcon onClick={open} />}</NoteModal>
        </Fab>
        <CreateOrderModalLNG>
          {({ open }) => (
            <CreateOrderButton
              data-testid="create-order-button"
              id="createOrderButton"
              onClick={open}
              render={(props) => (
                <Fab
                  onClick={open}
                  className={classes.fab}
                  aria-label="Add"
                  size="small"
                  color="primary"
                  {...props}
                >
                  <AddIcon />
                </Fab>
              )}
            />
          )}
        </CreateOrderModalLNG>
      </Mobile>

      <LogoutButton />
    </Navbar>
  )
}

export default memo(NavbarContainer)
