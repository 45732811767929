import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import { isEmpty } from 'lodash'
import { Field } from 'formik'
import MuiTextField from '@material-ui/core/TextField'
import { getSuggestions } from './helpers'
import Suggestion from './Suggestion'

const styles = (theme) => ({
  root: {
    display: 'inline-flex',
    width: '100%',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  divider: {
    height: theme.spacing(2),
  },
})

const getInputProps = (formikInputProps, downshiftInputProps) => ({
  'aria-autocomplete': downshiftInputProps['aria-autocomplete'],
  'aria-activedescendant': downshiftInputProps['aria-activedescendant'],
  'aria-controls': downshiftInputProps['aria-controls'],
  'aria-labelledby': downshiftInputProps['aria-labelledby'],
  autoComplete: 'off',
  value: downshiftInputProps.value,
  name: formikInputProps.name,
  id: downshiftInputProps.id,
  onChange(...args) {
    downshiftInputProps.onChange(...args)
    formikInputProps.onChange(...args)
  },
  onKeyDown: downshiftInputProps.onKeyDown,
  onBlur(...args) {
    downshiftInputProps.onBlur(...args)
    formikInputProps.onBlur(...args)
  },
})

const getFieldProps = (props, formikProps, formikForm, downshiftProps) => {
  const { classes, ...fieldProps } = props
  const { name } = fieldProps
  const fieldError = formikForm.errors[name]
  const showError = formikForm.touched[name] && !isEmpty(fieldError)

  const InputProps = {
    ...getInputProps(formikProps, downshiftProps),
    classes: {
      root: classes.inputRoot,
    },
  }

  return {
    ...fieldProps,
    ...formikProps,
    inputProps: {},
    InputProps,
    error: showError,
    helperText: showError ? fieldError : fieldProps.helperText,
    disabled: formikForm.isSubmitting || fieldProps.disabled,
  }
}

/* [1]. Formik is not able to get selected value so we mimic event */
const AutoComplete = ({ suggestions, ...props }) => {
  const { classes, className, name } = props

  return (
    <div className={`${className} ${classes.root}`}>
      <Field name={name}>
        {({ field: formikField, form: formikForm }) => (
          <Downshift
            inputValue={formikField.value || ''}
            onSelect={(selected /* [1] */) =>
              formikField.onChange({ target: { name, value: selected } })
            }
          >
            {(downshift) => (
              <div className={classes.container}>
                <MuiTextField
                  className={className}
                  {...getFieldProps(
                    props,
                    formikField,
                    formikForm,
                    downshift.getInputProps()
                  )}
                />
                <div {...downshift.getMenuProps()}>
                  {downshift.isOpen ? (
                    <Paper className={classes.paper} square>
                      {getSuggestions(suggestions, formikField.value).map(
                        (suggestion, index) => (
                          <Suggestion
                            key={suggestion}
                            suggestion={suggestion}
                            index={index}
                            itemProps={downshift.getItemProps({
                              item: suggestion,
                            })}
                            highlightedIndex={downshift.highlightedIndex}
                            selectedItem={downshift.selectedItem}
                          />
                        )
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            )}
          </Downshift>
        )}
      </Field>
    </div>
  )
}

AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
}

export default withStyles(styles)(AutoComplete)
