import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch } from '@material-ui/core'
import { get } from 'lodash'

const _SwitchComponent = ({ path, formik: { values, setFieldValue } }) => {
  const [checked, setChecked] = useState(get(values, path) || false)

  useEffect(() => {
    const currentValue = get(values, path) || false
    if (currentValue !== checked) {
      handleCheckedValue(currentValue)
    }
  }, [values])

  const handleCheckedValue = (checked) => {
    setChecked(checked)
    setFieldValue(path, checked)
  }

  const onSwitchChange = async (evt) => {
    const checked = evt?.target?.checked
    handleCheckedValue(checked)
  }

  return (
    <FormControlLabel
      style={{ marginTop: '16px' }}
      control={
        <Switch
          inputProps={{ 'data-testid': 'regions-ldcs-only-switch' }}
          checked={checked}
          onChange={onSwitchChange}
          color="primary"
          name={path}
        />
      }
      label={'LDCs only (LDCs displayed with gold outline)'}
    />
  )
}

_SwitchComponent.propTypes = {
  path: PropTypes.string.isRequired,
}

export const SwitchComponent = connect(_SwitchComponent)
