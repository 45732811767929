import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@material-ui/core/Typography'
import { lighten } from 'polished'

const Wrapper = styled.section`
  width: 100%;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing(1)}px;
  background: ${({ theme }) =>
    lighten(0.1, theme.palette.background.secondary)};
  border-radius: 5px;
`

const FieldCard = ({ title, children }) => (
  <Wrapper>
    <Text variant="caption" component="p" style={{ fontWeight: 500 }}>
      {title}
    </Text>
    {children}
  </Wrapper>
)

FieldCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default FieldCard
