import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import { useTheme } from '@material-ui/core'

const OrderBlockSkeleton = () => {
  const { spacing } = useTheme()

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      style={{ padding: spacing(1) }}
    >
      <Grid item>
        <Skeleton variant="rect" width="75%" height={20} />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" width="20%" height={15} />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" width="60%" height={15} />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" width="40%" height={15} />
      </Grid>
      <Grid
        container
        style={{ marginTop: spacing(2) }}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={4}>
          <Skeleton variant="rect" width="80%" height={30} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rect" width="40%" height={20} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const OrderBlock = ({ children, loading }) => (
  <Grid item xs={6}>
    <Grid container direction="column" spacing={2}>
      {loading ? <OrderBlockSkeleton /> : children}
    </Grid>
  </Grid>
)

OrderBlock.defaultProps = {
  loading: true,
}

OrderBlock.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
}
