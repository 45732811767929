import { string, object } from 'yup'

const getAuthorisedSignatoryFormSchema = ({ email }) =>
  object().shape({
    name: string().required('Required'),
    surname: string().required('Required'),
    jobTitle: string().required('Required'),
    email: string()
      .email('Invalid email')
      .emailIsUnique(email)
      .required('Required'),
    phone: string().required('Required'),
  })

export { getAuthorisedSignatoryFormSchema }
