import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import TextField from 'containers/formikTextField/FormikTextField'

export const DeliveryWindowSelect = ({ options, disabled, onChange, name }) => {
  const props = {
    onChange,
    disabled,
    name,
    label: 'Delivery Window',
    select: true,
    fullWidth: true,
  }

  return (
    <TextField {...props} margin="normal">
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  )
}

DeliveryWindowSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

DeliveryWindowSelect.defaultProps = {
  disabled: false,
  onChange: () => {},
}
