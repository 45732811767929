import React from 'react'
import MuiTableBody from '@material-ui/core/TableBody'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/styles'
import EmptyTable from './EmptyTable'
import TableData from './TableData'

const TableBody = (props) => {
  const { renderData } = props

  const isTableEmpty = renderData.length === 0

  return (
    <>
      <MuiTableBody>
        {props.options.filtering && (
          <props.components.FilterRow
            {...props}
            {...props.options}
            localization={{ ...props.localization.filterRow }}
            hasDetailPanel={false}
          />
        )}
      </MuiTableBody>
      {isTableEmpty ? (
        <EmptyTable noDataMessage={props.options.noDataMessage} />
      ) : (
        <TableData data={renderData} {...props} />
      )}
    </>
  )
}

TableBody.defaultProps = {
  currentPage: 0,
  renderData: [],
  onFilterSelectionChanged: () => {},
}

TableBody.propTypes = {
  columns: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  renderData: PropTypes.array,
  onFilterSelectionChanged: PropTypes.func.isRequired,
  localization: PropTypes.object,
  onFilterChanged: PropTypes.func,
  theme: PropTypes.object.isRequired,
  groupFunction: PropTypes.func,
}

export default withTheme(TableBody)
