import { connect } from 'formik'
import { get, map } from 'lodash'
import {
  DELIVERY_REGION,
  DELIVERY_TYPE,
  DELIVERY_TYPE_TEXT,
  PARTY,
} from 'emsurge-selectors/constants'
import { getCargoSizeVariance } from 'screens/orderCreate/helpers'

export const DeliveryType = connect(({ children, formik }) => {
  const options = map(DELIVERY_TYPE_TEXT, (label, value) => ({ value, label }))

  const setNominationField = (nomination, value) => {
    const base = `nominationRules[${nomination}].base.name`
    const alternatives = formik.values.nominationRules[
      nomination
    ].alternatives.map(
      (_, idx) => `nominationRules[${nomination}].alternatives[${idx}].name`
    )

    const slices = [base, ...alternatives]

    slices.forEach((slice) => {
      formik.setFieldValue(slice, value)
    })
  }

  const setOperationalToleranceParty = (party) => {
    const variance = getCargoSizeVariance(party)
    formik.setFieldValue('cargoSize.party', party)
    formik.setFieldValue('cargoSize.varianceMinus', variance.varianceMinus)
    formik.setFieldValue('cargoSize.variancePlus', variance.variancePlus)
  }

  const onChange = (deliveryType, port) => {
    const portsForType = get(DELIVERY_REGION, deliveryType)
    const newPort = portsForType.includes(port) ? port : portsForType[0]
    const currentDeliveryType = formik.values.delivery.type

    if (currentDeliveryType !== deliveryType) {
      if ([DELIVERY_TYPE.FOB, DELIVERY_TYPE.STS].includes(deliveryType)) {
        setOperationalToleranceParty(PARTY.BUYER)
      } else {
        setOperationalToleranceParty(PARTY.SELLER)
      }
    }

    if (newPort !== port) {
      formik.setFieldValue('delivery.location', newPort)
    }

    if ([DELIVERY_TYPE.FOB, DELIVERY_TYPE.STS].includes(deliveryType)) {
      setNominationField('loadPort', newPort)
      setNominationField('dischargePort', '')
    } else {
      setNominationField('loadPort', '')
      setNominationField('dischargePort', newPort)
    }
  }

  return children({ options, onChange })
})
