import React from 'react'
import { connect } from 'formik'
import { TERM_SPOT_INVESTMENT_DESCRIPTION } from 'emsurge-selectors'
import { ProjectInformation } from '../components/ProjectInformation'
import { DeferredTextField } from 'containers/formikTextField/DeferredTextField'

const _TermVintageInvestment = () => {
  return (
    <>
      <ProjectInformation />
      <DeferredTextField
        data-testid={'investment-description-field'}
        fullWidth
        multiline
        rows={5}
        name={TERM_SPOT_INVESTMENT_DESCRIPTION}
        id={TERM_SPOT_INVESTMENT_DESCRIPTION}
        InputProps={{ placeholder: 'Description' }}
      />
    </>
  )
}

export const TermVintageInvestment = connect(_TermVintageInvestment)
