import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Text from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

export const AbstractNavbar = ({ title, leftChild, rightChild }) => (
  <AppBar position="relative" color="default">
    <Toolbar>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid xs={1} item>
          {leftChild}
        </Grid>
        <Grid xs={10} item>
          <Text variant="button" component="div" align="center" color="inherit">
            {title}
          </Text>
        </Grid>
        <Grid xs={1} item>
          {rightChild}
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
)

AbstractNavbar.propTypes = {
  title: PropTypes.string,
  leftChild: PropTypes.node,
  rightChild: PropTypes.node,
}
