const {
  NOMINATION_TYPE_TEXT,
  NOMINATION_TYPE,
  PARTY_TEXT,
  VESSEL_SIZE_UNIT,
  VESSEL_TYPE_LABEL,
} = require('../../constants')

const nomination = require('./nomination')

const getVesselName = (terms) => {
  const vessel = terms.ship.base || {}

  return vessel.name
}

const getVesselNameText = (terms) => {
  const name = getVesselName(terms)

  return name || ''
}

const getVesselType = (terms) => {
  const vessel = terms.ship.base || {}

  return vessel.vesselType
}

const getVesselTypeText = (terms) => {
  const type = getVesselType(terms)

  return VESSEL_TYPE_LABEL[type] || ''
}

const getVesselSize = (terms) => {
  const vessel = terms.ship.base || {}
  const size = vessel.size
  const vesselSize = {}

  if (!size) {
    return vesselSize
  }

  if (vessel.toBeNominated) {
    vesselSize.min = size.min
    vesselSize.max = size.max
  }

  if (size.value) {
    vesselSize.value = size.value
  }

  return vesselSize
}

const getVesselSizeSummary = (terms) => {
  const vesselSize = getVesselSize(terms)
  const sizeSummary = {}

  for (const size in vesselSize) {
    sizeSummary[size] =
      vesselSize[size] !== undefined
        ? `${vesselSize[size]}${VESSEL_SIZE_UNIT.M_THREE}`
        : ''
  }

  return sizeSummary
}

const getVesselSizeText = (terms) => {
  const vessel = terms.ship.base || {}
  const sizeSummary = getVesselSizeSummary(terms)
  const typeText = getVesselTypeText(terms)

  const sizeText = []

  if (sizeSummary.min) {
    sizeText.push(sizeSummary.min)
  }

  if (sizeSummary.max) {
    sizeText.push(sizeSummary.max)
  }

  if (sizeSummary.value && !vessel.toBeNominated) {
    sizeText.push(sizeSummary.value)
  }

  if (sizeText.length !== 0) {
    return `Vessel ${typeText}: ${sizeText.join(' - ')}`
  }

  return ''
}

const getVesselParty = (terms) => {
  const vessel = terms.ship.base

  return vessel.party
}

const getVesselPartyText = (terms) => {
  const party = terms.ship.base

  return PARTY_TEXT[party] || ''
}

const getVesselNomination = (terms) => {
  return nomination.get(terms.ship.base)
}

const getVesselNominationSummary = (terms) => {
  return nomination.summary(terms.ship.base)
}

const getVesselNominationText = (terms) => {
  return nomination.text(terms.ship.base, {
    prefix: 'For each cargo, the',
    name: NOMINATION_TYPE_TEXT[NOMINATION_TYPE.SHIP],
  })
}

const getVessel = (terms) => {
  return {
    name: getVesselName(terms),
    type: getVesselType(terms),
    size: getVesselSize(terms),
    party: getVesselParty(terms),
    nomination: getVesselNomination(terms),
  }
}

const getVesselSummary = (terms) => {
  return {
    name: getVesselNameText(terms),
    type: getVesselTypeText(terms),
    size: getVesselSizeSummary(terms),
    party: getVesselPartyText(terms),
    nomination: getVesselNominationSummary(terms),
  }
}

const getVesselText = (terms) => {
  return {
    name: getVesselNameText(terms),
    type: getVesselTypeText(terms),
    size: getVesselSizeText(terms),
    party: getVesselPartyText(terms),
    nomination: getVesselNominationText(terms),
  }
}

const getVesselAlternatives = (terms) => {
  const vesselAlternatives = terms.ship.alternatives || []
  const alternatives = vesselAlternatives
    .map((alternative) => ({ ship: { base: alternative } }))
    .map(getVessel)

  return alternatives
}

const getVesselAlternativesText = (terms) => {
  const vesselAlternatives = terms.ship.alternatives || []
  const alternativesText = vesselAlternatives
    .map((alternative) => ({ ship: { base: alternative } }))
    .map(getVesselText)

  return alternativesText
}

const getVesselAlternativesSummary = (terms) => {
  const vesselAlternatives = terms.ship.alternatives || []
  const alternativesSummary = vesselAlternatives
    .map((alternative) => ({ ship: { base: alternative } }))
    .map(getVesselSummary)

  return alternativesSummary
}

module.exports = {
  get: getVessel,
  name: {
    get: getVesselName,
    text: getVesselNameText,
  },
  size: {
    get: getVesselSize,
    text: getVesselSizeText,
  },
  party: {
    get: getVesselParty,
    text: getVesselPartyText,
  },
  type: {
    get: getVesselType,
    text: getVesselTypeText,
  },
  nomination: {
    get: getVesselNomination,
    summary: getVesselNominationSummary,
    text: getVesselNominationText,
  },
  alternatives: {
    get: getVesselAlternatives,
    summary: getVesselAlternativesSummary,
    text: getVesselAlternativesText,
  },
  summary: getVesselSummary,
  text: getVesselText,
}
