import React from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@material-ui/core/TextField'
import Timezone from './Timezone'

export const DateField = ({ value, onChange, label, disabled }) => (
  <MuiTextField
    inputProps={{ 'data-testid': 'date-field' }}
    fullWidth
    margin="normal"
    type="date"
    label={label}
    value={value}
    InputLabelProps={{ shrink: true }}
    onChange={({ target: { value } }) => value && onChange(value)}
    disabled={disabled}
  />
)

DateField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

DateField.defaultProps = {
  label: 'Date',
  disabled: false,
}

export const TimeField = ({ value, onChange, label, disabled }) => (
  <MuiTextField
    inputProps={{ 'data-testid': 'time-field' }}
    fullWidth
    margin="normal"
    type="time"
    label={label}
    value={value}
    InputLabelProps={{ shrink: true }}
    onChange={({ target: { value } }) => onChange(value)}
    disabled={disabled}
  />
)

TimeField.defaultProps = {
  label: 'Time',
  disabled: false,
}

TimeField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

export const TimezoneField = ({ value, onChange, label, disabled }) => (
  <Timezone
    label={label}
    value={value}
    onChange={({ target: { value } }) => onChange(value)}
    disabled={disabled}
  />
)

TimezoneField.defaultProps = {
  label: 'Timezone',
  disabled: false,
}

TimezoneField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}
