import React, { useState, useEffect } from 'react'
import Text from '@material-ui/core/Typography'
import { sortBy } from 'lodash'
import { COLUMNS, NO_TRADERS_TEXT } from './constants'
import { mapToTraderView, sortAlphabetically } from './helpers'
import Table from 'components/Table/Table'
import { useApi } from 'containers/api/useApi'

const TradersView = () => {
  const [ traders, setTraders ] = useState({})
  const [ isLoading, setIsLoading ] = useState(true)
  const api = useApi()

  useEffect(async () => {
    const data = await api.getTraders()
    setTraders(data)
    setIsLoading(false)
  }, [])
  
  return (isLoading ? null :
    <>
      <Text variant="h1" gutterBottom>
        TRADERS
      </Text>

      <Table
        columns={COLUMNS}
        data={sortBy(traders.map(mapToTraderView), sortAlphabetically)}
        noDataMessage={NO_TRADERS_TEXT}
      />
    </>
  )
}

export default TradersView
