import { useContext, useState, useEffect, useCallback } from 'react'
import { isUndefined, isString, isNull, isEmpty } from 'lodash'
import { FetchContext } from './FetchProvider'
import { createId } from 'utils/createId'
import { useForceUpdate } from 'utils/useForceUpdate'

/* :: () -> void */
const useEffectOnce = (fn) => useEffect(fn, [])

const FETCH_INSTANCES = {}

/* :: () -> void */
export const refetchAll = () => {
  Object.values(FETCH_INSTANCES).forEach((f) => void f.refetch())
}

/* (string | string[], ?object) -> object */
export const useFetch = (urls, { auth = true } = {}) => {
  const { readCache, watchCache, fetch } = useContext(FetchContext)
  const [isFetched, setIsFetched] = useState(false)
  const forceUpdate = useForceUpdate()
  /* :: () -> Promise<void> */
  const fetchUrls = useCallback(
    () => fetch(urls, { auth }),
    [auth, fetch, urls]
  )

  /**
   @NOTE: urls can be a string or array so we use JSON.stringify for comparison
   */
  useEffect(() => {
    // Set as fetched so we can re-fetch when clearing cache
    setIsFetched(true)

    fetchUrls()
  }, [fetchUrls, isFetched])

  /**
   @NOTE: Store instance refetch so it can be triggered outside useFetch & Components
   */
  useEffectOnce(() => {
    const id = createId()
    FETCH_INSTANCES[id] = { refetch: fetchUrls }

    const removeInstance = () => {
      delete FETCH_INSTANCES[id]
    }

    return removeInstance
  })

  /** @NOTE: If cache for url changes rerender with latest data */
  useEffect(() => {
    const unsubscribe = watchCache((urlUpdated, isCleared = false) => {
      const urlList = Array.isArray(urls) ? urls : [urls]
      if (!urlList.includes(urlUpdated)) {
        return
      }

      if (!isCleared) {
        forceUpdate()
        return
      }

      // Set is fetched as false so it refetches the queries
      setIsFetched(false)
    })

    return unsubscribe
  }, [forceUpdate, urls, watchCache])

  /** @NOTE: useFetch('/users') */
  if (isString(urls)) {
    const urlInfo = isUndefined(readCache(urls))
      ? { loading: true, error: null, data: null }
      : readCache(urls)
    const { loading, error, data } = urlInfo

    return { loading, error, data, refetch: fetchUrls }
  }

  /** @NOTE: useFetch([ '/users', '/companies', '/notes' ]) */
  if (Array.isArray(urls)) {
    const urlsInfo = urls
      .map((url) => readCache(url))
      .map((urlData) => {
        return isUndefined(urlData)
          ? { loading: true, error: null, data: null }
          : urlData
      })

    const errors = urlsInfo.filter(({ error }) => !isNull(error))
    if (!isEmpty(errors)) {
      return { loading: false, error: errors, data: null, refetch: fetchUrls }
    }

    const isLoading = urlsInfo.some((url) => url.loading && isNull(url.data))
    if (isLoading) {
      return { loading: true, error: null, data: null, refetch: fetchUrls }
    }

    const data = urlsInfo.map(({ data }) => data)
    return { loading: false, error: null, data, refetch: fetchUrls }
  }
}
