import React from 'react'
import {
  CARBON_STANDARD_OPTIONS,
  CARBON_STANDARD_PREFIX,
} from 'emsurge-selectors'
import { CheckboxGroup } from './CheckboxGroup'

export const CarbonStandard = () => {
  const carbonStandard = CARBON_STANDARD_OPTIONS
  return (
    <CheckboxGroup
      key="radio-button-group-carbon-standard"
      label="Carbon Standard"
      fieldPrefix={CARBON_STANDARD_PREFIX}
      options={carbonStandard}
    />
  )
}
