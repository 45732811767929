const map = require('lodash/map')

const DELIVERY_FREQUENCY_TYPE = {
  PER_PERIOD: 'none',
  PER_MONTH: 'per_month',
  PER_ANNUM: 'per_annum',
}

const DELIVERY_FREQUENCY_TYPE_TEXT = Object.freeze({
  [DELIVERY_FREQUENCY_TYPE.PER_PERIOD]: 'Per period',
  [DELIVERY_FREQUENCY_TYPE.PER_MONTH]: 'Per month',
  [DELIVERY_FREQUENCY_TYPE.PER_ANNUM]: 'Per annum',
})

const DELIVERY_FREQUENCY_TYPE_OPTIONS = map(
  DELIVERY_FREQUENCY_TYPE_TEXT,
  (label, value) => ({ value, label })
)

const DELIVERY_FREQUENCY_DISTRIBUTION = Object.freeze({
  MONTHS_ODD: 'odd_months',
  MONTHS_EVEN: 'even_months',
  DAYS_OR_MONTHS: 'x_days_or_months',
})

const DELIVERY_FREQUENCY_DISTRIBUTION_TEXT = Object.freeze({
  [DELIVERY_FREQUENCY_DISTRIBUTION.MONTHS_ODD]: 'Odd months',
  [DELIVERY_FREQUENCY_DISTRIBUTION.MONTHS_EVEN]: 'Even months',
})

module.exports = {
  DELIVERY_FREQUENCY_DISTRIBUTION,
  DELIVERY_FREQUENCY_DISTRIBUTION_TEXT,
  DELIVERY_FREQUENCY_TYPE,
  DELIVERY_FREQUENCY_TYPE_TEXT,
  DELIVERY_FREQUENCY_TYPE_OPTIONS,
}
