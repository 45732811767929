import { PRICE_TYPE, PRICE_TYPE_TEXT } from 'emsurge-selectors/constants'

const hasPrice = () => true

const getPrice = () => {}

const getSummary = () => {
  const summary = 'TBD'

  return {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.TBD],
    short: summary,
    long: summary,
  }
}

export const tbd = {
  exists: hasPrice,
  get: getPrice,
  summary: getSummary,
}
