import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import MenuItem from '@material-ui/core/MenuItem'

import { ORDER_STATUS, PERSON_PERMISSION } from 'emsurge-selectors'
import { ConfirmActionDialog } from 'components/ConfirmActionDialog'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import withRouteParams from 'utils/withRouteParams'
import { useUserHasPermission } from 'permissions'
import { Mobile, Tablet } from 'components/Viewport'
import { MenuItemWithConfirm } from 'components/MenuItemWithConfirm'

const ARCHIVE_ORDER_TEXT =
  'By archiving this Order you will delete all associated data including open Term Sheets, Comments, Checklists and Nominations. Would you like to proceed?'

const ArchiveOrderMenuItem = ({ order, id, closeMenu, enqueueSnackbar }) => {
  const [open, setOpen] = useState(false)
  const userCanRemoveOrder = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.WRITE,
    forceSameCompany: true,
  })
  const api = useApi()

  if (
    !userCanRemoveOrder ||
    order.template ||
    order.status === ORDER_STATUS.CLOSED
  ) {
    return null
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const onCancel = () => {
    setOpen(false)
    closeMenu()
  }

  const onConfirm = async () => {
    const redirect = closeMenu()

    try {
      await api.patch(`/orders/${id}/status`, {
        status: ORDER_STATUS.CLOSED,
        context: order.context,
      })
      enqueueSnackbar('Order closed successfully', { variant: 'success' })

      redirect()
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Error closing order',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <>
      <Tablet matchLarger>
        <div>
          <MenuItem
            data-testid="menu-archive-order-button"
            onClick={handleClickOpen}
          >
            ARCHIVE ORDER
          </MenuItem>
          <ConfirmActionDialog
            onCancel={onCancel}
            onConfirm={onConfirm}
            open={open}
            text={ARCHIVE_ORDER_TEXT}
          ></ConfirmActionDialog>
        </div>
      </Tablet>
      <Mobile>
        <MenuItemWithConfirm
          data-testid="menu-archive-order-button"
          title="Archive"
          text={ARCHIVE_ORDER_TEXT}
          expanded={open}
          onCollapse={() => setOpen(false)}
          onClick={() => setOpen((value) => !value)}
          onConfirm={onConfirm}
        />
      </Mobile>
    </>
  )
}

const enhance = compose(withRouteParams, withSnackbar)

ArchiveOrderMenuItem.propTypes = {
  order: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  closeMenu: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default enhance(ArchiveOrderMenuItem)
