import { negate, isEmpty } from 'lodash'

const exists = negate(isEmpty)

/* :: object -> string[] */
export const getAddress = (address) =>
  [
    address.addressFirstLine,
    address.addressSecondLine,
    `${address.city} ${address.postcode}`,
    address.country,
  ].filter(exists)
