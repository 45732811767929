import React, { useState, useEffect, useRef } from 'react'
import { findIndex } from 'lodash'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const SelectableGrid = ({
  children,
  spacing,
  classes,
  value,
  onChange,
}) => {
  const [activeIndex, setActiveIndex] = useState()
  const oldIndex = usePrevious(activeIndex)

  useEffect(() => {
    if (!children[activeIndex] || oldIndex === activeIndex) {
      return
    }
    onChange(children[activeIndex].props.value, activeIndex)
  }, [onChange, activeIndex, children, oldIndex])

  useEffect(() => {
    const getChildIndexByValue = (value) =>
      findIndex(children, ({ props }) => props.value === value)
    setActiveIndex(getChildIndexByValue(value))
  }, [children, value])

  return (
    <Grid container classes={classes} spacing={spacing}>
      {React.Children.map(children, (fieldElem, index) =>
        React.cloneElement(fieldElem, {
          onClick: () => setActiveIndex(index),
          active: activeIndex === index,
        })
      )}
    </Grid>
  )
}

SelectableGrid.defaultProps = {
  spacing: 2,
  classes: {},
  value: null,
  onChange: () => {},
}

SelectableGrid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  spacing: PropTypes.number,
  classes: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
}
