import React from 'react'
import PropTypes from 'prop-types'
import { curry, times } from 'lodash'
import { pipe, flatten, map } from 'lodash/fp'
import Text from '@material-ui/core/Typography'
import { TRADING_TYPE } from 'emsurge-selectors'
import OrderCard from '../components/MarketInterest/MarketInterestOrderCard'
import { sortOrders } from 'screens/orderIndex/components/MarketInterest/helpers'
import { withHeight } from 'screens/orderIndex/components/helpers'
import { STATUS_VALUES } from 'components/StatusFilters'

export const filterMarketInterestOrdersByStatus = (status) => (orders) => {
  const result = orders.filter(
    (order) => STATUS_VALUES[status].includes(order.status) && !order.template
  )
  return result
}

export const filterMarketInterestOrdersByClassifications =
  (classifications = []) =>
  (orders) => {
    if (!classifications.length) {
      return orders
    }
    return orders.filter((order) =>
      classifications.includes(order.carbonType?.classifications?.second)
    )
  }

const STYLE_PROPS = {
  style: PropTypes.object,
}

const GUTTER = 4

const HEADER_LABEL_HEIGHT = 50
export const guessHeaderLabelHeight = () => HEADER_LABEL_HEIGHT

const ORDER_CARD_HEIGHT = 76
export const guessOrderCardHeight = () => ORDER_CARD_HEIGHT

export const headerLabelFactory = (label) => {
  const HeaderLabelWithStyle = ({ style }) => (
    <div
      style={{
        ...style,
        top: style.top + GUTTER,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text variant="subtitle1" style={{ fontWeight: 700, marginTop: 15 }}>
        {label}
      </Text>
    </div>
  )

  HeaderLabelWithStyle.propTypes = STYLE_PROPS
  return HeaderLabelWithStyle
}

const OrderCardWithStyle = (order, extraStyleProps, { style }) => (
  <OrderCard
    order={order}
    style={{
      ...style,
      ...extraStyleProps,
      overflow: 'hidden',
      top: style.top + GUTTER,
      width: '49%',
      height: style.height - GUTTER,
    }}
  />
)

const _OrderCardWithStyle = curry(OrderCardWithStyle)

const OrderCardRowWithStyle = (bidOrder, askOrder, { style }) => (
  <>
    <OrderCard
      order={bidOrder}
      style={{
        ...style,
        overflow: 'hidden',
        top: style.top + GUTTER,
        width: '49%',
        height: style.height - GUTTER,
      }}
    />
    <OrderCard
      order={askOrder}
      style={{
        ...style,
        overflow: 'hidden',
        left: '50%',
        top: style.top + GUTTER,
        width: '49%',
        height: style.height - GUTTER,
      }}
    />
  </>
)

const _OrderCardRowWithStyle = curry(OrderCardRowWithStyle)

export const orderCardsRowFactory = (bidOrder, askOrder) => {
  if (!bidOrder && !askOrder) {
    return () => null
  }

  if (bidOrder && !askOrder) {
    return _OrderCardWithStyle(bidOrder, {})
  }

  if (!bidOrder && askOrder) {
    return _OrderCardWithStyle(askOrder, { left: '50%' })
  }

  return _OrderCardRowWithStyle(bidOrder, askOrder)
}

export const groupMarketInterestOrders = (sortBy, appContext) => (orders) => {
  const items = sortOrders(orders, sortBy, appContext)

  const getOrderNodes = (orders) => {
    const bidOrders = orders.filter(
      (order) => order.tradingType === TRADING_TYPE.BID
    )
    const askOrders = orders.filter(
      (order) => order.tradingType === TRADING_TYPE.ASK
    )
    const longest = Math.max(bidOrders.length, askOrders.length)
    const placeholder = []

    times(longest, (index) =>
      placeholder.push(
        withHeight(
          guessOrderCardHeight(),
          orderCardsRowFactory(bidOrders[index], askOrders[index])
        )
      )
    )
    return placeholder
  }

  return pipe(
    map(([header, orders]) => [
      withHeight(guessHeaderLabelHeight(), headerLabelFactory(header)),
      ...getOrderNodes(orders),
    ]),
    flatten
  )(items)
}
