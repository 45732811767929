import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import { writeToClipboard } from 'utils/clipboard'
import { useApi } from 'containers/api/useApi'

export const ResetPasswordLinkButton = ({ personId }) => {
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const onClickResetPasswordLink = async (event) => {
    event.stopPropagation()
    setIsLoading(true)

    try {
      const { message: resetPasswordLink } = await api.getResetPasswordLink({ personId })

      if (resetPasswordLink) {
        const response = await writeToClipboard(
          resetPasswordLink,
          'Reset password link copied to clipboard'
        )
        enqueueSnackbar(response.message, {
          variant: response.error ? 'error' : 'success',
        })
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      disabled={isLoading}
      onClick={onClickResetPasswordLink}
    >
      RESET PASSWORD LINK
    </Button>
  )
}

ResetPasswordLinkButton.propTypes = {
  personId: PropTypes.string.isRequired,
}
