import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { TRADING_TYPE } from 'emsurge-selectors'

export const GridContext = createContext({})

export const GridProvider = ({ children }) => {
  const [filters, setFilters] = useState([TRADING_TYPE.BID, TRADING_TYPE.ASK])
  const [depth, setDepth] = useState({})

  const value = {
    filters,
    setFilters,
    depth,
    setDepth,
  }

  return <GridContext.Provider value={value}>{children}</GridContext.Provider>
}

GridProvider.propTypes = {
  children: PropTypes.node,
}
