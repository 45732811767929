const {
  PROGRAM_ELIGIBILITY_VALUES,
  CARBON_STANDARD_VALUES,
  TITLE_TRANSFER_ATTRIBUTES,
  CLASSIFICATIONS,
  CARBON_STANDARD_KEYS,
  PROGRAM_ELIGIBILITY_KEYS,
  RATING_KEYS,
  RATING_VALUES,
} = require('../../../constants')

const getType = (order) => order.carbonType || {}

const getProgramEligibility = (order) => getType(order).programEligibility || []
const getCarbonStandard = (order) => getType(order).carbonStandard || []
const getRating = (order) => getType(order).rating || []
const getOtherCarbonStandard = (order) =>
  getType(order).otherCarbonStandard || ''
const getOtherProgramEligibility = (order) =>
  getType(order).otherProgramEligibility || ''
const getOtherRating = (order) => getType(order).otherRating || ''
const getTitleTransfers = (order) => getType(order).titleTransfers || ''
const getClassifications = (order) => getType(order).classifications || {}

const getProgramEligibilityText = (order) => {
  const programEligibility = getProgramEligibility(order).map((pe) => {
    if (pe === 'otherContent') {
      return getOtherProgramEligibility(order)
    }
    if (pe === PROGRAM_ELIGIBILITY_KEYS.Other) {
      return ''
    }
    return PROGRAM_ELIGIBILITY_VALUES[pe]
  })
  return programEligibility.filter((pe) => pe).join(', ')
}

const getCarbonStandardText = (order) => {
  const carbonStandard = getCarbonStandard(order).map((reg) => {
    if (reg === 'otherContent') {
      return getOtherCarbonStandard(order)
    }
    if (reg === CARBON_STANDARD_KEYS.Other) {
      return ''
    }
    return CARBON_STANDARD_VALUES[reg]
  })
  return carbonStandard.filter((reg) => reg).join(', ')
}

const getRatingText = (order) => {
  const rating = getRating(order).map((reg) => {
    if (reg === 'otherContent') {
      return getOtherRating(order)
    }
    if (reg === RATING_KEYS.Other) {
      return ''
    }
    return RATING_VALUES[reg]
  })
  return rating.filter((rat) => rat).join(', ')
}

const getTitleTransferText = (order) => {
  const titleTransfer = getTitleTransfers(order)
  return TITLE_TRANSFER_ATTRIBUTES[titleTransfer].value
}

const getClassificationsText = (order) => {
  const { first, second } = getClassifications(order)
  const classifications = [first, second].map((addAttr) => {
    const mapped = CLASSIFICATIONS[addAttr] || {}
    return mapped.value || mapped.key || ''
  })
  return classifications.join(', ')
}

const getText = (order) => {
  return {
    programEligibility: getProgramEligibilityText(order),
    carbonStandard: getCarbonStandardText(order),
    rating: getRatingText(order),
    titleTransfer: getTitleTransferText(order),
    classifications: getClassificationsText(order),
  }
}

module.exports = {
  get: getType,
  text: getText,
  programEligibility: {
    get: getProgramEligibility,
    text: getProgramEligibilityText,
  },
  carbonStandard: {
    get: getCarbonStandard,
    text: getCarbonStandardText,
  },
  rating: {
    get: getRating,
    text: getRatingText,
  },
  titleTransfer: {
    get: getTitleTransfers,
    text: getTitleTransferText,
  },
  classifications: {
    get: getClassifications,
    text: getClassificationsText,
  },
}
