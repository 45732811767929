import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/styles'
import { FieldArray, connect } from 'formik'
import { get } from 'lodash'
import MultipleDateRangeField from './MultipleDateRangeField'
import FormikRadioInput from 'containers/FormikRadioInput/FormikRadioInput'
import TextField from 'containers/formikTextField/FormikTextField'
import { calculateCargoDeliverWindow } from 'utils/dataHandlers'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import { AddButton } from 'screens/orderCreateCommon/components/Buttons'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'
import Card from 'screens/orderCreate/components/Card'
import TimeFrame from 'screens/orderCreate/components/TimeFrame'
import { NOMINATION_PORT_DEFAULT_VALUES } from 'screens/orderCreate/containers/NominationsInitialValues'
import { isFOBType } from 'screens/orderCreate/helpers'
import CargoDeliveryWindowCard from 'screens/orderCreate/components/CargoDeliveryWindowCard'

const defaultProps = {
  type: 'number',
  fullWidth: true,
  margin: 'normal',
  InputLabelProps: {
    error: false,
  },
}

const DeliveryWindowForm = ({ formik: { values, setFieldValue } }) => {
  const { spacing } = useTheme()

  const getFieldValue = useCallback(
    (relativePath) =>
      get(values, `nominationRules.deliveryWindow.${relativePath}`),
    [values]
  )
  const setField = useCallback(() => setFieldValue, [setFieldValue])
  const getFieldName = (relativePath) =>
    `nominationRules.deliveryWindow.${relativePath}`

  useEffect(() => {
    const lastAlternativeAddedIndex = getFieldValue('alternatives').length - 1
    const defaultPartyValue = isFOBType(values.delivery.type) ? 'ask' : 'bid'
    if (
      getFieldValue(`alternatives[${lastAlternativeAddedIndex}].party`) !==
      defaultPartyValue
    ) {
      setField(
        getFieldName(`alternatives[${lastAlternativeAddedIndex}].party`),
        defaultPartyValue
      )
    }
  }, [getFieldValue, setField, values.delivery.type])

  const mainCargo = get(values, 'nominationRules.deliveryWindow.cargos[0]', {})
  const { interval, periodName } = calculateCargoDeliverWindow(mainCargo)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            {...defaultProps}
            name={getFieldName('arrival.window')}
            label="Arrival Period (in hours)"
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            {...defaultProps}
            type="time"
            name={getFieldName('arrival.period')}
            label="Local time at the Discharge Port Arrival Period commences at"
          />
        </Grid>
        <Grid item xs={2}>
          <CargoDeliveryWindowCard mainDeliveryWindow />
        </Grid>
      </Grid>

      <MultipleDateRangeField />

      <FieldArray
        name={getFieldName('alternatives')}
        render={(arrayHelpers) => {
          return (
            <>
              {getFieldValue('alternatives').map((_, index, alternatives) => {
                let label = `To narrow ${interval} ${periodName} DW to a XX day DW`

                if (index > 0) {
                  label = `To narrow ${
                    alternatives[index - 1].name
                  } Day DW to a XX day DW`
                }

                return (
                  <Card key={`deliveryMechanism_${index}`}>
                    <CloseButton onClick={() => arrayHelpers.remove(index)} />
                    <FormControl fullWidth style={{ marginTop: 0 }}>
                      <FormLabel>Party</FormLabel>
                      <FormikRadioInput
                        name={getFieldName(`alternatives[${index}].party`)}
                        options={[
                          { value: 'bid', label: 'Buyer' },
                          { value: 'ask', label: 'Seller' },
                          { value: 'tba', label: 'TBA' },
                        ]}
                      />
                    </FormControl>

                    <Grid container spacing={2}>
                      <Grid item xs>
                        <TextField
                          {...defaultProps}
                          required
                          name={getFieldName(`alternatives[${index}].name`)}
                          label={label}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <CargoDeliveryWindowCard
                          alternativeDeliveryWindow
                          index={index}
                        />
                      </Grid>
                    </Grid>
                    <TimeFrame
                      alternativeDeliveryWindow
                      alternativeDeliveryWindowIndex={index}
                      mainName={getFieldName(`alternatives[${index}]`)}
                      timeframe={getFieldValue(
                        `alternatives[${index}].timeframe`
                      )}
                    />
                  </Card>
                )
              })}
              <AddButton
                style={{ margin: spacing(1, 0) }}
                onClick={() =>
                  arrayHelpers.push(NOMINATION_PORT_DEFAULT_VALUES)
                }
              >
                Add Narrowing Mechanism
              </AddButton>
            </>
          )
        }}
      />
    </>
  )
}

DeliveryWindowForm.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const DeliveryWindow = connect(DeliveryWindowForm)
