import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { PRICE_VARIANT } from 'emsurge-selectors/constants'
import { Type } from './Type'
import Checkbox from 'containers/formikCheckbox/FormikCheckbox'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'

export const Price = () => (
  <>
    {/* BASIC */}
    <Type variant={PRICE_VARIANT.BASIC} />

    {/* RESERVE */}
    <FormControl fullWidth>
      <FormControlLabel
        label="Add reserve price"
        control={
          <Checkbox
            data-testid="add-reserve-price-checkbox"
            color="primary"
            value="price.reserve.enable"
          />
        }
      />
    </FormControl>
    <FormConsumer>
      {({ values }) =>
        values.price.reserve.enable ? (
          <Type variant={PRICE_VARIANT.RESERVE} />
        ) : null
      }
    </FormConsumer>

    {/* CONTRACT */}
    <FormControl fullWidth>
      <FormControlLabel
        label="Add contract price"
        control={
          <Checkbox
            data-testid="add-contract-price-checkbox"
            color="primary"
            value="price.contract.enable"
          />
        }
      />
    </FormControl>
    <FormConsumer>
      {({ values }) =>
        values.price.contract.enable ? (
          <Type variant={PRICE_VARIANT.CONTRACT} />
        ) : null
      }
    </FormConsumer>
  </>
)
