import React from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'
import { ActivityCardSkeleton } from '../ActivityCardSkeleton'
import {
  useSkeletonStyles,
  ORDER_ACTIVITY_CARD_HEIGHT_LNG,
} from 'screens/orderIndex/helpers'
import { Container } from 'components/Card'

export const OrderCardsSkeleton = ({ style }) => {
  const { root } = useSkeletonStyles()
  const cardStyles = {
    marginTop: '4px',
    height: `${ORDER_ACTIVITY_CARD_HEIGHT_LNG}px`,
  }
  return (
    <Container style={{ ...style }}>
      <div className={root} style={{ width: '140px', height: '22px' }} />
      <div style={{ height: '10px', width: '100%' }} />
      {times(10, (i) => (
        <ActivityCardSkeleton
          key={`order-card-skeleton-${i}`}
          style={cardStyles}
        />
      ))}
    </Container>
  )
}

OrderCardsSkeleton.propTypes = {
  style: PropTypes.object,
}
