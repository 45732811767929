import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, reduce } from 'lodash'
import { withSnackbar } from 'notistack'
import { useTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { ORDER_STATUS } from 'emsurge-selectors/constants'
// import { CounterAndTalkButton } from './CounterAndTalkButton'
import { TermsheetCardContainer } from './TermsheetCardContainer'
import { useApi } from 'containers/api/useApi'
import { downloadFile } from 'containers/navbar/ExportMenu'

const ExportTermsheetsButton = withSnackbar(({ order, enqueueSnackbar }) => {
  const [state, setState] = useState({ loading: false, error: null })
  const api = useApi()

  const getExport = async () => {
    setState({ loading: true, error: null })

    try {
      const fileName = `order-#${order.shortId.toUpperCase()}-TS.xls`
      await downloadFile(api, `/orders/${order.id}/export`, fileName)
      setState({ loading: false, error: null })
    } catch (e) {
      console.error(e)
      enqueueSnackbar('Error downloading term sheet excel file', {
        variant: 'error',
      })
      setState({ loading: false, error: e })
    }
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={getExport}
      disabled={state.loading}
    >
      Export Term Sheets
    </Button>
  )
})

const Termsheet = ({ order }) => {
  const { spacing } = useTheme()
  const isNotClosed = (termsheet) => termsheet.status !== ORDER_STATUS.CLOSED
  const liveTermsheets = order.termsheets.filter(isNotClosed)
  const termsheetIds = reduce(
    liveTermsheets,
    (acc, curr) => [curr.id, ...acc],
    []
  )

  return (
    <section>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginBottom: spacing(3) }}
      >
        {/* <CounterAndTalkButton order={order} /> */}
        {!isEmpty(termsheetIds) && <ExportTermsheetsButton order={order} />}
      </Grid>

      <Grid container spacing={2} direction="column">
        {termsheetIds.map((id) => (
          <Grid item key={id}>
            <TermsheetCardContainer termsheetId={id} />
          </Grid>
        ))}
      </Grid>
    </section>
  )
}

Termsheet.propTypes = {
  order: PropTypes.object.isRequired,
}

export default Termsheet
