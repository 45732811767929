import React from 'react'
import { makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useVersion } from '../../api/queries/version/useVersion'
import LinkButton from 'components/linkButton/LinkButton'

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
    },
}))

const Version = () => {
    const classes = useStyles()
    const { isLoading, data: version } = useVersion()
    var dateLocaleOptions = { timeZone: 'UTC', timeZoneName: 'short' };

    return isLoading ? "Loading..." : (
        <section>
            <p>
                Version: {version.versionNumber}
            </p>
            <p>
                Branch: <a className={classes.root} href={`https://dev.azure.com/Emsurge/Emsurge/_git/emsurge-webapp.git?version=GB${version.branch}`}>{version.branch}</a>
            </p>
            <p>
                Latest Commit Date: {version.latestCommitDate.toLocaleDateString()} {version.latestCommitDate.toLocaleTimeString([], dateLocaleOptions)}
            </p>
            <p>
                Latest Commits:
                <ul>
                    {version.latestCommits.map(x => <li key={x.commitId}><a className={classes.root} href={`https://dev.azure.com/Emsurge/Emsurge/_git/emsurge-webapp.git/commit/${x.commitId}`}>{x.commitMessage}</a></li>)}
                </ul>
            </p>
            <p>
                <LinkButton href="#" variant="contained" color="primary" to="/">
                    <ArrowBackIcon />
                    Go To Dashboard
                </LinkButton>
            </p>
            <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandLessIcon />}>
                    Uncommitted Local Changes
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        {version.uncommittedLocalChanges.map((x, i) => <li key={i}><span className={classes.root}>{x.content}</span></li>)}
                    </ul>
                </AccordionDetails>
            </Accordion>
        </section>
    )
}

export default Version