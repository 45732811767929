import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { TRADING_TYPE } from 'emsurge-selectors/constants'
import TraderView from './TraderView'
import TradeTimeView from './TradeTimeView'

export const ViewCounterParties = ({ termsheet }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <TradeTimeView termsheet={termsheet} />
        </Grid>
        <Grid item xs={4}>
          <TraderView type={TRADING_TYPE.ASK} termsheet={termsheet} />
        </Grid>
        <Grid item xs={4}>
          <TraderView type={TRADING_TYPE.BID} termsheet={termsheet} />
        </Grid>
      </Grid>
    </>
  )
}

ViewCounterParties.propTypes = {
  termsheet: PropTypes.object.isRequired,
}