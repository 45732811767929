const moment = require('moment')

const { VALIDITY_TYPE, VALIDITY_TYPE_TEXT } = require('../../constants')

const getValidityType = (order) => {
  return order.validityType
}

const getValidityTypeText = (order) => {
  return VALIDITY_TYPE_TEXT[order.validityType] || ''
}

const getValidityValidUntil = (order) => {
  return new Date(order.validUntil)
}

const getValidityValidUntilText = (order) => {
  const validUntil = getValidityValidUntil(order)

  if (validUntil) {
    return `Live till ${moment(validUntil).format(
      'Do MMM YYYY ddd HH:mm (UTC Z)'
    )}`
  }

  return ''
}

const getValidityText = (order) => {
  const validityType = getValidityType(order)

  switch (validityType) {
    case VALIDITY_TYPE.GOOD_TILL_CANCELLED:
    case VALIDITY_TYPE.INDICATIVE:
      return getValidityTypeText(order)
    case VALIDITY_TYPE.GOOD_TILL_DATE:
      return `${getValidityValidUntilText(order)}`
  }
}

module.exports = {
  type: {
    get: getValidityType,
    text: getValidityTypeText,
  },
  validUntil: {
    get: getValidityValidUntil,
    text: getValidityValidUntilText,
  },
  text: getValidityText,
}
