import { useState } from 'react'

/* :: ?boolean -> object */
export const useModal = (initialState = false) => {
  const [isOpen, setState] = useState(initialState)

  /* :: () -> void */
  const open = () => setState(true)
  const close = () => setState(false)

  return { isOpen, open, close }
}
