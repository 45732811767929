const REGIONS_KEYS = {
  AFRICA: 'AFRICA',
  ASIA: 'ASIA',
  EUROPE: 'EUROPE',
  LATIN_AMERICA: 'LATIN_AMERICA',
  NORTHERN_AMERICA: 'NORTHERN_AMERICA',
  OCEANIA: 'OCEANIA',
}

const REGIONS = Object.freeze({
  [REGIONS_KEYS.AFRICA]: 'Africa',
  [REGIONS_KEYS.ASIA]: 'Asia',
  [REGIONS_KEYS.EUROPE]: 'Europe',
  [REGIONS_KEYS.LATIN_AMERICA]: 'Latin America and the Caribbean',
  [REGIONS_KEYS.NORTHERN_AMERICA]: 'Northern America',
  [REGIONS_KEYS.OCEANIA]: 'Oceania',
})

module.exports = {
  REGIONS_KEYS,
  REGIONS,
}
