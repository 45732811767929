import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import MuiTooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/styles'
import { flatten } from 'lodash'
import { PRICE_INDEX_OPTIONS_BY_GROUP } from 'emsurge-selectors/constants'
import { TextField } from 'screens/orderCreateCommon/components/TextField'

const Tooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    ...theme.typography.caption,
  },
}))(MuiTooltip)

const getMenuItems = () => {
  const options = PRICE_INDEX_OPTIONS_BY_GROUP.map(
    (priceIndexOptions, index) => [
      ...priceIndexOptions.map(({ name, description, value }) => {
        if (!description) {
          return (
            <MenuItem data-testid="price-index-values" key={name} value={value}>
              {name}
            </MenuItem>
          )
        }

        return (
          <MenuItem data-testid="price-index-values" key={name} value={value}>
            <Tooltip title={description} disableFocusListener placement="right">
              <span style={{ width: '100%', height: '100%' }}>
                {`${name}*`}
              </span>
            </Tooltip>
          </MenuItem>
        )
      }),
      <Divider key={`divider-group${index + 1}`} />,
    ]
  )

  return flatten(options)
}

export const Indexes = ({ name }) => {
  const selectProps = {
    MenuProps: {
      MenuListProps: {
        style: { maxHeight: 350 },
      },
    },
  }

  const menuItems = getMenuItems()

  return (
    <TextField select SelectProps={selectProps} name={name} label="Of Index">
      {menuItems}
    </TextField>
  )
}

Indexes.propTypes = {
  name: PropTypes.string.isRequired,
}
