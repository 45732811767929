import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { LayoutContext } from 'containers/layout/LayoutProvider'

const HandleCollapse = ({ children, to, ...props }) => {
  const { toggleSubMenus } = useContext(LayoutContext)
  return (
    <div onClick={() => toggleSubMenus(to)} {...props}>
      {children}
    </div>
  )
}

HandleCollapse.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default HandleCollapse
