import React from 'react'
import PropTypes from 'prop-types'
import DoneIcon from '@material-ui/icons/Done'
import { withTheme } from '@material-ui/styles'
import SubmitButton from 'containers/formikSubmitButton/FormikSubmitButton'

const InviteCompanySubmitButton = ({ theme }) => (
  <SubmitButton>
    <DoneIcon style={{ marginRight: theme.spacing(1) }} />
    Send Invitation
  </SubmitButton>
)

InviteCompanySubmitButton.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default withTheme(InviteCompanySubmitButton)
