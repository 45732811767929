import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { withProps } from 'recompose'
import { useTheme } from '@material-ui/styles'
import IconButton from 'components/iconButton/IconButton'

const CloseOrderButton = withProps({
  icon: ClearIcon,
  color: 'primary',
  variant: 'contained',
})(IconButton)

const KeepOpenButton = withProps({
  icon: DoneIcon,
  color: 'primary',
  variant: 'contained',
})(IconButton)

const MarkAsTradedConfirmDialog = ({ children, setTraded }) => {
  const { spacing } = useTheme()
  const [isOpen, setOpen] = useState(false)
  const open = () => setOpen(true)
  const close = () => setOpen(false)

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(ev) => {
          ev.stopPropagation()
          close()
        }}
      >
        <DialogContent>
          <DialogTitle style={{ padding: spacing(0, 0, 1, 0) }}>
            You are about to mark a termsheet as traded
          </DialogTitle>
          <DialogContentText>
            You can either close your order and the associated term sheets you
            have created, or keep the order open and select the term sheets you
            want to close.
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <CloseOrderButton
            data-testid="mark-as-traded-close-order-button"
            onClick={(ev) => {
              ev.stopPropagation()
              setTraded({ closeOnTraded: true })
              close()
            }}
          >
            Close my order
          </CloseOrderButton>
          <KeepOpenButton
            data-testid="mark-as-traded-keep-open-button"
            onClick={(ev) => {
              ev.stopPropagation()
              setTraded({ closeOnTraded: false })
              close()
            }}
          >
            Keep my order open
          </KeepOpenButton>
        </DialogActions>
      </Dialog>

      {children({ open })}
    </>
  )
}

MarkAsTradedConfirmDialog.propTypes = {
  children: PropTypes.func.isRequired,
  setTraded: PropTypes.func.isRequired,
}

export default MarkAsTradedConfirmDialog
