import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { merge } from 'lodash'
import { TRADING_TYPE } from 'emsurge-selectors'
import { buildStyles, buildTradingTypeStyles } from './utils'
import { TimeAgo } from 'components/TimeAgo'

export const CardIcons = ({ order, tradingType, timestamp }) => {
  const { spacing } = useTheme()
  const styles = merge(
    buildStyles(spacing(1)),
    buildTradingTypeStyles(spacing(1))[tradingType]
  )
  return (
    <div style={styles.commentsBar}>
      <TimeAgo
        date={timestamp || order.updatedAt}
        style={styles.commentsItem}
      />
    </div>
  )
}

CardIcons.propTypes = {
  order: PropTypes.object.isRequired,
  tradingType: PropTypes.oneOf(Object.values(TRADING_TYPE)),
  timestamp: PropTypes.string,
}

CardIcons.defaultProps = {
  tradingType: TRADING_TYPE.ASK,
}
