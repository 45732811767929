import React from 'react'
import PropTypes from 'prop-types'

import { $OC } from 'emsurge-selectors/models/order'
import SummaryRow from './../OrderSummaryCarbon/SummaryRow'

const styleRoot = {
  fontSize: '12px',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const styleRootNoCurrency = {
  ...styleRoot,
  marginRight: 0,
}

const styleCurrency = {
  ...styleRoot,
  marginRight: 0,
}

export const CarbonMainPrice = ({
  order,
  style = { root: {}, currency: {} },
  ...props
}) => {
  const { value, hasUnit } = $OC.carbonPrice.text(order)

  if (!value) {
    return <span />
  }

  const styleRootProps = hasUnit ? styleRoot : styleRootNoCurrency
  return (
    <SummaryRow.Price
      value={value}
      hasUnit={hasUnit}
      render={true}
      style={{
        root: { ...styleRootProps, ...style.root },
        currency: { ...styleCurrency, ...style.currency },
      }}
      boldCurrency={false}
      showTooltipProps={false}
      {...props}
    />
  )
}

CarbonMainPrice.propTypes = {
  order: PropTypes.object.isRequired,
}
