import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withTheme } from '@material-ui/styles'
import { defaultProps } from 'recompose'
import { Form } from 'formik'
import FormikTextField from 'containers/formikTextField/FormikTextField'
import SubmitButton from 'containers/formikSubmitButton/FormikSubmitButton'
import FormRow from 'components/formRow/FormRow'

const TextField = defaultProps({
  fullWidth: true,
  margin: 'normal',
  required: true,
})(FormikTextField)

const EntityType = (props) => (
  <TextField disabled select label="Entity Type" {...props}>
    <MenuItem value="trader">Trader</MenuItem>
    <MenuItem value="broker">Broker</MenuItem>
  </TextField>
)

const EntityForm = ({ theme }) => {
  const marginTop = {
    sm: { marginTop: theme.spacing(2) },
    lg: { marginTop: theme.spacing(5) },
  }

  return (
    <Form>
      {/** GENERAL INFO */}
      <section style={marginTop.lg}>
        <Text variant="h2">General information</Text>
        <FormRow>
          <TextField name="info.name" label="Name" />
          <TextField name="info.registrationName" label="Registered name" />
        </FormRow>
        <FormRow>
          <TextField name="info.registrationNumber" label="Registered number" />
          <EntityType name="info.type" />
          <TextField name="info.code" label="Entity code" />
        </FormRow>
        <TextField name="info.addressFirstLine" label="Address line 1" />
        <TextField
          name="info.addressSecondLine"
          label="Address line 2"
          required={false}
        />
        <FormRow>
          <TextField name="info.postcode" label="Post code" />
          <TextField name="info.city" label="City" />
          <TextField name="info.country" label="Country" />
        </FormRow>
      </section>

      {/** PRIMARY POINT OF CONTACT */}
      <section style={marginTop.lg}>
        <Text variant="h2">Primary point of contact</Text>
        <FormRow>
          <TextField name="primaryContact.name" label="Name" />
          <TextField name="primaryContact.surname" label="Surname" />
          <TextField name="primaryContact.jobTitle" label="Job title" />
        </FormRow>
        <FormRow>
          <TextField name="primaryContact.email" label="Email address" />
          <TextField name="primaryContact.phone" label="Phone number" />
        </FormRow>
        <TextField
          name="primaryContact.addressFirstLine"
          label="Address line 1"
        />
        <TextField
          name="primaryContact.addressSecondLine"
          label="Address line 2"
          required={false}
        />
        <FormRow>
          <TextField name="primaryContact.postcode" label="Post code" />
          <TextField name="primaryContact.city" label="City" />
          <TextField name="primaryContact.country" label="Country" />
        </FormRow>
      </section>

      {/** AUTHORISED SIGNATORY */}
      <section style={marginTop.lg}>
        <Text variant="h2">Authorised signatory</Text>
        <FormRow>
          <TextField name="signatory.name" label="Name" />
          <TextField name="signatory.surname" label="Surname" />
          <TextField name="signatory.jobTitle" label="Job title" />
        </FormRow>
        <FormRow>
          <TextField name="signatory.email" label="Email address" />
          <TextField name="signatory.phone" label="Phone number" />
        </FormRow>
      </section>

      <Grid container justifyContent="flex-end">
        <SubmitButton style={marginTop.sm}>Submit</SubmitButton>
      </Grid>
    </Form>
  )
}

EntityForm.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default withTheme(EntityForm)
