import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import IconButton from 'components/iconButton/IconButton'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginRight: spacing(1),
    color: '#fff',
    backgroundColor: palette.background.paper,
  },
}))

export const CreateBulletinButton = ({ ...props }) => {
  const classes = useStyles()

  return (
    <IconButton
      {...props}
      classes={classes}
      icon={Add}
      variant="contained"
      size="small"
    >
      Broadcast Message
    </IconButton>
  )
}
