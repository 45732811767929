import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@material-ui/styles'
import { BrandLogo } from 'components/logo'

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

const LogoWrapper = styled.div`
  width: 120px;
  height: 120px;
  margin: ${({ theme }) => theme.spacing(1)}px;
`

export const BrandLogoWrapper = () => {
  const { palette } = useTheme()

  return (
    <Header>
      <LogoWrapper>
        <BrandLogo backgroundColor={palette.primary.main} />
      </LogoWrapper>
    </Header>
  )
}
