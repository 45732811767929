import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'

import Switch from '@material-ui/core/Switch'
import { $O, ORDER_STATUS, VALIDITY_TYPE } from 'emsurge-selectors'

export const _OrderStatusSwitch = ({
  formik: { values, setFieldValue },
  order,
}) => {
  const [status, setStatus] = useState($O.status.get(order))
  const onChange = () =>
    setStatus((status) =>
      status === ORDER_STATUS.WITHHELD
        ? ORDER_STATUS.LIVE
        : ORDER_STATUS.WITHHELD
    )

  useEffect(() => {
    setFieldValue('status', status)
    if (status !== ORDER_STATUS.LIVE && status !== ORDER_STATUS.CLOSED) {
      setFieldValue('validity.date', undefined)
      setFieldValue('validity.time', undefined)
    }
    if (status === ORDER_STATUS.WITHHELD) {
      setFieldValue('validity.until', VALIDITY_TYPE.INDICATIVE)
    }

    if (
      status === ORDER_STATUS.LIVE &&
      values.validity.until === VALIDITY_TYPE.INDICATIVE
    ) {
      setFieldValue('validity.until', VALIDITY_TYPE.GOOD_TILL_CANCELLED)
    }
  }, [setFieldValue, status, values.validity.until])

  return (
    <Switch
      color="primary"
      checked={$O.status.get(order) === ORDER_STATUS.LIVE}
      onChange={onChange}
      disabled={$O.status.get(order) === ORDER_STATUS.PENDING}
    />
  )
}

_OrderStatusSwitch.defaultProps = {
  order: {},
}

_OrderStatusSwitch.propTypes = {
  formik: PropTypes.object.isRequired,
  order: PropTypes.object,
}

export const OrderStatusSwitch = connect(_OrderStatusSwitch)
