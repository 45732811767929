import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { Grid as _Grid, Box as _Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { dark as theme } from 'theme'

export const Grid = styled(_Grid)`
  ${spacing}
`
export const Box = styled(_Box)`
  ${spacing}
`

const useOrderLinkStyles = makeStyles(() => ({
  root: {
    fontWeight: 500,
    fontSize: '14px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
}))

export const OrderLink = ({ children, onClick }) => {
  const classes = useOrderLinkStyles()
  return (
    <span className={classes.root} onClick={onClick}>
      {children}&nbsp;&nbsp;
    </span>
  )
}

OrderLink.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
}
