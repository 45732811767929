const { mapCountriesToData, OPTION_TYPE_IS_NON_LDC } = require('./helpers')

const COUNTRIES_AFRICA_KEYS = {
  BURUNDI: 'BURUNDI',
  COMOROS: 'COMOROS',
  DJIBOUTI: 'DJIBOUTI',
  ERITREA: 'ERITREA',
  ETHIOPIA: 'ETHIOPIA',
  KENYA: 'KENYA',
  MADAGASCAR: 'MADAGASCAR',
  MALAWI: 'MALAWI',
  MAURITIUS: 'MAURITIUS',
  MAYOTTE: 'MAYOTTE',
  MOZAMBIQUE: 'MOZAMBIQUE',
  REUNION: 'REUNION',
  RWANDA: 'RWANDA',
  SEYCHELLES: 'SEYCHELLES',
  SOMALIA: 'SOMALIA',
  SOUTH_SUDAN: 'SOUTH_SUDAN',
  UGANDA: 'UGANDA',
  UNITED_REPUBLIC_OF_TANZANIA: 'UNITED_REPUBLIC_OF_TANZANIA',
  ZAMBIA: 'ZAMBIA',
  ZIMBABWE: 'ZIMBABWE',
  ANGOLA: 'ANGOLA',
  CAMEROON: 'CAMEROON',
  CENTRAL_AFRICAN_REPUBLIC: 'CENTRAL_AFRICAN_REPUBLIC',
  CHAD: 'CHAD',
  CONGO: 'CONGO',
  DEMOCRATIC_REPUBLIC_OF_THE_CONGO: 'DEMOCRATIC_REPUBLIC_OF_THE_CONGO',
  EQUATORIAL_GUINEA: 'EQUATORIAL_GUINEA',
  GABON: 'GABON',
  SAO_TOME_AND_PRINCIPE: 'SAO_TOME_AND_PRINCIPE',
  BOTSWANA: 'BOTSWANA',
  ESWATINI: 'ESWATINI',
  LESOTHO: 'LESOTHO',
  NAMIBIA: 'NAMIBIA',
  SOUTH_AFRICA: 'SOUTH_AFRICA',
  BENIN: 'BENIN',
  BURKINA_FASO: 'BURKINA_FASO',
  CABO_VERDE: 'CABO_VERDE',
  COTE_DIVOIRE: 'COTE_DIVOIRE',
  GAMBIA: 'GAMBIA',
  GHANA: 'GHANA',
  GUINEA: 'GUINEA',
  GUINEA_BISSAU: 'GUINEA_BISSAU',
  LIBERIA: 'LIBERIA',
  MALI: 'MALI',
  MAURITANIA: 'MAURITANIA',
  NIGER: 'NIGER',
  NIGERIA: 'NIGERIA',
  SAINT_HELENA: 'SAINT_HELENA',
  SENEGAL: 'SENEGAL',
  SIERRA_LEONE: 'SIERRA_LEONE',
  TOGO: 'TOGO',
  ALGERIA: 'ALGERIA',
  EGYPT: 'EGYPT',
  LIBYA: 'LIBYA',
  MOROCCO: 'MOROCCO',
  SUDAN: 'SUDAN',
  TUNISIA: 'TUNISIA',
  WESTERN_SAHARA: 'WESTERN_SAHARA',
}

const COUNTRIES_AFRICA_VALUES = {
  BURUNDI: 'Burundi',
  COMOROS: 'Comoros',
  DJIBOUTI: 'Djibouti',
  ERITREA: 'Eritrea',
  ETHIOPIA: 'Ethiopia',
  KENYA: 'Kenya',
  MADAGASCAR: 'Madagascar',
  MALAWI: 'Malawi',
  MAURITIUS: 'Mauritius',
  MAYOTTE: 'Mayotte',
  MOZAMBIQUE: 'Mozambique',
  REUNION: 'Réunion',
  RWANDA: 'Rwanda',
  SEYCHELLES: 'Seychelles',
  SOMALIA: 'Somalia',
  SOUTH_SUDAN: 'South Sudan',
  UGANDA: 'Uganda',
  UNITED_REPUBLIC_OF_TANZANIA: 'United Republic of Tanzania',
  ZAMBIA: 'Zambia',
  ZIMBABWE: 'Zimbabwe',
  ANGOLA: 'Angola',
  CAMEROON: 'Cameroon',
  CENTRAL_AFRICAN_REPUBLIC: 'Central African Republic',
  CHAD: 'Chad',
  CONGO: 'Congo',
  DEMOCRATIC_REPUBLIC_OF_THE_CONGO: 'Democratic Republic of the Congo',
  EQUATORIAL_GUINEA: 'Equatorial Guinea',
  GABON: 'Gabon',
  SAO_TOME_AND_PRINCIPE: 'Sao Tome and Principe',
  BOTSWANA: 'Botswana',
  ESWATINI: 'Eswatini',
  LESOTHO: 'Lesotho',
  NAMIBIA: 'Namibia',
  SOUTH_AFRICA: 'South Africa',
  BENIN: 'Benin',
  BURKINA_FASO: 'Burkina Faso',
  CABO_VERDE: 'Cabo Verde',
  COTE_DIVOIRE: "Côte d'Ivoire",
  GAMBIA: 'Gambia',
  GHANA: 'Ghana',
  GUINEA: 'Guinea',
  GUINEA_BISSAU: 'Guinea-Bissau',
  LIBERIA: 'Liberia',
  MALI: 'Mali',
  MAURITANIA: 'Mauritania',
  NIGER: 'Niger',
  NIGERIA: 'Nigeria',
  SAINT_HELENA: 'Saint Helena',
  SENEGAL: 'Senegal',
  SIERRA_LEONE: 'Sierra Leone',
  TOGO: 'Togo',
  ALGERIA: 'Algeria',
  EGYPT: 'Egypt',
  LIBYA: 'Libya',
  MOROCCO: 'Morocco',
  SUDAN: 'Sudan',
  TUNISIA: 'Tunisia',
  WESTERN_SAHARA: 'Western Sahara',
}

const COUNTRIES_AFRICA_DISCARDED_LDCS = [
  COUNTRIES_AFRICA_KEYS.KENYA,
  COUNTRIES_AFRICA_KEYS.MAURITIUS,
  COUNTRIES_AFRICA_KEYS.MAYOTTE,
  COUNTRIES_AFRICA_KEYS.REUNION,
  COUNTRIES_AFRICA_KEYS.SEYCHELLES,
  COUNTRIES_AFRICA_KEYS.ZIMBABWE,
  COUNTRIES_AFRICA_KEYS.CAMEROON,
  COUNTRIES_AFRICA_KEYS.CONGO,
  COUNTRIES_AFRICA_KEYS.EQUATORIAL_GUINEA,
  COUNTRIES_AFRICA_KEYS.GABON,
  COUNTRIES_AFRICA_KEYS.BOTSWANA,
  COUNTRIES_AFRICA_KEYS.ESWATINI,
  COUNTRIES_AFRICA_KEYS.NAMIBIA,
  COUNTRIES_AFRICA_KEYS.SOUTH_AFRICA,
  COUNTRIES_AFRICA_KEYS.CABO_VERDE,
  COUNTRIES_AFRICA_KEYS.COTE_DIVOIRE,
  COUNTRIES_AFRICA_KEYS.GHANA,
  COUNTRIES_AFRICA_KEYS.NIGERIA,
  COUNTRIES_AFRICA_KEYS.SAINT_HELENA,
  COUNTRIES_AFRICA_KEYS.ALGERIA,
  COUNTRIES_AFRICA_KEYS.EGYPT,
  COUNTRIES_AFRICA_KEYS.LIBYA,
  COUNTRIES_AFRICA_KEYS.MOROCCO,
  COUNTRIES_AFRICA_KEYS.TUNISIA,
  COUNTRIES_AFRICA_KEYS.WESTERN_SAHARA,
]

const COUNTRIES_AFRICA = mapCountriesToData(
  COUNTRIES_AFRICA_KEYS,
  COUNTRIES_AFRICA_VALUES,
  {
    type: OPTION_TYPE_IS_NON_LDC,
    elements: COUNTRIES_AFRICA_DISCARDED_LDCS,
  }
)

module.exports = {
  COUNTRIES_AFRICA_KEYS,
  COUNTRIES_AFRICA_VALUES,
  COUNTRIES_AFRICA,
}
