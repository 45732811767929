import React from 'react'
import { connect } from 'formik'
import {
  ProgramEligibility,
  CarbonStandard,
  Classifications,
} from '../components'

const _Type = () => {
  return (
    <>
      <CarbonStandard key="carbon-standard" />
      <Classifications key="classifications" />
      <ProgramEligibility key="program-eligibility" />
    </>
  )
}

export const Type = connect(_Type)
