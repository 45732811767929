import React, { useRef, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { toPairs, fromPairs, isUndefined } from 'lodash'
import Url from 'url-parse'
import { useApi } from 'containers/api/useApi'

export const FetchContext = React.createContext()

/* :: object -> object */
const alphaSortedKeys = (o) =>
  fromPairs(toPairs(o).sort(([k1], [k2]) => (k1 < k2 ? -1 : 1)))

/**
 @NOTE: Deterministic namspace for given aboslute or relative url with query
 [1]. Ensures `/foo?a=1&b=2` and `/foo?b=2&a=1` have the same namespace
 */
/* :: string -> string */
const getNamespace = (url) => {
  const shouldParseQuery = true
  const parsedUrl = new Url(url, shouldParseQuery)

  parsedUrl.query = alphaSortedKeys(parsedUrl.query) /* [1] */

  return parsedUrl.toString()
}

const FetchProvider = ({ children }) => {
  const listenersRef = useRef([])
  const api = useApi()
  let cache = useRef(Object.create(null)).current

  /* :: string -> object */
  const readCache = useCallback((url) => cache[getNamespace(url)], [cache])

  /* :: Function -> Function */
  const watchCache = (fn) => {
    listenersRef.current.push(fn)

    const unsubscribe = () => {
      listenersRef.current = listenersRef.current.filter(
        (listener) => listener !== fn
      )
    }

    return unsubscribe
  }

  const value = useMemo(() => {
    const writeCache = (url, state) => {
      cache[getNamespace(url)] = state
      listenersRef.current.forEach((fn) => fn(url))
    }

    const clearCache = () => {
      const urls = Object.keys(cache).map((url) =>
        url.replace(new Url(url, true).origin, '')
      )

      cache = {}

      listenersRef.current.forEach((fn) => urls.forEach((url) => fn(url, true)))
    }

    const fetchSingleUrl = async (url, { auth = true }) => {
      let cacheResult = readCache(url)
      if (isUndefined(cacheResult)) {
        cacheResult = { loading: false, error: null, data: null }
        writeCache(url, cacheResult)
      }

      /** @NOTE: Already fetching the url */
      if (cacheResult.loading) {
        return
      }

      writeCache(url, { ...cacheResult, loading: true })

      try {
        const response = await api.get(url, { auth })
        const newState = { loading: false, error: null, data: response.data }

        writeCache(url, newState)
      } catch (e) {
        const newState = {
          ...cacheResult,
          loading: false,
          error: e.response,
        }

        writeCache(url, newState)
      }
    }

    const fetch = async (urls, { auth = true } = {}) => {
      const urlList = Array.isArray(urls) ? urls : [urls]
      const fetches = urlList.map((url) => fetchSingleUrl(url, { auth }))

      await Promise.all(fetches)
    }

    return { readCache, watchCache, fetch, clearCache }
  }, [api, cache, readCache])

  return <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
}

FetchProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FetchProvider
