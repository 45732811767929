import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MuiText from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { webKitBox } from 'utils/styles'
import { SmallText } from 'components/Text'

export const Text = styled(MuiText)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  white-space: nowrap;
`
export const SmallTextWrapped = styled(MuiText)`
  font-size: 12px;
  line-height: 15px;
`

export const SmallTextGrey = styled(MuiText)`
  white-space: nowrap;
  font-size: 12px;
  line-height: 15px;
  color: #9097a1;
`

const useClampedStyles = makeStyles({
  clamp: {
    ...webKitBox,
    '-webkit-line-clamp': 2,
  },
})

export const ClampedText = ({ children, component: Component, ...props }) => {
  const classes = useClampedStyles()
  return (
    <Component className={classes.clamp} {...props}>
      {children}
    </Component>
  )
}

ClampedText.defaultProps = {
  component: SmallText,
}

ClampedText.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.object,
}
