const {
  isEmpty,
  isString,
  isFinite,
  round,
  findKey,
  isNumber,
  isNaN,
} = require('lodash')

const {
  PRICE_UNIT_LABEL,
  PRICE_TYPE,
  PRICE_TYPE_TEXT,
  PRICE_UNIT_TEXT,
  PRICE_INDEX,
  PRICE_INDEX_TEXT,
} = require('../../../constants')
const period = require('../delivery/period')

const { withDecimals } = require('../../../helpers')
const { isFlat } = require('./helpers')

const hasPrice = (info) => {
  const percentage = parseFloat(info.percentage)
  const index = info.index

  return Number.isFinite(percentage) && isString(index) && !isEmpty(index)
}

const getPriceUnitText = (info) => {
  if (info.unit) {
    const unit = PRICE_UNIT_LABEL[info.unit]

    return unit
  }

  return ''
}

const getPricePercentageText = (info) => {
  if (info.percentage) {
    const percentage = round(info.percentage, 3)
    return `${percentage}%`
  }

  return ''
}

const getPriceIndexText = (info) => {
  if (info.index) {
    const key = findKey(PRICE_INDEX, (v) => v === info.index) || ''
    return PRICE_INDEX_TEXT[key] || ''
  }
  return ''
}

const getPricePlusOrMinusText = (info) => {
  if (info.plusOrMinus) {
    return info.plusOrMinus
  }

  return ''
}

const getPriceAmountText = (info) => {
  if (info.amount) {
    if (isFinite(parseFloat(info.amount))) {
      const amount = withDecimals(info.amount)
      return amount.toString()
    }
    return info.amount
  }

  return ''
}

const getFixedPriceAmountText = (info) => {
  const priceAmount = info.fixedPrice
  const indexAmount = info.indexAmount
  let fixedPrice = ''
  if (isNumber(priceAmount)) {
    fixedPrice = priceAmount
    if (isFinite(parseFloat(priceAmount))) {
      const fixedNr = parseFloat(priceAmount)
      fixedPrice = isNaN(fixedNr) ? undefined : fixedNr.toFixed(2)
    }
  }

  return {
    fixedPrice,
    indexAmount,
  }
}

const calculateFixedPrice = (amount = 0) => {
  const fixedPrice = parseFloat(amount)
  return isNumber(fixedPrice) ? fixedPrice : undefined
}

const getPrice = (info) => {
  const { fixedPrice: fixedPriceAmount, indexAmount } =
    getFixedPriceAmountText(info)
  const fixedPrice = parseFloat(fixedPriceAmount)
  return {
    amount: info.amount === 'X' ? 'X' : calculateFixedPrice(info.amount),
    index: info.index,
    percentage: parseFloat(info.percentage) || undefined,
    delivery: period.text({ delivery: info.delivery }),
    plusOrMinus: info.plusOrMinus,
    unit: info.unit,
    ...(isNumber(fixedPrice) && !isNaN(fixedPrice)
      ? { fixedPrice, indexAmount }
      : {}),
  }
}

const getPriceText = (info) => {
  let summary, shortSummary, fixed

  if (info.percentage) {
    const percentage = getPricePercentageText(info)
    const index = getPriceIndexText(info)
    const delivery = info.delivery
      ? `(${period.text({ delivery: info.delivery })})`
      : ''

    summary = `${percentage} ${index} ${delivery}${
      isFlat(info.amount) ? ' Flat' : ''
    }`.replace('100% ', '')
    shortSummary = `${percentage} ${index}${
      isFlat(info.amount) ? ' Flat' : ''
    }`.replace('100% ', '')

    if (info.amount) {
      const amount = getPriceAmountText(info)
      const unit = PRICE_UNIT_TEXT[info.unit](amount)
      const plusOrMinus = getPricePlusOrMinusText(info)

      summary += ` ${plusOrMinus} ${unit}`
      shortSummary += ` ${plusOrMinus} ${amount}`
    }

    if (isNumber(info.fixedPrice)) {
      const { fixedPrice } = getFixedPriceAmountText(info)
      fixed = PRICE_UNIT_TEXT[info.unit](fixedPrice)
    }
  }

  return {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.FLOATING],
    shorter: shortSummary,
    short: summary,
    fixed,
    long: summary,
    amount: getPriceAmountText(info),
    plusOrMinus: getPricePlusOrMinusText(info),
    index: getPriceIndexText(info),
    percentage: getPricePercentageText(info),
    unit: getPriceUnitText(info),
  }
}

module.exports = {
  exists: hasPrice,
  get: getPrice,
  summary: getPriceText,
}
