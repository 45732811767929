const { CARBON_STANDARD_PREFIX } = require('./carbonPrefix')

const CARBON_STANDARD_KEYS = Object.freeze({
  CCER: 'CCER',
  ACR: 'ACR',
  CAR: 'CAR',
  CDM: 'CDM',
  GS: 'GS',
  VCS: 'VCS',
  Other: 'Other',
})

const CARBON_STANDARD_VALUES = Object.freeze({
  [CARBON_STANDARD_KEYS.CCER]: 'CCER',
  [CARBON_STANDARD_KEYS.ACR]: 'ACR',
  [CARBON_STANDARD_KEYS.CAR]: 'CAR',
  [CARBON_STANDARD_KEYS.CDM]: 'CDM',
  [CARBON_STANDARD_KEYS.GS]: 'GS',
  [CARBON_STANDARD_KEYS.VCS]: 'VCS',
  [CARBON_STANDARD_KEYS.Other]: 'Other',
})

const CARBON_STANDARD_FULL_KEYS = {}
Object.keys(CARBON_STANDARD_KEYS).forEach((key) => {
  CARBON_STANDARD_FULL_KEYS[key] = `${CARBON_STANDARD_PREFIX}.${key}`
})

const CARBON_STANDARD_PATHS = Object.values(CARBON_STANDARD_FULL_KEYS)

const CARBON_STANDARD_OPTIONS = Object.values(CARBON_STANDARD_KEYS).map(
  (carbonStandard) => {
    const option = {
      label: CARBON_STANDARD_VALUES[carbonStandard],
      value: CARBON_STANDARD_FULL_KEYS[carbonStandard],
    }
    if (carbonStandard === CARBON_STANDARD_KEYS.Other) {
      option.extra = {
        field: `${CARBON_STANDARD_PREFIX}.otherContent`,
        placeholder: 'Carbon Standard',
      }
    }
    return option
  }
)

module.exports = {
  CARBON_STANDARD_KEYS,
  CARBON_STANDARD_PATHS,
  CARBON_STANDARD_OPTIONS,
  CARBON_STANDARD_FULL_KEYS,
  CARBON_STANDARD_VALUES,
}
