import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import Text from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Interpolate } from 'components/Interpolate'

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    marginTop: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  },
  divider: {
    height: 2,
    margin: theme.spacing(2, 0),
    backgroundColor: '#656E7C',
  },
  createdAt: {
    color: theme.palette.text.hint,
  },
}))

export const Comment = ({ author, message, createdAt }) => {
  const classes = useStyles()

  return (
    <article>
      <Grid container justifyContent="space-between">
        <Text data-testid="comment-author" variant="body2">
          {author}
        </Text>
        <Text variant="body1" className={classes.createdAt}>
          {createdAt}
        </Text>
      </Grid>
      <div className={classes.messageContainer}>
        <Text data-testid="comment-text" variant="body1">
          <Interpolate>{message}</Interpolate>
        </Text>
      </div>

      <Divider className={classes.divider} />
    </article>
  )
}

Comment.propTypes = {
  author: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
}
