import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import { OrderCardLNG } from './OrderCardLNG'
import { OrderCardCarbon } from './OrderCardCarbon'
import { StyledLink as Link } from 'components/StyledLink'
import { getBaseSlug } from 'utils/useRouteBaseSlug'

const MarketInterestOrderCard = ({
  order,
  disabled,
  style,
  renderTopRight,
}) => {
  const OrderCard =
    order.context === APP_CONTEXT_TYPE.CARBON ? OrderCardCarbon : OrderCardLNG

  return (
    <Link
      to={(location) => `/${getBaseSlug(location)}/orders/${order.id}`}
      disabled={disabled}
    >
      <OrderCard order={order} style={style} renderTopRight={renderTopRight} />
    </Link>
  )
}

MarketInterestOrderCard.defaultProps = {
  disabled: false,
  style: {},
  renderTopRight: () => {},
}

MarketInterestOrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  style: PropTypes.object,
  renderTopRight: PropTypes.func,
  disabled: PropTypes.bool,
}

export default memo(MarketInterestOrderCard)
