import decodeJWT from 'jwt-decode'
import { isEmpty, isNil } from 'lodash'

export const isLoggedIn = (user) => !isEmpty(user.token)

export const hasValidSession = (user) => {
  const { token } = user

  if (isEmpty(token)) {
    return false
  }

  const expiry = decodeJWT(token).exp * 1000
  const now = Date.now()

  return now <= expiry
}

export const LOCAL_STORAGE_KEY = 'EmsurgeUser'
export const INITIAL_STATE = {
  token: null,
  name: null,
  surname: null,
  email: null,
  phone: null,
  jobTitle: null,
  companyId: null,
  adminRole: null,
}

export const getInitialUserState = () => {
  const userStored = window.localStorage?.getItem(LOCAL_STORAGE_KEY)
  const user = isNil(userStored) ? INITIAL_STATE : JSON.parse(userStored)
  return user
}
