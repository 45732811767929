const moment = require('moment')
const {
  DELIVERY_WINDOW_CARGO_PERIOD_CUSTOM,
  DELIVERY_WINDOW_CARGO_PERIOD_TEXT,
} = require('../../../constants')

const getCargoDeliveryPeriodText = (cargo) => {
  return DELIVERY_WINDOW_CARGO_PERIOD_TEXT[cargo.deliveryPeriod] || ''
}

const getCargoDeliveryWindowText = (cargo) => {
  if (cargo.deliveryPeriod !== DELIVERY_WINDOW_CARGO_PERIOD_CUSTOM.CUSTOM) {
    return getCargoDeliveryPeriodText(cargo)
  }

  return `${moment(cargo.from).format('DD/MM')} - ${moment(cargo.to).format(
    'DD/MM'
  )}`
}

const getCargos = (terms = {}) => {
  const { deliveryWindow = {} } = terms
  const cargos = deliveryWindow.cargos || []

  return cargos.map((cargo) => ({
    deliveryPeriod: cargo.cargoWindow,
    from: new Date(cargo.from),
    to: new Date(cargo.to),
  }))
}

const getCargosSummary = (terms) => {
  const cargos = getCargos(terms)

  return cargos.map((cargo) => ({
    deliveryPeriod: getCargoDeliveryPeriodText(cargo),
    deliveryWindow: getCargoDeliveryWindowText(cargo),
    from: moment(cargo.from).format('DD MMM YYYY'),
    to: moment(cargo.to).format('DD MMM YYYY'),
  }))
}

const getCargosText = (terms) => {
  const cargoSummaries = getCargos(terms)

  return cargoSummaries.map(
    (cargoSummary) =>
      `${moment(cargoSummary.from).format('DD MMM YYYY')} to ${moment(
        cargoSummary.to
      ).format('DD MMM YYYY')}`
  )
}

module.exports = {
  get: getCargos,
  summary: getCargosSummary,
  text: getCargosText,
}
