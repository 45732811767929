import React, { useEffect } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router'
import { MatrixGrid, MarketLabelRow, PeriodBlock } from './components/Matrix'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { useGridPrice } from 'api'
import { MarketGridEdit } from 'screens/marketGridEdit/MarketGridEdit'
import { MARKET_GRID } from 'routes'

export const MarketGridView = () => {
  const { id } = useParams()
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const { data: grid, isLoading, isError } = useGridPrice(id)
  const columns = grid?.markets?.length

  useEffect(() => {
    if (isError) {
      history.push(MARKET_GRID)
    }
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <MatrixGrid columns={columns}>
        <MarketLabelRow grid={grid} />
        {grid?.data?.map((data, i) => (
          <PeriodBlock
            key={`period-block-${data.period}-${i}`}
            {...data}
            marketDepth={grid?.marketDepth}
          />
        ))}
      </MatrixGrid>
      <Route
        path={`${path}/edit`}
        render={() => (
          <MarketGridEdit id={id} onClose={() => history.push(url)} />
        )}
      />
    </>
  )
}
