import React, { useCallback } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { connect } from 'formik'
import { mapFormikValuesToOrder } from 'screens/orderCreateCommon/helpers'
import { ConfirmShareButton } from 'screens/orderCreateCommon/components/ConfirmShareButton'
import { useDebouncedState } from 'utils/useDebouncedState'
import { useCanShareOrderWithBroker } from 'hooks'

export const ButtonPlaceholder = styled.div`
  width: 70px;
`

export const ShareButton = connect(({ formik, actions }) => {
  const stateUpdater = useCallback((oldState) => !oldState, [])
  const [isDebouncing] = useDebouncedState(true, stateUpdater, 2000) // wait for 2 seconds to enable share button
  const hasSharePermission = useCanShareOrderWithBroker(
    mapFormikValuesToOrder(formik.values)
  )

  if (!hasSharePermission) {
    return <ButtonPlaceholder />
  }

  return (
    <ConfirmShareButton
      disabled={!formik.isValid || formik.isSubmitting || isDebouncing}
      onCancel={actions.close}
      size="small"
      style={{
        maxWidth: '150px',
      }}
    >
      Share with Emstream
    </ConfirmShareButton>
  )
})

export const SaveButton = connect(({ formik, actions }) => {
  const stateUpdater = useCallback((oldState) => !oldState, [])
  const [disabled] = useDebouncedState(true, stateUpdater, 2000) // wait for 2 seconds to enable save button

  return (
    <Button
      data-testid="order-creation-save-button"
      id="saveOrderButton"
      color="primary"
      variant="contained"
      size="small"
      onClick={actions.save || formik.submitForm}
      disabled={disabled || formik.isSubmitting}
    >
      Save
    </Button>
  )
})

export const NextButton = connect(({ formik, ...props }) => (
  <Button
    data-testid="order-creation-next-button"
    color="primary"
    variant="contained"
    size="small"
    {...props}
    disabled={!formik.isValid}
  >
    Next
  </Button>
))

export const SkipToEndButton = connect(({ formik, ...props }) => (
  <Button
    data-testid="order-creation-skip-button"
    color="secondary"
    variant="contained"
    size="small"
    {...props}
    disabled={!formik.isValid}
  >
    Skip
  </Button>
))
