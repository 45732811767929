import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/styles'
import { connect } from 'formik'
import { ViewCounterParties } from './TradeCounterParties'

const TradeDetail = ({ termsheet }) => {
  return <ViewCounterParties termsheet={termsheet} />
}

TradeDetail.propTypes = {
  termsheet: PropTypes.object.isRequired,
}

export default connect(withTheme(TradeDetail))
