import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import { withSnackbar } from 'notistack'
import { string, object, array } from 'yup'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { getDateTime, getDateTimeLocal } from 'utils/dates'

export const getDefaultActionItemValues = (user) => ({
  title: '',
  assignee: `${user.name} ${user.surname}`,
  dueDate: getDateTimeLocal(),
  isChecked: false,
})

const getInitialValues = () => {
  return {
    eventDate: getDateTime(),
    companyName: '',
    tags: '',
    priority: '1',
    note: '',
    actionItems: [],
    attendees: '',
  }
}

export const FormSchema = object().shape({
  eventDate: string().required('Required'),
  companyName: string().required('Required'),
  country: string().max(255, 'Location must be at most 255 characters'),
  actionItems: array().of(
    object().shape({
      title: string().required('Required'),
      assignee: string().required('Required'),
      dueDate: string().required('Required'),
    })
  ),
})

export const getAPIData = (values) => {
  const eventDate = values.eventDate
  const attendees = values.attendees
    .split(',')
    .map((s) => s.trim())
    .filter((s) => s)
  const tags = values.tags
    .split(',')
    .map((s) => s.trim())
    .filter((s) => s)
  const actionItems = values.actionItems.map((item) => ({
    ...item,
    dueDate: new Date(item.dueDate).getTime(),
  }))

  return {
    ...values,
    id: undefined,
    eventDate,
    attendees,
    tags,
    actionItems,
  }
}

export const FormProvider = ({
  children,
  enqueueSnackbar,
  onSuccess,
}) => {
  const api = useApi()

  const handleSubmit = async (values, actions) => {
    const data = getAPIData(values)

    try {
      await api.post('/notes', data)
      actions.resetForm()
      enqueueSnackbar('Note created successfully', { variant: 'success' })
      onSuccess()
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Note creation failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
    >
      {children}
    </Formik>
  )
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
}

export default compose(withSnackbar, withRouter)(FormProvider)
