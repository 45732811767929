import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DoneIcon from '@material-ui/icons/Done'
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft'
import { withProps, compose } from 'recompose'
import { withSnackbar } from 'notistack'
import IconButton from 'components/iconButton/IconButton'
import withParams from 'utils/withRouteParams'
import { ADMIN_VIEW_USERS } from 'routes'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const CancelButton = withProps({
  icon: ArrowBackIcon,
  color: 'default',
  variant: 'contained',
})(IconButton)

const ConfirmButton = withProps({
  icon: DoneIcon,
  color: 'primary',
  variant: 'contained',
})(IconButton)

const UserDeleteConfirmDialog = ({
  children,
  history,
  user,
  enqueueSnackbar,
}) => {
  const [isOpen, setOpen] = useState(false)
  const open = () => setOpen(true)
  const close = () => setOpen(false)

  const api = useApi()

  const onConfirm = async (close) => {
    try {
      await api.delete(`/persons/${user.id}`)
      enqueueSnackbar('User removal successful', { variant: 'success' })
      history.push(ADMIN_VIEW_USERS)
    } catch (error) {
      close()

      const message = getApiErrorMessage({
        error,
        defaultMessage: 'User removal not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <>
      <Dialog open={isOpen} onClose={close} maxWidth="sm">
        <DialogContent>
          <DialogContentText style={{ fontWeight: 'bold' }}>
            Are you sure you want to delete {user.name} {user.surname} user?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <CancelButton onClick={close}>Cancel</CancelButton>
          <ConfirmButton onClick={() => onConfirm(close)}>
            Confirm
          </ConfirmButton>
        </DialogActions>
      </Dialog>

      {children({ open })}
    </>
  )
}

UserDeleteConfirmDialog.propTypes = {
  children: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

const enhance = compose(withParams, withSnackbar)

export default enhance(UserDeleteConfirmDialog)
