import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@material-ui/core/TextField'
import { Field } from 'formik'
import { isEmpty, get, merge, identity, isNil } from 'lodash'
import { inputLabelProps } from './styles'

/* :: (object, object, object) -> object */
export const getTextFieldProps = (
  { shouldSave, transform, ...props },
  formikFieldProps,
  formikForm
) => {
  const { name, type } = props
  const fieldError = get(formikForm.errors, name)
  const showError =
    get(formikForm.touched, name) === true && !isEmpty(fieldError)

  const inputProps = ['email', 'password'].includes(type)
    ? {
        ...props.inputProps,
        spellCheck: false,
        autoCapitalize: 'none',
        autoCorrect: 'off',
      }
    : props.inputProps

  const InputLabelProps = merge(props.InputLabelProps, inputLabelProps)

  const textFieldProps = {
    ...props,
    ...formikFieldProps,
    inputProps,
    InputLabelProps,
    value: isNil(formikFieldProps.value) ? '' : formikFieldProps.value,
    id: props.id || props.name,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: formikForm.isSubmitting || props.disabled,
    onChange(evt) {
      const { value } = evt.target
      if (value) {
        evt.target.value = transform(evt.target.value)
      }
      if (shouldSave([evt])) {
        formikFieldProps.onChange(evt)
      }
      props.onChange(evt)
    },
    onBlur(evt) {
      const { value } = evt.target
      if (value) {
        evt.target.value = transform(evt.target.value)
      }
      formikFieldProps.onBlur(evt)
      props.onBlur(evt)
    },
  }

  return textFieldProps
}

const TextFieldWithDefault = ({ formikProps: { field, form }, ...props }) => {
  const textProps = getTextFieldProps(props, field, form)

  useEffect(() => {
    if (props.defaultValue && isNil(field.value)) {
      // if the formik field has no value a default one is passed, populate the formik field with that value
      form.setFieldValue(props.name, props.defaultValue)
    }
  }, [field.value, form, props.name, props.defaultValue])

  return <MuiTextField {...textProps} />
}

TextFieldWithDefault.propTypes = {
  formik: PropTypes.object,
  formikProps: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
}

const FormikTextField = (props) => (
  <Field name={props.name}>
    {(formikProps) => (
      <TextFieldWithDefault {...props} formikProps={formikProps} />
    )}
  </Field>
)

FormikTextField.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  shouldSave: () => true,
  transform: identity,
}

FormikTextField.propTypes = {
  ...MuiTextField.propTypes,
  name: PropTypes.string.isRequired,
  shouldSave: PropTypes.func,
  transform: PropTypes.func,
}

export default FormikTextField
