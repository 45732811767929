import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Header } from './Header'
import { TermsheetInfo } from './TermsheetInfo'
import { BidAskContainer } from './BidAskContainer'
import { Card } from 'components/Card'
import { StyledLink as Link } from 'components/StyledLink'
import { getBaseSlug } from 'utils/useRouteBaseSlug'

export const TermsheetCard = ({
  termsheet,
  explanation,
  label,
  author,
  date,
  ...cardProps
}) => {
  const bidOrder = termsheet.bidOrder
  const askOrder = termsheet.askOrder
  return (
    <Card {...cardProps}>
      <Link
        to={(location) =>
          `/${getBaseSlug(location)}/termsheets/${termsheet.id}`
        }
      >
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Header label={label} author={author} explanation={explanation} />
          <TermsheetInfo termsheet={termsheet} date={date} />
        </Grid>
      </Link>

      <Grid container justifyContent="space-between" alignItems="flex-start">
        <BidAskContainer bidOrder={bidOrder} askOrder={askOrder} />
      </Grid>
    </Card>
  )
}

TermsheetCard.propTypes = {
  termsheet: PropTypes.object.isRequired,
  label: PropTypes.string,
  author: PropTypes.node,
  date: PropTypes.string,
}
