import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/styles'
import Text from '@material-ui/core/Typography'

const Header = ({ theme }) => (
  <header style={{ marginBottom: theme.spacing(2) }}>
    <Text variant="h1" gutterBottom>
      Users
    </Text>
    <Text variant="h6">
      Add entities to give the broker access to their orders
    </Text>
  </header>
)

Header.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default withTheme(Header)
