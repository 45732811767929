import { useQuery } from 'react-query'
import { $O, PRICE_TYPE } from 'emsurge-selectors'
import { useApi } from 'containers/api/useApi'
import { PRICE_INDEXES_ALL_ORDERS_KEY } from 'api/constants'
import { getMainIndexSlug } from 'hooks/useImpliedPrice.helpers'

const FIVE_MINUTES = 5 * 60 * 1000

export const usePriceIndexForOrders = (orders) => {
  const api = useApi()

  const isFloatingPrice = (type) => type === PRICE_TYPE.FLOATING

  const get = async () => {
    const indexes = {}
    const filteredOrders = orders.filter((order) =>
      order ? isFloatingPrice($O.price.type(order)) : true
    )
    await Promise.all(
      filteredOrders.map(async (order) => {
        if (order) {
          const { index, delivery } = $O.price.get(order)
          const { period } = $O.delivery.text(order)
          const indexPeriod = delivery || period
          const orderIndex = getMainIndexSlug(index || '')
          const priceIndex = await api.getPriceIndex(
            { markets: [orderIndex], period: indexPeriod.toUpperCase() }
          )
          indexes[index] = indexes[index] || {}
          indexes[index][delivery] = priceIndex[orderIndex] || []
        }
      })
    )
    return indexes
  }

  return useQuery([PRICE_INDEXES_ALL_ORDERS_KEY, orders], () => get(), {
    enabled: true,
    staleTime: FIVE_MINUTES,
  })
}
