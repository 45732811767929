import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withTheme } from '@material-ui/styles'

const Option = ({
  theme,
  xs,
  children,
  onClick,
  active,
  style,
  value,
  disabled,
  ...props
}) => (
  <Grid
    item
    xs={xs}
    onClick={disabled ? () => {} : onClick}
    value={value}
    style={{
      background: active
        ? theme.palette.background.secondary
        : theme.palette.background.default,
      opacity: disabled ? 0.5 : 1,
      ...style,
    }}
    data-active={active.toString()}
    data-disabled={disabled.toString()}
    {...props}
  >
    {children}
  </Grid>
)

Option.defaultProps = {
  xs: 12,
  onClick: () => {},
  active: false,
  style: {},
  value: '',
  disabled: false,
}

Option.propTypes = {
  theme: PropTypes.object.isRequired,
  xs: PropTypes.number,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
  disabled: PropTypes.bool,
}

export const SelectableGridOption = withTheme(Option)
