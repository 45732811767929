const getDeliveryType = (order) => {
  return order.delivery.type
}

const getDeliveryTypeText = (order) => {
  const type = getDeliveryType(order)
  // TODO: the labels are being stored instead of the values. This should be DELIVERY_TYPE_TEXT[type]
  return type // DELIVERY_TYPE_TEXT[type]
}

module.exports = {
  get: getDeliveryType,
  text: getDeliveryTypeText,
}
