import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import {
  REGION_COUNTRY_KEYS,
  REGION_COUNTRY_OPTIONS,
  REGION_COUNTRY_PREFIX,
  REGION_COUNTRIES_PREFIX,
  REGION_LDCS_ONLY_PREFIX,
  REGION_OPTIONS_PREFIX,
  REGIONS_OPTIONS,
  REGIONS_KEYS,
  REGION_TYPE_KEYS,
  REGIONS_FULL_KEYS,
  REGION_TYPE_OPTIONS,
  REGION_TYPE_PREFIX,
  REGION_COUNTRIES,
  TRADING_TYPE,
} from 'emsurge-selectors'
import { get } from 'lodash'
import styled from 'styled-components'
import { Grid, Switch } from '@material-ui/core'
import { DropdownSelectOptions } from '../components/DropdownSelectOptions'
import { SwitchComponent } from '../components/Switch'
import { CheckboxGroup } from '../components'

const SwitchWrapper = styled.div`
  margin-top: 16px;
`

const MAPPED_REGIONS_TYPE = {
  false: REGION_TYPE_KEYS.ALL,
  true: REGION_TYPE_KEYS.SPECIFIC,
}

const ADD_REGION = 'ADD_REGION'
const REMOVE_REGION = 'REMOVE_REGION'
const NONE = 'NONE'

const _Region = ({ formik: { values, setFieldValue } }) => {
  const tradingType = get(values, 'tradingType')
  const [checked, setChecked] = useState(
    get(values, REGION_TYPE_PREFIX) === REGION_TYPE_KEYS.SPECIFIC
  )
  const [forceRefresh, setForceRefresh] = useState(0)
  const [previousSelectedRegions, setPreviousSelectedRegions] = useState([])
  const [countryKeys, setCountryKeys] = useState(REGION_COUNTRY_KEYS)

  useEffect(() => {
    handleCountryKeysList()
    filterRegionsByLDCs()
    handleDisabledRegions()
    if (getValuesLdcsOnly()) {
      setSwitch(true)
      onChangeListOptions([])
    }
    setForceRefresh(forceRefresh + 1)
  }, [values.regions.ldcsOnly])

  useEffect(() => {
    setPreviousSelectedRegions(getCurrentSelectedRegions())
  }, [])

  const getDiff = (a, b) => a.filter((item) => !b.includes(item))
  const getListCountriesByRegion = (region) =>
    Object.values(REGION_COUNTRIES[region] || {})
  const getValuesLdcsOnly = () => get(values, REGION_LDCS_ONLY_PREFIX) || false
  const getValuesCountries = () => get(values, REGION_COUNTRIES_PREFIX) || []
  const getValuesRegions = () => get(values, REGION_OPTIONS_PREFIX) || {}
  const getCountriesKeys = (countriesKeys = REGIONS_KEYS) =>
    Object.values(countriesKeys)
  const getRegionCountryKeys = () => Object.values(REGION_COUNTRY_KEYS)
  const getRegionsOptions = () => Object.values(REGIONS_OPTIONS)

  const setValuesCountries = (countries) =>
    setFieldValue(REGION_COUNTRIES_PREFIX, countries)
  const setValuesType = (type) => setFieldValue(REGION_TYPE_PREFIX, type)
  const setValuesLDCsOnly = (ldcsOnly) =>
    setFieldValue(REGION_LDCS_ONLY_PREFIX, ldcsOnly)
  const setValuesRegion = (region, value) =>
    setFieldValue(REGIONS_FULL_KEYS[region], value)

  const getCountriesNameByRegion = (region) =>
    getListCountriesByRegion(region).map(({ key }) => key)
  const addNewRegions = (regions) => getMissingCountriesByRegion(regions)
  const getOnlyLdcs = () => getValuesCountries().filter(({ focus }) => focus)
  const regionHasLDCs = (region) =>
    getListCountriesByRegion(region).find(({ focus }) => focus)
  const getNonLdcsByRegion = (region) =>
    getListCountriesByRegion(region).filter(({ focus }) => !focus)
  const getTotalNonLDCs = (region) => getNonLdcsByRegion(region).length
  const getLDCsCountriesKeys = () => {
    const countriesKeys = {}
    getRegionCountryKeys().forEach((country) => {
      const { focus, key } = country
      if (focus) {
        countriesKeys[key] = country
      }
    })
    return countriesKeys
  }
  const handleCountryKeysList = () => {
    setCountryKeys(
      getValuesLdcsOnly() ? getLDCsCountriesKeys() : REGION_COUNTRY_KEYS
    )
  }

  const diffRegions = (previous, current) => {
    const previousLength = previous.length
    const currentLength = current.length
    if (previousLength < currentLength) {
      return {
        type: ADD_REGION,
        regions: getDiff(current, previous),
      }
    }
    if (previousLength > currentLength) {
      return {
        type: REMOVE_REGION,
        regions: getDiff(previous, current),
      }
    }
    return {
      type: NONE,
    }
  }

  const getCountriesByRegion = (region) => {
    return getListCountriesByRegion(region).map(({ key, label, focus }) => ({
      key,
      value: label,
      focus,
    }))
  }

  const getMissingCountriesByRegion = (regions) => {
    const ldcsOption = getValuesLdcsOnly()
    const newCountries = getValuesCountries()
    const newCountriesKeys = newCountries.map(({ key }) => key)
    regions.forEach((region) => {
      const countriesRegion = getCountriesByRegion(region) || []
      countriesRegion.forEach((country) => {
        const allowedToInclude = !ldcsOption || (ldcsOption && country.focus)
        if (!newCountriesKeys.includes(country.key) && allowedToInclude) {
          newCountries.push(country)
        }
      })
    })
    return newCountries
  }

  const removeOldRegions = (regions) => {
    const previousSelectedCountries = getValuesCountries()
    const countriesByRegion = []
    regions.forEach((region) =>
      countriesByRegion.push(...getCountriesNameByRegion(region))
    )
    return previousSelectedCountries.filter(
      (country) => !countriesByRegion.includes(country.key)
    )
  }

  const getCurrentSelectedRegions = () => {
    const regionsOptions = getValuesRegions()
    return getCountriesKeys().filter((regionKey) => regionsOptions[regionKey])
  }

  const getCountriesByLDCs = () =>
    getValuesLdcsOnly()
      ? getOnlyLdcs()
      : getMissingCountriesByRegion(getCurrentSelectedRegions())

  const handleDisabledRegions = () => {
    const ldcsOption = getValuesLdcsOnly()
    getRegionsOptions().forEach((region) => {
      region.disabled = ldcsOption && !regionHasLDCs(region.key)
    })
  }

  const filterRegionsByLDCs = () => {
    setValuesCountries(getCountriesByLDCs())
    setForceRefresh(forceRefresh + 1)
  }

  const handleFilteredRegions = (currentSelectedRegions, region) => {
    if ([...currentSelectedRegions].includes(region)) {
      return currentSelectedRegions.filter((reg) => reg !== region)
    }
    return [...currentSelectedRegions, region]
  }

  const onChangeRegion = (region) => {
    const handledRegion = region.split('.').pop()
    const currentSelectedRegions = getCurrentSelectedRegions()
    const filteredRegions = handleFilteredRegions(
      currentSelectedRegions,
      handledRegion
    )
    setPreviousSelectedRegions(filteredRegions)
    const { type, regions } = diffRegions(
      previousSelectedRegions,
      filteredRegions
    )
    if (type !== NONE) {
      const fn = type === ADD_REGION ? addNewRegions : removeOldRegions
      const newCountries = fn(regions)
      setValuesCountries(newCountries)
      setForceRefresh(forceRefresh + 1)
      setSwitch(filteredRegions.length !== Object.keys(REGIONS_KEYS).length)
    }
  }

  const setSwitch = (boolChecked) => {
    const checkedValue = MAPPED_REGIONS_TYPE[boolChecked]
    setChecked(boolChecked)
    setValuesType(checkedValue)
  }

  const onSwitchChange = async (evt) => {
    const checkedOption = evt?.target?.checked
    const allOptionSelected =
      MAPPED_REGIONS_TYPE[checkedOption] === REGION_TYPE_KEYS.ALL
    const countriesFN = allOptionSelected ? addNewRegions : removeOldRegions
    setSwitch(checkedOption)
    setValuesLDCsOnly(false)
    setValuesCountries(countriesFN(getCountriesKeys()))
    getCountriesKeys().forEach((region) =>
      setValuesRegion(region, allOptionSelected)
    )
    setPreviousSelectedRegions(allOptionSelected ? getCountriesKeys() : [])
    setForceRefresh(forceRefresh + 1)
  }

  const nrCountriesByRegionInList = (listOptions) => {
    const groups = {}
    listOptions.forEach(({ key }) => {
      const { groupBy } = REGION_COUNTRY_KEYS[key] || {}
      if (groupBy) {
        groups[groupBy] = (groups[groupBy] || 0) + 1
      }
    })
    return groups
  }

  const onChangeListOptions = (listOptions) => {
    const ldcsOption = getValuesLdcsOnly()
    const countriesToBeSelected = nrCountriesByRegionInList(listOptions)
    const partialSelected = []
    const selectedRegions = []
    Object.keys(REGION_COUNTRIES).forEach((region) => {
      const nrCountriesByRegion =
        Object.keys(REGION_COUNTRIES[region] || {}).length -
        (ldcsOption ? getTotalNonLDCs(region) : 0)
      const nrSelectedCountriesByRegion =
        nrCountriesByRegion - (countriesToBeSelected[region] || 0)
      const regionIsSelected =
        nrSelectedCountriesByRegion === nrCountriesByRegion &&
        nrCountriesByRegion > 0
      setValuesRegion(region, regionIsSelected)
      if (regionIsSelected) {
        selectedRegions.push(region)
      }
      if (
        nrSelectedCountriesByRegion < nrCountriesByRegion &&
        nrSelectedCountriesByRegion > 0
      ) {
        partialSelected.push(region)
      }
    })
    if (partialSelected.length) {
      setSwitch(true)
    }
    setPreviousSelectedRegions(selectedRegions)
  }

  return (
    <Grid>
      {tradingType === TRADING_TYPE.BID && (
        <SwitchWrapper>
          <label>{REGION_TYPE_OPTIONS[REGION_TYPE_KEYS.ALL]}</label>
          <Switch
            inputProps={{ 'data-testid': 'region-all-specific-switch' }}
            checked={checked}
            onChange={onSwitchChange}
            color="primary"
            name={REGION_TYPE_PREFIX}
          />
          <label>{REGION_TYPE_OPTIONS[REGION_TYPE_KEYS.SPECIFIC]}</label>
        </SwitchWrapper>
      )}
      <CheckboxGroup
        key="radio-button-group-regions"
        label="Select one or more regions to refine the search below"
        fieldPrefix={REGION_OPTIONS_PREFIX}
        options={REGIONS_OPTIONS}
        onChange={onChangeRegion}
      />
      <DropdownSelectOptions
        optionsKeys={countryKeys}
        fullOptions={REGION_COUNTRY_OPTIONS}
        inputPath={REGION_COUNTRY_PREFIX}
        optionsPath={REGION_COUNTRIES_PREFIX}
        inputTitle="Add individual country"
        forceRefresh={forceRefresh}
        onChangeListOptions={onChangeListOptions}
      >
        <SwitchComponent path={REGION_LDCS_ONLY_PREFIX} />
      </DropdownSelectOptions>
    </Grid>
  )
}

export const Region = connect(_Region)
