import moment from 'moment'
import { isNil, toUpper } from 'lodash'
import { $O, TRADING_TYPE } from 'emsurge-selectors'
import {
  isCustomDeliveryPeriod,
  getTotalCargos,
  isGasYearDeliveryPeriod,
  isCalendarYearDeliveryPeriod,
} from 'screens/orderCreate/components/DeliveryWindow.helpers'

const isThirdPartyOrder = ({ thirdParty }) => !isNil(thirdParty)

const getEntityCode = ({ thirdParty, company, entity }) => {
  if (isThirdPartyOrder({ thirdParty })) {
    return `${thirdParty.companyName ? thirdParty.companyName : '---'} ${
      thirdParty.entityName ? thirdParty.entityName : ''
    }`
  }

  if (isNil(company) || isNil(entity)) {
    return null
  }

  return `${company.code}${entity.code}`
}

const getDeliveryPeriodLabel = ({ delivery }) => {
  const { period } = delivery
  if (period === 'custom') {
    return `${delivery.customFrom} - ${delivery.customTo}`
  }

  if (isGasYearDeliveryPeriod(period)) {
    return `GY-${delivery.year}`
  }

  if (isCalendarYearDeliveryPeriod(period)) {
    return `CAL-${delivery.year}`
  }

  return delivery.year
}

const getShortYear = ({ delivery: { period, year } }) => {
  if (!year || isCustomDeliveryPeriod(period)) {
    return ''
  }

  if (isCalendarYearDeliveryPeriod(period) || isGasYearDeliveryPeriod(period)) {
    return `-${year}`
  }

  const yearStr = `${year}`

  return `-${yearStr.slice(2)}`
}

const getShortPeriod = ({ delivery: { period, customFrom, customTo } }) => {
  if (!period || period === 'year') {
    return 'CAL'
  }

  if (isGasYearDeliveryPeriod(period)) {
    return 'GY'
  }

  if (isCustomDeliveryPeriod(period)) {
    return `${moment(customFrom).format('MMM-YY')} ${moment(customTo).format(
      'MMM-YY'
    )}`
  }

  return toUpper(period.slice(0, 3))
}

const getDeliveryWindowLabel = ({ deliveryWindow }) => {
  if (!deliveryWindow) {
    return ''
  }

  return `Arrival Period shall be a ${deliveryWindow.arrival.window} hour period commencing ${deliveryWindow.arrival.period} (local time at Discharge Port using 24hr clock)`
}

const getCargoLabel = ({ cargo, cargoIndex }) => {
  return `Cargo ${cargoIndex}: ${moment(cargo.from).format(
    'DD MMM YYYY'
  )} to ${moment(cargo.to).format('DD MMM YYYY')}`
}

export const sortByDescendingDate = (tsA, tsB) =>
  moment(tsA.createdAt).isAfter(moment(tsB.createdAt)) ? -1 : 1

const getTableDataFromOrders = (termsheets, user) => {
  return termsheets
    .map((termsheet) => {
      const {
        createdAt,
        delivery,
        id: termsheetId,
        orders,
        shortId,
        thirdParty,
      } = termsheet

      const buyerOrder = orders.find(
        (order) => order.tradingType === TRADING_TYPE.BID
      )
      const sellerOrder = orders.find(
        (order) => order.tradingType === TRADING_TYPE.ASK
      )

      return {
        buyer: getEntityCode(buyerOrder),
        buyerOrder,
        cargoes: getTotalCargos(termsheet),
        createdAt,
        location: delivery.location,
        period: getDeliveryPeriodLabel(termsheet),
        price: $O.price.summary(termsheet).short,
        seller: getEntityCode(sellerOrder),
        sellerOrder,
        shortId,
        termsheetId,
        thirdParty,
        user,
      }
    })
    .sort(sortByDescendingDate)
}

export {
  getTableDataFromOrders,
  getDeliveryPeriodLabel,
  getEntityCode,
  isThirdPartyOrder,
  getShortYear,
  getShortPeriod,
  getDeliveryWindowLabel,
  getCargoLabel,
}
