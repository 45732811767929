const REGISTRATION_STATUS = Object.freeze({
  ACTIVE: 'active',
  APPLICATION_STARTED: 'application_started',
  INACTIVE: 'inactive',
  PENDING_APPROVAL: 'pending_approval',
  SUSPENDED: 'suspended',
})

module.exports = {
  REGISTRATION_STATUS,
}
