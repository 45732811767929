import { connect } from 'formik'
import PropTypes from 'prop-types'
import { getFrequencyOptions } from 'screens/orderCreate/components/DeliveryWindow.helpers'

const _SelectCargosDeliveryFrequency = ({ formik, children }) => {
  const { values } = formik
  const options = getFrequencyOptions(values.delivery.period, {
    customFrom: values.delivery.customFrom,
    customTo: values.delivery.customTo,
  })

  return children({ options })
}

_SelectCargosDeliveryFrequency.defaultProps = {
  children: () => {},
}

_SelectCargosDeliveryFrequency.propTypes = {
  formik: PropTypes.object.isRequired,
  children: PropTypes.func,
}

export const SelectCargosDeliveryFrequency = connect(
  _SelectCargosDeliveryFrequency
)
