import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import MuiTextField from '@material-ui/core/TextField'
import Add from '@material-ui/icons/Add'
import { useTheme } from '@material-ui/styles'

export const CommentCreate = ({ addComment }) => {
  const { spacing } = useTheme()
  const [comment, setComment] = useState('')
  const onAddComment = () => {
    addComment(comment)
    setComment('')
  }

  return (
    <>
      <MuiTextField
        data-testid="add-comment-field"
        label="Add comment"
        multiline
        rows={5}
        fullWidth
        margin="normal"
        value={comment}
        onChange={(evt) => setComment(evt.target.value)}
      />
      <Button
        data-testid="post-comment-button"
        onClick={onAddComment}
        label={'PostButton'}
        disabled={!comment.length}
        variant="contained"
        color="primary"
        style={{ marginTop: spacing(2) }}
      >
        <Add />
        Post
      </Button>
    </>
  )
}

CommentCreate.propTypes = {
  addComment: PropTypes.func.isRequired,
}
