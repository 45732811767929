import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { isEmpty, get } from 'lodash'
import MuiTextField from '@material-ui/core/TextField'
import { inputLabelProps } from './styles'

export const DeferredTextField = ({
  name,
  shouldSave,
  onBlur,
  onChange,
  InputLabelProps,
  ...props
}) => (
  <Field name={name}>
    {({ field, form }) => {
      const fieldError = get(form.errors, name)
      const showError = !isEmpty(fieldError)

      return (
        <MuiTextField
          {...props}
          style={{ ...inputLabelProps, ...InputLabelProps }}
          defaultValue={field.value}
          disabled={form.isSubmitting}
          error={showError}
          helperText={showError ? fieldError : ''}
          onBlur={(evt) => {
            if (!shouldSave([evt])) {
              return
            }
            const { value } = evt.target
            form.setFieldValue(name, value)
            form.setFieldTouched(name)
            onBlur(evt)
          }}
          onChange={(evt) => {
            onChange(evt)
          }}
        />
      )
    }}
  </Field>
)

DeferredTextField.defaultProps = {
  shouldSave: () => true,
  onBlur: () => {},
  onChange: () => {},
  InputLabelProps: {},
}

DeferredTextField.propTypes = {
  name: PropTypes.string.isRequired,
  shouldSave: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  InputLabelProps: PropTypes.object,
}
