import { $U } from 'emsurge-selectors'

export const mapToTraderView = (trader) => {
  return {
    id: trader.id,
    name: $U.fullName.get(trader),
    email: trader.email,
    phone: trader.phone,
    jobTitle: trader.jobTitle,
    code: trader.entities
      .map((entity) => `${trader.company.code}${entity.code}`)
      .join(', '),
    companyName: trader.company.name,
  }
}

export const sortAlphabetically = [
  (trader) => trader.companyName.toLowerCase(),
  (trader) => trader.name.toLowerCase(),
]
