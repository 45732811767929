import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { withTheme } from '@material-ui/styles'

const InfoText = ({ header, info, gutterBottom, theme }) => {
  const lines = Array.isArray(info) ? info : [info]

  return (
    <>
      <Text variant="body2">{header}</Text>

      {lines.map((line, idx) => {
        const isLast = idx === lines.length - 1
        const style =
          gutterBottom && isLast
            ? { marginBottom: theme.spacing(2) }
            : undefined

        return (
          <Text key={idx} variant="body1" style={style}>
            {line || '--'}
          </Text>
        )
      })}
    </>
  )
}

InfoText.defaultProps = {
  gutterBottom: true,
}

InfoText.propTypes = {
  header: PropTypes.string.isRequired,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  theme: PropTypes.object.isRequired,
  gutterBottom: PropTypes.bool,
}

export default withTheme(InfoText)
