import moment from 'moment'
import { isNil } from 'lodash'
import { $OC } from 'emsurge-selectors/models/order'
import { useTruncatedTextObject } from 'hooks'

const isThirdPartyOrder = ({ thirdParty }) => !isNil(thirdParty)

const getEntityCode = ({ thirdParty, company, entity }) => {
  if (isThirdPartyOrder({ thirdParty })) {
    return `${thirdParty.companyName ? thirdParty.companyName : '---'} ${
      thirdParty.entityName ? thirdParty.entityName : ''
    }`
  }

  if (isNil(company) || isNil(entity)) {
    return null
  }

  return `${company.code}${entity.code}`
}

const sortByDescendingDate = (tsA, tsB) =>
  moment(tsA.tradeDate).isAfter(moment(tsB.tradeDate)) ? 1 : -1

const overrideOrderPartyInfo = (termsheetOrder, partyOverride) => {
        //We need to check bidOrderOverride?.company?.name to be sure the override is not empty, we have an empty override with personId empty that is created to avoid validations errors when sending trade updates for parties values returned to original values.
  if (partyOverride && partyOverride?.company?.name) { 
    if(partyOverride.company.code)
    {
      termsheetOrder.company = partyOverride.company
      termsheetOrder.entity = partyOverride.entity
      termsheetOrder.person = partyOverride.person  
      termsheetOrder.thirdParty = {}
    }
    else
    {
      termsheetOrder.company = {}
      termsheetOrder.entity = {}
      termsheetOrder.person = {}
      termsheetOrder.thirdParty = {
        companyName: partyOverride.company.name,
        entityName: partyOverride.entity.name,
      }
    }
  }
  else if(termsheetOrder.company.id === null)
  {
    termsheetOrder.company = {}
      termsheetOrder.entity = {}
      termsheetOrder.person = {}
      termsheetOrder.thirdParty = {
        companyName: 'Anon',
        entityName: ' ',
      }
  }
}

const getTableDataFromOrders = (termsheets) => {
  return termsheets
    .map((termsheet) => {
      const {
        createdAt,
        id: termsheetId,
        shortId,
        tradeDate,
        bidOrderOverride,
        askOrderOverride
      } = termsheet

      const termSpot = termsheet.termVintage.termSpot
      const vintage = $OC.termVintage.text(termsheet).vintage
      const volume = $OC.volume.text(termsheet).volume
      const priceInformation = $OC.carbonPrice.text(termsheet)
      const price = `${priceInformation.typeText} ${priceInformation.value} `
      const { projects } = $OC.projectSpecification.get(termsheet)
      const projectNames = useTruncatedTextObject(projects, x => x.name, 30).truncatedText
      const projectIds = useTruncatedTextObject(projects, x => x.referenceNr, 30).truncatedText

      overrideOrderPartyInfo(termsheet.bidOrder, bidOrderOverride)
      overrideOrderPartyInfo(termsheet.askOrder, askOrderOverride)

      return {
        createdAt,
        buyer: getEntityCode(termsheet.bidOrder),
        buyerOrder: termsheet.bidOrder,
        seller: getEntityCode(termsheet.askOrder),
        sellerOrder: termsheet.askOrder,
        shortId,
        termsheetId,
        tradeDate,
        termSpot,
        vintage,
        volume,
        price,
        projectNames,
        projectIds
      }
    })
    .sort(sortByDescendingDate)
}

export {
  getTableDataFromOrders
}
