const PARTY = Object.freeze({
  BUYER: 'bid',
  SELLER: 'ask',
  TBA: 'tba',
})

const PARTY_TEXT = Object.freeze({
  [PARTY.BUYER]: 'Buyer',
  [PARTY.SELLER]: 'Seller',
  [PARTY.TBA]: 'TBA',
  BUYER: 'Buyer',
  SELLER: 'Seller',
  TBA: 'TBA',
})

module.exports = {
  PARTY,
  PARTY_TEXT,
}
