import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { merge, identity } from 'lodash'
import queryString from 'query-string'
import { SESSION_EXPIRED_RESPONSE_HEADER } from 'emsurge-selectors'
import { getAppContextStorageValue } from 'containers/appContext/AppContextProvider'
import { refetchAll } from 'containers/fetch/useFetch'
import { useUser } from 'containers/user/useUser'
import { getApiBaseUrl } from 'utils/getApiConfig'
const { location } = window

export const ApiContext = React.createContext()

const getConfig = (user, config) =>
  user.token ? 
    merge(config, {
    headers: { authorization: `Bearer ${user.token}` },
    })
  : config

const createEmsurgeApi = (logoutFunc) => {
  const apiBaseURL = `${getApiBaseUrl(location)}/api`
  const result = axios.create({
    baseURL: apiBaseURL,
    headers: { 'Content-Type': 'application/json' },
  })

  /**
   *  Workaround to having to fetch the value and pass it down on each
   *  `getHeaders` call. Will work the same since the state is always in
   *  sync with the local storage, but will require refactoring if SSR
   *  ever becomes a requirement.
   *
   *  @TODO Have context being passed as a parameter instead of getting it
   *        directly from local storage.
   */
   result.interceptors.request.use((config) => {
    if (config.method !== 'get') {
      return config
    }

    const WHITELIST = ['activities', 'orders', 'termsheets']

    const isWhitelisted = WHITELIST.some(
      (url) => config.url.indexOf(`/${url}`) === 0
    )
    if (!isWhitelisted) {
      return config
    }

    return {
      ...config,
      params: {
        ...config.params,
        context: getAppContextStorageValue(),
      },
    }
  })

  result.interceptors.response.use(identity, (err) => {
    if (err.response.status === 401 && err.response.headers[SESSION_EXPIRED_RESPONSE_HEADER.toLowerCase()]) {
      console.warn('The user session has expired')
      logoutFunc(true)
      return
    }
    return Promise.reject(err)
  })

  return result
}

const ApiProvider = ({ children }) => {
  const { user, logout } = useUser()

  const api = createEmsurgeApi(logout)

  /* eslint-disable no-unused-vars */
  // ESLint complains that auth is an unused variable, however, if we remove it, the authentication stops working.
  // Probably the real solution is to understanding why auth is "magic" in this context, but we have no time or need for this now.
  const get = async (path, { auth = true, ...config } = {}) => {
    const response = await api.get(
      path,
      getConfig(user, config)
    )
    return response
  }

  const post = async (path, data, { auth = true, ...config } = {}) => {
    const response = await api.post(
      path,
      data,
      getConfig(user, config)
    )
    refetchAll()

    return response
  }

  const put = async (path, data, { auth = true, ...config } = {}) => {
    const response = await api.put(
      path,
      data,
      getConfig(user, config)
    )
    refetchAll()

    return response
  }

  const patch = async (path, data, { auth = true, ...config } = {}, refetch = true) => {
    const response = await api.patch(
      path,
      data,
      getConfig(user, config)
    )

    if (refetch) {
      refetchAll()
    }

    return response
  }

  const deleteFunc = async (path, { auth = true, ...config } = {}) => {
    const response = await api.delete(
      path,
      getConfig(user, config)
    )
    refetchAll()

    return response
  }
  /* eslint-enable no-unused-vars */

  const value = {
    get: get,
    post: post,
    put: put,
    patch: patch,
    delete: deleteFunc,

    getActivities: async (time) => {
      const { data } = await get(`/activities?since=${time}`)
      return data
    },
    
    getOrders: async () => {
      const { data } = await get(`/orders`)
      return data
    },
    
    getOrdersForDashboard: async () => {
      const { data } = await get(`/ordersDashboard`)
      return data
    },
    
    getOrder: async (id) => {
      const { data } = await get(`/orders/${id}`)
      return data
    },
    
    getOrderLog: async (id, config) => {
      const { data } = await get(`/orders/${id}/history`, config)
      return data
    },
    
    getTermsheet: async (id) => {
      const { data } = await get(`/termsheets/${id}`)
      return data
    },

    getTermsheetCarbon: async (id) => {
      const { data } = await get(`/termsheets/carbon/${id}`)
      if(data.askOrder.company.name === null) {//This is required to show a value in Order summary for Anonymized trades
        data.askOrder.company.id = 'ANON'
        data.askOrder.company.name = 'ANON'
        data.askOrder.company.code = 'ANON'
        data.askOrder.entity.id = 'ANON'
        data.askOrder.entity.name = 'ANON'
        data.askOrder.entity.code = '-'
        data.askOrder.person.name = 'ANON'
      }
      if(data.bidOrder.company.name === null) {
        data.bidOrder.company.id = 'ANON'
        data.bidOrder.company.name = 'ANON'
        data.bidOrder.company.code = 'ANON'
        data.bidOrder.entity.id = 'ANON'
        data.bidOrder.entity.name = 'ANON'
        data.bidOrder.entity.code = '-'
        data.bidOrder.person.name = 'ANON'
      }
      const result = {
        ...data,
        orders: [ //This is required to integrate with the existend UI components
          {...data.askOrder, 
            tradingType:'ask'}, 
          {...data.bidOrder, 
            tradingType:'bid'}
        ],
      }
      return result
    },
    
    getPriceIndex: async ({ markets, period }) => {
      const { data } = await get(
        `/price-index?${queryString.stringify({ market: markets, period })}`)
      return data
    },
    
    getPersonSettings: async ({ id }) => {
      const { data } = await get(`/persons/${id}/settings`)
      return data
    },

    getTraders: async () => {
      const { data } = await get('/persons?clientType=trader&permissions=trade')
      return data
    },
    
    getTraderPermissions: async () => {
      const { data } = await get('/permissions/trader')
      return data
    },
    
    createOrder: async (data) => {
      const { data: order } = await post('/orders', data)
      return order
    },
    
    editOrder: async (id, data) => {
      const { data: order } = await put(`/orders/${id}`, data)
      return order
    },
    
    shareOrderWithBroker: async ({ brokerId, orderId }) => {
      const { data: shareData } = await post(
        `/orders/${orderId}/access`,
        { brokerId }
      )
    
      return shareData
    },
    
    getEntityBrokers: async ( entityId ) => {
      const { data: brokers } = await get(
        `/permissions/broker/${entityId}/order`)
      return brokers
    },
    
    editChecklist: async (id, data) => {
      const { data: checklist } = await put(`/checklists/${id}`,data)
      return checklist
    },
    
    editChecklistItem: async (checklistId, itemId, data) => {
      const { data: checklistItem } = await patch(`/checklists/${checklistId}/item/${itemId}`, data)
      return checklistItem
    },
    
    getResetPasswordLink: async ({ personId }) => {
      const { data: resetPasswordLink } = await get(`/password/reset/${personId}`)
      return resetPasswordLink
    },
    
    setMarkAsTraded: async (
      { termsheetId, counterParty = {}, closeOnTraded }
    ) => {
      const { data } = await patch(
        `/termsheets/${termsheetId}/trade`,
        {
          id: counterParty.id,
          closeOnTraded,
          isTraded: counterParty.isTraded,
        }
      )
    
      return data
    },
    
    getGrids: async () => {
      const { data } = await get('/market-grids?related=markets&related=periods')
      return data
    },
    
    getGridPrices: async (id) => {
      const { data } = await get(`/market-grids/${id}/prices`)
      return data
    },
    
    editGrid: async (id, data) => {
      const { data: grid } = await put(
        `/market-grids/${id}`,
        data
      )
    
      return grid
    },
    
    editPersonSettings: async (personId, data) => {
      const { data: personSettings } = await patch(
        `/persons/${personId}/settings`,
        data
      )
      return personSettings
    },
    
    testSessionExpired: async () => {
      await get(`/test-session-expired`)
    }
  }

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

ApiProvider.propTypes = {
  children: PropTypes.node,
}

export default ApiProvider
