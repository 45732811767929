const TERM_SPOT_KEYS = {
  SPOT: 'SPOT',
  TERM: 'TERM',
  INVESTMENT: 'INVESTMENT',
}

const TERM_SPOT_VALUES = {
  TERM: 'Term',
  SPOT: 'Spot',
  INVESTMENT: 'Investment',
}

const TERM_SPOT = [
  {
    value: TERM_SPOT_KEYS.SPOT,
    label: TERM_SPOT_VALUES.SPOT,
  },
  {
    value: TERM_SPOT_KEYS.TERM,
    label: TERM_SPOT_VALUES.TERM,
  },
  {
    value: TERM_SPOT_KEYS.INVESTMENT,
    label: TERM_SPOT_VALUES.INVESTMENT,
  },
]

module.exports = {
  TERM_SPOT,
  TERM_SPOT_KEYS,
  TERM_SPOT_VALUES,
}
