const nomination = require('../nomination')

const getPort = (port = {}) => {
  const { name, toBeNominated } = port
  const portNomination = nomination.get(port)

  return {
    name,
    toBeNominated,
    nomination: portNomination,
  }
}

const getPortSummary = (port = {}) => {
  const { name = '', toBeNominated = '' } = getPort(port)
  const nominationPortSummary = nomination.summary(port)

  return {
    name,
    toBeNominated: toBeNominated.toString(),
    nomination: nominationPortSummary,
  }
}

const getPortText = (port = {}, nominationType) => {
  const portSummary = getPortSummary(port)
  const nominationPortText = nomination.text(port, {
    prefix: 'For each cargo, the',
    name: nominationType,
  })

  return {
    ...portSummary,
    nomination: nominationPortText,
  }
}

module.exports = {
  get: getPort,
  summary: getPortSummary,
  text: getPortText,
}
