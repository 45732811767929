import { ADMIN_VIEW_COMPANIES, DASHBOARD } from 'routes'
import { isSystemAdmin } from 'roles'

export const getRedirectLink = (user) => {
  if (isSystemAdmin(user)) {
    return ADMIN_VIEW_COMPANIES
  }

  return DASHBOARD
}
