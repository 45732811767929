import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { cloneDeep } from 'lodash'
import { ORDER_STATUS } from 'emsurge-selectors'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { ORDER_KEY } from 'api/constants'

export const useMarkAsTraded = () => {
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const mutate = (props) => api.setMarkAsTraded(props)

  const onMutate = ({ termsheetId, counterParty }) => {
    const query = [ORDER_KEY, termsheetId]
    queryClient.cancelQueries(query)
    const previousTermsheet = cloneDeep(queryClient.getQueryData(query))
    const rollback = () => queryClient.setQueryData(query, previousTermsheet)
    queryClient.setQueryData(query, (previous) => ({
      ...previous,
      orders:
        previous?.orders?.map((order) => ({
          ...order,
          isTraded:
            order.id === counterParty.id ? !order.isTraded : order.isTraded,
        })) || [],
    }))

    return rollback
  }

  const onSuccess = (termsheet, { counterParty }) => {
    enqueueSnackbar(
      `Order ${
        counterParty.isTraded ? 'marked' : 'unmarked'
      } as traded successfully`,
      { variant: 'success' }
    )
    if (termsheet?.status === ORDER_STATUS.TRADED) {
      queryClient.setQueryData([ORDER_KEY, termsheet.id], (previous) => ({
        ...previous,
        status: ORDER_STATUS.TRADED,
      }))
    }
  }

  const onError = (error, _, rollback) => {
    const message = getApiErrorMessage({
      error,
      defaultMessage: 'The order cannot be traded',
    })
    enqueueSnackbar(message, { variant: 'error' })
    rollback()
  }

  return useMutation(mutate, {
    onMutate,
    onSuccess,
    onError,
    throwOnError: true,
  })
}
