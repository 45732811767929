import React from 'react'
import PropTypes from 'prop-types'
import { toUpper, take, slice, find } from 'lodash'
import { DELIVERY_TYPE } from 'emsurge-selectors/constants'
import { connect } from 'formik'
import { ConnectedSelect } from 'components/Select'
import {
  PaddedContainer,
  Button,
  StyledContainer,
  StyledConnectedSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { OrderType } from 'screens/orderCreateCommon/components/OrderType'
import { SelectableGridOption as Option } from 'components/SelectableGrid'
import { DeliveryType } from 'screens/orderCreate/components/DeliveryType'
import { SelectDeliveryPort } from 'screens/orderCreate/components/SelectDeliveryPort'

const _DeliveryAndLocation = ({ formik: { values } }) => {
  return (
    <PaddedContainer>
      <OrderType>
        {(options) => (
          <StyledConnectedSelectableGrid name="tradingType">
            {options.map(({ value, label, ...props }) => (
              <Option
                data-testid={`tradingType-${value}`}
                key={`order-type-${value}`}
                xs={6}
                value={value}
                {...props}
              >
                <Button>{toUpper(label)}</Button>
              </Option>
            ))}
          </StyledConnectedSelectableGrid>
        )}
      </OrderType>

      <StyledContainer>
        <DeliveryType>
          {({ options, onChange }) => {
            const opts = [
              find(options, ({ value }) => value === DELIVERY_TYPE.DES),
              find(options, ({ value }) => value === DELIVERY_TYPE.FOB),
            ]

            return (
              <>
                <StyledConnectedSelectableGrid
                  name="delivery.type"
                  onChange={(value) =>
                    onChange(value, values.delivery.location)
                  }
                >
                  {opts.map(({ value, label }) => (
                    <Option
                      data-testid={`delivery.type-${value}`}
                      key={`order-delivery-type-${value}`}
                      xs={6}
                      value={value}
                    >
                      <Button>{label}</Button>
                    </Option>
                  ))}
                </StyledConnectedSelectableGrid>
                <StyledContainer>
                  <SelectDeliveryPort>
                    {({ options }) => {
                      const NR_OF_VISIBLE_DELIVERY_LOCATIONS = 12
                      const visibleOptions = take(
                        options,
                        NR_OF_VISIBLE_DELIVERY_LOCATIONS
                      )
                      const selectableOptions = slice(
                        options,
                        NR_OF_VISIBLE_DELIVERY_LOCATIONS
                      )
                      return (
                        <>
                          <StyledConnectedSelectableGrid
                            name="delivery.location"
                            onChange={(value) =>
                              onChange(values.delivery.type, value)
                            }
                          >
                            {visibleOptions.map((value) => (
                              <Option
                                key={`order-delivery-port-${value}`}
                                xs={6}
                                value={value}
                              >
                                <Button>{value}</Button>
                              </Option>
                            ))}
                          </StyledConnectedSelectableGrid>
                          <StyledContainer spacing={2}>
                            <ConnectedSelect
                              name="delivery.location"
                              onChange={(value) =>
                                onChange(values.delivery.type, value)
                              }
                              options={selectableOptions}
                              placeholder={
                                <option value={visibleOptions[0]}>OTHER</option>
                              }
                            />
                          </StyledContainer>
                        </>
                      )
                    }}
                  </SelectDeliveryPort>
                </StyledContainer>
              </>
            )
          }}
        </DeliveryType>
      </StyledContainer>
    </PaddedContainer>
  )
}

_DeliveryAndLocation.propTypes = {
  formik: PropTypes.object,
}

export const DeliveryAndLocation = connect(_DeliveryAndLocation)
