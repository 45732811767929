import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'

import { LoadPortModel } from 'screens/orderCreate/components/LoadPortModel'
import TextField from 'containers/formikTextField/FormikTextField'

export const _LoadPort = ({ formik: { values } }) => (
  <LoadPortModel>
    {({ shouldDisableField }) => (
      <TextField
        fullWidth
        margin="normal"
        multiline
        InputLabelProps={{
          error: false,
        }}
        type="text"
        name="nominationRules.loadPort.base.name"
        label="Base Port"
        disabled={shouldDisableField(values.nominationRules.loadPort.base)(
          'port'
        )}
      />
    )}
  </LoadPortModel>
)

_LoadPort.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const LoadPort = connect(_LoadPort)
