import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Modal from 'components/modal/Modal'
import { TermsheetEdit } from 'screens/termsheetEdit/TermsheetEdit'
import { TERMSHEET_EDIT, TRANSACTIONS_EDIT} from 'routes'
import { OrderProvider, useOrderState } from 'containers/OrderProvider'

const ViewTermsheetModal = ({ onClose, children }) => {
  const { setOrderState } = useOrderState()

  useEffect(() => {
    setOrderState({
      isTermsheet: true,
    })
  }, [setOrderState])

  return (
    <Modal open={true} onClose={onClose}>
      <Switch>
        <Route path={TERMSHEET_EDIT} component={TermsheetEdit} />
        <Route path={TRANSACTIONS_EDIT} component={TermsheetEdit} />
        <Route>{children}</Route>
      </Switch>
    </Modal>
  )
}

ViewTermsheetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const ViewTermsheetModalWithProvider = (props) => (
  <OrderProvider>
    <ViewTermsheetModal {...props} />
  </OrderProvider>
)

export default ViewTermsheetModalWithProvider
