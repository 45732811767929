import React from 'react'
import PropTypes from 'prop-types'
import { pick } from 'lodash'
import Checkbox from '@material-ui/core/Checkbox'

export const getApiData = (order) =>
  pick(order, [
    'personId',
    'status',
    'tradingType',
    'details',
    'price',
    'delivery',
    'volume',
    'cargoSize',
    'quality',
    'nomination',
    'companyId',
    'entityId',
    'thirdParty',
  ])

export const ItemCheckbox = ({ checked, onChange, disabled, ...props }) => {
  const onChangeHandler = () => onChange(!checked)

  return (
    <Checkbox
      disabled={disabled}
      color="primary"
      checked={checked}
      onChange={onChangeHandler}
      {...props}
    />
  )
}

ItemCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}
