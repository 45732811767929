import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import SearchForm from '../SearchForm'
import { orderCardFactory, guessPendingOrderCardHeight } from './helpers'
import { OrdersSkeleton } from './OrdersSkeleton'
import { BlockContent, ActionBlock } from 'screens/orderIndex/components/Block'
import { withHeight } from 'screens/orderIndex/components/helpers'
import { VirtualList } from 'components/VirtualList'

const RowRenderer = ({ index, style, data }) => {
  const { Component } = data[index]
  return <Component style={style} />
}

RowRenderer.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.array,
}

const getItemSize = (data, index) => {
  const { height } = data[index]
  return height
}

const Templates = ({ isLoading, templates, onFilterChange }) => {
  const { spacing } = useTheme()

  const nodeTree = templates.map((template) =>
    withHeight(guessPendingOrderCardHeight(), orderCardFactory(template))
  )

  const renderTemplates = (templates) =>
    isEmpty(templates) ? (
      <Typography>There are no templates</Typography>
    ) : (
      <VirtualList
        rowRenderer={RowRenderer}
        getItemSize={getItemSize}
        items={nodeTree}
      />
    )

  return (
    <>
      <ActionBlock />
      <SearchForm
        inputProps={{ 'data-testid': 'orders-search' }}
        onChange={(evt) => onFilterChange(evt.target.value)}
      />
      <BlockContent style={{ paddingTop: spacing(1) }}>
        {isLoading ? <OrdersSkeleton count={2} /> : renderTemplates(templates)}
      </BlockContent>
    </>
  )
}

Templates.defaultProps = {
  isLoading: false,
  templates: [],
  onFilterChange: () => {},
}

Templates.propTypes = {
  isLoading: PropTypes.bool,
  templates: PropTypes.array,
  onFilterChange: PropTypes.func,
}

export default memo(Templates)
