import { PRICE_TYPE, PRICE_TYPE_TEXT } from 'emsurge-selectors/constants'

const hasPrice = (info) => {
  const priceType = Object.values(PRICE_TYPE).includes(info.value)

  return priceType
}

const getPrice = (info) => {
  return { ...info }
}

const getSummary = (info) => {
  const priceType = info.value
  let longSummary
  let shortSummary

  if (priceType) {
    longSummary = PRICE_TYPE_TEXT[priceType]
    shortSummary = `Preference: ${PRICE_TYPE_TEXT[priceType]}`
  }

  return {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.PREFERENCE],
    short: shortSummary,
    long: longSummary,
  }
}

export const preference = {
  exists: hasPrice,
  get: getPrice,
  summary: getSummary,
}
