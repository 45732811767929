const moment = require('moment')
const {
  periodIsCustom,
  periodIsCalYear,
  periodIsQuarter,
  periodIsMonth,
  periodIsYMinus1,
  CARBON_PERIOD_LABELS_MONTHS_KEYS,
  CARBON_PERIOD_LABELS,
  CARBON_PERIOD,
  CARBON_PERIOD_ORDER,
  CARBON_PERIOD_MONTHS_BY_QUARTERS,
} = require('../constants')

const getDateMonth = (date) => moment(date).month()
const getMonthKey = (date) =>
  CARBON_PERIOD_LABELS_MONTHS_KEYS[getDateMonth(date)]
const getPeriodLabel = (period) => CARBON_PERIOD_LABELS[period].substring(0, 3)
const getMonthLabelShort = (date) => getPeriodLabel(getMonthKey(date))
const getFullYear = (date) => moment(date).year()
const getShortYear = (date) => getFullYear(date) % 100
const getStringCustomDate = (date) => {
  if (!date) {
    return ''
  }
  const month = getMonthLabelShort(date)
  const fullMonth = getMonthKey(date)
  const fullYear = getFullYear(date)
  const year = getShortYear(date)
  return {
    month,
    year,
    fullMonth,
    fullYear,
    text: `${month}-${year}`,
  }
}

const getQuarterPeriodDate = (period, year) => {
  const shortYear = getShortYear(String(year))
  const periodLabel = getPeriodLabel(period)
  const data = [periodLabel].concat(year ? [shortYear] : [])
  return data.join('-')
}

const getMonthPeriodDate = (period, year) => {
  const shortYear = getShortYear(String(year))
  const periodLabel = getPeriodLabel(period)
  return `${periodLabel}-${shortYear}`
}

const computeRenderedDate = (period, customFrom, customTo, year) => {
  if (periodIsCustom(period)) {
    const { month: monthFrom, text: textFrom } = getStringCustomDate(customFrom)
    const { month: monthTo, text: textTo } = getStringCustomDate(customTo)
    const fromJanDec = monthFrom === 'Jan' && monthTo === 'Dec'
    const from = fromJanDec ? getFullYear(customFrom) : textFrom
    const to = fromJanDec ? getFullYear(customTo) : textTo
    const separator = fromJanDec ? '-' : ' '
    const message = from === to ? from : [from, to].join(separator)
    return message
  }
  if (periodIsCalYear(period)) {
    return year
  }
  if (periodIsQuarter(period)) {
    return getQuarterPeriodDate(period, year)
  }
  if (periodIsMonth(period)) {
    return getMonthPeriodDate(period, year)
  }
  if (periodIsYMinus1(period)) {
    return 'Y - 1'
  }
}

const getDatePeriods = ({ period, customFrom, customTo, year }) => {
  const periodIndex = CARBON_PERIOD_ORDER.findIndex((item) => item === period)
  const defaultPeriods = {
    yearFrom: year,
    yearTo: year,
    monthFrom: period,
    monthTo: period,
    periodIndex,
  }
  if (periodIsCustom(period)) {
    const { fullMonth: monthFrom, fullYear: yearFrom } =
      getStringCustomDate(customFrom)
    const { fullMonth: monthTo, fullYear: yearTo } =
      getStringCustomDate(customTo)
    return {
      ...defaultPeriods,
      yearFrom,
      monthFrom,
      yearTo,
      monthTo,
    }
  }
  if (periodIsCalYear(period)) {
    return {
      ...defaultPeriods,
      monthFrom: CARBON_PERIOD.JAN,
      monthTo: CARBON_PERIOD.DEC,
    }
  }
  if (periodIsQuarter(period)) {
    const monthQuarter = CARBON_PERIOD_MONTHS_BY_QUARTERS[period] || []
    return {
      ...defaultPeriods,
      monthFrom: monthQuarter[0],
      monthTo: monthQuarter[monthQuarter.length - 1],
    }
  }
  if (periodIsMonth(period)) {
    return defaultPeriods
  }
  return {}
}

module.exports = {
  computeRenderedDate,
  getDatePeriods,
}
