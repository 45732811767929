import { ORDER_STATUS } from 'emsurge-selectors'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { useMarkAsTraded } from './useMarkAsTraded'
import { ORDER_KEY } from 'api/constants'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

export const useTradeTermsheet = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { mutateAsync: markAsTraded } = useMarkAsTraded()
  const api = useApi()

  const fetchTermsheet = async (id) =>
    await api.getTermsheet(id)

  const mutate = async ({ termsheetId }) => {
    const termsheet = await queryClient.fetchQuery(
      [ORDER_KEY, termsheetId],
      async () => await fetchTermsheet(termsheetId),
      {},
      {
        throwOnError: true,
      }
    )

    const [firstOrder, secondOrder] = termsheet?.orders || []
    const commonProps = {
      termsheetId: termsheet.id,
      closeOnTraded: true,
    }

    if (!firstOrder || !secondOrder) {
      throw new Error('Termsheet orders not found')
    }

    const firstOrderTrade = await markAsTraded({
      ...commonProps,
      counterParty: {
        isTraded: true,
        id: firstOrder.id,
      },
    })
    const secondOrderTrade = await markAsTraded({
      ...commonProps,
      counterParty: {
        isTraded: true,
        id: secondOrder.id,
      },
    })

    return [firstOrderTrade, secondOrderTrade]
  }

  const onSuccess = async (_, { termsheetId }) => {
    queryClient.setQueryData([ORDER_KEY, termsheetId], (previous) => ({
      ...previous,
      status: ORDER_STATUS.TRADED,
    }))
  }

  const onError = (error) => {
    const message = getApiErrorMessage({
      error,
      defaultMessage: 'The Term Sheet cannot be traded',
    })
    enqueueSnackbar(message, { variant: 'error' })
  }

  return useMutation(mutate, {
    onSuccess,
    onError,
    throwOnError: true,
  })
}
