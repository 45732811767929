import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import { Select } from './Select.mobile'

export const ConnectedSelect = ({ name, onChange, options, placeholder }) => (
  <Field name={name}>
    {({ field, form }) => (
      <Select
        value={field.value}
        options={options}
        onChange={(evt) => {
          form.setFieldValue(name, evt.target.value)
          onChange(evt.target.value)
        }}
        placeholder={placeholder}
      ></Select>
    )}
  </Field>
)

ConnectedSelect.defaultProps = {
  onChange: () => {},
  options: [],
}

ConnectedSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.node.isRequired,
}
