import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { omit, partial } from 'lodash'
import { $O, $U, PERSON_CLIENT_TYPE, APP_CONTEXT_TYPE } from 'emsurge-selectors'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router'
import FormProvider from './containers/FormProvider'
import ModalContent from 'screens/orderCreate/desktop/ModalContent'
import ModalHeader from 'screens/orderCreate/desktop/ModalHeader'
import ModalContentCarbon from 'screens/orderCreateCarbon/ModalContent'
import ModalHeaderCarbon from 'screens/orderCreateCarbon/ModalHeader'
import FormProviderCarbon from 'screens/orderEditCarbon/FormProvider'
import { getOrderInfo as getOrderInfoCarbon } from 'screens/orderCreateCarbon/FormProvider'
import withParams from 'utils/withRouteParams'
import { getOrderInfo } from 'screens/orderCreate/containers/FormProvider'
import { isBrokerOrder } from 'screens/orderCreate/helpers'
import { useUser } from 'containers/user/useUser'
import {
  ORDER_FLOW,
  useOrderFlow,
  useOrderState,
} from 'containers/OrderProvider'
import { useOrder, useEditOrder } from 'api'
import { DASHBOARD, ORDER_BASE_ROUTES } from 'routes'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { Mobile, Tablet } from 'components/Viewport'
import { CreateOrderModal } from 'screens/orderCreate/mobile/CreateOrderModal'
// import { CreateOrderModalCarbon } from 'screens/orderCreateCarbon/CreateOrderModal'

export const getData = (formValues, user, order) => {
  const orderInfo = omit(getOrderInfo(formValues, user), 'comments')

  /** @NOTE: Order shared to broker by a trader */
  if ($U.clientType.get(user) === PERSON_CLIENT_TYPE.BROKER) {
    if (!isBrokerOrder(order)) {
      orderInfo.personId = order.personId
      delete orderInfo.brokerId
      orderInfo.companyId = order.companyId
      orderInfo.entityId = order.entityId
    }

    // need to set personId to the broker person id in case the order has been updated from on to off system
    if (!orderInfo.entityId) {
      orderInfo.personId = user.id
    }
  }

  delete orderInfo.brokerAccess
  delete orderInfo.broker

  return orderInfo
}

export const getDataCarbon = (formValues, user, order) => {
  const orderInfo = omit(getOrderInfoCarbon(formValues, user), 'comments')

  /** @NOTE: Order shared to broker by a trader */
  if ($U.clientType.get(user) === PERSON_CLIENT_TYPE.BROKER) {
    if (!isBrokerOrder(order)) {
      orderInfo.personId = order.personId
      delete orderInfo.brokerId
      orderInfo.companyId = order.companyId
      orderInfo.entityId = order.entityId
    }

    // need to set personId to the broker person id in case the order has been updated from on to off system
    if (!orderInfo.entityId) {
      orderInfo.personId = user.id
    }
  }

  delete orderInfo.brokerAccess
  delete orderInfo.broker

  return orderInfo
}

const OrderEdit = ({ id }) => {
  const { user } = useUser()
  const history = useHistory()
  const { isLoading, data: order } = useOrder(id)
  const BASE_SLUG = useRouteBaseSlug()
  const rootSlug = ORDER_BASE_ROUTES.includes(`/${BASE_SLUG}`)
    ? `/${BASE_SLUG}`
    : DASHBOARD
  const { mutateAsync: editOrder } = useEditOrder()
  const { setFlow } = useOrderFlow()
  const { setOrderState } = useOrderState()

  useEffect(() => {
    setFlow(ORDER_FLOW.EDIT)
    setOrderState((state) => ({
      ...state,
      brokerEditsSharedOrder:
        user.clientType === PERSON_CLIENT_TYPE.BROKER && !state.hasBrokerId,
      stakeholderType: $O.stakeholder.type.get(order),
    }))
  }, [setFlow, setOrderState, user.clientType, order])

  const handleSubmit = async (order, values, actions) => {
    const data = getData(values, user, order)
    if (
      !values.brokerId &&
      $U.clientType.get(user) === PERSON_CLIENT_TYPE.BROKER
    ) {
      data.brokerId = null
    }

    try {
      await editOrder({ id, ...data })
      history.push(`${rootSlug}/orders/${id}`)
    } catch {
      actions.setSubmitting(false)
    }
  }

  const handleSubmitCarbon = async (order, values, actions) => {
    const data = getDataCarbon(values, user, order)
    if (
      !values.brokerId &&
      $U.clientType.get(user) === PERSON_CLIENT_TYPE.BROKER
    ) {
      data.brokerId = null
    }

    try {
      await editOrder({ id, ...data })
      history.push(`${rootSlug}/orders/${id}`) 
    } catch {
      actions.setSubmitting(false)
    }
  }

  if (isLoading) {
    return <CircularProgress />
  }

  const goToOrder = () => history.push(`${rootSlug}/orders/${id}`)

  return (  
    <>  
      {order.context === APP_CONTEXT_TYPE.LNG && (
        <FormProvider order={order} onSubmit={partial(handleSubmit, order)}>
          <>
            <Tablet matchLarger>
              <ModalHeader onCancel={goToOrder} isEdit />
              <ModalContent />
            </Tablet>
            <Mobile>
              <CreateOrderModal onCancel={goToOrder} />
            </Mobile>
          </>
        </FormProvider>
      )}
      {order.context === APP_CONTEXT_TYPE.CARBON && (
        <FormProviderCarbon order={order} onSubmit={partial(handleSubmitCarbon, order)}>
        <>
          <Tablet matchLarger>
            <ModalHeaderCarbon onCancel={goToOrder} isEdit />
            <ModalContentCarbon isEdit/>
          </Tablet>
          {/* The mobile creation is not yet implemented for Carbon */}
          {/* <Mobile>
            <CreateOrderModalCarbon onCancel={goToOrder} />
          </Mobile> */}
        </>
      </FormProviderCarbon>
      )}
    </>    
  )
}

OrderEdit.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withParams(OrderEdit)
