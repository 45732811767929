import { get, isEmpty } from 'lodash'

const isSharedWithBrokers = (order) => {
  const brokerAccess = get(order, 'brokerAccess')

  return !isEmpty(brokerAccess)
}

/* :: object -> boolean */
const getOrderBrokers = (order) => {
  if (!isSharedWithBrokers(order)) {
    throw new Error(
      'Order not shared with any broker. Use order.broker.exists()'
    )
  }

  return order.brokerAccess
}

export const broker = {
  exists: isSharedWithBrokers,
  get: getOrderBrokers,
}
