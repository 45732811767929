import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { PERSON_PERMISSION } from 'emsurge-selectors'
import {
  Dashboard as DashboardIcon,
  VerticalSplit as VerticalSplitIcon,
  CheckCircle as CheckCircleIcon,
  DoneAll as DoneAllIcon,
  Group as GroupIcon,
  Feedback as FeedbackIcon,
  SettingsApplications as SettingsApplicationsIcon,
  Business as BusinessIcon,
  People as PeopleIcon,
  ContactPhone,
  TableChart as TableChartIcon,
  ShowChart,
  Timeline,
  LinearScale,
  Equalizer
} from '@material-ui/icons'
import { userHasMinimumPermission } from '../../permissions'
import Navlink from './NavLink'
import { useUser } from 'containers/user/useUser'
import useAppContext from 'containers/appContext/useAppContext'
import { isSystemAdmin } from 'roles'

import {
  ADMIN_VIEW_BROKERS,
  ADMIN_VIEW_COMPANIES,
  ADMIN_VIEW_USERS,
  DASHBOARD,
  NOMINATIONS,
  NOTES,
  BULLETINS,
  SETTINGS,
  TRADERS,
  TRANSACTION_BOOK,
  MARKET_VISUALISATION,
  MARKET_GRID,
  PRICE_CHARTS_BASIS,
  PRICE_CHARTS_FIXED,
} from 'routes'

const _SideMenuOptions = ({ isDrawerOpen, isMobile }) => {
  const { user } = useUser()
  const { isLNGContext, appContext } = useAppContext()
  const hasTradePermission =
    user.entities &&
    user.entities.some(({ permission }) =>
      userHasMinimumPermission(permission, PERSON_PERMISSION.TRADE)
    )

  const computeLink = (URL) => {
    return `${URL}?appContext=${appContext}`
  }

  if (isSystemAdmin(user)) {
    return (
      <>
        <Navlink
          data-testid="menu-companies"
          to={computeLink(ADMIN_VIEW_COMPANIES)}
          Icon={BusinessIcon}
        >
          Companies
        </Navlink>
        <Navlink
          data-testid="menu-users"
          to={computeLink(ADMIN_VIEW_USERS)}
          Icon={PeopleIcon}
        >
          Users
        </Navlink>
        <Navlink
          data-testid="menu-brokers"
          to={computeLink(ADMIN_VIEW_BROKERS)}
          Icon={ContactPhone}
        >
          Brokers
        </Navlink>
      </>
    )
  }

  const isEntityAdmin = (user.entities || []).some((entity) => entity.isAdmin)

  const priceChartSubMenu = (
    <>
      <Navlink
        data-testid="menu-price-charts-basis"
        to={computeLink(PRICE_CHARTS_BASIS)}
        Icon={Timeline}
        isDrawerOpen={isDrawerOpen}
        isMobile={isMobile}
      >
        Basis
      </Navlink>
      <Navlink
        data-testid="menu-price-charts-fixed"
        to={computeLink(PRICE_CHARTS_FIXED)}
        Icon={LinearScale}
        isDrawerOpen={isDrawerOpen}
        isMobile={isMobile}
      >
        Fixed
      </Navlink>
    </>
  )

  return (
    <>
      <Navlink
        data-testid="menu-dashboard"
        to={computeLink(DASHBOARD)}
        Icon={DashboardIcon}
      >
        Dashboard
      </Navlink>

      {isLNGContext && (
        <>
          <Navlink
            data-testid="menu-market-visualisation"
            to={computeLink(MARKET_VISUALISATION)}
            Icon={Equalizer}
          >
            Visualisation
          </Navlink>

          <Navlink
            data-testid="menu-price-charts"
            to={computeLink(PRICE_CHARTS_BASIS)}
            Icon={ShowChart}
            subMenu={priceChartSubMenu}
          >
            Price Charts
          </Navlink>

          <Navlink
            data-testid="menu-market-grid"
            to={computeLink(MARKET_GRID)}
            Icon={TableChartIcon}
          >
            Grid
          </Navlink>
        </>
      )}

      <Navlink
        data-testid="menu-notes"
        to={computeLink(NOTES)}
        Icon={VerticalSplitIcon}
      >
        Notes
      </Navlink>
      <Navlink
        data-testid="menu-bulletins"
        to={computeLink(BULLETINS)}
        Icon={FeedbackIcon}
      >
        Bulletins
      </Navlink>

      {isLNGContext && (
        <>
          <Navlink
            data-testid="menu-nominations"
            to={computeLink(NOMINATIONS)}
            Icon={CheckCircleIcon}
          >
            Nominations
          </Navlink>
        </>
      )}

      {(
        <Navlink
          data-testid="menu-transactions"
          to={computeLink(TRANSACTION_BOOK)}
          Icon={DoneAllIcon}
        >
          Transactions
        </Navlink>
      )}

      {hasTradePermission && (
        <Navlink
          data-testid="menu-traders"
          to={computeLink(TRADERS)}
          Icon={GroupIcon}
        >
          Traders
        </Navlink>
      )}

      {isEntityAdmin && (
        <Navlink
          data-testid="menu-settings"
          to={computeLink(SETTINGS)}
          Icon={SettingsApplicationsIcon}
        >
          Settings
        </Navlink>
      )}
    </>
  )
}

_SideMenuOptions.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export const SideMenuOptions = memo(_SideMenuOptions)
