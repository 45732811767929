import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _Button from '@material-ui/core/Button'
import { Grid, useTheme } from '@material-ui/core'
import {
  useCanShareOrderWithBroker,
  useGetBrokerForEntityAndShareOrder,
} from 'hooks'
import { Mobile, Tablet } from 'components/Viewport'
import { BrandLogo } from 'components/logo'

const Button = styled(_Button)`
  background: ${({ theme }) => theme.palette.other.pink};
  color: white;
`

export const ShareWithBrokerMenuButton = ({ order }) => {
  const canShareOrder = useCanShareOrderWithBroker(order)
  const { broker, mutateAsync: shareWithBroker, isLoading } = useGetBrokerForEntityAndShareOrder(order?.entity?.id)
  const { palette } = useTheme()

  if (!canShareOrder) {
    return null
  }

  const share = () => shareWithBroker({ orderId: order.id, broker })

  return (
    <Grid item style={{ fontSize: 0 }}>
      <Mobile>
        <BrandLogo
          data-testid="share-with-emstream-button"
          width={40}
          height={40}
          backgroundColor={palette.other.pink}
          onClick={share}
        />
      </Mobile>
      <Tablet matchLarger>
        <Button
          data-testid="share-with-emstream-button"
          disabled={isLoading}
          variant="contained"
          onClick={share}
        >
          Share with Emstream
        </Button>
      </Tablet>
    </Grid>
  )
}

ShareWithBrokerMenuButton.propTypes = {
  order: PropTypes.object.isRequired,
}
