import React from 'react'
import TextField from 'containers/formikTextField/FormikTextField'
import { PaddedContainer } from 'screens/orderCreate/mobile/components'

export const Notes = () => {
  return (
    <PaddedContainer>
      <TextField
        data-testid="public-note-field"
        fullWidth
        margin="normal"
        multiline
        rows={10}
        name="details"
        placeholder="Insert public note"
      />
    </PaddedContainer>
  )
}
