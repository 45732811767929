import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { TextField } from './TextField'
import FormRow from 'components/formRow/FormRow'

export const TradeAdditionalParty = connect(({ partyName, label }) => {
  const nameKey = `${partyName}.name`
  const emailKey = `${partyName}.email`
  const phoneKey = `${partyName}.phone`

  const options = [
    {
      required: false,
      name: `${nameKey}`,
      label: `${label} Name`,
    },
    {
      required: false,
      name: `${emailKey}`,
      label: `${label} Email`,
    },
    {
      required: false,
      name: `${phoneKey}`,
      label: `${label} Phone`,
    },
  ]
  return (
    <>
      <FormRow>
        <FormRow>
          {options.map((props) => (
            <TextField
              key={`${props.name}`}
              {...props}
            />
          ))}
        </FormRow>
      </FormRow>
    </>
  )
})

TradeAdditionalParty.defaultProps = {
  formik: {},
}

TradeAdditionalParty.propTypes = {
  formik: PropTypes.object,
}
