import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import styled from 'styled-components'
import Footer, { FOOTER_HEIGHT } from './Footer'
import { LayoutContext } from 'containers/layout/LayoutProvider'
import { SIDE_MENU_WIDTH } from 'components/sideMenu/SideMenu'

export const PADDING_Y_UNIT = 3

const Wrapper = styled.main`
  position: relative;
  display: block;
  min-width: 1280px;
  margin-top: ${({ theme }) => theme.custom.desktop.navbar.height}px;
  height: ${({ theme }) =>
    `calc(100vh - ${theme.custom.desktop.navbar.height}px)`};
  overflow: auto;
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  padding-right: ${({ theme }) => theme.spacing(3)}px;
  padding-top: ${({ theme }) => theme.spacing(PADDING_Y_UNIT)}px;
`

const ContentWrapper = styled.div`
  min-height: calc(100% - ${FOOTER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`

const useStyles = makeStyles((theme) => ({
  shift: {
    marginLeft: `${SIDE_MENU_WIDTH}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  unshift: {
    marginLeft: theme.spacing(7) + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}))

export const Main = ({ children, ...props }) => {
  const { hasSideMenu } = useContext(LayoutContext)
  const classes = useStyles()

  return (
    <Wrapper
      className={hasSideMenu ? classes.shift : classes.unshift}
      {...props}
    >
      <ContentWrapper>{children}</ContentWrapper>
      <Footer />
    </Wrapper>
  )
}

Main.defaultProps = {
  style: {},
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}
