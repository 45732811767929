import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'

import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'
import { SelectCargoSizeUnit } from 'screens/orderCreate/components/SelectCargoSizeUnit'

export const Unit = () => (
  <FormControl fullWidth>
    <FormLabel>Unit</FormLabel>
    <SelectCargoSizeUnit>
      {({ options, onChange }) => (
        <RadioInputs
          onChange={(evt) => onChange(evt.target.value)}
          name="cargoSize.unit"
          options={options}
        />
      )}
    </SelectCargoSizeUnit>
  </FormControl>
)
