import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import CompanyCode from 'components/CompanyCode/CompanyCode'
import { StyledLink as Link } from 'components/StyledLink'
import { getBaseSlug } from 'utils/useRouteBaseSlug'

const useStyles = makeStyles((theme) => ({
  pill: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 5,
  },
  pillContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1 / 3),
  },
}))

export const BidAskContainer = ({ bidOrder, askOrder }) => {
  const classes = useStyles()
  return (
    <>
      <Grid xs={6} item className={classes.pillContainer}>
        <Link
          to={(location) => `/${getBaseSlug(location)}/orders/${bidOrder.id}`}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.pill}
          >
            <Grid item>
              <CompanyCode
                data-testid="bid-order-company-code"
                order={bidOrder}
              />
            </Grid>
            <Grid item>
              <Text variant="caption">BID</Text>
            </Grid>
          </Grid>
        </Link>
      </Grid>

      <Grid xs={6} item className={classes.pillContainer}>
        <Link
          to={(location) => `/${getBaseSlug(location)}/orders/${askOrder.id}`}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.pill}
          >
            <Grid item>
              <Text variant="caption">ASK</Text>
            </Grid>
            <Grid item>
              <CompanyCode
                data-testid="ask-order-company-code"
                order={askOrder}
              />
            </Grid>
          </Grid>
        </Link>
      </Grid>
    </>
  )
}

BidAskContainer.defaultProps = {
  bidOrder: {},
  askOrder: {},
}

BidAskContainer.propTypes = {
  bidOrder: PropTypes.object.isRequired,
  askOrder: PropTypes.object.isRequired,
}
