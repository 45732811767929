import React, { useState, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useLocation, useHistory, Route } from 'react-router'
import queryString from 'query-string'
import { isEmpty, memoize } from 'lodash'
import moment from 'moment'
import { Typography as Text, useTheme } from '@material-ui/core'
import {
  getFormattedOrders,
  getFormattedGroups,
  getFormattedCargos,
  getDeliveryProps,
  getKeys,
  getOrders,
  withHighlightStyle,
} from './MarketVisualisationTimeline.utils'
import { Box, OrderLink } from './MarketVisualisationTimeline.components'
import { Header } from './Header'
import { getItemStyleProps as _getItemStyleProps } from 'utils/charts'
import { useRouteParamsUpdater } from 'utils/useRouteParamsUpdater'
import { useFetchOrders } from 'hooks'
import { PERIOD } from 'screens/marketVisualisation/MarketVisualisation.constants'
import { typeMap } from 'screens/marketVisualisation/containers/periodRow/PeriodRow.constants'
import { Timeline } from 'components/Timeline'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { STATUS, STATUS_VALUES } from 'components/StatusFilters'
import { useUser } from 'containers/user/useUser'
import { defaultCss } from 'components/Timeline/constants'
import { MARKET_VISUALISATION_TIMELINE } from 'routes'
import ViewOrder from 'screens/orderView/ViewOrder'

const ITEM_HEIGHT = 8
const MIDDLE_OF_MONTH = 15

const CSS = `
  ${defaultCss}

  .vis-panel.vis-top :hover {
    cursor: pointer;       
  }

  .vis-item {
    border: 0;
    background-color: rgba(0,0,0,0);
  }

  .vis-item-visible-frame {
    transform: translateY(-${16 - ITEM_HEIGHT / 2}px);
  }
`

const timelineContainerStyle = { flex: 1 }

const defaultOptions = {
  stack: false,
  editable: false,
  zoomable: false,
  moveable: true,
  selectable: false,
  orientation: 'top',
  showCurrentTime: false,
  horizontalScroll: true,
  verticalScroll: true,
  height: '100%',
  timeAxis: {
    scale: 'day',
    step: 2,
  },
}

const MarketVisualisationTimeline = () => {
  const location = useLocation()
  const history = useHistory()
  const { palette } = useTheme()
  const {
    highlightId,
    status: qStatus = STATUS.ACTIVE,
    type = PERIOD.ANNUAL,
    period = typeMap[PERIOD.ANNUAL].keyGenerator(moment.now()),
  } = queryString.parse(location.search)
  const [status, setStatus] = useState(qStatus)
  const periodStartDate = typeMap[type]?.getStartDate(period)
  const { loading, orders: _orders } = useFetchOrders({
    status: STATUS_VALUES[status],
    template: false,
    ...getDeliveryProps(type, period, periodStartDate),
  })
  const keys = getKeys(type, _orders)
  const orders = getOrders(type, period, _orders)
  const { user } = useUser()
  const getItemStyleProps = memoize(_getItemStyleProps, ({ id }) => id)
  const visibleFrameTemplate = (item, element) => {
    const style = getItemStyleProps(
      orders.find(({ id }) => id === item.orderId),
      user,
      palette
    )
    return ReactDOM.createPortal(
      ReactDOM.render(
        <div style={{ ...style, height: `${ITEM_HEIGHT}px` }}>
          {item.content}
        </div>,
        element
      ),
      element
    )
  }
  const groupTemplate = (group, element) => {
    if (!group || !group.content) {
      return
    }

    if (group.treeLevel < 2) {
      return group.content
    }

    const viewOrder = () =>
      history.push(
        `${MARKET_VISUALISATION_TIMELINE}/orders/${group.id}${location.search}`
      )
    return ReactDOM.createPortal(
      ReactDOM.render(
        <OrderLink onClick={viewOrder}>{group.content}</OrderLink>,
        element
      ),
      element
    )
  }
  const params = useMemo(() => ({ status }), [status])
  useRouteParamsUpdater(params)

  if (!periodStartDate || !periodStartDate.isValid()) {
    return (
      <Box display="flex" justifyContent="center" py={8}>
        <Text variant="h6">Invalid parameters</Text>
      </Box>
    )
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const groups = [
    ...getFormattedGroups(orders),
    ...withHighlightStyle(getFormattedOrders(orders), highlightId),
  ]
  const start = periodStartDate.clone().subtract(1, 'day')
  const timelineViewport = {
    min: start,
    max: periodStartDate.clone().add(12, 'month'),
    start,
    end: periodStartDate.clone().add(2, 'month').add(1, 'day'),
  }
  const cargos = getFormattedCargos(orders)
  const handlers = {
    click: (timeline, evt) =>
      evt.what === 'axis' &&
      timeline.moveTo(moment(evt.time).date(MIDDLE_OF_MONTH)),
    complete: (timeline) => {
      if (highlightId) {
        const selectableCargos = cargos
          .filter(({ orderId }) => orderId === highlightId)
          .map(({ id }) => id)

        timeline.focus(selectableCargos, { zoom: false, animation: false })
        timeline.setWindow(timelineViewport) // window zoom done by focus() needs to be reset to original values
      }
    },
  }
  const options = {
    ...defaultOptions,
    ...timelineViewport,
    groupTemplate,
    visibleFrameTemplate,
  }

  return (
    <>
      <Header
        status={status}
        onStatusChange={setStatus}
        type={type}
        keys={keys}
        period={period}
      />
      <Box display="flex" justifyContent="center">
        <h1>{period}</h1>
      </Box>
      {isEmpty(orders) ? (
        <Box display="flex" justifyContent="center" py={8}>
          <Text variant="h6">No orders</Text>
        </Box>
      ) : (
        <div style={{ flex: 1, display: 'flex' }}>
          <Timeline
            groups={groups}
            items={cargos}
            options={options}
            handlers={handlers}
            css={CSS}
            containerStyle={timelineContainerStyle}
          />
        </div>
      )}
      <Route
        path={`${MARKET_VISUALISATION_TIMELINE}/orders/:id`}
        component={() => (
          <ViewOrder
            onClose={() =>
              history.push(`${MARKET_VISUALISATION_TIMELINE}${location.search}`)
            }
          />
        )}
      />
    </>
  )
}

export default MarketVisualisationTimeline
