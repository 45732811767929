import React from 'react'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { toPairs } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography as Text } from '@material-ui/core'
import { ChevronLeft, ChevronRight, EventNote, Add } from '@material-ui/icons'

import { DatedOrders } from '../../components/DatedOrders'
import { PERIOD_TYPES } from '../../MarketVisualisation.constants'
import { usePeriodRow } from './PeriodRow.hook'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import IconButton from 'components/iconButton/IconButton'
import { NavButton } from 'screens/marketVisualisation/components/NavButton'

const ExpandButton = withProps({
  icon: Add,
  color: 'secondary',
  variant: 'contained',
  size: 'small',
  children: 'Expand',
})(IconButton)

const useStyles = makeStyles(({ spacing, typography }) => {
  return {
    root: {
      marginTop: spacing(1.5),
      position: 'relative',
      border: ({ expanded }) => (expanded ? 'none' : 'solid 2px #363f4f'),
      borderRadius: 5,
      width: '100%',
    },
    header: {
      padding: `${spacing(1.5)}px ${spacing(1)}px !important`,
    },
    titleIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: spacing(4),
    },
    body: {
      padding: ({ expanded }) => `${expanded ? 0 : spacing(1)}px !important`,
      paddingTop: '0px !important',
      flexGrow: 1,
      height: spacing(37),
      flexShrink: 0,
    },
    prevPageButton: {
      minHeight: spacing(4),
      height: spacing(4),
      width: spacing(4),
      lineHeight: spacing(2),
      marginRight: spacing(1),
    },
    nextPageButton: {
      minHeight: spacing(4),
      height: spacing(4),
      width: spacing(4),
      lineHeight: spacing(2),
      marginLeft: spacing(1),
    },
    expandButton: {
      height: spacing(3),
      fontSize: 0.8 * typography.fontSize,
      alignItems: 'center',
      padding: spacing(0, 1),
    },
  }
})

export const PeriodRow = ({ type, filters, expanded, onExpand }) => {
  const classes = useStyles({ expanded })

  const {
    orders,
    loading,
    prevPeriod,
    nextPeriod,
    canScrollForward,
    canScrollBackward,
    name,
  } = usePeriodRow(type, filters)

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      spacing={1}
      className={classes.root}
    >
      {!expanded && (
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}
        >
          <Grid
            item
            container
            alignItems="center"
            spacing={1}
            style={{ width: 'auto' }}
          >
            <Grid item>
              <Text className={classes.titleIcon}>
                <EventNote fontSize="small" />
              </Text>
            </Grid>
            <Grid item>
              <Text style={{ fontWeight: 'bold', fontSize: '.9rem' }}>
                {name}
              </Text>
            </Grid>
          </Grid>

          <Grid item>
            <ExpandButton
              className={classes.expandButton}
              onClick={() => onExpand && onExpand(type)}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        item
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.body}
      >
        {loading && <LoadingSpinner />}

        <Grid item>
          <NavButton
            onClick={prevPeriod}
            disabled={!canScrollBackward}
            title="Previous period"
            className={classes.prevPageButton}
          >
            <ChevronLeft />
          </NavButton>
        </Grid>

        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          xs={11}
        >
          {toPairs(orders).map(([name, items]) => (
            <DatedOrders
              filters={filters}
              type={type}
              orders={items}
              name={name}
              expanded={expanded}
              key={`orders-column-${name}`}
            />
          ))}
        </Grid>

        <Grid item>
          <NavButton
            onClick={nextPeriod}
            disabled={!canScrollForward}
            title="Next period"
            className={classes.nextPageButton}
          >
            <ChevronRight />
          </NavButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

PeriodRow.defaultProps = {
  expanded: false,
}

PeriodRow.propTypes = {
  type: PropTypes.oneOf(PERIOD_TYPES).isRequired,
  filters: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
}
