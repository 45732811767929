const CLASSIFICATIONS = {
  NBS: { key: 'NBS', value: 'Nature Based Solutions (NBS)' },
  NNBS: { key: 'NNBS', value: 'Non-NBS' },
  ALL: { key: 'ALL', value: 'All' },
  REMO: { key: 'REMO', value: 'Removals' },
  AVOI: { key: 'AVOI', value: 'Avoidance/Reduction' },
  RA: { key: 'RA', value: 'Removals and Avoidance' },
}

const CLASSIFICATIONS_FIRST = [
  {
    value: CLASSIFICATIONS.NBS.key,
    label: CLASSIFICATIONS.NBS.value,
    short: CLASSIFICATIONS.NBS.key,
  },
  {
    value: CLASSIFICATIONS.NNBS.key,
    label: CLASSIFICATIONS.NNBS.value,
    short: CLASSIFICATIONS.NNBS.value,
  },
  {
    value: CLASSIFICATIONS.ALL.key,
    label: CLASSIFICATIONS.ALL.value,
    short: CLASSIFICATIONS.ALL.value,
  },
]
const CLASSIFICATIONS_SECOND = [
  {
    value: CLASSIFICATIONS.REMO.key,
    label: CLASSIFICATIONS.REMO.value,
  },
  {
    value: CLASSIFICATIONS.AVOI.key,
    label: CLASSIFICATIONS.AVOI.value,
  },
  {
    value: CLASSIFICATIONS.RA.key,
    label: CLASSIFICATIONS.RA.value,
  },
]

module.exports = {
  CLASSIFICATIONS_FIRST,
  CLASSIFICATIONS_SECOND,
  CLASSIFICATIONS,
}
