import React from 'react'
import { connect } from 'formik'
import { isEmpty, isObject } from 'lodash'
import { STAKEHOLDER_TYPE } from 'emsurge-selectors'
import { OrderSummary as OrderSummaryLNG } from 'components/OrderSummary'
import { OrderSummary as OrderSummaryCarbon } from 'components/OrderSummaryCarbon'
import useAppContext from 'containers/appContext/useAppContext'
import { useFetch } from 'containers/fetch/useFetch'
import { useUser } from 'containers/user/useUser'
import { getOrderInfo as getOrderInfoLNG } from 'screens/orderCreate/containers/FormProvider'
import { getOrderInfo as getOrderInfoCarbon } from 'screens/orderCreateCarbon/FormProvider'

export const getOrderUsingFormForBroker = (
  formValues,
  user,
  entities,
  isEdit,
  isLNGContext = true
) => {
  const getOrderInfo = isLNGContext ? getOrderInfoLNG : getOrderInfoCarbon
  const order = {
    ...getOrderInfo(formValues, user),
    orderBeenTradedTradingType: formValues.orderBeenTradedTradingType && formValues.orderBeenTradedTradingType.toLowerCase()
  }

  if (!isEdit) {
    order.brokerId = user.entities[0].id
  }

  if (
    formValues.behalfOf === STAKEHOLDER_TYPE.ON_SYSTEM &&
    !isEmpty(formValues.myCompany.entityId)
  ) {
    const entitySelected = entities.find(
      (e) => e.id === formValues.myCompany.entityId
    )

    if (isObject(entitySelected)) {
      order.company = entitySelected.company
      order.entity = entitySelected
    } else {
      console.error(
        `Could not find entity with id ${formValues.myCompany.entityId}`
      )
    }
  }

  return order
}

export const BrokerOrderSummary = connect(({ formik, isEdit, isCreatingTrade }) => {
  const { user } = useUser()
  const { isLNGContext } = useAppContext()
  const { loading, data: entities } = useFetch('/entities?type=trader')

  if (loading) {
    return null
  }

  const order = getOrderUsingFormForBroker(
    formik.values,
    user,
    entities,
    isEdit,
    isLNGContext
  )

  const OrderSummary = isLNGContext ? OrderSummaryLNG : OrderSummaryCarbon
  return <OrderSummary order={order} isCreatingTrade={isCreatingTrade}/>
})
