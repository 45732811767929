import { withProps } from 'recompose'
import { Link } from 'react-router-dom'
import Add from '@material-ui/icons/Add'
import Button from 'components/iconButton/IconButton'

const AddButton = withProps({
  'aria-label': 'Add',
  variant: 'contained',
  color: 'primary',
  icon: Add,
  children: 'Add',
  component: Link,
})(Button)

export default AddButton
