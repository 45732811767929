import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Edit from '@material-ui/icons/Edit'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from 'components/iconButton/IconButton'
import TableRowLink from 'components/tableRowLink/TableRowLink'
import { ADMIN_VIEW_ENTITY, ADMIN_EDIT_BROKER_ENTITIES } from 'routes'

const EditEntitiesButton = withRouter(({ broker, history, ...props }) => (
  <IconButton
    data-testid={props?.['data-testid'] && `${props['data-testid']}`}
    icon={Edit}
    onClick={(e) => {
      e.stopPropagation()
      history.push(ADMIN_EDIT_BROKER_ENTITIES.replace(':id', broker.id))
    }}
  />
))

const BrokersTable = ({ brokers }) => (
  <Paper>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Code</TableCell>
          <TableCell align="right">Number of entities permissioned</TableCell>
          <TableCell align="right" />
        </TableRow>
      </TableHead>

      <TableBody>
        {brokers.map((brokerEntity) => (
          <TableRowLink
            key={brokerEntity.id}
            to={ADMIN_VIEW_ENTITY.replace(':id', brokerEntity.id)}
          >
            <TableCell>{brokerEntity.name}</TableCell>
            <TableCell>{`${brokerEntity.company.code}${brokerEntity.code}`}</TableCell>
            <TableCell align="right">{brokerEntity.entities.length}</TableCell>
            <TableCell align="right">
              <EditEntitiesButton
                data-testid="edit-broker-access-button"
                broker={brokerEntity}
              />
            </TableCell>
          </TableRowLink>
        ))}
      </TableBody>
    </Table>
  </Paper>
)

BrokersTable.propTypes = {
  brokers: PropTypes.array.isRequired,
}

export default BrokersTable
