import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'

import {
  PRICE_TYPE,
  PRICE_TYPE_TEXT,
  PRICE_VARIANT,
} from 'emsurge-selectors/constants'
import { TextField } from 'screens/orderCreateCommon/components/TextField'

export const Preference = ({ variant }) => {
  const priceTypeOptions = Object.values(PRICE_TYPE)
    .filter((PRICE_TYPE_VALUE) => PRICE_TYPE_VALUE !== PRICE_TYPE.PREFERENCE)
    .map((PRICE_TYPE_VALUE) => (
      <MenuItem
        data-testid="preference-price-values"
        key={PRICE_TYPE_VALUE}
        value={PRICE_TYPE_VALUE}
      >
        {PRICE_TYPE_TEXT[PRICE_TYPE_VALUE]}
      </MenuItem>
    ))

  return (
    <TextField
      data-testid="preference-price-dropdown"
      select
      name={`price.${variant}.preferenceInfo.value`}
      label="Preference"
    >
      {priceTypeOptions}
    </TextField>
  )
}

Preference.propTypes = {
  variant: PropTypes.oneOf(Object.values(PRICE_VARIANT)).isRequired,
}
