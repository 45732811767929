import React from 'react'
import PropTypes from 'prop-types'
import { useSkeletonStyles } from 'screens/orderIndex/helpers'

export const ActivityCardSkeleton = ({ style }) => {
  const { root } = useSkeletonStyles()
  const defaultStyles = {
    width: '100%',
    borderRadius: '5px',
  }
  return <div className={root} style={{ ...defaultStyles, ...style }} />
}

ActivityCardSkeleton.propTypes = {
  style: PropTypes.object,
}
