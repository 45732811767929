import React from 'react'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { $U, PERSON_CLIENT_TYPE } from 'emsurge-selectors'
import FormControl from '../../components/SelectionFormControl'
import { TextField } from '../../components/TextField'
import { OnBehalfOf } from '../../components/OnBehalfOf'
import { NewTradeParties } from '../../components/NewTradeParties'
import { MyCompanyEntitiesSelect } from '../../components/MyCompanyEntitiesSelect'
import { TraderEntitiesSelect } from '../../components/TraderEntitiesSelect'
import { ThirdPartyInfo } from '../../components/ThirdPartyInfo'
import { OrderStatus } from '../../components/OrderStatus'
import { OrderType } from '../../components/OrderType'
import { OrderValidity } from '../../components/OrderValidity'
import { useUser } from 'containers/user/useUser'
import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'
import FormRow from 'components/formRow/FormRow'
import { DateTime } from 'components/DateTime'
import {
  useOrderFlow,
  ORDER_FLOW,
  useOrderState,
} from 'containers/OrderProvider'

const OrderValidityField = () => (
  <OrderValidity>
    {(options) => (
      <FormControl required fullWidth>
        <FormLabel>Visibility</FormLabel>
        <RadioInputs name="validity.until" options={options} />
      </FormControl>
    )}
  </OrderValidity>
)

const OrderStatusField = () => (
  <OrderStatus>
    {({ options, onChange, value }) => (
      <FormControl required>
        <FormLabel>Status</FormLabel>
        <RadioGroup
          onChange={(evt) => onChange(evt.target.value)}
          value={value}
          style={{ flexDirection: 'row' }}
        >
          {options.map(({ value, label, disabled }) => (
            <FormControlLabel
              key={value}
              value={value}
              label={label}
              disabled={disabled}
              control={
                <Radio
                  color="primary"
                  size="small"
                  inputProps={{ 'data-testid': `status-${value}` }}
                />
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    )}
  </OrderStatus>
)

const _General = ({ company, isCreatingTrade, isEditTrade}) => {
  const { flow } = useOrderFlow()
  const { user } = useUser()
  const {
    orderState: { isTermsheet, hasTermsheets },
  } = useOrderState()
  const clientType = $U.clientType.get(user)

  if (isTermsheet && !(isCreatingTrade || isEditTrade)) {
    return (
      <>
        <OrderStatusField />
        <OrderValidityField />
      </>
    )
  }

  return (
    <>
      <DateTime
        name="submittedAt"
        disabled={hasTermsheets && flow === ORDER_FLOW.EDIT}
      />
      {!(isCreatingTrade || isEditTrade) && (
      <OnBehalfOf>
        {(options) => (
          <FormControl required>
            <FormLabel>On behalf of</FormLabel>
            <RadioInputs name="behalfOf" options={options} />
          </FormControl>
        )}
      </OnBehalfOf>
      )}
      {!(isCreatingTrade || isEditTrade) && clientType === PERSON_CLIENT_TYPE.TRADER && (
        <MyCompanyEntitiesSelect company={company} />
      )}
      {!(isCreatingTrade || isEditTrade) && clientType === PERSON_CLIENT_TYPE.BROKER && <TraderEntitiesSelect />}
      {!(isCreatingTrade || isEditTrade) && (
      <ThirdPartyInfo>
        {(options) => (
          <FormRow>
            {options.map((props, index) => (
              <TextField
                inputProps={{
                  'data-testid': `third-party-${options[
                    index
                  ].label.toLowerCase()}-field`,
                }}
                key={`third-party-info-${index}`}
                {...props}
              />
            ))}
          </FormRow>
        )}
      </ThirdPartyInfo>
      )}

      {(isCreatingTrade || isEditTrade) && (
        <>
          <NewTradeParties partyName='buyer' />
          <NewTradeParties partyName='seller' />
        </>
      )}

      {!(isCreatingTrade || isEditTrade) && (
        <>
        <OrderType>
          {(options) => (
            <div>
              <FormControl required>
                <FormLabel>Type</FormLabel>
                <RadioInputs name="tradingType" options={options} />
              </FormControl>
            </div>
          )}
        </OrderType>
        <OrderStatusField />
        <OrderValidityField />
        </>
      )}
    </>
  )
}

_General.propTypes = {
  company: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
}

export const General = connect(_General)
