const map = require('lodash/map')

const CARGO_SIZE_UNIT = Object.freeze({
  TBTU: 'tbtu',
  M3: 'm3',
  GWH: 'gwh',
})

const CARGO_SIZE_UNIT_TEXT = Object.freeze({
  [CARGO_SIZE_UNIT.TBTU]: 'TBtu',
  [CARGO_SIZE_UNIT.M3]: 'm³',
  [CARGO_SIZE_UNIT.GWH]: 'GWh',
})

const CARGO_SIZE_UNIT_TITLE = {
  [CARGO_SIZE_UNIT.TBTU]: 'Cargo Size (Estimated Delivered Quantity)',
  [CARGO_SIZE_UNIT.M3]: 'Type',
  [CARGO_SIZE_UNIT.GWH]: 'Type',
}

const CARGO_SIZE_UNIT_OPTIONS = map(CARGO_SIZE_UNIT_TEXT, (label, value) => ({
  value,
  label,
}))

const CARGO_SIZE_UNIT_MMBTU = 'MMBtu'

module.exports = {
  CARGO_SIZE_UNIT_MMBTU,
  CARGO_SIZE_UNIT,
  CARGO_SIZE_UNIT_OPTIONS,
  CARGO_SIZE_UNIT_TEXT,
  CARGO_SIZE_UNIT_TITLE,
}
