const ORDER_STATUS = Object.freeze({
  CANCELLED: 'cancelled',
  CLOSED: 'closed',
  LIVE: 'live',
  PENDING: 'pending',
  TRADED: 'traded',
  WITHHELD: 'withheld',
})

const NEW_TRADE = "New Trade"

const ORDER_STATUS_TEXT = Object.freeze({
  [ORDER_STATUS.CANCELLED]: 'Cancelled',
  [ORDER_STATUS.CLOSED]: 'Archived',
  [ORDER_STATUS.LIVE]: 'Live',
  [ORDER_STATUS.PENDING]: 'Pending',
  [ORDER_STATUS.TRADED]: 'Traded',
  [ORDER_STATUS.WITHHELD]: 'Withheld',
  CANCELLED: 'Cancelled',
  CLOSED: 'Archived',
  LIVE: 'Live',
  PENDING: 'Pending',
  TRADED: 'Traded',
  WITHHELD: 'Withheld',
})

const ORDER_STATUS_MARKET = Object.freeze({
  ACTIVE: 'active',
  PENDING: 'pending',
})

const ORDER_STATUS_MARKET_TEXT = Object.freeze({
  [ORDER_STATUS_MARKET.ACTIVE]: 'Active',
  [ORDER_STATUS_MARKET.PENDING]: 'Pending',
  ACTIVE: 'Active',
  PENDING: 'Pending',
})

module.exports = {
  ORDER_STATUS,
  ORDER_STATUS_TEXT,
  ORDER_STATUS_MARKET,
  ORDER_STATUS_MARKET_TEXT,
  NEW_TRADE
}
