import { $O, PRICE_PLUS_MINUS, PRICE_TYPE } from 'emsurge-selectors'
import { cloneDeep } from 'lodash'
import { getMidPointFromPriceList } from './useImpliedPrice.helpers'

export const sum = (a, b) => a + b
export const subtraction = (a, b) => a - b
export const isFloatingPrice = (type) => type === PRICE_TYPE.FLOATING

export const calculateFixedPrice = (
  { amount, percentage, plusOrMinus, index, delivery },
  priceIndexes
) => {
  const midpoint =
    getMidPointFromPriceList(priceIndexes?.[index]?.[delivery]) || 0
  const method = plusOrMinus === PRICE_PLUS_MINUS.PLUS ? sum : subtraction
  const fixedPrice = method(midpoint * (percentage / 100), amount || 0)
  return {
    value: Math.max(0, fixedPrice),
    midpoint,
  }
}

export const computeFixedPrices = (orders, priceIndexes) => {
  const clonedOrders = cloneDeep(orders)
  clonedOrders.map((order) => {
    const price = $O.price.get(order)
    const priceType = $O.price.type(order)
    if (isFloatingPrice(priceType)) {
      const { value, midpoint } = calculateFixedPrice(price, priceIndexes)
      order.price.basic.info.fixedPrice = value
      order.price.basic.info.indexAmount = midpoint
    }
    return order
  })
  return clonedOrders
}
