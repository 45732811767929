const alternatives = require('./alternatives')
const arrival = require('./arrival')
const cargos = require('./cargos')
const isProRata = require('./is-pro-rata')
const type = require('./type')

const getDeliveryWindow = (terms) => {
  return {
    alternatives: alternatives.get(terms),
    arrival: arrival.get(terms),
    cargos: cargos.get(terms),
    isProRata: isProRata.get(terms),
    type: type.get(terms),
  }
}

const getDeliveryWindowSummary = (terms) => {
  return {
    alternatives: alternatives.summary(terms),
    arrival: arrival.summary(terms),
    cargos: cargos.summary(terms),
    isProRata: isProRata.summary(terms),
    type: type.text(terms),
  }
}

const getDeliveryWindowText = (terms) => {
  return {
    alternatives: alternatives.text(terms),
    arrival: arrival.text(terms),
    cargos: cargos.text(terms),
    isProRata: isProRata.text(terms),
    type: type.text(terms),
  }
}

module.exports = {
  get: getDeliveryWindow,
  summary: getDeliveryWindowSummary,
  text: getDeliveryWindowText,
  arrival,
  cargos,
  type,
  alternatives,
  isProRata,
}
