import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { cloneDeep, find, noop, omit } from 'lodash'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { ORDER_KEY } from 'api/constants'

const omitArrayProps = (collection, props) =>
  collection.map((item) => omit(item, props))

const mapProps = (checklist) => ({
  ...omit(checklist, ['id']),
  items: omitArrayProps(checklist?.items || [], [
    'checkedDate',
    'checkedPersonId',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'checkedPerson',
  ]),
})

export const useEditChecklist = () => {
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const putChecklist = async (checklist) =>
    api.editChecklist(checklist.id, mapProps(checklist))

  const updateCachedOrder = (order) => {
    const queryKey = [ORDER_KEY, order.id]
    queryClient.setQueryData(queryKey, order)
  }

  const itemMapper = (previousItems) => (currentItem) => {
    const { isChecked, checkedDate, checkedPersonId, checkedPerson } =
      find(previousItems, ({ id }) => id === currentItem.id) || {}
    return {
      ...currentItem,
      isChecked,
      checkedDate,
      checkedPersonId,
      checkedPerson,
    }
  }

  const onMutate = ({ orderId, items }) => {
    const query = [ORDER_KEY, orderId]
    queryClient.cancelQueries(query)
    const previousOrder = cloneDeep(queryClient.getQueryData(query))
    const rollback = () => queryClient.setQueryData(query, previousOrder)
    updateCachedOrder({
      id: orderId,
      ...previousOrder,
      checklist: [
        {
          ...previousOrder?.checklist?.[0],
          items: items.map(itemMapper(previousOrder?.checklist?.[0]?.items)),
        },
      ],
    })
    return rollback
  }

  const onSuccess = (_, checklist) => {
    enqueueSnackbar('Checklist updated successfully', { variant: 'success' })

    // must be invalidated in case new items are created and their id needs to be referenced
    queryClient.invalidateQueries([ORDER_KEY, checklist.orderId])
  }

  const onError = (error, _, rollback = noop) => {
    const message = getApiErrorMessage({
      error,
      defaultMessage: 'Checklist update failed',
    })
    enqueueSnackbar(message, { variant: 'error' })
    rollback()
  }

  return useMutation(putChecklist, {
    onMutate,
    onSuccess,
    onError,
    throwOnError: true,
  })
}
