import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import RemoveEntityPermission from '../containers/RemoveEntityPermission'
import TableRowLink from 'components/tableRowLink/TableRowLink'
import { ADMIN_VIEW_ENTITY } from 'routes'

const EntitiesTable = ({ entities, refetch }) => (
  <Paper>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Company</TableCell>
          <TableCell>Entity</TableCell>
          <TableCell>Entity Code</TableCell>
          <TableCell align="right" />
        </TableRow>
      </TableHead>

      <TableBody>
        {entities.map((entity) => (
          <TableRowLink
            key={entity.id}
            to={ADMIN_VIEW_ENTITY.replace(':id', entity.id)}
          >
            <TableCell>{entity.company.name}</TableCell>
            <TableCell>{entity.name}</TableCell>
            <TableCell>
              {entity.company.code}
              {entity.code}
            </TableCell>
            <TableCell align="right">
              <RemoveEntityPermission
                entityToRemove={entity}
                entities={entities}
                refetchPermissions={refetch}
              />
            </TableCell>
          </TableRowLink>
        ))}
      </TableBody>
    </Table>
  </Paper>
)

EntitiesTable.propTypes = {
  refetch: PropTypes.func.isRequired,
  entities: PropTypes.array.isRequired,
}

export default EntitiesTable
