const DELIVERY_PERIOD_QUARTER = Object.freeze({
  Q1: 'q1',
  Q2: 'q2',
  Q3: 'q3',
  Q4: 'q4',
})

const DELIVERY_PERIOD_MONTH = Object.freeze({
  JAN: 'january',
  FEB: 'february',
  MAR: 'march',
  APR: 'april',
  MAY: 'may',
  JUN: 'june',
  JUL: 'july',
  AUG: 'august',
  SEP: 'september',
  OCT: 'october',
  NOV: 'november',
  DEC: 'december',
})

const DELIVERY_PERIOD_SEASON = Object.freeze({
  SUMMER: 'summer',
  WINTER: 'winter',
})

const DELIVERY_PERIOD_YEAR = Object.freeze({
  GAS_YEAR: 'gas_year',
  CAL_YEAR: 'year',
})

const DELIVERY_PERIOD_VARIABLE_YEAR = Object.freeze({
  M: 'm',
  M1: 'm-1',
})

const DELIVERY_PERIOD_CUSTOM = Object.freeze({
  CUSTOM: 'custom',
})

const DELIVERY_PERIOD = Object.freeze({
  ...DELIVERY_PERIOD_CUSTOM,
  ...DELIVERY_PERIOD_YEAR,
  ...DELIVERY_PERIOD_VARIABLE_YEAR,
  ...DELIVERY_PERIOD_SEASON,
  ...DELIVERY_PERIOD_MONTH,
  ...DELIVERY_PERIOD_QUARTER,
})

const DELIVERY_PERIOD_TEXT = Object.freeze({
  [DELIVERY_PERIOD.CUSTOM]: 'Custom',
  [DELIVERY_PERIOD.GAS_YEAR]: 'GY',
  [DELIVERY_PERIOD.CAL_YEAR]: 'CAL',
  [DELIVERY_PERIOD.M]: 'M',
  [DELIVERY_PERIOD.M1]: 'M-1',
  [DELIVERY_PERIOD.SUMMER]: 'Sum', // TODO: change this to SUM
  [DELIVERY_PERIOD.WINTER]: 'Win', // TODO: change this to WIN
  [DELIVERY_PERIOD.Q1]: 'Q1',
  [DELIVERY_PERIOD.Q2]: 'Q2',
  [DELIVERY_PERIOD.Q3]: 'Q3',
  [DELIVERY_PERIOD.Q4]: 'Q4',
  [DELIVERY_PERIOD.JAN]: 'January',
  [DELIVERY_PERIOD.FEB]: 'February',
  [DELIVERY_PERIOD.MAR]: 'March',
  [DELIVERY_PERIOD.APR]: 'April',
  [DELIVERY_PERIOD.MAY]: 'May',
  [DELIVERY_PERIOD.JUN]: 'June',
  [DELIVERY_PERIOD.JUL]: 'July',
  [DELIVERY_PERIOD.AUG]: 'August',
  [DELIVERY_PERIOD.SEP]: 'September',
  [DELIVERY_PERIOD.OCT]: 'October',
  [DELIVERY_PERIOD.NOV]: 'November',
  [DELIVERY_PERIOD.DEC]: 'December',
})

const DELIVERY_PERIOD_VARIABLE_YEAR_TEXT = {
  [DELIVERY_PERIOD_VARIABLE_YEAR.M1]: 'M-1',
  [DELIVERY_PERIOD_VARIABLE_YEAR.M]: 'M',
}

const DELIVERY_PERIOD_TYPE = {
  MONTH: 1,
  QUARTER: 10,
  SEASON: 20,
  YEAR: 30,
}

const DELIVERY_PERIOD_TYPE_TEXT = {
  [DELIVERY_PERIOD_TYPE.MONTH]: 'Months',
  [DELIVERY_PERIOD_TYPE.QUARTER]: 'Quarters',
  [DELIVERY_PERIOD_TYPE.SEASON]: 'Seasons',
  [DELIVERY_PERIOD_TYPE.YEAR]: 'Years',
  MONTH: 'Months',
  QUARTER: 'Quarters',
  SEASON: 'Seasons',
  YEAR: 'Years',
}

module.exports = {
  DELIVERY_PERIOD_CUSTOM,
  DELIVERY_PERIOD_YEAR,
  DELIVERY_PERIOD_VARIABLE_YEAR,
  DELIVERY_PERIOD_SEASON,
  DELIVERY_PERIOD_MONTH,
  DELIVERY_PERIOD_QUARTER,
  DELIVERY_PERIOD,
  DELIVERY_PERIOD_TEXT,
  DELIVERY_PERIOD_TYPE,
  DELIVERY_PERIOD_TYPE_TEXT,
  DELIVERY_PERIOD_VARIABLE_YEAR_TEXT,
}
