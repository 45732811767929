const getOtherTerms = (order) => order.alternatives || {}

const getOtherTermsText = (order) => {
  const { otherTerms } = getOtherTerms(order)
  return {
    otherTerms,
  }
}

module.exports = {
  get: getOtherTerms,
  text: getOtherTermsText,
}
