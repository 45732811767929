/** Routes */
export const HOME = '/'
export const REGISTER_COMPANY = '/company/register'
export const DASHBOARD = '/dashboard'
export const NEW_PASSWORD = '/password/new'
export const NEW_PASSWORD_REQUEST = '/reset-password'
export const ERROR = '/error'
export const ORDER_SHARE = '/orders/share/:token'
export const NOTES = '/notes'
export const BULLETINS = '/bulletins'
export const TRANSACTION_BOOK = '/transactions'
export const TRADERS = '/traders'
export const SETTINGS = '/settings'
export const NOMINATIONS = '/nominations'
export const PRIVACY_POLICY = '/privacy-policy'
export const TERMS_CONDITIONS = '/terms-and-conditions'
export const FEATURE_FLAGS = '/feature-flags'

export const MARKET_VISUALISATION = '/market-visualisation'
export const MARKET_VISUALISATION_TIMELINE = `${MARKET_VISUALISATION}/timeline`
export const PRICE_CHARTS_BASIS = '/price-charts-basis'
export const PRICE_CHARTS_FIXED = '/price-charts-fixed'

export const MARKET_GRID = '/market-grid'
export const MARKET_GRID_VIEW = '/market-grid/:id'

export const ORDER_BASE_ROUTES = [
  DASHBOARD,
  MARKET_VISUALISATION,
  MARKET_VISUALISATION_TIMELINE,
  NOMINATIONS,
  TRANSACTION_BOOK,
  PRICE_CHARTS_BASIS,
  PRICE_CHARTS_FIXED,
  MARKET_GRID,
]
const ORDER_BASE_SLUGS = ORDER_BASE_ROUTES.reduce(
  (prev, acc) => `${prev}|${acc}`,
  ''
).slice(1)

export const ORDER_VIEW = `(${ORDER_BASE_SLUGS})/orders/:id`
export const ORDER_COUNTER_CREATE = `${ORDER_VIEW}/counter`
export const ORDER_CLONE = `${ORDER_VIEW}/clone`
export const ORDER_CREATE_TEMPLATE = `${ORDER_VIEW}/create-template`
export const ORDER_EDIT = `${ORDER_VIEW}/edit`
export const ORDER_SWAP = `${ORDER_VIEW}/swap`
export const ORDER_MARK_AS_TRADED = `${ORDER_VIEW}/mark-as-traded`

export const TERMSHEET_VIEW = `(${ORDER_BASE_SLUGS})/termsheets/:id`
export const TERMSHEET_EDIT = `${TERMSHEET_VIEW}/edit`

export const TRANSACTIONS_VIEW = `(${TRANSACTION_BOOK})/termsheets/:id`
export const TRANSACTIONS_EDIT = `${TRANSACTIONS_VIEW}/edit`

/** Admin routes */
export const ADMIN_GENERATE_TOKEN = '/admin/generateToken'
export const ADMIN_VIEW_COMPANIES = '/admin/companies'
export const ADMIN_VIEW_COMPANY = '/admin/companies/:id'
export const ADMIN_VIEW_COMPANY_REQUEST = '/admin/companies/request/:id'
export const ADMIN_CREATE_ENTITY = '/admin/companies/:companyId/entities/new'
export const ADMIN_VIEW_USERS = '/admin/users'
export const ADMIN_CREATE_USER = '/admin/companies/:companyId/users/new'
export const ADMIN_VIEW_ENTITY = '/admin/entities/:id'
export const ADMIN_EDIT_ENTITY = '/admin/entities/:id/edit'
export const ADMIN_ADD_COMPANY = '/admin/companies/new'
export const ADMIN_EDIT_COMPANY = '/admin/companies/:id/edit'
export const ADMIN_VIEW_USER = '/admin/users/:id'
export const ADMIN_EDIT_USER = '/admin/users/:id/edit'
export const ADMIN_VIEW_BROKERS = '/admin/brokers'
export const ADMIN_EDIT_BROKER_ENTITIES =
  '/admin/brokers/:id/trading-entities/edit'

/** Company admin */
export const ADMIN_CREATE_ACCOUNT = '/admin/new'
export const USER_CREATE_ACCOUNT = '/users/new'

/** Version */
export const VERSION = '/version'
