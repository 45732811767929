export const PERIOD = Object.freeze({
  CUSTOM: 'custom',
  GAS_YEAR: 'gas_year',
  CAL_YEAR: 'year',
  SUMMER: 'summer',
  WINTER: 'winter',
  Q1: 'q1',
  Q2: 'q2',
  Q3: 'q3',
  Q4: 'q4',
  JAN: 'january',
  FEB: 'february',
  MAR: 'march',
  APR: 'april',
  MAY: 'may',
  JUN: 'june',
  JUL: 'july',
  AUG: 'august',
  SEP: 'september',
  OCT: 'october',
  NOV: 'november',
  DEC: 'december',
})

export const PERIOD_LABELS = Object.freeze({
  [PERIOD.CUSTOM]: 'Custom',
  [PERIOD.GAS_YEAR]: 'GY-xx',
  [PERIOD.CAL_YEAR]: 'CAL-xx',
  [PERIOD.SUMMER]: 'Sum',
  [PERIOD.WINTER]: 'Win',
  [PERIOD.Q1]: 'Q1',
  [PERIOD.Q2]: 'Q2',
  [PERIOD.Q3]: 'Q3',
  [PERIOD.Q4]: 'Q4',
  [PERIOD.JAN]: 'January',
  [PERIOD.FEB]: 'February',
  [PERIOD.MAR]: 'March',
  [PERIOD.APR]: 'April',
  [PERIOD.MAY]: 'May',
  [PERIOD.JUN]: 'June',
  [PERIOD.JUL]: 'July',
  [PERIOD.AUG]: 'August',
  [PERIOD.SEP]: 'September',
  [PERIOD.OCT]: 'October',
  [PERIOD.NOV]: 'November',
  [PERIOD.DEC]: 'December',
})

export const WINDOW = Object.freeze({
  CUSTOM: 'custom',
  W1: 'w1',
  W2: 'w2',
  W3: 'w3',
  W4: 'w4',
  FIRST_HALF: 'first half',
  SECOND_HALF: 'second half',
  EARLY: 'early',
  MID: 'mid',
  LATE: 'late',
})

export const WINDOW_LABELS = Object.freeze({
  [WINDOW.CUSTOM]: 'Specific Dates',
  [WINDOW.W1]: 'W1',
  [WINDOW.W2]: 'W2',
  [WINDOW.W3]: 'W3',
  [WINDOW.W4]: 'W4',
  [WINDOW.FIRST_HALF]: 'First Half',
  [WINDOW.SECOND_HALF]: 'Second Half',
  [WINDOW.EARLY]: 'Early',
  [WINDOW.MID]: 'Mid',
  [WINDOW.LATE]: 'Late',
})
