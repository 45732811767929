import { withProps } from 'recompose'
import DoneIcon from '@material-ui/icons/Done'
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft'
import IconButton from 'components/iconButton/IconButton'

export const CancelButton = withProps({
  icon: ArrowBackIcon,
  color: 'default',
  variant: 'contained',
})(IconButton)

export const ConfirmButton = withProps({
  icon: DoneIcon,
  color: 'primary',
  variant: 'contained',
})(IconButton)
