import React from 'react'
import PropTypes from 'prop-types'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const DefaultLeftButton = (props) => (
  <Button size="small" {...props}>
    <KeyboardArrowLeft /> Back
  </Button>
)

const DefaultRightButton = (props) => (
  <Button size="small" {...props}>
    Next <KeyboardArrowRight />
  </Button>
)

export const ProgressStepper = ({
  classes,
  variant,
  steps,
  activeStep,
  LeftButton,
  RightButton,
}) => (
  <MobileStepper
    data-testid="mobile-stepper"
    classes={classes}
    variant={variant}
    steps={steps}
    position="static"
    activeStep={activeStep}
    backButton={<LeftButton disabled={activeStep === 0} />}
    nextButton={
      <RightButton
        data-testid="order-creation-next-button"
        disabled={activeStep === steps - 1}
      />
    }
  />
)

ProgressStepper.defaultProps = {
  variant: 'text',
  steps: 0,
  activeStep: 0,
  LeftButton: DefaultLeftButton,
  RightButton: DefaultRightButton,
}

ProgressStepper.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.string,
  steps: PropTypes.number,
  activeStep: PropTypes.number,
  LeftButton: PropTypes.func,
  RightButton: PropTypes.func,
}
