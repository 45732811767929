import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { isBoolean, isString } from 'lodash'
import { useTheme, makeStyles } from '@material-ui/styles'

/**
 @NOTE: This component only supports 2 list types. Lower case alpha and numberic
 */
const OrderedList = ({ children, alpha, style }) => {
  const { typography, palette, spacing } = useTheme()
  const rootStyle = {
    ...typography.h6,
    counterReset: 'item',
    paddingLeft: spacing(1) * (alpha ? 5 : 3),
    color: palette.text.primary,
    listStyleType: alpha ? 'lower-alpha' : 'initial',
    ...style,
  }

  return <ol style={rootStyle}>{children}</ol>
}

OrderedList.defaultProps = {
  alpha: false,
}

OrderedList.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  alpha: PropTypes.bool,
}

const List = ({ children, style, gutterBottom }) => {
  const { palette, spacing } = useTheme()
  const rootStyle = {
    paddingLeft: spacing(3),
    color: palette.text.primary,
    marginBottom: isBoolean(gutterBottom)
      ? spacing(1) * Boolean(gutterBottom)
      : spacing(gutterBottom),
    ...style,
  }

  return <ul style={rootStyle}>{children}</ul>
}

List.defaultProps = {
  gutterBottom: 0,
}

List.propTypes = {
  children: PropTypes.node.isRequired,
  gutterBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  style: PropTypes.object,
}

const useStyles = makeStyles(({ typography }) => ({
  root: {
    display: 'block',
    '& > :first-child': {
      display: 'inline-block',
    },
    '& > :first-child::before': {
      ...typography.h6,
      content: 'counters(item, ".") ". "',
      counterIncrement: 'item',
    },
  },
}))

const ListItem = ({ style, gutterBottom, ordered, children }) => {
  const classes = useStyles()
  const { spacing } = useTheme()
  const rootStyle = {
    ...style,
    marginBottom: isBoolean(gutterBottom) ? spacing(1) : spacing(gutterBottom),
  }

  return (
    <li style={rootStyle} className={ordered ? classes.root : ''}>
      {isString(children) ? <Text>{children}</Text> : children}
    </li>
  )
}

ListItem.defaultProps = {
  gutterBottom: 0,
  ordered: true,
}

ListItem.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  gutterBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  ordered: PropTypes.bool,
}

export { OrderedList, List, ListItem }
