import React from 'react'
import {
  $OC,
  ADDITIONAL_ATTRIBUTES_TYPE_OPTIONS,
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS,
  REGION_TYPE_KEYS,
  TRADING_TYPE,
  getCountryRegionFromKey
} from 'emsurge-selectors'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import {
  AccordionDetails,
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import Accordion from '../../orderView/components/Accordion'
import DetailRow from '../../orderView/components/DetailRow'
import {
  STEP_CARBON_ESSENTIAL,
  STEP_CARBON_OPTIONAL,
  TAB_CARBON,
  useActiveStep,
} from 'containers/OrderProvider'
import DetailDivider from 'screens/orderView/components/DetailDivider'

const allIsSelected = (selectedScopes) => {
  const { all, ALL } = selectedScopes
  return all || ALL
}

const getSectoralScopeText = (sectoralScopes, selectedScopes) => {
  if (sectoralScopes.length) {
    return sectoralScopes
      .map((proj) => PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS[proj])
      .join(', ')
  }
  return allIsSelected(selectedScopes) ? 'All' : ''
}

const _OptionalPanel = ({ order, isTermSheetSummary }) => {
  const { navigate } = useActiveStep()

  const navigateTo = (tab, step) => navigate.goTo({ tab, step })
  const navigateToRegion = () =>
    navigateTo(TAB_CARBON.OPTIONAL, STEP_CARBON_OPTIONAL.REGION)
  const navigateToProject = () =>
    navigateTo(TAB_CARBON.OPTIONAL, STEP_CARBON_OPTIONAL.PROJECT_SPECIFICATION)
  const navigateToAdditionalAttributes = () =>
    navigateTo(TAB_CARBON.OPTIONAL, STEP_CARBON_OPTIONAL.ADDITIONAL_ATTRIBUTES)
  const navigateToVenue = () =>
    navigateTo(TAB_CARBON.OPTIONAL, STEP_CARBON_OPTIONAL.VENUE)
  const navigateToOtherTerms = () =>
    navigateTo(TAB_CARBON.OPTIONAL, STEP_CARBON_OPTIONAL.OTHER_TERMS)
  const navigateToType = () =>
    navigateTo(TAB_CARBON.ESSENTIAL, STEP_CARBON_ESSENTIAL.TYPE)

  const tradingType = $OC.tradingType.get(order)
  const region = $OC.region.text(order)
  const projectSpecification = $OC.projectSpecification.text(order)
  const additionalAttributes = $OC.additionalAttributes.text(order)
  const venue = $OC.venue.text(order)
  const otherTerms = $OC.otherTerms.text(order)
  const carbonType = $OC.carbonType.text(order)
  const getRegions = (countries) => {
    const regions = countries.map(x => getCountryRegionFromKey(x))
    const result = [...new Set(regions)].join(', ')
    return result
  }

  return (
    <Accordion defaultExpanded>
      <AccordionDetails
        style={{ flexDirection: 'column' }}
        id="orderTerms"
      >
        <DetailRow
          title="Region"
          data-testid="order-overview-optional-region"
          info={
            region.type === REGION_TYPE_KEYS.SPECIFIC
              ? region.countries.length > 0
                ? getRegions(order.regions.countries)
                : 'No regions selected'
              : 'All regions'
          }
          onClick={() => navigateToRegion()}
        />
        <DetailRow
          title="Country List"
          data-testid="order-overview-optional-country-list"
          info={
            region.type === REGION_TYPE_KEYS.SPECIFIC
              ? region.countries.length > 0
                ? region.countries
                : 'No countries selected'
              : 'All countries'
          }
          onClick={() => navigateToRegion()}
        />

        <DetailDivider />

        {(tradingType === TRADING_TYPE.ASK || isTermSheetSummary) &&
          projectSpecification.projects.map((project, idx) => {
            const projectInfo = isEmpty(project) ? 'ND' : ''
            return (
              <div key={`project-${idx}`}>
                <DetailRow
                  key={`project-info-${idx}`}
                  title={`Project #${idx + 1}`}
                  info={projectInfo}
                  onClick={() => navigateToProject()}
                />
                {project.referenceNr && (
                  <DetailRow
                    data-testid={`order-overview-optional-project-reference-${idx}`}
                    key={`project-ref-nr-${idx}`}
                    title="Reference Number"
                    info={project.referenceNr}
                    onClick={() => navigateToProject()}
                  />
                )}
                {project.name && (
                  <DetailRow
                    data-testid={`order-overview-optional-project-name-${idx}`}
                    key={`project-name-${idx}`}
                    title="Project Name"
                    info={project.name}
                    onClick={() => navigateToProject()}
                  />
                )}
                {project.sectoralScope && (
                  <DetailRow
                    data-testid={`order-overview-optional-project-scope-${idx}`}
                    key={`project-sectoral-scope-${idx}`}
                    title="Sectoral Scope"
                    info={project.sectoralScope}
                    onClick={() => navigateToProject()}
                  />
                )}
                {project.storageType && (
                  <DetailRow
                    data-testid={`order-overview-optional-storage-type-${idx}`}
                    key={`project-storage-type-${idx}`}
                    title="Storage Type"
                    info={project.storageType}
                    onClick={() => navigateToProject()}
                  />
                )}
                {project.url && (
                  <DetailRow
                    data-testid={`order-overview-optional-project-url-${idx}`}
                    key={`project-url-${idx}`}
                    title="Project URL"
                    info={project.url}
                    showLink={true}
                    onClick={() => navigateToProject()}
                  />
                )}
                {project.startDate && (
                  <DetailRow
                    data-testid={`order-overview-optional-project-date-${idx}`}
                    key={`project-period-start-date-${idx}`}
                    title="Period Start Date"
                    info={project.startDate}
                    onClick={() => navigateToProject()}
                  />
                )}
                <DetailDivider />
              </div>
            )
          })}
        {tradingType === TRADING_TYPE.BID && (
          <>
            {!!projectSpecification.keywords.length && (
              <DetailRow
                data-testid="project-specification-keywords"
                key={`project-specification-keywords`}
                title="Keywords"
                info={projectSpecification.keywords.join(', ')}
                onClick={() => navigateToProject()}
              />
            )}
            <DetailRow
              data-testid="project-specification-sectoral-scopes"
              key={`project-specification-sectoral-scopes`}
              title="Sectoral Scopes"
              info={getSectoralScopeText(
                projectSpecification.sectoralScopes,
                projectSpecification.selectedScopes
              )}
              onClick={() => navigateToProject()}
            />
            <DetailDivider />
          </>
        )}

        <DetailRow
          data-testid="order-overview-optional-additional-attributes"
          title="Additional Attributes"
          info={additionalAttributes.additionalAttributes}
          onClick={() => navigateToAdditionalAttributes()}
        />
        {!!additionalAttributes.sdgs && (
          <DetailRow
            data-testid="order-overview-optional-sdgs"
            title="SDGs"
            info={additionalAttributes.sdgs}
            onClick={() => navigateToAdditionalAttributes()}
          />
        )}
        {additionalAttributes.sdgs.length > 0 && (
          <DetailRow
            data-testid="order-overview-optional-sdgs-type"
            title="Type"
            info={ADDITIONAL_ATTRIBUTES_TYPE_OPTIONS[additionalAttributes.type]}
            onClick={() => navigateToAdditionalAttributes()}
          />
        )}
        <DetailRow
          title="Rating"
          data-testid="order-overview-essential-rating"
          info={carbonType.rating}
          onClick={() => navigateToType()}
        />

        <DetailDivider />
        <DetailRow
          title="Venue"
          data-testid="order-overview-optional-venue"
          info={venue.venue}
          onClick={() => navigateToVenue()}
        />

        <DetailDivider />
        {otherTerms?.otherTerms?.length > 0 && otherTerms.otherTerms.map(({ item, description }, otherIndex) => (
          <DetailRow
            data-testid={`order-overview-optional-other-terms-description-${otherIndex}`}
            key={`other-terms-${otherIndex}`}
            title={item}
            info={description}
            onClick={() => navigateToOtherTerms()}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

_OptionalPanel.propTypes = {
  order: PropTypes.object.isRequired,
}

export const OptionalPanel = connect(_OptionalPanel)
