import PropTypes from 'prop-types'
import { connect } from 'formik'
import { QUALITY_TYPE, QUALITY_TYPE_OPTIONS } from 'emsurge-selectors/constants'

export const _QualityType = ({ formik, children }) => {
  const onChange = (value) => {
    const newQualityType = value

    switch (newQualityType) {
      case QUALITY_TYPE.OVER_1040:
      case QUALITY_TYPE.OVER_1050:
      case QUALITY_TYPE.RICH:
        formik.setFieldValue('quality.cbmPermitted', false)
        break
      default:
        formik.setFieldValue('quality.cbmPermitted', true)
        return
    }
  }

  return children({ options: QUALITY_TYPE_OPTIONS, onChange })
}

_QualityType.defaultProps = {
  children: () => {},
}

_QualityType.propTypes = {
  formik: PropTypes.object.isRequired,
  children: PropTypes.func,
}

export const QualityType = connect(_QualityType)
