const {
  REGIONS,
  REGION_COUNTRY_OPTIONS,
} = require('../../constants/carbon/countries')

const getRegion = (order) => order.regions || {}

const getRegionText = (order) => {
  const { countries, options, ldcsOnly, type } = getRegion(order)
  return {
    regions: options.map((region) => REGIONS[region]).join(', '),
    countries: countries
      .map((country) => REGION_COUNTRY_OPTIONS[country])
      .join(', '),
    ldcsOnly,
    type,
  }
}

module.exports = {
  get: getRegion,
  text: getRegionText,
}
