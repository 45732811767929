import isFunction from 'lodash/isFunction'

const DEFAULT_SUCCESS_MESSAGE = 'Copied to clipboard.'
const DEFAULT_ERROR_MESSAGE =
  'Cannot copy to clipboard. Please check your browser permissions.'

const isClipboardAvailable = () =>
  window.navigator.clipboard && isFunction(window.navigator.clipboard.writeText)

export const writeToClipboard = async (
  textToCopy,
  successMessage = DEFAULT_SUCCESS_MESSAGE,
  errorMessage = DEFAULT_ERROR_MESSAGE
) => {
  try {
    if (!isClipboardAvailable()) {
      throw new Error(DEFAULT_ERROR_MESSAGE)
    }

    await window.navigator.clipboard.writeText(textToCopy)

    return { message: successMessage, error: false }
  } catch (err) {
    return { message: errorMessage, error: true }
  }
}
