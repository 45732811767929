const env = process.env.NODE_ENV
const { PRICE_INDEX } = require('./price')

const CURVES = Object.freeze({
  EVOLUTION: 'EVOGAS',
  EMS: 'EMS',
})

const CURVE_ENV = {
  development: CURVES.EVOLUTION,
  staging: CURVES.EVOLUTION,
  beta: CURVES.EVOLUTION,
  test: CURVES.EVOLUTION,
  demo: CURVES.EMS,
  production: CURVES.EMS,
}

const CURVE = CURVE_ENV[env] || CURVES.EVOLUTION

const VENUE_DEMO = Object.freeze({
  EVO: 'EVO',
})
const VENUE_LIVE = Object.freeze({
  EVO: 'EVOE',
})

const VENUE_ENV = Object.freeze({
  development: VENUE_DEMO,
  staging: VENUE_DEMO,
  beta: VENUE_DEMO,
  test: VENUE_DEMO,
  demo: VENUE_LIVE,
  production: VENUE_LIVE,
})

const VENUE = VENUE_ENV[env] || VENUE_DEMO

const COLUMNS = Object.freeze({
  VALUE: 'value',
  VALUE_BID: 'bid_value',
  VALUE_ASK: 'ask_value',
  LAST: 'last',
  CHANGE: 'change',
  OPEN: 'open',
  LOW: 'low',
  HIGH: 'high',
  SETTLE: 'settle',
  CLOSE: 'close',
  VOLUME: 'volume',
})

const PRODUCT_DEMO = Object.freeze({
  FX_CAD_USD: 'FXCADUSD',
  FX_EUR_GBP: 'FXEURGBP',
  FX_EUR_USD: 'FXEURUSD',
  FX_GBP_EUR: 'FXGBPEUR',
  FX_GBP_USD: 'FXGBPUSD',
  FX_USD_EUR: 'FXUSDEUR',
  FX_USD_MYR: 'FXUSDMYR',
  JKM_NBP: 'JKM/NBP',
  JKM_TTF: 'JKM/TTF',
  JKM: 'JKM',
  NBP_GBP: 'NBPGBP',
  NBP_TTF: 'NBP/TTF',
  NBP_USD: 'NBPUSD',
  NG: 'NG',
  THD: 'THD',
  TTF_EUR: 'TTFEUR',
  TTF_NBP: 'TTF/NBP',
  TTF_USD: 'TTFUSD',
  HH: 'NG',
})

const PRODUCT_LIVE = Object.freeze({
  ATW_TTF_USD: 'ATW/TTFUSD',
  JKM_DATA: 'JKMDATA',
  JKM_NBP_USD_DATA: 'JKM/NBPUSDDATA',
  JKM_NBP_USD: 'JKM/NBPUSD',
  JKM_TTF_USD_DATA: 'JKM/TTFUSDDATA',
  JKM_TTF_USD: 'JKM/TTFUSD',
  JKM: 'JKM',
  NBP_GBP_DATA: 'NBPGBPDATA',
  NBP_GBP: 'NBPGBP',
  NBP_TTF_GBP_DATA: 'NBP/TTFGBPDATA',
  NBP_TTF_GBP: 'NBP/TTFGBP',
  NBP_TTF_USD_DATA: 'NBP/TTFUSDDATA',
  NBP_USD_DATA: 'NBPUSDDATA',
  NBP_USD: 'NBPUSD',
  NG: 'NG',
  THD: 'THD',
  TTF_EUR_DATA: 'TTFEURDATA',
  TTF_EUR: 'TTFEUR',
  TTF_NBP_EUR_DATA: 'TTF/NBPEURDATA',
  TTF_NBP_EUR: 'TTF/NBPEUR',
  TTF_USD_DATA: 'TTFUSDDATA',
  TTF_USD: 'TTFUSD',
  HH: 'NGDATA',
})

const PRICE_INDEX_TO_PRODUCT_DEMO = Object.freeze({
  [PRICE_INDEX.NBP]: PRODUCT_DEMO.NBP_USD,
  [PRICE_INDEX.JKM]: PRODUCT_DEMO.JKM,
  [PRICE_INDEX.TTF]: PRODUCT_DEMO.TTF_USD,
  [PRICE_INDEX.JKM_TTF]: PRODUCT_DEMO.JKM_TTF,
  [PRICE_INDEX.JKM_NBP]: PRODUCT_DEMO.JKM_NBP,
  [PRICE_INDEX.NBP_TTF]: PRODUCT_DEMO.NBP_TTF,
  [PRICE_INDEX.HH]: PRODUCT_DEMO.HH,
})

const PRICE_INDEX_TO_PRODUCT_LIVE = Object.freeze({
  [PRICE_INDEX.NBP]: PRODUCT_LIVE.NBP_USD_DATA,
  [PRICE_INDEX.JKM]: PRODUCT_LIVE.JKM_DATA,
  [PRICE_INDEX.TTF]: PRODUCT_LIVE.TTF_USD_DATA,
  [PRICE_INDEX.JKM_TTF]: PRODUCT_LIVE.JKM_TTF_USD_DATA,
  [PRICE_INDEX.JKM_NBP]: PRODUCT_LIVE.JKM_NBP_USD_DATA,
  [PRICE_INDEX.NBP_TTF]: PRODUCT_LIVE.NBP_TTF_USD_DATA,
  [PRICE_INDEX.HH]: PRODUCT_LIVE.HH,
})

const PRICE_INDEX_TO_PRODUCT_ENV = Object.freeze({
  development: PRICE_INDEX_TO_PRODUCT_DEMO,
  staging: PRICE_INDEX_TO_PRODUCT_DEMO,
  beta: PRICE_INDEX_TO_PRODUCT_DEMO,
  test: PRICE_INDEX_TO_PRODUCT_DEMO,
  demo: PRICE_INDEX_TO_PRODUCT_LIVE,
  production: PRICE_INDEX_TO_PRODUCT_LIVE,
})

const PRICE_INDEX_TO_PRODUCT =
  PRICE_INDEX_TO_PRODUCT_ENV[env] || PRICE_INDEX_TO_PRODUCT_DEMO

const PRODUCT_TO_PRICE_INDEX_DEMO = {
  [PRODUCT_DEMO.NBP_USD]: PRICE_INDEX.NBP,
  [PRODUCT_DEMO.JKM]: PRICE_INDEX.JKM,
  [PRODUCT_DEMO.TTF_USD]: PRICE_INDEX.TTF,
  [PRODUCT_DEMO.JKM_TTF]: PRICE_INDEX.JKM_TTF,
  [PRODUCT_DEMO.JKM_NBP]: PRICE_INDEX.JKM_NBP,
  [PRODUCT_DEMO.NBP_TTF]: PRICE_INDEX.NBP_TTF,
  [PRODUCT_DEMO.HH]: PRICE_INDEX.HH,
}

const PRODUCT_TO_PRICE_INDEX_LIVE = {
  [PRODUCT_LIVE.NBP_USD_DATA]: PRICE_INDEX.NBP,
  [PRODUCT_LIVE.JKM_DATA]: PRICE_INDEX.JKM,
  [PRODUCT_LIVE.TTF_USD_DATA]: PRICE_INDEX.TTF,
  [PRODUCT_LIVE.JKM_TTF_USD_DATA]: PRICE_INDEX.JKM_TTF,
  [PRODUCT_LIVE.JKM_NBP_USD_DATA]: PRICE_INDEX.JKM_NBP,
  [PRODUCT_LIVE.NBP_TTF_USD_DATA]: PRICE_INDEX.NBP_TTF,
  [PRODUCT_LIVE.HH]: PRICE_INDEX.HH,
}

const PRODUCT_TO_PRICE_INDEX_ENV = {
  development: PRODUCT_TO_PRICE_INDEX_DEMO,
  staging: PRODUCT_TO_PRICE_INDEX_DEMO,
  beta: PRODUCT_TO_PRICE_INDEX_DEMO,
  test: PRODUCT_TO_PRICE_INDEX_DEMO,
  demo: PRODUCT_TO_PRICE_INDEX_LIVE,
  production: PRODUCT_TO_PRICE_INDEX_LIVE,
}

const PRODUCT_TO_PRICE_INDEX =
  PRODUCT_TO_PRICE_INDEX_ENV[env] || PRODUCT_TO_PRICE_INDEX_DEMO

const SUBSCRIPTION_DEFAULT = Object.freeze({
  curve: CURVE,
  depth: 1,
})

module.exports = {
  COLUMNS,
  PRICE_INDEX_TO_PRODUCT,
  PRODUCT_TO_PRICE_INDEX,
  SUBSCRIPTION_DEFAULT,
  VENUE,
}
