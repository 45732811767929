import { dark as theme } from 'theme'

export const defaultCss = `
    /* outer border */
    .vis-timeline {
        border: 0;
    }

    /* left column and days separating border */
    .vis-panel.vis-center, 
    .vis-panel.vis-top, 
    .vis-panel.vis-bottom,
    .vis-panel.vis-left {
        border: 0;
    }

    /* row */
    .vis-foreground .vis-group {
        border: 0;
    }

    /* left handside rows  */
    .vis-labelset .vis-label {
        border: 0;
        display: flex;
        align-items: center;
    }

    .vis-label.vis-nested-group.vis-group-level-1 {
        color: white;
        font-weight: ${theme.typography.button.fontWeight};
        font-size: ${theme.typography.subtitle1.fontSize};
    }

    .vis-label.vis-nested-group.vis-group-level-2 {
        color: white;
        font-weight: ${theme.typography.subtitle1.fontWeight};
        font-size: ${theme.typography.subtitle1.fontSize};
    }

    /* days column */
    .vis-time-axis .vis-grid.vis-vertical.vis-minor {
        border: 0;
    }

    /* start of month column */
    .vis-time-axis .vis-grid.vis-vertical.vis-major {
        border-left: 1px dashed #ffffff36;
    }

    /* day cell */
    .vis-time-axis .vis-minor {
        text-align: center;
        color: white;
        font-size: 12px;
        font-weight: ${theme.typography.h3.fontWeight};
    }

    /* month cell */
    .vis-time-axis .vis-major {
        text-align: center;
        color: white;
        font-size: 12px;
        font-weight: ${theme.typography.h3.fontWeight};
    }

    .vis-label.vis-nested-group.vis-group-level-2 {
        background-color: rgba(0, 0, 0, 0);
    }
`
