import React from 'react'
import { RATING_PREFIX, RATING_OPTIONS } from 'emsurge-selectors'
import { CheckboxGroup } from './CheckboxGroup'

export const Rating = () => {
  return (
    <CheckboxGroup
      key="radio-button-group-rating"
      label="Rating"
      fieldPrefix={RATING_PREFIX}
      options={RATING_OPTIONS}
      style={{ marginTop: 0 }}
    />
  )
}
