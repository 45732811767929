import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import {
  CARGO_SIZE_UNIT_TITLE,
  CARGO_SIZE_TYPE_OPTIONS,
} from 'emsurge-selectors/constants'
import { SelectCargoSizeType } from 'screens/orderCreate/components/SelectCargoSizeType'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'

export const Type = () => (
  <SelectCargoSizeType>
    {({ unit, onChange }) => (
      <FormControl>
        <FormLabel>{CARGO_SIZE_UNIT_TITLE[unit]}</FormLabel>
        <RadioInputs
          onChange={(evt) => onChange(evt.target.value)}
          name="cargoSize.type"
          options={CARGO_SIZE_TYPE_OPTIONS[unit]}
        />
      </FormControl>
    )}
  </SelectCargoSizeType>
)
