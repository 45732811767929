const { NOMINATION_TYPE_TEXT, NOMINATION_TYPE } = require('../../../constants')
const port = require('./port')

const getPortBase = (terms) => {
  const { loadPort = {} } = terms
  const { base, adgas, timezone } = loadPort
  const loadPortBase = port.get(base)

  return {
    ...loadPortBase,
    adgas,
    timezone,
  }
}

const getPortBaseText = (terms) => {
  const { loadPort = {} } = terms
  const portBaseText = port.text(
    loadPort.base,
    NOMINATION_TYPE_TEXT[NOMINATION_TYPE.LOAD_PORT]
  )
  const { adgas, timezone } = getPortBase(terms)
  const adgasText = adgas
    ? "Nomination of ADGAS as a Supply Source shall be subject to Buyer's consent, not to be unreasonably withheld."
    : ''

  return {
    ...portBaseText,
    adgas: adgasText,
    timezone,
  }
}

const getPortBaseSummary = (terms) => {
  const { loadPort = {} } = terms
  const portBaseSummary = port.summary(loadPort.base)
  const { adgas, timezone } = getPortBaseText(terms)

  return {
    ...portBaseSummary,
    adgas,
    timezone,
  }
}

const getPortAlternatives = (terms) => {
  const { loadPort = {} } = terms
  const { alternatives = [] } = loadPort
  const basePortAlternatives = alternatives.map(port.get)

  return basePortAlternatives
}

const getPortAlternativesText = (terms) => {
  const { loadPort = {} } = terms
  const { alternatives = [] } = loadPort
  const portAlternativesText = alternatives.map((alternative) =>
    port.text(alternative, NOMINATION_TYPE_TEXT[NOMINATION_TYPE.LOAD_PORT])
  )

  return portAlternativesText
}

const getPort = (terms) => {
  const base = getPortBase(terms)
  return {
    ...base,
    alternatives: getPortAlternatives(terms),
  }
}

const getPortSummary = (terms) => {
  const baseSummary = getPortBaseSummary(terms)

  return {
    ...baseSummary,
    alternatives: getPortAlternativesText(terms),
  }
}

const getPortText = (terms) => {
  const baseText = getPortBaseText(terms)

  return {
    ...baseText,
    alternatives: getPortAlternativesText(terms),
  }
}
module.exports = {
  get: getPort,
  summary: getPortSummary,
  text: getPortText,
  base: {
    get: getPortBase,
    text: getPortBaseText,
    summary: getPortBaseSummary,
  },
  alternatives: {
    get: getPortAlternatives,
    text: getPortAlternativesText,
  },
}
