import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

export const useRouteParamsUpdater = (params) => {
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    const newParams = {
      ...queryString.parse(location.search),
      ...params,
    }
    history.replace({
      search: queryString.stringify(newParams),
    })
  }, [history, location.search, params])
}
