import { useQuery } from 'react-query'
import { useUser } from 'containers/user/useUser'
import { useApi } from 'containers/api/useApi'
import { PERSON_SETTINGS } from 'api/constants'

const FIVE_MINUTES = 5 * 60 * 1000

export const usePersonSettingsQuery = () => {
  const { user } = useUser()
  const api = useApi()

  return useQuery([PERSON_SETTINGS], () => api.getPersonSettings({ id: user.id }), {
    enabled: true,
    staleTime: FIVE_MINUTES,
  })
}
