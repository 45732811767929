import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { connect } from 'formik'
import { FormHelperText, Grid, TextField } from '@material-ui/core'
import {
  PROJECT_KEYWORD_INPUT,
  PROJECT_KEYWORD_OPTIONS,
  PROJECT_SECTORAL_SCOPE_CHECKBOX,
  PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE,
  PROJECT_SECTORAL_SCOPE_INPUT,
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS,
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS,
  PROJECT_SECTORAL_SCOPE_OPTIONS,
} from 'emsurge-selectors'
import { SelectedOptions } from './SelectedOptions'
import { CheckboxGroup } from './CheckboxGroup'
import { DropdownSelectOptions } from './DropdownSelectOptions'
import { TitleModalSection } from 'screens/orderCreateCommon/components/TitleModalSection'

const buildOption = (opt) => ({ key: opt, value: opt })

const _ProjectBuyer = ({ formik: { values, setFieldValue, errors } }) => {
  const previousOptions = get(values, PROJECT_KEYWORD_OPTIONS) || []
  const [selectedOptions, setSelectedOptions] = useState(previousOptions)
  const [keyword, setKeyword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [refreshSectoralScopeList, setRefreshSectoralScopeList] =
    useState(false)

  const isSelected = (value, opts) => {
    return opts.filter((opt) => opt.value === value).length
  }

  useEffect(() => {
    const message = get(errors, PROJECT_SECTORAL_SCOPE_INPUT)
    const messageText = message && typeof message === 'string' ? message : ''
    setErrorMessage(messageText)
  }, [errors])

  const handleKeywordValue = (keyword, opts = selectedOptions) => {
    keyword.split(',').forEach((opt) => {
      const optValue = opt.trim()
      if (optValue && !isSelected(optValue, opts)) {
        opts.push(buildOption(optValue))
      }
    })
    setSelectedOptions([...opts])
    setKeyword('')
    setFieldValue(PROJECT_KEYWORD_OPTIONS, opts)
  }

  const onBlur = ({ target: { value }, opts }) => {
    handleKeywordValue(value || '', opts)
  }

  const onKeyUp = (evt) => {
    if (evt.keyCode === 13 && !evt.shiftKey) {
      evt.preventDefault()
      handleKeywordValue(keyword)
    }
  }

  const onDeleteSelectedOption = (option) => {
    const newSelectedOptions = selectedOptions.filter(
      ({ key }) => key !== option
    )
    setSelectedOptions(newSelectedOptions)
    setFieldValue(PROJECT_KEYWORD_OPTIONS, newSelectedOptions)
  }

  const onChangeSectoralScope = (path) => {
    const newSelectedSectoralScope = !get(values, path)
    setFieldValue(path, newSelectedSectoralScope)
    if (newSelectedSectoralScope) {
      setFieldValue(PROJECT_SECTORAL_SCOPE_OPTIONS, [])
    }
    setRefreshSectoralScopeList(!refreshSectoralScopeList)
  }

  const onSelectSectoralScopeOption = (option) => {
    if (!option) {
      return
    }
    setFieldValue(`${PROJECT_SECTORAL_SCOPE_CHECKBOX}.ALL`, false)
  }

  const onDeleteSectoralScopeOption = (option) => {
    if (!option) {
      return
    }
    const sectoralScopeOptions = get(values, PROJECT_SECTORAL_SCOPE_OPTIONS)
    const nOptions = (sectoralScopeOptions || []).filter(
      ({ key }) => key !== option
    )
    if (!nOptions.length) {
      setFieldValue(`${PROJECT_SECTORAL_SCOPE_CHECKBOX}.ALL`, true)
    }
  }

  return (
    <Grid container spacing={3} style={{ paddingTop: '8px' }}>
      <Grid item xs={12}>
        <TitleModalSection>Project Identification Keywords</TitleModalSection>
        <TextField
          inputProps={{ 'data-testid': 'project-keywords-field' }}
          fullWidth
          margin="normal"
          type="text"
          name={PROJECT_KEYWORD_INPUT}
          label={'Enter keywords'}
          helperText={'Separated by a comma'}
          onBlur={onBlur}
          onChange={({ target: { value } }) => setKeyword(value)}
          value={keyword}
          onKeyUp={onKeyUp}
        />
      </Grid>
      {selectedOptions.length ? (
        <Grid item xs={12}>
          <SelectedOptions
            options={selectedOptions}
            onDelete={onDeleteSelectedOption}
          />
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={12}>
        <TitleModalSection>Sectoral Scope</TitleModalSection>
        <CheckboxGroup
          key="radio-button-sectoral-scope-all"
          fieldPrefix={PROJECT_SECTORAL_SCOPE_CHECKBOX}
          options={PROJECT_SPECIFICATION_BUYER_SECTORAL_SCOPE}
          onChange={onChangeSectoralScope}
        />
      </Grid>
      <Grid item xs={12}>
        <DropdownSelectOptions
          optionsKeys={PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS}
          fullOptions={PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS}
          inputPath={PROJECT_SECTORAL_SCOPE_INPUT}
          optionsPath={PROJECT_SECTORAL_SCOPE_OPTIONS}
          inputTitle={'Select options from the list'}
          forceRefresh={refreshSectoralScopeList}
          onSelectOption={onSelectSectoralScopeOption}
          onDeleteOption={onDeleteSectoralScopeOption}
        >
          {errorMessage && (
            <FormHelperText component="div" error={true}>
              {errorMessage}
            </FormHelperText>
          )}
        </DropdownSelectOptions>
      </Grid>
    </Grid>
  )
}

export const ProjectBuyer = connect(_ProjectBuyer)
