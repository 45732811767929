import React from 'react'
import { isEmpty, get } from 'lodash'
import { Form, Formik } from 'formik'
import { withTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import { compose } from 'recompose'
import { handleSave } from '../containers/TradedNominations'
import Textfield from 'containers/formikTextField/FormikTextField'
import SumbitButton from 'containers/formikSubmitButton/FormikSubmitButton'
import { useApi } from 'containers/api/useApi'
import SelectTimeZone from 'screens/orderCreate/components/SelectTimeZone'

const enhance = compose(withSnackbar, withTheme)

const NameForm = enhance(
  ({
    nameLabel,
    theme,
    nomination,
    nominationRules,
    enqueueSnackbar,
    index,
  }) => {
    const api = useApi()
    const otherNominationDesc =
      nomination.description ||
      get(nominationRules, `other.alternatives[${index}].description`, null)

    const needsTimezone = !isEmpty(nomination.timezone)
    const INITIAL_VALUES = needsTimezone
      ? { name: nomination.name, timezone: nomination.timezone }
      : !otherNominationDesc
      ? { name: nomination.name }
      : { description: otherNominationDesc }

    /* :: (object, object) -> Promise<void> */
    const handleSubmit = (values, actions) => {
      handleSave(api, nomination.id, values, actions, enqueueSnackbar)
    }

    return (
      <Formik onSubmit={handleSubmit} initialValues={INITIAL_VALUES}>
        <Form>
          <Grid container spacing={2} alignItems="flex-end">
            {needsTimezone && (
              <Grid item xs={12}>
                <SelectTimeZone name="timezone" label="Timezone" />
              </Grid>
            )}
            <Grid item xs={10}>
              <Textfield
                name={!otherNominationDesc ? 'name' : 'description'}
                label={nameLabel}
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs>
              <SumbitButton
                fullWidth
                size="small"
                style={{ marginBottom: theme.spacing(1) }}
              >
                Save
              </SumbitButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    )
  }
)

export default NameForm
