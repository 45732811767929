import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import Tooltip from 'components/tooltip/Tooltip'
import { Currency } from 'screens/orderCreateCarbon/components/Currency'

export const SummaryBlock = ({
  value,
  tooltipProps: { title, ...tooltipPropsRest },
  textProps: { style = { root: {}, currency: {} }, ...textPropsRest },
  showCurrency,
  showVolume,
  volumePeriod = 'per_year',
  boldCurrency = true,
}) => {
  const { spacing } = useTheme()

  if (!value) {
    return null
  }

  const getSpacing = () => (showVolume ? 2 : 1)

  return (
    <Tooltip title={title} {...tooltipPropsRest}>
      <span>
        <Text
          display="inline"
          style={{ marginRight: spacing(getSpacing()), ...style.root }}
          {...textPropsRest}
        >
          {value}
        </Text>
        {showCurrency && (
          <Currency
            unit="currency"
            style={{
              marginLeft: spacing(-1),
              display: 'inline',
              ...style.currency,
            }}
            bold={boldCurrency}
          />
        )}
        {showVolume && (
          <Currency
            unit={volumePeriod}
            style={{
              marginLeft: spacing(-1),
              display: 'inline',
              ...style.currency,
            }}
            bold={boldCurrency}
          />
        )}
      </span>
    </Tooltip>
  )
}

SummaryBlock.defaultProps = {
  value: '',
  tooltipProps: {
    title: '',
  },
  textProps: {
    style: {},
  },
  showCurrency: false,
}

SummaryBlock.propTypes = {
  value: PropTypes.node,
  tooltipProps: PropTypes.object,
  textProps: PropTypes.object,
  showCurrency: PropTypes.bool,
}
