import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'formik'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Tabs, Tab } from '@material-ui/core'
import { get, isNil } from 'lodash'
import { Redirect } from 'react-router-dom'
import {
  REGIONS_FULL_KEYS,
  REGION_COUNTRIES_PREFIX,
  REGION_TYPE_KEYS,
  REGION_TYPE_PREFIX,
  TITLE_TRANSFER_ATTRIBUTES,
  TITLE_TRANSFER_PREFIX,
  TRADING_TYPE,
} from 'emsurge-selectors'
import { SideMenu } from './SideMenu'
import EssentialFormStepper from './EssentialFormStepper'
import OptionalFormStepper from './OptionalFormStepper'
import RecapFormStepper from './RecapFormStepper'
import { essentialHasErrors, optionalHasErrors } from './getErrors'
import {
  TAB_CARBON,
  TAB_CARBON_TEXT,
  useActiveStep,
} from 'containers/OrderProvider'
import Tooltip from 'components/tooltip/Tooltip'
import { Laptop } from 'components/Viewport'
import TabChevron from 'components/TabChevron/TabChevron'
import { VerticalDividerWrapper } from 'screens/orderCreateCommon/components/VerticalDivider'
import Fetch from 'containers/fetch/Fetch'
import { useUser } from 'containers/user/useUser'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { ERROR } from 'routes'
import PublicNotes from 'screens/orderCreate/components/PublicNotes'
import { useIsReactInDevelomentMode } from 'screens/orderCreateCarbon/helpers'

const TabsWrapper = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.background.secondary};
`

const PanelWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: calc(100% - 206px);
`

const useTermsTabStyles = makeStyles({
  root: {
    minWidth: '186px',
  },
})

const usePanelStyles = makeStyles(({ spacing }) => ({
  component: {
    overflow: 'auto',
    flex: 'auto',
    padding: spacing(4, 4, 15, 0),
  },
  sideMenu: {
    paddingTop: spacing(4),
  },
}))

const Panel = ({ component: Component, hasSideMenu, ...props }) => {
  const classes = usePanelStyles()
  return (
    <PanelWrapper>
      {hasSideMenu && (
        <Laptop matchLarger>
          <div className={classes.sideMenu}>
            <SideMenu {...props} />
          </div>
        </Laptop>
      )}
      <div className={classes.component}>
        <Component {...props} />
      </div>
    </PanelWrapper>
  )
}

Panel.propTypes = {
  component: PropTypes.func.isRequired,
  hasSideMenu: PropTypes.bool,
}

const TabLabel = ({ error, children }) => {
  const { palette } = useTheme()

  if (!error) {
    return children
  }

  return (
    <Tooltip title="Please correct all the errors">
      <span style={{ color: palette.error.main }}>{children}</span>
    </Tooltip>
  )
}

TabLabel.defaultProps = {
  error: false,
}

TabLabel.propTypes = {
  children: PropTypes.string.isRequired,
  error: PropTypes.bool,
}

const EmptyComponent = () => <></>

const MAP_DEFAULT = {
  [TRADING_TYPE.ASK]: TITLE_TRANSFER_ATTRIBUTES.sellerRetireTransfer.key,
  [TRADING_TYPE.BID]: TITLE_TRANSFER_ATTRIBUTES.sellerTransfers.key,
}

const Content = ({ formik, isEdit , isCreatingTrade, isEditTrade }) => {
  const { user } = useUser()
  const termsClasses = useTermsTabStyles()
  const {
    activeStep,
    navigate: { goTo },
  } = useActiveStep()

  const setRegistryByTradingType = () => {
    const tradingType = get(formik.values, 'tradingType')
    const mappedTitleTransfer = MAP_DEFAULT[tradingType]
    if (mappedTitleTransfer) {
      formik.setFieldValue(TITLE_TRANSFER_PREFIX, mappedTitleTransfer)
    }
  }

  const setRegionsByTradingType = () => {
    const tradingType = get(formik.values, 'tradingType')
    let type = REGION_TYPE_KEYS.ALL
    if (tradingType === TRADING_TYPE.ASK) {
      type = REGION_TYPE_KEYS.SPECIFIC
      formik.setFieldValue(REGION_COUNTRIES_PREFIX, [])
    }
    Object.values(REGIONS_FULL_KEYS).forEach((path) =>
      formik.setFieldValue(path, tradingType === TRADING_TYPE.BID)
    )
    formik.setFieldValue(REGION_TYPE_PREFIX, type)
  }

  useEffect(() => {
    if ( !isEdit && !isCreatingTrade && !isEditTrade) {
      setRegistryByTradingType()
      setRegionsByTradingType()
    }
  }, [formik.values.tradingType])

  return (
    <>
      <TabsWrapper>
        <Tabs
          indicatorColor="primary"
          value={activeStep.tab}
          onChange={(_, tab) => goTo({ tab, step: 0 })}
          {...(useIsReactInDevelomentMode ? {title: `Values are: ${JSON.stringify(formik.values)}`} : {})}
        >
          <Tab
            data-testid="order-creation-essential-tab-button"
            label={
              <TabLabel error={essentialHasErrors(formik)}>
                {TAB_CARBON_TEXT.ESSENTIAL}
              </TabLabel>
            }
            value={TAB_CARBON.ESSENTIAL}
          />
          <TabChevron />
          <Tab
            data-testid="order-creation-optional-tab-button"
            classes={termsClasses}
            label={
              <TabLabel error={optionalHasErrors(formik)}>
                {TAB_CARBON_TEXT.OPTIONAL}
              </TabLabel>
            }
            value={TAB_CARBON.OPTIONAL}
          />
          <TabChevron />
          <Tab
            data-testid="order-creation-recap-tab-button"
            classes={termsClasses}
            label={<TabLabel>{TAB_CARBON_TEXT.RECAP}</TabLabel>}
            value={TAB_CARBON.RECAP}
          />
          <VerticalDividerWrapper />
          <Tab
            data-testid="order-creation-comments-tab-button"
            label={<TabLabel>{TAB_CARBON_TEXT.COMMENTS}</TabLabel>}
            value={TAB_CARBON.COMMENTS}
          />
        </Tabs>
      </TabsWrapper>
      <Fetch url={`/companies/${user.companyId}`}>
        {({ loading, error, data: company }) => {
          if (loading && isNil(company)) {
            return <Panel component={LoadingSpinner} />
          }

          if (error) {
            return <Redirect to={ERROR} />
          }

          const components = {
            [TAB_CARBON.ESSENTIAL]: {
              component: EssentialFormStepper,
              hasSideMenu: true,
              company,
              isCreatingTrade: isCreatingTrade,
              isEditTrade: isEditTrade
            },
            [TAB_CARBON.OPTIONAL]: {
              component: OptionalFormStepper,
              hasSideMenu: true,
              company,
            },
            [TAB_CARBON.RECAP]: {
              component: RecapFormStepper,
              hasSideMenu: true,
              company,
              isCreatingTrade
            },
            [TAB_CARBON.COMMENTS]: {
              component: EmptyComponent,
              hasSideMenu: true,
            },
            default: {
              component: EmptyComponent,
              hasSideMenu: true,
            },
          }

          const componentProps =
            components[activeStep.tab] || components.default
          return <Panel {...componentProps} />
        }}
      </Fetch>
      <PublicNotes />
    </>
  )
}

Content.propTypes = {
  formik: PropTypes.object.isRequired,
}

export default connect(Content)
