const { REGISTRATION_STATUS } = require('./registration')

const COMPANY_STATUS = Object.freeze({
  ...REGISTRATION_STATUS,
})

const COMPANY_TYPE = Object.freeze({
  SYSTEM: 'system',
  TRADER: 'trader',
  BROKER: 'broker',
})

const COMPANY_TYPE_TEXT = Object.freeze({
  [COMPANY_TYPE.BROKER]: 'Broker',
  [COMPANY_TYPE.TRADER]: 'Trader',
  [COMPANY_TYPE.SYSTEM]: 'System',
  BROKER: 'Broker',
  TRADER: 'Trader',
  SYSTEM: 'System',
})

module.exports = {
  COMPANY_STATUS,
  COMPANY_TYPE,
  COMPANY_TYPE_TEXT,
}
