import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { get } from 'lodash'
import { useIsReactInDevelomentMode } from '../helpers'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'

const _RadioButtonGroup = ({
  formik: { values, setFieldValue, setFieldTouched },
  title,
  options,
  fieldName,
  style = {},
  onChangeRadioButton,
}) => {
  const [value, setValue] = useState(get(values, fieldName))

  useEffect(() => {
    setValue(get(values, fieldName))
  }, [values])

  const onChange = (value) => {
    setFieldValue(fieldName, value)
    setValue(value)
    setFieldTouched(fieldName, true)
    onChangeRadioButton(value)
  }
  const isReactInDevelomentModeResult = useIsReactInDevelomentMode()
  return (
    <FormControl required style={{ ...style }}>
      {title && <FormLabel 
      {...(isReactInDevelomentModeResult ? {title: `Selected value is in: ${fieldName}`} : {})}
      >{title}</FormLabel>}
      <RadioGroup
        onChange={(evt) => onChange(evt.target.value)}
        value={value}        
        style={{ flexDirection: 'row' }}
      >
        {options.map(({ value, label, disabled }) => (
          <FormControlLabel
            key={value}
            value={value}
            label={label}
            disabled={disabled}
            {...(isReactInDevelomentModeResult && title ? {title: `Value: ${value}`} : {})} 
            {...(isReactInDevelomentModeResult && !title ? {title: `Selected value is in: ${fieldName}. Value: ${value}`} : {})} 
            control={
              <Radio
                inputProps={{ 'data-testid': `${value}-radio` }}
                color="primary"
                size="small"
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

_RadioButtonGroup.defaultProps = {
  onChangeRadioButton: () => {},
}

_RadioButtonGroup.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  fieldName: PropTypes.string.isRequired,
  style: PropTypes.object,
  onChangeRadioButton: PropTypes.func,
}

export const RadioButtonGroup = connect(_RadioButtonGroup)
