import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Fab as _Fab } from '@material-ui/core'

const Fab = styled(_Fab)`
  width: ${({ dimension }) => dimension}px;
  height: ${({ dimension }) => dimension}px;
  font-size: ${({ dimension }) => dimension / 1.75}px;
  border: 1px solid ${({ theme }) => theme.palette.text.primary};
  background: transparent;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 500;
  min-height: auto;

  &:hover {
    background: inherit;
  }
`

const SwapSymbol = ({ size, style, ...props }) => (
  <Fab
    disableFocusRipple
    disableRipple
    dimension={size}
    style={style}
    {...props}
  >
    S
  </Fab>
)

SwapSymbol.defaultProps = {
  size: 35,
}

SwapSymbol.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
}

export default SwapSymbol
