import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import { each, omit } from 'lodash'
import { useQueryClient } from 'react-query'
import { TRADING_TYPE } from 'emsurge-selectors'
import { getCounterPartyData, getTermSheetData } from '../helpers'
import { getInformationInitialValues } from './getInformationInitialValues'
import { validateOrder } from 'screens/orderCreate/containers/validateOrder'
import { getSchema } from 'screens/orderCreate/containers/FormSchema'
import { getOrderInfo } from 'screens/orderCreate/containers/FormProvider'
import { getNominationInitialValues } from 'screens/orderEdit/containers/getNominationInitialValues'
import { useApi } from 'containers/api/useApi'
import { useUser } from 'containers/user/useUser'
import { getApiErrorMessage } from 'containers/api/helpers'
import { ORDER_KEY } from 'api/constants'

const getInitialValues = (order, user) => {
  const initialValues = {
    ...getInformationInitialValues(order, user),
    ...getNominationInitialValues(order),
    referenceOrderForTermsheet: 'counter',
    template: false,
  }

  return initialValues
}

const getData = (values, user) =>
  omit(getOrderInfo(values, user), ['comments', 'broker', 'brokerAccess'])

const getApiData = (order, formValues, user, isCreatingTrade) => {
  const orderId = order.id
  const { referenceOrderForTermsheet, ...values } = formValues
  const fromOrder = referenceOrderForTermsheet === 'original'
  const counterOrderData = getData(values, user)
  counterOrderData.tradingType = order.tradingType === TRADING_TYPE.ASK ? TRADING_TYPE.BID : TRADING_TYPE.ASK //This is required because the form has a hardcoded "ASK" value for the trading type to force the UI to behave as an "ASK" flow.

  //This is because the counterOrder information is retrieved from the form values and in the form values the tradingType is set to ASK to force the UI flow to behave as an ASK flow
  counterOrderData.tradingType = order.tradingType === TRADING_TYPE.BID ? TRADING_TYPE.ASK : TRADING_TYPE.BID

  const counterOrder = {
    ...counterOrderData,
    ...getCounterPartyData(counterOrderData, formValues, user)
  }  

  if(!isCreatingTrade) /*Counter Order*/ counterOrder.entityId = counterOrderData.entityId

  const termsheetData = getTermSheetData(order, formValues)
  
  return { orderId, fromOrder, counterOrder, termsheetData }
}

const FormProvider = ({
  order,
  enqueueSnackbar,
  history,
  children,
  postSubmit,
  isCreatingTrade
}) => {
  const api = useApi()
  const { user } = useUser()
  const queryClient = useQueryClient()

  /* :: (object, object) -> Promise<void> */
  const handleSubmit = async (values, actions) => {
    const data = getApiData(order, values, user, isCreatingTrade)

    try {
      const {
        data: { termsheet },
      } = await api.post('/termsheets/counter', data)

      queryClient.setQueryData([ORDER_KEY, termsheet.id], termsheet)
      each(termsheet.orders, (order) =>
        queryClient.invalidateQueries([ORDER_KEY, order.id])
      ) // TODO: replace with mutation

      if (!postSubmit) {
        return history.goBack()
      }

      return await postSubmit(termsheet)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Counter creation failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getInitialValues(order, user)}
      validationSchema={getSchema(user, isCreatingTrade)}
      validate={validateOrder}
      validateOnMount={false}
    >
      {({errors}) => (
        <div>
          {errors && console.debug("Errors", errors) /* Show Formik errors if they are present */}
          {children}
        </div>
      )}
    </Formik>
  )
}

FormProvider.defaultProps = {
  initialValues: {},
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  postSubmit: PropTypes.func,
  initialValues: PropTypes.object,
}

export default compose(withSnackbar, withRouter)(FormProvider)
