import { object } from 'yup'
import { getAuthorisedSignatoryFormSchema } from './AuthorisedSignatory/FormSchema'
import { GeneralInformationFormSchema } from './GeneralInformation/FormSchema'
import { getPrimaryPointContactFormSchema } from './PrimaryPointContact/FormSchema'

const getTradingEntityFormSchema = ({ authorisedSignatory, pointOfContact }) =>
  object().shape({
    ...GeneralInformationFormSchema,
    authorisedSignatory: getAuthorisedSignatoryFormSchema({
      email: authorisedSignatory.email,
    }),
    pointOfContact: getPrimaryPointContactFormSchema({
      email: pointOfContact.email,
    }),
  })

export { getTradingEntityFormSchema }
