const { PERSON_CLIENT_TYPE_TEXT } = require('../../constants')
const company = require('./company')

const getUser = (user) => ({
  ...user,
  company: user.company || {},
  entities: user.entities || [],
})

const getUserClientType = (user) => {
  return (user || {}).clientType
}

const getUserClientTypeText = (user) => {
  const clientType = getUserClientType(user)

  return PERSON_CLIENT_TYPE_TEXT[clientType] || ''
}

const getUserFullName = (user) => {
  const { name = '', surname = '' } = getUser(user)

  return `${name} ${surname}`.trim()
}

const getUserSummary = (user) => {
  const _user = getUser(user)

  return {
    id: _user.id || '',
    name: _user.name || '',
    surname: _user.surname || '',
    jobTitle: _user.jobTitle || '',
    email: _user.email || '',
    phone: _user.phone || '',
    clientType: getUserClientType(user),
    companyId: _user.companyId || '',
    company: company.text(user),
    entities: _user.entities,
    adminRole: _user.adminRole || '',
    token: _user.token || '',
    fullName: getUserFullName(user),
  }
}

const getUserText = (user) => {
  const userSummary = getUserSummary(user)

  return {
    ...userSummary,
    clientType: getUserClientTypeText(user),
  }
}

module.exports = {
  get: getUser,
  summary: getUserSummary,
  text: getUserText,
  clientType: {
    get: getUserClientType,
    text: getUserClientTypeText,
  },
  fullName: {
    get: getUserFullName,
  },
}
