import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MoreVertical from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import { isObject } from 'lodash'
import Tooltip from 'components/tooltip/Tooltip'

const MoreMenu = ({ id, children, style, iconStyle, Icon }) => {
  const [buttonElem, setElement] = useState()
  const MORE_MENU_ID = `${id}-more-menu`
  const isOpen = isObject(buttonElem)
  const close = () => setElement(null)

  return (
    <>
      <Tooltip title="Menu">
        <IconButton
          aria-owns={isOpen ? MORE_MENU_ID : undefined}
          aria-haspopup="true"
          onClick={(e) => setElement(e.currentTarget)}
          style={style}
        >
          <Icon style={iconStyle} />
        </IconButton>
      </Tooltip>

      <Menu
        id={MORE_MENU_ID}
        anchorEl={buttonElem}
        open={isOpen}
        onClose={close}
      >
        <span>{children({ close })}</span>
      </Menu>
    </>
  )
}
MoreMenu.defaultProps = {
  Icon: MoreVertical,
}

MoreMenu.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  Icon: PropTypes.object,
}

export default MoreMenu
