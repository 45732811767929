const brokerAccess = require('./broker-access')
const cargoSize = require('./cargo-size')
const delivery = require('./delivery')
const note = require('./note')
const orderLNG = require('./order')
const orderCarbon = require('./orderCarbon')
const owner = require('./owner')
const party = require('./party')
const price = require('./price')
const quality = require('./quality')
const stakeholder = require('./stakeholder')
const status = require('./status')
const submittedAt = require('./submitted-at')
const timestamps = require('./timestamps')
const tradingType = require('./trading-type')
const validity = require('./validity')
const views = require('./views')
const template = require('./template')
const carbonType = require('./carbon/type')
const carbonPrice = require('./carbon/price')
const termVintage = require('./termVintage')
const volume = require('./volume')
const region = require('./region')
const projectSpecification = require('./projectSpecification')
const additionalAttributes = require('./additionalAttributes')
const venue = require('./venue')
const otherTerms = require('./otherTerms')

const $O = {
  ...orderLNG,
  brokerAccess,
  cargoSize,
  delivery,
  note,
  owner,
  party,
  price,
  quality,
  stakeholder,
  status,
  submittedAt,
  timestamps,
  tradingType,
  validity,
  views,
  template,
}

const $OC = {
  ...orderCarbon,
  status,
  stakeholder,
  carbonType,
  carbonPrice,
  tradingType,
  termVintage,
  volume,
  region,
  projectSpecification,
  additionalAttributes,
  venue,
  otherTerms,
}

module.exports = { $O, $OC }
