import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { chain, get } from 'lodash'
import Paper from '@mui/material/Paper'
import Autocomplete from '@mui/material/Autocomplete'
import MuiTextField from '@material-ui/core/TextField'
import { defaultProps } from 'recompose'
import { TradeAdditionalParty } from './TradeAdditionalParty'
import FormRow from 'components/formRow/FormRow'
import { useApi } from 'containers/api/useApi'
import { useFetch } from 'containers/fetch/useFetch'

export const TextField = defaultProps({
  fullWidth: true,
  margin: 'normal',
})(MuiTextField)

const useUpdatedValues = (id, url) => {
  const api = useApi()
  const [values, setValues] = useState([])

  useEffect(() => {
    let didCancel = false

    const getValues = async () => {
      let newValues

      if (id) {
        const { data = [] } = await api.get(url)
        newValues = data
      } else {
        newValues = []
      }

      if (!didCancel) {
        setValues(newValues)
      }
    }

    getValues()

    return () => {
      didCancel = true
    }
  }, [api, id, url])

  return values
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const NewTradeParties = connect(({ formik, partyName }) => {
  const companyIdKey = `${partyName}.company.id`
  const entityIdKey = `${partyName}.entity.id`
  const personIdKey = `${partyName}.person.id`
  const companyNameKey = `${partyName}.company.name`
  const companyCodeKey = `${partyName}.company.code`
  const entityNameKey = `${partyName}.entity.name`
  const entityCodeKey = `${partyName}.entity.code`
  const personNameKey = `${partyName}.person.name`
  const executionTraderKey = `${partyName}.executionTrader`
  const companySelectionDesabled = get(formik.values, `${partyName}.isCompanyDisabled`)
  const [companyId, setCompanyId] = useState(get(formik.values, companyIdKey))
  const [entityId, setEntityId] = useState(get(formik.values, entityIdKey))
  const [isFreeEntry, setIsFreeEntry] = useState(true)

  useEffect(() => {
    setIsFreeEntry(companyId ? false: true)
  }, [companyId])

  const entities = useUpdatedValues(
    companyId, `/directory/entities?companyId=${companyId}`
  )
  const persons = useUpdatedValues(
    entityId,
    `/directory/traders?companyId=${companyId}`
  )
  const { data: companies = [] } = useFetch(`/directory/companies`)

  const updateCompany = (company) => {
    const companyId = company.id
    const companyName = company.name
    const companyCode = company.code
    setCompanyId(companyId)
    formik.setFieldValue(companyIdKey, companyId)
    formik.setFieldValue(companyNameKey, companyName)
    formik.setFieldValue(companyCodeKey, companyCode)
  }

  const updateCompanyName = (companyName) => {
    formik.setFieldValue(companyNameKey, companyName)
  }

  const resetCompany = () => {
    setCompanyId('')
    setEntityId('')
    formik.setFieldValue(companyIdKey, '')
    formik.setFieldValue(companyCodeKey, '')
    updateCompanyName('')
  }

  const updateEntity = (entity) => {
    const entityId = entity.id
    const entityName = entity.name
    const entityCode = entity.code
    setEntityId(entityId)
    formik.setFieldValue(entityIdKey, entityId)
    formik.setFieldValue(entityNameKey, entityName)
    formik.setFieldValue(entityCodeKey, entityCode)
  }

  const updateEntityName = (entityName) => {
    formik.setFieldValue(entityNameKey, entityName)
  }

  const resetEntity = () => {
    setEntityId('')
    formik.setFieldValue(entityIdKey, '')
    formik.setFieldValue(entityCodeKey, '')
    updateEntityName('')
  }

  const updatePerson = (person) => {
    const personId = person.id
    const personName = person.name
    formik.setFieldValue(personIdKey, personId)
    formik.setFieldValue(personNameKey,personName)
  }

  const updatePersonName = (personName) => {
    formik.setFieldValue(personNameKey, personName)
  }

  const resetPerson = () => {
    formik.setFieldValue(personIdKey, '')
    updatePersonName('')
  }

  const handleCompanyChange = (event, newValue) => {
    const company = companies.find( x => x.name === newValue)
    if(company){
      updateCompany(company)
    }
    else {
      resetCompany();
      updateCompanyName(newValue)
    }  
    resetEntity()
    resetPerson()
  }

  const handleEntityChange = (event, newValue) => {
    const entity = entities.find( x => x.name === newValue)
    if(entity){
      updateEntity(entity)
    }
    else {
      resetEntity()
      updateEntityName(newValue)
    }  
    resetPerson()
  }

  const handlePersonChange = (event, newValue) => {
    const person = persons.find( x => x.name === newValue)
    if(person){
      updatePerson(person)
    }
    else {
      updatePersonName(newValue)
    }  
  }

  const setError = (key) => {
    return formik.submitCount > 0 && !get(formik.values, key)
  }

  // Ideally we can reference the Theme, but I couldn't find a way to do it.
  const autoCompleteStyle = {
    backgroundColor: "rgb(26, 34, 47)",
    color: "#ffffff"
  }

  return (
    <>
      <FormRow>
      <Autocomplete
        required
        freeSolo={isFreeEntry}
        autoComplete
        autoHighlight
        autoSelect
        value={get(formik.values, companyNameKey)}
        options={chain(companies || []).sortBy('name').value().map(x => x.name)}
        disabled={companySelectionDesabled}
        onChange={handleCompanyChange}
        renderInput={(params) => <TextField {...params} error={setError(companyNameKey)} label={`${capitalizeFirstLetter(partyName)} Company`} />}
        PaperComponent={({children}) => (
          <Paper style={autoCompleteStyle}>{children}</Paper>
        )}
      />
      <Autocomplete
        required
        freeSolo={isFreeEntry}
        autoComplete
        autoHighlight
        autoSelect
        value={get(formik.values, entityNameKey)}
        options={chain(entities || []).sortBy('name').value().map(x => x.name)}
        onChange={handleEntityChange}
        renderInput={(params) => <TextField {...params} error={setError(entityNameKey)} label={`${capitalizeFirstLetter(partyName)} Entity`} />}
        PaperComponent={({children}) => (
          <Paper style={autoCompleteStyle}>{children}</Paper>
        )}
      />
      <Autocomplete
        required
        freeSolo={isFreeEntry}
        autoComplete
        autoHighlight
        autoSelect
        value={get(formik.values, personNameKey)}
        options={chain(persons || []).sortBy('name').value().map(x => x.name)}
        onChange={handlePersonChange}
        renderInput={(params) => <TextField {...params} error={setError(personNameKey)} label={`${capitalizeFirstLetter(partyName)} Trader`} />}
        PaperComponent={({children}) => (
          <Paper style={autoCompleteStyle}>{children}</Paper>
        )}
      />
      </FormRow>
      <TradeAdditionalParty partyName={executionTraderKey} label={`${capitalizeFirstLetter(partyName)} Execution Trader`}/>
    </>
  )
})

NewTradeParties.defaultProps = {
  formik: {},
}

NewTradeParties.propTypes = {
  formik: PropTypes.object,
}
