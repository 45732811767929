const moment = require('moment')

const formatDateText = (date) => moment(date).format('Do MMM YYYY ddd HH:mm')

const getViews = (order) => {
  const { views = {} } = order

  return {
    count: views.count || 0,
    hasViewed: views.hasViewed || false,
    updatedAt: views.updatedAt || '',
  }
}

const getViewsText = (order) => {
  const _order = getViews(order)

  return {
    ..._order,
    updatedAt: formatDateText(_order.updatedAt),
  }
}

module.exports = {
  get: getViews,
  text: getViewsText,
}
