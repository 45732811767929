import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { $OC, CLASSIFICATIONS_SECOND } from 'emsurge-selectors'
import OrderType from './OrderType'
import Person from './Person'
import { CardIcons } from './MarketInterest/CardIcons'
import OrderStatusSwitch from './OrderStatusSwitch'
import { SmallText } from 'components/Text'
import { Card, Container } from 'components/Card'
import { Row } from 'components/Row'
import CompanyCode from 'components/CompanyCode/CompanyCode'
import { StyledLink as Link } from 'components/StyledLink'
import { CarbonMainPrice } from 'components/MainPrice'
import { useTruncatedTextObject } from 'hooks'

const OrderCardCarbon = ({ order, style }) => {
  const { spacing } = useTheme()
  const gutterR = { marginRight: spacing(1.5) }
  const { classifications = {} } = $OC.carbonType.get(order)
  const { text: volumeText } = $OC.volume.text(order)
  const classificationsDetail = CLASSIFICATIONS_SECOND.find(
    (attr) => attr.value === classifications.second
  ) || { label: '' }
  const classificationVolume = [
    volumeText.join('-'),
    classificationsDetail.label,
  ]

  const { projects } = $OC.projectSpecification.get(order)
  const projectNamesText = useTruncatedTextObject(projects, x => `${x.referenceNr}-${x.name}`, 30)

  return (
    <Link to={(location) => `${location.pathname}/orders/${order.id}`}>
      <Card
        style={style}
        id={`my-order-${order.id}`}
        data-cy="orders-panel-order-cards"
        data-testid={`my-order-${order.id}`}
      >
        <Row direction={'row'}>
          <Person data-testid="order-card-author" person={order.person} />
        </Row>

        <Container justifyContent="space-between">
          <Container style={{ width: 'auto' }}>
            <CompanyCode
              data-testid="order-card-company-code"
              order={order}
              style={gutterR}
            />
            <OrderType data-testid="order-card-order-type" order={order} />
          </Container>

          <Container style={{ width: 'auto' }}>
            <CardIcons order={order} />
          </Container>
        </Container>

        <Row direction={'row'} >
          <SmallText title={projectNamesText.completeText}>{projectNamesText.truncatedText}</SmallText>
          <OrderStatusSwitch data-testid="order-status-switch" order={order} />
        </Row>

        <Row direction={'row'}>
          <SmallText>{classificationVolume.join(' ')}</SmallText>
          <CarbonMainPrice data-testid="order-card-main-price" order={order} />
        </Row>

      </Card>
    </Link>
  )
}

OrderCardCarbon.defaultProps = {
  toggle: true,
}

OrderCardCarbon.propTypes = {
  order: PropTypes.object.isRequired,
  style: PropTypes.object,
  toggle: PropTypes.bool,
}

export default memo(OrderCardCarbon)
