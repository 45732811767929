import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { merge } from 'lodash'
import { getEssentialEditValues } from './essentialEditValues'
import { getOptionalEditValues } from './optionalEditValues'
import { validateOrder } from 'screens/orderCreateCarbon/containers/validation'
import { getSchema } from 'screens/orderCreateCarbon/FormSchema'
import { useUser } from 'containers/user/useUser'
import { getEssentialInitialValues } from 'screens/orderCreateCarbon/essentialInitialValues'
import { getOptionalInitialValues } from 'screens/orderCreateCarbon/optionalInitialValues'

export const getInitialValues = (order) => {
  const initialValues = merge(
    {
      isEdit: true
    },
    {...getEssentialInitialValues(order)}, //We need default values for any new option the user will select.
    {...getOptionalInitialValues(order)}, 
    
  )

  const projectInformationTmp = order.termVintage.projectInformation // This is required because there are multiple calls to this method and if the value is removed we have errors.
  order.termVintage.projectInformation = {} //The project information need to be removed to avoid validation issues. The project informatoin was already recovered in the getEssentialEditValues
  
  const programEligibilityTmp = order.carbonType.programEligibility 
  order.carbonType.programEligibility = {} 

  const carbonStandardTmp = order.carbonType.carbonStandard 
  order.carbonType.carbonStandard = {} 

  const regionOptionsTmp = order.regions.options 
  order.regions.options = {} 

  const regionCountriesTmp = order.regions.countries 
  order.regions.countries = {} 

  const additionalAttributesOptionsTmp = order.additionalAttributes.options 
  order.additionalAttributes.options = {} 

  const venueOptionsTmp = order.venue.options 
  order.venue.options = {} 

  const projectSpecificationBuyerOptionsTmp = order.projectSpecification.sectoralScopes
  order.projectSpecification.sectoralScopes  = {} 

  const projectSpecificationKeywordsTmp = order.projectSpecification.keywords
  order.projectSpecification.keywords = []

  const sdgsTmp = order.additionalAttributes.sdgs
  order.additionalAttributes.sdgs = []

  const projectsTmp = order.projectSpecification.projects
  order.projectSpecification.projects = []

  const priceTmp = order.price.basic
  order.price.basic = {}

  const ratingTmp = order.carbonType.rating
  order.carbonType.rating = {}

  const initialValuesWithOrderData = merge(
    initialValues,
    order
  )
  
  initialValuesWithOrderData.termVintage.projectInformation = {} //Clean default value in project information status multiple selection
  order.termVintage.projectInformation = projectInformationTmp
  order.carbonType.programEligibility = programEligibilityTmp
  order.carbonType.carbonStandard = carbonStandardTmp
  order.regions.options = regionOptionsTmp
  order.additionalAttributes.options = additionalAttributesOptionsTmp
  initialValuesWithOrderData.venue.options = {} //Clean default value in venue multiple selection
  order.venue.options = venueOptionsTmp
  order.regions.countries = regionCountriesTmp
  order.projectSpecification.sectoralScopes = projectSpecificationBuyerOptionsTmp
  order.projectSpecification.keywords = projectSpecificationKeywordsTmp
  order.additionalAttributes.sdgs = sdgsTmp
  order.projectSpecification.projects = projectsTmp
  order.price.basic = priceTmp
  order.carbonType.rating = ratingTmp

  initialValuesWithOrderData.regions.options = {}
  const result = merge(
    initialValuesWithOrderData,
    getEssentialEditValues(order),
    getOptionalEditValues(order),
  )

  return result
}

const FormProvider = ({ order, children, onSubmit }) => {
  const { user } = useUser()
  const initialValues = getInitialValues(order)
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={getSchema(user)}
      validate={validateOrder}
      validateOnMount={false}
    >
      {children}
    </Formik>
  )
}

FormProvider.defaultProps = {
  postSubmit: () => {},
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  order: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default FormProvider
