import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/styles'

import { SideMenuOptions } from './SideMenuOptions'
import NavList from './NavList'
import { ViewportOverrideToggle } from './ViewportOverrideToggle'
import { Mobile } from 'components/Viewport'
import { LayoutContext } from 'containers/layout/LayoutProvider'
import { useIsMobile } from 'components/Viewport/Mobile'

export const SIDE_MENU_WIDTH = 220

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: SIDE_MENU_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  full: {
    flexShrink: 0,
    width: SIDE_MENU_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hidden: {
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  compact: {
    flexShrink: 0,
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}))

export const SideMenu = ({ closeOnClick, ...drawerProps }) => {
  const layout = useContext(LayoutContext)
  const isMobile = useIsMobile()
  const classes = useStyles()

  const allProps = {
    anchor: 'left',
    open: layout.hasSideMenu,
    onClose: () => layout.setSideMenu(false),
    ...drawerProps,
  }

  const collapsed = isMobile ? classes.hidden : classes.compact
  const { open } = allProps

  return (
    <Drawer
      className={`${classes.drawer} ${open ? classes.full : collapsed}`}
      classes={{
        paper: open ? classes.full : collapsed,
      }}
      {...allProps}
    >
      <NavList onClick={closeOnClick ? allProps.onClose : () => {}}>
        <SideMenuOptions isMobile={isMobile} isDrawerOpen={open} />
      </NavList>
      <Mobile>
        <ViewportOverrideToggle />
      </Mobile>
      {layout.isDesktopViewportMode && <ViewportOverrideToggle />}
    </Drawer>
  )
}

SideMenu.defaultProps = {
  closeOnClick: false,
}

SideMenu.propTypes = {
  closeOnClick: PropTypes.bool,
}
