import { times } from 'lodash'
import moment from 'moment'
import flatten from 'flat'
import {
  CARGO_SIZE_UNIT,
  DELIVERY_REGION,
  DELIVERY_TYPE,
  PRICE_INDEX,
  STAKEHOLDER_TYPE,
} from 'emsurge-selectors/constants'
import {
  getCargoSizeType,
  getCargoSizeParty,
  getCargoSizeVariance,
} from 'screens/orderCreate/helpers'

const getStep1 = () => ({
  submissionDate: new Date().toISOString(),
  behalfOf: STAKEHOLDER_TYPE.ON_SYSTEM,
  myCompany: {
    entityId: '',
  },
  thirdParty: {
    companyName: '',
    entityName: '',
    contact: '',
  },
  tradingType: 'bid',
  status: 'pending',
  validity: {},
  details: '',
  template: false,
})

export const getStep1Paths = () => [
  'behalfOf',
  'myCompany.entityId',
  'myCompany.traderId',
  'thirdParty.companyName',
  'thirdParty.entityName',
  'thirdParty.contact',
  'tradingType',
  'status',
  'validity.until',
  'validity.date',
  'validity.time',
  'details',
  'buyer.company.name',
  'buyer.entity.name',
  'buyer.person.name',
  'seller.company.name',
  'seller.entity.name',
  'seller.person.name',
]

const getCurrentYear = () => {
  const date = new Date()
  return date.getFullYear()
}

export const STEP2 = {
  delivery: {
    type: DELIVERY_TYPE.DES,
    customTo: moment().add(2, 'month').format('YYYY-MM'),
    customFrom: moment().add(1, 'month').format('YYYY-MM'),
    year: getCurrentYear() + 1,
    period: 'year',
    location: DELIVERY_REGION?.[DELIVERY_TYPE.DES]?.[0] || 'ANY',
    frequency: 'none',
    frequencyDistribution: 'x_days_or_months',
  },
  volume: {
    type: 'fixed',
    min: 1,
    max: undefined,
  },
}

export const getStep2Paths = () => Object.keys(flatten(STEP2))

export const DEFAULT_HYBRID_PRICE = {
  weight: '',
  percentage: 100,
  index: PRICE_INDEX.NBP,
  plusOrMinus: '+',
  amount: '',
  unit: 'mmbtu',
}

const getInitialPriceValues = (enable = false) => ({
  enable,
  type: 'tbd',
  floatingInfo: {
    percentage: 100,
    index: PRICE_INDEX.NBP,
    plusOrMinus: '+',
    amount: '',
    unit: 'mmbtu',
  },
  fixedInfo: {
    value: '',
    unit: 'mmbtu',
  },
  hybridInfo: times(2).map(() => ({ ...DEFAULT_HYBRID_PRICE })),
  rfqInfo: {
    deadline: { date: moment().format('YYYY-MM-DD'), time: '00:00' },
    award: { date: moment().format('YYYY-MM-DD'), time: '00:00' },
    notes: '',
  },
  preferenceInfo: {
    value: 'tbd',
  },
})

const STEP3 = {
  price: {
    basic: getInitialPriceValues(true),
    reserve: getInitialPriceValues(),
    contract: getInitialPriceValues(),
  },
}

export const getStep3Paths = () => ['price']

const cargoSizeParty = getCargoSizeParty(STEP2.delivery.type)
const variance = getCargoSizeVariance(cargoSizeParty)
const cargoSizeUnit = CARGO_SIZE_UNIT.TBTU

const STEP4 = {
  cargoSize: {
    unit: cargoSizeUnit,
    type: getCargoSizeType(cargoSizeUnit),
    party: cargoSizeParty,
    sizeMin: '',
    sizeMax: '',
    ...variance,
  },
}

export const getStep4Paths = () => Object.keys(flatten(STEP4))

export const DEFAULT_CUSTOM_QUALITY_RANGE = [1000, 1150]

const STEP5 = {
  quality: {
    type: 'flexible',
    customMin: DEFAULT_CUSTOM_QUALITY_RANGE[0],
    customMax: DEFAULT_CUSTOM_QUALITY_RANGE[1],
    cbmPermitted: true,
    usPermitted: true,
  },
}

export const getStep5Paths = () => Object.keys(flatten(STEP5))

export const getInformationInitialValues = () => ({
  comments: [],
  ...getStep1(),
  ...STEP2,
  ...STEP3,
  ...STEP4,
  ...STEP5,
})

export const getInformationPaths = () => [
  ...getStep1Paths(),
  ...getStep2Paths(),
  ...getStep3Paths(),
  ...getStep4Paths(),
  ...getStep5Paths(),
]
