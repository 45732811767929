import React from 'react'
import PropTypes from 'prop-types'
import FilterList from '@material-ui/icons/FilterList'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from 'components/tooltip/Tooltip'
import MoreMenu from 'components/moreMenu/MoreMenu'

const HeaderMoreMenu = ({ setAscOrder, setDesOrder }) => (
  <Tooltip title="Sort">
    <span>
      <MoreMenu
        id="nominations-sort"
        Icon={FilterList}
        iconStyle={{
          fontSize: 32,
        }}
      >
        {({ close }) => [
          <MenuItem
            key="1"
            onClick={() => {
              setAscOrder()
              close()
            }}
          >
            ORDER BY NEWEST
          </MenuItem>,
          <MenuItem
            key="2"
            onClick={() => {
              setDesOrder()
              close()
            }}
          >
            ORDER BY OLDEST
          </MenuItem>,
        ]}
      </MoreMenu>
    </span>
  </Tooltip>
)

HeaderMoreMenu.propTypes = {
  setDesOrder: PropTypes.func.isRequired,
  setAscOrder: PropTypes.func.isRequired,
}

export default HeaderMoreMenu
