import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from '@material-ui/core/MenuItem'
import { identity, upperCase } from 'lodash'
import { StyledLink } from 'components/StyledLink'
import { useOrderCanBeTraded } from 'screens/orderMarkAsTraded/helpers'
import { useIsMobile } from 'components/Viewport/Mobile'

export const MarkAsTradedMenuItem = ({ order }) => {
  const orderCanBeTraded = useOrderCanBeTraded(order)
  const isMobile = useIsMobile()
  const transformer = isMobile ? identity : upperCase

  if (!orderCanBeTraded) {
    return null
  }

  const label = transformer('Mark as Traded')

  return (
    <StyledLink to={(location) => `${location.pathname}/mark-as-traded`}>
      <MenuItem data-testid="menu-mark-as-traded-button">{label}</MenuItem>
    </StyledLink>
  )
}

MarkAsTradedMenuItem.propTypes = {
  order: PropTypes.object.isRequired,
}
