const frequency = require('./frequency')
const period = require('./period')
const region = require('./region')
const type = require('./type')
const volume = require('./volume')

module.exports = {
  ...frequency,
  ...period,
  ...region,
  ...type,
  ...volume,
}
