import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { object, string, lazy } from 'yup'
import { useSnackbar } from 'notistack'
import Form from 'containers/formikForm/FormikForm'
import { BrandLogoWrapper } from 'components/logo'
import { LayoutWrapper } from 'components/LayoutWrapper'
import { SubmitButton } from 'screens/signIn/components/Buttons'
import { PasswordField } from 'screens/signIn/components/SignInInputs'
import { DASHBOARD } from 'routes'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const Schema = lazy((values) =>
  object().shape({
    password: string()
      .min(12, 'Password has to be longer than 12 characters')
      .required('Required'),
    passwordConfirmation: string()
      .oneOf([values.password], 'Passwords do not match')
      .required('Required'),
  })
)

const INITIAL_VALUES = {
  password: '',
  passwordConfirmation: '',
}

const NewPassword = ({ history, location }) => {
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()
  const { token } = queryString.parse(location.search)

  const handleNewPassword = async (values, form) => {
    const { password } = values
    const data = { token, password }

    try {
      await api.patch('/password', data, { auth: false })
      form.resetForm()
      enqueueSnackbar('New password created with success', {
        variant: 'success',
      })
      history.push(DASHBOARD)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'New password cannot be created',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      form.setSubmitting(false)
    }
  }

  return (
    <LayoutWrapper>
      <BrandLogoWrapper />
      <Form
        initialValues={INITIAL_VALUES}
        validationSchema={Schema}
        onSubmit={handleNewPassword}
      >
        <PasswordField name="password" label="New password" />
        <PasswordField
          name="passwordConfirmation"
          label="Confirm new password"
        />

        <SubmitButton>Submit</SubmitButton>
      </Form>
    </LayoutWrapper>
  )
}

NewPassword.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default NewPassword
