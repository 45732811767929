import React from 'react'
import PropTypes from 'prop-types'

import { PRICE_VARIANT } from 'emsurge-selectors/constants'
import { Unit } from './Unit'
import { UppercaseTextField } from 'screens/orderCreateCommon/components/TextField'
import FormRow from 'components/formRow/FormRow'

export const Fixed = ({ variant }) => {
  const fixedValue = `price.${variant}.fixedInfo.value`
  const fixedUnit = `price.${variant}.fixedInfo.unit`
  return (
    <FormRow>
      <UppercaseTextField
        inputProps={{ 'data-testid': fixedValue }}
        name={fixedValue}
        label="Amount"
      />
      <Unit data-testid={fixedUnit} name={fixedUnit} />
    </FormRow>
  )
}

Fixed.propTypes = {
  variant: PropTypes.oneOf(Object.values(PRICE_VARIANT)).isRequired,
}
