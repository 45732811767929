import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { FormProvider } from './components/FormProvider'
import { Form } from './components/Form'
import Modal from 'components/modal/Modal'

const usePaperPropsStyles = (theme) => ({
  width: 'calc(60vw - 160px)',
  minWidth: '754px',
  height: 'auto',
  minHeight: '48vh',
  padding: theme.spacing(3, 4),
})

export const BulletinCreate = ({ children }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const styles = usePaperPropsStyles(theme)

  const close = () => setOpen(false)

  return (
    <>
      <Modal paperProps={{ styles }} open={open}>
        <FormProvider postSubmit={close}>
          <Form onClose={close} />
        </FormProvider>
      </Modal>

      {children({ open: () => setOpen(true) })}
    </>
  )
}

BulletinCreate.propTypes = {
  children: PropTypes.func,
}
