import moment from 'moment'
import { get, groupBy, mapValues, isNil } from 'lodash'
import { PARTY_TEXT } from 'emsurge-selectors/constants'
import { getEntityCode } from 'screens/transactionIndex/helpers'

/*
This is the logic for getting the timezone for a nomination in nominations (`api/nominations?toBeNominated=true`)
1) IF: nomination has `timezone` use it (`dischargePort` or `loadPort`)
2) ELSE: Find all nominations which has same `orderId` and `cargo`. From list find if any has `timezone`
3) ELSE: Use nomination.nominationRules to retrieve `timezone` (edited)
*/

/* :: object[] -> object */
export const getOrderCargoTimezoneMap = (nominations) => {
  const orderCargoMap = groupBy(nominations, (n) => [n.orderId, n.cargo])
  const orderCargoTimezoneNominationMap = mapValues(
    orderCargoMap,
    (cargoNominations) => cargoNominations.find((n) => !isNil(n.timezone))
  )
  const orderCargoTimezoneMap = mapValues(
    orderCargoTimezoneNominationMap,
    (nomination) => get(nomination, 'timezone', null)
  )

  return orderCargoTimezoneMap
}

/* :: (object, object) -> string */
export const getNominationTimezone = (nomination, orderCargoTimezoneMap) => {
  const { timezone, orderId, cargo, nominateUntil, termsheetTimezone } =
    nomination
  const nominationTimezone =
    timezone ||
    orderCargoTimezoneMap[`${orderId},${cargo}`] ||
    termsheetTimezone

  const userOffsetInMins = new Date().getTimezoneOffset()
  const nominateBy = moment(nominateUntil)
    .subtract(nominationTimezone, 'hours')
    .add(userOffsetInMins, 'minutes')
    .format()

  return nominateBy
}

export const getPartyEntityCode = ({ party }, orders) => {
  const partyOrder = orders.find((order) => order.tradingType === party)

  if (partyOrder) {
    return getEntityCode(partyOrder)
  }

  return ''
}

export const getVesselType = (type) =>
  type === 'vessel_size' ? 'size' : 'capacity'

export const getPartyLabel = (party) => {
  if (!party) {
    return 'Nomination TBA'
  }

  return `To be nominated by ${PARTY_TEXT[party]}`
}
