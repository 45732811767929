import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { HighlightLink } from 'components/HighlightLink'

const useStyles = makeStyles((theme) => ({
  selected: {
    color: theme.palette.primary.main,
  },
  icon: {
    minWidth: '40px',
  },
  subMenu: ({ isDrawerOpen, isMobile }) => ({
    marginLeft: isDrawerOpen || isMobile ? theme.spacing(2) : 0,
    transition: 'margin-left 225ms',
  }),
}))

const MenuItem = styled.span`
  font-weight: 800;
`

const NavLinkMenu = ({
  to,
  matchPath,
  children,
  Icon,
  isSubMenu = false,
  isDrawerOpen = false,
  isMobile = false,
  ...textProps
}) => {
  const classes = useStyles({ isDrawerOpen, isMobile })
  const rootProps = {
    to,
    divider: true,
    button: true,
    component: Link,
  }
  const subMenuClass = isSubMenu ? classes.subMenu : ''
  const pathName = to.split('?')?.[0]

  return (
    <ListItem {...rootProps}>
      <HighlightLink to={pathName || to} match={matchPath}>
        {({ isActive }) => (
          <>
            <ListItemIcon classes={{ root: `${classes.icon} ${subMenuClass}` }}>
              <Icon
                classes={{
                  root: `${isActive && classes.selected} ${subMenuClass}`,
                }}
              />
            </ListItemIcon>
            <ListItemText
              data-testid={
                textProps?.['data-testid'] && `${textProps['data-testid']}`
              }
              primary={<MenuItem>{children.toUpperCase()}</MenuItem>}
              classes={{
                primary: `${isActive && classes.selected} ${subMenuClass}`,
              }}
            />
          </>
        )}
      </HighlightLink>
    </ListItem>
  )
}

NavLinkMenu.propTypes = {
  to: PropTypes.string.isRequired,
  matchPath: PropTypes.array,
  children: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  isSubMenu: PropTypes.bool,
  isDrawerOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
}

export default NavLinkMenu
