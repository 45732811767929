import {
  partialRight,
  map,
  toLower,
  times,
  range,
  flatten,
  cloneDeep,
  capitalize,
} from 'lodash'
import moment from 'moment'
import {
  DELIVERY_FREQUENCY_TYPE,
  DELIVERY_FREQUENCY_TYPE_OPTIONS,
} from 'emsurge-selectors/constants'
import { PERIOD } from 'model/order/constants/delivery'

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'

export const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4']

export const isWeekStr = (deliveryWindowOption) =>
  deliveryWindowOption &&
  deliveryWindowOption.substr(0, 1).toLowerCase() === 'w'

export const SEASONS = ['SUM', 'WIN']

export const GENERAL_OPTIONS = ['Early', 'Mid', 'Late']

export const HALF_OPTIONS = ['First Half', 'Second Half']

export const SPECIFIC_DATES_DELIVERY_OPTION = 'Specific Dates'

export const lowercase = partialRight(map, toLower)

export const getWinterMonths = () => [
  ...MONTHS.slice(9, 12),
  ...MONTHS.slice(0, 3),
]

export const getSummerMonths = () => MONTHS.slice(3, 9)

export const getQuarterNumber = (quarterStr) => parseInt(quarterStr.substr(1))

export const getWeekNumber = (weekStr) => parseInt(weekStr.substr(1))

export const getMonthWeek = (momentDate) =>
  momentDate.week() - moment(momentDate).startOf('month').week() + 1

export const formatDate = (date) =>
  (date.format && date.format(DEFAULT_DATE_FORMAT)) || date

export const isCustomDeliveryPeriod = (period = '') =>
  period.toLowerCase() === 'custom'

export const isCalendarYearDeliveryPeriod = (period = '') =>
  period.toLowerCase() === 'year'

export const isGasYearDeliveryPeriod = (period = '') =>
  period.toLowerCase() === 'gas_year'

export const isDeliveryWindowPerCargo = ({ deliveryType }) =>
  deliveryType === 'per_cargo'

export const isSpecificDatesOption = (option) =>
  option === SPECIFIC_DATES_DELIVERY_OPTION

export const formatDateRanges = (range) =>
  range.map((r) => ({ ...r, from: formatDate(r.from), to: formatDate(r.to) }))

export const getRangeMonthsNumber = (startDate, endDate) =>
  endDate.month() -
  startDate.month() +
  1 +
  (endDate.year() - startDate.year()) * 12

export const isMonthPeriod = (period) =>
  !!period && lowercase(MONTHS).includes(period.substring(0, 3).toLowerCase())

export const isQuarterPeriod = (period) =>
  !!period && lowercase(QUARTERS).includes(period.toLowerCase())

export const isSeasonPeriod = (period) =>
  !!period && lowercase(SEASONS).includes(period.toLowerCase().substring(0, 3))

export const isRangePeriodCargoWindow = (cargoWindow) =>
  !!cargoWindow &&
  (lowercase(HALF_OPTIONS).includes(cargoWindow.toLowerCase()) ||
    isWeekStr(cargoWindow.toLowerCase()))

/**
 @TODO: We need to refactor this helper as it is confusing in its current state.

 @NOTE: Calculates the total number of cargos to load on delivery window
 step of nominations tab.
 Uses the "period" and the "frequency" values of step 2 of information form.
 */

/* :: object -> number */
export const getTotalCargos = (formValues) => {
  const { volume, delivery } = formValues
  const { period, frequency, customTo, customFrom } = delivery
  const cargoQuantity =
    volume.type === 'fixed'
      ? parseInt(volume.min, 10)
      : parseInt(volume.max, 10) || parseInt(volume.min, 10)

  /** @NOTE: none === Per period */
  if (frequency === 'none') {
    return cargoQuantity
  }

  if (isCustomDeliveryPeriod(period)) {
    const unit = frequency === 'per_month' ? 'months' : 'years'

    /** @NOTE: The dates are inclusive so we add 1 unit */
    const diff = moment(customTo).diff(customFrom, unit) + 1

    return cargoQuantity * diff
  }

  if (frequency === 'per_annum') {
    return cargoQuantity
  }

  /** @NOTE: Per month frequency */
  if (lowercase(MONTHS).includes(period)) {
    return cargoQuantity * 12
  }

  if (lowercase(QUARTERS).includes(period)) {
    return cargoQuantity * 3
  }

  if (toLower(period) === 'year' || toLower(period) === 'gas_year') {
    return cargoQuantity * 12
  }

  if (toLower(period) === 'summer' || toLower(period) === 'winter') {
    return cargoQuantity * 6
  }

  return cargoQuantity
}

const getFirstMonthEvenOrOdd = ({ startDate, even = false }) => {
  const startMonthIsEven = (startDate.month() + 1) % 2 === 0

  if ((startMonthIsEven && even) || (!startMonthIsEven && !even)) {
    return startDate
  }

  if ((!startMonthIsEven && even) || (startMonthIsEven && !even)) {
    return moment(startDate).add(1, 'month')
  }
}

export const getPeriodMonths = (startDate, endDate) => {
  return flatten(
    range(startDate.year(), endDate.year() + 1).map((year, index) => {
      const firstPeriodYear = index === 0
      const lastPeriodYear = endDate.year() === year
      return range(
        firstPeriodYear ? startDate.month() + 1 : 1,
        (lastPeriodYear ? endDate.month() + 1 : 12) + 1
      ).map((month) => ({ month, year }))
    })
  )
}

const splitInEvenOrOddMonths = (
  startDate,
  endDate,
  numberOfRanges,
  optionalParams
) => {
  const { spreadRange, evenMonthsOnly } = optionalParams
  const monthsToConsider = getPeriodMonths(startDate, endDate).filter(
    ({ month }) => (evenMonthsOnly ? month % 2 === 0 : month % 2 !== 0)
  )

  if (monthsToConsider.length >= numberOfRanges) {
    const numberOfMonths = getRangeMonthsNumber(startDate, endDate)
    const monthsByRange = numberOfMonths / numberOfRanges
    const startDateToConsider = getFirstMonthEvenOrOdd({
      startDate,
      even: evenMonthsOnly,
    })

    return times(numberOfRanges).map((index) => {
      const from = moment(startDateToConsider).add(
        monthsByRange * index,
        'month'
      )
      const to = spreadRange
        ? moment(from).add(monthsByRange, 'month').subtract(1, 'days')
        : moment(startDateToConsider)
            .add(monthsByRange * index + 1, 'month')
            .subtract(1, 'days')
      return {
        from,
        to,
      }
    })
  }

  const numberOfRangesByMonth = numberOfRanges / monthsToConsider.length

  return flatten(
    monthsToConsider.map(({ month, year }) => {
      const currentMonth = moment(
        `${startDate.date()}-${month}-${year}`,
        'DD-MM-YYYY'
      )
      const currentMonthDays = moment(currentMonth).endOf('month').date()
      const daysPerMonth = currentMonthDays / numberOfRangesByMonth
      return times(numberOfRangesByMonth).map((index) => ({
        from: moment(currentMonth).add(daysPerMonth * index, 'days'),
        to: moment(currentMonth)
          .add(daysPerMonth * (index + 1), 'days')
          .subtract(1, 'days'),
      }))
    })
  )
}

/*
 * Checks if the range is a complete month and should be
 * divided by two.
 */
const shouldSplitMonthByTwo = (startDate, endDate, numberOfRanges) => {
  const startDateDay = moment(startDate).date()
  const endDateDay = moment(endDate).date()
  const endDateMonthLastDay = moment(endDate).endOf('month').date()

  const sameMonth =
    startDate.month() === endDate.month() && startDate.year() === endDate.year()

  return (
    sameMonth &&
    startDateDay === 1 &&
    endDateDay === endDateMonthLastDay &&
    numberOfRanges === 2
  )
}

/*
 * Splits a month in two different periods according month number of days.
 */
const splitMonthByTwo = (startDate, endDate) => {
  const endDateMonthLastDay = moment(endDate).endOf('month').date()

  if ([29, 30, 31].includes(endDateMonthLastDay)) {
    return [
      { from: moment(startDate), to: moment(startDate).add(14, 'days') },
      { from: moment(startDate).add(15, 'days'), to: moment(endDate) },
    ]
  }

  return [
    { from: moment(startDate), to: moment(startDate).add(13, 'days') },
    { from: moment(startDate).add(14, 'days'), to: moment(endDate) },
  ]
}

const splitInEqualMonthsRanges = (startDate, endDate, numberOfRanges) => {
  const difference = endDate.diff(startDate, 'months') + 1
  const interval = difference / numberOfRanges
  return times(numberOfRanges).map((index) => ({
    from: moment(startDate).add(interval * index, 'months'),
    to: moment(startDate)
      .add(interval * (index + 1), 'months')
      .subtract(1, 'day'),
  }))
}

const splitInEqualDaysRanges = (startDate, endDate, numberOfRanges) => {
  const periodLength = endDate.valueOf() - startDate.valueOf()
  const step = periodLength / numberOfRanges

  return times(numberOfRanges).map((index) => ({
    from:
      index === 0
        ? startDate
        : moment(startDate)
            .add(step * index, 'milliseconds')
            .add(1, 'day'),
    to: moment(startDate).add(step * (index + 1), 'milliseconds'),
  }))
}

const splitInHalfMonths = (startDate, endDate, numberOfRanges) => {
  const numberOfMonths = getRangeMonthsNumber(startDate, endDate)
  const numberOfRangesByMonth = numberOfRanges / numberOfMonths

  return flatten(
    times(numberOfMonths).map((index) => {
      const currentMonth = moment(startDate).add(index, 'month')
      const currentMonthDays = moment(currentMonth).endOf('month').date()
      const daysPerMonth = currentMonthDays / numberOfRangesByMonth
      return times(numberOfRangesByMonth).map((index) => {
        return {
          from: moment(currentMonth).add(daysPerMonth * index, 'days'),
          to: moment(currentMonth)
            .add(daysPerMonth * (index + 1), 'days')
            .subtract(1, 'days'),
        }
      })
    })
  )
}

export const isCompleteMonthsRange = (startDate, endDate) => {
  const firstDayOfMonth = 1
  const lastDayOfMonth = moment(endDate).endOf('month').date()

  const isFromDayOneOfMonth = startDate.date() === firstDayOfMonth
  const isToLastDayOfMonth = endDate.date() === lastDayOfMonth

  return isFromDayOneOfMonth && isToLastDayOfMonth
}

export const isEvenlyMonths = (startDate, endDate, numberOfRanges) => {
  if (!isCompleteMonthsRange(startDate, endDate)) {
    return false
  }
  return getRangeMonthsNumber(startDate, endDate) % numberOfRanges === 0
}

export const isEvenlyCargoes = (startDate, endDate, numberOfRanges) => {
  if (!isCompleteMonthsRange(startDate, endDate) || numberOfRanges === 1) {
    return false
  }

  return numberOfRanges % getRangeMonthsNumber(startDate, endDate) === 0
}
/*
 * Splits a date range interval in several equal length sub-ranges.
 * The number of intervals generated is specified by the numberOfRanges
 * param.
 * The startDate and endDate should be moment dates.
 * Example for startDate 01/01/2019, endDate 31/12/2019, numberOfRanges 2
 *  -> outputs [{ from: 01/01/2019, to: 31/06/2019 }, { from: 01/07/2019, to: 31/12/2019 }]
 */
export const splitInEqualDateRanges = (
  startDate,
  endDate,
  numberOfRanges,
  optionalParams = {}
) => {
  const { evenMonthsOnly, oddMonthsOnly } = optionalParams

  if (shouldSplitMonthByTwo(startDate, endDate, numberOfRanges)) {
    return splitMonthByTwo(startDate, endDate)
  }

  if (evenMonthsOnly || oddMonthsOnly) {
    return splitInEvenOrOddMonths(
      startDate,
      endDate,
      numberOfRanges,
      optionalParams
    )
  }

  if (isEvenlyMonths(startDate, endDate, numberOfRanges)) {
    return splitInEqualMonthsRanges(
      startDate,
      endDate,
      numberOfRanges,
      optionalParams
    )
  }

  if (isEvenlyCargoes(startDate, endDate, numberOfRanges)) {
    return splitInHalfMonths(startDate, endDate, numberOfRanges)
  }

  return splitInEqualDaysRanges(startDate, endDate, numberOfRanges)
}

/*
 * Derives a date range based on the range param and one of these
 * three options: 'Early', 'Mid' and 'Late'
 *
 */
const strGeneralOptionToDateRange = (generalOptionStr, range) => {
  const ranges = splitInEqualDateRanges(range.from, range.to, 3, true)
  let optionNumber

  switch (generalOptionStr) {
    case 'Early':
      optionNumber = 0
      break
    case 'Mid':
      optionNumber = 1
      break
    case 'Late':
      optionNumber = 2
      break
    default:
      optionNumber = 0
  }

  return ranges[optionNumber]
}

/*
 * Derives a date range based on the range param and one of these
 * two options: 'First Half' and 'Second Half
 *
 */
const strHalfToDateRange = (halfStr, range) => {
  const ranges = splitInEqualDateRanges(range.from, range.to, 2, true)
  if (halfStr === 'First Half') {
    return ranges[0]
  }

  return ranges[1]
}

/*
 * Parses a year string to calendar date range.
 */
const strCalendarYearToDateRange = (yearStr) => {
  const from = moment(yearStr, 'YYYY')
  const to = moment(from).add(1, 'year').subtract(1, 'day')

  return { from, to }
}

/*
 * Parses a calendar year string to gas date range (OUT-SET).
 */
const strGasYearToDateRange = (yearStr) => {
  const from = moment(yearStr, 'YYYY').add(9, 'months')
  const to = moment(from).add(1, 'year').subtract(1, 'day')
  return { from, to }
}

/*
 * Parses a season string to date range
 */
const strSeasonToDateRange = (seasonStr, year) => {
  let seasonMonths

  if (seasonStr.toLowerCase().substring(0, 3) === 'sum') {
    seasonMonths = getSummerMonths()
    const from = moment(`${year} ${seasonMonths[0]}`, 'YYYY MMMM')
    const to = moment(
      `${year} ${seasonMonths[seasonMonths.length - 1]}`,
      'YYYY MMMM'
    )
      .add(1, 'month')
      .subtract(1, 'days')

    return { from, to }
  }

  seasonMonths = getWinterMonths()
  const from = moment(`${year} ${seasonMonths[0]}`, 'YYYY MMMM')
  const nextYear = parseInt(year) + 1
  const to = moment(
    `${nextYear} ${seasonMonths[seasonMonths.length - 1]}`,
    'YYYY MMMM'
  )
    .add(1, 'month')
    .subtract(1, 'days')
  return { from, to }
}

/*
 * Derives a date range based on the range param and one of these
 * two options: 'First Half' and 'Second Half
 *
 */
const strQuarterToDateRange = (quarterStr, year) => {
  const quarter = getQuarterNumber(quarterStr)
  const from = moment(year, 'YYYY').quarter(quarter)
  const to = moment(from).add(3, 'month').subtract(1, 'day')

  return { from, to }
}

/*
 * Parses a month and year string to date range.
 */
const strMonthToDateRange = (monthStr, year) => {
  const from = moment(`${year} ${monthStr}`, 'YYYY MMMM')
  const to = moment(from).add(1, 'month').subtract(1, 'day')

  return { from, to }
}

/*
 * Parses a week, month and year string to a date range.
 */
const strWeekToDateRange = (weekStr, month, year) => {
  const week = getWeekNumber(weekStr)
  const from = moment(`${year} ${month}`, `YYYY ${month > 9 ? 'MM' : 'M'}`).add(
    week - 1,
    'week'
  )
  const to = moment(from).add(6, 'day')
  return { from, to }
}

/*
 * Derives the date range based on the selected period on
 * information tab (step 2).
 */
export const getDeliveryPeriodRange = ({
  period,
  year,
  customFrom,
  customTo,
}) => {
  if (isCalendarYearDeliveryPeriod(period)) {
    return strCalendarYearToDateRange(year)
  }

  if (isGasYearDeliveryPeriod(period)) {
    return strGasYearToDateRange(year)
  }

  if (isSeasonPeriod(period)) {
    return strSeasonToDateRange(period, year)
  }

  if (isQuarterPeriod(period)) {
    return strQuarterToDateRange(period, year)
  }

  if (isMonthPeriod(period)) {
    return strMonthToDateRange(period, year)
  }

  if (isCustomDeliveryPeriod(period)) {
    return { from: moment(customFrom), to: moment(customTo).endOf('month') }
  }
}

/*
 * Derives the date ranges based on the delivery window option
 * selected in nominations tab (step 1).
 *
 * @baseRange: from and to dates
 * @deliveryWindowOption: option selected for delivery window, ie: Q1, Jan, First Half
 * @numberOfRanges: number of ranges to create
 * @optionalParams: can have an offset ({ units, number} applied to start date ) or spreadRange (if we want each range ending on the last day of each period)
 */
export const getDeliveryWindowRanges = (
  baseRange,
  deliveryWindowOption,
  numberOfRanges,
  optionalParams = {}
) => {
  if (deliveryWindowOption === SPECIFIC_DATES_DELIVERY_OPTION) {
    return splitInEqualDateRanges(
      baseRange.from,
      baseRange.to,
      numberOfRanges,
      optionalParams
    )
  }

  if (MONTHS.includes(deliveryWindowOption)) {
    const year = baseRange.from.year()
    const monthRange = strMonthToDateRange(deliveryWindowOption, year)
    return splitInEqualDateRanges(
      monthRange.from,
      monthRange.to,
      numberOfRanges,
      optionalParams
    )
  }

  if (QUARTERS.includes(deliveryWindowOption)) {
    const year = baseRange.from.year()
    const quarterRange = strQuarterToDateRange(deliveryWindowOption, year)
    return splitInEqualDateRanges(
      quarterRange.from,
      quarterRange.to,
      numberOfRanges,
      optionalParams
    )
  }

  if (SEASONS.includes(deliveryWindowOption.substring(0, 3))) {
    const year = baseRange.from.year()
    const seasonRange = strSeasonToDateRange(deliveryWindowOption, year)

    return splitInEqualDateRanges(
      seasonRange.from,
      seasonRange.to,
      numberOfRanges,
      optionalParams
    )
  }

  if (HALF_OPTIONS.includes(deliveryWindowOption)) {
    const halfRange = strHalfToDateRange(deliveryWindowOption, baseRange)
    return splitInEqualDateRanges(
      halfRange.from,
      halfRange.to,
      numberOfRanges,
      optionalParams
    )
  }

  if (GENERAL_OPTIONS.includes(deliveryWindowOption)) {
    const optionRange = strGeneralOptionToDateRange(
      deliveryWindowOption,
      baseRange
    )
    return splitInEqualDateRanges(
      optionRange.from,
      optionRange.to,
      numberOfRanges,
      optionalParams
    )
  }

  if (isWeekStr(deliveryWindowOption)) {
    const year = baseRange.from.year()
    const month = baseRange.from.month() + 1
    const weekRange = strWeekToDateRange(deliveryWindowOption, month, year)
    return splitInEqualDateRanges(
      weekRange.from,
      weekRange.to,
      numberOfRanges,
      optionalParams
    )
  }

  throw new Error(`Unknown delivery window option: ${deliveryWindowOption}`)
}

const PERIOD_ENABLE_FREQUENCY = [
  PERIOD.CUSTOM,
  PERIOD.GAS_YEAR,
  PERIOD.CAL_YEAR,
]

const isPerAnnumWithDate = ({ customFrom, customTo }) => {
  if (!customFrom || !customTo) {
    return false
  }
  const startDate = moment(customFrom)
  const endDate = moment(customTo)

  const doesPeriodSpanInclusiveYears =
    (endDate.month() + 1) % 12 === startDate.month() &&
    endDate.isAfter(startDate)
  return doesPeriodSpanInclusiveYears
}

export const getFrequencyOptions = (
  period = PERIOD.CUSTOM,
  { customFrom = null, customTo = null }
) => {
  const frequencyOptions = cloneDeep(DELIVERY_FREQUENCY_TYPE_OPTIONS)
  const perAnnum = frequencyOptions.find(
    (item) => item.value === DELIVERY_FREQUENCY_TYPE.PER_ANNUM
  )

  perAnnum.disabled = !PERIOD_ENABLE_FREQUENCY.includes(period)

  if (period === PERIOD.CUSTOM) {
    perAnnum.disabled = !isPerAnnumWithDate({ customFrom, customTo })
  }

  return frequencyOptions
}

export const getDeliveryPeriodLabel = ({
  period,
  customFrom,
  customTo,
  year,
}) => {
  const yearStr = `${year}`
  switch (period) {
    case PERIOD.CUSTOM:
      return `${moment(customFrom).format('MMM-YY')} ${moment(customTo).format(
        'MMM-YY'
      )}`
    case PERIOD.SUMMER:
      return `Sum-${yearStr.slice(2)}`
    case PERIOD.WINTER:
      return `Win-${yearStr.slice(2)}`
    case PERIOD.CAL_YEAR:
      return `CAL-${year}`
    case PERIOD.GAS_YEAR:
      return `GY-${year}`
    default:
      return `${capitalize(period).slice(0, 3)}-${yearStr.slice(2)}`
  }
}
