import { lazy, object, string, array, number, mixed, bool } from 'yup'
import moment from 'moment'

import {
  CARGO_SIZE_TYPE,
  DELIVERY_VOLUME_TYPE,
  PERSON_CLIENT_TYPE,
  QUALITY_TYPE,
  STAKEHOLDER_TYPE,
} from 'emsurge-selectors/constants'
import { getTimeFrameSchema } from './FormSchemaHelpers'
import { PERIOD } from 'model/order/constants/delivery'

const ALTERNATIVE_SCHEMA = getTimeFrameSchema()
const DELIVERY_WINDOW_ALTERNATIVE_SCHEMA = getTimeFrameSchema({
  skipToBeNominated: true,
}).concat(
  object({
    name: number().typeError('Required').required('Required'),
  })
)

const nominationRulesSchema = object({
  deliveryWindow: object({
    alternatives: array().of(DELIVERY_WINDOW_ALTERNATIVE_SCHEMA),
  }),
  loadPort: object({
    base: ALTERNATIVE_SCHEMA,
    alternatives: array().of(ALTERNATIVE_SCHEMA),
    adgas: bool(),
  }),
  dischargePort: object({
    base: ALTERNATIVE_SCHEMA,
    alternatives: array().of(ALTERNATIVE_SCHEMA),
  }),
  ship: object({
    base: ALTERNATIVE_SCHEMA,
    alternatives: array().of(ALTERNATIVE_SCHEMA),
  }),
  other: object({
    alternatives: array().of(ALTERNATIVE_SCHEMA),
  }),
})

const numberOrX = string().matches(/^\d+$|^\d+\.\d+$|^x$/i, {
  excludeEmptyString: true,
  message: 'Value must be a number or X',
})
const priceShape = object({
  floatingInfo: object({
    amount: numberOrX,
  }).nullable(),
  fixedInfo: object({
    value: numberOrX,
  }).nullable(),
  hybridInfo: array()
    .of(
      object({
        amount: numberOrX,
      })
    )
    .nullable(),
})

const companyShapeByClientType = {
  [PERSON_CLIENT_TYPE.TRADER]: object().when('behalfOf', (behalfOf, schema) => {
    if ([STAKEHOLDER_TYPE.ON_SYSTEM].includes(behalfOf)) {
      return schema.shape({
        entityId: string().label('Entity').required(),
      })
    }
  }),
  [PERSON_CLIENT_TYPE.BROKER]: object().when('behalfOf', (behalfOf, schema) => {
    if ([STAKEHOLDER_TYPE.ON_SYSTEM].includes(behalfOf)) {
      return schema.shape({
        companyId: string().required(),
        entityId: string().required(),
        traderId: string().required(),
      })
    }
  }),
}

export const getSchema = (user, isCreatingTrade) =>
  lazy((values) =>
    object({
      behalfOf: string().oneOf([
        STAKEHOLDER_TYPE.OFF_SYSTEM,
        STAKEHOLDER_TYPE.ON_SYSTEM,
      ]),
      thirdParty: object().when('behalfOf', (behalfOf, schema) => {
        if ([STAKEHOLDER_TYPE.OFF_SYSTEM].includes(behalfOf)) {
          return schema.shape({
            entityName: string().label('Entity name').required().min(1),
            companyName: string().label('Company name').required().min(2),
          })
        }
        return schema
      }),
      delivery: object({
        customFrom: mixed().when(
          ['period', 'customTo'],
          (period, customTo, schema) => {
            if (period !== PERIOD.CUSTOM) {
              return schema
            }
            return string().test(
              'customFrom',
              'Date must be the same or before end date',
              (value) => moment(value).isSameOrBefore(moment(customTo))
            )
          }
        ),
      }),
      price: object({
        basic: priceShape,
        reserve: priceShape,
        contract: priceShape,
      }),
      volume: object({
        max: mixed().when('type', (type, schema) => {
          if (type !== DELIVERY_VOLUME_TYPE.RANGE) {
            return schema
          }
          return number()
            .moreThan(values.volume.min, 'Invalid range')
            .typeError('Invalid range')
        }),
        min: mixed().when('type', (type) => {
          const minimum = number()
            .min(1, 'Value must be at least ${min}') // eslint-disable-line no-template-curly-in-string
            .typeError('Invalid number')

          if (type === DELIVERY_VOLUME_TYPE.RANGE) {
            return minimum
              .lessThan(values.volume.max, 'Invalid range')
              .typeError('Invalid range')
          }
          return minimum
        }),
      }),
      cargoSize: object({
        sizeMin: mixed().when('type', (type, schema) => {
          if (type === CARGO_SIZE_TYPE.SPECIFIC) {
            return number().typeError('Invalid number').required()
          }

          if (type !== CARGO_SIZE_TYPE.CUSTOM) {
            return schema
          }

          return number()
            .lessThan(values.cargoSize.sizeMax, 'Invalid range')
            .typeError('Invalid number')
            .required()
        }),
        sizeMax: mixed().when('type', (type, schema) => {
          if (type !== CARGO_SIZE_TYPE.CUSTOM) {
            return schema
          }

          return number()
            .moreThan(values.cargoSize.sizeMin, 'Invalid range')
            .typeError('Invalid number')
            .required()
        }),
      }),
      quality: object({
        customMin: mixed().when('type', (type, schema) => {
          if (type !== QUALITY_TYPE.CUSTOM) {
            return schema
          }

          return number()
            .lessThan(values.quality.customMax, 'Invalid range')
            .typeError('Invalid number')
            .required()
        }),
        customMax: mixed().when('type', (type, schema) => {
          if (type !== QUALITY_TYPE.CUSTOM) {
            return schema
          }

          return number()
            .moreThan(values.quality.customMin, 'Invalid range')
            .typeError('Invalid number')
            .required()
        }),
      }),
      nominationRules: nominationRulesSchema,
      myCompany: isCreatingTrade ? object().notRequired() : companyShapeByClientType[user.clientType],
      seller: isCreatingTrade ? partySchema.required() : object().notRequired(),
      buyer: isCreatingTrade ? partySchema.required() : object().notRequired()
    })
  )

  const partySchema = object({
    company: object({
      name : string().required(),
      id: string().notRequired()
    }),
    entity: object({
      name : string().required(),
      id: string().notRequired()
    }),
    person: object({
      name : string().required(),
      id: string().notRequired()
    }),
  })
