import styled from 'styled-components'

const Main = styled.main`
  position: relative;
  display: block;
  overflow: auto;
  max-width: 992px;
  margin: 0 auto;
  @media (min-width: 600px) {
    padding: ${({ theme }) => theme.spacing(4)}px;
  }
`

export default Main
