import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { CancelButton, ConfirmButton } from './Buttons'

const TEXT_DEFAULT = 'Are you sure?'
const TITLE_DEFAULT = 'Please confirm'

export const ConfirmActionDialog = ({
  onCancel,
  onConfirm,
  text,
  title,
  ...other
}) => (
  <Dialog onClose={onCancel} maxWidth="sm" {...other}>
    <DialogTitle>{title || TITLE_DEFAULT}</DialogTitle>
    <DialogContent>
      <DialogContentText style={{ fontWeight: 'bold' }}>
        {text || TEXT_DEFAULT}
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <CancelButton onClick={onCancel}>Cancel</CancelButton>
      <ConfirmButton data-testid="dialog-confirm-button" onClick={onConfirm}>
        Confirm
      </ConfirmButton>
    </DialogActions>
  </Dialog>
)

ConfirmActionDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
}
