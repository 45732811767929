import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { TRADING_TYPE } from 'emsurge-selectors/constants'

export const getTraderDetail = (type, termsheet) => {
  const { orders, ask, bid } = termsheet
  const orderAdditionalData = type === TRADING_TYPE.BID ? bid : ask
  const order = orders.find((order) => order.tradingType === type)
  let data
  if(order.thirdParty?.companyName)
  {
    data = { 
      companyName : orderAdditionalData?.companyName ?? order.thirdParty.companyName,
      entityName : orderAdditionalData?.entityName ?? order.thirdParty.entityName,
      personName : orderAdditionalData?.personName ?? order.thirdParty.contact,
      executionTrader : orderAdditionalData?.executionTrader || {},
      opsContact : orderAdditionalData?.opsContact || {},
    }
  }
  else
  {
    data = { 
      companyName : orderAdditionalData?.companyName ?? order.company.name, 
      entityName : orderAdditionalData?.entityName ?? order.entity.name,
      personName : orderAdditionalData?.personName ?? order.person.name,
      executionTrader : orderAdditionalData?.executionTrader || {},
      opsContact : orderAdditionalData?.opsContact || {},
    }
  }
  return data
}

const TraderView = ({ type, termsheet }) => {
  const info = getTraderDetail(type, termsheet)
  return (
    <>
      <Text variant="h6" gutterBottom paragraph>
        {type === TRADING_TYPE.BID ? 'Buyer' : 'Seller'}
      </Text>
      <Text variant="caption">Company (Brand)</Text>
      <Text variant="subtitle1" color="textSecondary" gutterBottom>
        {info.companyName}
      </Text>
      <Text variant="caption">Entity</Text>
      <Text variant="subtitle1" color="textSecondary" gutterBottom>
        {info.entityName}
      </Text>
      <Text variant="caption">User</Text>
      <Text variant="subtitle1" color="textSecondary" gutterBottom>
        {info.personName}
      </Text>
      <Text variant="caption">Execution Trader</Text>
      <Text variant="subtitle1" color="textSecondary" gutterBottom>
        {info.executionTrader.name || '--'}
      </Text>
      <Text variant="caption">Email</Text>
      <Text variant="subtitle1" color="textSecondary" gutterBottom>
        {info.executionTrader.email || '--'}
      </Text>
      <Text variant="caption">Phone Number</Text>
      <Text variant="subtitle1" color="textSecondary" gutterBottom>
        {info.executionTrader.phone || '--'}
      </Text>
      <Text variant="caption">Ops Contact</Text>
      <Text variant="subtitle1" color="textSecondary" gutterBottom>
        {info.opsContact.name || '--'}
      </Text>
      <Text variant="caption">Email</Text>
      <Text variant="subtitle1" color="textSecondary" gutterBottom>
        {info.opsContact.email || '--'}
      </Text>
      <Text variant="caption">Phone Number</Text>
      <Text variant="subtitle1" color="textSecondary" gutterBottom>
        {info.opsContact.phone || '--'}
      </Text>
    </>
  )
}
TraderView.propTypes = {
  type: PropTypes.string,
  termsheet: PropTypes.object,
}

export default TraderView
