const ACTIVITY_TYPE_ORDER = Object.freeze({
  ORDER_CREATED: 'order_created',
  ORDER_DELETED: 'order_deleted',
  ORDER_UPDATED: 'order_updated',
  ORDER_CLOSED: 'order_closed',
  ORDER_SHARED: 'order_shared',
})

const ACTIVITY_TYPE_COMMENT = Object.freeze({
  COMMENT_CREATED: 'comment_created',
  COMMENT_UPDATED: 'comment_updated',
})

const ACTIVITY_TYPE_NOTE = Object.freeze({
  NOTE_CREATED: 'note_created',
  NOTE_UPDATED: 'note_updated',
})

const ACTIVITY_TYPE_TERMSHEET = Object.freeze({
  TERMSHEET_CREATED: 'termsheet_created',
  TERMSHEET_UPDATED: 'termsheet_updated',
  TERMSHEET_DELETED: 'termsheet_deleted',
  TERMSHEET_CLOSED: 'termsheet_closed',
  TERMSHEET_TRADED: 'termsheet_traded',
  TERMSHEET_COUNTERPARTY_TRADED: 'termsheet_counterparty_traded',
})

const ACTIVITY_TYPE_BULLETIN = Object.freeze({
  BULLETIN_CREATED: 'bulletin_created',
  BULLETIN_UPDATED: 'bulletin_updated',
})

const ACTIVITY_TYPE = Object.freeze({
  ...ACTIVITY_TYPE_ORDER,
  ...ACTIVITY_TYPE_COMMENT,
  ...ACTIVITY_TYPE_NOTE,
  ...ACTIVITY_TYPE_TERMSHEET,
  ...ACTIVITY_TYPE_BULLETIN,
})

const ACTIVITY_EVENT = Object.freeze({
  CREATE: 1,
  DELETE: 2,
  UPDATE: 3,
  CLOSE: 4,
  VIEW: 5,
  TRADE: 6,
  COUNTERPARTY_TRADE: 7,
})

const ACTIVITY_ACTION_TEXT = Object.freeze({
  CREATED: 'New',
  DELETED: 'Delete',
  UPDATED: 'Edit',
  SHARED: 'Share',
  CLOSED: 'Archive',
  VIEW: 'View',
  TRADE: 'Trade',
  MARK_AS_TRADED: 'Mark as Traded',
  COUNTERPARTY_TRADED: 'Counterparty Trade',
  [ACTIVITY_EVENT.CREATE]: 'New',
  [ACTIVITY_EVENT.DELETE]: 'Delete',
  [ACTIVITY_EVENT.UPDATE]: 'Edit',
  [ACTIVITY_EVENT.CLOSE]: 'Archive',
  [ACTIVITY_EVENT.VIEW]: 'View',
  [ACTIVITY_EVENT.TRADE]: 'Trade',
  [ACTIVITY_EVENT.COUNTERPARTY_TRADE]: 'Counterparty Trade',
})

module.exports = {
  ACTIVITY_ACTION_TEXT,
  ACTIVITY_EVENT,
  ACTIVITY_TYPE_BULLETIN,
  ACTIVITY_TYPE_COMMENT,
  ACTIVITY_TYPE_NOTE,
  ACTIVITY_TYPE_ORDER,
  ACTIVITY_TYPE_TERMSHEET,
  ACTIVITY_TYPE,
}
