export const PRIORITIES = {
  1: 'Memo',
  2: 'Low',
  3: 'Medium',
  4: 'High',
}

export const NOTES_PRIORITIES_COLORS = {
  memo: '#1EB2FF',
  low: '#FFF900',
  medium: '#FF7400',
  high: '#FE3851',
}
