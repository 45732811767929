import moment from 'moment'
import { isEmpty, get } from 'lodash'
import { $T } from 'emsurge-selectors'
import {
  NOMINATIONS_INITIAL_VALUES,
  NOMINATION_PORT_DEFAULT_VALUES,
  isFixedDateTimeFrameValue,
} from 'screens/orderCreate/containers/NominationsInitialValues'

/* :: object -> string */
const getTimezone = (nominationRules) => nominationRules.deliveryWindow.timezone

/* :: (object, string) -> object */
const getNominationValue = (nomination, timezone) => {
  const party = isEmpty(nomination.party) ? 'tba' : nomination.party
  const timeframe = isEmpty(nomination.timeframe)
    ? 'days_ahead'
    : nomination.timeframe

  if (!isFixedDateTimeFrameValue(nomination)) {
    return {
      ...nomination,
      timeframeValueTime: NOMINATION_PORT_DEFAULT_VALUES.timeframeValueTime,
      timeframeValue:
        nomination?.timeframeValue ||
        NOMINATION_PORT_DEFAULT_VALUES.timeframeValue,
      timeframe,
      party,
    }
  }

  const [timeframeValue, timeframeValueTime] = moment(nomination.timeframeValue)
    .add(timezone, 'hours')
    .format('YYYY-MM-DD HH:mm')
    .split(' ')

  return { ...nomination, timeframe, timeframeValue, timeframeValueTime, party }
}

/* :: object -> object */
const getDeliveryWindow = (nominationRules) => {
  const deliveryWindow = {
    ...NOMINATIONS_INITIAL_VALUES.deliveryWindow,
    ...nominationRules.deliveryWindow,
    deliveryType: $T.deliveryWindow.type.get(nominationRules),
  }

  const timezone = getTimezone(nominationRules)
  deliveryWindow.alternatives = (deliveryWindow.alternatives || []).map(
    (nomination) => getNominationValue(nomination, timezone)
  )

  return deliveryWindow
}

/* :: object -> object */
const getLoadPort = (nominationRules) => {
  const timezone = getTimezone(nominationRules)
  const base = getNominationValue(
    get(nominationRules, 'loadPort.base', {}),
    timezone
  )
  const alternatives = get(nominationRules, 'loadPort.alternatives', []).map(
    (nomination) => getNominationValue(nomination, timezone)
  )
  const adgas = get(nominationRules, 'loadPort.adgas', false)

  return { base, alternatives, adgas }
}

/* :: object -> object */
const getDischargePort = (nominationRules) => {
  const timezone = getTimezone(nominationRules)
  const base = getNominationValue(
    get(nominationRules, 'dischargePort.base', {}),
    timezone
  )
  const alternatives = get(
    nominationRules,
    'dischargePort.alternatives',
    []
  ).map((nomination) => getNominationValue(nomination, timezone))
  const na = isEmpty(nominationRules.dischargePort)

  return { base, alternatives, na }
}

/* :: object -> object */
const getShip = (nominationRules) => {
  const timezone = getTimezone(nominationRules)
  const base = getNominationValue(
    {
      ...get(nominationRules, 'ship.base', {}),
      min: get(nominationRules, 'ship.base.size.min'),
      max: get(nominationRules, 'ship.base.size.max'),
      size: get(nominationRules, 'ship.base.size.value'),
    },
    timezone
  )
  const alternatives = get(nominationRules, 'ship.alternatives', []).map(
    (alternative) => ({
      ...getNominationValue(alternative, timezone),
      min: get(alternative, 'size.min'),
      max: get(alternative, 'size.max'),
      size: get(alternative, 'size.value'),
      vesselType: get(alternative, 'vesselType'),
    })
  )

  return { base, alternatives }
}

/* :: object -> object */
const getOther = (nominationRules) => {
  const timezone = getTimezone(nominationRules)
  const alternatives = get(nominationRules, 'other.alternatives', []).map(
    (nomination) => getNominationValue(nomination, timezone)
  )

  return { alternatives }
}

/* :: object -> object */
export const getNominationInitialValues = ({ nominationRules }) => {
  const initialValues = {
    nominationRules: {
      deliveryWindow: getDeliveryWindow(nominationRules),
      loadPort: getLoadPort(nominationRules),
      dischargePort: getDischargePort(nominationRules),
      ship: getShip(nominationRules),
      other: getOther(nominationRules),
      laytime: nominationRules.laytime || '',
      boilOff: nominationRules.boilOff || '',
      demurage: nominationRules.demurage || '',
      fsruFsu: nominationRules.fsruFsu || '',
      demurageNote: nominationRules.demurageNote || '',
    },
  }

  return initialValues
}
