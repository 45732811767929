import {
  ORDER_STATUS,
  ORDER_STATUS_TEXT,
  VALIDITY_TYPE,
  $OC,
  NEW_TRADE
} from 'emsurge-selectors'
import { isNumber } from 'lodash'
import moment from 'moment'
import React from 'react'

const getValidityLabel = (value) =>
  value?.validity?.until === VALIDITY_TYPE.INDICATIVE
    ? ORDER_STATUS_TEXT[ORDER_STATUS.WITHHELD]
    : ORDER_STATUS_TEXT[ORDER_STATUS.LIVE]

export const getStatusLabel = (value = ORDER_STATUS.PENDING, isCreatingTrade) => {
  if(isCreatingTrade) {
    return NEW_TRADE
  }
  const status = $OC.status.get(value)
  if ([ORDER_STATUS.PENDING, ORDER_STATUS.CLOSED].includes(status)) {
    return ORDER_STATUS_TEXT[ORDER_STATUS.PENDING]
  }
  return getValidityLabel(value)
}

export const computeVintageYear = () => {
  const DATE_FORMAT = 'YYYY-MM-DD'
  const now = moment()
  const currentYear = now.year()
  const limitDate = moment(`${currentYear}-07-01`)
  const currentDate = moment(now.format(DATE_FORMAT))
  const diffYears = currentDate - limitDate < 0 ? 2 : 1
  return currentYear - diffYears
}

export const computeVolumeInputNumber = (amount) => {
  const regex = /,/g
  const numberAmount = Number(String(amount).replace(regex, ''))
  const invalidNumber =
    !isNumber(numberAmount) || isNaN(numberAmount) || amount === ''
  return invalidNumber ? '' : numberAmount
}

export const getDefaultVolumeInputNumber = (amount) => {
  const volumeAmount = computeVolumeInputNumber(amount)
  return volumeAmount <= 10000 ? volumeAmount / 2 : 10000
}

export const useIsReactInDevelomentMode = () => { 
  return '_self' in React.createElement('div');
}