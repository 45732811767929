import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'formik'
import {
  TERM_SPOT_OFFSET_PREFIX,
  TERM_SPOT_VINTAGE_FIXED_PREFIX,
  TERM_FIXED_ROLLING_PREFIX,
  TERM_FIXED_ROLLING_KEYS,
} from 'emsurge-selectors'
import { get } from 'lodash'
import { PeriodSelect } from '../components/PeriodSelect'
import { TermFixedRolling } from '../components/TermFixedRolling'
import { RollingRange } from '../components/RollingRange'
import { ProjectInformation } from '../components/ProjectInformation'
import { TitleModalSection } from 'screens/orderCreateCommon/components/TitleModalSection'

const Wrapper = styled.div`
  margin-top: 8px;
`

const _TermVintageTerm = ({ formik: { values } }) => {
  const selectedVintage =
    get(values, TERM_FIXED_ROLLING_PREFIX) || TERM_FIXED_ROLLING_KEYS.FIXED
  const [vintage, setVintage] = useState(selectedVintage)

  useEffect(() => {
    setVintage(get(values, TERM_FIXED_ROLLING_PREFIX))
  }, [values.termVintage.fixedRolling])

  return (
    <>
      <TitleModalSection>
        Offset Title Transfer/Retirement Period
      </TitleModalSection>
      <PeriodSelect
        fieldPrefix={TERM_SPOT_OFFSET_PREFIX}
        displaySelectPeriod={false}
      />
      <TitleModalSection>Vintage</TitleModalSection>
      <TermFixedRolling></TermFixedRolling>
      <Wrapper>
        {vintage === TERM_FIXED_ROLLING_KEYS.FIXED && (
          <PeriodSelect
            fieldPrefix={TERM_SPOT_VINTAGE_FIXED_PREFIX}
            infoTitle="Vintage"
            hasYMinus1={true}
          />
        )}
        {vintage === TERM_FIXED_ROLLING_KEYS.ROLLING && <RollingRange />}
      </Wrapper>
      <TitleModalSection>Project Information</TitleModalSection>
      <ProjectInformation />
    </>
  )
}

export const TermVintageTerm = connect(_TermVintageTerm)
