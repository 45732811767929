import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import MuiTextField from '@material-ui/core/TextField'
import Text from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Search from '@material-ui/icons/Search'
import { Block } from './Block'
import { BulletinsActivity } from './BulletinsActivity'
import { getFormattedDateWithOffset } from 'utils/dates'
import { Bulletin } from 'screens/bulletinView/components/Bulletin'

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: '100%',
    padding: '9px 2px 6px 2px',
  },
  container: {
    height: '860px',
  },
  paper: {
    padding: theme.spacing(3, 4),
  },
  list: {
    width: '350px',
  },
}))

export const _BulletinsTable = ({ bulletins, match }) => {
  const [filter, setFilter] = useState('')
  const classes = useStyles()

  if (!bulletins.length) {
    return (
      <Paper>
        <Text style={{ padding: 20 }}>There are no bulletins</Text>
      </Paper>
    )
  }

  if (match.isExact) {
    return <Redirect to={`${match.path}/${bulletins[0].id}`} />
  }

  const handleChange = (e) => {
    const value = e.target.value.trim().toLowerCase()

    setFilter(value)
  }

  const filteredBulletins = !filter
    ? bulletins
    : bulletins.filter(
        ({ createdAt, messageType, title, message, author, company }) =>
          [
            getFormattedDateWithOffset(createdAt),
            messageType,
            title,
            message,
            author.name,
            author.surname,
            company.name,
          ].some((field = '') =>
            field.toLowerCase().includes(filter.toLowerCase())
          )
      )

  return (
    <Route
      exact
      path={`${match.path}/:id`}
      render={({ match, history }) => {
        const { id: routeId } = match.params
        const currentBulletin =
          bulletins.find(({ id }) => id === routeId) || bulletins[0]

        return (
          <Grid classes={{ root: classes.container }} container spacing={2}>
            <Grid item classes={{ root: classes.list }}>
              <Block>
                <MuiTextField
                  className={classes.searchField}
                  onChange={handleChange}
                  InputProps={{
                    disableUnderline: true,
                    placeholder: 'Search Bulletins',
                    endAdornment: <Search />,
                  }}
                />
                <BulletinsActivity
                  bulletins={filteredBulletins}
                  active={currentBulletin}
                  onActiveChange={({ id }) => history.push(id)}
                />
              </Block>
            </Grid>

            <Grid item xs>
              <Paper classes={{ root: classes.paper }}>
                <Bulletin bulletin={currentBulletin} />
              </Paper>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

_BulletinsTable.propTypes = {
  bulletins: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
}

export const BulletinsTable = withRouter(_BulletinsTable)
