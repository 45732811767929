import MuiButton from '@material-ui/core/Button'
import { withProps } from 'recompose'
import styled from 'styled-components'
import SubmitButton from 'containers/formikSubmitButton/FormikSubmitButton'

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-left: auto;
`
const NextButton = withProps({ children: 'Next' })(StyledSubmitButton)

const StyledMuiButton = styled(MuiButton)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-left: auto;
`
const BackButton = withProps({
  variant: 'text',
  color: 'primary',
  children: 'Back',
})(StyledMuiButton)

const FinishButton = withProps({ children: 'Submit' })(StyledSubmitButton)

export { NextButton, BackButton, FinishButton }
