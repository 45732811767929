import { $O, TRADING_TYPE } from 'emsurge-selectors'
import { getCompanyCodeStyle } from 'components/CompanyCode/CompanyCodeHelpers'

export const getItemStyleProps = (order, user, palette) => {
  const { textColor } = getCompanyCodeStyle({ user, order, palette })
  const isBid = $O.tradingType.get(order) === TRADING_TYPE.BID
  const border = `2px solid ${textColor}`
  const backgroundColor = `${isBid ? textColor : 'rgba(0, 0, 0, 0)'}`
  const color = textColor
  const borderRadius = '5px'

  return {
    borderRadius,
    border,
    backgroundColor,
    color,
  }
}
