const COMPANY_PRIMARY_CONTACT_INITIAL_VALUES = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  jobTitle: '',
  entity: '',
  addressFirstLine: '',
  addressSecondLine: '',
  postcode: '',
  city: '',
  country: '',
}

export { COMPANY_PRIMARY_CONTACT_INITIAL_VALUES }
