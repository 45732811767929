import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isObject } from 'lodash'
import { compose } from 'recompose'
import ShareIcon from '@material-ui/icons/Share'
import Menu from '@material-ui/core/Menu'
import Fab from '@material-ui/core/Fab'
import MenuItem from '@material-ui/core/MenuItem'
import { useSnackbar } from 'notistack'
import { PERSON_PERMISSION } from 'emsurge-selectors/constants'
import withParams from 'utils/withRouteParams'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import Tooltip from 'components/tooltip/Tooltip'
import { writeToClipboard } from 'utils/clipboard'
import { useUserHasPermission } from 'permissions'

const { protocol, host } = window.location
const SHARE_ORDER_URL = `${protocol}//${host}/orders/share/`
const STYLE_UPPERCASE = { textTransform: 'uppercase' }

const copyToClipboardOrderShareLink = async (token) => {
  const textToCopy = `${SHARE_ORDER_URL}${token}`

  return writeToClipboard(
    textToCopy,
    'Order link copied to clipboard.',
    'Please enable the copy to clipboard function from this website on your device.'
  )
}

const CreateShareLinkMenuButton = ({ closeMenu, orderId, setState, style }) => {
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()

  const createOrderShareLink = async () => {
    setState({
      isCancelled: false,
      loading: true,
    })

    try {
      const {
        data: { shareToken },
      } = await api.post(`/orders/${orderId}/share`, {})
      const response = await copyToClipboardOrderShareLink(shareToken)

      enqueueSnackbar(response.message, {
        variant: response.error ? 'warning' : 'success',
      })

      setState({ token: shareToken })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Error generating order link. Please try again.',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      setState({ loading: false })
      closeMenu()
    }
  }

  return (
    <MenuItem
      data-testid="share-order-link-with-terms-button"
      onClick={createOrderShareLink}
      style={style}
    >
      Link with terms
    </MenuItem>
  )
}

CreateShareLinkMenuButton.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  style: PropTypes.object,
}

const ManageShareLinkMenuButton = ({
  closeMenu,
  orderId,
  setState,
  style,
  token,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()

  const handleCopy = async () => {
    const response = await copyToClipboardOrderShareLink(token)

    enqueueSnackbar(response.message, {
      variant: response.error ? 'error' : 'success',
    })

    closeMenu()
  }

  const handleCancel = async () => {
    setState({ loading: true })

    try {
      await api.delete(`/orders/${orderId}/share`)

      enqueueSnackbar('Order link canceled successfully.', {
        variant: 'success',
      })

      setState({
        token: null,
        isCancelled: true,
      })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Error canceling order link. Please try again.',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      setState({ loading: false })
      closeMenu()
    }
  }

  return (
    <>
      <MenuItem
        data-testid="share-order-copy-order-link-button"
        onClick={handleCopy}
        style={style}
      >
        Copy order link
      </MenuItem>
      <MenuItem
        data-testid="share-order-cancel-order-link-button"
        onClick={handleCancel}
        style={style}
      >
        Cancel order link
      </MenuItem>
    </>
  )
}

ManageShareLinkMenuButton.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  style: PropTypes.object,
}

const OrderShareButton = ({ style, order }) => {
  const INITIAL_STATE = {
    loading: false,
    token: null,
    isCancelled: false,
  }
  const userCanShareOrder = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.TRADE,
    forceSameCompany: true,
  })
  const [state, setState] = useState(INITIAL_STATE)
  const [buttonElem, setElement] = useState(null)
  const isOpen = isObject(buttonElem)

  const disabled = state.loading
  const token = order.shareToken || state.token
  const displayGenerateBtn = state.isCancelled || isEmpty(token)

  const closeMenu = () => setElement(null)

  const setStateHandler = (newState) => {
    setState({
      ...state,
      ...newState,
    })
  }

  if (!userCanShareOrder || order.isTemplate) {
    return null
  }

  return (
    <>
      <Tooltip title="Share order">
        <Fab
          data-testid="share-order-button"
          size="small"
          onClick={(e) => setElement(e.currentTarget)}
          style={style}
        >
          <ShareIcon />
        </Fab>
      </Tooltip>
      <Menu anchorEl={buttonElem} open={isOpen} onClose={closeMenu}>
        <span>
          {displayGenerateBtn && (
            <CreateShareLinkMenuButton
              closeMenu={closeMenu}
              disabled={disabled}
              orderId={order.id}
              setState={setStateHandler}
              style={STYLE_UPPERCASE}
            />
          )}
          {!displayGenerateBtn && (
            <ManageShareLinkMenuButton
              closeMenu={closeMenu}
              orderId={order.id}
              setState={setStateHandler}
              style={STYLE_UPPERCASE}
              token={token}
            />
          )}
        </span>
      </Menu>
    </>
  )
}

OrderShareButton.propTypes = {
  order: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export default compose(withParams)(OrderShareButton)
