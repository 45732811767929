import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import {
  getProjectSpecificationSectoralScopePrefix,
  getProjectSpecificationStorageTypePrefix,
  getProjectSpecificationReferenceNumberPrefix,
  getProjectSpecificationProjectNamePrefix,
  getProjectSpecificationProjectURLPrefix,
  getProjectSpecificationProjectStartDatePrefix,
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS,
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS,
  PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS,
  PROJECT_SPECIFICATION_STORAGE_TYPE_OPTIONS,
  CLASSIFICATIONS_SECOND_PREFIX,
  CLASSIFICATIONS
} from 'emsurge-selectors'
import { get } from 'lodash'
import { Dropdown } from './Dropdown'
import TextField from 'containers/formikTextField/FormikTextField'
import { DateField } from 'components/DateTime/DateTime.fields'

const buildOptionLabel = (option, fullOptions) => ({
  key: option,
  value: option,
  label: fullOptions[option],
})

const _ProjectSeller = ({
  formik: { setFieldValue, values },
  projectNumber,
}) => {
  const SECTORAL_SCOPE_PATH =
    getProjectSpecificationSectoralScopePrefix(projectNumber)
  const STORAGE_TYPE_PATH =
    getProjectSpecificationStorageTypePrefix(projectNumber)
  const REFERENCE_NUMBER_PATH =
    getProjectSpecificationReferenceNumberPrefix(projectNumber)
  const PROJECT_NAME_PATH =
    getProjectSpecificationProjectNamePrefix(projectNumber)
  const PROJECT_URL_PATH =
    getProjectSpecificationProjectURLPrefix(projectNumber)
  const PROJECT_START_DATE_PATH =
    getProjectSpecificationProjectStartDatePrefix(projectNumber)
  const [date, setDate] = useState(get(values, PROJECT_START_DATE_PATH) || '')

  const isAvoidanceClassification = (values) => get(values, CLASSIFICATIONS_SECOND_PREFIX) === CLASSIFICATIONS.AVOI.key
  const [isStorageEnabled, setIsStorageEnabled] = useState(!isAvoidanceClassification(values))

  useEffect(() => {
    const currentDate = get(values, PROJECT_START_DATE_PATH)
    if (currentDate) {
      setDate(currentDate)
    }
    setIsStorageEnabled(!isAvoidanceClassification(values))
  }, [values])

  const sort = (a, b) =>
    a.value.localeCompare(b.value, 'en', { sensitivity: 'base' })
  const sortOptions = (options) => options.sort(sort)

  const getSortedOptions = (optionsKeys, optionsValues, sort = true) => {
    const options = Object.keys(optionsKeys).map((option) =>
      buildOptionLabel(option, optionsValues)
    )
    return sort ? sortOptions(options) : options
  }

  const sectoralScopeOptions = getSortedOptions(
    PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS,
    PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS
  )

  const storageTypeOptions = getSortedOptions(
    PROJECT_SPECIFICATION_STORAGE_TYPE_KEYS,
    PROJECT_SPECIFICATION_STORAGE_TYPE_OPTIONS,
    false
  )

  const onChangeDate = (date) => {
    setFieldValue(PROJECT_START_DATE_PATH, date)
    setDate(date)
  }

  return (
    <Grid container spacing={3} style={{ paddingTop: '8px' }}>
      <Grid item xs={12}>
        <TextField
          inputProps={{ 'data-testid': 'project-reference-number-field' }}
          name={REFERENCE_NUMBER_PATH}
          label={'Registry Project Reference Number'}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          inputProps={{ 'data-testid': 'project-name-field' }}
          name={PROJECT_NAME_PATH}
          label={'Project Name'}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          inputProps={{ 'data-testid': 'project-url-field' }}
          name={PROJECT_URL_PATH}
          label={'Project URL'}
          fullWidth
        />
      </Grid>
      <Grid data-testid="project-sectoral-scope-dropdown" item xs={12}>
        <Dropdown
          path={SECTORAL_SCOPE_PATH}
          title={'Sectoral Scope'}
          listOptions={sectoralScopeOptions}
        />
      </Grid>
      <Grid data-testid="project-storage-type-dropdown" item xs={12}>
        <Dropdown
          path={STORAGE_TYPE_PATH}
          title={'Storage Type'}
          listOptions={storageTypeOptions}
          isDisabled={!isStorageEnabled}
        />
      </Grid>
      <Grid item xs={12}>
        <DateField
          label={
            'Crediting period start date specified at time of project registration'
          }
          onChange={(value) => onChangeDate(value)}
          value={date}
        />
      </Grid>
    </Grid>
  )
}

_ProjectSeller.propTypes = {
  projectNumber: PropTypes.number.isRequired,
}

export const ProjectSeller = connect(_ProjectSeller)
