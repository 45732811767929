import React from 'react'
import Text from '@material-ui/core/Typography'
import { Header } from 'screens/privacyPolicy/PrivacyPolicy'
import { OrderedList, ListItem, List } from 'screens/privacyPolicy/List'

const TermsConditions = () => (
  <>
    <Header>
      <Text variant="h1" align="center">
        Emsurge Terms of Use
      </Text>
    </Header>

    <OrderedList style={{ paddingLeft: 0, marginBottom: 240 }}>
      {/* 1 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Introduction to the emsurge network
        </Text>

        <OrderedList>
          {/* 1.1 */}
          <ListItem gutterBottom={3}>
            We provide our platform online at www.emsurge.com to let
            inter-dealer brokers and traders connect with each other (the
            “Emsurge Network“). When providing the Emsurge Network we want to
            ensure that you and other users use it as we would wish and as helps
            us keep it available for you. To assist with this we ask for these
            terms of use (“Terms”), and our Privacy Policy (set out at
            www.emsurge.com (the “Privacy Policy”), to be accepted and agreed.
          </ListItem>

          {/* 1.2 */}
          <ListItem gutterBottom={3}>
            In these Terms “Emsurge”, “we”, “us” and “our” mean Emsurge Limited,
            a company incorporated in England and Wales under company number
            11236705 and VAT number GB 308 1209 36 and whose registered office
            is 1 Garrick Close, Hersham, Walton-On-Thames, KT12 5NY. To contact
            us, please email m@emsurge.com.
          </ListItem>

          {/* 1.3 */}
          <ListItem>
            <Text gutterBottom>
              For your convenience we have split these Terms into four parts:
            </Text>

            <OrderedList alpha>
              <ListItem ordered={false} gutterBottom>
                Key Points which you should read first;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                Part 1 addresses who the parties to these Terms are, and how
                these Terms work;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                Part 2 sets out the features and main operational aspects of the
                Emsurge Network and how they relate to your legal rights; and
              </ListItem>
              <ListItem ordered={false}>
                Part 3 contains other legal terms which form part of the Terms.
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>
      </ListItem>

      <Text variant="h1" gutterBottom>
        Key Points
      </Text>

      {/* 2 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Roles
        </Text>

        <OrderedList>
          {/* 2.1 */}
          <ListItem>
            <Text gutterBottom>
              The various roles on the Emsurge Network are as follows:
            </Text>

            <OrderedList alpha>
              <ListItem ordered={false} gutterBottom>
                “Customer” means the organisation that agrees to these terms
                through using the Emsurge Network, and / or through entering
                into a membership form with Emsurge;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                “Administrator” means the User which is the controller of a
                Customer account, created for administration purposes, including
                the management of the Customer’s Users;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                “Trader” means a User which has the authorisation to present
                Orders and respond to Orders on the Emsurge Network;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                ”Broker” means a Customer which is a broker and is therefore
                able to request to Emsurge for non-Broker Users to have access
                to its “Broker Emsurge account”; and
              </ListItem>
              <ListItem ordered={false}>
                “User” means an individual which has a login to the Emsurge
                Network, in association with a Customer.
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 3 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Nature of the Platform
        </Text>

        <OrderedList>
          {/* 3.1 */}
          <ListItem gutterBottom={3}>
            Emsurge is responsible for running the infrastructure of the Emsurge
            Network in accordance with these Terms. Other Users however create
            the content on the Emsurge Network including their Orders. Users are
            responsible for the content which they upload to the Emsurge
            Network, and not Emsurge. Where there are disputes between Users
            regarding content on or submissions to the Emsurge Network, this
            should be resolved between the relevant Users.
          </ListItem>

          {/* 3.2 */}
          <ListItem gutterBottom={3}>
            Please note that, when a User uses the Emsurge Network to enter into
            arrangements with Customers or third parties, Emsurge has no control
            or responsibility to such arrangements and Emsurge accepts no
            liability whatsoever in respect of such arrangement, (including in
            respect of any goods or services provided by or to Users and/or
            Customers as a result of such an arrangement).
          </ListItem>

          {/* 3.3 */}
          <ListItem>
            The use of the Emsurge Network is at the users own risk. The Emsurge
            Network is provided on an “as is” and “as available” basis. To the
            fullest extent possible under applicable law, Emsurge gives no
            representation, warranty, condition or other term, whether express
            or implied or statutory, as to the quality, content and availability
            or fitness for a specific purpose of the Emsurge Network or the
            content on it.
          </ListItem>
        </OrderedList>
      </ListItem>

      <Text variant="h1" gutterBottom>
        Part 1: These Terms
      </Text>

      {/* 4 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Agreeing to these Terms
        </Text>

        <OrderedList>
          {/* 4.1 */}
          <ListItem gutterBottom={3}>
            These Terms are entered into by Emsurge and the Customer. Emsurge
            provides the Emsurge Network in consideration of your Content and
            Order submissions to the Emsurge Network, and of any applicable
            fees.
          </ListItem>

          {/* 4.2 */}
          <ListItem gutterBottom={3}>
            Where these Terms set out requirements for Users, it is the
            responsibility of each Customer to ensure that it, and all of its
            Users, comply with such requirements.
          </ListItem>

          {/* 4.3 */}
          <ListItem gutterBottom={3}>
            A Customer or User should not agree separate terms of use of the
            Emsurge Network with a Broker that conflict with these Terms. Any
            agreement between a Customer or User, and a third party, shall not
            release such Customer or User from their obligations under these
            Terms.
          </ListItem>

          {/* 4.4 */}
          <ListItem gutterBottom={3}>
            In accepting and agreeing to these Terms the Customer undertakes,
            represents and warrants that any registration information it or its
            Users submit to Emsurge is true, accurate and complete and that it
            will be maintained at all times.
          </ListItem>

          {/* 4.5 */}
          <ListItem gutterBottom={3}>
            The Customer should not permit any of its Users to access or use the
            Emsurge Network if it does not agree to any of these Terms. Users
            may then use the Emsurge Network in accordance with these Terms
            only.
          </ListItem>

          {/* 4.6 */}
          <ListItem>
            Emsurge reserves its sole discretion as to the Users, and in
            particular Traders, which it allows to participate in the Emsurge
            Network.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 5 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Variations
        </Text>

        <OrderedList>
          {/* 5.1 */}
          <ListItem>
            From time to time at our own discretion Emsurge may vary these
            Terms. All such variations shall become effective when they are
            notified on the Emsurge Network. Customers must review these Terms
            for variations. If a Customer disagrees with any variation of these
            Terms, it should not make any further use of or access to the
            Emsurge Network. By continuing to use the Emsurge Network `following
            any variation Customers are indicating their acceptance of the
            varied Terms.
          </ListItem>
        </OrderedList>
      </ListItem>

      <Text variant="h1" gutterBottom>
        Part 2: Operation of the Emsurge Network
      </Text>

      {/* 6 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Services
        </Text>

        <OrderedList>
          {/* 6.1 */}
          <ListItem gutterBottom={3}>
            Emsurge shall during the term of these Terms, provide Users with
            access to the Emsurge Network, subject to the terms of these Terms.
          </ListItem>

          {/* 6.2 */}
          <ListItem gutterBottom={3}>
            Administrators can disable their Customer’s Users, and request
            changes to the membership tier of its Users. The rights and
            privileges of a User on the Emsurge Network (including the ability
            to write Content, to trade, and to view certain Content by other
            Users) depend on the membership tiers of the relevant User which are
            governed by these Terms.
          </ListItem>

          {/* 6.3 */}
          <ListItem gutterBottom={3}>
            The extent to which User Content and submissions are visible to
            other users, is controlled through each User’s activity on the
            Emsurge Network and account settings (the “Settings”). Guests, and
            other Users associated with Brokers, should note that their Content
            and submissions will be available to their associated Broker and
            that the visibility of their actions to other Users may depend on
            the settings of the Broker. An Order submitted by a Trader to a
            Broker may be captured and viewable by other Traders the Broker has
            permitted to use its Broker Emsurge account.
          </ListItem>

          {/* 6.4 */}
          <ListItem>
            An Administrator’s or Users request to change their Settings or
            level of access constitutes agreement by the Customer to the impact
            of such change as indicated in the description of the documentation
            available on the Emsurge Network as updated from time to time (the
            “Platform Documentation”) and the ‘User Profiles’ outlined in the
            Membership Form, as applicable.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 7 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          User Interactions
        </Text>

        <OrderedList>
          {/* 7.1 */}
          <ListItem gutterBottom={3}>
            Users are associated with one Customer. Each Customer may have one
            or more entity that its Users are permitted to act on behalf of. A
            Customer may be affiliated with and have access to zero or any
            number of Brokers on the Emsurge Network. Users’ activity on the
            Emsurge Network is directed through its Customer’s entities. In this
            regard the Emsurge Network operates as a means of communication
            between Users on behalf of Customers and Brokers. Such
            communications, and the arrangements which arise through them
            (including for the avoidance of doubt, any fees), are subject to the
            terms of business between those parties. These Terms apply as
            between Emsurge and each Customer, but Emsurge is not a party to the
            arrangements or contracts which may arise between Customers and/or
            Brokers (whether on or off the Emsurge Network).
          </ListItem>

          {/* 7.2 */}
          <ListItem gutterBottom={3}>
            The primary function of the Emsurge Network is to enable Users to
            view information and exchange information with other Users. A
            feature of this is that Traders have the opportunity to use features
            of the Emsurge Network to present trade opportunities in Liquefied
            Natural Gas, commodities and other similar products (each an
            “Order”) to other Users and third parties who are not on the Emsurge
            Network. Traders should only raise Orders which they are prepared to
            honour. Unless expressly set out otherwise by the relevant Trader,
            an Order: (a) is an invitation for the party receiving the Order to
            enter a dialogue regarding potential future arrangements, to which
            additional terms may apply; (b) is not, and should not be
            interpreted to be, an offer to enter into a contract; and (c)
            remains subject to contract. Where a Trader elects to counter an
            existing Order on the system (a “Counter Order”) an associated term
            sheet is created. Term sheets can be marked as ‘Traded’. You
            acknowledge that Emsurge has no control or responsibility regarding
            the accuracy of such markings and that any such markings may not
            accurately reflect the status of any such arrangement. The ability
            to mark a term sheet as “Traded” is designed to release the post
            trade functionality (which Users must subscribe to) that enables the
            internal tracking of post trade work flows and nominations.
          </ListItem>

          {/* 7.3 */}
          <ListItem gutterBottom={3}>
            Customers acknowledge that, in the event that a User initiates or
            facilitates an arrangement, including through messaging, the sharing
            of Orders or the marking a term sheet as “Traded”, with another
            User, or other third party with access to the Emsurge Network via a
            Broker, we have no control or responsibility and we accept no
            liability whatsoever in respect of that arrangement (including in
            respect of any goods or services provided as a result of such an
            arrangement). For the avoidance of doubt, this includes any contract
            for sale, purchase, option, future rental, borrowing or other
            transaction (whether or not involving the exchange of money) that
            its Users enter into with third parties which are prompted by or
            otherwise arise as a result of use of the Emsurge Network.
          </ListItem>

          {/* 7.4 */}
          <ListItem>
            Emsurge will not be responsible for dealing with any queries and
            complaints in relation to any arrangements which are made or
            concluded between Customers and/or Users – such queries and
            complaints should be directed to the relevant third party.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 8 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Control over content
        </Text>

        <OrderedList>
          {/* 8.1 */}
          <ListItem gutterBottom={3}>
            The Emsurge Network allows Users to contribute, view and export
            information, data, text, or other materials (“Content”) to the
            Emsurge Network, depending on the type of account held by a User and
            membership enhancements subscribed to. Users are responsible for
            only creating Content that complies with these Terms.
          </ListItem>

          {/* 8.2 */}
          <ListItem gutterBottom={3}>
            Users retain ownership of all rights, including intellectual
            property rights, in the Content they post, upload and/or contribute
            to the Emsurge Network.
          </ListItem>

          {/* 8.3 */}
          <ListItem gutterBottom={3}>
            <Text gutterBottom>
              The Customer grants, and shall procure that its Users grant,
              Emsurge a non-exclusive, transferable, sub-licensable,
              royalty-free, perpetual, worldwide licence to use, store,
              distribute, publish, reproduce and process:
            </Text>

            <OrderedList alpha>
              <ListItem ordered={false} gutterBottom>
                subject to clause 1.1, Content; and
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                any feature suggestions or ideas that its Users make to Emsurge,
                however such publication and distribution shall be on an
                indemnity basis,
              </ListItem>
            </OrderedList>

            <Text>in each case, solely in respect of the Emsurge Network.</Text>
          </ListItem>

          {/* 8.4 */}
          <ListItem gutterBottom={3}>
            Any use of Content by Emsurge which is not solely in respect of the
            Emsurge Network, or otherwise permitted in accordance with these
            Terms, shall require the consent of the Customer (and/or the
            relevant User).
          </ListItem>

          {/* 8.5 */}
          <ListItem gutterBottom={3}>
            The Customer represents that it owns or otherwise has all necessary
            rights to any Content its Users upload and/or contribute to the
            Emsurge Network, and that such Content does not violate these Terms,
            applicable law, or the intellectual property rights of others and
            that it shall be responsible for loss or damage suffered by Emsurge
            as a result of such Content that its Users upload and/or contribute
            to the Emsurge Network that does not comply with these Terms.
          </ListItem>

          {/* 8.6 */}
          <ListItem gutterBottom={3}>
            Customers shall, and shall ensure that its Users shall, comply with
            their obligations under the data protection legislation (as defined
            in clause 20.1) in respect of their use and Emsurge’s use (in
            accordance with these Terms) of any personal data on the Emsurge
            Network, whether or not such personal data belongs to a User, on the
            Emsurge Network.
          </ListItem>

          {/* 8.7 */}
          <ListItem gutterBottom={3}>
            On acceptance of and subject to these Terms, we grant a limited,
            revocable, non-exclusive, non-sublicensable and non-assignable
            licence access and view the Content of other Users within the scope
            of normal use of the Emsurge Network during the term of these Terms.
          </ListItem>

          {/* 8.8 */}
          <ListItem gutterBottom={3}>
            Emsurge does not monitor, review, or edit Content, but reserves the
            right to remove or disable access to any Content for any or no
            reason, including but not limited to, Content that, in Emsurge’s
            sole discretion, violates these Terms. Emsurge may take these
            actions without prior notification.
          </ListItem>

          {/* 8.9 */}
          <ListItem gutterBottom={3}>
            Users understand that by using the Emsurge Network they may be
            exposed to Content that is offensive, indecent or objectionable.
            Emsurge is not responsible for Content nor does it endorse any
            opinion contained in Content. Under no circumstances will Emsurge be
            liable in any way for any Content, including, but not limited to,
            any errors or omissions in any Content, or any loss or damage of any
            kind incurred as a result of the use of any Content made available
            via the Emsurge Network.
          </ListItem>

          {/* 8.10 */}
          <ListItem gutterBottom={3}>
            If a Customer believes that any Content infringes its rights, the
            rights of any User or the rights of any other Customer, under
            applicable law, please contact us at m@emsurge.com.
          </ListItem>

          {/* 8.11 */}
          <ListItem>
            <Text gutterBottom>
              Customers acknowledge and agree that Emsurge may preserve Content
              and may also disclose Content if required to do so by law, or in
              the good faith belief that such preservation or disclosure is
              reasonably necessary to:
            </Text>

            <OrderedList alpha>
              <ListItem ordered={false} gutterBottom>
                comply with legal process;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                enforce the Terms;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                respond to claims that any Content violates the rights of third
                parties; or
              </ListItem>
              <ListItem ordered={false}>
                protect the rights, property, or personal safety of Emsurge,
                other Customers, Users and the public.
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 9 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          CONFIDENTIALITY
        </Text>

        <OrderedList>
          {/* 9.1 */}
          <ListItem gutterBottom={3}>
            Customers acknowledge that the Content of other Customers and Users
            is confidential, and neither Customers nor Users shall at any time
            disclose to any person any confidential information from the Emsurge
            Network except as expressly permitted by the relevant Customer or
            User, or as required by the law, a court of competent jurisdiction
            or any governmental or regulatory authority.
          </ListItem>

          {/* 9.2 */}
          <ListItem>
            Customers are responsible for its Users use of any Content of other
            Customers and Users outside of the Emsurge Network. In particular,
            Customers are responsible for ensuring that any such use by it or
            its Users of such Content does not breach the confidentiality rights
            of any third party, and is in accordance with any agreement which
            it, or its Users has with third parties regarding disclosure of
            information and Content.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 10 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Expected Behaviours
        </Text>

        <Text gutterBottom>
          Customers agree to use, and to ensure that its Users use, the Emsurge
          Network in a diligent, proper and lawful manner and to ensure that
          such use does not violate any applicable local, state, national or law
          or any rights of third parties (including trade mark, trade secret,
          copyright or other proprietary rights). Emsurge reserves the right to
          revoke or suspend access to the Emsurge Network in respect of any
          Users and/or Customers who violate, or we suspect to have violated,
          these Terms, in particular, clauses 10 (m), (n), (p) and (q). In
          particular, Users shall not:
        </Text>

        <OrderedList alpha>
          {/* Identity */}
          <Text variant="h6" gutterBottom>
            Identity
          </Text>
          <ListItem ordered={false} gutterBottom>
            attempt to use multiple identities or create multiple Emsurge
            accounts;
          </ListItem>
          <ListItem ordered={false} gutterBottom>
            impersonate any person or entity or falsely state or otherwise
            misrepresent your affiliation with any person or entity on the
            Emsurge network;
          </ListItem>
          <ListItem ordered={false} gutterBottom>
            share logins or access to the Emsurge Network with other people;
          </ListItem>
          <ListItem ordered={false} gutterBottom={3}>
            attempt to transfer an account on the Emsurge Network or any part of
            it to another person;
          </ListItem>

          {/* Content */}
          <Text variant="h6" gutterBottom>
            Content
          </Text>
          <ListItem ordered={false} gutterBottom>
            <Text gutterBottom>
              upload, post, email, transmit or otherwise make available any
              Content that:
            </Text>

            <List>
              <ListItem ordered={false} gutterBottom>
                is unlawful, harmful, threatening, abusive, harassing,
                defamatory, vulgar, obscene, libellous, invasive of
                another&apos;s privacy, hateful, or racially, ethnically or
                otherwise objectionable;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                is inaccurate, misleading, fraudulent, libellous, defamatory;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                is not accurate, truthful, current, complete and fit for
                purpose;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                discloses personal information of any person without their
                consent;
              </ListItem>
              <ListItem ordered={false}>
                discloses sensitive personal data (as defined in the data
                protection legislation set out in clause 20.1) whether or not
                without consent;
              </ListItem>
            </List>
          </ListItem>
          <ListItem ordered={false} gutterBottom>
            upload, post, email, transmit or otherwise make available any
            Content that you do not have a right to make available under any law
            or under contractual or fiduciary relationships (such as inside
            information, proprietary and confidential information learned or
            disclosed as part of employment relationships);
          </ListItem>
          <ListItem ordered={false} gutterBottom={3}>
            upload, post, email, transmit or otherwise make available any
            Content that infringes any intellectual property rights or
            confidentiality rights of any party;
          </ListItem>

          {/* Safety */}
          <Text variant="h6" gutterBottom>
            Safety
          </Text>
          <ListItem ordered={false} gutterBottom>
            share their password to the Emsurge Network. Users must ensure that
            their password to the Emsurge Network is secure, private to them,
            and that they change it regularly;
          </ListItem>
          <ListItem ordered={false} gutterBottom={3}>
            &quot;stalk&quot; or otherwise harass any other Users;
          </ListItem>

          {/* Technical */}
          <Text variant="h6" gutterBottom>
            Technical
          </Text>
          <ListItem ordered={false} gutterBottom>
            forge headers or otherwise manipulate any content transmitted
            through the Emsurge Network in order to disguise its origin;
          </ListItem>
          <ListItem ordered={false} gutterBottom>
            upload, post, email, transmit or otherwise make available on the
            Emsurge Network any material that contains software viruses or any
            other computer code, files or programs designed to interrupt,
            destroy or limit the functionality of any computer software or
            hardware or telecommunications equipment;
          </ListItem>
          <ListItem ordered={false} gutterBottom>
            interfere with or disrupt the Emsurge Network or servers or networks
            connected to the Emsurge Network, or disobey any requirements,
            procedures, policies or regulations of networks connected to the
            Emsurge Network;
          </ListItem>
          <ListItem ordered={false} gutterBottom>
            modify, translate, create derivative copies of or copy the Emsurge
            Network, in whole or in part, or combine or merge the Emsurge
            Network with any other object code or program;
          </ListItem>
          <ListItem ordered={false} gutterBottom={3}>
            reverse engineer, decompile, disassemble or otherwise reduce the
            object code of the Emsurge Network to source code form;
          </ListItem>

          {/* Advertising */}
          <Text variant="h6" gutterBottom>
            Advertising
          </Text>
          <ListItem ordered={false} gutterBottom={3}>
            upload, post, email, transmit or otherwise make available on or to
            the Emsurge Network, any unsolicited or unauthorised advertising,
            promotional materials, &quot;junk mail&quot;, &quot;spam&quot;,
            &quot;chain letters&quot;, &quot;pyramid schemes&quot;, or other
            equivalent form of solicitation;
          </ListItem>

          {/* Commercial */}
          <Text variant="h6" gutterBottom>
            Commercial
          </Text>
          <ListItem ordered={false} gutterBottom>
            copy or recreate any part of the Emsurge Network;
          </ListItem>
          <ListItem ordered={false} gutterBottom={3}>
            develop or promote any software or service that competes with, or is
            similar to, or that imitates, the Emsurge Network, or its content,
            features or functionality;
          </ListItem>

          {/* General */}
          <Text variant="h6" gutterBottom>
            General
          </Text>
          <ListItem ordered={false} gutterBottom>
            otherwise act in a manner that negatively affects other Users
            ability to engage in real time exchanges; or
          </ListItem>
          <ListItem ordered={false}>
            collect or store personal data about other Customers or Users in
            connection with the prohibited conduct and activities set forth in
            paragraphs above.
          </ListItem>
        </OrderedList>
      </ListItem>

      <Text variant="h1" gutterBottom>
        Part 3: Other Legal Terms
      </Text>

      {/* 11 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Storage
        </Text>

        <OrderedList>
          {/* 11.1 */}
          <ListItem gutterBottom={3}>
            The Emsurge Network is not a storage service, and should not be used
            as one. Customers are expected to take regular back-ups of all data
            and material they, or their connected Users may require a copy of in
            relation to the Emsurge Network. In the event of any loss or damage
            to data or materials, the sole and exclusive remedy shall be for
            Emsurge to use reasonable commercial endeavours to restore the lost
            or damaged data or materials from any the latest backup of such data
            or materials, where such a back-up exists. We may introduce new
            features which result in the permanent non-availability of data.
          </ListItem>

          {/* 11.2 */}
          <ListItem>
            Emsurge shall not be responsible for any loss, destruction,
            alteration or unauthorised access to or disclosure of Customer or
            User data caused by any third party (except those third parties
            sub-contracted by the Supplier to perform services related to User
            or Customer data maintenance and back-up).
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 12 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Availability
        </Text>

        <OrderedList>
          {/* 12.1 */}
          <ListItem gutterBottom={3}>
            Customers, and not Emsurge, are responsible for any equipment
            including computer devices and communications connections and/or
            subscriptions necessary to access the Emsurge Network.
          </ListItem>

          {/* 12.2 */}
          <ListItem gutterBottom={3}>
            We will make reasonable efforts to keep the Emsurge Network
            available, however, certain technical difficulties or maintenance
            may, from time to time, result in temporary interruptions. Emsurge
            make no guarantees of availability as temporary interruptions may
            arise due to technical difficulties or planned maintenance from time
            to time.
          </ListItem>

          {/* 12.3 */}
          <ListItem>
            Emsurge reserves the right at any time and from time to time to
            modify or discontinue, temporarily or permanently, functions and
            features of the Emsurge Network with or without notice, and
            Customers understand and agree that Emsurge shall not be liable for
            any interruption, modification, or discontinuation of the Emsurge
            Network or any function or feature thereof.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 13 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Intellectual Property Rights
        </Text>

        <OrderedList>
          {/* 13.1 */}
          <ListItem gutterBottom={3}>
            <Text gutterBottom>
              Emsurge and any third party licensors of Emsurge retain all right,
              title, ownership and interest in and to:
            </Text>

            <OrderedList alpha>
              <ListItem ordered={false} gutterBottom>
                the Emsurge Network, including, without limitation, all data,
                information (other than data and information that Users provide
                which we may use in accordance with these Terms), content (other
                than Content) and materials provided on or through, and the
                business process, procedures, methods and techniques used in or
                in connection with, the Emsurge Network; and
              </ListItem>
              <ListItem ordered={false}>
                all patent rights, copyright rights, trademark rights, trade
                secret rights and other intellectual property and proprietary
                rights recognised anywhere in the world in relation to the
                Emsurge Network.
              </ListItem>
            </OrderedList>
          </ListItem>

          {/* 13.2 */}
          <ListItem>
            All rights not expressly granted in these Terms are expressly
            reserved by Emsurge and its licensors.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 14 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          THIRD-PARTY CONTENT AND LINKS TO and from THIRD-PARTY WEB SITES
        </Text>

        <OrderedList>
          {/* 14.1 */}
          <ListItem gutterBottom={3}>
            The Emsurge Network may contain third-party owned content and links
            to other websites (&quot;Linked Sites&quot;). Emsurge does not
            endorse, sponsor, recommend, or otherwise accept responsibility for
            any Linked Sites. In addition, Linked Sites are not under the
            control of Emsurge, and Emsurge is not responsible for the content
            or privacy practices of the Linked Sites, including, without
            limitation, links contained on Linked Sites or any changes or
            updates to Linked Sites.
          </ListItem>

          {/* 14.2 */}
          <ListItem>
            Emsurge respects the intellectual property rights of others, and we
            ask Customers and Users to do the same. Emsurge has no
            responsibility for content on other websites that can be found or
            accessed when using the Emsurge Network. Material available on or
            through other websites may be protected by copyright and the
            intellectual property laws of any country. The terms of use of those
            websites, and not the Terms of Emsurge, govern use of that material.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 15 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Representations and Warranties
        </Text>

        <OrderedList>
          {/* 15.1 */}
          <ListItem gutterBottom={3}>
            <Text gutterBottom>
              To the fullest extent possible under applicable law, Emsurge gives
              no representation, warranty, condition or other term, whether
              express or implied or statutory, as to:
            </Text>

            <OrderedList alpha>
              <ListItem ordered={false} gutterBottom>
                the Emsurge Network being secure of free from bugs and viruses.
              </ListItem>
              <ListItem ordered={false}>
                the non-infringement by: (i) the Emsurge Network; (ii) any
                Content; or (iii) any Linked Sites; or (iv) any other product or
                service advertised or offered by a third party on or through the
                Emsurge Network or any hyperlinked website, or featured in any
                banner or other advertising (“Third Party Content”), of any
                third-party rights of any kind. In addition, Emsurge does not
                warrant, endorse, guarantee or assume responsibility for any
                Content or Third Party Content, none of which is intended to be
                advice or relied on as such.
              </ListItem>
            </OrderedList>
          </ListItem>

          {/* 15.2 */}
          <ListItem gutterBottom={3}>
            No support is offered in relation to the Emsurge Network, except as
            expressly set out in the Platform Documentation.
          </ListItem>

          {/* 15.3 */}
          <ListItem>
            Customers agree that Emsurge is not responsible or liable for any
            transaction with third-party providers advertised or made available
            on or through the Emsurge Network. As with any purchase of a product
            or service through any medium or in any environment, Customers and
            Users should use their judgment and exercise caution where
            appropriate. No advice or information whether oral or in writing
            obtained from Emsurge shall create any warranty or representation on
            behalf of Emsurge in this regard.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 16 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Liability
        </Text>

        <OrderedList>
          {/* 16.1 */}
          <ListItem gutterBottom={3}>
            In no event shall Emsurge, its affiliates, officers, directors,
            employees, licensors or business partners be liable for any direct,
            indirect, incidental, special or consequential damages, or for any
            loss of profit, business, revenue, goodwill, data, or pecuniary
            loss) arising out of the use of or inability to use the Emsurge
            Network (including but not limited to its content), or any Orders,
            Content or any Third Party Content, even if Emsurge has been advised
            about the possibility of such loss, and including any damages
            resulting therefrom. Customers understand that Emsurge has no
            ability to control any Third Party Content, and Customers agree that
            Emsurge assumes no responsibility or liability for any Third Party
            Content.
          </ListItem>

          {/* 16.2 */}
          <ListItem gutterBottom={3}>
            Customers agree that, to the extent permitted by applicable law, the
            sole and exclusive remedy for any problems or dissatisfaction with
            the Emsurge Network or Content is to stop using the Emsurge Network.
            In no event shall Emsurge be liable to any Customer or User for any
            more than the total amounts the relevant Customer has paid to
            Emsurge.
          </ListItem>

          {/* 16.3 */}
          <ListItem>
            Nothing in these Terms removes or limits Emsurge’s liability for
            fraudulent misrepresentation, death or personal injury caused by its
            negligence.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 17 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Leaving the Emsurge Network
        </Text>

        <OrderedList>
          {/* 17.1 */}
          <ListItem>
            <Text gutterBottom>
              At any time, if Emsurge considers that an User should not have
              access to the Emsurge Network or an User chooses to leave the
              Emsurge Network:
            </Text>

            <OrderedList alpha>
              <ListItem ordered={false} gutterBottom>
                we may immediately cease providing access to the Emsurge
                Network;
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                Emsurge may destroy or otherwise dispose of any of Content
                provided in respect of these Terms in its possession; and
              </ListItem>
              <ListItem ordered={false}>
                any rights, remedies, obligations or liabilities of the parties
                that have accrued up to the date of termination, including the
                right to claim damages in respect of any breach of these Terms
                which existed at or before the date of termination shall not be
                affected or prejudiced.
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 18 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Indemnity
        </Text>

        <OrderedList>
          {/* 18.1 */}
          <ListItem>
            Customers agree to indemnify and hold Emsurge, and its affiliates,
            successors, assigns, officers, directors and employees, harmless, to
            the extent permitted by applicable law, from and against any claims,
            damages, losses, liabilities, judgments, settlements, costs and
            expenses (including reasonable professional legal advisers’ fees (on
            a solicitor – client basis) which arises from or relates to their:
            breach or alleged breach of these Terms; use of the Emsurge Network;
            violation of any applicable laws or of any rights of others
            (including, without limitation any third party right or right of
            Emsurge, including, without limitation, any intellectual property or
            proprietary right).
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 19 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          Anti-bribery and corruption
        </Text>

        <OrderedList>
          {/* 19.1 */}
          <ListItem gutterBottom={3}>
            Emsurge agrees and undertakes to comply with all laws applicable to
            these Terms including all relevant anti-bribery/anti-corruption
            laws.
          </ListItem>

          {/* 19.2 */}
          <ListItem gutterBottom={3}>
            <Text gutterBottom>
              Emsurge represents, warrants and undertakes that it shall not, nor
              shall any of its officers, employees, shareholders,
              representatives or agents, directly or indirectly:
            </Text>

            <OrderedList alpha>
              <ListItem ordered={false} gutterBottom>
                <Text gutterBottom>
                  make or offer any payment, gift or other advantage with
                  respect to any matters which are the subject of these Terms
                  which would:
                </Text>

                <List>
                  <ListItem ordered={false} gutterBottom>
                    influence, or would be intended to influence, any person to
                    act in breach of an expectation of good faith, impartiality
                    or trust, or reward;
                  </ListItem>
                  <ListItem ordered={false} gutterBottom>
                    be considered by a reasonable person to be unethical,
                    illegal or improper; or
                  </ListItem>
                  <ListItem ordered={false}>
                    violate any anti-corruption laws or regulations applicable
                    to the parties to this agreement;
                  </ListItem>
                </List>
              </ListItem>
              <ListItem ordered={false} gutterBottom>
                share any fees, commission or other reward paid to it by the
                Customer with any public official; or
              </ListItem>
              <ListItem ordered={false}>
                engage in other acts or transactions in violation of or
                inconsistent with the anti-bribery or anti-corruption
                legislation of any government including the UK Bribery Act 2010.
              </ListItem>
            </OrderedList>
          </ListItem>

          {/* 19.3 */}
          <ListItem gutterBottom={3}>
            In particular, Emsurge represents and warrants that it has not made
            or offered any payment, gift or other advantage to government
            officials, officers or employees or other person which would be
            inconsistent with or contravene any of the above-referenced
            legislation.
          </ListItem>

          {/* 19.4 */}
          <ListItem>
            Emsurge represents and warrants that it has in place appropriate
            anti-corruption systems and controls such as are reasonably likely
            to be regarded as constituting “adequate procedures” for the
            purposes of section 7 of the UK Bribery Act 2010.
          </ListItem>
        </OrderedList>
      </ListItem>

      {/* 20 */}
      <ListItem gutterBottom={10}>
        <Text variant="h6" gutterBottom>
          General
        </Text>

        <OrderedList>
          {/* 20.1 */}
          <ListItem gutterBottom={3}>
            Data Protection. For the purpose of these terms “data protection
            legislation” means: (a) Regulation (EU) 2016/679, ‘General Data
            Protection Regulation’, (‘GDPR’) or equivalent legislation; (b) the
            Privacy and Electronic Communications (EC Directive) Regulations
            2003; and (c) all other applicable laws (including judgments of any
            relevant court of law) and regulations relating to the processing of
            personal data, data privacy or electronic communications, in each
            case as amended, extended or re-enacted from time to time and all
            orders, regulations, statutes, instruments and/or other subordinate
            legislation made under any of the above in any jurisdiction from
            time to time.
          </ListItem>

          {/* 20.2 */}
          <ListItem gutterBottom={3}>
            Notices. All notices, requests, complaints and other correspondence
            with Emsurge should be sent to us either by email to m@emsurge.com.
          </ListItem>

          {/* 20.3 */}
          <ListItem gutterBottom={3}>
            Assignment. We may transfer our rights and obligations under these
            Terms to another organisation and will provide you with notice in
            writing if this happens. You may only transfer your rights or
            obligations under these Terms to another person if we agree in
            writing.
          </ListItem>

          {/* 20.4 */}
          <ListItem gutterBottom={3}>
            Entire Agreement. These Terms constitute the whole agreement between
            the Emsurge and Customers relating to its subject matter and
            supersedes and extinguishes any prior agreements, undertakings,
            representations, warranties and arrangements of any nature. Unless
            otherwise expressly agreed in writing these Terms apply in place of
            and prevails over any terms or conditions contained in or referred
            to in correspondence or elsewhere or implied by trade, custom or
            course of dealing. Nothing in this clause 20.4 shall limit liability
            for any representations made fraudulently.
          </ListItem>

          {/* 20.5 */}
          <ListItem gutterBottom={3}>
            Benefits. The rights, powers, privileges and remedies provided under
            any provision of these Terms or provided by applicable law or
            otherwise (“Benefits”) are cumulative and are not exclusive of any
            Benefits provided under any other provision of these Terms or in any
            other way. No failure to exercise or any delay in exercising by any
            party of any Benefit shall impair or operate as a partial of full
            waiver of that Benefit. No single or partial exercise of any Benefit
            shall prevent any further or other exercise of that Benefit.
          </ListItem>

          {/* 20.6 */}
          <ListItem gutterBottom={3}>
            Invalidity. If any provision of these Terms shall be held to be
            illegal, void, invalid or unenforceable under the applicable laws of
            any jurisdiction, the legality, validity and enforceability of the
            remainder of these Terms in that jurisdiction shall not be affected,
            and the legality, validity and enforceability of the whole of these
            Terms in any other jurisdiction shall not be affected.
          </ListItem>

          {/* 20.7 */}
          <ListItem gutterBottom={3}>
            Third Party Rights. A person who is not a party to these Terms shall
            not have any rights under the Contracts (Rights of Third Parties)
            Act 1999 or other analogous law in any jurisdiction from time to
            time to enforce any term of these Terms.
          </ListItem>

          {/* 20.8 */}
          <ListItem gutterBottom={3}>
            Relationship of the Parties. Unless expressly provided to the
            contrary in these Terms nothing in these Terms shall constitute or
            be deemed to constitute a partnership, joint venture or agency
            between the parties and neither party shall have any authority or
            power to bind, contract in the name of or create a liability for or
            against the other.
          </ListItem>

          {/* 20.9 */}
          <ListItem>
            Governing Law and Jurisdiction. These Terms shall be governed by and
            construed in accordance with the laws of the laws of England. In
            relation to any legal action or proceedings to enforce these Terms
            or arising out of or in connection with these Terms or use of the
            Emsurge Network the parties irrevocably submit to the exclusive
            jurisdiction of the courts of England.
          </ListItem>
        </OrderedList>
      </ListItem>
    </OrderedList>
  </>
)

export default TermsConditions
