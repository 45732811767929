import React from 'react'
import { connect } from 'formik'
import { TERM_SPOT_PERIOD_PREFIX } from 'emsurge-selectors'
import { PeriodSelect } from '../components/PeriodSelect'
import { ProjectInformation } from '../components/ProjectInformation'
import { Delivery } from '../components/Delivery'
import { TitleModalSection } from 'screens/orderCreateCommon/components/TitleModalSection'

const _TermVintageSpot = () => {
  return (
    <>
      <TitleModalSection>Vintage</TitleModalSection>
      <PeriodSelect fieldPrefix={TERM_SPOT_PERIOD_PREFIX} infoTitle="Vintage" />
      <TitleModalSection>Delivery</TitleModalSection>
      <Delivery/>
      <TitleModalSection>Project Information</TitleModalSection>
      <ProjectInformation onlyActive={true} />
    </>
  )
}

export const TermVintageSpot = connect(_TermVintageSpot)
