import React from 'react'
import PropTypes from 'prop-types'
import ClearIcon from '@material-ui/icons/Clear'
import Tooltip from 'components/tooltip/Tooltip'

const CloseButton = ({ onClick, style, size }) => (
  <Tooltip title="Close">
    <div
      style={{ position: 'absolute', cursor: 'pointer', ...style }}
      onClick={onClick}
      id="closeOrderModalButton"
      data-testid="close-modal-button"
    >
      <ClearIcon color="inherit" fontSize={size} />
    </div>
  </Tooltip>
)

CloseButton.defaultProps = {
  style: {
    position: 'absolute',
    zIndex: 100,
    top: -12,
    right: -12,
    width: 24,
    height: 24,
    backgroundColor: '#FFF',
    color: '#363f4f',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  size: 'large',
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  size: PropTypes.string,
}

export default CloseButton
