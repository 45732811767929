import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import Text from '@material-ui/core/Typography'

import FieldCard from 'screens/orderCreateCommon/components/FieldCard'
import { getDeliveryPeriodLabel } from 'screens/orderCreate/components/DeliveryWindow.helpers'

const _DeliveryPeriodContract = ({ formik: { values } }) => {
  const { delivery } = values
  const contract = getDeliveryPeriodLabel(delivery)
  return (
    <FieldCard title="Contract">
      <Text noWrap>{contract}</Text>
    </FieldCard>
  )
}

_DeliveryPeriodContract.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const DeliveryPeriodContract = connect(_DeliveryPeriodContract)
