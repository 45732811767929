const fill = require('lodash/fill')

const DELIVERY_WINDOW_TYPE = Object.freeze({
  PRO_RATA: 'pro_rata',
  PER_CARGO: 'per_cargo',
})

const DELIVERY_WINDOW_TYPE_TEXT = Object.freeze({
  [DELIVERY_WINDOW_TYPE.PRO_RATA]: 'Pro Rata',
  [DELIVERY_WINDOW_TYPE.PER_CARGO]: 'Per Cargo',
  PRO_RATA: 'Pro Rata',
  PER_CARGO: 'Per Cargo',
})

const DELIVERY_WINDOW_CARGO_PERIOD_WEEK = fill(Array(52)).reduce(
  (weekLabels, _, index) => {
    const weekNumber = index + 1
    weekLabels[`W${weekNumber}`] = `W${weekNumber}`
    return weekLabels
  },
  {}
)

const DELIVERY_WINDOW_CARGO_PERIOD_QUARTER = Object.freeze({
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4',
})

const DELIVERY_WINDOW_CARGO_PERIOD_MONTH = Object.freeze({
  JAN: 'Jan',
  FEB: 'Feb',
  MAR: 'Mar',
  APR: 'Apr',
  MAY: 'May',
  JUN: 'Jun',
  JUL: 'Jul',
  AUG: 'Aug',
  SEP: 'Sep',
  OCT: 'Oct',
  NOV: 'Nov',
  DEC: 'Dec',
})

const DELIVERY_WINDOW_CARGO_PERIOD_SEASON = Object.freeze({
  SUMMER: 'SUM',
  WINTER: 'WIN',
})

const DELIVERY_WINDOW_CARGO_PERIOD_CUSTOM = Object.freeze({
  CUSTOM: 'Specific Dates',
})

const DELIVERY_WINDOW_CARGO_PERIOD_SECTION = Object.freeze({
  EARLY: 'Early',
  MID: 'Mid',
  LATE: 'Late',
})

const DELIVERY_WINDOW_CARGO_PERIOD_HALF = Object.freeze({
  FIRST_HALF: 'First Half',
  SECOND_HALF: 'Second Half',
})

const DELIVERY_WINDOW_CARGO_PERIOD_TEXT = Object.freeze({
  [DELIVERY_WINDOW_CARGO_PERIOD_QUARTER.Q1]: 'Q1',
  [DELIVERY_WINDOW_CARGO_PERIOD_QUARTER.Q2]: 'Q2',
  [DELIVERY_WINDOW_CARGO_PERIOD_QUARTER.Q3]: 'Q3',
  [DELIVERY_WINDOW_CARGO_PERIOD_QUARTER.Q4]: 'Q4',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.JAN]: 'Jan',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.FEB]: 'Feb',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.MAR]: 'Mar',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.APR]: 'Apr',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.MAY]: 'May',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.JUN]: 'Jun',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.JUL]: 'Jul',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.AUG]: 'Aug',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.SEP]: 'Sep',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.OCT]: 'Oct',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.NOV]: 'Nov',
  [DELIVERY_WINDOW_CARGO_PERIOD_MONTH.DEC]: 'Dec',
  [DELIVERY_WINDOW_CARGO_PERIOD_SEASON.SUMMER]: 'SUM',
  [DELIVERY_WINDOW_CARGO_PERIOD_SEASON.WINTER]: 'WIN',
  [DELIVERY_WINDOW_CARGO_PERIOD_CUSTOM.CUSTOM]: 'Specific Dates',
  [DELIVERY_WINDOW_CARGO_PERIOD_SECTION.EARLY]: 'Early',
  [DELIVERY_WINDOW_CARGO_PERIOD_SECTION.MID]: 'Mid',
  [DELIVERY_WINDOW_CARGO_PERIOD_SECTION.LATE]: 'Late',
  [DELIVERY_WINDOW_CARGO_PERIOD_HALF.FIRST_HALF]: '1H',
  [DELIVERY_WINDOW_CARGO_PERIOD_HALF.SECOND_HALF]: '2H',
  ...DELIVERY_WINDOW_CARGO_PERIOD_WEEK,
})

module.exports = {
  DELIVERY_WINDOW_TYPE,
  DELIVERY_WINDOW_TYPE_TEXT,
  DELIVERY_WINDOW_CARGO_PERIOD_QUARTER,
  DELIVERY_WINDOW_CARGO_PERIOD_MONTH,
  DELIVERY_WINDOW_CARGO_PERIOD_SEASON,
  DELIVERY_WINDOW_CARGO_PERIOD_CUSTOM,
  DELIVERY_WINDOW_CARGO_PERIOD_SECTION,
  DELIVERY_WINDOW_CARGO_PERIOD_HALF,
  DELIVERY_WINDOW_CARGO_PERIOD_TEXT,
}
