import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

const FormRow = ({ children, spacing }) => (
  <Grid container spacing={spacing}>
    {React.Children.map(children, (fieldElem) => (
      <Grid item xs>
        {React.cloneElement(fieldElem, { fullWidth: true })}
      </Grid>
    ))}
  </Grid>
)

FormRow.defaultProps = {
  spacing: 2,
}

FormRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  spacing: PropTypes.number,
}

export default FormRow
