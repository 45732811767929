import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { repeat } from 'lodash'
import { Laptop, Mobile, Tablet } from 'components/Viewport'

export const HEADER_AREAS = {
  SUMMARY: 'summary',
  DETAILS: 'details',
  MAIN_ACTIONS: 'main-actions',
  OTHER_ACTIONS: 'other-actions',
}

const WrapperDesktop = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 4)};
`

const WrapperMobile = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 2)};
`

export const Wrapper = (props) => (
  <>
    <Tablet matchLarger>
      <WrapperDesktop {...props} />
    </Tablet>
    <Mobile>
      <WrapperMobile {...props} />
    </Mobile>
  </>
)

const GridContainerLaptop = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 25px auto;
  gap: 1px 1px;
  grid-template-areas:
    '${repeat(
      `${HEADER_AREAS.SUMMARY} `,
      12
    )} ${repeat(`${HEADER_AREAS.OTHER_ACTIONS} `, 4)}'
    '${repeat(
      `${HEADER_AREAS.DETAILS} `,
      8
    )} ${repeat(`${HEADER_AREAS.MAIN_ACTIONS} `, 8)}';
`

const GridContainerTablet = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 50px auto;
  gap: 1px 1px;
  grid-template-areas:
    '${repeat(
      `${HEADER_AREAS.SUMMARY} `,
      12
    )} ${repeat(`${HEADER_AREAS.OTHER_ACTIONS} `, 4)}'
    '${repeat(
      `${HEADER_AREAS.DETAILS} `,
      6
    )} ${repeat(`${HEADER_AREAS.MAIN_ACTIONS} `, 10)}';
`

const GridContainerMobile = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 50px auto;
  gap: 1px 1px;
  grid-template-areas:
    '${repeat(`${HEADER_AREAS.OTHER_ACTIONS} `, 16)}'
    '${repeat(`${HEADER_AREAS.SUMMARY} `, 16)}'
    '${repeat(`${HEADER_AREAS.DETAILS} `, 16)}'
    '${repeat(`${HEADER_AREAS.MAIN_ACTIONS} `, 16)}';
`

export const GridContainer = (props) => (
  <>
    <Tablet>
      <GridContainerTablet {...props} />
    </Tablet>
    <Laptop matchLarger>
      <GridContainerLaptop {...props} />
    </Laptop>
    <Mobile>
      <GridContainerMobile {...props} />
    </Mobile>
  </>
)

export const GridArea = ({ name, style, ...props }) => (
  <div style={{ ...style, gridArea: name }} {...props} />
)

GridArea.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
}
