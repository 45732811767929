import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  TextField,
} from '@material-ui/core'
import { get, isEmpty, set } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import Checkbox from 'containers/formikCheckbox/FormikCheckbox'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'

const ANY = 'ANY'
const filterMultiple = (option) => option.label !== ANY && option.key !== ANY
const mapKey = (option) => option.key || option.label

const useStyles = makeStyles(() => ({
  input: {
    marginTop: 0,
  },
  errorContainer: {
    width: '100%',
  },
}))

const _CheckboxGroup = ({
  formik,
  label,
  fieldPrefix,
  options,
  customUnchecked = [],
  onChange,
  style,
}) => {
  const classes = useStyles()
  const [checked, setChecked] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [radioButtonOptions, setRadioButtonOptions] = useState(options)
  const { values, setFieldValue, errors, setFieldTouched } = formik
  const MULTIPLES = radioButtonOptions.filter(filterMultiple).map(mapKey)

  const setDefaults = (multiples = false) => {
    setMultiples(multiples)
  }

  const setMultiples = (value) => {
    if (!value) {
      toggleAllExtraData()
    }
    MULTIPLES.forEach((option) => {
      setFieldValue(`${fieldPrefix}.${option}`, value)
    })
  }

  const getPathKey = (path) => path.split('.').pop()

  const toggleExtraData = (fieldName, value) => {
    const extraIndex = radioButtonOptions.findIndex(
      (option) => option.value === fieldName && !isEmpty(option.extra)
    )
    if (extraIndex >= 0) {
      set(radioButtonOptions[extraIndex], 'extra.visible', value)
      setRadioButtonOptions(radioButtonOptions)
      setChecked('')
      if (!value) {
        const otherContentPath = get(
          radioButtonOptions[extraIndex],
          'extra.field'
        )
        setFieldValue(otherContentPath, '')
      }
    }
  }

  const toggleAllExtraData = () => {
    const extraData = radioButtonOptions.filter(
      (option) => !isEmpty(option.extra)
    )
    extraData.forEach(({ value }) => {
      toggleExtraData(value, false)
    })
  }

  useEffect(() => {
    if (!get(values, fieldPrefix)) {
      setDefaults()
    }
  }, [])

  useEffect(() => {
    const message = get(errors, fieldPrefix)
    const messageText = message && typeof message === 'string' ? message : ''
    setErrorMessage(messageText)
  }, [errors])

  useEffect(() => {
    if (!checked) return
    setFieldTouched(fieldPrefix, true)
    const key = getPathKey(checked)
    const value = get(values, checked)
    if (key === ANY && value) {
      return setMultiples(false)
    }
    toggleExtraData(checked, value)
  }, [checked])

  const onChangeTextField = (field, value) => {
    setFieldValue(field, value)
  }

  const getIndeterminateProps = (path) => {
    const key = getPathKey(path)
    return customUnchecked.includes(key)
  }

  const isVisible = (radioOptionExtra) => {
    const filtered = radioButtonOptions.filter(
      ({ extra, value: otherPath }) => {
        const { field: extraPath } = extra || {}
        return radioOptionExtra.field === extraPath && get(values, otherPath)
      }
    )
    return filtered.length > 0
  }

  return (
    <>
      <FormControl fullWidth style={style}>
        <FormLabel className={(errorMessage && 'Mui-error') || ''}>
          {label}
        </FormLabel>
        <FormGroup aria-label="position" row>
          {radioButtonOptions.map(({ label, value, extra, disabled }, idx) => (
            <div key={`${fieldPrefix}-iterator-${idx}`}>
              <FormControlLabel
                label={label}
                key={`${fieldPrefix}-${idx}`}
                control={
                  <Checkbox
                    inputProps={{
                      'data-testid': `${value.replace(/\./g, '-')}-checkbox`,
                    }}
                    color="primary"
                    value={value}
                    key={`${fieldPrefix}-checkbox-${idx}`}
                    onChange={({ target: { defaultValue } }) => {
                      setChecked(defaultValue)
                      onChange(defaultValue)
                    }}
                    disabled={disabled}
                    indeterminate={getIndeterminateProps(value)}
                  />
                }
              />
              {extra && isVisible(extra) && (
                <TextField
                  margin="normal"
                  type="text"
                  key={`${fieldPrefix}-extra-${idx}`}
                  name={extra.field}
                  placeholder={extra.placeholder}
                  label={extra.label}
                  className={classes.input}
                  required={true}
                  onChange={({ target: { value } }) =>
                    onChangeTextField(extra.field, value)
                  }
                  value={get(values, extra.field) || ''}
                />
              )}
            </div>
          ))}
          {errorMessage && (
            <FormHelperText
              className={classes.errorContainer}
              component="div"
              error={true}
            >
              {errorMessage}
            </FormHelperText>
          )}
        </FormGroup>
      </FormControl>
    </>
  )
}

_CheckboxGroup.defaultProps = {
  label: '',
  onChange: () => {},
  style: {},
}

_CheckboxGroup.propTypes = {
  label: PropTypes.string,
  fieldPrefix: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  customUnchecked: PropTypes.array,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

export const populateCheckboxGroupInitialValues = (initialValues, orderSectionKeys, orderSectionObject, orderSectionFieldsPrefix, otherValue) => {
  Object.keys(orderSectionKeys).forEach(x => {
    if(orderSectionObject && orderSectionObject.includes(x)) {
      set(
        initialValues,
        `${orderSectionFieldsPrefix}.${x}`,
        true,
      )
      if (x === orderSectionKeys.Other) {
        set(
          initialValues,
          `${orderSectionFieldsPrefix}.otherContent`,
          otherValue
        )
      }
    }
  })
}

export const CheckboxGroup = connect(_CheckboxGroup)
