const {
  DELIVERY_VOLUME_METRIC_TEXT,
  DELIVERY_VOLUME_TYPE_TEXT,
} = require('../../../constants')

const getVolumeMetric = (order) => {
  const volume = order.volume || {}

  return volume.metric
}

const getVolumeMetricText = (order) => {
  const volumeMetric = getVolumeMetric(order)

  return DELIVERY_VOLUME_METRIC_TEXT[volumeMetric] || ''
}

const getVolumeType = (order) => {
  const volume = order.volume || {}

  return volume.type
}

const getVolumeTypeText = (order) => {
  const volumeType = getVolumeType(order)

  return DELIVERY_VOLUME_TYPE_TEXT[volumeType] || ''
}

const getVolume = (order) => {
  const metric = getVolumeMetric(order)
  const type = getVolumeType(order)
  const { min, max } = order.volume || {}

  return {
    metric,
    type,
    min: min ? parseInt(min) : undefined,
    max: max ? parseInt(max) : undefined,
  }
}

const getVolumeSummary = (order) => {
  const metric = getVolumeMetricText(order)
  const type = getVolumeTypeText(order)
  const { min, max } = order.volume || {}

  return {
    metric,
    type,
    min: min ? parseInt(min) : undefined,
    max: max ? parseInt(max) : undefined,
  }
}

// There's no text definition for the volume. Feel free to change this if needed. Created for consistency sake.
const getVolumeText = () => {
  return 'Method not implemented'
}

module.exports = {
  metric: {
    get: getVolumeMetric,
    text: getVolumeMetricText,
  },
  type: {
    get: getVolumeType,
    text: getVolumeTypeText,
  },
  get: getVolume,
  summary: getVolumeSummary,
  text: getVolumeText,
}
