import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import { NativeSelect } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  select: {
    paddingRight: 0,
    margin: 'auto',
    width: 'auto',
  },
  icon: {
    display: 'none',
  },
}))

export const Select = ({ onChange, options, value, placeholder, style }) => {
  const classes = useStyles()
  const { palette, spacing } = useTheme()
  const styles = {
    borderRadius: spacing(1),
    fontWeight: 'bold',
    background: options.includes(value)
      ? palette.background.secondary
      : palette.background.default,
    ...style,
  }

  return (
    <NativeSelect
      style={styles}
      inputProps={{ classes }}
      fullWidth
      disableUnderline
      value={value}
      onChange={onChange}
    >
      {placeholder}
      {options.map((option) => (
        <option
          key={`select-${option}`}
          data-active={value === option}
          value={option}
        >
          {option}
        </option>
      ))}
    </NativeSelect>
  )
}

Select.defaultProps = {
  onChange: () => {},
  options: [],
  value: '',
  placeholder: <option value="">Select</option>,
  style: {},
}

Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  placeholder: PropTypes.node,
  style: PropTypes.object,
}
