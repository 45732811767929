import { createTheme } from '@material-ui/core/styles'

const palette = {
  type: 'dark',
  primary: {
    light: '#d8cfb3',
    main: '#7F6000',
    dark: '#654c00',
  },
  secondary: {
    light: '#9097A1',
    main: '#656E7C',
    dark: '#353F50',
  },
  error: {
    light: '#fe8796',
    main: '#fe3851',
    dark: '#cb2c40',
  },
  divider: '#454e5e',
  background: {
    default: '#0f1723',
    paper: '#1a222f',
    secondary: '#353F50',
  },
  other: {
    pink: '#f176ff',
    blue: '#31ffe5',
    orange: '#ff7400',
  },
  carbon: {
    navbar: {
      background: 'rgb(233, 227, 222)',
      text: '#7B601D'
    }
  }
}

// @NOTE: 1rem = 16px
const typography = {
  fontFamily: 'Montserrat, sans-serif',
  h1: {
    fontWeight: 800,
    fontSize: '2rem',
    lineHeight: 1.2,
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  h2: {
    fontWeight: 600,
    fontSize: '1.6rem',
    lineHeight: 1.2,
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: 1.2,
  },
  h4: {
    fontWeight: 200,
    fontSize: '1.3rem',
    lineHeight: 1.2,
    textTransform: 'uppercase',
  },
  h5: {
    fontWeight: 200,
    fontSize: '1.3rem',
    lineHeight: 1.2,
  },
  h6: {
    fontWeight: 700,
    fontSize: '1.2rem',
    lineHeight: 1.2,
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  subtitle2: {
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  body1: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  body2: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  button: {
    fontWeight: 800,
    fontSize: '0.875rem',
    lineHeight: 1.5,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  caption: {
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: 0.5,
  },
  overline: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 2.66,
    textTransform: 'uppercase',
  },
}

const overrides = {
  MuiTableCell: {
    root: { padding: '4px 24px 4px 16px' }, // Reduce horrizontal padding for all table cells
    head: {
      fontSize: '0.75rem',
      fontWeight: 500,
      opacity: 0.7,
    },
    body: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
  },
  MuiTableRow: {
    root: { height: '56px' },
    head: { height: '56px' },
  },
}

const custom = {
  desktop: {
    navbar: {
      height: 64,
    },
  },
  mobile: {
    navbar: {
      height: 56,
    },
  },
}

// @NOTE: We are not using a light theme for the application, only docs. */
const light = createTheme({ custom, typography })
const dark = createTheme({ custom, palette, typography, overrides })

export { light, dark }
