import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'

import { PRICE_UNIT, PRICE_UNIT_LABEL } from 'emsurge-selectors/constants'
import { TextField } from 'screens/orderCreateCommon/components/TextField'

export const Unit = ({ name, ...props }) => (
  <TextField select name={name} label="Unit" {...props}>
    {Object.values(PRICE_UNIT).map((PRICE_UNIT_VALUE) => (
      <MenuItem
        data-testid="price-unit-values"
        key={PRICE_UNIT_VALUE}
        value={PRICE_UNIT_VALUE}
      >
        {PRICE_UNIT_LABEL[PRICE_UNIT_VALUE]}
      </MenuItem>
    ))}
  </TextField>
)

Unit.propTypes = {
  name: PropTypes.string.isRequired,
}
