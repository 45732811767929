import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import Text from '@material-ui/core/Typography'
import DeleteButton from './components/DeleteButton'
import UserInfo from './components/UserInfo'
import UserDeleteConfirmDialog from './containers/UserDeleteConfirmDialog'
import Fetch from 'containers/fetchPlus/FetchPlus'
import withParams from 'utils/withRouteParams'
import { HeaderWrapper as Header } from 'screens/entityView/components/EntityInformation'
import EditButton from 'screens/entityView/components/EditButton'
import { ADMIN_EDIT_USER } from 'routes'
import { isSystemAdmin } from 'roles'

const UserView = ({ id }) => {
  const { spacing } = useTheme()

  return (
    <Fetch url={`/persons/${id}`} debug>
      {(user) => {
        const isUserSystemAdmin = isSystemAdmin(user)

        return (
          <>
            <Header>
              <Text component="h1" variant="h1" gutterBottom>
                User information
              </Text>
              <section>
                <EditButton
                  data-testid="edit-user-button"
                  to={ADMIN_EDIT_USER.replace(':id', id)}
                  disabled={isUserSystemAdmin}
                />
                {!isUserSystemAdmin && (
                  <UserDeleteConfirmDialog user={user}>
                    {({ open }) => (
                      <DeleteButton
                        onClick={open}
                        style={{ margin: spacing(0, 1) }}
                      />
                    )}
                  </UserDeleteConfirmDialog>
                )}
              </section>
            </Header>

            <UserInfo user={user} />
          </>
        )
      }}
    </Fetch>
  )
}

UserView.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withParams(UserView)
