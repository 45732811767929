import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import Text from '@material-ui/core/Typography'
import Tooltip from 'components/tooltip/Tooltip'

export const SummaryBlock = ({
  value,
  tooltipProps: { title, ...tooltipPropsRest },
  textProps: { style, ...textPropsRest },
}) => {
  const { spacing } = useTheme()

  if (!value) {
    return null
  }

  return (
    <Tooltip title={title} {...tooltipPropsRest}>
      <Text
        display="inline"
        style={{ marginRight: spacing(1), ...style }}
        {...textPropsRest}
      >
        {value}
      </Text>
    </Tooltip>
  )
}

SummaryBlock.defaultProps = {
  value: '',
  tooltipProps: {
    title: '',
  },
  textProps: {
    style: {},
  },
}

SummaryBlock.propTypes = {
  value: PropTypes.node,
  tooltipProps: PropTypes.object,
  textProps: PropTypes.object,
}
