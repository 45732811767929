import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import Text from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { connect } from 'formik'
import { PersonPermission } from './PersonPermission'

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}))

const _EntityPermission = ({ formik: { submitForm, values: entities } }) => {
  const classes = useStyles()

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Text data-testid="settings-entity-name" variant="h6">
          {entities.name}
        </Text>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {entities.persons.map((person, index) => (
              <PersonPermission
                key={person.id}
                index={index}
                onChange={submitForm}
              ></PersonPermission>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

_EntityPermission.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const EntityPermission = connect(_EntityPermission)
