const buildCountryData = (key, countries, focus = true) => ({
  key,
  label: countries[key],
  focus,
})

const OPTION_TYPE_IS_LDC = 'isLDC'
const OPTION_TYPE_IS_NON_LDC = 'isNonLDC'

const elementsAreNotLDCS = (elements, key) => !elements.includes(key)
const elementsAreLDCS = (elements, key) => elements.includes(key)

const mapCountriesToData = (
  countriesKeys,
  countriesValues,
  options = {
    type: OPTION_TYPE_IS_NON_LDC,
    elements: [],
  }
) => {
  const mappedCountries = {}
  const { type, elements } = options
  const fn = type === OPTION_TYPE_IS_LDC ? elementsAreLDCS : elementsAreNotLDCS
  Object.keys(countriesKeys || []).forEach(
    (countryKey) =>
      (mappedCountries[countryKey] = buildCountryData(
        countryKey,
        countriesValues,
        fn(elements, countryKey)
      ))
  )
  return mappedCountries
}

module.exports = {
  mapCountriesToData,
  OPTION_TYPE_IS_LDC,
  OPTION_TYPE_IS_NON_LDC,
}
