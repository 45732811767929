import * as React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, TextField } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

const TableFilter = ({ columns, onFilterChanged }) => {
  const { spacing } = useTheme()

  const tableCells = columns.map((columnDef) => {
    const isNumeric = columnDef.type === 'numeric'

    return (
      <TableCell
        key={columnDef.tableData.id}
        style={{
          padding: spacing(2, 2),
        }}
      >
        <TextField
          placeholder={columnDef.title}
          style={isNumeric ? { float: 'right' } : {}}
          type={isNumeric ? 'number' : 'text'}
          value={columnDef.tableData.filterValue}
          onChange={(evt) =>
            onFilterChanged(columnDef.tableData.id, evt.target.value)
          }
        />
      </TableCell>
    )
  })

  return <TableRow>{tableCells}</TableRow>
}

TableFilter.propTypes = {
  columns: PropTypes.array.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
}

export default TableFilter
