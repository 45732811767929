import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid'
import Done from '@material-ui/icons/Done'
import Fab from '@material-ui/core/Fab'
import InputAdornment from '@material-ui/core/InputAdornment'
import SelectTimeZone from 'screens/orderCreate/components/SelectTimeZone'
import FormRow from 'components/formRow/FormRow'
import TextField from 'containers/formikTextField/FormikTextField'

const defaultProps = {
  type: 'date',
  onClick: (ev) => ev.stopPropagation(),
  fullWidth: true,
  margin: 'normal',
  InputLabelProps: {
    error: false,
  },
}

const SaveButton = () => (
  <Fab
    type="submit"
    color="primary"
    size="small"
    aria-label="Add"
    style={{ alignSelf: 'center' }}
    onClick={(ev) => ev.stopPropagation()}
  >
    <Done />
  </Fab>
)

const DateRange = ({ nominationId }) => (
  <Grid container spacing={2}>
    <Grid item xs>
      <section style={{ maxWidth: '145px' }}>
        <TextField
          {...defaultProps}
          name={`${nominationId}.from`}
          label="From"
        />
      </section>
    </Grid>
    <Grid item xs>
      <section style={{ maxWidth: '145px' }}>
        <TextField {...defaultProps} name={`${nominationId}.to`} label="To" />
      </section>
    </Grid>
  </Grid>
)

DateRange.propTypes = {
  nominationId: PropTypes.string.isRequired,
}

const Name = ({ label, nominationId, timezone, other }) => {
  if (isEmpty(timezone)) {
    return (
      <TextField
        {...defaultProps}
        type="text"
        name={!other ? `${nominationId}.name` : `${nominationId}.description`}
        label={label}
      />
    )
  }

  return (
    <>
      <TextField
        {...defaultProps}
        type="text"
        name={!other ? `${nominationId}.name` : `${nominationId}.description`}
        label={label}
        InputLabelProps={{
          style: { whiteSpace: 'nowrap' },
        }}
      />
      <SelectTimeZone
        name={`${nominationId}.timezone`}
        label="Timezone"
        onClick={(ev) => ev.stopPropagation()}
      />
    </>
  )
}

Name.propTypes = {
  label: PropTypes.string.isRequired,
  nominationId: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  other: PropTypes.bool,
}

const ShipFields = ({ nominationId }) => (
  <FormRow>
    <TextField
      {...defaultProps}
      type="text"
      name={`${nominationId}.name`}
      label="Name"
    />
    <TextField
      {...defaultProps}
      type="number"
      name={`${nominationId}.size`}
      label="Size"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <span>m&sup3;</span>
          </InputAdornment>
        ),
      }}
    />
  </FormRow>
)

ShipFields.propTypes = {
  nominationId: PropTypes.string.isRequired,
}

export { DateRange, Name, ShipFields, SaveButton }
