import styled from 'styled-components'

export const Block = styled.section`
  display: flex;
  flex-direction: column;
  height: ${({ theme }) =>
    `calc(100vh - ${theme.custom.mobile.navbar.height}px)`};
  background-color: ${({ theme }) => theme.palette.background.default};
  padding-top: ${({ theme }) => theme.spacing(1)}px;
`
