import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import { MenuItem, TextField } from '@material-ui/core'
import { get } from 'lodash'

const _Dropdown = ({
  path,
  title,
  onChange,
  currentSelected,
  listOptions,
  clearAfterSelection,
  formik: { setFieldValue, values, initialValues },
  style,
  className,
  margin,
  isDisabled
}) => {
  const [currentOption, setCurrentOption] = useState('')

  useEffect(() => {
    setCurrentOption('')
  }, [currentSelected])

  const handleChange = (target) => {
    const { value, name } = target
    if (!clearAfterSelection) {
      setFieldValue(name, value)
      setCurrentOption(value)
    }
    onChange(target)
  }

  useEffect(() => {
    const currentValue =
      get(values, path) || get(initialValues, path) || currentSelected
    setCurrentOption(currentValue)
  }, [])

  useEffect(() => {
    const selectedValue = get(values, path)
    if (selectedValue) {
      setFieldValue(path, selectedValue)
      setCurrentOption(selectedValue)
    }
  }, [values])

  return (
    <>
      <TextField
        name={path}
        label={title}
        select
        value={currentOption}
        onChange={(evt) => handleChange(evt.target)}
        fullWidth
        style={{ ...style }}
        className={className}
        margin={margin}
        disabled={isDisabled}
      >
        {listOptions.map(({ key, value, label, disabled }) => (
          <MenuItem key={key} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}

_Dropdown.defaultProps = {
  onChange: () => {},
  currentSelected: '',
  listOptions: [],
  clearAfterSelection: false,
  title: '',
  className: '',
  style: {},
  margin: 'none',
}

_Dropdown.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  listOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  currentSelected: PropTypes.string,
  clearAfterSelection: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  margin: PropTypes.string,
}

export const Dropdown = connect(_Dropdown)
