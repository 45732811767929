import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'

import { TRADING_TYPE } from 'emsurge-selectors/constants'
import { AMOUNT } from '../containers/periodRow/PeriodRow.hook'
import { OrderChip } from './OrderChip'
import { MARKET_VISUALISATION_TIMELINE } from 'routes'

const MAX_ORDERS_ON_STACK = 10

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: `0 ${spacing(0.25)}px`,
  },
  askStackContainer: {
    paddingLeft: spacing(0.25),
  },
  name: {
    padding: `0 ${spacing(1)}px`,
    backgroundColor: palette.background.paper,
    borderRadius: 5,
    textAlign: 'center',
    minHeight: 4 * spacing(1),
    fontSize: '.8rem',
  },
  nameText: {
    fontWeight: 'bold',
    flexGrow: 1,
    fontSize: 'inherit',
  },
  paginationButton: {
    padding: `${(3 / 8) * spacing(1)}px ${spacing(1)}px`,
    marginTop: spacing(0.25),

    borderRadius: 5,
    backgroundColor: '#1a222f',
    cursor: 'pointer',
    fontSize: 10,
    color: 'white',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  bidPaginationButton: {
    textAlign: 'right',
  },
  askPaginationButton: {
    textAlign: 'left',
  },
  orderAmount: {
    fontSize: 10,
  },
  ordersContainer: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    height: ({ expanded }) => (expanded ? 'calc(100vh - 270px)' : 'auto'),
  },
  link: {
    textDecoration: 'none',
    color: palette.primary.main,
    '&:hover': {
      color: 'inherit',
    },
  },
}))

export const DatedOrders = ({ filters, name, type, orders, expanded }) => {
  const classes = useStyles({ expanded })

  const bids = orders.filter((order) => order.tradingType === TRADING_TYPE.BID)
  const asks = orders.filter((order) => order.tradingType === TRADING_TYPE.ASK)

  const hasOrders = !isEmpty(orders)

  const shouldTruncateBids = !expanded && bids.length > MAX_ORDERS_ON_STACK
  const shouldTruncateAsks = !expanded && asks.length > MAX_ORDERS_ON_STACK

  return (
    <Grid item container xs={Math.floor(12 / AMOUNT)} className={classes.root}>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        className={classes.name}
      >
        {hasOrders && (
          <Text className={classes.orderAmount}>{bids.length}</Text>
        )}

        <Text className={classes.nameText}>
          {hasOrders ? (
            <Link
              to={`${MARKET_VISUALISATION_TIMELINE}?status=${
                filters.status
              }&type=${type}&period=${name.replace(/\u2013/, '-')}`}
              className={classes.link}
            >
              {name}
            </Link>
          ) : (
            name
          )}
        </Text>

        {hasOrders && (
          <Text className={classes.orderAmount}>{asks.length}</Text>
        )}
      </Grid>

      <div className={classes.ordersContainer}>
        <Grid item container xs={6} alignContent="flex-start">
          {(shouldTruncateBids ? bids.slice(0, MAX_ORDERS_ON_STACK) : bids).map(
            (o) => (
              <OrderChip
                order={o}
                key={`order-bid-${o.id}`}
                type={TRADING_TYPE.BID}
              />
            )
          )}
          {shouldTruncateBids && (
            <Text
              className={`${classes.paginationButton} ${classes.bidPaginationButton}`}
            >
              +{bids.length - MAX_ORDERS_ON_STACK} orders
            </Text>
          )}
        </Grid>

        <Grid
          item
          container
          xs={6}
          alignContent="flex-start"
          className={classes.askStackContainer}
        >
          {(shouldTruncateAsks ? asks.slice(0, MAX_ORDERS_ON_STACK) : asks).map(
            (o) => (
              <OrderChip
                order={o}
                key={`order-ask-${o.id}`}
                type={TRADING_TYPE.ASK}
              />
            )
          )}
          {shouldTruncateAsks && (
            <Text
              className={`${classes.paginationButton} ${classes.askPaginationButton}`}
            >
              +{asks.length - MAX_ORDERS_ON_STACK} orders
            </Text>
          )}
        </Grid>
      </div>
    </Grid>
  )
}

DatedOrders.defaultProps = {
  filters: {},
}

DatedOrders.propTypes = {
  orders: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  filters: PropTypes.object,
}
