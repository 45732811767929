import React from 'react'
import { withRouter } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import Fab from '@material-ui/core/Fab'
import Tooltip from 'components/tooltip/Tooltip'
import { getOrderOrTermsheetTitle } from 'screens/orderView/helpers'

export const EditOrderButton = withRouter(
  ({ order, history, match, style, ...props }) => {
    const goToEditOrder = () => {
      const link = match.path.replace(':id', order.id) + '/edit'
      history.push(link)
    }

    const valueText = getOrderOrTermsheetTitle(order)
    const title = `Edit ${valueText}`

    return (
      <Tooltip title={title}>
        <Fab
          size="small"
          onClick={goToEditOrder}
          style={style}
          data-testid={props['data-testid'] || 'edit-order-button'}
        >
          <EditIcon />
        </Fab>
      </Tooltip>
    )
  }
)
