import React from 'react'
import PropTypes from 'prop-types'
import { isObject } from 'lodash'
import MoreVertical from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import Fab from '@material-ui/core/Fab'
import { PERSON_PERMISSION, ORDER_STATUS } from 'emsurge-selectors'
import { ArchiveTermsheetMenuItem } from '../components/MenuItems'
import Tooltip from 'components/tooltip/Tooltip'
import { useUser } from 'containers/user/useUser'
import { useUserHasPermission } from 'permissions'

const HeaderMoreMenu = ({ termsheet, style, onClose }) => {
  const { user } = useUser()

  const userHasPermission = useUserHasPermission({
    order: termsheet,
    minimumPermission: PERSON_PERMISSION.WRITE,
  })
  const isArchivableByUser =
    user.id === termsheet.personId || user.id === termsheet.brokerPersonId
  const isClosed = termsheet.status === ORDER_STATUS.CLOSED
  const isTraded = termsheet.status === ORDER_STATUS.TRADED

  const [buttonElem, setElement] = React.useState(null)
  const isOpen = isObject(buttonElem)
  const close = () => {
    setElement(null)
    return onClose
  }

  if (!userHasPermission || !isArchivableByUser || isClosed || isTraded) {
    return null
  }

  return (
    <>
      <Tooltip title="Menu">
        <Fab
          data-testid="more-menu-button"
          size="small"
          style={style}
          onClick={(e) => setElement(e.currentTarget)}
        >
          <MoreVertical />
        </Fab>
      </Tooltip>

      <Menu keepMounted anchorEl={buttonElem} open={isOpen} onClose={close}>
        <span>
          <ArchiveTermsheetMenuItem termsheet={termsheet} closeMenu={close} />
        </span>
      </Menu>
    </>
  )
}

HeaderMoreMenu.propTypes = {
  termsheet: PropTypes.object.isRequired,
  style: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default HeaderMoreMenu
