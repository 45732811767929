import { Link } from 'react-router-dom'
import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import { withProps } from 'recompose'
import FormikSubmitButton from 'containers/formikSubmitButton/FormikSubmitButton'

const StyledFormikSubmitButton = styled(FormikSubmitButton)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`
const SubmitButton = withProps({ fullWidth: true })(StyledFormikSubmitButton)

const StyledMuiButton = styled(MuiButton)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`
const LinkButton = withProps({ fullWidth: true, component: Link })(
  StyledMuiButton
)

export { SubmitButton, LinkButton }
