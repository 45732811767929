import { useMutation } from 'react-query'
import { $O } from 'emsurge-selectors'
import { useSnackbar } from 'notistack'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

export const useCreateOrder = () => {
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()

  const postOrder = async (props) => api.createOrder(props)

  const onSuccess = (newOrder) => {
    enqueueSnackbar(
      `Order ${
        $O.template.get(newOrder) ? 'template ' : ''
      }created successfully`,
      { variant: 'success' }
    )
  }

  const onError = (error) => {
    const message = getApiErrorMessage({
      error,
      defaultMessage: 'Order creation failed',
    })
    enqueueSnackbar(message, { variant: 'error' })
  }

  return useMutation(postOrder, {
    onSuccess,
    onError,
    throwOnError: true,
  })
}
