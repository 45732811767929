import React from 'react'
import { connect } from 'formik'
import { Grid } from '@material-ui/core'
import { PRICE_RANGE_PREFIX } from 'emsurge-selectors'
import { VolumeInput } from './VolumeInput'

const _RangePrice = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={7}>
        <VolumeInput
          name={PRICE_RANGE_PREFIX.min}
          label="Min"
          currencyFieldPath={PRICE_RANGE_PREFIX.currency}
          dropdownNrCells={3}
        />
      </Grid>
      <Grid item xs={5}>
        <VolumeInput name={PRICE_RANGE_PREFIX.max} label="Max" />
      </Grid>
    </Grid>
  )
}

export const RangePrice = connect(_RangePrice)
