import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  float: right;
  width: 100%;
`
export const Footer = ({ children, style }) => (
  <StyledFooter style={style}>{children}</StyledFooter>
)

Footer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}
