const VOLUME_TYPE_KEYS = {
  PER_ANNUM: 'PER_ANNUM',
  TOTAL: 'TOTAL',
}

const VOLUME_TYPE_VALUES = {
  [VOLUME_TYPE_KEYS.PER_ANNUM]: 'Per Annum',
  [VOLUME_TYPE_KEYS.TOTAL]: 'Total',
}

const VOLUME_TYPE_OPTIONS = [
  {
    value: VOLUME_TYPE_KEYS.TOTAL,
    label: VOLUME_TYPE_VALUES[VOLUME_TYPE_KEYS.TOTAL],
  },
  {
    value: VOLUME_TYPE_KEYS.PER_ANNUM,
    label: VOLUME_TYPE_VALUES[VOLUME_TYPE_KEYS.PER_ANNUM],
  },
]

module.exports = {
  VOLUME_TYPE_KEYS,
  VOLUME_TYPE_OPTIONS,
  VOLUME_TYPE_VALUES,
}
