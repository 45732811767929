import React from 'react'
import PropTypes from 'prop-types'

import { STAKEHOLDER_TYPE } from 'emsurge-selectors/constants'
import { OnSystem } from './OnSystem'
import { OffSystem } from './OffSystem'

export const Stakeholder = ({ type }) => (
  <>
    {type === STAKEHOLDER_TYPE.ON_SYSTEM && <OnSystem />}
    {type === STAKEHOLDER_TYPE.OFF_SYSTEM && <OffSystem />}
  </>
)

Stakeholder.propTypes = {
  type: PropTypes.oneOf(Object.values(STAKEHOLDER_TYPE)).isRequired,
}
