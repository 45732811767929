const tradingType = require('./trading-type')

const getOrderSummary = (order) => {
  return {
    id: order.shortId ? `#${order.shortId}` : '',
    tradingType: tradingType.text(order).toUpperCase(),
  }
}

module.exports = {
  summary: getOrderSummary,
}
