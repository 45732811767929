import React from 'react'
import { useHistory } from 'react-router'
import { $O, ORDER_STATUS, APP_CONTEXT_TYPE } from 'emsurge-selectors'
import OrderCreateCounter from 'screens/orderCreateCounter/OrderCreateCounter'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { TERMSHEET_TAB, TERMSHEET_TAB_CARBON } from 'screens/termsheetView/ViewTermsheet'
import { useTradeTermsheet } from 'api'

export const OrderMarkAsTraded = () => {
  const history = useHistory()
  const BASE_SLUG = useRouteBaseSlug()
  const { mutateAsync: tradeTermsheet } = useTradeTermsheet()

  const onSave = async (termsheet) => {
    const disabled = [ORDER_STATUS.TRADED, ORDER_STATUS.CLOSED].includes(
      $O.status.get(termsheet)
    )

    if (disabled) {
      throw new Error('Term sheet could not be traded')
    }

    await tradeTermsheet({ termsheetId: termsheet.id })
    const tab = termsheet.context === APP_CONTEXT_TYPE.LNG ? TERMSHEET_TAB.INFORMATION : TERMSHEET_TAB_CARBON.INFORMATION
    history.push(
      `/${BASE_SLUG}/termsheets/${termsheet.id}?tab=${tab}`,
      { referrer: `/${BASE_SLUG}` }
    )
  }

  return (
    <OrderCreateCounter postSubmit={onSave} isCreatingTrade={true}/>
  )
}
