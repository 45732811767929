import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { constant } from 'lodash'
import ModalContent from './components/ModalContent'
import NewNoteForm from './components/NewNoteForm'
import ConnectedFormProvider from './containers/FormProvider'
import Modal from 'components/modal/Modal'

const NoteCreate = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  const open = () => setOpen(true)
  const close = () => setOpen(false)

  return (
    <>
      <Modal open={isOpen}>
        <ConnectedFormProvider onSuccess={close}>
          <ModalContent>
            <NewNoteForm onCancel={close} />
          </ModalContent>
        </ConnectedFormProvider>
      </Modal>

      {children({ open })}
    </>
  )
}

NoteCreate.defaultProps = {
  children: constant(null),
}

NoteCreate.propTypes = {
  children: PropTypes.func,
}

export default NoteCreate
