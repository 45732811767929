import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  PERSON_CLIENT_TYPE,
  PERSON_PERMISSION,
} from 'emsurge-selectors/constants'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { Shell } from './Shell'
import AuthRoute from 'containers/authRoute/AuthRoute'
import { SignIn } from 'screens/signIn/SignIn'
import { Dashboard } from 'screens/orderIndex/Dashboard'
import { ErrorView } from 'screens/errorView'
import { useApi } from 'containers/api/useApi'

import {
  HOME,
  REGISTER_COMPANY,
  ADMIN_VIEW_COMPANIES,
  ADMIN_GENERATE_TOKEN,
  ADMIN_VIEW_COMPANY,
  ADMIN_VIEW_COMPANY_REQUEST,
  ADMIN_CREATE_ACCOUNT,
  ADMIN_VIEW_USERS,
  ADMIN_CREATE_USER,
  ADMIN_CREATE_ENTITY,
  ADMIN_EDIT_COMPANY,
  ADMIN_ADD_COMPANY,
  DASHBOARD,
  ADMIN_VIEW_ENTITY,
  ADMIN_EDIT_ENTITY,
  USER_CREATE_ACCOUNT,
  NEW_PASSWORD,
  NEW_PASSWORD_REQUEST,
  ERROR,
  SETTINGS,
  ORDER_SHARE,
  TRANSACTION_BOOK,
  ADMIN_VIEW_USER,
  NOTES,
  BULLETINS,
  ADMIN_EDIT_USER,
  TRADERS,
  NOMINATIONS,
  ADMIN_VIEW_BROKERS,
  ADMIN_EDIT_BROKER_ENTITIES,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  MARKET_VISUALISATION,
  MARKET_VISUALISATION_TIMELINE,
  FEATURE_FLAGS,
  MARKET_GRID,
  PRICE_CHARTS_BASIS,
  PRICE_CHARTS_FIXED,
  VERSION
} from 'routes'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { AddCustomValidations } from 'utils/AddCustomValidations'
import useAppContext from 'containers/appContext/useAppContext'

import TradersView from 'screens/TradersView/TradersView'
import Settings from 'screens/Settings/Settings'
import ViewNominations from 'screens/nominationsIndex/ViewNominations'
import ViewNotes from 'screens/noteIndex/ViewNotes'
import ViewBulletins from 'screens/bulletinIndex/ViewBulletins'
import ViewCompanies from 'screens/companyIndex/ViewCompanies'
import ViewUsers from 'screens/userIndex/ViewUsers'
import CreateAccount from 'screens/userCreatePassword/CreateAdminAccount'
import ViewCompanyRequest from 'screens/companyViewRequest/ViewCompanyRequest'
import CreateUser from 'screens/userCreate/CreateUser'
import CreateEntity from 'screens/entityCreate/CreateEntity'
import ViewCompany from 'screens/companyView/ViewCompany'
import ViewEntity from 'screens/entityView/ViewEntity'
import EditEntity from 'screens/entityEdit/EditEntity'
import AddCompany from 'screens/companyCreate/AddCompany'
import EditCompany from 'screens/companyEdit/EditCompany'
import UserResetPassword from 'screens/userResetPassword/UserResetPassword'
import UserRequestResetPassword from 'screens/userRequestResetPassword/UserRequestResetPassword'
import RegisterCompany from 'screens/companyRegister/RegisterCompany'
import OrderShare from 'screens/orderShare/OrderShare'
import UserView from 'screens/userView/UserView'
import TransactionBook from 'screens/transactionIndex/TransactionBook'
import UserEdit from 'screens/userEdit/UserEdit'
import BrokerIndex from 'screens/brokersIndex/BrokersIndex'
import BrokerPermissionedEntities from 'screens/brokerPermissionedEntities/BrokerPermissionedEntities'
import PrivacyPolicy from 'screens/privacyPolicy/PrivacyPolicy'
import TermsConditions from 'screens/termsConditions/TermsConditions'
import MarketVisualisation from 'screens/marketVisualisation/MarketVisualisation'
import MarketVisualisationTimeline from 'screens/marketVisualisation/MarketVisualisationTimeline'
import MarketGridIndex from 'screens/marketGridIndex/MarketGridIndex'
import GenerateToken from 'utils/GenerateToken'
import FeatureFlags from 'screens/featureFlags/FeatureFlags'
import PriceCharts from 'screens/priceCharts/PriceCharts'
import PriceChartsFixed from 'screens/priceCharts/PriceChartsFixed'
import Version from 'screens/version/Version'

const USERS = {
  BASE: [
    PERSON_CLIENT_TYPE.BROKER,
    PERSON_CLIENT_TYPE.BROKER_CLIENT,
    PERSON_CLIENT_TYPE.TRADER,
  ],
  SYSTEM: [PERSON_CLIENT_TYPE.SYSTEM],
}

const App = () => {
  const { appContext } = useAppContext()
  const api = useApi()

  const appInsights = useAppInsightsContext()
  appInsights.trackMetric("Component 'App' is in use")

  return (
    <>
      <AddCustomValidations />
      <CssBaseline />
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            <Route exact path={HOME} component={SignIn} />
            <Route exact path={VERSION} component={Version} />
            <Route exact path={REGISTER_COMPANY} component={RegisterCompany} />
            <Route
              exact
              path={ADMIN_CREATE_ACCOUNT}
              component={CreateAccount}
            />
            <Route exact path={USER_CREATE_ACCOUNT} component={CreateAccount} />
            <Route exact path={NEW_PASSWORD} component={UserResetPassword} />
            <Route
              exact
              path={NEW_PASSWORD_REQUEST}
              component={UserRequestResetPassword}
            />
            <Route exact path={ORDER_SHARE} component={OrderShare} />
            <Route exact path={ERROR} component={ErrorView} />

            <Route
              exact
              path="/test-session-expired"
              component={() => {
                api.testSessionExpired()
                return (<h1>Your session has expired. Please, log in again.</h1>)
              }}
            />

            <Route>
              <Shell>
                <Suspense fallback={<LoadingSpinner />}>
                  <Switch key={appContext}>
                    <Route
                      exact
                      path={PRIVACY_POLICY}
                      component={PrivacyPolicy}
                    />
                    <Route
                      exact
                      path={TERMS_CONDITIONS}
                      component={TermsConditions}
                    />

                    <AuthRoute
                      permissions={USERS.BASE}
                      path={FEATURE_FLAGS}
                      component={FeatureFlags}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={DASHBOARD}
                      component={Dashboard}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={NOTES}
                      component={ViewNotes}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={BULLETINS}
                      component={ViewBulletins}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      minimumPermission={PERSON_PERMISSION.TRADE}
                      path={TRADERS}
                      component={TradersView}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={NOMINATIONS}
                      component={ViewNominations}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={TRANSACTION_BOOK}
                      component={TransactionBook}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={MARKET_VISUALISATION_TIMELINE}
                      component={MarketVisualisationTimeline}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={MARKET_VISUALISATION}
                      component={MarketVisualisation}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={PRICE_CHARTS_BASIS}
                      component={PriceCharts}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={PRICE_CHARTS_FIXED}
                      component={PriceChartsFixed}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={MARKET_GRID}
                      component={MarketGridIndex}
                    />
                    <AuthRoute
                      permissions={USERS.BASE}
                      path={SETTINGS}
                      component={Settings}
                      exact
                    />

                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_GENERATE_TOKEN}
                      component={GenerateToken}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_VIEW_COMPANIES}
                      component={ViewCompanies}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_ADD_COMPANY}
                      component={AddCompany}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_VIEW_USERS}
                      component={ViewUsers}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_VIEW_COMPANY_REQUEST}
                      component={ViewCompanyRequest}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_CREATE_USER}
                      component={CreateUser}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_CREATE_ENTITY}
                      component={CreateEntity}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_VIEW_COMPANY}
                      component={ViewCompany}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_EDIT_COMPANY}
                      component={EditCompany}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_VIEW_ENTITY}
                      component={ViewEntity}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_EDIT_ENTITY}
                      component={EditEntity}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_VIEW_USER}
                      component={UserView}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_EDIT_USER}
                      component={UserEdit}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_VIEW_BROKERS}
                      component={BrokerIndex}
                      exact
                    />
                    <AuthRoute
                      permissions={USERS.SYSTEM}
                      path={ADMIN_EDIT_BROKER_ENTITIES}
                      component={BrokerPermissionedEntities}
                      exact
                    />
                    {/* No 404 page for now :( */}
                    <Redirect to="/" />
                  </Switch>
                </Suspense>
              </Shell>
            </Route>
          </Switch>
        </Suspense>
    </>
  )
}

export default App
