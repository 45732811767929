import { useEffect, useCallback, useMemo, useRef } from 'react'
import { connect } from 'formik'
import { setCargoesDateRangeFields } from './DeliveryWindowCargoes.helpers'

const INITIAL_RENDER = 1
export const DeliveryCargosUpdater = connect(({ formik, children }) => {
  const { delivery, volume } = formik.values
  const setFieldValue = useCallback(formik.setFieldValue, [])
  const counter = useRef(0)
  const values = useMemo(
    () => ({
      delivery: {
        customFrom: delivery.customFrom,
        customTo: delivery.customTo,
        period: delivery.period,
        year: delivery.year,
        frequency: delivery.frequency,
        min: delivery.min,
        frequencyDistribution: delivery.frequencyDistribution,
      },
      volume: {
        min: volume.min,
        max: volume.max,
        type: volume.type,
      },
    }),
    [
      delivery.customFrom,
      delivery.customTo,
      delivery.period,
      delivery.year,
      delivery.frequency,
      delivery.min,
      delivery.frequencyDistribution,
      volume.min,
      volume.max,
      volume.type,
    ]
  )

  useEffect(() => {
    counter.current = counter.current + 1

    if (counter.current === INITIAL_RENDER) {
      // bail out on first render to avoid resetting cargos to default values
      return
    }
    setCargoesDateRangeFields({ values, setFieldValue })
  }, [setFieldValue, values])

  return children
})
