import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { useTheme } from '@material-ui/styles'
import { slice, get, find, isNumber } from 'lodash'
import {
  Grid,
  MenuItem,
  InputAdornment,
  TextField as MuiTextField,
} from '@material-ui/core'
import {
  isUndefinedTimeFrameValue,
  isFixedDateTimeFrameValue,
  isDaysAheadTimeFrameValue,
  isMonthAheadTimeFrameValue,
} from '../containers/NominationsInitialValues'
import {
  getTimeFrameValueProps,
  getTimeFrameValueDeliveryWindowOptions,
} from './TimeFrameValueHelpers'
import NominationDate from './NominationDate'
import TextField from 'containers/formikTextField/FormikTextField'

/*
 * This component is a TextField with a dynamic type based on option selected in Time Frame radios.
 * If user selects "days_ahead" should be a number.
 * If user selects "month_ahead" should be a nCargoDeliveryWindowCardumber of the month
 * If user selects "fixed_date" should be a date
 * If user selects "point_trade" the textField should be disabled
 */
const TimeFrameValue = ({ timeframe, mainName, disabled }) => (
  <TextField
    fullWidth
    type="number"
    margin="normal"
    InputLabelProps={{
      error: false,
    }}
    name={`${mainName}.timeframeValue`}
    {...getTimeFrameValueProps(timeframe)}
    required={!isUndefinedTimeFrameValue({ timeframe })}
    disabled={isUndefinedTimeFrameValue({ timeframe }) || disabled}
  />
)

TimeFrameValue.propTypes = {
  timeframe: PropTypes.string,
  mainName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

/*
 * This component is an input time and is rendered only when the selected
 * timeframe is fixed date.
 *
 */

const TimeFrameValueTime = ({ mainName, timezone = '0' }) => {
  return (
    <TextField
      fullWidth
      margin="normal"
      type="time"
      name={`${mainName}.timeframeValueTime`}
      label="Time"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <span style={{ whiteSpace: 'nowrap' }}>
              {timezone === '0' ? 'UTC' : `UTC${timezone}`}
            </span>
          </InputAdornment>
        ),
      }}
    />
  )
}

TimeFrameValueTime.propTypes = {
  mainName: PropTypes.string.isRequired,
  timezone: PropTypes.string,
}

/*
 * This component is a select box and is rendered only when the selected
 * timeframe is XX days ahead.
 *
 */
const TimeFrameValueDeliveryWindow = connect(
  ({ mainName, disabled, formik, index, label }) => {
    const { values, setFieldValue } = formik
    const allOptions = getTimeFrameValueDeliveryWindowOptions({ values })
    const idx = isNumber(index) ? index + 1 : allOptions.length
    const options = slice(allOptions, 0, idx).map((label, index) => ({
      value: `narrowing-window-${index}`,
      label,
      index,
    }))
    const currentDeliveryWindow = get(
      values,
      `${mainName}.timeframeValueDeliveryWindow`
    )
    const matchedOption = find(
      options,
      (option) => option.label === currentDeliveryWindow
    )
    const currentOption = matchedOption || options[index || 0] || {}

    useEffect(() => {
      if (!currentDeliveryWindow || !matchedOption) {
        setFieldValue(
          `${mainName}.timeframeValueDeliveryWindow`,
          currentOption.label
        )
      }
    }, [
      currentDeliveryWindow,
      currentOption.label,
      mainName,
      matchedOption,
      setFieldValue,
    ])

    const onChange = (evt) => {
      const value = evt?.target?.value
      const newOption = options.find((option) => option.value === value)
      setFieldValue(
        `${mainName}.timeframeValueDeliveryWindow`,
        newOption?.label || ''
      )
    }

    return (
      <MuiTextField
        fullWidth
        margin="normal"
        select
        value={currentOption.value}
        onChange={onChange}
        disabled={disabled}
        label={label}
      >
        {options?.map(({ value, label }) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
      </MuiTextField>
    )
  }
)

TimeFrameValueDeliveryWindow.propTypes = {
  mainName: PropTypes.string.isRequired,
  index: PropTypes.number,
}

const TimeFrameValueContainer = ({
  mainName,
  timeframe,
  disabled,
  formik,
  alternativeDeliveryWindowIndex,
}) => {
  const { spacing } = useTheme()
  return (
    <Grid container spacing={2} alignItems="center">
      <section
        style={{
          display: 'flex',
          alignItems: 'baseline',
          width: '100%',
          marginBottom: spacing(2),
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs>
            <TimeFrameValue
              disabled={disabled}
              mainName={mainName}
              timeframe={timeframe}
            />
          </Grid>
          {isFixedDateTimeFrameValue({ timeframe }) && (
            <Grid item xs>
              <TimeFrameValueTime
                timezone={
                  formik?.values?.nominationRules?.deliveryWindow?.timezone
                }
                mainName={mainName}
              />
            </Grid>
          )}
          {isDaysAheadTimeFrameValue({ timeframe }) && (
            <Grid item xs>
              <TimeFrameValueDeliveryWindow
                disabled={disabled}
                index={alternativeDeliveryWindowIndex}
                mainName={mainName}
                label={' '}
              />
            </Grid>
          )}
          {(isDaysAheadTimeFrameValue({ timeframe }) ||
            isMonthAheadTimeFrameValue({ timeframe })) && (
            <Grid item xs={2}>
              <NominationDate title={'Nomination Date'} mainName={mainName} />
            </Grid>
          )}
        </Grid>
      </section>
    </Grid>
  )
}

TimeFrameValueContainer.propTypes = {
  mainName: PropTypes.string.isRequired,
  timeframe: PropTypes.string,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  alternativeDeliveryWindowIndex: PropTypes.number,
}

export default connect(TimeFrameValueContainer)
