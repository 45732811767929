import React from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { FEATURE_FLAGS, useFeatureFlag } from 'containers/featureFlags/'

export const QueryClientInspector = () => {
  const [isEnabled] = useFeatureFlag(FEATURE_FLAGS.REACT_QUERY_DEVTOOLS)

  if (process.env.NODE_ENV !== 'development' || !isEnabled) {
    return null
  }

  return <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
}
