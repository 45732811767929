import moment from 'moment'
import { flatMap, fromPairs, uniq, toPairs, isEmpty, memoize } from 'lodash'
import { useState, useEffect, useMemo, useRef, useCallback } from 'react'

import { typeMap } from './PeriodRow.constants'
import { useForceUpdate } from 'utils/useForceUpdate'
import { useFetch } from 'containers/fetch/useFetch'
import { PERIOD_LOOKUP } from 'components/DeliveryContract'
import { getOrdersByDate } from 'screens/orderIndex/components/MarketInterest/helpers'
import { PERIOD } from 'screens/marketVisualisation/MarketVisualisation.constants'
import { STATUS } from 'components/StatusFilters'
import { fieldFromArray } from 'utils/queryString'

export const AMOUNT = 6
const DEFAULT_OFFSET = 0
const OFFSET_STEP = 1

const now = moment()

const calculateKeys = memoize(
  (type, offset, date) => {
    const {
      keyGenerator = () => '?',
      getWeightOfMarkOfPeriod = () => [],
      keysPerIteration = 1,
    } = typeMap[type] || {}

    const keys = flatMap(
      Array(Math.floor(AMOUNT / keysPerIteration)).fill(''),
      (_, idx) =>
        keyGenerator(moment(date).add(...getWeightOfMarkOfPeriod(offset + idx)))
    )

    return keys
  },
  (type, offset, date) => `${type}${offset}${date.format('YY')}`
)

const getOrdersQuery = (periods = [], years = [], filters = {}) => {
  const periodParameter = periods
    .map((period) => `delivery.period=${period}`)
    .join('&')
  const yearParameter = years.map((year) => `delivery.year=${year}`).join('&')
  const filtersParameter = toPairs(filters)
    .map(([name, value]) => {
      if (isEmpty(value)) {
        return null
      }

      switch (name) {
        case 'type':
          return `delivery.type=${value}`
        case 'ports':
          return fieldFromArray(
            value.map(encodeURIComponent),
            'delivery.location'
          )
        case 'status':
          switch (value) {
            case STATUS.ALL:
              return 'status=live&status=withheld&status=closed'
            case STATUS.ACTIVE:
              return 'status=live&status=withheld'
            case STATUS.ARCHIVED:
              return 'status=closed'
            default:
              return null
          }
        default:
          return null
      }
    })
    .filter((v) => v)
    .join('&')

  return `/orders?${periodParameter}&${yearParameter}&${filtersParameter}&template=false`
}

const extendItemsFromKeys = (keys, oldItems = {}) => {
  const blankItems = fromPairs(
    keys.filter((key) => !oldItems[key]).map((key) => [key, []])
  )
  return { ...blankItems, ...oldItems }
}

export const usePeriodRow = (
  type,
  filters,
  startDate = now,
  retrieveAll = false
) => {
  const { periodValues, name } = typeMap[type] || {}

  const forceUpdate = useForceUpdate()

  const [offset, setOffset] = useState(DEFAULT_OFFSET)
  const [keys, setKeys] = useState(() => {
    return type !== PERIOD.CUSTOM ? calculateKeys(type, offset, startDate) : []
  })

  const orderStorage = useRef(extendItemsFromKeys(keys))

  // Set keys if type is NOT custom
  useEffect(
    () =>
      void (
        type !== PERIOD.CUSTOM &&
        setKeys(calculateKeys(type, offset, startDate))
      ),
    [type, offset, startDate]
  )

  const yearsFromKeys = useMemo(() => {
    if (type === PERIOD.CUSTOM) {
      return []
    }

    const yearEnds = uniq(keys.map((key) => key.slice(-2)))
    return yearEnds.map((year) => moment(year, 'YY').format('YYYY'))
  }, [keys, type])

  const { loading, data: fetchedOrders } = useFetch(
    getOrdersQuery(periodValues, yearsFromKeys, filters)
  )

  useEffect(() => {
    if (type !== PERIOD.CUSTOM) {
      if (!loading && fetchedOrders) {
        const blankItems = extendItemsFromKeys(keys)

        const newItems = fetchedOrders.reduce((acc, order) => {
          const { period, year } = order.delivery
          const displayYear = year.toString().substr(2)
          const key = `${PERIOD_LOOKUP[period]}${displayYear}`

          const existingOrders = acc[key] || []

          return { ...acc, [key]: [order, ...existingOrders] }
        }, blankItems)

        Object.assign(orderStorage.current, newItems)

        forceUpdate()
      }
    }
  }, [fetchedOrders, type, loading, keys, forceUpdate])

  useEffect(() => {
    if (type === PERIOD.CUSTOM) {
      if (!loading && fetchedOrders) {
        const groupedOrders = getOrdersByDate(fetchedOrders).map(
          ([name, orders]) => [name.split('(')[0].trim(), orders]
        )

        const newItems = fromPairs(groupedOrders)

        orderStorage.current = newItems

        setKeys(Object.keys(orderStorage.current))
      }
    }
  }, [fetchedOrders, type, loading])

  const canScrollForward =
    type !== PERIOD.CUSTOM ||
    offset + AMOUNT < Object.keys(orderStorage.current).length
  const canScrollBackward = type !== PERIOD.CUSTOM || offset - OFFSET_STEP >= 0

  const nextPeriod = useCallback(
    () => canScrollForward && setOffset((o) => o + OFFSET_STEP),
    [canScrollForward]
  )
  const prevPeriod = useCallback(
    () => canScrollBackward && setOffset((o) => o - OFFSET_STEP),
    [canScrollBackward]
  )

  let keysToPickOrders = keys

  if (type === PERIOD.CUSTOM && !retrieveAll) {
    keysToPickOrders = keys.slice(offset, offset + AMOUNT)
  }

  const orders = keysToPickOrders.reduce(
    (acc, key) => ({ ...acc, [key]: orderStorage.current[key] || [] }),
    {}
  )

  return {
    name,
    loading,
    orders,
    keys,
    nextPeriod,
    prevPeriod,
    canScrollForward,
    canScrollBackward,
  }
}
