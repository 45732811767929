const bulletin = require('./bulletin')
const order = require('./order')
const terms = require('./terms')
const user = require('./user')

module.exports = {
  ...bulletin,
  ...order,
  ...terms,
  ...user,
}
