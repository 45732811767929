import React from 'react'
import PropTypes from 'prop-types'
import {
  TERM_FIXED_ROLLING,
  TERM_FIXED_ROLLING_PREFIX,
} from 'emsurge-selectors'
const { RadioButtonGroup } = require('./RadioButtonGroup')

export const TermFixedRolling = ({ title }) => {
  return (
    <RadioButtonGroup
      title={title}
      options={TERM_FIXED_ROLLING}
      fieldName={TERM_FIXED_ROLLING_PREFIX}
      style={{ marginTop: '8px' }}
    />
  )
}

TermFixedRolling.defaultProps = {
  title: '',
}

TermFixedRolling.propTypes = {
  title: PropTypes.string,
}
