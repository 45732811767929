import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import { OptionBubble } from './OptionBubble'

const MAXIMUM = 999999999

const _SelectedOptions = ({ options, onDelete, maxItems }) => {
  const [listOptions, setListOptions] = useState([])

  useEffect(() => {
    setListOptions(options.slice(0, maxItems))
  }, [options, maxItems])

  return listOptions.map(({ key, value, focus }) => (
    <OptionBubble
      key={key}
      id={key}
      text={value}
      onDelete={onDelete}
      focus={focus}
    />
  ))
}

_SelectedOptions.defaultProps = {
  options: [],
  onDelete: () => {},
  maxItems: MAXIMUM,
}

_SelectedOptions.propTypes = {
  options: PropTypes.array,
  onDelete: PropTypes.func,
  maxItems: PropTypes.number,
}

export const SelectedOptions = connect(_SelectedOptions)
