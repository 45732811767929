import React from 'react'
import PropTypes from 'prop-types'
import ClearIcon from '@material-ui/icons/ClearRounded'
import Tooltip from 'components/tooltip/Tooltip'

const CloseButton = ({ onClick, style, size }) => (
  <Tooltip title="Close">
    <div
      style={style}
      onClick={onClick}
      id="closeOrderModalButton"
      data-testid="close-modal-button"
    >
      <ClearIcon color="inherit" fontSize={size} />
    </div>
  </Tooltip>
)

CloseButton.defaultProps = {
  style: {
    marginLeft: 5,
    height: 35,
    color: '#fff',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  size: 'large',
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  size: PropTypes.string,
}

export default CloseButton
