import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { constant } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import ModalHeader from './desktop/ModalHeader'
import ModalContent from './desktop/ModalContent'
import { CreateOrderModal } from './mobile/CreateOrderModal'
import FormProvider from './containers/FormProvider'
import {
  OrderProvider,
  useActiveStep,
  TAB,
  STEP,
  useOrderFlow,
  ORDER_FLOW,
} from 'containers/OrderProvider'
import Modal from 'components/modal/Modal'
import { useModal } from 'utils/useModal'
import { Mobile, Tablet } from 'components/Viewport'

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
  },
})

const CreateOrder = ({ children }) => {
  const { isOpen, open, close } = useModal()
  const {
    navigate: { goTo },
  } = useActiveStep()
  const classes = useStyles()
  const { setFlow } = useOrderFlow()

  useEffect(() => setFlow(ORDER_FLOW.CREATE), [setFlow])

  const onClose = () => {
    goTo({ tab: TAB.OVERVIEW, step: STEP[TAB.OVERVIEW].GENERAL })
    close()
  }

  return (
    <>
      <Modal open={isOpen} paperProps={{ classes }}>
        <FormProvider postSubmit={onClose}>
          <>
            <Tablet matchLarger>
              <ModalHeader
                showSaveAsTemplateOption
                onCancel={onClose}
              />
              <ModalContent />
            </Tablet>
            <Mobile>
              <CreateOrderModal onCancel={onClose} />
            </Mobile>
          </>
        </FormProvider>
      </Modal>

      {children({ open })}
    </>
  )
}

CreateOrder.defaultProps = {
  children: constant(null),
}

CreateOrder.propTypes = {
  children: PropTypes.func,
}

const _CreateOrder = (props) => (
  <OrderProvider>
    <CreateOrder {...props} />
  </OrderProvider>
)

export default _CreateOrder
