import {
  CARBON_STANDARD_PATHS,
  STAKEHOLDER_TYPE,
  PROGRAM_ELIGIBILITY_PREFIX,
  PROGRAM_ELIGIBILITY_PATHS,
  CLASSIFICATIONS_FIRST_PREFIX,
  CLASSIFICATIONS,
  CLASSIFICATIONS_SECOND_PREFIX,
  TITLE_TRANSFER_PREFIX,
  TITLE_TRANSFER_ATTRIBUTES,
  TERM_SPOT_PREFIX,
  TERM_SPOT_PERIOD_PREFIX,
  TERM_SPOT_OFFSET_PREFIX,
  CARBON_PERIOD,
  TERM_SPOT_VINTAGE_FIXED_PREFIX,
  TERM_FIXED_ROLLING_PREFIX,
  CARBON_STANDARD_PREFIX,
  TERM_SPOT_VINTAGE_ROLLING_PREFIX,
  TERM_SPOT_VINTAGE_PROJECT_INFORMATION,
  PROJECT_INFORMATION_KEYS,
  TERM_SPOT_KEYS,
  TERM_FIXED_ROLLING_KEYS,
  VOLUME_AMOUNT_PREFIX,
  VOLUME_MIN_PARCEL_SIZE_PREFIX,
  VOLUME_TYPE_PREFIX,
  VOLUME_TYPE_KEYS,
  VOLUME_OFFSET_PREFIX,
  VOLUME_OFFSET,
  VOLUME_RANGE_PREFIX,
  VOLUME_OFFSET_PATHS,
  PRICE_TYPE_PREFIX,
  PRICE_TYPE_KEYS,
  PRICE_RANGE_PREFIX,
  PRICE_RFQ_PREFIX,
  PRICE_FIXED_PREFIX,
  PRICE_FLOATING_PREFIX,
  PRICE_TBD_PREFIX,
  PRICE_VOLUME_DEPENDENT_PREFIX,
  RATING_PATHS,
  RATING_PREFIX,
  PRICE_VOLUME_DEPENDENT_TYPE_KEYS,
  DELIVERED_BY_KEY,
  DELIVERED_BY_PREFIX,
} from 'emsurge-selectors'
import { set } from 'lodash'
import moment from 'moment'
import { computeVintageYear } from './helpers'

const DEFAULT_VINTAGE_YEAR_FROM = 2016
const DEFAULT_VINTAGE_YEAR_TO = 2020
const DEFAULT_START_MONTH = 1
const DEFAULT_END_MONTH = 12

const getStep1 = () => ({
  submissionDate: new Date().toISOString(),
  behalfOf: STAKEHOLDER_TYPE.ON_SYSTEM,
  myCompany: {
    entityId: '',
  },
  thirdParty: {
    companyName: '',
    entityName: '',
    contact: '',
  },
  tradingType: 'bid',
  status: 'pending',
  validity: {},
  details: '',
  template: false,
})

const getStep2 = () => {
  const initialValues = {}
  set(initialValues, PROGRAM_ELIGIBILITY_PREFIX, {})
  set(initialValues, CARBON_STANDARD_PREFIX, {})
  set(initialValues, RATING_PREFIX, {})
  set(
    initialValues,
    TITLE_TRANSFER_PREFIX,
    TITLE_TRANSFER_ATTRIBUTES.sellerTransfers.key
  )
  set(initialValues, CLASSIFICATIONS_FIRST_PREFIX, CLASSIFICATIONS.ALL.key)
  set(initialValues, CLASSIFICATIONS_SECOND_PREFIX, CLASSIFICATIONS.RA.key)
  return initialValues
}

const getDateFormatted = (year, month) => {
  const DATE_FORMAT = 'YYYY-MM'
  return moment(new Date(year, month)).format(DATE_FORMAT)
}

const getDatesInitialValues = (
  period,
  yearVintage,
  yearFrom,
  yearTo,
  monthFrom,
  monthTo
) => {
  return {
    period,
    year: yearVintage,
    customFrom: getDateFormatted(yearFrom, monthFrom - 1),
    customTo: getDateFormatted(yearTo, monthTo - 1),
  }
}

const getRollingInfo = () => {
  return {
    within: 36,
    year: computeVintageYear(),
  }
}

const getStep3 = () => {
  const initialValues = {}
  const nowPlusYear = moment().add(2, 'year')
  const now = moment()
  const year = now.year()
  const vintageYear = computeVintageYear()
  set(initialValues, TERM_SPOT_PREFIX, TERM_SPOT_KEYS.SPOT)
  set(
    initialValues,
    TERM_SPOT_PERIOD_PREFIX,
    getDatesInitialValues(
      CARBON_PERIOD.CAL_YEAR,
      vintageYear,
      DEFAULT_VINTAGE_YEAR_FROM,
      DEFAULT_VINTAGE_YEAR_TO,
      DEFAULT_START_MONTH,
      DEFAULT_END_MONTH
    )
  )
  set(
    initialValues,
    TERM_SPOT_OFFSET_PREFIX,
    getDatesInitialValues(
      CARBON_PERIOD.CUSTOM,
      vintageYear,
      year,
      nowPlusYear.year(),
      DEFAULT_START_MONTH,
      DEFAULT_END_MONTH
    )
  )
  set(
    initialValues,
    TERM_SPOT_VINTAGE_FIXED_PREFIX,
    getDatesInitialValues(
      CARBON_PERIOD.Y_MINUS_1,
      vintageYear,
      DEFAULT_VINTAGE_YEAR_FROM,
      DEFAULT_VINTAGE_YEAR_TO,
      DEFAULT_START_MONTH,
      DEFAULT_END_MONTH
    )
  )
  set(initialValues, TERM_FIXED_ROLLING_PREFIX, TERM_FIXED_ROLLING_KEYS.FIXED)
  set(initialValues, TERM_SPOT_VINTAGE_ROLLING_PREFIX, getRollingInfo())
  set(
    initialValues,
    `${TERM_SPOT_VINTAGE_PROJECT_INFORMATION}.${PROJECT_INFORMATION_KEYS.ACTIVE}`,
    true
  )
  set(initialValues, DELIVERED_BY_PREFIX, DELIVERED_BY_KEY.SPOT)
  return initialValues
}

const getStep4 = () => {
  const initialValues = {}
  const DEFAULT_VOLUME_AMOUNT = 100000
  set(initialValues, VOLUME_AMOUNT_PREFIX, DEFAULT_VOLUME_AMOUNT)
  set(initialValues, VOLUME_TYPE_PREFIX, VOLUME_TYPE_KEYS.TOTAL)
  set(
    initialValues,
    `${VOLUME_OFFSET_PREFIX}.${VOLUME_OFFSET.ALL_OR_NOTHING}`,
    true
  )
  set(initialValues, `${VOLUME_RANGE_PREFIX}.first`, DEFAULT_VOLUME_AMOUNT)
  set(initialValues, `${VOLUME_RANGE_PREFIX}.last`, DEFAULT_VOLUME_AMOUNT)
  return initialValues
}

const getStep5 = () => {
  const initialValues = {}
  const currentDate = moment(new Date()).format('YYYY-MM-DD')
  set(initialValues, PRICE_TYPE_PREFIX, PRICE_TYPE_KEYS.FIXED)
  set(initialValues, PRICE_RANGE_PREFIX, {})
  set(initialValues, PRICE_RFQ_PREFIX.deadline.date, currentDate)
  set(initialValues, PRICE_RFQ_PREFIX.deadline.time, '00:00')
  set(initialValues, PRICE_RFQ_PREFIX.award.date, currentDate)
  set(initialValues, PRICE_RFQ_PREFIX.award.time, '00:00')
  set(initialValues, PRICE_VOLUME_DEPENDENT_PREFIX, [
    { type: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.UP_TO.key },
  ])
  return initialValues
}

export const getStep1Paths = () => [
  'behalfOf',
  'myCompany.entityId',
  'myCompany.traderId',
  'thirdParty.companyName',
  'thirdParty.entityName',
  'thirdParty.contact',
  'tradingType',
  'status',
  'validity.until',
  'validity.date',
  'validity.time',
  'details',
  'buyer.company.name',
  'buyer.entity.name',
  'buyer.person.name',
  'seller.company.name',
  'seller.entity.name',
  'seller.person.name',
]

const getProgramEligibilityPaths = () => PROGRAM_ELIGIBILITY_PATHS
const getCarbonStandardPaths = () => CARBON_STANDARD_PATHS
const getRatingPaths = () => RATING_PATHS

export const getStep2Paths = () => [
  ...getProgramEligibilityPaths(),
  ...getCarbonStandardPaths(),
  ...getRatingPaths(),
]

const mapPathField = (options, path) =>
  options.map((fieldName) => [path, fieldName].join('.'))
const fillDateCustomPath = (path) =>
  mapPathField(['period', 'customFrom', 'customTo'], path)
const fillDateQuarterYearPath = (path) => mapPathField(['period', 'year'], path)
const getDatePath = (path) => [
  ...fillDateCustomPath(path),
  ...fillDateQuarterYearPath(path),
]

export const getStep3Paths = () => [
  TERM_SPOT_PREFIX,
  TERM_FIXED_ROLLING_PREFIX,
  TERM_SPOT_VINTAGE_PROJECT_INFORMATION,
  ...getDatePath(TERM_SPOT_PERIOD_PREFIX),
  ...getDatePath(TERM_SPOT_OFFSET_PREFIX),
  ...getDatePath(TERM_SPOT_VINTAGE_FIXED_PREFIX),
  ...mapPathField(['within', 'year'], TERM_SPOT_VINTAGE_ROLLING_PREFIX),
]

export const getStep4Paths = () => [
  VOLUME_AMOUNT_PREFIX,
  VOLUME_MIN_PARCEL_SIZE_PREFIX,
  VOLUME_TYPE_PREFIX,
  ...VOLUME_OFFSET_PATHS,
  ...mapPathField(['first', 'last'], VOLUME_RANGE_PREFIX),
]

export const getStep5Paths = () => [
  PRICE_TYPE_PREFIX,
  PRICE_FIXED_PREFIX,
  PRICE_FLOATING_PREFIX,
  PRICE_TBD_PREFIX,
  PRICE_RFQ_PREFIX.notes,
  ...Object.values(PRICE_RANGE_PREFIX),
  ...Object.values(PRICE_RFQ_PREFIX.deadline),
  ...Object.values(PRICE_RFQ_PREFIX.award),
  PRICE_VOLUME_DEPENDENT_PREFIX,
]

export const getEssentialInitialValues = () => ({
  ...getStep1(),
  ...getStep2(),
  ...getStep3(),
  ...getStep4(),
  ...getStep5(),
})

export const getEssentialPaths = () => [
  ...getStep1Paths(),
  ...getStep2Paths(),
  ...getStep3Paths(),
  ...getStep4Paths(),
  ...getStep5Paths(),
]
