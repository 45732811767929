import React from 'react'
import PropTypes from 'prop-types'
import useWindowSize from 'react-use/lib/useWindowSize'
import { useTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { $O } from 'model/order/selectors/selector'
import { BrandName, BrandLogo } from 'components/logo'
import LinkButton from 'components/linkButton/LinkButton'
import { TabsWrapper } from 'screens/orderView/components/ModalHeader'
import { Wrapper } from 'components/ModalHeader/layout'

const EmsurgeLogo = () => {
  const { palette, spacing } = useTheme()

  return (
    <Grid container alignItems="center" style={{ width: 'auto', height: 64 }}>
      <BrandLogo
        width={64}
        backgroundColor={palette.primary.main}
        style={{ marginRight: spacing(2) }}
      />
      <BrandName height={36} style={{ position: 'relative', top: 5 }} />
    </Grid>
  )
}

const ModalHeader = ({ order, activeTab, setTab }) => {
  const { spacing } = useTheme()
  const handleTabChange = (_, tabIndex) => setTab(tabIndex)
  const { width } = useWindowSize()

  return (
    <header>
      {/* UPPER SECTION */}
      <Wrapper style={{ display: 'flex', alignItems: 'center' }}>
        {width > 600 && <EmsurgeLogo />}

        <div style={{ marginLeft: width > 600 ? 'auto' : 0 }}>
          <LinkButton
            to="/"
            variant="contained"
            color="primary"
            style={{ marginRight: spacing(1) }}
          >
            Login
          </LinkButton>
        </div>
      </Wrapper>

      {/* Tabs */}
      <TabsWrapper>
        <Tabs
          indicatorColor="primary"
          value={activeTab}
          onChange={handleTabChange}
        >
          {$O.swapLeg.exists(order) ? (
            [
              <Tab key="0" label={`LEG 1 #${order.shortId}`} />,
              <Tab key="1" label={`LEG 2 #${order.swapOrder.shortId}`} />,
            ]
          ) : (
            <Tab label={`Order #${order.shortId}`} />
          )}
        </Tabs>
      </TabsWrapper>
    </header>
  )
}

ModalHeader.propTypes = {
  order: PropTypes.object.isRequired,
  activeTab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
}

export default ModalHeader
