import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

export const StyledGrid = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;

  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'cursor')};
  }
`
