const BULLETIN_MESSAGE_TYPE = Object.freeze({
  NEWS: 'news',
  BID: 'bid',
  OFFER: 'offer',
  EVENT: 'event',
  ORDER: 'order',
  COMMENT: 'comment',
  MARKETS: 'markets',
})

const BULLETIN_MESSAGE_TYPE_TEXT = Object.freeze({
  [BULLETIN_MESSAGE_TYPE.NEWS]: 'News',
  [BULLETIN_MESSAGE_TYPE.BID]: 'Bid',
  [BULLETIN_MESSAGE_TYPE.OFFER]: 'Offer',
  [BULLETIN_MESSAGE_TYPE.EVENT]: 'Event',
  [BULLETIN_MESSAGE_TYPE.ORDER]: 'Order',
  [BULLETIN_MESSAGE_TYPE.COMMENT]: 'Comment',
  [BULLETIN_MESSAGE_TYPE.MARKETS]: 'Markets',
})

module.exports = {
  BULLETIN_MESSAGE_TYPE,
  BULLETIN_MESSAGE_TYPE_TEXT,
}
