import React from 'react'
import Text from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { useFetch } from 'containers/fetch/useFetch'

const GenerateToken = ({ location }) => {
  const { email } = queryString.parse(location.search)
  const { loading, data } = useFetch(`/password/generateToken?email=${email}`)
  if (loading) {
    return 'loading'
  }
  if (data.message) {
    const url = `${window.location.origin}/password/new?token=${data.message}`
    return (
      <>
        <Text variant="body2">Password reset link</Text>
        <a href={url}>{url}</a>
      </>
    )
  }
}

export default withRouter(GenerateToken)
