import React from 'react'
import TextField from 'containers/formikTextField/FormikTextField'
import FormRow from 'components/formRow/FormRow'

const defaultProps = {
  type: 'number',
  fullWidth: true,
  margin: 'normal',
  InputLabelProps: {
    error: false,
  },
}

export const Other = () => (
  <>
    <FormRow>
      <TextField
        {...defaultProps}
        name="nominationRules.demurage"
        label="Demurrage"
        helperText="USD per day pro rata"
      />
      <TextField
        {...defaultProps}
        name="nominationRules.boilOff"
        label="Boil-Off"
        helperText="% per day pro rata"
      />
      <TextField
        {...defaultProps}
        name="nominationRules.laytime"
        label="Allowed Laytime"
        helperText="Consecutive hours"
      />
      <TextField
        {...defaultProps}
        name="nominationRules.fsruFsu"
        label="FSRU/FSU"
        helperText="Consecutive hours"
      />
    </FormRow>

    <TextField
      fullWidth
      margin="normal"
      multiline
      rows={3}
      name="nominationRules.demurageNote"
      label="Demurrage Note"
    />
  </>
)
