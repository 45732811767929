import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Text from '@material-ui/core/Typography'

const useMultilineTextStyles = makeStyles(() => ({
  multiline: {
    whiteSpace: 'pre-wrap',
  },
}))

const MultilineText = ({ children, gutterBottom, ...textProps }) => {
  const classes = useMultilineTextStyles()

  return (
    <Text
      variant="body1"
      gutterBottom={gutterBottom}
      className={classes.multiline}
      {...textProps}
    >
      {children}
    </Text>
  )
}

MultilineText.defaultProps = {
  gutterBottom: false,
}

MultilineText.propTypes = {
  children: PropTypes.node.isRequired,
  gutterBottom: PropTypes.bool,
}

export default MultilineText
