const moment = require('moment')

const { getOrdinalSuffix } = require('../../helpers')
const {
  PARTY,
  PARTY_TEXT,
  NOMINATION_TIMEFRAME,
  NOMINATION_TIMEFRAME_TEXT,
} = require('../../constants')

const getTimeframe = (term) => {
  const {
    timeframe,
    timeframeValue,
    timeframeValueDeliveryWindow,
    toBeNominated = false,
  } = term

  if (!toBeNominated) {
    return {}
  }

  const nominationTimeframe = {
    type: timeframe,
    deliveryWindow: timeframeValueDeliveryWindow,
  }

  switch (timeframe) {
    case NOMINATION_TIMEFRAME.DAYS_AFTER:
    case NOMINATION_TIMEFRAME.DAYS_AHEAD:
    case NOMINATION_TIMEFRAME.MONTH_AHEAD:
      nominationTimeframe.value = parseInt(timeframeValue)
      break
    case NOMINATION_TIMEFRAME.FIXED_DATE:
      nominationTimeframe.value = new Date(timeframeValue)
      break
    case NOMINATION_TIMEFRAME.POINT_TRADE:
    default:
      nominationTimeframe.value = undefined
  }

  return nominationTimeframe
}

const getTimeframeSummary = (term) => {
  const { deliveryWindow, type, value } = getTimeframe(term)

  let deliveryWindowText

  if (deliveryWindow) {
    deliveryWindowText = deliveryWindow.substring(
      deliveryWindow.lastIndexOf('(') + 1,
      deliveryWindow.lastIndexOf(')')
    )
  } else {
    deliveryWindowText = ''
  }

  return {
    deliveryWindow: deliveryWindowText,
    type: NOMINATION_TIMEFRAME_TEXT[type] || '',
    value: value || '',
  }
}

const getTimeframeText = (term) => {
  const { type } = getTimeframe(term)
  const { deliveryWindow, value } = getTimeframeSummary(term)
  let timeframeText

  switch (type) {
    case NOMINATION_TIMEFRAME.DAYS_AFTER:
      timeframeText = `${value} days after the point of trade`
      break
    case NOMINATION_TIMEFRAME.DAYS_AHEAD:
      timeframeText = `no later than ${value} days prior to the start of the first day of the ${deliveryWindow}`
      break
    case NOMINATION_TIMEFRAME.MONTH_AHEAD:
      timeframeText = `no later than the ${value}${getOrdinalSuffix(
        value
      )} day of m-1, where m is the scheduled delivery month`
      break
    case NOMINATION_TIMEFRAME.FIXED_DATE:
      timeframeText = `no later than ${moment(value).format('DD MMM YYYY')}`
      break
    case NOMINATION_TIMEFRAME.POINT_TRADE:
      timeframeText = 'at the point of trade'
      break
    default:
      timeframeText = ''
  }

  return timeframeText
}

const getParty = (term) => {
  const { party = PARTY.TBA, toBeNominated = false } = term

  if (!toBeNominated) {
    return undefined
  }

  return party
}

const getPartyText = (term) => {
  const party = getParty(term)

  return PARTY_TEXT[party] || ''
}

const getNomination = (term) => {
  if (!term.toBeNominated) {
    return {}
  }

  return {
    timeframe: getTimeframe(term),
    party: getParty(term),
  }
}

const getNominationSummary = (term) => {
  const timeframeSummary = getTimeframeSummary(term)
  const partyText = getPartyText(term)

  return {
    timeframe: timeframeSummary,
    party: partyText,
  }
}

const getNominationText = (
  term,
  { prefix = 'The', name = '', nominate = 'nominate' }
) => {
  if (!term.toBeNominated) {
    return ''
  }

  const partyText = getPartyText(term)
  const timeframeText = getTimeframeText(term)

  return `${prefix} ${partyText} shall ${nominate} the ${name} ${timeframeText}`
}

module.exports = {
  get: getNomination,
  summary: getNominationSummary,
  text: getNominationText,
  timeframe: {
    get: getTimeframe,
    summary: getTimeframeSummary,
    text: getTimeframeText,
  },
  party: {
    get: getParty,
    text: getPartyText,
  },
}
