import { useLocation } from 'react-router-dom'
import { DASHBOARD } from 'routes'

export const useRouteBaseSlug = () => {
  const location = useLocation()
  return getBaseSlug(location)
}

export const getBaseSlug = (location) => {
  const [, BASE_SLUG] = location.pathname.split('/')
  return BASE_SLUG || DASHBOARD.slice(1)
}
