import React from 'react'
import PropTypes from 'prop-types'

import { COMMENT_TYPE } from 'emsurge-selectors/constants'
import { useSnackbar } from 'notistack'
import { useQueryClient } from 'react-query'
import { Comments } from 'components/Comments'
import { isTermsheet } from 'model/termsheet/isTermsheet'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { ORDER_KEY } from 'api/constants'

const getCommentTypeFromOrder = (order) => {
  return isTermsheet(order) ? COMMENT_TYPE.CLARIFICATION : COMMENT_TYPE.COMMENT
}

const CommentsPanel = ({ order, label, headerColor }) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const api = useApi()
  const addComment = async (newComment) => {
    const data = {
      text: newComment,
      type: getCommentTypeFromOrder(order),
    }
    try {
      await api.post(`/orders/${order.id}/comment`, data)
      queryClient.invalidateQueries([ORDER_KEY, order.id])
      enqueueSnackbar('Comment posted successfully', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Comment creation not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <Comments
      comments={order.comments}
      headerColor={headerColor}
      label={label}
      addComment={addComment}
    />
  )
}

CommentsPanel.propTypes = {
  order: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  headerColor: PropTypes.string,
}

export default CommentsPanel
