import React from 'react'
import PropTypes from 'prop-types'
import { flatten } from 'lodash'
import { Grid, Typography as Text, Link } from '@material-ui/core'
import { StyledGrid } from './StyledGrid'
import { Currency } from 'screens/orderCreateCarbon/components/Currency'

const DetailSingleRow = ({
  style,
  info,
  gutterBottom,
  showCurrency = false,
  showVolume = false,
  showLink = false,
  ...textProps
}) => {
  const text = showVolume ? (info || '').split('tCO2e') : [info]
  return (
    <Grid item xs={12} sm={9}>
      {text.map((t, idx) => (
        <span key={`detail-row-group-${idx}`}>
          {showLink ?
          (<Link
            key={`detail-row-text-${idx}`}
            href={t}
            target="_blank"
            style={{
              ...style,
              overflowWrap: 'break-word',
              maxWidth: '100%',
              float: 'left',
            }}
            variant="body1"
            gutterBottom={gutterBottom}
            {...textProps}
          >
            {t}
          </Link>)
          : (<Text
            key={`detail-row-text-${idx}`}
            style={{
              ...style,
              overflowWrap: 'break-word',
              maxWidth: '100%',
              float: 'left',
            }}
            variant="body1"
            gutterBottom={gutterBottom}
            {...textProps}
          >
            {t}
          </Text>)}
          {showVolume && idx < text.length - 1 && (
            <Currency
              unit="unit"
              key={`detail-row-currency-${idx}`}
              style={{
                display: 'inline',
                float: 'left',
                marginLeft: '8px',
              }}
            />
          )}
        </span>
      ))}
      {showCurrency && (
        <Currency
          unit="currency"
          style={{
            float: 'left',
            display: 'block',
          }}
        />
      )}
    </Grid>
  )
}

DetailSingleRow.propTypes = {
  info: PropTypes.node,
  gutterBottom: PropTypes.bool,
  style: PropTypes.object,
  showCurrency: PropTypes.bool,
  showVolume: PropTypes.bool,
  showLink: PropTypes.bool
}

const DetailsByColumn = ({ style, info, gutterBottom, ...textProps }) => (
  <>
    {info.map((text, idx) => (
      <Grid key={idx} item xs>
        <Text
          style={style}
          variant="body1"
          gutterBottom={gutterBottom}
          {...textProps}
        >
          {flatten([text]).join('\n')}
        </Text>
      </Grid>
    ))}
  </>
)

DetailsByColumn.propTypes = {
  info: PropTypes.array,
  gutterBottom: PropTypes.bool,
  style: PropTypes.object,
}

const DetailsByRow = ({ style, info, gutterBottom, ...textProps }) => (
  <Grid item xs>
    {info.map((text, idx) => (
      <Text
        key={idx}
        style={style}
        variant="body1"
        gutterBottom={gutterBottom}
        {...textProps}
        data-testid={
          textProps?.['data-testid'] &&
          `details-by-row-${textProps['data-testid']}-${idx}`
        }
      >
        {text}
      </Text>
    ))}
  </Grid>
)

DetailsByRow.propTypes = {
  info: PropTypes.array,
  gutterBottom: PropTypes.bool,
  style: PropTypes.object,
}

const DetailRow = ({
  style,
  title,
  info,
  gutterBottom,
  infoInColumns,
  onClick,
  showCurrency,
  showVolume,
  showLink,
  ...textProps
}) => (
  <StyledGrid onClick={onClick} container>
    <Grid item xs={12} sm={3}>
      <Text
        variant="body2"
        gutterBottom={gutterBottom}
        style={{ fontWeight: 700, ...style }}
      >
        {title}
      </Text>
    </Grid>

    {Array.isArray(info) ? (
      infoInColumns ? (
        <DetailsByColumn
          style={style}
          info={info}
          gutterBottom={gutterBottom}
          {...textProps}
        />
      ) : (
        <DetailsByRow
          style={style}
          info={info}
          gutterBottom={gutterBottom}
          {...textProps}
        />
      )
    ) : (
      <DetailSingleRow
        style={style}
        info={info}
        gutterBottom={gutterBottom}
        showCurrency={showCurrency}
        showVolume={showVolume}
        showLink={showLink}
        {...textProps}
      />
    )}
  </StyledGrid>
)

DetailRow.defaultProps = {
  gutterBottom: false,
  infoInColumns: true,
  title: '',
  showCurrency: false,
  showVolume: false,
  showLink: false
}

DetailRow.propTypes = {
  title: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  gutterBottom: PropTypes.bool,
  style: PropTypes.object,
  infoInColumns: PropTypes.bool,
  onClick: PropTypes.func,
  showCurrency: PropTypes.bool,
  showVolume: PropTypes.bool,
  showLink: PropTypes.bool
}

export default DetailRow
