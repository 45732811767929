import React, { useState, useCallback, useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { isFunction, isEqual } from 'lodash'
import { useRouter } from 'utils/useRouter'

export const TAB = {
  OVERVIEW: 0,
  TERMS: 1,
  RECAP: 2,
  COMMENTS: 3,
}

export const TAB_CARBON = {
  ESSENTIAL: 0,
  OPTIONAL: 1,
  RECAP: 2,
  COMMENTS: 3,
}

export const TAB_CARBON_TEXT = {
  ESSENTIAL: 'Essential',
  OPTIONAL: 'Optional',
  RECAP: 'Recap',
  COMMENTS: 'Comments',
}

export const STEP = {
  [TAB.OVERVIEW]: {
    GENERAL: 0,
    DELIVERY: 1,
    PRICE: 2,
    CARGO_SIZE: 3,
    QUALITY_GHV: 4,
  },
  [TAB.TERMS]: {
    DELIVERY_WINDOW: 0,
    DELIVERY_PORT: 1,
    LOAD_PORT: 2,
    LNG_SHIP: 3,
    OTHER_SHIPPING: 4,
    OTHER_TERMS: 5,
  },
}

export const STEP_CARBON_ESSENTIAL = {
  GENERAL: 0,
  TYPE: 1,
  TERM: 2,
  VOLUME: 3,
  PRICE: 4,
}

export const STEP_CARBON_OPTIONAL = {
  REGION: 0,
  PROJECT_SPECIFICATION: 1,
  ADDITIONAL_ATTRIBUTES: 2,
  VENUE: 3,
  OTHER_TERMS: 4,
}

export const STEP_CARBON = {
  [TAB_CARBON.ESSENTIAL]: STEP_CARBON_ESSENTIAL,
  [TAB_CARBON.OPTIONAL]: STEP_CARBON_OPTIONAL,
}

export const ORDER_CONFIRMATION = {
  UNCONFIRMED: 0,
  CONFIRMED: 1,
}

export const ORDER_FLOW = {
  COUNTER: 0,
  CREATE: 1,
  EDIT: 2,
  SWAP: 3,
  CLONE: 4,
  CREATE_TEMPLATE: 5,
}

export const initialStepState = {
  tab: TAB.OVERVIEW,
  step: STEP[TAB.OVERVIEW].GENERAL,
}

export const OrderContext = createContext(initialStepState)

export const OrderProvider = ({ children }) => {
  const { location } = useRouter()
  const query = queryString.parse(location.search)
  const [activeStep, setState] = useState({
    ...initialStepState,
    step: parseInt(query.step) || 0,
  })
  const [confirmation, setConfirmation] = useState(
    ORDER_CONFIRMATION.UNCONFIRMED
  )
  const [flow, setFlow] = useState()
  const [orderState, setOrderState] = useState({})

  const setActiveStep = useCallback(
    (newState) => {
      if (isFunction(newState)) {
        return setState((state) => ({ ...state, ...newState(state) }))
      }

      const prevState = activeStep
      const nextState = { ...activeStep, ...newState }

      if (isEqual(prevState, nextState)) {
        return
      }

      return setState((state) => ({ ...state, ...newState }))
    },
    [activeStep]
  )

  const navigate = {
    goTo: (props) =>
      setActiveStep((activeStep) => ({ ...activeStep, ...props })),
    goBack: () =>
      setActiveStep((activeStep) => ({
        ...activeStep,
        step: activeStep.step - 1,
      })),
    goForward: () =>
      setActiveStep((activeStep) => ({
        ...activeStep,
        step: activeStep.step + 1,
      })),
  }

  return (
    <OrderContext.Provider
      value={{
        activeStep,
        navigate,
        confirmation,
        setConfirmation,
        flow,
        setFlow,
        orderState,
        setOrderState,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

OrderProvider.propTypes = {
  children: PropTypes.node,
}

export const useActiveStep = () => {
  const { activeStep, navigate } = useContext(OrderContext)
  return {
    activeStep,
    navigate,
  }
}

export const useOrderConfirmation = () => {
  const { confirmation, setConfirmation } = useContext(OrderContext)
  return {
    confirmation,
    setConfirmation,
  }
}

export const useOrderFlow = () => {
  const { flow, setFlow } = useContext(OrderContext)
  return {
    flow,
    setFlow,
  }
}

export const useOrderState = () => {
  const { orderState, setOrderState } = useContext(OrderContext)
  return {
    orderState,
    setOrderState,
  }
}
