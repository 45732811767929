const ROLES = {
  COMPANY_ADMIN: 'company',
  SYSTEM_ADMIN: 'system',
}

/* :: object -> boolean */
export const isCompanyAdmin = ({ adminRole }) =>
  adminRole === ROLES.COMPANY_ADMIN

/* :: object -> boolean */
export const isSystemAdmin = ({ adminRole }) => adminRole === ROLES.SYSTEM_ADMIN
