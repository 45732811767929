import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useTheme } from '@material-ui/styles'
import Clear from '@material-ui/icons/Clear'
import Done from '@material-ui/icons/Done'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import {
  PERSON_CLIENT_TYPE,
  STAKEHOLDER_TYPE,
} from 'emsurge-selectors/constants'
import { useQueryClient } from 'react-query'
import Form from 'containers/formikForm/FormikForm'
import SubmitButton from 'containers/formikSubmitButton/FormikSubmitButton'
import CloseButton from 'components/closeButton/CloseButton'
import { useApi } from 'containers/api/useApi'
import { useUser } from 'containers/user/useUser'
import { getApiErrorMessage } from 'containers/api/helpers'
import { Stakeholder, StakeholderType } from 'components/Stakeholder'
import { ORDER_KEY } from 'api/constants'

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'center',
  },
  dialogText: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  dialogTitle: {
    textAlign: 'center',
  },
}))

export const MatchOrder = ({ order, children, dialogProps }) => {
  const classes = useStyles()
  const [isOpen, setOpen] = useState(false)
  const [stakeholderType, setStakeholderType] = useState(
    STAKEHOLDER_TYPE.ON_SYSTEM
  )
  const [closeOnTraded, setClosedOnTraded] = useState(false)
  const { spacing } = useTheme()
  const api = useApi()
  const { user } = useUser()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const initialValues = {
    companyName: '',
    entityName: '',
    contact: '',
    entityId: '',
  }

  const open = () => setOpen(true)
  const close = () => setOpen(false)

  const match = async (counterParty, actions) => {
    let stakeholder

    switch (stakeholderType) {
      case STAKEHOLDER_TYPE.ON_SYSTEM:
        stakeholder = {
          companyId: counterParty.companyId,
          entityId: counterParty.entityId,
          personId: counterParty.personId,
        }
        break
      case STAKEHOLDER_TYPE.OFF_SYSTEM:
        stakeholder = {
          companyName: counterParty.companyName,
          entityName:
            counterParty.entityName === ''
              ? undefined
              : counterParty.entityName,
          contact:
            counterParty.contact === '' ? undefined : counterParty.contact,
        }
        break
      default:
        stakeholder = undefined
        break
    }

    const data = {
      orderId: order.id,
      closeOnTraded,
      counterPartyType: stakeholderType,
      counterParty: stakeholder,
    }

    try {
      await api.post('/termsheets/match', data)
      enqueueSnackbar('Order matched successfully', { variant: 'success' })
      queryClient.invalidateQueries([ORDER_KEY, order.id]) // TODO: replace with mutation
      close()
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Failed to match order',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={close}
        onKeyDown={(e) => e.stopPropagation()}
        TransitionProps = {dialogProps}
      >
        <CloseButton
          onClick={close}
          style={{ right: spacing(1), top: spacing(1) }}
        />
        <DialogTitle className={classes.dialogTitle}>
          Select the counterparty for this order
        </DialogTitle>

        {!order.thirdParty && (
          <StakeholderType
            type={stakeholderType}
            handleChange={(stakeholderType) =>
              setStakeholderType(stakeholderType)
            }
            options={{
              [STAKEHOLDER_TYPE.OFF_SYSTEM]: {
                disabled:
                  user.clientType === PERSON_CLIENT_TYPE.TRADER &&
                  user.companyId !== order.companyId,
              },
            }}
          />
        )}

        <Form initialValues={initialValues} onSubmit={match}>
          <DialogContent>
            <Stakeholder type={stakeholderType} />
            <DialogContentText className={classes.dialogText}>
              By matching this order, you will mark the associated Term Sheet as
              traded. You can either close your order, or keep the order open.
            </DialogContentText>
          </DialogContent>

          <DialogActions className={classes.dialogActions}>
            <SubmitButton
              data-testid="match-close-order-button"
              icon={Clear}
              onClick={() => setClosedOnTraded(true)}
            >
              Close my order
            </SubmitButton>
            <SubmitButton
              data-testid="match-keep-open-button"
              icon={Done}
              onClick={() => setClosedOnTraded(false)}
            >
              Keep my order open
            </SubmitButton>
          </DialogActions>
        </Form>
      </Dialog>

      {children({ open })}
    </>
  )
}

MatchOrder.defaultProps = {
  dialogProps: {},
}

MatchOrder.propTypes = {
  order: PropTypes.object.isRequired,
  dialogProps: PropTypes.object,
  children: PropTypes.func.isRequired,
}
