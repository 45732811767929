const { isEmpty, isString } = require('lodash')

const {
  PRICE_UNIT_LABEL,
  PRICE_TYPE,
  PRICE_TYPE_TEXT,
  PRICE_UNIT_TEXT,
} = require('../../../constants')

const { withDecimals } = require('../../../helpers')

const hasPrice = (info) => {
  const amount = parseFloat(info.value)
  const unit = info.unit

  return Number.isFinite(amount) && isString(unit) && !isEmpty(unit)
}

const getPrice = (info) => {
  return { ...info }
}

const getPriceUnitText = (info) => {
  if (info.unit) {
    return PRICE_UNIT_LABEL[info.unit]
  }

  return ''
}

const getPriceAmountText = (info) => {
  if (info.value) {
    if (isFinite(parseFloat(info.value))) {
      return withDecimals(info.value)
    }
    return info.value
  }
}

const getText = (info) => {
  let summary
  const priceUnitText = PRICE_UNIT_TEXT[info.unit]

  if (info.value && priceUnitText) {
    const amount = getPriceAmountText(info)
    const unit = priceUnitText(amount)

    summary = unit
  }

  return {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.FIXED],
    shorter: getPriceAmountText(info),
    short: summary,
    long: summary,
    amount: getPriceAmountText(info),
    unit: getPriceUnitText(info),
  }
}

module.exports = {
  exists: hasPrice,
  get: getPrice,
  summary: getText,
}
