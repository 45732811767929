import React, { useCallback, useEffect } from 'react'
import { $U, PERSON_CLIENT_TYPE } from 'emsurge-selectors'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'formik'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { getStatusLabel, useIsReactInDevelomentMode } from './helpers'
import { useUser } from 'containers/user/useUser'
import {
  GridArea,
  GridContainer,
  HEADER_AREAS,
  Wrapper,
} from 'components/ModalHeader/layout'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'
import { BrokerOrderSummary } from 'screens/orderCreateCommon/components/BrokerOrderSummary'
import { TraderOrderSummary } from 'screens/orderCreateCommon/components/TraderOrderSummary'
import withRouteParams from 'utils/withRouteParams'
import {
  ORDER_CONFIRMATION,
  TAB_CARBON,
  useActiveStep,
  useOrderConfirmation,
} from 'containers/OrderProvider'
import { ConfirmShareButton } from 'screens/orderCreateCommon/components/ConfirmShareButton'
import {
  ConfirmButton,
  SaveButton,
} from 'screens/orderCreateCommon/components/Buttons'
import { getEarliestErrorStep } from 'screens/orderCreateCarbon/getErrors'

const Header = ({ onCancel, formik, isEdit, onSave, isCreatingTrade }) => {
  const { user } = useUser()
  const { confirmation, setConfirmation } = useOrderConfirmation()
  const { enqueueSnackbar } = useSnackbar()
  const hasSharePermission = false
  const {
    navigate: { goTo: _goTo },
  } = useActiveStep()
  const goTo = useCallback(_goTo, [])

  const onSaveClick = useCallback(async () => {
    const errors = await formik.validateForm()

    if (!isEmpty(errors)) {
      if ( useIsReactInDevelomentMode ) console.debug("Errors when saving: ", errors)
      enqueueSnackbar('Please correct all the errors', { variant: 'error' })
      const { tab, step } = await getEarliestErrorStep(formik)
      formik.submitForm() // force touching all form fields to make errors visible
      goTo({ tab, step })
    } else {
      setConfirmation(ORDER_CONFIRMATION.CONFIRMED)
      goTo({ tab: TAB_CARBON.RECAP, step: 0 })
    }
  }, [enqueueSnackbar, formik, goTo, setConfirmation])

  useEffect(() => {
    setConfirmation(ORDER_CONFIRMATION.UNCONFIRMED)
  }, [formik.values, setConfirmation])

  return (
    <Wrapper>
      <GridContainer data-testid="order-modal-header">
        {$U.clientType.get(user) === PERSON_CLIENT_TYPE.BROKER ? (
          <BrokerOrderSummary isEdit={isEdit} isCreatingTrade={isCreatingTrade}/>
        ) : (
          <TraderOrderSummary isCreatingTrade={isCreatingTrade}/>
        )}

        <GridArea name={HEADER_AREAS.OTHER_ACTIONS}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid data-testid="order-creation-status-label" item>
              {getStatusLabel(formik.values, isCreatingTrade)}
            </Grid>
            <Grid item>
              <CloseButton onClick={onCancel} />
            </Grid>
          </Grid>
        </GridArea>

        <GridArea name={HEADER_AREAS.MAIN_ACTIONS} style={{ marginTop: '8px' }}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {confirmation === ORDER_CONFIRMATION.CONFIRMED && (
              <>
                {hasSharePermission && (
                  <Grid item>
                    <ConfirmShareButton
                      disabled={formik.isSubmitting}
                      onCancel={onCancel}
                    />
                  </Grid>
                )}
                <Grid item>
                  <ConfirmButton
                    disabled={formik.isSubmitting}
                    onClick={onSave || formik.submitForm}
                  />
                </Grid>
              </>
            )}
            {confirmation === ORDER_CONFIRMATION.UNCONFIRMED && (
              <Grid item>
                <SaveButton onClick={onSaveClick} />
              </Grid>
            )}
          </Grid>
        </GridArea>
      </GridContainer>
    </Wrapper>
  )
}

Header.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  formik: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
}

Header.defaultProps = {
  isEdit: false,
}

const enhance = compose(connect, withRouteParams)

export default enhance(Header)
