import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { ResetPasswordLinkButton } from './ResetPasswordLinkButton'
import TableRowLink from 'components/tableRowLink/TableRowLink'
import { ADMIN_VIEW_USER } from 'routes'

const UsersTable = ({ users }) => (
  <Paper>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Company</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Client type</TableCell>
          <TableCell>Job title</TableCell>
          <TableCell>Email address</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {users.map((user) => (
          <TableRowLink
            key={user.id}
            to={ADMIN_VIEW_USER.replace(':id', user.id)}
          >
            <TableCell>{user.companyName}</TableCell>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.clientType}</TableCell>
            <TableCell>{user.jobTitle}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
              <ResetPasswordLinkButton personId={user.id} />
            </TableCell>
          </TableRowLink>
        ))}
      </TableBody>
    </Table>
  </Paper>
)

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
}

export default UsersTable
