import React from 'react'
import {
  PROGRAM_ELIGIBILITY_PREFIX,
  PROGRAM_ELIGIBILITY_OPTIONS,
} from 'emsurge-selectors'
import { CheckboxGroup } from './CheckboxGroup'

export const ProgramEligibility = () => {
  return (
    <CheckboxGroup
      key="radio-button-group-program-eligibility"
      label="Programme Eligibility"
      fieldPrefix={PROGRAM_ELIGIBILITY_PREFIX}
      options={PROGRAM_ELIGIBILITY_OPTIONS}
    />
  )
}
