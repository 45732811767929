import styled from 'styled-components'

export const ModalContent = styled.section`
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing(1, 4, 1, 4)};
`
