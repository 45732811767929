import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Typography as Text,
} from '@material-ui/core'
import { partial, uniqueId, capitalize } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import {
  getPropLabels,
  toLogLines as _toLogLines,
} from './EditLogPanel.helpers'
import { useOrderLog } from 'api/queries/order/useOrderLog'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'

const useHeadStyles = makeStyles((theme) => ({
  stickyHeader: {
    top: '-24px',
    backgroundColor: theme.palette.background.secondary,
    opacity: 1,
  },
}))

const useBodyStyles = makeStyles((theme) => ({
  body: {
    borderBottom: `1px solid ${theme.palette.background.secondary}`,
  },
}))

const HeadCell = ({ children, ...props }) => {
  const classes = useHeadStyles()
  return (
    <TableCell classes={classes} {...props}>
      <Text variant="body1">{children}</Text>
    </TableCell>
  )
}

HeadCell.propTypes = {
  children: PropTypes.node,
}

const BodyCell = ({ children, ...props }) => {
  const classes = useBodyStyles()
  return (
    <TableCell classes={classes} {...props}>
      <Text variant="body1">{children}</Text>
    </TableCell>
  )
}

BodyCell.propTypes = {
  children: PropTypes.node,
}

const ASCENDING = 'asc'
const DESCENDING = 'desc'

export const EditLogPanel = ({ order }) => {
  const { isLoading, data: logs } = useOrderLog(order.id)
  const [timeSort, setTimeSort] = useState(DESCENDING)

  const toLogLines = partial(_toLogLines, getPropLabels(order))

  const sortedLogs = useMemo(
    () => {
      let result
      if (logs) {
        result = logs.sort((a, b) => {
          if (timeSort === ASCENDING) {
            return moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1
          }
          return moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1
        })
      }
      return result || []
    },
    [logs, timeSort]
  )

  const onSort = useCallback(
    () => setTimeSort((prev) => (prev === ASCENDING ? DESCENDING : ASCENDING)),
    []
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <HeadCell>
            <TableSortLabel active={true} direction={timeSort} onClick={onSort}>
              Time
            </TableSortLabel>
          </HeadCell>
          <HeadCell>Change made</HeadCell>
          <HeadCell>From</HeadCell>
          <HeadCell>To</HeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedLogs?.map((log) =>
          toLogLines(log).map((prop) => (
            <TableRow data-testid="edit-log-rows" key={uniqueId('log-')}>
              <BodyCell data-testid="edit-log-date">
                {moment(log.createdAt).format('DD/MM/YY, HH:mm')}
              </BodyCell>
              <BodyCell data-testid="edit-log-change-made">
                <strong>{prop.label}</strong>
              </BodyCell>
              <BodyCell data-testid="edit-log-changed-from">
                {capitalize(prop.from)}
              </BodyCell>
              <BodyCell data-testid="edit-log-changed-to">
                {capitalize(prop.to)}
              </BodyCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  )
}

EditLogPanel.propTypes = {
  order: PropTypes.object,
}
