const { mapCountriesToData, OPTION_TYPE_IS_LDC } = require('./helpers')

const COUNTRIES_NORTHERN_AMERICA_KEYS = {
  BERMUDA: 'BERMUDA',
  CANADA: 'CANADA',
  GREENLAND: 'GREENLAND',
  SAINT_PIERRE_AND_MIQUELON: 'SAINT_PIERRE_AND_MIQUELON',
  UNITED_STATES_OF_AMERICA: 'UNITED_STATES_OF_AMERICA',
}

const COUNTRIES_NORTHERN_AMERICA_VALUES = {
  BERMUDA: 'Bermuda',
  CANADA: 'Canada',
  GREENLAND: 'Greenland',
  SAINT_PIERRE_AND_MIQUELON: 'Saint Pierre and Miquelon',
  UNITED_STATES_OF_AMERICA: 'United States of America',
}

const COUNTRIES_NORTHERN_AMERICA = mapCountriesToData(
  COUNTRIES_NORTHERN_AMERICA_KEYS,
  COUNTRIES_NORTHERN_AMERICA_VALUES,
  {
    type: OPTION_TYPE_IS_LDC,
    elements: [],
  }
)

module.exports = {
  COUNTRIES_NORTHERN_AMERICA_KEYS,
  COUNTRIES_NORTHERN_AMERICA_VALUES,
  COUNTRIES_NORTHERN_AMERICA,
}
