import { merge } from 'lodash'
import { $O, $U, PERSON_CLIENT_TYPE, STAKEHOLDER_TYPE } from 'emsurge-selectors'
import { $O as $o } from 'model/order/selectors/selector'
import {
  getStep2,
  getStep3,
  getStep4,
  getStep5,
  getValidity,
} from 'screens/orderEdit/containers/getInformationInitialValues'

const getStep1 = (order, user) => {
  const stepValues = {
    behalfOf: $O.stakeholder.type.get(order),
    details: '',
    validity: getValidity(order),
    myCompany: {},
    thirdParty: order.thirdParty || {},
  }

  if (
    $O.stakeholder.type.get(order) === STAKEHOLDER_TYPE.ON_SYSTEM &&
    $U.clientType.get(user) === PERSON_CLIENT_TYPE.BROKER
  ) {
    stepValues.myCompany = {
      brokerEntityId: order.brokerId,
      // a trader's information
      companyId: order.companyId,
      entityId: order.entityId,
      traderId: order.personId,
    }

    stepValues.thirdParty = {
      companyName: '',
      entityName: '',
      contact: '',
    }
    return stepValues
  }

  if (!$o.author.isFromMyCompany(order, user)) {
    // if order is anonymous, use the data as third party
    stepValues.behalfOf = STAKEHOLDER_TYPE.OFF_SYSTEM
    stepValues.thirdParty = {
      companyName: order?.company?.name,
      entityName: order?.entity?.name,
      contact: `${order?.person?.name} ${order?.person?.surname}`,
    }
    return stepValues
  }

  return stepValues
}

export const getInformationInitialValues = (order, user) => {
  const initialValues = merge(
    {},
    order,
    getStep1(order, user),
    getStep2(order),
    getStep3(order),
    getStep4(order),
    getStep5(order)
  )

  return initialValues
}
