import { PRICE_TYPE, PRICE_TYPE_TEXT } from 'emsurge-selectors/constants'
import { Preference } from 'screens/orderCreate/components/Preference'
import { Floating } from 'screens/orderCreate/components/Floating'
import { Fixed } from 'screens/orderCreate/components/Fixed'
import { Rfq } from 'screens/orderCreateCommon/components/Rfq'
import { Tbd } from 'screens/orderCreateCommon/components/Tbd'

export const PRICE_OPTIONS = {
  [PRICE_TYPE.FLOATING]: {
    label: PRICE_TYPE_TEXT[PRICE_TYPE.FLOATING],
    component: Floating,
  },
  [PRICE_TYPE.FIXED]: {
    label: PRICE_TYPE_TEXT[PRICE_TYPE.FIXED],
    component: Fixed,
  },
  [PRICE_TYPE.RFQ]: {
    label: PRICE_TYPE_TEXT[PRICE_TYPE.RFQ],
    component: Rfq,
  },
  [PRICE_TYPE.PREFERENCE]: {
    label: PRICE_TYPE_TEXT[PRICE_TYPE.PREFERENCE],
    component: Preference,
  },
  [PRICE_TYPE.TBD]: {
    label: PRICE_TYPE_TEXT[PRICE_TYPE.TBD],
    component: Tbd,
  },
}
