import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { connect } from 'formik'
import { Grid } from '@material-ui/core'
import {
  VOLUME_TYPE_PREFIX,
  VOLUME_TYPE_OPTIONS,
  VOLUME_TYPE_KEYS,
  VOLUME_AMOUNT_PREFIX,
} from 'emsurge-selectors'
import { VolumeInput } from '../components/VolumeInput'
import { RadioButtonGroup } from '../components'
import { VolumeTotal } from './VolumeTotal'
import { VolumePerAnnum } from './VolumePerAnnum'

const _Volume = ({ formik: { values } }) => {
  const selectedType = get(values, VOLUME_TYPE_PREFIX) || VOLUME_TYPE_KEYS.TOTAL
  const [type, setType] = useState(selectedType)

  useEffect(() => {
    setType(get(values, VOLUME_TYPE_PREFIX))
  }, [values.volume.type])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <VolumeInput name={VOLUME_AMOUNT_PREFIX} label="Volume" unit="unit" />
        </Grid>
        <Grid item xs={6}>
          <RadioButtonGroup
            title="Type"
            fieldName={VOLUME_TYPE_PREFIX}
            options={VOLUME_TYPE_OPTIONS}
          />
        </Grid>
      </Grid>
      {type === VOLUME_TYPE_KEYS.TOTAL && <VolumeTotal />}
      {type === VOLUME_TYPE_KEYS.PER_ANNUM && <VolumePerAnnum />}
    </>
  )
}

export const Volume = connect(_Volume)
