import React from 'react'
import { map, get } from 'lodash'
import styled from 'styled-components'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { useFeatureFlags, FEATURE_FLAGS } from 'containers/featureFlags/'

const StyledFormGroup = styled(FormGroup)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`

const FeatureFlags = () => {
  const [flags, set] = useFeatureFlags()

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        Select which features should be active
      </FormLabel>
      <StyledFormGroup>
        {map(FEATURE_FLAGS, (value) => {
          const checked = get(flags, value, false)
          return (
            <FormControlLabel
              key={value}
              control={
                <Switch
                  color="primary"
                  checked={checked}
                  onChange={() => set(value, !checked)}
                  value={value}
                />
              }
              label={value}
            />
          )
        })}
      </StyledFormGroup>
    </FormControl>
  )
}

export default FeatureFlags
