const { PRICE_TYPE, PRICE_TYPE_TEXT } = require('../../../constants')

const hasPrice = () => true

const getPrice = () => {}

const getText = () => {
  const summary = 'TBD'

  return {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.TBD],
    shorter: summary,
    short: summary,
    long: summary,
  }
}

module.exports = {
  exists: hasPrice,
  get: getPrice,
  summary: getText,
}
