import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import MenuItem from '@material-ui/core/MenuItem'

import { ConfirmActionDialog } from 'components/ConfirmActionDialog'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import withRouteParams from 'utils/withRouteParams'

const ARCHIVE_ORDER_TEXT =
  'This will archive the term sheet. Would you like to proceed?'

const _ArchiveTermsheetMenuItem = ({
  termsheet,
  closeMenu,
  enqueueSnackbar,
}) => {
  const [open, setOpen] = useState(false)
  const api = useApi()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const onCancel = () => {
    setOpen(false)
    closeMenu()
  }

  const onConfirm = async () => {
    const redirect = closeMenu()

    try {
      await api.delete(`/termsheets/${termsheet.id}`)
      enqueueSnackbar('Term sheet closed successfully', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Error closing term sheet',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
    redirect()
  }

  return (
    <div>
      <MenuItem
        data-testid="archive-termsheet-button"
        onClick={handleClickOpen}
      >
        ARCHIVE TERM SHEET
      </MenuItem>
      <ConfirmActionDialog
        onCancel={onCancel}
        onConfirm={onConfirm}
        open={open}
        text={ARCHIVE_ORDER_TEXT}
      />
    </div>
  )
}

const enhance = compose(withRouteParams, withSnackbar)

_ArchiveTermsheetMenuItem.propTypes = {
  termsheet: PropTypes.object.isRequired,
  closeMenu: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export const ArchiveTermsheetMenuItem = enhance(_ArchiveTermsheetMenuItem)
