import { withProps } from 'recompose'
import countryList from 'country-list'
import TextField from 'containers/formikTextField/FormikTextField'
import AutoComplete from 'containers/formikAutocomplete/AutoComplete'

const defaultProps = {
  required: true,
  type: 'text',
  fullWidth: true,
  margin: 'normal',
  InputLabelProps: {
    error: false,
  },
}

const Name = withProps({
  ...defaultProps,
  name: 'pointOfContact.name',
  id: 'pointOfContact.name',
  label: 'Name',
  autoComplete: 'given-name',
})(TextField)

const Surname = withProps({
  ...defaultProps,
  name: 'pointOfContact.surname',
  id: 'pointOfContact.surname',
  label: 'Surname',
  autoComplete: 'family-name',
})(TextField)

const JobTitle = withProps({
  ...defaultProps,
  name: 'pointOfContact.jobTitle',
  id: 'pointOfContact.jobTitle',
  label: 'Job Title',
  autoComplete: 'organization-title',
})(TextField)

const Entity = withProps({
  ...defaultProps,
  name: 'pointOfContact.entity',
  id: 'pointOfContact.entity',
  label: 'Entity',
  autoComplete: 'off',
})(TextField)

const Phone = withProps({
  ...defaultProps,
  name: 'pointOfContact.phone',
  id: 'pointOfContact.phone',
  label: 'Telephone Number',
  autoComplete: 'tel',
})(TextField)

const Email = withProps({
  ...defaultProps,
  name: 'pointOfContact.email',
  type: 'email',
  id: 'pointOfContact.email',
  label: 'Email',
  autoComplete: 'email',
})(TextField)

const AddressFirstLine = withProps({
  ...defaultProps,
  name: 'pointOfContact.addressFirstLine',
  id: 'pointOfContact.addressFirstLine',
  label: 'Address First Line',
  autoComplete: 'address-line1',
})(TextField)

const AddressSecondLine = withProps({
  ...defaultProps,
  required: false,
  name: 'pointOfContact.addressSecondLine',
  id: 'pointOfContact.addressSecondLine',
  label: 'Address Second Line',
  autoComplete: 'address-line2',
})(TextField)

const Postcode = withProps({
  ...defaultProps,
  name: 'pointOfContact.postcode',
  id: 'pointOfContact.postcode',
  label: 'Postcode',
  autoComplete: 'postal-code',
})(TextField)

const City = withProps({
  ...defaultProps,
  name: 'pointOfContact.city',
  id: 'pointOfContact.city',
  label: 'City',
  autoComplete: 'address-level2',
})(TextField)

const Country = withProps({
  ...defaultProps,
  name: 'pointOfContact.country',
  id: 'pointOfContact.country',
  label: 'Country',
  suggestions: countryList.getNames(),
})(AutoComplete)

export {
  AddressFirstLine,
  AddressSecondLine,
  Postcode,
  City,
  Name,
  Surname,
  JobTitle,
  Entity,
  Phone,
  Email,
  Country,
}
