import styled from 'styled-components'
import { withProps } from 'recompose'
import Grid from '@material-ui/core/Grid'

/** [1]. @TODO: Move this to theme once we have shades of blue  */
export const Card = styled.article`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  overflow: auto;
  background-color: #1a222f;
  border: ${({ theme, border }) =>
    border ? `2px solid ${theme.palette.primary.main}` : '0px'};
  border-radius: 5px;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(1)}px;
`

export const Container = withProps({ container: true, alignItems: 'center' })(
  Grid
)
