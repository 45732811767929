import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { truncate } from 'lodash'
import { Text, ClampedText } from './Text'
import NoteActions from './NoteActions'
import Person from './Person'
import { XsmallText, SmallText } from 'components/Text'
import { TimeAgo } from 'components/TimeAgo'
import { Card, Container } from 'components/Card'
import { getFormattedDateWithOffset } from 'utils/dates'
import { isUpdateActivity } from 'screens/orderIndex/containers/Activity'
import { PriorityIcon } from 'screens/noteIndex/components/NotesTable'
import { StyledLink as Link } from 'components/StyledLink'

const ActivityNoteCard = ({ activity, style }) => {
  const { spacing } = useTheme()
  const { type, ...note } = activity

  const typeLabel = isUpdateActivity(type) ? 'Edit Note' : 'New Note'
  const timeAgoDate = note.occurredAt ?? note.updatedAt // This control is shared with the "Notes" screen which uses a query that returns an 'updatedAt' property instead of 'occurredAt'

  const explanationText = note.explanation
    ? `You can see this note because of Rule #1: You are in the same company as the note. Your company ID is ${note.explanation.rule1.person_company_id}. The note is in company ${note.explanation.rule1.note_company_id}. Note ID=${note.id}.`
    : ''

  return (
    <Link to={`/notes/${activity.id}`}>
      <Card data-testid={`activity-note-${activity.id}`} style={style}>
        {/* ROW 1 */}
        <Container justifyContent="space-between">
          <XsmallText title={explanationText} data-testid="activity-note-type">
            {typeLabel}
          </XsmallText>
          <Person
            data-testid="activity-note-person-name"
            person={note.person}
          ></Person>
        </Container>

        {/* ROW 2 */}
        <Container style={{ width: 'auto' }}>
          <PriorityIcon
            data-testid="activity-note-priority"
            note={note}
            style={{ marginRight: spacing(1) }}
          />
          <Text data-testid="activity-note-company-name" variant="subtitle2">
            {truncate(note.companyName, { length: 22 })}
          </Text>
        </Container>

        {/* ROW 3 */}
        <Container style={{ width: 'auto' }}>
          <SmallText noWrap>
            {getFormattedDateWithOffset(note.eventDate)}
          </SmallText>
        </Container>

        {/* ROW 4 */}
        <Container justifyContent="space-between">
          <TimeAgo date={timeAgoDate} />

          <SmallText data-testid="activity-note-country" noWrap>
            {truncate(note.country, { length: 30 })}
          </SmallText>
          <NoteActions data-testid="activity-note-actions" resource={note} />
        </Container>

        {/* ROW 5 */}
        <Container>
          <SmallText
            data-testid="activity-note-tags"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {note.tags.join(', ')}
          </SmallText>
        </Container>

        <Container justifyContent="space-between" style={{ marginTop: '8px' }}>
          <ClampedText
            data-testid="activity-note-text"
            component={XsmallText}
            style={{ whiteSpace: 'normal', height: 'auto' }}
          >
            {note.note}
          </ClampedText>
        </Container>
      </Card>
    </Link>
  )
}

ActivityNoteCard.propTypes = {
  style: PropTypes.object,
  activity: PropTypes.object.isRequired,
}

export default memo(ActivityNoteCard)
