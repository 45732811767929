import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Formik } from 'formik'
import { object, array, string } from 'yup'
import { useEditChecklist } from 'api'

const getChecklistFormSchema = (checklists) => {
  const checklistsIds = checklists.map(({ id }) => id)
  return object({
    ...checklistsIds.reduce((acc, currentId) => {
      acc[currentId] = object({
        items: array().of(
          object({
            name: string()
              .typeError('Required')
              .required('Required')
              .label('Item')
              .min(1),
          })
        ),
      })
      return acc
    }, {}),
  })
}

const getInitialValues = (checklists) =>
  checklists.reduce((values, checklist) => {
    values[checklist.id] = {
      type: checklist.type,
      items: checklist.items.map((item) => ({
        name: item.name,
        deadlineDate: moment(item.deadlineDate).format('YYYY-MM-DD'),
        id: item.id,
        isChecked: item.isChecked,
      })),
    }
    return values
  }, {})

const ChecklistFormProvider = ({ children, checklists, postSubmit }) => {
  const { mutateAsync: editChecklist } = useEditChecklist()

  /* :: (object, object) -> Promise<void> */
  const handleSubmit = async (values, actions) => {
    if (checklists.length) {
      for (const checklist of checklists) {
        const filteredChecklist = {
          ...checklist,
          items: values[checklist.id]?.items || [],
        }
        editChecklist(filteredChecklist)
      }
    }
    postSubmit()
    actions.setSubmitting(false)
  }

  const initialValues = getInitialValues(checklists)
  const schema = getChecklistFormSchema(checklists)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {children}
    </Formik>
  )
}

ChecklistFormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  checklists: PropTypes.array.isRequired,
  postSubmit: PropTypes.func,
}

ChecklistFormProvider.defaultProps = {
  postSubmit: () => {},
}

export default ChecklistFormProvider
