import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { $U, PERSON_CLIENT_TYPE } from 'emsurge-selectors'
import { TextField } from 'screens/orderCreateCommon/components/TextField'
import { OnBehalfOf } from 'screens/orderCreateCommon/components/OnBehalfOf'
import { MyCompanyEntitiesSelect } from 'screens/orderCreateCommon/components/MyCompanyEntitiesSelect'
import { TraderEntitiesSelect } from 'screens/orderCreateCommon/components/TraderEntitiesSelect'
import { ThirdPartyInfo } from 'screens/orderCreateCommon/components/ThirdPartyInfo'
import { OrderStatus } from 'screens/orderCreateCommon/components/OrderStatus'
import { SelectableGridOption as Option } from 'components/SelectableGrid'
import {
  PaddedContainer,
  Button,
  StyledContainer,
  StyledConnectedSelectableGrid,
  StyledSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { useUser } from 'containers/user/useUser'
import { OrderValidity } from 'screens/orderCreateCommon/components/OrderValidity'

const OrderValidityField = () => (
  <OrderValidity>
    {(options) => (
      <StyledConnectedSelectableGrid name="validity.until">
        {options.map(({ value, label }) => (
          <Option
            data-testid={`validity-${value}`}
            key={`order-validity-${value}`}
            value={value}
          >
            <Button>{label}</Button>
          </Option>
        ))}
      </StyledConnectedSelectableGrid>
    )}
  </OrderValidity>
)

const OrderStatusField = () => (
  <OrderStatus>
    {({ options, onChange, value }) => (
      <StyledSelectableGrid onChange={onChange} value={value}>
        {options.map(({ value, label, disabled }) => (
          <Option
            data-testid={`status-${value}`}
            key={`order-status-${value}`}
            value={value}
            disabled={disabled}
          >
            <Button>{label}</Button>
          </Option>
        ))}
      </StyledSelectableGrid>
    )}
  </OrderStatus>
)

const _General = ({ company }) => {
  const { user } = useUser()
  const clientType = $U.clientType.get(user)

  return (
    <PaddedContainer>
      <OnBehalfOf>
        {(options) => (
          <StyledConnectedSelectableGrid name="behalfOf">
            {options.map(({ value, label, ...props }) => (
              <Option
                data-testid={`behalfOf-${value}`}
                key={`order-on-behalf-of-${value}`}
                value={value}
                {...props}
              >
                <Button>{label}</Button>
              </Option>
            ))}
          </StyledConnectedSelectableGrid>
        )}
      </OnBehalfOf>

      {clientType === PERSON_CLIENT_TYPE.TRADER && (
        <StyledContainer>
          <MyCompanyEntitiesSelect company={company} />
        </StyledContainer>
      )}

      {clientType === PERSON_CLIENT_TYPE.BROKER && (
        <StyledContainer>
          <TraderEntitiesSelect />
        </StyledContainer>
      )}

      <ThirdPartyInfo>
        {(options) =>
          options.map((props, index) => (
            <TextField
              inputProps={{
                'data-testid': `third-party-${options[
                  index
                ].label.toLowerCase()}-field`,
              }}
              key={`order-third-party-info-${index}`}
              {...props}
            />
          ))
        }
      </ThirdPartyInfo>

      <StyledContainer>
        <OrderStatusField />
      </StyledContainer>

      <StyledContainer>
        <OrderValidityField />
      </StyledContainer>
    </PaddedContainer>
  )
}

_General.propTypes = {
  formik: PropTypes.object,
  company: PropTypes.object,
}
export const General = connect(_General)
