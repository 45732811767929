import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import Grid from '@material-ui/core/Grid'
import { ErrorMessage } from './components/ErrorMessage'

const _ErrorView = ({
  children,
  location,
}) => {

    const title = get(location, 'state.error.message')
    const message = get(location, 'state.error.description')
    return (
      <ErrorMessage message={message} title={title}>
          <Grid container justifyContent="center">
            {children}
          </Grid>
      </ErrorMessage>
    )
}

_ErrorView.defaultProps = {
}

_ErrorView.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export const ErrorView = withRouter(_ErrorView)
