import React from 'react'
import PropTypes from 'prop-types'
import { TableBody, TableCell, TableRow } from '@material-ui/core'

const EmptyTable = ({ noDataMessage }) => (
  <TableBody>
    <TableRow>
      <TableCell style={{ border: 'none' }} colSpan={6}>
        {noDataMessage}
      </TableCell>
    </TableRow>
  </TableBody>
)

EmptyTable.propTypes = {
  noDataMessage: PropTypes.string,
}

EmptyTable.defaultProps = {
  noDataMessage: 'No rows to show',
}

export default EmptyTable
