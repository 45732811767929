import React, { useState } from 'react'
import { Box, Grid, Typography as Text } from '@material-ui/core'
import { $O, PRICE_INDEX, PRICE_TYPE, PRICE_UNIT } from 'emsurge-selectors'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { isEmpty } from 'lodash'
import { CargoChart } from './components/CargoChart'
import { Filters } from './components/Filters'
import { useFetchOrders } from 'hooks'
import { STATUS, STATUS_VALUES } from 'components/StatusFilters'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import ViewTermsheet from 'screens/termsheetView/ViewTermsheet'
import ViewOrder from 'screens/orderView/ViewOrder'
import { PRICE_CHARTS_BASIS } from 'routes'
import { PERIOD } from 'model/order/constants/delivery'

const DEFAULT_FILTERS = {
  indexes: [PRICE_INDEX.TTF],
  unit: PRICE_UNIT.MMBTU,
}

const PriceCharts = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const { loading, orders } = useFetchOrders({
    status: STATUS_VALUES[STATUS.ACTIVE],
    'delivery.period': Object.values(PERIOD),
    template: false,
  })

  if (loading) {
    return <LoadingSpinner />
  }

  const filteredOrders =
    orders?.filter((order) => {
      const price = $O.price.get(order)
      const priceType = $O.price.type(order)
      return (
        filters.indexes.includes(price?.index) &&
        price?.unit === filters.unit &&
        priceType === PRICE_TYPE.FLOATING
      )
    }) || []

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing={2}
        style={{ flexGrow: 1 }}
      >
        <Grid item>
          <Text variant="h1">Basis Visualisation</Text>
        </Grid>
        <Filters values={filters} onChange={(filters) => setFilters(filters)} />
        {isEmpty(filteredOrders) ? (
          <Box display="flex" justifyContent="center" py={8}>
            <Text variant="h6">No orders</Text>
          </Box>
        ) : (
          <CargoChart orders={filteredOrders} />
        )}
      </Grid>
      <Route
        path={`${match.path}/orders/:id`}
        component={() => (
          <ViewOrder onClose={() => history.push(PRICE_CHARTS_BASIS)} />
        )}
      />
      <Route path={`${match.path}/termsheets/:id`} component={ViewTermsheet} />
    </>
  )
}

export default PriceCharts
