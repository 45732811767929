import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DetailRow from '../../orderView/components/DetailRow'
import { getNominationTimezone, getPartyLabel } from '../helpers'
import OffsetQuarterCard from './OffsetQuarterCard'
import NameForm from './NameForm'

const DischargePortNominations = ({
  nomination,
  orderCargoTimeZoneMap,
  entityCode,
}) => {
  const nominateUntil = getNominationTimezone(nomination, orderCargoTimeZoneMap)
  return (
    <>
      <DetailRow
        gutterBottom
        title={`${
          nomination.alternative ? 'Alternative Discharge' : 'Discharge port'
        }`}
        info={[
          `${getPartyLabel(nomination.party)}`,
          moment(nominateUntil).format('DD/MM/YYYY HH:mm'),
          entityCode,
        ]}
      />

      <OffsetQuarterCard>
        <NameForm nomination={nomination} nameLabel="Discharge port" />
      </OffsetQuarterCard>
    </>
  )
}

DischargePortNominations.propTypes = {
  nomination: PropTypes.object.isRequired,
  orderCargoTimeZoneMap: PropTypes.object.isRequired,
  entityCode: PropTypes.string.isRequired,
}

export default DischargePortNominations
