import React from 'react'
import PropTypes from 'prop-types'
import MuiSwitch from '@material-ui/core/Switch'
import { Field } from 'formik'

/* :: (object, object, object) -> object */
export const getSwitchProps = (props, formikProps, formikForm) => {
  const switchProps = {
    color: 'primary',
    ...props,
    id: props.id || props.value,
    onChange: formikProps.onChange,
    checked: formikProps.value || false,
    disabled: formikForm.isSubmitting || props.disabled,
  }

  return switchProps
}

const FormikSwitch = (props) => (
  <Field name={props.value}>
    {({ field, form }) => <MuiSwitch {...getSwitchProps(props, field, form)} />}
  </Field>
)

FormikSwitch.propTypes = {
  ...MuiSwitch.propTypes,
  value: PropTypes.string.isRequired,
}

export default FormikSwitch
