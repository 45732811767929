import React from 'react'
import PropTypes from 'prop-types'
import ArrowIcon from '@material-ui/icons/KeyboardArrowLeft'
import { useTheme } from '@material-ui/styles'
import LinkButton from 'components/linkButton/LinkButton'
import withParams from 'utils/withRouteParams'

const _BackToOrderButton = ({ location }) => {
  const { spacing } = useTheme()

  return (
    <LinkButton
      data-testid="back-to-order-button"
      to={location.state.referrer}
      href="#"
      variant="contained"
      color="secondary"
      style={{ marginRight: spacing(1) }}
    >
      <ArrowIcon style={{ marginRight: spacing(1) }} />
      Back to order
    </LinkButton>
  )
}

_BackToOrderButton.propTypes = {
  location: PropTypes.object.isRequired,
}

export const BackToOrderButton = withParams(_BackToOrderButton)
