import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { getAddress } from '../helper'
import InfoText from './InfoText'
import { GridContainer, Column } from './Grid'
import Wrapper from './Wrapper'

const EntityInformation = ({ entities }) => (
  <Wrapper>
    <Text variant="h1" gutterBottom>
      Trading entities information
    </Text>

    <GridContainer>
      {entities.map((entity) => {
        const { pointOfContact, authorisedSignatory } = entity

        return (
          <Fragment key={entity.id}>
            {/** GENERAL INFORMATION */}
            <Column>
              <Text variant="h2" gutterBottom>
                General information
              </Text>
              <InfoText header="Name" info={entity.name} />
              <InfoText
                header="Registered Name"
                info={entity.registrationName}
              />
              <InfoText
                header="Registration Number"
                info={entity.registrationNumber}
              />
              <InfoText header="Address" info={getAddress(entity)} />
            </Column>

            {/** PRIMARY POINT OF CONTACT */}
            <Column>
              <Text variant="h2" gutterBottom>
                Primary point of contact
              </Text>
              <InfoText header="Name" info={pointOfContact.name} />
              <InfoText header="Job Title" info={pointOfContact.jobTitle} />
              <InfoText header="Email address" info={pointOfContact.email} />
              <InfoText header="Telephone number" info={pointOfContact.phone} />
              <InfoText header="Address" info={getAddress(pointOfContact)} />
            </Column>

            {/** COMPANY ADMIM */}
            <Column>
              <Text variant="h2" gutterBottom>
                Authorised Signatory
              </Text>
              <InfoText header="Name" info={authorisedSignatory.name} />
              <InfoText
                header="Job Title"
                info={authorisedSignatory.jobTitle}
              />
              <InfoText header="Email" info={authorisedSignatory.email} />
              <InfoText
                header="Telephone Number"
                info={authorisedSignatory.phone}
              />
            </Column>
          </Fragment>
        )
      })}
    </GridContainer>
  </Wrapper>
)

EntityInformation.propTypes = {
  entities: PropTypes.array.isRequired,
}

export default EntityInformation
