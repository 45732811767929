import React, { useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router'
import { isNil } from 'lodash'
import { OrderMarkAsTraded } from './OrderMarkAsTraded'
import { useOrderCanBeTraded } from './helpers'
import { ORDER_MARK_AS_TRADED } from 'routes'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { useOrder } from 'api'

export const OrderMarkAsTradedContainer = () => {
  const {
    params: { id },
  } = useRouteMatch(ORDER_MARK_AS_TRADED)
  const { data: order, isLoading, error } = useOrder(id)
  const BASE_SLUG = useRouteBaseSlug()
  const history = useHistory()
  const orderCanBeTraded = useOrderCanBeTraded(order)
  const isOrderValid = orderCanBeTraded && !isNil(order) && !error

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!isOrderValid) {
      return history.push(`/${BASE_SLUG}`)
    }
  }, [BASE_SLUG, history, isOrderValid, isLoading])

  if (isLoading && isNil(order)) {
    return <LoadingSpinner />
  }

  return isOrderValid ? <OrderMarkAsTraded /> : null
}
