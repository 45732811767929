import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'formik'
import { get } from 'lodash'
import { computeRenderedDate } from 'emsurge-selectors'

const Wrapper = styled.div`
  min-height: 30px;
  display: flex;
  flex-direction: column-reverse;
`

const _VintageText = ({ formik: { values }, fieldPrefix, ...textProps }) => {
  const [text, setText] = useState('')

  useEffect(() => {
    const termVintagePeriod = get(values, fieldPrefix)
    const { period, year, customFrom, customTo } = termVintagePeriod
    setText(computeRenderedDate(period, customFrom, customTo, year))
  }, [values])

  return <Wrapper {...textProps}>{text}</Wrapper>
}

_VintageText.propTypes = {
  fieldPrefix: PropTypes.string.isRequired,
}

export const VintageText = connect(_VintageText)
