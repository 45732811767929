const party = require('./party')
const size = require('./size')
const tolerance = require('./tolerance')
const type = require('./type')
const unit = require('./unit')
const variance = require('./variance')

const getCargoSize = (order) => {
  return {
    party: party.get(order),
    size: size.get(order),
    tolerance: tolerance.get(order),
    type: type.get(order),
    unit: unit.get(order),
    variance: variance.get(order),
  }
}

const getCargoSizeText = (order) => {
  return {
    party: party.text(order),
    size: size.text(order),
    tolerance: tolerance.text(order),
    type: type.text(order),
    unit: unit.text(order),
    variance: variance.text(order),
  }
}

const getCargoSizeSummary = (order) => {
  return {
    party: party.text(order),
    size: size.summary(order),
    tolerance: tolerance.summary(order),
    type: type.text(order),
    unit: unit.text(order),
    variance: variance.summary(order),
  }
}

module.exports = {
  party,
  size,
  tolerance,
  type,
  unit,
  variance,
  get: getCargoSize,
  text: getCargoSizeText,
  summary: getCargoSizeSummary,
}
