const getOperationalTolerance = (cargoSize = {}) => {
  let operationalTolerance = null

  if (cargoSize.sizeMin) {
    const minPercent = (100 - cargoSize.varianceMinus) / 100
    const maxPercent = (100 + cargoSize.variancePlus) / 100

    const minSize = cargoSize.sizeMin
    const maxSize = cargoSize.sizeMax || cargoSize.sizeMin

    operationalTolerance = {
      min: (minSize * minPercent).toFixed(2),
      max: (maxSize * maxPercent).toFixed(2),
      unit: cargoSize.unit,
    }
  }

  return operationalTolerance
}

export const operationalTolerance = {
  get: getOperationalTolerance,
}
