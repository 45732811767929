import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const LoadingSpinner = ({ size }) => (
  <Wrapper>
    <CircularProgress size={size} />
  </Wrapper>
)

LoadingSpinner.defaultProps = {
  size: 56,
}

LoadingSpinner.propTypes = {
  size: PropTypes.number,
}

export default LoadingSpinner
