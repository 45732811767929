import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { isEmpty, findIndex } from 'lodash'
import Autosizer from 'react-virtualized-auto-sizer'
import { guessNoteActivityCardHeight } from '../utils'
import ActivityCard from './ActivityCard'
import { VirtualList } from 'components/VirtualList'

const NotesActivity = ({ notes, location }) => {
  const [selectedNoteId, setSelectedNoteId] = useState(
    !isEmpty(notes) ? notes[0].id : null
  )

  useEffect(() => {
    const orderId = location.pathname.split('/').pop()

    setSelectedNoteId(orderId)
  }, [location.pathname])

  const memoizedNotes = useMemo(() => notes, [notes])

  const selectedIndex = findIndex(
    memoizedNotes,
    ({ id }) => id === selectedNoteId
  )

  const getItemSize = (data, index) => {
    const note = data[index]
    return guessNoteActivityCardHeight(note.note, !isEmpty(note.tags))
  }

  const RowRenderer = ({ index, style, data }) => {
    const GUTTER = 4
    const note = data[index]
    return (
      <ActivityCard
        key={note.id}
        note={note}
        selected={note.id === selectedNoteId}
        onClick={() => setSelectedNoteId(note.id)}
        style={{
          ...style,
          overflow: 'hidden',
          top: style.top + GUTTER,
          height: style.height - GUTTER,
        }}
      />
    )
  }

  RowRenderer.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
    data: PropTypes.array,
  }

  return (
    <Autosizer>
      {({ width, height }) => (
        <VirtualList
          width={width}
          height={height - 55 /* provide enough space for search bar (55px) */}
          fillParent={false}
          getItemSize={getItemSize}
          items={memoizedNotes}
          scrollTo={selectedIndex}
          rowRenderer={RowRenderer}
        />
      )}
    </Autosizer>
  )
}

NotesActivity.propTypes = {
  notes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
}

export default withRouter(NotesActivity)
