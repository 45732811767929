import moment from 'moment'
import { get, merge, isNil, negate, times } from 'lodash'

import {
  CARGO_SIZE_UNIT,
  PRICE_INDEX,
  STAKEHOLDER_TYPE,
  VALIDITY_TYPE,
} from 'emsurge-selectors/constants'
import { $O } from 'model/order/selectors/selector'
import { DEFAULT_CUSTOM_QUALITY_RANGE } from 'screens/orderCreate/containers/informationInitialValues'
import { isThirdPartyOrder } from 'screens/transactionIndex/helpers'
import { DELIVERY_TYPES } from 'model/order/constants/cargo'
import {
  getCargoSizeType,
  getCargoSizeParty,
  getCargoSizeVariance,
} from 'screens/orderCreate/helpers'

const exists = negate(isNil)

const validateBehalfOf = (thirdParty) => {
  return thirdParty ? STAKEHOLDER_TYPE.OFF_SYSTEM : STAKEHOLDER_TYPE.ON_SYSTEM
}

export const getValidity = ({ validUntil, validityType }) => {
  if (validityType === VALIDITY_TYPE.GOOD_TILL_DATE) {
    return {
      until: VALIDITY_TYPE.GOOD_TILL_DATE,
      date: moment.utc(validUntil).format('YYYY-MM-DD'),
      time: moment.utc(validUntil).format('HH:mm'),
    }
  }

  return {
    until: validityType,
  }
}

const getStep1 = (order) => {
  const orderInformation = {
    behalfOf: validateBehalfOf(order.thirdParty),
    tradingType: order.tradingType,
    details: order.details,
    validity: getValidity(order),
  }

  if (!isThirdPartyOrder(order)) {
    orderInformation.myCompany = {
      brokerEntityId: order.brokerId,
      // a trader's information
      companyId: order.companyId,
      entityId: order.entityId,
      traderId: order.personId,
    }

    orderInformation.thirdParty = {
      companyName: '',
      entityName: '',
      contact: '',
    }
  } else {
    orderInformation.myCompany = { brokerEntityId: order.brokerId }
    orderInformation.thirdParty = order.thirdParty
  }

  return orderInformation
}

export const getStep2 = (order) => ({
  delivery: {
    ...order.delivery,
    type: order.delivery.type,
  },
  volume: {
    ...order.volume,
    type: order.volume.min && order.volume.max ? 'range' : 'fixed',
  },
})

export const DEFAULT_HYBRID_PRICE = {
  weight: '',
  percentage: 100,
  index: PRICE_INDEX.NBP,
  plusOrMinus: '+',
  amount: '',
  unit: 'mmbtu',
}

const PRICE_DEFAULT = {
  enable: false,
  type: 'floating',
  floatingInfo: {
    percentage: 100,
    index: PRICE_INDEX.NBP,
    plusOrMinus: '+',
    amount: '',
    unit: 'mmbtu',
  },
  fixedInfo: {
    value: '',
    unit: 'mmbtu',
  },
  hybridInfo: times(2).map(() => ({ ...DEFAULT_HYBRID_PRICE })),
  rfqInfo: {
    deadline: { date: moment().format('YYYY-MM-DD'), time: '00:00' },
    award: { date: moment().format('YYYY-MM-DD'), time: '00:00' },
    notes: '',
  },
  preferenceInfo: {
    value: 'tbd',
  },
}

const getInitialPriceValues = (order, type) => {
  const priceType = get(order, `price.${type}.type`)
  const enable = exists(priceType)

  if (!enable) {
    return PRICE_DEFAULT
  }

  const floatingInfo =
    priceType === 'floating'
      ? order.price[type].info
      : PRICE_DEFAULT.floatingInfo
  const fixedInfo =
    priceType === 'fixed' ? order.price[type].info : PRICE_DEFAULT.fixedInfo
  const hybridInfo =
    priceType === 'hybrid' ? order.price[type].info : PRICE_DEFAULT.hybridInfo
  const rfqInfo =
    priceType === 'rfq' ? order.price[type].info : PRICE_DEFAULT.rfqInfo
  const preferenceInfo =
    priceType === 'preference' ? order.price[type].info : PRICE_DEFAULT.rfqInfo

  return {
    enable,
    type: priceType,
    fixedInfo,
    floatingInfo,
    hybridInfo,
    preferenceInfo,
    rfqInfo,
  }
}

export const getStep3 = (order) => ({
  price: {
    basic: getInitialPriceValues(order, 'basic'),
    reserve: getInitialPriceValues(order, 'reserve'),
    contract: getInitialPriceValues(order, 'contract'),
  },
})

export const getStep4 = (order) => {
  const deliveryType = get(
    order,
    'delivery.type',
    DELIVERY_TYPES.DES
  ).toLowerCase()
  const party = get(order, 'cargoSize.party', getCargoSizeParty(deliveryType))
  const defaultVariance = getCargoSizeVariance(party)
  const unit = get(order, 'cargoSize.unit', CARGO_SIZE_UNIT.TBTU)

  return {
    cargoSize: {
      unit,
      type: get(order, 'cargoSize.type', getCargoSizeType(unit)),
      party,
      sizeMin: get(order, 'cargoSize.sizeMin', ''),
      sizeMax: get(order, 'cargoSize.sizeMax', ''),
      variancePlus: get(
        order,
        'cargoSize.variancePlus',
        defaultVariance.variancePlus
      ),
      varianceMinus: get(
        order,
        'cargoSize.varianceMinus',
        defaultVariance.varianceMinus
      ),
    },
  }
}

export const getStep5 = ({ quality }) => ({
  quality: {
    ...quality,
    customMin: $O.quality.isCustom({ quality })
      ? quality.customMin
      : DEFAULT_CUSTOM_QUALITY_RANGE[0],
    customMax: $O.quality.isCustom({ quality })
      ? quality.customMax
      : DEFAULT_CUSTOM_QUALITY_RANGE[1],
  },
})

export const getInformationInitialValues = (order) => {
  const initialValues = merge(
    {},
    order,
    getStep1(order),
    getStep2(order),
    getStep3(order),
    getStep4(order),
    getStep5(order)
  )

  return initialValues
}
