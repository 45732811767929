import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter, useHistory } from 'react-router-dom'
import { withSnackbar } from 'notistack'

import MenuItem from '@material-ui/core/MenuItem'

import { ConfirmActionDialog } from 'components/ConfirmActionDialog'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { useUser } from 'containers/user/useUser'
import { useCacheHelpers } from 'api'
import { ORDER_KEY } from 'api/constants'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'
import { Mobile, Tablet } from 'components/Viewport'
import { MenuItemWithConfirm } from 'components/MenuItemWithConfirm'

const enhance = compose(withSnackbar, withRouter)

const DELETE_ORDER_TEXT =
  'You are about to permanently delete this Order.'

const DeleteOrderMenuItem = ({ order, closeMenu, enqueueSnackbar }) => {
  // only the user that created the order can delete it
  const history = useHistory()
  const BASE_SLUG = useRouteBaseSlug()
  const { user } = useUser()
  const [open, setOpen] = React.useState(false)
  const api = useApi()
  const { removeFromCache } = useCacheHelpers()

  const userCanDeleteOrder =
    order.personId === user.id || order.brokerPersonId === user.id

  if (!userCanDeleteOrder || order.template) {
    return null
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleCancelDelete = () => {
    setOpen(false)
    closeMenu()
  }

  const onConfirm = async () => {
    try {
      await api.delete(`/orders/${order.id}`)
      const termsheets = order?.termsheets?.map(({ id }) => ({ id }))
      removeFromCache(ORDER_KEY, [{ id: order.id }, ...termsheets]) //  TODO: replace with mutation
      enqueueSnackbar('Order deleted successfully', { variant: 'success' })
      closeMenu()
      history.push(`/${BASE_SLUG}`)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Order delete failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <>
      <Tablet matchLarger>
        <MenuItem
          data-testid="menu-delete-order-button"
          onClick={handleClickOpen}
        >
          DELETE ORDER
        </MenuItem>
        <ConfirmActionDialog
          onCancel={handleCancelDelete}
          onConfirm={onConfirm}
          open={open}
          text={DELETE_ORDER_TEXT}
        />
      </Tablet>
      <Mobile>
        <MenuItemWithConfirm
          data-testid="menu-delete-order-button"
          title="Delete"
          text={DELETE_ORDER_TEXT}
          expanded={open}
          onCollapse={() => setOpen(false)}
          onClick={() => setOpen((value) => !value)}
          onConfirm={onConfirm}
        />
      </Mobile>
    </>
  )
}

DeleteOrderMenuItem.propTypes = {
  order: PropTypes.object.isRequired,
  closeMenu: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default enhance(DeleteOrderMenuItem)
