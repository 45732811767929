import React from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import InfoText from 'screens/companyViewRequest/components/InfoText'
import { getAddress } from 'screens/companyViewRequest/helper'

const UserInfo = ({ user }) => {
  const { company } = user

  return (
    <section>
      <Grid container spacing={2}>
        {/** GENERAL INFORMATION */}
        <Grid item xs={4}>
          <Text variant="h2" gutterBottom>
            General information
          </Text>

          <InfoText header="Name" info={`${user.name} ${user.surname}`} />
          <InfoText header="Job title" info={user.jobTitle} />
          <InfoText header="Status" info={capitalize(user.status)} />
          <InfoText header="Email" info={user.email} />
          <InfoText header="Phone" info={user.phone} />
        </Grid>

        {/** COMPANY INFORMATION */}
        <Grid item xs={4}>
          <Text variant="h2" gutterBottom>
            Company information
          </Text>

          <InfoText header="Company Brand name" info={company.name} />
          <InfoText
            header="Parent company Entity"
            info={company.parentCompany}
          />
          <InfoText
            header="Registration Number"
            info={company.registrationNumber}
          />
          <InfoText header="Address" info={getAddress(company)} />
        </Grid>
      </Grid>
    </section>
  )
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserInfo
