import React from 'react'
import PropTypes from 'prop-types'
import MuiButton from '@material-ui/core/Button'
import { connect } from 'formik'
import { isEmpty, isNil, get, toPairs } from 'lodash'
import flatten from 'flat'
import IconButton from 'components/iconButton/IconButton'

/* :: Formik -> number */
export const getTotalFormErrors = ({ touched, errors }) => {
  const totalTouchedFieldError = toPairs(flatten(errors))
    .map(([fieldPath, fieldError]) => {
      const isFieldTouched = get(touched, fieldPath)

      return isFieldTouched && !isEmpty(fieldError) ? 1 : 0
    })
    .reduce((acc, x) => acc + x, 0)

  return totalTouchedFieldError
}

const FormikSubmitButton = ({ formik: form, ...props }) => {
  const errors = getTotalFormErrors(form)
  const isDisabled = form.isSubmitting || errors > 0 || props.disabled
  const Button = isNil(props.icon) ? MuiButton : IconButton

  return (
    <Button
      variant="contained"
      color="primary"
      {...props}
      type="submit"
      disabled={isDisabled}
    />
  )
}

FormikSubmitButton.propTypes = {
  formik: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
}

export default connect(FormikSubmitButton)
