import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import {
  StepTitle,
  MainTitle,
  FormInstructionsTitle,
} from './components/Titles'
import CompanyInformationForm from './containers/CompanyInformationForm'
import CompanyPrimaryContactForm from './containers/CompanyPrimaryContactForm'
import CompanyAdminForm from './containers/CompanyAdminForm'
import TradingEntityForm from './containers/TradingEntityForm'
import { COMPANY_INFORMATION_INITIAL_VALUES } from './components/CompanyInformation/FormInitialValues'
import { COMPANY_PRIMARY_CONTACT_INITIAL_VALUES } from './components/CompanyPrimaryContact/FormInitialValues'
import { COMPANY_ADMIN_INITIAL_VALUES } from './components/CompanyAdmin/FormInitialValues'
import { TRADING_ENTITY_INITIAL_VALUES } from './components/TradingEntities/FormInitialValues'
import { omitEmpty } from 'utils/formHelpers'

const steps = [
  'General Information',
  'Primary point of contact',
  'Primary Account Holder',
  'Trading Entities',
]

class CompanyForm extends Component {
  state = {
    copyPrimaryEntity: false,
    copyCompanyAdmin: false,
    activeStep: 0,
    companyAdmin: undefined,
    company: undefined,
    companyPrimaryContact: undefined,
    entity: undefined,
  }

  handleNext = ({ form, values }) => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
      [form]: values,
    }))
  }

  handleBack = ({ form, values }) => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
      [form]: values,
    }))
  }

  handleCopyCompanyAdmin = (copyCompanyAdmin) => {
    const { companyAdmin } = this.props
    let valuesToUse = companyAdmin
    if (copyCompanyAdmin) {
      const { name, surname, jobTitle, email, phone } =
        this.state.companyPrimaryContact
      valuesToUse = {
        name,
        surname,
        jobTitle,
        email,
        phone,
      }
    }
    this.setState({
      copyCompanyAdmin,
      companyAdmin: valuesToUse,
    })
  }

  handleCopyPrimaryEntity = (copyPrimaryEntity) => {
    const { entity } = this.props
    let valuesToUse = entity

    if (copyPrimaryEntity) {
      const {
        name,
        registrationNumber,
        addressFirstLine,
        addressSecondLine,
        postcode,
        city,
        country,
        parentCompany,
        type,
      } = this.state.company
      valuesToUse = {
        ...entity,
        addressFirstLine,
        addressSecondLine,
        authorisedSignatory: this.state.companyAdmin,
        city,
        country,
        name,
        pointOfContact: this.state.companyPrimaryContact,
        postcode,
        registrationName: parentCompany,
        registrationNumber,
        type,
      }
    }

    this.setState({
      entity: valuesToUse,
      copyPrimaryEntity,
    })
  }

  handleFinalStep = ({ values }) => {
    const { company, companyAdmin, companyPrimaryContact } = this.state
    const { onSubmit } = this.props
    onSubmit({
      companyAdmin: companyAdmin,
      company: {
        ...omitEmpty(company),
        pointOfContact: omitEmpty(companyPrimaryContact),
      },
      entity: {
        ...omitEmpty(values),
        pointOfContact: omitEmpty(values.pointOfContact),
        authorisedSignatory: omitEmpty(values.authorisedSignatory),
      },
    })
  }

  render() {
    const { activeStep } = this.state
    const { title, subTitle } = this.props
    return (
      <>
        <MainTitle>{title}</MainTitle>
        <FormInstructionsTitle>{subTitle}</FormInstructionsTitle>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{
            backgroundColor: 'transparent',
            paddingLeft: 0,
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                <StepTitle>{label}</StepTitle>
              </StepLabel>
              <StepContent>
                {(() => {
                  switch (activeStep) {
                    case 0:
                      return (
                        <CompanyInformationForm
                          initialValues={
                            this.state.company || this.props.company
                          }
                          handleNext={this.handleNext}
                          handleBack={this.handleBack}
                        />
                      )
                    case 1:
                      return (
                        <CompanyPrimaryContactForm
                          initialValues={
                            this.state.companyPrimaryContact ||
                            this.props.companyPrimaryContact
                          }
                          handleNext={this.handleNext}
                          handleBack={this.handleBack}
                        />
                      )
                    case 2:
                      return (
                        <CompanyAdminForm
                          initialValues={
                            this.state.companyAdmin || this.props.companyAdmin
                          }
                          copyCompanyAdmin={this.state.copyCompanyAdmin}
                          handleNext={this.handleNext}
                          handleBack={this.handleBack}
                          handleCopyCompanyAdmin={this.handleCopyCompanyAdmin}
                        />
                      )
                    case 3:
                      return (
                        <TradingEntityForm
                          initialValues={this.state.entity || this.props.entity}
                          copyPrimaryEntity={this.state.copyPrimaryEntity}
                          handleBack={this.handleBack}
                          handleFinish={this.handleFinalStep}
                          handleCopyPrimaryEntity={this.handleCopyPrimaryEntity}
                        />
                      )
                    default:
                      throw new Error('Unknown step')
                  }
                })()}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </>
    )
  }
}

CompanyForm.propTypes = {
  companyAdmin: PropTypes.object,
  company: PropTypes.object,
  companyPrimaryContact: PropTypes.object,
  entity: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}

CompanyForm.defaultProps = {
  companyAdmin: {
    ...COMPANY_ADMIN_INITIAL_VALUES,
  },
  company: {
    ...COMPANY_INFORMATION_INITIAL_VALUES,
  },
  companyPrimaryContact: {
    ...COMPANY_PRIMARY_CONTACT_INITIAL_VALUES,
  },
  entity: {
    ...TRADING_ENTITY_INITIAL_VALUES,
  },
}

export default CompanyForm
