const moment = require('moment')

const getTimestamps = (order) => {
  const { createdAt, updatedAt, deletedAt } = order
  const timestamps = {}

  if (createdAt) {
    timestamps.createdAt = new Date(createdAt)
  }
  if (updatedAt) {
    timestamps.updatedAt = new Date(updatedAt)
  }
  if (deletedAt) {
    timestamps.deletedAt = new Date(deletedAt)
  }

  return timestamps
}

const getTimestampsSummary = (order) => {
  const { createdAt, updatedAt, deletedAt } = getTimestamps(order)
  const timestampsSummary = {}

  if (createdAt) {
    timestampsSummary.createdAt = moment(createdAt).format(
      'Do MMM YYYY ddd HH:mm'
    )
  }
  if (updatedAt) {
    timestampsSummary.updatedAt = moment(updatedAt).format(
      'Do MMM YYYY ddd HH:mm'
    )
  }
  if (deletedAt) {
    timestampsSummary.deletedAt = moment(deletedAt).format(
      'Do MMM YYYY ddd HH:mm'
    )
  }

  return timestampsSummary
}

module.exports = {
  get: getTimestamps,
  summary: getTimestampsSummary,
}
