import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import { getInformationInitialValues } from './getInformationInitialValues'
import { validateOrder } from 'screens/orderCreate/containers/validateOrder'
import { getSchema } from 'screens/orderCreate/containers/FormSchema'
import { getOrderInfo } from 'screens/orderCreate/containers/FormProvider'
import { getNominationInitialValues } from 'screens/orderEdit/containers/getNominationInitialValues'
import { useApi } from 'containers/api/useApi'
import { useUser } from 'containers/user/useUser'
import { getApiErrorMessage } from 'containers/api/helpers'
import { useOrderState } from 'containers/OrderProvider'

const getInitialValues = (order, user) => {
  const initialValues = {
    ...getInformationInitialValues(order, user),
    ...getNominationInitialValues(order),
    template: false, // When we clone a template, the template checkbox should be unticked
    comments: [],
    brokerAccess: [],
    broker: undefined,
    details: order.details,
  }

  return initialValues
}

export const _FormProvider = ({
  order,
  enqueueSnackbar,
  postSubmit,
  children,
}) => {
  const api = useApi()
  const { user } = useUser()
  const { setOrderState } = useOrderState()

  const snackBarSuccessMsg = useMemo(() => {
    const action = order.template ? 'created' : 'cloned'
    return `Order ${action} successfully`
  }, [order])

  order.submittedAt = new Date().toISOString()

  const handleSubmit = async (values, actions) => {
    const data = getOrderInfo(values, user)
    try {
      const { data: order } = await api.post('/orders', data)
      actions.resetForm()
      enqueueSnackbar(snackBarSuccessMsg, { variant: 'success' })

      postSubmit(order)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Order cloned failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
      actions.setSubmitting(false)
    }
  }

  useEffect(() => {
    if (order.thirdParty) {
      return
    }

    setOrderState({ isPending: true })
  }, [setOrderState, order])

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getInitialValues(order, user)}
      validationSchema={getSchema(user)}
      validate={validateOrder}
      validateOnMount={false}
    >
      {children}
    </Formik>
  )
}

_FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  postSubmit: PropTypes.func,
}

export default compose(withSnackbar, withRouter)(_FormProvider)
