const moment = require('moment')

const getSubmittedAt = (order) => {
  const { submittedAt } = order

  if (submittedAt) {
    return new Date(submittedAt)
  }
}

const getSubmittedAtText = (order) => {
  const submittedAt = getSubmittedAt(order)

  if (submittedAt) {
    return moment(submittedAt).format('Do MMM YYYY ddd HH:mm (UTC Z)')
  }

  return ''
}

module.exports = {
  get: getSubmittedAt,
  text: getSubmittedAtText,
}
