export const useTruncatedTextObject = (array, mapFunc, maxLength, separator = ', ', truncatedSuffix = '...') => {
    const text = (array || []).map(mapFunc).join(separator)
    const truncatedText =
        text.length > maxLength
            ? (text.substring(0, maxLength) + truncatedSuffix)
            : text.substring(0, maxLength)
    const result = {
        truncatedText: truncatedText
    }
    if (text.length > maxLength) {
        result['completeText'] = text
    }

    return result
}
