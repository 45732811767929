import PropTypes from 'prop-types'
import { isFunction } from 'lodash'

const If = (props) => {
  let render

  if (props.condition) {
    render = props.then || props.children
  } else {
    render = props.else
  }

  return isFunction(render) ? render() : render
}

If.defaultProps = {
  else: null,
}

If.propTypes = {
  condition: PropTypes.bool.isRequired,
  then: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  else: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

export default If
