import {ApplicationInsights, DistributedTracingModes} from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { getInitialUserState } from 'containers/user/helpers'

let appInsights = null;

export const createTelemetryService = (appInsightsConnectionString, history) => {
        const reactPluginConfig = { history: history }
        const reactPlugin = new ReactPlugin()
    
        const clickPlugin = new ClickAnalyticsPlugin()
        const clickPluginConfig = {
            autoCapture: true,
            useDefaultContentNameOrId: true,
        }
    
        console.debug('Initialising appInsights...')
        appInsights = new ApplicationInsights({
            config: {
            connectionString: appInsightsConnectionString,
            extensions: [reactPlugin, clickPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: reactPluginConfig,
                [clickPlugin.identifier]: clickPluginConfig,
            },
            autoTrackPageVisitTime: true,
            // These settings are required to correlate client requests with their server activities.
            // See https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#enable-correlation for details about
            disableFetchTracking: false,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            distributedTracingMode: DistributedTracingModes.W3C,
            },
        })
        console.log('appInsights has been initialised')
        appInsights.loadAppInsights()
     
        const configureAuthenticatedUserInAppInsights = (userId) => {
            var sanitizedUserId = userId?.replace(/[,;=| ]+/g, '_')
            if (appInsights) {
              // It looks like under some circumstances, appInsights is still null at this point.
              // This is causing an error in the app which prevent a user from logging out.
              appInsights.setAuthenticatedUserContext(sanitizedUserId)
            }
        }
    
        const user = getInitialUserState()
        if(user.email)
        {
            configureAuthenticatedUserInAppInsights(user.email)
        }

        return reactPlugin   
};

export const configureAuthenticatedUserInAppInsights = (userId) => {
    var sanitizedUserId = userId?.replace(/[,;=| ]+/g, '_')
    if (appInsights) {
      // It looks like under some circumstances, appInsights is still null at this point.
      // This is causing an error in the app which prevent a user from logging out.
      appInsights.setAuthenticatedUserContext(sanitizedUserId)
    }
    else{
        console.error("AppInsight is not available. A call to createTelemetryService.initialise should be made first")
    }
}
