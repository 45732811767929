import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import { PROJECT_PATH, getProjectNr, TRADING_TYPE } from 'emsurge-selectors'
import { cloneDeep, get } from 'lodash'
import { ProjectBuyer } from '../components/ProjectBuyer'
import { ProjectSeller } from '../components/ProjectSeller'
import { AddButton } from 'screens/orderCreateCommon/components/AddButton'
import Card from 'screens/orderCreate/components/Card'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'

const ProjectSellerComponent = ({ index }) => (
  <ProjectSeller key={index} projectNumber={index} />
)

const Wrapper = ({ removeProject, index }) => (
  <Card key={`project_specification_${index}`} style={{ marginTop: '24px' }}>
    <CloseButton onClick={() => removeProject(index)} />
    <ProjectSellerComponent index={index} />
  </Card>
)

const _ProjectSpecification = ({ formik: { values, setFieldValue } }) => {
  const [incremental, setIncremental] = useState(0)

  const addProject = () => {
    setIncremental(incremental + 1)
    setFieldValue(`${getProjectNr(incremental)}`, {})
  }

  useEffect(() => {
    const projects = get(values, PROJECT_PATH) || []
    const nProjects = projects.length
    let index = 0
    while (index < nProjects) {
      setFieldValue(getProjectNr(index), projects[index])
      index++
    }
    setIncremental(index)
  }, [])

  const removeProject = (id) => {
    const projects = cloneDeep(get(values, PROJECT_PATH))
    const nProjects = projects.length
    let index = id
    while (index < nProjects) {
      const isLastPosition = index === nProjects - 1
      if (isLastPosition) {
        projects.pop()
      } else {
        projects[index] = projects[index + 1]
      }
      index++
    }
    setFieldValue(PROJECT_PATH, projects)
    setIncremental(incremental - 1)
  }

  return values.tradingType === TRADING_TYPE.BID ? (
    <ProjectBuyer />
  ) : (
    <>
      {Array.from({ length: incremental }).map((_, idx) =>
        idx === 0 ? (
          <ProjectSellerComponent key={idx} index={idx} />
        ) : (
          <Wrapper key={idx} removeProject={removeProject} index={idx} />
        )
      )}
      <AddButton
        data-testid="add-project-button"
        onClick={addProject}
        style={{ marginTop: '16px' }}
      >
        ADD PROJECT
      </AddButton>
    </>
  )
}

export const ProjectSpecification = connect(_ProjectSpecification)
