import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import { connect } from 'formik'
import { get } from 'lodash'
import { withTheme } from '@material-ui/core'
import { compose } from 'recompose'
import TimeFrame from '../components/TimeFrame'
import DeliveryLocationCard from '../components/DeliveryLocationCard'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import Checkbox from 'containers/formikCheckbox/FormikCheckbox'
import TextField from 'containers/formikTextField/FormikTextField'
import FormikRadioInput from 'containers/FormikRadioInput/FormikRadioInput'

export const Party = ({
  values,
  setPartyDefaultValue,
  name,
  disabled,
  toBeNominated,
}) => {
  useEffect(() => {
    !toBeNominated && setPartyDefaultValue(name)
  }, [name, setPartyDefaultValue, toBeNominated, values.delivery.type])

  return (
    <FormControl fullWidth>
      <FormLabel>Party</FormLabel>
      <FormikRadioInput
        name={name}
        options={[
          { disabled, value: 'bid', label: 'Buyer' },
          { disabled, value: 'ask', label: 'Seller' },
          { disabled, value: 'tba', label: 'TBA' },
        ]}
      />
    </FormControl>
  )
}

Party.propTypes = {
  toBeNominated: PropTypes.bool,
  values: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setPartyDefaultValue: PropTypes.func,
}

Party.defaultProps = {
  setPartyDefaultValue: () => {},
}

export const ToBeNominated = (props) => (
  <FormControl fullWidth>
    <FormControlLabel
      label="To be nominated"
      control={<Checkbox {...props} color="primary" />}
    />
  </FormControl>
)

const NominationPort = ({
  mainName,
  shouldBeDisabled,
  portNameLabel,
  theme,
  formik: { values },
  setPartyDefaultValue,
  setNameDefaultValue,
}) => {
  const getFieldName = useCallback(
    (relativeName) => `${mainName}.${relativeName}`,
    [mainName]
  )
  const getFieldValue = (relativeName) =>
    get(values, `${mainName}.${relativeName}`)

  useEffect(() => {
    setNameDefaultValue(getFieldName('name'))
  }, [
    getFieldName,
    setNameDefaultValue,
    values.delivery.location,
    values.delivery.type,
  ])

  return (
    <section style={{ paddingTop: theme.spacing(1) }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <TextField
            fullWidth
            margin="normal"
            multiline
            InputLabelProps={{
              error: false,
            }}
            type="text"
            name={getFieldName('name')}
            label={portNameLabel}
            disabled={shouldBeDisabled('port')}
          />
        </Grid>
        <Grid item xs>
          <DeliveryLocationCard title="Delivery Port" />
        </Grid>
      </Grid>

      <ToBeNominated
        disabled={shouldBeDisabled('toBeNominated')}
        name={getFieldName('toBeNominated')}
        value={getFieldName('toBeNominated')}
      />

      <Party
        toBeNominated={getFieldValue('toBeNominated')}
        values={values}
        disabled={shouldBeDisabled('party')}
        name={getFieldName('party')}
        setPartyDefaultValue={setPartyDefaultValue}
      />

      <TimeFrame
        disabled={shouldBeDisabled('timeframe')}
        mainName={mainName}
        timeframe={getFieldValue('timeframe')}
      />
    </section>
  )
}

NominationPort.propTypes = {
  formik: PropTypes.object.isRequired,
  mainName: PropTypes.string.isRequired,
  portNameLabel: PropTypes.string.isRequired,
  shouldBeDisabled: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  setPartyDefaultValue: PropTypes.func,
  setNameDefaultValue: PropTypes.func,
}

NominationPort.defaultProps = {
  setNameDefaultValue: () => {},
}

const enhance = compose(connect, withTheme)

export default enhance(NominationPort)
