import React from 'react'
import PropTypes from 'prop-types'
import Edit from '@material-ui/icons/Edit'
import Add from '@material-ui/icons/Add'
import { withTheme } from '@material-ui/styles'
import { COMPANY_STATUS, COMPANY_TYPE } from 'emsurge-selectors/constants'
import CreateCompany from '../containers/ApproveCompanyDialog'
import Wrapper from './Wrapper'
import Button from 'components/iconButton/IconButton'
import withParams from 'utils/withRouteParams'
import { ADMIN_EDIT_COMPANY } from 'routes'

const allowedTypes = [COMPANY_TYPE.TRADER, COMPANY_TYPE.BROKER]

const Header = ({ company, theme, history, id: companyId }) => {
  if (!allowedTypes.includes(company.type)) {
    return null
  }

  return (
    <Wrapper>
      <Button
        icon={Edit}
        variant="contained"
        color="primary"
        style={{ marginRight: theme.spacing(1) }}
        onClick={() =>
          history.push(ADMIN_EDIT_COMPANY.replace(':id', companyId))
        }
      >
        Edit
      </Button>

      {company.status === COMPANY_STATUS.APPLICATION_STARTED && (
        <CreateCompany company={company}>
          {({ accept }) => (
            <Button
              icon={Add}
              variant="contained"
              color="primary"
              onClick={accept}
            >
              Create
            </Button>
          )}
        </CreateCompany>
      )}
    </Wrapper>
  )
}

Header.propTypes = {
  theme: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
}

export default withTheme(withParams(Header))
