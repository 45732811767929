const {
  DELIVERY_TYPE,
  DELIVERY_PERIOD_TYPE,
  DELIVERY_REGION_TYPE,
} = require('./delivery')

const { PRICE_INDEX } = require('./price')

const MARKET_GRID_COLUMNS = Object.freeze({
  MID: 'mid',
})

const MARKET_GRID_ACTUAL_IMPLIED = Object.freeze({
  ACTUAL: 'actual',
  IMPLIED: 'implied',
})

const MARKET_GRID_MARKET_PROVIDER = Object.freeze({
  EMSURGE: '1',
  CT_GRID: '10',
})

const MARKET_GRID_MARKET_PROVIDER_TEXT = Object.freeze({
  [MARKET_GRID_MARKET_PROVIDER.EMSURGE]: 'Emsurge',
  [MARKET_GRID_MARKET_PROVIDER.CT_GRID]: 'Evolution',
  EMSURGE: 'Emsurge',
  EVOLUTION: 'Evolution',
})

const MARKET_GRID_MARKET_PROVIDER_TEXT_SHORT = Object.freeze({
  [MARKET_GRID_MARKET_PROVIDER.EMSURGE]: '',
  [MARKET_GRID_MARKET_PROVIDER.CT_GRID]: 'Evo',
  EMSURGE: '',
  EVOLUTION: 'Evo',
})

const MARKET_GRID_PERIOD_DEFAULT_VALUES = Object.freeze({
  [DELIVERY_PERIOD_TYPE.MONTH]: 9,
  [DELIVERY_PERIOD_TYPE.QUARTER]: 4,
  [DELIVERY_PERIOD_TYPE.SEASON]: 4,
  [DELIVERY_PERIOD_TYPE.YEAR]: 4,
  MONTH: 9,
  QUARTER: 4,
  SEASON: 4,
  YEAR: 4,
})

const MARKET_GRID_DEFAULT_GRID = Object.freeze({
  name: 'Grid 1',
  markets: [
    {
      provider: MARKET_GRID_MARKET_PROVIDER.EMSURGE,
      name: 'DES NWE',
      value: {
        type: DELIVERY_TYPE.DES,
        location: [DELIVERY_REGION_TYPE.NWE],
      },
      index: 0,
    },
    {
      provider: MARKET_GRID_MARKET_PROVIDER.EMSURGE,
      name: 'DES MED',
      value: {
        type: DELIVERY_TYPE.DES,
        location: [DELIVERY_REGION_TYPE.MED],
      },
      index: 1,
    },
    {
      provider: MARKET_GRID_MARKET_PROVIDER.CT_GRID,
      name: 'TTF [Evo]',
      value: {
        type: PRICE_INDEX.TTF,
      },
      index: 2,
    },
    {
      provider: MARKET_GRID_MARKET_PROVIDER.CT_GRID,
      name: 'NBP [Evo]',
      value: {
        type: PRICE_INDEX.NBP,
      },
      index: 3,
    },
    {
      provider: MARKET_GRID_MARKET_PROVIDER.EMSURGE,
      name: 'DES JKTC',
      value: {
        type: DELIVERY_TYPE.DES,
        location: [DELIVERY_REGION_TYPE.JKTC],
      },
      index: 4,
    },
    {
      provider: MARKET_GRID_MARKET_PROVIDER.CT_GRID,
      name: 'JKM [Evo]',
      value: {
        type: PRICE_INDEX.JKM,
      },
      index: 5,
    },
    {
      provider: MARKET_GRID_MARKET_PROVIDER.CT_GRID,
      name: 'JKM/TTF [Evo]',
      value: {
        type: PRICE_INDEX.JKM_TTF,
      },
      index: 6,
    },
    {
      provider: MARKET_GRID_MARKET_PROVIDER.CT_GRID,
      name: 'JKM/NBP [Evo]',
      value: {
        type: PRICE_INDEX.JKM_NBP,
      },
      index: 7,
    },
    {
      provider: MARKET_GRID_MARKET_PROVIDER.CT_GRID,
      name: 'NBP/TTF [Evo]',
      value: {
        type: PRICE_INDEX.NBP_TTF,
      },
      index: 8,
    },
  ],
  periods: [
    {
      type: DELIVERY_PERIOD_TYPE.MONTH,
      value: 9,
      index: 0,
    },
    {
      type: DELIVERY_PERIOD_TYPE.QUARTER,
      value: 4,
      index: 1,
    },
    {
      type: DELIVERY_PERIOD_TYPE.SEASON,
      value: 4,
      index: 2,
    },
    {
      type: DELIVERY_PERIOD_TYPE.YEAR,
      value: 4,
      index: 3,
    },
  ],
})

module.exports = {
  MARKET_GRID_COLUMNS,
  MARKET_GRID_MARKET_PROVIDER,
  MARKET_GRID_MARKET_PROVIDER_TEXT,
  MARKET_GRID_MARKET_PROVIDER_TEXT_SHORT,
  MARKET_GRID_PERIOD_DEFAULT_VALUES,
  MARKET_GRID_DEFAULT_GRID,
  MARKET_GRID_ACTUAL_IMPLIED,
}
