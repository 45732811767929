const NOTE_PRIORITY = Object.freeze({
  MEMO: 1,
  LOW: 2,
  MEDIUM: 3,
  HIGH: 4,
})

const NOTE_PRIORITY_TEXT = Object.freeze({
  [NOTE_PRIORITY.MEMO]: 'Memo',
  [NOTE_PRIORITY.LOW]: 'Low',
  [NOTE_PRIORITY.MEDIUM]: 'Medium',
  [NOTE_PRIORITY.HIGH]: 'High',
})

const NOTE_PRIORITY_COLOR = Object.freeze({
  [NOTE_PRIORITY.MEMO]: '#1EB2FF',
  [NOTE_PRIORITY.LOW]: '#FFF900',
  [NOTE_PRIORITY.MEDIUM]: '#FF7400',
  [NOTE_PRIORITY.HIGH]: '#FE3851',
  MEMO: '#1EB2FF',
  LOW: '#FFF900',
  MEDIUM: '#FF7400',
  HIGH: '#FE3851',
})

module.exports = {
  NOTE_PRIORITY,
  NOTE_PRIORITY_COLOR,
  NOTE_PRIORITY_TEXT,
}
