const { CARGO_SIZE_UNIT_TEXT } = require('../../../constants')

const getCargoSizeUnit = (order) => {
  const cargoSize = order.cargoSize || {}

  return cargoSize.unit
}

const getCargoSizeUnitText = (order) => {
  const unit = getCargoSizeUnit(order)

  return CARGO_SIZE_UNIT_TEXT[unit] || ''
}

module.exports = {
  get: getCargoSizeUnit,
  text: getCargoSizeUnitText,
}
