import React, { useEffect, useState } from 'react'
import { connect } from 'formik'
import { get } from 'lodash'
import {
  PRICE_TYPE_KEYS,
  PRICE_TYPE_OPTIONS,
  PRICE_TYPE_PREFIX,
  PRICE_FIXED_PREFIX,
  PRICE_FIXED_CURRENCY_PREFIX,
  priceTypeIsFixed,
  priceTypeIsRange,
  priceTypeIsVolumeDependent,
  priceTypeIsTBD,
  priceTypeIsRFQ,
  PRICE_RANGE_PREFIX,
  PRICE_VOLUME_DEPENDENT_PREFIX,
  getPriceVolumeDependentCurrencyPath,
} from 'emsurge-selectors'
import { RadioButtonGroup } from '../components'
import { RangePrice } from '../components/RangePrice'
import { VolumeInput } from '../components/VolumeInput'
import { Tbd } from 'screens/orderCreateCommon/components/Tbd'
import { Rfq } from 'screens/orderCreateCommon/components/Rfq'
import { VolumeDependent } from 'screens/orderCreateCarbon/components/VolumeDependent'

const getType = (values, initialValues) => {
  return (
    get(values, PRICE_TYPE_PREFIX) ||
    get(initialValues, PRICE_TYPE_PREFIX) ||
    PRICE_TYPE_KEYS.FIXED
  )
}

const _Price = ({ formik: { values, initialValues, setFieldValue } }) => {
  const [type, setType] = useState(getType(values, initialValues))

  const setDefaultCurrency = (currencyPath) => {
    const priceCurrency = get(values, currencyPath)
    if (!priceCurrency) {
      setFieldValue(currencyPath, 'USD')
    }
  }

  const setDefaultCurrencyByPriceType = (type) => {
    if (type === PRICE_TYPE_KEYS.FIXED) {
      setDefaultCurrency(PRICE_FIXED_CURRENCY_PREFIX)
    }
    if (type === PRICE_TYPE_KEYS.RANGE) {
      setDefaultCurrency(PRICE_RANGE_PREFIX.currency)
    }
    if (type === PRICE_TYPE_KEYS.VOLUME_DEPENDENT) {
      const priceLines = get(values, PRICE_VOLUME_DEPENDENT_PREFIX) || []
      priceLines.forEach((_, index) => {
        setDefaultCurrency(getPriceVolumeDependentCurrencyPath(index))
      })
    }
  }

  useEffect(() => {
    setType(get(values, PRICE_TYPE_PREFIX))
  }, [values.price.type])

  useEffect(() => {
    setDefaultCurrencyByPriceType(get(values, PRICE_TYPE_PREFIX))
  }, [values.price])

  return (
    <>
      <RadioButtonGroup
        key="checkbox-button-price-type"
        title="Type"
        options={PRICE_TYPE_OPTIONS}
        fieldName={PRICE_TYPE_PREFIX}
      />
      {priceTypeIsFixed(type) && (
        <VolumeInput
          name={PRICE_FIXED_PREFIX}
          label="Amount"
          currencyFieldPath={PRICE_FIXED_CURRENCY_PREFIX}
        />
      )}
      {priceTypeIsRange(type) && <RangePrice />}
      {priceTypeIsVolumeDependent(type) && <VolumeDependent />}
      {priceTypeIsRFQ(type) && <Rfq variant="basic" rfqPath="rfq" />}
      {priceTypeIsTBD(type) && <Tbd />}
    </>
  )
}

export const Price = connect(_Price)
