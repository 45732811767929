import React, { useMemo } from 'react'
import { connect } from 'formik'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { take, slice, find, differenceWith, isEqual } from 'lodash'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import { withStyles } from '@material-ui/styles'
import { DELIVERY_TYPE } from 'emsurge-selectors'
import { DeliveryPeriodContract } from './DeliveryPeriodContract'
import withParams from 'utils/withRouteParams'

import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import { TextField } from 'screens/orderCreateCommon/components/TextField'
import FrequencyDistribution from 'screens/orderCreate/components/FrequencyDistribution'
import { DeliveryType } from 'screens/orderCreate/components/DeliveryType'
import { SelectDeliveryPort } from 'screens/orderCreate/components/SelectDeliveryPort'
import { SelectDeliveryPeriod } from 'screens/orderCreate/components/SelectDeliveryPeriod'
import { SelectCustomDeliveryDate } from 'screens/orderCreate/components/SelectCustomDeliveryDate'
import { SelectDeliveryYear } from 'screens/orderCreateCommon/components/SelectDeliveryYear'
import { SelectNoOfCargoesType } from 'screens/orderCreate/components/SelectNoOfCargoesType'
import { SelectCargosQuantity } from 'screens/orderCreate/components/SelectCargosQuantity'
import { SelectCargosDeliveryFrequency } from 'screens/orderCreate/components/SelectCargosDeliveryFrequency'
import { PERIOD } from 'model/order/constants/delivery'
import { $O } from 'model/order/selectors/selector'
import { getCargoOptions } from 'screens/orderCreate/components/DeliveryWindowCargoes.helpers'
import { CargoCard } from 'screens/orderCreate/desktop/DeliveryWindow/CargoCard'

const styles = () => ({
  label: {
    fontWeight: 'bold',
  },
})

export const _Delivery = ({ location, formik, classes }) => {
  const { focus } = queryString.parse(location.search)
  const { values } = formik
  const { volume, delivery, nominationRules } = values

  const options = useMemo(
    () => getCargoOptions({ volume, delivery, nominationRules }),
    [delivery, nominationRules, volume]
  )

  return (
    <>
      <DeliveryType>
        {({ options, onChange }) => {
          const DES = find(options, ({ value }) => value === DELIVERY_TYPE.DES)
          const FOB = find(options, ({ value }) => value === DELIVERY_TYPE.FOB)
          const rest = differenceWith(options, [DES, FOB], isEqual)

          const deliveryOptions = [
            { ...DES, labelClassName: classes.label },
            { ...FOB, labelClassName: classes.label },
            ...rest,
          ]
          return (
            <>
              {/* ROW 1: DELIVERY TYPE */}
              <FormControl>
                <FormLabel>Type</FormLabel>
                <RadioInputs
                  name="delivery.type"
                  options={deliveryOptions}
                  onChange={(evt) =>
                    onChange(evt.target.value, values.delivery.location)
                  }
                />
              </FormControl>

              {/* ROW 2: DELIVERY PORT */}
              <SelectDeliveryPort>
                {({ options }) => {
                  const NR_OF_VISIBLE_ITEMS_BEFORE_BLANK_SPACE = 12
                  const sortedOptions = [
                    ...take(options, NR_OF_VISIBLE_ITEMS_BEFORE_BLANK_SPACE),
                    '',
                    ...slice(options, NR_OF_VISIBLE_ITEMS_BEFORE_BLANK_SPACE),
                  ]
                  return (
                    <TextField
                      data-testid="delivery-port-dropdown"
                      name="delivery.location"
                      label="Delivery Port"
                      select
                      onChange={(evt) =>
                        onChange(values.delivery.type, evt.target.value)
                      }
                    >
                      {sortedOptions.map((option) => (
                        <MenuItem
                          data-testid="delivery-port-values"
                          key={option}
                          value={option}
                          disabled={!option}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              </SelectDeliveryPort>
            </>
          )
        }}
      </DeliveryType>

      {/* ROW 3: DELIVERY CONTRACT */}
      <Grid container spacing={2}>
        <SelectDeliveryPeriod>
          {({ options, onChange }) => (
            <Grid item xs>
              <TextField
                data-testid="delivery-period-dropdown"
                onChange={(evt) => onChange(evt.target.value)}
                select
                name="delivery.period"
                label="Period"
              >
                {options.map(({ label, value }) => (
                  <MenuItem
                    data-testid="delivery-period-values"
                    key={value}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </SelectDeliveryPeriod>

        {values.delivery.period === PERIOD.CUSTOM ? (
          <SelectCustomDeliveryDate />
        ) : (
          <SelectDeliveryYear focus={focus === 'year'} />
        )}

        <Grid item xs={3} style={{ alignSelf: 'center' }}>
          <DeliveryPeriodContract />
        </Grid>
      </Grid>

      {$O.delivery.contract.isSingleMonth(values) &&
        Boolean(values.volume.min) && <CargoCard index={0} options={options} />}

      {/* ROW 4: NUMBER OF CARGOES */}
      <FormControl fullWidth>
        <FormLabel>No. of Cargoes</FormLabel>
        <SelectNoOfCargoesType>
          {({ options }) => (
            <RadioInputs name="volume.type" options={options} />
          )}
        </SelectNoOfCargoesType>
      </FormControl>

      <Grid container spacing={2}>
        <SelectCargosQuantity focus={focus === 'min'} />

        <Grid item style={{ alignSelf: 'center' }} xs>
          <FormControl>
            <FormLabel>Frequency</FormLabel>
            <SelectCargosDeliveryFrequency>
              {({ options }) => (
                <RadioInputs name="delivery.frequency" options={options} />
              )}
            </SelectCargosDeliveryFrequency>
          </FormControl>
        </Grid>
      </Grid>

      {/* ROW 7: FREQUENCY DISTRIBUTION */}
      <FrequencyDistribution />
    </>
  )
}

_Delivery.propTypes = {
  location: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

const enhance = compose(withParams, connect, withStyles(styles))

export const Delivery = enhance(_Delivery)
