import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

export const HighlightLink = ({ children, ...props }) => (
  <Route url={props.to} path={props.match || props.to} exact={props.exact}>
    {({ match }) => children({ isActive: Boolean(match), props })}
  </Route>
)

HighlightLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  exact: PropTypes.bool,
  match: PropTypes.array,
}
