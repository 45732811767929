import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { withTheme } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'
import { COMPANY_STATUS } from 'emsurge-selectors/constants'
import { getViewCompanyRequestLink, getViewCompanyLink } from '../helpers'
import { useApi } from 'containers/api/useApi'
import TableRowLink from 'components/tableRowLink/TableRowLink'
import { getApiErrorMessage } from 'containers/api/helpers'

const PendingApproval = ({ companies, theme, enqueueSnackbar, history }) => {
  const api = useApi()

  const confirmApplication = async (enqueueSnackbar, company, history) => {
    try {
      await api.patch(`/registration/companies/${company.id}`, {
        company: { status: COMPANY_STATUS.ACTIVE },
      })
      enqueueSnackbar('Confirmation successful', { variant: 'success' })
      history.push(getViewCompanyLink(company))
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Confirmation not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <section
      style={{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      }}
    >
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'left', width: '50%' }}>
                Company
              </TableCell>
              <TableCell
                style={{ textAlign: 'left', width: '25%' }}
                align="right"
              >
                Entities
              </TableCell>
              <TableCell style={{ textAlign: 'right' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRowLink
                to={getViewCompanyRequestLink(company)}
                key={company.id}
                hover
              >
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  {company.name}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'left',
                    width: '25%',
                  }}
                  align="right"
                >
                  {company.entities.length}
                </TableCell>
                <TableCell style={{ textAlign: 'right', paddingRight: 5 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      confirmApplication(enqueueSnackbar, company, history)
                    }}
                  >
                    Confirm admin
                  </Button>
                </TableCell>
              </TableRowLink>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </section>
  )
}

PendingApproval.propTypes = {
  companies: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}
const enhance = compose(withRouter, withSnackbar)
export default withTheme(enhance(PendingApproval))
