import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Grid, Typography as Text } from '@material-ui/core'
import Done from '@material-ui/icons/Done'
import { GridInline } from './GridInline'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'

export const Header = styled.section`
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing(4, 4, 1, 4)};
`

const SaveButton = (props) => (
  <Button
    data-testid="edit-market-grid-save-button"
    id="saveMarketGrid"
    startIcon={<Done />}
    variant="contained"
    color="primary"
    autoFocus
    {...props}
  >
    Save
  </Button>
)

export const ModalHeader = ({ onClose }) => (
  <Header>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Text variant="h6">Edit Grid</Text>
      </Grid>
      <GridInline item>
        <GridInline item>
          <FormConsumer>
            {({ submitForm, isValid }) => (
              <SaveButton onClick={submitForm} disabled={!isValid} />
            )}
          </FormConsumer>
        </GridInline>
        <GridInline item>
          <CloseButton onClick={onClose} />
        </GridInline>
      </GridInline>
    </Grid>
  </Header>
)

ModalHeader.defaultProps = {
  onClose: () => {},
}

ModalHeader.propTypes = {
  onClose: PropTypes.func,
}
