import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import Text from '@material-ui/core/Typography'
import { Interpolate } from 'components/Interpolate'

const PublicNote = ({ order, style }) => {
  const publicNote = order.details

  if (isEmpty(publicNote)) {
    return null
  }

  return (
    <section style={style}>
      <Text data-testid="public-note" style={{ whiteSpace: 'pre-line' }}>
        <Interpolate>{publicNote}</Interpolate>
      </Text>
    </section>
  )
}

PublicNote.propTypes = {
  order: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export default PublicNote
