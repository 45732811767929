import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { connect } from 'formik'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import { Delivery } from './Delivery'
import { Price } from './Price'
import { CargoSize } from './CargoSize'
import { QualityGHV } from './QualityGHV'
import { General } from 'screens/orderCreateCommon/desktop/General'
import { useActiveStep, TAB, STEP } from 'containers/OrderProvider'
import { AddButton } from 'screens/orderCreateCommon/components/AddButton'
import VerticalStepper from 'screens/orderCreateCommon/components/VerticalStepper'
import StepLabel from 'screens/orderCreateCommon/components/StepLabel'
import StepActions, {
  validNextStep,
} from 'screens/orderCreateCommon/components/StepActions'
import {
  getInfoStep1Errors,
  getInfoStep2Errors,
  getInfoStep3Errors,
  getInfoStep4Errors,
  getInfoStep5Errors,
} from 'screens/orderCreate/getErrors'

export const steps = [
  { title: 'General Information', component: General, number: 1 },
  { title: 'Delivery Information', component: Delivery, number: 2 },
  { title: 'Price', component: Price, number: 3 },
  { title: 'Cargo Size', component: CargoSize, number: 4 },
  { title: 'Quality GHV (in Btu/scf)', component: QualityGHV, number: 5 },
]

export const stepsNewTrade = [
  { title: 'Trade Information', component: General, number: 1 },
  { title: 'Delivery Information', component: Delivery, number: 2 },
  { title: 'Price', component: Price, number: 3 },
  { title: 'Cargo Size', component: CargoSize, number: 4 },
  { title: 'Quality GHV (in Btu/scf)', component: QualityGHV, number: 5 },
]

const getStepsForContext = (isCreatingTrade) => {
  if(isCreatingTrade){
    return stepsNewTrade
  }
  return steps
}

const InformationFormStepper = ({ company, formik, isCreatingTrade }, ) => {
  const {
    activeStep,
    navigate: { goTo, goBack, goForward },
  } = useActiveStep()

  const stepErrorGetters = [
    getInfoStep1Errors,
    getInfoStep2Errors,
    getInfoStep3Errors,
    getInfoStep4Errors,
    getInfoStep5Errors,
  ]
  const stepsWithContext = getStepsForContext(isCreatingTrade)
  return (
    <VerticalStepper activeStep={activeStep.step} nonLinear>
      {stepsWithContext.map(({ title, component: Content }, idx) => {
        const currentStepErrors = stepErrorGetters[idx](formik)

        return (
          <Step key={title}>
            <StepLabel
              onClick={() => goTo({ step: idx })}
              error={!isEmpty(currentStepErrors)}
            >
              {title}
            </StepLabel>

            <StepContent>
              <Content company={company} isCreatingTrade={isCreatingTrade}/>
              <StepActions
                totalSteps={stepsWithContext.length}
                activeStep={activeStep.step}
                onBack={goBack}
                onNext={() => validNextStep(formik, goForward)}
                completeButton={
                  <AddButton
                    data-testid="order-creation-add-terms-button"
                    onClick={() =>
                      goTo({
                        tab: TAB.TERMS,
                        step: STEP[TAB.TERMS].DELIVERY_WINDOW,
                      })
                    }
                  >
                    Add Terms
                  </AddButton>
                }
              />
            </StepContent>
          </Step>
        )
      })}
    </VerticalStepper>
  )
}

InformationFormStepper.propTypes = {
  formik: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
}

export default connect(InformationFormStepper)
