import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import {
  ORDER_STATUS,
  ORDER_STATUS_MARKET,
  ORDER_STATUS_MARKET_TEXT,
  STAKEHOLDER_TYPE,
  VALIDITY_TYPE,
  PERSON_CLIENT_TYPE
} from 'emsurge-selectors/constants'
import { isNil } from 'lodash'
import { isThirdPartyOrder } from 'screens/orderCreateCommon/helpers'
import { useOrderState } from 'containers/OrderProvider'
import { useUser } from 'containers/user/useUser'

const getStatusMarketByOrderStatus = (orderStatus) => {
  switch (orderStatus) {
    case ORDER_STATUS.PENDING:
      return ORDER_STATUS_MARKET.PENDING
    case ORDER_STATUS.LIVE:
    case ORDER_STATUS.WITHHELD:
      return ORDER_STATUS_MARKET.ACTIVE
    default:
      return undefined
  }
}

export const OrderStatus = connect(
  ({ children, formik: { values, setFieldValue } }) => {
    const [statusMarket, setStatusMarket] = useState(
      getStatusMarketByOrderStatus(values.status)
    )
    const {
      orderState: { isPending, isTermsheet },
    } = useOrderState()

    const setFieldAsActive = () => {
      setStatusMarket(ORDER_STATUS_MARKET.ACTIVE)
      setFieldValue('status', ORDER_STATUS.LIVE)
      setFieldValue('validity.until', VALIDITY_TYPE.GOOD_TILL_CANCELLED)
      setFieldValue('validity.date', undefined)
      setFieldValue('validity.time', undefined)
    }

    const setFieldAsInactive = () => {
      setStatusMarket(ORDER_STATUS_MARKET.PENDING)
      setFieldValue('status', ORDER_STATUS.PENDING)
      setFieldValue('validity.until', undefined)
      setFieldValue('validity.date', undefined)
      setFieldValue('validity.time', undefined)
    }

    const onChange = (newStatusMarket) => {
      if (newStatusMarket === ORDER_STATUS_MARKET.PENDING) {
        setFieldAsInactive()
        return
      }

      setFieldAsActive()
    }

    useEffect(() => {
      if (values.template || values.behalfOf !== STAKEHOLDER_TYPE.OFF_SYSTEM) {
        return
      }

      setStatusMarket(ORDER_STATUS_MARKET.ACTIVE)
    }, [values.template, values.behalfOf])

    if ((!isNil(isPending) && !isPending && !values.template) || isTermsheet) {
      return null
    }

    const { user } = useUser()
    const isBroker = user.clientType === PERSON_CLIENT_TYPE.BROKER

    const options = [
      {
        value: ORDER_STATUS_MARKET.PENDING,
        label: ORDER_STATUS_MARKET_TEXT.PENDING,
        disabled: !values.template && isThirdPartyOrder(values) && !isBroker,
      },
      {
        value: ORDER_STATUS_MARKET.ACTIVE,
        label: ORDER_STATUS_MARKET_TEXT.ACTIVE,
      },
    ]

    return children({ options, onChange, value: statusMarket })
  }
)

OrderStatus.defaultProps = {
  children: () => {},
  formik: {},
}

OrderStatus.propTypes = {
  children: PropTypes.func,
  initialStatus: PropTypes.string,
  formik: PropTypes.object,
}
