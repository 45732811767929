const isFinite = require('lodash/isFinite')
const type = require('./type')
const unit = require('./unit')

const { CARGO_SIZE_TYPE, CARGO_SIZE_TYPE_TEXT } = require('../../../constants')

const getSize = (order) => {
  const cargoSize = order.cargoSize || {}
  const cargoSizeType = type.get(order)
  const size = {}

  switch (cargoSizeType) {
    case CARGO_SIZE_TYPE.CUSTOM:
    case CARGO_SIZE_TYPE.SPECIFIC:
      if (isFinite(cargoSize.sizeMin)) {
        size.min = cargoSize.sizeMin
      }

      if (isFinite(cargoSize.sizeMax)) {
        size.max = cargoSize.sizeMax
      }
      break
    case CARGO_SIZE_TYPE.THREE_TWO:
    case CARGO_SIZE_TYPE.THREE_THREE:
    case CARGO_SIZE_TYPE.THREE_FIVE:
      // TODO: when cargo size type is one of these, we're not storing its value when saving the order
      size.min = parseFloat(cargoSizeType)
      break
    case CARGO_SIZE_TYPE.STANDARD:
    default:
      break
  }

  return size
}

const getSizeSummary = (order) => {
  const size = getSize(order)
  const unitText = unit.text(order)
  const sizeSummary = {}

  if (size.min) {
    sizeSummary.min = `${parseFloat(size.min).toFixed(2)} ${unitText}`.trim()
  }

  if (size.max) {
    sizeSummary.max = `${parseFloat(size.max).toFixed(2)} ${unitText}`.trim()
  }

  return sizeSummary
}

const getSizeText = (order) => {
  const size = getSize(order)
  const unitText = unit.text(order)
  const cargoSizeType = type.get(order)

  switch (cargoSizeType) {
    case CARGO_SIZE_TYPE.CUSTOM:
    case CARGO_SIZE_TYPE.SPECIFIC:
    case CARGO_SIZE_TYPE.THREE_TWO:
    case CARGO_SIZE_TYPE.THREE_THREE:
    case CARGO_SIZE_TYPE.THREE_FIVE:
      return Object.values(size)
        .sort((a, b) => a - b)
        .map((a) => `${parseFloat(a).toFixed(2)}`)
        .join('-')
        .concat(` ${unitText}`)
        .trim()
    case CARGO_SIZE_TYPE.STANDARD:
      return CARGO_SIZE_TYPE_TEXT.STANDARD
    default:
      return ''
  }
}

module.exports = {
  get: getSize,
  summary: getSizeSummary,
  text: getSizeText,
}
