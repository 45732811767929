import React, { useContext } from 'react'
import styled from 'styled-components'
import Switch from '@material-ui/core/Switch'
import _ListItem from '@material-ui/core/ListItem'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'

import { LayoutContext } from 'containers/layout/LayoutProvider'

const ListItem = styled(_ListItem)`
  position: absolute;
  bottom: 0;
  justify-content: flex-start;
`

export const ViewportOverrideToggle = () => {
  const layout = useContext(LayoutContext)

  const onChange = (evt) => {
    const isDesktop = evt.target.checked
    layout.setDesktopViewportMode(isDesktop)
  }

  return (
    <ListItem>
      <PhoneIphoneIcon />
      <Switch
        data-testid="toggle-view-port"
        color="primary"
        onClick={(evt) => evt.stopPropagation()}
        checked={layout.isDesktopViewportMode}
        onChange={onChange}
        inputProps={{ 'aria-label': 'override desktop mode' }}
      />
      <DesktopWindowsIcon />
    </ListItem>
  )
}
