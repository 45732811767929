import React from 'react'
import Text from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'
import { useTheme } from '@material-ui/styles'
import { withProps } from 'recompose'
import styled from 'styled-components'
import InviteCompanyDialog from './InviteCompanyDialog'
import { ADMIN_ADD_COMPANY } from 'routes'
import IconButton from 'components/iconButton/IconButton'
import AddButtonLink from 'screens/companyView/components/AddButton'

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

const AddButton = withProps({
  icon: Add,
  variant: 'contained',
})(IconButton)

const Header = () => {
  const { spacing } = useTheme()

  return (
    <FlexWrapper>
      <Text variant="h4">Companies</Text>

      <div>
        <InviteCompanyDialog>
          {({ open }) => (
            <AddButton
              data-testid="invite-company-button"
              style={{ marginRight: spacing(1) }}
              onClick={open}
            >
              Invite
            </AddButton>
          )}
        </InviteCompanyDialog>
        <AddButtonLink
          data-testid="add-company-button"
          to={ADMIN_ADD_COMPANY}
        />
      </div>
    </FlexWrapper>
  )
}

export default Header
