import React from 'react'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import { Cancel as CancelIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  focus: {
    border: '1px solid #7F6000',
  },
  margin: {
    marginRight: '8px',
    marginTop: '8px',
  },
}))

const _OptionBubble = ({ text, id, onDelete, focus }) => {
  const classes = useStyles()

  return (
    <Chip
      data-testid="option-bubble"
      label={text}
      onDelete={() => onDelete(id)}
      deleteIcon={<CancelIcon data-testid="remove-option-bubble-button" />}
      variant={'outlined'}
      className={`${focus ? classes.focus : ''} ${classes.margin}`}
    />
  )
}

_OptionBubble.defaultProps = {
  onDelete: () => {},
  focus: false,
}

_OptionBubble.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  onDelete: PropTypes.func,
  focus: PropTypes.bool,
}

export const OptionBubble = connect(_OptionBubble)
