const getDeliveryLocation = (order) => {
  return order.delivery.location
}

const getDeliveryText = (order) => {
  // TODO: there's an issue storing the value in the database which stores the text instead of the key.
  return order.delivery.location
}

module.exports = {
  get: getDeliveryLocation,
  text: getDeliveryText,
}
