import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from 'containers/formikTextField/FormikTextField'

const defaultProps = {
  type: 'date',
  fullWidth: true,
  margin: 'normal',
  InputLabelProps: {
    error: false,
  },
}

const SelectTimeZone = (props) => {
  const menuitems = [
    { value: '-12', label: '(UTC -12:00) Baker Island Time' },
    { value: '-11', label: '(UTC -11:00) Niue Time, Samoa Standard Time' },
    {
      value: '-10',
      label: '(UTC -10:00) Hawaii-Aleutian Standard Time, Cook Island Time',
    },
    { value: '-9.5', label: '(UTC -9:30) Marquesas Islands Time' },
    {
      value: '-9',
      label: '(UTC -9:00) Alaska Standard Time, Gambier Island Time',
    },
    { value: '-8', label: '(UTC -8:00) Pacific Standard Time' },
    { value: '-7', label: '(UTC -7:00) Mountain Standard Time' },
    { value: '-6', label: '(UTC -6:00) Central Standard Time' },
    { value: '-5', label: '(UTC -5:00) Eastern Standard Time' },
    { value: '-4.5', label: '(UTC -4:30) Venezuelan Standard Time' },
    { value: '-4', label: '(UTC -4:00) Atlantic Standard Time' },
    { value: '-3.5', label: '(UTC -3:30) Newfoundland Standard Time' },
    {
      value: '-3',
      label: '(UTC -3:00) Amazon Standard Time, Central Greenland Time',
    },
    {
      value: '-2',
      label:
        '(UTC -2:00) Fernando de Noronha Time, South Georgia & the South Sandwich Islands Time',
    },
    {
      value: '-1',
      label:
        '(UTC -1:00) Azores Standard Time, Cape Verde Time, Eastern Greenland Time',
    },
    { value: '0', label: '(UTC) Western European Time, Greenwich Mean Time' },
    {
      value: '+1',
      label: '(UTC +1:00) Central European Time, West African Time',
    },
    {
      value: '+2',
      label: '(UTC +2:00) Eastern European Time, Central African Time',
    },
    {
      value: '+3',
      label: '(UTC +3:00) Moscow Standard Time, Eastern African Time',
    },
    { value: '+3.5', label: '(UTC +3:30) Iran Standard Time' },
    {
      value: '+4',
      label: '(UTC +4:00) Gulf Standard Time, Samara Standard Time',
    },
    { value: '+4.5', label: '(UTC +4:30) Afghanistan Time' },
    {
      value: '+5',
      label: '(UTC +5:00) Pakistan Standard Time, Yekaterinburg Standard Time',
    },
    {
      value: '+5.5',
      label: '(UTC +5:30) Indian Standard Time, Sri Lanka Time',
    },
    { value: '+5.75', label: '(UTC +5:45) Nepal Time' },
    {
      value: '+6',
      label:
        '(UTC +6:00) Bangladesh Time, Bhutan Time, Novosibirsk Standard Time',
    },
    { value: '+6.5', label: '(UTC +6:30) Cocos Islands Time, Myanmar Time' },
    {
      value: '+7',
      label: '(UTC +7:00) Indochina Time, Krasnoyarsk Standard Time',
    },
    {
      value: '+8',
      label:
        '(UTC +8:00) Chinese Standard Time, Australian Western Standard Time, Irkutsk Standard Time',
    },
    {
      value: '+8.75',
      label: '(UTC +8:45) Southeastern Western Australia Standard Time',
    },
    {
      value: '+9',
      label:
        '(UTC +9:00) Japan Standard Time, Korea Standard Time, Chita Standard Time',
    },
    { value: '+9.5', label: '(UTC +9:30) Australian Central Standard Time' },
    {
      value: '+10',
      label:
        '(UTC +10:00) Australian Eastern Standard Time, Vladivostok Standard Time',
    },
    { value: '+10.5', label: '(UTC +10:30) Lord Howe Standard Time' },
    {
      value: '+11',
      label: '(UTC +11:00) Solomon Island Time, Magadan Standard Time',
    },
    { value: '+11.5', label: '(UTC +11:30) Norfolk Island Time' },
    {
      value: '+12',
      label:
        '(UTC +12:00) New Zealand Time, Fiji Time, Kamchatka Standard Time',
    },
    { value: '+12.75', label: '(UTC +12:45) Chatham Islands Time' },
    { value: '+13', label: '(UTC +13:00) Tonga Time, Phoenix Islands Time' },
    { value: '+14', label: '(UTC +14:00) Line Island Time' },
  ]

  return (
    <TextField {...defaultProps} select {...props}>
      {menuitems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SelectTimeZone
