import React from 'react'
import { connect } from 'formik'
import { STAKEHOLDER_TYPE } from 'emsurge-selectors'
import { EssentialPanel } from '../components/EssentialPanel'
import { OptionalPanel } from '../components/OptionalPanel'
import { getOrderInfo } from '../FormProvider'
import { useUser } from 'containers/user/useUser'
import PublicNote from 'screens/orderView/components/PublicNote'

const mapFormikGeneralData = (values) => {
  const person = values.person || {}
  const company = values.company || {}
  const entity = values.entity || {}
  if (values.behalfOf === STAKEHOLDER_TYPE.ON_SYSTEM) {
    return {
      person,
      company,
      entity,
      companyId: company.id,
      entityId: entity.id,
      thirdParty: undefined,
    }
  }
  if (values.behalfOf === STAKEHOLDER_TYPE.OFF_SYSTEM) {
    return {
      company: undefined,
      entity: undefined,
      companyId: undefined,
      entityId: undefined,
    }
  }
}

export const _RecapScreen = ({ formik: { values }, isCreatingTrade }) => {
  const { user } = useUser()
  const buyerAndSellerInformation = isCreatingTrade ? {
    buyer: values.buyer,
    seller: values.seller
  } : {}
  const order = {
    ...getOrderInfo(values, user, true),
    ...mapFormikGeneralData(values),
    ...buyerAndSellerInformation
  }

  return (
    <section>
      <PublicNote order={order} />
      <EssentialPanel order={order} isCreatingTrade={isCreatingTrade}/>
      <OptionalPanel order={order} />
    </section>
  )
}

export const RecapScreen = connect(_RecapScreen)
