const { mapCountriesToData, OPTION_TYPE_IS_LDC } = require('./helpers')

const COUNTRIES_LATIN_AMERICA_KEYS = {
  ANGUILLA: 'ANGUILLA',
  ANTIGUA_AND_BARBUDA: 'ANTIGUA_AND_BARBUDA',
  ARUBA: 'ARUBA',
  BAHAMAS: 'BAHAMAS',
  BARBADOS: 'BARBADOS',
  BONAIRE_SINT_EUSTATIUS_AND_SABA: 'BONAIRE_SINT_EUSTATIUS_AND_SABA',
  BRITISH_VIRGIN_ISLANDS: 'BRITISH_VIRGIN_ISLANDS',
  CAYMAN_ISLANDS: 'CAYMAN_ISLANDS',
  CUBA: 'CUBA',
  CURACAO: 'CURACAO',
  DOMINICA: 'DOMINICA',
  DOMINICAN_REPUBLIC: 'DOMINICAN_REPUBLIC',
  GRENADA: 'GRENADA',
  GUADELOUPE: 'GUADELOUPE',
  HAITI: 'HAITI',
  JAMAICA: 'JAMAICA',
  MARTINIQUE: 'MARTINIQUE',
  MONTSERRAT: 'MONTSERRAT',
  PUERTO_RICO: 'PUERTO_RICO',
  SAINT_BARTHELEMY: 'SAINT_BARTHELEMY',
  SAINT_KITTS_AND_NEVIS: 'SAINT_KITTS_AND_NEVIS',
  SAINT_LUCIA: 'SAINT_LUCIA',
  SAINT_MARTIN_FRENCH_PART: 'SAINT_MARTIN_FRENCH_PART',
  SAINT_VINCENT_AND_THE_GRENADINES: 'SAINT_VINCENT_AND_THE_GRENADINES',
  SINT_MAARTEN_DUTCH_PART: 'SINT_MAARTEN_DUTCH_PART',
  TRINIDAD_AND_TOBAGO: 'TRINIDAD_AND_TOBAGO',
  TURKS_AND_CAICOS_ISLANDS: 'TURKS_AND_CAICOS_ISLANDS',
  UNITED_STATES_VIRGIN_ISLANDS: 'UNITED_STATES_VIRGIN_ISLANDS',
  BELIZE: 'BELIZE',
  COSTA_RICA: 'COSTA_RICA',
  EL_SALVADOR: 'EL_SALVADOR',
  GUATEMALA: 'GUATEMALA',
  HONDURAS: 'HONDURAS',
  MEXICO: 'MEXICO',
  NICARAGUA: 'NICARAGUA',
  PANAMA: 'PANAMA',
  ARGENTINA: 'ARGENTINA',
  BOLIVIA_PLURINATIONAL_STATE_OF: 'BOLIVIA_PLURINATIONAL_STATE_OF',
  BRAZIL: 'BRAZIL',
  CHILE: 'CHILE',
  COLOMBIA: 'COLOMBIA',
  ECUADOR: 'ECUADOR',
  FALKLAND_ISLANDS_MALVINAS: 'FALKLAND_ISLANDS_MALVINAS',
  FRENCH_GUIANA: 'FRENCH_GUIANA',
  GUYANA: 'GUYANA',
  PARAGUAY: 'PARAGUAY',
  PERU: 'PERU',
  SURINAME: 'SURINAME',
  URUGUAY: 'URUGUAY',
  VENEZUELA_BOLIVARIAN_REPUBLIC_OF: 'VENEZUELA_BOLIVARIAN_REPUBLIC_OF',
}

const COUNTRIES_LATIN_AMERICA_VALUES = {
  ANGUILLA: 'Anguilla',
  ANTIGUA_AND_BARBUDA: 'Antigua and Barbuda',
  ARUBA: 'Aruba',
  BAHAMAS: 'Bahamas',
  BARBADOS: 'Barbados',
  BONAIRE_SINT_EUSTATIUS_AND_SABA: 'Bonaire, Sint Eustatius and Saba',
  BRITISH_VIRGIN_ISLANDS: 'British Virgin Islands',
  CAYMAN_ISLANDS: 'Cayman Islands',
  CUBA: 'Cuba',
  CURACAO: 'Curaçao',
  DOMINICA: 'Dominica',
  DOMINICAN_REPUBLIC: 'Dominican Republic',
  GRENADA: 'Grenada',
  GUADELOUPE: 'Guadeloupe',
  HAITI: 'Haiti',
  JAMAICA: 'Jamaica',
  MARTINIQUE: 'Martinique',
  MONTSERRAT: 'Montserrat',
  PUERTO_RICO: 'Puerto Rico',
  SAINT_BARTHELEMY: 'Saint Barthélemy',
  SAINT_KITTS_AND_NEVIS: 'Saint Kitts and Nevis',
  SAINT_LUCIA: 'Saint Lucia',
  SAINT_MARTIN_FRENCH_PART: 'Saint Martin (French part))',
  SAINT_VINCENT_AND_THE_GRENADINES: 'Saint Vincent and the Grenadines',
  SINT_MAARTEN_DUTCH_PART: 'Sint Maarten (Dutch part))',
  TRINIDAD_AND_TOBAGO: 'Trinidad and Tobago',
  TURKS_AND_CAICOS_ISLANDS: 'Turks and Caicos Islands',
  UNITED_STATES_VIRGIN_ISLANDS: 'United States Virgin Islands',
  BELIZE: 'Belize',
  COSTA_RICA: 'Costa Rica',
  EL_SALVADOR: 'El Salvador',
  GUATEMALA: 'Guatemala',
  HONDURAS: 'Honduras',
  MEXICO: 'Mexico',
  NICARAGUA: 'Nicaragua',
  PANAMA: 'Panama',
  ARGENTINA: 'Argentina',
  BOLIVIA_PLURINATIONAL_STATE_OF: 'Bolivia (Plurinational State of)',
  BRAZIL: 'Brazil',
  CHILE: 'Chile',
  COLOMBIA: 'Colombia',
  ECUADOR: 'Ecuador',
  FALKLAND_ISLANDS_MALVINAS: 'Falkland Islands (Malvinas)',
  FRENCH_GUIANA: 'French Guiana',
  GUYANA: 'Guyana',
  PARAGUAY: 'Paraguay',
  PERU: 'Peru',
  SURINAME: 'Suriname',
  URUGUAY: 'Uruguay',
  VENEZUELA_BOLIVARIAN_REPUBLIC_OF: 'Venezuela (Bolivarian Republic of)',
}

const COUNTRIES_LATIN_AMERICA_INCLUDED_LDCS = [
  COUNTRIES_LATIN_AMERICA_KEYS.HAITI,
]

const COUNTRIES_LATIN_AMERICA = mapCountriesToData(
  COUNTRIES_LATIN_AMERICA_KEYS,
  COUNTRIES_LATIN_AMERICA_VALUES,
  {
    type: OPTION_TYPE_IS_LDC,
    elements: COUNTRIES_LATIN_AMERICA_INCLUDED_LDCS,
  }
)

module.exports = {
  COUNTRIES_LATIN_AMERICA_KEYS,
  COUNTRIES_LATIN_AMERICA_VALUES,
  COUNTRIES_LATIN_AMERICA,
}
