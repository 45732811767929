import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Grid from '@material-ui/core/Grid'

import { TextField } from 'screens/orderCreateCommon/components/TextField'

const isValidYear = (event) => {
  const year = get(event[0], 'target.value')
  const yearLength = 4
  return year.length <= yearLength
}

export const SelectDeliveryYear = ({ focus, fieldPrefix, ...props }) => (
  <Grid item xs>
    <TextField
      inputProps={{ 'data-testid': 'delivery-year-field' }}
      shouldSave={isValidYear}
      autoFocus={focus === 'year'}
      type="number"
      name={`${fieldPrefix}.year`}
      label="Year of Delivery"
      {...props}
    />
  </Grid>
)

SelectDeliveryYear.defaultProps = {
  focus: false,
  fieldPrefix: 'delivery',
}

SelectDeliveryYear.propTypes = {
  focus: PropTypes.bool,
  fieldPrefix: PropTypes.string,
}
