import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import FormProvider from './containers/FormProvider'
import Fetch from 'containers/fetchPlus/FetchPlus'
import withParams from 'utils/withRouteParams'
import UserForm from 'screens/userCreate/components/UserForm'

const UserEdit = ({ id }) => (
  <Fetch url={`/persons/${id}`}>
    {(user) => (
      <Fetch url={`/companies/${user.companyId}`}>
        {(company) => (
          <>
            <Text component="h1" variant="h1">
              Edit user
            </Text>

            <FormProvider
              company={company}
              entities={company.entities}
              user={user}
            >
              <UserForm company={company} entities={company.entities} />
            </FormProvider>
          </>
        )}
      </Fetch>
    )}
  </Fetch>
)

UserEdit.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withParams(UserEdit)
