import React from 'react'
import PropTypes from 'prop-types'
import { object, string } from 'yup'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import { omitEmpty } from 'utils/formHelpers'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const FormSchema = object().shape({
  email: string().email('Invalid email').required('Required'),
  name: string().required('Required'),
})

const InviteFormProvider = ({ enqueueSnackbar, children, onPostSubmit }) => {
  const api = useApi()

  /* :: (object, object) -> Promise<void> */
  const handleSubmit = async (values, form) => {
    try {
      await api.post('/invitations', omitEmpty(values))
      enqueueSnackbar('Invitation sent successful', { variant: 'success' })
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Invitation failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      form.setSubmitting(false)
      form.resetForm()
      onPostSubmit()
    }
  }

  return (
    <Formik
      initialValues={{ email: '', name: '' }}
      validationSchema={FormSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}

InviteFormProvider.defaultProps = {
  onPostSubmit: () => {},
}

InviteFormProvider.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  onPostSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
}

export default withSnackbar(InviteFormProvider)
