import React from 'react'
import PropTypes from 'prop-types'
import { useTheme, makeStyles } from '@material-ui/styles'
import CompareArrows from '@material-ui/icons/CompareArrows'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'

import { BlockHeader, BlockRow, IconTitle, ActionBlock } from '../Block'
import SearchForm from '../SearchForm'
import { Tablet } from 'components/Viewport'
import { StatusFilters } from 'components/StatusFilters'
import useAppContext from 'containers/appContext/useAppContext'

const useStyles = makeStyles(() => ({
  floating: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    width: '1px',
    backgroundColor: '#9097A1',
    margin: '6px 8px 6px 16px',
  },
}))

const MarketInterestHeader = ({
  sortByValue,
  onSortByChange,
  status,
  setStatus,
  setFilter
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { isLNGContext } = useAppContext()

  const options = isLNGContext
    ? [
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'location',
          label: 'Location',
        },
      ]
    : [
        {
          key: 'vintage',
          label: 'Vintage',
        },
      ]

  return (
    <BlockHeader style={{ padding: 0 }}>
      <Tablet matchLarger>
        <BlockRow>
          <Grid item>
            <IconTitle icon={CompareArrows}>Market Interest</IconTitle>
          </Grid>
        </BlockRow>
      </Tablet>

      <ActionBlock>
        <Grid item>
          <BlockRow style={{ padding: 0 }} spacing={1}>
            <Grid item>
              <Text variant="body2" style={{ fontWeight: 700 }} noWrap>
                Sort by
              </Text>
            </Grid>
            <Grid item xs>
              <TextField
                select
                InputProps={{
                  disableUnderline: true,
                }}
                value={sortByValue}
                onChange={onSortByChange}
              >
                {options.map(({ key, label }) => (
                  <MenuItem key={`menu-sort-${key}`} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </BlockRow>
        </Grid>
        <Tablet matchLarger>
          <Grid item className={classes.floating}>
            <StatusFilters
              gridProps={{ p: 0 }}
              value={status}
              onChange={setStatus}
            />
          </Grid>
        </Tablet>
      </ActionBlock>

      <Grid container>
        <Grid item xs>
          <SearchForm
            inputProps={{ 'data-testid': 'market-interest-search' }}
            onChange={(evt) => setFilter(evt.target.value)}
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: theme.spacing(1, 2) }}
      >
        <Text variant="subtitle1" style={{ fontWeight: 700 }}>
          BID
        </Text>
        <Text variant="subtitle1" style={{ fontWeight: 700 }}>
          ASK
        </Text>
      </Grid>
    </BlockHeader>
  )
}

MarketInterestHeader.defaultProps = {
  status: '',
  setStatus: () => {},
}

MarketInterestHeader.propTypes = {
  sortByValue: PropTypes.string.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  status: PropTypes.string,
  setStatus: PropTypes.func,
}

export default MarketInterestHeader
