import React from 'react'
import { toUpper } from 'lodash'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core'
import Text from '@material-ui/core/Typography'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { TERM_SPOT_KEYS } from 'emsurge-selectors'
import { SummaryBlock } from './SummaryBlock'
import { useIsMobile } from 'components/Viewport/Mobile'
import { writeToClipboard } from 'utils/clipboard'

const useStyles = makeStyles((theme) => ({
  customText: {
    fontWeight: 700,
    fontSize: (isMobile) => (isMobile ? 19 : 21),
    lineHeight: 25 / 21,
    textTransform: 'uppercase',
  },
  priceText: {
    fontSize: (isMobile) => (isMobile ? 14 : 16),
    fontWeight: 700,
    lineHeight: 19 / 16,
  },
  idText: {
    color: theme.palette.primary.main,
    fontSize: (isMobile) => (isMobile ? 12 : 14),
    fontWeight: 700,
    lineHeight: 18 / 14,
  },
  hoverable: {
    cursor: 'pointer',
  },
  copyOrderIcon: {
    color: theme.palette.primary.main,
    fontSize: (isMobile) => (isMobile ? 14 : 12),
    marginLeft: theme.spacing(1),
  },
  vintagePrefix: {
    textTransform: 'lowercase',
  },
}))

const useClasses = () => useStyles(useIsMobile())

export const Price = ({
  value,
  hasUnit,
  style,
  showTooltipProps = true,
  ...props
}) => {
  const classes = useClasses()
  const getTooltipProps = () => {
    if (showTooltipProps) {
      return {
        tooltipProps: {
          title: 'Basic Price',
          placement: 'left',
        },
      }
    }
    return {}
  }
  return (
    <SummaryBlock
      {...getTooltipProps()}
      textProps={{
        'data-testid': 'order-summary-basic-price',
        classes: {
          root: classes.priceText,
        },
        style: style || {},
      }}
      value={value}
      showCurrency={hasUnit}
      style={style}
      {...props}
    />
  )
}

Price.propTypes = {
  value: PropTypes.string,
  hasUnit: PropTypes.bool,
  showTooltipProps: PropTypes.bool,
}

export const ClassificationsInfo = ({ value, volumeText = [] }) => {
  const classes = useClasses()
  const getTextProps = (dataTestId) => ({
    'data-testid': dataTestId,
    classes: {
      root: classes.priceText,
    },
  })
  const getTooltipProps = (title) => ({
    title,
    placement: 'left',
  })

  return (
    <>
      <SummaryBlock
        tooltipProps={getTooltipProps('Volume')}
        textProps={getTextProps('order-summary-additional-info-volume')}
        value={volumeText.join('-')}
        showVolume={false}
        volumePeriod={volumeText.length > 1 ? 'per_year' : 'unit'}
      />
      <SummaryBlock
        tooltipProps={getTooltipProps('Classifications Info')}
        textProps={getTextProps('order-summary-additional-info')}
        value={value}
      />
    </>
  )
}

ClassificationsInfo.defaultProps = {
  value: '',
}

ClassificationsInfo.propTypes = {
  value: PropTypes.string,
}

export const TradingType = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Trading Type',
        placement: 'top',
      }}
      textProps={{
        'data-testid': 'order-summary-trading-type',
        classes: {
          root: classes.customText,
        },
      }}
      value={value}
    />
  )
}

TradingType.defaultProps = {
  value: '',
}

TradingType.propTypes = {
  value: PropTypes.string,
}

export const ProjectIds = ({ value }) => {
  const classes = useClasses()
  const valueToShow = value?.length > 15 ? `${value.substring(0, 15)}...` : value ?? ''
  return (
    <>
      {value && (
          <SummaryBlock
            tooltipProps={{
              title: 'Project Ids',
              placement: 'top',
            }}
            textProps={{
              classes: {
                root: classes.customText,
              },
            }}
            value={valueToShow}
          />
      )}
     </>
  )
}

TradingType.ProjectIds = {
  value: '',
}

TradingType.ProjectIds = {
  value: PropTypes.string,
}

export const AdditionalAttributes = ({ value }) => {
  const classes = useClasses()
  const valueToShow = value?.length > 15 ? `${value.substring(0, 15)}...` : value ?? ''
  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Additional Attributes',
        placement: 'top',
      }}
      textProps={{
        classes: {
          root: classes.customText,
        },
      }}
      value={valueToShow}
    />
  )
}

TradingType.AdditionalAttributes = {
  value: '',
}

TradingType.AdditionalAttributes = {
  value: PropTypes.string,
}

export const CarbonStandard = ({ value }) => {
  const classes = useClasses()
  const allValues = value.join(' ')
  const valueToShow = allValues?.length > 15 ? `${allValues.substring(0, 15)}...` : allValues ?? ''
  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Carbon Standard',
        placement: 'top',
      }}
      textProps={{
        'data-testid': 'order-summary-carbon-standard',
        classes: {
          root: classes.customText,
        },
      }}
      value={valueToShow}
    />
  )
}

CarbonStandard.defaultProps = {
  value: [],
}

CarbonStandard.propTypes = {
  value: PropTypes.array,
}

export const Date = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Term',
        placement: 'top',
      }}
      textProps={{
        'data-testid': 'order-summary-term',
        classes: {
          root: classes.customText,
        },
      }}
      value={value}
    />
  )
}

Date.defaultProps = {
  value: '',
}

Date.propTypes = {
  value: PropTypes.string,
}

export const VintageDate = ({ value }) => {
  const classes = useClasses()
  const displayPrefix = !Object.keys(TERM_SPOT_KEYS).includes(toUpper(value))

  return (
    <>
      {value && displayPrefix && (
        <Text
          data-testid="order-summary-vintage-v-char"
          className={`${classes.customText} ${classes.vintagePrefix}`}
        >
          v
        </Text>
      )}
      <Date value={value} />
    </>
  )
}

Date.defaultProps = {
  value: '',
}

Date.propTypes = {
  value: PropTypes.string,
}

export const Classifications = ({ value }) => {
  const classes = useClasses()

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Classifications',
        placement: 'top',
      }}
      textProps={{
        'data-testid': 'order-summary-additional',
        classes: {
          root: classes.customText,
        },
      }}
      value={value}
    />
  )
}

Classifications.defaultProps = {
  value: '',
}

Classifications.propTypes = {
  value: PropTypes.string,
}

export const OrderId = ({ value, shortId, label }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useClasses()

  const copyToClipboard = async (text, successMessage) => {
    const response = await writeToClipboard(text, successMessage)
    enqueueSnackbar(response.message, {
      variant: response.error ? 'error' : 'success',
    })
  }

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'ID',
        placement: 'bottom',
      }}
      textProps={{
        'data-testid': 'order-short-id',
        className: classes.hoverable,
        classes: {
          root: classes.idText,
        },
        onClick: () =>
          copyToClipboard(shortId, `${label} ID copied to clipboard.`),
      }}
      value={
        <>
          {`${label} ${value.toUpperCase()}`}
          <FileCopyIcon classes={{ root: classes.copyOrderIcon }} />
        </>
      }
    />
  )
}

OrderId.defaultProps = {
  value: '',
  shortId: '',
  label: '',
}

OrderId.propTypes = {
  value: PropTypes.string,
  shortId: PropTypes.string,
  label: PropTypes.string,
}

export const CopyOrderSummary = ({ orderSummary }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useClasses()

  const copyToClipboard = async (text, successMessage) => {
    const response = await writeToClipboard(text, successMessage)
    enqueueSnackbar(response.message, {
      variant: response.error ? 'error' : 'success',
    })
  }

  return (
    <SummaryBlock
      tooltipProps={{
        title: 'Copy Summary',
        placement: 'top',
      }}
      textProps={{
        className: classes.hoverable,
        classes: {
          root: classes.idText,
        },
        onClick: () =>
          copyToClipboard(orderSummary, `Order summary copied to clipboard.`),
      }}
      value={
        <>
          <FileCopyIcon classes={{ root: classes.copyOrderIcon }} />
        </>
      }
    />
  )
}

CopyOrderSummary.defaultProps = {
  orderSummary: null,
}

CopyOrderSummary.propTypes = {
  orderSummary: PropTypes.string,
}

const Exports = {
  Price,
  TradingType,
  CarbonStandard,
  Date,
  VintageDate,
  Classifications,
  ClassificationsInfo,
  OrderId,
  ProjectIds,
  AdditionalAttributes,
  CopyOrderSummary
}

export default Exports