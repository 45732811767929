const { TRADING_TYPE_TEXT } = require('../../constants')

const getTradingType = (order) => {
  return order.tradingType
}

const getTradingTypeText = (order) => {
  return TRADING_TYPE_TEXT[order.tradingType] || ''
}

module.exports = {
  get: getTradingType,
  text: getTradingTypeText,
}
