import React from 'react'
import styled from 'styled-components'

const VerticalDivider = styled.div`
  width: 2px;
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`

// this is necessary if we want to use it in tabs component from material-ui
export const VerticalDividerWrapper = () => <VerticalDivider />

export default VerticalDivider
