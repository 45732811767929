import {
  periodIsCustom,
  TERM_SPOT_PERIOD_PREFIX,
  TERM_SPOT_OFFSET_PREFIX,
  TERM_SPOT_VINTAGE_FIXED_PREFIX,
  TERM_SPOT_VINTAGE_ROLLING_PREFIX,
  periodIsCalYear,
  periodIsQuarter,
  TERM_SPOT_VINTAGE_PROJECT_INFORMATION,
  TERM_SPOT_KEYS,
  TERM_FIXED_ROLLING_KEYS,
  PRICE_RANGE_PREFIX,
  priceTypeIsFixed,
  priceTypeIsRange,
  priceTypeIsRFQ,
  PRICE_FIXED_PREFIX,
  PRICE_RFQ_PREFIX,
  PROJECT_SECTORAL_SCOPE_INPUT,
} from 'emsurge-selectors'
import { get, isEmpty, isNumber, set } from 'lodash'
import moment from 'moment'
import { computeVolumeInputNumber } from '../helpers'

const validateDates = (order, path, errors) => {
  const { period, customFrom, customTo, year } = get(order, path)
  if (periodIsCustom(period)) {
    if (!customFrom) {
      set(errors, `${path}.customFrom`, 'Start date is required')
    }
    if (!customTo) {
      set(errors, `${path}.customTo`, 'End date is required')
    }
    if (moment(customFrom) > moment(customTo)) {
      set(
        errors,
        `${path}.customFrom`,
        'The start date must be greater than or equal to the end date'
      )
      set(
        errors,
        `${path}.customTo`,
        'The end date must be less than or equal to the start date.'
      )
    }
  }
  if ((periodIsCalYear(period) || periodIsQuarter(period)) && !year) {
    set(errors, `${path}.year`, 'Year is required')
  }
}

const validateRollingRange = (order, path, errors) => {
  const { within, year } = get(order, path)
  if (!within) {
    set(errors, `${path}.within`, 'Within is required')
  }
  if (!year) {
    set(errors, `${path}.year`, 'Year is required')
  }
}

const validateProjectInformation = (order, errors) => {
  const projectInformation =
    get(order, TERM_SPOT_VINTAGE_PROJECT_INFORMATION) || {}
  const filteredProjectInformation = Object.values(projectInformation).filter(
    (projectInfo) => projectInfo
  )
  const isValid = filteredProjectInformation.length
  if (!isValid) {
    set(
      errors,
      TERM_SPOT_VINTAGE_PROJECT_INFORMATION,
      'Select at least one status'
    )
  }
}

export const validateStep3 = (order) => {
  const errors = {}
  const { termSpot, fixedRolling } = order.termVintage || {}

  if (termSpot === TERM_SPOT_KEYS.SPOT) {
    validateDates(order, TERM_SPOT_PERIOD_PREFIX, errors)
  }
  if (termSpot === TERM_SPOT_KEYS.TERM) {
    validateDates(order, TERM_SPOT_OFFSET_PREFIX, errors)
    if (fixedRolling === TERM_FIXED_ROLLING_KEYS.FIXED) {
      validateDates(order, TERM_SPOT_VINTAGE_FIXED_PREFIX, errors)
    }
    if (fixedRolling === TERM_FIXED_ROLLING_KEYS.ROLLING) {
      validateRollingRange(order, TERM_SPOT_VINTAGE_ROLLING_PREFIX, errors)
    }
  }
  validateProjectInformation(order, errors)
  return {
    errors,
    validation: isEmpty(errors),
  }
}

export const higherThanZeroFn = (amount) =>
  isNumber(amount) && amount > 0 ? '' : 'The amount must be greater than 0'
export const higherThanEqualToZeroFn = (amount) =>
  (isNumber(amount) && amount >= 0) || amount === ''
    ? ''
    : 'The amount must be greater than or equal to 0'

const validateAmount = ({
  amount,
  errors,
  path,
  maxAmount,
  higherThanEqualToZero = false,
}) => {
  const numberAmount = computeVolumeInputNumber(amount)
  const numberMaxAmount = computeVolumeInputNumber(maxAmount)
  const fn = higherThanEqualToZero ? higherThanEqualToZeroFn : higherThanZeroFn
  const hasErrorValidatingZero = fn(numberAmount)
  if (hasErrorValidatingZero) {
    return set(errors, path, hasErrorValidatingZero)
  }
  if (numberMaxAmount && numberAmount > numberMaxAmount) {
    return set(
      errors,
      path,
      `The value must be less than or equal to ${maxAmount}`
    )
  }
}

export const validateStep4 = () => {
  const errors = {}
  return {
    errors,
    validation: isEmpty(errors),
  }
}

const validateRanges = (range, errors, path) => {
  const { date, time } = range
  if (!date || date === '') {
    set(errors, path.date, 'Must be a valid date')
  }
  if (!time || time === '') {
    set(errors, path.time, 'Must be a valid time')
  }
}

export const validateStep5 = (order) => {
  const errors = {}
  const {
    type,
    basic: { fixed, range, rfq },
  } = order.price || { basic: {} }
  if (priceTypeIsFixed(type)) {
    validateAmount({
      amount: Number(fixed || 0),
      errors,
      path: PRICE_FIXED_PREFIX,
      higherThanEqualToZero: true,
    })
  }
  if (priceTypeIsRange(type)) {
    const { min, max } = range || {}
    const minRange = !min ? 0 : min
    const maxRange = !max ? 0 : max
    validateAmount({
      amount: minRange,
      errors,
      path: PRICE_RANGE_PREFIX.min,
      higherThanEqualToZero: true,
    })
    validateAmount({
      amount: maxRange,
      errors,
      path: PRICE_RANGE_PREFIX.max,
      higherThanEqualToZero: true,
    })
  }
  if (priceTypeIsRFQ(type)) {
    validateRanges(rfq.deadline, errors, PRICE_RFQ_PREFIX.deadline)
    validateRanges(rfq.award, errors, PRICE_RFQ_PREFIX.award)
  }
  return {
    errors,
    validation: isEmpty(errors),
  }
}

export const validateStep7 = (order) => {
  const errors = {}
  const { sectoralScope } = order.projectSpecification.buyer
  if (!sectoralScope.ALL && !sectoralScope.options.length) {
    set(errors, PROJECT_SECTORAL_SCOPE_INPUT, 'Select at least one option')
  }
  return {
    errors,
    validation: isEmpty(errors),
  }
}

export const validateStep8 = () => {
  const errors = {}
  return {
    errors,
    validation: isEmpty(errors),
  }
}

export const validateStep9 = () => {
  return {
    errors: {},
    validation: true,
  }
}

export const validateOrder = (order) => {
  const { errors: step3Errors, validation: isValidStep3 } = validateStep3(order)
  const { errors: step4Errors, validation: isValidStep4 } = validateStep4(order)
  const { errors: step5Errors, validation: isValidStep5 } = validateStep5(order)
  const { errors: step7Errors, validation: isValidStep7 } = validateStep7(order)
  const { errors: step8Errors, validation: isValidStep8 } = validateStep8(order)
  const { errors: step9Errors, validation: isValidStep9 } = validateStep9(order)
  const errors = {
    ...step3Errors,
    ...step4Errors,
    ...step5Errors,
    ...step7Errors,
    ...step8Errors,
    ...step9Errors,
  }
  const validations = [
    isValidStep3,
    isValidStep4,
    isValidStep5,
    isValidStep7,
    isValidStep8,
    isValidStep9,
  ]
  const validationStatus = validations.filter((valid) => !valid)
  return validationStatus.length ? errors : undefined
}
