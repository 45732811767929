import React from 'react'
import { get } from 'lodash'
import { Grid } from '@material-ui/core'
import { connect, FieldArray } from 'formik'
import { useTheme } from '@material-ui/styles'
import {
  PRICE_VOLUME_DEPENDENT_PREFIX,
  PRICE_VOLUME_DEPENDENT_TYPE_KEYS,
} from 'emsurge-selectors'
import { VolumeDependentItem } from './VolumeDependentItem'
import { AddButton } from 'screens/orderCreateCommon/components/Buttons'

const DEFAULT_TYPES_BY_ITEM = {
  0: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.UP_TO.key,
  1: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.FROM.key,
  default: PRICE_VOLUME_DEPENDENT_TYPE_KEYS.ABOVE.key,
}

const _VolumeDependent = ({ formik: { values } }) => {
  const { spacing } = useTheme()

  const getDefaultType = (arrayHelpers) => {
    let nPrices = 0
    const name = arrayHelpers.name || ''
    if (name) {
      const prices = get(values, name) || []
      nPrices = prices.length
    }
    return DEFAULT_TYPES_BY_ITEM[nPrices] || DEFAULT_TYPES_BY_ITEM.default
  }

  return (
    <div style={{ paddingTop: '16px' }}>
      <FieldArray
        name={PRICE_VOLUME_DEPENDENT_PREFIX}
        render={(arrayHelpers) => (
          <>
            <Grid>
              {(get(values, PRICE_VOLUME_DEPENDENT_PREFIX) || []).map(
                (_, index) => (
                  <VolumeDependentItem
                    key={`volume-dependent-item-${index}`}
                    itemNr={index}
                    onClose={() => arrayHelpers.remove(index)}
                  />
                )
              )}
            </Grid>
            <AddButton
              data-testid="volume-dependent-add-parameter-button"
              style={{ margin: spacing(1, 0) }}
              onClick={() =>
                arrayHelpers.push({
                  type: getDefaultType(arrayHelpers),
                })
              }
            >
              Add Parameter
            </AddButton>
          </>
        )}
      />
    </div>
  )
}

export const VolumeDependent = connect(_VolumeDependent)
