import React from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import { useInView } from 'react-intersection-observer'
import { connect } from 'formik'
import { DeliveryWindowSelect } from './DeliveryWindowSelect'
import { CargoCardSkeleton } from './CargoCardSkeleton'
import Card from 'screens/orderCreate/components/Card'
import { CargoCardDateFields } from 'screens/orderCreate/components/CargoCardDateFields'
import {
  setAllCargosDW,
  setAllCargosDates,
  isDisabled,
} from 'screens/orderCreate/components/DeliveryWindowCargoes.helpers'

export const _CargoCard = ({
  formik: { values, setFieldValue },
  index,
  style,
  options,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
  })

  const disabled = isDisabled(index + 1, values.nominationRules.deliveryWindow)

  const onChangeCargoDeliveryWindow = (value) =>
    setAllCargosDW({
      cargoIndex: index,
      newDeliveryWindowOption: value,
      mainCargoDeliveryWindowOptions: options[0],
      values,
      setFieldValue,
    })

  const onChangeCargoDate = (dateFieldName, newValue) =>
    setAllCargosDates({
      dateFieldName,
      cargoIndex: index,
      newValue,
      values,
      setFieldValue,
    })

  return (
    <div ref={ref}>
      {!inView && <CargoCardSkeleton />}
      {inView && (
        <Card id={`delivery-window-cargo-card-${index}`} style={style}>
          <Text variant="body1">Cargo {index + 1}</Text>
          <DeliveryWindowSelect
            name={`nominationRules.deliveryWindow.cargos[${index}].cargoWindow`}
            options={options[index]}
            disabled={disabled}
            onChange={({ target: { value } }) =>
              onChangeCargoDeliveryWindow(value)
            }
          />
          <CargoCardDateFields
            spacing={2}
            index={index}
            onChangeDate={onChangeCargoDate}
            disabled={disabled}
          />
        </Card>
      )}
    </div>
  )
}

_CargoCard.defaultProps = {
  style: {},
  options: [],
}

_CargoCard.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object,
  options: PropTypes.array,
  formik: PropTypes.object.isRequired,
}

export const CargoCard = connect(_CargoCard)
