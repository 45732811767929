import React from 'react'
import PropTypes from 'prop-types'
import { get, tail } from 'lodash'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { $T, DELIVERY_TYPE } from 'emsurge-selectors'
import {
  Typography as Text,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Hidden,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import {
  getPartyLabel,
  getDeliveryWindowTimeFrameLabel,
  getTimeFrameLabel,
} from '../helpers'
import DetailRow from './DetailRow'
import Accordion from './Accordion'
import DetailDivider from './DetailDivider'
import PortNominations from './PortNominations'
import NominationRulesOtherFields from './NominationRulesOtherFields'
import ShipPort, { AlternativeShipPorts } from './ShipPort'
import OtherNominationsPort from './OtherNominationsPort'
import { StyledGrid } from './StyledGrid'
import {
  getCargoLabel,
  getDeliveryWindowLabel,
} from 'screens/transactionIndex/helpers'
import { useActiveStep, TAB, STEP } from 'containers/OrderProvider'
import { calculateNominationDate } from 'screens/orderCreate/helpers'

const NominationRulesPort = ({
  title,
  name,
  toBeNominated,
  party,
  timeframe,
  timeframeValue,
  style,
  description,
  nominationType,
  onClick,
  timeframeValueDeliveryWindow,
  additionalInfo,
  ...textProps
}) => {
  const timeframeValueLabel = getTimeFrameLabel(
    timeframe,
    timeframeValue,
    timeframeValueDeliveryWindow
  )
  const nominationSentence = `For each cargo, the ${getPartyLabel(
    party
  )} ${nominationType} ${timeframeValueLabel}`

  return (
    <PortNominations
      style={style}
      title={title}
      name={`${name || ''} ${description || ''}`}
      nominationSentence={toBeNominated ? `${nominationSentence}` : ''}
      onClick={onClick}
      additionalInfo={additionalInfo}
      {...textProps}
    />
  )
}

NominationRulesPort.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  toBeNominated: PropTypes.bool,
  nominationType: PropTypes.string,
  party: PropTypes.string,
  timeframe: PropTypes.string,
  timeframeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timeframeValueTime: PropTypes.string,
  timeframeValueDeliveryWindow: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  additionalInfo: PropTypes.array,
}

const AlternativePorts = ({
  alternatives,
  nominationType,
  onClick,
  additionalInfo,
  ...textProps
}) => {
  if (!alternatives) {
    return null
  }

  return alternatives.map((alternative, index) => (
    <NominationRulesPort
      {...alternative}
      onClick={onClick}
      style={{
        fontSize: 14,
        fontWeight: 500,
      }}
      key={index}
      nominationType={nominationType}
      title={`Alternative Port ${index + 1}`}
      additionalInfo={[additionalInfo?.[index]]}
      {...textProps}
    />
  ))
}

const computeCalculationNominationDate = (cargo, alternatives) =>
  alternatives.map(
    (alternative) =>
      alternative.toBeNominated &&
      calculateNominationDate({ cargo, alternative })
  )

const NominationsPanel = ({ order, buyer, seller, clickable, isCreatingTrade }) => {
  const { spacing } = useTheme()
  const shipNomination = get(order, 'nominationRules.ship.base', {})
  const loadPortNomination = get(order, 'nominationRules.loadPort.base', {})
  const dischargePortNomination = get(
    order,
    'nominationRules.dischargePort.base',
    {}
  )

  const cargoes = get(order, 'nominationRules.deliveryWindow.cargos', [])
  const timezone = get(order, 'nominationRules.dischargePort.timezone', '0')
  const firstCargo = { ...(cargoes?.[0] || {}), timezone }
  const deliveryWindowAlternatives = get(
    order,
    'nominationRules.deliveryWindow.alternatives',
    []
  ).sort((a, b) => b.name - a.name)
  const shipAlternatives = get(order, 'nominationRules.ship.alternatives', [])
  const loadPortAlternatives = get(
    order,
    'nominationRules.loadPort.alternatives',
    []
  )
  const dischargePortAlternatives = get(
    order,
    'nominationRules.dischargePort.alternatives',
    []
  )
  const otherNominationPort = $T.other.text(order.nominationRules) || []

  const alternativeLoadPortNominationDate = computeCalculationNominationDate(
    firstCargo,
    loadPortAlternatives
  )
  const alternativeDischargePortNominationDate =
    computeCalculationNominationDate(firstCargo, dischargePortAlternatives)
  const alternativeShipNominationDate = computeCalculationNominationDate(
    firstCargo,
    shipAlternatives
  )
  const otherNominationPortNominationDate = computeCalculationNominationDate(
    firstCargo,
    otherNominationPort
  )

  const adgas = get(order, 'nominationRules.loadPort.adgas', false)
  const adgasLabel = adgas
    ? "Nomination of ADGAS as a Supply Source shall be subject to Buyer's consent, not to be unreasonably withheld."
    : ''

  const orderType = get(order, 'delivery.type')
  const isFobOrSts = [DELIVERY_TYPE.FOB, DELIVERY_TYPE.STS].includes(orderType)
  const { navigate } = useActiveStep()

  const navigateOrCancel = (props) =>
    clickable ? () => navigate.goTo(props) : null

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Text variant="h6">Terms</Text>
      </AccordionSummary>
      <AccordionDetails
        style={{ flexDirection: 'column' }}
        id="orderTerms"
      >
        {buyer && !isCreatingTrade && (
          <>
            <DetailRow
              data-testid="order-terms-buyer-code"
              title="Buyer"
              info={buyer}
              onClick={navigateOrCancel({
                tab: TAB.OVERVIEW,
                step: STEP[TAB.OVERVIEW].GENERAL,
              })}
            />
            <DetailDivider />
          </>
        )}

        {seller && !isCreatingTrade && (
          <>
            <DetailRow
              data-testid="order-terms-seller-code"
              title="Seller"
              info={seller}
              onClick={navigateOrCancel({
                tab: TAB.OVERVIEW,
                step: STEP[TAB.OVERVIEW].GENERAL,
              })}
            />
            <DetailDivider />
          </>
        )}

        {order.nominationRules && (
          <>
            <DetailRow
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: STEP[TAB.TERMS].DELIVERY_WINDOW,
              })}
              data-testid="order-terms-cargo-delivery-window"
              title="Delivery Windows"
              info={getCargoLabel({
                cargo: cargoes[0],
                cargoIndex: 1,
              })}
            />
            {tail(cargoes).map((cargo, i) => (
              <DetailRow
                onClick={navigateOrCancel({
                  tab: TAB.TERMS,
                  step: STEP[TAB.TERMS].DELIVERY_WINDOW,
                })}
                key={i}
                info={getCargoLabel({ cargo, cargoIndex: i + 2 })}
                data-testid="order-terms-cargo-delivery-window"
              />
            ))}
            <DetailDivider />
            <StyledGrid
              key="order-terms-delivery-schedule-container"
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: STEP[TAB.TERMS].DELIVERY_WINDOW,
              })}
              container
            >
              <Grid item xs={12} sm={3}>
                <Text
                  variant="body2"
                  key={'order-terms-delivery-schedule-title'}
                  style={{ fontWeight: 700 }}
                >
                  Delivery Schedule
                </Text>
              </Grid>

              <Grid item xs={12} sm={7}>
                <Text
                  variant="body1"
                  data-testid={'order-terms-delivery-schedule'}
                  key={'order-terms-delivery-schedule'}
                >
                  {getDeliveryWindowLabel(order.nominationRules)}
                </Text>
              </Grid>
            </StyledGrid>
            {deliveryWindowAlternatives.map((alternative, index) => {
              const previousNarrowing = index
                ? deliveryWindowAlternatives[index - 1].name
                : null
              return (
                <StyledGrid
                  onClick={navigateOrCancel({
                    tab: TAB.TERMS,
                    step: STEP[TAB.TERMS].DELIVERY_WINDOW,
                  })}
                  key={`narrowing-mechanism-container-${index}`}
                  container
                >
                  <Grid item xs={12} sm={3}>
                    <Text
                      variant="body2"
                      key={`narrowing-mechanism-title-${index}`}
                      style={{ fontSize: 14 }}
                    >
                      {`Narrowing Mechanism ${index + 1}`}
                    </Text>
                  </Grid>

                  <Grid item xs={12} sm={7}>
                    <Text
                      style={{ fontSize: 14 }}
                      variant="body1"
                      data-testid={'order-terms-narrowing-mechanism'}
                      key={`narrowing-mechanism-${index}`}
                    >
                      {getDeliveryWindowTimeFrameLabel(
                        alternative,
                        firstCargo,
                        previousNarrowing
                      )}
                    </Text>
                  </Grid>

                  <Hidden xsDown>
                    <Grid item sm={2}>
                      <Text
                        style={{ paddingLeft: spacing(2), fontSize: 14 }}
                        variant="body1"
                        data-testid={'order-terms-narrowing-mechanism-date'}
                        key={`narrowing-mechanism-date-${index}`}
                      >
                        {calculateNominationDate({
                          cargo: firstCargo,
                          alternative,
                        })}
                      </Text>
                    </Grid>
                  </Hidden>
                </StyledGrid>
              )
            })}

            <DetailDivider />
            <NominationRulesPort
              {...loadPortNomination}
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: isFobOrSts
                  ? STEP[TAB.TERMS].DELIVERY_PORT
                  : STEP[TAB.TERMS].LOAD_PORT,
              })}
              nominationType="Load Port"
              title={isFobOrSts ? 'Delivery Port/\nLoad Port' : 'Load Port'}
              style={{ whiteSpace: 'pre-line' }}
              data-testid="order-terms-load-port"
              additionalInfo={
                loadPortNomination.toBeNominated
                  ? [
                      calculateNominationDate({
                        cargo: firstCargo,
                        alternative: loadPortNomination,
                      }),
                    ]
                  : []
              }
            />

            {adgasLabel ? (
              <DetailRow
                data-testid="order-terms-load-port-adgas"
                info={adgasLabel}
              />
            ) : null}
            <AlternativePorts
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: STEP[TAB.TERMS].LOAD_PORT,
              })}
              data-testid="order-terms-alternative-load-port"
              alternatives={loadPortAlternatives}
              nominationType="Alternative Load Port"
              additionalInfo={alternativeLoadPortNominationDate}
            />

            <DetailDivider />
            <NominationRulesPort
              {...dischargePortNomination}
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: isFobOrSts
                  ? STEP[TAB.TERMS].LOAD_PORT
                  : STEP[TAB.TERMS].DELIVERY_PORT,
              })}
              nominationType="Discharge Port"
              title={
                isFobOrSts ? 'Discharge Port' : 'Delivery Port/\nDischarge Port'
              }
              style={{ whiteSpace: 'pre-line' }}
              data-testid="order-terms-discharge-port"
              additionalInfo={
                dischargePortNomination.toBeNominated
                  ? [
                      calculateNominationDate({
                        cargo: firstCargo,
                        alternative: dischargePortNomination,
                      }),
                    ]
                  : []
              }
            />
            <AlternativePorts
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: STEP[TAB.TERMS].DELIVERY_PORT,
              })}
              data-testid="order-terms-alternative-discharge-port"
              alternatives={dischargePortAlternatives}
              nominationType="Alternative Discharge Port"
              additionalInfo={alternativeDischargePortNominationDate}
            />

            <DetailDivider />
            <ShipPort
              {...shipNomination}
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: STEP[TAB.TERMS].LNG_SHIP,
              })}
              data-testid="order-terms-lng-ship"
              title="LNG Ship"
              nominationType="Vessel"
              additionalInfo={
                shipNomination.toBeNominated
                  ? [
                      calculateNominationDate({
                        cargo: firstCargo,
                        alternative: shipNomination,
                      }),
                    ]
                  : []
              }
            />
            <AlternativeShipPorts
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: STEP[TAB.TERMS].LNG_SHIP,
              })}
              data-testid="order-terms-alternative-lng-ship"
              alternatives={shipAlternatives}
              nominationType="Alternative Vessel"
              additionalInfo={alternativeShipNominationDate}
            />

            <NominationRulesOtherFields
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: STEP[TAB.TERMS].OTHER_SHIPPING,
              })}
              nominationRules={order.nominationRules}
            />

            <OtherNominationsPort
              onClick={navigateOrCancel({
                tab: TAB.TERMS,
                step: STEP[TAB.TERMS].OTHER_TERMS,
              })}
              data-testid="order-terms-other-terms"
              nominationRules={order.nominationRules}
              additionalInfo={otherNominationPortNominationDate}
            />
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

NominationsPanel.defaultProps = {
  clickable: false,
}

NominationsPanel.propTypes = {
  order: PropTypes.object.isRequired,
  buyer: PropTypes.string,
  seller: PropTypes.string,
  clickable: PropTypes.bool,
}

export default NominationsPanel
