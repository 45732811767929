import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { withProps } from 'recompose'

const StyledGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`
const GridContainer = withProps({ container: true, spacing: 2 })(StyledGrid)

const Column = withProps({ xs: 4, item: true })(Grid)

export { Column, GridContainer }
