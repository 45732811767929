import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { $B } from 'emsurge-selectors/models'
import { Interpolate } from 'components/Interpolate'

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: '600px',
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  date: {
    fontWeight: 'normal',
    marginLeft: theme.spacing(2),
  },
  title: {
    fontSize: 22,
    marginBottom: theme.spacing(4),
  },
  message: {
    minHeight: '100px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    wordBreak: 'break-word',
  },
  author: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: '0',
  },
}))

export const Bulletin = ({ bulletin }) => {
  const classes = useStyles()
  const bulletinText = $B.text(bulletin)

  const message = bulletinText.message
    .split('\n')
    .map((line, i) => <Interpolate key={i}>{line}</Interpolate>)

  return (
    <Box
      classes={{ root: classes.container }}
      display="flex"
      flexDirection="column"
    >
      <Typography classes={{ root: classes.header }} variant="h2" paragraph>
        BROADCAST MESSAGE
      </Typography>
      <Box display="flex">
        <Typography data-testid="bulletin-type" variant="caption">
          {bulletinText.messageType}
        </Typography>
        <Typography classes={{ root: classes.date }} variant="caption">
          {' '}
          {bulletinText.createdAt}{' '}
        </Typography>
      </Box>
      <Typography
        data-testid="bulletin-title"
        classes={{ root: classes.title }}
        variant="subtitle1"
        paragraph
      >
        {bulletinText.title}
      </Typography>
      <Typography
        data-testid="bulletin-message"
        classes={{ root: classes.message }}
        variant="body1"
        paragraph
      >
        {message}
      </Typography>
      <Typography
        data-testid="bulletin-author"
        classes={{ root: classes.author }}
        variant="subtitle1"
      >
        {bulletinText.author.fullName}
      </Typography>
    </Box>
  )
}

Bulletin.propTypes = {
  bulletin: PropTypes.object,
}
