const { NOMINATION_TYPE_TEXT, NOMINATION_TYPE } = require('../../../constants')
const port = require('./port')

const getPortBase = (terms) => {
  const { dischargePort = {} } = terms
  const { base, timezone } = dischargePort
  const deliveryPortBase = port.get(base)

  return {
    ...deliveryPortBase,
    timezone,
  }
}

const getPortBaseText = (terms) => {
  const { dischargePort = {} } = terms
  const deliveryPortBaseText = port.text(
    dischargePort.base,
    NOMINATION_TYPE_TEXT[NOMINATION_TYPE.DISCHARGE_PORT]
  )

  return deliveryPortBaseText
}

const getPortBaseSummary = (terms) => {
  const { dischargePort = {} } = terms
  const portSummary = port.summary(dischargePort.base)

  const portBaseSummary = {
    ...portSummary,
  }

  return portBaseSummary
}

const getPortAlternatives = (terms) => {
  const { dischargePort = {} } = terms
  const { alternatives = [] } = dischargePort
  const basePortAlternatives = alternatives.map(port.get)

  return basePortAlternatives
}

const getPortAlternativesText = (terms) => {
  const { dischargePort = {} } = terms
  const { alternatives = [] } = dischargePort
  const portAlternativesText = alternatives.map((alternative) =>
    port.text(alternative, NOMINATION_TYPE_TEXT[NOMINATION_TYPE.DISCHARGE_PORT])
  )

  return portAlternativesText
}

const getPort = (terms) => {
  const base = getPortBase(terms)
  return {
    ...base,
    alternatives: getPortAlternatives(terms),
  }
}

const getPortSummary = (terms) => {
  const baseSummary = getPortBaseSummary(terms)

  return {
    ...baseSummary,
    alternatives: getPortAlternativesText(terms),
  }
}

const getPortText = (terms) => {
  const baseText = getPortBaseText(terms)

  return {
    ...baseText,
    alternatives: getPortAlternativesText(terms),
  }
}

module.exports = {
  get: getPort,
  summary: getPortSummary,
  text: getPortText,
  base: {
    get: getPortBase,
    text: getPortBaseText,
    summary: getPortBaseSummary,
  },
  alternatives: {
    get: getPortAlternatives,
    text: getPortAlternativesText,
  },
}
