import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { get } from 'lodash'
import { CARBON_PERIOD } from 'emsurge-selectors'
import { SelectVintagePeriod } from './SelectVintagePeriod'
import { SelectCustomVintageDate } from './SelectCustomVintageDate'
import { VintageText } from './VintageText'
import { SelectDeliveryYear } from 'screens/orderCreateCommon/components/SelectDeliveryYear'
import FieldCard from 'screens/orderCreateCommon/components/FieldCard'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
  },
}))

const _PeriodSelect = ({
  formik: { values, initialValues, setFieldValue },
  fieldPrefix,
  displaySelectPeriod,
  infoTitle,
  hasYMinus1,
}) => {
  const PERIOD_PATH = `${fieldPrefix}.period`
  const [period, setPeriod] = useState('')
  const classes = useStyles()

  useEffect(() => {
    const value = get(values, PERIOD_PATH) || get(initialValues, PERIOD_PATH)
    setFieldValue(PERIOD_PATH, value)
    setPeriod(value)
  }, [values])

  const onChangePeriod = (period) => {
    setFieldValue(PERIOD_PATH, period)
  }

  return (
    <Grid container spacing={3}>
      {displaySelectPeriod && (
        <SelectVintagePeriod fieldPrefix={fieldPrefix} hasYMinus1={hasYMinus1}>
          {({ options, onChange }) => (
            <Grid item xs={period === CARBON_PERIOD.CUSTOM ? 3 : 4}>
              <TextField
                data-testid="vintage-period-dropdown"
                onChange={(evt) => {
                  onChange(evt.target.value)
                  onChangePeriod(evt.target.value)
                }}
                select
                name={fieldPrefix}
                label="Period"
                value={period}
                margin="normal"
                className={classes.content}
              >
                {options.map(({ label, value }) => (
                  <MenuItem
                    data-testid="vintage-period-values"
                    key={value}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </SelectVintagePeriod>
      )}

      <Grid item xs>
        {period === CARBON_PERIOD.CUSTOM ? (
          <SelectCustomVintageDate xs={6} fieldName={fieldPrefix} />
        ) : ( period !== CARBON_PERIOD.Y_MINUS_1 &&
          <SelectDeliveryYear fieldPrefix={fieldPrefix} />
        )}
      </Grid>

      <Grid item xs={4}>
        <FieldCard title={infoTitle}>
          <VintageText
            data-testid={fieldPrefix.replace(/\./g, '-')}
            fieldPrefix={fieldPrefix}
          />
        </FieldCard>
      </Grid>
    </Grid>
  )
}

_PeriodSelect.defaultProps = {
  displaySelectPeriod: true,
  infoTitle: 'Range',
}

_PeriodSelect.propTypes = {
  fieldPrefix: PropTypes.string.isRequired,
  displaySelectPeriod: PropTypes.bool,
  infoTitle: PropTypes.string,
}

export const PeriodSelect = connect(_PeriodSelect)
