import { string, object } from 'yup'

const getPrimaryPointContactFormSchema = ({ email }) =>
  object().shape({
    name: string().required('Required'),
    surname: string().required('Required'),
    jobTitle: string().required('Required'),
    entity: string().required('Required'),
    email: string()
      .email('Invalid email')
      .emailIsUnique(email)
      .required('Required'),
    phone: string().required('Required'),
    addressFirstLine: string().required('Required'),
    addressSecondLine: string(),
    postcode: string().required('Required'),
    country: string().required('Required'),
  })

export { getPrimaryPointContactFormSchema }
