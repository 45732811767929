import React from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { Formik } from 'formik'
import { omit, merge } from 'lodash'
import { useQueryClient } from 'react-query'
import { TRADING_TYPE } from 'emsurge-selectors'
import { getOrderInfo } from 'screens/orderCreate/containers/FormProvider'
import { getInformationInitialValues } from 'screens/orderEdit/containers/getInformationInitialValues'
import { getNominationInitialValues } from 'screens/orderEdit/containers/getNominationInitialValues'
import { useApi } from 'containers/api/useApi'
import { useUser } from 'containers/user/useUser'
import { getApiErrorMessage } from 'containers/api/helpers'
import { useRouter } from 'utils/useRouter'
import { ORDER_KEY } from 'api/constants'
import { getPartyFromOrderInTermsheet } from 'screens/orderView/helpers'
import {IsOrderPartyDataEqualsToFormValuesPartyData, getTermsheetPartyData, AreTermsheetPartiesEqual, getTermsheetAdditionalParty} from 'screens/orderCreateCounter/helpers'

const getInitialValues = (termsheet) => {
  const initialValues = {
    ...getInformationInitialValues(termsheet),
    ...getNominationInitialValues(termsheet),
  }

  return initialValues
}

const getData = (values, user) =>
  omit(getOrderInfo(values, user), [
    'comments',
    'entityId',
    'companyId',
    'thirdParty',
  ])

export const getStep1 = (termsheet) => {

  const buyerOrder = termsheet.orders.find(x => x.tradingType === TRADING_TYPE.BID)
  const sellerOrder = termsheet.orders.find(x => x.tradingType === TRADING_TYPE.ASK)

  const isBuyerOriginalOrder = !!buyerOrder.lastLiveAt
  const buyerInformationDefaultValues = {
    ...getPartyFromOrderInTermsheet(buyerOrder, termsheet),
    ...termsheet.bid,
    isCompanyDisabled: isBuyerOriginalOrder
  }

  const isSellerOriginalOrder = !!sellerOrder.lastLiveAt
  const sellerInformationDefaultValues = {
    ...getPartyFromOrderInTermsheet(sellerOrder, termsheet),
    ...termsheet.ask,
    isCompanyDisabled: isSellerOriginalOrder
  }

  const stepValues = {
    buyer: buyerInformationDefaultValues,
    seller: sellerInformationDefaultValues,
  }

  return stepValues
}

const getEditTradeInitialValues = (termsheet) => {
  const initialValues = merge(
    {},
    getInitialValues(termsheet),
    getStep1(termsheet)
  )

  return initialValues
}

const getApiData = (termsheet, values, user) => {
  const result = {
    id: termsheet.id,
    ...getData(values, user),
  }

  result.brokerId = termsheet.brokerId
  result.companyId = termsheet.companyId
  result.entityId = termsheet.entityId
  result.personId = termsheet.personId
  result.tradingType =  termsheet.tradingType

  return result
}

export const getApiDataUpdateOnlyTermsheet = (termsheet, values) => {
  const result = {
    tradeDate: termsheet.tradeDate,
    cnDate: termsheet.cnDate || null,
    tradeDetails: termsheet.tradeDetails || null,
    ...getTermSheetData(termsheet, values)
  }

  return result
}

const getTermSheetData = (termsheet, formValues) => {
  const buyerOrder = termsheet.orders.find(x => x.tradingType === TRADING_TYPE.BID)
  const sellerOrder = termsheet.orders.find(x => x.tradingType === TRADING_TYPE.ASK)
  let bidPartyData = {}
  let askPartyData = {}
  if(!IsOrderPartyDataEqualsToFormValuesPartyData(buyerOrder, formValues.buyer))
  {
    bidPartyData = getTermsheetPartyData(formValues.buyer)
  }
  else if (termsheet.bid && !AreTermsheetPartiesEqual(termsheet.bid, formValues.buyer))
  {
    //When party information is set back to original party that was modified then we need to clear the original party modified information.
    bidPartyData = { }
  }
  if (!IsOrderPartyDataEqualsToFormValuesPartyData(sellerOrder, formValues.seller))
  {
    askPartyData = getTermsheetPartyData(formValues.seller)
  }
  else if (termsheet.ask && !AreTermsheetPartiesEqual(termsheet.ask, formValues.seller))
  {
    //When party information is set back to original party that was modified then we need to clear the original party modified information.
    askPartyData = { }
  }

  const result = {
    bid:{
      ...bidPartyData,
      ...getTermsheetAdditionalParty(formValues.buyer)
    },
    ask: {
      ...askPartyData,
      ...getTermsheetAdditionalParty(formValues.seller)
    }
  }

  return result
}
  
const FormProvider = ({ termsheet, children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { history } = useRouter()
  const api = useApi()
  const { user } = useUser()
  const queryClient = useQueryClient()

  const handleSubmit = async (values, actions) => {
    const data = getApiData(termsheet, values, user)
    const dataUpdateOnlyTermSheet = getApiDataUpdateOnlyTermsheet(termsheet, values)
    const isTermsheetTableUpdateRequired = !!(dataUpdateOnlyTermSheet.bid || dataUpdateOnlyTermSheet.ask)
    delete data.orders

    try {
      await api.put(`/orders/${termsheet.id}`, data)
      if(isTermsheetTableUpdateRequired)
      {
        await api.patch(`/termsheets/${termsheet.id}/tradedetails`, dataUpdateOnlyTermSheet)
      }
      actions.resetForm()
      enqueueSnackbar('Term sheet updated successfully', {
        variant: 'success',
      })
      queryClient.invalidateQueries([ORDER_KEY, termsheet.id]) // TODO: replace with mutation
      history.goBack()
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Term sheet update failed',
      })
      enqueueSnackbar(message, { variant: 'error' })
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={getEditTradeInitialValues(termsheet)}>
      {children}
    </Formik>
  )
}

FormProvider.defaultProps = {
  postSubmit: () => {},
}

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  termsheet: PropTypes.object.isRequired,
}

export default FormProvider
