const { CARGO_SIZE_UNIT } = require('./unit')

const CARGO_SIZE_TYPE = Object.freeze({
  STANDARD: 'standard',
  SPECIFIC: 'specific',
  THREE_TWO: '3.2',
  THREE_THREE: '3.3',
  THREE_FIVE: '3.5',
  CUSTOM: 'custom',
})

const CARGO_SIZE_TYPE_TEXT = Object.freeze({
  [CARGO_SIZE_TYPE.CUSTOM]: 'Range',
  [CARGO_SIZE_TYPE.SPECIFIC]: 'Specific',
  [CARGO_SIZE_TYPE.STANDARD]: 'Standard',
  [CARGO_SIZE_TYPE.THREE_TWO]: '3.2',
  [CARGO_SIZE_TYPE.THREE_THREE]: '3.3',
  [CARGO_SIZE_TYPE.THREE_FIVE]: '3.5',
  CUSTOM: 'Range',
  SPECIFIC: 'Specific',
  STANDARD: 'Standard',
  THREE_TWO: '3.2',
  THREE_THREE: '3.3',
  THREE_FIVE: '3.5',
})

const CARGO_SIZE_TYPE_OPTIONS = {
  [CARGO_SIZE_UNIT.TBTU]: [
    { value: CARGO_SIZE_TYPE.STANDARD, label: CARGO_SIZE_TYPE_TEXT.STANDARD },
    { value: CARGO_SIZE_TYPE.SPECIFIC, label: CARGO_SIZE_TYPE_TEXT.SPECIFIC },
    { value: CARGO_SIZE_TYPE.THREE_TWO, label: CARGO_SIZE_TYPE_TEXT.THREE_TWO },
    {
      value: CARGO_SIZE_TYPE.THREE_THREE,
      label: CARGO_SIZE_TYPE_TEXT.THREE_THREE,
    },
    {
      value: CARGO_SIZE_TYPE.THREE_FIVE,
      label: CARGO_SIZE_TYPE_TEXT.THREE_FIVE,
    },
    { value: CARGO_SIZE_TYPE.CUSTOM, label: CARGO_SIZE_TYPE_TEXT.CUSTOM },
  ],
  [CARGO_SIZE_UNIT.M3]: [
    { value: CARGO_SIZE_TYPE.SPECIFIC, label: CARGO_SIZE_TYPE_TEXT.SPECIFIC },
    { value: CARGO_SIZE_TYPE.CUSTOM, label: CARGO_SIZE_TYPE_TEXT.CUSTOM },
  ],
  [CARGO_SIZE_UNIT.GWH]: [
    { value: CARGO_SIZE_TYPE.SPECIFIC, label: CARGO_SIZE_TYPE_TEXT.SPECIFIC },
    { value: CARGO_SIZE_TYPE.CUSTOM, label: CARGO_SIZE_TYPE_TEXT.CUSTOM },
  ],
}

module.exports = {
  CARGO_SIZE_TYPE,
  CARGO_SIZE_TYPE_TEXT,
  CARGO_SIZE_TYPE_OPTIONS,
}
