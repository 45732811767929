import { TRADING_TYPE, PERSON_CLIENT_TYPE } from 'emsurge-selectors/constants'

const createThirdParty = (counterParty) =>
{
  const result = {
    thirdParty: {
      "companyName": counterParty.company?.name,
      "entityName": counterParty.entity?.name,
      "contact":counterParty.person?.name,  
    }
  }
  return result
}

const createOffSystemCounterParty = (user, counterParty) =>
{
  const result = {
    entityId: null,
    personId: user.id,
    ...createThirdParty(counterParty)
  }
  return result
}

const createInSystemCounterParty = (counterParty) =>
{
  const result = {
    companyId: counterParty.company?.id,
      entityId: counterParty.entity?.id,
      personId: counterParty.person?.id,
  }
  return result
}

const createBrokerCounterParty = (user, counterParty) => {
  const brokerInfo = {
    brokerId: user.company?.id, 
    brokerPersonId: user.id,
  }
  let counterPartyInfo;
  if(counterParty.company?.id)
  {
    counterPartyInfo = createInSystemCounterParty(counterParty)
  }
  else
  {
    counterPartyInfo = createOffSystemCounterParty(user, counterParty)
  }
  return {
    ...brokerInfo,
    ...counterPartyInfo
  }
}

const createTraderCounterParty = (user, counterParty) => {
  const brokerInfo = {
    brokerId: '', 
    brokerPersonId: '',
  }
  let counterPartyInfo;
  if(counterParty.company?.id !== user.company.id || user.entities.find(x => x === counterParty.entity?.id ))
  {
    counterPartyInfo = createOffSystemCounterParty(user, counterParty)
  }
  else
  {
    counterPartyInfo = createInSystemCounterParty(counterParty)
  }
  return {
    ...brokerInfo,
    ...counterPartyInfo
  }
}

export const getCounterPartyData = (counterOrder, formValues, user) =>
{
  const counterParty = counterOrder.tradingType === TRADING_TYPE.BID ? formValues.buyer : formValues.seller
  const result = user.clientType === PERSON_CLIENT_TYPE.BROKER ? createBrokerCounterParty(user, counterParty) : createTraderCounterParty(user, counterParty)
  return result 
}

export const getTermsheetPartyData = (party) => {
  const result = {
    companyName: party.company.name,
    companyId: party.company.id,
    companyCode: party.company.code,
    entityName: party.entity.name,
    entityId: party.entity.id,
    entityCode: party.entity.code,
    personName: party.person.name,
    personId: party.person.id,
    opsContact: party.opsContact,
    executionTrader: party.executionTrader
  }
  return result
}

export const getTermsheetAdditionalParty = (party) => {
  const result = {
    opsContact: party.opsContact,
    executionTrader: party.executionTrader
  }
  return result
}

export const getTermSheetData = (order, formValues) => {
  let bidPartyData = {}
  let askPartyData = {}
  if(order.tradingType === TRADING_TYPE.BID && !IsOrderPartyDataEqualsToFormValuesPartyData(order, formValues.buyer))
  {
    bidPartyData = getTermsheetPartyData(formValues.buyer)
  }
  else if (order.tradingType === TRADING_TYPE.ASK && !IsOrderPartyDataEqualsToFormValuesPartyData(order, formValues.seller))
  {
    askPartyData = getTermsheetPartyData(formValues.seller)
  }
  const result = {
    bid:{
      ...bidPartyData,
      ...getTermsheetAdditionalParty(formValues.buyer)
    },
    ask: {
      ...askPartyData,
      ...getTermsheetAdditionalParty(formValues.seller)

    }
  }
  return result
}

export const IsOrderPartyDataEqualsToFormValuesPartyData = (order, party) =>
{
  let result
  if(order?.company?.id) // On system order
  {
    result = order?.company?.id == party?.company?.id &&
              order?.entity?.id == party?.entity?.id &&
              order?.person?.id == party?.person?.id
  }
  else //Off system order
  {
    result = order?.thirdParty?.companyName == party?.company?.name &&
              order?.thirdParty?.entityName == party?.entity?.name &&
              order?.thirdParty?.contact == party?.person?.name
  }
  return result
}

export const AreTermsheetPartiesEqual = (party1, party2) =>
{
  const result = party1?.company?.id == party2?.company?.id &&
  party1?.entity?.id == party2?.entity?.id &&
  party1?.person?.id == party2?.person?.id
  return result
}