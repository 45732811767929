import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Typography as Text } from '@material-ui/core'

const Container = styled(Grid)`
  background: ${({ theme }) => theme.palette.background.secondary};
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  border-radius: 3px;
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
`

export const Option = ({ label = '', actions = '' }) => {
  return (
    <Container container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Text variant="subtitle1">{label}</Text>
      </Grid>
      <Grid item>{actions}</Grid>
    </Container>
  )
}

Option.propTypes = {
  label: PropTypes.node,
  actions: PropTypes.node,
}
