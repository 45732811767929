import React, { useState, useEffect, useCallback, memo } from 'react'
import { each, partial, once } from 'lodash'
import PropTypes from 'prop-types'
import { Timeline as VisTimeline, DataSet as VisDataSet } from 'vis-timeline/standalone'
import { defaultCss } from './constants'

export const _Timeline = ({
  options,
  groups,
  items,
  css,
  containerStyle,
  handlers,
}) => {
  const [timeline, setTimeline] = useState()
  const timelineRef = useCallback(
    (node) => {
      if (node !== null) {
        const tml = new VisTimeline(node)
        tml.setOptions({})
        tml.setItems([])
        if (groups) {
          tml.setGroups([])
        }
        setTimeline(tml)
      }
    },
    [groups]
  )

  useEffect(() => {
    if (timeline) {
      each(handlers, (handler, event) => {
        if (event === 'complete') {
          return timeline.on(
            'changed',
            once(() => handler(timeline))
          )
        }
        return timeline.on(event, partial(handler, timeline))
      })
    }
  }, [handlers, timeline])
  useEffect(() => timeline && timeline.setOptions(options), [options, timeline])
  useEffect(
    () => timeline && timeline.setItems(new VisDataSet(items)),
    [items, timeline]
  )
  useEffect(
    () => timeline && groups && timeline.setGroups(new VisDataSet(groups)),
    [groups, timeline]
  )
  useEffect(() => {
    if (timeline) {
      return () => timeline.destroy()
    }
  }, [timeline])

  return (
    <>
      <style>{css}</style>
      <div ref={timelineRef} style={containerStyle} />
    </>
  )
}

_Timeline.defaultProps = {
  options: {},
  items: [],
  css: defaultCss,
  handlers: {},
  containerStyle: {},
}

_Timeline.propTypes = {
  options: PropTypes.object,
  groups: PropTypes.array,
  items: PropTypes.array,
  css: PropTypes.string,
  containerStyle: PropTypes.object,
  handlers: PropTypes.object,
}

export const Timeline = memo(_Timeline)
