import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { connect } from 'formik'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'

import { DELIVERY_TYPE } from 'emsurge-selectors'
import { DeliveryWindow } from './DeliveryWindow'
import { LoadPort } from './LoadPort'
import { DischargePort } from './DischargePort'
import { ShipNominations } from './ShipNominations'
import { OtherNominations } from './OtherNominations'
import { Other } from './Other'
import { useActiveStep } from 'containers/OrderProvider'
import VerticalStepper from 'screens/orderCreateCommon/components/VerticalStepper'
import StepLabel from 'screens/orderCreateCommon/components/StepLabel'
import StepActions, {
  validNextStep,
} from 'screens/orderCreateCommon/components/StepActions'
import {
  getNominationsStep1Errors,
  getNominationsStep2Errors,
  getNominationsStep3Errors,
  getNominationsStep4Errors,
  getNominationsStep5Errors,
} from 'screens/orderCreate/getErrors'

export const getTermsSteps = ({ delivery: { type } }) => {
  const steps = [
    { title: 'Delivery Window', component: DeliveryWindow, number: 6 },
    undefined,
    undefined,
    { title: 'LNG Ship', component: ShipNominations, number: 9 },
    { title: 'Other Shipping', component: Other, number: 10 },
    {
      title: 'Other Terms / Conditions Precedent',
      component: OtherNominations,
      number: 11,
    },
  ]

  if ([DELIVERY_TYPE.FOB, DELIVERY_TYPE.STS].includes(type)) {
    steps[1] = {
      title: 'Delivery Port (Load Port)',
      component: LoadPort,
      number: 7,
    }
    steps[2] = { title: 'Discharge Port', component: DischargePort, number: 8 }
    return steps
  }

  steps[1] = {
    title: 'Delivery Port (Discharge Port)',
    component: DischargePort,
    number: 7,
  }
  steps[2] = { title: 'Load Port', component: LoadPort, number: 8 }

  return steps
}

const NominationsFormStepper = ({ formik }) => {
  const {
    activeStep,
    navigate: { goTo, goBack, goForward },
  } = useActiveStep()

  const steps = getTermsSteps(formik.values)
  const stepErrorGetters = [
    getNominationsStep1Errors,
    getNominationsStep2Errors,
    getNominationsStep3Errors,
    getNominationsStep4Errors,
    getNominationsStep5Errors,
  ]

  return (
    <VerticalStepper activeStep={activeStep.step} nonLinear>
      {steps.map(({ title, number, component: Content }, idx) => {
        const currentStepErrors = stepErrorGetters[idx]
          ? stepErrorGetters[idx](formik)
          : null

        return (
          <Step key={title}>
            <StepLabel
              icon={number}
              error={!isEmpty(currentStepErrors)}
              onClick={() => goTo({ step: idx })}
            >
              {title}
            </StepLabel>
            <StepContent>
              <Content />
              <StepActions
                totalSteps={steps.length}
                activeStep={activeStep.step}
                onBack={goBack}
                onNext={() => validNextStep(formik, goForward)}
              />
            </StepContent>
          </Step>
        )
      })}
    </VerticalStepper>
  )
}

NominationsFormStepper.propTypes = {
  formik: PropTypes.object.isRequired,
}

export default connect(NominationsFormStepper)
