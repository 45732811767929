import React from 'react'
import Text from '@material-ui/core/Typography'
import { TraderPermissions } from './components/TraderPermissions'

const Settings = () => {
  return (
    <>
      <Text variant="h1" gutterBottom>
        SETTINGS
      </Text>
      <TraderPermissions />
    </>
  )
}

export default Settings
