import React from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'
import { OrderCardsSkeleton } from './OrderCardsSkeleton'

export const OrdersSkeleton = ({ count }) => (
  <>
    {times(count, (i) => (
      <OrderCardsSkeleton
        key={`order-cards-skeleton-${i}`}
        style={{ marginTop: '10px' }}
      />
    ))}
  </>
)

OrdersSkeleton.defaultProps = {
  count: 1,
}

OrdersSkeleton.propTypes = {
  count: PropTypes.number,
}
