const NOMINATION_TIMEFRAME = Object.freeze({
  DAYS_AFTER: 'days_after',
  DAYS_AHEAD: 'days_ahead',
  FIXED_DATE: 'fixed_date',
  MONTH_AHEAD: 'month_ahead',
  POINT_TRADE: 'point_trade',
})

const NOMINATION_TYPE = Object.freeze({
  DELIVERY_WINDOW: 'deliveryWindow',
  DISCHARGE_PORT: 'dischargePort',
  LOAD_PORT: 'loadPort',
  SHIP: 'ship',
  OTHER: 'other',
})

const NOMINATION_TYPE_TEXT = Object.freeze({
  [NOMINATION_TYPE.DELIVERY_WINDOW]: '',
  [NOMINATION_TYPE.DISCHARGE_PORT]: 'Discharge Port',
  [NOMINATION_TYPE.LOAD_PORT]: 'Load Port',
  [NOMINATION_TYPE.SHIP]: 'Vessel',
  [NOMINATION_TYPE.OTHER]: '',
})

const NOMINATION_TIMEFRAME_TEXT = Object.freeze({
  [NOMINATION_TIMEFRAME.DAYS_AFTER]: 'XX days after point of trade',
  [NOMINATION_TIMEFRAME.DAYS_AHEAD]: 'XX days ahead of relevant DW',
  [NOMINATION_TIMEFRAME.FIXED_DATE]: 'Fixed date',
  [NOMINATION_TIMEFRAME.MONTH_AHEAD]: 'XX of M-1',
  [NOMINATION_TIMEFRAME.POINT_TRADE]: 'Point of trade',
})

module.exports = {
  NOMINATION_TIMEFRAME_TEXT,
  NOMINATION_TIMEFRAME,
  NOMINATION_TYPE_TEXT,
  NOMINATION_TYPE,
}
