import { get } from 'lodash'
import {
  compose,
  entries,
  map,
  join,
  flatten,
  isObject,
  isString,
} from 'lodash/fp'

export const getApiErrorMessage = ({ error, defaultMessage }) => {
  const data = get(error, 'response.data', {})
  const { message, description, details } = data

  return (
    (isString(details) && details) || description || message || defaultMessage
  )
}

export const toQueryString = compose(
  (str) => (str ? `?${str}` : null),
  join('&'),
  flatten,
  map(([key, value]) =>
    flatten([value]).map((v) =>
      isObject(v) ? `${key}=${JSON.stringify(v)}` : `${key}=${v}`
    )
  ),
  entries
)
