import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import { connect } from 'formik'
import moment from 'moment'
import {
  getDeliveryPeriodRange,
  isEvenlyMonths,
  isEvenlyCargoes,
  getRangeMonthsNumber,
  getPeriodMonths,
  getTotalCargos,
} from './DeliveryWindow.helpers'
import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'

const optionShouldBeDisabled = (values, monthsFilterFn) => {
  const { delivery } = values
  const periodRange = getDeliveryPeriodRange(delivery)
  const filteredMonths = getPeriodMonths(periodRange.from, periodRange.to)
    .map(({ month }) => month)
    .filter(monthsFilterFn)
  const numberOfMonths = filteredMonths.length
  const numberOfCargoes = getTotalCargos(values)
  return (
    numberOfMonths === 0 ||
    (numberOfMonths % numberOfCargoes !== 0 &&
      numberOfCargoes % numberOfMonths !== 0)
  )
}

const evenOptionShouldBeDisabled = ({ values }) =>
  optionShouldBeDisabled(values, (monthIndex) => monthIndex % 2 === 0)

const oddOptionShouldBeDisabled = ({ values }) =>
  optionShouldBeDisabled(values, (monthIndex) => monthIndex % 2 !== 0)

const setDefaultFrequencyDistribution = ({ values, setFieldValue }) => {
  if (
    (evenOptionShouldBeDisabled({ values }) ||
      oddOptionShouldBeDisabled({ values })) &&
    values.delivery.frequencyDistribution !== 'x_days_or_months'
  ) {
    setFieldValue('delivery.frequencyDistribution', 'x_days_or_months')
  }
}

const getCargoesFrequencyOptions = ({ values }) => {
  const periodRange = getDeliveryPeriodRange(values.delivery)
  const numberOfCargoes = getTotalCargos(values)
  const evenMonths = isEvenlyMonths(
    periodRange.from,
    periodRange.to,
    numberOfCargoes
  )
  const evenCargoes = isEvenlyCargoes(
    periodRange.from,
    periodRange.to,
    numberOfCargoes
  )
  const numberOfMonths = getRangeMonthsNumber(periodRange.from, periodRange.to)

  const options = [
    {
      value: 'odd_months',
      label: 'Odd months',
      disabled: oddOptionShouldBeDisabled({ values }),
    },
    {
      value: 'even_months',
      label: 'Even months',
      disabled: evenOptionShouldBeDisabled({ values }),
    },
  ]

  if (evenMonths) {
    const monthsByRange = numberOfMonths / numberOfCargoes
    options.push({
      label: `1 cargo every ${monthsByRange > 1 ? monthsByRange : ''} month${
        monthsByRange > 1 ? 's' : ''
      }`,
      value: 'x_days_or_months',
    })
  } else if (evenCargoes) {
    const cargoesPerMonth = numberOfCargoes / numberOfMonths
    options.push({
      value: 'x_days_or_months',
      label: `${cargoesPerMonth} cargo${
        cargoesPerMonth > 1 ? 'es' : ''
      } every month`,
    })
  } else {
    const periodLength = periodRange.to.valueOf() - periodRange.from.valueOf()
    const daysByRange = Math.trunc(
      moment.duration(periodLength / numberOfCargoes, 'milliseconds').asDays()
    )

    options.push({
      value: 'x_days_or_months',
      label: `1 cargo every ~ ${daysByRange} day${daysByRange > 1 ? 's' : ''}`,
    })
  }

  return options
}

const FrequencyDistribution = ({ formik: { values, setFieldValue } }) => {
  useEffect(() => {
    setDefaultFrequencyDistribution({ values, setFieldValue })
  }, [
    values.volume.min,
    values.delivery.year,
    values.delivery.customFrom,
    values.delivery.customTo,
    values.delivery.period,
    values,
    setFieldValue,
  ])

  if (values.delivery.frequency !== 'per_annum') {
    return null
  }

  return (
    <FormControl required>
      <FormLabel>Sequence</FormLabel>
      <RadioInputs
        name="delivery.frequencyDistribution"
        options={getCargoesFrequencyOptions({ values })}
      />
    </FormControl>
  )
}

FrequencyDistribution.propTypes = {
  formik: PropTypes.object.isRequired,
}

export default connect(FrequencyDistribution)
