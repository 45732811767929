import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import MenuItem from '@material-ui/core/MenuItem'
import { chain, isEmpty } from 'lodash'
import MuiTextField from '@material-ui/core/TextField'
import { defaultProps } from 'recompose'

import { useApi } from 'containers/api/useApi'
import { useFetch } from 'containers/fetch/useFetch'
import { useUser } from 'containers/user/useUser'
import { isMyCompanyOrder } from 'screens/orderCreateCommon/helpers'
import {
  useOrderFlow,
  ORDER_FLOW,
  useOrderState,
} from 'containers/OrderProvider'

export const TextField = defaultProps({
  fullWidth: true,
  margin: 'normal',
})(MuiTextField)

const useUpdatedValues = (id, url) => {
  const api = useApi()
  const [values, setValues] = useState([])

  useEffect(() => {
    let didCancel = false

    const getValues = async () => {
      let newValues

      if (id) {
        const { data = [] } = await api.get(url)
        newValues = data
      } else {
        newValues = []
      }

      if (!didCancel) {
        setValues(newValues)
      }
    }

    getValues()

    return () => {
      didCancel = true
    }
  }, [api, id, url])

  return values
}

export const TraderEntitiesSelect = connect(({ formik }) => {
  const { flow } = useOrderFlow()
  const { user } = useUser()
  const [companyId, setCompanyId] = useState(formik.values.myCompany.companyId)
  const [entityId, setEntityId] = useState(formik.values.myCompany.entityId)
  const entities = useUpdatedValues(
    companyId,
    `/brokers/${user.id}/entities?companyId=${companyId}`
  )
  const traders = useUpdatedValues(
    entityId,
    `/brokers/${user.id}/persons?entityId=${entityId}`
  )
  const {
    orderState: { hasTermsheets, brokerEditsSharedOrder },
  } = useOrderState()
  const disabled =
    (hasTermsheets && flow === ORDER_FLOW.EDIT) || brokerEditsSharedOrder

  const { data: companies = [] } = useFetch(`/brokers/${user.id}/companies`)

  if (!isMyCompanyOrder(formik.values)) {
    return null
  }

  const updateCompanyId = (newCompanyId) => {
    const newCompany = companies.find(({ id }) => id === newCompanyId)
    setCompanyId(newCompanyId)
    formik.setFieldValue('myCompany.companyId', newCompanyId)
    formik.setFieldValue('myCompany.company', newCompany)
    formik.setFieldValue('company', newCompany)
  }

  const updateEntityId = (newEntityId) => {
    const newEntity = entities.find(({ id }) => id === newEntityId)
    setEntityId(newEntityId)
    formik.setFieldValue('myCompany.entityId', newEntityId)
    formik.setFieldValue('myCompany.entity', newEntity)
    formik.setFieldValue('entity', newEntity)
  }

  const updateTraderId = (newTraderId) => {
    const newTrader = traders.find(({ id }) => id === newTraderId)
    formik.setFieldValue('myCompany.traderId', newTraderId)
    formik.setFieldValue('myCompany.trader', newTrader)
    formik.setFieldValue('person', newTrader)
  }

  const handleCompanyChange = (event) => {
    const newCompanyId = event.target.value

    updateCompanyId(newCompanyId)
    updateEntityId(undefined)
    updateTraderId(undefined)
  }

  const handleEntityChange = (event) => {
    const newEntityId = event.target.value

    updateEntityId(newEntityId)
    updateTraderId(undefined)
  }

  const handleTraderChange = (event) => {
    const newTraderId = event.target.value

    updateTraderId(newTraderId)
  }

  const setValueWhenNotEmpty = (options, key) => {
    return isEmpty(options) ? '' : formik.values.myCompany[key] || ''
  }

  const setError = (key) => {
    return formik.submitCount > 0 && !formik.values.myCompany[key]
  }

  const isDisabled = (options) => {
    return disabled || isEmpty(options)
  }

  return (
    <>
      <TextField
        select
        required
        label="Company"
        InputProps={{ 'data-testid': 'trader-entities-select-company' }}
        value={setValueWhenNotEmpty(companies, 'companyId')}
        onChange={handleCompanyChange}
        disabled={isDisabled(companies)}
        error={setError('companyId')}
      >
        {chain(companies || [])
          .sortBy('name')
          .value()
          .map(({ id, name }) => (
            <MenuItem data-testid="company-dropdown-values" key={id} value={id}>
              {name}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        select
        required
        label="Entity"
        InputProps={{ 'data-testid': 'trader-entities-select-entity' }}
        value={setValueWhenNotEmpty(entities, 'entityId')}
        onChange={handleEntityChange}
        disabled={isDisabled(entities)}
        error={setError('entityId')}
      >
        {chain(entities)
          .sortBy('name')
          .value()
          .map(({ id, name }) => (
            <MenuItem data-testid="entity-dropdown-values" key={id} value={id}>
              {name}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        select
        required
        label="Trader"
        InputProps={{ 'data-testid': 'trader-entities-select-trader' }}
        value={setValueWhenNotEmpty(traders, 'traderId')}
        onChange={handleTraderChange}
        disabled={isDisabled(traders)}
        error={setError('traderId')}
      >
        {chain(traders)
          .sortBy('name')
          .value()
          .map(({ id, name }) => (
            <MenuItem data-testid="trader-dropdown-values" key={id} value={id}>
              {name}
            </MenuItem>
          ))}
      </TextField>
    </>
  )
})

TraderEntitiesSelect.defaultProps = {
  formik: {},
}

TraderEntitiesSelect.propTypes = {
  formik: PropTypes.object,
}
