import React, { useState } from 'react'
import { connect } from 'formik'
import { get } from 'lodash'
import { 
  DELIVERED_BY_KEY,
  DELIVERED_BY, 
  DELIVERED_BY_PREFIX,
  DELIVERED_BY_FIX_PREFIX,
  DELIVERED_BY_AFTER_DAYS_PREFIX } from 'emsurge-selectors'
import { RadioButtonGroup } from './RadioButtonGroup'
import { DateField } from 'components/DateTime/DateTime.fields'
import { TextField } from 'screens/orderCreateCommon/components/TextField'

const _Delivery = ({ formik }) => {

  const { values, setFieldValue } = formik
  const selectedDeliveredBy = get(values, DELIVERED_BY_PREFIX)
  const [deliveredByOption, setSelectedDeliveredBy] = useState(selectedDeliveredBy)

  const onChange = (deliveredByOptionNewValue) => {
    setFieldValue(DELIVERED_BY_PREFIX, deliveredByOptionNewValue)
    setSelectedDeliveredBy(deliveredByOptionNewValue)
  }

  return (
    <>
      <RadioButtonGroup
        title="Delivered By"
        options={DELIVERED_BY}
        fieldName={DELIVERED_BY_PREFIX}
        onChangeRadioButton={onChange}
      />
      {deliveredByOption === DELIVERED_BY_KEY.FIXED && <>
        <DateField
          value={get(values, DELIVERED_BY_FIX_PREFIX)}
          onChange={(value) => value && setFieldValue(DELIVERED_BY_FIX_PREFIX, value)}
        />
      </>}
      {deliveredByOption === DELIVERED_BY_KEY.DAYS_AFTER && <>
        <TextField
          type="number"
          name={DELIVERED_BY_AFTER_DAYS_PREFIX}
          label="Days after the Point of Trade"
        />
      </>}
    </>
  )
}

_Delivery.defaultProps = {
}

_Delivery.propTypes = {
}

export const Delivery = connect(_Delivery)