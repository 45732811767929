import React from 'react'
import { connect } from 'formik'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'

import { $O } from 'emsurge-selectors'
import styled from 'styled-components'
import { Unit } from './Unit'
import { Type } from './Type'
import { Party } from './Party'
import { CargoSizeFields } from 'screens/orderCreate/components/CargoSizeFields'
import { VarianceFields } from 'screens/orderCreate/components/VarianceFields'
import FieldCard from 'screens/orderCreateCommon/components/FieldCard'

const ToleranceText = styled(Text)`
  min-height: 30px;
`

export const CargoSize = connect(({ formik }) => {
  const tolerance = $O.cargoSize.tolerance.text(formik.values)

  return (
    <>
      <Unit />
      <Type />
      <CargoSizeFields />

      <Party />

      <Grid container spacing={1}>
        <VarianceFields />
        <Grid item xs>
          <FieldCard title="Operational tolerance">
            <ToleranceText noWrap>{tolerance}</ToleranceText>
          </FieldCard>
        </Grid>
      </Grid>
    </>
  )
})
