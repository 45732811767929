import { isString, isObject, isEmpty } from 'lodash'

const hasSwapLeg = (order) => {
  const hasId = isString(order.swapId) && !isEmpty(order.swapId)
  const hasSwapOrder = isObject(order.swapOrder) && !isEmpty(order.swapOrder)

  return hasId || hasSwapOrder
}

const getSwapLeg = (order) => {
  if (!hasSwapLeg(order)) {
    throw new Error('Order has no swap leg')
  }

  return order.swapOrder || { id: order.swapId }
}

export const swapLeg = {
  exists: hasSwapLeg,
  get: getSwapLeg,
}
