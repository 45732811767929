const CARBON_PERIOD = Object.freeze({
  CUSTOM: 'custom',
  Y_MINUS_1: 'Y-1',
  CAL_YEAR: 'year',
  Q1: 'q1',
  Q2: 'q2',
  Q3: 'q3',
  Q4: 'q4',
  JAN: 'january',
  FEB: 'february',
  MAR: 'march',
  APR: 'april',
  MAY: 'may',
  JUN: 'june',
  JUL: 'july',
  AUG: 'august',
  SEP: 'september',
  OCT: 'october',
  NOV: 'november',
  DEC: 'december',
})

const CARBON_PERIOD_LABELS_QUARTERS_KEYS = [
  CARBON_PERIOD.Q1,
  CARBON_PERIOD.Q2,
  CARBON_PERIOD.Q3,
  CARBON_PERIOD.Q4,
]

const CARBON_PERIOD_LABELS_QUARTERS = Object.freeze({
  [CARBON_PERIOD.Q1]: 'Q1',
  [CARBON_PERIOD.Q2]: 'Q2',
  [CARBON_PERIOD.Q3]: 'Q3',
  [CARBON_PERIOD.Q4]: 'Q4',
})

const CARBON_PERIOD_MONTHS_BY_QUARTERS = Object.freeze({
  [CARBON_PERIOD.Q1]: [CARBON_PERIOD.JAN, CARBON_PERIOD.FEB, CARBON_PERIOD.MAR],
  [CARBON_PERIOD.Q2]: [CARBON_PERIOD.APR, CARBON_PERIOD.MAY, CARBON_PERIOD.JUN],
  [CARBON_PERIOD.Q3]: [CARBON_PERIOD.JUL, CARBON_PERIOD.AUG, CARBON_PERIOD.SEP],
  [CARBON_PERIOD.Q4]: [CARBON_PERIOD.OCT, CARBON_PERIOD.NOV, CARBON_PERIOD.DEC],
})

const CARBON_PERIOD_LABELS_MONTHS_KEYS = [
  CARBON_PERIOD.JAN,
  CARBON_PERIOD.FEB,
  CARBON_PERIOD.MAR,
  CARBON_PERIOD.APR,
  CARBON_PERIOD.MAY,
  CARBON_PERIOD.JUN,
  CARBON_PERIOD.JUL,
  CARBON_PERIOD.AUG,
  CARBON_PERIOD.SEP,
  CARBON_PERIOD.OCT,
  CARBON_PERIOD.NOV,
  CARBON_PERIOD.DEC,
]

const CARBON_PERIOD_LABELS_MONTHS = Object.freeze({
  [CARBON_PERIOD.JAN]: 'January',
  [CARBON_PERIOD.FEB]: 'February',
  [CARBON_PERIOD.MAR]: 'March',
  [CARBON_PERIOD.APR]: 'April',
  [CARBON_PERIOD.MAY]: 'May',
  [CARBON_PERIOD.JUN]: 'June',
  [CARBON_PERIOD.JUL]: 'July',
  [CARBON_PERIOD.AUG]: 'August',
  [CARBON_PERIOD.SEP]: 'September',
  [CARBON_PERIOD.OCT]: 'October',
  [CARBON_PERIOD.NOV]: 'November',
  [CARBON_PERIOD.DEC]: 'December',
})

const CARBON_PERIOD_OPTIONS = Object.freeze({
  [CARBON_PERIOD.CUSTOM]: 'Custom',
  [CARBON_PERIOD.CAL_YEAR]: 'CAL-xx',
  ...CARBON_PERIOD_LABELS_QUARTERS,
})

const CARBON_PERIOD_TERM_OPTIONS = Object.freeze({
  [CARBON_PERIOD.Y_MINUS_1]: 'Y-1',
  [CARBON_PERIOD.CUSTOM]: 'Custom',
  [CARBON_PERIOD.CAL_YEAR]: 'CAL-xx',
  ...CARBON_PERIOD_LABELS_QUARTERS,
})

const CARBON_PERIOD_LABELS = Object.freeze({
  ...CARBON_PERIOD_OPTIONS,
  ...CARBON_PERIOD_LABELS_MONTHS,
})

const CARBON_PERIOD_ORDER = [
  CARBON_PERIOD.CAL_YEAR,
  CARBON_PERIOD.CUSTOM,
  ...CARBON_PERIOD_LABELS_QUARTERS_KEYS,
  ...CARBON_PERIOD_LABELS_MONTHS_KEYS,
]

const periodIsCustom = (period) => period === CARBON_PERIOD.CUSTOM
const periodIsCalYear = (period) => period === CARBON_PERIOD.CAL_YEAR
const periodIsQuarter = (period) =>
  CARBON_PERIOD_LABELS_QUARTERS_KEYS.includes(period)
const periodIsMonth = (period) =>
  CARBON_PERIOD_LABELS_MONTHS_KEYS.includes(period)
const periodIsYMinus1 = (period) => period === CARBON_PERIOD.Y_MINUS_1

module.exports = {
  CARBON_PERIOD,
  CARBON_PERIOD_ORDER,
  CARBON_PERIOD_OPTIONS,
  CARBON_PERIOD_TERM_OPTIONS,
  CARBON_PERIOD_LABELS,
  CARBON_PERIOD_LABELS_MONTHS_KEYS,
  CARBON_PERIOD_MONTHS_BY_QUARTERS,
  periodIsCustom,
  periodIsCalYear,
  periodIsQuarter,
  periodIsMonth,
  periodIsYMinus1
}
