import PropTypes from 'prop-types'
import React from 'react'
import { $T } from 'emsurge-selectors/models'
import { isEmpty } from 'lodash'
import { useTheme } from '@material-ui/styles'
import { Hidden, Grid, Typography as Text } from '@material-ui/core'
import DetailDivider from './DetailDivider'
import { StyledGrid } from './StyledGrid'
import MultilineText from 'components/MultilineText/MultilineText'

const OtherNominationItem = ({
  title,
  description,
  nominationSentence,
  gutterBottom,
  onClick,
  additionalInfo,
  ...textProps
}) => {
  const { spacing } = useTheme()
  return (
    <>
      <StyledGrid onClick={onClick} container>
        <Grid item xs={12} sm={3}>
          <Text
            {...textProps}
            data-testid={
              textProps?.['data-testid'] && `${textProps['data-testid']}-title`
            }
            variant="body2"
            gutterBottom={gutterBottom}
            style={{
              fontWeight: 700,
            }}
          >
            {title}
          </Text>
        </Grid>

        <Grid item xs={12} sm={7}>
          <MultilineText
            gutterBottom={gutterBottom}
            data-testid={
              textProps?.['data-testid'] &&
              `${textProps['data-testid']}-description`
            }
          >
            {description}
          </MultilineText>
        </Grid>
        <Hidden xsDown>
          {additionalInfo &&
            !isEmpty(additionalInfo) &&
            additionalInfo.map((text, idx) => (
              <Grid
                key={`other-nominations-additional-info-container-${idx}`}
                item
                xs={12}
                sm={2}
              >
                <Text
                  key={`other-nominations-additional-info-${idx}`}
                  variant="body1"
                  {...textProps}
                  data-testid={`other-nominations-additional-info-${idx}`}
                  style={{ fontSize: 14, paddingLeft: spacing(2) }}
                >
                  {text}
                </Text>
              </Grid>
            ))}
        </Hidden>
      </StyledGrid>
      <StyledGrid container>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={7}>
          <Text
            variant="body1"
            gutterBottom={gutterBottom}
            {...textProps}
            data-testid={
              textProps?.['data-testid'] &&
              `${textProps['data-testid']}-nomination`
            }
          >
            {nominationSentence}
          </Text>
        </Grid>
      </StyledGrid>
    </>
  )
}

OtherNominationItem.defaultProps = {
  description: '',
  additionalInfo: [],
}

OtherNominationItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  nominationSentence: PropTypes.string.isRequired,
  gutterBottom: PropTypes.bool,
  onClick: PropTypes.func,
  additionalInfo: PropTypes.array,
}

const OtherNominationsPort = ({
  nominationRules,
  onClick,
  additionalInfo,
  ...textProps
}) => {
  const otherTermsText = $T.other.text(nominationRules)

  if (!otherTermsText.length) {
    return null
  }

  return (
    <>
      <DetailDivider />
      {otherTermsText.map((otherTerm, index) => {
        const { name, description, nomination } = otherTerm

        return (
          <OtherNominationItem
            onClick={onClick}
            key={index}
            title={name || `Other Nominations (${index})`}
            description={description}
            nominationSentence={nomination || ''}
            additionalInfo={[additionalInfo?.[index]]}
            {...textProps}
          />
        )
      })}
    </>
  )
}

OtherNominationsPort.propTypes = {
  nominationRules: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  additionalInfo: PropTypes.array,
}

export default OtherNominationsPort
