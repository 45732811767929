import moment from 'moment'
import { map, sortBy, groupBy, pipe } from 'lodash/fp'
import { $OC, APP_CONTEXT_TYPE } from 'emsurge-selectors'
import { isCustomDeliveryPeriod } from 'screens/orderCreate/components/DeliveryWindow.helpers'
import { PERIOD_LOOKUP } from 'components/DeliveryContract'

const PERIODS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'q1',
  'q2',
  'q3',
  'q4',
  'summer',
  'winter',
  'year',
  'gas_year',
]

const getOrderPeriodLabel = (periodFrom, yearFrom, periodTo, yearTo) => {
  const isCutomPeriod = periodFrom !== periodTo || yearFrom !== yearTo

  const formatPeriod = (period) => PERIOD_LOOKUP[period]
  const formatYear = (year) => String(year).slice(-2)

  if (isCutomPeriod) {
    const date = `${formatPeriod(periodFrom)}${formatYear(
      yearFrom
    )} – ${formatPeriod(periodTo)}${formatYear(yearTo)}`

    return date
  }

  const period = periodFrom
  const year = yearFrom

  return `${formatPeriod(period)}${formatYear(year)}`
}

const getOrderPeriod = (order) => {
  let yearFrom, periodFrom, yearTo, periodTo

  if (isCustomDeliveryPeriod(order.delivery.period)) {
    const from = moment(order.delivery.customFrom)
    const to = moment(order.delivery.customTo)

    yearFrom = from.year()
    periodFrom = PERIODS[from.month()]
    yearTo = to.year()
    periodTo = PERIODS[to.month()]
  } else {
    yearFrom = parseInt(order.delivery.year, 10)
    periodFrom = order.delivery.period
    yearTo = yearFrom
    periodTo = periodFrom
  }

  return { yearFrom, periodFrom, yearTo, periodTo }
}

const groupByDateTypeLocation = (order) => {
  const { type, location } = order.delivery
  const { yearFrom, periodFrom, yearTo, periodTo } = getOrderPeriod(order)

  return [periodFrom, yearFrom, periodTo, yearTo, type, location]
}

const groupByTypeLocationDate = (order) => {
  const { type, location } = order.delivery
  const { yearFrom, periodFrom, yearTo, periodTo } = getOrderPeriod(order)

  return [periodFrom, yearFrom, periodTo, yearTo, type, location]
}

const getOrderPeriodCarbon = (order) => {
  const { yearFrom, yearTo, monthFrom, monthTo, periodIndex, sortOrder } =
    $OC.termVintage.spotTerm.vintage(order)
  return {
    yearFrom,
    periodFrom: monthFrom,
    yearTo,
    periodTo: monthTo,
    periodIndex,
    sortOrder,
  }
}

const groupByVintage = (order) => {
  const { yearFrom, periodFrom, yearTo, periodTo, periodIndex, sortOrder } =
    getOrderPeriodCarbon(order)
  return [periodFrom, yearFrom, periodTo, yearTo, periodIndex, sortOrder]
}

export const getOrdersByDate = pipe(
  groupBy(groupByDateTypeLocation),
  Object.entries,
  map(([group, orders]) => {
    const [periodFrom, yearFrom, periodTo, yearTo, type, location] =
      group.split(',')

    return {
      type,
      location,
      periodFrom,
      periodTo,
      orders,
      yearFrom: parseInt(yearFrom, 10),
      yearTo: parseInt(yearTo, 10),
      periodFromIdx: PERIODS.findIndex((p) => p === periodFrom),
      periodToIdx: PERIODS.findIndex((p) => p === periodTo),
    }
  }),
  sortBy(['yearFrom', 'periodFromIdx', 'type', 'location']),
  map(({ type, location, periodFrom, periodTo, yearFrom, yearTo, orders }) => {
    const date = getOrderPeriodLabel(periodFrom, yearFrom, periodTo, yearTo)

    return [`${date} (${type} ${location})`, orders]
  })
)

const getOrdersByLocation = pipe(
  groupBy(groupByTypeLocationDate),
  Object.entries,
  map(([group, orders]) => {
    const [periodFrom, yearFrom, periodTo, yearTo, type, location] =
      group.split(',')

    return {
      type,
      location,
      periodFrom,
      periodTo,
      orders,
      yearFrom: parseInt(yearFrom, 10),
      yearTo: parseInt(yearTo, 10),
      periodFromIdx: PERIODS.findIndex((p) => p === periodFrom),
      periodToIdx: PERIODS.findIndex((p) => p === periodTo),
    }
  }),
  sortBy(['type', 'location', 'yearFrom', 'periodFromIdx']),
  map(({ type, location, periodFrom, periodTo, yearFrom, yearTo, orders }) => {
    const date = getOrderPeriodLabel(periodFrom, yearFrom, periodTo, yearTo)

    return [`${type} ${location} (${date})`, orders]
  })
)

const getOrdersByVintage = pipe(
  groupBy(groupByVintage),
  Object.entries,
  map(([group, orders]) => {
    const [periodFrom, yearFrom, periodTo, yearTo, periodIndex, sortOrder] =
      group.split(',')

    return {
      orders,
      yearFrom,
      yearTo,
      periodFrom,
      periodTo,
      periodIndex,
      sortOrder,
    }
  }),
  sortBy(['sortOrder', 'yearFrom', 'periodIndex']),
  map(({ orders = [] }) => {
    const mappingLabel = {
      INVESTMENT: 'Investment',
      TERM: 'Term',
    }
    const firstOrderByGroup = orders[0] || {}
    const { termSpot, fixedRolling } = firstOrderByGroup.termVintage
    const { vintage } = $OC.termVintage.spotTerm.text(firstOrderByGroup)
    const groupText =
      termSpot === 'INVESTMENT' ||
      (termSpot === 'TERM' && fixedRolling === 'ROLLING')
        ? mappingLabel[termSpot]
        : vintage
    const prefixText =
      termSpot === 'TERM' && fixedRolling === 'FIXED' ? mappingLabel.TERM : ''
    return [[prefixText, groupText].filter((txt) => txt).join(' '), orders]
  })
)

export const sortOrders = (
  orders,
  sortBy,
  appContext = APP_CONTEXT_TYPE.LNG
) => {
  const sorts = {
    [APP_CONTEXT_TYPE.LNG]: {
      date: getOrdersByDate,
      location: getOrdersByLocation,
    },
    [APP_CONTEXT_TYPE.CARBON]: {
      vintage: getOrdersByVintage,
    },
  }
  const sortContext = sorts[appContext] || sorts[APP_CONTEXT_TYPE.LNG]
  const sortByFN = sortContext[sortBy]
  if (!sortByFN) {
    throw new Error('Unknown `Sort by` option')
  }
  return sortByFN(orders)
}
