import React from 'react'
import PropTypes from 'prop-types'
import { Typography as Text } from '@material-ui/core'
import {
  PRICE_UNIT,
  PRICE_UNIT_TEXT,
  TRADING_TYPE_TEXT,
} from 'emsurge-selectors'
import { Card } from 'components/Card'
import { Row } from 'components/Row'
import { getTimeAgo } from 'components/TimeAgo/TimeAgo.helpers'
import { SmallText } from 'components/Text'

export const EvoCard = ({
  tradingType = '',
  market = '',
  lastUpdate,
  period,
  price,
}) => {
  const formatter = PRICE_UNIT_TEXT[PRICE_UNIT.MMBTU]
  return (
    <Card style={{ minWidth: '300px', background: '#161d29' }}>
      <Row>
        <Text variant="subtitle2">
          {TRADING_TYPE_TEXT[tradingType]?.toUpperCase()}
        </Text>
        <SmallText>{getTimeAgo(lastUpdate)}</SmallText>
      </Row>
      <Row>
        <SmallText>{period}</SmallText>
        <SmallText>{`${market.toUpperCase()} [Evo]`}</SmallText>
        <SmallText>{formatter(price)}</SmallText>
      </Row>
    </Card>
  )
}

EvoCard.propTypes = {
  tradingType: PropTypes.string,
  market: PropTypes.string,
  lastUpdate: PropTypes.string,
  period: PropTypes.string,
  price: PropTypes.number,
}
