import { partial } from 'lodash'
import * as Yup from 'yup'
import { useApi } from 'containers/api/useApi'

const validateEmail = async (api, currentEmail, email) => {
  if (email === currentEmail) {
    return true
  }

  const { data: isEmailTaken } = await api.get(`persons/email/${email}`, {
    auth: false,
  })

  return !isEmailTaken
}

const isEmailUnique = function (api, currentEmail) {
  return this.test(
    'is-email-unique',
    'This email is already taken',
    async (email) => {
      return validateEmail(api, currentEmail, email)
    }
  )
}

const isCompanyCodeUnique = function (api, currentCode) {
  return this.test(
    'is-company-code-unique',
    'This company code is already taken',
    async (code) => {
      if (code && code.length === 4) {
        code = code.toUpperCase()

        if (currentCode && code === currentCode) {
          return true
        }

        const { data: isCodeTaken } = await api.get(`companies/code/${code}`, {
          auth: false,
        })

        return !isCodeTaken
      }

      return true
    }
  )
}

export const AddCustomValidations = () => {
  const api = useApi()

  Yup.addMethod(Yup.string, 'emailIsUnique', partial(isEmailUnique, api))
  Yup.addMethod(
    Yup.string,
    'companyCodeIsUnique',
    partial(isCompanyCodeUnique, api)
  )

  return null
}
