import React from 'react'
import PropTypes from 'prop-types'
import CompanyInformation from './components/CompanyInformation'
import EntityInformation from './components/EntityInformation'
import Fetch from 'containers/fetchPlus/FetchPlus'
import withParams from 'utils/withRouteParams'

const ViewCompanyRequest = ({ id }) => (
  <Fetch url={`/companies/${id}`}>
    {(company) => (
      <>
        <CompanyInformation company={company} />
        <EntityInformation entities={company.entities} />
      </>
    )}
  </Fetch>
)

ViewCompanyRequest.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withParams(ViewCompanyRequest)
