import { useQuery, useQueryClient } from 'react-query'
import { MARKET_GRID_MARKET_PROVIDER } from 'emsurge-selectors'
import { flatten, isEmpty } from 'lodash'
import { useApi } from 'containers/api/useApi'
import { MARKET_GRID, ORDER_KEY } from 'api/constants'

export const useGridPrice = (id) => {
  const api = useApi()
  const queryClient = useQueryClient()

  const onSuccess = ({ data = [] }) => {
    const mkts = data.reduce(
      (dataAcc, dataCurr) => [
        ...dataAcc,
        ...dataCurr.markets.reduce((mktAcc, mktCurr) => {
          if (mktCurr.type === MARKET_GRID_MARKET_PROVIDER.EMSURGE) {
            return [
              ...mktAcc,
              ...mktCurr.columns.reduce(
                (colAcc, collCurr) => [...colAcc, ...collCurr.values],
                []
              ),
            ]
          }
          return mktAcc
        }, []),
      ],
      []
    )

    const orders = flatten(mkts).filter((value) => !isEmpty(value))
    orders.forEach((order) =>
      queryClient.setQueryData([ORDER_KEY, order.id], order)
    )
  }

  return useQuery(id && [MARKET_GRID, id], () => api.getGridPrices(id), {
    onSuccess,
    retry: false,
    refetchInterval: 3000,
    refetchIntervalInBackground: 30000,
  })
}
