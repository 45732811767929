import PropTypes from 'prop-types'
import { connect } from 'formik'

import { PARTY, PARTY_TEXT } from 'emsurge-selectors/constants'
import { getCargoSizeVariance } from 'screens/orderCreate/helpers'

const _SelectCargoSizeParty = ({ formik, children }) => {
  const onChange = (value) => {
    const variance = getCargoSizeVariance(value)

    formik.setFieldValue('cargoSize.party', value)
    formik.setFieldValue('cargoSize.varianceMinus', variance.varianceMinus)
    formik.setFieldValue('cargoSize.variancePlus', variance.variancePlus)
  }

  const options = [
    {
      value: PARTY.BUYER,
      label: PARTY_TEXT[PARTY.BUYER],
    },
    {
      value: PARTY.SELLER,
      label: PARTY_TEXT[PARTY.SELLER],
    },
    {
      value: PARTY.TBA,
      label: PARTY_TEXT[PARTY.TBA],
    },
  ]

  return children({ options, onChange })
}

_SelectCargoSizeParty.propTypes = {
  formik: PropTypes.object.isRequired,
  children: PropTypes.func,
}

export const SelectCargoSizeParty = connect(_SelectCargoSizeParty)
