import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import { isEmpty } from 'lodash'
import { $O as Order } from 'emsurge-selectors'
import OrderType from './OrderType'
import Person from './Person'
import OrderStatusSwitch from './OrderStatusSwitch'
import { SwapLegContainer } from './SwapLegContainer'
import { CardIcons } from './MarketInterest/CardIcons'
import { MainPrice } from 'components/MainPrice'
import { DeliveryContract } from 'components/DeliveryContract'
import { DeliveryInfo } from 'components/DeliveryInfo'
import { Card, Container } from 'components/Card'
import { Row } from 'components/Row'
import { $O } from 'model/order/selectors/selector'
import CompanyCode from 'components/CompanyCode/CompanyCode'
import { StyledLink as Link } from 'components/StyledLink'

const getProps = (showFixedPriceCalculated) => ({
  'data-testid': showFixedPriceCalculated
    ? 'order-card-fixed-price'
    : 'order-card-main-price',
  fixed: !!showFixedPriceCalculated,
})

const getPriceProps = (order) => {
  const { fixed: showFixedPriceCalculated } = Order.price.summary(order)
  const firstLinePriceProps = getProps(showFixedPriceCalculated)
  const secondLinePriceProps = showFixedPriceCalculated ? getProps(false) : {}
  return { firstLinePriceProps, secondLinePriceProps }
}

const OrderCard = ({ order, style, toggle }) => {
  const { spacing } = useTheme()
  const gutterR = { marginRight: spacing(1.5) }
  const isThereSwapleg = $O.swapLeg.exists(order)
  const { firstLinePriceProps, secondLinePriceProps } = getPriceProps(order)

  return (
    <Link to={(location) => `${location.pathname}/orders/${order.id}`}>
      <Card
        style={style}
        id={`my-order-${order.id}`}
        data-cy="orders-panel-order-cards"
        data-testid={`my-order-${order.id}`}
      >
        <Row direction={'row'}>
          <Person data-testid="order-card-author" person={order.person} />
          {isThereSwapleg && (
            <SwapLegContainer
              size={14}
              order={order}
              style={{ flexDirection: 'row' }}
            />
          )}
        </Row>

        <Container justifyContent="space-between">
          <Container style={{ width: 'auto' }}>
            <CompanyCode
              data-testid="order-card-company-code"
              order={order}
              style={gutterR}
            />
            <OrderType data-testid="order-card-order-type" order={order} />
          </Container>

          <Container style={{ width: 'auto' }}>
            <CardIcons order={order} />
          </Container>
        </Container>

        {/* ROW 2 */}
        <Container justifyContent="space-between">
          <Container style={{ flex: 1, maxWidth: '80%' }}>
            {!isThereSwapleg && (
              <DeliveryInfo
                data-testid="order-card-delivery-info"
                order={order}
                style={gutterR}
              />
            )}
            {!isThereSwapleg && (
              <DeliveryContract
                data-testid="order-card-delivery-contract"
                order={order}
                style={gutterR}
              />
            )}
            <MainPrice order={order} {...firstLinePriceProps} />
          </Container>

          {toggle && (
            <OrderStatusSwitch
              data-testid="order-status-switch"
              order={order}
            />
          )}
        </Container>
        {!isEmpty(secondLinePriceProps) && (
          <Container justifyContent="space-between">
            <MainPrice order={order} {...secondLinePriceProps} />
          </Container>
        )}
      </Card>
    </Link>
  )
}

OrderCard.defaultProps = {
  toggle: true,
}

OrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  style: PropTypes.object,
  toggle: PropTypes.bool,
}

export default memo(OrderCard)
