import {
  ADMIN_VIEW_COMPANY_REQUEST,
  ADMIN_VIEW_COMPANY,
  ADMIN_EDIT_COMPANY,
} from 'routes'

const getCompanyLink = (id, route) => route.replace(':id', id)

const getViewCompanyRequestLink = ({ id }) =>
  getCompanyLink(id, ADMIN_VIEW_COMPANY_REQUEST)

const getViewCompanyLink = ({ id }) => getCompanyLink(id, ADMIN_VIEW_COMPANY)

const getEditCompanyLink = ({ id }) => getCompanyLink(id, ADMIN_EDIT_COMPANY)

export { getViewCompanyLink, getViewCompanyRequestLink, getEditCompanyLink }
