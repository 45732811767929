import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useSnackbar } from 'notistack'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import LayoutWrapper from 'screens/companyRegister/components/LayoutWrapper'
import CompanyForm from 'screens/companyCreate/companyForm/CompanyForm'
import { BrandLogoHex } from 'components/logo'
import Footer from 'components/main/Footer'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { HOME } from 'routes'

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    marginTop: theme.spacing(4),
    flexWrap: 'nowrap',
  },
}))

const RegisterCompany = ({ location, history }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()

  const handleFinish = async ({ company, companyAdmin, entity }) => {
    const { token } = queryString.parse(location.search)
    const data = { companyAdmin, company, entity, token }

    try {
      await api.post('/registration', data, { auth: false })
      enqueueSnackbar('Registration successful', { variant: 'success' })
      history.push(HOME)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Registration not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <>
      <LayoutWrapper>
        <CompanyForm
          title="Register Company"
          subTitle="Register your information to add your company to the system"
          onSubmit={handleFinish}
        />
        <Grid
          container
          justifyContent="center"
          className={classes.logoContainer}
        >
          <BrandLogoHex />
        </Grid>
      </LayoutWrapper>
      <Footer />
    </>
  )
}

RegisterCompany.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default RegisterCompany
