import React from 'react'
import { connect } from 'formik'
import PropTypes from 'prop-types'
import { TERM_SPOT, TERM_SPOT_PREFIX } from 'emsurge-selectors'
import { RadioButtonGroup } from './RadioButtonGroup'

export const _TermVintageRadio = ({ onChange }) => {
  return (
    <RadioButtonGroup
      title="Term"
      options={TERM_SPOT}
      fieldName={TERM_SPOT_PREFIX}
      onChangeRadioButton={onChange}
    />
  )
}

_TermVintageRadio.defaultProps = {
  onChange: () => {},
}

_TermVintageRadio.propTypes = {
  onChange: PropTypes.func,
}

export const TermVintageRadio = connect(_TermVintageRadio)
