import {
  ADDITIONAL_ATTRIBUTES_SDGS_PREFIX,
  ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX,
  PROJECT_PATH,
  VENUES,
  VENUE_OPTIONS_PREFIX,
  ALTERNATIVES_PREFIX,
  REGION_OPTIONS_PREFIX,
  REGION_TYPE_KEYS,
  REGIONS_KEYS,
  COUNTRIES_KEYS,
  COUNTRIES_VALUES,
  REGION_COUNTRIES_PREFIX,
  PROJECT_SECTORAL_SCOPE_CHECKBOX,
  PROJECT_SECTORAL_SCOPE_OPTIONS,
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS,
  PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS,
  ADDITIONAL_ATTRIBUTES,
  RATING_PREFIX,
  RATING_VALUES,
  PROJECT_KEYWORD_OPTIONS,
  SDGS_KEYS,
  SDGS_OPTIONS,
} from 'emsurge-selectors'
import { set } from 'lodash'
import { populateCheckboxGroupInitialValues } from '../orderCreateCarbon/components/CheckboxGroup'

export const getStep6 = (order) => {
  const initialValues = {}
  if(order.regions.type === REGION_TYPE_KEYS.ALL){
      Object.keys(REGIONS_KEYS).forEach(x => {
        set(
          initialValues,
          `${REGION_OPTIONS_PREFIX}.${x}`,
          true,
        )
      })
    }
    else if (order.regions.type === REGION_TYPE_KEYS.SPECIFIC) {
      Object.keys(REGIONS_KEYS).forEach(x => {
        if(order.regions.options && order.regions.options.includes(x)) {
          set(
            initialValues,
            `${REGION_OPTIONS_PREFIX}.${x}`,
            true,
          )
        }
      })
    } 
  const ldcsOnly = order.regions.ldcsOnly
  const countries = Object.keys(COUNTRIES_KEYS).filter(x => order.regions.countries && order.regions.countries.includes(x))
  .map(x => ({
    key: x,
    value: COUNTRIES_VALUES[x],
    focus: ldcsOnly
  }))
  set(
    initialValues,
    REGION_COUNTRIES_PREFIX,
    countries,
  )
  return initialValues
}

export const getStep7 = (order) => {
  const initialValues = {}
  set(
    initialValues,
    `${PROJECT_SECTORAL_SCOPE_CHECKBOX}.ALL`,
    order.projectSpecification.selectedScopes.all,
  )

  const sectoralScopes = Object.keys(PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS_KEYS)
  .filter(x => order.projectSpecification.sectoralScopes && order.projectSpecification.sectoralScopes.includes(x))
  .map( x => ({
    key: x,
    value: PROJECT_SPECIFICATION_SECTORAL_SCOPE_OPTIONS[x]
  }))
  set(
    initialValues,
    PROJECT_SECTORAL_SCOPE_OPTIONS,
    sectoralScopes,
  )

  const keywordOptions = order.projectSpecification.keywords
  ?.map( x => ({
    key: x,
    value: x
  }))
  set(
    initialValues,
    PROJECT_KEYWORD_OPTIONS,
    keywordOptions,
  )

  const projects = order.projectSpecification.projects
  .map( x => ({
    sectoralScope: x.sectoralScope,
    storageType : x.storageType,
    referenceNr: x.referenceNr,
    name: x.name,
    startDate: x.startDate,
    url: x.url
  }))
  set(initialValues, PROJECT_PATH, projects)
  return initialValues
}

export const getStep8 = (order) => {
  const initialValues = {}
  populateCheckboxGroupInitialValues(initialValues, ADDITIONAL_ATTRIBUTES, order.additionalAttributes.options, ADDITIONAL_ATTRIBUTES_OPTIONS_PREFIX, order.additionalAttributes.otherAdditionalAttributes)
  populateCheckboxGroupInitialValues(initialValues, RATING_VALUES, order.carbonType.rating, RATING_PREFIX, order.carbonType.otherRating)  
  set(initialValues, ADDITIONAL_ATTRIBUTES_SDGS_PREFIX, [])
  const sdgs = Object.keys(SDGS_KEYS)
  .filter(x => order.additionalAttributes.sdgs && order.additionalAttributes.sdgs.includes(x))
  .map( x => ({
    key: x,
    value: SDGS_OPTIONS[x]
  }))
  set(
    initialValues,
    ADDITIONAL_ATTRIBUTES_SDGS_PREFIX,
    sdgs,
  )

  return initialValues
}

export const getStep9 = (order) => {
  const initialValues = {}
  populateCheckboxGroupInitialValues(initialValues, VENUES, order.venue.options, VENUE_OPTIONS_PREFIX)
  return initialValues
}

export const getStep10 = (order) => {
  const initialValues = {}
  set(initialValues, ALTERNATIVES_PREFIX, order.alternatives.otherTerms)
  return initialValues
}

export const getOptionalEditValues = (order) => {
  return {
    ...getStep6(order),
    ...getStep7(order),
    ...getStep8(order),
    ...getStep9(order),
    ...getStep10(order),
  }
}