import flatten from 'flat'
import moment from 'moment'
import { isEmpty, get } from 'lodash'
import { formatDateRanges } from '../components/DeliveryWindow.helpers'
import { getCargoesDateRanges } from '../components/DeliveryWindowCargoes.helpers'
import { isFOBType } from '../helpers'
import { STEP2 as DELIVERY_INITIAL_VALUES } from './informationInitialValues'

const isUndefinedTimeFrameValue = ({ timeframe }) => timeframe === 'point_trade'
const isFixedDateTimeFrameValue = ({ timeframe }) => timeframe === 'fixed_date'
const isMonthAheadTimeFrameValue = ({ timeframe }) =>
  timeframe === 'month_ahead'
const isDaysAfterTimeFrameValue = ({ timeframe }) => timeframe === 'days_after'
const isDaysAheadTimeFrameValue = ({ timeframe }) => timeframe === 'days_ahead'
const toBeNominated = ({ toBeNominated }) => toBeNominated

/* :: (object, sring) -> ?string */
const getTimeFrameValue = (nomination, timezone) => {
  const { timeframeValue, timeframeValueTime } = nomination

  if (isUndefinedTimeFrameValue(nomination)) {
    return undefined
  }

  if (isFixedDateTimeFrameValue(nomination)) {
    const input = `${timeframeValue.replace('/')} ${timeframeValueTime}`
    return moment(input, 'YYYY-MM-DD HH:mm')
      .subtract(timezone, 'hours')
      .format('YYYY-MM-DD HH:mm')
  }

  return timeframeValue
}

const cleanToBeNominated = (nomination, timezone) => {
  const parsedNomination = {
    name: isEmpty(nomination.name) ? undefined : nomination.name,
    description: isEmpty(nomination.description)
      ? undefined
      : nomination.description,
    toBeNominated: nomination.toBeNominated,
  }

  if (nomination.toBeNominated) {
    return {
      ...parsedNomination,
      party: nomination.party === 'tba' ? undefined : nomination.party,
      timeframe: nomination.timeframe,
      timeframeValue: getTimeFrameValue(nomination, timezone),
      timeframeValueTime: undefined,
      timeframeValueDeliveryWindow: isDaysAheadTimeFrameValue(nomination)
        ? nomination.timeframeValueDeliveryWindow
        : undefined,
    }
  }

  return {
    ...parsedNomination,
    party: undefined,
    timeframe: undefined,
    timeframeValue: undefined,
    timeframeValueTime: undefined,
  }
}

// DELIVERY WINDOW

const DELIVERY_WINDOW_DEFAULT_VALUES = {
  timezone: '0',
  deliveryType: 'pro_rata',
  arrival: {
    period: '00:00',
    window: 24,
  },
  alternatives: [],
}

const getDeliveryWindowNominations = ({
  deliveryWindow: {
    from,
    to,
    timezone,
    arrival,
    cargos = [],
    alternatives = [],
    deliveryType,
  },
}) => ({
  from,
  to,
  arrival,
  cargos: cargos.map(({ cargoWindow, from, to }) => ({
    cargoWindow,
    from,
    to,
  })),
  alternatives: alternatives.map((nomination) => ({
    party: nomination.party === 'tba' ? undefined : nomination.party,
    name: `${nomination.name}`,
    timeframe: nomination.timeframe,
    timeframeValue: getTimeFrameValue(nomination, timezone),
    timeframeValueDeliveryWindow: isDaysAheadTimeFrameValue(nomination)
      ? nomination.timeframeValueDeliveryWindow
      : undefined,
    toBeNominated: true,
  })),
  isProRata: deliveryType === 'pro_rata',
})

export const NOMINATION_PORT_DEFAULT_VALUES = {
  name: '',
  toBeNominated: false,
  timeframe: 'days_ahead',
  timeframeValue: undefined,
  timeframeValueTime: '00:00',
  timeframeValueDeliveryWindow: undefined,
  party: 'bid',
}

// LOAD PORT && DISCHARGE PORT

const getLoadPortNominations = ({ loadPort }, timezone) => {
  return {
    base: cleanToBeNominated(loadPort.base, timezone),
    alternatives: loadPort.alternatives.map((alternativeNomination) =>
      cleanToBeNominated(alternativeNomination, timezone)
    ),
    adgas: loadPort.adgas,
  }
}

const getDischargePortNominations = ({ dischargePort }, timezone) => {
  if (dischargePort.na) {
    return undefined
  }

  return {
    base: cleanToBeNominated(dischargePort.base, timezone),
    alternatives: dischargePort.alternatives.map((alternativeNomination) =>
      cleanToBeNominated(alternativeNomination, timezone)
    ),
  }
}

// LNG-SHIP

export const LNG_SHIP_NOMINATION_DEFAULT_VALUES = {
  name: '',
  size: undefined,
  min: undefined,
  max: undefined,
  toBeNominated: false,
  timeframe: 'days_ahead',
  timeframeValue: undefined,
  timeframeValueTime: '00:00',
  party: 'ask',
  vesselType: 'vessel_size',
}

const getSize = ({ min, max, size }) => {
  if (min || max || size) {
    return { min, max, value: size }
  }

  return undefined
}

const getLNGShipNominations = ({ ship }, timezone) => ({
  base: {
    ...cleanToBeNominated(ship.base, timezone),
    size: getSize(ship.base),
    min: undefined,
    max: undefined,
    vesselType: ship.base.vesselType,
  },
  alternatives: ship.alternatives.map((alternativeNomination) => ({
    ...cleanToBeNominated(alternativeNomination, timezone),
    min: undefined,
    max: undefined,
    size: getSize(alternativeNomination),
    vesselType: alternativeNomination.vesselType,
  })),
})

// OTHER

const getOtherNominations = ({ other }, timezone) => {
  return {
    alternatives: other.alternatives.map((alternativeNomination) =>
      cleanToBeNominated(alternativeNomination, timezone)
    ),
  }
}

const NOMINATIONS_INITIAL_VALUES = {
  deliveryWindow: {
    cargos: formatDateRanges(
      getCargoesDateRanges({
        ...DELIVERY_INITIAL_VALUES,
        nominationRules: { deliveryWindow: DELIVERY_WINDOW_DEFAULT_VALUES },
      })
    ),
    ...DELIVERY_WINDOW_DEFAULT_VALUES,
  },
  loadPort: {
    base: {
      ...NOMINATION_PORT_DEFAULT_VALUES,
    },
    alternatives: [],
    adgas: false,
  },
  dischargePort: {
    base: {
      ...NOMINATION_PORT_DEFAULT_VALUES,
      name: DELIVERY_INITIAL_VALUES.delivery.location,
    },
    alternatives: [],
    na: false,
  },
  ship: {
    base: {
      ...LNG_SHIP_NOMINATION_DEFAULT_VALUES,
    },
    alternatives: [],
  },
  other: {
    alternatives: [],
  },
  laytime: 36,
  boilOff: '',
  demurage: '',
  fsruFsu: 42,
  demurageNote: '',
}

/* :: object -> object */
const getNominationsRules = (formValues) => {
  const { nominationRules: values, delivery } = formValues
  const { timezone } = values.deliveryWindow

  const nominationRules = {
    laytime: values.laytime,
    boilOff: values.boilOff,
    demurage: values.demurage,
    fsruFsu: values.fsruFsu,
    demurageNote: values.demurageNote,
  }

  nominationRules.deliveryWindow = getDeliveryWindowNominations(values)
  nominationRules.loadPort = getLoadPortNominations(values, timezone)
  nominationRules.dischargePort = getDischargePortNominations(values, timezone)
  nominationRules.ship = getLNGShipNominations(values, timezone)
  nominationRules.other = getOtherNominations(values, timezone)

  if (isFOBType(delivery.type)) {
    nominationRules.loadPort.timezone = timezone
  } else {
    nominationRules.dischargePort.timezone = timezone
  }

  return nominationRules
}

const getNominationsStep1Paths = (formik) =>
  Object.keys(flatten(formik.values.nominationRules.deliveryWindow)).map(
    (path) => `nominationRules.deliveryWindow.${path}`
  )
const getNominationsStep2Paths = (formik) => {
  const portName = isFOBType(get(formik, 'values.delivery.type'), '')
    ? 'loadPort'
    : 'dischargePort'
  return Object.keys(flatten(formik.values.nominationRules.loadPort)).map(
    (path) => `nominationRules.${portName}.${path}`
  )
}
const getNominationsStep3Paths = (formik) => {
  const portName = isFOBType(get(formik, 'values.delivery.type'), '')
    ? 'dischargePort'
    : 'loadPort'
  return Object.keys(flatten(formik.values.nominationRules.loadPort)).map(
    (path) => `nominationRules.${portName}.${path}`
  )
}
const getNominationsStep4Paths = (formik) =>
  Object.keys(flatten(formik.values.nominationRules.ship)).map(
    (path) => `nominationRules.ship.${path}`
  )
const getNominationsStep5Paths = (formik) =>
  Object.keys(flatten(formik.values.nominationRules.other.alternatives)).map(
    (path) => `nominationRules.other.alternatives.${path}`
  )

/* :: Formik -> string[] */
const getNominationPaths = (formik) =>
  Object.keys(flatten({ nominationRules: formik.values.nominationRules }))

export {
  NOMINATIONS_INITIAL_VALUES,
  getNominationsRules,
  isUndefinedTimeFrameValue,
  isFixedDateTimeFrameValue,
  isMonthAheadTimeFrameValue,
  isDaysAfterTimeFrameValue,
  isDaysAheadTimeFrameValue,
  toBeNominated,
  getNominationPaths,
  getNominationsStep1Paths,
  getNominationsStep2Paths,
  getNominationsStep3Paths,
  getNominationsStep4Paths,
  getNominationsStep5Paths,
}
