export const buildStyles = (unit) => ({
  cardContent: {
    flexWrap: 'nowrap',
  },
  commentsBar: {
    display: 'inline-flex',
  },
  companyCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '60px',
    justifyContent: 'space-between',
  },
  companyCodeItem: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: unit,
  },
  marketInterestInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '60px',
    overflow: 'auto',
    overflowWrap: 'break-word',
  },
  swapLegContainer: {
    display: 'flex',
  },
  deliveryInformationContainer: {
    width: 'auto',
    alignItems: 'unset',
  },
  deliveryInformationItem: {
    marginRight: unit,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const buildTradingTypeStyles = (unit) => ({
  ask: {
    commentsBar: {
      flexDirection: 'row-reverse',
    },
    commentsItem: {
      marginLeft: unit,
      marginRight: '0',
    },
    companyCodeContainer: {
      alignItems: 'flex-end',
    },
    deliveryInformationContainer: {
      marginLeft: unit,
    },
    marketInterestInfoContainer: {
      textAlign: 'left',
    },
    swapSymbol: {
      alignSelf: 'flex-start',
    },
    swapLegContainer: {
      flexDirection: 'row-reverse',
    },
  },

  bid: {
    commentsBar: {
      flexDirection: 'row',
    },
    commentsItem: {
      marginLeft: '0',
      marginRight: unit,
    },
    companyCodeContainer: {
      alignItems: 'flex-start',
    },
    marketInterestInfoContainer: {
      textAlign: 'right',
    },
    swapSymbol: {
      alignSelf: 'flex-end',
    },
  },
})
