import React from 'react'
import PropTypes from 'prop-types'
import {
  ClickAwayListener,
  Collapse,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { noop } from 'lodash'
import { ConfirmActionInline } from 'components/ConfirmActionDialog'

export const MenuItemWithConfirm = ({
  title,
  text,
  expanded,
  onCollapse,
  onClick,
  onConfirm,
  ...props
}) => (
  <ClickAwayListener onClickAway={onCollapse}>
    <div>
      <ListItem {...props} onClick={onClick} button>
        <ListItemText>{title}</ListItemText>
      </ListItem>
      <Collapse
        in={expanded}
        data-testid="menu-item-collapsible"
        data-hidden={!expanded}
      >
        <ListItem>
          <ConfirmActionInline
            text={text}
            onConfirm={onConfirm}
            onCancel={onCollapse}
          />
        </ListItem>
      </Collapse>
    </div>
  </ClickAwayListener>
)

MenuItemWithConfirm.defaultProps = {
  title: '',
  text: '',
  expanded: false,
  onCollapse: noop,
  onClick: noop,
  onConfirm: noop,
}

MenuItemWithConfirm.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  expanded: PropTypes.bool,
  onCollapse: PropTypes.func,
  onClick: PropTypes.func,
  onConfirm: PropTypes.func,
}
