const cargo = require('./cargo')
const frequency = require('./frequency')
const location = require('./location')
const period = require('./period')
const type = require('./type')
const volume = require('./volume')

const getDelivery = (order) => {
  return {
    cargo: cargo.get(order),
    frequency: frequency.get(order),
    location: location.get(order),
    period: period.get(order),
    type: type.get(order),
    volume: volume.get(order),
  }
}

const getSummary = (order) => {
  return {
    cargo: cargo.get(order),
    frequency: frequency.summary(order),
    location: location.text(order),
    period: period.summary(order),
    type: type.text(order),
    volume: volume.summary(order),
  }
}

const getText = (order) => {
  return {
    cargo: cargo.get(order),
    frequency: frequency.text(order),
    location: location.text(order),
    period: period.text(order),
    type: type.text(order),
    volume: volume.text(order),
  }
}

module.exports = {
  cargo,
  frequency,
  location,
  period,
  type,
  volume,
  get: getDelivery,
  summary: getSummary,
  text: getText,
}
