import { withProps } from 'recompose'
import Clear from '@material-ui/icons/Clear'
import IconButton from 'components/iconButton/IconButton'

const DeleteButton = withProps({
  variant: 'contained',
  color: 'secondary',
  icon: Clear,
  children: 'Delete',
})(IconButton)

export default DeleteButton
