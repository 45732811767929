const moment = require('moment')
const isFinite = require('lodash/isFinite')

const {
  DELIVERY_PERIOD_TEXT,
  DELIVERY_PERIOD_MONTH,
  DELIVERY_PERIOD,
} = require('../../../constants')

const getPeriodType = (order) => {
  const delivery = order.delivery || {}

  return delivery.period
}

const getPeriodTypeText = (order) => {
  const periodType = getPeriodType(order)

  if (Object.values(DELIVERY_PERIOD_MONTH).includes(periodType)) {
    return DELIVERY_PERIOD_TEXT[periodType].substring(0, 3).toUpperCase()
  }

  return DELIVERY_PERIOD_TEXT[periodType] || ''
}

const getPeriod = (order) => {
  const delivery = order.delivery || {}
  const period = {
    type: getPeriodType(order),
  }

  const year = delivery.year || ''
  if (year.toString().length === 4 && isFinite(parseInt(year))) {
    period.year = delivery.year
  }

  const from = moment(delivery.customFrom)
  if (from.isValid()) {
    period.from = from.format()
  }

  const to = moment(delivery.customTo)
  if (to.isValid()) {
    period.to = to.format()
  }

  return period
}

const getPeriodSummary = (order) => {
  const period = getPeriod(order)
  const periodSummary = {
    type: getPeriodTypeText(order),
  }

  if (period.year) {
    periodSummary.year = period.year.toString().substring(2)
  }

  const from = moment(period.from)
  if (from.isValid()) {
    periodSummary.from = from.format('MMM-YY').toUpperCase()
  }

  const to = moment(period.to)
  if (period.to) {
    periodSummary.to = to.format('MMM-YY').toUpperCase()
  }

  return periodSummary
}

const getPeriodText = (order) => {
  const periodType = getPeriodType(order)
  const delivery = getPeriodSummary(order)
  const periodText = []

  if (periodType !== DELIVERY_PERIOD.CUSTOM) {
    if (delivery.type) {
      periodText.push(delivery.type)
    }

    if (delivery.year) {
      periodText.push(delivery.year)
    }
    return periodText.join('-')
  }

  if (delivery.from) {
    periodText.push(delivery.from)
  }

  if (delivery.to) {
    periodText.push(delivery.to)
  }

  return periodText.join(' - ')
}

module.exports = {
  get: getPeriod,
  text: getPeriodText,
  summary: getPeriodSummary,
  type: {
    get: getPeriodType,
    text: getPeriodText,
  },
}
