import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Grid as _Grid,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { Autorenew } from '@material-ui/icons'
import { spacing } from '@material-ui/system'
import { StatusFilters } from 'components/StatusFilters'
import _IconButton from 'components/iconButton/IconButton'

const Grid = styled(_Grid)`
  ${spacing}
`

const Filter = styled(Select)`
  min-width: ${({ theme }) => theme.spacing(15)}px;
  max-width: 200px;
  ${(props) => !props.selected ?? 'opacity: 0.5;'}
`

const IconButton = styled(_IconButton)`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`

const ResetFiltersButton = (props) => (
  <IconButton
    icon={Autorenew}
    color="secondary"
    variant="contained"
    size="small"
    id="resetVisualisationFiltersButton"
    {...props}
  >
    Reset
  </IconButton>
)

export const Filters = ({ filters, onChange, onReset, options, touched }) => {
  return (
    <Grid spacing={2} container alignItems="flex-end">
      <Grid item>
        <FormControl>
          <InputLabel id="type-selection-label">Type</InputLabel>
          <Filter
            labelId="type-selection-label"
            name="filter-delivery-type"
            placeholder="Delivery Type"
            value={filters.type}
            onChange={(e) => onChange('type', e.target.value)}
          >
            <MenuItem value="" style={{ opacity: 0.5 }}>
              Type
            </MenuItem>
            {options.deliveryTypes.map((option) => (
              <MenuItem key={`filter-delivery-type-${option}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Filter>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl>
          <InputLabel id="port-selection-label">Ports</InputLabel>
          <Filter
            multiple
            labelId="port-selection-label"
            name="filter-delivery-port"
            placeholder="Delivery Port"
            value={filters.ports}
            renderValue={(selected) => selected.join(', ')}
            onChange={(e) => onChange('ports', e.target.value)}
          >
            {options.ports.map((option) => (
              <MenuItem key={`filter-delivery-port-${option}`} value={option}>
                <Checkbox checked={filters.ports.includes(option)} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Filter>
        </FormControl>
      </Grid>

      <StatusFilters
        value={filters.status}
        onChange={(value) => onChange('status', value)}
      />

      <Grid p={1} pb={0.75}>
        <ResetFiltersButton onClick={onReset} disabled={!touched} />
      </Grid>
    </Grid>
  )
}

Filters.propTypes = {
  filters: PropTypes.shape({
    ports: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  options: PropTypes.shape({
    ports: PropTypes.array.isRequired,
    deliveryTypes: PropTypes.array.isRequired,
  }),
  touched: PropTypes.bool.isRequired,
}
