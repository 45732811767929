import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { PERSON_PERMISSION } from 'emsurge-selectors/constants'
import { useUserHasPermission } from 'permissions'
import { $O } from 'model/order/selectors/selector'
import { StyledLink } from 'components/StyledLink'

export const CreateSwapLegMenuItem = ({ order }) => {
  const userCanCreateSwapLeg = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.WRITE,
    forceSameCompany: true,
  })

  if (!userCanCreateSwapLeg || order.template || $O.swapLeg.exists(order)) {
    return null
  }

  return (
    <MenuItem>
      <StyledLink
        data-testid="menu-create-swap-leg-button"
        to={(location) => `${location.pathname}/swap`}
      >
        CREATE SWAP LEG
      </StyledLink>
    </MenuItem>
  )
}

CreateSwapLegMenuItem.propTypes = {
  order: PropTypes.object.isRequired,
}
