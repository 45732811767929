import React from 'react'
import PropTypes from 'prop-types'
import { SideMenu } from 'components/sideMenu'
import { Main as MainDesktop } from 'components/main/Main.desktop'
import { Main as MainMobile } from 'components/main/Main.mobile'
import Navbar from 'containers/navbar/Navbar'
import { Tablet, Mobile } from 'components/Viewport'

export const Shell = ({ children }) => (
  <>
    <Navbar />
    <Tablet matchLarger>
      <SideMenu variant="permanent" />
      <MainDesktop>{children}</MainDesktop>
    </Tablet>
    <Mobile>
      <SideMenu variant="permanent" closeOnClick />
      <MainMobile>{children}</MainMobile>
    </Mobile>
  </>
)

Shell.propTypes = {
  children: PropTypes.node,
}
