import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import Grid from '@material-ui/core/Grid'

import { PERIOD_OPTIONS } from './helpers'
import { PERIOD } from 'model/order/constants/delivery'
import { SelectDeliveryPeriod } from 'screens/orderCreate/components/SelectDeliveryPeriod'
import { SelectDeliveryYear } from 'screens/orderCreateCommon/components/SelectDeliveryYear'
import { SelectCustomDeliveryDate } from 'screens/orderCreate/components/SelectCustomDeliveryDate'
import {
  PaddedContainer,
  Button,
  StyledContainer,
  StyledConnectedSelectableGrid,
} from 'screens/orderCreate/mobile/components'
import { SelectableGridOption as Option } from 'components/SelectableGrid'

const _DeliveryPeriod = ({ formik: { values } }) => (
  <PaddedContainer>
    {values.delivery.period !== PERIOD.CUSTOM && <SelectDeliveryYear />}

    <StyledContainer>
      <SelectDeliveryPeriod>
        {({ onChange }) => {
          return (
            <StyledConnectedSelectableGrid
              name="delivery.period"
              onChange={(value) => onChange(value)}
            >
              {PERIOD_OPTIONS.map(({ value, label, xs }) => (
                <Option
                  key={`order-delivery-location-${value}`}
                  xs={xs}
                  value={value}
                >
                  <Button>{label}</Button>
                </Option>
              ))}
            </StyledConnectedSelectableGrid>
          )
        }}
      </SelectDeliveryPeriod>
    </StyledContainer>

    {values.delivery.period === PERIOD.CUSTOM && (
      <StyledContainer>
        <Grid container spacing={1}>
          <SelectCustomDeliveryDate xs={6} />
        </Grid>
      </StyledContainer>
    )}
  </PaddedContainer>
)

_DeliveryPeriod.propTypes = {
  formik: PropTypes.object.isRequired,
}

export const DeliveryPeriod = connect(_DeliveryPeriod)
