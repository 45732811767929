import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'

import FormControl from 'screens/orderCreateCommon/components/SelectionFormControl'
import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'
import { SelectCargoSizeParty } from 'screens/orderCreate/components/SelectCargoSizeParty'

export const Party = () => (
  <FormControl fullWidth>
    <FormLabel>Party for Operational Tolerance</FormLabel>
    <SelectCargoSizeParty>
      {({ options, onChange }) => (
        <RadioInputs
          onChange={(evt) => onChange(evt.target.value)}
          name="cargoSize.party"
          options={options}
        />
      )}
    </SelectCargoSizeParty>
  </FormControl>
)
