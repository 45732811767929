import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withSnackbar } from 'notistack'
import {
  ORDER_STATUS,
  PERSON_PERMISSION,
  VALIDITY_TYPE,
} from 'emsurge-selectors/constants'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'
import { useUserHasPermission } from 'permissions'

const OrderStatusSwitch = ({ order, enqueueSnackbar, label, id, ...props }) => {
  const api = useApi()
  const [isLive, setIsLive] = useState(order.status === ORDER_STATUS.LIVE)

  /**
   @NOTE: On order update update status. This fixes issue where order.status
   changes when first retrieving order from cahce then api
   */
  useEffect(() => {
    setIsLive(order.status === ORDER_STATUS.LIVE)
  }, [order.status])

  const handleChange = async (event) => {
    event.preventDefault()
    setIsLive(!isLive)

    const data = isLive
      ? {
          status: ORDER_STATUS.WITHHELD,
          validityType: VALIDITY_TYPE.INDICATIVE,
          validUntil: null,
        }
      : {
          status: ORDER_STATUS.LIVE,
          validityType: VALIDITY_TYPE.GOOD_TILL_CANCELLED,
          validUntil: null,
        }

    try {
      await api.patch(
        `/orders/${order.id}/status`,
        {
          ...data,
          context: order.context,
        },
        {},
        false
      )
      enqueueSnackbar('Order status updated successfully', {
        variant: 'success',
      })
    } catch (error) {
      setIsLive(isLive)
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Order status update not successful',
      })
      enqueueSnackbar(message, {
        variant: 'error',
      })
    }
  }

  const userCanUpdateStatus = useUserHasPermission({
    order,
    minimumPermission: PERSON_PERMISSION.WRITE,
    forceSameCompany: true,
  })

  if (order.template) {
    return null
  }

  if (!userCanUpdateStatus) {
    return null
  }

  /**
   @NOTE: The user cannot change the status of order when pending, traded,
   cancelled or closed
   */
  if (![ORDER_STATUS.LIVE, ORDER_STATUS.WITHHELD].includes(order.status)) {
    return null
  }

  const switchElem = (
    <Switch
      color="primary"
      checked={isLive}
      onChange={handleChange}
      className="order-live-checkbox"
      id={id}
      data-testid={props?.['data-testid'] && props['data-testid']}
    />
  )

  if (isEmpty(label)) {
    return switchElem
  }

  return <FormControlLabel label={label} control={switchElem} />
}

OrderStatusSwitch.defaulProps = {
  label: null,
}

OrderStatusSwitch.propTypes = {
  order: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  'data-testid': PropTypes.string,
}

export default withSnackbar(OrderStatusSwitch)
