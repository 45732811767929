const NOMINAL_SUFFIXES = {
  TH: 'th',
  ND: 'nd',
  RD: 'rd',
  ST: 'st',
}

const NUMBER_NOMINAL_SUFFIXES = {
  1: NOMINAL_SUFFIXES.ST,
  2: NOMINAL_SUFFIXES.ND,
  3: NOMINAL_SUFFIXES.RD,
}

const decimalsOf = (number) => {
  const [, decimal = 0] = /\d+\.(\d+)/g.exec(number) || []
  return decimal.toString()
}

const withDecimals = (price, minimum = 2) => {
  const sPrice = price.toString()
  const dp = decimalsOf(sPrice).length

  if (dp <= minimum) {
    const fixedNr = parseFloat(sPrice)
    return Number.isNaN(fixedNr) ? undefined : fixedNr.toFixed(minimum)
  }
  return sPrice
}

const isZero = (amount) => parseFloat(amount) === 0

const getOrdinalSuffix = (number) => {
  if (!number || isNaN(number)) {
    return ''
  }

  if (
    parseInt(number) === 11 ||
    parseInt(number) === 12 ||
    parseInt(number) === 13
  ) {
    return NOMINAL_SUFFIXES.TH
  }

  const numberString = number.toString()
  const lastNumber = numberString.substr(numberString.length - 1)

  return NUMBER_NOMINAL_SUFFIXES[lastNumber] || NOMINAL_SUFFIXES.TH
}

module.exports = {
  decimalsOf,
  getOrdinalSuffix,
  withDecimals,
  isZero,
}
