import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { connect } from 'formik'
import { makeStyles } from '@material-ui/styles'
import { get, clamp } from 'lodash'
import { STEPS, UNKNOWN_STEP } from './constants'
import { Navbar, Footer, Content } from 'components/modal/SimpleModal/'
import { useFetch } from 'containers/fetch/useFetch'
import { useUser } from 'containers/user/useUser'
import { ProgressStepper } from 'components/ProgressStepper'
import { DeliveryCargosUpdater } from 'screens/orderCreate/components/DeliveryCargosUpdater'

const useStepperStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.secondary,
    padding: theme.spacing(1.5),
  },
}))

const _CreateOrderModal = ({ formik, onSave, onCancel }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const { user } = useUser()
  const { data: company } = useFetch(`/companies/${user.companyId}`)
  const classes = useStepperStyles()
  const totalSteps = STEPS.length
  const firstStep = 0
  const lastStep = totalSteps - 1
  const withBounds = (value) => clamp(value, firstStep, lastStep)
  const {
    title,
    Component: StepComponent,
    LeftButton: LeftBtn,
    RightButton: RightBtn,
  } = get(STEPS, currentStep, UNKNOWN_STEP)

  const actions = {
    goBack: () => setCurrentStep((step) => withBounds(step - 1)),
    goForward: () => setCurrentStep((step) => withBounds(step + 1)),
    goToLast: () => setCurrentStep(withBounds(lastStep)),
    goTo: (step) => setCurrentStep(withBounds(step)),
    close: onCancel,
    save: onSave,
  }

  const LeftButton = withProps({ actions })(LeftBtn)
  const RightButton = withProps({ actions })(RightBtn)

  return (
    <>
      <Navbar
        title={title}
        onCancel={onCancel}
        shouldRenderBackButton={currentStep !== firstStep}
        disabled={!formik.isValid}
        onBackButtonClicked={actions.goBack}
      />
      <Content>
        <DeliveryCargosUpdater>
          <StepComponent company={company} actions={actions} />
        </DeliveryCargosUpdater>
      </Content>
      <Footer>
        <ProgressStepper
          classes={classes}
          steps={totalSteps}
          activeStep={currentStep}
          LeftButton={LeftButton}
          RightButton={RightButton}
        />
      </Footer>
    </>
  )
}

_CreateOrderModal.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  formik: PropTypes.object.isRequired,
}

export const CreateOrderModal = connect(_CreateOrderModal)
