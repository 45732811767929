import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { spacing } from '@material-ui/system'
import TextField from '@material-ui/core/TextField'

const Container = styled.div`
  ${spacing};
`

const SearchForm = ({ style, ...props }) => {
  return (
    <Container p={2} style={style}>
      <TextField
        placeholder="Search"
        fullWidth
        margin="none"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Container>
  )
}

SearchForm.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default SearchForm
