import moment from 'moment'
import { map } from 'lodash'
import { connect } from 'formik'
import { DELIVERY_FREQUENCY_TYPE_OPTIONS } from 'emsurge-selectors/constants'
import { getDeliveryPeriodRange } from './DeliveryWindow.helpers'
import { PERIOD, PERIOD_LABELS } from 'model/order/constants/delivery'

const _SelectDeliveryPeriod = ({ formik, children }) => {
  const periodOptions = map(PERIOD_LABELS, (label, value) => ({ value, label }))

  const onChange = (value) => {
    const newPeriod = value

    if (newPeriod === PERIOD.CUSTOM) {
      formik.setFieldValue('delivery.customFrom', moment().format('YYYY-MM'))
      formik.setFieldValue('delivery.customTo', moment().format('YYYY-MM'))
    } else {
      const currentYear = moment().year()
      const periodRange = getDeliveryPeriodRange({
        period: newPeriod,
        year: currentYear,
      })
      const startDateIsInPast = moment().isAfter(periodRange.from)
      const yearToSet = startDateIsInPast
        ? `${parseInt(currentYear, 10) + 1}`
        : `${currentYear}`
      formik.setFieldValue('delivery.year', yearToSet)
    }
    formik.setFieldValue(
      'delivery.frequency',
      DELIVERY_FREQUENCY_TYPE_OPTIONS[0].value
    )
  }

  return children({ options: periodOptions, onChange })
}

export const SelectDeliveryPeriod = connect(_SelectDeliveryPeriod)
