import { $U, PERSON_CLIENT_TYPE } from 'emsurge-selectors'
import { $O } from 'model/order/selectors/selector'

const getTraderStyles = (colors) => [
  {
    style: {
      symbolColor: colors.blue,
      textColor: colors.pink,
    },
    shouldApply: ({ user, order }) => {
      return (
        $O.author.isBroker(order) &&
        $O.author.exists(order) &&
        ($O.author.get(order).id === user.id ||
          $O.author.isFromMyCompany(order, user))
      )
    },
  },
  {
    style: {
      symbolColor: undefined,
      textColor: colors.pink,
    },
    shouldApply: ({ user, order }) => {
      return (
        $O.author.isBroker(order) && !$O.author.isFromMyCompany(order, user)
      )
    },
  },
  {
    style: {
      symbolColor: undefined,
      textColor: colors.pink,
    },
    shouldApply: ({ user, order }) => {
      return !$O.author.isFromMyCompany(order, user)
    },
  },
  {
    style: {
      symbolColor: undefined,
      textColor: colors.blue,
    },
    shouldApply: ({ user, order }) => {
      return (
        $O.author.isFromMyCompany(order, user) &&
        !$O.stakeholder.isOffSystem(order) &&
        !$O.broker.exists(order)
      )
    },
  },
  {
    style: {
      symbolColor: undefined,
      textColor: colors.orange,
    },
    shouldApply: ({ order }) => {
      return $O.stakeholder.isOffSystem(order)
    },
  },
  {
    style: {
      symbolColor: colors.pink,
      textColor: colors.blue,
    },
    shouldApply: ({ user, order }) => {
      return (
        $O.author.isFromMyCompany(order, user) &&
        $O.broker.exists(order) &&
        !$O.author.isBroker(order)
      )
    },
  },
]

const getBrokerStyles = (colors) => [
  {
    style: {
      symbolColor: colors.orange,
      textColor: colors.pink,
    },
    shouldApply: ({ order }) => {
      return $O.author.isBroker(order) && $O.stakeholder.isOffSystem(order)
    },
  },
  {
    style: {
      symbolColor: colors.blue,
      textColor: colors.pink,
    },
    shouldApply: ({ order }) => {
      return $O.author.isBroker(order) && !$O.stakeholder.isOffSystem(order)
    },
  },
  {
    style: {
      symbolColor: undefined,
      textColor: colors.blue,
    },
    shouldApply: ({ order }) => {
      return !$O.author.isBroker(order)
    },
  },
]

export const getCompanyCodeStyle = ({ user, order, palette }) => {
  const clientType = $U.clientType.get(user)
  let matchedRule

  switch (clientType) {
    case PERSON_CLIENT_TYPE.BROKER:
      matchedRule = getBrokerStyles(palette.other).find((rule) =>
        rule.shouldApply({ user, order })
      )
      break
    default:
      matchedRule = getTraderStyles(palette.other).find((rule) =>
        rule.shouldApply({ user, order })
      )
      break
  }

  return matchedRule.style
}
