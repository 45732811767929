const { PARTY_TEXT } = require('../../constants')

const getTradingType = (order) => {
  const { tradingType } = order

  return tradingType
}

const getTradingTypeText = (order) => {
  const party = getTradingType(order)

  return PARTY_TEXT[party] || ''
}

module.exports = {
  get: getTradingType,
  text: getTradingTypeText,
}
