import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { connect } from 'formik'

import { DELIVERY_VOLUME_MAX_VALUE } from 'emsurge-selectors/constants'
import { TextField } from 'screens/orderCreateCommon/components/TextField'
import FormRow from 'components/formRow/FormRow'

const preventEnterE = (e) => e.key === 'e' && e.preventDefault()

const _SelectCargosQuantity = ({ formik, focus }) => {
  const { values } = formik
  return values.volume.type === 'range' ? (
    <Grid item style={{ alignSelf: 'center' }}>
      <FormRow>
        <TextField
          inputProps={{ 'data-testid': 'number-of-cargoes-min' }}
          autoFocus={focus}
          type="number"
          name="volume.min"
          label="Min"
          onKeyDown={preventEnterE}
        />
        <TextField
          inputProps={{ 'data-testid': 'number-of-cargoes-max' }}
          type="number"
          name="volume.max"
          label="Max"
          onKeyDown={preventEnterE}
        />
      </FormRow>
    </Grid>
  ) : (
    <Grid item style={{ alignSelf: 'center' }}>
      <TextField
        autoFocus={focus}
        type="number"
        name="volume.min"
        label="Quantity"
        inputProps={{
          max: DELIVERY_VOLUME_MAX_VALUE,
          'data-testid': 'number-of-cargoes-min',
        }}
        onKeyDown={preventEnterE}
      />
    </Grid>
  )
}

_SelectCargosQuantity.defaultProps = {
  focus: false,
}

_SelectCargosQuantity.propTypes = {
  formik: PropTypes.object.isRequired,
  focus: PropTypes.bool,
}

export const SelectCargosQuantity = connect(_SelectCargosQuantity)
