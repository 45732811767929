import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { object, string, lazy } from 'yup'
import { useSnackbar } from 'notistack'
import { HOME } from 'routes'
import Form from 'containers/formikForm/FormikForm'
import { BrandLogoWrapper } from 'components/logo'
import { LayoutWrapper } from 'components/LayoutWrapper'
import { SubmitButton } from 'screens/signIn/components/Buttons'
import {
  EmailField,
  PasswordField,
} from 'screens/signIn/components/SignInInputs'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const Schema = lazy((values) =>
  object().shape({
    email: string().email('Invalid email').required('Required'),
    password: string()
      .min(12, 'Password has to be longer than 12 characters')
      .required('Required'),
    passwordConfirmation: string()
      .oneOf([values.password], 'Passwords do not match')
      .required('Required'),
  })
)

const INITIAL_VALUES = {
  email: '',
  password: '',
  passwordConfirmation: '',
}

const CreateAdminAccount = ({ history, location }) => {
  const { enqueueSnackbar } = useSnackbar()
  const api = useApi()

  const { token, email } = queryString.parse(location.search)

  const handleCreateAccount = async (values, form) => {
    const { password, email } = values
    const data = { token, email, password }

    try {
      await api.post('/password', data, { auth: false })

      form.resetForm()
      enqueueSnackbar('Account created with success', { variant: 'success' })

      history.push(`${HOME}?email=${email}`)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Account cannot be created',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      form.setSubmitting(false)
    }
  }

  return (
    <LayoutWrapper>
      <BrandLogoWrapper />

      <Form
        initialValues={{ ...INITIAL_VALUES, email }}
        validationSchema={Schema}
        onSubmit={handleCreateAccount}
      >
        <EmailField name="email" label="Email" autoFocus disabled />
        <PasswordField name="password" />
        <PasswordField name="passwordConfirmation" label="Confirm password" />

        <SubmitButton>Create Account</SubmitButton>
      </Form>
    </LayoutWrapper>
  )
}

CreateAdminAccount.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default CreateAdminAccount
