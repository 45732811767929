const { VENUES } = require('../../constants')

const getVenue = (order) => order.venue || {}

const getVenueText = (order) => {
  const { options } = getVenue(order)
  return {
    venue: options.map((opt) => VENUES[opt]).join(', '),
  }
}

module.exports = {
  get: getVenue,
  text: getVenueText,
}
