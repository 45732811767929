import React, { Fragment } from 'react'
import { TableBody, TableCell, TableRow, TableHead } from '@material-ui/core'
import PropTypes from 'prop-types'

const UngroupedTableData = ({ data, ...props }) => (
  <TableBody>
    {data.map((data, index) => (
      <props.components.Row {...props} key={index} index={index} data={data} />
    ))}
  </TableBody>
)

UngroupedTableData.propTypes = {
  data: PropTypes.array.isRequired,
}

const GroupedTableData = ({ data, ...props }) => {
  const { groupFunction } = props.options
  const dataGroupedByDate = groupFunction(data)

  return Object.keys(dataGroupedByDate).map((groupName) => (
    <Fragment key={groupName}>
      <TableHead style={{ backgroundColor: '#363f4f' /* [1] */ }}>
        <TableRow>
          <TableCell colSpan={props.columns.length}>{groupName}</TableCell>
        </TableRow>
      </TableHead>
      <UngroupedTableData data={dataGroupedByDate[groupName]} {...props} />
    </Fragment>
  ))
}

GroupedTableData.propTypes = {
  data: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
}

const TableData = ({ data, ...props }) => {
  const { groupFunction } = props.options
  const shouldTheDataBeGrouped =
    groupFunction !== undefined && typeof groupFunction === 'function'

  if (shouldTheDataBeGrouped) {
    return <GroupedTableData data={data} {...props} />
  }
  return <UngroupedTableData data={data} {...props} />
}

TableData.propTypes = {
  data: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
}

export default TableData
