import React from 'react'
import Text from '@material-ui/core/Typography'
import BrokersTable from './components/BrokersTable'
import Fetch from 'containers/fetchPlus/FetchPlus'

const ViewBrokers = () => (
  <Fetch url="/entities?type=broker">
    {(brokers) => (
      <section>
        <Text component="h2" variant="h1" gutterBottom>
          Brokers
        </Text>
        <BrokersTable brokers={brokers} />
      </section>
    )}
  </Fetch>
)

export default ViewBrokers
