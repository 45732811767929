import { isEmpty, isString, round } from 'lodash'

import {
  PRICE_TYPE,
  PRICE_TYPE_TEXT,
  PRICE_UNIT_TEXT,
} from 'emsurge-selectors/constants'

const hasPrice = (info) => {
  const percentage = parseFloat(info.percentage)
  const index = info.index

  return Number.isFinite(percentage) && isString(index) && !isEmpty(index)
}

const getPrice = (info) => {
  return {
    amount: parseFloat(info.amount) || undefined,
    index: info.index,
    percentage: parseFloat(info.percentage) || undefined,
    plusOrMinus: info.plusOrMinus,
    unit: info.unit,
  }
}

const getSummary = (info) => {
  let summary

  if (info.percentage) {
    const percentage = round(info.percentage, 3)
    const index = info.index.toUpperCase()

    summary = `${percentage}% ${index}`.replace('100% ', '')

    if (info.amount) {
      const amount = round(info.amount, 3)
      const unit = PRICE_UNIT_TEXT[info.unit](amount)

      summary += ` ${info.plusOrMinus} ${unit}`
    }
  }

  return {
    type: PRICE_TYPE_TEXT[PRICE_TYPE.FLOATING],
    short: summary,
    long: summary,
  }
}

export const floating = {
  exists: hasPrice,
  get: getPrice,
  summary: getSummary,
}
