import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import { getAddress } from '../helper'
import Header from './Header'
import { GridContainer, Column } from './Grid'
import InfoText from './InfoText'
import Wrapper from './Wrapper'
import { isCompanyAdmin } from 'roles'

/* :: object -> ?object */
const getCompanyAdmin = (company) =>
  company.persons.find(
    (person) => isCompanyAdmin(person) && person.companyId === company.id
  )

const EMPTY_ADMIN = {
  name: '--',
  jobTitle: '--',
  email: '--',
  phone: '--',
}

const CompanyInformation = ({ company }) => {
  const companyAdmin = getCompanyAdmin(company) || EMPTY_ADMIN
  const { pointOfContact: primaryContact } = company

  return (
    <Wrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Text variant="h1" gutterBottom>
          Company information
        </Text>

        <Header company={company} />
      </Grid>

      <GridContainer>
        {/** GENERAL INFORMATION */}
        <Column>
          <Text variant="h2" gutterBottom>
            General information
          </Text>
          <InfoText header="Company Brand name" info={company.name} />
          <InfoText
            header="Parent company Entity"
            info={company.parentCompany}
          />
          <InfoText
            header="Registration Number"
            info={company.registrationNumber}
          />
          <InfoText header="Address" info={getAddress(company)} />
        </Column>

        {/** PRIMARY POINT OF CONTACT */}
        <Column>
          <Text variant="h2" gutterBottom>
            Primary point of contact
          </Text>
          <InfoText header="Name" info={primaryContact.name} />
          <InfoText header="Job Title" info={primaryContact.jobTitle} />
          <InfoText header="Email" info={primaryContact.email} />
          <InfoText header="Telephone Number" info={primaryContact.phone} />
          <InfoText header="Address" info={getAddress(primaryContact)} />
        </Column>

        {/** COMPANY ADMIM */}
        <Column>
          <Text variant="h2" gutterBottom>
            Primary Account Holder
          </Text>
          <InfoText header="Name" info={companyAdmin.name} />
          <InfoText header="Job Title" info={companyAdmin.jobTitle} />
          <InfoText header="Email" info={companyAdmin.email} />
          <InfoText header="Telephone number" info={companyAdmin.phone} />
        </Column>
      </GridContainer>
    </Wrapper>
  )
}

CompanyInformation.propTypes = {
  company: PropTypes.object.isRequired,
}

export default CompanyInformation
