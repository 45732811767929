import React from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Text from '@material-ui/core/Typography'
import { AccordionSummary, AccordionDetails } from '@material-ui/core'
import { PRICE_VARIANT, ORDER_STATUS } from 'emsurge-selectors/constants'
import { $O, TRADING_TYPE } from 'emsurge-selectors'
import { getPartyFromOrderInTermsheet } from '../helpers'
import Accordion from './Accordion'
import DetailRow from './DetailRow'
import DetailDivider from './DetailDivider'
import PriceSummary from './PriceSummary'
import { useActiveStep, TAB, STEP } from 'containers/OrderProvider'
import { isTermsheet } from 'model/termsheet/isTermsheet'

const InformationPanel = ({ order, clickable, isCreatingTrade, isTermSheetSummary}) => {
  const cargoSizeSize = $O.cargoSize.size.text(order)
  const cargoSizeContract = $O.cargoSize.tolerance.contract.summary(order)
  const delivery = $O.delivery.text(order)
  const {
    cargo: { min, max },
  } = delivery
  const nCargoes = min !== max ? `${min} - ${max}` : `${max}`
  const stakeholder = $O.stakeholder.summary(order)
  const isOrder = !isTermsheet(order)
  const { navigate } = useActiveStep()

  const navigateOrCancel = (props) =>
    clickable ? () => navigate.goTo(props) : null

  let seller
  let buyer
  if(isCreatingTrade) {
    buyer = order.buyer
    seller = order.seller
  }
  else if(isTermSheetSummary) {
    const buyerOrder = order.orders.find(x => x.tradingType === TRADING_TYPE.BID)
    const sellerOrder = order.orders.find(x => x.tradingType === TRADING_TYPE.ASK)
    buyer = {
      ...getPartyFromOrderInTermsheet(buyerOrder, order),
      ...order.bid
    }
    seller = { 
      ...getPartyFromOrderInTermsheet(sellerOrder, order),
      ...order.ask
    }
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Text variant="h6">Overview</Text>
      </AccordionSummary>

      <AccordionDetails
        style={{ flexDirection: 'column' }}
        id="orderOverview"
      >
        {(isCreatingTrade || isTermSheetSummary) && (
          <>
            <DetailRow
              onClick={navigateOrCancel({
                tab: TAB.OVERVIEW,
                step: STEP[TAB.OVERVIEW].GENERAL,
              })}
              title="Buyer"
              info={`Company: ${buyer?.company?.name}, Entity: ${buyer?.entity?.name}, Trader: ${buyer?.person?.name}`}
              gutterBottom
            /> 

            <DetailRow
              onClick={navigateOrCancel({
                tab: TAB.OVERVIEW,
                step: STEP[TAB.OVERVIEW].GENERAL,
              })}
              title="Buyer Execution Trader"
              info={`Name: ${buyer?.executionTrader?.name}, Email: ${buyer?.executionTrader?.email}, Phone: ${buyer?.executionTrader?.phone}`}
              gutterBottom
            />

            <DetailRow
              onClick={navigateOrCancel({
                tab: TAB.OVERVIEW,
                step: STEP[TAB.OVERVIEW].GENERAL,
              })}
              title="Buyer Ops Contact"
              info={`Name: ${buyer?.opsContact?.name}, Email: ${buyer?.opsContact?.email}, Phone: ${buyer?.opsContact?.phone}`}
              gutterBottom
            />

            <DetailRow
              onClick={navigateOrCancel({
                tab: TAB.OVERVIEW,
                step: STEP[TAB.OVERVIEW].GENERAL,
              })}
              title="Seller"
              info={`Company: ${seller?.company?.name}, Entity: ${seller?.entity?.name}, Trader: ${seller?.person?.name}`}
              gutterBottom
            /> 

            <DetailRow
              onClick={navigateOrCancel({
                tab: TAB.OVERVIEW,
                step: STEP[TAB.OVERVIEW].GENERAL,
              })}
              title="Seller Execution Trader"
              info={`Name: ${seller?.executionTrader?.name}, Email: ${seller?.executionTrader?.email}, Phone: ${seller?.executionTrader?.phone}`}
              gutterBottom
            />

            <DetailRow
              onClick={navigateOrCancel({
                tab: TAB.OVERVIEW,
                step: STEP[TAB.OVERVIEW].GENERAL,
              })}
              title="Seller Ops Contact"
              info={`Name: ${seller?.opsContact?.name}, Email: ${seller?.opsContact?.email}, Phone: ${seller?.opsContact?.phone}`}
              gutterBottom
            />

          </>
        )}

        {isOrder && !(isCreatingTrade || isTermSheetSummary) && (
          <DetailRow
            onClick={navigateOrCancel({
              tab: TAB.OVERVIEW,
              step: STEP[TAB.OVERVIEW].GENERAL,
            })}
            data-testid="order-overview-company-name"
            title="Company"
            info={stakeholder.company}
            gutterBottom
          />
        )}
        {isOrder && !(isCreatingTrade || isTermSheetSummary) && (
          <DetailRow
            onClick={navigateOrCancel({
              tab: TAB.OVERVIEW,
              step: STEP[TAB.OVERVIEW].GENERAL,
            })}
            data-testid="order-overview-entity-name"
            title="Entity"
            info={stakeholder.entity}
            gutterBottom
          />
        )}
        {isOrder && !(isCreatingTrade || isTermSheetSummary) && (
          <DetailRow
            onClick={navigateOrCancel({
              tab: TAB.OVERVIEW,
              step: STEP[TAB.OVERVIEW].GENERAL,
            })}
            data-testid="order-overview-trader-name"
            title="Trader"
            info={stakeholder.person}
            gutterBottom
          />
        )}
        <DetailRow
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].DELIVERY,
          })}
          data-testid="order-overview-delivery-term"
          title="Delivery Term"
          info={delivery.type}
          gutterBottom
        />
        <DetailRow
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].DELIVERY,
          })}
          data-testid="order-overview-delivery-port"
          title="Delivery Port"
          info={delivery.location}
          gutterBottom
        />
        <DetailRow
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].DELIVERY,
          })}
          data-testid="order-overview-delivery-period"
          title="Delivery Period"
          info={delivery.period}
          gutterBottom
        />
        <DetailRow
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].DELIVERY,
          })}
          data-testid="order-overview-number-of-cargoes"
          title="No. of Cargoes"
          info={nCargoes}
          gutterBottom
        />
        <DetailRow
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].GENERAL,
          })}
          data-testid="order-overview-submission-date"
          title={ (isCreatingTrade || isTermSheetSummary) ? "Trade Date" : "Submission Date"}
          info={$O.submittedAt.text(order)}
          gutterBottom
        />
        {isOrder && $O.status.get(order) !== ORDER_STATUS.PENDING && !(isCreatingTrade || isTermSheetSummary) && (
          <DetailRow
            onClick={navigateOrCancel({
              tab: TAB.OVERVIEW,
              step: STEP[TAB.OVERVIEW].GENERAL,
            })}
            data-testid="order-overview-visibility"
            title="Visibility"
            info={$O.validity.text(order)}
          />
        )}
        <DetailDivider />

        <DetailRow
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].CARGO_SIZE,
          })}
          data-testid="order-overview-contract-quantity"
          title="Contract Quantity"
          info={cargoSizeSize}
          gutterBottom
        />
        <DetailRow
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].CARGO_SIZE,
          })}
          data-testid="order-overview-contract-quantity-operational-tolerance-description"
          info={cargoSizeContract.variance}
        />
        {cargoSizeContract.tolerance && (
          <DetailRow
            onClick={navigateOrCancel({
              tab: TAB.OVERVIEW,
              step: STEP[TAB.OVERVIEW].CARGO_SIZE,
            })}
            data-testid="order-overview-contract-quantity-operational-tolerance-range"
            info={cargoSizeContract.tolerance}
          />
        )}
        <DetailDivider />

        <DetailRow
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].QUALITY_GHV,
          })}
          data-testid="order-overview-quality-ghv"
          title="Quality GHV"
          info={$O.quality.text(order)}
        />
        <DetailDivider />

        <PriceSummary
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].PRICE,
          })}
          data-testid="order-overview-basic-price"
          title="Price"
          variant={PRICE_VARIANT.BASIC}
          order={order}
          gutterBottom
        />
        <PriceSummary
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].PRICE,
          })}
          data-testid="order-overview-contract-price"
          title="Contract Price"
          variant={PRICE_VARIANT.CONTRACT}
          order={order}
          gutterBottom
        />
        <PriceSummary
          onClick={navigateOrCancel({
            tab: TAB.OVERVIEW,
            step: STEP[TAB.OVERVIEW].PRICE,
          })}
          data-testid="order-overview-reserve-price"
          title="Reserve Price"
          variant={PRICE_VARIANT.RESERVE}
          order={order}
        />
      </AccordionDetails>
    </Accordion>
  )
}

InformationPanel.defaultProps = {
  clickable: false,
}

InformationPanel.propTypes = {
  order: PropTypes.object.isRequired,
  clickable: PropTypes.bool,
}

export default InformationPanel
