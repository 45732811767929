import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { FieldArray, Form, connect } from 'formik'
import { withTheme } from '@material-ui/styles'
import Done from '@material-ui/icons/Done'
import Add from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import moment from 'moment'
import { CHECKLIST_TYPE_TEXT } from 'emsurge-selectors/constants'
import Button from 'components/iconButton/IconButton'
import Card from 'screens/orderCreate/components/Card'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'
import TextField from 'containers/formikTextField/FormikTextField'

const ChecklistItemFields = connect(({ index, checklistId }) => (
  <Card>
    <FieldArray
      name={`${checklistId}.items`}
      render={(items) => (
        <CloseButton
          onClick={() => {
            items.remove(index)
          }}
        />
      )}
    />
    <Grid container spacing={2}>
      <Grid item xs={6} sm={9}>
        <TextField
          multiline
          name={`${checklistId}.items[${index}].name`}
          label="Item*"
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          type="date"
          name={`${checklistId}.items[${index}].deadlineDate`}
          label="Date"
          InputLabelProps={{ shrink: true }}
          fullWidth
          margin="normal"
        />
      </Grid>
    </Grid>
  </Card>
))

ChecklistItemFields.propTypes = {
  index: PropTypes.number.isRequired,
  checklistId: PropTypes.string.isRequired,
}

const AddItemButton = withProps({
  icon: Add,
  variant: 'contained',
  children: 'Add item',
  color: 'secondary',
})(Button)

const SaveButton = withProps({
  icon: Done,
  type: 'submit',
  variant: 'contained',
  color: 'primary',
  children: 'Save',
})(Button)

const ChecklistEditForm = ({ theme }) => (
  <Form>
    <Grid
      container
      justifyContent="space-between"
      style={{ marginBottom: theme.spacing(2) }}
    >
      <SaveButton />
    </Grid>

    <FormConsumer>
      {({ values }) => {
        const checklistsIds = Object.keys(values)
        return checklistsIds.map((checklistId) => {
          return (
            <Fragment key={checklistId}>
              <Text gutterBottom variant="h6">
                {CHECKLIST_TYPE_TEXT[values[checklistId].type]}
              </Text>
              {values[checklistId].items.map((_, index) => (
                <ChecklistItemFields
                  key={index}
                  index={index}
                  checklistId={checklistId}
                />
              ))}
              <FieldArray
                name={`${checklistId}.items`}
                render={(items) => (
                  <AddItemButton
                    style={{ margin: theme.spacing(1, 0) }}
                    onClick={() =>
                      items.push({
                        name: '',
                        isChecked: false,
                        deadlineDate: moment().format('YYYY-MM-DD'),
                      })
                    }
                  />
                )}
              />
            </Fragment>
          )
        })
      }}
    </FormConsumer>
  </Form>
)

ChecklistEditForm.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default withTheme(ChecklistEditForm)
