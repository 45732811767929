import React from 'react'
import PropTypes from 'prop-types'
import { isObject, isEmpty } from 'lodash'
import { withTheme } from '@material-ui/styles'
import { BackButton, NextButton } from './Buttons'

export const validNextStep = async (formik, next) => {
  const result = await formik.validateForm()
  if (!isEmpty(result)) {
    return formik.submitForm()
  }
  return next()
}

const StepActions = ({
  totalSteps,
  activeStep,
  completeButton,
  onBack,
  onNext,
  theme,
}) => {
  const isLastStep = activeStep + 1 === totalSteps
  const displayCompleteBtn = isLastStep && isObject(completeButton)

  return (
    <footer style={{ marginTop: theme.spacing(3) }}>
      <BackButton
        disabled={activeStep === 0}
        onClick={onBack}
        style={{ marginRight: theme.spacing(1) }}
      >
        Back
      </BackButton>

      {displayCompleteBtn ? (
        completeButton
      ) : (
        <NextButton
          data-testid="order-creation-next-button"
          onClick={onNext}
          disabled={isLastStep}
        >
          Next
        </NextButton>
      )}
    </footer>
  )
}

StepActions.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  completeButton: PropTypes.element,
}

export default withTheme(StepActions)
