import React from 'react'
import { FieldArray } from 'formik'
import { useTheme } from '@material-ui/styles'
import FormConsumer from 'containers/formikFormConsumer/FormikFormConsumer'
import { CloseButton } from 'screens/orderCreate/components/CloseButton'
import { AddButton } from 'screens/orderCreateCommon/components/Buttons'
import Card from 'screens/orderCreate/components/Card'
import { NOMINATION_PORT_DEFAULT_VALUES } from 'screens/orderCreate/containers/NominationsInitialValues'
import TextField from 'containers/formikTextField/FormikTextField'
import TimeFrame from 'screens/orderCreate/components/TimeFrame'
import {
  ToBeNominated,
  Party,
} from 'screens/orderCreate/components/NominationPort'

const OTHER_NOMINATION_PORT_DEFAULT_VALUES = {
  ...NOMINATION_PORT_DEFAULT_VALUES,
  description: '',
}

export const OtherNominations = () => {
  const { spacing } = useTheme()
  return (
    <>
      <FieldArray
        name="nominationRules.other.alternatives"
        render={(arrayHelpers) => (
          <>
            <FormConsumer>
              {({ values }) =>
                values.nominationRules.other.alternatives.map((_, index) => (
                  <Card key={`nomination${index}`}>
                    <CloseButton onClick={() => arrayHelpers.remove(index)} />
                    <TextField
                      fullWidth
                      margin="normal"
                      multiline
                      InputLabelProps={{
                        error: false,
                      }}
                      type="text"
                      name={`nominationRules.other.alternatives[${index}].name`}
                      label="Item"
                    />

                    <TextField
                      fullWidth
                      multiline
                      margin="normal"
                      InputLabelProps={{
                        error: false,
                      }}
                      type="text"
                      name={`nominationRules.other.alternatives[${index}].description`}
                      label="Description"
                    />

                    <ToBeNominated
                      disabled={false}
                      name={`nominationRules.other.alternatives[${index}].toBeNominated`}
                      value={`nominationRules.other.alternatives[${index}].toBeNominated`}
                    />

                    <Party
                      values={values}
                      disabled={
                        !values.nominationRules.other.alternatives[index]
                          .toBeNominated
                      }
                      name={`nominationRules.other.alternatives[${index}].party`}
                    />

                    <TimeFrame
                      disabled={
                        !values.nominationRules.other.alternatives[index]
                          .toBeNominated
                      }
                      mainName={`nominationRules.other.alternatives[${index}]`}
                      timeframe={
                        values.nominationRules.other.alternatives[index]
                          .timeframe
                      }
                    />
                  </Card>
                ))
              }
            </FormConsumer>
            <AddButton
              style={{ margin: spacing(1, 0) }}
              onClick={() =>
                arrayHelpers.push(OTHER_NOMINATION_PORT_DEFAULT_VALUES)
              }
            >
              Add Term
            </AddButton>
          </>
        )}
      />
    </>
  )
}
