import React, { useState, useMemo } from 'react'
import { debounce } from 'lodash'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Tab, Tabs } from '@material-ui/core'
import { FileCopy as OrderIcon } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import {
  ORDERS_TAB,
  ALL_ORDERS_TAB,
  isOrdersTabSelected,
  isTemplatesTabSelected,
} from './OrdersAndTemplates.helper'
import { IconTitle } from 'screens/orderIndex/components/Block'
import Orders from 'screens/orderIndex/components/OrdersAndTemplates/Orders'
import Templates from 'screens/orderIndex/components/OrdersAndTemplates/Templates'
import { Tablet } from 'components/Viewport'
import useAppContext from 'containers/appContext/useAppContext'
import { useOrders } from 'api'
import { filterArrayOfObjects } from 'utils/dataHandlers'

const useStyles = makeStyles((theme) => ({
  tabBorderRight: {
    borderRight: `2px solid ${theme.palette.background.secondary}`,
  },
  titlePadding: {
    paddingLeft: '18px',
  },
  tabs: {
    '& .MuiTab-wrapper': {
      alignItems: 'start',
    },
  },
}))

const iconTextStyles = (theme) => ({
  textTransform: 'capitalize',
  fontSize: theme.typography.h6.fontSize,
  textAlign: 'center',
})

const DEBOUNCE_RATE = 500
export const OrdersAndTemplates = () => {
  const [filter, setFilter] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [tab, setTab] = useState(ORDERS_TAB)
  const [ordersTab, setOrdersTab] = useState(ALL_ORDERS_TAB)

  const onFilterChange = debounce((value) => setFilter(value), DEBOUNCE_RATE)

  const classes = useStyles()
  const theme = useTheme()
  const { isLNGContext, isCarbonContext } = useAppContext()

  const { isLoading, isError, data } = useOrders()
  const orders = data || []

  if (isError) {
    enqueueSnackbar('Connection failed updating orders', {
      variant: 'warning',
    })
  }

  const filteredOrders = useMemo(
    () => filterArrayOfObjects(orders, filter),
    [orders, filter]
  )

  const handleTabIndexChange = (_, tab) => {
    setTab(tab)
    setFilter('')
  }

  const titleSectionClasses = `
    ${isLNGContext ? classes.tabBorderRight : ''}
    ${isCarbonContext ? classes.titlePadding : ''}
  `

  return (
    <>
      <Tablet matchLarger>
        <Tabs
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          variant="fullWidth"
          value={tab}
          onChange={handleTabIndexChange}
          className={isLNGContext ? '' : classes.tabs}
        >
          <Tab
            data-testid="orders-tab"
            classes={{
              root: titleSectionClasses,
            }}
            disableRipple
            icon={
              <IconTitle
                style={{ marginRight: 0 }}
                textStyle={iconTextStyles(theme)}
                icon={OrderIcon}
              >
                Orders
              </IconTitle>
            }
          />
          {isLNGContext && (
            <Tab
              data-testid="templates-tab"
              disableRipple
              icon={
                <IconTitle
                  style={{ marginRight: 0 }}
                  textStyle={iconTextStyles(theme)}
                  icon={OrderIcon}
                >
                  Templates
                </IconTitle>
              }
            />
          )}
        </Tabs>
      </Tablet>
      {isOrdersTabSelected(tab) && (
        <Orders
          tab={ordersTab}
          setTab={setOrdersTab}
          isLoading={isLoading}
          orders={filteredOrders.filter(({ template }) => !template)}
          onFilterChange={onFilterChange}
        />
      )}
      {isTemplatesTabSelected(tab) && (
        <Templates
          isLoading={isLoading}
          templates={filteredOrders.filter(({ template }) => template)}
          onFilterChange={onFilterChange}
        />
      )}
    </>
  )
}
