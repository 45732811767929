import { useQuery } from 'react-query'
import { useApi } from 'containers/api/useApi'
import { PRICE_INDEX_KEY } from 'api/constants'

const FIVE_MINUTES = 5 * 60 * 1000

export const usePriceIndex = (markets = [], period = '') => {
  const api = useApi()

  return useQuery([PRICE_INDEX_KEY, period.toUpperCase(), { markets }], () => api.getPriceIndex({ markets, period: period.toUpperCase() }), {
    enabled: Boolean(markets.length && period),
    staleTime: FIVE_MINUTES,
  })
}
