import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { connect } from 'formik'
import { $T } from 'emsurge-selectors'
import { MULTIPLE_CARGO_DISCLAIMER } from './helpers'
import { CargoCard } from './CargoCard'
import { PaddedContainer } from 'screens/orderCreate/mobile/components'
import {
  getCargoOptions,
  setAllCargosDW,
  setAllCargosDates,
} from 'screens/orderCreate/components/DeliveryWindowCargoes.helpers'

const Paragraph = styled.p`
  white-space: pre-wrap;
  position: absolute;
  bottom: 0;
  text-align: justify;
  text-justify: inter-word;
  width: calc(100vw - (2 * var(--simple-modal__content-padding)));
`

const _DeliveryWindow = ({ formik: { values, setFieldValue } }) => {
  const { volume, delivery, nominationRules } = values

  const options = getCargoOptions({ volume, delivery, nominationRules })

  const onChangeCargoDeliveryWindow = (value) => {
    const window =
      $T.deliveryWindow.cargos.get(nominationRules)?.[0]?.deliveryPeriod

    if (value === window) {
      return
    }

    setAllCargosDW({
      cargoIndex: 0,
      newDeliveryWindowOption: value,
      mainCargoDeliveryWindowOptions: options[0],
      values,
      setFieldValue,
    })
  }

  const onChangeCargoDate = (dateFieldName, newValue) =>
    setAllCargosDates({
      dateFieldName,
      cargoIndex: 0,
      newValue,
      values,
      setFieldValue,
    })

  return (
    <PaddedContainer>
      <CargoCard
        index={0}
        options={options[0]}
        onChangeDeliveryWindow={onChangeCargoDeliveryWindow}
        onChangeDate={onChangeCargoDate}
      />

      <Paragraph>{MULTIPLE_CARGO_DISCLAIMER}</Paragraph>
    </PaddedContainer>
  )
}

_DeliveryWindow.propTypes = {
  formik: PropTypes.object.isRequired,
}
export const DeliveryWindow = connect(_DeliveryWindow)
