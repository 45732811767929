import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormikForm } from 'formik'
import { Typography, FormLabel, Box, makeStyles } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { BULLETIN_MESSAGE_TYPE_TEXT } from 'emsurge-selectors/constants'
import CloseButton from 'components/innerCloseButton/InnerCloseButton'
import RadioInputs from 'containers/FormikRadioInput/FormikRadioInput'
import TextField from 'containers/formikTextField/FormikTextField'
import SubmitButton from 'containers/formikSubmitButton/FormikSubmitButton'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1, 0),
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
}))

export const Form = ({ onClose }) => {
  const classes = useStyles()
  const options = Object.entries(BULLETIN_MESSAGE_TYPE_TEXT).map(
    ([value, label]) => ({ value, label })
  )

  return (
    <FormikForm>
      <Box
        classes={{ root: classes.margin }}
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h2">BROADCAST MESSAGE</Typography>
        <Box display="inline-flex">
          <SubmitButton
            data-testid="broadcast-message-send-button"
            icon={Check}
          >
            Send
          </SubmitButton>
          <CloseButton onClick={onClose} />
        </Box>
      </Box>

      <div className={`${classes.margin} ${classes.marginTop}`}>
        <FormLabel>Category</FormLabel>
        <RadioInputs name="messageType" options={options} />
      </div>

      <TextField
        inputProps={{ 'data-testid': 'broadcast-message-title' }}
        classes={{ root: classes.margin }}
        name="title"
        type="text"
        label="Title"
        fullWidth
      />

      <TextField
        inputProps={{ 'data-testid': 'broadcast-message-text' }}
        classes={{ root: classes.margin }}
        name="message"
        type="text"
        label="Message"
        rows="8"
        multiline
        fullWidth
      />
    </FormikForm>
  )
}

Form.defaultProps = {
  onClose: () => {},
}

Form.propTypes = {
  onClose: PropTypes.func,
}
