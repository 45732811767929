import { withProps } from 'recompose'
import TextField from 'containers/formikTextField/FormikTextField'

/**
 [1]. On error, the label is colored red as well the input + input helper text.
 This forces the default color. Opinionated style
 */
const NameField = withProps({
  name: 'name',
  label: 'Company Name',
  autoComplete: 'organization',
  fullWidth: true,
  margin: 'normal',
  required: true,
  InputLabelProps: {
    error: false /* [1] */,
  },
})(TextField)

const EmailField = withProps({
  name: 'email',
  type: 'email',
  label: 'Email Address',
  autoComplete: 'email',
  autoFocus: true,
  fullWidth: true,
  margin: 'normal',
  required: true,
  InputLabelProps: {
    error: false /* [1] */,
  },
})(TextField)

export { EmailField, NameField }
