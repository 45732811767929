import { useQuery } from 'react-query'
import { useApi } from 'containers/api/useApi'

export const useOrders = () => {
  const api = useApi()

  return useQuery('ALL_ORDERS', () => api.getOrdersForDashboard(), {
    refetchInterval: 3000,
    refetchIntervalInBackground: false,
    staleTime: Infinity
  })
}
