const { mapCountriesToData, OPTION_TYPE_IS_LDC } = require('./helpers')

const COUNTRIES_ASIA_KEYS = {
  ARMENIA: 'ARMENIA',
  AZERBAIJAN: 'AZERBAIJAN',
  BAHRAIN: 'BAHRAIN',
  CYPRUS: 'CYPRUS',
  GEORGIA: 'GEORGIA',
  IRAQ: 'IRAQ',
  ISRAEL: 'ISRAEL',
  JORDAN: 'JORDAN',
  KUWAIT: 'KUWAIT',
  LEBANON: 'LEBANON',
  OMAN: 'OMAN',
  QATAR: 'QATAR',
  SAUDI_ARABIA: 'SAUDI_ARABIA',
  STATE_OF_PALESTINE: 'STATE_OF_PALESTINE',
  SYRIAN_ARAB_REPUBLIC: 'SYRIAN_ARAB_REPUBLIC',
  TURKEY: 'TURKEY',
  UNITED_ARAB_EMIRATES: 'UNITED_ARAB_EMIRATES',
  YEMEN: 'YEMEN',
  KAZAKHSTAN: 'KAZAKHSTAN',
  KYRGYZSTAN: 'KYRGYZSTAN',
  TAJIKISTAN: 'TAJIKISTAN',
  TURKMENISTAN: 'TURKMENISTAN',
  UZBEKISTAN: 'UZBEKISTAN',
  AFGHANISTAN: 'AFGHANISTAN',
  BANGLADESH: 'BANGLADESH',
  BHUTAN: 'BHUTAN',
  INDIA: 'INDIA',
  IRAN_ISLAMIC_REPUBLIC_OF: 'IRAN_ISLAMIC_REPUBLIC_OF',
  MALDIVES: 'MALDIVES',
  NEPAL: 'NEPAL',
  PAKISTAN: 'PAKISTAN',
  SRI_LANKA: 'SRI_LANKA',
  CHINA: 'CHINA',
  CHINA_HONG_KONG_SAR: 'CHINA_HONG_KONG_SAR',
  CHINA_MACAO_SAR: 'CHINA_MACAO_SAR',
  CHINA_TAIWAN_PROVINCE_OF_CHINA: 'CHINA_TAIWAN_PROVINCE_OF_CHINA',
  DEM_PEOPLE_REPUBLIC_OF_KOREA: 'DEM_PEOPLE_REPUBLIC_OF_KOREA',
  JAPAN: 'JAPAN',
  MONGOLIA: 'MONGOLIA',
  REPUBLIC_OF_KOREA: 'REPUBLIC_OF_KOREA',
  BRUNEI_DARUSSALAM: 'BRUNEI_DARUSSALAM',
  CAMBODIA: 'CAMBODIA',
  INDONESIA: 'INDONESIA',
  LAO_PEOPLE_DEMOCRATIC_REPUBLIC: 'LAO_PEOPLE_DEMOCRATIC_REPUBLIC',
  MALAYSIA: 'MALAYSIA',
  MYANMAR: 'MYANMAR',
  PHILIPPINES: 'PHILIPPINES',
  SINGAPORE: 'SINGAPORE',
  THAILAND: 'THAILAND',
  TIMOR_LESTE: 'TIMOR_LESTE',
  VIETNAM: 'VIETNAM',
}

const COUNTRIES_ASIA_VALUES = {
  ARMENIA: 'Armenia',
  AZERBAIJAN: 'Azerbaijan',
  BAHRAIN: 'Bahrain',
  CYPRUS: 'Cyprus',
  GEORGIA: 'Georgia',
  IRAQ: 'Iraq',
  ISRAEL: 'Israel',
  JORDAN: 'Jordan',
  KUWAIT: 'Kuwait',
  LEBANON: 'Lebanon',
  OMAN: 'Oman',
  QATAR: 'Qatar',
  SAUDI_ARABIA: 'Saudi Arabia',
  STATE_OF_PALESTINE: 'State of Palestine',
  SYRIAN_ARAB_REPUBLIC: 'Syrian Arab Republic',
  TURKEY: 'Turkey',
  UNITED_ARAB_EMIRATES: 'United Arab Emirates',
  YEMEN: 'Yemen',
  KAZAKHSTAN: 'Kazakhstan',
  KYRGYZSTAN: 'Kyrgyzstan',
  TAJIKISTAN: 'Tajikistan',
  TURKMENISTAN: 'Turkmenistan',
  UZBEKISTAN: 'Uzbekistan',
  AFGHANISTAN: 'Afghanistan',
  BANGLADESH: 'Bangladesh',
  BHUTAN: 'Bhutan',
  INDIA: 'India',
  IRAN_ISLAMIC_REPUBLIC_OF: 'Iran (Islamic Republic of)',
  MALDIVES: 'Maldives',
  NEPAL: 'Nepal',
  PAKISTAN: 'Pakistan',
  SRI_LANKA: 'Sri Lanka',
  CHINA: 'China',
  CHINA_HONG_KONG_SAR: 'China, Hong Kong SAR',
  CHINA_MACAO_SAR: 'China, Macao SAR',
  CHINA_TAIWAN_PROVINCE_OF_CHINA: 'China, Taiwan Province of China',
  DEM_PEOPLE_REPUBLIC_OF_KOREA: "Dem. People's Republic of Korea",
  JAPAN: 'Japan',
  MONGOLIA: 'Mongolia',
  REPUBLIC_OF_KOREA: 'Republic of Korea',
  BRUNEI_DARUSSALAM: 'Brunei Darussalam',
  CAMBODIA: 'Cambodia',
  INDONESIA: 'Indonesia',
  LAO_PEOPLE_DEMOCRATIC_REPUBLIC: "Lao People's Democratic Republic",
  MALAYSIA: 'Malaysia',
  MYANMAR: 'Myanmar',
  PHILIPPINES: 'Philippines',
  SINGAPORE: 'Singapore',
  THAILAND: 'Thailand',
  TIMOR_LESTE: 'Timor-Leste',
  VIETNAM: 'Viet Nam',
}

const COUNTRIES_ASIA_INCLUDED_LDCS = [
  COUNTRIES_ASIA_KEYS.YEMEN,
  COUNTRIES_ASIA_KEYS.AFGHANISTAN,
  COUNTRIES_ASIA_KEYS.BANGLADESH,
  COUNTRIES_ASIA_KEYS.BHUTAN,
  COUNTRIES_ASIA_KEYS.NEPAL,
  COUNTRIES_ASIA_KEYS.CAMBODIA,
  COUNTRIES_ASIA_KEYS.LAO_PEOPLE_DEMOCRATIC_REPUBLIC,
  COUNTRIES_ASIA_KEYS.MYANMAR,
  COUNTRIES_ASIA_KEYS.TIMOR_LESTE,
]

const COUNTRIES_ASIA = mapCountriesToData(
  COUNTRIES_ASIA_KEYS,
  COUNTRIES_ASIA_VALUES,
  {
    type: OPTION_TYPE_IS_LDC,
    elements: COUNTRIES_ASIA_INCLUDED_LDCS,
  }
)

module.exports = {
  COUNTRIES_ASIA_KEYS,
  COUNTRIES_ASIA_VALUES,
  COUNTRIES_ASIA,
}
