import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { isCancelledError } from 'react-query'
import { APP_CONTEXT_TYPE } from 'emsurge-selectors'
import ModalHeader from './components/ModalHeader'
import { Information as InformationLNG } from './components/Information'
import { Information as InformationCarbon } from './components/InformationCarbon'
import CommentsPanel from './components/CommentsPanel'
import { EditLogPanel } from './components/EditLogPanel'
import { Panel } from './components/Panel'
import Termsheet from './components/Termsheet'
import Checklist from './components/Checklist'
import Modal from './containers/Modal'
import ModalHeaderCarbon from './components/ModalHeaderCarbon'
import withParams from 'utils/withRouteParams'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { useOrder } from 'api'
import { useRouteBaseSlug } from 'utils/useRouteBaseSlug'

const TabContentComponent = ({ tabIndex, ...props }) => {
  switch (tabIndex) {
    case 0:
      return <Panel component={InformationLNG} {...props} />
    case 1:
      return <Panel component={Termsheet} {...props} />
    case 2:
      return <Panel component={Checklist} {...props} />
    case 3:
      return <Panel component={CommentsPanel} label="Comments" {...props} />
    case 4:
      return <Panel component={EditLogPanel} {...props} />
    default:
      throw new Error('Undefined tab index')
  }
}

TabContentComponent.propTypes = {
  tabIndex: PropTypes.number.isRequired,
}

const TabContentComponentCarbon = ({ tabIndex, ...props }) => {
  switch (tabIndex) {
    case 0:
      return <Panel component={InformationCarbon} {...props} />
    case 1:
      return <Panel component={CommentsPanel} label="Comments" {...props} />
    case 2:
      return <Panel component={EditLogPanel} {...props} />
    default:
      throw new Error('Undefined tab index')
  }
}

TabContentComponentCarbon.propTypes = {
  tabIndex: PropTypes.number.isRequired,
}

const ViewOrder = ({ id, history, location, onClose }) => {
  const [tabIndex, setTab] = useState(0)
  const { isLoading, error, data: order } = useOrder(id)
  const BASE_SLUG = useRouteBaseSlug()

  const onModalClose = () => {
    const { pathname } = history.location

    if (pathname.includes('edit')) {
      return
    }

    onClose()
    if (location.state?.referrer) {
      history.push(location.state.referrer)
    }
  }

  if (isLoading) {
    return (
      <Modal>
        <Panel component={LoadingSpinner} />
      </Modal>
    )
  }

  if (error && !isCancelledError(error)) {
    return <Redirect to={`/${BASE_SLUG}`} />
  }

  return (
    <Modal onClose={onModalClose}>
      {order.context === APP_CONTEXT_TYPE.LNG && (
        <>
          <ModalHeader
            order={order}
            activeTab={tabIndex}
            setTab={setTab}
            onClose={onModalClose}
          />
          <TabContentComponent tabIndex={tabIndex} order={order} />
        </>
      )}
      {order.context === APP_CONTEXT_TYPE.CARBON && (
        <>
          <ModalHeaderCarbon
            order={order}
            activeTab={tabIndex}
            setTab={setTab}
            onClose={onModalClose}
          />
          <TabContentComponentCarbon tabIndex={tabIndex} order={order} />
        </>
      )}
    </Modal>
  )
}

ViewOrder.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

export default withParams(ViewOrder)
