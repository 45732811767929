import { defaultProps } from 'recompose'
import FormikTextField from 'containers/formikTextField/FormikTextField'
import { DeferredTextField as _DeferredTextField } from 'containers/formikTextField/DeferredTextField'
import { UppercaseTextField as _UppercaseTextField } from 'containers/formikTextField/UppercaseTextField'

export const TextField = defaultProps({
  fullWidth: true,
  margin: 'normal',
})(FormikTextField)

export const DeferredTextField = defaultProps({
  fullWidth: true,
  margin: 'normal',
})(_DeferredTextField)

export const UppercaseTextField = defaultProps({
  fullWidth: true,
  margin: 'normal',
})(_UppercaseTextField)
