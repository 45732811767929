import * as React from 'react'
import PropTypes from 'prop-types'
import MuiTableRow from '@material-ui/core/TableRow/TableRow'
import TableRowLink from 'components/tableRowLink/TableRowLink'

const TableRow = (props) => {
  const renderColumns = () => {
    const mapArr = props.columns
      .filter((columnDef) => !columnDef.hidden)
      .map((columnDef) => {
        const value = props.getFieldValue(props.data, columnDef)
        return (
          <props.components.Cell
            columnDef={columnDef}
            value={value}
            key={columnDef.tableData.id}
            rowData={props.data}
          />
        )
      })
    return mapArr
  }

  const getStyle = () => {
    if (!props.options.rowStyle) {
      return {}
    }
    let style = props.options.rowStyle
    if (typeof props.options.rowStyle === 'function') {
      style = props.options.rowStyle(props.data)
    }
    return style
  }

  const { getLink } = props.options
  const isNavigationRow =
    getLink !== undefined && typeof props.options.getLink === 'function'
  const RowComponent = isNavigationRow ? TableRowLink : MuiTableRow
  const rowProps = {
    style: getStyle(),
    to: isNavigationRow ? getLink(props.data) : undefined,
  }

  return <RowComponent {...rowProps}>{renderColumns()}</RowComponent>
}

TableRow.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  columns: PropTypes.array,
}

export default TableRow
