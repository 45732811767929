const getOwner = (order) => {
  return order.person || {}
}

const getOwnerFullName = (order) => {
  const { name, surname } = getOwner(order)

  return {
    name,
    surname,
  }
}

const getOwnerFullNameText = (order) => {
  const owner = getOwnerFullName(order)
  let fullName = ''

  if (owner.name) {
    fullName += `${owner.name} `
  }

  if (owner.surname) {
    fullName += `${owner.surname}`
  }

  return fullName.trim()
}

const getOwnerSummary = (order) => {
  const owner = getOwner(order)

  return {
    ...owner,
    fullName: getOwnerFullNameText(order),
  }
}

module.exports = {
  get: getOwner,
  summary: getOwnerSummary,
  fullName: {
    get: getOwnerFullName,
    text: getOwnerFullNameText,
  },
}
