import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withTheme, makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import FormProvider from './containers/FormProvider'
import withParams from 'utils/withRouteParams'
import Fetch from 'containers/fetchPlus/FetchPlus'
import NewNoteForm from 'screens/noteCreate/components/NewNoteForm'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))

const NoteView = ({ id, refetch }) => {
  const classes = useStyles()
  useEffect(() => {
    refetch()
  }, [id, refetch])

  return (
    <Paper className={classes.root}>
      <Fetch url={`/notes/${id}`}>
        {(note) => (
          <FormProvider note={note}>
            <NewNoteForm />
          </FormProvider>
        )}
      </Fetch>
    </Paper>
  )
}

NoteView.propTypes = {
  id: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
}

export default compose(withTheme, withParams)(NoteView)
