import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { ADMIN_VIEW_COMPANY } from 'routes'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

/* :: () -> object */
const getInitialValues = (company) => ({
  info: {
    name: '',
    registrationName: '',
    registrationNumber: '',
    code: '',
    addressFirstLine: '',
    addressSecondLine: '',
    postcode: '',
    city: '',
    country: '',
    type: company.type,
  },
  primaryContact: {
    name: '',
    surname: '',
    jobTitle: '',
    phone: '',
    addressFirstLine: '',
    addressSecondLine: '',
    postcode: '',
    city: '',
    country: '',
  },
  signatory: {
    name: '',
    surname: '',
    jobTitle: '',
    phone: '',
  },
})

export const getFormSchema = () =>
  object().shape({
    info: object().shape({
      name: string().required('Required'),
      registrationName: string().required('Required'),
      registrationNumber: string().required('Required'),
      code: string()
        .max(1, 'Code is single character that is used with the company code')
        .required('Required'),
      addressFirstLine: string().required('Required'),
      addressSecondLine: string(),
      postcode: string().required('Required'),
      city: string().required('Required'),
      country: string().required('Required'),
      type: string().required('Required'),
    }),
    primaryContact: object().shape({
      name: string().required('Required'),
      surname: string().required('Required'),
      jobTitle: string().required('Required'),
      email: string().email().required('Required'),
      phone: string().required('Required'),
      addressFirstLine: string().required('Required'),
      addressSecondLine: string(),
      postcode: string().required('Required'),
      city: string().required('Required'),
      country: string().required('Required'),
    }),
    signatory: object().shape({
      name: string().required('Required'),
      surname: string().required('Required'),
      jobTitle: string().required('Required'),
      email: string().email().required('Required'),
      phone: string().required('Required'),
    }),
  })

const FormProvider = ({
  company,
  companyId,
  history,
  enqueueSnackbar,
  children,
}) => {
  const api = useApi()

  /* :: (object, object) -> Promise<void> */
  const handleSubmit = async (values, form) => {
    const data = {
      companyId,
      ...values.info,
      authorisedSignatory: values.signatory,
      pointOfContact: values.primaryContact,
    }

    try {
      await api.post('/entities', data)
      enqueueSnackbar('Entity created successful', { variant: 'success' })
      history.push(`${ADMIN_VIEW_COMPANY.replace(':id', companyId)}`)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Entity creation not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
    } finally {
      form.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={getInitialValues(company)}
      validationSchema={getFormSchema(getInitialValues(company))}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}

FormProvider.propTypes = {
  company: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}

export default compose(withRouter, withSnackbar)(FormProvider)
