import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Text from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { withRouter } from 'react-router'
import { withSnackbar } from 'notistack'
import { compose } from 'recompose'
import { COMPANY_STATUS } from 'emsurge-selectors/constants'
import { CancelButton, ConfirmButton } from '../components/Buttons'
import { ADMIN_VIEW_COMPANIES } from 'routes'
import { useApi } from 'containers/api/useApi'
import { getApiErrorMessage } from 'containers/api/helpers'

const ApproveCompanyDialog = ({
  company,
  children,
  history,
  enqueueSnackbar,
}) => {
  const api = useApi()

  const [isOpen, setOpen] = useState(false)
  const open = () => setOpen(true)
  const close = () => setOpen(false)

  /* :: () -> Promise<void> */
  const approveCompany = async () => {
    try {
      await api.patch(`/registration/companies/${company.id}`, {
        company: {
          status: COMPANY_STATUS.PENDING_APPROVAL,
        },
      })
      enqueueSnackbar('Company creation successful', { variant: 'success' })
      history.push(ADMIN_VIEW_COMPANIES)
    } catch (error) {
      const message = getApiErrorMessage({
        error,
        defaultMessage: 'Company creation not successful',
      })
      enqueueSnackbar(message, { variant: 'error' })
      close()
    }
  }

  return (
    <>
      <Dialog open={isOpen} onClose={close}>
        <DialogContent>
          <Text variant="h6">Please confirm to definitely create company</Text>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={close}>Cancel</CancelButton>
          <ConfirmButton onClick={approveCompany}>Confirm</ConfirmButton>
        </DialogActions>
      </Dialog>

      {children({ accept: open })}
    </>
  )
}

ApproveCompanyDialog.propTypes = {
  children: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
}

const enhance = compose(withRouter, withSnackbar)

export default enhance(ApproveCompanyDialog)
