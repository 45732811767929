import React from 'react'
import PropTypes from 'prop-types'
import Header from './components/Header'
import NewEntityForm from './components/NewEntityForm'
import EntitiesTable from './components/EntitiesTable'
import withRouteParams from 'utils/withRouteParams'
import Fetch from 'containers/fetchPlus/FetchPlus'

const BrokerPermissionedEntities = ({ id }) => (
  <Fetch url={`/permissions/broker/${id}`}>
    {(permissionedEntities, refetch) => (
      <section>
        <Header />

        <NewEntityForm
          permissionedEntities={permissionedEntities}
          refetch={refetch}
        />

        <EntitiesTable entities={permissionedEntities} refetch={refetch} />
      </section>
    )}
  </Fetch>
)

BrokerPermissionedEntities.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withRouteParams(BrokerPermissionedEntities)
