const { isEmpty, isNumber } = require('lodash')
const {
  PRICE_TYPE_KEYS,
  PRICE_TYPE_VALUES,
  PRICE_VOLUME_DEPENDENT_TYPE_KEYS,
  getCurrencyByKey,
} = require('../../../constants')

const computeVolumeInputNumber = (amount) => {
  const regex = /,/g
  const numberAmount = Number(String(amount).replace(regex, ''))
  const invalidNumber = !isNumber(numberAmount) || isNaN(numberAmount)
  return invalidNumber ? '' : numberAmount
}

const formatNumber = (numbers, currency = [], suffix = '') => {
  const formatted = numbers.map((price, index) => {
    const prefix = currency[index] || ''
    const fullPrice = computeVolumeInputNumber(price)
    const decimal = String(fullPrice).split('.')
    const minimumFractionDigits = Math.max(
      2,
      decimal.length < 2 ? 2 : decimal.pop().length
    )
    const amount = Number(fullPrice).toLocaleString('en-US', {
      minimumFractionDigits,
    })
    return !price ? '' : `${prefix}${amount}${suffix}`
  })
  const filterFormatted = formatted.filter((val) => !!val)
  return filterFormatted.length !== numbers.length
    ? ''
    : filterFormatted.join('-')
}

const formatPrice = (prices, currency, suffix = '') =>
  formatNumber(prices, currency, suffix)
const formatQuantity = (quantities) => formatNumber(quantities, '', ' tCO2e')
const getCurrencyBySymbol = (key) => {
  const { symbol } = getCurrencyByKey(key) || {}
  return symbol
}

const getPriceTextFixed = ({ basic, type }) => {
  return {
    text: String(basic.amount),
    currency: getCurrencyBySymbol(basic.fixedCurrency),
    hasUnit: true,
    type,
    typeText: PRICE_TYPE_VALUES[type],
  }
}

const getPriceTextRange = ({ basic, type }) => {
  const { min, max, currency } = basic || {}
  const currencySymbol = getCurrencyBySymbol(currency)
  return {
    range: [min, max],
    currency: [currencySymbol, currencySymbol],
    hasUnit: true,
    type,
    typeText: PRICE_TYPE_VALUES[type],
  }
}

const isValidDate = (d) => d instanceof Date && !isNaN(d)
const formatDate = (d) =>
  d.toLocaleString('en-US', {
    weekday: 'short',
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
  })

const getFormattedDataObject = ({ date, time }, title) => {
  const dateToFormat = new Date(`${date} ${time}`)
  const detail = []
  if (isValidDate(dateToFormat)) {
    detail.push({
      text: formatDate(dateToFormat),
      title,
    })
  }
  return detail
}

const getPriceTextRFQ = (price) => {
  const { type, basic } = price
  const { deadline, award, notes } = basic || {}
  const detail = [
    ...[ deadline ? {...getFormattedDataObject(deadline, 'Deadline Date')} : {}],
    ...[ award ? {...getFormattedDataObject(award, 'Award Date')} : {}],
    ...[ notes ? { title: 'Notes', text: notes } : {}],
  ]
  return {
    type,
    typeText: PRICE_TYPE_VALUES[type],
    detail,
  }
}

const getPriceTextTBD = ({ type }) => ({ text: type, type })

const getQuantityText = (type, quantity, quantityFrom, quantityTo) => {
  if (type === PRICE_VOLUME_DEPENDENT_TYPE_KEYS.FROM.key) {
    return `${formatQuantity([quantityFrom])} to ${formatQuantity([
      quantityTo,
    ])}`
  }
  return formatQuantity([quantity])
}

const getVolumeDependentDescription = ({
  type,
  quantity,
  quantityFrom,
  quantityTo,
  price,
  currency,
}) => {
  const typeText = PRICE_VOLUME_DEPENDENT_TYPE_KEYS[type].value
  const quantityText = getQuantityText(type, quantity, quantityFrom, quantityTo)
  const priceText = formatPrice([price], [getCurrencyBySymbol(currency)])
  return `${typeText} ${quantityText} - ${priceText}`
}

const getPriceTextVolumeDependent = ({ basic, type }) => {
  const basicValue = basic.length > 0 ? basic : [{}]
  const prices = basicValue
    .map(({ price, currency }) => ({
      amount: Number(price),
      currency: getCurrencyBySymbol(currency),
    }))
    .sort((a, b) => a.amount - b.amount)
  const { amount: min, currency: minCurrency } = prices[0]
  const { amount: max, currency: maxCurrency } = prices[prices.length - 1]
  const priceLines = (basic || []).map((priceLine, index) => ({
    text: getVolumeDependentDescription(priceLine),
    title: `Parameter #${index + 1}`,
  }))
  return {
    range: [max, min],
    currency: [maxCurrency, minCurrency],
    hasUnit: true,
    type,
    typeText: PRICE_TYPE_VALUES[type],
    detail: priceLines,
  }
}

const FN_MAPPER = {
  [PRICE_TYPE_KEYS.FIXED]: getPriceTextFixed,
  [PRICE_TYPE_KEYS.RANGE]: getPriceTextRange,
  [PRICE_TYPE_KEYS.RFQ]: getPriceTextRFQ,
  [PRICE_TYPE_KEYS.TBD]: getPriceTextTBD,
  [PRICE_TYPE_KEYS.VOLUME_DEPENDENT]: getPriceTextVolumeDependent,
}

const getPriceText = (order) => {
  const { price } = order
  const fn = FN_MAPPER[price.type]
  const calculatedPrice = fn ? fn(price) : {}
  const { text, range, hasUnit, type, typeText, detail, currency } =
    calculatedPrice
  const fixedText = text && formatPrice([text], [currency])
  const rangeText = !isEmpty(range) && formatPrice(range, currency)
  return {
    value: hasUnit ? fixedText || rangeText || '' : type,
    hasUnit,
    typeText,
    detail,
  }
}

const getPrice = (order) => order.price

module.exports = {
  get: getPrice,
  text: getPriceText,
}
